import {
  takeEvery, put, call, all, takeLatest,
} from 'redux-saga/effects';
import { batchActions } from 'redux-batched-actions';
import forEach from 'lodash/forEach';

import * as enumAction from '../actions/enum';
import api from '../../api';
import { apiUrls } from '../../api/constants';
import { logoutHandler } from './util';
import { enums as enumNames } from '../../lib/constants';

const enumNamesCallWithMaster = ['BILLING_STATE', 'BILLING_STAGE', 'CLAIMS_QUEUE'];

export const enumSaga = function* ({
  enumId, parentId,
}) {
  const params = { EnumId: enumId };
  if (enumId) {
    try {
      if (parentId) {
        params.ParentId = parentId;
      }
      const response = yield call(api.get, {
        token: localStorage.getDecryptedData('token'),
        url: apiUrls.GET_ENUM_FIELDS,
        params,
      });
      yield put(enumAction.setEnumFieldData(enumId, parentId, response));
    } catch (e) {
      yield put(logoutHandler(e));
    }
  }
};

export const getEnumSaga = function* () {
  yield put(enumAction.setLoading(true));
  try {
    const result = yield call(api.get, {
      token: localStorage.getDecryptedData('token'),
      url: apiUrls.GET_ENUM,
    });
    const enums = {};
    if (result) {
      forEach(result, (item) => {
        enums[item.enumName] = item;
      });
    }
    yield put(batchActions([
      enumAction.setEnumData(enums),
      enumAction.setError(null),
    ]));
    if (enums) {
      yield put(batchActions(enumNamesCallWithMaster.map(
        (item) => (enumAction.getEnumMasterData(enums[enumNames[item]]?.enumId,
          undefined)),
      )));
    }
  } catch (e) {
    yield put(batchActions([
      logoutHandler(e),
      enumAction.setError(e),
    ]));
  } finally {
    yield put(enumAction.setLoading(false));
  }
};

export default function* root() {
  yield all([
    takeEvery(enumAction.GET_ENUM_FIELD_DATA, enumSaga),
    takeLatest(enumAction.GET_ENUM_MASTER_DATA, getEnumSaga),
  ]);
}
