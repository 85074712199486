import React, { useCallback } from 'react';
import { Form as Antd } from 'antd';

import moment from 'moment';
import Button from '../../../../../components/Button';
import Form from '../../../../../components/Form';
import Notification from '../../../../../components/Notification';

import {
  labelPaths, contentType, formId,
} from '../../../../../lib/constants';
import rights from '../../../../../lib/rights';
import { contentTypeCalculator } from '../../../../../lib/util';
import WithLabel from '../../../../../hoc/withLabel';

import useRedirect from '../../../../../hooks/useRedirect';
import useRights from '../../../../../hooks/useRights';
import useReduxState from '../../../../../hooks/useReduxState';

import ContractForm from '../../../Components/ContractForm';

import { apiUrls } from '../../../../../api/constants';

import formFieldValuesParser from '../../../../../lib/formFieldValuesParser';
import SuccessMessages from '../../../../../lib/successMessages';

const isContractExpired = (effectiveTo) => !!effectiveTo?.isBefore(moment());

const selectedLocationParser = (locations) => {
  const locationsArr = [];
  locations?.forEach((location, index) => {
    locationsArr[index] = { ...location };
    locationsArr[index].name = location.locationName;
    locationsArr[index].id = location.locationId;
  });
  return locationsArr;
};

const selectedPayerParser = (payers) => {
  const payersArr = [];
  payers?.forEach((payer, index) => {
    payersArr[index] = { ...payer };
    payersArr[index].name = payer.payerName;
    payersArr[index].id = payer.payerId;
  });
  return payersArr;
};

const selectedProviderParser = (providers) => {
  const providersArr = [];
  providers?.forEach((provider, index) => {
    providersArr[index] = { ...provider };
    providersArr[index].name = provider.providerName;
    providersArr[index].id = provider.providerId;
  });
  return providersArr;
};

const parser = (values, allValues) => {
  const parsedValues = formFieldValuesParser(values, {
    date: [
      'effectiveFrom',
      'effectiveTo',
    ],
    isDateToString: true,
    files: ['contractFile'],
  });
  parsedValues.SelectedContractPayerIds = allValues.selectedPayers?.map(
    (item) => item.insurancePayerId || item.payerId);
  return parsedValues;
};

function General({ labels, isUpdateContractAuthenticated }) {
  const { params } = useRedirect();
  const { id, tabId } = params;

  const [form] = Antd.useForm();

  const [isClear, setClear] = useReduxState('is-clear', false);
  const [contractProviders, setSelectedContractProviders] = useReduxState('contract-providers');
  const [isValueRecalculationAuthenticated] = useRights([rights.can_schedule_value_recalculation]);

  const initialDataParser = (values) => {
    setSelectedContractProviders(values?.selectedContractProviders);
    const data = formFieldValuesParser(values, {
      date: [
        'effectiveFrom',
        'effectiveTo',
      ],
    });
    const {
      selectedContractProviders,
      selectedContractPayers,
      selectedContractLocations,
      contractFiles,
    } = data;
    data.selectedProviders = selectedProviderParser(selectedContractProviders);
    data.selectedPayers = selectedPayerParser(selectedContractPayers);
    data.payerName = data?.selectedPayers?.[0]?.name;
    data.selectedLocations = selectedLocationParser(selectedContractLocations);
    const clonedContractFile = [];
    contractFiles.forEach((value, index) => {
      const {
        documentName, documentType, documentURL, documentId,
      } = value;
      clonedContractFile.push({
        uid: index,
        name: documentName,
        status: 'done',
        url: documentURL,
        type: contentTypeCalculator(documentType),
        documentId,
      });
    });
    data.contractFile = clonedContractFile;
    return data;
  };

  const onClear = useCallback((resetForm) => {
    setClear(true);
    resetForm();
  }, [setClear]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.CONTRACT_UPDATED_SUCCESSFULLY, success: true });
    }
  }, []);

  return (
    <Form
      section
      form={form}
      parser={parser}
      getUrl={`${apiUrls.GET_CONTRACT}/${id}`}
      url={`${apiUrls.UPDATE_CONTRACT}`}
      contentType={contentType.MULTIPART}
      formId={`${formId.EDIT_CONTRACT}-${id}`}
      isUpdate
      initialDataParser={initialDataParser}
      onRequestComplete={onRequestComplete}
      tabId={tabId}
      name={`${formId.EDIT_CONTRACT}-${id}`}
      extraData={{
        ContractId: id,
      }}
      disableForm={!isUpdateContractAuthenticated}
    >
      {({ resetForm }) => (
        <>
          <Antd.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => (
              prevValues.effectiveTo !== currentValues.effectiveTo)}
          >
            {({ getFieldValue }) => isContractExpired(getFieldValue('effectiveTo')) && <div className="danger mr-btm-6"> Contract Expired</div>}
          </Antd.Item>
          <ContractForm
            form={form}
            isClear={isClear}
            setClear={setClear}
            isUpdate
            contractProviders={contractProviders}
          />
          <div className="form-btns contract-btns">
            <Button className="btn btn-success" type="primary" htmlType="submit">{labels.get('buttons.update')}</Button>
            {isValueRecalculationAuthenticated && (
            <Button className="btn btn-success">{labels.get('buttons.requestRecalculation')}</Button>
            )}
            <Button className="btn btn-ghost" onClick={() => onClear(resetForm)}>{labels.get('buttons.clear')}</Button>
          </div>
        </>
      )}
    </Form>
  );
}

export default WithLabel(General, labelPaths.EDIT_CONTRACT);
