import React, { useCallback } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';

import { enums, enumMasterCode } from '../../../../../../../../lib/constants';
import IconNames from '../../../../../../../../lib/iconNames';
import Smcheckbox from '../../../../../../../../components/SmCheckbox';
import { Input } from '../../../../../../../../components/Form/inputFields';
import Icon from '../../../../../../../../components/Icon';

import CPTAutocomplete from '../../../../../../../../wiredComponents/CPTAutocomplete';
import SelectBox from '../../../../../../../../wiredComponents/Enum/Select';
import WiredModifier from '../../../../../../../../wiredComponents/SingleModifier';
import useMasterId from '../../../../../../../../hooks/useMasterId';

const masterCodes = [
  enumMasterCode.contractTermExceptionTypeIncluded,
];

const enumNames = [
  enums.CONTRACT_TERM_EXCEPTION_TYPE,
];

const EditableCPTCell = ({
  label,
  editable,
  form,
  initialValue,
  onSelect,
  error,
  value,
  parentFocusId,
}) => {
  const onSelectCode = useCallback((selectedValue, allItem) => {
    onSelect(allItem?.item);
  }, [onSelect]);
  return (
    <CPTAutocomplete
      className={classNames({ 'error-border': !!error })}
      allowClearOnSelect={false}
      type
      disabled={!editable}
      name={null}
      labelSpan="0"
      inputSpan="20"
      label={label}
      form={form}
      initialValue={initialValue}
      selectProps={{
        onChange: onSelectCode,
        id: `${parentFocusId}_cpt_search`,
      }}
      value={value}
      showTotalCount
      dropdownMatchSelectWidth={500}
    />
  );
};
const EditableInputCell = ({
  value,
  label,
  required,
  editable,
  name,
  onChange,
  error,
  id,
  testid,
}) => (
  <Input
    disabled={!editable}
    className={classNames({ 'non-editable': !editable }, { 'error-border': !!error })}
    name={name}
    style={{ minWidth: 60 }}
    value={value}
    labelSpan="0"
    inputSpan="24"
    label={label}
    required={required}
    onChange={onChange}
    dataTestId={testid}
    inputProps={{
      id,
    }}
  />
);

const EditableCheckBoxCell = ({
  checked,
  onChange,
}) => (
  <Smcheckbox labelSpan="0" inputSpan="14" id="23" checked={checked} onChange={onChange} />
);

const EditableSelectCell = ({
  editable,
  enumName,
  onChange,
  value,
  error,
  parentFocusId,
  testId,
}) => (
  <SelectBox
    disabled={!editable}
    className={classNames({ 'error-border': !!error })}
    id="23"
    labelSpan="0"
    name={null}
    inputSpan="24"
    placeholder=""
    enumName={enumName}
    required
    value={value}
    onChange={onChange}
    selectProps={{
      id: `${parentFocusId}_type`,
    }}
    dataTestId={testId}
  />
);

const ExceptionComponent = ({
  labels,
  exception,
  onChange,
  form,
  isEdit,
  dataError,
  parentFocusId,
  onDeleteException,
  exceptionId,
  // contractId,
  // contractTermId,
}) => {
  const masterCodesWithId = useMasterId(masterCodes, enumNames);
  return (
    <>
      <td>
        <EditableCheckBoxCell
          checked={get(exception, 'isRange')}
          onChange={onChange('isRange')}
        />
      </td>
      <td>
        <div className="field-row flex align-center">
          <div className="min-width-procedure">
            <EditableCPTCell
              editable={isEdit}
              label={labels.get('labels.procedure')}
              required
              labels={labels}
              form={form}
              initialValue={get(exception, 'procedureFromCode') && {
                name: get(exception, 'procedureFromCode'),
                value: get(exception, 'procedureFromId'),
                cptCode: get(exception, 'procedureFromCode'),
                description: get(exception, 'procedureFromDescription'),
              }}
              onSelect={onChange('procedureFrom')}
              error={dataError.procedureFrom}
              value={exception?.procedureFromId}
              parentFocusId={parentFocusId}
            />
          </div>
          {get(exception, 'isRange') && (
          <>
            <div className="to">To</div>
            <div className="min-width-procedure">
              <EditableCPTCell
                form={form}
                editable={isEdit}
                labels={labels}
                required
                initialValue={get(exception, 'procedureToCode') && {
                  name: get(exception, 'procedureToCode'),
                  value: get(exception, 'procedureToId'),
                  cptCode: get(exception, 'procedureToCode'),
                  description: get(exception, 'procedureToDescription'),
                }}
                onSelect={onChange('procedureTo')}
                error={dataError.procedureTo}
                value={exception?.procedureToId}
                parentFocusId={`${parentFocusId}_to`}
              />
            </div>
          </>
          )}
        </div>
      </td>
      <td className="flex align-center">
        <div className={get(exception, 'isRange') ? 'exception-description' : 'exception-description-full'}>
          <EditableInputCell name={null} editable={false} value={get(exception, 'procedureFromDescription')} />
          <EditableInputCell name={null} editable={false} value={get(exception, 'procedureToDescription')} />
        </div>
      </td>
      <td>
        <EditableSelectCell
          editable={isEdit}
          form={form}
          enumName={enums.SCHEDULAR_PROVIDER}
          onChange={onChange('feeSchedule')}
          value={get(exception, 'feeScheduleTypeId')}
          error={dataError.feeSchedule}
          testId="edit-exception-type"
        />
      </td>
      <td>
        <EditableSelectCell
          editable={isEdit}
          form={form}
          enumName={enums.CONTRACT_TERM_EXCEPTION_TYPE}
          onChange={onChange('typeId')}
          value={get(exception, 'typeId')}
          error={dataError.typeId}
        />
      </td>
      <td>
        <WiredModifier
          name={exceptionId}
          editable={isEdit}
          labels={labels}
          form={form}
          valuePropName={get(exception, 'modifier')}
          value={get(exception, 'modifier')}
          onSelect={onChange('modifier')}
          params={{
          // CPTCode: exception?.procedureFromCode,
            IncludeInactive: false,
            PageIndex: 0,
            PageSize: 50,
          }}
          selectProps={{
            onClear: onChange('modifier'),
          }}
          minCharLength={2}
          isParamsRequired
          className={classNames('modifier-autocomplete')}
          parentFocusId={parentFocusId}
        />
      </td>
      <td>
        {get(exception, 'typeId') === masterCodesWithId?.Included && (
        <EditableInputCell
          name={null}
          editable={isEdit}
          labels={labels}
          form={form}
          value={get(exception, 'typeValue')}
          required
          onChange={onChange('typeValue')}
          error={dataError.typeValue}
          id={`${parentFocusId}_type`}
          testid="edit-exception-typeValue-input"
        />
        )}
      </td>
      <td>
        {get(exception, 'typeId') === masterCodesWithId?.Included && (
        <EditableSelectCell
          editable={isEdit}
          form={form}
          value={get(exception, 'typeValueTypeId')}
          onChange={onChange('typeValueTypeId')}
          enumName={enums.CONTRACT_TERM_EXCEPTION_TYPE_VALUE_TYPE}
        />
        )}
      </td>
      <td>
        {onDeleteException && (
        <Icon
          name={IconNames.deleteIcon}
          className="cursor-pointer"
          onClick={onDeleteException || (() => { // This is intentional
          })}
        />
        )}
      </td>
    </>
  );
};

export default ExceptionComponent;
