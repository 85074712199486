import React, { useCallback } from 'react';
import moment from 'moment';
import find from 'lodash/find';
import { dateFormatWithSlash } from '../../../lib/constants';
import './pdfView.scss';

const showBenefitEntitiesField = (label, data) => (
  <tr style={{ fontFamily: 'Courier Prime' }}>
    <td style={{ width: '10%' }} />
    <td style={{ width: '80%', paddingLeft: '16px' }}>
      <table style={{ fontWeight: 300, width: '100%' }}>
        <tbody>
          <tr>
            <td style={{ width: '50%', paddingLeft: '6px' }}>{label}</td>
            <td style={{ width: '50%' }}>
              <table>
                <tr>
                  <td style={{ width: '8px', verticalAlign: 'top' }}>:</td>
                  <td>{data}</td>
                </tr>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </td>
    <td style={{ width: '10%' }} />
  </tr>
);

const showServiceTypeField = (label, data) => (
  <tr style={{ fontFamily: 'Courier Prime' }}>
    <td style={{ width: '10%' }} />
    <td style={{ width: '80%', paddingLeft: '12px' }}>
      <table style={{ fontWeight: 300, width: '100%' }}>
        <tbody>
          <tr>
            <td style={{ width: '50%' }}>{label}</td>
            <td style={{ width: '50%' }}>
              :
              {' '}
              {data}
            </td>
          </tr>
        </tbody>
      </table>
    </td>
    <td style={{ width: '10%' }} />
  </tr>
);
const EligibilityAndBenfitInformationForSixPage = ({
  data,
  showOnlyGivenServiceType,
}) => data?.ServiceDetails?.map((serviceItem) => {
  if (
    showOnlyGivenServiceType
      && !showOnlyGivenServiceType.includes(serviceItem?.ServiceName)
  ) {
    return '';
  }
  return (
    <>
      <tr>
        <td style={{ width: '10%' }} />
        <td style={{ width: '80%' }}>
          - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
          - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
          - - - - - - - - - - - - - - - - - - - - - - - - - -
        </td>
        <td style={{ width: '10%' }} />
      </tr>
      <tr style={{ fontFamily: 'Courier Prime' }}>
        <td style={{ width: '10%' }} />
        <td style={{ width: '80%' }}>
          Service Type :
          {' '}
          {serviceItem?.ServiceName}
        </td>
        <td style={{ width: '10%' }} />
      </tr>
      <tr>
        <td style={{ width: '10%' }} />
        <td style={{ width: '80%' }}>
          - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
          - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
          - - - - - - - - - - - - - - - - - - - - - - - - - -
        </td>
        <td style={{ width: '10%' }} />
      </tr>
      <tr>
        <td style={{ height: 12 }} />
      </tr>

      {serviceItem?.EligibilityDetails?.map((innerItem) => (
        <>
          <tr style={{ fontFamily: 'Courier Prime' }}>
            <td style={{ width: '10%' }} />
            <td style={{ width: '80%', paddingLeft: '12px' }}>
              <table style={{ fontWeight: 300, width: '100%' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '50%' }}>
                      {innerItem?.EligibilityOrBenefit}
                    </td>
                    <td style={{ width: '50%' }} />
                  </tr>
                </tbody>
              </table>
            </td>
            <td style={{ width: '10%' }} />
          </tr>
          {innerItem?.InsuranceType
              && showServiceTypeField('Policy Type', innerItem.InsuranceType)}
          {innerItem?.AuthorizationOrCertificationRequired
              && showServiceTypeField(
                'Authorization / Certification Required',
                innerItem.AuthorizationOrCertificationRequired,
              )}
          {innerItem?.Identifications?.map(
              (item) => item?.Type && showServiceTypeField(item.Type, item?.Code)
            )}
          {innerItem?.BenefitEntities?.map((item) => (
            <>
              <tr style={{ fontFamily: 'Courier Prime' }}>
                <td style={{ width: '10%' }} />
                <td style={{ width: '80%', paddingLeft: '16px' }}>
                  <table style={{ fontWeight: 300, width: '100%' }}>
                    <tbody>
                      <tr>
                        <td style={{ width: '50%', paddingLeft: '6px' }}>
                          {item?.EntityType && `[${item.EntityType}]`}
                        </td>
                        <td style={{ width: '50%' }} />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td style={{ width: '10%' }} />
              </tr>
              {item?.Firstname
                  && showBenefitEntitiesField('First Name', item.Firstname)}
              {item?.Middlename
                  && showBenefitEntitiesField('Middle Name', item.Middlename)}
              {item?.['Lastname_R']
                  && showBenefitEntitiesField('Last Name', item.Lastname_R)}
              {item?.CommunicationNumber?.length
                ? showBenefitEntitiesField(
                      item?.CommunicationNumber[0]?.Type,
                      item?.CommunicationNumber[0]?.Number,
                )
                : ''}
              {item?.Address1
                  && showBenefitEntitiesField('Address', item.Address1)}
              {item?.City && showBenefitEntitiesField('City', item.City)}
              {item?.State && showBenefitEntitiesField('State', item.State)}
              {item?.Zip && showBenefitEntitiesField('Zip', item.Zip)}
              {item?.IdentificationCode
                  && showBenefitEntitiesField(
                    item?.IdentificationType,
                    item?.IdentificationCode,
                  )}
              {item?.ProviderTaxonomy
                  && showBenefitEntitiesField(
                    'Provider Taxonomy',
                    item.ProviderTaxonomy,
                  )}
            </>
          ))}
          <tr style={{ fontFamily: 'Courier Prime' }}>
            <td style={{ width: '10%' }} />
            <td style={{ width: '80%', paddingLeft: '16px' }}>
              <table style={{ fontWeight: 300, width: '100%' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '100%' }}>
                      {innerItem?.Message?.map((msg, index) => (
                        <p key={index}>
                          ****
                          <span
                            style={{
                              display: 'inlineBlock',
                              paddingLeft: '6px',
                            }}
                          >
                            {msg}
                          </span>
                        </p>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td style={{ width: '10%' }} />
          </tr>
        </>
      ))}
    </>
  );
}) || '';

const SubscriberInformation = ({ data }) => {
  const getMemberId = useCallback(
    (value) => find(value || [], { Type: 'Member ID' })?.Code || '',
    [],
  );

  return (
    <>
      <tr>
        <td style={{ width: '10%' }} />
        <td style={{ width: '80%' }}>
          ==========================================================================================
        </td>
        <td style={{ width: '10%' }} />
      </tr>
      <tr>
        <td style={{ width: '10%' }} />
        <td style={{ width: '80%', fontFamily: 'Courier Prime' }}>
          SUBSCRIBER INFORMATION
        </td>
        <td style={{ width: '10%' }} />
      </tr>
      <tr>
        <td style={{ width: '10%' }} />
        <td style={{ width: '80%' }}>
          ==========================================================================================
        </td>
        <td style={{ width: '10%' }} />
      </tr>
      <tr style={{ fontFamily: 'Courier Prime' }}>
        <td style={{ width: '10%' }} />
        <td style={{ width: '80%' }}>
          <table style={{ fontWeight: 300, width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ width: '50%' }}>Name</td>
                <td style={{ width: '50%' }}>
                  <table>
                    <tr>
                      <td style={{ width: '8px', verticalAlign: 'top' }}>:</td>
                      <td>{data?.Subscriber?.FullName}</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        <td style={{ width: '10%' }} />
      </tr>
      <tr style={{ fontFamily: 'Courier Prime' }}>
        <td style={{ width: '10%' }} />
        <td style={{ width: '80%' }}>
          <table style={{ fontWeight: 300, width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ width: '50%' }}>Address</td>
                <td style={{ width: '50%' }}>
                  <table>
                    <tr>
                      <td style={{ width: '8px', verticalAlign: 'top' }}>:</td>
                      <td>{data?.Subscriber?.Address1}</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        <td style={{ width: '10%' }} />
      </tr>
      <tr style={{ fontFamily: 'Courier Prime' }}>
        <td style={{ width: '10%' }} />
        <td style={{ width: '80%' }}>
          <table style={{ fontWeight: 300, width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ width: '50%' }}>City-State-Zip</td>
                <td style={{ width: '50%' }}>
                  <table>
                    <tr>
                      <td style={{ width: '8px', verticalAlign: 'top' }}>:</td>
                      <td>
                        {data?.Subscriber?.Zip
                          && `${data?.Subscriber?.City || ''}-${
                            data?.Subscriber?.State || ''
                          }-${data?.Subscriber?.Zip}`}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        <td style={{ width: '10%' }} />
      </tr>
      <tr style={{ fontFamily: 'Courier Prime' }}>
        <td style={{ width: '10%' }} />
        <td style={{ width: '80%' }}>
          <table style={{ fontWeight: 300, width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ width: '50%' }}>Gender</td>
                <td style={{ width: '50%' }}>
                  <table>
                    <tr>
                      <td style={{ width: '8px', verticalAlign: 'top' }}>:</td>
                      <td>
                        {data?.Subscriber && data?.Subscriber['Gender_R']}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        <td style={{ width: '10%' }} />
      </tr>
      <tr style={{ fontFamily: 'Courier Prime' }}>
        <td style={{ width: '10%' }} />
        <td style={{ width: '80%' }}>
          <table style={{ fontWeight: 300, width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ width: '50%' }}>Date Of Birth</td>
                <td style={{ width: '50%' }}>
                  <table>
                    <tr>
                      <td style={{ width: '8px', verticalAlign: 'top' }}>:</td>
                      <td>{data?.Subscriber && data?.Subscriber['DOB_R']}</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        <td style={{ width: '10%' }} />
      </tr>
      <tr style={{ fontFamily: 'Courier Prime' }}>
        <td style={{ width: '10%' }} />
        <td style={{ width: '80%' }}>
          <table style={{ fontWeight: 300, width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ width: '50%' }}>Lastname_R</td>
                <td style={{ width: '50%' }}>
                  <table>
                    <tr>
                      <td style={{ width: '8px', verticalAlign: 'top' }}>:</td>
                      <td>
                        {data?.Subscriber && data?.Subscriber['Lastname_R']}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        <td style={{ width: '10%' }} />
      </tr>
      <tr style={{ fontFamily: 'Courier Prime' }}>
        <td style={{ width: '10%' }} />
        <td style={{ width: '80%' }}>
          <table style={{ fontWeight: 300, width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ width: '50%' }}>Firstname</td>
                <td style={{ width: '50%' }}>
                  <table>
                    <tr>
                      <td style={{ width: '8px', verticalAlign: 'top' }}>:</td>
                      <td>{data?.Subscriber?.Firstname}</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        <td style={{ width: '10%' }} />
      </tr>
      <tr style={{ fontFamily: 'Courier Prime' }}>
        <td style={{ width: '10%' }} />
        <td style={{ width: '80%' }}>
          <table style={{ fontWeight: 300, width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ width: '50%' }}>Member ID</td>
                <td style={{ width: '50%' }}>
                  <table>
                    <tr>
                      <td style={{ width: '8px', verticalAlign: 'top' }}>:</td>
                      <td>{getMemberId(data?.Subscriber?.Identification)}</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        <td style={{ width: '10%' }} />
      </tr>
      <tr>
        <td style={{ height: 12 }} />
      </tr>
    </>
  );
};

const PayerInfo = ({ data }) => (
  <>
    <tr style={{ fontFamily: 'Courier Prime' }}>
      <td style={{ width: '10%' }} />
      <td style={{ width: '80%' }}>
        <table style={{ fontWeight: 300, width: '100%' }}>
          <tbody>
            <tr>
              <td style={{ width: '50%' }}>PAYER :</td>
              <td style={{ width: '50%' }}>{data?.PayerName}</td>
            </tr>
          </tbody>
        </table>
      </td>
      <td style={{ width: '10%' }} />
    </tr>
    <tr style={{ fontFamily: 'Courier Prime' }}>
      <td style={{ width: '10%' }} />
      <td style={{ width: '80%' }}>
        <table style={{ fontWeight: 300, width: '100%' }}>
          <tbody>
            <tr>
              <td style={{ width: '50%' }}>DOS :</td>
              <td style={{ width: '50%' }}>{data?.DOS}</td>
            </tr>
          </tbody>
        </table>
      </td>
      <td style={{ width: '10%' }} />
    </tr>
    <tr style={{ fontFamily: 'Courier Prime' }}>
      <td style={{ width: '10%' }} />
      <td style={{ width: '80%' }}>
        <table style={{ fontWeight: 300, width: '100%' }}>
          <tbody>
            <tr>
              <td style={{ width: '50%' }}>VERIFICATION TYPE :</td>
              <td style={{ width: '50%' }}>{data?.VerificationType}</td>
            </tr>
          </tbody>
        </table>
      </td>
      <td style={{ width: '10%' }} />
    </tr>
    <tr>
      <td style={{ height: 12 }} />
    </tr>
  </>
);

const ErrorInfo = ({ data }) => (
  <>
    <tr>
      <td style={{ width: '10%' }} />
      <td style={{ width: '80%' }}>
        ==========================================================================================
      </td>
      <td style={{ width: '10%' }} />
    </tr>
    <tr style={{ fontFamily: 'Courier Prime' }}>
      <td style={{ width: '10%' }} />
      <td style={{ width: '80%' }}>VALIDATION ERROR FROM PAYER</td>
      <td style={{ width: '10%' }} />
    </tr>
    <tr>
      <td style={{ width: '10%' }} />
      <td style={{ width: '80%' }}>
        ==========================================================================================
      </td>
      <td style={{ width: '10%' }} />
    </tr>
    <tr style={{ fontFamily: 'Courier Prime' }}>
      <td style={{ width: '10%' }} />
      <td style={{ width: '80%' }}>
        <table style={{ fontWeight: 300, width: '100%' }}>
          <tbody>
            <tr>
              <td style={{ width: '100%' }}>
                {data?.EDIErrorMessage || data?.ErrorDescription}
              </td>
            </tr>
          </tbody>
        </table>
      </td>
      <td style={{ width: '10%' }} />
    </tr>
    <tr>
      <td style={{ width: '10%' }} />
      <td style={{ width: '80%' }}>
        - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        - - - - - - - - - - - - - - - - - - - - -
      </td>
      <td style={{ width: '10%' }} />
    </tr>
    <tr>
      <td style={{ height: 12 }} />
    </tr>
  </>
);

const ShowDataBaseOnSubCategoryName = ({
  pdfViewData,
  demographicInfo,
  twoPagePdf,
  sixPagePdf,
  insuranceStatus,
  issueReason,
}) => {
  if (issueReason?.toLowerCase() === 'mismatched demographic') {
    return <ErrorInfo data={pdfViewData} />;
  }
  if (issueReason?.toLowerCase() === 'other') {
    return <ErrorInfo data={pdfViewData} />;
  }
  if (issueReason?.toLowerCase() === 'not a supported payer') {
    return <PayerInfo data={pdfViewData} />;
  }
  if (
    twoPagePdf
    || (sixPagePdf && insuranceStatus === 'Verified with issues')
  ) {
    return (
      <>
        <PayerInfo data={pdfViewData} />
        <tr>
          <td style={{ width: '10%' }} />
          <td style={{ width: '80%' }}>
            <table style={{ fontWeight: 300, width: '100%' }}>
              <tbody>
                <tr>
                  <td style={{ width: '100%' }}>
                    {`Error Reason' : ${
                    pdfViewData?.ErrorDescription
                      ? pdfViewData?.ErrorDescription
                      : ''
                    }`}

                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td style={{ width: '10%' }} />
        </tr>
      </>
    );
  }
  return (
    <>
      <PayerInfo data={pdfViewData} />
      <SubscriberInformation data={demographicInfo} />
      <tr>
        <td style={{ width: '10%' }} />
        <td style={{ width: '80%' }}>
          ==========================================================================================
        </td>
        <td style={{ width: '10%' }} />
      </tr>
      <tr style={{ fontFamily: 'Courier Prime' }}>
        <td style={{ width: '10%' }} />
        <td style={{ width: '80%' }}>ELIGIBILITY &amp; BENEFIT INFORMATION</td>
        <td style={{ width: '10%' }} />
      </tr>
      <tr>
        <td style={{ width: '10%' }} />
        <td style={{ width: '80%' }}>
          ==========================================================================================
        </td>
        <td style={{ width: '10%' }} />
      </tr>
      <tr>
        <td style={{ height: 0 }} />
      </tr>
      <EligibilityAndBenfitInformationForSixPage
        data={pdfViewData}
        showOnlyGivenServiceType={[
          'Health Benefit Plan Coverage',
          'Misc. Info',
          'Medical Care',
        ]}
      />
    </>
  );
};

const PverifyDetailViewPdf = ({
  pdfHeaderData,
  pdfViewData,
  twoPagePdf,
  sixPagePdf,
  subCategoryName,
}) => {
  const {
    summaryRequest: { provider, dependent, subscriber } = {},
    groupNpi,
    fedTaxId,
  } = pdfHeaderData || {};
  const {
    DemographicInfo,
    HBPC_Deductible_OOP_Summary: deductible,
    SpecialistOfficeSummary,
  } = pdfViewData || {};

  return (
    <div className="pverify-pdf-table">
      <table style={{ width: '100%' }}>
        <thead>
          <tr>
            <th style={{ textAlign: 'left' }}>
              <h3
                style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                  color: '#000000',
                  margin: 0,
                  borderBottom: '1px solid #000000',
                  display: 'inline-block',
                  cursor: 'pointer',
                }}
              >
                ELIGIBILITY INQUIRY REPORT
              </h3>
              <p style={{ fontWeight: 'normal', fontSize: 14 }}>
                {pdfHeaderData?.createdOn
                  && moment(pdfHeaderData.createdOn).format(dateFormatWithSlash)}
              </p>
            </th>
            <th style={{ textAlign: 'right' }}>
              <img
                alt="logo"
                src={`${process.env.PUBLIC_URL}/images/pVerify-logo.png`}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ height: 8 }} />
          </tr>
          <tr>
            <td colSpan={2}>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        textAlign: 'center',
                        border: '1px solid #000000',
                        background: '#ccc',
                        padding: 6,
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                      }}
                    >
                      QUERY CRITERIA
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{ width: '60%' }}>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td style={{ height: '6px' }} />
                  </tr>
                  <tr>
                    <td>
                      <div style={{ display: 'table' }}>
                        <p
                          style={{
                            paddingRight: 20,
                            display: 'table-cell',
                            fontWeight: 500,
                          }}
                        >
                          PAYER :
                        </p>
                        <p style={{ display: 'table-cell' }}>
                          {pdfHeaderData?.payer?.payerName}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ height: '6px' }} />
                  </tr>
                  <tr>
                    <td>
                      <div style={{ display: 'table' }}>
                        <p
                          style={{
                            paddingRight: 20,
                            display: 'table-cell',
                            fontWeight: 500,
                          }}
                        >
                          Client Payer Name :
                        </p>
                        <p style={{ display: 'table-cell' }}>
                          {
                            pdfHeaderData?.insuranceProfileDetail
                              ?.insurancePayerName
                          }
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ height: '6px' }} />
                  </tr>
                </tbody>
              </table>
            </td>
            <td style={{ width: '40%' }}>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td style={{ height: '6px' }} />
                  </tr>
                  <tr>
                    <td>
                      <div style={{ display: 'table' }}>
                        <p
                          style={{
                            paddingRight: 20,
                            display: 'table-cell',
                            fontWeight: 500,
                          }}
                        >
                          DOS :
                        </p>
                        <p style={{ display: 'table-cell' }}>
                          {pdfHeaderData?.summaryRequest
                            && pdfHeaderData?.summaryRequest['doS_StartDate']}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ height: '6px' }} />
                  </tr>
                  <tr>
                    <td>
                      <div style={{ display: 'table' }}>
                        <p
                          style={{
                            paddingRight: 20,
                            display: 'table-cell',
                            fontWeight: 500,
                          }}
                        >
                          Batch :
                        </p>
                        <p style={{ display: 'table-cell' }}>{}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ height: '6px' }} />
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '33.3333337%' }}>
                      <table
                        style={{
                          border: '1px solid #000000',
                          borderBottom: 'none',
                          width: 110,
                        }}
                      >
                        <tbody>
                          <tr>
                            <td>
                              <div
                                style={{
                                  display: 'inline-block',
                                  width: 110,
                                  fontWeight: 500,
                                }}
                              >
                                PROVIDER
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        style={{ width: '100%', border: '1px solid #000000' }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ width: '50%' }}>
                              <table style={{ width: '100%' }}>
                                <tbody>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          paddingRight: 10,
                                          display: 'table-cell',
                                        }}
                                      >
                                        Last :
                                      </p>
                                      <p style={{ display: 'table-cell' }}>
                                        {provider?.lastName}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: '4px' }} />
                                  </tr>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          paddingRight: 10,
                                          display: 'table-cell',
                                        }}
                                      >
                                        First :
                                      </p>
                                      <p style={{ display: 'table-cell' }}>
                                        {provider?.firstName}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: '4px' }} />
                                  </tr>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          paddingRight: 10,
                                          display: 'table-cell',
                                        }}
                                      >
                                        NPI :
                                      </p>
                                      <p style={{ display: 'table-cell' }}>
                                        {provider?.npi}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: '4px' }} />
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              style={{
                                width: '50%',
                                textAlign: 'right',
                                verticalAlign: 'bottom',
                              }}
                            >
                              <table style={{ width: '100%' }}>
                                <tbody>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          paddingRight: 10,
                                          display: 'table-cell',
                                        }}
                                      >
                                        Grp NPI :
                                      </p>
                                      <p style={{ display: 'table-cell' }}>
                                        {groupNpi}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: '4px' }} />
                                  </tr>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          paddingRight: 10,
                                          display: 'table-cell',
                                        }}
                                      >
                                        Fed Tax ID :
                                      </p>
                                      <p style={{ display: 'table-cell' }}>
                                        {fedTaxId}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: '4px' }} />
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {/* </div> */}
                    </td>
                    <td style={{ width: '33.3333337%' }}>
                      <table
                        style={{
                          border: '1px solid #000000',
                          borderBottom: 'none',
                          width: 110,
                        }}
                      >
                        <tbody>
                          <tr>
                            <td>
                              <div
                                style={{
                                  display: 'inline-block',
                                  width: 110,
                                  fontWeight: 500,
                                }}
                              >
                                SUBSCRIBER
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        style={{ width: '100%', border: '1px solid #000000' }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ width: '50%' }}>
                              <table style={{ width: '100%' }}>
                                <tbody>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          paddingRight: '6px',
                                          display: 'table-cell',
                                        }}
                                      >
                                        Member ID :
                                      </p>
                                      <p style={{ display: 'table-cell' }}>
                                        {subscriber?.memberID}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: '4px' }} />
                                  </tr>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          paddingRight: 10,
                                          display: 'table-cell',
                                        }}
                                      >
                                        First :
                                      </p>
                                      <p style={{ display: 'table-cell' }}>
                                        {subscriber?.firstName}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: '4px' }} />
                                  </tr>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          paddingRight: 10,
                                          display: 'table-cell',
                                        }}
                                      >
                                        Last :
                                      </p>
                                      <p style={{ display: 'table-cell' }}>
                                        {subscriber?.lastName}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: '4px' }} />
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td style={{ width: '50%', textAlign: 'right' }}>
                              <table style={{ width: '100%' }}>
                                <tbody>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          paddingRight: 10,
                                          display: 'table-cell',
                                        }}
                                      >
                                        DOB :
                                      </p>
                                      <p style={{ display: 'table-cell' }}>
                                        {subscriber?.dob}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: '4px' }} />
                                  </tr>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          paddingRight: 10,
                                          display: 'table-cell',
                                        }}
                                      >
                                        MI :
                                      </p>
                                      <p style={{ display: 'table-cell' }}>
                                        {subscriber?.middleName}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: '4px' }} />
                                  </tr>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          paddingRight: 10,
                                          display: 'table-cell',
                                        }}
                                      >
                                        Gender :
                                      </p>
                                      <p style={{ display: 'table-cell' }}>
                                        {subscriber?.gender}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: '4px' }} />
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td style={{ width: '33.3333337%' }}>
                      <table
                        style={{
                          border: '1px solid #000000',
                          borderBottom: 'none',
                          width: 110,
                        }}
                      >
                        <tbody>
                          <tr>
                            <td>
                              <div
                                style={{
                                  display: 'inline-block',
                                  width: 110,
                                  fontWeight: 500,
                                }}
                              >
                                DEPENDENT
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        style={{ width: '100%', border: '1px solid #000000' }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ width: '50%' }}>
                              <table style={{ width: '100%' }}>
                                <tbody>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          paddingRight: 10,
                                          display: 'table-cell',
                                        }}
                                      >
                                        Relation :
                                      </p>
                                      <p style={{ display: 'table-cell' }}>
                                        {dependent?.relationWithSubscriber}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: '4px' }} />
                                  </tr>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          paddingRight: 10,
                                          display: 'table-cell',
                                        }}
                                      >
                                        First :
                                      </p>
                                      <p style={{ display: 'table-cell' }}>
                                        {dependent?.patient?.firstName}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: '4px' }} />
                                  </tr>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          paddingRight: 10,
                                          display: 'table-cell',
                                        }}
                                      >
                                        Last :
                                      </p>
                                      <p style={{ display: 'table-cell' }}>
                                        {dependent?.patient?.lastName}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: '4px' }} />
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td style={{ width: '50%', textAlign: 'right' }}>
                              <table style={{ width: '100%' }}>
                                <tbody>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          paddingRight: 10,
                                          display: 'table-cell',
                                        }}
                                      >
                                        DOB :
                                      </p>
                                      <p style={{ display: 'table-cell' }}>
                                        {dependent?.patient?.dob}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: '4px' }} />
                                  </tr>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          paddingRight: 10,
                                          display: 'table-cell',
                                        }}
                                      >
                                        MI :
                                      </p>
                                      <p style={{ display: 'table-cell' }}>
                                        {dependent?.patient?.middleName}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: '4px' }} />
                                  </tr>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          paddingRight: 10,
                                          display: 'table-cell',
                                        }}
                                      >
                                        Gender :
                                      </p>
                                      <p style={{ display: 'table-cell' }}>
                                        {dependent?.patient?.gender}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: '4px' }} />
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{ height: '6px' }} />
          </tr>
          <tr>
            <td colSpan={4}>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '33.333%' }}>
                      <div style={{ display: 'table' }}>
                        <p
                          style={{
                            paddingRight: 20,
                            display: 'table-cell',
                            fontWeight: 500,
                          }}
                        >
                          Created On :
                        </p>
                        <p style={{ display: 'table-cell' }}>
                          {pdfHeaderData?.createdOn
                            && moment(pdfHeaderData.createdOn).format(
                              dateFormatWithSlash,
                            )}
                        </p>
                      </div>
                    </td>
                    <td style={{ width: '33.333%' }}>
                      <div style={{ display: 'table' }}>
                        <p
                          style={{
                            paddingRight: 20,
                            display: 'table-cell',
                            fontWeight: 500,
                          }}
                        >
                          Created By :
                        </p>
                        <p style={{ display: 'table-cell' }}>
                          {pdfHeaderData?.createdByName}
                        </p>
                      </div>
                    </td>
                    <td style={{ width: '33.333%' }}>
                      <div style={{ display: 'table' }}>
                        <p
                          style={{
                            paddingRight: 20,
                            display: 'table-cell',
                            fontWeight: 500,
                          }}
                        >
                          Verified On :
                        </p>
                        <p style={{ display: 'table-cell' }}>
                          {pdfHeaderData?.modifiedOn
                            && moment(pdfHeaderData.modifiedOn).format(
                              dateFormatWithSlash,
                            )}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{ height: '6px' }} />
          </tr>
          <tr>
            <td colSpan={4}>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '33.333%' }}>
                      <div style={{ display: 'table' }}>
                        <p
                          style={{
                            paddingRight: 20,
                            display: 'table-cell',
                            fontWeight: 500,
                          }}
                        >
                          Verified By :
                        </p>
                        <p style={{ display: 'table-cell' }}>
                          {pdfHeaderData?.modifiedByName}
                        </p>
                      </div>
                    </td>
                    <td style={{ width: '33.333%' }}>
                      <div style={{ display: 'table' }}>
                        <p
                          style={{
                            paddingRight: 20,
                            display: 'table-cell',
                            fontWeight: 500,
                          }}
                        >
                          Internal Id :
                        </p>
                        <p style={{ display: 'table-cell' }}>
                          {pdfHeaderData?.summaryRequest?.internalId}
                        </p>
                      </div>
                    </td>
                    <td style={{ width: '33.333%' }}>
                      <div style={{ display: 'table' }}>
                        <p
                          style={{
                            paddingRight: 20,
                            display: 'table-cell',
                            fontWeight: 500,
                          }}
                        >
                          Customer Id :
                        </p>
                        <p style={{ display: 'table-cell' }}>
                          {pdfHeaderData?.summaryRequest?.customerId}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{ height: '6px' }} />
          </tr>
          <tr>
            <td colSpan={2}>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        textAlign: 'center',
                        border: '1px solid #000000',
                        background: '#ccc',
                        padding: 6,
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                      }}
                    >
                      ELIGIBILITY RESULT
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{ height: '6px' }} />
          </tr>
          <tr>
            <td colSpan={4}>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '25%' }}>
                      <div style={{ display: 'table' }}>
                        <p
                          style={{
                            paddingRight: 20,
                            display: 'table-cell',
                            fontWeight: 500,
                          }}
                        >
                          Status :
                        </p>
                        <p
                          style={{
                            display: 'table-cell',
                            color:
                              pdfViewData?.PlanCoverageSummary?.Status
                              === 'Active'
                                ? 'green'
                                : 'red',
                            fontWeight: 500,
                          }}
                        >
                          {pdfViewData?.PlanCoverageSummary?.Status}
                        </p>
                      </div>
                    </td>
                    <td style={{ width: '25%' }}>
                      <div style={{ display: 'table' }}>
                        <p
                          style={{
                            paddingRight: 20,
                            display: 'table-cell',
                            fontWeight: 500,
                          }}
                        >
                          Eff .From :
                        </p>
                        <p style={{ display: 'table-cell' }}>
                          {pdfViewData?.PlanCoverageSummary?.EffectiveDate}
                        </p>
                      </div>
                    </td>
                    <td style={{ width: '25%' }}>
                      <div style={{ display: 'table' }}>
                        <p
                          style={{
                            paddingRight: 20,
                            display: 'table-cell',
                            fontWeight: 500,
                          }}
                        >
                          Exp.On :
                        </p>
                        <p style={{ display: 'table-cell' }}>
                          {pdfViewData?.PlanCoverageSummary?.ExpiryDate}
                        </p>
                      </div>
                    </td>
                    <td style={{ width: '25%' }} />
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{ height: '14px' }} />
          </tr>
          <tr>
            <td colSpan={4}>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '25%' }}>
                      <div style={{ display: 'table' }}>
                        <p
                          style={{
                            paddingRight: 20,
                            display: 'table-cell',
                            fontWeight: 500,
                          }}
                        >
                          Plan :
                        </p>
                        <p style={{ display: 'table-cell' }}>
                          {pdfViewData?.PlanCoverageSummary?.PlanName}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{ height: '6px' }} />
          </tr>
          <tr>
            <td colSpan={4}>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '25%' }}>
                      <div style={{ display: 'table' }}>
                        <p
                          style={{
                            paddingRight: 20,
                            display: 'table-cell',
                            fontWeight: 500,
                          }}
                        >
                          Additional Info :
                        </p>
                        <p style={{ display: 'table-cell' }}>
                          {pdfViewData?.AddtionalInfo}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{ height: '12px' }} />
          </tr>
          <tr>
            <td colSpan={4}>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td className="width-25-print">
                      <div style={{ display: 'table' }}>
                        <p
                          style={{
                            paddingRight: 20,
                            display: 'table-cell',
                            fontWeight: 500,
                          }}
                        >
                          Exception Notes :
                        </p>
                        <p style={{ display: 'table-cell' }}>
                          {pdfViewData?.ExceptionNotes}
                        </p>
                      </div>
                    </td>
                    <td className="width-25-print">
                      <div style={{ display: 'table' }}>
                        <p
                          style={{
                            paddingRight: 20,
                            display: 'table-cell',
                            fontWeight: 500,
                          }}
                        >
                          Patient Last Name :
                        </p>
                        <p style={{ display: 'table-cell' }}>
                          {DemographicInfo?.Subscriber
                            && DemographicInfo?.Subscriber['Lastname_R']}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{ height: 12 }} />
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td colSpan={5}>
              <table className="prime-table" style={{ textAlign: 'center' }}>
                <tbody>
                  <tr>
                    <td colSpan="" />
                    <td colSpan={2} style={{ fontWeight: 500 }}>
                      Primary Care
                    </td>
                    <td colSpan={2} style={{ fontWeight: 500 }}>
                      Specialist
                    </td>
                    <td colSpan={3} style={{ fontWeight: 500 }}>
                      Deductible
                    </td>
                    <td colSpan={2} style={{ fontWeight: 500 }}>
                      Out-of-pocket
                    </td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Co-Pay</td>
                    <td>Co-Ins</td>
                    <td>Co-Pay</td>
                    <td>Co-Ins</td>
                    <td>Annual</td>
                    <td>Met</td>
                    <td>Remaining</td>
                    <td>Maximum</td>
                    <td>Remaining</td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p style={{ margin: '6px 0', height: '14px' }}>
                          In-Network-
                        </p>
                        <p style={{ margin: '6px 0', height: '14px' }}>
                          {' '}
                          Out-Network-
                        </p>
                      </div>
                    </td>
                    <td />
                    <td />
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p style={{ margin: '6px 0', height: '14px' }}>
                          {SpecialistOfficeSummary?.CoPayInNet?.Value}
                        </p>
                        <p style={{ margin: '6px 0', height: '14px' }}>
                          {SpecialistOfficeSummary?.CoPayOutNet?.Value}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p style={{ margin: '6px 0', height: '14px' }}>
                          {SpecialistOfficeSummary?.CoInsInNet?.Value}
                        </p>
                        <p style={{ margin: '6px 0', height: '14px' }}>
                          {SpecialistOfficeSummary?.CoInsOutNet?.Value}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p style={{ margin: '6px 0', height: '14px' }}>
                          {deductible?.IndividualDeductibleInNet?.Value}
                        </p>
                        <p style={{ margin: '6px 0', height: '14px' }}>
                          {deductible?.IndividualDeductibleOutNet?.Value}
                        </p>
                      </div>
                    </td>
                    <td />
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p style={{ margin: '6px 0', height: '14px' }}>
                          {
                            deductible?.IndividualDeductibleRemainingInNet
                              ?.Value
                          }
                        </p>
                        <p style={{ margin: '6px 0', height: '14px' }}>
                          {
                            deductible?.IndividualDeductibleRemainingOutNet
                              ?.Value
                          }
                        </p>
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p style={{ margin: '6px 0', height: '14px' }}>
                          {deductible?.['IndividualOOP_InNet']?.Value}
                        </p>
                        <p style={{ margin: '6px 0', height: '14px' }}>
                          {deductible?.['IndividualOOP_OutNet']?.Value}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p style={{ margin: '6px 0', height: '14px' }}>
                          {deductible?.IndividualOOPRemainingInNet?.Value}
                        </p>
                        <p style={{ margin: '6px 0', height: '14px' }}>
                          {deductible?.IndividualOOPRemainingOutNet?.Value}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{ height: 20 }} />
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td colSpan={5}>
              <table style={{ width: '100%' }}>
                <tbody>
                  <ShowDataBaseOnSubCategoryName
                    pdfViewData={pdfViewData}
                    demographicInfo={DemographicInfo}
                    subCategoryName={subCategoryName}
                    twoPagePdf={twoPagePdf}
                    sixPagePdf={sixPagePdf}
                    issueReason={pdfHeaderData?.issueReason}
                    insuranceStatus={pdfHeaderData?.insuranceStatus}
                  />
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td colSpan={5}>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '10%', height: 12 }} />
                    <td style={{ width: '90%', height: 12 }} />
                  </tr>
                  <tr>
                    <td style={{ width: '10%' }} />
                    <td style={{ width: '90%', fontWeight: 'bold' }}>
                      Declaration
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: '10%', height: 8 }} />
                    <td style={{ width: '90%', height: 8 }} />
                  </tr>
                  <tr>
                    <td style={{ width: '10%' }} />
                    <td
                      style={{
                        width: '90%',
                        paddingLeft: 8,
                        letterSpacing: '1.6px',
                        fontWeight: 300,
                      }}
                    >
                      I
                      {' '}
                      <span
                        style={{
                          display: 'inline-block',
                          paddingRight: 5,
                          letterSpacing: 1,
                        }}
                      >
                        {`${pdfHeaderData?.patient?.firstName || ''} ${
                          pdfHeaderData?.patient?.lastName || ''
                        }`}
                      </span>
                      have read and understood the benefits that have been
                      explained to me.
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: '10%' }} />
                    <td style={{ width: '90%' }}>
                      <table>
                        <tr>
                          <td style={{ width: '60%' }} />
                          <td style={{ width: '40%' }}>
                            <div>
                              <div className="signature">
                                <p>
                                  <input type="text" />
                                </p>
                                <p>Signature</p>
                                <p />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: '10%', height: 12 }} />
                    <td style={{ width: '90%', height: 12 }} />
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PverifyDetailViewPdf;
