import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form as AntdForm } from 'antd';
import { useSelector } from 'react-redux';
import isFunction from 'lodash/isFunction';

import Notification from '../../../../../components/Notification';
import Input from '../../../../../components/Form/Input';
import CheckBox from '../../../../../components/Form/CheckBox';
import Form from '../../../../../components/Form';
import { formId } from '../../../../../lib/constants';
import SuccessMessages from '../../../../../lib/successMessages';
import Events from '../../../../../lib/events';

import { apiUrls } from '../../../../../api/constants';

import Button from '../../../../../components/Button';

import { getCrudData } from '../../../../../store/selectors';

const AddPlanType = (props) => {
  const {
    labels, toggleModal, isEditPlanType, policyFormInstance,
  } = props;
  const [form] = AntdForm.useForm();
  const planTypeList = useSelector((state) => getCrudData(state, 'plan-type-list'));
  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      form.resetFields();
      if (isFunction(toggleModal)) {
        toggleModal();
      }
      Notification({ message: SuccessMessages.PLAN_TYPE_SAVED_SUCCESSFULLY, success: true });
      Events.trigger('plan-type-list');
    }
  }, [form, toggleModal]);

  useEffect(() => {
    const planTypeId = policyFormInstance.getFieldValue('planTypeId');
    if (planTypeId && isEditPlanType) {
      const selectedPlanType = planTypeList?.find((item) => item?.id === planTypeId);
      form.setFieldsValue({
        plan: selectedPlanType?.plan,
        isActive: selectedPlanType?.isActive,
      });
    }
  }, [planTypeList, policyFormInstance]);

  return (
    <>
      <Form
        section
        form={form}
        listId="PLANTYPE"
        formId={formId.ADD_UPDATE_PLAN_TYPE_FORM}
        url={apiUrls.ADD_UPDATE_PLAN_TYPE}
        onRequestComplete={onRequestComplete}
        extraData={{
          id: isEditPlanType ? policyFormInstance.getFieldValue('planTypeId') : 0,
        }}
      >
        <div className="pannel back-pannel">
          <div className="pannel-body">
            <Form.Section noOfColumns={2}>
              <Form.Column>
                <Input
                  label={labels.get('labels.plan')}
                  id="1"
                  name="plan"
                  required
                />
                <CheckBox
                  label={labels.get('labels.active')}
                  name="isActive"
                  labelSpan="10"
                  inputSpan="14"
                  valuePropName="checked"
                />
              </Form.Column>
            </Form.Section>
          </div>
        </div>
      </Form>
      <div className="ant-modal-footer ">
        <div className="group-btns">
          <Button id="provider_credentials_cancel" className="btn min-wt-86" onClick={toggleModal}>{labels.get('buttons.cancel')}</Button>
          <Button id="provider_credentials_save" className="btn btn-success min-wt-86" onClick={() => form.submit()}>{labels.get('buttons.save')}</Button>
        </div>
      </div>
    </>
  );
};

AddPlanType.defaultProps = {
  toggleModal: () => { /* This is intentional */ },
  labels: {},
  planTypeDetails: {},
  setPlanTypeDetails: () => { /* This is intentional */ },
  isEditPlanType: false,
};

AddPlanType.propTypes = {
  toggleModal: PropTypes.func,
  labels: PropTypes.objectOf(PropTypes.object),
  planTypeDetails: PropTypes.objectOf(PropTypes.object),
  setPlanTypeDetails: PropTypes.func,
  isEditPlanType: PropTypes.bool,
};

export default AddPlanType;
