import React from 'react';
import { Modal } from 'antd';
import Icon from '../Icon';

const { confirm: ConfirmModal } = Modal;

export default function ConfirmDialog({
  onOk,
  okText,
  title,
  content,
  icon,
  ...otherProps
}) {
  return () => ConfirmModal({
    onOk,
    okText,
    title,
    content,
    zIndex: 1001,
    icon: icon || <Icon name="ExclamationCircleOutlined" />,
    ...otherProps,
  });
}
