import React, { useCallback, useState } from 'react';
import { Form as AntdForm } from 'antd';
import moment from 'moment';

import get from 'lodash/get';
import Input from '../../../../../../components/Form/Input';
import DatePicker from '../../../../../../components/Form/DatePicker';
import Modals from '../../../../../../components/Modal';
import Button from '../../../../../../components/Button';
import Form from '../../../../../../components/Form';
import Notification from '../../../../../../components/Notification';
import Select from '../../../../../../wiredComponents/Enum/Select';
import WiredSelect from '../../../../../../wiredComponents/Select/selectBoxV2';

import ConfirmDialog from '../../../../../../components/ConfirmDialog';
import Icon from '../../../../../../components/Icon';

import SuccessMessages from '../../../../../../lib/successMessages';
import {
  enums as enumConstant, labelPaths, formId, UiRoutes,
} from '../../../../../../lib/constants';
import WithLabel from '../../../../../../hoc/withLabel';
import { apiUrls } from '../../../../../../api/constants';
import useRedirect from '../../../../../../hooks/useRedirect';

import Events from '../../../../../../lib/events';
import { getDateWithZeroTimeFormat } from '../../../../../../lib/util';
import SelectBox from '../../../../../../components/Form/SelectBox';

const parser = (formValues) => ({
  ...formValues,
  effectiveDate: getDateWithZeroTimeFormat(formValues.effectiveDate),
});

const PatientSimpleCharge = ({
  visible, toggleModal, labels, isReplaceable = true,
}) => {
  const [form] = AntdForm.useForm();
  const {
    params: { id: patientId }, params, generatePath, replace,
  } = useRedirect();
  const [chargeOptions, setChargeOptions] = useState({});
  const [selectedProvider, setSelectedProvider] = useState();
  const [selectedLocation, setSelectedLocation] = useState();

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.PAYMENT_ADDED_SUCCESSFULLY, success: true });
      toggleModal();
      Events.trigger('refetch-balance-sheet');
      Events.trigger('refetch-charge-table');
      if (isReplaceable) {
        replace(generatePath(UiRoutes.viewFinancialDetailsWithId,
          { ...params, financialId: response }));
      }
    }
  }, [generatePath, isReplaceable, params, replace, toggleModal]);

  const handleSubmit = useCallback(() => {
    ConfirmDialog({
      onOk: (close) => {
        form.submit();
        close();
      },
      title: 'Warning',
      okButtonProps: {
        'data-testid': 'yes',
      },
      okText: 'Yes',
      cancelText: 'No',
      content: 'Please check one more time if all the information you entered is correct?',
      icon: <Icon name="ExclamationCircleOutlined" />,
    })();
  }, [form]);

  const handleSimpleCharge = useCallback((id, data) => {
    const optionalValues = get(data, 'item.optionalValues');
    if (optionalValues) {
      const parsedValues = JSON.parse(optionalValues);
      setChargeOptions(parsedValues);
      form.setFields([
        { name: 'unit', value: parsedValues?.units },
        { name: 'amount', value: parsedValues?.amount }]);
    } else {
      setChargeOptions({});
      form.setFields([
        { name: 'unit', value: null },
        { name: 'amount', value: null }]);
    }
  }, [form]);

  const handleUnitChange = useCallback(({ target: { value } }) => {
    if (chargeOptions?.units && chargeOptions?.amount && value) {
      const calculatedAmount = parseFloat(parseInt(value, 10) * parseFloat(chargeOptions?.amount
        / chargeOptions?.units)).toFixed(2);
      form.setFieldsValue({ amount: calculatedAmount });
    }
  }, [chargeOptions, form]);

  const onSelectProvider = useCallback((providerId) => setSelectedProvider(providerId), []);
  const onSelectLocation = useCallback((locationId) => setSelectedLocation(locationId), []);

  return (
    <Modals
      visible={!!visible}
      toggleModal={toggleModal}
      destroyOnClose
      width="800px"
      footer={[
        <div className="group-btns" key="footer">
          <Button
            id="patients_financial_patientSimple.cancel"
            lassName="btn min-wt-86"
            data-testid="cancel"
            onClick={() => toggleModal(false)}
          >
            {labels.get('buttons.cancel')}
          </Button>
          <Button
            id="patients_financial_patientSimple.save"
            className="btn btn-success min-wt-86 inline"
            onClick={handleSubmit}
            data-testid="save"
          >
            {labels.get('buttons.save')}
          </Button>
        </div>,
      ]}
    >
      <Form
        section
        form={form}
        formId={formId.ADD_SIMPLE_CHARGE}
        url={apiUrls.ADD_PATIENT_SIMPLE_CHARGE}
        onRequestComplete={onRequestComplete}
        parser={parser}
        extraData={{ patientId }}
      >
        <div className="addition-header">
          <div className="lookup sprite-img-before">
            <p>{labels.get('titles.patientSimpleCharge')}</p>
          </div>
        </div>
        <div className="modal-content-weapper shadow-wrap">
          <div className="modal-from-feilds">
            <Form.Section noOfColumns="2">
              <Form.Column>
                <DatePicker
                  label={labels.get('labels.effectiveDate')}
                  required
                  id="19"
                  name="effectiveDate"
                  labelSpan="7"
                  inputSpan="16"
                  disabledDate={(current) => current >= moment().add(150, 'years')}
                  initialValue={moment()}
                  datePickerProps={{ getPopupContainer: (trigger) => trigger?.parentNode }}
                  dataTestId="effectiveDate"
                />
                <Select
                  enumName={enumConstant.SIMPLE_CHARGE_TYPE_STATUS}
                  label={labels.get('labels.chargeType')}
                  id="1"
                  required
                  name="TransactionTypeId"
                  labelSpan="7"
                  inputSpan="16"
                  onChange={handleSimpleCharge}
                  selectProps={{ getPopupContainer: (trigger) => trigger?.parentNode }}
                  dataTestId="charge-type"
                />
                <Input
                  label={labels.get('labels.units')}
                  id="1"
                  required
                  name="unit"
                  labelSpan="7"
                  inputSpan="16"
                  numberOnly
                  maxValueLength={4}
                  onChange={handleUnitChange}
                  dataTestId="units"
                  placeholder="Enter Unit"
                />
              </Form.Column>
              <Form.Column>
                <WiredSelect
                  id="provider"
                  name="providerId"
                  label={labels.get('labels.provider')}
                  placeholder="Select Provider"
                  url={apiUrls.SCHEDULAR_PROVIDER_DROPDOWN}
                  nameAccessor="providerName"
                  valueAccessor="providerId"
                  Component={SelectBox}
                  labelSpan="7"
                  inputSpan="16"
                  startCaseOptions
                  selectProps={{
                    style: { width: '100%' },
                    showSearch: true,
                    getPopupContainer: (trigger) => trigger?.parentNode,
                  }}
                  defaultSorting={false}
                  onChange={onSelectProvider}
                  params={{
                    LocationId: selectedLocation,
                  }}
                  required
                  dataTestId="provider"
                />
                <WiredSelect
                  id="location"
                  name="locationId"
                  label={labels.get('labels.location')}
                  url={apiUrls.SCHEDULAR_LOCATION_DROPDOWN}
                  placeholder="Select Location"
                  nameAccessor="locationName"
                  valueAccessor="locationId"
                  Component={SelectBox}
                  startCaseOptions
                  labelSpan="7"
                  inputSpan="16"
                  selectProps={{
                    style: { width: '100%' },
                    showSearch: true,
                    getPopupContainer: (trigger) => trigger?.parentNode,
                  }}
                  params={{
                    ProviderId: selectedProvider,
                  }}
                  onChange={onSelectLocation}
                  required
                  dataTestId="location"
                />
                <Input
                  label={labels.get('labels.amount')}
                  id="1"
                  required
                  name="amount"
                  labelSpan="7"
                  inputSpan="16"
                  numberWithDecimal
                  maxValueLength={11}
                  dataTestId="amount"
                  placeholder="Enter Amount"
                />
              </Form.Column>
            </Form.Section>
          </div>
        </div>
      </Form>
    </Modals>
  );
};

export default
React.memo(WithLabel(PatientSimpleCharge, labelPaths.FINANCIAL_POPUP_PATIENT_SIMPLE_CHARGE));
