import React, { useMemo } from 'react';

import withQuery from '../../../../../../../../hoc/withQuery/withQuery';

import ExceptionsTable from './ExceptionTable';
import { listIds } from '../../../../../../../../lib/constants';
import { apiUrls } from '../../../../../../../../api/constants';

const Exception = ({
  labels,
  schedule,
  clearSchedule,
  providerId,
  feeExceptionScheduleId,
  searchText,
  setSchedule,
  newSchedule,
  onCancelNewSchedule,
  onScheduleAdded,
  onUpdateSchedule,
  onCancelEditSchedule,
  isProviderViewOnly,
}) => {
  const CustomExceptionTable = useMemo(() => {
    if (feeExceptionScheduleId) {
      return withQuery({
        url: `${apiUrls.PROVIDER_FEE_PREFERENCES_EXCEPTION}/${feeExceptionScheduleId}`,
        listId: `${listIds.PROVIDER_FEE_PREFERENCES_EXCEPTION}_${feeExceptionScheduleId}`,
        accessor: (data) => ({ result: data.exceptions }),
      })();
    }
    return ({ children }) => (children({}));
  }, [feeExceptionScheduleId]);

  const searchContent = useMemo(() => {
    if (feeExceptionScheduleId && searchText?.length >= 3) return searchText;
    return null;
  }, [feeExceptionScheduleId, searchText]);

  return (
    <CustomExceptionTable filters={{ searchText: searchContent }}>
      {({
        data, onSort, initialSort, totalCount, reFetch, loading,
      }) => (
        <ExceptionsTable
          labels={labels}
          data={data}
          onSort={onSort}
          initialSort={initialSort}
          totalCount={totalCount}
          schedule={schedule}
          clearSchedule={clearSchedule}
          reFetch={reFetch}
          providerId={providerId}
          feeExceptionScheduleId={feeExceptionScheduleId}
          setSchedule={setSchedule}
          newSchedule={newSchedule}
          onScheduleAdded={onScheduleAdded}
          onCancelNewSchedule={onCancelNewSchedule}
          onUpdateSchedule={onUpdateSchedule}
          onCancelEditSchedule={onCancelEditSchedule}
          tableLoading={loading}
          isProviderViewOnly={isProviderViewOnly}
        />
      )}
    </CustomExceptionTable>

  );
};

export default Exception;
