import React from 'react';
import AP from '../../../../../../Doctor/Components/PatientDetails/Components/Tabs/AP';

const ProcedureDx = () => (
  <div>
    <AP />
  </div>
);

export default ProcedureDx;
