import React, { useCallback } from 'react';
import range from 'lodash/range';
import { Form as AntdForm } from 'antd';
import moment from 'moment';

import Form from '../../../../components/Form';
import TimePicker from '../../../../components/TimePicker';
import Notification from '../../../../components/Notification';
import WidgetLoader from '../../../../components/WidgetLoader';
import WithLabel from '../../../../hoc/withLabel';
import { labelPaths } from '../../../../lib/constants';
import { compareStartTimeGreaterOrEqualToEndTime } from '../../../../lib/util';
import ErrorMessages from '../../../../lib/errorMessages';

const AnesthesiaBilling = ({
  labels, anesthesia, setAnesthesia, handleAnesthesiaAutoSave, encounterId, loading, isDisabled,
}) => {
  const [form] = AntdForm.useForm();

  const handleAnesthesiaStartTimeChange = useCallback((selectedTime) => {
    if (!selectedTime || (!Array.isArray(selectedTime) && selectedTime?.isValid() && (!anesthesia?.anesthesiaStartTime || moment(selectedTime).format('hh mm') !== moment(anesthesia?.anesthesiaStartTime).format('hh mm')))) {
      if (selectedTime && anesthesia?.anesthesiaEndTime
        && compareStartTimeGreaterOrEqualToEndTime(selectedTime, anesthesia?.anesthesiaEndTime)) {
        Notification({
          message: ErrorMessages.ANESTHESIA_GREATER_OR_EQUAL_MESSAGE,
          success: false,
        });
      } else {
        setAnesthesia({ ...anesthesia, anesthesiaStartTime: selectedTime });
        if (selectedTime && anesthesia?.anesthesiaEndTime && encounterId) {
          handleAnesthesiaAutoSave({ anesthesiaStartTime: selectedTime });
        }
      }
    }
  }, [anesthesia, encounterId, handleAnesthesiaAutoSave, setAnesthesia]);

  const handleAnesthesiaEndTimeChange = useCallback((selectedTime) => {
    if (!selectedTime || (!Array.isArray(selectedTime) && selectedTime?.isValid() && (!anesthesia?.anesthesiaEndTime || moment(selectedTime).format('hh mm') !== moment(anesthesia?.anesthesiaEndTime).format('hh mm')))) {
      if (selectedTime
        && compareStartTimeGreaterOrEqualToEndTime(anesthesia?.anesthesiaStartTime, selectedTime)) {
        Notification({ message: ErrorMessages.ANESTHESIA_LESS_OR_EQUAL_MESSAGE, success: false });
      } else {
        setAnesthesia({ ...anesthesia, anesthesiaEndTime: selectedTime });
        if (selectedTime && anesthesia?.anesthesiaStartTime && encounterId) {
          handleAnesthesiaAutoSave({ anesthesiaEndTime: selectedTime });
        }
      }
    }
  }, [anesthesia, encounterId, handleAnesthesiaAutoSave, setAnesthesia]);

  const handleDisabledHoursForStartTime = useCallback(() => {
    if (anesthesia?.anesthesiaEndTime) {
      return [...range(anesthesia.anesthesiaEndTime.get('hours') + 1, 24)];
    }
    return [];
  }, [anesthesia]);
  const handleDisabledMinutesForStartTime = useCallback((minute) => {
    if (anesthesia?.anesthesiaEndTime && anesthesia?.anesthesiaEndTime.get('hours') === minute) {
      return range(anesthesia?.anesthesiaEndTime.get('minutes'), 60, 10);
    }
    return [];
  }, [anesthesia]);

  const handleDisabledHours = useCallback(() => {
    if (!anesthesia?.anesthesiaStartTime) {
      return [...range(0, 24)];
    }
    return [...range(0, anesthesia?.anesthesiaStartTime?.get('hours'))];
  }, [anesthesia]);

  const handleDisabledMinutes = useCallback((minute) => {
    if (!anesthesia?.anesthesiaStartTime || minute < 0) {
      return range(0, 60, 10);
    }
    if (anesthesia?.anesthesiaStartTime && (minute || minute === 0) && anesthesia.anesthesiaStartTime.get('hours') === minute) {
      return range(0, anesthesia.anesthesiaStartTime.get('minutes') + 10, 10);
    }
    return [];
  }, [anesthesia]);

  return (
    <div className="form-container">
      {loading && <WidgetLoader />}
      <Form
        form={form}
      >
        <Form.Column>
          <TimePicker
            label={labels.get('labels.anesthesiaStartTime')}
            form={form}
            labelSpan="4"
            inputSpan="6"
            name="anesthesiaStartTime"
            format="HH:mm"
            minuteStep={10}
            showNow={false}
            timePickerProps={{ value: anesthesia?.anesthesiaStartTime, disabled: isDisabled }}
            valuePropName="anesthesiaStartTime"
            onChange={handleAnesthesiaStartTimeChange}
            disabledHours={handleDisabledHoursForStartTime}
            disabledMinutes={handleDisabledMinutesForStartTime}
            resetTimeOnCancel
          />
          <TimePicker
            label={labels.get('labels.anesthesiaEndTime')}
            form={form}
            labelSpan="4"
            inputSpan="6"
            name="anesthesiaEndTime"
            format="HH:mm"
            minuteStep={10}
            showNow={false}
            valuePropName="anesthesiaEndTime"
            timePickerProps={{ value: anesthesia?.anesthesiaEndTime, disabled: isDisabled }}
            onChange={handleAnesthesiaEndTimeChange}
            disabledHours={handleDisabledHours}
            disabledMinutes={handleDisabledMinutes}
            resetTimeOnCancel
          />
        </Form.Column>
      </Form>
    </div>
  );
};

export default WithLabel(AnesthesiaBilling, labelPaths.ENCOUNTERS_ANESTHESIA);
