import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import useCRUD from '../../../../hooks/useCRUD';
import useRedirect from '../../../../hooks/useRedirect';

import { enums, UiRoutes } from '../../../../lib/constants';
import { getEnumMaster } from '../../../../store/selectors';
import { apiUrls } from '../../../../api/constants';

import Modals from '../../../../components/Modal';
import CustomTag from '../../../../components/Tag';
import Button from '../../../../components/Button';

import '../composer.scss';

const NewTemplateModal = ({
  toggleModal, isVisible, labels,
}) => {
  const { push, generatePath, params } = useRedirect();

  const [
    templateTypes,,,
    getTemplateTypes,
  ] = useCRUD({
    id: 'templates-type',
    url: apiUrls.GET_ENUM_FIELDS,
    type: 'read',
  });

  const enumMaster = useSelector((state) => getEnumMaster(state));
  const enumId = get(enumMaster, `${enums.COMPOSER_TEMPLATE_TYPES}.enumId`);

  const openTemplate = useCallback((templateType, templateTypeId) => () => {
    push(generatePath(UiRoutes.newComposerTemplate, { ...params, templateType, templateTypeId }));
  }, [generatePath, params, push]);

  useEffect(() => {
    if (enumId) {
      getTemplateTypes({ EnumId: enumId });
    }
  }, [enumId]);

  return (
    <Modals
      visible={isVisible}
      toggleModal={toggleModal}
      width="480px"
      className="composer-design-header"
      footer={[
        <Button
          key="footer"
          className="btn min-wt-86"
          onClick={toggleModal}
        >
          {labels.get('buttons.cancel')}
        </Button>]}
      title={labels.get('titles.newTemplate')}
    >
      <span className="settings-title-composer">Please choose a template you would like to use:</span>
      <div className="template-tags settings-composer-button">
        {templateTypes?.map((name, index) => (
          <CustomTag
            key={index + 1}
            label={name?.masterCode}
            onClick={openTemplate(name?.masterCode, name?.masterId)}
            tagClassName="cursor-pointer"
          />
        ))}
      </div>
    </Modals>
  );
};

export default NewTemplateModal;
