import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { downloadFileFromURL } from '../../lib/downloadFile';
import Notification from '../../components/Notification';
import { superBillActions } from '../../store/actions/superBillActions';

function SuperBillDownloader() {
  const dispatch = useDispatch();
  const superBillTasks = useSelector((state) => state.superBill.tasks);

  useEffect(() => {
    // Retrieve tasks from local storage
    const storedTasks = JSON.parse(localStorage.getDecryptedData('superBillTasks')) || [];

    // Update Redux store
    storedTasks.forEach((task) => {
      dispatch(superBillActions.addSuperBillTask(task));
    });
  }, []);

  useEffect(() => {
    // Start polling for each task that is 'In Progress' or 'Error' and not currently being polled
    superBillTasks.forEach(({ taskId, status, polling }) => {
      if ((status === 'In Progress') && !polling) {
        dispatch(superBillActions.pollSuperBillStatus(taskId));
      }
    });

    // Handle download and notification
    superBillTasks.forEach(async ({
      status, downloadUrl, taskId, query, downloading,
    }) => {
      if (status === 'Completed' && downloadUrl && !downloading) {
        let fromDate = query?.fromdate.split('-');
        let toDate = query?.todate.split('-');

        fromDate = `${fromDate[2]}${fromDate[1]}${fromDate[0]}`;
        toDate = `${toDate[2]}${toDate[1]}${toDate[0]}`;

        try {
          dispatch(superBillActions.setSuperBillDownloading(taskId, true));
          await downloadFileFromURL(downloadUrl, `SuperBill-(${fromDate}-${toDate}).pdf`);
          Notification({ message: 'Superbill downloaded successfully', success: true });
          dispatch(superBillActions.setSuperBillStatus('Downloaded', taskId));
        } catch (error) {
          console.error('File download error:', error);
        }
      } else if (status === 'Error' && downloadUrl === null) {
        Notification({ message: 'Superbill was not downloaded', success: false });
      }
    });
  }, [superBillTasks]);

  return null;
}

export default SuperBillDownloader;
