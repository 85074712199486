/* eslint-disable no-unused-expressions */
import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import Label from '../../../../../components/Label';
import Form from '../../../../../components/Form';
import { getString } from '../../../../../lib/util';

import './encounterDetails.scss';

const Details = ({ data = {} }) => {
  const diagnosisCodes = useMemo(() => {
    const arr = [];
    data?.serviceLineItems?.map(({ billingEncounterDiagnosisCodes }) => {
      if (!isEmpty(billingEncounterDiagnosisCodes)) {
        billingEncounterDiagnosisCodes?.forEach(({ icdCode }) => {
          arr.push(icdCode);
        });
      }
    });
    return uniqBy(arr).join(', ');
  }, [data]);

  return (
    <Form>
      <Form.Section noOfColumns={3}>
        <Form.Column>
          <div className="group-shadow view-mode-field">
            <div className="posi-relative">
              <Label label="Provider" />
              <div className="name-view">
                {getString([
                  data.providerFirstName,
                  data.providerMiddleName,
                  data.providerLastName,
                ])}
              </div>
            </div>
            <div className="posi-relative">
              <Label label="Case" />
              <div className="name-view">{data.caseDescription}</div>
            </div>
            <div className="posi-relative">
              <Label label="Attending Provider" />
              <div className="name-view">
                {getString([
                  data.attendingProviderFirstName,
                  data.attendingProviderMiddleName,
                  data.attendingProviderLastName,
                ])}
              </div>
            </div>
            <div className="posi-relative">
              <Label label="Diagnosis Code" />
              <div className="name-view">{diagnosisCodes}</div>
            </div>
            <div className="posi-relative">
              <Label label="Location" />
              <div className="name-view">{data.locationName}</div>
            </div>
          </div>
        </Form.Column>
        <Form.Column>
          <div className="group-shadow view-mode-field">
            <div className="posi-relative">
              <Label label="Status" />
              <div className="name-view">{data.status}</div>
            </div>
            <div className="posi-relative">
              <Label label="Refering Physician" />
              <div className="name-view">{data.referringPhysicianName}</div>
            </div>
            <div className="posi-relative">
              <Label label="Origin" />
              <div className="name-view">{data.encounterOriginDescription}</div>
            </div>
            <div className="posi-relative">
              <Label label="Authorization" />
              <div className="name-view">{data.authorizationNumber}</div>
            </div>
            <div className="posi-relative">
              <Label label="Amount" />
              <div className="name-view">{parseFloat(data?.totalAmount || 0).toFixed(2)}</div>
            </div>
          </div>
        </Form.Column>
        <Form.Column>
          <div className="group-shadow view-mode-field">
            <div className="posi-relative">
              <Label label="Insurance Profile" />
              <div className="name-view">{data.insuranceProfileTypeName}</div>
            </div>
            <div className="posi-relative">
              <Label label="Value" />
              <div className="name-view">{parseFloat(data?.totalValue || 0).toFixed(2)}</div>
            </div>
            <div className="posi-relative">
              <Label label="Payer Name" />
              <div className="name-view">{data.insurancePayerName}</div>
            </div>
            <div className="posi-relative">
              <Label label="Entered By" />
              <div className="name-view">{data.createdByName}</div>
            </div>
            <div className="posi-relative">
              <Label label="Marked Unbillable By" isUnbillable />
              {data.status === 'Unbillable'
                ? (
                  <div className="name-view">
                    {data.modifiedByName}
                    {' on '}
                    {moment(data.modifiedOn).format('MM/DD/YYYY [at] hh:mm a')}
                  </div>
                ) : <> </>}
            </div>
          </div>
        </Form.Column>
      </Form.Section>
    </Form>
  );
};

export default Details;
