import React, { useCallback, useState, useRef } from 'react';
import { Form as AntdForm } from 'antd';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

import { useDispatch, useSelector } from 'react-redux';
import { apiUrls } from '../../../../api/constants';

import useRedirect from '../../../../hooks/useRedirect';
import useUpdateTabName from '../../../../hooks/useUpdateTabName';

import WithLabel from '../../../../hoc/withLabel';

import {
  formName, formId, labelPaths, listId,
} from '../../../../lib/constants';
import SuccessMessages from '../../../../lib/successMessages';
import formFieldValueParser from '../../../../lib/formFieldValuesParser';

import Form from '../../../../components/Form';
import Button from '../../../../components/Button';
import Notification from '../../../../components/Notification';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import Icon from '../../../../components/Icon';
import PatientInformation from './PatientInformation';
import useCRUDWithoutTab from '../../../../hooks/useCRUDWithoutTab';
import { deleteTabData } from '../../../../store/actions/navigation';
import ErrorMessages from '../../../../lib/errorMessages';
import Events from '../../../../lib/events';
import useReduxState from '../../../../hooks/useReduxState';

const Demographic = (props) => {
  const [formData, setFormData] = useState({});
  const { params } = useRedirect();
  const { id, tabId } = params;
  const [isClear, setIsClear] = useState(false);
  const [form] = AntdForm.useForm();
  const updatePatientRef = useRef(false);
  const scheduler = useSelector((state) => state?.navigation?.get('scheduler'));

  const {
    labels,
    isUpdatePatientAuthenticated,
    isFromCollection,
  } = props;
  const dispatch = useDispatch();
  const clearPatientDetail = useCRUDWithoutTab({ id: `patient-detail-${id}` })[4];
  const [patientCareTeamsDataCopy, setPatientCareTeamsDataCopy] = useReduxState('patient-demographic-patientCareTeamsDataCopy');

  const setUpdatedNameInTab = useUpdateTabName();

  const parser = useCallback((patientUpdatedData) => {
    const parsedValues = formFieldValueParser(patientUpdatedData, {
      int: [
        'primaryPhoneExt',
        'secondaryPhoneExt',
      ],
      date: ['patientDeceasedDate'],
    });

    const parsedDateOfBirth = moment(patientUpdatedData?.dateOfBirth || '').format('MM-DD-YYYY');

    const filteredData = [];
    if (patientCareTeamsDataCopy && patientCareTeamsDataCopy.length) {
      patientCareTeamsDataCopy.forEach((data, index) => {
        const physician = patientCareTeamsDataCopy[index];
        if (physician?.providerName) filteredData.push(physician);
      });
    }
    if (updatePatientRef.current) {
      parsedValues.ProceedIfDuplicate = updatePatientRef.current;
    }
    parsedValues.patientCareTeams = filteredData;
    parsedValues.dateOfBirth = parsedDateOfBirth;
    parsedValues.label = !isEmpty(parsedValues.label) ? parsedValues?.label?.join(',') : undefined;
    return parsedValues;
  }, [patientCareTeamsDataCopy]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      let name = `${form.getFieldValue('firstName')} ${form.getFieldValue('middleName') ? form.getFieldValue('middleName') : ''} ${form.getFieldValue('lastName')}`;
      if (isFromCollection) {
        name = `Claim: ${name}`;
      }
      setUpdatedNameInTab(name);
      clearPatientDetail(true);
      const careTeamData = isArray(patientCareTeamsDataCopy) && [...patientCareTeamsDataCopy];
      if (scheduler) {
        Object.keys(scheduler || {}).forEach((key) => {
          if (scheduler[key]?.tabId && scheduler[key]?.tabId !== 'parent' && (scheduler[key]?.path?.includes('Check-in') || scheduler[key]?.path?.includes('Check-out'))) {
            dispatch(deleteTabData({ tabId: scheduler[key].tabId }));
          }
        });
      }
      Notification({ message: SuccessMessages.PATIENT_UPDATED_SUCCESSFULLY, success: true });
      updatePatientRef.current = false;
      if (isFromCollection) {
        Events.trigger('refetch-claim-overview');
      } else {
        dispatch(deleteTabData({ tabId }));
      }
      setPatientCareTeamsDataCopy(careTeamData);
    }
  }, [clearPatientDetail, dispatch, form, isFromCollection,
    patientCareTeamsDataCopy, scheduler, setPatientCareTeamsDataCopy, setUpdatedNameInTab, tabId]);

  const initialDataParser = useCallback((patientInfo) => {
    const parsedData = formFieldValueParser(patientInfo, {
      date: ['patientDeceasedDate'],
      string: ['ssn', 'primaryPhoneExt', 'secondaryPhoneExt'],
      int: ['howDidYouHear'],
    });
    return {
      ...parsedData,
      dateOfBirth: moment(patientInfo?.dateOfBirth?.split('T')?.[0]),
      email: patientInfo?.isOptOutEmail ? undefined : patientInfo?.email,
    };
  }, []);

  const onGetResponseComplete = useCallback((patientData) => {
    if (patientData && Object.values(patientData).length) {
      setFormData(patientData);
    }
  }, [setFormData]);

  const resetFormData = useCallback((resetForm) => () => {
    resetForm();
    setIsClear(true);
  }, []);

  const onRequestFail = useCallback((message) => {
    if (message?.[0] === ErrorMessages.PATIENT_ALREADY_EXISTS) {
      ConfirmDialog({
        onOk: (close) => {
          updatePatientRef.current = true;
          form.submit();
          close();
        },
        onCancel: (close) => {
          close();
        },
        okText: 'Continue',
        title: 'Warning',
        content: ErrorMessages.PATIENT_ALREADY_EXISTS,
        icon: <Icon name="ExclamationCircleOutlined" />,
        cancelButtonProps: { className: 'ant-modal-continue-green-btn' },
        okButtonProps: { className: 'ant-modal-ok-white-btn' },
      })();
    } else if (message?.length) {
      Notification({ message: message?.[0], success: false });
    }
  }, [form]);

  return (
    <div className="complete-form-wrap">
      <Form
        form={form}
        section
        isUpdate
        getUrl={`${apiUrls.GET_PATIENT}/${id}`}
        url={`${apiUrls.UPDATE_PATIENT}/${id}`}
        initialDataParser={initialDataParser}
        parser={parser}
        formId={formId.EDIT_PATIENT_DEMOGRAPHIC}
        onRequestComplete={onRequestComplete}
        onRequestFail={onRequestFail}
        name={formName.patientForm}
        tabId={tabId}
        listId={listId.PATIENTS}
        onGetResponseComplete={onGetResponseComplete}
        extraData={{
          patientCareTeams: [],
        }}
        disableForm={!isUpdatePatientAuthenticated}
      >
        {({ resetForm }) => (
          <>
            <PatientInformation
              form={form}
              labels={labels}
              data={formData}
              formId={formId.EDIT_PATIENT_DEMOGRAPHIC}
              isClear={isClear}
              setIsClear={setIsClear}
              isUpdate
              patientCareTeamsDataCopy={patientCareTeamsDataCopy}
              setPatientCareTeamsDataCopy={setPatientCareTeamsDataCopy}
            />
            <Form.Section>
              <div className="btns-action mr-top-8">
                <Button className="btn btn-success min-wt-86" id="patients_demographic_update" type="submit">{labels.get('buttons.update')}</Button>
                <Button className="btn" id="patients_demographic_clear" onClick={resetFormData(resetForm)}>{labels.get('buttons.clear')}</Button>
              </div>
            </Form.Section>
          </>
        )}
      </Form>
    </div>
  );
};

export default WithLabel(Demographic, labelPaths.EDIT_PATIENT_DEMOGRAPHIC);
