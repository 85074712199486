import React, { useState, useCallback } from 'react';
import {
  Form as AntdForm, Dropdown, Menu,
} from 'antd';
import isFunction from 'lodash/isFunction';

import SuccessMessages from '../../lib/successMessages';
import Form from '../../components/Form';
import { formId, enums } from '../../lib/constants';
import { apiUrls } from '../../api/constants';
import Select from '../../wiredComponents/Enum/Select';
import Button from '../../components/Button';
import { Input as FormInput } from '../../components/Form/inputFields';
import Notification from '../../components/Notification';

const otherMasterId = 1681;

const StatusDropDown = ({
  patientId, appointmentId, children, onFinish,
}) => {
  const [visible, setVisible] = useState(false);
  const [isNotesMandatory, setNotesMandatory] = useState(false);
  const [form] = AntdForm.useForm();

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.CONFIRMATION_ADDED_SUCCESSFULLY, success: true });
      setVisible(!visible);
      if (isFunction(onFinish)) onFinish();
      form.resetFields();
    }
  }, [visible, onFinish, form]);

  const onMethodChange = useCallback((methodMasterId) => {
    if (methodMasterId === otherMasterId) {
      setNotesMandatory(true);
    } else {
      setNotesMandatory(false);
    }
  }, []);

  const setVisibility = useCallback((e) => {
    e.stopPropagation();
    setVisible(!visible);
  }, [visible]);

  const menu = (
    <Menu onClick={(e) => { e.stopPropagation(); }}>
      <div style={{ padding: '12px' }}>
        <Form
          className="status-dropdown-menu-form"
          form={form}
          formId={`${formId.ADD_PATIENT_STATUS}-${appointmentId}`}
          url={apiUrls.ADD_APPOINTMENT_PATIENT_STATUS}
          key={appointmentId}
          onRequestComplete={onRequestComplete}
          extraData={{
            patientId,
            appointmentId,
          }}
          section
        >
          <Form.Section>
            <div className="main-content-area">
              <Select
                enumName={enums.CONFIRMATION_OUTCOME}
                required
                name="outcomeId"
                label="Outcome"
                labelSpan="7"
                inputSpan="17"
              />
              <Select
                enumName={enums.CONFIRMATION_METHOD}
                name="methodId"
                label="Method"
                labelSpan="7"
                inputSpan="17"
                onChange={onMethodChange}
              />
              <FormInput.TextArea
                name="notes"
                label="Notes"
                autoSize={{ minRows: 2, maxRows: 3 }}
                labelSpan="7"
                inputSpan="17"
                required={isNotesMandatory}
              />
            </div>
          </Form.Section>
          <Form.Section>
            <div className="flex justify-content-flex-end">
              <Button className="btn sm-btn mr-rt-8" onClick={() => setVisible(false)}>Cancel</Button>
              <Button className="btn sm-btn btn-success" type="submit">Save</Button>
            </div>
          </Form.Section>
        </Form>
      </div>
    </Menu>
  );
  return (
    <Dropdown
      overlay={menu}
      visible={visible}
      placement="bottomCenter"
    >
      <span>
        {children ? (
          <span role="presentation" onClick={setVisibility}>{children}</span>
        ) : (
          <span className="add-icon" role="presentation" onClick={setVisibility} />
        )}
      </span>
    </Dropdown>
  );
};

export default StatusDropDown;
