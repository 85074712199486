import React, { useCallback, useMemo, useState } from 'react';
import { Form as AntdForm } from 'antd';

import WithLabel from '../../../../../../hoc/withLabel';
import useRedirect from '../../../../../../hooks/useRedirect';
import Events from '../../../../../../lib/events';
import { formId, labelPaths } from '../../../../../../lib/constants';
import SuccessMessage from '../../../../../../lib/successMessages';
import WarningMessages from '../../../../../../lib/warningMessages';
import { getDateWithZeroTimeFormat } from '../../../../../../lib/util';

import Form from '../../../../../../components/Form';
import Modals from '../../../../../../components/Modal';
import Button from '../../../../../../components/Button';
import PatientRefundTable from './Components/PatientRefundTable';
import PatientRefundForm from './Components/PatientRefundForm';
import { apiUrls } from '../../../../../../api/constants';
import Notification from '../../../../../../components/Notification';

const manualCase = [948, 949, 957, 958];

const parser = (formValues) => ({
  ...formValues,
  effectiveDate: getDateWithZeroTimeFormat(formValues.effectiveDate),
  checkDate: getDateWithZeroTimeFormat(formValues.checkDate),
});

const PatientRefund = ({
  visible, toggleModal, labels, detailData, hasLineItems,
}) => {
  const [form] = AntdForm.useForm();
  const { params: { id: patientId, financialId } } = useRedirect();
  const [lineItemData, setLineItemData] = useState([]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessage.PATIENT_REFUND_SAVED_SUCCESSFULLY, success: true });
      toggleModal();
      if (financialId) Events.trigger(`reFetchFinancialDetail-${financialId}`);
    }
  }, [financialId, toggleModal]);

  const { lineItems, sum, totalApplied } = useMemo(() => {
    const parsedLineItems = [];
    let total = 0;
    let totalChargeAppliedAmount = 0;
    if (lineItemData?.length) {
      lineItemData.forEach((item) => {
        if (item?.refund) {
          parsedLineItems.push({
            financeId: item?.financeId,
            refund: item?.refund,
            paid: item?.paid,
          });
          total += parseFloat(item?.refund || 0);
        }
        totalChargeAppliedAmount += parseFloat(item?.applied || 0);
      });
    }
    return { lineItems: parsedLineItems, sum: total, totalApplied: totalChargeAppliedAmount };
  }, [lineItemData]);

  const submitForm = useCallback(() => {
    const amount = parseFloat(form.getFieldValue('amount') || 0);
    const unappliedCredit = parseFloat(detailData?.balance ?? 0);
    const isChargeWithMoreRefundAmount = lineItemData?.some((item) => item?.refund > item?.applied);
    const isAmountRefundedFromAnyCharge = lineItemData?.some((item) => item?.refund > 0);
    const amountRefundedFromUnappliedCredit = amount - sum;
    const amountToRefundedFromCharge = amountRefundedFromUnappliedCredit - unappliedCredit;

    if (sum > amount && hasLineItems) {
      Notification({ message: WarningMessages.REFUND_AMOUNT_SHOULD_NOT_BE_LESS_THEN_TOTAL_REFUND });
    } else if (isChargeWithMoreRefundAmount) {
      Notification({ message: WarningMessages.CAN_NOT_REFUND_MORE_THAN_APPLIED_AMOUNT });
    } else if (unappliedCredit === 0 && !isAmountRefundedFromAnyCharge) {
      Notification({
        message: WarningMessages.PLEASE_ENTER_APPROPRIATE_REFUND_AMOUNTS_IN_EACH_CPT_CODE,
      });
    } else if (amountRefundedFromUnappliedCredit > unappliedCredit
      && (isAmountRefundedFromAnyCharge || (amountToRefundedFromCharge > totalApplied))) {
      Notification({ message: WarningMessages.SYSTEM_CAN_NOT_REFUND_MORE_UNAPPLIED_AMOUNT });
    } else if (unappliedCredit > 0 && amount > 0
        && hasLineItems && !isAmountRefundedFromAnyCharge && amountToRefundedFromCharge > 0) {
      /* Weighted Refund Calculation =
        (chargeAppliedAmount / SumOfAllChargeAppliedAmount) * (amountToRefundedFromCharge) */
      const weightedRefundAmount = lineItemData?.map((item) => parseFloat((
        (item?.applied || 0) / totalApplied) * amountToRefundedFromCharge).toFixed(2));
      const sumOfWeightedRefundAmount = (weightedRefundAmount?.reduce(
        (sumAmount, val) => sumAmount + parseFloat(val), 0) || 0).toFixed(2);
      const remainingRefundAmount = parseFloat(
        amountToRefundedFromCharge - sumOfWeightedRefundAmount,
      ).toFixed(2);
      const lastIndex = weightedRefundAmount?.findLastIndex((item) => item > 0);
      setLineItemData(lineItemData?.map((item, index) => {
        let refundAmount = weightedRefundAmount[index];
        if (lastIndex === index) {
          refundAmount = parseFloat(refundAmount || 0) + parseFloat(remainingRefundAmount || 0);
        }
        return {
          ...item,
          refund: parseFloat(refundAmount).toFixed(2),
          approved: parseFloat(refundAmount).toFixed(2),
        };
      }));
      form.submit();
    } else {
      form.submit();
    }
  }, [detailData, form, hasLineItems, lineItemData, sum, totalApplied]);

  const getPaymentMethod = () => {
    const paymentType = form.getFieldValue('paymentTypeId');
    if (paymentType) {
      if (manualCase.includes(parseInt(paymentType, 10))) {
        return 'Manual';
      }
      return 'Electronic';
    }
    return null;
  };

  return (
    <Modals
      visible={!!visible}
      toggleModal={toggleModal}
      width="1000px"
      destroyOnClose
      footer={[
        <div className="group-btns" key="footer">
          <Button
            id="patients_financial_insuranceRefund_cancel"
            className="btn min-wt-86"
            onClick={() => toggleModal(false)}
          >
            {labels.get('buttons.cancel')}
          </Button>
          <Button
            id="patients_financial_insuranceRefund_save"
            className="btn btn-success min-wt-86 inline"
            onClick={submitForm}
          >
            {labels.get('buttons.save')}
          </Button>
        </div>,
      ]}
    >
      <div className="addition-header">
        <div className="lookup sprite-img-before">
          <p>{labels.get('titles.patientRefund')}</p>
        </div>
      </div>
      <div className="modal-content-weapper shadow-wrap">
        <Form
          form={form}
          section
          initialData={{
            patientBalance: parseFloat(detailData?.amount || 0).toFixed(2),
            refundableBalance: parseFloat(detailData?.refund || 0).toFixed(2),
          }}
          extraData={() => ({
            patientId,
            lineItems,
            PaymentSource: 'OverTheCounter',
            PaymentMethod: getPaymentMethod(),
            RefundPaymentId: financialId,
          })}
          url={apiUrls.ADD_PATIENT_REFUND}
          formId={`${formId.ADD_PATIENT_REFUND}-${patientId}`}
          parser={parser}
          onRequestComplete={onRequestComplete}
        >
          <div className="modal-from-feilds">
            <PatientRefundForm form={form} labels={labels} />
            <div className="modal-content-area patient-refund-table">
              <PatientRefundTable
                labels={labels}
                patientId={patientId}
                lineItemData={lineItemData}
                setLineItemData={setLineItemData}
              />
            </div>
          </div>
        </Form>
      </div>
    </Modals>
  );
};

export default WithLabel(PatientRefund, labelPaths.FINANCIAL_POPUP_PATIENT_REFUND);
