import React from 'react';
import PropTypes from 'prop-types';
import './smcheckbox.scss';
import { Checkbox, Form } from 'antd';

function Smcheckbox({
  className, children, onChange, valuePropName, name, isFormItem, ...otherProps
}) {
  const checkbox = (
    <Checkbox
      className={className}
      onChange={onChange}
      name={name}
      data-testid={name}
      {...otherProps}
    >
      {children}
    </Checkbox>
  );
  if (!isFormItem) {
    return (
      <div className="sm-checkbox">
        {checkbox}
      </div>
    );
  }
  return (
    <div className="sm-checkbox">
      <Form.Item name={name} valuePropName={valuePropName}>
        {checkbox}
      </Form.Item>
    </div>
  );
}

Smcheckbox.defaultProps = {
  onChange: (event) => event.stopPropagation(),
  className: '',
  children: [],
  valuePropName: 'checked',
  isFormItem: true,
};

Smcheckbox.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  valuePropName: PropTypes.string,
  isFormItem: PropTypes.bool,
};

export default Smcheckbox;
