import React, { useState, useCallback } from 'react';
import { noSpecialCharacterAllowed } from '../../../lib/util';

import Input from '../../Form/Input';

const InputCell = ({
  initialValue, handleTextBox, name, ...props
}) => {
  const [localData, setLocalData] = useState(initialValue);

  const handleLocalTextBox = useCallback(({ target: { value } }) => {
    if (noSpecialCharacterAllowed(value)) { setLocalData(value); }
  }, []);

  return (
    <Input
      name={name}
      isFormItem={false}
      labelSpan={0}
      value={localData}
      inputSpan={24}
      onBlur={handleTextBox}
      onChange={handleLocalTextBox}
      maxValueLength={4}
      {...props}
    />
  );
};

export default InputCell;
