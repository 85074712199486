import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import clone from 'lodash/clone';

import * as selectors from '../../store/selectors';
import { getUserSettings } from '../../store/actions/users';

import Loader from '../../components/Loader';
import SavedNavigationBar from '../../components/SavedNavigationBar';

import AppointmentDashBoard from './AppointmentDashBoard';
import { getLoggedInProviderId } from '../../lib/util';

const DashBoard = ({ dispatch, userSettings }) => {
  const filters = useRef();
  const [loading, setLoading] = useState(true);
  const localStorageData = useMemo(() => JSON.parse(localStorage.getDecryptedData('userSettingsData')) || {}, [userSettings]);
  const loggedInUserId = getLoggedInProviderId();

  const saveFrontDeskFilters = useCallback(debounce((frontdeskFilters = {}) => {
    const newFrontDeskFilters = { ...frontdeskFilters };
    const { current = {}, appliedFilters = {} } = localStorageData || {};
    const { newSchedularFilters = {} } = current;
    if (!localStorageData?.current) {
      localStorageData.current = {};
    }
    newSchedularFilters.doctor = newFrontDeskFilters?.doctor;
    newSchedularFilters.location = newFrontDeskFilters?.location;
    newSchedularFilters.frontDeskDate = newFrontDeskFilters?.appointmentDate;
    appliedFilters.doctor = frontdeskFilters?.doctor[frontdeskFilters?.doctor?.length - 1];
    appliedFilters.location = frontdeskFilters?.location;
    localStorageData.appliedFilters = appliedFilters;
    localStorageData.current.newSchedularFilters = newSchedularFilters;
    localStorage.setEncryptedData('userSettingsData', JSON.stringify(localStorageData));
  }, 1000), [userSettings]);

  const updateFilters = useCallback((label, value) => {
    if (label && !loggedInUserId) {
      const newValue = label === 'appointmentDate' ? value.map((date) => date.unix()) : value;
      const newFilters = { ...filters.current, [label]: newValue };
      filters.current = newFilters;
      saveFrontDeskFilters(newFilters);
    }
  }, [filters, saveFrontDeskFilters]);

  useEffect(() => {
    if (!localStorageData) dispatch(getUserSettings());
  }, []);

  useEffect(() => {
    if (loading && localStorageData) {
      if (localStorageData?.appliedFilters) {
        const {
          current, appliedFilters,
        } = localStorageData || {};
        const { doctor: doctorParams } = current || {};
        const schedulerFilters = current?.newSchedularFilters || current?.frontdeskFilters || {};
        const { frontDeskDate } = schedulerFilters;
        let newDoctorParams = schedulerFilters?.doctor ? clone(schedulerFilters?.doctor) : [];
        let newParamsLocation;
        if (appliedFilters?.doctor) {
          if (newDoctorParams?.length === 5) {
            const index = newDoctorParams?.findIndex((
              currentDoctor,
            ) => currentDoctor === doctorParams);
            if (index > -1)newDoctorParams.splice(index, 1, appliedFilters?.doctor);
          } else newDoctorParams.push(appliedFilters?.doctor);
        } else { newDoctorParams.pop(); }
        newDoctorParams = uniq(compact(newDoctorParams));

        if (appliedFilters?.location && newDoctorParams?.length <= 1) {
          newParamsLocation = appliedFilters?.location;
        }
        delete schedulerFilters.lasModified;
        localStorageData.appliedFilters = {
          doctor: newDoctorParams[newDoctorParams.length - 1],
          location: newParamsLocation,
        };
        if (!localStorageData?.current) {
          localStorageData.current = {};
        }
        localStorageData.current.newSchedularFilters = {
          ...schedulerFilters,
          doctor: newDoctorParams,
          location: newParamsLocation,
        };
        localStorage.setEncryptedData('userSettingsData', JSON.stringify(localStorageData));
        filters.current = {
          ...schedulerFilters,
          appointmentDate: frontDeskDate,
          doctor: newDoctorParams,
          location: newParamsLocation,
        };
        setLoading(false);
      } else {
        const { frontdeskFilters = {} } = localStorageData?.current || {};
        filters.current = frontdeskFilters;
        setLoading(false);
      }
      setLoading(false);
    }
  }, [userSettings, localStorageData, loading]);

  return (
    <div className="appointment-dashboard">
      <SavedNavigationBar>
        {loading
          ? <Loader />
          : (
            <AppointmentDashBoard
              initialFilters={filters.current || {}}
              updateFilters={updateFilters}
            />
          )}
      </SavedNavigationBar>
    </div>
  );
};

export default connect((state) => ({
  userSettings: selectors.userSettings(state),
}))(DashBoard);
