import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import groupBy from 'lodash/groupBy';

import { apiUrls } from '../../../api/constants';
import { getUTCDate } from '../../../lib/util';
import Modals from '../../../components/Modal';
import useCRUD from '../../../hooks/useCRUD';
import WidgetLoader from '../../../components/WidgetLoader';
import Collapse from '../../../components/Collapse';

import './auditLog.scss';

const AuditLogsModal = ({ visible, toggleModal, appointmentId }) => {
  const [log, setLog] = useState([]);
  const [
    activityResponse,,
    activityLoading,
    getActivity,
    clearActivity,
  ] = useCRUD({
    id: `appointment-activity-${appointmentId}`, url: apiUrls.GET_ACTIVITY, type: 'read',
  });

  useEffect(() => {
    if (appointmentId && visible) {
      getActivity({ SortBy: 'modifiedOn', SortOrder: 'desc' }, `/${appointmentId}/Activity`);
    }
  }, [appointmentId, getActivity, visible]);

  useEffect(() => {
    if (activityResponse) {
      setLog(activityResponse);
      clearActivity(true);
    }
  }, [activityResponse]);

  if (log[log.length - 1]) {
    log[log.length - 1].isNew = true;
  }

  const groupedLogsData = useMemo(() => groupBy(log, (data) => moment(data.createdOn).format('MM/DD/YYYY')), [log]);

  return (
    <Modals
      visible={visible}
      width={768}
      className="notes-modal"
      toggleModal={toggleModal}
      destroyOnClose
      footer={null}
    >
      {activityLoading && <WidgetLoader />}
      <div className="addition-header print-modal-header">
        <span className="watch-modal-icon" />
        <p className="bold">Audit Logs</p>
      </div>
      <div className="info-accordian activity-logs">
        {Object.entries(groupedLogsData)
       && Object.entries(groupedLogsData).map(([key, value], index) => (
         <Collapse
           header={key}
           key={index}
         >
           {
             value?.map(({
               description, modifiedByName, modifiedOnDateModel, isNew,
             }, index1) => (
               <div style={{ padding: '8px', paddingTop: '0' }}>
                 <Collapse
                   key={index1}
                   header={(
                     <span style={{ color: '#aab3b6' }}>
                       <span>
                         Appointment
                         {' '}
                         {isNew ? 'Created' : 'Updated'}
                         {' '}
                         by
                       </span>
                       <span style={{ color: '#2567a3' }}>
                         {` ${modifiedByName || ''}`}
                       </span>
                       <span style={{ color: '#aab3b6' }}>
                         {` at ${getUTCDate(modifiedOnDateModel?.dateString, 'hh:mm A')}`}
                       </span>
                     </span>
                  )}
                 >
                   <div className="title">
                     {description?.split('\n').map((data) => <p className="inner-title">{data}</p>)}
                   </div>
                 </Collapse>
               </div>
             )
          )
        }

         </Collapse>
       ))}
      </div>
    </Modals>
  );
};
export default AuditLogsModal;
