import React from 'react';
import { Tag } from 'antd';

import TimeCell from '../../../../../../components/Cells/TimeCell';
import { dateFormatWith12Hour } from '../../../../../../lib/constants';

const columns = (labels) => [
  {
    Header: labels.get('tableColumns.date'),
    accessor: 'date',
    Cell: ({ row: { original } }) => (
      <TimeCell
        value={original?.appointmentStartDateTime?.dateString}
        format={dateFormatWith12Hour}
      />
    ),
    sort: true,
  },
  {
    Header: labels.get('tableColumns.provider'),
    accessor: 'provider',
    Cell: ({ row: { original: { providerFirstName, providerLastName } } }) => (
      (providerFirstName || providerLastName) ? `${providerFirstName} ${providerLastName}` : ''
    ),
    sort: true,
  },
  {
    Header: labels.get('tableColumns.location'),
    accessor: 'locationName',
    sort: true,

  },
  {
    Header: labels.get('tableColumns.examType'),
    accessor: 'examType.examTypeId',
    Cell: ({ row: { original: { examType } } }) => (
      examType.map((examTypeData) => (
        <Tag key={examTypeData?.examTypeId}>
          {examTypeData?.examName}
        </Tag>
      ))
    ),
    sort: true,
  },
];

export default columns;
