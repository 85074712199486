import React, { useCallback, useEffect, useMemo } from 'react';
import { Spin } from 'antd';

import isFunction from 'lodash/isFunction';
import words from 'lodash/words';
import startCase from 'lodash/startCase';
import { listIds, UiRoutes } from '../../../../lib/constants';
import { getContextMenuItems } from '../../../../lib/schedulerHelpers';
import rights from '../../../../lib/rights';
import { apiUrls } from '../../../../api/constants';
import useCRUD from '../../../../hooks/useCRUD';
import useRights from '../../../../hooks/useRights';

import Link from '../../../../components/SavedNavigationBar/Link';
import Notification from '../../../../components/Notification';
import useUpdateAppointmentStatus from '../../Components/useUpdateAppointmentStatus';

const menuItemClass = 'ant-dropdown-menu-item ant-dropdown-menu-item-only-child';

const MenuLinkItem = ({
  params, className, children, dataTestId,
}) => (
  <Link
    to={`${UiRoutes.schedularWithExtraInfo}`}
    component="li"
    className={className}
    params={{ ...params, appointmentStatus: children }}
    dataTestId={dataTestId}
  >
    {children}
  </Link>
);

const components = {
  link: MenuLinkItem,
  li: 'li',
};

const AppointmentContextMenu = ({
  item, visible, isListMenu, reFetch, isCheckedIn,
}) => {
  const {
    patient, providerId, patientName, appointmentId, patientId, status,
  } = item;

  const [appointment,, loading, getAppointment, clearAppointment] = useCRUD({
    id: `${listIds.GET_APPOINTMENT_DETAILs}-${appointmentId}`,
    url: `${apiUrls.GET_APPOINTMENT_DETAIL}/${appointmentId}`,
    type: 'read',
    isCheckedIn,
  });

  const responseCallback = useCallback(() => {
    Notification({ message: 'Appointment status changed to pending checkout', success: true });
    if (isFunction(reFetch)) reFetch();
    if (!isListMenu) getAppointment();
  }, [getAppointment, isListMenu, reFetch]);

  const responseCallbackUndoCheckIn = useCallback(() => {
    Notification({ message: 'Appointment status changed to pending', success: true });
    if (isFunction(reFetch)) reFetch();
    if (!isListMenu) getAppointment();
  }, [getAppointment, isListMenu, reFetch]);

  const [updateAppointmentStatus, statusLoading] = useUpdateAppointmentStatus({
    id: 'update-appointment-status-to-checkout',
    url: apiUrls.SCHEDULER_UPDATE_APPOINTMENT_STATUS,
    callBack: responseCallback,
  });

  const [undoCheckIn, undoLoading] = useUpdateAppointmentStatus({
    id: 'update-appointment-status-to-pending',
    url: apiUrls.UNDO_CHECK_IN,
    callBack: responseCallbackUndoCheckIn,
    type: 'create',
  });

  useEffect(() => {
    if (visible && status?.toLowerCase() !== 'billed') {
      getAppointment();
    } else {
      clearAppointment(true);
    }
  }, [visible]);

  const params = useMemo(() => ({
    id: patient?.id || patientId || providerId,
    appointmentId,
    data: { name: (patient?.name || patientName), showFullTitle: true },
  }), [appointmentId, patient, patientId, patientName, providerId]);

  const [isUpdateAppointmentAuthenticated] = useRights([rights.update_appointment]);

  const activeMenuItems = useMemo(() => getContextMenuItems(
    isListMenu ? item : appointment,
    isUpdateAppointmentAuthenticated,
  ), [isListMenu, item, appointment, isUpdateAppointmentAuthenticated]);

  const handleClick = useCallback((menuItemName) => () => {
    if (menuItemName === 'Undo Check-out') {
      return updateAppointmentStatus({ appointmentId: params?.appointmentId, status: 'PendingCheckout' });
    }
    if (menuItemName === 'Undo Check-in') {
      return undoCheckIn({ data: { appointmentId: params?.appointmentId } });
    }
    return null;
  }, [params, undoCheckIn, updateAppointmentStatus]);

  if (loading || statusLoading || undoLoading) return <div className="loader-container"><Spin /></div>;

  return (
    <div>
      {activeMenuItems.map(({ name, tab, type }) => {
        const Component = components[type];
        const tabName = `${startCase(words(tab || '')?.join(' '))}: ${params?.data?.name || ''}`;
        return (
          <Component
            key={tab}
            onClick={type === 'li' && handleClick(name)}
            className={menuItemClass}
            params={{ ...params, data: { name: tabName, showFullTitle: true }, tab }}
            data-testid={tab}
            dataTestId={tab}
          >
            {name}
          </Component>
        );
      })}
    </div>
  );
};

export default AppointmentContextMenu;
