import React, { useCallback } from 'react';

import Form from '../../../../components/Form';
import Select from '../../../Enum/Select';
import DatePicker from '../../../../components/Form/DatePicker';
import DateFilter from '../../../../components/DateFilter';
import SelectBox from '../../../../components/Form/SelectBox';
import Input from '../../../../components/Form/Input';

import { enums as enumConstant } from '../../../../lib/constants';

import LocationGridAutoComplete from '../../../LocationGridAutoComplete';
import UserAutoComplete from '../../../UserAutoComplete';
import PayerGridAutoComplete from '../../../PayerGridAutoComplete';
import '../../billing.scss';

const dueDateOptions = [
  { name: 'Past Due', value: 'pastDue' },
  { name: 'Due Today', value: 'dueToday' },
  { name: 'Next 7 Days', value: 'next7Days' },
  { name: 'Next 14 days', value: 'next14Days' },
  { name: 'Next 30 days', value: 'next30Days' },
];

const referralOption = [{ name: 'Yes', value: 'true' }, { name: 'No', value: 'false' }];

const FilterForm = ({
  labels, form, onDueDateChange, onFieldChange, additionalFilters,
}) => {
  const handleIdentifierValueChange = useCallback(({ target: { value = '' } }) => {
    const identifierValueLength = `${value}`?.trim()?.length;
    if ((!identifierValueLength && additionalFilters?.TaskIdentifier?.trim()?.length)) {
      onFieldChange();
    }
    if (identifierValueLength > 2) onFieldChange();
  }, [additionalFilters, onFieldChange]);

  return (
    <Form.Section noOfColumns={2}>
      <Form.Column>
        <UserAutoComplete
          name="AssignedTo"
          label={labels.get('labels.assignedTo')}
          form={form}
          minCharLength={3}
          labelSpan={10}
          inputSpan={14}
          header={false}
          onSelect={onFieldChange}
        />
        <Select
          label={labels.get('labels.priority')}
          name="TaskPriorityId"
          enumName={enumConstant.TASK_PRIORITY_IDS}
          onChange={onFieldChange}
        />
        <UserAutoComplete
          name="CreatedBy"
          label={labels.get('labels.createdBy')}
          form={form}
          minCharLength={3}
          labelSpan={10}
          inputSpan={14}
          header={false}
          onSelect={onFieldChange}
        />
        <Select
          label={labels.get('labels.status')}
          name="TaskStatusId"
          enumName={enumConstant.TASK_STATUS_IDS}
          onChange={onFieldChange}
        />
        <DatePicker
          label={labels.get('labels.createDateFrom')}
          name="CreatedFrom"
          onChange={onFieldChange}
        />
        <Input
          name="TaskIdentifier"
          label={labels.get('labels.taskIdentifier')}
          onChange={handleIdentifierValueChange}
        />
      </Form.Column>
      <Form.Column>
        <div>
          <DatePicker
            label={labels.get('labels.createDateTo')}
            name="CreatedTo"
            onChange={onFieldChange}
          />
          <LocationGridAutoComplete
            name="LocationId"
            label={labels.get('labels.location')}
            form={form}
            minCharLength={3}
            labelSpan={10}
            inputSpan={14}
            header={false}
            onSelect={onFieldChange}
          />
          <PayerGridAutoComplete
            name="InsurancePayerId"
            label={labels.get('labels.insurance')}
            nameAccessor="payerName"
            valueAccessor="payerId"
            labelSpan="10"
            inputSpan="14"
            onSelect={onFieldChange}
          />
          <DateFilter
            options={dueDateOptions}
            label={labels.get('labels.dueDate')}
            labelSpan="10"
            inputSpan="14"
            noDefaultValue
            onChange={onDueDateChange}
          />
          <SelectBox
            label={labels.get('labels.outsideReferrals')}
            name="OutsideReferral"
            options={referralOption}
            onChange={onFieldChange}
          />
        </div>
      </Form.Column>
    </Form.Section>
  );
};

export default FilterForm;
