import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Upload, Button, Form, Row, Col,
} from 'antd';
import classNames from 'classnames';
import { UploadOutlined } from '@ant-design/icons';

import { fileSizeCheck } from '../../lib/util';

import EventWrapper from '../EventWrapper';
import Notification from '../Notification';

import './upload.scss';

export default function Uploads(props) {
  const {
    label,
    name,
    value,
    onChange,
    accept,
    listType,
    required,
    showUploadList,
    title,
    labelSpan,
    inputSpan,
    disabled,
    multiple,
    onClick,
    children,
    onRemove,
    beforeUpload,
    dataTestId,
    form,
    transformFile,
    sizeLimit,
    ...otherProps
  } = props;
  const uploadProps = {
    accept,
    listType,
    multiple,
    disabled,
    onChange,
    transformFile,
    onRemove,
    showUploadList,
  };

  const normFile = useCallback((e) => {
    if (Array.isArray(e)) {
      return e;
    }
    if (e?.fileList?.length) {
      const shouldUpload = fileSizeCheck(
        e?.fileList[e?.fileList?.length - 1]?.originFileObj, sizeLimit || 120,
      );
      if (!shouldUpload) {
        e?.fileList?.pop();
      }
    }
    if (!multiple) {
      if (e.fileList[e.fileList.length - 1]) {
        e.fileList[e.fileList.length - 1].status = 'done';
      }
      return e?.fileList?.length ? [e.fileList.pop()] : [];
    }
    return e?.fileList;
  }, [multiple, sizeLimit]);

  const customRequestHandler = ({ onSuccess }) => {
    onSuccess('ok');
  };

  const onImagePreview = useCallback((data) => {
    const { url, thumbUrl } = data;
    if (thumbUrl) {
      const image = new Image();
      image.src = thumbUrl;
      const w = window.open('');
      w.document.write(image.outerHTML);
    } else if (url) window.open(url);
    else {
      try {
        const urlBlob = URL.createObjectURL(data.originFileObj);
        if (urlBlob) {
          window.open(urlBlob);
        } else {
          Notification({ message: 'Can not open the image as it is not uploaded yet.' });
        }
      } catch (e) {
        Notification({ message: 'Can not open the image as it is not uploaded yet.' });
      }
    }
  }, []);

  return (
    <Row>
      <Col span={labelSpan || 10}>
        <div className="ant-form-item-label ant-form-item-label-left">
          <EventWrapper
            type="label"
            className={classNames('ant-form-item-no-colon', required && 'ant-form-item-required')}
            title={label}
          >
            {label}
          </EventWrapper>
        </div>
      </Col>
      <Col span={inputSpan || 14}>
        <Form.Item
          name={name}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required, message: `Upload ${label} file` }]}
          {...otherProps}
        >
          <Upload
            onPreview={onImagePreview}
            data-testid={dataTestId || name}
            customRequest={customRequestHandler}
            {...uploadProps}
          >
            {children || (
            <Button onClick={onClick} data-testid={`${dataTestId || name}-btn`}>
              <UploadOutlined />
              {title}
            </Button>
            )}
          </Upload>
        </Form.Item>
      </Col>
    </Row>
  );
}

Uploads.defaultProps = {
  title: 'Upload',
  label: '',
  name: '',
  value: '',
  onChange: () => { /* This is intentional */ },
  accept: false,
  listType: 'picture',
  required: false,
  showUploadList: true,
  labelSpan: 10,
  inputSpan: 14,
  disabled: false,
  multiple: false,
  onClick: () => { /* This is intentional */ },
  onRemove: () => { /* This is intentional */ },
};

Uploads.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  accept: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  listType: PropTypes.string,
  required: PropTypes.bool,
  labelSpan: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  inputSpan: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  onClick: PropTypes.func,
  showUploadList: PropTypes.bool,
  onRemove: PropTypes.func,
};
