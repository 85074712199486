import React, { useCallback, useEffect } from 'react';
import { Col, Form } from 'antd';

import { apiUrls } from '../../../../../api/constants';
import successMessage from '../../../../../lib/successMessages';
import { enums as enumConstant } from '../../../../../lib/constants';
import Events from '../../../../../lib/events';

import useCRUD from '../../../../../hooks/useCRUD';

import Modal from '../../../../../components/Modal';
import Loader from '../../../../../components/WidgetLoader';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Form/Input';
import Notification from '../../../../../components/Notification';

import Select from '../../../../../wiredComponents/Enum/Select';

import './CancelAppointment.scss';

const ModalTitle = () => (
  <div className="modal-title">
    Cancel Appointment?
  </div>
);

const CancelAppointmentDialog = ({
  isVisible, toggleModal, info, onRemove, reFetch,
}) => {
  const [form] = Form.useForm();
  const [cancelResponse, , loading, cancel, clearResponse] = useCRUD({
    id: `${apiUrls.CANCEL_APPOINTMENT}-${info && info.id}`,
    url: apiUrls.CANCEL_APPOINTMENT,
    type: 'update',
  });

  const onFinish = useCallback((values) => {
    cancel({
      ...values,
      appointmentId: info && info.appointmentId,
    });
  }, [cancel, info]);

  const toggle = useCallback(() => {
    form.resetFields();
    toggleModal(!isVisible);
  }, [form, isVisible, toggleModal]);

  useEffect(() => {
    if (cancelResponse && cancelResponse.message && info) {
      Notification({ message: successMessage.APPOINTMENT_CANCELLED_SUCCESSFULLY, success: true });
      clearResponse();
      onRemove(info);
      toggleModal(false);
      form.resetFields();
      Events.trigger('refetchSchedulerAppointmentListViewTable');
      if (reFetch) { reFetch(); }
    }
  }, [cancelResponse, toggleModal, onRemove, clearResponse, info]);

  return (
    <Modal
      visible={isVisible}
      centered
      title={<ModalTitle />}
      onCancel={toggle}
      destroyOnClose
      footer={[
        <div className="group-btns" key="footer">
          <Button className="btn min-wt-86" onClick={toggle}>Cancel</Button>
          <Button className="btn btn-success min-wt-86 inline" onClick={form.submit}>Confirm</Button>
        </div>,
      ]}
      className="cancel-appointment-modal"
    >
      {loading && <Loader />}
      {`Do you want to remove ${info && (info?.patient ? info?.patient?.name : info?.patientName)}'s appointment?`}
      <Form
        form={form}
        onFinish={onFinish}
      >
        <Col span={24}>
          <Select
            label="Reason"
            name="reasonId"
            enumName={enumConstant.CANCELLATIONS}
            inputSpan={16}
            labelSpan={8}
          />
        </Col>
        <Col span={24}>
          <Input.TextArea
            label="Comments"
            name="reason"
            inputSpan={16}
            labelSpan={8}
            autoSize={{ minRows: 2, maxRows: 6 }}
            maxValueLength={200}
          />
        </Col>
      </Form>
    </Modal>
  );
};

export default CancelAppointmentDialog;
