import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';

import AutoComplete from '../../../components/Form/AutoComplete';

import * as selectors from '../../../store/selectors';

const EnumAutoComplete = ({
  enumName,
  enumMaster,
  params,
  ...props
}) => {
  const enumId = get(enumMaster, `${enumName}.enumId`);

  return (
    <AutoComplete
      params={{
        enumId,
        ...params,
      }}
      {...props}
    />
  );
};

export default connect((state) => ({
  enumMaster: selectors.getEnumMaster(state),
}))(EnumAutoComplete);
