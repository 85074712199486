import React, { useEffect, useState, useCallback } from 'react';
import { apiUrls } from '../../../../../api/constants';
import withQuery from '../../../../../hoc/withQuery/withQuery';
import WithLabel from '../../../../../hoc/withLabel';
import columns from './columns';
import useRedirect from '../../../../../hooks/useRedirect';
import Events from '../../../../../lib/events';
import { labelPaths } from '../../../../../lib/constants';
import PdfViewModal from '../../../../../wiredComponents/Modal/PdfViewModal';

const AddHistoryTable = withQuery({
  url: apiUrls.INSURANCE_VERIFICATION_HISTORY,
  listId: 'insurance-verification-history',
})();

const TableWrapper = ({
  reFetch, Component,
}) => {
  useEffect(() => {
    Events.on('refresh-verification-history-table', 'refresh-verification-history-table', reFetch);
    return () => Events.remove('refresh-verification-history-table', 'refresh-verification-history-table');
  }, []);

  return Component;
};

function AddHistory({ labels }) {
  const { params } = useRedirect();
  const [isPdfModalVisible, setIsPdfModalVisible] = useState(false);
  const [selectedDataForPdfView, setSelectedDataForPdfView] = useState();

  const handlePdfModalMethod = useCallback(() => {
    setIsPdfModalVisible(!isPdfModalVisible);
  }, [isPdfModalVisible]);

  const handleResponseMethod = useCallback((selectedRowData) => {
    setSelectedDataForPdfView(selectedRowData);
    setIsPdfModalVisible(!isPdfModalVisible);
  }, [isPdfModalVisible]);

  return (
    <div className="complete-form-wrap insurance-aside-wrap posi-relative">
      <AddHistoryTable
        columns={columns}
        filters={{ PatientId: params?.id }}
        initialSort={[{ id: 'modifiedOn', desc: true }]}
        handleResponseMethod={handleResponseMethod}
      >
        {({
          Component, reFetch,
        }) => (
          <TableWrapper
            Component={Component}
            reFetch={reFetch}
          />
        )}
      </AddHistoryTable>
      {isPdfModalVisible && (
      <PdfViewModal
        isVisible={isPdfModalVisible}
        toggleModal={handlePdfModalMethod}
        data={selectedDataForPdfView}
        subCategoryName={selectedDataForPdfView?.subCategoryName}
        labels={labels}
        sixPagePdf
      />
      )}
    </div>
  );
}

export default WithLabel(AddHistory, labelPaths.VERIFICATION_HISTORY);
