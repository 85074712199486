import React, { useCallback, useEffect } from 'react';
import { Form as AntdForm } from 'antd';

import { apiUrls } from '../../../../../../../api/constants';
import formFieldValuesParser from '../../../../../../../lib/formFieldValuesParser';
import { formId } from '../../../../../../../lib/constants';

import Form from '../../../../../../../components/Form';
import Button from '../../../../../../../components/Button';
import useReduxState from '../../../../../../../hooks/useReduxState';

import CaseForm from './CaseForm';

const parser = (values) => formFieldValuesParser(values, {
  date: [
    'effectiveDateFrom',
    'effectiveDateTo',
    'lastSeenDate',
    'onSetDate',
    'hospitalizationDateFrom',
    'hospitalizationDateTo',
    'accidentDate',
  ],
});

const EditCase = ({
  labels, params, serviceDate, onUpdateRequestComplete, clearButtonRef, destroyOnClose = false,
}) => {
  const [form] = AntdForm.useForm();
  const [formData, setFormData] = useReduxState('Patient-cases-formData', null);

  const { id: patientId, caseId: id, tabId } = params;

  useEffect(() => () => { if (destroyOnClose) { form.completeReset(); } }, []);

  const handleCaseTypeSelect = useCallback((value, allItem) => {
    const { item } = allItem || {};
    if (serviceDate && (['clinicaltrial', 'outpatient'].indexOf(item?.masterCode?.toLowerCase()) > -1)) {
      form.setFieldsValue({ onSetDate: serviceDate });
    } else {
      form.setFieldsValue({ onSetDate: '' });
    }
  }, [form, serviceDate]);

  const handleFormData = useCallback((data = {}) => {
    setFormData(data);
  }, [setFormData]);

  return (
    <Form
      form={form}
      extraData={{
        patientId,
        caseTypeName: 'OutPatient', // this property will remove with fake
      }}
      parser={parser}
      formId={`${formId.UPDATE_CASE}-${patientId}`}
      url={`${apiUrls.UPDATE_CASE}/${id}`}
      getUrl={`${apiUrls.GET_CASE}/${id}`}
      onRequestComplete={onUpdateRequestComplete}
      isUpdate
      initialDataParser={parser}
      onGetResponseComplete={handleFormData}
      name={`${formId.UPDATE_CASE}-${patientId}-${tabId}`}
      tabId={tabId}
    >
      {({ resetForm }) => (
        <div>
          <CaseForm
            labels={labels}
            handleCaseTypeSelect={handleCaseTypeSelect}
            form={form}
            formData={formData}
            patientId={patientId}
          />
          <div className="btns-action">
            <Button className="btn btn-success min-wt-86" onClick={form.submit}>{labels.get('buttons.update')}</Button>
            <Button ref={clearButtonRef} className="btn" onClick={resetForm}>{labels.get('buttons.clear') }</Button>
          </div>
        </div>
      )}
    </Form>
  );
};

export default EditCase;

