import React, {
  useCallback, useMemo, useState, useRef, useEffect,
} from 'react';
import { useDrop } from 'react-dnd';
import moment from 'moment';

import debounce from 'lodash/debounce';
import isFunction from 'lodash/isFunction';
import Notification from '../../../../components/Notification';

import ErrorMessages from '../../../../lib/errorMessages';
import { slotCalculator } from '../../../../lib/schedulerHelpers';
import rights from '../../../../lib/rights';

import useRights from '../../../../hooks/useRights';

const tooltipOffset = { x: 11, y: 22 };

const EventContainer = ({
  children,
  onDrop,
  onClick = () => { /* This is intentional */ },
  slotDuration,
  viewDimentions,
  selectedDate,
  selectedDoctor,
  schedule,
  freeSlots,
  timeRange,
  minSlotTime,
  workupTime,
  getTodayMoment,
  isContextMenuActive,
}) => {
  const ref = useRef(null);
  const tooltip = useRef(null);
  const { from, to } = timeRange;
  const { cellHeight: heightOfEachCell } = viewDimentions;

  const [showTooltip, setTooltipVisibility] = useState(false);
  const [isCreateAppointmentAuthenticated] = useRights([rights.create_appointment]);

  const [{ isOver }, drop] = useDrop({
    accept: 'CARD',
    drop(item, monitor) {
      if (!ref.current) {
        return;
      }
      const { startTime, endTime } = item;
      const { startYAt, endYAt } = slotCalculator(startTime, endTime, timeRange, slotDuration);

      // get item initial offset from the mouse position and from the item being dragged (0, 0)
      // and mouse position from the event container (0, 0)
      const initialOffsetYFromContainer = (
        monitor.getInitialSourceClientOffset().y - ref.current.getBoundingClientRect().top);

      // get final offset from the container
      const offsetYFromContainer = initialOffsetYFromContainer
      + monitor.getDifferenceFromInitialOffset().y + (heightOfEachCell / 4);

      // calculate new cell number based on the cell height and width defined in the index
      const newCellNumberBasedOnHeight = Math.ceil(
        offsetYFromContainer / (heightOfEachCell / 2),
      ) || 1;

      // calculate new start and end times
      const changeInMinutes = (newCellNumberBasedOnHeight - startYAt) * (slotDuration / 2);

      const newStartTime = moment(startTime)
        .date(moment(selectedDate).date())
        .add(changeInMinutes, 'minutes')
        .second(1)
        .millisecond(0);
      const newEndTime = moment(endTime)
        .date(moment(selectedDate).date())
        .add(changeInMinutes, 'minutes')
        .second(0)
        .millisecond(0);

      const location = schedule.find((locationData) => {
        const locationEnter = moment(locationData.from).date(newStartTime.date());
        const locationExit = moment(locationData.to).date(newStartTime.date());
        return (
          newStartTime.isSameOrAfter(locationEnter) && newStartTime.isBefore(locationExit)
        );
      });

      const newItem = {
        ...item,
        date: newStartTime.format(),
        startTime: newStartTime.format(),
        endTime: newEndTime.format(),
        location: location ? location.location : null,
        doctor: {
          id: selectedDoctor.providerId,
          providerId: selectedDoctor.providerId,
          name: selectedDoctor.providerName,
        },
        slotPositions: {
          startYAt: newCellNumberBasedOnHeight,
          endYAt: newCellNumberBasedOnHeight + endYAt - startYAt,
        },
      };

      // call onDrop method for validations and actions
      if (onDrop) {
        onDrop(newItem, item);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const getCellTime = useCallback((offsetY) => {
    const startTime = getTodayMoment(selectedDate, from);
    const cellNumberY = Math.floor(offsetY / (heightOfEachCell / 2));
    const cellNumber = (cellNumberY * (slotDuration / 2));
    const cellForTime = Math.floor(cellNumber / minSlotTime) * minSlotTime;
    return startTime.add(cellForTime, 'minutes');
  }, [from, heightOfEachCell, minSlotTime, selectedDate, slotDuration, getTodayMoment]);

  // calculate data to be sent to add appointment dialog
  const handleOnClick = (e) => {
    if (e?.stopPropagation) e.stopPropagation();
    if (e?.nativeEvent && !isContextMenuActive) {
      const startTime = getTodayMoment(selectedDate, from);
      const endTime = getTodayMoment(selectedDate, to);
      const { offsetY } = e.nativeEvent;
      const time = getCellTime(offsetY);

      if (moment().isAfter(time)) {
        // check if time is already elapsed
        Notification({ message: ErrorMessages.SCHEDULER_CANNOT_ADD_BEFORE });
        return;
      }
      if (moment(time).isAfter(endTime)) {
        // check time is inside the time range
        const timeString = time.format('HH:mm');
        Notification({ message: `${ErrorMessages.SCHEDULER_SLOT_NOT_FREE}${timeString}` });
        return;
      }
      if (moment(time).isBefore(moment(startTime).add(workupTime, 'minutes'))) {
        // check arrival time is inside time range
        const timeString = time.format('HH:mm');
        Notification({ message: `${ErrorMessages.SCHEDULER_SLOT_NOT_FREE}${timeString}. ${ErrorMessages.SCHEDULER_ARRIVAL_TIME_BEFORE_START_TIME}` });
        return;
      }

      const isTimeValid = !freeSlots.every((slot) => {
        const { startTime: slotStartTime, endTime: slotEndTime } = slot;
        if (!slotStartTime || !slotEndTime) return true;
        const startTimeMoment = moment(slotStartTime).seconds(0);
        const endTimeMoment = moment(slotEndTime).seconds(0);
        if (time.isSameOrAfter(startTimeMoment) && time.isBefore(endTimeMoment)) return false;
        return true;
      });
      if (isTimeValid) {
        const location = schedule.find((locationData) => (
          moment(time).isSameOrAfter(locationData.from) && moment(time).isBefore(locationData.to)
        ));
        onClick({ time, location, doctor: selectedDoctor });
      } else {
        const timeString = time.format('HH:mm');
        Notification({ message: `${ErrorMessages.SCHEDULER_SLOT_NOT_FREE}${timeString}` });
      }
    }
  };

  const setTooltipPosition = useCallback((x, y) => {
    if (tooltip.current) {
      tooltip.current.style.left = `${x}px`;
      tooltip.current.style.top = `${y}px`;
      if (x >= 0 && y >= 0) {
        const expectedArrivalTime = moment(getCellTime(y - tooltipOffset.y));
        const outOfSlot = moment(expectedArrivalTime).isBefore(getTodayMoment(selectedDate, from));
        tooltip.current.innerHTML = `Arrival time: <span class="${
          outOfSlot ? 'outOfSlot' : ''}">${expectedArrivalTime.format('h:mm A')
        }</span>`;
      }
    }
  }, [getCellTime, selectedDate, from, getTodayMoment]);

  const debounceTooltipPosition = useMemo(() => debounce((x, y) => {
    setTooltipVisibility(x >= 0 && y >= 0);
    setTooltipPosition(x, y);
  }, 300), [setTooltipVisibility, setTooltipPosition]);

  const hideTooltip = useCallback(() => {
    setTooltipVisibility(false);
    setTooltipPosition(-100, -100);
    debounceTooltipPosition(-100, -100);
  }, [setTooltipPosition, debounceTooltipPosition]);

  const handleMousePosition = useCallback((event) => {
    event.persist();
    const {
      offsetX, offsetY, movementX, movementY, target,
    } = event?.nativeEvent;
    if (target !== ref.current) {
      setTooltipPosition(-100, -100);
      return;
    }
    if (Math.abs(movementX) > 50 || Math.abs(movementY) > 50) {
      // Remove tooltip if mouse is moved very fast
      hideTooltip();
      return;
    }
    const position = { x: offsetX + tooltipOffset.x, y: offsetY + tooltipOffset.y };
    if (showTooltip && tooltip.current) {
      setTooltipPosition(position.x, position.y);
    }
    debounceTooltipPosition(position.x, position.y);
  }, [debounceTooltipPosition, tooltip, showTooltip, setTooltipPosition, hideTooltip]);

  useEffect(() => {
    if (isOver) hideTooltip();
  }, [isOver]);

  drop(ref);
  return (
    // eslint-disable-next-line
    <div
      ref={ref}
      className="grid-events-container"
      onClick={
          (e) => (
            isCreateAppointmentAuthenticated
              ? handleOnClick(e)
              : e?.preventDefault()
          )
        }
      onMouseMove={handleMousePosition}
      onMouseLeave={hideTooltip}
    >
      <div ref={tooltip} className="event-tooltip-container" />
      {isFunction(children) ? children(handleOnClick) : children}
    </div>
  );
};

export default EventContainer;
