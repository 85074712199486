import React, { useCallback, useEffect } from 'react';
import { Form as Antd } from 'antd';

import { apiUrls } from '../../../../../../../api/constants';
import SuccessMessages from '../../../../../../../lib/successMessages';
import { labelPaths, formId, listId } from '../../../../../../../lib/constants';
import formFieldValuesParser from '../../../../../../../lib/formFieldValuesParser';

import WithLabel from '../../../../../../../hoc/withLabel';

import Button from '../../../../../../../components/Button';
import Form from '../../../../../../../components/Form';
import Notification from '../../../../../../../components/Notification';

import useCRUD from '../../../../../../../hooks/useCRUD';
import useRedirect from '../../../../../../../hooks/useRedirect';

import TermsGeneralForm from '../../Components/TermsGeneralForm';

const initialDataParser = (values) => {
  const parsedValues = formFieldValuesParser(values, {
    date: [
      'effectiveFrom',
      'effectiveTo',
    ],
    string: ['capitationAmount', 'feeForServiceAmount'],
  });
  if (parsedValues?.typeCapitation?.amount) {
    parsedValues.typeCapitation.amount = `${parsedValues.typeCapitation.amount}`;
  }
  const {
    typeCapitation,
    typeFeeForServices,
    valueIfProcedureNotFound,
    specificPlacesOfServices,
    specificPlans,
    specificPolicies,
    specificLocations,
  } = values;

  return {
    ...parsedValues,
    capitationPaymentArrangementId: typeCapitation.paymentArrangementId,
    capitationAmount: `${typeCapitation.amount}`,
    capitationAdjustClaim: typeCapitation.adjustClaim,
    capitationAdjustClaimReasonCode: typeCapitation.adjustClaimReasonCode,
    capitationAdjustClaimReasonValue: typeCapitation.adjustClaimReasonValue,
    capitationSubmitClaims: typeCapitation.submitClaims,
    capitationComments: typeCapitation.comments,
    feeForServicePaymentArrangementId: typeFeeForServices.paymentArrangementId,
    feeForServiceAmount: typeFeeForServices.amount,
    feeForServiceFeeScheduleTypeId: typeFeeForServices?.feeScheduleTypeId
      ? typeFeeForServices.feeScheduleTypeId : undefined,
    feeForServiceYearId: typeFeeForServices?.yearId ? typeFeeForServices.yearId : undefined,
    feeForServiceLocalityId: typeFeeForServices?.localityId
      ? typeFeeForServices.localityId : undefined,
    feeForServiceMultiplier: typeFeeForServices.multiplier.toString(),
    feeForServiceValueIfNoProcedureId: typeFeeForServices.valueIfNoProcedureId,
    feeScheduleTypeId: valueIfProcedureNotFound.feeScheduleTypeId || undefined,
    feeScheduleTypeYearId: valueIfProcedureNotFound.yearId || undefined,
    feeScheduleTypeLocalityId: valueIfProcedureNotFound.localityId || undefined,
    feeScheduleTypeMultiplier: valueIfProcedureNotFound.multiplier || undefined,
    specificPlacesOfServiceId: specificPlacesOfServices
    && specificPlacesOfServices.length ? specificPlacesOfServices.map((item) => item.masterId) : [],
    selectedLocations: specificLocations,
    specificLocationId: specificLocations?.map((item) => (item.locationId)) || [],
    selectedPlans: specificPlans,
    selectedPolicies: specificPolicies,
    dateDriverId: typeFeeForServices?.dateDriverId,
    amountPerRVU: typeFeeForServices?.amountPerRVU,
    percentageOfBilledAmount: typeFeeForServices?.percentageOfBilledAmount,
    AppliedToAllPlacesOfService: values?.appliedToAllPlacesOfService,
    AppliedToAllPlans: values?.appliedToAllPlans,
    AppliedToAllPolicyTypes: values?.appliedToAllPolicyTypes,
  };
};
const General = ({ labels, isUpdateContractAuthenticated }) => {
  const [form] = Antd.useForm();
  const { params } = useRedirect();
  const { termId, id, tabId } = params;

  const [termsTableResponse, , , getTableData, clearResponse] = useCRUD({
    id: 'edit-contract-terms-table-data',
    url: apiUrls.GET_CONTRACT_TERM,
    type: 'read',
  });

  useEffect(() => {
    getTableData({ contractId: id });
    return () => { clearResponse(true); };
  }, []);

  const parser = useCallback((values, allValues) => {
    const parsedValues = formFieldValuesParser(values, {
      date: [
        'effectiveFrom',
        'effectiveTo',
      ],
      bool: [
        'capitationAdjustClaim',
        'capitationSubmitClaims',
        'isActive',
      ],
    });
    if (!values.specificPlacesOfServiceId) {
      parsedValues.specificPlacesOfServiceId = [];
    }
    if (!values.specificLocationId) {
      parsedValues.specificLocationId = [];
    }
    parsedValues.specificPlanId = allValues?.selectedPlans?.length ? allValues?.selectedPlans.map(
      (item) => item.masterId || item.value,
    ) : [];
    parsedValues.specificPolicyId = allValues?.selectedPolicies?.length && !form.getFieldValue('AppliedToAllPolicyTypes')
      ? allValues?.selectedPolicies.map((item) => item.masterId || item.value) : [];
    return {
      ...parsedValues,
    };
  }, [form]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.CONTRACT_TERM_UPDATED_SUCCESSFULLY, success: true });
    }
  }, []);

  return (
    <Form
      section
      form={form}
      onRequestComplete={onRequestComplete}
      parser={parser}
      initialDataParser={initialDataParser}
      isUpdate
      listId={listId.CONTRACT_TERMS}
      url={`${apiUrls.UPDATE_CONTRACT_TERM_GENERAL}`}
      getUrl={`${apiUrls.GET_CONTRACT_TERM_DETAIL}/${termId}`}
      formId={`${formId.EDIT_TERM_GENERAL}-${termId}`}
      name={`${formId.EDIT_TERM_GENERAL}-${termId}`}
      tabId={tabId}
      editKey={termId}
      extraData={{
        contractId: id,
      }}
      initialData={{ contractId: id }}
      disableForm={!isUpdateContractAuthenticated}
    >
      {({ resetForm }) => (
        <>
          <TermsGeneralForm
            form={form}
            termsTableResponse={termsTableResponse}
          />
          <div className="form-btns contract-btns">
            <Button className="btn btn-success" type="primary" htmlType="submit">{labels.get('buttons.update')}</Button>
            <Button className="btn btn-ghost" onClick={resetForm}>{labels.get('buttons.clear')}</Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default WithLabel(General, labelPaths.EDIT_CONTRACT_TERMS_GENERAL);
