import React, { useEffect, useState, useCallback } from 'react';
import { useDrop } from 'react-dnd';
import { Collapse } from 'antd';
import classNames from 'classnames';

import List from '../Components/List';

import './upcoming.scss';

const { Panel } = Collapse;

const PanelHeader = ({
  panelKey, title, openPanel, accept,
}) => {
  const [{ isOver }, drop] = useDrop({
    accept,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  useEffect(() => {
    if (isOver) {
      openPanel(panelKey);
    }
  }, [isOver]);

  return (
    <div
      ref={drop}
      className={classNames('dashboard-upcoming-panel-headers', { 'hovering-above-header': isOver })}
    >
      {title}
    </div>
  );
};

const Upcoming = ({
  labels, data, time, acceptance, ...otherProps
}) => {
  const [panelsOpened, setOpenedPanels] = useState(['CheckIn', 'New', 'Cancel']);

  const openPanel = useCallback((panelKey) => {
    const newHoveredPanel = [...panelsOpened];
    if (panelKey && newHoveredPanel.indexOf(panelKey) === -1) {
      newHoveredPanel.push(panelKey);
      setOpenedPanels(newHoveredPanel);
    }
  }, [panelsOpened]);

  const isOpen = useCallback(
    (panelKey) => (panelKey && panelsOpened.indexOf(panelKey) !== -1),
    [panelsOpened],
  );

  return (
    <div className="dashboard-upcoming-category-container dash-category-container">
      <h4>{labels.get('headers.upcoming')}</h4>
      <div className="dashboard-list-container">
        <Collapse
          bordered={false}
          expandIconPosition="right"
          activeKey={panelsOpened}
          onChange={setOpenedPanels}
        >
          {data.map((section) => {
            const labelPath = `headers.status.${section.status}`;
            return (
              <Panel
                style={{ flex: isOpen(section.status) ? 1 : 0 }}
                header={(
                  <PanelHeader
                    panelKey={section.status}
                    title={`${labels.get(labelPath)} (${section.count || 0})`}
                    openPanel={openPanel}
                    accept={acceptance[section.status]}
                  />
            )}
                key={section.status}
              >
                <List
                  id={section.status}
                  data={section.appointments}
                  time={section.status !== 'Cancel' && time}
                  acceptance={acceptance}
                  labels={labels}
                  {...otherProps}
                />
              </Panel>
            );
          })}
        </Collapse>
      </div>
    </div>
  );
};

Upcoming.defaultProps = {
  data: [],
  onDrop: () => { /* This is intentional */ },
};

export default Upcoming;
