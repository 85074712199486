import React, { useCallback, useEffect, useState } from 'react';

import WithLabel from '../../../../hoc/withLabel';
import useTabLink from '../../../../hooks/useTabLink';
import useRedirect from '../../../../hooks/useRedirect';
import useRights from '../../../../hooks/useRights';

import { labelPaths, listIds, UiRoutes } from '../../../../lib/constants';
import rights from '../../../../lib/rights';
import { apiUrls } from '../../../../api/constants';

import Modals from '../../../../components/Modal';
import Button from '../../../../components/Button';
import Select from '../../../../components/SelectBox';
import RadioGroup from '../../../../components/RadioGroup';

import ErrorsTable from './Table';
import Notification from '../../../../components/Notification';
import ErrorMessages from '../../../../lib/errorMessages';
import SuccessMessages from '../../../../lib/successMessages';
import useCRUD from '../../../../hooks/useCRUD';
import useCRUDWithoutTab from '../../../../hooks/useCRUDWithoutTab';
import WidgetLoader from '../../../../components/WidgetLoader';

const tabList = (labels) => [
  { value: 'softEdits', label: labels.get('titles.softEdits') },
  { value: 'hardEdits', label: labels.get('titles.hardEdits') },
];

const options = [
  { value: 'editEncounter', name: 'Edit Encounter' },
  { value: 'editPatient', name: 'Edit Patient' },
  { value: 'editProvider', name: 'Edit Provider' },
];

const ErrorPopup = (props) => {
  const {
    params, push, generatePath,
  } = useRedirect();

  const {
    patientId, encounterId, providerId,
  } = params;

  const [patientInfo,,, getPatientInfo] = useCRUDWithoutTab({
    id: `patient-detail-${patientId}`, url: `${apiUrls.GET_PATIENT}/${patientId}`, type: 'read',
  });

  const [providerData, , , getProvider] = useCRUDWithoutTab({
    id: `${listIds.DOCTOR_DETAILS}_${providerId}`, url: apiUrls.GET_PROVIDER, type: 'read',
  });

  const [overrideResponse, , loading, overrideError, clearResponse] = useCRUD({ id: apiUrls.OVERRIDE_ERROR, url: apiUrls.OVERRIDE_ERROR, type: 'create' });

  const { navigate: navigateToEditPatient } = useTabLink({
    to: UiRoutes.editPatientWithTabId,
  });

  const { navigate: navigateToEditProvider } = useTabLink({
    to: UiRoutes.editProviderWithTabId,
  });

  const { navigate: navigateToEditEncounter } = useTabLink({
    to: UiRoutes.editEncounterWithId,
  });

  const { labels, setParentPath } = props;

  const [selectedRows, setSelectedRows] = useState([]);
  const [reFetchErrors, setRefetchErrors] = useState();

  useEffect(() => {
    if (!patientInfo) {
      getPatientInfo();
    }
  }, [patientInfo]);

  useEffect(() => {
    if (!providerData) {
      getProvider({}, `/${providerId}`);
    }
  }, [providerData]);

  const onCancel = useCallback(() => {
    push(generatePath(UiRoutes.claimsFilters, { ...params, claimFilter: 'error' }));
    setParentPath(generatePath(UiRoutes.claims));
  }, [push, generatePath, params, setParentPath]);

  const tabSwitchHandler = useCallback(({ target: { value } }) => {
    push(generatePath(UiRoutes.claimsErrorPopup, { ...params, errorTab: value }));
    setSelectedRows([]);
  }, [generatePath, push, params]);

  const selectHandler = useCallback((value) => {
    if (value === 'editPatient') {
      navigateToEditPatient({
        data: {
          name: `${patientInfo?.fullName} Demographic`,
        },
        ...params,
        id: patientId,
        tab: 'demographic',
      });
    } else if (value === 'editProvider') {
      navigateToEditProvider({
        data: {
          name: providerData?.firstName || providerData?.middleName || providerData?.lastName || '',
          showFullTitle: true,
        },
        ...params,
        id: providerId,
        tab: 'general',
      });
    } else if (value === 'editEncounter') {
      navigateToEditEncounter({
        data: {
          name: `${patientInfo?.fullName} Encounter`,
        },
        ...params,
        id: patientId,
        encounterId,
      });
    }
  }, [encounterId, navigateToEditEncounter, navigateToEditPatient, navigateToEditProvider,
    params, patientId, patientInfo, providerData, providerId]);

  useEffect(() => {
    if (overrideResponse) {
      Notification({ message: SuccessMessages?.ERROR_OVERRIDE_SUCCESSFULLY, success: true });
      clearResponse();
      reFetchErrors();
    }
  }, [clearResponse, overrideResponse, reFetchErrors]);

  const handleSubmit = useCallback(() => {
    if (selectedRows?.length) {
      overrideError({
        data: {
          claimErrorIds: selectedRows?.map(({ original }) => (original?.claimErrorId)),
          claimId: params?.claimNumber,
        },
      });
    } else {
      Notification({ message: ErrorMessages?.PLEASE_SELECT_AT_LEAST_ONE_ERROR_ITEM });
    }
  }, [overrideError, params, selectedRows]);
  const [isOverrideEditsAuthenticated] = useRights([rights.override_edits]);

  return (
    <Modals
      visible
      toggleModal={onCancel}
      footer={[
        <div className="group-btns" key="footer">
          <Button className="btn min-wt-86" onClick={onCancel}>{labels.get('buttons.cancel')}</Button>
          {isOverrideEditsAuthenticated && <Button className="cursor-not-allowed disabled-btn" onClick={handleSubmit} disabled>{labels.get('buttons.overrideEdits')}</Button>}
        </div>,
      ]}
    >
      <>
        <div className="addition-header">
          <div className="lookup sprite-img-before">
            <p>{labels.get('titles.errors')}</p>
          </div>
        </div>
        <div className="">
          <div className="modal-content-area">
            <div className="heading-area flex justify-content-sp-bt align-center">
              <RadioGroup
                tabList={tabList(labels)}
                onChange={tabSwitchHandler}
                value={params?.errorTab}
              />
              <Select
                options={options}
                selectProps={{ style: { width: 130 } }}
                placeholder="Actions"
                onChange={selectHandler}
              />
            </div>
            <div className="mr-top-16 posi-relative">
              {loading && <WidgetLoader />}
              <ErrorsTable
                labels={labels}
                setSelectedRows={setSelectedRows}
                setRefetchErrors={setRefetchErrors}
                checkedRowIds={selectedRows}
              />
            </div>
          </div>
        </div>
      </>
    </Modals>
  );
};

export default WithLabel(ErrorPopup, labelPaths.ERROR_POPUP);
