import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';

import Spinner from '../../../../../components/Spinner';

const getNewCategory = ({ categoryData, result, selected }) => (
  categoryData.map((category) => {
    const newCount = result.find((data) => data.categoryId === category.masterId);
    if (newCount) {
      if (selected.modifiedOn) {
        if (moment(selected.modifiedOn).isBefore(moment(newCount.modifiedOn))) {
          // eslint-disable-next-line no-param-reassign
          selected = {
            id: category.masterId,
            modifiedOn: newCount.modifiedOn,
            name: category.masterName,
          };
        }
      } else {
        // eslint-disable-next-line no-param-reassign
        selected = {
          id: category.masterId,
          modifiedOn: newCount.modifiedOn,
          name: category.masterName,
        };
      }
      return {
        ...category, count: newCount.documentCount, modifiedOn: newCount.modifiedOn,
      };
    }
    return category;
  })
);

const Category = ({
  item, setCategory, categoryId, searchText, setSearchText,
}) => {
  const handleCategoryChange = useCallback(() => {
    setCategory({ newCategoryId: item.masterId, name: item.masterName });
    setSearchText(null);
  }, [item.masterId, item.masterName, setCategory, setSearchText]);
  return (
    <li
      id={`patients_documents_${item.masterName}`}
      onClick={handleCategoryChange}
      className={classNames({
        active: Number(categoryId) === item.masterId && !searchText,
      })}
      role="presentation"
      key={item.masterName}
    >
      <span className="list-icon sprite-img" />
      <p>{`${item.masterName} (${item.count || 0})`}</p>
    </li>
  );
};

const setCategoryAndCategories = ({
  categoryCount, categoryData, setCategories, setCategory, categoryId,
}) => {
  if (categoryCount) {
    const { result = [] } = categoryCount;
    const selected = { id: categoryData[0].masterId, name: categoryData[0].masterName };
    const newCategories = getNewCategory({ categoryData, result, selected });
    setCategories(newCategories);
    // set latest modified selected
    if (categoryId !== selected.id) {
      setCategory({ newCategoryId: selected.id, name: selected.name });
    }
  } else {
    setCategories(categoryData);
    // set first selected
    if (categoryId !== categoryData[0].masterId) {
      setCategory({
        newCategoryId: categoryData[0].masterId,
        name: categoryData[0].masterName,
      });
    }
  }
};

const CategoryList = ({
  setCategory,
  categoryId,
  searchText,
  setSearchText,
  categoryCount,
  categoryCountLoading,
  categoryData,
  categoryDataLoading,
}) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (categoryData && categoryData.length) {
      setCategoryAndCategories({
        categoryCount, categoryData, setCategories, setCategory, categoryId,
      });
    }
  }, [categoryData, categoryCount]);

  return (
    <>
      {(categoryDataLoading || categoryCountLoading) ? (
        <div className="loader">
          <Spinner size="large" />
        </div>
      ) : null}
      <div className="category-header">
        <p className="title">Category</p>
      </div>
      <div className="category-list">
        <ul>
          {categories.map((item) => (
            <Category
              key={item?.masterName}
              item={item}
              setCategory={setCategory}
              categoryId={categoryId}
              searchText={searchText}
              setSearchText={setSearchText}
            />
          ))}
        </ul>
      </div>
    </>
  );
};

export default CategoryList;
