/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo } from 'react';
import { Form as AntdForm } from 'antd';
import uniqBy from 'lodash/uniqBy';

import SelectBox from '../../components/Form/SelectBox';
import WiredSelect from '../Select/selectBoxV2';

import WiredModifier from '../ModifierCptSearch';
import { apiUrls } from '../../api/constants';
import { RULES_ENGINE_HIGHLIGHT_KEYS, listIds } from '../../lib/constants';
import useCRUDWithoutTab from '../../hooks/useCRUDWithoutTab';
import RcmWrapper from '../../hoc/withRCM/RcmWrapper';
import { getRulesEngineOperandByKey } from '../AddNewEncounter/Components/RulesEngine/RulesEngineEncounter';

const getModifiersAccordingtoParent = (ODOSModifierOptions, data) => uniqBy(data?.reduce(
  (acc, element) => {
    if (element?.modifierCode === '50' || element?.modifierCode === 50) {
      const ltPair = ODOSModifierOptions?.action?.find((item) => item?.modifierCode.toLowerCase() === 'lt');
      const rtPair = ODOSModifierOptions?.action?.find((item) => item?.modifierCode.toLowerCase() === 'rt');
      if (ltPair && rtPair) {
        acc.push(
          { description: ltPair?.description, modifierId: ltPair?.modifierId },
          { description: rtPair?.description, modifierId: rtPair?.modifierId },
        );
      }
    }
    if (ODOSModifierOptions?.action?.find((item) => item?.modifierId === element?.modifierId)) {
      acc.push({ description: element.description, modifierId: element.modifierId });
    }
    return acc;
  }, []), 'modifierId');

const ModifierGroup = ({
  // selectedProcedureCPT,
  modifier1Props,
  modifier2Props,
  modifier3Props,
  modifier4Props,
  Component,
  isFirstAutocomplete,
  cptCode,
  isJcode,
  associatedParentModifiersForJcode,
  finalRcmHighlightData,
  showRcmHighlight,
}) => {
  const [ODOSModifierOptions] = useCRUDWithoutTab({ id: listIds.GET_MODIFIER_OPTIONS, type: 'read' });
  const [
    response, ,, get,
  ] = useCRUDWithoutTab({
    id: apiUrls.GET_MODIFIERS,
    url: `${apiUrls.GET_MODIFIERS}?PageSize=1200`,
    type: 'read',
  });

  const parser = useCallback((options, data) => {
    if (isJcode && associatedParentModifiersForJcode?.length) {
      return getModifiersAccordingtoParent(ODOSModifierOptions, associatedParentModifiersForJcode);
    }
    /** check for mips code requirement */
    if (cptCode === '92310' || cptCode === '92072') {
      return [...(data?.action || []), {
        description: '22',
        isActive: true,
        modifierCode: '22',
        modifierId: 515,
        modifierMappingId: 0,
      }];
    }
    return data?.action || [];
  }, [ODOSModifierOptions, associatedParentModifiersForJcode, cptCode, isJcode]);

  useEffect(() => {
    get();
  }, []);

  const getModifierPositionValue = useMemo(() => {
    if (!Array.isArray(finalRcmHighlightData)) {
      return undefined;
    }
    const foundItem = finalRcmHighlightData.find((item) => item.operands.some((operand) => operand?.attributes?.Position !== undefined));
    const operandWithAttributes = foundItem?.operands?.find(
      (operand) => operand.attributes.Position !== undefined,
    );
    return operandWithAttributes?.attributes?.Position;
  }, [finalRcmHighlightData]);

  const doesPositionMatch = useCallback((positionValue) => {
    if (getRulesEngineOperandByKey(finalRcmHighlightData, RULES_ENGINE_HIGHLIGHT_KEYS.MODIFIERS_POSITION)) {
      return getModifierPositionValue !== undefined && getModifierPositionValue === positionValue;
    }
    return undefined;
  }, [getModifierPositionValue, finalRcmHighlightData]);

  return (
    <div>
      <div className="flex modifiers-box">
        <span>
          {isFirstAutocomplete ? (
            <RcmWrapper isVisible={showRcmHighlight && doesPositionMatch(1)} finalRcmHighlightData={getRulesEngineOperandByKey(finalRcmHighlightData, RULES_ENGINE_HIGHLIGHT_KEYS.MODIFIERS_POSITION)}>
              <WiredModifier
                defaultOptions={response}
                cptCode={cptCode}
                name="modifier1"
                required
                {...modifier1Props}
              />
            </RcmWrapper>
          ) : (
            <RcmWrapper isVisible={showRcmHighlight && doesPositionMatch(1)} finalRcmHighlightData={getRulesEngineOperandByKey(finalRcmHighlightData, RULES_ENGINE_HIGHLIGHT_KEYS.MODIFIERS_POSITION)}>
              <WiredSelect
                Component={Component}
                url={apiUrls.GET_MODIFIER_OPTIONS}
                id={listIds.GET_MODIFIER_OPTIONS}
                nameAccessor="description"
                valueAccessor="modifierId"
                name="modifier1"
                required
                labelSpan="0"
                inputSpan="20"
                placeholder="Code"
                selectProps={{
                  style: { maxWidth: 90, minWidth: 80 },
                }}
                parser={parser}
                shouldShowWarningText={false}
                isStatCaseOptions={false}
                defaultSorting={false}
                {...modifier1Props}
              />
            </RcmWrapper>
          )}
        </span>
        <span className="mr-lt-12">
          <RcmWrapper isVisible={showRcmHighlight && doesPositionMatch(2)} finalRcmHighlightData={getRulesEngineOperandByKey(finalRcmHighlightData, RULES_ENGINE_HIGHLIGHT_KEYS.MODIFIERS_POSITION)}>
            <AntdForm.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => (
                prevValues.modifier1 !== currentValues.modifier1)}
            >
              {({ getFieldValue }) => (
                <WiredModifier
                  cptCode={cptCode}
                  defaultOptions={response}
                  name="modifier2"
                  {...modifier2Props}
                  disabled={modifier2Props?.isFormItem === false ? modifier2Props?.disabled
                    : (!getFieldValue(modifier1Props?.name || 'modifier1') && !getFieldValue(modifier2Props?.name || 'modifier2')) || modifier2Props?.disabled}
                />
              )}
            </AntdForm.Item>
          </RcmWrapper>
        </span>
        <span className="mr-lt-12">
          <RcmWrapper isVisible={showRcmHighlight && doesPositionMatch(3)} finalRcmHighlightData={getRulesEngineOperandByKey(finalRcmHighlightData, RULES_ENGINE_HIGHLIGHT_KEYS.MODIFIERS_POSITION)}>
            <AntdForm.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => (
                prevValues.modifier2 !== currentValues.modifier2)}
            >
              {({ getFieldValue }) => (
                <WiredModifier
                  cptCode={cptCode}
                  defaultOptions={response}
                  name="modifier3"
                  {...modifier3Props}
                  disabled={modifier3Props?.isFormItem === false ? modifier3Props?.disabled
                    : (!getFieldValue(modifier2Props?.name || 'modifier2') && !getFieldValue(modifier3Props?.name || 'modifier3')) || modifier3Props?.disabled}
                />
              )}
            </AntdForm.Item>
          </RcmWrapper>
        </span>
        <span className="mr-lt-12">
          <RcmWrapper isVisible={showRcmHighlight && doesPositionMatch(4)} finalRcmHighlightData={getRulesEngineOperandByKey(finalRcmHighlightData, RULES_ENGINE_HIGHLIGHT_KEYS.MODIFIERS_POSITION)}>
            <AntdForm.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => (
                prevValues.modifier3 !== currentValues.modifier3)}
            >
              {({ getFieldValue }) => (
                <WiredModifier
                  cptCode={cptCode}
                  defaultOptions={response}
                  name="modifier4"
                  {...modifier4Props}
                  disabled={modifier4Props?.isFormItem === false ? modifier4Props?.disabled
                    : (!getFieldValue(modifier3Props?.name || 'modifier3') && !getFieldValue(modifier4Props?.name || 'modifier4')) || modifier4Props?.disabled}
                />
              )}
            </AntdForm.Item>
          </RcmWrapper>
        </span>
      </div>
    </div>
  );
};

ModifierGroup.defaultProps = {
  modifier1: {},
  modifier2: {},
  modifier3: {},
  modifier4: {},
  showRcmHighlight: false,
  Component: SelectBox,
};

export default React.memo(ModifierGroup);
