import React from 'react';
import { Dropdown } from 'antd';
import EventWrapper from '../../../components/EventWrapper';

const IconsWithDropDown = (props) => {
  const {
    overlay, placement, trigger, iconProps,
  } = props;
  return (
    <EventWrapper
      type={iconProps?.type}
      className="dropdown-with-icon"
    >
      <div>
        <span
          role="presentation"
          title={iconProps?.title}
          id={iconProps?.id}
          className={iconProps?.className}
          onClick={iconProps?.handleClick}
        />
        <Dropdown
          overlay={overlay}
          placement={placement}
          trigger={trigger}
        >
          <EventWrapper
            type="li"
            title={`${iconProps?.title} DropDown`}
            id="social-menu-item"
          />
        </Dropdown>
      </div>
    </EventWrapper>
  );
};
export default IconsWithDropDown;
