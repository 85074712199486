import React, { useCallback } from 'react';
import Label from '../../../../../../components/Label';

const AmountAndBalance = ({
  financialDetail = {},
  insuranceAdjustment = false,
  isReversalForm,
}) => {
  const negativeAmountCheck = useCallback((amount) => {
    if (isReversalForm && `${amount}`.includes('-')) {
      return `(${parseFloat(`${amount}`.replace('-', '')).toFixed(2)})`;
    }
    return parseFloat(amount).toFixed(2);
  }, [isReversalForm]);
  return (
    <>
      <div className="input-wrap right-align-financial-charge">
        <div className="label-wrap">
          <Label label={insuranceAdjustment ? 'Adjustment Amount' : 'Payment Amount'} />
          :
        </div>
        <div className="name-view">
          {negativeAmountCheck(financialDetail?.amount) || (0).toFixed(2)}
        </div>
      </div>
      <div className="input-wrap right-align-financial-charge">
        <div className="label-wrap">
          <Label label="Un-Applied Credit" />
          :
        </div>
        <div className="name-view">{(financialDetail?.balance || 0).toFixed(2)}</div>
      </div>
    </>
  );
};

export default AmountAndBalance;
