import moment from 'moment';
import toLower from 'lodash/toLower';
import lowerCase from 'lodash/lowerCase';
import uniqBy from 'lodash/uniqBy';
import pick from 'lodash/pick';
import range from 'lodash/range';
import isNaN from 'lodash/isNaN';
import { v4 as uuidv4 } from 'uuid';
import words from 'lodash/words';
import { apiUrls } from '../api/constants';
import { enumMasterCode } from './constants';
import api from '../api';
import ErrorMessages from './errorMessages';
import Notification from '../components/Notification';

const typeExtractor = (fileTypeCheck) => {
  let fileType = null;
  if (fileTypeCheck) {
    const type = toLower(fileTypeCheck);
    if (type.indexOf('pdf') > -1) {
      fileType = 'PDF';
    } else if (type.indexOf('jpeg') > -1) {
      fileType = 'JPEG';
    } else if (type.indexOf('jpg') > -1) {
      fileType = 'JPG';
    } else if (type.indexOf('png') > -1) {
      fileType = 'PNG';
    }
  }
  return fileType;
};

function retry(
  fn,
) {
  return new Promise((resolve) => {
    fn()
      .then(resolve)
      .catch(() => {
        window.location.reload();
      });
  });
}

function getTimeString(yearsInt, monthsInt, weeksInt, daysInt) {
  const years = parseInt(yearsInt || 0, 10);
  const months = parseInt(monthsInt || 0, 10);
  const weeks = parseInt(weeksInt || 0, 10);
  const days = parseInt(daysInt || 0, 10);
  let timeString = '';
  if (years > 1) {
    timeString = `${years} years`;
  } else if (years > 0) {
    timeString = `${years} year`;
  }

  if (months > 1) {
    timeString += ` ${months} months`;
  } else if (months > 0) {
    timeString += ` ${months} month`;
  }

  if (weeks > 1) {
    timeString += ` ${weeks} weeks`;
  } else if (weeks > 0) {
    timeString += ` ${weeks} week`;
  }

  if (days > 1) {
    timeString += ` ${days} days`;
  } else if (days > 0) {
    timeString += ` ${days} day`;
  }
  return timeString.trim();
}

const isInputExistInMap = (input, itemsKeys, item = {}) => {
  const mapKeys = itemsKeys || Object.keys(item || {});
  let isExist = false;
  // eslint-disable-next-line no-unused-expressions
  mapKeys?.some((ele, index) => {
    if (lowerCase(item[mapKeys[index]]).indexOf(lowerCase(input)) > -1) {
      isExist = true;
    }
  });
  return isExist;
};

const getName = (fName, mName, lName) => {
  const name = [];
  if (fName) {
    name.push(fName);
  }
  if (mName) {
    name.push(mName);
  }
  if (lName) {
    name.push(lName);
  }
  return name.join(' ');
};

const getString = (data, joinBy = ' ') => {
  const name = [];
  // eslint-disable-next-line no-unused-expressions
  data?.forEach((item) => {
    if (item) {
      name.push(item);
    }
  });
  return name.join(joinBy);
};

const typeObj = {
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  png: 'image/png',
};

const contentTypeCalculator = (imageType) => {
  if (!imageType) return false;
  const type = imageType.toLowerCase();
  return typeObj[type] || type;
};

const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = (`${phoneNumberString}`).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return phoneNumberString ?? '';
};

const getInitials = (name) => {
  if (Array.isArray(name)) {
    // eslint-disable-next-line no-param-reassign
    name = getString(name);
  }

  const initials = name.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g);

  if (name) {
    return initials.join('').toUpperCase();
  }

  return initials;
};

const encodeUserSettings = (filters = {}) => {
  let parsedFilter = filters;
  if (typeof filters === 'string') {
    try {
      parsedFilter = JSON.parse(filters);
    } catch (settingsParseError) {
      console.error('encodeUserSettings | settingsParseError', settingsParseError);
      return filters;
    }
  }
  parsedFilter.lastModified = moment().unix();
  return JSON.stringify(parsedFilter);
};

const decodeUserSettings = (settings, encode = false) => {
  if (!settings) return encode ? '' : {};
  let parsedFilter = {};
  if (typeof settings === 'string') {
    try {
      parsedFilter = JSON.parse(settings);
    } catch (settingsParseError) {
      console.error('decodeUserSettings | settingsParseError', settingsParseError);
      return encode ? '' : {};
    }
  } else parsedFilter = { ...settings };
  if (encode) {
    delete parsedFilter.lastModified;
    return Object.entries(parsedFilter).filter(([, val]) => val && val !== 'undefined').map(([key, val]) => {
      if (Array.isArray(val)) {
        return [key, window.escape(val.join(','))].join('=');
      }
      return [key, val].map(window.escape).join('=');
    }).join('&');
  }
  return parsedFilter;
};

const extractNumber = (number) => {
  const numberPattern = /\d+/g;
  return number?.toString().trim().match(numberPattern).join('');
};

const isAuthorized = (roles) => {
  let loggedInUserRole = null;
  try {
    const { roleName } = JSON.parse(localStorage.getDecryptedData('user') || '{}');
    loggedInUserRole = (roleName || '').toLowerCase();
  } catch {
    return false;
  }
  if (loggedInUserRole) {
    const rolesArray = Array.isArray(roles) ? roles : [roles];
    return rolesArray.some((role) => role === loggedInUserRole);
  }
  return false;
};

const updateUserRole = async () => {
  try {
    const token = localStorage.getDecryptedData('token');
    const userData = (await api.get({ url: apiUrls.GET_USER_PROFILE, token })) || {};
    let user = localStorage.getDecryptedData('user');
    user = JSON.parse(user);
    user.roleName = userData?.roleName;
    user.roleId = userData?.roleId;
    localStorage.setEncryptedData('user', JSON.stringify(user));
    localStorage.setEncryptedData('user-profile', JSON.stringify((userData || {})));
    return { roleName: userData?.userName, roleId: userData?.roleId };
  } catch (e) {
    console.warn(e);
  }
  return {};
};

const getLoggedInUserId = () => {
  let user = localStorage.getDecryptedData('user');
  user = JSON.parse(user);
  return user?.userId;
};

const urltoFile = (url, filename, mimeType) => {
  // eslint-disable-next-line no-param-reassign
  mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1];
  return (fetch(url)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], filename, { type: mimeType }))
  );
};

const openUrlInNewTab = (url, pageNumber) => {
  let firstPage = pageNumber;
  if (typeof pageNumber === 'string') {
    const pageNumbers = pageNumber.split(/,|-/).map((num) => parseInt(num, 10));
    firstPage = Math.min(...pageNumbers);
  }
  let newWindow;
  if (pageNumber) {
    newWindow = window.open(`${url}#page=${firstPage}`, '_blank');
  } else {
    newWindow = window.open(url, '_blank');
  }
  if (newWindow) {
    newWindow.focus();
  } else {
    Notification({ message: 'Popup blocked, please allow popups for this site', success: false });
  }
};

const fileNameFromUrl = (url) => {
  const matches = url.match(/\/([^/?#]+)[^/]*$/);
  if (matches?.length > 1) {
    return matches[1];
  }
  return null;
};

const getRandomNumber = () => {
  const crypto = window.crypto || window.msCrypto || { getRandomValues: () => [1.11] };
  const array = new Uint32Array(1);
  crypto.getRandomValues(array); // Compliant for security-sensitive use cases
  return array[0];
};

const JArray = JSON.parse('[{"Key": "Prompt1", "Type": "DropDown", "Lable": "J-code", "Order": "1", "Default": "", "Options": [{"Units": "1", "Dosage": "1.25 mg", "J-code": "J9035", "Abbreviation": "IVA", "Medication name": "Avastin"}, {"Units": "3", "Dosage": "0.3 mg", "J-code": "J2778", "Abbreviation": "IVL", "Medication name": "Lucentis"}, {"Units": "4", "Dosage": "4 mg", "J-code": "J3301", "Abbreviation": "IVK", "Medication name": "Kenalog"}, {"Units": "2", "Dosage": "2 mg", "J-code": "J0178", "Abbreviation": "?", "Medication name": "Eylea"}, {"Units": "19", "Dosage": "0.19 mg", "J-code": "J7313", "Abbreviation": "?", "Medication name": "Illuvien"}, {"Units": "1", "Dosage": "0.125 mg", "J-code": "J7316", "Abbreviation": "?", "Medication name": "Jetrea"}, {"Units": "7", "Dosage": "0.7 mg", "J-code": "J7312", "Abbreviation": "?", "Medication name": "Ozurdex"}, {"Units": "40", "Dosage": "4 mg", "J-code": "J3300", "Abbreviation": "?", "Medication name": "Triesence"}], "IsNested": "0"}, {"Key": "Prompt2", "Type": "TextBox", "Lable": "Lot #", "Order": "2", "Default": "", "Options": [], "IsNested": "0"}, {"Key": "Prompt3", "Type": "TextBox", "Lable": "Expiration", "Order": "3", "Default": "", "Options": [], "IsNested": "0"}, {"Key": "Prompt4", "Type": "TextBox", "Lable": "Dosage", "Order": "4", "Default": "", "Options": [], "IsNested": "0"}, {"Key": "Prompt5", "Type": "TextBox", "Lable": "Units", "Order": "5", "Default": "", "Options": [], "IsNested": "0"}]');

const crudSelector = (state, id, type) => {
  if (!id) {
    return null;
  }
  if (state.crud) {
    if (state.crud.get('currentTab')) {
      return state.crud.get(state.crud.get('currentTab')) && state.crud.get(state.crud.get('currentTab'))
        .get(id) && state.crud.get(state.crud.get('currentTab'))
        .get(id)
        .get(type);
    }
    return state.crud.get(id) && state.crud.get(id).get(type);
  }
  return true;
};

const getUTCDate = (date, format) => (date ? moment.utc(date).local().format(format || 'MM/DD/YY') : '');
const getUTCDateForImaging = (date, format) => (date ? moment.utc(date).format(format || 'MM/DD/YY') : '');

const getUTCTimeZoneDate = (date) => (date ? moment.utc(date) : '');

const getSystemDate = (date) => (date ? moment.utc(date).local().format('MM-DD-YYYY HH:mm:ss') : '');

const getDateWithZeroTimeFormat = (date, format) => (date ? moment(date).format(`${format || 'YYYY-MM-DD'} 00:00:00`) : '');

const getuuid = () => uuidv4();

const saveFilterInLocalStorage = (fieldName, fieldData) => {
  const localStorageData = JSON.parse(localStorage.getDecryptedData('userSettingsData')) || {};
  const { appliedFilters = {} } = localStorageData;
  appliedFilters[fieldName] = fieldData;
  localStorageData.appliedFilters = appliedFilters;
  localStorage.setEncryptedData('userSettingsData', JSON.stringify(localStorageData));
};

const getLoggedInProviderId = () => {
  const user = localStorage.getDecryptedData('user');
  const { providerDetail } = JSON.parse(user) || {};
  const { providerId: loggedInProvider } = providerDetail || {};
  return loggedInProvider;
};

const cptWithSixCodes = ['64612', '67500', '67515', '66030', '67028'];

const parseResult = (item) => ({
  examId: item?.examId,
  eyeType: item?.eyeType,
  result: JSON.parse(item.result)?.map((slitItem) => pick(slitItem, ['Key', 'Answers'])),
});

const sortOrder = { assessment: 1, plan: 2, notes: 3 };

const customSort = (data) => data?.sort(
  (a, b) => sortOrder[a?.noteType?.toLowerCase()] - sortOrder[b?.noteType?.toLowerCase()]
);

const getAbbreviatedString = (string) => {
  const trimmedString = string?.trim();
  if (trimmedString?.length) {
    return words(trimmedString).reduce((acc, item) => {
      if (item?.length) {
        // eslint-disable-next-line no-param-reassign
        acc += item[0]?.toUpperCase();
      }
      return acc;
    }, '');
  }
  return '';
};

const isDate = (date) => !!Date.parse(date);

const formatAmount = (amount) => (
  amount < 0 ? `-$${(-amount || 0).toFixed(2)}` : `$${(amount || 0).toFixed(2)}`
);

const lengthCheck = (lenValue,
  errorMessage = ErrorMessages.VALUE_CANNOT_BE_LONGER_THAN_4_DIGITS,
  isMax = true, decimalCheck = false, beforeDecimalCheck = false,
  beforeDecimal = 1, decimalPlaces = 2) => ({
  validator(rules, value) {
    const valueLength = `${value}`?.trim()?.length;
    if (!value || !valueLength) return Promise.resolve();
    if (beforeDecimalCheck) {
      const decimalIndex = `${value}`?.indexOf('.');
      if (decimalIndex > beforeDecimal) {
        return Promise.reject(ErrorMessages.VALUE_BEFORE_DECIMAL_PLACES);
      }
    }
    if (decimalCheck) {
      const decimalIndex = `${value}`?.indexOf('.');
      if (decimalIndex !== -1 && `${value}`?.slice(decimalIndex + 1,).length > decimalPlaces) {
        // eslint-disable-next-line max-len
        return Promise.reject(ErrorMessages.VALUE_AFTER_DECIMAL_PLACES_CANNOT_BE_GREATER(decimalPlaces));
      }
    }
    if (valueLength <= lenValue && isMax) {
      return Promise.resolve();
    }
    if (valueLength >= lenValue && !isMax) {
      return Promise.resolve();
    }
    return Promise.reject(errorMessage);
  },
});

const isValueUptoTwoDecimalPlaces = (val, regex = /^-?[0-9]*\.?(?:\d{1,2})?$/) => `${val}`?.match(regex);

const disableDatesFilter = (valueFrom, valueTo, isGreaterThan = true) => {
  if (valueTo && isGreaterThan) { return valueFrom >= valueTo; }
  if (valueTo) { return valueFrom <= valueTo; }
  return false;
};

const isDecimal = (number, regex = /^\d*\.\d{0,2}$/) => number.match(regex);

const isPageRange = (fieldValue = '', label, totalNoOfPages) => ({
  validator() {
    const errorMessage = `Please enter a valid ${label}`;
    const regex = /^[0-9,-]*$/;
    const value = `${fieldValue}`;
    if (value.includes('-,') || value.includes(',-') || value.includes(',,') || value.includes('--')) {
      return Promise.reject(errorMessage);
    }
    if (value[0] === ',' || value[0] === '-') {
      return Promise.reject(errorMessage);
    }
    if (value[value.length - 1] === ',' || value[value.length - 1] === '-') {
      return Promise.reject(errorMessage);
    }
    if (value.split(',').some((item) => item.split('-').length > 2)) {
      return Promise.reject(errorMessage);
    }
    if (value.split(',').some((item) => item.split('-').some((element) => parseInt(element, 10) > totalNoOfPages || parseInt(element, 10) < 1))) {
      return Promise.reject(errorMessage);
    }
    if (regex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(errorMessage);
  },
});

const getShieldIconClassName = (status = '') => {
  let className = '';
  if (status === 'Verified') {
    className = 'green-shield-image';
  } else if (status === 'Not verified') {
    className = 'orange-shield-image';
  } else if (status === 'Verified with issues') {
    className = 'red-shield-image';
  } else if (status === 'Verification in progress') {
    className = 'hour-glass-shield-image';
  }
  return className;
};

const VerificationStatusForTooltip = {
  Verified: 'Verified',
  'Not verified': 'Not Verified',
  'Verified with issues': 'Verified with issue',
  'Verification in progress': 'Verification in Progress',
};

const getIsPolicyTypeRequireAuthorization = (policyTypeCode) => {
  const { HMO, MedicaidHMO, MedicareHMO } = enumMasterCode;
  return [HMO, MedicaidHMO, MedicareHMO].includes(policyTypeCode);
};

const getIsInjectionAuthNeeded = (policyTypeCode) => {
  const {
    BlueCrossBlueShield, Capitation,
    Champus, CommercialInsurance, IndemnityInsurance,
  } = enumMasterCode;
  return [
    BlueCrossBlueShield, Capitation, Champus,
    CommercialInsurance, IndemnityInsurance,
  ].includes(policyTypeCode);
};

const getNumberListFromRangeString = (numbers) => {
  const numbersSplit = `${numbers}`?.split(',');
  const convertedNumbers = new Set();
  numbersSplit?.forEach((strNumber) => {
    if (strNumber?.includes('-')) {
      // delimited by range
      const splitRange = strNumber?.split('-');
      if (splitRange?.length === 2 && !isNaN(splitRange?.[0]) && !isNaN(splitRange?.[1])) {
          range(parseInt(splitRange?.[0], 10), parseInt(splitRange?.[1], 10) + 1)?.forEach((item) => convertedNumbers.add(`${item}`));
      }
    } else {
      convertedNumbers.add(strNumber);
    }
  });
  return Array.from(convertedNumbers);
};

const getPageNumbers = (lineItems) => {
  const pageNumbers = [];
  lineItems?.forEach((item) => {
    if (item?.pageNumber?.length) {
      const parsedNumberString = item?.pageNumber?.split(',');
      for (let i = 0; i < parsedNumberString?.length; i += 1) {
        if (parsedNumberString[i]?.includes('-')) {
          const rangeNumbers = parsedNumberString[i]?.split('-');
          if (parseInt(rangeNumbers[0], 10) < parseInt(rangeNumbers[1], 10)) {
            for (let number = parseInt(rangeNumbers[0], 10);
              number <= parseInt(rangeNumbers[1], 10);
              number += 1) {
              pageNumbers.push(`${number}`);
            }
          } else {
            for (let number = parseInt(rangeNumbers[0], 10);
              number >= parseInt(rangeNumbers[1], 10);
              number -= 1) {
              pageNumbers.push(`${number}`);
            }
          }
        } else {
          pageNumbers.push(parsedNumberString[i]);
        }
      }
    }
  });
  return pageNumbers;
};

const compareStartTimeGreaterOrEqualToEndTime = (startTime, endTime) => {
  const start = new Date(startTime);
  const end = new Date(endTime);
  const startHours = start?.getHours();
  const startMinutes = startHours * 60 + start?.getMinutes();
  const endHours = end?.getHours();
  const endMinutes = endHours * 60 + end?.getMinutes();
  return startMinutes >= endMinutes;
};

const getDifferenceInMinute = (startTime, endTime) => {
  const start = new Date(startTime);
  const end = new Date(endTime);
  const startHours = start?.getHours();
  const startMinutes = startHours * 60 + start?.getMinutes();
  const endHours = end?.getHours();
  const endMinutes = endHours * 60 + end?.getMinutes();
  return endMinutes - startMinutes;
};

const disableDates = (effectiveToDate, effectiveFromDate, current) => {
  if (effectiveToDate) {
    return !(moment(effectiveFromDate).isBefore(current)
     && (moment(effectiveToDate).isAfter(current) || moment(moment(effectiveToDate).format('YYYY-MM-DD')).isSame(moment(current).format('YYYY-MM-DD'))));
  }
  return !(moment(effectiveFromDate).isBefore(current));
};

const noSpecialCharacterAllowed = (value) => value?.match(/^\d*$/);

const getDateString = (date) => date?.split('T')?.[0];
/**
 * getBase64DataFromPDFjsDocument function is being used for get base 64 data
 */
const getBase64DataFromPDFjsDocument = async (data) => {
  // Use a FileReader to generate a base64 data URI
  const base64url = await new Promise((readerFunction) => {
    const reader = new FileReader();
    reader.onload = () => readerFunction(reader.result);
    reader.readAsDataURL(new Blob([data]));
  });

  /*
  The result looks like
  "data:application/octet-stream;base64,<base64 data>",
  so we split off the beginning:
  */
  return base64url.split(',', 2)[1];
};

const getInsuranceProfileRelationDetails = (relationOptions = [], selectedRelationValue) => {
  if (relationOptions?.length) {
    return relationOptions?.find((item) => item.masterId === selectedRelationValue);
  }
  return {};
};

const negativeAmountCheck = (amount, isReversalForm = false) => {
  if (isReversalForm && `${amount}`.includes('-')) {
    return `(${parseFloat(`${amount}`.replace('-', '')).toFixed(2)})`;
  }
  return parseFloat(amount).toFixed(2);
};

const getVisionODValidation = ({ getFieldValue }) => ({
  validator() {
    const leftItem = getFieldValue('LeftItem');
    const rightItem = getFieldValue('RightItem');
    if (!rightItem && !leftItem) {
      return Promise.reject(ErrorMessages.VISION_FORM_OD_OS_REQUIRED_VALIDATION);
    }
    if (leftItem && (parseInt(leftItem, 10) < 5 || parseInt(leftItem, 10) > 800)) {
      return Promise.reject(ErrorMessages.VISION_FORM_OD_VALUE_RANGE_VALIDATION);
    }
    return Promise.resolve();
  },
});

const getVisionOSValidation = ({ getFieldValue }) => ({
  validator() {
    const leftItem = getFieldValue('LeftItem');
    const rightItem = getFieldValue('RightItem');
    if (!rightItem && !leftItem) {
      return Promise.reject(ErrorMessages.VISION_FORM_OD_OS_REQUIRED_VALIDATION);
    }
    if (rightItem && (parseInt(rightItem, 10) < 5 || parseInt(rightItem, 10) > 800)) {
      return Promise.reject(ErrorMessages.VISION_FORM_OS_VALUE_RANGE_VALIDATION);
    }
    return Promise.resolve();
  },
});

const parseReferringPhysician = (data = {}) => ({
  firstName: data?.firstName,
  middleName: data?.middleName,
  lastName: data?.lastName,
  referringPhysicianNpi: data?.npi,
  address1: data?.addresses?.[0]?.['address_1'],
  address2: data?.addresses?.[0]?.['address_2'],
  city: data?.addresses?.[0]?.city,
  stateCode: data?.addresses?.[0]?.state,
  zip: data?.addresses?.[0]?.['postal_code'],
  primaryPhone: data?.addresses?.[0]?.['telephone_number']
  && Number(data?.addresses?.[0]?.['telephone_number'].split('-').join('')),
  speciality: data?.speciality,
  fax: data?.addresses?.[0]?.['fax_number'],
});

/*
  * Added a validation for wrong decimal expression in refraction form
*/
const invalidDecimalExpression = () => ({
  validator(rules, value) {
    const invalidDecimalRegex = /(\.[0-9][0-9][0-9])/;
    return `${value}`?.match(invalidDecimalRegex) ? Promise.reject(ErrorMessages.VALUE_AFTER_DECIMAL_PLACES_CANNOT_BE_GREATER(2)) : Promise.resolve();
  },
});
const optionParserForDx = (i, data = {}) => (options) => {
  const newOptions = uniqBy(options, 'id');
  if (newOptions.length) {
    const arr = [1, 2, 3, 4];
    return options.reduce((acc, item) => {
      const isValid = arr.every((index) => {
        if (index === i) return true;
        return item?.value !== (data[`diagnosis${index}`] || data[`Diagnosis${index}`]);
      });
      if (isValid) {
        acc.push({ ...item, name: `${item.icdCode} - ${item.description || ''}` });
      }
      return acc;
    }, []);
  }
  return [];
};

const fileSizeCheck = (file, size, showNotification = true) => {
  const fileShouldUpload = (file.size / 1024 / 1024) <= size;
  if (!fileShouldUpload && showNotification) {
    Notification({ message: ErrorMessages.FILE_SIZE_SHOULD_NOT_BE_LARGER_THAN_SIZE(size) });
  }
  return fileShouldUpload;
};

const checkFileUploadStatus = (fileStatus) => {
  if (fileStatus) {
    return true;
  }
  if (fileStatus === false) {
    return 'File is being uploaded';
  }
  return 'File Not Found';
};

function convertToRangeString(arr) {
  if (arr.length <= 0) return 'Pages Not Found';
  arr.sort((a, b) => a - b);
  const result = [];

  let start = parseInt(arr[0], 10);
  let end = start;

  for (let i = 1; i < arr.length; i += 1) {
    const currNum = parseInt(arr[i], 10);
    if (currNum === end + 1) {
      end = currNum;
    } else {
      result.push(start === end ? start.toString() : `${start}-${end}`);
      start = currNum;
      end = currNum;
    }
  }

  result.push(start === end ? start.toString() : `${start}-${end}`);

  return result.join(',');
}

function sortedUniq(arr) {
  return [...new Set(arr)].sort((a, b) => a - b);
}
/*
  * check is line item disable
*/
const checkDisabledLineItem = (stateMasterCode) => stateMasterCode?.toLowerCase() === 'waystarclearinghouse' || stateMasterCode?.toLowerCase() === 'billingstatesubmitted';

const addTimeZoneInDate = (date) => {
  if (!date || date?.includes('Z')) return date;
  return `${date}Z`;
};

const getFeatureState = (features, keyToFind) => {
  const foundItem = features?.find((item) => item.key === keyToFind);
  return foundItem ? foundItem.state : undefined;
};

const updateImageSrc = (
  html,
  elementId,
  dynamicVariable,
  imageBytes,
  useDynamicVariable = false,
) => {
  // Create a DOM parser
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const imgElements = doc.querySelectorAll(`#${elementId}`);

  imgElements.forEach((imgElement) => {
    let newSrc;
    if (useDynamicVariable) {
      newSrc = dynamicVariable;
    } else if (imageBytes) {
      newSrc = imageBytes;
    }

    if (newSrc) {
      // Create a new variable to avoid direct assignment to parameter
      const updatedImgElement = imgElement;
      updatedImgElement.src = newSrc;

      // Find the parent span element
      const parentSpan = updatedImgElement.closest('span');
      if (parentSpan) {
        // Remove the class from the parent span element
        const newSpan = document.createElement('span');
        newSpan.innerHTML = parentSpan.innerHTML;
        parentSpan.replaceWith(newSpan);
      }
    }
  });

  // Serialize the HTML back to a string
  const updatedHtml = doc.body.innerHTML;

  return updatedHtml;
};

export {
  typeExtractor,
  retry,
  getTimeString,
  isInputExistInMap,
  contentTypeCalculator,
  getName,
  formatPhoneNumber,
  getString,
  getInitials,
  encodeUserSettings,
  decodeUserSettings,
  extractNumber,
  isAuthorized,
  updateUserRole,
  getLoggedInUserId,
  JArray,
  urltoFile,
  getRandomNumber,
  crudSelector,
  getuuid,
  getUTCDate,
  getUTCDateForImaging,
  getLoggedInProviderId,
  saveFilterInLocalStorage,
  cptWithSixCodes,
  parseResult,
  customSort,
  getAbbreviatedString,
  isDate,
  formatAmount,
  lengthCheck,
  isValueUptoTwoDecimalPlaces,
  disableDatesFilter,
  isDecimal,
  fileNameFromUrl,
  isPageRange,
  disableDates,
  getShieldIconClassName,
  VerificationStatusForTooltip,
  getIsInjectionAuthNeeded,
  getIsPolicyTypeRequireAuthorization,
  getNumberListFromRangeString,
  getPageNumbers,
  compareStartTimeGreaterOrEqualToEndTime,
  getDifferenceInMinute,
  noSpecialCharacterAllowed,
  getDateString,
  getBase64DataFromPDFjsDocument,
  negativeAmountCheck,
  getVisionODValidation,
  getVisionOSValidation,
  parseReferringPhysician,
  getInsuranceProfileRelationDetails,
  invalidDecimalExpression,
  optionParserForDx,
  getUTCTimeZoneDate,
  fileSizeCheck,
  checkFileUploadStatus,
  convertToRangeString,
  sortedUniq,
  checkDisabledLineItem,
  openUrlInNewTab,
  getDateWithZeroTimeFormat,
  addTimeZoneInDate,
  getFeatureState,
  updateImageSrc,
  getSystemDate,
};
