import React, { useEffect, useCallback, useMemo } from 'react';
import get from 'lodash/get';

import Notification from '../../../../components/Notification';
import Loader from '../../../../components/Loader';

import withQuery from '../../../../hoc/withQuery/withQuery';

import expandedCPTRowRender from './ICDCodeTable';

import { apiUrls } from '../../../../api/constants';
import { listIds } from '../../../../lib/constants';
import successMessages from '../../../../lib/successMessages';
import useCRUD from '../../../../hooks/useCRUD';

import { cptColumns } from './columns';

const hiddenCPTColumns = ['createdAt'];

const CPTCodeTable = (props) => {
  const {
    onAddMappingIconClick, labels, tableContent, isPayerRequestViewOnly,
  } = props;

  const payer = useMemo(() => get(tableContent, 'row.original', {}), [tableContent]);

  const { insurancePayerId: payerId } = payer;

  const [
    deleteICDCode,,
    deleteLoading,
    deleteICDCodeMapping,
    clearDeleteResponse,
  ] = useCRUD({
    id: listIds.SETTING_DEL_ICD_CODE_MAPPING,
    url: apiUrls.DEL_CPT_PAYER_MAPPING,
    type: 'update',
  });

  const onDeleteICD = useCallback((icdId, cptId) => {
    deleteICDCodeMapping({ cptCodeId: cptId, payerId, icdCodes: [icdId] });
  }, [deleteICDCodeMapping, payerId]);

  useEffect(() => {
    if (deleteICDCode && deleteICDCode.message) {
      clearDeleteResponse();
      Notification({ message: successMessages.DELETE_ICD_CODE_SUCCESSFUL, success: true });
    }
  }, [deleteICDCode, clearDeleteResponse]);

  const CustomCPTTable = withQuery({
    url: apiUrls.GET_CPT_PAYER_MAPPING,
    listId: `${listIds.SETTING_GET_CPT_PAYER_MAPPING}_${payerId}`,
    accessor: (data) => ({ result: get(data, 'result[0].cptCodes', []) }),
  })();

  if (deleteLoading) {
    return <Loader />;
  }

  return (
    <CustomCPTTable
      columns={cptColumns(onAddMappingIconClick, labels)}
      renderRowSubComponent={
        expandedCPTRowRender(payerId, labels, onDeleteICD, isPayerRequestViewOnly)
      }
      filters={{ PayerId: payerId }}
      currentPayer={{ payer }}
      hiddenColumns={isPayerRequestViewOnly ? hiddenCPTColumns : []}
    >
      {({ Component }) => Component}
    </CustomCPTTable>
  );
};

export default CPTCodeTable;
