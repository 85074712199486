import React, { useCallback } from 'react';
import { Route, Switch } from 'react-router-dom';

import { labelPaths, UiRoutes } from '../../../../lib/constants';

import WithLabel from '../../../../hoc/withLabel';

import useRedirect from '../../../../hooks/useRedirect';

import RemittanceTable from './Table';

import './remittance.scss';

const Remittance = (props) => {
  const {
    replace, generatePath, params, path,
  } = useRedirect();

  const { labels } = props;

  const routeToTable = useCallback(() => {
    replace(generatePath(UiRoutes.remittanceClaimsFilters, { ...params, errorTab: 'softEdits', claimFilter: 'ready' }));
  }, [generatePath, params, replace]);

  return (
    <Switch>
      <Route path={UiRoutes.collectionClaimsFilters}>
        <RemittanceTable labels={labels} />
      </Route>
      <Route exact path={path}>
        {routeToTable}
      </Route>
    </Switch>
  );
};

export default WithLabel(Remittance,
  labelPaths.REMITTANCE_LIST);
