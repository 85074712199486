import inRange from 'lodash/inRange';

const checkCellsIntersect = (
  leftCellStartTime,
  rightCellStartTime,
  leftCellEndTime,
  rightCellEndTime,
) => (inRange(
  leftCellStartTime,
  rightCellStartTime,
  rightCellEndTime,
)
  || inRange(leftCellEndTime,
    rightCellStartTime + 5,
    rightCellEndTime + 5))
    || (inRange(rightCellStartTime,
      leftCellStartTime,
      leftCellEndTime)
      || inRange(rightCellEndTime,
        leftCellStartTime + 5,
        leftCellEndTime + 5));

export default checkCellsIntersect;
