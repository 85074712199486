import React, { useState, useCallback } from 'react';

import { apiUrls } from '../../../../../api/constants';
import Form from '../../../../../components/Form';
import { Input, CheckBox } from '../../../../../components/Form/inputFields';

import Select from '../../../../../wiredComponents/Select';

import '../../composer.scss';

const SaveForm = ({ labels, isHeader }) => {
  const [isReplacing, setReplacing] = useState(false);

  const onChange = useCallback(({ target: { checked } }) => {
    setReplacing(checked);
  }, []);

  return (
    <Form.Column>
      <Input
        name={isHeader ? 'newHeaderName' : 'newFooterName'}
        labelSpan="8"
        inputSpan="14"
        label={isHeader ? labels.get('labels.newHeaderName') : labels.get('labels.newFooterName')}
        required
      />
      <div className="custom-composer-checkbox">
        <CheckBox
          name={isHeader ? 'replaceExistingHeader' : 'replaceExistingFooter'}
          label={isHeader ? labels.get('labels.replaceExistingHeader') : labels.get('labels.replaceExistingFooter')}
          valuePropName="checked"
          labelSpan="8"
          inputSpan="14"
          onChange={onChange}
        />
      </div>
      {isReplacing
      && (
      <Select
        labelSpan="8"
        inputSpan="14"
        name={isHeader ? 'existingHeader' : 'existingFooter'}
        label={isHeader ? labels.get('labels.existingHeader') : labels.get('labels.existingFooter')}
        disabled={!isReplacing}
        required={isReplacing}
        nameAccessor="name"
        url={`${apiUrls.GET_TEMPLATE_HEADER_FOOTER_LIST}/${isHeader ? 'header' : 'footer'}`}
        valueAccessor="composerTemplateHeaderFooterId"
        id="headerList"
      />
      )}
    </Form.Column>
  );
};

export default SaveForm;
