/* eslint-disable max-len */
import React, { useCallback, useMemo, useState } from 'react';
import { Checkbox } from 'antd';
import Button from '../../../../components/Button';
import { UiRoutes, listIds } from '../../../../lib/constants';

import withQuery from '../../../../hoc/withQuery/withQuery';
import withReduxManager from '../../../../hoc/withReduxManager';

import FilterManager from '../../../../components/FilterManager';
import FilterComponents from '../../../../components/FilterComponents';

import { apiUrls } from '../../../../api/constants';
import useRights from '../../../../hooks/useRights';
import rights from '../../../../lib/rights';
import ChangePasswordModal from '../Components/ChangePasswordModal';
import Link from '../../../../components/SavedNavigationBar/Link';
import TimeCell from '../../../../components/Cells/TimeCell';
import StatusCell from '../../../../components/StatusCell';
import './userTable.scss';

const initialSort = [{ id: 'name', desc: false }];

const TableComponent = withReduxManager({
  listId: 'LOCATION',
})(withQuery({
  url: apiUrls.GET_USERS,
  listId: listIds.SETTINGS_USERS,
})());

const FilterCollection = FilterComponents([
  {
    type: 'filterButton',
    filterProps: {
      placeholder: 'Exclude Inactive Users',
      name: 'ExcludeInactiveUsers',
      id: 'users_filters_exclude_inactive_users',
    },
  },
  {
    type: 'search',
    filterProps: {
      placeholder: 'Name',
      name: 'searchText',
      id: 'users_filters_search',
    },
  },
]);

const UserTable = ({ labels, ...props }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isPasswordModalVisible, setPasswordModalVisible] = useState(false);
  const [isUserSetupAuthenticated, isUserViewOnlyAuthenticated] = useRights(
    [rights.user_setup, rights.user_setup_view_only],
  );
  const isUserPermitted = useMemo(() => (isUserSetupAuthenticated
    || (isUserSetupAuthenticated && isUserViewOnlyAuthenticated)
  ), [isUserSetupAuthenticated, isUserViewOnlyAuthenticated]);

  const toggleReassignModal = useCallback(() => {
    setPasswordModalVisible(!isPasswordModalVisible);
  }, [isPasswordModalVisible]);

  const [
    isChangePasswordAuthenticted,
  ] = useRights(
    [
      rights.bulk_change_password,
    ],
  );

  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const handleSelectAll = useCallback(
    (e, res) => {
      const { checked } = e.target;
      const activeUserIds = res.data
          ?.filter((user) => user.userTypeName === 'FOCUS')
          .map((user) => user.id);

      setSelectedUsers(checked ? activeUserIds : []);
      setSelectAllChecked(checked);
    },
    [],
  );

  const handleSelectUser = useCallback(
    (e, userId) => {
      const { checked } = e.target;
      if (checked) {
        setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, userId]);
      } else {
        setSelectedUsers((prevSelectedUsers) => prevSelectedUsers.filter((id) => id !== userId));
      }
      setSelectAllChecked(false);
    },
    [],
  );

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({
          row: {
            original:
          {
            id, name, firstName, middleName, lastName,
          },
          },
          isPermitted,
        }) => (isPermitted ? (
          <Link
            to={UiRoutes.editUserWithTabId}
            params={{
              id,
              tab: 'general',
              data: { name: name || `${firstName}` },
            }}
          >
            {name || `${firstName} ${middleName || ''} ${lastName} `}
          </Link>
        ) : (
          <span>
            {name || `${firstName} ${middleName || ''} ${lastName} `}
          </span>
        )),
        sort: true,
      },
      {
        Header: 'Username',
        accessor: 'userName',
        Cell: ({ row: { original: { userName, email } } }) => userName || email,
        sort: true,
      },
      {
        Header: 'Last Login',
        accessor: 'lastLogin',
        Cell: ({ row: { original: { lastLogin } } }) => <TimeCell value={lastLogin} />,
        sort: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row: { original: { status } } }) => <StatusCell active={status} />,
      },
      {
        Header: 'Login type',
        accessor: 'type',
        Cell: ({ row: { original: { userTypeName } } }) => (userTypeName || 'AZURE'), // for now we have treated '' to a AZURE user as we don't have AZURE field in db
      },
    ];

    if (isChangePasswordAuthenticted) {
      return [
        {
          Header: (response) => (
            <Checkbox
              onChange={(e) => handleSelectAll(e, response)}
              checked={selectAllChecked}
              indeterminate={
                selectedUsers?.length > 0
                && selectedUsers?.length < response.data?.length
              }
            />
          ),
          accessor: 'selection',
          width: 50,
          Cell: ({ row }) => {
            const { userTypeName, id } = row.original;
            return (
              <Checkbox
                onChange={(e) => handleSelectUser(e, id)}
                checked={selectedUsers?.includes(id)}
                disabled={userTypeName !== 'FOCUS'}
              />
            );
          },
        },
        ...baseColumns,
      ];
    }

    return baseColumns;
  }, [isChangePasswordAuthenticted, handleSelectAll, selectedUsers, selectAllChecked]);

  return (
    <div className="user-table">
      <TableComponent columns={columns} initialSort={initialSort} noDataText="User not found" isPermitted={isUserPermitted} {...props}>
        {({
          Component, initialFilters, onFiltersChange, totalCount,
        }) => (
          <FilterManager initialFilters={initialFilters} onChange={onFiltersChange}>
            {({ onFilterChange, filters }) => (
              <>
                <div className="table-filters">
                  <div className="flex table-filters-wrap">
                    <FilterCollection onFilterChange={onFilterChange} filters={filters} />
                  </div>
                  <span className="table-count">
                    {isChangePasswordAuthenticted && (
                      <Button className="btn btn-success sm-btn" id="users_add" disabled={selectedUsers?.length === 0} onClick={() => toggleReassignModal()}>
                        Change Password
                      </Button>
                    )}
                    <span className="mr-lt-12">
                      {`${labels.get('titles.totalCount')}`}
                      :
                      {' '}
                      <span className="count-value">{`${totalCount}`}</span>
                    </span>
                  </span>
                </div>
                {Component}
              </>
            )}
          </FilterManager>
        )}
      </TableComponent>
      <ChangePasswordModal
        setSelectedUsers={setSelectedUsers}
        toggleModal={toggleReassignModal}
        isVisible={isPasswordModalVisible}
        labels={labels}
        selectedUsers={selectedUsers}
      />
    </div>
  );
};

export default UserTable;
