import React, { useEffect, useState, useMemo } from 'react';

import {
  Form, TreeSelect, Col, Row,
} from 'antd';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import classNames from 'classnames';

import './select.scss';
import EventWrapper from '../EventWrapper';

function TreeSelectBox(props) {
  const {
    options,
    selectedOption,
    labelSpan,
    inputSpan,
    label,
    name,
    value,
    required,
    disabled,
    onChange,
    placeholder,
    selectProps,
    setFirstOptionSelected,
    isFormItem,
    loadData,
    rules,
    dropdownClassName,
    selectNodes,
    dataTestId,
    ...otherProps
  } = props;

  const clonedRules = cloneDeep(rules);

  if (required) {
    clonedRules.push({
      required: true,
      message: `${label} is required`,
    });
  }

  const [initialSet, setInitialSet] = useState(true);

  const selectedNodesList = useMemo(() => (
    Array.isArray(selectNodes) ? selectNodes : [selectNodes]
  ), [selectNodes]);

  const optionsList = useMemo(() => options.map((option) => ({
    ...option,
    className: selectedNodesList.indexOf(option?.id) >= 0 ? classNames('ant-select-tree-node-selected', option.className) : option.className,
  })), [options, selectedNodesList]);

  useEffect(() => {
    if (setFirstOptionSelected && typeof onChange === 'function' && options[0] && initialSet) {
      onChange([0], [{ item: options[0] }]);
      setInitialSet(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, setFirstOptionSelected]);

  const select = (
    <TreeSelect
      placeholder={placeholder}
      dropdownClassName={classNames('tree-select-dropdown-component', dropdownClassName)}
      onChange={onChange || selectedOption}
      loadData={loadData}
      allowClear
      value={value || undefined}
      showArrow
      disabled={disabled}
      name={name}
      treeData={optionsList}
      data-testid={dataTestId || name}
      {...selectProps}
    />
  );

  return (
    <div className="custom-select-box custom-select">
      {isFormItem ? (
        <Row>
          <Col span={labelSpan}>
            <div className="ant-form-item-label ant-form-item-label-left">
              <EventWrapper
                type="label"
                className={classNames('ant-form-item-no-colon')}
                title={label}
              >
                {label}
              </EventWrapper>
              <span className={classNames('req-star', required && 'ant-form-item-required')} />
            </div>
          </Col>
          <Col span={inputSpan}>
            <Form.Item
              name={name}
              rules={[
                ...clonedRules,
              ]}
              {...otherProps}
            >
              {select}
            </Form.Item>
          </Col>
        </Row>
      ) : (
        <>
          <label className="ant-form-item-no-colon">{label}</label>
          {select}
        </>
      )}
    </div>
  );
}

TreeSelectBox.defaultProps = {
  placeholder: '',
  name: '',
  id: 'tree-select-box',
  onChange: null,
  value: undefined,
  filters: {},
  options: [],
  defaultValue: null,
  selectedOption: () => { /* This is intentional */ },
  loadData: () => new Promise((resolve) => resolve()),
  label: '',
  required: false,
  disabled: false,
  selectProps: {},
  setFirstOptionSelected: false,
  isFormItem: true,
  labelSpan: '10',
  inputSpan: '14',
  rules: [],
  dropdownClassName: '',
  selectNodes: [],
};

TreeSelectBox.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  filters: PropTypes.instanceOf(Object),
  options: PropTypes.instanceOf(Array),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  selectedOption: PropTypes.func,
  loadData: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  selectProps: PropTypes.instanceOf(Object),
  setFirstOptionSelected: PropTypes.bool,
  isFormItem: PropTypes.bool,
  labelSpan: PropTypes.string,
  inputSpan: PropTypes.string,
  rules: PropTypes.instanceOf(Array),
  dropdownClassName: PropTypes.string,
  selectNodes: PropTypes.oneOf([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default React.memo(TreeSelectBox);
