import React, { useMemo } from 'react';

import { apiUrls } from '../../../../api/constants';
import { listId } from '../../../../lib/constants';

import withQuery from '../../../../hoc/withQuery/withQuery';
import withReduxManager from '../../../../hoc/withReduxManager';

import FilterManager from '../../../../components/FilterManager';
import FilterComponents from '../../../../components/FilterComponents';

import columns from './columns';
import useRights from '../../../../hooks/useRights';
import rights from '../../../../lib/rights';

const initialSort = [{ id: 'providerName', desc: false }];

const CustomProviderTable = withReduxManager({
  listId: listId.PROVIDERS,
})(
  withQuery({
    url: apiUrls.GET_PROVIDER,
    listId: listId.PROVIDERS,
    alias: {
      providerName: 'providerName',
      modifiedOn: 'ModifiedOn',
    },
  })(),
);

const FilterCollection = FilterComponents([
  {
    type: 'filterButton',
    filterProps: {
      placeholder: 'Exclude inactive providers',
      name: 'ExcludeInactiveProviders',
      id: 'provider_filter_Exclude_Inactive_Providers',
    },
  },
  {
    type: 'search',
    filterProps: {
      placeholder: 'Provider, Modified By',
      name: 'searchText',
      id: 'provider_filter_search_text',
    },
  },
]);

const ProviderTable = ({ labels, ...props }) => {
  const [isProviderViewOnlyAuthenticated, isProvider] = useRights([
    rights.provider_view_only,
    rights.provider,
  ]);

  const isPermitted = useMemo(() => (isProvider || (isProvider && isProviderViewOnlyAuthenticated)
  ), [isProvider, isProviderViewOnlyAuthenticated]);

  return (
    <CustomProviderTable columns={columns(labels, isPermitted)} initialSort={initialSort} noDataText="Provider not found" {...props}>
      {({
        Component, initialFilters, onFiltersChange, totalCount,
      }) => (
        <FilterManager initialFilters={initialFilters} onChange={onFiltersChange}>
          {({ onFilterChange, filters }) => (
            <>
              <div className="table-filters">
                <FilterCollection onFilterChange={onFilterChange} filters={filters} />
                <span className="table-count">
                  <span>{`${labels.get('titles.totalCount')}: ${totalCount}`}</span>
                </span>
              </div>
              {Component}
            </>
          )}
        </FilterManager>
      )}
    </CustomProviderTable>
  );
};

export default ProviderTable;
