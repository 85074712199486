import React from 'react';
import TimeCell from '../../../../../../components/Cells/TimeCell';
import Label from '../../../../../../components/Label';

export const FinancialDetailGroup3 = ({ financialDetail = {} }) => (
  <>
    <div className="input-wrap right-align-financial-charge">
      <div className="label-wrap">
        <Label label="Batch Number" />
        :
      </div>
      <div className="name-view">{financialDetail?.financeDetails?.batchNumber ?? ''}</div>
    </div>
    <div className="input-wrap right-align-financial-charge">
      <div className="label-wrap">
        <Label label="Check Date" />
        :
      </div>
      <div className="name-view"><TimeCell value={financialDetail?.financeDetails?.checkDate} /></div>
    </div>
    <div className="input-wrap right-align-financial-charge">
      <div className="label-wrap">
        <Label label="Check Amount" />
        :
      </div>
      <div className="name-view">{(financialDetail?.financeDetails?.checkAmount || 0).toFixed(2)}</div>
    </div>
  </>
);

export const FinancialDetailWriteOffReason = ({ financialDetail, isUseAdjustmentReason }) => (
  <div className="input-wrap right-align-financial-charge">
    <div className="label-wrap">
      <Label label="Adjustment Type" />
      :
    </div>
    <div className="name-view">{isUseAdjustmentReason ? financialDetail?.adjustmentReason ?? '' : financialDetail?.writeOffReason ?? ''}</div>
  </div>
);
