import CommonColumns from './commonColumns';

const patientAdjustmentColumn = (labels) => [
  ...CommonColumns(labels),
  {
    Header: labels.get('tableColumns.adjusted'),
    accessor: 'adjustment',
    Cell: ({ row: { original: { adjustment } } }) => (
      parseFloat(adjustment || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.enteredBy'),
    accessor: 'enteredBy',
    className: 'text-align-left',
  },
  {
    Header: labels.get('tableColumns.withhold'),
    accessor: 'withHold',
    Cell: ({ row: { original: { withHold } } }) => (
      parseFloat(withHold || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
];

export default patientAdjustmentColumn;
