import React from 'react';

export const FollowupCommon = () => (
  <div>
    <p style={{
      marginBottom: '0', fontSize: '18px', fontWeight: 'bold', color: '#2a4a79',
    }}
    >
      RENAISSANCE ASC
    </p>
    <p style={{
      color: '#2a4a79', fontSize: '12px', padding: '12px 0 0', marginBottom: '12px',
    }}
    >
      44139 Monterey Ave., Ste B
      <br />
      Palm Desert, CA 92260
      <br />
      (T) 760.773.4411
      <br />
      {' '}
      (F) 760.773.2393
    </p>
  </div>
);
export const FollowupTableRow = ({ heading }) => (
  <tr>
    <td style={{ paddingLeft: '48px', paddingTop: '4px' }}>
      {heading}
    </td>
    <td>
      <table>
        <tr>
          <td style={{ paddingLeft: '4px', paddingTop: '4px' }}>
            <input type="checkbox" id="yes1" name="yes1" value="yes1" />
            <label htmlFor="facemayes1sk"> Yes</label>
          </td>
          <td style={{ paddingLeft: '4px', paddingTop: '4px' }}>
            <input type="checkbox" id="No1" name="No1" value="No1" />
            <label htmlFor="No1"> No</label>
          </td>
        </tr>
      </table>
    </td>
  </tr>
);

const FollowupTab = () => (
  <div>
    <div className="main-form-container">
      <FollowupCommon />
      <table border="1" style={{ border: '1px solid #d2ddef', fontSize: '13px' }} cellPadding="0" cellSpacing="0" width="100%">
        <tr style={{ background: '#2e79f8', color: '#fff' }}>
          <td style={{ paddingLeft: '4px' }}>
            Heading If any
          </td>
          <td style={{ paddingLeft: '4px' }}>
            UNKNOWN TEXT
          </td>
        </tr>
        <tr>
          <td style={{ paddingLeft: '4px' }}>
            1.
            {' '}
            <span style={{
              textTransform: 'uppercase', fontWeight: '700', paddingLeft: '30px', display: 'inlineBlock',
            }}
            >
              Patient Satisfaction:
            </span>
          </td>
          <td />
        </tr>
        <FollowupTableRow
          heading="a. Was the staff friendly and helpful?"
        />
        <FollowupTableRow
          heading="b. Was waiting time reasonable?"
        />
        <FollowupTableRow
          heading="c .Were discharge instructions explained and understood?"
        />
        <FollowupTableRow
          heading="d. Was ASC easy to find and locate??"
        />
        <FollowupTableRow
          heading="e. Comment area"
        />
        <tr>
          <td style={{ paddingLeft: '4px' }}>
            2.
            {' '}
            <span style={{
              textTransform: 'uppercase', fontWeight: '700', paddingLeft: '30px', display: 'inlineBlock',
            }}
            >
              Post Op Followup:
            </span>
          </td>
          <td />
        </tr>
        <FollowupTableRow
          heading="f. Any nausea or vomiting?"
        />
        <FollowupTableRow
          heading="g. Are you having any post op discomfort or pain Level (scale 0-10)?"
        />
        <FollowupTableRow
          heading="h. Have you noticed a fever?"
        />
        <FollowupTableRow
          heading="i. Any redness or drainage to the surgical site?"
        />
        <tr>
          <td style={{ paddingLeft: '48px', paddingTop: '4px' }}>
            j. Any other issues or concerns related to your surgery? Comment area
          </td>
          <td>
            <table width="100%">
              <tr>
                <td style={{ paddingLeft: '4px', paddingTop: '4px', paddingBottom: '4px' }}>
                  <input
                    style={{
                      width: '98%', border: '1px solid #d2ddef', padding: '0 4px', marginLeft: '4px',
                    }}
                    type="text"
                  />
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td style={{ paddingLeft: '48px', paddingTop: '4px' }}>
            k. Comment area
          </td>
          <td>
            <table width="100%">
              <tr>
                <td style={{ paddingLeft: '4px', paddingTop: '4px', paddingBottom: '4px' }}>
                  <input
                    style={{
                      width: '98%', border: '1px solid #d2ddef', padding: '0 4px', marginLeft: '4px',
                    }}
                    type="text"
                  />
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

    </div>
  </div>
);

export default FollowupTab;
