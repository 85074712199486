import React, { useMemo } from 'react';
import TimeCell from '../../../../../components/Cells/TimeCell';
import Label from '../../../../../components/Label';

import { dateFormat } from '../../../../../lib/constants';
import dateFormatter from '../../../../../lib/dateFormatter';
import { addTimeZoneInDate } from '../../../../../lib/util';

const DefaultForm = ({ financialDetail, isRefundForm }) => {
  const computedCreatedOn = financialDetail?.createdOn ? `on ${dateFormatter(addTimeZoneInDate(financialDetail.createdOn), dateFormat)}` : '';

  const parsedAmount = useMemo(() => {
    if (isRefundForm && `${financialDetail?.amount || 0}`.includes('-')) {
      return `(${parseFloat(`${financialDetail?.amount || 0}`.replace('-', '')).toFixed(2)})`;
    }
    return parseFloat(financialDetail?.amount || 0).toFixed(2);
  }, [financialDetail, isRefundForm]);

  return (
    <div className="max-width-100 table-layout-field">
      <form className="max-width-100">
        <div className="feild-row justify-content-sp-bt align-center no-top-mr">
          <h2>Financial Details</h2>
        </div>
        <div className="feild-row display-view  group-shadow">
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Amount" />
              :
            </div>
            <div className="name-view">{parsedAmount || (0).toFixed(2)}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Balance" />
              :
            </div>
            <div className="name-view">{(financialDetail?.balance || 0).toFixed(2)}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Entered By" />
              :
            </div>
            <div className="name-view">{`${financialDetail?.enteredBy ?? ''} ${computedCreatedOn}`}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Effective Date" />
              :
            </div>
            <div className="name-view"><TimeCell value={financialDetail?.effectiveDate} /></div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Payment Method" />
              :
            </div>
            <div className="name-view">{financialDetail?.paymentMethod ?? ''}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Payment Source" />
              :
            </div>
            <div className="name-view">{financialDetail?.paymentSource ?? ''}</div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DefaultForm;
