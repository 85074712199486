import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import { listIds, CPTICDSynonymStatus } from '../../../../lib/constants';
import useCRUD from '../../../../hooks/useCRUD';

import Icon from '../../../../components/Icon';
import ConfirmModal from '../../../../components/ConfirmDialog';
import WidgetLoader from '../../../../components/WidgetLoader';
import Notification from '../../../../components/Notification';

import CPTTable from '../Components/CPTTable';
import ICDTable from '../Components/ICDTable';
import AddSynonymModal from '../Components/AddSynonymModal';
import useRights from '../../../../hooks/useRights';
import rights from '../../../../lib/rights';

const CPTICDSynonym = ({
  updateSynonymUrl, deleteSynonymUrl, synonymType, labels, isIcdSynonym,
}) => {
  const createdNewSynonym = useRef(false);

  const [refetch, setRefetch] = useState(() => { /* This is intentional */ });
  const [selectedCPTICD, setSelectedCPTICD] = useState({});
  const [isModalVisible, setModalVisibility] = useState(false);
  const [accessToAcceptRejectSynonym] = useRights([rights.manage_cpt_icd_synonym]);

  const [response, error, updating, updateSynonym, clear] = useCRUD({
    id: listIds.UPDATE_SYNONYM_CPT_ICD,
    url: updateSynonymUrl,
    type: 'update',
    shouldClearError: false,
  });

  const [deleteResponse, deleteError, deleting, deleteSynonym, clearDelete] = useCRUD({
    id: listIds.UPDATE_SYNONYM_CPT_ICD,
    url: deleteSynonymUrl,
    type: 'delete',
    shouldClearError: false,
  });

  const onAddSynonym = useCallback((data = {}) => {
    setSelectedCPTICD({ ...data, code: data?.cptCode || data?.icdCode });
    setModalVisibility(true);
  }, []);

  const onEditSynonym = useCallback((data = {}) => {
    setSelectedCPTICD({ ...data, code: data?.icdCptSynonymId, isEdit: true });
    setModalVisibility(true);
  }, []);

  const onEditCpt = useCallback((data = {}) => {
    setSelectedCPTICD({ ...data, code: data?.icdCptSynonymId, isEdit: true });
    setModalVisibility(true);
  }, []);

  const onDeleteSynonym = useCallback(({ icdCptSynonymId, synonym }) => {
    ConfirmModal({
      onOk: (close) => {
        deleteSynonym({}, `/${icdCptSynonymId}`);
        close();
      },
      okText: 'Delete',
      title: 'Delete synonym?',
      content: `Are you sure you want to delete "${synonym}" synonym?`,
      icon: <Icon name="ExclamationCircleOutlined" />,
    })();
  }, [deleteSynonym]);

  const onAcceptSynonym = useCallback(({ icdCptSynonymId } = {}) => {
    updateSynonym({ status: CPTICDSynonymStatus?.approved, icdCptSynonymId });
  }, [updateSynonym]);

  const onRejectSynonym = useCallback(({ icdCptSynonymId, synonym } = {}) => {
    ConfirmModal({
      onOk: (close) => {
        updateSynonym({ status: CPTICDSynonymStatus?.reject, icdCptSynonymId });
        close();
      },
      okText: 'Reject',
      title: 'Reject synonym?',
      content: `Are you sure you want to reject "${synonym}" synonym?`,
      icon: <Icon name="ExclamationCircleOutlined" />,
    })();
  }, [updateSynonym]);

  useEffect(() => {
    if (response) {
      refetch();
      const { message } = response;
      if (!createdNewSynonym.current) {
        Notification({ message, success: true });
      } else {
        createdNewSynonym.current = false;
      }
      clear();
    }
  }, [response]);

  useEffect(() => {
    if (deleteResponse) {
      refetch();
      const { message } = deleteResponse;
      Notification({ message, success: true });
      clearDelete();
    }
  }, [deleteResponse]);

  useEffect(() => {
    if (error || deleteError) {
      Notification({ message: error || deleteError });
      refetch();
      clear();
    }
  }, [error, deleteError]);

  const toggleAddModal = useCallback((modalState, update) => {
    setModalVisibility(modalState);
    if (update) {
      refetch();
      createdNewSynonym.current = true;
    }
  }, [refetch]);

  return (
    <div className="tab-container">
      {(updating || deleting) && <WidgetLoader />}
      {synonymType === 'CPT' && (
        <CPTTable
          onAddSynonym={onAddSynonym}
          onEditSynonym={onEditSynonym}
          onEditCpt={onEditCpt}
          onDeleteSynonym={onDeleteSynonym}
          onAcceptSynonym={onAcceptSynonym}
          onRejectSynonym={onRejectSynonym}
          setRefetch={setRefetch}
          accessToAcceptRejectSynonym={accessToAcceptRejectSynonym}
        />
      )}
      {synonymType === 'ICD' && (
        <ICDTable
          onAddSynonym={onAddSynonym}
          onEditSynonym={onEditSynonym}
          onDeleteSynonym={onDeleteSynonym}
          onAcceptSynonym={onAcceptSynonym}
          onRejectSynonym={onRejectSynonym}
          setRefetch={setRefetch}
          accessToAcceptRejectSynonym={accessToAcceptRejectSynonym}
        />
      )}
      <AddSynonymModal
        labels={labels}
        isIcdSynonym={isIcdSynonym}
        isModalVisible={isModalVisible}
        setModalVisibility={toggleAddModal}
        selectedCode={selectedCPTICD}
        showCptLookup={synonymType === 'CPT' && !selectedCPTICD?.code}
        showIcdLookup={synonymType === 'ICD' && !selectedCPTICD?.code}
        updateSynonym={updateSynonym}
      />
    </div>
  );
};

export default CPTICDSynonym;
