/* eslint-disable camelcase */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Row, Col } from 'antd';
import isFunction from 'lodash/isFunction';

import Events from '../../lib/events';
import useCRUD from '../../hooks/useCRUD';
import { listIds } from '../../lib/constants';
import { apiUrls } from '../../api/constants';
import { getName, getString } from '../../lib/util';
import useReduxStoreWithId from '../../hooks/useReduxStoreWithId';

import Icon from '../../components/Icon';
import Modal from '../../components/Modal';
import Tooltip from '../../components/Tooltip';
import SmCheckbox from '../../components/SmCheckbox';
import AutoComplete from '../../components/Form/GridAutoComplete';

import AdvanceSearch from './AdvanceSearch';

import './autocomplete.scss';

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    flex: 3,
    maxWidth: 'auto',
  },
  {
    Header: 'NPI',
    accessor: ({ npi }, isTitle) => {
      if (isTitle) {
        if (npi) {
          return `${npi}`;
        }
        return npi || '';
      }
      if (npi) {
        return (
          <span>{npi}</span>
        );
      }
      return npi || '';
    },
    flex: 3,
    maxWidth: 'auto',
  },
  {
    Header: 'Specialty',
    accessor: ({ speciality }, isTitle) => {
      if (isTitle) {
        if (speciality) {
          return `${speciality}`;
        }
        return speciality || '';
      }
      if (speciality) {
        return (
          <span>{speciality}</span>
        );
      }
      return speciality || '';
    },
    flex: 3,
    maxWidth: 'auto',
  },
  {
    Header: 'Primary Address',
    accessor: 'primary',
    flex: 5,
    maxWidth: 'auto',
  },
  {
    Header: 'Mailing Address',
    accessor: 'mailing',
    flex: 5,
    maxWidth: 'auto',
  },
];

const tooltipContent = (data) => (
  <>
    <h4>
      {data.name}
    </h4>
    <p>
      {data.speciality}
    </p>
    <p>
      NPI:
      {' '}
      {data.npi}
    </p>
    <p style={{ flex: 4 }}>
      <p className="team-row">
        <strong>
          Primary Address
        </strong>
        <br />
        {data?.addresses?.map((address) => (
            address?.address_purpose === 'LOCATION'
              && (
                <>
                  <span>{`${address?.address_1 || ' '}, ${address?.address_2 || ' '}`}</span>
                  <br />
                  <span>{`${address?.city || ' '}, ${address?.postal_code || ' '}`}</span>
                  <br />
                  <span>
                    <strong>Phone:</strong>
                    {' '}
                    {`${address?.telephone_number || '-'}`}
                  </span>
                  <br />
                  <span>
                    <strong>Fax:</strong>
                    {' '}
                    {`${address?.fax_number || '-'}`}
                  </span>
                </>
              )
        ))}
      </p>
    </p>
    <p style={{ flex: 4 }}>
      <p className="team-row">
        <strong>
          Mailing Address
        </strong>
        <br />
        {data?.addresses?.map((address) => (
            address?.address_purpose === 'MAILING'
              && (
                <>
                  <span>{`${address?.address_1 || ' '}, ${address?.address_2 || ' '}`}</span>
                  <br />
                  <span>{`${address?.city || ' '}, ${address?.postal_code || ' '}`}</span>
                  <br />
                  <span>
                    <strong>Phone:</strong>
                    {' '}
                    {`${address?.telephone_number || '-'}`}
                  </span>
                  <br />
                  <span>
                    <strong>Fax:</strong>
                    {' '}
                    {`${address?.fax_number || '-'}`}
                  </span>
                </>
              )
        ))}
      </p>
    </p>
  </>
);

const getMyAddress = (address) => address?.map((item) => getString(
  [`${item?.address_purpose === 'LOCATION' ? 'Primary Practice Address:' : 'Mailing Address:'}\n${item?.address_1}`,
    item?.address_2,
      item?.city,
      item?.state,
      item?.postal_code],
)).join('\n');

const getPrimaryAddress = (address) => address?.map((item) => (
  item?.address_purpose === 'LOCATION'
      && (
      <>
        <span>{`${item?.address_1 || ' '}, ${item?.address_2 || ' '}`}</span>
        <br />
        <span>{`${item?.city || ' '}, ${item?.postal_code || ' '}`}</span>
        <br />
        <span>
          <strong>Phone:</strong>
          {' '}
          {`${item?.telephone_number || '-'}`}
        </span>
        <br />
        <span>
          <strong>Fax:</strong>
          {' '}
          {`${item?.fax_number || '-'}`}
        </span>
      </>
      )));

const getMailingAddress = (address) => address?.map((item) => (
  item?.address_purpose === 'MAILING'
  && (
  <>
    <span>{`${item?.address_1 || ' '}, ${item?.address_2 || ' '}`}</span>
    <br />
    <span>{`${item?.city || ' '}, ${item?.postal_code || ' '}`}</span>
    <br />
    <span>
      <strong>Phone:</strong>
      {' '}
      {`${item?.telephone_number || '-'}`}
    </span>
    <br />
    <span>
      <strong>Fax:</strong>
      {' '}
      {`${item?.fax_number || '-'}`}
    </span>
  </>
  )));

const getMyPhone = (phone) => phone?.map((item) => getString(
  [`${item?.address_purpose === 'LOCATION' ? 'Primary Phone:' : 'Mailing Phone:'}\n${item?.telephone_number}`],
)).join('\n');

const getMyFax = (fax) => fax?.map((item) => getString(
  [`${item?.address_purpose === 'LOCATION' ? 'Primary Fax:' : 'Mailing Fax:'}\n${item?.fax_number}`],
)).join('\n');

const ReferringPhysicianAutoComplete = ({
  label,
  placeholder,
  disabled,
  required,
  initialValue,
  labelSpan = '10',
  inputSpan = '13',
  onSelect,
  name,
  onChange,
  setIsResetFields,
  allowClearOnBlur,
  selectClassName,
  styleWidth,
  dropdownMatchSelectWidth,
  className,
  selectProps,
  params,
  formId,
  form,
  showAdvancedSearch,
  valueInString,
  dataTestId,
  ...props
}) => {
  const [isHovering, setHovering] = useState(false);
  const [includeAllStates, setIncludeAllStates] = useState(false);
  const [isTooltipVisible, setTooltipVisibility] = useState(true);
  const [isAdvanceSearchModal, setAdvanceSearchVisibility] = useState(false);

  const [fetchedInitialValueData,, setFetchedInitialValue] = useReduxStoreWithId({ id: `referringPhysician_${name}` });

  const fetchedInitialValue = useMemo(() => fetchedInitialValueData?.get('data')
   && ({
     ...fetchedInitialValueData?.get('data'),
     value:
   valueInString ? fetchedInitialValueData?.get('data')?.value : fetchedInitialValueData?.get('data')?.value && parseInt(fetchedInitialValueData?.get('data')?.value, 10),
   }), [fetchedInitialValueData, valueInString]);

  const effectiveInputSpan = useMemo(() => (
    showAdvancedSearch ? parseInt(inputSpan, 10) - 1 : parseInt(inputSpan, 10)
  ), [inputSpan, showAdvancedSearch]);
  const effectiveIconOffset = useMemo(() => (
    showAdvancedSearch ? effectiveInputSpan + parseInt(labelSpan, 10) : 0
  ), [effectiveInputSpan, labelSpan, showAdvancedSearch]);

  const [
    initialReferringPhysician,,
    loading,
    getInitialReferringPhysician,
    clearInitialReferringPhysician,
  ] = useCRUD({
    id: `${listIds.GET_INITIAL_REFERRING_PHYSICIAN}_${initialValue?.npi}_${initialValue?.value}_${name}`,
    url: apiUrls.GET_REFERRING_PHYSICIAN,
    type: 'read',
  });

  useEffect(() => {
    if ((initialValue?.npi || initialValue?.value)
    && ((!initialReferringPhysician
    && !loading
    && !fetchedInitialValue) || (initialValue?.npi !== fetchedInitialValue?.npi
      || initialValue?.value !== fetchedInitialValue?.npi))) {
      getInitialReferringPhysician({ SearchText: initialValue?.npi || initialValue?.value });
    }
    return () => { clearInitialReferringPhysician(true); };
  }, [initialValue]);

  useEffect(() => {
    if (initialReferringPhysician?.length) {
      setFetchedInitialValue({
        ...initialReferringPhysician[0],
        name: initialReferringPhysician[0]?.name
        || getName(initialReferringPhysician[0]?.firstName,
           initialReferringPhysician[0]?.middleName,
           initialReferringPhysician[0]?.lastName),
        value: initialReferringPhysician[0].npi || initialReferringPhysician[0].value,
        address: getMyAddress(initialReferringPhysician[0]?.addresses),
        primary: getPrimaryAddress(initialReferringPhysician[0]?.addresses),
        mailing: getMailingAddress(initialReferringPhysician[0]?.addresses),
        phone: getMyPhone(initialReferringPhysician[0]?.addresses),
        fax: getMyFax(initialReferringPhysician[0]?.addresses),
      });
    } else if (!initialValue) {
      setFetchedInitialValue();
    }
  }, [initialReferringPhysician]);

  const physicianOptionParser = useCallback((option) => ({
    ...option,
    address: getMyAddress(option?.addresses),
    primary: getPrimaryAddress(option?.addresses),
    mailing: getMailingAddress(option?.addresses),
    name: option.name || getName(option.firstName, option.middleName, option.lastName),
    phone: getMyPhone(option?.addresses),
    fax: getMyFax(option?.addresses),
    speciality: option.speciality,
    npi: option.npi || option.value,
    value: option.npi || option.value,
  }), []);

  const onPhysicianSelect = useCallback((item) => {
    if (onSelect) {
      onSelect(item);
    }
    setFetchedInitialValue(item);
    clearInitialReferringPhysician(true);
  }, [clearInitialReferringPhysician, onSelect, setFetchedInitialValue]);

  useEffect(() => {
    if (initialValue && Object.values(initialValue).length) {
      if (isFunction(setIsResetFields)) setIsResetFields(true);
    }
  }, [initialValue, setIsResetFields]);

  const handleCheckbox = useCallback(({ target: { checked } }) => setIncludeAllStates(checked), []);

  const reset = useCallback(() => {
    getInitialReferringPhysician({ SearchText: initialValue?.npi || initialValue?.value });
  }, [getInitialReferringPhysician, initialValue]);

  useEffect(() => {
    Events.on(`resetForm-${Array.isArray(name) ? name[0] : name}`, `resetForm-${Array.isArray(name) ? name.join('') : name}`, reset);
    return () => Events.remove(`resetForm-${Array.isArray(name) ? name[0] : name}`, `resetForm-${Array.isArray(name) ? name.join('') : name}`);
  });

  const onDropdownVisibleChange = useCallback((value) => {
    setTooltipVisibility(!value);
  }, []);

  const toggleModal = useCallback(() => setAdvanceSearchVisibility(!isAdvanceSearchModal),
    [isAdvanceSearchModal]);

  return (
    <div
      className="referring-physician-autocomplete-container"
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <Modal
        visible={isAdvanceSearchModal}
        toggleModal={toggleModal}
        footer={null}
        destroyOnClose
      >
        <AdvanceSearch
          onPhysicianSelect={onPhysicianSelect}
          toggleModal={toggleModal}
          parentForm={form}
          name={name}
        />
      </Modal>
      {showAdvancedSearch && !disabled && (
      <Row className="advanced-search-icon" align="middle">
        <Col offset={effectiveIconOffset} span={1}>
          <Icon onClick={toggleModal} name="FilterOutlined" />
        </Col>
      </Row>
      )}
      <Tooltip
        overlayClassName="tooltip-container"
        title={(
        fetchedInitialValue && isTooltipVisible && isHovering
        && (selectProps?.mode?.toLowerCase() === 'multiple' ? form.getFieldValue(name)?.length
          : form.getFieldValue(name)?.trim()?.length)
        && Object.values(fetchedInitialValue).length
          ? tooltipContent({ ...fetchedInitialValue, title: label || '' }) : ''
      )}
      >
        <AutoComplete
          name={name}
          url={apiUrls.GET_REFERRING_PHYSICIAN}
          optionParser={physicianOptionParser}
          optionMaster="SearchText"
          labelSpan={labelSpan}
          inputSpan={effectiveInputSpan}
          disabled={disabled}
          required={required}
          minCharLength={3}
          initialValue={fetchedInitialValue || initialValue}
          columns={columns}
          style={styleWidth ? { width: '100%' } : {}}
          dropdownMatchSelectWidth={600}
          className={className}
          notFoundContent="Not found"
          showArrow
          labelInValue
          selectProps={{
            ...selectProps,
            optionLabelProp: 'name',
            allowClear: true,
            onDropdownVisibleChange,
          }}
          params={{ IncludeAllStates: includeAllStates, PageSize: 100 }}
          onSelect={onPhysicianSelect}
          Header
          label={label}
          loading={loading}
          tooltipKey="name"
          customInnerTextKey="name"
          dataTestId={dataTestId || name}
          placeholder={placeholder}
          {...props}
        />
      </Tooltip>
      {!disabled && (
      <Row className="all-states-checkbox-row">
        <Col span={effectiveInputSpan} offset={labelSpan}>
          <SmCheckbox
            name="all"
            className={classnames(selectClassName, 'all-states-checkbox', { show: isHovering })}
            isFormItem={false}
            checked={includeAllStates}
            onChange={handleCheckbox}
          >
            All States
          </SmCheckbox>
        </Col>
      </Row>
      )}
    </div>
  );
};

ReferringPhysicianAutoComplete.defaultProps = {
  disabled: false,
  required: false,
  initialValue: {},
  labelSpan: '10',
  inputSpan: '13',
  onSelect: () => { /* This is intentional */ },
  name: '',
  onChange: null,
  label: '',
  placeholder: 'First and Last Name only',
  allowClearOnBlur: true,
  showAdvancedSearch: true,
};

ReferringPhysicianAutoComplete.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  initialValue: PropTypes.objectOf(PropTypes.any),
  labelSpan: PropTypes.string,
  inputSpan: PropTypes.string,
  onSelect: PropTypes.func,
  name: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  allowClearOnBlur: PropTypes.bool,
  showAdvancedSearch: PropTypes.bool,
};

export default ReferringPhysicianAutoComplete;
