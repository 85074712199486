import React from 'react';
import withQuery from '../../../../../hoc/withQuery/withQuery';
import columns from './columns';
import withReduxManager from '../../../../../hoc/withReduxManager';
import { apiUrls } from '../../../../../api/constants';
import { listIds } from '../../../../../lib/constants';

const initialSort = [{ id: 'name', desc: false }];

const CustomExamRoomTable = withReduxManager({
  listId: listIds.LOCATION_EXAMROOM_LIST,
  pageSize: 30,
})(withQuery({
  url: apiUrls.GET_EXAMROOM_LIST,
  listId: listIds.LOCATION_EXAMROOM_LIST,
})());

const ExamRoomList = ({
  labels, id, ...otherProps
}) => (
  <CustomExamRoomTable
    initialSort={initialSort}
    columns={columns(labels)}
    filters={{ locationId: id }}
    {...otherProps}
  >
    {({
      Component, totalCount,
    }) => (
      <>
        <div className="table-filters">
          <span />
          <span className="table-count">
            <span>{`${labels.get('labels.totalCount')}: ${totalCount}`}</span>
          </span>
        </div>
        {Component}
      </>
    )}
  </CustomExamRoomTable>
);

export default ExamRoomList;
