import React, { useEffect } from 'react';

import useCRUDWithoutTab from '../../../../../../hooks/useCRUDWithoutTab';

import WidgetLoader from '../../../../../../components/WidgetLoader';

import SelectWrapper from '../../SelectWrapper';
import InputWrapper from '../../InputWrapper';

const parseItem = ({ object, item }) => {
  if (object[item.eyeType]) {
    if (object[item.eyeType][item.examId]) {
      object[item.eyeType][item.examId].push(...JSON.parse(item.result).map((result) => ({
        Key: result.Key,
        answers: result.Answers.filter((resultAnswers) => resultAnswers.Answer),
      })).filter(({ answers }) => answers));
    } else {
      const parsed = JSON.parse(item.result).map((result) => ({
        Key: result.Key,
        answers: result.Answers.filter((resultAnswers) => resultAnswers.Answer),
      })).filter(({ answers }) => answers);
      if (parsed.length) {
        // eslint-disable-next-line no-param-reassign
        object[item.eyeType][item.examId] = parsed;
      }
    }
  } else {
    // eslint-disable-next-line no-param-reassign
    object[item.eyeType] = {};
    const parsed = JSON.parse(item.result).map((result) => ({
      Key: result.Key,
      answers: result.Answers.filter((resultAnswers) => resultAnswers.Answer),
    })).filter(({ answers }) => answers);
    if (parsed.length) {
      // eslint-disable-next-line no-param-reassign
      object[item.eyeType][item.examId] = parsed;
    }
  }
};

const CommonExamSelect = ({
  masterId, savedExamResult, scrollId,
  setFormData, values, setValues,
  handleSave, savedValues, setSavedValues,
  id, apiUrl, displayOnly,
}) => {
  const [
    questionnaire,,
    questionnaireLoading,
    getQuestionnaire,
  ] = useCRUDWithoutTab({
    id,
    url: apiUrl,
    type: 'read',
  });

  useEffect(() => {
    if (savedExamResult) {
      const object = {};
      savedExamResult.forEach((item) => {
        parseItem({ object, item });
      });
      setValues(object);
      setSavedValues(object);
      setFormData(object);
    }
  }, [savedExamResult]);

  useEffect(() => {
    if (!questionnaire && !questionnaire?.length) {
      getQuestionnaire({
        TypeId: masterId,
      });
    }
  }, [masterId, getQuestionnaire]);

  return (
    <>
      {questionnaireLoading && <WidgetLoader />}
      {questionnaire && questionnaire.map((item) => (
        <div className="content-body" key={item.QuestionnairesMasterId}>
          <div className="text-box">{item.DescriptorName}</div>
          {JSON.parse(item.Questionnaire).map((question) => {
            if (question.Type === 'DropDown') {
              return (
                <SelectWrapper
                  question={question}
                  key={question.Key}
                  examId={item.QuestionnairesMasterId}
                  handleValues={handleSave}
                  values={values}
                  savedValues={savedValues}
                  scrollId={scrollId}
                  displayOnly={displayOnly}
                />
              );
            }
            if (question.Type === 'TextBox') {
              return (
                <InputWrapper
                  question={question}
                  key={question.Key}
                  examId={item.QuestionnairesMasterId}
                  handleValues={handleSave}
                  values={values}
                  savedValues={savedValues}
                  displayOnly={displayOnly}
                />
              );
            }
            return null;
          })}
        </div>
      ))}
    </>
  );
};

export default CommonExamSelect;
