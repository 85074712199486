import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import get from 'lodash/get';
import { connect, useDispatch } from 'react-redux';

import { Form as Antd } from 'antd';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import Upload from '../../../../components/Upload';
import Form from '../../../../components/Form';
import Icon from '../../../../components/Icon';
import {
  Input, DatePicker, CheckBox, Radio,
} from '../../../../components/Form/inputFields';

import { labelPaths, enums } from '../../../../lib/constants';
import { toDateValidator } from '../../../../lib/validator';

import WithLabel from '../../../../hoc/withLabel';

import LocationLookUp from '../../../../wiredComponents/LookUp/LocationLookup';
import PayerLookup from '../../../../wiredComponents/LookUp/PayerLookup';
import { Select } from '../../../../wiredComponents/Enum';

import * as selectors from '../../../../store/selectors';
import { getEnumMasterData } from '../../../../store/actions/enum';
import Modals from '../../../../components/Modal';
import Button from '../../../../components/Button';

import '../../contract.scss';

const locationOptions = [{ label: 'All Location', value: true }, { label: 'Specific Location', value: false }];
const contractOptions = [{ label: 'Only this Entity', value: true }, { label: 'Use this Contract For', value: false }];

const disableDates = (current, providerData, field) => {
  if (!providerData?.length) { return false; }
  if (field === 'effectivefrom') {
    let effectiveFrom = moment(providerData?.[0]?.effectiveFrom);
  providerData?.forEach((item) => {
    if (moment(item?.effectiveFrom).isBefore(effectiveFrom)) {
      effectiveFrom = moment(item?.effectiveFrom);
    }
  });
  return moment(current).isAfter(effectiveFrom);
  }
  if (field === 'effectiveto') {
    let effectiveTo = moment(providerData?.[0]?.effectiveFrom);
    providerData?.forEach((item) => {
      if (moment(item?.effectiveTo).isAfter(effectiveTo)) {
        effectiveTo = moment(item?.effectiveTo);
      }
    });
    return moment(current).isBefore(effectiveTo);
  }
  return null;
};

const ContractForm = (props) => {
  const {
    labels,
    enumMaster,
    isClear,
    setClear,
    form,
    contractFiles,
    contractProviders,
  } = props;

  const dispatch = useDispatch();

  const [addLocationModal, setAddLocationModal] = useState(false);
  const [addLocationModalCopy, setAddLocationModalCopy] = useState(false);

  const enumId = get(enumMaster, `${enums.CONTRACT_PANEL_STATUS}.enumId`);

  useEffect(() => {
    dispatch(getEnumMasterData(enumId));
  }, [enumId, dispatch]);

  const openStatusConfirmationDialogue = useCallback(({ target: { checked } }) => {
    ConfirmDialog({
      onOk: (close) => {
        close();
      },
      onCancel: (close) => {
        form.setFieldsValue({ status: !checked });
        close();
      },
      title: `Do you want to make the Contract ${checked ? 'Active' : 'inactive'}?`,
      content: `When clicked the OK button, Contract Will be ${checked ? 'Active' : 'inactive'}`,
      icon: <Icon name="ExclamationCircleOutlined" />,
    })();
  }, [form]);

  const onDeleteContractFile = useCallback((deletedFile) => {
    const { documentId } = deletedFile;
    if (documentId) {
      const alreadyDeletedFiles = form.getFieldValue('deletedContractFile') || [];
      form.customSetFieldsValue({ deletedContractFile: [...alreadyDeletedFiles, documentId] });
    }
  }, [form]);

  useEffect(() => {
    if (isClear) {
      form.setFieldsValue({ contractFile: contractFiles });
      setClear(false);
    }
  }, [isClear, setClear, form, contractFiles]);

  const setSelectedLocations = useCallback((data) => {
    form.customSetFieldsValue({ selectedLocations: data });
  }, [form]);

  const setSelectedPayers = useCallback((data) => {
    form.customSetFieldsValue({ selectedPayers: data });
  }, [form]);

  const addLocationMethod = useCallback(() => {
    setAddLocationModal(!addLocationModal);
  }, [addLocationModal]);

  const addLocationMethodCopy = useCallback(() => {
    setAddLocationModalCopy(!addLocationModalCopy);
  }, [addLocationModalCopy]);

  return (
    <>
      <Modals
        visible={addLocationModalCopy}
        toggleModal={addLocationMethodCopy}
        width={500}
        destroyOnClose
        className="schedular-patient-modal"
        footer={[
          <div className="group-btns" key="footer">
            <Button
              className="btn min-wt-86"
              onClick={addLocationMethodCopy}
            >
              {labels.get('buttons.cancel')}
            </Button>
            <Button
              className="btn btn-success min-wt-86 inline"
              onClick={form.submit}
            >
              save
            </Button>
          </div>,
        ]}
      >
        <div className="addition-header">
          <div className="lookup sprite-img-before">
            <p>Select Location</p>
          </div>
        </div>
        <div className="modal-content-weapper shadow-wrap">
          <div className="modal-from-feilds">
            <Radio options={locationOptions} label={labels.get('titles.locations')} name="appliedToAllLocations" labelSpan="7" inputSpan="17" />
            <Antd.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => (
                prevValues.appliedToAllLocations !== currentValues.appliedToAllLocations)}
            >
              {({ getFieldValue }) => (!getFieldValue('appliedToAllLocations') ? (
                <LocationLookUp
                  label={labels.get('labels.locations')}
                  inputSpan="17"
                  labelSpan="7"
                  initialData={getFieldValue('selectedLocations')}
                  setParentData={setSelectedLocations}
                />
              ) : null)}
            </Antd.Item>
          </div>
        </div>
      </Modals>
      <Modals
        visible={addLocationModal}
        toggleModal={addLocationMethod}
        width={400}
        destroyOnClose
        className="provider-location-ui"
        footer={[
          <div className="group-btns" key="footer">
            <Button
              className="btn min-wt-86"
              onClick={addLocationMethod}
            >
              {labels.get('buttons.cancel')}
            </Button>
            <Button
              className="btn btn-success min-wt-86 inline"
              onClick={form.submit}
            >
              save
            </Button>
          </div>,
        ]}
      >
        <div className="addition-header">
          <div className="lookup sprite-img-before">
            <p>Select Location</p>
          </div>
        </div>
        <div className="modal-content-weapper shadow-wrap">
          <div className="modal-from-feilds">
            <Form.Column>
              <Input
                name={null}
                label="Effective From"
                labelSpan="8"
                inputSpan="16"
                required
              />
              <Input
                name={null}
                label="Effective To"
                inputSpan="16"
                labelSpan="8"
                required
              />
              <Input
                name={null}
                label="Location"
                inputSpan="16"
                labelSpan="8"
              />
            </Form.Column>

          </div>
        </div>
      </Modals>
      <div className="">
        <Form.Section noOfColumns={3}>
          <Form.Column>
            <div className="group-shadow">
              <Input label={labels.get('labels.name')} placeholder={labels.get('placeholders.name')} name="name" maxValueLength={50} required labelSpan="7" inputSpan="17" />
              <Antd.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => (
                  prevValues.selectedPayers?.length !== currentValues.selectedPayers?.length)}
              >
                {({ getFieldValue }) => (
                  <PayerLookup
                    label={labels.get('titles.payers')}
                    inputSpan="17"
                    labelSpan="7"
                    initialData={getFieldValue('selectedPayers')}
                    setParentData={setSelectedPayers}
                    placeholder={labels.get('placeholders.SearchPayer')}
                    required
                  />
                )}
              </Antd.Item>
              <Radio required options={contractOptions} label={labels.get('labels.contractUse')} name="contractUseForAEG" labelSpan="7" inputSpan="17" />
              <Antd.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => (
                  prevValues.contractUseForAEG !== currentValues.contractUseForAEG)}
              >
                {({ getFieldValue }) => (getFieldValue('contractUseForAEG') === false) && (
                  <Select
                    label={labels.get('titles.contracts')}
                    inputSpan="17"
                    labelSpan="7"
                    enumName={enums.BUSINESS_ENTITY}
                    name="contractUseForOtherId"
                  />
                )}
              </Antd.Item>
            </div>
          </Form.Column>
          <Form.Column>
            <div className="group-shadow">
              <Input
                label={labels.get('labels.county')}
                name="county"
                inputSpan="16"
                labelSpan="8"
                placeholder={labels.get('placeholders.county')}
              />
              <Select
                label={labels.get('labels.panelStatus')}
                name="panelStatusId"
                labelSpan="8"
                inputSpan="16"
                enumName={enums.CONTRACT_PANEL_STATUS}
              />
              <Select
                label={labels.get('labels.contractType')}
                name="contractTypeId"
                labelSpan="8"
                inputSpan="16"
                enumName={enums.CONTRACT_TYPE}
                required
              />
              <DatePicker
                label={labels.get('labels.effectiveFrom')}
                placeholder={labels.get('placeholders.effectiveFrom')}
                name="effectiveFrom"
                required
                labelSpan="8"
                inputSpan="16"
                disabledDate={
                  (current) => disableDates(current, contractProviders, 'effectivefrom')
                }
              />
              <DatePicker
                label={labels.get('labels.effectiveTo')}
                placeholder={labels.get('labels.effectiveTo')}
                name="effectiveTo"
                labelSpan="8"
                inputSpan="16"
                rules={[
                  ({ getFieldValue }) => toDateValidator(getFieldValue, 'effectiveFrom'),
                ]}
                dependencies={['effectiveFrom']}
                disabledDate={(current) => disableDates(current, contractProviders, 'effectiveto')}
              />
            </div>
          </Form.Column>
          <Form.Column>
            <div className="group-shadow">
              <CheckBox
                label={labels.get('labels.status')}
                name="status"
                labelSpan="8"
                inputSpan="16"
                onChange={openStatusConfirmationDialogue}
                valuePropName="checked"
              />
              <Input.TextArea
                label={labels.get('labels.contractExclusions')}
                name="contractExclusions"
                maxValueLength={200}
                labelSpan="8"
                inputSpan="16"
                size="large"
                autoSize={{ minRows: 2, maxRows: 6 }}
                placeholder={labels.get('placeholders.contractExclusions')}
              />
              <Input.TextArea
                label={labels.get('labels.contractNotes')}
                name="contractNotes"
                maxValueLength={200}
                labelSpan="8"
                inputSpan="16"
                size="large"
                autoSize={{ minRows: 2, maxRows: 6 }}
                placeholder={labels.get('placeholders.contractNotes')}
              />
              <Upload
                accept=".png, .jpeg, .jpg, .gif, .pdf, .xlsx, .xls, .doc, .docx"
                title="Select File"
                label={labels.get('labels.contractFiles')}
                name="contractFile"
                listType="picture"
                multiple
                labelSpan="8"
                inputSpan="16"
                form={form}
                sizeLimit={30}
                onRemove={onDeleteContractFile}
              />
            </div>
          </Form.Column>
        </Form.Section>
      </div>

    </>
  );
};

export default connect((state) => ({
  enumOptions: selectors.getEnumOptions(state),
  enumMaster: selectors.getEnumMaster(state),
}))(WithLabel(ContractForm, labelPaths.NEW_CONTRACT));
