import React, {
  useState, useCallback, useMemo, useEffect, useRef,
} from 'react';
import findIndex from 'lodash/findIndex';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import isFunction from 'lodash/isFunction';
import clone from 'lodash/clone';
import { Collapse } from 'antd';

import Form from '../../../../../../../../components/Form';
import Input from '../../../../../../../../components/Form/Input';
import Radio from '../../../../../../../../components/Form/Radio';
import Smcheckbox from '../../../../../../../../components/SmCheckbox';
import WidgetLoader from '../../../../../../../../components/WidgetLoader';
import Events from '../../../../../../../../lib/events';
import rights from '../../../../../../../../lib/rights';

import Notification from '../../../../../../../../components/Notification';
import Modals from '../../../../../../../../components/Modal';
import Button from '../../../../../../../../components/Button';
import SelectBox from '../../../../../../../../components/Form/SelectBox';
import { apiUrls } from '../../../../../../../../api/constants';
import DroppableJsonTree from '../DroppableJsonTree';
import useCRUDWithoutTab from '../../../../../../../../hooks/useCRUDWithoutTab';
import useRights from '../../../../../../../../hooks/useRights';
import SlitExam from '../../../../../ODOS/SlitExam';
import Fundus from '../../../../../ODOS/Fundus';
import FollowUp from '../../../../../FollowUp';

const { Panel } = Collapse;
const orderSetOptions = [
  { name: 'practice', label: 'Practice', value: 'practice' },
  { name: 'provider', label: 'Provider', value: 'provider' },
];
const orderSetOptionsNew = [
  { name: 'provider', label: 'Provider', value: 'provider' },
];
const getTagName = (defaultCheckedDiagnosis, values) => {
  if (values?.tagNames?.length) {
    return values?.tagNames;
  }
  return [];
};

const SetModal = ({
  type,
  isModalVisible,
  toggleModal,
  form,
  labelList,
  setLabelsList,
  notesList,
  downloadImaging,
  source,
  encounterId,
  providerId,
  clonedNotes,
  defaultCheckedDiagnosis,
  setDefaultCheckedDiagnosis,
  defaultCheckedProcedure,
  orderSetData,
  navigateBack,
}) => {
  const [checkedProcedures, setCheckedProcedures] = useState({});
  const [orderSetValue, setOrderSetValue] = useState('provider');
  const [isSelectAll, setSelectAll] = useState(false);
  const checkBoxRef = useRef({ includeExam: false, includeFollowUp: false });
  const updateUrl = defaultCheckedDiagnosis
    ? apiUrls.SAVE_DIAGNOSIS_FAVORITE : apiUrls.SAVE_PROCEDURE_ORDER_SET;
  const selectBoxLabel = defaultCheckedDiagnosis ? 'Folder' : 'Tags';
  const AAndPModalHeading = defaultCheckedDiagnosis ? 'Save Diagnosis Favorite' : 'Save As Procedure Set';

  const [
    procedureSetResponse, ,
    procedureSetLoading,
    getProcedureSet,
  ] = useCRUDWithoutTab({
    id: 'get-procedure-set-source-diagnosis',
    url: apiUrls.GET_IN_OFFICE_PROCEDURE_SETS,
    type: 'read',
  });

  useEffect(() => {
    const data = { source: defaultCheckedDiagnosis ? 'Diagnosis' : source };
    if (orderSetValue === 'provider') {
      data.ProviderId = providerId;
    }
    getProcedureSet(data);
  }, [defaultCheckedDiagnosis, orderSetValue, providerId, source]);

  const tagsoptions = useMemo(() => {
    const data = [];
    procedureSetResponse?.forEach(({ tags }) => {
      tags?.forEach((tagData) => {
        data.push({
          name: tagData?.tagName?.toLowerCase(),
          value: tagData?.tagName?.toLowerCase(),
        });
      });
    });
    return uniqBy(data, 'value');
  }, [procedureSetResponse]);

  const modeOptionPlaceholder = defaultCheckedDiagnosis ? 'Folder' : 'Tags';

  useEffect(() => {
    if (defaultCheckedProcedure) {
      setCheckedProcedures({
        [defaultCheckedProcedure?.groupId]: {
          dxIds: defaultCheckedProcedure?.dxIds || [],
          patientProcedureCodeIds: [defaultCheckedProcedure?.procedureDetailsId],
        },
      });
    }
  }, [defaultCheckedProcedure]);

  useEffect(() => {
    if (defaultCheckedDiagnosis) {
      setCheckedProcedures({
        [defaultCheckedDiagnosis?.groupId]: {
          patientProcedureCodeIds: [],
          dxIds: [defaultCheckedDiagnosis?.IcdCodeId],
        },
      });
    }
    return () => {
      setSelectAll(false);
      setCheckedProcedures({});
      setDefaultCheckedDiagnosis(null);
    };
  }, [defaultCheckedDiagnosis]);

  const [isManagePracticeRole] = useRights([rights.access_to_manage_practice_favourite]);
  const updatedOrderSetOptions = useMemo(() => {
    if (isManagePracticeRole) {
      return orderSetOptions;
    }
    return orderSetOptionsNew;
  }, [isManagePracticeRole]);

  const getExtraData = useMemo(() => {
    const data = { procedureFavorites: [] };
    data.orderSetType = orderSetValue;
    const user = localStorage.getDecryptedData('user');
    const parsedUser = JSON.parse(user);
    data.specialityName = parsedUser?.providerDetail?.specialtyName;
    data.specialityId = parsedUser?.providerDetail?.specialtyId;
    data.providerName = parsedUser?.providerDetail?.providerName;
    if (orderSetValue === 'provider') {
      data.providerId = providerId;
    }
    if (labelList?.length || Object.keys(checkedProcedures).length) {
      Object.keys(checkedProcedures).forEach((groupId) => {
        if (!Array.isArray(checkedProcedures[groupId])
          && (checkedProcedures?.[groupId]?.dxIds?.length
            || checkedProcedures?.[groupId]?.patientProcedureCodeIds?.length)) {
          data.procedureFavorites.push({
            groupId,
            dxIds: checkedProcedures?.[groupId]?.dxIds || [],
            patientProcedureCodeIds: defaultCheckedDiagnosis ? []
              : checkedProcedures?.[groupId]?.patientProcedureCodeIds || [],
          });
        }
        if (Array.isArray(checkedProcedures[groupId])) data[groupId] = checkedProcedures[groupId];
      });
    }
    return data;
  }, [checkedProcedures, labelList, providerId, orderSetValue]);

  const formParser = useCallback((values) => ({
    ...values,
    tagNames: getTagName(defaultCheckedDiagnosis, values),
    encounterId,
  }), [defaultCheckedDiagnosis, encounterId]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({
        message: defaultCheckedDiagnosis ? 'Favorites has been added successfully'
          : 'Procedure set has been saved successfully',
        success: true,
      });
      if (defaultCheckedDiagnosis) Events.trigger('get-diagnosis-list');
      else Events.trigger(`fetch-procedure-sets-${encounterId}`);
      toggleModal();
    }
  }, [defaultCheckedDiagnosis, encounterId, toggleModal]);

  const onRequestCompleteOrderSet = useCallback(({ response }) => {
    if (response) {
      Notification({ message: 'Order set has been saved successfully', success: true });
      Events.trigger(`fetch-order-sets-${encounterId}`);
      if (isFunction(navigateBack)) navigateBack();
      toggleModal();
    }
  }, [encounterId, navigateBack, toggleModal]);

  const handleCheckBoxClick = useCallback((groupId,
    element, isParent) => ({ target: { checked } }) => {
    const checkedProceduresData = checkedProcedures;
    const {
      dxIds = [],
      patientProcedureCodeIds = [],
    } = checkedProceduresData[groupId] || {};

    if (isParent === null) {
      if (checked) {
          clonedNotes?.current?.push({ noteType: element?.noteType, note: element?.note });
          checkedProceduresData.notes = clonedNotes?.current;
          return setCheckedProcedures({
            ...checkedProceduresData,
            noteNames: clonedNotes?.current?.map((noteData) => (noteData.noteType)),
          });
      }
      const notesIndex = findIndex(clonedNotes?.current,
        (note) => note.noteType === element.noteType);
        clonedNotes?.current.splice(notesIndex, 1);
        checkedProceduresData.notes = clonedNotes?.current;
        return setCheckedProcedures({
          ...checkedProceduresData,
          noteNames: clonedNotes?.current?.map((noteData) => (
            noteData.noteType
          )),
        });
    }

    if (isParent && checked) {
      dxIds.push(element?.id);
      checkedProceduresData[groupId] = {
        patientProcedureCodeIds,
        dxIds: uniq(dxIds),
      };
      return setCheckedProcedures({ ...checkedProceduresData });
    }

    if (isParent && !checked) {
      const dxIndex = findIndex(dxIds, (id) => id === element.id);
      dxIds.splice(dxIndex, 1);
      checkedProceduresData[groupId] = {
        dxIds,
        patientProcedureCodeIds,
      };
      setSelectAll(checked);
      return setCheckedProcedures({ ...checkedProceduresData });
    }

    if (!isParent && checked) {
      patientProcedureCodeIds.push(element?.procedureDetailsId);
      checkedProceduresData[groupId] = {
        dxIds,
        patientProcedureCodeIds: uniq(patientProcedureCodeIds),
      };
      return setCheckedProcedures({ ...checkedProceduresData });
    }

    if (!isParent && !checked) {
      const prIndex = findIndex(patientProcedureCodeIds, (id) => id === element.procedureDetailsId);
      patientProcedureCodeIds.splice(prIndex, 1);
      checkedProceduresData[groupId] = {
        dxIds,
        patientProcedureCodeIds: uniq(patientProcedureCodeIds),
      };
      setSelectAll(checked);
      return setCheckedProcedures({ ...checkedProceduresData });
    }

    const index = findIndex(patientProcedureCodeIds,
      (id) => id === element.patientProcedureCodeId);
    patientProcedureCodeIds.splice(index, 1);
    checkedProceduresData[groupId] = {
      patientProcedureCodeIds,
      dxIds,
    };

    setSelectAll(checked);
    return setCheckedProcedures({ ...checkedProceduresData });
  }, [checkedProcedures, clonedNotes]);

  const onChange = useCallback(({ target: { checked } }) => {
    const procedureFavorites = {};
    if (checked) {
      labelList.forEach((procedure) => {
        const { diagnosisList, groupId, subType } = procedure;
        procedureFavorites[groupId] = {
          dxIds: diagnosisList?.map((diagnosis) => diagnosis?.id),
          patientProcedureCodeIds: type === 'inOffice'
            ? subType.reduce((acc, procedureCode) => {
              if (procedureCode?.source !== 'ImagingLab') {
                acc.push(procedureCode?.procedureDetailsId);
              }
              return acc;
            }, [])
            : subType?.map((procedureCode) => procedureCode?.procedureDetailsId),
        };
      });
      if (notesList?.length) {
        const notesObject = {};
        notesList.forEach((value) => {
          const { noteType, note } = value || {};
          if (noteType) {
            notesObject[noteType] = { noteType, note };
          }
        });
        procedureFavorites.notes = Object.values(notesObject);
        procedureFavorites.noteNames = Object.keys(notesObject);
      }
    }
    // eslint-disable-next-line no-param-reassign
    clonedNotes.current = (procedureFavorites.notes || []);
    setCheckedProcedures(procedureFavorites);
    setSelectAll(checked);
  }, [clonedNotes, labelList, notesList, type]);

  const handleOrderSetOptions = useCallback(({ target }) => {
    const { value } = target;
    setOrderSetValue(value);
  }, [setOrderSetValue]);

  const handleSave = useCallback(() => {
    if (getExtraData?.procedureFavorites?.length) {
      form.submit();
    } else {
      Notification({ message: 'Please select at least one Procedure or Diagnosis' });
    }
  }, [form, getExtraData]);

  const orderSetParser = useCallback((values) => {
    const clonedOrderSetData = clone(orderSetData);
    if (!checkBoxRef.current.includeExam) {
      delete clonedOrderSetData.slitExamList;
      delete clonedOrderSetData.fundusExamList;
    }
    if (!checkBoxRef.current.includeFollowUp) {
      delete clonedOrderSetData.followUp;
    }
    return {
      ...values,
      encounterId,
      ...clonedOrderSetData,
      notes: clonedNotes.current || [],
    };
  }, [clonedNotes, encounterId, orderSetData]);

  const handleTagChange = (value) => {
    const filteredValues = value.filter((item) => {
      if (item?.trim()?.length === 0 || item?.trim()?.length > 50 || !/^[a-zA-Z\d\-_,\s]+$/.test(item)) {
        return false;
      }
      return true;
    });
    if (defaultCheckedDiagnosis) {
      if (filteredValues.length > 0) {
        form.setFieldsValue({ tagNames: [filteredValues.pop()] });
      } else {
        form.setFieldsValue({ tagNames: [] });
      }
    } else {
      form.setFieldsValue({ tagNames: filteredValues });
    }
  };

  const handleCheckboxChange = ({ target: { name, checked } }) => {
    checkBoxRef.current[name] = checked;
  };

  const handleTagInputValidation = useCallback(() => {
    const element = document.getElementById('form_tagNames');
    element.setAttribute('maxlength', 50);
  }, []);

  return (
    <Modals
      className="save-order-set"
      visible={isModalVisible}
      toggleModal={toggleModal}
      destroyOnClose
      footer={[
        <div className="group-btns mng-footer-btn" key="footer">
          <Button className="btn min-wt-86" onClick={toggleModal}>Cancel</Button>
          <Button className="btn btn-success min-wt-86 inline" onClick={handleSave}>
            Save
          </Button>
        </div>,
      ]}
    >
      {procedureSetLoading && <WidgetLoader />}
      {(type === 'ap' && !defaultCheckedDiagnosis) ? (
        <div>
          <div className="addition-header">
            <div className="lookup sprite-img-before">
              <p className="">Save As Order Set</p>
            </div>
          </div>
          <Form
            form={form}
            extraData={getExtraData}
            formId={apiUrls.SAVE_AP_ORDER_SET}
            url={apiUrls.SAVE_AP_ORDER_SET}
            onRequestComplete={onRequestCompleteOrderSet}
            parser={orderSetParser}
          >
            <Form.Column>
              <Input
                name="favouriteName"
                label="Order Set Name"
                labelSpan={8}
                inputSpan={16}
                maxValueLength={50}
                required
              />
              <Radio
                className="radio-option-gap"
                options={updatedOrderSetOptions}
                labelSpan={8}
                inputSpan={16}
                label="Save as"
                valuePropName="checked"
                onChange={handleOrderSetOptions}
                radioProps={{
                  value: orderSetValue,
                }}
              />
            </Form.Column>
          </Form>
          <div className="save-as-order-collapse-wrap">
            <Collapse defaultActiveKey={['1']}>
              <Panel
                header="Procedures"
                key="1"
              >
                {(labelList || [])?.length > 0 && (
                <Smcheckbox onChange={onChange} checked={isSelectAll}>
                  Select All
                </Smcheckbox>
                )}
                <div className="max-height-400">
                  <DroppableJsonTree
                    setLabelsList={setLabelsList}
                    labelList={labelList}
                    checkable
                    defaultExpandAll
                    notesList={notesList}
                    showSelection
                    showCloseIcon={false}
                    draggable={false}
                    handleCheckBoxClick={handleCheckBoxClick}
                    checkedValues={checkedProcedures}
                    downloadImaging={downloadImaging}
                  />
                </div>
              </Panel>
              <Panel
                header={(
                  <div
                    className="flex justify-content-sp-bt"
                  >
                    <span>Follow Up</span>
                    <div
                      role="presentation"
                      onClick={(event) => event.stopPropagation()}
                    >
                      <Smcheckbox
                        name="includeFollowUp"
                        isFormItem={false}
                        onChange={handleCheckboxChange}
                      >
                        Include Follow up
                      </Smcheckbox>

                    </div>
                  </div>
                )}
                key="2"
              >
                <FollowUp
                  disabledAll
                  hideSignBill
                  isOrderSet
                />
              </Panel>
              <Panel
                header={(
                  <div className="flex justify-content-sp-bt">
                    <span>Exam findings</span>
                    <div role="presentation" onClick={(event) => event.stopPropagation()}>
                      <Smcheckbox
                        name="includeExam"
                        isFormItem={false}
                        onChange={handleCheckboxChange}
                      >
                        Include Exam Finding
                      </Smcheckbox>

                    </div>
                  </div>
                )}
                key="3"
              >
                <div className="select-box-section" id="select-box-section-doctor" style={{ position: 'relative' }}>
                  <SlitExam />
                  <Fundus />
                </div>
              </Panel>
            </Collapse>
          </div>
        </div>
      ) : (
        <div>
          <div className="addition-header">
            <div className="lookup sprite-img-before">
              <p className="">{AAndPModalHeading}</p>
            </div>
          </div>
          <Form
            form={form}
            extraData={getExtraData}
            formId={updateUrl}
            url={updateUrl}
            onRequestComplete={onRequestComplete}
            parser={formParser}
          >
            <Form.Column>
              {!defaultCheckedDiagnosis && (
                <Input
                  name="favouriteName"
                  label="Procedure Set Name"
                  labelSpan={8}
                  inputSpan={16}
                  maxValueLength={50}
                  required
                />
              )}
              <Radio
                className="radio-option-gap"
                options={updatedOrderSetOptions}
                labelSpan={8}
                inputSpan={16}
                label="Save as"
                valuePropName="checked"
                onChange={handleOrderSetOptions}
                radioProps={{
                  value: orderSetValue,
                }}
              />
              <div className="procedure-tags-wrap">
                <SelectBox
                  placeholder={`${modeOptionPlaceholder} accepts only alphanumeric values and maxlength upto 50 characters.`}
                  label={selectBoxLabel}
                  name="tagNames"
                  options={tagsoptions}
                  mode="tags"
                  labelSpan={8}
                  inputSpan={16}
                  onChange={handleTagChange}
                  selectProps={{
                    onInputKeyDown: handleTagInputValidation,
                  }}
                />
              </div>
            </Form.Column>
          </Form>
          <Smcheckbox onChange={onChange} checked={isSelectAll}>
            Select All
          </Smcheckbox>
          <div className="max-height-400">
            <DroppableJsonTree
              type={type}
              setLabelsList={setLabelsList}
              labelList={labelList}
              checkable
              defaultExpandAll
              showCloseIcon={false}
              draggable={false}
              showSelection
              handleCheckBoxClick={handleCheckBoxClick}
              checkedValues={checkedProcedures}
              downloadImaging={downloadImaging}
              showAddedProcedures={!defaultCheckedDiagnosis}
              savedSimpleEncounter={defaultCheckedDiagnosis}
            />
          </div>
        </div>
      )}
    </Modals>
  );
};

export default SetModal;
