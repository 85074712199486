import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';
import isFunction from 'lodash/isFunction';

import { enums as enumConstant } from '../../../lib/constants';
import Table from '../../../components/Table';
import { Select } from '../../Enum';

import planColumns from './columns/plan';

function PlanLookup({
  label,
  // placeholder,
  required,
  labelSpan = '10',
  inputSpan = '13',
  setParentData,
  initialData,
  isSetToInitial,
  form,
}) {
  const [data, setData] = useState(initialData);
  const [initialTableData, setInitialTableData] = useState(null);

  useEffect(() => {
    if (isSetToInitial) {
      setData(initialTableData || []);
      if (isFunction(setParentData)) setParentData(initialTableData || []);
    }
  }, [data, initialTableData, isSetToInitial, setParentData]);

  useEffect(() => {
    if (initialData?.length) {
      setData(initialData);
      if (!initialTableData) {
        setInitialTableData(initialData);
      }
    }
  }, [initialData, initialTableData]);

  const onPlanSelect = useCallback((value, allItems) => {
    const { item } = allItems;
    const index = findIndex(data,
      (plan) => item.value === plan.value || item.value === plan?.masterId);
    if (index < 0) {
      setData([...data, item]);
      setParentData([...data, item]);
    }
    form.customSetFieldsValue({ PlanName: null });
  }, [data, form, setParentData]);

  const deletePlan = useCallback((id) => {
    const index = findIndex(data, (item) => item.id === id);
    const cloned = [...data];
    cloned.splice(index, 1);
    setData(cloned);
    setParentData(cloned);
  }, [data, setParentData]);

  return (
    <div>
      <Select
        label={label}
        labelSpan={labelSpan}
        inputSpan={inputSpan}
        name="PlanName"
        required={data.length ? null : required}
        enumName={enumConstant.CONTRACT_TERM_PLAN}
        onChange={onPlanSelect}
      />
      {data.length > 0
        ? <Table columns={planColumns} data={data} deletePlan={deletePlan} />
        : null}

    </div>
  );
}

PlanLookup.defaultProps = {
  label: '',
  placeholder: '',
  disabled: false,
  required: false,
  name: '',
  labelSpan: '10',
  inputSpan: '13',
  initialData: [],
  setParentData: () => { /* This is intentional */ },
  isSetToInitial: false,

};

PlanLookup.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  name: PropTypes.string,
  labelSpan: PropTypes.string,
  inputSpan: PropTypes.string,
  initialData: PropTypes.arrayOf(PropTypes.object),
  setParentData: PropTypes.func,
  isSetToInitial: PropTypes.bool,
};

export default PlanLookup;
