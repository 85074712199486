import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import SelectBox from '../../../../components/Form/SelectBox';
import useCRUD from '../../../../hooks/useCRUD';
import { apiUrls } from '../../../../api/constants';
import Notification from '../../../../components/Notification';
import SuccessMessages from '../../../../lib/successMessages';
import Events from '../../../../lib/events';

const markAsOptions = [{ name: 'Read', value: 'read' }, { name: 'Unread', value: 'unread' }];

const MarkAsRead = ({ selectedTask, setSelectedTasks }) => {
  const [isRead, setRead] = useState(false);
  const [markedAsValue, setMarkAsValue] = useState(null);
  const [readResponse, readError,, markAsReadUnread, clearResponse] = useCRUD({
    id: 'mark-as-read-unread', url: apiUrls.MARK_READ_UNREAD, type: 'create', shouldClearError: false,
  });

  const taskIds = useMemo(() => {
    if (selectedTask && selectedTask.length) {
      return selectedTask.map(({ original }) => original.taskId);
    }
    return [];
  }, [selectedTask]);

  useEffect(() => {
    if (readError) {
      Notification({ message: readError });
      clearResponse(true);
      setMarkAsValue();
    }
  }, [readError]);

  useEffect(() => {
    if (readResponse) {
      Notification({
        message:
         (isRead
           ? SuccessMessages.MARKED_AS_READ_SUCCESSFULLY
           : SuccessMessages.MARKED_AS_UNREAD_SUCCESSFULLY),
        success: true,
      });
      clearResponse(true);
      Events.trigger('refetchTaskManagementList');
      setMarkAsValue();
      setSelectedTasks([]);
    }
  }, [readResponse]);

  const onChangeRead = useCallback((markAsValue) => {
    setRead(markAsValue === 'read');
    setMarkAsValue(markAsValue);
    if (markAsValue === 'read' && taskIds?.length) {
      const data = { taskIds, isRead: true };
      markAsReadUnread({ data });
    } else if (markAsValue === 'unread' && taskIds?.length) {
      const data = { taskIds, isRead: false };
      markAsReadUnread({ data });
    }
  }, [markAsReadUnread, taskIds]);

  return (
    <SelectBox
      name={null}
      options={markAsOptions}
      placeholder="Mark As"
      labelSpan="0"
      inputSpan="24"
      onChange={onChangeRead}
      value={markedAsValue}
      disabled={!taskIds?.length}
    />
  );
};

export default MarkAsRead;
