import React, { useCallback } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';

import IconNames from '../../../../../../../../lib/iconNames';
import Input from '../../../../../../../../components/Form/Input';
import Icon from '../../../../../../../../components/Icon';
import ModifierAutocomplete from '../../../../../../../../wiredComponents/ModifierAutocomplete';

const EditableModifierCell = ({
  label,
  editable,
  form,
  initialValue,
  onSelect,
  error,
  parentFocusId,
  value,
}) => {
  const onSelectCode = useCallback((selectedValue, allItem) => {
    onSelect(allItem?.item);
  }, [onSelect]);
  return (
    <ModifierAutocomplete
      allowClearOnSelect={false}
      type
      disabled={!editable}
      className={classNames('non-editable width-100-px', !editable, { 'error-border': !!error })}
      name={null}
      labelSpan="0"
      inputSpan="24"
      label={label}
      form={form}
      initialValue={initialValue}
      params={{}}
      minCharLength={2}
      selectProps={{
        id: `${parentFocusId}_search_autocomplete`,
        onChange: onSelectCode,
      }}
      value={value}
      showTotalCount
    />
  );
};

const EditableInputCell = ({
  value,
  label,
  required,
  editable,
  name,
  onChange,
  error,
  parentFocusId,
}) => (
  <Input
    disabled={!editable}
    className={classNames('non-editable', !editable, { 'error-border': !!error })}
    name={name}
    value={value}
    labelSpan="0"
    inputSpan="16"
    label={label}
    required={required}
    onChange={onChange}
    inputProps={{
      id: `${parentFocusId}_input`,
    }}
  />
);

const ModifierComponent = ({
  labels, modifier, onChange, form, isEdit, dataError, parentFocusId, onDeleteModifier = () => {
    // This is intentional
  },
}) => (
  <>
    <td>
      <div className="field-row flex align-center flex-dir-column search-modifier-contract">
        <EditableModifierCell
          editable={isEdit}
          label={labels.get('labels.modifier')}
          required
          labels={labels}
          form={form}
          initialValue={get(modifier, 'modifierId') && {
            name: get(modifier, 'modifierCPTCode'),
            value: get(modifier, 'modifierId'),
            modifierCode: get(modifier, 'modifierCPTCode'),
            description: get(modifier, 'modifierDescription'),
            tier: get(modifier, 'modifierTier'),
          }}
          onSelect={onChange('modifierCPTCode')}
          error={dataError.modifierCPTCode}
          parentFocusId={parentFocusId}
          value={modifier?.modifierId}
        />
      </div>
    </td>
    <td>
      <EditableInputCell name={null} editable={false} value={get(modifier, 'modifierDescription')} />
    </td>
    <td>
      <EditableInputCell name={null} editable={false} value={get(modifier, 'modifierTier')} />
    </td>
    <td>
      <EditableInputCell
        name={null}
        editable={isEdit}
        label={labels.get('labels.multiplierValue')}
        form={form}
        value={get(modifier, 'multiplier')}
        required
        onChange={onChange('multiplier')}
        error={dataError.multiplier}
        parentFocusId={parentFocusId}
      />
    </td>
    <td>
      <Icon name={IconNames.deleteIcon} className="cursor-pointer" onClick={onDeleteModifier} />
    </td>
  </>
);

export default ModifierComponent;
