import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';

const PopOver = ({
  children, content, placement, ...otherProps
}) => (
  <Popover content={content} placement={placement} {...otherProps}>
    {children}
  </Popover>
);

PopOver.defaultProps = {
  trigger: 'click',
  content: null,
  placement: '',
  children: [],
};

PopOver.propTypes = {
  trigger: PropTypes.string,
  content: PropTypes.node,
  placement: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default PopOver;
