import React from 'react';
import { Modal } from 'antd';
import Icon from '../../../../../../../../components/Icon';

const GlobalPeriodModal = (error, postCall, payload, clearError) => Modal.warning({
  onOk: (close) => {
    localStorage.setEncryptedData('procedureSuppressWarning', 'true');
    postCall({
      data: {
        ...payload,
        suppressWarning: true,
      },
    });
    close();
  },
  onCancel: () => {
    clearError();
  },
  okText: 'ok',
  title: 'Warning',
  closable: true,
  afterClose: () => { clearError(true); },
  content: error,
  icon: <Icon name="ExclamationCircleOutlined" />,
});

export default GlobalPeriodModal;
