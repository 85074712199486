import React from 'react';

import '../../financial.scss';

const columns = (labels, isCustomized) => [
  {
    Header: labels.get('tableColumns.balance'),
    accessor: 'type',
    Footer: ({ data }) => {
      if (data?.length) { return <div className={`${isCustomized ? 'financial-footer' : ''}`}>Account</div>; }
      return <div />;
    },
    fixWidth: '140',
    className: 'text-align-left',
  },
  {
    Header: labels.get('tableColumns.total'),
    accessor: 'totalDue',
    Cell: ({ row: { original } }) => (original?.totalDue?.toFixed(2)),
    Footer: ({ data }) => {
      let totalDueSum = 0;
      if (data?.length) data.forEach(({ totalDue }) => { totalDueSum += totalDue; return 0; });
      return data?.length ? <div className="financial-footer text-align-right">{totalDueSum?.toFixed(2)}</div> : <div />;
    },
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.unappliedCredit'),
    accessor: 'unAppliedCredit',
    Cell: ({ row: { original } }) => (original?.unAppliedCredit?.toFixed(2)),
    Footer: ({ data }) => {
      let unAppliedCreditSum = 0;
      if (data?.length) {
        data.map(({ unAppliedCredit }) => { unAppliedCreditSum += unAppliedCredit; return 0; });
      }
      return data?.length ? <div className="financial-footer text-align-right">{unAppliedCreditSum?.toFixed(2)}</div> : <div />;
    },
    fixWidth: '120',
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.unbilled'),
    accessor: 'unbilled',
    Cell: ({ row: { original } }) => (original?.unbilled?.toFixed(2)),
    Footer: ({ data }) => {
      let unbilledSum = 0;
      if (data?.length) data.forEach(({ unbilled }) => { unbilledSum += unbilled; return 0; });
      return data?.length ? <div className="financial-footer text-align-right">{unbilledSum?.toFixed(2)}</div> : <div />;
    },
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.current'),
    accessor: 'current',
    Cell: ({ row: { original } }) => (original?.current?.toFixed(2)),
    Footer: ({ data }) => {
      let currentSum = 0;
      if (data?.length) data.forEach(({ current }) => { currentSum += current; return 0; });
      return data?.length ? <div className="financial-footer text-align-right">{currentSum?.toFixed(2)}</div> : <div />;
    },
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.gt30days'),
    accessor: 'greaterThanThirty',
    Cell: ({ row: { original } }) => (original?.greaterThanThirty?.toFixed(2)),
    Footer: ({ data }) => {
      let greaterThanThirtySum = 0;
      if (data?.length) {
        data.forEach(({ greaterThanThirty }) => {
          greaterThanThirtySum += greaterThanThirty;
          return 0;
        });
      }
      return data?.length ? <div className="financial-footer text-align-right">{greaterThanThirtySum?.toFixed(2)}</div> : <div />;
    },
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.gt60days'),
    accessor: 'greaterThanSixty',
    Cell: ({ row: { original } }) => (original?.greaterThanSixty?.toFixed(2)),
    Footer: ({ data }) => {
      let greaterThanSixtySum = 0;
      if (data?.length) {
        data.forEach(({ greaterThanSixty }) => {
          greaterThanSixtySum += greaterThanSixty; return 0;
        });
      }
      return data?.length ? <div className="financial-footer text-align-right">{greaterThanSixtySum?.toFixed(2)}</div> : <div />;
    },
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.gt90days'),
    accessor: 'greaterThanNinty',
    Cell: ({ row: { original } }) => (original?.greaterThanNinty?.toFixed(2)),
    Footer: ({ data }) => {
      let greaterThanNintySum = 0;
      if (data?.length) {
        data.forEach(({ greaterThanNinty }) => {
          greaterThanNintySum += greaterThanNinty; return 0;
        });
      }
      return data?.length ? <div className="financial-footer text-align-right">{greaterThanNintySum?.toFixed(2)}</div> : <div />;
    },
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.gt120days'),
    accessor: 'greaterThanOneTwenty',
    Cell: ({ row: { original } }) => (original?.greaterThanOneTwenty?.toFixed(2)),
    Footer: ({ data }) => {
      let greaterThanOneTwentySum = 0;
      if (data?.length) {
        data.forEach(({ greaterThanOneTwenty }) => {
          greaterThanOneTwentySum += greaterThanOneTwenty; return 0;
        });
      }
      return data?.length ? <div className="financial-footer text-align-right">{greaterThanOneTwentySum?.toFixed(2)}</div> : <div />;
    },
    className: 'text-align-right',
  },
];

export default columns;
