import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './app';
import { store } from './store';
import './lib/localStorageEncryption';

import * as serviceWorker from './serviceWorker';

const version = localStorage.getDecryptedData('migrationVersion');
if (!version) {
  localStorage.clear();
  localStorage.setEncryptedData('migrationVersion', '1');
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
