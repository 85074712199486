import React, { useCallback, useState } from 'react';
import className from 'classnames';

import { apiUrls } from '../../../../api/constants';
import SuccessMessages from '../../../../lib/successMessages';

import Form from '../../../../components/Form';
import Button from '../../../../components/Button';
import Input from '../../../../components/Form/Input';
import CheckBox from '../../../../components/Form/CheckBox';
import Notification from '../../../../components/Notification';
import useRights from '../../../../hooks/useRights';
import rights from '../../../../lib/rights';
import Tooltip from '../../../../components/Tooltip';

export const CptIcdButtons = ({ navigateBack }) => (
  <div className="group-btns">
    <Button className="btn min-wt-86 sm-btn" id="patients_add_cancel" onClick={navigateBack}>Cancel</Button>
    <Button className="btn btn-success min-wt-86 sm-btn" id="patients_add_save" type="submit">Save</Button>
  </div>
);

export const CptIcdFormColumns = ({ label, name, isCptCode }) => {
  const [globalPeriod, setGlobalPeriod] = useState(null);
  const handleGlobalPeriodChange = (e) => {
    const { value } = e.target;
    if (value.length > 3 || /[^\d]/.test(value)) {
      e.target.value = globalPeriod;
    } else {
      setGlobalPeriod(value);
    }
  };

  const [
    isAccessToAddOrEditGlobalPeriod,
  ] = useRights([
    rights.access_to_add_or_edit_global_period,
  ]);

  return (
    <div className="main-form-container">
      <div className="add-patient-cl">
        <div className="demographic-wrap">
          <div className="group-shadow">
            <Form.Section noOfColumns={1}>
              <Form.Column>
                <Input label={label} name={name} />
              </Form.Column>
              <Form.Column>
                <Input label="Description" name="description" />
              </Form.Column>
              <Form.Column>
                <CheckBox label="Active" name="isActive" valuePropName="checked" />
              </Form.Column>
              { isCptCode && isAccessToAddOrEditGlobalPeriod && (
                <Form.Column>
                  <Input
                    value={globalPeriod}
                    maxValueLength={3}
                    label="Global Period (in days)"
                    placeholder="Global Period"
                    name="globalPeriod"
                    onInput={handleGlobalPeriodChange}
                  />
                </Form.Column>
              )}
              { isCptCode && isAccessToAddOrEditGlobalPeriod ? (
                <>
                  <Form.Column>
                    <CheckBox label="Global Period is Eye-Specific" name="isGlobalPeriodEyeSpecific" valuePropName="checked" />
                  </Form.Column>
                  <Form.Column>
                    <Tooltip title="This will impacts on Doctor's App">
                      <CheckBox label="Apply to Doctor App" name="shouldImpactDoctor" valuePropName="checked" />
                    </Tooltip>
                  </Form.Column>
                </>
              ) : null}
            </Form.Section>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddCPT = ({
  form, navigateBack, headingVisible,
}) => {
  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.CPT_ADDED_SUCCESSFULLY, success: true });
      navigateBack();
    }
  }, [navigateBack]);
  return (
    <div className="main-content-area">
      <Form
        form={form}
        section
        scrollToFirstErrors
        formId="ADD_CPT"
        url={apiUrls.ADD_CPT}
        listId="ADD_CPT"
        onRequestComplete={onRequestComplete}
      >
        <div className={className('heading-area flex align-center add-payer-screen',
          { 'justify-content-sp-bt': headingVisible, 'justify-content-flex-end': !headingVisible })}
        >
          {headingVisible && <h2>Add CPT</h2>}
          <CptIcdButtons navigateBack={navigateBack} />
        </div>
        <CptIcdFormColumns label="CPT Code" name="cptCode" isCptCode />
      </Form>
    </div>
  );
};

export default AddCPT;
