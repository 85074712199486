import React from 'react';
import moment from 'moment';
import Link from '../../../../../components/SavedNavigationBar/Link';
import { UiRoutes, dateFormat } from '../../../../../lib/constants';

const Columns = () => [
  {
    Header: 'Contract Name',
    accessor: 'name',
    Cell: ({
      row: {
        original: { name, contractId },
      },
    }) => (
      <Link
        to={UiRoutes.editContractWithTabId}
        className="blue-link"
        params={{ id: contractId, tab: 'general', data: { name } }}
      >
        {name}
      </Link>
    ),
  },
  {
    Header: 'Effective From',
    accessor: 'effectiveFrom',
    Cell: ({
      row: {
        original: { effectiveFrom },
      },
    }) => moment(effectiveFrom).format(dateFormat),
  },
  {
    Header: 'Effective To',
    accessor: 'effectiveTo',
    Cell: ({
      row: {
        original: { effectiveTo },
      },
    }) => (effectiveTo ? moment(effectiveTo).format(dateFormat) : '-'),
  },
  {
    Header: 'Status',
    accessor: 'isActive',
    Cell: ({
      row: {
        original: { isActive },
      },
    }) => (isActive ? (
      <span className="active">Active</span>
    ) : (
      <span className="inactive">Inactive</span>
    )),
  },
];

export default Columns;
