import React, { useCallback, useEffect } from 'react';
import { Form as AntdFrom } from 'antd';

import {
  formName, formId, labelPaths, listId, UiRoutes,
} from '../../../../lib/constants';
import formFieldValueParser from '../../../../lib/formFieldValuesParser';
import SuccessMessages from '../../../../lib/successMessages';
import { apiUrls } from '../../../../api/constants';

import withLabel from '../../../../hoc/withLabel';
import useRedirect from '../../../../hooks/useRedirect';
import useReduxState from '../../../../hooks/useReduxState';
import Button from '../../../../components/Button';
import Form from '../../../../components/Form';
import Notification from '../../../../components/Notification';

import GeneralForm from '../Components/GeneralForm';
import useTabLink from '../../../../hooks/useTabLink';

const parser = (userInfo) => {
  const parsedValues = formFieldValueParser(userInfo, {
    int: [
      'primaryPhoneExt',
      'secondaryPhoneExt',
      'zip',
      'scriptsureId',
    ],
    date: ['dateOfBirth', 'dateOfSendInvitation'],
    bool: ['isActive'],
  });
  return { ...parsedValues, locationIds: parsedValues?.locationIds?.join(','), roleIds: parsedValues?.roleIds?.join(',') };
};

const initialDataParser = (userInfo) => {
  const parsedValues = formFieldValueParser(userInfo, {
    date: ['dateOfBirth', 'dateToSendInvitation'],
    bool: ['isActive'],
  });
  return {
    ...parsedValues,
    confirmEmail: parsedValues?.email,
    genderId: parsedValues?.genderId ? parsedValues?.genderId : null,
    locationIds: userInfo?.locationDetail?.map((item) => item?.locationId),
    dateToSendInvitationId: parsedValues?.dateToSendInvitationId
      ? parsedValues?.dateToSendInvitationId : null,
    roleIds: parsedValues?.childRoleIds,
  };
};

function General({ labels }) {
  const [form] = AntdFrom.useForm();
  const {
    params: { tabId, id }, params, generatePath, push,
  } = useRedirect();
  const [userFormData, setUserFormData] = useReduxState(`edit-user-formData-${id}`, null);
  const [emailDisable, setEmailDisable] = useReduxState(`edit-user-emailDisable-${id}`, false);
  const { navigate: navigateToEditProvider } = useTabLink({
    to: UiRoutes.editProviderWithTabId,
  });

  useEffect(() => {
    const data = JSON.parse(localStorage.getDecryptedData('providerFormData'));
    if (data?.email) {
      setEmailDisable(true);
      localStorage.removeEncryptedData('providerFormData');
    }
  }, []);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.USER_UPDATED_SUCCESSFULLY, success: true });
    }
  }, []);

  const onGetResponseComplete = useCallback((user = {}) => {
    setUserFormData({ ...user });
  }, [setUserFormData]);

  const navigateToAddProvider = useCallback(() => {
    localStorage.setEncryptedData('userFormData', JSON.stringify(userFormData));
    if (!userFormData?.providerId) {
      push({ pathname: generatePath(UiRoutes.newProvider) });
    } else {
      navigateToEditProvider({
        ...params,
        id: userFormData?.providerId,
        data: {
          name: userFormData?.firstName || `${userFormData?.middleName}` || `${userFormData?.lastName}` || '',
          showFullTitle: true,
        },
      });
    }
  }, [generatePath, navigateToEditProvider, params, push, userFormData]);

  return (
    <div className="main-content-area">
      <Form
        section
        form={form}
        parser={parser}
        url={`${apiUrls.UPDATE_USER}/${id}`}
        getUrl={`${apiUrls.GET_USER}/${id}`}
        scrollToFirstError
        name={formName.usersForm}
        tabId={tabId}
        listId={listId.SETTINGS_USERS}
        formId={formId.EDIT_USER_GENERAL}
        onRequestComplete={onRequestComplete}
        initialDataParser={initialDataParser}
        isUpdate
        onGetResponseComplete={onGetResponseComplete}
      >
        {({ resetForm }) => (
          <>
            <GeneralForm
              form={form}
              labels={labels}
              formData={userFormData}
              emailDisable={emailDisable}
            />
            <div className="btns-action">
              <Button className="btn btn-success min-wt-86" type="submit" id="users_general_update">{labels.get('buttons.update')}</Button>
              <Button className="btn" onClick={resetForm} id="users_general_cancel">{labels.get('buttons.clear')}</Button>
              {!emailDisable && <Button className="btn" onClick={navigateToAddProvider} id="users_general_cancel">{labels.get('buttons.addUserProvider')}</Button>}
            </div>
          </>
        )}
      </Form>
    </div>
  );
}

export default withLabel(General, labelPaths.EDIT_USER_GENERAL);
