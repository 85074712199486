import React, {
  useCallback, useState, useMemo, useEffect,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import classNames from 'classnames';
import get from 'lodash/get';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';

import { apiUrls } from '../../../../../api/constants';

import withReduxManager from '../../../../../hoc/withReduxManager';
import withQuery from '../../../../../hoc/withQuery/withQuery';
import { getEnumMasterData } from '../../../../../store/actions/enum';

import FilterManager from '../../../../../components/FilterManager';
import FilterComponents from '../../../../../components/FilterComponents';
import Button from '../../../../../components/Button';

import useRedirect from '../../../../../hooks/useRedirect';
import useRights from '../../../../../hooks/useRights';

import columns from './columns';
import NewIncidentModal from '../Components/NewIncidentModal';
import CollectionOtherAdditionalFilters from './CollectionOtherAdditionalFilters';
import {
  listId, UiRoutes, enums,
} from '../../../../../lib/constants';
import rights from '../../../../../lib/rights';
import Events from '../../../../../lib/events';
import * as selectors from '../../../../../store/selectors';
import { setFilters } from '../../../../../store/actions/queryApi';

const initialSort = [{ id: 'name', desc: false }];

const CustomCollectionsTable = withReduxManager({
  listId: listId.COLLECTIONS,
})(withQuery({
  url: apiUrls.GET_COLLECTIONS_LIST,
  listId: listId.COLLECTIONS,
})());

const TypeFilters = [
  {
    placeholder: 'New',
    name: 'new',
    id: 'collections_ready',
  },
  {
    placeholder: 'In Progress',
    name: 'inProgress',
    id: 'collections_in_progress',
  },
  {
    placeholder: 'Needs Action',
    name: 'needsAction',
    id: 'collection_needs_action',
  },
  // {
  //   placeholder: 'Small Balance',
  //   name: 'smallBalance',
  //   id: 'collection_small_balance',
  // },
  {
    placeholder: 'Completed',
    name: 'completed',
    id: 'collection_completed',
  },
];

const FilterCollectionSearch = FilterComponents([
  {
    type: 'search',
    filterProps: {
      placeholder: 'Search by DOS, Claim Number, Chart Number, Patient Name',
      name: 'SearchText',
      id: 'collection_filter_search',
      style: {
        minWidth: 340,
      },
      dataTestId: 'searchFilter',
    },
  },
]);

const keysToMapStage = {
  BillingStageDenial: 'denial',
  BillingStageManual: 'manual',
  BillingStageNoResponse: 'noResponse',
};

const keysToMapState = {
  BillingStateNew: 'new',
  BillingStateInProgress: 'inProgress',
  BillingStateNeedAction: 'needsAction',
  BillingStateSmallBalance: 'smallBalance',
  BillingStateCompleted: 'completed',
};

const ADDITIONAL_FILTER_PAYER = 'Payer';
const ADDITIONAL_FILTER_LOCATION = 'Location';
const ADDITIONAL_FILTER_AGING = 'Aging';
const ADDITIONAL_FILTER_PROVIDER = 'Provider';
const ADDITIONAL_FILTER_RESPONSIBILITY = 'Responsibility';
const ADDITIONAL_FILTER_DENIAL_TYPE = 'Denial Type';
const ADDITIONAL_FILTER_DENIAL_NAME = 'Denial Name';
const ADDITIONAL_FILTER_PAYER_TYPE = 'Payer Type';
const ADDITIONAL_FILTER_INCIDENT_DATE = 'Incident Date';

const CollectionAdditionalFilters = ({
  filters, options, setAdditionalOptions, children,
}) => {
  useEffect(() => {
    const groupByCode = get(filters, 'GroupBy');
    const newOptions = options?.filter((item) => item?.masterName !== 'Type');
    const filteredOptions = newOptions?.filter((item) => item?.masterName !== groupByCode);
    setAdditionalOptions(filteredOptions || []);
  }, []);

  return children;
};

const TableWrapper = ({
  reFetch, Component, filters,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setFilters({ SearchText: filters?.SearchText }, listId.COLLECTION_CLAIMS));
    Events.on('reFetchCollectionManualData', 'reFetchCollectionManualData', reFetch);
    return () => Events.remove('reFetchCollectionManualData', 'reFetchCollectionManualData');
  }, []);

  return Component;
};

const CollectionTable = ({
  labels, enumOptions, enumMaster, isSetSearchData, ...props
}) => {
  const {
    params, push, generatePath, replace,
  } = useRedirect();
  const { claimFilter } = params;
  const [activeColumns, setActiveColumns] = useState(claimFilter || 'new');
  const [selectedAdditionFilter, setSelectedAdditionalFilter] = useState(null);
  const [additionalOptions, setAdditionalOptions] = useState(null);
  const [groupByFilterOptions, setGroupByOptions] = useState(null);
  const [initialDataFilters, setInitialDataFilters] = useState({});
  const dispatch = useDispatch();
  const enumId = get(enumMaster, `${enums.COLLECTION_GROUPING}.enumId`);
  const [isCreateIncidentAuthenticated] = useRights([rights.create_manual_incident]);

  useEffect(() => {
    dispatch(getEnumMasterData(enumId));
  }, [enumId, dispatch]);

  const { state, stage, queueId } = useMemo(() => {
    const stateIds = {};
    const stageIds = {};
    let queueIdClaims = '';

    if (!isEmpty(enumMaster) && !isEmpty(enumOptions)) {
      const { BillingStage, BillingState, ClaimQueue } = pick(enumMaster,
        [enums.BILLING_STATE, enums.BILLING_STAGE], enums.CLAIMS_QUEUE);
      queueIdClaims = enumOptions[ClaimQueue?.enumId]?.data?.find((item) => item.masterCode?.toLowerCase() === 'collectionqueue')?.masterId;

      enumOptions[BillingStage?.enumId]?.data?.forEach((item) => {
        if (keysToMapStage[item?.masterCode]) {
          stageIds[keysToMapStage[item?.masterCode]] = item?.masterId;
        }
      });
      enumOptions[BillingState?.enumId]?.data?.forEach((item) => {
        if (keysToMapState[item?.masterCode]) {
          stateIds[keysToMapState[item?.masterCode]] = item?.masterId;
        }
      });
    }
    return { state: stateIds, stage: stageIds, queueId: queueIdClaims };
  }, [enumMaster, enumOptions]);

  const options = useMemo(() => {
    if (enumOptions[enumId]?.data?.length) {
      return enumOptions[enumId].data.map((item) => {
        const cloneItem = item;
        cloneItem.name = item.masterName;
        cloneItem.value = item.masterName;
        return cloneItem;
      });
    }
    return [];
  }, [enumOptions]);

  const [isModalVisible, setModalVisibility] = useState(false);
  const stateId = state[activeColumns];

  const onChangeStatus = useCallback((e) => {
    const { target: { name } } = e;
    setActiveColumns(name);
    replace(generatePath(UiRoutes.collectionClaimsFilters, { ...params, claimFilter: name }));
  }, [generatePath, params, replace]);

  const onRowClick = useCallback((type, id, prevFilter, agingMasterCode) => {
    push(generatePath(UiRoutes.collectionClaimsAdditionalFilters,
      {
        ...params,
        type,
        groupByFilter: prevFilter?.GroupBy || 0,
        collectionId: id || 0,
        agingMasterCode: agingMasterCode || 0,
        selectedAdditionFilter: prevFilter?.additionalFilter || 0,
        filterId: prevFilter?.filterId || 0,
        denialCategoryCode: prevFilter?.denialCategoryCode || 0,
      }));
  }, [push, generatePath, params]);

  const setAdditionalFilter = useCallback((filter) => {
    if (filter === ADDITIONAL_FILTER_LOCATION) {
      setSelectedAdditionalFilter('location');
    } else if (filter === ADDITIONAL_FILTER_PAYER) setSelectedAdditionalFilter('payer');
    else if (filter === ADDITIONAL_FILTER_AGING) setSelectedAdditionalFilter('aging');
    else if (filter === ADDITIONAL_FILTER_PROVIDER) setSelectedAdditionalFilter('provider');
    else if (filter === ADDITIONAL_FILTER_RESPONSIBILITY) setSelectedAdditionalFilter('responsibility');
    else if (filter === ADDITIONAL_FILTER_DENIAL_TYPE) setSelectedAdditionalFilter('denialType');
    else if (filter === ADDITIONAL_FILTER_DENIAL_NAME) setSelectedAdditionalFilter('denialName');
    else if (filter === ADDITIONAL_FILTER_PAYER_TYPE) setSelectedAdditionalFilter('payerType');
    else if (filter === ADDITIONAL_FILTER_INCIDENT_DATE) setSelectedAdditionalFilter('incidentDate');
    else {
      setSelectedAdditionalFilter();
    }
  }, []);

  const additionalFilterHandler = useCallback((currentFilter, onFiltersChange, filters) => {
    const arr = ['GroupBy', 'additionalFilter'];
    Object.keys(currentFilter)
      .filter((key) => !arr.includes(key))
      // eslint-disable-next-line no-param-reassign
      .forEach((key) => delete currentFilter[key]);

    const filter = currentFilter?.additionalFilter;
    const filteredOptions = options.filter((item) => item.masterCode !== filter);
    setGroupByOptions(filteredOptions);
    setAdditionalFilter(filter);
    const clonedFilters = currentFilter;
    clonedFilters.additionalFilter = filter;
    onFiltersChange({
      ...initialDataFilters,
      SearchText: filters?.SearchText,
      ...clonedFilters,
    }, true);
  }, [initialDataFilters, options, setAdditionalFilter]);

  const onChangeGroupBy = useCallback((currentFilter, onFiltersChange, filters) => {
    const groupByCode = get(currentFilter, 'GroupBy');
    const arr = ['GroupBy'];
    Object.keys(currentFilter)
      .filter((key) => !arr.includes(key))
      // eslint-disable-next-line no-param-reassign
      .forEach((key) => delete currentFilter[key]);
    const newOptions = options.filter((item) => item.masterName !== 'Type');
    const filteredOptions = newOptions.filter((item) => item.masterName !== groupByCode);
    setAdditionalOptions(filteredOptions);
    onFiltersChange({
      ...initialDataFilters,
      SearchText: filters?.SearchText,
      ...currentFilter,
    }, true);
  }, [initialDataFilters, options]);

  const searchHandler = useCallback((currentFilter, onFiltersChange, filters) => {
    const clonedFilters = filters;
    clonedFilters.SearchText = currentFilter?.target?.value || null;
    if (isSetSearchData) {
      dispatch(setFilters({ SearchText: clonedFilters.SearchText }, listId.COLLECTION_CLAIMS));
    }
    onFiltersChange(clonedFilters, true);
  }, [dispatch, isSetSearchData]);

  const toggleIncidentModal = useCallback(() => {
    setModalVisibility(!isModalVisible);
  }, [isModalVisible]);

  const FilterCollectionsAdditionalFilter = FilterComponents([
    {
      type: 'select',
      filterProps: {
        placeholder: 'Select An Option',
        name: 'additionalFilter',
        id: 'select_additional_filter',
        label: 'Additional Filter',
        options: additionalOptions || options.filter((item) => item.masterName !== 'Type'),
        selectProps: {
          style: {
            minWidth: 150,
            marginLeft: 10,
          },
        },
        dataTestId: 'additionalFilter',
      },
    },
  ]);

  const FilterCollections = FilterComponents([
    {
      type: 'select',
      filterProps: {
        placeholder: 'Select An Option',
        name: 'GroupBy',
        id: 'collection_group_select',
        valueAccessor: 'masterName',
        label: 'Group by',
        options: groupByFilterOptions || options,
        selectProps: {
          style: {
            minWidth: 150,
            marginLeft: 10,
          },
        },
        dataTestId: 'groupByFilter',
      },
    },
  ]);

  useEffect(() => {
    if (queueId && stateId && stage?.noResponse) {
      setInitialDataFilters({
        QueueId: queueId,
        StateId: stateId,
        BillingStages: `${stage?.noResponse},${stage?.denial},${stage?.manual}`,
      });
    }
  }, [queueId, stateId, stage?.noResponse]);

  return (
    <>
      <CustomCollectionsTable
        columns={columns?.(labels, onRowClick)}
        initialSort={initialSort}
        noDataText="Collections not found"
        skipInitialFetch
        {...props}
      >
        {({
          Component, initialFilters, onFiltersChange, otherTableData, reFetch,
        }) => (
          <FilterManager initialFilters={initialFilters} onChange={onFiltersChange}>
            {({ onFilterChange, filters }) => (
              <>
                <div className="heading-area flex">
                  { TypeFilters.map((value, index) => (
                    <button
                      type="button"
                      name={value.name}
                      className={classNames('btn btn-primary sm-btn filter-button', claimFilter === value.name ? 'active' : '')}
                      key={index + 1}
                      onClick={onChangeStatus}
                      data-testid={value.name}
                    >
                      {value.placeholder}
                    </button>
                  ))}
                </div>
                <div className="table-filters mr-top-8 collections-filters mobile-layout-filter">
                  <div className="flex">
                    <FilterCollectionSearch
                      onFilterChange={
                    (currentFilter) => searchHandler(currentFilter, onFilterChange, filters)
                      }
                      filters={filters}
                      allowClear
                    />
                    <FilterCollections
                      onFilterChange={
                    (currentFilter) => {
                      setSelectedAdditionalFilter(false);
                      onChangeGroupBy(currentFilter, onFilterChange, filters);
                    }
                    }
                      filters={filters}
                    />
                    <CollectionAdditionalFilters
                      filters={filters}
                      options={options}
                      setAdditionalOptions={setAdditionalOptions}
                    >
                      <FilterCollectionsAdditionalFilter
                        onFilterChange={(currentFilter) => (
                          additionalFilterHandler(currentFilter, onFilterChange, filters)
                        )}
                        filters={filters}
                      />
                    </CollectionAdditionalFilters>

                    <CollectionOtherAdditionalFilters
                      labels={labels}
                      filters={filters}
                      stage={stage}
                      queueId={queueId}
                      stateId={stateId}
                      selectedAdditionFilter={selectedAdditionFilter}
                      onFiltersChange={onFilterChange}
                      setAdditionalFilter={setAdditionalFilter}
                    />

                  </div>
                  <div className="feild-row">
                    <div className="btns-action mr-bt-16">
                      {isCreateIncidentAuthenticated && <Button className="btn btn-success min-wt-86 sm-btn" onClick={toggleIncidentModal}>{labels.get('buttons.newIncident')}</Button>}
                    </div>
                    <span className="table-count">
                      <span>{`${labels.get('titles.totalCount')}: ${otherTableData?.totalClaimCount || 0}`}</span>
                    </span>
                  </div>
                </div>
                <TableWrapper
                  Component={Component}
                  reFetch={reFetch}
                  filters={filters}
                />
              </>
            )}
          </FilterManager>
        )}
      </CustomCollectionsTable>
      <NewIncidentModal
        isVisible={isModalVisible}
        toggleIncidentModal={toggleIncidentModal}
        labels={labels}
      />
    </>
  );
};

export default connect((states) => ({
  enumOptions: selectors.getEnumOptions(states),
  enumMaster: selectors.getEnumMaster(states),
}))(CollectionTable);
