import React, { useCallback } from 'react';
import { Tooltip } from 'antd';

import Events from '../../../lib/events';
import { dateFormat, timeFormat12 } from '../../../lib/constants';
import { statusBasedClasses, statusBasedTexts } from '../../../lib/schedulerHelpers';

import TimeCell from '../../../components/Cells/TimeCell';

import StatusDropDown from '../StatusDropDown';

const columns = () => [
  {
    Header: 'Date',
    accessor: 'appointmentStartDateTime',
    sort: true,
    Cell: ({
      row:
      { original: { appointmentStartDateTime } },
    }) => (
      <TimeCell
        format={dateFormat}
        value={appointmentStartDateTime?.dateString}
      />
    ),
  },
  {
    Header: 'Time',
    accessor: 'timeString',
    sort: true,
    Cell: ({
      row:
      { original: { appointmentStartDateTime } },
    }) => (
      <TimeCell
        format={timeFormat12}
        value={appointmentStartDateTime?.dateString}
      />
    ),
  },
  {
    Header: 'Patient Name',
    accessor: 'patientName',
    sort: true,
  },
  {
    Header: 'Location',
    accessor: 'locationName',
    sort: true,
  },
  {
    Header: 'Provider',
    accessor: 'providerFirstName',
    sort: true,
  },
  {
    Header: 'Test Type',
    accessor: 'examType',
    Cell: ({ row: { original: { examType } } }) => <div>{examType && examType.length > 0 ? examType[0].examName : ''}</div>,
    sort: true,
  },
  {
    Header: 'Visit Type',
    accessor: 'visitType',
    Cell: ({ row: { original: { visitType } } }) => <div>{visitType?.visitName || ''}</div>,
    sort: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    sort: true,
    className: 'status-cell-container',
    fixWidth: '115',
    Cell: ({ row: { original: { status = '', cancellation } } }) => (
      <Tooltip title={(
        <>
          <div>
            {status && `Status: ${statusBasedTexts[status]}`}
          </div>
          <div>
            {cancellation && cancellation.description && `Reason: ${cancellation.reason}`}
          </div>
          <div>
            {cancellation && cancellation.description && `Description: ${cancellation.description}`}
          </div>
        </>
        )}
      >
        <span className={statusBasedClasses[status]}>{statusBasedTexts[status]}</span>
      </Tooltip>
    ),
  },
  {
    Header: 'Co-Pay',
    accessor: 'copayment',
    Cell: ({
      row:
      { original: { copayment } },
    }) => ((copayment || 0).toFixed(2)),
  },
  {
    Header: 'Insurance',
    accessor: 'policyType',
  },
  {
    Header: 'Eligibility',
    accessor: 'insuranceVerificationStatus',
    sort: true,
  },
  {
    Header: 'Authorization Placed',
    accessor: 'authorizationNumber',
  },
  {
    Header: 'Phone Number',
    accessor: 'patientPrimaryPhone',
  },
  {
    Header: 'Confirmation',
    accessor: 'isConfirmed',
    Cell: ({ row: { original } }) => {
      const computedConfirmationMethod = original?.confirmationMethod ? `(${original?.confirmationMethod})` : '';
      return (
        <span>{`${original?.confirmationOutcome || ''} ${computedConfirmationMethod}`}</span>
      );
    },
  },
  {
    Header: 'Balance',
    accessor: 'balance',
    Cell: ({ row: { original: { balance } } }) => <div>{balance || '0.00'}</div>,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    fixWidth: '220',
    Cell: ({
      row,
      row: {
        original: {
          status, patientId, appointmentId, isCheckedIn,
        },
        original,
      },
      cancelAppointment, undoCancelAppointment, onTaskClick, isCancelAppointment,
    }) => {
      const rowProps = row.getToggleRowSelectedProps();
      rowProps.onClick = (e) => e.stopPropagation();
      const onAddConfirmation = useCallback(() => {
        Events.trigger('refetchSchedulerAppointmentListViewTable');
      }, []);
      return (
        <div {...rowProps} title="">
          {!isCheckedIn && (status !== 'Reschedule') && (
            <StatusDropDown
              patientId={patientId}
              appointmentId={appointmentId}
              onFinish={onAddConfirmation}
            >
              <button className="btn action-button" type="button">Confirm</button>
            </StatusDropDown>
          )}
          {(!isCheckedIn && (status === 'New' || status === 'CheckIn')) && isCancelAppointment && (
            <button className="btn action-button mr-left-5" type="button" onClick={(e) => cancelAppointment(e, original)}>
              Cancel
            </button>
          )}
          {!isCheckedIn && status === 'Cancel' && (
            <button className="btn action-button mr-left-5" type="button" onClick={(e) => undoCancelAppointment(e, original)}>
              Undo Cancel
            </button>
          )}
          {status !== 'Reschedule' && (
            <button className="btn action-button mr-left-5" type="button" onClick={onTaskClick({ patientId, appointmentId, original })}>Task</button>
          )}
        </div>
      );
    },
  },
];

export default columns;
