import React, {
  useState, useCallback, useEffect,
} from 'react';

import { enums as enumConstant } from '../../../lib/constants';
import { lengthCheck } from '../../../lib/util';
import ErrorMessages from '../../../lib/errorMessages';

import { apiUrls } from '../../../api/constants';
import Form from '../../../components/Form';
import Button from '../../../components/Button';
import PhoneNumberInput from '../../../components/Form/PhoneNumberInput';
import {
  Input, CheckBox, AutoComplete, SelectBox,
} from '../../../components/Form/inputFields';
import WiredSelect from '../../../wiredComponents/Select';
import { Select } from '../../../wiredComponents/Enum';

const placeOfServiceOptionParser = (opt) => (
  opt.map((item) => ({
    ...item,
    name: `${item.masterCode} ${item.masterName}`,
  }))
);

const options = [{ name: '1', value: 1 }, { name: '2', value: 2 }, { name: '3', value: 3 }, { name: '4', value: 4 }];

function General({
  labels, form, facilityTypeId, countryId, selectedEntity,
  showMfaxButton = false, calculateMfaxKeyExpiryStatus, handleKeyRegeneration,
}) {
  const [facilityType, setFacilityType] = useState(null);
  const [country, setCountry] = useState(form.getFieldValue('countryId') || null);

  useEffect(() => {
    if (facilityTypeId) {
      setFacilityType(facilityTypeId);
    }
  }, [facilityTypeId]);

  useEffect(() => {
    if (countryId) {
      setCountry(countryId);
    }
  }, [countryId]);

  const onZipCodeSelect = useCallback((option = {}) => {
    form.setFields([{ name: 'city', value: option.city }]);
    form.setFields([{ name: 'stateId', value: option.stateId }]);
    form.setFields([{ name: 'countryId', value: option.countryId }]);
    setCountry(option.countryId);
  }, [form]);

  const optionParser = useCallback((option) => ({
    name: option.zipCode,
    value: option.zipCode,
    ...option,
  }), []);

  return (
    <div className="form-responsive">
      <div className="demographic-wrap">
        <Form.Section noOfColumns={3}>
          <Form.Column>
            <div className="feild-row required-feilds top-feild-mng">
              <h2 className="h2-title">{labels.get('titles.basicInformation')}</h2>
            </div>
          </Form.Column>
          <Form.Column>
            <div className="feild-row required-feilds top-feild-mng">
              <h2 className="h2-title">{labels.get('titles.contactInformation')}</h2>
            </div>
          </Form.Column>
          <Form.Column>
            <div className="feild-row required-feilds top-feild-mng inline-right mr-bt-8">
              {showMfaxButton && (
                <Button
                  className={calculateMfaxKeyExpiryStatus ? 'btn sm-btn btn-success' : 'btn sm-btn cursor-not-allowed disabled-btn'}
                  disabled={!calculateMfaxKeyExpiryStatus}
                  onClick={handleKeyRegeneration}
                >
                  REGENRATE MFAX API KEY
                </Button>
              )}
            </div>
          </Form.Column>
        </Form.Section>
        <Form.Section noOfColumns={3}>
          <Form.Column>
            <div className="group-shadow">
              <Input
                label={labels.get('labels.name')}
                name="locationName"
                required
                labelSpan="8"
                inputSpan="16"
                charOnly
                maxValueLength={50}
              />
              <WiredSelect
                id="businessEntityId"
                label={labels.get('labels.businessEntity')}
                name="businessEntityId"
                placeholder={labels.get('placeholders.businessEntity')}
                required
                nameAccessor="entityName"
                valueAccessor="businessEntityId"
                url={apiUrls.GET_BUSINESS_ENTITY}
                inActiveOption={{
                  entityName: selectedEntity?.name,
                  businessEntityId: selectedEntity?.value,
                }}
                labelSpan="8"
                inputSpan="16"
              />
              <Select
                label={labels.get('labels.placeOfService')}
                name="placeofServiceId"
                placeholder={labels.get('placeholders.placeOfService')}
                required
                labelSpan="8"
                inputSpan="16"
                enumName={enumConstant.PLACE_OF_SERVICE}
                isOptionSortable={false}
                optionParser={placeOfServiceOptionParser}
              />
              <div>
                <Select
                  label={labels.get('labels.locationType')}
                  name="locationTypeId"
                  placeholder={labels.get('placeholders.locationType')}
                  required
                  labelSpan="8"
                  inputSpan="16"
                  enumName={enumConstant.LOCATION_TYPE}
                />
                <Select
                  label={labels.get('labels.facilityType')}
                  name="facilityTypeId"
                  placeholder={labels.get('placeholders.facilityType')}
                  labelSpan="8"
                  inputSpan="16"
                  required
                  enumName={enumConstant.FACILITY_TYPE}
                  onChange={(value) => {
                    setFacilityType(value);
                  }}
                />
                {facilityType && (
                <Select
                  label={labels.get('labels.facilitySubType')}
                  name="facilitySubTypeId"
                  placeholder={labels.get('placeholders.facilitySubType')}
                  labelSpan="8"
                  inputSpan="16"
                  enumName={enumConstant.FACILITY_SUB_TYPE}
                  parentId={facilityType}
                />
                )}
                <Input
                  label={labels.get('labels.locationDesignator')}
                  name="locationDesignator"
                  labelSpan="8"
                  inputSpan="16"
                  charOnly
                  maxValueLength={4000}
                />
                <Input
                  label={labels.get('labels.locationNumber')}
                  name="locationNumber"
                  labelSpan="8"
                  inputSpan="16"
                  charOnly
                  maxValueLength={4000}
                />
              </div>
            </div>
          </Form.Column>
          <Form.Column>
            <div className="group-shadow">
              <Input
                label={labels.get('labels.addressLine1')}
                name="addressLine1"
                labelSpan="8"
                inputSpan="16"
                required
                maxValueLength={100}
              />
              <Input
                label={labels.get('labels.addressLine2')}
                name="addressLine2"
                labelSpan="8"
                inputSpan="16"
                maxValueLength={100}
              />
              <AutoComplete
                label={labels.get('labels.zip')}
                name="zip"
                optionParser={optionParser}
                url={apiUrls.ZIP_LOOKUP}
                required
                labelSpan="8"
                inputSpan="16"
                onSelect={onZipCodeSelect}
                optionMaster="zipcode"
              />
              <Input
                  // disabled
                label={labels.get('labels.city')}
                name="city"
                required
                labelSpan="8"
                inputSpan="16"
              />
              <Select
                label={labels.get('labels.state')}
                name="stateId"
                placeholder={labels.get('placeholders.state')}
                required
                labelSpan="8"
                inputSpan="16"
                enumName={enumConstant.STATE}
                parentId={country}
              />
              <Select
                label={labels.get('labels.country')}
                name="countryId"
                placeholder={labels.get('placeholders.country')}
                required
                labelSpan="8"
                inputSpan="16"
                initialValue="United States"
                form={form}
                enumName={enumConstant.COUNTRY}
              />
              <Select
                label={labels.get('labels.locality')}
                name="localityId"
                placeholder={labels.get('placeholders.locality')}
                required
                labelSpan="8"
                inputSpan="16"
                enumName={enumConstant.LOCALITY}
              />
              <Input
                label={labels.get('labels.latitude')}
                name="latitude"
                labelSpan="8"
                inputSpan="16"
              />
              <Input
                label={labels.get('labels.longitude')}
                name="longitude"
                labelSpan="8"
                inputSpan="16"
              />
            </div>
          </Form.Column>
          <Form.Column>
            <div className="group-shadow">
              <div className="phone-group phone-group-validation together-fields loc-field-wrap">
                <PhoneNumberInput
                  label={labels.get('labels.primaryPhone')}
                  name="primaryPhone"
                  required
                  labelSpan="8"
                  inputSpan="10"
                  numberOnly
                  minValueLength={10}
                  maxValueLength={12}
                />
                <Input
                  label={labels.get('labels.primaryPhoneExt')}
                  name="primaryPhoneExt"
                  numberOnly
                  labelSpan="9"
                  inputSpan="15"
                  maxValueLength={8}
                />
              </div>
              <Select
                label={labels.get('labels.primaryPhoneType')}
                name="primaryPhoneTypeId"
                placeholder={labels.get('placeholders.primaryPhoneType')}
                required
                labelSpan="8"
                inputSpan="16"
                form={form}
                enumName={enumConstant.PHONE_TYPE}
                initialValue={form.getFieldValue('primaryPhoneTypeId') || 'Home'}
              />
              <div className="phone-group phone-group-validation together-fields loc-field-wrap">
                <PhoneNumberInput
                  label={labels.get('labels.secondaryPhone')}
                  name="secondaryPhone"
                  labelSpan="8"
                  inputSpan="10"
                  numberOnly
                  maxValueLength={12}
                  minValueLength={10}
                />
                <Input
                  label={labels.get('labels.secondaryPhoneExt')}
                  name="secondaryPhoneExt"
                  numberOnly
                  labelSpan="9"
                  inputSpan="15"
                  maxValueLength={8}
                />
              </div>
              <Select
                label={labels.get('labels.secondaryPhoneType')}
                name="secondaryPhoneTypeId"
                placeholder={labels.get('placeholders.secondaryPhoneType')}
                labelSpan="8"
                inputSpan="16"
                form={form}
                enumName={enumConstant.PHONE_TYPE}
                initialValue={form.getFieldValue('secondaryPhoneTypeId') || 'Home'}
              />
              <Select
                label={labels.get('labels.region')}
                name="regionId"
                placeholder={labels.get('placeholders.region')}
                labelSpan="8"
                inputSpan="16"
                form={form}
                enumName={enumConstant.REGION}
                required
              />
              <Input
                label={labels.get('labels.practiceId')}
                name="practiceId"
                numberOnly
                labelSpan="8"
                inputSpan="16"
                rules={[
                  () => lengthCheck(
                    8, ErrorMessages.VALUE_CANNOT_BE_LONGER_THAN_8_CHARACTERS,
                  ),
                ]}
              />
            </div>
          </Form.Column>
        </Form.Section>
        <Form.Section>
          <Form.Column>
            <div className="feild-row required-feilds top-feild-mng mr-top-12">
              <h2 className="h2-title">{labels.get('titles.generalInformation')}</h2>
            </div>
          </Form.Column>
        </Form.Section>
        <Form.Section noOfColumns={3}>
          <Form.Column>
            <div className="group-shadow">
              <Input
                label={labels.get('labels.fda')}
                name="fda"
                labelSpan="8"
                inputSpan="16"
                numberOnly
                maxValueLength={11}
                minValueLength={11}
              />
              <Input
                label={labels.get('labels.clia')}
                name="clia"
                labelSpan="8"
                inputSpan="16"
                numberOnly
                maxValueLength={10}
                minValueLength={10}
              />
              <Input
                label={labels.get('labels.npi')}
                name="npi"
                labelSpan="8"
                inputSpan="16"
                required
                maxValueLength={10}
              />
              <SelectBox
                label={labels.get('labels.verify')}
                placeholder={labels.get('placeholders.verify')}
                name="verify"
                options={options}
                labelSpan="8"
                inputSpan="16"
              />
            </div>
          </Form.Column>
          <Form.Column>
            <div className="group-shadow">
              <CheckBox label={labels.get('labels.autoConfirmation')} name="isAutoConfirmation" labelSpan="8" inputSpan="16" valuePropName="checked" />
              <CheckBox label={labels.get('labels.autoEligibilityCheck')} name="autoEligibilityCheck" labelSpan="8" inputSpan="16" valuePropName="checked" />
              <Select
                label={labels.get('labels.timezone')}
                name="timezone"
                placeholder={labels.get('placeholders.timezone')}
                labelSpan="8"
                inputSpan="16"
                enumName={enumConstant.TIMEZONE}
              />
              <Select
                label={labels.get('labels.schedule')}
                name="scheduleId"
                placeholder={labels.get('placeholders.schedule')}
                labelSpan="8"
                inputSpan="16"
                enumName={enumConstant.SCHEDULE}
              />
            </div>
          </Form.Column>
          <Form.Column>
            <div className="group-shadow">
              <CheckBox label={labels.get('labels.displayInScheduler')} name="isDisplayinScheduler" valuePropName="checked" labelSpan="8" inputSpan="16" />
              <CheckBox label={labels.get('labels.communityView')} name="isCommunityView" valuePropName="checked" labelSpan="8" inputSpan="16" />
              <CheckBox label={labels.get('labels.active')} labelSpan="8" name="isActive" valuePropName="checked" inputSpan="16" />
            </div>
          </Form.Column>
        </Form.Section>

      </div>
    </div>
  );
}

export default General;
