import React from 'react';
import {
  GoogleMap, Marker, withScriptjs, withGoogleMap,
} from 'react-google-maps';
import get from 'lodash/get';
import filter from 'lodash/filter';
import PropTypes from 'prop-types';

import './MapContainer.scss';
import { urls } from '../../../../lib/constants';

const Map = withScriptjs(withGoogleMap((props) => {
  const filteredAppointments = filter(props.appointmentData, (appointment) => {
    if (get(appointment, 'locationLatitude') && get(appointment, 'locationLongitude')) {
      return appointment;
    }
    return false;
  });
  const defaultCenter = { lat: parseFloat(get(filteredAppointments, '[0].locationLatitude', 32.8352)), lng: parseFloat(get(filteredAppointments, '[0].locationLongitude', -116.7659)) };
  return (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={defaultCenter}
    >
      {props.appointmentData
    && props.appointmentData.map((appointment, index) => (
      <Marker
        key={`marker-${index}`}
        position={{
          lat: parseFloat(appointment.locationLatitude),
          lng: parseFloat(appointment.locationLongitude),
        }}
      />
    ))}
    </GoogleMap>
  );
}));

const MapContainer = ({ appointmentData }) => (
  // Important! Always set the container height explicitly
  <Map
    googleMapURL={urls.MAP}
    loadingElement={<div />}
    containerElement={<div className="map-container" />}
    mapElement={<div className="map" />}
    isMarkerShown
    appointmentData={appointmentData}
  />
);

MapContainer.propTypes = {
  appointmentData: PropTypes.arrayOf(PropTypes.object),
};

MapContainer.defaultProps = {
  appointmentData: [],
};

export default MapContainer;
