import React, { useMemo } from 'react';

import { apiUrls } from '../../../../../api/constants';
import useRedirect from '../../../../../hooks/useRedirect';
import withQuery from '../../../../../hoc/withQuery/withQuery';
import { listId } from '../../../../../lib/constants';
import TableWrapper from '../../../../../components/Table/TableWrapper';

import columns from './columns';
import '../errorPopupTable.scss';

const initialSort = [{ id: 'name', desc: false }];

const PatientTable = ({
  labels, setSelectedRows, setRefetchErrors, ...props
}) => {
  const {
    params: { claimNumber }, params,
  } = useRedirect();

  const CustomClaimsErrorTable = useMemo(() => withQuery({
    url: `${apiUrls.GET_ERRORS}/${claimNumber}`,
    listId: listId.CLAIMS_ERRORS,
    accessor: (data) => ({ result: data }),
  })(), [claimNumber]);

  return (
    <div className="error-popup-table">
      <CustomClaimsErrorTable
        columns={columns(labels, params?.errorTab === 'softEdits')}
        initialSort={initialSort}
        noDataText="Errors not found"
        scrollId="claims-error-queue"
        filters={{ canoverride: params?.errorTab === 'softEdits' }}
        showRowSelection
        setSelectedRow={setSelectedRows}
        {...props}
      >
        {({
          Component, reFetch,
        }) => (
          <TableWrapper
            Component={Component}
            reFetch={reFetch}
            setReFetch={setRefetchErrors}
          />
        )}
      </CustomClaimsErrorTable>
    </div>
  );
};

export default PatientTable;
