import React from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';

import idCalculator from '../../lib/idCalculator';

export default function RadioGroup({
  tabList, onChange, value, id, ...otherProps
}) {
  return (
    <div>
      <Radio.Group value={value} buttonStyle="solid" onChange={onChange} {...otherProps}>
        {tabList
          && tabList.length > 0
          && tabList.map((item, index) => (
            <Radio.Button
              id={idCalculator(id, item.value)}
              value={item.value}
              key={`${id}_${index}`}
            >
              {item.label}
            </Radio.Button>
          ))}
      </Radio.Group>
    </div>
  );
}

RadioGroup.defaultProps = {
  className: '',
  tabList: [],
  onChange: () => { /* This is intentional */ },
  value: '',
  label: '',
  id: '',
};

RadioGroup.propTypes = {
  className: PropTypes.string,
  tabList: PropTypes.instanceOf(Array),
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
};
