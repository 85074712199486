import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form as AntdForm } from 'antd';

import Button from '../../components/Button';
import Form from '../../components/Form';
import Input from '../../components/Form/Input';
import Image from '../../components/Image';
import Loader from '../../components/Loader';

import * as loginAction from '../../store/actions/login';

import useRedirect from '../../hooks/useRedirect';
import { UiRoutes, formId } from '../../lib/constants';
import { apiUrls } from '../../api/constants';
import Notification from '../../components/Notification';
import SuccessMessages from '../../lib/successMessages';
import encryptData from '../../lib/encryptData';

const parser = (formValues) => ({
  ...formValues,
  password: encryptData(formValues?.password),
  confirmPassword: encryptData(formValues?.confirmPassword),
});

function ResetPassword(props) {
  const { loading } = props;
  const { push, location } = useRedirect();
  const [form] = AntdForm.useForm();
  const { search } = location;
  const params = JSON.parse(`{"${decodeURI(search.substr(1)).replace(/&/g, '","').replace(/=/g, '":"')}"}`);
  const { Id, code } = params;

  const onRequestComplete = useCallback((response) => {
    if (response) {
      Notification({ message: SuccessMessages.PASSWORD_RESET_SUCCESSFULLY, success: true });
      push(UiRoutes.login);
    }
  }, [push]);

  return (
    <>
      {loading && <Loader />}
      <div className="login-wrap">
        <div className="login-section">
          <div className="login-logo">
            <Image name="login-logo.png" alt="logo" />
          </div>
          <div className="shadow-bbg-1" />
          <div className="shadow-bbg-2" />
          <div className="login-alignment">
            <div className="login-field-wrap fields-wrapper">
              <Form
                section
                form={form}
                formId={formId.RESET_PASSWORD_FORM}
                url={apiUrls.RESET_PASSWORD}
                parser={parser}
                onRequestComplete={onRequestComplete}
                extraData={{
                  userId: Id,
                  token: code,
                }}
              >
                <h1>Reset Password</h1>
                <Form.Section>
                  <Input
                    type="password"
                    name="password"
                    labelSpan="0"
                    label="Password"
                    inputSpan="24"
                    placeholder="Password"
                    required
                    updatePassword
                    applyValidation={false}
                    maxValueLength={15}
                  />
                  <Input
                    type="password"
                    name="confirmPassword"
                    dependencies={['password']}
                    labelSpan="0"
                    label="Confirm Password"
                    inputSpan="24"
                    placeholder="Confirm Password"
                    applyValidation={false}
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                      }),
                    ]}
                  />
                  <Button className="btn btn-success btn-block" type="submit">Update Password</Button>
                  <Link to={UiRoutes.login} className="forget-pass">Back to Login</Link>
                </Form.Section>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  loading: state.login.get('loading'),
});

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (email, password) => dispatch(loginAction.requestLogin(email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
