import React from 'react';

import { apiUrls } from '../../api/constants';
import { listIds } from '../../lib/constants';

import Form from '../../components/Form';
import Input from '../../components/Form/Input';

const NoteForm = ({
  patientNotes, patientId, triggerCode, triggerScreen, onRequestComplete, labels, form, categoryId,
}) => (
  <Form
    form={form}
    section
    initialData={{
      text: patientNotes?.text,
    }}
    extraData={{
      PatientId: parseInt(patientId, 10),
      noteId: patientNotes?.noteId,
      categoryId,
      triggerCode,
      triggerScreen,
    }}
    formId={listIds.ADD_PATIENT_NOTES}
    url={apiUrls.ADD_PATIENT_NOTES}
    onRequestComplete={onRequestComplete}
    listId={listIds.ADD_PATIENT_NOTES}
  >
    <div className="note-section-form">
      <div className="addition-header">
        <div className="lookup sprite-img-before">
          <p>{labels.get('titles.note')}</p>
        </div>
      </div>
      <div className="modal-content-weapper shadow-wrap">
        <Input.TextArea
          name="text"
          labelSpan="0"
          inputSpan="24"
          size="large"
          autoSize={{ minRows: 8, maxRows: 12 }}
          required
          label={labels.get('labels.note')}
        />
      </div>
    </div>
  </Form>
);

export default NoteForm;
