import React, { useState, useCallback } from 'react';
import moment from 'moment';

import {
  dateFormatStartsWithYearSeconds,
} from '../../../../../../lib/constants';
import { apiUrls } from '../../../../../../api/constants';

import dateFormatter from '../../../../../../lib/dateFormatter';

import SelectBox from '../../../../../../components/SelectBox';
import DatePicker from '../../../../../../components/Form/DatePicker';

import WiredSelectWithoutTab from '../../../../../../wiredComponents/Select/selectBoxV2';
import UserAutoComplete from '../../../../../../wiredComponents/UserAutoComplete';

const RemittanceQueueFilter = ({
  onFilterChange: onChangeFilter,
  filters,
  activeTab,
}) => {
  const [sourceFilterOptions, setSourceFilterOptions] = useState([]);

  const onSourceOptionsFetch = useCallback((options) => {
    let initialOptions = [{ name: 'Waystar', value: 'Waystar', entityName: 'Waystar' }];
    if (options?.length) {
      initialOptions = [
        ...initialOptions,
        ...options
          .map((item) => ({
            ...item,
            name: item.entityName,
            value: item.businessEntityId,
          })),
      ];
    }
    setSourceFilterOptions(initialOptions);
  }, []);

  const sourceFilterHandler = useCallback((item, onFilterChange) => {
    onFilterChange({
      target: {
        name: 'Source',
        value: item?.entityName,
      },
    });
  }, []);

  const assingToFilterHandler = useCallback((item, onFilterChange) => {
    onFilterChange({
      target: {
        name: 'assignToId',
        value: item?.id,
      },
    });
  }, []);

  const depositeDateFromHandler = useCallback((
    depositeDateFrom, onFilterChange,
  ) => {
    if (depositeDateFrom) {
      onFilterChange({
        target: {
          name: 'depositeDateFrom',
          value: dateFormatter(depositeDateFrom, dateFormatStartsWithYearSeconds),
        },
      });
    } else {
      onFilterChange({ target: { name: 'depositeDateFrom', value: null } });
    }
  }, []);

  const depositeDateToHandler = useCallback((
    depositeDateTo, onFilterChange,
  ) => {
    if (depositeDateTo) {
      onFilterChange({
        target: {
          name: 'depositeDateTo',
          value: dateFormatter(depositeDateTo, dateFormatStartsWithYearSeconds),
        },
      });
    } else {
      onFilterChange({ target: { name: 'depositeDateTo', value: null } });
    }
  }, []);
  return (
    <div className="filter-components">
      {(activeTab === 'outbox' || activeTab === 'postedBatch') && (
        <div className="input-wrap filter-select-box">
          <WiredSelectWithoutTab
            id="remittance-source"
            name="source"
            url={`${apiUrls.GET_BUSINESS_ENTITY}?PageIndex=0&PageSize=1000`}
            placeholder="Source"
            nameAccessor="entityName"
            valueAccessor="businessEntityId"
            Component={SelectBox}
            defaultOptions={sourceFilterOptions}
            onFetchComplete={onSourceOptionsFetch}
            selectProps={{
              size: 'middle',
              style: { width: 150 },
              showSearch: true,
            }}
            onChange={(value, selectedData) => (
              sourceFilterHandler(selectedData?.item, onChangeFilter)
            )}
          />
        </div>
      )}
      {(activeTab === 'ready' || activeTab === 'errors' || activeTab === 'outbox') && (
        <div className="input-wrap filter-select-box user-auto-suggest-filter">
          <UserAutoComplete
            name="AssignedTo"
            label="Assigned To"
            minCharLength={3}
            labelSpan={1}
            inputSpan={24}
            header={false}
            placeholder="Assigned To"
            selectProps={{
              style: { width: 150 },
              mode: 'multiple',
              onChange: (value) => (
                assingToFilterHandler(value, onChangeFilter)
              ),
            }}
          />
        </div>
      )}
      {(activeTab !== 'unBatched' && activeTab !== 'inProgress') && (
        <div className="input-wrap filter-select-box">
          <DatePicker
            labelSpan="0"
            inputSpan="24"
            name={null}
            placeholder="Deposite Date From"
            onChange={(fromDate) => depositeDateFromHandler(fromDate, onChangeFilter)}
            value={filters?.depositeDateFrom && moment(filters?.depositeDateFrom)}
          />
        </div>
      )}
      {(activeTab !== 'unBatched' && activeTab !== 'inProgress') && (
        <div className="input-wrap filter-select-box">
          <DatePicker
            labelSpan="0"
            inputSpan="24"
            name={null}
            placeholder="Deposite Date To"
            onChange={(fromDate) => depositeDateToHandler(fromDate, onChangeFilter)}
            value={filters?.depositeDateTo && moment(filters?.depositeDateTo)}
          />
        </div>
      )}
    </div>
  );
};

export default RemittanceQueueFilter;
