import React from 'react';

import { apiUrls } from '../../../../api/constants';
import CPTICDSynonym from '../CPTICDSynonym';

const CptSynonym = ({ labels }) => (
  <CPTICDSynonym
    updateSynonymUrl={apiUrls.ADD_SYNONYM_FOR_CPT_ICD}
    deleteSynonymUrl={apiUrls.ADD_SYNONYM_FOR_CPT_ICD}
    synonymType="CPT"
    labels={labels}
  />
);

export default CptSynonym;
