/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import { Tooltip } from 'antd';

import { cptIcdCommonColumns } from '../ICD/columns';
import './CPTTable.scss';
import Icon from '../../../components/Icon';

const columns = (hideCPTSearch, isAccessToAddOrEditGlobalPeriod) => {
  if (isAccessToAddOrEditGlobalPeriod) {
    return [
      {
        Header: 'CPT Code',
        accessor: 'cptCode',
        fixWidth: 200,
        Cell: ({ row: { original: { isActive, cptCode } } }) => (
          <div className={!isActive ? 'backColor' : 'noBackColor'}>
            {cptCode}
          </div>
        ),
      },
      ...cptIcdCommonColumns,
      {
        Header: 'Global Period',
        accessor: 'globalPeriod',
        fixWidth: 150,
        Cell: ({
          row: {
            original: { isActive, globalPeriod },
          },
        }) => (
          <div className={!isActive ? 'backColor' : 'noBackColor'}>
            {globalPeriod === null ? '-' : globalPeriod > 1 ? `${globalPeriod} days` : `${globalPeriod} day` }
          </div>
        ),
      },
      {
        Header: 'Eye-Specificity',
        accessor: 'isGlobalPeriodEyeSpecific',
        fixWidth: 150,
        Cell: ({
          row: {
            original: { isActive, isGlobalPeriodEyeSpecific },
          },
        }) => (
          <div className={!isActive ? 'backColor' : 'noBackColor'}>
            {isGlobalPeriodEyeSpecific === true ? 'Yes' : 'No'}
          </div>
        ),
      },
      {
        Header: 'Apply to Doctor App ',
        accessor: 'shouldImpactDoctor ',
        fixWidth: 150,
        Cell: ({
          row: {
            original: { isActive, shouldImpactDoctor },
          },
        }) => (
          <div className={!isActive ? 'backColor' : 'noBackColor'}>
            {shouldImpactDoctor === true ? 'Yes' : 'No'}
          </div>
        ),
      },
      {
        Header: 'Actions',
        accessor: 'hideCptCodeSearch',
        fixWidth: '150',
        Cell: ({ row: { original }, onEditCpt }) => {
          const { isActive } = original;
          const onhideCptSearchClick = useCallback(
            (e, status) => {
              e.stopPropagation();
              hideCPTSearch(status, original);
            },
            [original],
          );
          const onEditcpt = useCallback(
            () => onEditCpt(original),
            [original, onEditCpt],
          );
          return (
            <div className={!isActive ? 'backColor' : 'noBackColor'}>
              <span className="actions-container mr-rt-16">
                <Icon onClick={onEditcpt} name="EditOutlined" />
              </span>
              {isActive ? (
                <Tooltip title="Hide term from search results">
                  <i
                    className="row-icdSearch-hide"
                    onClick={(e) => onhideCptSearchClick(e, false)}
                    role="presentation"
                  >
                    X
                  </i>
                </Tooltip>
              ) : (
                <Tooltip title="Reappear term in search results">
                  <i
                    className="tick"
                    onClick={(e) => onhideCptSearchClick(e, true)}
                    role="presentation"
                  />
                </Tooltip>
              )}
            </div>
          );
        },
      },
    ];
  }
  return [
    {
      Header: 'CPT Code',
      accessor: 'cptCode',
      fixWidth: 200,
      Cell: ({ row: { original: { isActive, cptCode } } }) => (
        <div className={!isActive ? 'backColor' : 'noBackColor'}>
          {cptCode}
        </div>
      ),
    },
    ...cptIcdCommonColumns,
    {
      Header: 'Actions',
      accessor: 'hideCptCodeSearch',
      fixWidth: '150',
      Cell: ({ row: { original } }) => {
        const { isActive } = original;
        const onhideCptSearchClick = useCallback(
          (e, status) => {
            e.stopPropagation();
            hideCPTSearch(status, original);
          },
          [original],
        );

        return (
          <div className={!isActive ? 'backColor' : 'noBackColor'}>
            {isActive ? (
              <Tooltip title="Hide term from search results">
                <i
                  className="row-icdSearch-hide"
                  onClick={(e) => onhideCptSearchClick(e, false)}
                  role="presentation"
                >
                  X
                </i>
              </Tooltip>
            ) : (
              <Tooltip title="Reappear term in search results">
                <i
                  className="tick"
                  onClick={(e) => onhideCptSearchClick(e, true)}
                  role="presentation"
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];
};

export default columns;
