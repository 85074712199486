import React from 'react';

import Icon from '../../../../../../../../components/Icon';

import IconNames from '../../../../../../../../lib/iconNames';

const Label = ({ value, ...otherProps }) => (
  <div className="ant-row">
    <div className="ant-form-item-label ant-form-item-label-left">
      <label className="ant-form-item-no-colon" title="First Name" htmlFor="1" {...otherProps}>
        {value}
      </label>
    </div>
  </div>
);

const ModifierLabel = ({
  modifier, onDeleteModifier = () => {
    // This is intentional
  },
}) => (
  <>
    <td>
      <Label value={modifier.modifierCPTCode} />
    </td>
    <td>
      <Label value={modifier.modifierDescription} />
    </td>
    <td>
      <Label value={modifier.modifierTier} />
    </td>
    <td>
      <Label value={modifier.multiplier} />
    </td>
    <td>
      <Icon name={IconNames.deleteIcon} className="cursor-pointer" onClick={onDeleteModifier} />
    </td>
  </>
);

export default ModifierLabel;
