/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import uniq from 'lodash/uniq';
import groupBy from 'lodash/groupBy';
import clone from 'lodash/clone';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { apiUrls } from '../../../api/constants';
import useCRUD from '../../../hooks/useCRUD';
import useAnesthesiaCptCode from '../../../hooks/useAnesthesiaCptCode';
import { getEnumMaster, getEnumOptions } from '../../../store/selectors';
import { allowAmountValidationForManualEncounter, listIds, enums } from '../../../lib/constants';
import LineItem from '../Components/LineItem';
import useReduxStoreWithId from '../../../hooks/useReduxStoreWithId';
import useRedirect from '../../../hooks/useRedirect';

const EncounterTable = ({
  labels,
  form,
  patientId,
  lineItemsData,
  isUpdate,
  providerId,
  locationId,
  diagnosesCodes: diagnosesCodesSet,
  setLineItems,
  onValuesChange,
  billingEncounterId,
  financialId,
  serviceDate,
  encounterStatus,
  setDiagnosesCodes,
  savedEncounter,
  isCollectionCharges,
  isUpdateInsuranceData,
  setIsUpdateInsuranceData,
  isShowExtraColumnForAnesthesia,
  anesthesiaStartAndEndTimeDiffMin,
  providerFullName,
  tabId,
  showRcmHighlight,
  finalRcmHighlightData,
}) => {
  const {
    params: { type, claimFilter },
  } = useRedirect();

  const enumMaster = useSelector((state) => getEnumMaster(state));
  const enumId = get(enumMaster, `${enums.MASTER_CONFIG}.enumId`);
  const enumOptions = useSelector((state) => getEnumOptions(state)?.[enumId]?.data || []);
  const [claimServiceLineItemReduxData] = useReduxStoreWithId({ id: `claim-service-line-item-${tabId}` });
  const claimServiceLineItemData = claimServiceLineItemReduxData?.get('data') || {};
  const collectionNotInCompletedQueue = claimFilter !== 'completed';
  const anesthesiaCPTCode = useAnesthesiaCptCode();

  const [response, , , getAssociateNDCData] = useCRUD({
    id: listIds.GET_ASSOCIATE_NDC_FOR_JCODES,
    url: apiUrls.GET_ASSOCIATE_NDC_FOR_JCODES,
    type: 'read',
  });

  const numberOfCharges = useMemo(() => {
    let number = 0;
    // eslint-disable-next-line no-unused-expressions
    lineItemsData?.forEach((item) => {
      if (!item.deleted && item.billingEncounterServiceLineId !== 0) {
        number += 1;
      }
    });
    return number;
  }, [lineItemsData]);

  useEffect(() => {
    if (!numberOfCharges && lineItemsData?.length && !(isCollectionCharges && claimFilter === 'completed')) {
      const clonedLineItemData = clone(lineItemsData);
      clonedLineItemData.push({ billingEncounterServiceLineId: `new_${(lineItemsData?.length || 0) + 1}`, serviceDate: lineItemsData?.[0]?.serviceDate || new Date() });
      setLineItems(clonedLineItemData);
    }
  }, [numberOfCharges, lineItemsData?.length]);

  const billingNdcData = useMemo(() => {
    const arr = [];
    (lineItemsData || []).forEach((item) => {
      if ((item?.CPTCode || item?.cptCode)?.startsWith('J') || (item?.CPTCode === 'Q5128' || item?.cptCode === 'Q5128')) {
        arr.push(item.cptCodeId);
      }
    });
    return uniq(arr);
  }, [lineItemsData]);

  const jcodeRef = useRef();

  useEffect(() => {
    if (billingNdcData?.length) {
      jcodeRef.current = billingNdcData;
      getAssociateNDCData({ jCodes: jcodeRef.current?.join(',') });
    }
  }, [billingNdcData, getAssociateNDCData]);

  const jCodesWithNdc = useMemo(() => {
    if (response?.length) {
      return groupBy(response, (item) => item?.procedureId);
    }
    return {};
  }, [response]);

  const calculatedAmounts = useMemo(() => {
    const total = { totalAmount: 0, totalValue: 0 };
    if (encounterStatus === 'OnHold') {
      lineItemsData?.forEach((item) => {
        if (!item?.disabled && !item?.deleted) {
          total.totalAmount += parseFloat(item.amount || 0);
          total.totalValue += parseFloat(item.totalValue || 0);
        }
      });
      return total;
    }
      lineItemsData?.forEach((item) => {
        if (!item?.deleted) {
          total.totalAmount += parseFloat(item.amount || 0);
          total.totalValue += parseFloat(item.totalValue || 0);
        }
      });
      return total;
  }, [encounterStatus, lineItemsData]);

  const onChangeRequiredFields = useCallback((index, data) => {
    if (lineItemsData?.length) {
      const prevLineItem = lineItemsData[index];
      // eslint-disable-next-line no-param-reassign
      lineItemsData[index] = { ...prevLineItem, ...data, isEdit: true };
      setLineItems([...lineItemsData]);
    }
  }, [lineItemsData, setLineItems]);

  const onRemoveLineItem = useCallback((lineItemIndex) => {
    onChangeRequiredFields(lineItemIndex, { deleted: true });
  }, [onChangeRequiredFields]);

  let needToAddNewItem = false;
  // eslint-disable-next-line no-unused-expressions
  lineItemsData?.forEach((lineItemToCheck) => {
    const {
      serviceDate: srviceDate, cptCodeId, diagnosesCodes, unit, insuranceProfileDetailId, CPTCode,
    } = lineItemToCheck;
    if ((srviceDate && cptCodeId && (diagnosesCodes?.[0]) && unit > 0 && insuranceProfileDetailId)
    || (allowAmountValidationForManualEncounter[CPTCode] && unit > 0)) {
      needToAddNewItem = true;
    } else if (!lineItemToCheck.deleted && lineItemToCheck.billingEncounterServiceLineId !== 0) {
      needToAddNewItem = false;
    }
  });
  if (needToAddNewItem && !(isCollectionCharges && claimFilter === 'completed')) {
    lineItemsData.push({
      billingEncounterServiceLineId: `new_${lineItemsData.length + 1}`,
      serviceDate: lineItemsData[0]?.serviceDate || new Date(),
      insuranceProfileDetailId: savedEncounter?.insuranceProfileDetailId,
      insuranceProfileId: savedEncounter?.insuranceProfileId,
      isEdit: true,
      providerId,
      providerName: providerFullName,
    });
    setLineItems(lineItemsData);
  }

  const selectedCPTLensData = useMemo(() => {
    if (enumOptions?.length) {
      return JSON.parse(enumOptions?.find((item) => item?.masterCode === 'RefractionLenseDetail')?.masterDescription);
    }
    return [];
  }, [enumOptions]);

  const parseAttributes = finalRcmHighlightData?.map((item) => ({
    ...item,
    operands: item.operands?.map((operand) => ({
      ...operand,
      attributes: typeof operand.attributes === 'string' ? typeof JSON.parse(operand.attributes) === 'string' ? JSON.parse(JSON.parse(operand.attributes)) : JSON.parse(operand.attributes) : operand.attributes,
    })),
  }));

  const getObjectsByServiceLineId = (serviceLineId) => parseAttributes?.filter((cs) => cs.operands?.some((dd) => dd?.attributes?.BillingEncounterServiceLineId === serviceLineId));

  return (
    <div className="app-table" style={{ overflow: 'auto' }}>
      <table>
        <thead>
          <tr>
            <th>Service Date</th>
            <th>Procedure</th>
            <th>Modifiers</th>
            <th>ICD-10 Diagnosis</th>
            <th>Units</th>
            <th>Amount</th>
            <th>Ins. Profile</th>
            <th>Authorization</th>
            {isShowExtraColumnForAnesthesia && <th>Business Entity</th>}
            {isShowExtraColumnForAnesthesia && <th>Provider</th>}
            <th>Cap</th>
            {!financialId && <th>Hold</th>}
            <th>Value</th>
            {!isCollectionCharges && <th>Delete</th>}
          </tr>
        </thead>
        <tbody>
          {lineItemsData?.map((item, key) => (
            <LineItem
              hidden={item.deleted}
              key={key}
              data={item}
              index={key}
              labels={labels}
              form={form}
              patientId={patientId}
              onRemoveLineItem={onRemoveLineItem}
              onChangeRequiredFields={onChangeRequiredFields}
              isUpdate={isUpdate}
              diagnosesCodesSet={diagnosesCodesSet}
              isHeaderFixed={false}
              providerId={providerId}
              locationId={locationId}
              onValuesChange={onValuesChange}
              financialId={financialId}
              billingEncounterId={billingEncounterId}
              jcodeRef={jcodeRef}
              jCodesWithNdc={jCodesWithNdc}
              serviceDate={serviceDate}
              setDiagnosesCodes={setDiagnosesCodes}
              isCollectionCharges={isCollectionCharges}
              isDenial={(collectionNotInCompletedQueue && (claimServiceLineItemData[item?.billingEncounterServiceLineId] || type === 'Manual' || type === 'No Response'))}
              isUpdateInsuranceData={isUpdateInsuranceData}
              setIsUpdateInsuranceData={setIsUpdateInsuranceData}
              isShowExtraColumnForAnesthesia={isShowExtraColumnForAnesthesia}
              anesthesiaStartAndEndTimeDiffMin={anesthesiaStartAndEndTimeDiffMin}
              anesthesiaCPTCode={anesthesiaCPTCode}
              selectedCPTLensData={selectedCPTLensData}
              tabId={`${tabId}-${item?.billingEncounterServiceLineId}`}
              showRcmHighlight={showRcmHighlight}
              finalRcmHighlightData={getObjectsByServiceLineId(item?.billingEncounterServiceLineId)}
            />
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>
              {numberOfCharges}
              {' '}
              charge(s)
            </td>
            <td colSpan="3">
              Total Amount:
              {' '}
              <b>
                {Math.round(calculatedAmounts?.totalAmount * 100) / 100}
              </b>
              &nbsp;&nbsp;&nbsp;
              Total Value:
              {' '}
              <b>
                {Math.round(calculatedAmounts?.totalValue * 100) / 100}
              </b>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default EncounterTable;
