import React, { useCallback } from 'react';

import { labelPaths, listId, UiRoutes } from '../../../../../../lib/constants';
import withQuery from '../../../../../../hoc/withQuery/withQuery';
import WithLabel from '../../../../../../hoc/withLabel';
import { apiUrls } from '../../../../../../api/constants';
import withReduxManager from '../../../../../../hoc/withReduxManager';
import Button from '../../../../../../components/Button';
import useRedirect from '../../../../../../hooks/useRedirect';

import columns from './columns';

const initialSort = [{ id: 'name', desc: false }];

const TermsTable = ({ navigateToEditContractTerm, labels, isUpdateContractAuthenticated }) => {
  const { params, push, generatePath } = useRedirect();
  const { id } = params;
  const navigateToAddContractTerm = useCallback(() => {
    push(generatePath(UiRoutes.addContractTermsWithTabId, { ...params, termsTab: 'general' }));
  }, [push, generatePath, params]);

  const CustomContractTable = withReduxManager({
    listId: `${listId.CONTRACT_TERMS}-${id}`,
  })(withQuery({
    id: 'contract-terms-table',
    url: apiUrls.GET_CONTRACT_TERM,
    listId: `${listId.CONTRACT_TERMS}-${id}`,
    accessor: (data) => ({ result: data?.contractTermListResponse?.result }),
  })());

  return (
    <CustomContractTable
      columns={columns(labels)}
      navigateToEditTerms={navigateToEditContractTerm}
      initialSort={initialSort}
      filters={{ contractId: id }}
    >
      {
          ({ Component, totalCount }) => (
            <>
              <div className="new-enrollment-wrap mr-bt-8">
                <div className="table-filters">
                  <div className="group-btns">
                    {isUpdateContractAuthenticated && (
                      <Button className="btn btn-success sm-btn" type="primary" onClick={navigateToAddContractTerm} id="new_contract_term">
                        {labels.get('labels.addNewTerm')}
                      </Button>
                    )}
                  </div>
                  <span className="table-count">
                    {`${labels.get('labels.totalCount')}: ${totalCount}`}
                  </span>
                </div>
              </div>
              {Component}
            </>
          )
        }
    </CustomContractTable>
  );
};

export default WithLabel(TermsTable, labelPaths.EDIT_CONTRACT_TERMS);
