import React from 'react';

const DirectAddress = () => (
  <div className="documents-wrap">
    <h1>Direct Address</h1>
    <p className="verified-email">Aalden@acuityretinainstituteofcallmd.careclouddirect.com (verified)</p>
  </div>
);

export default DirectAddress;
