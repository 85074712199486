import React from 'react';
import TimeCell from '../../../../../../components/Cells/TimeCell';

const columns = (labels) => [
  {
    Header: labels.get('columns.name'),
    accessor: 'patientName',
    Footer: ({ data = [] }) => {
      let totalCount = 0;
      data.forEach(() => {
        totalCount += 1;
      });
      if (!totalCount) return null;
      return (
        <span style={{ fontWeight: 'bolder', color: '#2a4a79' }}>
          {totalCount}
          {' '}
          Items
        </span>
      );
    },
    fixWidth: '150',
  },
  {
    Header: labels.get('columns.dos'),
    accessor: 'billingservicelineitemcreateddate.timestemp',
    Cell: (e) => <TimeCell value={e?.row.original?.billingServiceLineItemCreatedDate?.dateString} format="MM-DD-YYYY" />,
  },
  {
    Header: labels.get('payer'),
    accessor: 'insurancePayerName',
  },
  {
    Header: labels.get('columns.claimNumber'),
    accessor: 'claimNo',
  },
  {
    Header: labels.get('columns.amount'),
    accessor: 'amount',
    Cell: ({ row: { original: { amount } } }) => (
      (amount || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
];

export default columns;
