import React, { useCallback, useState } from 'react';
import { Route, Switch, matchPath } from 'react-router-dom';

import WithLabel from '../../hoc/withLabel';
import WithRights from '../../hoc/withRights';
import useRedirect from '../../hooks/useRedirect';
import { labelPaths, UiRoutes } from '../../lib/constants';

import TabsComponent from '../../components/Tabs';
import SavedNavigationBar from '../../components/SavedNavigationBar';

import EnterAdjustment from '../../wiredComponents/Adustment/Components/PatientAdjustment';

import Claims from './Tabs/Claims';
import Remittance from './Tabs/Remittance';
import Collections from './Tabs/Collections';
import Statements from './Tabs/Statements';

import './Claims.scss';
import useRights from '../../hooks/useRights';
import rights from '../../lib/rights';

const tabsList = [
  {
    id: 'claims',
    component: Claims,
  },
  {
    id: 'remittance',
    component: Remittance,
  },
  {
    id: 'collections',
    component: Collections,
  },
  {
    id: 'statement',
    component: Statements,
  },
];

const ClaimsTabs = (props) => {
  const { labels } = props;
  const [parentPath, setParentPath] = useState(() => UiRoutes.claims);
  const [isAccessToCollectionsAuthenticated,
    isAccessToRemittanceAuthenticated] = useRights([
    rights.access_to_collections,
    rights.access_to_remittance]);

  const {
    replace, generatePath, params: { tab }, push, params, location,
  } = useRedirect();

  const clonedTabs = [];
  tabsList.forEach((tabName) => {
    if ((tabName.id === 'collections' && isAccessToCollectionsAuthenticated)
    || (tabName.id === 'remittance' && isAccessToRemittanceAuthenticated)
    || (tabName.id === 'claims' || tabName.id === 'statement')) {
      clonedTabs.push({ ...tabName, name: labels.get(`titles.${tabName.id}`) });
    }
  });

  const routeToTable = useCallback(() => {
    replace(generatePath(UiRoutes.claimsTab, { tab: tab || 'claims' }));
  }, [generatePath, replace, tab]);

  const onClickGoBackOnAdjustment = useCallback(() => {
    const match = matchPath(location.pathname, {
      path: UiRoutes.enterAdjustmentsClaims,
      exact: true,
      strict: false,
    });
    if (match) {
      const matchParams = match.params;
      push(generatePath(UiRoutes.claimsCollections, { ...params, ...matchParams }));
    }
  }, [generatePath, location.pathname, params, push]);

  return (
    <div>
      <SavedNavigationBar
        parentPath={parentPath}
        listName="Claims List"
        titleComp={() => (
          <div className="mr-rt-12">
            <h2>{labels.get('titles.claims')}</h2>
          </div>
        )}
      >
        <div className="white-tabs">
          <Switch>
            <Route exact path={UiRoutes.enterAdjustmentsClaims}>
              <p role="presentation" className="back-arrow" id="claims_encounter_room_back" onClick={onClickGoBackOnAdjustment}>{labels.get('buttons.goBack')}</p>
              <EnterAdjustment />
            </Route>
            <Route exact path={UiRoutes.claims}>
              {routeToTable}
            </Route>
            <Route path={UiRoutes.claimsTab}>
              <TabsComponent tabsList={clonedTabs} id="claims" className="main-tab-wrap" setParentPath={setParentPath} />
            </Route>
          </Switch>
        </div>
      </SavedNavigationBar>
    </div>
  );
};

export default WithRights(WithLabel(ClaimsTabs, labelPaths.CLAIMS), rights.access_to_claims_app);
