/* eslint-disable no-unused-expressions */
import React, { useCallback, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Form as AntdForm } from 'antd';

import Modal from '../../components/Modal';
import Button from '../../components/Button';
import WidgetLoader from '../../components/WidgetLoader';

import useCRUD from '../../hooks/useCRUD';
import useRedirect from '../../hooks/useRedirect';

import { apiUrls } from '../../api/constants';
import { listIds, enumMasterCode, enums } from '../../lib/constants';
import Events from '../../lib/events';
import dateFormatter from '../../lib/dateFormatter';

import NoteForm from './NoteForm';

import './note.scss';
import useMasterId from '../../hooks/useMasterId';

const NotesList = ({ data, isUpdatePatientAuthenticated, handleNotes }) => {
  if (data?.length) {
    return data.map((notesItem) => {
      const refactorModifiedByName = `by ${notesItem?.modifiedByName}`;
      const refactorModifiedOn = `on ${dateFormatter(notesItem?.modifiedOn)}`;
      return (
        <div className="description-feilds insurance-summary shadow-wrap flex" key={notesItem?.noteId}>
          <div className="description-feilds">
            <p className="value note">{notesItem?.text}</p>
            {notesItem?.modifiedByName && (
            <p className="paragraph">
              {`Last updated ${notesItem?.modifiedByName ? refactorModifiedByName : ''} ${notesItem?.modifiedOn ? refactorModifiedOn : ''}.`}
            </p>
              )}
          </div>
          <p
            className="edit-icon cursor-pointer"
            style={!isUpdatePatientAuthenticated ? { cursor: 'not-allowed' } : {}}
            onClick={() => { isUpdatePatientAuthenticated && handleNotes(notesItem); }}
            role="presentation"
          />
        </div>
      );
    });
  }
  return <span>Notes not found</span>;
};

const Notes = ({
  labels, collapsable, isUpdatePatientAuthenticated,
}) => {
  const { params: { id, patientId } } = useRedirect();
  const [currentNotes, setCurrentNotes] = useState(undefined);
  const [visibility, toggleVisibility] = useState(false);
  const [form] = AntdForm.useForm();
  const { Patients: PATIENT_CATEGORY_ID } = useMasterId(
    [enumMasterCode.patientCategoryIdForNotes], [enums.NOTES],
  );

  const [
    patientNotes,,
    patientNotesLoading,
    getPatientNotes,
  ] = useCRUD({
    id: `${listIds.GET_PATIENT_NOTES}-${patientId}`,
    url: apiUrls.GET_PATIENT_NOTES,
    type: 'read',
  });

  const currentPatientId = patientId || id;

  useEffect(() => {
    if (currentPatientId) {
      getPatientNotes({ PatientId: currentPatientId, categoryId: PATIENT_CATEGORY_ID });
    }
  }, [currentPatientId]);

  useEffect(() => {
    Events.on('refetchNotesData', 'refetchNotesData', () => getPatientNotes({ PatientId: currentPatientId, categoryId: PATIENT_CATEGORY_ID }));
    return () => Events.remove('refetchNotesData');
  }, [currentPatientId, getPatientNotes]);

  const toggleModal = useCallback(() => {
    toggleVisibility(!visibility);
  }, [visibility]);

  const onRequestComplete = useCallback(() => {
    getPatientNotes({ PatientId: currentPatientId, categoryId: PATIENT_CATEGORY_ID });
    toggleModal();
  }, [getPatientNotes, currentPatientId, toggleModal]);

  const handleNotes = useCallback((notesItem) => {
    setCurrentNotes(notesItem);
    toggleModal();
  }, [toggleModal]);

  return (
    <>
      <div className={classNames('notes-widget', { 'insurance-summary pharmacy-box shadow-wrap mr-top-16': !collapsable })}>
        <div className="pharmacy-wrap">
          <p className="title sprite-img-before note-icon">
            <span className="note-icon" />
            {collapsable ? '' : labels.get('titles.note')}
          </p>
        </div>
        <div className="posi-relative notes-container">
          {patientNotesLoading && <WidgetLoader />}
          <NotesList
            data={patientNotes?.result?.length ? patientNotes.result : []}
            isUpdatePatientAuthenticated={isUpdatePatientAuthenticated}
            handleNotes={handleNotes}
          />
        </div>
      </div>
      <Modal
        visible={visibility}
        toggleModal={toggleModal}
        centered
        footer={[
          <div className="group-btns" key="footer">
            <Button className="btn min-wt-86" onClick={toggleModal}>{labels.get('buttons.cancel')}</Button>
            <Button className="btn btn-success min-wt-86 inline" onClick={() => form.submit()}>
              {patientNotes?.result?.length
        && patientNotes?.result[0]?.noteId
                ? labels.get('buttons.update')
                : labels.get('buttons.save')}
            </Button>
          </div>,
        ]}
        destroyOnClose
      >
        <NoteForm
          labels={labels}
          onRequestComplete={onRequestComplete}
          patientId={currentPatientId}
          patientNotes={currentNotes}
          categoryId={PATIENT_CATEGORY_ID}
          triggerCode="patientSummary"
          triggerScreen="patientSummary"
          form={form}
        />
      </Modal>
    </>
  );
};

export default Notes;

