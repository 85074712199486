import React, { useState, useCallback, useMemo } from 'react';
import classNames from 'classnames';

import withQuery from '../../../../../hoc/withQuery/withQuery';

import { listIds } from '../../../../../lib/constants';
import { apiUrls } from '../../../../../api/constants';

import columns, { mipsColumns } from './columns';

const hiddenColumns = ['remove'];

const DocumentTable = ({
  deletePatientDocument,
  getSelectedFile,
  categoryId,
  patientId,
  searchText,
  navigateToEditDocument,
  collapsableView,
  selectedCategoryName,
  columns: documentColumns,
  documentsInitialSort,
  scrollId,
  handleCareTeamLetterUpload,
  setSelectedRows,
  selectedRows,
  // isUpdateDocumentAuthenticated,
  // isReadDocumentAuthenticated,
}) => {
  const [inactiveRows, setInactiveRows] = useState([]);

  const changeActiveStatus = useCallback((id) => {
    const index = inactiveRows.indexOf(id);
    const newInactiveRows = [...inactiveRows];
    if (index >= 0) newInactiveRows.splice(index, 1);
    else newInactiveRows.push(id);
    setInactiveRows(newInactiveRows);
  }, [inactiveRows]);

  const filters = searchText
    ? { PatientId: patientId, SearchText: searchText }
    : { PatientId: patientId, CategoryId: categoryId };

  const CustomDocumentTable = useMemo(() => withQuery({
    url: searchText ? apiUrls.SEARCH_PATIENT_DOCUMENT : apiUrls.GET_PATIENT_DOCUMENT,
    listId: `${listIds.PATIENTS_DOCUMENTS}_${categoryId}_${patientId}`,
  })(), [categoryId, patientId, searchText]);

  const getColumns = useCallback(() => {
    if (['care team letters', 'signed encounter pdf'].includes(selectedCategoryName?.toLowerCase())) {
      return mipsColumns();
    }
    return documentColumns() || columns();
  }, [documentColumns, selectedCategoryName]);

  return (
    <div className={classNames('documents-table-wrap', { 'width-100-pr': collapsableView, 'patient-document-table': collapsableView })}>
      <div className="document-list-table" data-testid="document-table">
        { categoryId && (
        <CustomDocumentTable
          columns={getColumns()}
          hiddenColumns={hiddenColumns}
              // hiddenColumns={!isUpdateDocumentAuthenticated ? hiddenColumns : []}
          filters={filters}
          navigateToEditDocument={navigateToEditDocument}
          getSelectedFile={getSelectedFile}
          handleCareTeamLetterUpload={handleCareTeamLetterUpload}
          deletePatientDocument={deletePatientDocument}
          selectedCategoryName={selectedCategoryName}
          changeActiveStatus={changeActiveStatus}
              // disableNavigation={!isReadDocumentAuthenticated}
          disabledRows={inactiveRows}
          initialSort={documentsInitialSort}
          showRowSelection
          setSelectedRow={setSelectedRows}
          scrollId={scrollId}
          checkedRowIds={selectedRows}
        >
          {({ Component }) => (Component)}
        </CustomDocumentTable>
        )}
      </div>
    </div>
  );
};

DocumentTable.defaultProps = {
  columns,
};

export default DocumentTable;
