import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import { useSelector } from 'react-redux';
import isFunction from 'lodash/isFunction';
import findIndex from 'lodash/findIndex';
import clone from 'lodash/clone';
import moment from 'moment';
import get from 'lodash/get';

import useRedirect from '../../../../hooks/useRedirect';
import useCRUD from '../../../../hooks/useCRUD';

import { apiUrls } from '../../../../api/constants';
import Events from '../../../../lib/events';
import { contentType, enums } from '../../../../lib/constants';
import { contentTypeCalculator, getDateString } from '../../../../lib/util';
import SuccessMessages from '../../../../lib/successMessages';
import formFieldValueParser from '../../../../lib/formFieldValuesParser';
import ErrorMessages from '../../../../lib/errorMessages';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import Icon from '../../../../components/Icon';
import Form from '../../../../components/Form';
import Notification from '../../../../components/Notification';
import WidgetLoader from '../../../../components/WidgetLoader';
import { getEnumMaster, getEnumOptions } from '../../../../store/selectors';

import TaskForm from './TaskForm';

const initialDataParser = (values) => {
  let clonedValues = { ...values };
  delete clonedValues.taskDetails;
  const { taskDetails } = values || {};
  clonedValues = { ...clonedValues, ...taskDetails };
  clonedValues.insuranceDetailId = values.insuranceId;
  if (taskDetails?.taskDOS?.dateString) {
    clonedValues.dos = taskDetails.taskDOS.dateString;
  }

  if (clonedValues?.taskDueDate?.dateString) {
    clonedValues.dueDate = clonedValues.taskDueDate.dateString;
  }
  const parsedValues = formFieldValueParser(clonedValues, {
    date: ['dueDate', 'dos'],
  });

  const clonedTaskFile = [];
  if (parsedValues?.taskDocuments?.length) {
    parsedValues.taskDocuments.forEach((value, index) => {
      const {
        documentDisplayName, documentExt, documentUrl, taskDocumentId,
      } = value;
      clonedTaskFile.push({
        uid: index,
        name: documentDisplayName,
        status: 'done',
        url: documentUrl,
        type: contentTypeCalculator(documentExt),
        taskDocumentId,
      });
    });
  }
  return {
    ...parsedValues,
    files: clonedTaskFile,
    priority: values.taskPriorityId,
    insurancePhoneNumber: parsedValues?.insurancePayerPrimaryPhone,
    proceduresRequested: JSON.parse(parsedValues?.proceduresRequested || '[]'),
    insuranceId: parsedValues?.insuranceProfileDetailId === 0
      ? parsedValues?.insuranceId : parsedValues?.insuranceProfileDetailId,
    insuranceNumber: parsedValues?.insuranceId,
    policyTypeId: parsedValues?.insurancePolicyTypeId,
    dob: moment(getDateString(parsedValues?.dob)),
  };
};

const AddTask = ({
  labels, form, toggleAddMode, taskId, appointmentDetail, manualInterventionQueueTaskId,
  ...otherProps
}) => {
  const [comments, setComments] = useState([]);
  const [formData, setFormData] = useState({});
  const [deletedTaskFile, setDeletedTaskFiles] = useState([]);
  const [selectedProcedures, setSelectedProcedures] = useState([]);
  const [selectedRows, setSelectedRow] = useState([]);
  const suppressWarningRef = useRef(false);

  const enumMaster = useSelector((state) => getEnumMaster(state));
  const enumId = get(enumMaster, `${enums.TASK_STATUS_IDS}.enumId`);
  const taskStatus = useSelector((state) => getEnumOptions(state)?.[enumId]?.data || []);

  const [appointmentResponse,,
    appointmentLoading,
    getAppointmentDetail,
  ] = useCRUD({
    id: 'get-appointment-detail', url: apiUrls.GET_ACTIVITY, type: 'read',
  });

  useEffect(() => {
    if (appointmentDetail?.appointmentId) {
      getAppointmentDetail({}, `/${appointmentDetail?.appointmentId}`);
    }
  }, [appointmentDetail, getAppointmentDetail]);

  const navigation = useSelector((state) => state.navigation);
  const tabs = useSelector((state) => state.tabs);
  const {
    params, url, location, path, query,
  } = useRedirect();

  const parser = useCallback((values) => {
    const parsedValues = formFieldValueParser(values, {
      dateInString: [
        'dueDate', 'dob', 'dos',
      ],
      files: [
        'files',
      ],
    });
    if (formData?.taskType === 'ManualInterventionQueue') {
      parsedValues.taskType = formData.taskType;
    }
    return { ...parsedValues };
  }, [formData]);

  const onAddComment = useCallback(() => {
    const comment = form.getFieldValue('comment 2');
    if (comment && comment.length) {
      const clonedComments = clone(comments);
      clonedComments.push(comment);
      setComments(clonedComments);
      form.setFields([{ name: 'comment 2', value: '' }]);
    }
  }, [comments, form]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      if (response?.message === ErrorMessages.TASK_ALREADY_EXISTS) {
        ConfirmDialog({
          onOk: (close) => {
            suppressWarningRef.current = true;
            form.submit();
            close();
          },
          onCancel: (close) => {
            close();
            suppressWarningRef.current = false;
          },
          okText: 'Continue',
          title: 'Warning',
          content: ErrorMessages.TASK_ALREADY_EXISTS,
          icon: <Icon name="ExclamationCircleOutlined" />,
        })();
      } else {
        suppressWarningRef.current = false;
        Notification({
          message: taskId
            ? SuccessMessages.TASK_UPDATED_SUCCESSFULLY : SuccessMessages.TASK_ADDED_SUCCESSFULLY,
          success: true,
        });
        if (manualInterventionQueueTaskId) {
          Events.trigger('refetchManualInterventionQueueList');
        }
        if (isFunction(toggleAddMode)) {
          Events.trigger('refetchTaskManagementList');
          toggleAddMode();
        }
      }
    }
  }, [form, manualInterventionQueueTaskId, taskId, toggleAddMode]);

  const onGetResponseComplete = useCallback((formValues) => {
    setFormData(formValues);
    setSelectedProcedures(formValues?.proceduresRequested || []);
    setSelectedRow(formValues?.proceduresRequested?.filter((procedure) => procedure?.isAuthorize)
    || []);
  }, []);

  const onFinishFailed = useCallback((errorInfo) => {
    suppressWarningRef.current = false;
    const requiredError = errorInfo?.errorFields.find((err) => err.errors[0].indexOf('is required') > -1 || err.errors[0] === ' ');
    if (requiredError) {
      Notification({ message: ErrorMessages.PLEASE_FILL_ALL_REQUIRED_FIELDS });
    } else {
      Notification({ message: errorInfo?.errorFields[0].errors[0] });
    }
  }, []);

  const getExtraData = useCallback(() => {
    const data = {
      contentType: contentType.MULTIPART,
      taskId,
      patientId: otherProps?.patientId || formData.patientId,
      comments: comments || [],
      deletedTaskAttachmentIds: taskId ? deletedTaskFile : [],
    };
    if (form.getFieldValue('taskCategoryName')?.toLowerCase() === 'authorization') {
      const updatedProcedureData = [];
      selectedProcedures.forEach((procedure) => {
        const updatedProcedure = procedure;
        updatedProcedure.isAuthorize = false;
        updatedProcedureData.push(updatedProcedure);
      });
      selectedRows.forEach(({ original }) => {
        const rowIndex = findIndex(updatedProcedureData,
          (procedure) => original.value.toString() === procedure.value.toString());
        updatedProcedureData[rowIndex].isAuthorize = true;
      });
      data.ProceduresRequested = JSON.stringify(updatedProcedureData);
    } else data.ProceduresRequested = JSON.stringify([]);
    if (params.tabId && !taskId) {
      const tabModuleName = tabs.get('tabModuleName');
      const navigationData = navigation.get(tabModuleName);
      data.origin = JSON.stringify({
        params, url, location, path, query, savedNavigation: navigationData[params.tabId],
      });
    } else if (!taskId) {
      data.origin = JSON.stringify({
        params, url, location, path, query,
      });
    }
    const selectedInsurance = form.getFieldInstance('insuranceDetailId')?.props?.selectedInsurance;
    if (selectedInsurance && selectedInsurance.pId) {
      data.insuranceProfileId = selectedInsurance.pId;
    }
    if (suppressWarningRef.current) {
      data.SuppressWarning = suppressWarningRef.current;
    }
    const taskClosedOrCompletedStatusMasterId = taskStatus?.find(({ masterId }) => masterId === form.getFieldValue('taskStatusId'));
    if (taskClosedOrCompletedStatusMasterId?.masterName?.toLowerCase() === 'closed' || taskClosedOrCompletedStatusMasterId?.masterName?.toLowerCase() === 'completed') {
      data.SuppressWarning = true;
    }
    return data;
  }, [comments,
    deletedTaskFile,
    form,
    formData.patientId,
    location,
    navigation,
    otherProps,
    params,
    path,
    query,
    selectedProcedures,
    selectedRows,
    tabs,
    taskId,
    url, taskStatus]);

  return (
    <div className="tasks-modal-add-new">
      {appointmentLoading && <WidgetLoader />}
      { !appointmentLoading && (
      <Form
        form={form}
        url={apiUrls.ADD_TASK}
        getUrl={`${apiUrls.GET_TASK_DETAIL}?TaskId=${taskId}`}
        extraData={getExtraData}
        parser={parser}
        formId={apiUrls.UPDATE_PATIENT}
        contentType={contentType.MULTIPART}
        onRequestComplete={onRequestComplete}
        isUpdate={!!taskId}
        initialDataParser={initialDataParser}
        onGetResponseComplete={onGetResponseComplete}
        onFinishFailed={onFinishFailed}
        initialData={!taskId && appointmentDetail ? {
          dos: moment(get(appointmentDetail, 'appointmentStartDateTime.dateString', appointmentDetail.appointmentStartTime)),
          dueDate: moment(get(appointmentDetail, 'appointmentStartDateTime.dateString', appointmentDetail.appointmentStartTime)),
          taskStatusId: 950,
          insuranceNumber: appointmentResponse?.insuranceId,
          insuranceId: appointmentResponse?.insurancedetailId || appointmentResponse?.insuranceId,
          locationId: appointmentResponse?.locationId,
          providerId: appointmentResponse?.providerId,
        } : {
          dos: !taskId && moment(),
          dueDate: !taskId && moment(),
        }}

      >
        <TaskForm
          labels={labels}
          comments={comments}
          onAddComment={onAddComment}
          form={form}
          taskId={taskId}
          formData={formData}
          patientId={otherProps.patientId || formData.patientId}
          deletedTaskFile={deletedTaskFile}
          setDeletedTaskFiles={setDeletedTaskFiles}
          selectedProcedures={selectedProcedures}
          setSelectedProcedures={setSelectedProcedures}
          selectedRows={selectedRows}
          setSelectedRow={setSelectedRow}
          appointmentDetail={appointmentDetail}
          appointmentResponse={appointmentResponse}
          {...otherProps}
        />
      </Form>
      )}
    </div>
  );
};

export default AddTask;
