import React, { useCallback } from 'react';

import { UiRoutes, labelPaths } from '../../../../../../lib/constants';

import useRedirect from '../../../../../../hooks/useRedirect';
import withLabel from '../../../../../../hoc/withLabel';

import General from './General';

const AddTerms = ({ labels }) => {
  const {
    params, generatePath, push,
  } = useRedirect();

  const navigateToTermsList = useCallback(() => (
    push(generatePath(UiRoutes.editContractWithTabId, params))
  ), [generatePath, push, params]);

  const navigateToEditTerms = useCallback((termId) => (
    push(generatePath(UiRoutes.editContractTermsWithTabId, { ...params, termsTab: 'general', termId }))
  ), [generatePath, push, params]);

  return (
    <div className="main-form-container contract-container">
      <div className="flex justify-content-sp-bt align-center mng-head-gaps">
        <div className="heading-area flex justify-content-sp-bt align-center">
          <h1>{labels.get('title.addTerms')}</h1>
        </div>
      </div>
      <General
        navigateToTermsList={navigateToTermsList}
        navigateToEditTerms={navigateToEditTerms}
      />
    </div>
  );
};

export default withLabel(AddTerms, labelPaths.EDIT_CONTRACT_TERMS);
