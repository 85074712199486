import React, { useMemo } from 'react';
import {
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import get from 'lodash/get';
import withQuery from '../../../../hoc/withQuery/withQuery';
import withReduxManager from '../../../../hoc/withReduxManager';
import StatusCell from '../../../../components/StatusCell';
import FilterManager from '../../../../components/FilterManager';
import { apiUrls } from '../../../../api/constants';
import { listId } from '../../../../lib/constants';

import TableWrapper from './TableWrapper';
import FilterComponents from '../../../../components/FilterComponents';

const initialSort = [{ id: 'name', desc: false }];

const FilterCollection = FilterComponents([
  {
    type: 'search',
    filterProps: {
      placeholder: 'Search',
      name: 'SearchText',
      id: 'cpt_filter_search',
    },
  },
  {
    type: 'checkbox',
    filterProps: {
      name: 'isICDSearch',
      id: 'isICDSearch',
      label: 'Enable ICD Search',
    },
  },
  {
    type: 'checkbox',
    filterProps: {
      name: 'includedInActive',
      id: 'includedInActive',
      label: 'Inactive',
    },
  },
]);

const cptColumns = (togglePayerCptModal, setSelectedCPT) => [
  {
    Header: '',
    accessor: 'expander',
    fixWidth: '50',
    Cell: ({ row }) => {
      const expanderProps = row.getToggleRowExpandedProps();
      if (expanderProps.onClick) {
        const { onClick } = expanderProps;
        expanderProps.onClick = (e) => {
          e.stopPropagation();
          onClick(e);
        };
      }
      return (
        <span {...expanderProps}>
          <div>
            {row.isExpanded ? <MinusOutlined /> : <PlusOutlined />}
          </div>
        </span>
      );
    },
  },
  {
    Header: 'CPT Code',
    accessor: 'cptCode',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Status',
    accessor: 'isActive',
    Cell: (e) => <StatusCell active={e?.row?.original?.isActive} />,
  },
  {
    Header: '',
    accessor: 'createdAt',
    fixWidth: '50',
    Cell: ({ row: { original } }) => (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a
        className="row-add sprite-img-before"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          togglePayerCptModal(true);
          setSelectedCPT(original);
        }}
        role="presentation"
      />
    ),
  },
];

const columns = () => [
  {
    Header: 'ICD Code',
    fixWidth: '100',
    accessor: 'icdCode',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Status',
    accessor: 'isActive',
    Cell: (e) => <StatusCell active={e?.row?.original?.isActive} />,
  },
];

const CustomCPTTable = withReduxManager({
  listId: listId.PROVIDERS,
})(
  withQuery({
    url: apiUrls.CPT_ICD_CROSSWALK_LIST,
    listId: apiUrls.CPT_ICD_CROSSWALK_LIST,
  })(),
);

const initialSortICD = [{ id: 'icdCode', desc: false }];
const initialFilter = { includedInActive: false, isICDSearch: false };

const ExpandedCPTRowRender = ({ row }) => {
  const cptCode = get(row, 'original.cptCode');
  const CustomICDTable = useMemo(() => withReduxManager({
    listId: `${apiUrls.GET_ICD_CPT_MAPPING}-${cptCode}`,
  })(withQuery({
    url: apiUrls.GET_ICD_CPT_MAPPING,
    listId: `${apiUrls.GET_ICD_CPT_MAPPING}-${cptCode}`,
    accessor: (data) => data,
  })()), [cptCode]);

  return (
    <CustomICDTable columns={columns()} filters={{ CPTCode: cptCode }} initialSort={initialSortICD} noDataText="ICD code not found">
      {({
        Component, initialFilters, onFiltersChange,
      }) => (
        <FilterManager initialFilters={initialFilters} onChange={onFiltersChange}>
          {() => Component}
        </FilterManager>
      )}
    </CustomICDTable>
  );
};

const CPTTable = ({
  togglePayerCptModal, setSelectedCPT,
}) => (
  <CustomCPTTable
    columns={cptColumns(togglePayerCptModal, setSelectedCPT)}
    initialSort={initialSort}
    initialFilters={initialFilter}
    noDataText="No results found"
    renderRowSubComponent={(props) => <ExpandedCPTRowRender {...props} />}
  >
    {({
      Component, totalCount, initialFilters, onFiltersChange, reFetch,
    }) => (
      <FilterManager
        initialFilters={initialFilters}
        onChange={onFiltersChange}
      >
        {({ onFilterChange, filters }) => (
          <>
            <div className="table-filters">
              <FilterCollection
                onFilterChange={onFilterChange}
                filters={filters}
                allowClear
              />
              <span className="table-count">
                <span>{`Total Count: ${totalCount}`}</span>
              </span>
            </div>
            <TableWrapper Component={Component} reFetch={reFetch} />
          </>
        )}
      </FilterManager>
    )}
  </CustomCPTTable>

);
export default CPTTable;
