import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';

import get from 'lodash/get';
import set from 'lodash/set';
import { Form } from 'antd';
import moment from 'moment';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';

import { apiUrls } from '../../../../../../../../api/constants';

import SuccessMessages from '../../../../../../../../lib/successMessages';
import ErrorMessages from '../../../../../../../../lib/errorMessages';
import WarningMessages from '../../../../../../../../lib/warningMessages';
import {
  formId, enumMasterCode, enums,
} from '../../../../../../../../lib/constants';
import formFieldValuesParser from '../../../../../../../../lib/formFieldValuesParser';

import useCRUD from '../../../../../../../../hooks/useCRUD';

import Table from '../../../../../../../../components/Table';
import Button from '../../../../../../../../components/Button';
import Notification from '../../../../../../../../components/Notification';
import ConfirmDialog from '../../../../../../../../components/ConfirmDialog';

import columns from './columns';
import useMasterId from '../../../../../../../../hooks/useMasterId';

const parser = (exception) => formFieldValuesParser(exception, {
  bool: ['isRange', 'IsDeleted', 'isActive'], int: ['fieldValue'],
});

const dateComparison = (date) => moment(date).toISOString();
const { REACT_APP_FAKE } = process.env;

const isNewException = (exception) => ((isString(exception.feePreferencesExceptionId)
    && exception.feePreferencesExceptionId.indexOf('new') > -1) || !exception.feePreferencesExceptionId);

const masterCodes = [
  enumMasterCode.exceptionModifier,
  enumMasterCode.exceptionValueType,
];

const enumNames = [
  enums.MODIFIER,
  enums.VALUE_TYPE,
];

const ExceptionTable = ({
  data,
  labels,
  onSort,
  initialSort,
  schedule,
  providerId,
  reFetch,
  feeExceptionScheduleId,
  newSchedule,
  onCancelNewSchedule,
  onScheduleAdded,
  onUpdateSchedule,
  onCancelEditSchedule,
  tableLoading,
  isProviderViewOnly,
}) => {
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [removedExceptions, setRemovedExceptions] = useState([]);
  const [editedRows, setEditedRows] = useState({});
  const isSchedule = useMemo(() => !!Object.values(schedule).length, [schedule]);
  const masterCodesWithId = useMasterId(masterCodes, enumNames);
  const facilityMasterCodesWithId = useMasterId([
    enumMasterCode.exceptionFacilityType,
  ], [
    enums.FACILITY_TYPE_EXCEPTION,
  ]);

  const [
    response,,
    loading,
    createException,
    clearCreateException,
  ] = useCRUD({
    id: formId.PROVIDER_FEE_PREFERENCES_EXCEPTION,
    url: apiUrls.ADD_UPDATE_FEE_PREFERENCES_EXCEPTION,
    type: 'create',
  });

  useEffect(() => {
    if (response && !isArray(response)) {
      Notification({ message: SuccessMessages.EXCEPTIONS_ADDED_SUCCESSFULLY, success: true });
      onScheduleAdded(response.feePreferencesExceptionSchedulerId || 20);
      setEditedRows({});
      setRemovedExceptions([]);
      clearCreateException();
    }
  }, [response, reFetch, clearCreateException, onScheduleAdded]);

  const [
    updateResponse,,
    updateLoading,
    updateException,
    clearUpdateException,
  ] = useCRUD({
    id: formId.UPDATE_PROVIDER_FEE_PREFERENCES_EXCEPTION,
    url: apiUrls.ADD_UPDATE_FEE_PREFERENCES_EXCEPTION,
    type: 'update',
  });

  useEffect(() => {
    if (updateResponse && !isArray(updateResponse)) {
      Notification({ message: SuccessMessages.EXCEPTION_UPDATED_SUCCESSFULLY, success: true });
      reFetch();
      onUpdateSchedule();
      clearUpdateException();
    }
  }, [updateResponse, reFetch, clearUpdateException, onUpdateSchedule]);

  const newException = useCallback(() => {
    if (isSchedule) {
      let currentTableData = form.getFieldsValue();
      currentTableData = get(currentTableData, 'exceptions', []);
      const feePreferencesExceptionId = `new-${Date.now()}`;
      currentTableData.unshift({
        feePreferencesExceptionId,
        valueTypeId: masterCodesWithId?.Amount,
        modifierId: masterCodesWithId?.All,
        facilityTypeId: facilityMasterCodesWithId?.All,
      });
      editedRows[feePreferencesExceptionId] = true;
      setTableData(currentTableData);
      setEditedRows({ ...editedRows });
    } else {
      ConfirmDialog({
        title: 'Warning',
        content: WarningMessages.CREATE_OR_SELECT_EXCEPTION,
      })();
    }
  }, [setTableData, editedRows, setEditedRows, form, isSchedule]);

  const onRemoveException = useCallback((exceptionIndex) => {
    let currentTableData = form.getFieldsValue();
    currentTableData = get(currentTableData, 'exceptions', []);
    const removedRecord = currentTableData.splice(exceptionIndex, 1);
    if (!isNewException(removedRecord[0])) {
      removedRecord[0].IsDeleted = true;
      removedExceptions.push(removedRecord[0]);
      setRemovedExceptions(removedExceptions);
    }
    setTableData(currentTableData);
  }, [setTableData, form, removedExceptions, setRemovedExceptions]);

  useEffect(() => {
    if (REACT_APP_FAKE) {
      const filteredData = [];
      data.forEach((item) => {
        if (
          isSchedule
          && dateComparison(item.effectiveFrom) >= dateComparison(schedule.effectiveFrom)
          && dateComparison(item.effectiveTo) <= dateComparison(schedule.effectiveTo)
        ) {
          filteredData.push(item);
        }
      });
      setTableData(filteredData);
    } else if (data && Array.isArray(data)) {
      setTableData(data);
      setEditedRows({});
      setRemovedExceptions([]);
    }
  }, [data, form, setTableData, setRemovedExceptions, setEditedRows, schedule, isSchedule]);

  useEffect(() => {
    if (tableData && tableData.length) {
      form.setFieldsValue({ exceptions: tableData });
    }
  }, [tableData, form]);

  useEffect(() => {
    if (isSchedule) {
      onSort(initialSort);
    }
  }, [isSchedule, initialSort]);

  const editSelectedException = useCallback(({ original: { feePreferencesExceptionId } }) => {
    if (!editedRows[feePreferencesExceptionId]) {
      setEditedRows({
        ...editedRows,
        [feePreferencesExceptionId]: true,
      });
    }
  }, [setEditedRows, editedRows]);

  const onRangeSelect = useCallback((event, name, feePreferencesExceptionId) => {
    let currentTableData = form.getFieldsValue();
    currentTableData = get(currentTableData, 'exceptions', []);
    set(currentTableData, name, event.target.checked);
    if (feePreferencesExceptionId && !editedRows[feePreferencesExceptionId]) {
      setEditedRows({
        ...editedRows,
        [feePreferencesExceptionId]: true,
      });
    }
    setTableData([...currentTableData]);
  }, [setTableData, setEditedRows, editedRows, form]);

  const onFinishFailed = useCallback(() => {
    Notification({ message: ErrorMessages.PLEASE_FILL_ALL_REQUIRED_FIELDS });
  }, []);

  const onClearException = useCallback(() => {
    setTableData([...data]);
    setEditedRows({});
    setRemovedExceptions([]);
    if (newSchedule) onCancelNewSchedule();
    else onCancelEditSchedule();
  }, [data, newSchedule, onCancelNewSchedule, onCancelEditSchedule]);

  const onFinish = useCallback((formData) => {
    const exceptionsData = get(formData, 'exceptions', []);
    let exceptions = [];
    exceptionsData.forEach((item) => {
      const exception = item;
      if (isNewException(exception)) {
        delete exception.feePreferencesExceptionId;
        exception.IsDeleted = false;
        exceptions.push(parser(exception));
      } else if (editedRows[exception.feePreferencesExceptionId]) {
        exception.IsDeleted = false;
        exceptions.push(parser(exception));
      }
    });
    exceptions = exceptions.concat(removedExceptions);
    const exceptionData = {
      effectiveFrom: schedule.effectiveFrom,
      effectiveTo: schedule.effectiveTo,
      exceptions,
      id: providerId,
      providerId: parseInt(providerId, 10),
    };

    if (newSchedule) {
      createException({ data: exceptionData });
    } else {
      updateException(exceptionData, `/${feeExceptionScheduleId}`);
    }
  }, [
    createException,
    providerId,
    schedule,
    removedExceptions,
    editedRows,
    updateException,
    feeExceptionScheduleId,
    newSchedule,
  ]);

  useEffect(() => {
    if (newSchedule) {
      setTableData([]);
      setEditedRows({});
      setRemovedExceptions([]);
    }
  }, [newSchedule]);

  return (
    <div className="mng-exception-tab move-btn-top feePreferences-container manage-fre-table">
      <div className="flex justify-content-sp-bt">
        {!isProviderViewOnly && (
          <Button id="provider_assistants_add" className="btn-success btn sm-btn" onClick={newException}>{labels.get('link.addException')}</Button>
        )}
        <div className="table-filters">
          <span className="table-count">
            <span>{`Total Count: ${tableData && tableData.length}`}</span>
          </span>
        </div>
      </div>
      <div className="mr-top-12">
        <Form
          form={form}
          onFinishFailed={onFinishFailed}
          onFinish={onFinish}
          initialValues={{ exceptions: tableData }}
        >
          <Table
            columns={columns(labels, form)}
            data={tableData}
            loading={(loading || updateLoading || tableLoading)}
            editedRows={editedRows}
            onRowClick={editSelectedException}
            onRangeSelect={onRangeSelect}
            onRemoveException={onRemoveException}
            isHeaderFixed={false}
            disableTable={isProviderViewOnly}
          />
          <div className="form-btns contract-btns">
            {!isProviderViewOnly && (
              <>
                <Button className="btn btn-success" type="primary" htmlType="submit">{labels.get('buttons.save')}</Button>
                <Button className="btn btn-ghost" onClick={onClearException}>{labels.get('buttons.clear')}</Button>
              </>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

ExceptionTable.defaultProps = {
  data: [],
  onSort: () => { /* This is intentional */ },
  initialSort: [],
  totalCount: 0,
  reFetch: () => { /* This is intentional */ },
  loading: false,
};

export default ExceptionTable;
