import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Form as AntdForm } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { Input } from '../../../../components/Form/inputFields';
import Form from '../../../../components/Form';
import Button from '../../../../components/Button';
import CommentCard from '../../../../components/CommentCard';
import Notification from '../../../../components/Notification';

import useCRUD from '../../../../hooks/useCRUD';
import { apiUrls } from '../../../../api/constants';
import { formId } from '../../../../lib/constants';
import SuccessMessages from '../../../../lib/successMessages';
import WidgetLoader from '../../../../components/WidgetLoader';

import '../tasks.scss';

const UpdateComment = ({ labels, taskId }) => {
  const [form] = AntdForm.useForm();
  const [comments, setComments] = useState([]);
  const [allComments, , commentsLoading, getAllComments] = useCRUD({ id: 'get-all-comment', url: apiUrls.GET_TASK_COMMENTS, type: 'read' });

  useEffect(() => {
    getAllComments({}, `/${taskId}/GetComments`);
  }, [getAllComments, taskId]);

  useEffect(() => {
    if (allComments) {
      setComments(allComments);
    }
  }, [allComments]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.COMMENT_ADDED_SUCCESSFULLY, success: true });
      const useComment = form.getFieldValue('comments');
      setComments([...comments, { comments: useComment }]);
      form.setFields([{ name: 'comments', value: '' }]);
    }
  }, [comments, form]);

  const onAddComment = useCallback(() => {
    const comment = form.getFieldValue('comments');
    if (comment && comment.trim().length) form.submit();
  }, [form]);

  return (
    <div className="group-shadow posi-relative">
      {commentsLoading && <WidgetLoader />}
      <Row gutter={16}>
        <Col span={12}>
          <Form
            form={form}
            formId={`${formId.EDIT_TASK_COMMENT}-${taskId}`}
            url={`${apiUrls.ADD_COMMENT}/${taskId}/AddComment`}
            onRequestComplete={onRequestComplete}
            extraData={{ taskId }}
            shouldShowLoader={false}
          >
            <div className="cmt-mr-b-0">
              <Input.TextArea
                name="comments"
                autoSize={{ minRows: 4, maxRows: 10 }}
                labelSpan="0"
                inputSpan="24"
                className="text-area-100"
                placeholder="Enter Comments"
                maxValueLength={500}
              />
            </div>
            <Button type="button" className="btn sm-btn btn-success text-area-100" onClick={onAddComment}>{labels.get('buttons.addComment')}</Button>
          </Form>
        </Col>
        <Col span={12}>
          <div className="comment-card-container-wrap">
            <div className="comment-card-container mr-top-12">
              {!isEmpty(comments) && comments.map((comment) => (
                <CommentCard comment={comment?.comments} key={comment} name={comment?.createdByName || 'Me'} date={comment?.createdOn} />))}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default UpdateComment;
