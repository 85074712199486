import React, { useCallback, useEffect, useState } from 'react';
import { Form as AntdForm } from 'antd';

import Form from '../../../../../components/Form';
import Notification from '../../../../../components/Notification';
import Button from '../../../../../components/Button';
import Modals from '../../../../../components/Modal';

import { apiUrls } from '../../../../../api/constants';
import SuccessMessages from '../../../../../lib/successMessages';
import { formId } from '../../../../../lib/constants';
import Events from '../../../../../lib/events';
import IncidentForm from './IncidentForm';

const customErrorMessage = 'Record not exist';

const NewIncidentModal = ({ isVisible, toggleIncidentModal, labels }) => {
  const [form] = AntdForm.useForm();
  const [claimNo, setClaimNo] = useState(null);
  const [isIncident, setIsIncident] = useState(false);
  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.INCIDENT_ADDED_SUCCESSFULLY, success: true });
      toggleIncidentModal();
      Events.trigger('reFetchCollectionManualData');
    }
  }, [toggleIncidentModal]);

  const submitForm = useCallback(() => form.submit(), [form]);

  const onClaimNoChange = useCallback(({ target: { value } }) => {
    setClaimNo(value.trim());
  }, []);

  useEffect(() => {
    if (!isVisible) {
      setIsIncident(false);
      setClaimNo(null);
    }
  }, [isVisible]);

  return (
    <Modals
      visible={isVisible}
      toggleModal={toggleIncidentModal}
      title={labels.get('titles.newIncident')}
      destroyOnClose
      footer={!isIncident ? null : [
        <div className="group-btns" key="footer">
          <Button className="btn btn-success min-wt-86 inline" onClick={submitForm}>
            {labels.get('buttons.save')}
          </Button>
          <Button className="btn min-wt-86" onClick={toggleIncidentModal}>{labels.get('buttons.cancel')}</Button>
        </div>,
      ]}
    >
      <Form
        form={form}
        formId={formId.ADD_INCIDENT}
        url={claimNo && `${apiUrls.COLLECTIONS_INCIDENT_ADD}/${claimNo}`}
        onRequestComplete={onRequestComplete}
        isUpdate
        isCustomError
        customErrorMessage={customErrorMessage}
        initialData={{
          denialReason: 'Manual Denial',
        }}
      >
        <IncidentForm
          onChange={onClaimNoChange}
          labels={labels}
          claimNo={claimNo}
          isIncident={isIncident}
          setIsIncident={setIsIncident}
        />
      </Form>
    </Modals>
  );
};

export default NewIncidentModal;
