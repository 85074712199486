import React, { useCallback } from 'react';
import { Form as AntdForm } from 'antd';

import Notification from '../../../components/Notification';
import Button from '../../../components/Button';
import Form from '../../../components/Form';

import useRedirect from '../../../hooks/useRedirect';
import WithLabel from '../../../hoc/withLabel';

import formFieldValuesParser from '../../../lib/formFieldValuesParser';
import SuccessMessages from '../../../lib/successMessages';
import {
  UiRoutes, labelPaths, formId, listIds, contentType,
} from '../../../lib/constants';

import { apiUrls } from '../../../api/constants';

import ContractForm from '../Components/ContractForm';

import '../contract.scss';

const parser = (values, allValues) => {
  const parsedValues = formFieldValuesParser(values, {
    date: [
      'effectiveFrom',
      'effectiveTo',
    ],
    isDateToString: true,
    files: ['contractFile'],
  });
  if (parsedValues?.appliedToAllProvider) {
    parsedValues.SelectedContractProviderIds = [];
  } else {
    parsedValues.SelectedContractProviderIds = allValues.selectedProviders?.map(
      (item) => item.providerId);
  }
  if (parsedValues?.appliedToAllLocations) {
    parsedValues.selectedContractLocations = [];
  } else {
    parsedValues.SelectedLocationIds = allValues.selectedLocations?.map(
      (item) => item.locationId).join(',');
  }
  parsedValues.SelectedContractPayerIds = allValues.selectedPayers?.map(
    (item) => item.insurancePayerId || item.payerId);
  return parsedValues;
};

const NewContract = ({ labels }) => {
  const [form] = AntdForm.useForm();

  const { params, push, generatePath } = useRedirect();

  const navigateToContractTable = useCallback(() => push(UiRoutes.contract), [push]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response?.contractId) {
      Notification({ message: SuccessMessages.CONTRACT_SAVE_SUCCESSFULLY, success: true });
      push(generatePath(UiRoutes.contract, { ...params }));
    }
  }, [generatePath, params, push]);

  return (
    <Form
      section
      form={form}
      formId={formId.ADD_CONTRACT_GENERAL}
      parser={parser}
      url={apiUrls.ADD_CONTRACT_GENERAL}
      onRequestComplete={onRequestComplete}
      scrollToFirstError
      listId={listIds.CONTRACT}
      contentType={contentType.MULTIPART}
      initialData={{
        status: true,
        appliedToAllLocations: true,
        appliedToAllProvider: true,
      }}
    >
      <div className="new-contract-wrap">
        <div className="heading-area flex justify-content-sp-bt align-center">
          <h2 className="bold">{labels.get('header.newContract')}</h2>
          <div className="group-btns">
            <Button className="btn min-wt-86 sm-btn" onClick={navigateToContractTable}>{labels.get('buttons.cancel')}</Button>
            <Button className="btn btn-success min-wt-86 sm-btn" type="submit">{labels.get('buttons.save')}</Button>
          </div>
        </div>
        <div className="new-contract-form shadow-wrap">
          <ContractForm
            form={form}
          />
        </div>
      </div>
    </Form>
  );
};

export default WithLabel(NewContract, labelPaths.NEW_CONTRACT);
