import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Form as AntdForm } from 'antd';
import moment from 'moment';

import useCRUD from '../../../hooks/useCRUD';
import WithLabel from '../../../hoc/withLabel';

import SuccessMessages from '../../../lib/successMessages';
import { formName, labelPaths, listId } from '../../../lib/constants';
import { apiUrls } from '../../../api/constants';

import useRedirect from '../../../hooks/useRedirect';

import Form from '../../../components/Form';
import Button from '../../../components/Button';
import Notification from '../../../components/Notification';

import General from '../components/General';

function AddLocation(props) {
  const {
    parser,
    isUpdate,
    initialDataParser,
    editKey,
    formId,
    url,
    getUrl,
    onRequestComplete,
    labels,
    isLocationViewOnly,
  } = props;
  const [form] = AntdForm.useForm();
  const { params } = useRedirect();
  const { tabId, id } = params;
  const [facilityTypeId, setFacilityTypeId] = useState();
  const [countryId, setCountryId] = useState();
  const [selectedEntity, setSelectedBusinessEntity] = useState({});
  const [mfaxKeyDetails, setMfaxKeyDetails] = useState();

  const [regenerateAPIKeyResponse,,, regenerateAPIKey, clearRegenerateResponse] = useCRUD({ id: 'regenerate-mfax-api-key', url: apiUrls.REGENERATE_MFAX_API_KEY, type: 'create' });

  const getFormData = useCallback((data = {}) => {
    setFacilityTypeId(data.facilityTypeId);
    setCountryId(data.countryId);
    setSelectedBusinessEntity({
      name: data?.businessEntityName,
      value: data?.businessEntityId,
    });
    setMfaxKeyDetails(data?.mFaxLocation);
  }, []);

  useEffect(() => {
    if (regenerateAPIKeyResponse) {
      Notification({ message: SuccessMessages.MFAX_KEY_GENERATE_SUCCESSFULLY, success: true });
      clearRegenerateResponse(true);
    }
  }, [regenerateAPIKeyResponse]);

  const calculateMfaxKeyExpiryStatus = useMemo(() => {
    const noOfDays = moment(mfaxKeyDetails?.keyExpiryDate).diff(moment(), 'days');
    if (noOfDays > 0 && noOfDays <= 90) {
      return true;
    }
    return false;
  }, [mfaxKeyDetails]);

  const handleKeyRegeneration = useCallback(() => {
    regenerateAPIKey({
      data: {
        locationId: id,
      },
    });
  }, [id, regenerateAPIKey]);

  return (
    <Form
      form={form}
      section
      formId={formId}
      parser={parser}
      isUpdate={isUpdate}
      initialDataParser={initialDataParser}
      getUrl={getUrl}
      url={url}
      editKey={editKey}
      onRequestComplete={onRequestComplete}
      name={formName.locationsForm}
      onGetResponseComplete={getFormData}
      tabId={tabId}
      listId={listId.LOCATIONS}
      disableForm={isLocationViewOnly}
    >
      {({ resetForm }) => (
        <div className="main-content-area">
          <div className="main-form-container">
            <General
              form={form}
              labels={labels}
              facilityTypeId={facilityTypeId}
              countryId={countryId}
              selectedEntity={selectedEntity}
              mfaxKeyDetails={mfaxKeyDetails}
              showMfaxButton
              calculateMfaxKeyExpiryStatus={calculateMfaxKeyExpiryStatus}
              handleKeyRegeneration={handleKeyRegeneration}
            />
            <Form.Section>
              <div className="btns-action">
                <Button className="btn btn-success min-wt-86" type="submit" id="locations_general_update">{labels.get('buttons.update')}</Button>
                <Button className="btn min-wt-86" onClick={resetForm} id="locations_general_clear">{labels.get('buttons.clear')}</Button>
              </div>
            </Form.Section>
          </div>
        </div>
      )}
    </Form>
  );
}

export default WithLabel(AddLocation, labelPaths.EDIT_LOCATION_GENERAL);
