import React, { useCallback, useEffect } from 'react';
import get from 'lodash/get';

import useReduxState from '../../../../../../../hooks/useReduxState';
import SelectBox from '../../../../../../../components/Form/SelectBox';
import Checkbox from '../../../../../../../components/Form/CheckBox';
import Input from '../../../../../../../components/Form/Input';
import Radio from '../../../../../../../components/Form/Radio';
import Form from '../../../../../../../components/Form';

import EnumSelect from '../../../../../../../wiredComponents/Enum/Select';

import { enums } from '../../../../../../../lib/constants';

const localityOption = [
  {
    name: 'Variable Based On Location',
    value: 0,
    label: 'Variable Based On Location',
  },
  {
    name: 'Specific Value',
    value: 1,
    label: 'Specific Value',
  },
];

const facilityOption = [
  {
    name: 'Variable Based On Location',
    value: 0,
    label: 'Variable Based On Location',
  },
  {
    name: 'Specific Value',
    value: 1,
    label: 'Specific Value',
  },
];

const billingYearOption = [
  {
    name: 'Variable Based On DOS',
    value: 0,
    label: 'Variable Based On DOS',
  },
  {
    name: 'Specific Value',
    value: 1,
    label: 'Specific Value',
  },
];

const selectedOptions = {
  billingYear: 1,
  facilityId: 1,
  localityId: 1,
};

const getLastTenYears = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const lastTenYearJson = [];

  for (let i = currentYear; i > currentYear - 10; i -= 1) {
    lastTenYearJson.push({ name: i.toString(), value: i });
  }

  return lastTenYearJson;
};

const GeneralForm = ({ labels, data }) => {
  const [isSelected, setSelected] = useReduxState('provider-fee-preferences-general-form-isSelected');

  const onChange = useCallback((name) => ({ target: { value } }) => {
    setSelected({
      isDataTouched: true,
      ...isSelected,
      [name]: selectedOptions[name] === value,
    });
  }, [setSelected, isSelected]);

  useEffect(() => {
    if (!isSelected || !isSelected.isDataTouched) {
      setSelected(data);
    }
  }, [data]);

  return (
    <Form.Section noOfColumns={3}>
      <Form.Column>
        <div className="group-shadow">
          <EnumSelect
            label={labels.get('labels.defaultSchedule')}
            placeholder={labels.get('placeholders.defaultSchedule')}
            name="defaultScheduleId"
            required
            inputSpan="16"
            labelSpan="8"
            enumName={enums.SCHEDULAR_PROVIDER}
          />
          <Input
            label={labels.get('labels.multiplier')}
            name="multiplier"
            id="9"
            labelSpan="8"
            inputSpan="16"
            numberOnly
          />
        </div>
      </Form.Column>
      <Form.Column>
        <div className="group-shadow">
          <Radio
            options={localityOption}
            label={labels.get('labels.locality')}
            name="isLocality"
            labelSpan="8"
            inputSpan="16"
            onChange={onChange('localityId')}
          />
          {get(isSelected, 'localityId') && (
            <EnumSelect
              name="localityId"
              labelSpan="8"
              inputSpan="16"
              enumName={enums.LOCALITY}
            />
          )}
          <Radio
            options={facilityOption}
            label={labels.get('labels.facility')}
            name="isFacility"
            labelSpan="8"
            inputSpan="16"
            onChange={onChange('facilityId')}
          />
          {get(isSelected, 'facilityId') && (
            <EnumSelect
              name="facilityId"
              labelSpan="8"
              inputSpan="16"
              enumName={enums.FACILITY_TYPE}
            />
          )}
        </div>
      </Form.Column>
      <Form.Column>
        <div className="group-shadow">
          <Radio
            options={billingYearOption}
            label={labels.get('labels.billingYear')}
            name="isBillingYear"
            labelSpan="8"
            inputSpan="16"
            onChange={onChange('billingYear')}
          />
          {get(isSelected, 'billingYear') && (
            <SelectBox
              name="billingYear"
              labelSpan="8"
              inputSpan="16"
              options={getLastTenYears()}
            />
          )}
          <Checkbox
            name="isRoundFee"
            label={labels.get('labels.roundFee')}
            valuePropName="checked"
            labelSpan="8"
            inputSpan="16"
          />
        </div>
      </Form.Column>
    </Form.Section>
  );
};
export default GeneralForm;
