import React, {
  useState, useCallback, useRef, useEffect,
} from 'react';
import { Form as AntdForm } from 'antd';

import { useReactToPrint } from 'react-to-print';
import Form from '../../../../../components/Form';
import Modals from '../../../../../components/Modal';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Form/Input';
import DatePicker from '../../../../../components/Form/DatePicker';
import WithLabel from '../../../../../hoc/withLabel';
import { labelPaths, listIds } from '../../../../../lib/constants';
import { getDateWithZeroTimeFormat } from '../../../../../lib/util';

import InsuranceRefundTable from './EditInsuranceRefundTable';
import PayerGridAutoComplete from '../../../../../wiredComponents/PayerGridAutoComplete';
import WiredSelect from '../../../../../wiredComponents/Select/selectBoxV2';
import { apiUrls } from '../../../../../api/constants';
import SuccessMessages from '../../../../../lib/successMessages';
import useRedirect from '../../../../../hooks/useRedirect';
import useCRUD from '../../../../../hooks/useCRUD';
import Loader from '../../../../../components/Loader';
import Notification from '../../../../../components/Notification';
import Events from '../../../../../lib/events';
import SelectBox from '../../../../../components/Form/SelectBox';

const parser = (formValues) => ({
  ...formValues,
  effectiveDate: getDateWithZeroTimeFormat(formValues.effectiveDate),
  checkDate: getDateWithZeroTimeFormat(formValues.checkDate),
});

function InsuranceRefund({ visible, toggleModal, labels }) {
  const [form] = AntdForm.useForm();
  const ref = useRef();
  const viewPanel = useRef(null);
  const confirmModalRef = useRef(null);
  const { params: { id } } = useRedirect();
  const [lineItemData, setLineItemData] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState();
  const [selectedLocation, setSelectedLocation] = useState();

  const onSelectProvider = useCallback((providerId) => setSelectedProvider(providerId), []);
  const onSelectLocation = useCallback((locationId) => setSelectedLocation(locationId), []);

  const [
    printReceiptResponse,,
    printReceiptLoading,
    printReceipt,
  ] = useCRUD({
    id: listIds.PRINT_ADJUSTMENT_RECEIPT,
    url: apiUrls.PRINT_ADJUSTMENT_RECEIPT,
    type: 'read',
  });

  const handleHtmlPrint = useReactToPrint({
    content: () => viewPanel.current,
    copyStyles: false,
  });

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({
        message: SuccessMessages.INSURANCE_REFUND_ADDED_SUCCESSFULLY,
        success: true,
      });
      toggleModal(false);
      Events.trigger('refetch-balance-sheet');
      Events.trigger('refetch-charge-table');
    }
  }, [id, printReceipt, toggleModal]);

  const getLineItems = useCallback(() => {
    const lineItems = [];
    lineItemData.forEach(({ charges }) => charges.forEach((charge) => {
      if (parseFloat(charge?.refund) > 0) {
        lineItems.push({
          financeId: charge?.financeId,
          refund: charge?.refund,
          paid: charge?.paid,
        });
      }
    }));
    return lineItems;
  }, [lineItemData]);

  const handleSubmit = useCallback(() => {
    const lineItems = getLineItems();
    if (ref.current.checkIfRefundGreaterThanPaid()) {
      Notification({ message: 'Refund amount cannot be more than paid amount' });
      return;
    }
    if (lineItems?.length) {
      const totalApplied = lineItems?.reduce((sum, {
        refund =
        0,
      }) => sum + parseFloat(refund), 0) || 0;
      const amount = parseFloat(form.getFieldValue('amount') || 0);
      if (totalApplied > amount) {
        Notification({ message: 'Refund amount should not be less then total applied' });
      } else {
        form.submit();
      }
    } else {
      Notification({
        message: 'There must be at least one line item',
      });
    }
  }, [form, getLineItems]);

  useEffect(() => {
    if (printReceiptResponse && !Array.isArray(printReceiptResponse)) {
      viewPanel.current.innerHTML = printReceiptResponse || '';
      handleHtmlPrint();
      confirmModalRef.current();
      confirmModalRef.current = null;
    }
  }, [printReceiptResponse]);

  const getExtraData = useCallback(() => ({
    lineItems: getLineItems(),
    PaymentSource: 'OverTheCounter',
    PaymentMethod: 'Manual',
    patientId: id,
  }), [getLineItems, id]);

  return (
    <>
      <div ref={viewPanel} className="display-none" />
      <Modals
        visible={!!visible}
        toggleModal={toggleModal}
        width="1000px"
        destroyOnClose
        footer={[
          <div className="group-btns" key="footer">
            <Button
              id="patients_financial_insuranceRefund_cancel"
              className="btn min-wt-86"
              onClick={() => toggleModal(false)}
              data-testid="cancel-btn"
            >
              {labels.get('buttons.cancel')}
            </Button>
            <Button
              id="patients_financial_insuranceRefund_save"
              className="btn btn-success min-wt-86 inline"
              onClick={handleSubmit}
              data-testid="save-btn"
            >
              {labels.get('buttons.save')}
            </Button>
          </div>,
        ]}
      >
        {printReceiptLoading && <Loader />}
        <Form
          section
          form={form}
          url={apiUrls.INSURANCE_REFUND_IN_DETAIL_PAGE}
          formId={`${apiUrls.INSURANCE_REFUND_IN_DETAIL_PAGE}-${id}`}
          extraData={getExtraData}
          parser={parser}
          onRequestComplete={onRequestComplete}
        >
          <div className="addition-header">
            <div className="lookup sprite-img-before">
              <p>{labels.get('titles.insuranceRefund')}</p>
            </div>
          </div>
          <div className="modal-content-weapper shadow-wrap">
            <div className="modal-from-feilds">
              <Form.Section>
                <Form.Column noOfColumns="3">
                  <DatePicker
                    label={labels.get('labels.effectiveDate')}
                    name="effectiveDate"
                    required
                    id="19"
                    datePickerProps={{ getPopupContainer: (trigger) => trigger?.parentNode }}
                    dataTestId="effectiveDate"
                  />
                  <Input
                    label={labels.get('labels.refundAmount')}
                    name="amount"
                    id="1"
                    required
                    numberOnly
                    maxValueLength={11}
                    decimalPlaces={2}
                    dataTestId="refundAmount"
                    placeholder="Enter Refund Amount"
                  />
                  <PayerGridAutoComplete
                    label={labels.get('labels.payer')}
                    id="19"
                    name="payerId"
                    labelSpan={10}
                    inputSpan={14}
                    selectProps={{ getPopupContainer: (trigger) => trigger?.parentNode }}
                    dataTestId="payer"
                  />

                </Form.Column>
                <Form.Column noOfColumns="3">
                  <div>
                    <Input
                      label={labels.get('labels.batchIdentifier')}
                      name="batchIdentifier"
                      id="1"
                      maxValueLength={25}
                      dataTestId="batchIdentifier"
                      placeholder="Enter Batch Identifier"
                    />
                    <Input
                      label={labels.get('labels.checkAmount')}
                      name="checkAmount"
                      id="1"
                      required
                      numberOnly
                      maxValueLength={11}
                      decimalPlaces={2}
                      dataTestId="checkAmount"
                      placeholder="Enter Check Amount"
                    />
                    <DatePicker
                      label={labels.get('labels.checkDate')}
                      name="checkDate"
                      required
                      id="19"
                      datePickerProps={{ getPopupContainer: (trigger) => trigger?.parentNode }}
                      dataTestId="checkDate"
                    />
                  </div>
                </Form.Column>
                <Form.Column noOfColumns="3">
                  <div>
                    <Input
                      label={labels.get('labels.checkNumber')}
                      name="checkNumber"
                      required
                      id="1"
                      maxValueLength={25}
                      dataTestId="checkNumber"
                      placeholder="Enter Check Number"
                    />
                    <WiredSelect
                      id="provider"
                      name="providerId"
                      label={labels.get('labels.provider')}
                      placeholder="Select Provider"
                      url={apiUrls.SCHEDULAR_PROVIDER_DROPDOWN}
                      nameAccessor="providerName"
                      valueAccessor="providerId"
                      Component={SelectBox}
                      startCaseOptions
                      selectProps={{
                        style: { width: '100%' },
                        showSearch: true,
                        getPopupContainer: (trigger) => trigger?.parentNode,
                      }}
                      defaultSorting={false}
                      onChange={onSelectProvider}
                      params={{
                        LocationId: selectedLocation,
                      }}
                      dataTestId="provider"
                    />
                    <WiredSelect
                      id="location"
                      name="locationId"
                      label={labels.get('labels.location')}
                      url={apiUrls.SCHEDULAR_LOCATION_DROPDOWN}
                      placeholder="Select Location"
                      nameAccessor="locationName"
                      valueAccessor="locationId"
                      Component={SelectBox}
                      startCaseOptions
                      selectProps={{
                        style: { width: '100%' },
                        showSearch: true,
                        getPopupContainer: (trigger) => trigger?.parentNode,
                      }}
                      params={{
                        ProviderId: selectedProvider,
                      }}
                      onChange={onSelectLocation}
                      required
                      dataTestId="location"
                    />
                  </div>
                </Form.Column>
              </Form.Section>
            </div>
            <div className="modal-content-area">
              <InsuranceRefundTable
                labels={labels}
                lineItemData={lineItemData}
                setLineItemData={setLineItemData}
                ref={ref}
              />
            </div>
          </div>
        </Form>
      </Modals>
    </>
  );
}

export default React.memo(WithLabel(InsuranceRefund, labelPaths.FINANCIAL_POPUP_INSURANCE_REFUND));

