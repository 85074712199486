import React, { useCallback, useState } from 'react';

import Modals from '../../../../components/Modal';
import Notification from '../../../../components/Notification';
import WithLabel from '../../../../hoc/withLabel';
import { labelPaths } from '../../../../lib/constants';
import SuccessMessages from '../../../../lib/successMessages';
import Events from '../../../../lib/events';

import AuthorizationTable from '../../../AuthorizationTable';
import useRedirect from '../../../../hooks/useRedirect';

import EditAuthorization from '../../../../screens/Dashboard/Tabs/Lists/components/Authorization/Edit/EditAuthorizationForm';

import columns from './Table/columns';

import './authorization.scss';
import useReduxStoreWithId from '../../../../hooks/useReduxStoreWithId';

const EditAuthorizationForm = WithLabel(EditAuthorization, labelPaths.EDIT_AUTHORIZATION);

const Doctor = ({ labels, isDisabled }) => {
  const { params } = useRedirect();

  const { id } = params;
  const [isShowEditAuthorizationModal, setEditAuthorizationModalVisibility] = useState(null);
  const [editAuthorizationIdRedux,, setEditAuthorizationId] = useReduxStoreWithId({ id: 'editAuthorizationId-Encounter' });
  const editAuthorizationId = editAuthorizationIdRedux?.get('data');

  const toggleEditAuthorizationModal = useCallback(() => {
    setEditAuthorizationModalVisibility(!isShowEditAuthorizationModal);
  }, [setEditAuthorizationModalVisibility, isShowEditAuthorizationModal]);

  const onEditAuthorizationRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.AUTHORIZATION_UPDATED_SUCCESSFULLY, success: true });
      toggleEditAuthorizationModal();
      Events.trigger('refetchAuthorizationTable');
    }
  }, [toggleEditAuthorizationModal]);

  const navigateToEditAuthorization = useCallback((row) => {
    if (isDisabled) {
      return;
    }
    toggleEditAuthorizationModal();
    setEditAuthorizationId(row?.original?.authorizationId);
  }, [isDisabled, toggleEditAuthorizationModal, setEditAuthorizationId]);

  return (
    <>
      <AuthorizationTable labels={labels} patientId={id} columns={columns} scrollId="authorizationTable" className="authorization-table" onRowClick={navigateToEditAuthorization} />

      <Modals
        visible={isShowEditAuthorizationModal}
        toggleModal={toggleEditAuthorizationModal}
        destroyOnClose
        footer={null}
        width="1200px"
        wrapClassName="add-authorization-modal"
        title="Edit Authorization"
      >
        <EditAuthorizationForm
          onRequestComplete={onEditAuthorizationRequestComplete}
          onCancelButtonClicked={toggleEditAuthorizationModal}
          params={{ ...params, authorizationId: editAuthorizationId }}
        />
      </Modals>
    </>
  );
};

export default WithLabel(Doctor, labelPaths.ENCOUNTER_AUTHORIZATION);
