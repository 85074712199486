import React from 'react';
import TimeCell from '../../../../../components/Cells/TimeCell';

const columns = (labels) => [
  {
    Header: labels.get('tableColumns.type'),
    accessor: 'caseTypeName',
  },
  {
    Header: labels.get('tableColumns.startDate'),
    accessor: 'startDate',
    Cell: (e) => <TimeCell value={e?.row?.original?.effectiveDateFrom} />,
  },
  {
    Header: labels.get('tableColumns.endDate'),
    accessor: 'endDate',
    Cell: (e) => <TimeCell value={e?.row?.original?.effectiveDateTo} />,
  },
  {
    Header: labels.get('tableColumns.provider'),
    accessor: 'providerName',
  },
  {
    Header: labels.get('tableColumns.lastUsed'),
    accessor: 'lastSeenDate',
  },
];

export default columns;
