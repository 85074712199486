import { enumDataIds } from '../../../../../lib/constants';

import readyColumns from './columns/readyColumns';
import errorColumns from './columns/errorColumns';
import outBoxColumns from './columns/outBoxColumns';
import unBatchedColumns from './columns/unBatchedColumns';
import postedBatchColumns from './columns/postedBatchColumns';
import inProgressColumns from './columns/inProgressColumns';

const columnsMap = {
  ready: readyColumns,
  errors: errorColumns,
  outbox: outBoxColumns,
  unBatched: unBatchedColumns,
  inProgress: inProgressColumns,
  postedBatch: postedBatchColumns,
  trash: unBatchedColumns,
};

const initialSortMap = {
  ready: [{ id: 'createdDate', desc: true }],
  errors: [{ id: 'createdDate', desc: true }],
  outbox: [{ id: 'createdDate', desc: true }],
  unBatched: [{ id: 'batchNumber', desc: true }],
  postedBatch: [{ id: 'depositDate', desc: true }],
};

const stateIdMap = {
  ready: enumDataIds.STATE_READY,
  errors: enumDataIds.STATE_ERROR,
  outbox: enumDataIds.STATE_OUTBOX,
  unBatched: enumDataIds.STATE_UNBATCH,
  postedBatch: enumDataIds.STATE_POSTED, // TODO: need to update enum
  trash: enumDataIds.STATE_TRASH,
};

const stageIdMap = {
  ready: enumDataIds.STAGE_NEW,
  errors: enumDataIds.STAGE_ERROR,
  outbox: enumDataIds.STAGE_OUTBOX,
  unBatched: enumDataIds.STAGE_UNBATCH,
  postedBatch: enumDataIds.STAGE_POSTED, // TODO: need to update enum
  trash: enumDataIds.STAGE_TRASH,
};

export const getColumns = (active) => (columnsMap[active] ? columnsMap[active] : () => ([]));

export const getInitialSort = (active) => (initialSortMap[active]
  ? initialSortMap[active] : []);

export const getStateId = (active) => (stateIdMap[active] ? stateIdMap[active]
  : enumDataIds.STATE_READY);

export const getStageId = (active) => (stageIdMap[active] ? stageIdMap[active]
  : enumDataIds.STAGE_READY);
