import React from 'react';
import isArray from 'lodash/isArray';

const RenderMultipleSynoynms = ({ synonyms }) => {
  const synonymsArray = synonyms?.split('\n');
  return (
    <span>
      {isArray(synonymsArray) && synonymsArray?.map((item, index) => (
        <span
          className="flex flex-dir-col"
          key={index}
          data-testid="synonym"
        >
          {item}
        </span>
      )
        )}
    </span>
  );
};

export default RenderMultipleSynoynms;
