import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { UiRoutes } from '../../lib/constants';
import rights from '../../lib/rights';
import SavedNavigationBar from '../../components/SavedNavigationBar';

import Tabs from './Tabs';
import Edit from './withoutTabs/documents/Edit';

import PatientEncounter from '../../wiredComponents/AddNewEncounter';
import ActionIcons from '../../wiredComponents/ActionIcons';
import Adjustment from '../../wiredComponents/Adustment';

import './dashboard.scss';
import useRedirect from '../../hooks/useRedirect';
import useRights from '../../hooks/useRights';

function EditPatient() {
  const { params } = useRedirect();
  const [
    isUpdatePatientAuthenticated,
    isCreateDocumentAuthenticated,
    // isUpdateDocumentAuthenticated,
    // isReadDocumentAuthenticated,
    isDocumentAuthenticated,
    isPayerRequestAuthenticated,
  ] = useRights([
    rights.update_patient,
    rights.create_document,
    // rights.update_document,
    // rights.read_document,
    rights.access_to_document_management_app,
    rights.payer_request,
  ]);
  return (
    <SavedNavigationBar
      listName="Patient List"
      parentPath={UiRoutes.patients}
      title={(<h1>Patients</h1>)}
      rightChildren={(
        <ActionIcons
          fax
          share
          print
          email
          camera
          userNotes
          merge
          edit
          pVerify
          printProps={{
            patientId: params?.id,
            superbill: true,
            facesheet: true,
            detailsStatement: true,
            appointment: true,
            clinicalSummary: true,
            includeLatestAppointmentSuperBill: true,
          }}
          notesProps={{
            page: 'patients',
          }}
          patientId={params?.id}
          key={params?.id}
        />
)}
    >
      {({ closeTab }) => (
        <div className="main-form-container">
          <Switch>
            <Route path={UiRoutes.editFinancialEncounterWithTabId}>
              <PatientEncounter closeTab={closeTab} />
            </Route>
            <Route exact path={UiRoutes.editPatientDocumentWithTabId}>
              <Edit
                closeTab={closeTab}
                isUpdatePatientAuthenticated={isUpdatePatientAuthenticated}
                isCreateDocumentAuthenticated={isCreateDocumentAuthenticated}
               /* isUpdateDocumentAuthenticated={isUpdateDocumentAuthenticated} */
                // isReadDocumentAuthenticated={isReadDocumentAuthenticated}
              />
            </Route>
            <Route path={UiRoutes.newEncounterWithTabId}>
              <PatientEncounter closeTab={closeTab} />
            </Route>
            <Route path={UiRoutes.editEncounterWithId}>
              <PatientEncounter closeTab={closeTab} />
            </Route>
            <Route path={UiRoutes.newAdjustmentWithTabId}>
              <Adjustment closeTab={closeTab} />
            </Route>
            <Route>
              <Tabs
                closeTab={closeTab}
                isUpdatePatientAuthenticated={isUpdatePatientAuthenticated}
                isCreateDocumentAuthenticated={isCreateDocumentAuthenticated}
               /* isUpdateDocumentAuthenticated={isUpdateDocumentAuthenticated} */
                // isReadDocumentAuthenticated={isReadDocumentAuthenticated}
                isDocumentAuthenticated={isDocumentAuthenticated}
                isPayerRequestAuthenticated={isPayerRequestAuthenticated}
              />
            </Route>
          </Switch>
        </div>
      )}
    </SavedNavigationBar>
  );
}

export default EditPatient;
