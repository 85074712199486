const sortData = (sortBy, data = []) => {
  const { id, desc } = sortBy?.[0] || {};
  const sortedData = [...data];
  sortedData.sort((a, b) => {
    switch (true) {
      case (id === 'modifiedOn' && !desc):
        return new Date(a.modifiedOn) - new Date(b.modifiedOn);
      case (id === 'modifiedOn' && desc):
        return new Date(b.modifiedOn) - new Date(a.modifiedOn);
      case (id === 'permissionSetName' && !desc):
        return a.permissionSetName.localeCompare(b.permissionSetName);
      case (id === 'permissionSetName' && desc):
        return b.permissionSetName.localeCompare(a.permissionSetName);
      case (id === 'modifiedBy' && !desc):
        return a.modifiedBy.localeCompare(b.modifiedBy);
      case (id === 'modifiedBy' && desc):
        return b.modifiedBy.localeCompare(a.modifiedBy);
      default:
        return 0;
    }
  });
  return sortedData;
};

export default sortData;
