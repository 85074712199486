import React from 'react';
import { Switch, Route } from 'react-router-dom';
import DocumentsTable from './DocumentsTable';
import { UiRoutes, labelPaths } from '../../../../lib/constants';
import WithLabel from '../../../../hoc/withLabel';

function Documents({
  labels, closeTab, isUpdateDocumentAuthenticated, isCreateDocumentAuthenticated,
  isReadDocumentAuthenticated,
}) {
  return (
    <Switch>
      <Route exact path={UiRoutes.editPatientWithTabId}>
        <DocumentsTable
          labels={labels}
          closeTab={closeTab}
          isCreateDocumentAuthenticated={isCreateDocumentAuthenticated}
          isUpdateDocumentAuthenticated={isUpdateDocumentAuthenticated}
          isReadDocumentAuthenticated={isReadDocumentAuthenticated}
        />
      </Route>
      <Route path={UiRoutes.patientDocumentsWithCategoryId}>
        <DocumentsTable
          labels={labels}
          closeTab={closeTab}
          isCreateDocumentAuthenticated={isCreateDocumentAuthenticated}
          isUpdateDocumentAuthenticated={isUpdateDocumentAuthenticated}
          isReadDocumentAuthenticated={isReadDocumentAuthenticated}
        />
      </Route>
    </Switch>

  );
}
export default WithLabel(Documents, labelPaths.PATIENT_DOCUMENTS);
