import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';
import isFunction from 'lodash/isFunction';

import AutoComplete from '../../../components/Form/AutoComplete/AntdAutocomplete';
import { apiUrls } from '../../../api/constants';
import Table from '../../../components/Table';
import providerColumns from './columns/provider';
import providerOptionRenderer from '../commonOptionRenderer';

function ProviderLookup({
  label,
  placeholder,
  disabled,
  required,
  labelSpan,
  inputSpan,
  type,
  allowClear,
  initialData,
  setParentData,
  disableTable,
  onClickDisableTable,
  isTableRequired,
  initialValue,
  params,
  onSelect,
  isSetToInitial,
  resetOnParamChange,
  addLocationMethod,
  addLocationMethodCopy,
}) {
  const [data, setData] = useState([]);
  const [initialTableData, setInitialTableData] = useState(null);

  useEffect(() => {
    if (isSetToInitial) {
      setData(initialTableData || []);
      if (isFunction(setParentData)) setParentData(initialTableData || []);
    }
  }, [initialTableData, isSetToInitial, setParentData]);

  useEffect(() => {
    if (initialData) {
      setData(initialData);
      if (!initialTableData) setInitialTableData(initialData);
    }
  }, [initialData, initialTableData]);

  const providerOptionParser = useCallback((option) => ({
    name: option.providerName,
    value: option.providerName,
    ...option,
  }), []);

  const onProviderSelect = useCallback((value, allItems) => {
    const { item } = allItems;
    const index = findIndex(data, (provider) => item.providerId === provider.id
      || item.providerId === provider.providerId);
    if (index < 0) {
      setData([...data, item]);
      setParentData([...data, item]);
    }
    if (onSelect) {
      onSelect(allItems, [...data, item]);
    }
  }, [data, onSelect, setParentData]);

  const deleteProviderFromTable = useCallback((id) => {
    const index = findIndex(data, (item) => item.id === id);
    const cloned = [...data];
    cloned.splice(index, 1);
    setData(cloned);
    setParentData(cloned);
  }, [data, setParentData]);

  const deleteProvider = useCallback((id) => {
    if (disableTable && isFunction(onClickDisableTable)) {
      onClickDisableTable({ isDelete: true, removeProvider: deleteProviderFromTable, id });
    } else {
      deleteProviderFromTable(id);
    }
  }, [disableTable, onClickDisableTable, deleteProviderFromTable]);

  return (
    <div>
      <div
        role="presentation"
        onClick={onClickDisableTable}
      >
        <AutoComplete
          label={label}
          placeholder={placeholder}
          name="ProviderName"
          url={apiUrls.GET_PROVIDERS}
          optionParser={providerOptionParser}
          optionMaster="SearchText"
          labelSpan={labelSpan}
          inputSpan={inputSpan}
          disabled={disabled}
          required={required}
          optionRenderer={providerOptionRenderer}
          style={{ width: '100%' }}
          dropdownMatchSelectWidth={350}
          onSelect={onProviderSelect}
          allowClearOnSelect={allowClear}
          initialValue={initialValue}
          params={params}
          resetOnParamChange={resetOnParamChange}
          dataTestId="providerName"
        />
      </div>
      {type === 'multi' && data.length > 0 && isTableRequired
        ? (
          <Table
            columns={providerColumns}
            data={data}
            deleteProvider={deleteProvider}
            addLocationMethod={addLocationMethod}
            addLocationMethodCopy={addLocationMethodCopy}
            disableTable={disableTable}
          />
        )
        : null}
    </div>
  );
}

ProviderLookup.defaultProps = {
  label: '',
  placeholder: '',
  disabled: false,
  required: false,
  labelSpan: '10',
  inputSpan: '13',
  type: 'multi',
  allowClear: false,
  initialData: [],
  setParentData: () => { /* This is intentional */ },
  disableTable: false,
  onClickDisableTable: null,
  isTableRequired: true,
  params: {},
  isSetToInitial: false,
};

ProviderLookup.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  labelSpan: PropTypes.string,
  inputSpan: PropTypes.string,
  type: PropTypes.string,
  allowClear: PropTypes.bool,
  initialData: PropTypes.arrayOf(PropTypes.object),
  setParentData: PropTypes.func,
  disableTable: PropTypes.bool,
  onClickDisableTable: PropTypes.func,
  isTableRequired: PropTypes.bool,
  params: PropTypes.objectOf,
  isSetToInitial: PropTypes.bool,
};

export default ProviderLookup;
