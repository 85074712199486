import React from 'react';

import { listIds } from '../../../../../lib/constants';

import withQuery from '../../../../../hoc/withQuery/withQuery';
import withReduxManager from '../../../../../hoc/withReduxManager';

import columns from './columns';
import { apiUrls } from '../../../../../api/constants';

const CustomTaskTable = withReduxManager({
  listId: listIds.PATIENT_TASK_TABLE,
})(withQuery({
  url: apiUrls.GET_PATIENT_TASK,
  listId: listIds.PATIENT_TASK_TABLE,
})());

const initialSort = [{ id: 'createdOn', desc: true }];

const TaskTable = ({
  labels, patientId, onRowClick, scrollId,
}) => (
  <CustomTaskTable filters={{ PatientId: patientId, GetAllStatusTasks: true }} onRowClick={onRowClick} columns={columns(labels)} initialSort={initialSort} noDataText="Tasks Data not found" scrollId={scrollId}>
    {({
      Component,
    }) => (
      Component
    )}
  </CustomTaskTable>
);

export default TaskTable;
