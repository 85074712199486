const multiplierRegex = /^($|\d{1,5}|\d{0,5}\.|\d{0,5}\.\d{1,2})$/;

export const validator = (data) => {
  const error = {};
  if (!data.modifierId) error.modifierCPTCode = 'Modifier is required';
  if (!data.multiplier && data.multiplier !== 0) error.multiplier = 'Multiplier is required';
  if (Object.values(error).length) return error;
  return {};
};

export const numberCheck = (value, prevValue) => {
  if (multiplierRegex.test(value)) return value;
  if (multiplierRegex.test(prevValue) && !multiplierRegex.test(value)) return prevValue;
  return '';
};

export const onChangeData = ({
  label, event, modifier, dataError,
}) => {
  const modifierData = { ...modifier };
  const resetError = { ...dataError };

  switch (label) {
    case 'multiplier': {
      modifierData.multiplier = numberCheck(event.target.value, modifier.multiplier);
      if (dataError.multiplier) resetError.multiplier = false;
      break;
    }
    case 'modifierCPTCode': {
      const {
        modifierCode, description, modifierId, tier,
      } = event || {};
      modifierData.modifierId = modifierId;
      modifierData.modifierCPTCode = modifierCode;
      modifierData.modifierTier = tier;
      modifierData.modifierDescription = description;
      if (dataError.modifierCPTCode) resetError.modifierCPTCode = false;
      break;
    }
    default: return null;
  }
  return {
    modifierData,
    resetError,
  };
};

export const setFocusToFields = (error, parentFocusId) => {
  if (error?.modifierCPTCode) {
    const node = document.getElementById(`${parentFocusId}_search_autocomplete`);
    if (node) node.focus();
  } else if (error?.multiplier) {
    const node = document.getElementById(`${parentFocusId}_input`);
    if (node) node.focus();
  }
};
