import React from 'react';

import { UiRoutes } from '../../../lib/constants';

import Link from '../../../components/SavedNavigationBar/Link';
import TimeCell from '../../../components/Cells/TimeCell';

const columns = (labels, isPermitted) => ([
  {
    Header: labels.get('tableColumns.name'),
    accessor: 'locationName',
    Cell: ({ row: { original: { locationId, id, locationName } } }) => (
      isPermitted ? (
        <Link
          to={`${UiRoutes.editLocationWithTabId}`}
          params={{
            id: locationId || id, // id check is added to support dev environment
            tab: 'general',
            data: { name: locationName },
          }}
        >
          <span title={locationName}>
            {locationName}
          </span>
        </Link>
      ) : (
        <span title={locationName}>
          {locationName}
        </span>
      )),
    sort: true,
  },
  {
    Header: labels.get('tableColumns.type'),
    accessor: 'locationTypeName',
  },
  {
    Header: labels.get('tableColumns.status'),
    accessor: 'isActive',
    Cell: ({ row: { original: { isActive } } }) => {
      const color = isActive ? '#32c788' : 'red';
      return <span style={{ color }}>{isActive ? 'Active' : 'Inactive'}</span>;
    },
  },
  {
    Header: labels.get('tableColumns.lastModified'),
    accessor: 'modified',
    sort: true,
    Cell: ({
      row: { original: { modified, modifiedOn } },
    }) => <TimeCell value={modified || modifiedOn} />,
  },
  {
    Header: labels.get('tableColumns.lastModifiedBy'),
    accessor: 'modifiedByName',
    sort: true,
  },
]);

export default columns;
