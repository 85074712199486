import React from 'react';

import TimeCell from '../../../../../../../components/Cells/TimeCell';

const InsurancePayment = (labels) => [
  {
    Header: labels.get('tableColumns.dos'),
    accessor: 'dos',
    Cell: ({
      row: { original: { billingServiceLineItemCreatedDate } },
    }) => (
      <div className="text-align-left">
        {`${billingServiceLineItemCreatedDate?.month}/${billingServiceLineItemCreatedDate?.day}/${billingServiceLineItemCreatedDate?.year}`}
      </div>
    ),
  },
  {
    Header: labels.get('tableColumns.claimDate'),
    accessor: 'claimSubmissionDate',
    Cell: ({ row: { original } }) => <TimeCell value={original?.createdOn?.dateString || ''} />,
    className: 'text-align-left',
  },
  {
    Header: labels.get('tableColumns.number'),
    accessor: 'claimNo',
    className: 'text-align-left',
  },
  {
    Header: labels.get('tableColumns.payer'),
    accessor: 'insurancePayerName',
    className: 'text-align-left',
  },
  {
    Header: labels.get('tableColumns.provider'),
    accessor: 'providerName',
    className: 'text-align-left',
  },
  {
    Header: labels.get('tableColumns.location'),
    accessor: 'locationName',
    className: 'text-align-left',
  },
  {
    Header: labels.get('tableColumns.amount'),
    accessor: 'amount',
    className: 'text-align-right',
  },
];

export default InsurancePayment;
