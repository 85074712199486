import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';
import isFunction from 'lodash/isFunction';

import Table from '../../../components/Table';
import AutoComplete from '../../../components/Form/AutoComplete/AntdAutocomplete';
import { apiUrls } from '../../../api/constants';
import payerColumns from './columns/payer';
import payerOptionRenderer from './payerOptionRenderer';

function PayerLookup({
  label,
  placeholder,
  disabled,
  required,
  labelSpan = '10',
  inputSpan = '13',
  initialData,
  setParentData,
  isSetToInitial,
  dataTestId,
  debounceDuration,
  ...otherProps
}) {
  const [data, setData] = useState([]);
  const [isRequired, setRequired] = useState(required);
  const [initialTableData, setTableData] = useState(null);

  useEffect(() => {
    if (isSetToInitial) {
      setData(initialTableData || []);
      if (isFunction(setParentData)) setParentData(initialTableData || []);
    }
  }, [initialTableData, isSetToInitial, setParentData]);

  useEffect(() => {
    if (required) {
      if (data.length) {
        setRequired(false);
      } else {
        setRequired(true);
      }
    }
  }, [required, data, setRequired, isRequired]);

  useEffect(() => {
    if (initialData) {
      setData(initialData);
      if (!initialTableData) setTableData(initialData);
    }
  }, [initialData, initialTableData]);

  const payerOptionParser = useCallback((option) => ({
    name: option.name,
    value: option.name,
    ...option,
  }), []);

  const onPayerSelect = useCallback((value, allItems) => {
    const { item } = allItems;
    const index = findIndex(data, (payer) => item?.insurancePayerId === payer.id
      || item.insurancePayerId === payer.insurancePayerId);
    if (index < 0) {
      setData([...data, item]);
      setParentData([...data, item]);
    }
  }, [data, setParentData]);

  const deletePayer = useCallback((id) => {
    const index = findIndex(data, (item) => item?.insurancePayerId === id
    || item?.contractPayerId === id);
    const cloned = [...data];
    cloned.splice(index, 1);
    setData(cloned);
    setParentData(cloned);
  }, [data, setParentData]);

  return (
    <div>
      <AutoComplete
        label={label}
        placeholder={placeholder}
        name="payerName"
        url={apiUrls.GET_PAYERS}
        optionParser={payerOptionParser}
        optionMaster="SearchText"
        labelSpan={labelSpan}
        inputSpan={inputSpan}
        disabled={disabled}
        required={isRequired}
        optionRenderer={payerOptionRenderer}
        style={{ width: '100%' }}
        dropdownMatchSelectWidth={350}
        onSelect={onPayerSelect}
        allowClearOnSelect
        debounceDuration={debounceDuration}
        autoCompleteProps={{ 'data-testid': dataTestId }}
        {...otherProps}
      />
      {data.length > 0
        ? <div className="mr-bt-12" data-testid="payer-lookup-table"><Table columns={payerColumns} data={data} deletePayer={deletePayer} /></div> : null}

    </div>
  );
}

PayerLookup.defaultProps = {
  initialData: [],
  label: '',
  placeholder: '',
  disabled: false,
  required: false,
  setParentData: () => { /* This is intentional */ },
  labelSpan: '10',
  inputSpan: '13',
  isSetToInitial: false,
  dataTestId: '',
};

PayerLookup.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  initialData: PropTypes.arrayOf(PropTypes.object),
  setParentData: PropTypes.func,
  labelSpan: PropTypes.string,
  inputSpan: PropTypes.string,
  isSetToInitial: PropTypes.bool,
  dataTestId: PropTypes.string,
};

export default PayerLookup;
