import React from 'react';
import { FollowupCommon } from '../FollowupTab';

const PhysicianTableRow = ({ heading }) => (
  <tr>
    <td style={{ paddingLeft: '48px', paddingTop: '4px' }}>
      <input type="checkbox" id="facemask" name="facemask" value="facemask" />
      <label htmlFor="facemask">
        {heading}
      </label>
    </td>
    <td />
    <td />
  </tr>
);

const PhysicianTableRowWithSpan = ({ value, heading }) => (
  <tr>
    <td style={{ paddingLeft: '4px' }}>
      {`${value}.`}
      <span style={{
        textTransform: 'uppercase', fontWeight: '700', paddingLeft: '30px', display: 'inlineBlock',
      }}
      >
        {heading}
      </span>
    </td>
    <td />
    <td />
  </tr>
);

const TableTd = ({ label }) => (
  <td style={{ minWidth: '60px' }}>
    <input type="checkbox" id={label} name={label} value={label} />
    <label htmlFor={label}>
      {' '}
      {label}
    </label>
  </td>
);
const tdBorderStyle = { borderLeft: '1px solid #d2ddef', borderBottom: '1px solid #d2ddef' };
const tdBorderWidthStyle = {
  width: '5%', height: '22px', borderRight: '1px solid #d2ddef', borderBottom: '1px solid #d2ddef',
};
const tdBorderWidthStyle2 = {
  width: '1.6%', height: '14px', borderBottom: '1px solid #d2ddef', borderRight: '1px solid #d2ddef',
};

const TableRowWithTdStyle = () => (
  <tr>
    <td style={tdBorderWidthStyle} />
    <td style={tdBorderWidthStyle} />
    <td style={tdBorderWidthStyle} />
    <td style={tdBorderWidthStyle} />
    <td style={tdBorderWidthStyle} />
    <td style={tdBorderWidthStyle} />
    <td style={tdBorderWidthStyle} />
    <td style={tdBorderWidthStyle} />
    <td style={tdBorderWidthStyle} />
    <td style={tdBorderWidthStyle} />
    <td style={tdBorderWidthStyle} />
    <td style={tdBorderWidthStyle} />
    <td style={tdBorderWidthStyle} />
    <td style={tdBorderWidthStyle} />
    <td style={tdBorderWidthStyle} />
    <td style={tdBorderWidthStyle} />
    <td style={tdBorderWidthStyle} />
    <td style={tdBorderWidthStyle} />
    <td style={tdBorderWidthStyle} />
  </tr>
);

const TableRowWithTdStyle2 = () => (
  <tr>
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={tdBorderWidthStyle2} />
    <td style={{
      width: '1.6%', height: '14px', borderBottom: '1px solid #d2ddef',
    }}
    />
  </tr>
);
const Physician = () => (
  <div>
    <div className="main-form-container">
      <FollowupCommon />
      <table border="1" style={{ border: '1px solid #d2ddef', fontSize: '13px' }} cellPadding="0" cellSpacing="0" width="100%">
        <tr style={{ background: '#2e79f8', color: '#fff' }}>
          <td style={{ paddingLeft: '4px' }}>
            POST ANESTHESIA
          </td>
          <td style={{ paddingLeft: '4px' }}>
            UNKNOWN TEXT
          </td>
          <td style={{ paddingLeft: '4px' }}>
            UNKNOWN TEXT
          </td>
        </tr>
        <tr>
          <td style={{ paddingLeft: '4px' }}>
            1.
            {' '}
            <span style={{
              textTransform: 'uppercase', fontWeight: '700', paddingLeft: '30px', display: 'inlineBlock',
            }}
            >
              OXYGEN
            </span>
          </td>
          <td />
          <td />
        </tr>
        <PhysicianTableRow heading="3-10 liters per minute via facemask" />
        <PhysicianTableRow heading="3-10 liters per minute via facemask" />
        <PhysicianTableRow heading="3-10 liters per minute via facemask" />
        <PhysicianTableRow heading="3-10 liters per minute via facemask" />
        <PhysicianTableRowWithSpan value={2} heading="IV" />
        <tr>
          <td style={{ paddingLeft: '48px', paddingTop: '4px' }}>
            <input type="checkbox" id="facemask" name="facemask" value="facemask" />
            <label htmlFor="facemask">
              Run current IV at
              <span>
                <input
                  style={{
                    width: '80px', border: '1px solid #d2ddef', padding: '0 4px', marginLeft: '4px', marginBottom: '4px',
                  }}
                  type="text"
                />
              </span>
              ml/hr
            </label>
          </td>
          <td />
          <td />
        </tr>
        <PhysicianTableRow heading="D/C IV prior to discharge" />
        <PhysicianTableRowWithSpan value={3} heading="Pain Management" />
        <tr>
          <td style={{ paddingLeft: '48px', paddingTop: '4px' }}>
            <input type="checkbox" id="facemask" name="facemask" value="facemask" />
            <label htmlFor="facemask">
              {' '}
              Run current IV at
              <span>
                <input
                  style={{
                    width: '80px', border: '1px solid #d2ddef', padding: '0 4px', marginLeft: '4px', marginBottom: '4px',
                  }}
                  type="text"
                />
              </span>
              {' '}
              ml/hr
            </label>
          </td>
          <td />
          <td />
        </tr>
        <PhysicianTableRow heading="D/C IV prior to discharge" />
        <PhysicianTableRow heading="D/C IV prior to discharge" />
        <PhysicianTableRow heading="D/C IV prior to discharge" />
        <PhysicianTableRow heading="D/C IV prior to discharge" />
        <PhysicianTableRow heading="D/C IV prior to discharge" />
        <PhysicianTableRow heading="D/C IV prior to discharge" />
        <PhysicianTableRowWithSpan value={4} heading="Nausea and Vomiting" />
        <tr>
          <td style={{ paddingLeft: '48px', paddingTop: '4px' }}>
            <input type="checkbox" id="facemask" name="facemask" value="facemask" />
            <label htmlFor="facemask">
              {' '}
              Run current IV at
              <span>
                <input
                  style={{
                    width: '80px', border: '1px solid #d2ddef', padding: '0 4px', marginLeft: '4px', marginBottom: '4px',
                  }}
                  type="text"
                />
              </span>
              {' '}
              ml/hr
            </label>
          </td>
          <td />
          <td />
        </tr>
        <PhysicianTableRow heading="D/C IV prior to discharge" />
        <PhysicianTableRow heading="D/C IV prior to discharge" />
        <PhysicianTableRow heading="D/C IV prior to discharge" />
        <PhysicianTableRow heading="D/C IV prior to discharge" />
        <PhysicianTableRow heading="D/C IV prior to discharge" />
        <PhysicianTableRow heading="D/C IV prior to discharge" />
        <PhysicianTableRowWithSpan value={5} heading="Additional Orders" />
        <tr height="22px">
          <td style={{ paddingLeft: '48px', paddingTop: '4px' }} />
          <td />
          <td />
        </tr>
        <tr height="22px">
          <td style={{ paddingLeft: '48px', paddingTop: '4px' }} />
          <td />
          <td />
        </tr>
        <PhysicianTableRowWithSpan
          value={6}
          heading="May discharge patient when alert, stable,
              discharge criteria met and evaluated by a physician"
        />
        <tr style={{ minHeight: '34px' }}>
          <td style={{ paddingLeft: '4px' }}>
            ANESTHESIA PROVIDER SIGNATURE
          </td>
          <td style={{ paddingLeft: '4px' }}>
            TIME
          </td>
          <td style={{ paddingLeft: '4px' }}>
            UNKNOWN TEXT
          </td>
        </tr>
      </table>

      <div style={{ marginTop: '16px' }}>
        <p style={{
          fontSize: '18px', fontWeight: 'bold', color: '#2a4a79', marginBottom: '6px',
        }}
        >
          UNKNOWN TEXT
        </p>
        <p style={{
          color: '#2a4a79', fontSize: '12px', padding: '0px 0 0', marginBottom: '0px',
        }}
        >
          Orange Asc, LLC
          <br />
          1310 W. Stewart Dr. Suite #310
          <br />
          Orange, CA 92868
          <br />
          {' '}
          (714)633-1338
        </p>
        <p style={{
          color: '#2a4a79', fontSize: '12px', padding: '12px 0 0', textTransform: 'uppercase',
        }}
        >
          Anesthesia RECORD
        </p>
      </div>
      <table border="1" style={{ border: '1px solid #d2ddef', fontSize: '13px' }} cellPadding="0" cellSpacing="0" width="100%">
        <tr>
          <td style={{ verticalAlign: 'Top' }}>
            <table>
              <tr style={{ borderBottom: '1px solid rgb(210, 221, 239)' }}>
                <td style={{ borderRight: '1px solid rgb(210, 221, 239)', paddingLeft: '4px' }}>SURGEON:</td>
                <td>
                  <table>
                    <tr>
                      <td style={{ paddingRight: '4px' }}>CONSENT:</td>
                      <td style={{ minWidth: '60px' }}>
                        <input type="checkbox" id="Yes" name="Yes" value="Yes" />
                        <label htmlFor="Yes"> Yes</label>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr style={{ borderBottom: '1px solid rgb(210, 221, 239)' }}>
                <td style={{ borderRight: '1px solid rgb(210, 221, 239)', width: '240px' }}>ANESTHESIA PROVIDER:</td>
                <td>
                  <table>
                    <tr>
                      <td style={{ paddingRight: '4px' }}>PLAN:</td>
                    </tr>
                    <tr>
                      <td style={{ minWidth: '42px' }}>
                        <input type="checkbox" id="Yes" name="Yes" value="Yes" />
                        <label htmlFor="Yes"> Yes</label>
                      </td>
                      <td style={{ minWidth: '42px' }}>
                        <input type="checkbox" id="Yes" name="Yes" value="Yes" />
                        <label htmlFor="Yes"> Yes</label>
                      </td>
                      <td style={{ minWidth: '42px' }}>
                        <input type="checkbox" id="Yes" name="Yes" value="Yes" />
                        <label htmlFor="Yes"> Yes</label>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr style={{ borderBottom: '1px solid rgb(210, 221, 239)' }}>
                <td style={{ borderRight: '1px solid rgb(210, 221, 239)' }}>PROCEDURE:</td>
                <td>
                  <table>
                    <tr>
                      <td style={{ paddingRight: '4px' }}>ALLERGIES:</td>
                      <td style={{ minWidth: '42px' }}>
                        <input type="checkbox" id="NKDA" name="NKDA" value="NKDA" />
                        <label htmlFor="Yes"> NKDA</label>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr style={{ borderBottom: '1px solid rgb(210, 221, 239)' }}>
                <td style={{ borderRight: '1px solid rgb(210, 221, 239)' }}>DX:</td>
                <td>
                  <table>
                    <tr>
                      <td colSpan="2" style={{ paddingRight: '4px' }}>AIRWAY:</td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan="2">PHYSICAL EXAMINATION:</td>
              </tr>
            </table>
          </td>
          <td style={{ verticalAlign: 'Top' }}>
            <table>
              <tr>
                <td style={{ borderBottom: '1px solid rgb(210, 221, 239)', borderRight: '1px solid rgb(210, 221, 239)' }}>
                  <table>
                    <tr>
                      <td style={{ paddingRight: '4px' }}>
                        AGE
                      </td>
                      <td>
                        <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                      </td>
                    </tr>
                  </table>
                </td>
                <td style={{ borderBottom: '1px solid rgb(210, 221, 239)', borderRight: '1px solid rgb(210, 221, 239)' }}>
                  <table>
                    <tr>
                      <td style={{ paddingRight: '4px' }}>
                        SEX
                      </td>
                      <td>
                        <table>
                          <tr>
                            <td style={{ minWidth: '42px' }}>
                              <input type="checkbox" id="M" name="M" value="M" />
                              <label htmlFor="M"> M</label>
                            </td>
                            <td style={{ minWidth: '42px' }}>
                              <input type="checkbox" id="F" name="F" value="F" />
                              <label htmlFor="F"> F</label>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
                <td style={{ borderBottom: '1px solid rgb(210, 221, 239)', borderRight: '1px solid rgb(210, 221, 239)' }}>
                  <table>
                    <tr>
                      <td style={{ paddingRight: '4px' }}>
                        WEIGHT
                      </td>
                      <td>
                        <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                      </td>
                    </tr>
                  </table>
                </td>
                <td style={{ borderBottom: '1px solid rgb(210, 221, 239)' }}>
                  <table>
                    <tr>
                      <td style={{ paddingRight: '4px' }}>
                        HEIGHT
                      </td>
                      <td>
                        <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan="4" style={{ borderBottom: '1px solid rgb(210, 221, 239)', borderRight: '1px solid rgb(210, 221, 239)' }}>REVIEW OF SYSTEMS</td>
              </tr>
              <tr>
                <td colSpan="4" style={{ height: '24px', borderBottom: '1px solid rgb(210, 221, 239)', borderRight: '1px solid rgb(210, 221, 239)' }} />
              </tr>
              <tr>
                <td colSpan="4" style={{ borderBottom: '1px solid rgb(210, 221, 239)', borderRight: '1px solid rgb(210, 221, 239)' }}>
                  <table>
                    <tr>
                      <td style={{ paddingRight: '4px' }}>Current MEDS:</td>
                    </tr>
                    <tr>
                      <td style={{ minWidth: '42px' }}>
                        <input type="checkbox" id="REVIEWED" name="REVIEWED" value="REVIEWED" />
                        <label htmlFor="Yes"> REVIEWED</label>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan="4">
                  <table>
                    <tr>
                      <td style={{ paddingRight: '4px' }}>ANESTHESIA:</td>
                    </tr>
                    <tr>
                      <td style={{ minWidth: '42px' }}>
                        <input type="checkbox" id="REVIEWED" name="REVIEWED" value="REVIEWED" />
                        <label htmlFor="Yes"> REVIEWED</label>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
          <td width="160px">
            <table width="100%" style={{ textAlign: 'center' }}>
              <tr>
                <td colSpan="3">ABA PHYSICAL STATUS</td>
              </tr>
              <tr style={{ borderBottom: '1px solid rgb(210, 221, 239)' }}>
                <td width="33.333%">I</td>
                <td width="33.333%">II</td>
                <td width="33.333%">III</td>
              </tr>
              <tr style={{ borderBottom: '1px solid rgb(210, 221, 239)' }}>
                <td colSpan="3">ANESTHESIA TIME</td>
              </tr>
              <tr style={{ borderBottom: '1px solid rgb(210, 221, 239)' }}>
                <td width="100%" colSpan="3">
                  <table width="100%">
                    <tr>
                      <td style={{ borderRight: '1px solid rgb(210, 221, 239)' }}>
                        START
                      </td>
                      <td>
                        STOP
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr style={{ borderBottom: '1px solid rgb(210, 221, 239)' }}>
                <td colSpan="3">SURGERY TIME</td>
              </tr>
              <tr style={{ borderBottom: '1px solid rgb(210, 221, 239)' }}>
                <td width="100%" colSpan="3">
                  <table width="100%">
                    <tr>
                      <td style={{ borderRight: '1px solid rgb(210, 221, 239)' }}>
                        START
                      </td>
                      <td>
                        STOP
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr style={{ borderBottom: '1px solid rgb(210, 221, 239)' }}>
                <td colSpan="3" style={{ textAlign: 'left' }}>IV TOTALS</td>
              </tr>
              <tr>
                <td colSpan="3" style={{ textAlign: 'left' }}>IV TOTALS</td>
              </tr>
              <tr>
                <td colSpan="3" style={{ textAlign: 'left' }}>IV TOTALS</td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colSpan="3">
            <table width="100%">
              <tr>
                <td style={{ borderRight: '1px solid rgb(210, 221, 239)' }}>
                  <table width="100%">
                    <tr>
                      <TableTd label="HTN" />
                      <TableTd label="COPD" />
                      <TableTd label="CAD" />
                      <TableTd label="OBESITY" />
                      <TableTd label="ABTHMA" />
                      <TableTd label="DIABETES" />
                    </tr>
                    <tr>
                      <TableTd label="OSA" />
                      <TableTd label="SEVEREANXIETY" />
                      <TableTd label="CHRONICOPONION" />
                      <td style={{ paddingLeft: '24px' }}>
                        <span>BS</span>
                        <input
                          style={{
                            width: '70px', border: '1px solid #d2ddef', padding: '0 4px', display: 'inlineBlock', marginRight: '12px', marginLeft: '12px',
                          }}
                          type="text"
                        />
                        <span>high</span>
                      </td>
                    </tr>
                    <tr>
                      <TableTd label="ANESTHESIA MACHINE AND MEDICATION CHECKED" />
                    </tr>
                    <tr style={{ borderTop: '1px solid #d2ddef' }}>
                      <td colSpan="6" style={{ paddingLeft: '0px' }}>
                        <span>PRE-0P........</span>
                        <input
                          style={{
                            width: '70px', border: '1px solid #d2ddef', padding: '0 4px', display: 'inlineBlock', marginRight: '12px', marginLeft: '12px',
                          }}
                          type="text"
                        />
                        <span>Abe/Ratio</span>
                        <input
                          style={{
                            width: '70px', border: '1px solid #d2ddef', padding: '0 4px', display: 'inlineBlock', marginRight: '12px', marginLeft: '12px',
                          }}
                          type="text"
                        />
                      </td>
                    </tr>
                  </table>
                </td>
                <td style={{ paddingLeft: '4px' }}>
                  <table>
                    <tr>
                      <td style={{ paddingLeft: '4px', paddingRight: '8px' }}>LABS NOTED:</td>
                      <td style={{ minWidth: '60px' }}>
                        <input type="checkbox" id="Yes" name="Yes" value="Yes" />
                        <label htmlFor="Yes"> Yes</label>
                      </td>
                      <td style={{ minWidth: '60px' }}>
                        <input type="checkbox" id="NO" name="NO" value="NO" />
                        <label htmlFor="NO"> NO</label>
                      </td>
                      <td>ABNORMALS</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colSpan="21">
            <table width="100%">
              <tr>
                <td width="120px" style={{ borderRight: '1px solid #d2ddef', padding: '0', verticalAlign: 'top' }}>
                  <table width="100%">
                    <tr style={{ borderBottom: '1px solid #d2ddef' }}>
                      <td colSpan="2" style={{ textAlign: 'center' }}>Time</td>
                    </tr>
                    <tr>
                      <td>
                        <table>
                          <tr>
                            <td>AGENTS</td>
                          </tr>
                          <tr>
                            <td>MONITORS</td>
                          </tr>
                        </table>
                      </td>
                      <td style={{ padding: '0' }}>
                        <table width="100%">
                          <tr>
                            <td style={tdBorderStyle}>Dxygo1</td>
                          </tr>
                          <tr>
                            <td style={tdBorderStyle}>Dxygo1</td>
                          </tr>
                          <tr>
                            <td style={tdBorderStyle}>Dxygo1</td>
                          </tr>
                          <tr>
                            <td style={tdBorderStyle}>Dxygo1</td>
                          </tr>
                          <tr>
                            <td style={tdBorderStyle}>Dxygo1</td>
                          </tr>
                          <tr>
                            <td style={tdBorderStyle}>Dxygo1</td>
                          </tr>
                          <tr>
                            <td style={tdBorderStyle}>Dxygo1</td>
                          </tr>
                          <tr>
                            <td style={tdBorderStyle} height="22px" />
                          </tr>
                          <tr>
                            <td style={tdBorderStyle} height="22px" />
                          </tr>
                          <tr>
                            <td style={tdBorderStyle} height="22px" />
                          </tr>
                          <tr>
                            <td style={tdBorderStyle}>Dxygo1</td>
                          </tr>
                          <tr>
                            <td style={tdBorderStyle}>Dxygo1</td>
                          </tr>
                          <tr>
                            <td style={tdBorderStyle}>Dxygo1</td>
                          </tr>
                          <tr>
                            <td style={tdBorderStyle}>Dxygo1</td>
                          </tr>
                          <tr>
                            <td style={tdBorderStyle}>Dxygo1</td>
                          </tr>
                          <tr>
                            <td style={tdBorderStyle}>Dxygo1</td>
                          </tr>
                          <tr>
                            <td style={tdBorderStyle}>Dxygo1</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
                <td style={{ verticalAlign: 'top', padding: '0' }}>
                  <table width="100%">
                    <TableRowWithTdStyle />
                    <TableRowWithTdStyle />
                    <TableRowWithTdStyle />
                    <TableRowWithTdStyle />
                    <TableRowWithTdStyle />
                    <TableRowWithTdStyle />
                    <TableRowWithTdStyle />
                    <TableRowWithTdStyle />
                    <TableRowWithTdStyle />
                    <TableRowWithTdStyle />
                    <TableRowWithTdStyle />
                    <TableRowWithTdStyle />
                    <TableRowWithTdStyle />
                    <TableRowWithTdStyle />
                    <TableRowWithTdStyle />
                    <TableRowWithTdStyle />
                    <TableRowWithTdStyle />
                    <TableRowWithTdStyle />
                  </table>
                  <td style={{ width: '5%', height: '24px' }} />
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colSpan="4">
            <table width="100%">
              <tr>
                <td width="60px" style={{ borderRight: '1px solid #d2ddef', verticalAlign: 'top' }}>
                  <table width="100%" style={{ textAlign: 'center' }}>
                    <tr>
                      <td>
                        BP
                        {' '}
                        <br />
                        X
                      </td>
                    </tr>
                    <tr>
                      <td>
                        BP
                        {' '}
                        <br />
                        X
                      </td>
                    </tr>
                    <tr>
                      <td>
                        BP
                        {' '}
                        <br />
                        X
                      </td>
                    </tr>
                    <tr>
                      <td>
                        BP
                        {' '}
                        <br />
                        X
                      </td>
                    </tr>
                    <tr>
                      <td>
                        BP
                        {' '}
                        <br />
                        X
                      </td>
                    </tr>
                  </table>
                </td>
                <td width="60px" style={{ borderRight: '1px solid #d2ddef', verticalAlign: 'top' }}>
                  <table width="100%" style={{ textAlign: 'center' }}>
                    <tr>
                      <td>200</td>
                    </tr>
                    <tr>
                      <td>200</td>
                    </tr>
                    <tr>
                      <td>200</td>
                    </tr>
                    <tr>
                      <td>200</td>
                    </tr>
                    <tr>
                      <td>200</td>
                    </tr>
                    <tr>
                      <td>200</td>
                    </tr>
                    <tr>
                      <td>200</td>
                    </tr>
                    <tr>
                      <td>200</td>
                    </tr>
                    <tr>
                      <td>200</td>
                    </tr>
                    <tr>
                      <td>200</td>
                    </tr>
                  </table>
                </td>
                <td style={{ verticalAlign: 'top', padding: '0' }}>
                  <table width="100%">
                    <TableRowWithTdStyle2 />
                    <TableRowWithTdStyle2 />
                    <TableRowWithTdStyle2 />
                    <TableRowWithTdStyle2 />
                    <TableRowWithTdStyle2 />
                    <TableRowWithTdStyle2 />
                    <TableRowWithTdStyle2 />
                    <TableRowWithTdStyle2 />
                    <TableRowWithTdStyle2 />
                    <TableRowWithTdStyle2 />
                    <TableRowWithTdStyle2 />
                    <TableRowWithTdStyle2 />
                    <TableRowWithTdStyle2 />
                    <TableRowWithTdStyle2 />
                    <TableRowWithTdStyle2 />
                    <TableRowWithTdStyle2 />
                    <TableRowWithTdStyle2 />
                    <TableRowWithTdStyle2 />
                  </table>
                </td>
                <td width="220px" style={{ verticalAlign: 'top', padding: '0', borderLeft: '1px solid #d2ddef' }}>
                  <table width="100%">
                    <tr style={{ borderBottom: '1px solid #d2ddef' }}>
                      <td colSpan="2">
                        Monitors
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingLeft: '4px', paddingTop: '4px' }}>
                        <input type="checkbox" id="Monitors" name="Monitors" value="Monitors" />
                        <label htmlFor="Monitors"> ASA Standard Monitors</label>
                      </td>
                    </tr>
                    <tr>
                      <p style={{ paddingLeft: '4px', fontWeight: 'bold' }}>AIRWAY MANG</p>
                    </tr>
                    <tr>
                      <td style={{ paddingLeft: '4px', paddingTop: '4px' }}>
                        <input type="checkbox" id="N/G" name="N/G" value="N/G" />
                        <label htmlFor="N/G"> N/G</label>
                      </td>
                      <td style={{ paddingLeft: '4px', paddingTop: '4px' }}>
                        <input type="checkbox" id="F/M" name="F/M" value="F/M" />
                        <label htmlFor="F/M"> F/M</label>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingLeft: '4px', paddingTop: '4px' }}>
                        <input type="checkbox" id="LMA" name="LMA" value="LMA" />
                        <label htmlFor="LMA"> LMA</label>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingLeft: '4px', paddingTop: '4px' }}>
                        <input type="checkbox" id="LMA2" name="LMA2" value="LMA2" />
                        <label htmlFor="LMA2"> LMA</label>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <input
                          style={{
                            width: '200px', marginLeft: '4px', border: '1px solid #d2ddef', padding: '0 4px', display: 'inlineBlock',
                          }}
                          type="text"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" style={{ paddingLeft: '0px' }}>

                        <td style={{ paddingLeft: '4px', paddingTop: '4px' }}>
                          <input type="checkbox" id="CTT" name="CTT" value="CTT" />
                          <label htmlFor="CTT"> CTT</label>
                        </td>
                        <td>
                          <input
                            style={{
                              width: '129px', border: '1px solid #d2ddef', padding: '0 4px', display: 'inlineBlock',
                            }}
                            type="text"
                          />
                        </td>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <input
                          style={{
                            width: '200px', marginLeft: '4px', border: '1px solid #d2ddef', padding: '0 4px', display: 'inlineBlock',
                          }}
                          type="text"
                        />
                      </td>
                    </tr>
                    <tr>
                      <p style={{ paddingLeft: '4px', fontWeight: 'bold', paddingTop: '6px' }}>EYE CARE</p>
                    </tr>
                    <tr>
                      <td style={{ paddingLeft: '4px', paddingTop: '4px' }}>
                        <input type="checkbox" id="N/G" name="N/G" value="N/G" />
                        <label htmlFor="N/G"> N/G</label>
                      </td>
                      <td style={{ paddingLeft: '4px', paddingTop: '4px' }}>
                        <input type="checkbox" id="F/M" name="F/M" value="F/M" />
                        <label htmlFor="F/M"> F/M</label>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingLeft: '4px', paddingTop: '4px' }}>
                        <input type="checkbox" id="LMA" name="LMA" value="LMA" />
                        <label htmlFor="LMA"> LMA</label>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colSpan="4" style={{ paddingLeft: '4px' }}>
            <table>
              <tr>
                <td height="160px" style={{ verticalAlign: 'top' }}>
                  <p>REMARKS</p>
                </td>

              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colSpan="4" style={{ paddingLeft: '4px' }}>
            <table>
              <tr>
                <td>
                  <p style={{ marginBottom: '0' }}>POST ANESTHESIA ASSESSMENTS</p>
                </td>

              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colSpan="4" style={{ paddingLeft: '0px' }}>
            <table width="100%">
              <tr>
                <td width="60px" style={{ padding: '0', verticalAlign: 'top' }}>
                  <table width="100%">
                    <tr style={{ borderBottom: '1px solid #d2ddef' }}>
                      <td style={{ width: '100%', height: '22px' }} />
                    </tr>
                    <tr style={{ borderBottom: '1px solid #d2ddef' }}>
                      <td style={{ width: '100%', height: '22px' }} />
                    </tr>
                    <tr>
                      <td style={{ width: '100%', height: '127px' }}>
                        RECOVERY FORM
                      </td>
                    </tr>
                  </table>
                </td>
                <td style={{ borderLeft: '1px solid #d2ddef', verticalAlign: 'top', padding: '0' }}>
                  <table width="100%">
                    <tr>
                      <td style={{ padding: '0' }}>
                        <table width="100%">
                          <tr style={{ borderBottom: '1px solid #d2ddef' }}>
                            <td width="48px">Time:</td>
                            <td width="48px">B/P</td>
                            <td width="48px">B</td>
                            <td width="48px">P</td>
                            <td width="48px">O2SAT</td>
                            <td width="48px">Temp</td>
                          </tr>
                          <tr style={{ borderBottom: '1px solid #d2ddef' }}>
                            <td width="100px">POST GENERAL</td>
                            <td style={{ paddingLeft: '4px', paddingTop: '4px', width: '70px' }}>
                              <input type="checkbox" id="F/M" name="F/M" value="F/M" />
                              <label htmlFor="F/M"> F/M</label>
                            </td>
                            <td style={{ paddingLeft: '4px', paddingTop: '4px', width: '70px' }}>
                              <input type="checkbox" id="F/M" name="F/M" value="F/M" />
                              <label htmlFor="F/M"> F/M</label>
                            </td>
                            <td style={{ paddingLeft: '4px', paddingTop: '4px', width: '70px' }}>
                              <input type="checkbox" id="F/M" name="F/M" value="F/M" />
                              <label htmlFor="F/M"> F/M</label>
                            </td>
                            <td style={{ paddingLeft: '4px', paddingTop: '4px', width: '70px' }}>
                              <input type="checkbox" id="F/M" name="F/M" value="F/M" />
                              <label htmlFor="F/M"> F/M</label>
                            </td>
                            <td style={{ paddingLeft: '4px', paddingTop: '4px', width: '70px' }}>
                              <input type="checkbox" id="F/M" name="F/M" value="F/M" />
                              <label htmlFor="F/M"> Hydratiion</label>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ padding: '0' }}>
                              <table width="100%">
                                <tr style={{ borderBottom: '1px solid #d2ddef', height: '22px' }}>
                                  <td />
                                </tr>
                                <tr style={{ borderBottom: '1px solid #d2ddef', height: '22px' }}>
                                  <td />
                                </tr>
                                <tr style={{ borderBottom: '1px solid #d2ddef', height: '22px' }}>
                                  <td />
                                </tr>
                                <tr style={{ borderBottom: '1px solid #d2ddef', height: '22px' }}>
                                  <td>
                                    <table>
                                      <tr>
                                        <td>Anasthesia provider signature</td>
                                        <td style={{ paddingLeft: '4px', paddingTop: '4px', width: '70px' }}>
                                          <input type="checkbox" id="MD" name="MD" value="MD" />
                                          <label htmlFor="MD"> MD</label>
                                        </td>
                                        <td style={{ paddingLeft: '4px', paddingTop: '4px', width: '70px' }}>
                                          <input type="checkbox" id="DRNA" name="DRNA" value="DRNA" />
                                          <label htmlFor="DRNA"> DRNA</label>
                                        </td>
                                        <td style={{ paddingLeft: '4px', paddingTop: '4px', width: '70px' }}>
                                          <input type="checkbox" id="SRNA" name="SRNA" value="SRNA" />
                                          <label htmlFor="SRNA"> SRNA</label>
                                        </td>
                                        <td style={{ paddingLeft: '16px' }}>
                                          Date/Time
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                                <tr style={{ height: '22px' }}>
                                  <td>
                                    <table>
                                      <tr>
                                        <td>Anasthesia provider signature</td>
                                        <td style={{ paddingLeft: '4px', paddingTop: '4px', width: '70px' }}>
                                          <input type="checkbox" id="MD" name="MD" value="MD" />
                                          <label htmlFor="MD"> MD</label>
                                        </td>
                                        <td style={{ paddingLeft: '4px', paddingTop: '4px', width: '70px' }}>
                                          <input type="checkbox" id="DRNA" name="DRNA" value="DRNA" />
                                          <label htmlFor="DRNA"> DRNA</label>
                                        </td>
                                        <td style={{ paddingLeft: '4px', paddingTop: '4px', width: '70px' }}>
                                          <input type="checkbox" id="SRNA" name="SRNA" value="SRNA" />
                                          <label htmlFor="SRNA"> SRNA</label>
                                        </td>
                                        <td style={{ paddingLeft: '16px' }}>
                                          Date/Time
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td style={{ borderLeft: '1px solid #d2ddef' }} />
                          </tr>
                        </table>
                      </td>
                    </tr>

                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

    </div>
  </div>
);

export default Physician;
