/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo } from 'react';
import { Switch } from 'antd';
import SelectBox from '../../../../components/SelectBox';
import Tooltip from '../../../../components/Tooltip';
import ErrorMessages from '../../../../lib/errorMessages';
import useCRUD from '../../../../hooks/useCRUD';
import { apiUrls } from '../../../../api/constants';
import Notification from '../../../../components/Notification';
import { getFeatureState } from '../../../../lib/util';
import Loader from '../../../../components/Loader';
import { featureKeys } from '../../../../lib/constants';

export const getRulesEngineOperandByKey = (data, keys) => {
  const searchKeys = Array.isArray(keys) ? keys : [keys];
  const itemWithMatchingOperand = data?.find((item) => item.operands?.some((operand) => searchKeys.includes(operand.key))
  );
  return itemWithMatchingOperand || null;
};

const RulesEngineEncounter = ({
  encounterId,
  showRcmHighlight,
  setShowRcmHighlight,
  savedEncounter,
  setSelectedRule,
  selectedRule,
  isUpdate,
  rulesEngineEvaluationStatus,
  isCollectionCharges,
  isRulesEngineOverRidePermitted,
  setShowDropDownOfOverrideRulesEngine,
  setRulesEngineEvaluationStatus,
  hasInsuranceProfileSelf,
  activeTabName,
}) => {
  // Rules Engine API calls
  const [rcmHighlightData, getRcmHighlightError, loadingHighlight, getResults] = useCRUD({
    id: `rulesEvaluation-single-${encounterId}`,
    url: apiUrls.GET_RULES_EVALUATION,
    type: 'read',
  });

  const [features, , featureLoading, getFeatures] = useCRUD({
    id: 'feature-flags',
    url: `${apiUrls.GET_BUSINESS_ENTITY_FEATURES_FLAGS}?BusinessEntityId=${savedEncounter?.businessEntityId}`,
    type: 'read',
  });

  const getRulesEvaluation = useCallback(() => {
    getResults({}, `?BillingEncounterId=${Number(encounterId)}&Values=latest`);
  }, [encounterId, getResults]);

  // Show error msg to user if encounter has any cpt codes which does not have matching rules
  const areAllEvaluationSuccessTrue = (evaluations) => evaluations?.every((evaluation) => evaluation.evaluationSuccess);
  const hasNoMatchingRule = (evaluation) => evaluation?.length > 0 && evaluation[0]?.ruleName === null && evaluation[0]?.ruleId === null;

  const finalRcmHighlightData = useMemo(
    () => rcmHighlightData?.encounterEvaluations[0]?.evaluation
        .filter((relFail) => relFail.relatedFailures.length > 0)
        .map((evaluation) => ({
          ...evaluation,
          relatedFailures: evaluation.relatedFailures.map((failure) => ({
            ...failure,
            ruleName: evaluation.ruleName,
          })),
        })),
    [rcmHighlightData],
  );

  const handleRcmHighlightNotifications = useCallback(() => {
    if (!showRcmHighlight) {
      return;
    }
    switch (savedEncounter?.ruleEvaluationStatus) {
      case 'Success':
        Notification({
          message: ErrorMessages.PASSED_ALL_RULES,
          success: true,
        });
        setShowRcmHighlight(false);
        break;
      case null:
        Notification({ message: ErrorMessages.RULES_NOT_EVALUATED });
        setShowRcmHighlight(false);
        break;
      case 'Failure':
        if (
          areAllEvaluationSuccessTrue(
            rcmHighlightData?.encounterEvaluations[0]?.evaluation,
          )
        ) {
          Notification({ message: ErrorMessages.NO_MATCHING_RULES });
          setShowRcmHighlight(false);
        } else if (
          hasNoMatchingRule(
            rcmHighlightData?.encounterEvaluations[0]?.evaluation,
          )
        ) {
          Notification({
            message: ErrorMessages.NO_MATCHING_RULES_ALL_CPT_CODES,
          });
          setShowRcmHighlight(false);
        }
        break;
      default:
        break;
    }
  }, [showRcmHighlight, savedEncounter, setShowRcmHighlight, rcmHighlightData]);

  const handleSelectedRule = useCallback(() => {
    if (finalRcmHighlightData?.length > 0) {
      setSelectedRule(finalRcmHighlightData[0]);
    }
  }, [finalRcmHighlightData, setSelectedRule]);

  useEffect(() => {
    handleRcmHighlightNotifications();
    handleSelectedRule();
  }, [
    rcmHighlightData,
    getRcmHighlightError,
    showRcmHighlight,
    savedEncounter,
    handleRcmHighlightNotifications,
    handleSelectedRule,
  ]);

  useEffect(() => {
    if (showRcmHighlight && savedEncounter?.ruleEvaluationStatus === 'Failure') {
      getRulesEvaluation();
    }
  }, [savedEncounter, getRulesEvaluation, showRcmHighlight]);

  useEffect(() => {
    if (showRcmHighlight) {
      setShowRcmHighlight(false);
    }
    setSelectedRule([]);
    if (savedEncounter?.businessEntityId && !features && !featureLoading) {
      getFeatures();
    }
    if (isUpdate && rulesEngineEvaluationStatus && !isCollectionCharges && isRulesEngineOverRidePermitted && !(savedEncounter?.encounterOriginDescription === 'Manual') && !(activeTabName === 'claims') && !hasInsuranceProfileSelf) {
      setShowDropDownOfOverrideRulesEngine(true);
    } else {
      setShowDropDownOfOverrideRulesEngine(false);
    }
  }, [savedEncounter, rulesEngineEvaluationStatus, encounterId, hasInsuranceProfileSelf]);

  useEffect(() => {
    if (features?.length) {
      // Checks if feature of rules engine is ON/OFF
      setRulesEngineEvaluationStatus(getFeatureState(features, featureKeys.RULES_ENGINE_FLAG));
    }
  }, [features, encounterId]);
  return (
    <>
      {featureLoading && <Loader />}
      <Tooltip content="Show Rules Evaluation Results">
        <span>Evaluation Result</span>
        <Switch
          checked={showRcmHighlight}
          onChange={(checked) => setShowRcmHighlight(checked)}
          loading={loadingHighlight}
        />
      </Tooltip>
      {finalRcmHighlightData?.length > 0 && showRcmHighlight && (
        <SelectBox
          isFormItem={false}
          placeholder="Select a Rule"
          value={selectedRule?.id}
          onChange={(value, data) => setSelectedRule(data.item)}
          options={finalRcmHighlightData?.map((item) => ({
            name: item.ruleName,
            value: item.id,
            ...item,
          }))}
          selectProps={{
            style: {
              width: '85%',
              marginLeft: '15px',
            },
          }}
          loading={loadingHighlight}
        />
      )}
    </>
  );
};

export default RulesEngineEncounter;
