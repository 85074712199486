import React from 'react';
import { Form as AntdForm } from 'antd';

import Modals from '../../../../../components/Modal';
import Button from '../../../../../components/Button';

import SettingsForm from './SettingsForm';

import '../../composer.scss';

const SettingsModal = ({
  toggleModal, isVisible, labels, getHTMLContent, templateData, isUpdate,
}) => {
  const [form] = AntdForm.useForm();

  return (
    <Modals
      visible={isVisible}
      toggleModal={toggleModal()}
      destroyOnClose
      width={800}
      className="composer-design-header"
      footer={[
        <div className="btns-action mr-left-60 mr-bt-12" key="footer">
          <Button className="btn btn-success min-wt-86" onClick={form.submit}>{labels.get('buttons.save')}</Button>
          <Button className="btn" onClick={toggleModal()}>{labels.get('buttons.cancel')}</Button>
        </div>,
      ]}
      title={labels.get('titles.settings')}
    >
      <div className="mr-bt-20 settings-title-composer">Document Margins (in inches)</div>
      <SettingsForm
        labels={labels}
        form={form}
        getHTMLContent={getHTMLContent}
        templateData={templateData}
        isUpdate={isUpdate}
        toggleModal={toggleModal}
      />
    </Modals>
  );
};

export default SettingsModal;
