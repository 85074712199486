import { useEffect } from 'react';

import Events from '../../lib/events';

const TableWrapper = ({ Component, reFetch }) => {
  useEffect(() => {
    Events.on('refetchModalPatientList', 'refetchModalPatientList', reFetch);
    return () => Events.remove('refetchModalPatientList', 'refetchModalPatientList');
  }, []);
  return (Component);
};

export default TableWrapper;
