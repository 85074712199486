import React, { useCallback, useEffect } from 'react';

import Loader from '../../../../../../components/Loader';
import Checkbox from '../../../../../../components/SmCheckbox';

import useCRUD from '../../../../../../hooks/useCRUD';
import useRedirect from '../../../../../../hooks/useRedirect';
import WithLabel from '../../../../../../hoc/withLabel';

import { labelPaths } from '../../../../../../lib/constants';
import { apiUrls } from '../../../../../../api/constants';

import ViewInsurancePolicy from './View';

const ViewResponsibleParty = (props) => {
  const { labels, defaultParams } = props;
  const { params } = useRedirect();
  const { rpId } = defaultParams || params;

  const [rpData, , rpLoading, requestRP] = useCRUD({
    id: 'patient-insurance-rp-id', url: apiUrls.GET_PATIENT_INSURANCE, type: 'read',
  });

  const [ipUpdateData, , ipUpdateLading, updateIP, clearUpdateIpData] = useCRUD({
    id: 'patient-insurance-profile-defaults-rp',
    url: 'masterservice/PatientInsurance/SetDefaultInsuranceProfile',
    type: 'update',
  });

  useEffect(() => {
    if (rpId) {
      requestRP({}, `/${rpId}`);
    }
  }, [rpId]);

  useEffect(() => {
    if (ipUpdateData) {
      requestRP();
      clearUpdateIpData();
    }
  }, [ipUpdateData]);

  const setDefaultProfile = useCallback((event) => {
    const { insuranceProfileId, patientId } = rpData;
    updateIP({ insuranceProfileId, patientId, isDefault: event.target.checked });
  }, [rpData, updateIP]);

  if ((rpLoading && !rpData) || ipUpdateLading) return <Loader />;

  return (
    <>
      <div className="default-profile-checkbox">
        <Checkbox
          checked={!!rpData?.isDefault}
          onChange={setDefaultProfile}
          valuePropName="checked"
        >
          Default Profile
        </Checkbox>
      </div>
      <ViewInsurancePolicy
        data={rpData?.responsibleParty}
        labels={labels}
        profileName={rpData?.profileName}
        showResponsibleParty={rpData?.relationName !== 'Self'}
      />
    </>
  );
};

export default WithLabel(ViewResponsibleParty, labelPaths.VIEW_RESPONSIBLE_PARTY);
