import React, {
  useMemo, useCallback, useState, useEffect, useRef,
} from 'react';
import times from 'lodash/times';
import moment from 'moment';
import classNames from 'classnames';

import dateFormatter from '../../../../lib/dateFormatter';
import { dateFormatStartsWithYear, dateFormatStartsWithYearSeconds, timeFormat } from '../../../../lib/constants';

const freeSlotClassName = 'free-slot-cell';
const noLocationClassName = 'no-location-cell';

const timerUpdateRate = 1000; // 1 sec

const TimeSlotCell = ({
  numberOfRows,
  slotDuration,
  timeRange: { from },
  minSlotTime,
  selectedDate,
  locations,
  freeSlots,
}) => {
  const timerInterval = useRef();

  const [timer, setTimer] = useState();

  const slotsPerRow = useMemo(() => (slotDuration / minSlotTime), [slotDuration, minSlotTime]);

  const fromTime = useMemo(() => {
    const date = dateFormatter(selectedDate, dateFormatStartsWithYear);
    const time = dateFormatter(from, timeFormat);
    return moment(`${date} ${time}`, dateFormatStartsWithYearSeconds);
  }, [selectedDate, from]);

  const getCellClassName = useCallback((row, column) => {
    if (locations) {
      // if true, cell doesn't lie inside any location
      const isSlotInLocation = locations.every(({ slotPositions }) => {
        const actualRowNumber = (row * 2) + column + 1;
        const { startYAt, endYAt } = slotPositions;
        if (startYAt <= actualRowNumber && endYAt > actualRowNumber) return false;
        return true;
      });
      if (isSlotInLocation) {
        return noLocationClassName;
      }
    }
    const time = moment(fromTime).add((row * slotDuration) + (column * minSlotTime), 'minutes');
    if (moment(time).isBefore(moment())) return null;
    if (freeSlots?.length) {
      if (freeSlots && freeSlots.length) {
        const isSlotFree = !freeSlots.every((slot) => {
          const { startTime, endTime } = slot;
          if (!startTime || !endTime) return true;
          const startTimeMoment = moment(startTime).seconds(0);
          const endTimeMoment = moment(endTime).seconds(0);
          if (time.isSameOrAfter(startTimeMoment) && time.isBefore(endTimeMoment)) return false;
          return true;
        });
        if (isSlotFree) {
          return freeSlotClassName;
        }
      }
    }
    return null;
  }, [locations, fromTime, slotDuration, minSlotTime, freeSlots]);

  useEffect(() => {
    setTimer(moment().unix());
    timerInterval.current = setInterval(() => {
      const now = moment();
      if (!(now.get('seconds') || now.get('minutes') % minSlotTime)) setTimer(moment().unix());
    }, timerUpdateRate);
    return () => { if (timerInterval.current) clearInterval(timerInterval.current); };
  }, []);

  const tableComponent = useMemo(() => times(numberOfRows, (row) => (
    times(slotsPerRow, (column) => (
      <tr key={2 * row + column} className={classNames(getCellClassName(row, column, timer), { 'even-row': column })}>
        <td />
      </tr>
    ))
  )), [numberOfRows, slotsPerRow, getCellClassName, timer]);

  return (
    <table className="timeslot-view-table">
      <tbody>
        {tableComponent}
      </tbody>
    </table>
  );
};

TimeSlotCell.defaultProps = {
  locations: [],
};

export default TimeSlotCell;
