import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { Modal as AntdModal } from 'antd';
import moment from 'moment';
import compact from 'lodash/compact';
import cloneDeep from 'lodash/cloneDeep';
import api from '../../api';

import Notification from '../../components/Notification';
import WidgetLoader from '../../components/WidgetLoader';
import Icon from '../../components/Icon';
import ConfirmDialog from '../../components/ConfirmDialog';
import Loader from '../../components/Loader';

import WithLabel from '../../hoc/withLabel';

import useCRUD from '../../hooks/useCRUD';
import useRedirect from '../../hooks/useRedirect';

import { labelPaths, listIds, UiRoutes } from '../../lib/constants';
import encode from '../../lib/encode';
import { getName } from '../../lib/util';

import { apiUrls } from '../../api/constants';

import Header from './Components/Header';
import Widgets from './Components/Widgets';
import Filters from './Components/Filters';
import Legends from './Components/Legends';
import CustomDragLayer from './Components/CustomDragLayer';

import Upcoming from './Components/AppointmentList/Upcoming';
import Workup from './Components/AppointmentList/Workup';
import ReadyForPhysician from './Components/AppointmentList/ReadyForPhysician';
import Physician from './Components/AppointmentList/Physician';
import CheckOut from './Components/AppointmentList/CheckOut';
import './dashboard.scss';
import ActivityLog from './Components/ActivityLog';
import useTabLink from '../../hooks/useTabLink';

const { warning } = AntdModal;

const timerUpdateRate = 1000;

const acceptance = {
  CheckIn: ['Workup'],
  New: ['CheckIn', 'Cancel', 'Workup', 'ReadyForPhysician', 'Physician', 'PendingCheckout', 'CheckOut'],
  Cancel: ['CheckIn', 'New', 'Workup', 'CheckOut'],
  Workup: ['CheckIn', 'New', 'PendingCheckout', 'Cancel', 'CheckOut', 'ReadyForPhysician'],
  ReadyForPhysician: ['Workup', 'PendingCheckout', 'Cancel', 'CheckOut', 'Physician'],
  Physician: ['ReadyForPhysician', 'PendingCheckout', 'Cancel', 'CheckOut'],
  PendingCheckout: ['Physician', 'Cancel', 'CheckOut'],
  CheckOut: ['Cancel'],
};

const defaultAppointments = {
  // change in labels dashboard/index.json if the keys change
  CheckIn: { status: 'CheckIn', appointments: [] },
  New: { status: 'New', appointments: [] },
  Cancel: { status: 'Cancel', appointments: [] },
  Workup: { status: 'Workup', appointments: [] },
  ReadyForPhysician: { status: 'ReadyForPhysician', appointments: [] },
  Physician: { status: 'Physician', appointments: [] },
  PendingCheckout: { status: 'PendingCheckout', appointments: [] },
  CheckOut: { status: 'CheckOut', appointments: [] },
};

const injectRefs = (data = {}) => ({
  ...data,
  appointments: data?.appointments.map(
    (appointment) => ({ ...appointment, ref: React.createRef() }),
  ),
});

const Dashboard = ({ labels, initialFilters, updateFilters }) => {
  const draggingCardRef = useRef();
  const timerInterval = useRef();
  const needToFetchCards = useRef(false);
  const [isLoading, setLoading] = useState(false);
  const {
    replace, params, generatePath, path,
  } = useRedirect();

  const [appointmentList, , loading, getAppointmentList, clearAppointmentList] = useCRUD({
    id: listIds.APPOINTMENT_CARDS_LIST,
    url: apiUrls.GET_APPOINTMENT_CARDS,
    type: 'read',
  });
  const [updateAppointmentStatusResponse,,,
    updateAppointmentStatus, clearAppointmentStatus] = useCRUD({
    id: listIds.SCHEDULER_UPDATE_STATUS,
    url: apiUrls.SCHEDULER_UPDATE_APPOINTMENT_STATUS,
    type: 'update',
  });

  const [time, setTime] = useState();
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedDoctors, selectDoctors] = useState(initialFilters?.doctor || []);
  const [location, selectLocation] = useState(initialFilters?.location);
  const [filteredAppointments, setAppointments] = useState({});

  const { navigate: navigateToTechnician } = useTabLink({
    to: UiRoutes.technicianWithPatientIDAndEncounterId,
  });

  const { navigate: navigateToDoctor } = useTabLink({
    to: UiRoutes.doctorWithPatientIDAndEncounterId,
  });

  const onChangeDate = useCallback((date) => {
    setSelectedDate(moment(date));
  }, []);

  const getAppointmentsList = useCallback(() => {
    const appointmentParams = {};
    const selectedDoctorsData = Array.isArray(selectedDoctors) && compact(selectedDoctors);
    if (selectedDoctorsData?.length) {
      appointmentParams.ProviderId = selectedDoctorsData.join(',');
    }
    if (location) {
      appointmentParams.LocationId = location;
    }
    if (selectedDate) {
      appointmentParams.AppointmentDate = moment(selectedDate).format('YYYY-MM-DD');
    }
    if (appointmentParams?.ProviderId || appointmentParams?.LocationId) {
      getAppointmentList(appointmentParams);
    } else {
      clearAppointmentList(true);
      setAppointments({});
    }
  }, [selectedDoctors, location, selectedDate, getAppointmentList, clearAppointmentList]);

  const getSections = useCallback((status) => (
    Array.isArray(status) ? status : [status])
    .map((key) => filteredAppointments[key])
    .filter((section) => section),
  [filteredAppointments]);

  const toggleCheckInModal = useCallback((data) => {
    if (data) {
      replace(generatePath(`${path}/checkIn/:patientId/:appointmentId/:to/:step`, {
        step: 0,
        patientId: data?.patientId,
        appointmentId: data?.newData?.appointmentId,
        to: data?.newData?.status,
        ...params,
      }));
    } else {
      replace(generatePath(`${path}`, params));
    }
  }, [generatePath, params, path, replace]);

  const toggleCheckOutModal = useCallback((data) => {
    if (data) {
      replace(generatePath(`${path}/checkOut/:patientId/:appointmentId/:encounterId`, {
        patientId: data?.patientId,
        appointmentId: data?.newData?.appointmentId,
        encounterId: data?.newData?.encounterId || 'null',
        ...params,
      }));
    } else {
      replace(generatePath(`${path}`, params));
    }
  }, [generatePath, params, path, replace]);

  const onCardClick = useCallback((status, {
    patientId,
    appointmentId,
    providerId,
    patientFirstName,
    patientMiddleName,
    patientLastName,
    visitType,
  }) => {
    const item = {
      newData: {
        appointmentId, status, patientFirstName, patientMiddleName, patientLastName,
      },
      patientId,
      providerId,
      visitType,
    };
    if (status === 'CheckIn' || status === 'New') toggleCheckInModal(item);
    if (status === 'CheckOut') toggleCheckOutModal(item);
    if (status === 'PendingCheckout') {
      item.newData.status = 'CheckOut'; // status to be set after checkout call
      ConfirmDialog({
        onOk: (close) => {
          toggleCheckOutModal(item);
          close();
        },
        okText: labels.get('buttons.move'),
        title: labels.get('titles.confirmationModal'),
        content: `${labels.get('message.dropConfirmationMessage')} 'Pending Check Out' to 'Completed Check Out'?`,
        icon: <Icon name="ExclamationCircleOutlined" />,
      })();
    }
  }, [labels, toggleCheckInModal, toggleCheckOutModal]);

  const navigateOnDoubleClick = async (navigateFunction, data, tab) => {
    setLoading(true);
    const url = `${apiUrls.GET_APPOINTMENT_ENCOUNTER_ID}/${data.appointmentId}`;
    try {
      const appointmentDetailResponse = await api.get({ token: localStorage.getDecryptedData('token'), url });
      const { encounterId, prevEncounterId } = appointmentDetailResponse;
      const {
        patientId,
        providerId,
        appointmentId,
        patientFirstName,
        patientMiddleName,
        patientLastName,
      } = data;
      const name = getName(patientFirstName, patientMiddleName, patientLastName);
      navigateFunction({
        patientId,
        providerId,
        appointmentId,
        encounterId,
        previousEncounterId: prevEncounterId || '__null',
        tab,
        data: { name },
      });
    } catch (error) {
      Notification({ message: error });
    } finally {
      setLoading(false);
    }
  };

  const onCardDoubleClick = useCallback((status, data) => {
    if (status.toLowerCase() === 'workup') {
      navigateOnDoubleClick(navigateToTechnician, data, 'ccHpi');
    } else if (status.toLowerCase() === 'readyforphysician' || status.toLowerCase() === 'physician') {
      navigateOnDoubleClick(navigateToDoctor, data);
    }
  }, [navigateToDoctor, navigateToTechnician]);

  const scheduleAppointment = useCallback((data) => {
    const {
      visitType,
      patientId,
      locationId,
      providerId,
    } = data;
    const search = {};
    if (visitType) search.visitType = visitType?.visitTypeId;
    if (patientId) search.patient = patientId;
    if (locationId) search.location = locationId;
    if (providerId) search.doctor = providerId;
    replace({
      search: encode(search),
      pathname: UiRoutes.schedular,
    });
  }, [replace]);

  const updateAppointments = useCallback(({ newAppointments, newData }) => {
    if (newAppointments) {
      setAppointments(newAppointments);
    } else {
      needToFetchCards.current = true;
    }
    if (newData) {
      updateAppointmentStatus(newData);
    }
  }, [updateAppointmentStatus]);

  const onCardDrop = useCallback((item, from, to) => {
    if (item && from && to) {
      const newAppointments = cloneDeep({ ...filteredAppointments });
      const {
        appointmentId, patientId, providerId, encounterFlag,
      } = item;
      const fromIndex = newAppointments[from]?.appointments.findIndex(
        (appointment) => appointment.appointmentId === appointmentId,
      );
      if (fromIndex >= 0) {
        newAppointments[from].appointments.splice(fromIndex, 1);
        newAppointments[from].count = newAppointments[from].appointments.length;
      }
      newAppointments[to].appointments.push({ ...item, ref: React.createRef() });
      newAppointments[to].count = newAppointments[to].appointments.length;
      const encounterIndex = encounterFlag.findIndex((encounter) => `${encounter?.appointmentId}` === `${appointmentId}`);
      const { encounterId } = encounterFlag[encounterIndex] || {};
      const data = {
        newAppointments,
        newData: {
          appointmentId,
          status: to,
          encounterId,
        },
      };

      if (
        (from === 'Cancel' && to !== 'CheckIn')
        || from === 'CheckOut'
        || to === 'New'
        || (to === 'CheckIn' && item?.wasInPendingCheckout)
      ) {
        warning({
          onOk: (close) => {
            close();
          },
          title: labels.get('titles.invalidMove'),
          icon: <Icon name="ExclamationCircleOutlined" />,
        });
        return;
      }

      needToFetchCards.current = true;

      switch (`${from} -> ${to}`) {
        case 'New -> CheckIn': toggleCheckInModal({ ...data, patientId, providerId });
          return;
        case 'New -> Workup': toggleCheckInModal({
          ...data, patientId, providerId,
        });
          return;
        case 'Cancel -> CheckIn': ConfirmDialog({
          onOk: (close) => {
            scheduleAppointment(item);
            close();
          },
          okText: labels.get('buttons.createNewAppointment'),
          title: labels.get('titles.invalidMove'),
          content: labels.get('message.droppedCanceledAppointment'),
          icon: <Icon name="ExclamationCircleOutlined" />,
        })();
          return;
        case 'PendingCheckout -> CheckOut': toggleCheckOutModal({ ...data, patientId, providerId });
          return;
        default: updateAppointments(data);
      }
    }
  }, [labels, filteredAppointments, updateAppointments, scheduleAppointment,
    toggleCheckInModal, toggleCheckOutModal]);

  useEffect(() => { getAppointmentsList(); }, [getAppointmentsList]);

  useEffect(() => {
    setTime(moment().unix());
    timerInterval.current = setInterval(() => setTime(moment().unix()), timerUpdateRate);
    return () => { if (timerInterval.current) clearInterval(timerInterval.current); };
  }, []);

  useEffect(() => {
    if (updateAppointmentStatusResponse) {
      clearAppointmentStatus();
      if (needToFetchCards.current) {
        needToFetchCards.current = false;
        getAppointmentsList();
      }
    }
  }, [updateAppointmentStatusResponse]);

  useEffect(() => {
    if (appointmentList) {
      const newAppointmentList = cloneDeep(defaultAppointments);
      appointmentList.map(injectRefs).forEach((appointment) => {
        newAppointmentList[appointment.status] = appointment;
      });
      setAppointments(newAppointmentList);
    }
  }, [appointmentList, selectedDate]);

  return (
    <div className="dashboard-screen">
      {isLoading && <Loader />}
      <Header
        labels={labels}
        location={location}
        selectedDoctors={selectedDoctors}
        onDoctorSelect={selectDoctors}
        onLocationSelect={selectLocation}
        filterSetter={updateFilters}
      />
      <Widgets
        selectedDoctors={selectedDoctors}
        location={location}
        labels={labels}
        selectedDate={selectedDate}
        onDoctorSelect={selectDoctors}
        onLocationSelect={selectLocation}
        filterSetter={updateFilters}
        initialFilters={initialFilters}
      />
      <Filters
        labels={labels}
        onChangeDate={onChangeDate}
        selectedDate={selectedDate}
      />
      <div className="appointment-panel">
        {loading && <WidgetLoader />}
        <CustomDragLayer ref={draggingCardRef} />
        <Upcoming
          cardRef={draggingCardRef}
          labels={labels}
          data={getSections(['CheckIn', 'New', 'Cancel'])}
          onDrop={onCardDrop}
          acceptance={acceptance}
          time={time}
          onClick={onCardClick}
        />
        <Workup
          cardRef={draggingCardRef}
          labels={labels}
          data={getSections('Workup')}
          onDrop={onCardDrop}
          acceptance={acceptance}
          time={time}
          onDoubleClick={onCardDoubleClick}
        />
        <ReadyForPhysician
          cardRef={draggingCardRef}
          labels={labels}
          data={getSections('ReadyForPhysician')}
          onDrop={onCardDrop}
          acceptance={acceptance}
          time={time}
          onDoubleClick={onCardDoubleClick}
        />
        <Physician
          cardRef={draggingCardRef}
          labels={labels}
          data={getSections('Physician')}
          onDrop={onCardDrop}
          acceptance={acceptance}
          time={time}
          onDoubleClick={onCardDoubleClick}
        />
        <CheckOut
          cardRef={draggingCardRef}
          labels={labels}
          data={getSections(['PendingCheckout', 'CheckOut'])}
          onDrop={onCardDrop}
          acceptance={acceptance}
          time={time}
          onClick={onCardClick}
        />
      </div>
      <div className="front-desk-footer">
        <Legends labels={labels} />
      </div>
      {/* <Route exact path={`${path}/checkIn/:patientId/:appointmentId/:to/:step`}>
        <CheckInModal
          details={modalData}
          visible
          toggleModal={toggleCheckInModal}
          onFinish={updateAppointments}
        />
      </Route>
      <Route exact path={`${path}/checkOut/:patientId/:appointmentId/:encounterId`}>
        <CheckOutModal
          details={modalData}
          visible
          toggleModal={toggleCheckOutModal}
          onFinish={updateAppointments}
        />
      </Route> */}
      <ActivityLog />
    </div>
  );
};

export default WithLabel(Dashboard, labelPaths.DASHBOARD);
