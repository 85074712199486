import React, { useCallback } from 'react';

import Icon from '../../components/Icon';

import iconNames from '../../lib/iconNames';

const columns = [
  {
    Header: 'Code',
    accessor: 'name',
    fixWidth: 60,
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: '',
    accessor: 'delete',
    Cell: ({ row: { original }, deleteProcedure }) => {
      const onDeleteProcedureClicked = useCallback(() => {
        deleteProcedure(original.value);
      }, [deleteProcedure, original]);

      return (
        <Icon name={iconNames.deleteIcon} onClick={onDeleteProcedureClicked} />
      );
    },
    fixWidth: 60,
  },
];
export default columns;
