import React from 'react';
import TimeCell from '../../../../../../components/Cells/TimeCell';

const columns = () => [
  {
    Header: 'Dos',
    accessor: 'serviceDate',
    Cell: ({ row: { original: { serviceDateString } } }) => (
      <TimeCell
        value={serviceDateString?.dateString}
        format="MM-DD-YYYY"
      />
    ),
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Procedure',
    accessor: 'cptCode',
  },
  {
    Header: 'Description',
    accessor: 'cptCodeDescription',
  },
  {
    Header: 'Diagnosis',
    accessor: 'billingEncounterDiagnosisCodes',
    Cell: ({ row: { original: { billingEncounterDiagnosisCodes } } }) => {
      const icdCodesStr = billingEncounterDiagnosisCodes.map(({ icdCode }) => icdCode).join(', ');
      return <span title={icdCodesStr}>{icdCodesStr}</span>;
    },
  },
  {
    Header: 'Units',
    accessor: 'unit',
    className: 'text-align-right',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ row: { original: { amount } } }) => (
      (amount || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
  {
    Header: 'Balance',
    accessor: 'balance',
    Cell: ({ row: { original: { balance } } }) => (
      (balance || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
];

export default columns;
