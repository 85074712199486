import React, { useCallback } from 'react';
import { Form as AntdForm, Row, Col } from 'antd';

import WithLabel from '../../../../hoc/withLabel';
import { labelPaths } from '../../../../lib/constants';
import useRedirect from '../../../../hooks/useRedirect';

import Form from '../../../../components/Form';
import Button from '../../../../components/Button';

import InsuranceAdjustmentForm from './Components/InsuranceAdjustmentForm';

import UserProfile from '../UserProfile';
import BalanceSheetTable from '../BalanceSheetTable';
import LineItemsTable from './Components/LineItemsTable';
import Notification from '../../../../components/Notification';
import useReduxState from '../../../../hooks/useReduxState';

const InsuranceAdjustment = (props) => {
  const {
    labels, closeTab, stateId, stageId, queueId, navigateFromCollection,
  } = props;
  const [form] = AntdForm.useForm();
  const { params: { id, tabId } } = useRedirect();
  const [lineItemData, setLineItemData] = useReduxState(`patient-insurance-adjustment-${id}`, []);
  const handleCancel = useCallback(() => closeTab(tabId, true), [closeTab, tabId]);

  const handleSubmit = useCallback(() => {
    let isWriteOffReasonIdError = null;
    let isAdjustmentReasonIdError = null;
    const isWriteOffAndAdjustmentLessThanBalance = lineItemData.every((lineItem) => {
      const writeOffAmount = parseFloat(lineItem?.writeOffAmount ?? 0);
      const adjustment = parseFloat(lineItem?.adjustment ?? 0);
      const balance = parseFloat(lineItem?.balance ?? 0);

      if (writeOffAmount && !lineItem?.writeOffReasonId) {
        isWriteOffReasonIdError = true;
        return false;
      }
      if (adjustment && !lineItem?.adjustmentReasonId) {
        isAdjustmentReasonIdError = true;
        return false;
      }

      // Check for positive balance
      if (balance >= 0 && (writeOffAmount + adjustment) > balance) {
        console.group('positive adj + writeoff amt check');
        console.log(lineItem.cptCode, 'cptCode');
        console.log(balance, 'balance', writeOffAmount, 'writeOffAmount', adjustment, 'adjustment');
        console.groupEnd();
        return false;
      }

      // Check for negative balance
      if (balance < 0 && (writeOffAmount + adjustment) > Math.abs(balance)) {
        console.group('negative adj + writeoff amt check');
        console.log(lineItem.cptCode, 'cptCode');
        console.log(balance, 'balance', writeOffAmount, 'writeOffAmount', adjustment, 'adjustment');
        console.groupEnd();
        return false;
      }
      return true;
    });
    if (isWriteOffReasonIdError) {
      Notification({ message: 'Please add write off reason.' });
    } else if (isAdjustmentReasonIdError) {
      Notification({ message: 'Please add adjustment reason.' });
    } else if (!isWriteOffAndAdjustmentLessThanBalance) {
      Notification({ message: 'Adjustments cannot be greater than balance.' });
    } else {
      form.submit();
    }
  }, [form, lineItemData]);
  return (
    <div className="">
      <div className="patient-description">
        <Row>
          <Col md={24} xl={8}>
            <UserProfile labels={labels} />
          </Col>
          <Col md={24} xl={16}>
            <div className="modal-content-area mr-bt-24 custom-user-table">
              <BalanceSheetTable labels={labels} isCustomized={false} />
            </div>
          </Col>
        </Row>
      </div>
      <InsuranceAdjustmentForm
        lineItemData={lineItemData}
        form={form}
        labels={labels}
        closeTab={closeTab}
        stateId={stateId}
        stageId={stageId}
        queueId={queueId}
      />
      <div className="modal-content-area mr-bt-16 scroll-wrapper">
        <LineItemsTable
          labels={labels}
          lineItemData={lineItemData}
          setLineItemData={setLineItemData}
          navigateFromCollection={navigateFromCollection}
        />
      </div>
      <Form.Section>
        <div className="group-btns">
          <Button
            className="btn min-wt-86"
            id="patients_financial_patientAdjustment_cancel"
            onClick={handleCancel}
            data-testid="cancel-btn"
          >
            {labels.get('buttons.cancel')}
          </Button>
          <Button
            className="btn btn-success min-wt-86"
            id="patients_financial_patientAdjustment_save"
            onClick={handleSubmit}
            data-testid="save-btn"
          >
            {labels.get('buttons.save')}
          </Button>
        </div>
      </Form.Section>
    </div>
  );
};

export default WithLabel(InsuranceAdjustment, labelPaths.FINANCIAL_POPUP_INSURANCE_ADJUSTMENT);
