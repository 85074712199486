import React from 'react';
import TimeCell from '../../../../../../components/Cells/TimeCell';
import Label from '../../../../../../components/Label';
import { addTimeZoneInDate } from '../../../../../../lib/util';

const FinancialDetailGroup2 = ({ financialDetail, createdOn }) => (
  <>
    <div className="input-wrap right-align-financial-charge">
      <div className="label-wrap">
        <Label label="Entered By" />
        :
      </div>
      <div className="name-view">{`${financialDetail?.enteredBy ?? ''} ${createdOn}`}</div>
    </div>
    <div className="input-wrap right-align-financial-charge">
      <div className="label-wrap">
        <Label label="Effective Date" />
        :
      </div>
      <div className="name-view"><TimeCell value={financialDetail?.effectiveDate} /></div>
    </div>
    <div className="input-wrap right-align-financial-charge">
      <div className="label-wrap">
        <Label label="Posting Date" />
        :
      </div>
      <div className="name-view"><TimeCell value={addTimeZoneInDate(financialDetail?.createdOn)} /></div>
    </div>
    <div className="input-wrap right-align-financial-charge">
      <div className="label-wrap">
        <Label label="Payment Method" />
        :
      </div>
      <div className="name-view">{financialDetail?.paymentMethod ?? ''}</div>
    </div>
  </>
);

export default FinancialDetailGroup2;
