import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import keys from 'lodash/keys';
import size from 'lodash/size';
import sortBy from 'lodash/sortBy';

import useCRUD from '../../../../hooks/useCRUD';
import useRedirect from '../../../../hooks/useRedirect';

import * as selectors from '../../../../store/selectors';

import InsuranceDetailsSelect from '../../../../wiredComponents/InsuranceDetailsSelect';
import EnumSelect from '../../../../wiredComponents/Enum/Select';
import WiredSelect from '../../../../wiredComponents/Select';
import { Select } from '../../../../wiredComponents/Enum';

import { apiUrls } from '../../../../api/constants';

import { enums, formId } from '../../../../lib/constants';
import encode from '../../../../lib/encode';
import decode from '../../../../lib/decode';

import Notification from '../../../../components/Notification';
import Loader from '../../../../components/Loader';
import Input from '../../../../components/Form/Input';
import Button from '../../../../components/Button';

import './HeaderFilter.scss';

const specialityOptionParser = (options) => options?.filter((item) => item?.masterCode?.toLowerCase() !== 'outsidereferrals');

const HeaderFilter = ({
  labels, setAppointmentData, form, setFilterParams, setSelectedPatient,
}) => {
  const [appointmentData, , loading, getFilterAppointmentData, clearGetFilterAppointmentData] = useCRUD({ id: formId.BOOK_APPOINTMENT_FILTER, url: apiUrls.GET_BOOK_APPOINTMENT, type: 'read' });
  const { replace, path, query } = useRedirect();

  const [insuranceFilter, setInsuranceFilter] = useState({});

  const promptError = useCallback((name) => {
    Notification({ message: `${name} is required` });
  }, []);

  useEffect(() => {
    setAppointmentData(sortBy(appointmentData, ['locationDistance']));
  }, [setAppointmentData, appointmentData]);

  const onApply = useCallback((params) => {
    if (!params.Radius && !params.zipcode) return promptError('Zip & Radius');
    if (!params.zipcode) return promptError('Zip');
    if (!params.Radius) return promptError('Radius');

    const filteredParams = {};
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        filteredParams[key] = params[key];
      }
    });
    if (query?.patient) {
      filteredParams.patient = query.patient;
      if (insuranceFilter?.payerId) {
        filteredParams.payerId = insuranceFilter?.payerId;
      }
    }
    if (filteredParams.subSpecialityIds) {
      filteredParams.subSpecialityIds = typeof filteredParams.subSpecialityIds === 'object' ? filteredParams?.subSpecialityIds?.join() : filteredParams?.subSpecialityIds;
    }
    getFilterAppointmentData({ ...filteredParams });
    if (query?.patient) {
      if (insuranceFilter?.insuranceId) {
        filteredParams.insuranceId = insuranceFilter?.insuranceId;
      }
      if (insuranceFilter?.payerId) {
        filteredParams.insuranceDetailId = insuranceFilter?.insuranceDetailId;
      }
    }
    setFilterParams({ ...filteredParams });
    const search = encode(filteredParams);
    localStorage.setEncryptedData('book-appointment-settings', search);
    return replace({ search, pathname: path });
  }, [getFilterAppointmentData, insuranceFilter, path,
    promptError, query, replace, setFilterParams]);

  useEffect(() => {
    const lastSetting = localStorage.getDecryptedData('book-appointment-settings');
    const lastFilters = decode(lastSetting) || {};
    const lastSubSpecialityFilter = lastFilters?.subSpecialityIds?.length ? lastFilters?.subSpecialityIds?.split(',') : [];
    if (size(keys(query))) {
      form.setFieldsValue({
        Radius: '5',
        ...query,
        SpecialityId: (query?.SpecialityId && parseInt(query.SpecialityId, 10)) || null,
        payerId: (query?.payerId && parseInt(query.payerId, 10)) || null,
        subSpecialityIds: (lastSubSpecialityFilter?.length
          && lastSubSpecialityFilter.map((e) => parseInt(e, 10))) || undefined,
      });
      onApply({ ...query, Radius: query.Radius ? query.Radius : '5' });
    } else if (size(keys(lastFilters))) {
      form.setFieldsValue({
        Radius: '5',
        ...lastFilters,
        SpecialityId: (lastFilters?.SpecialityId && parseInt(lastFilters.SpecialityId, 10)) || null,
        payerId: (lastFilters?.payerId && parseInt(lastFilters.payerId, 10)) || null,
        subSpecialityIds: (lastSubSpecialityFilter?.length
          && lastSubSpecialityFilter.map((e) => parseInt(e, 10))) || undefined,
      });
      onApply({ ...lastFilters, Radius: lastFilters.Radius ? lastFilters.Radius : '5' });
    } else {
      form.setFieldsValue({ Radius: '5' });
      clearGetFilterAppointmentData(true);
    }
  }, []);

  useEffect(() => {
    const insuranceData = {};
    if (query?.payerId && query?.payerId !== 'undefined') {
      insuranceData.payerId = parseInt(query?.payerId, 10);
    }
    if (query?.insuranceId && query?.insuranceId !== 'undefined') {
      insuranceData.insuranceId = parseInt(query?.insuranceId, 10);
    }
    if (query?.insuranceDetailId && query?.insuranceDetailId !== 'undefined') {
      insuranceData.insuranceDetailId = parseInt(query?.insuranceDetailId, 10);
    }
    setInsuranceFilter(insuranceData);
  }, []);

  const onClear = useCallback(() => {
    setFilterParams(null);
    form.setFieldsValue({
      SpecialtyId: undefined,
      payerId: undefined,
      zipcode: undefined,
      Radius: undefined,
      SpecialityId: undefined,
      subSpecialityIds: undefined,
    });
    replace({
      search: encode({}),
      pathname: path,
    });
    setSelectedPatient(null);
    setAppointmentData([]);
    localStorage.removeEncryptedData('book-appointment-settings');
  }, [form, path, replace, setAppointmentData, setFilterParams, setSelectedPatient]);

  const handleInsuranceFilter = useCallback((value, data) => {
    const { item } = data || {};
    const insuranceData = {};
    if (item?.payerId) {
      insuranceData.payerId = item.payerId;
    }
    if (item?.insuranceProfileId) {
      insuranceData.insuranceId = item.insuranceProfileId;
    }
    if (item?.insuranceProfileDetailId) {
      insuranceData.insuranceDetailId = item.insuranceProfileDetailId;
    }
    setInsuranceFilter(insuranceData);
  }, []);

  const handleInsuranceFilterChange = useCallback((value) => {
    if (!value) {
      setInsuranceFilter({});
    }
  }, []);

  useEffect(() => {
    if (query?.zipcode === 'undefined') {
      form.setFieldsValue({ zipcode: undefined });
    }
    if (query?.zipcode !== 'undefined') {
      form.setFieldsValue({ zipcode: query.zipcode });
    }
  }, [query]);

  const insuranceInitialValue = useMemo(() => (insuranceFilter?.insuranceId
    ? { insuranceProfileId: insuranceFilter?.insuranceId }
    : {}), [insuranceFilter]);

  return (
    <Form form={form} onFinish={onApply}>
      {loading && <Loader />}
      <div className="flex flex-wrap">
        <div className="input-wrap">
          {!query?.patient || query?.patient === 'undefined' ? (
            <WiredSelect
              id="payer"
              name="payerId"
              url={apiUrls.CONTRACT_PAYER_DROPDOWN}
              label={labels.get('labels.insuranceName')}
              placeholder={labels.get('placeholders.payer')}
              nameAccessor="payerName"
              valueAccessor="payerId"
              labelSpan="10"
              inputSpan="14"
              selectProps={{
                style: { width: 140 },
                showSearch: true,
              }}
            />
          )
            : (
              <div className="insurance-tree-wrap">
                <InsuranceDetailsSelect
                  selectProps={{
                    style: { width: 140 },
                    showSearch: true,
                  }}
                  id="payer"
                  name="payerId"
                  placeholder={labels.get('placeholders.payer')}
                  patientId={query.patient}
                  label={labels.get('labels.insuranceName')}
                  onSelect={handleInsuranceFilter}
                  onChange={handleInsuranceFilterChange}
                  isFormItem={false}
                  labelSpan="8"
                  inputSpan="16"
                  initialValue={insuranceInitialValue}
                  value={insuranceFilter?.insuranceDetailId}
                  disableOtherThanPrimaryAndSelf
                  includePayerName
                />
              </div>
            )}
        </div>
        <div className="input-wrap">
          <EnumSelect
            id="specialty"
            label={labels.get('labels.serviceType')}
            name="SpecialityId"
            enumName={enums.PROVIDER_SPECIALTY}
            labelSpan="8"
            inputSpan="16"
            placeholder={labels.get('placeholders.specialty')}
            optionParser={specialityOptionParser}
            selectProps={{
              style: { width: 154 },
              showSearch: true,
            }}
          />
        </div>
        <div className="input-wrap sub-special-wrap">
          <Select
            label="Sub-Speciality"
            name="subSpecialityIds"
            id="9"
            enumName={enums.SUB_SPECIALITY}
            form={form}
            labelSpan="8"
            inputSpan="16"
            selectProps={{
              showArrow: true,
              showSearch: false,
              mode: 'multiple',
              maxTagCount: 'responsive',
              style: { width: 200 },
            }}
          />
        </div>
        <div className="input-wrap">
          <Input
            id="zip"
            label={labels.get('labels.zip')}
            name="zipcode"
            numberOnly
            minLength="4"
            labelSpan="8"
            inputSpan="16"
            placeholder={labels.get('labels.zip')}
            required
          />
        </div>
        <div className="input-wrap">
          <EnumSelect
            // id="Radius"
            label={labels.get('labels.radius')}
            name="Radius"
            enumName={enums.LOCATION_SEARCH_RADIUS}
            labelSpan="8"
            inputSpan="16"
            placeholder={labels.get('placeholders.radius')}
            // nameAccessor="masterName"
            valueAccessor="masterCode"
            required
            selectProps={{
              style: { width: 154 },
              showSearch: true,
            }}
          />
          {/* <WiredSelect
            id="radius"
            name="Radius"
            url={apiUrls.GET_ENUM_FIELDS}
            label={labels.get('labels.radius')}
            nameAccessor="masterName"
            valueAccessor="masterCode"
            params={{ EnumId: get(enumMaster, `${enums.LOCATION_SEARCH_RADIUS}.enumId`) }}
            selectProps={{
              style: { minWidth: 140 },
              allowClear: false,
            }}
            labelSpan="6"
            inputSpan="18"
            sortParamter={{ name: Number }}
          /> */}
        </div>
        <div className="filter-buttons appointment_filter-btns">
          <Button className="btn sm-btn mr-rt-8" data-testid="view-button" type="submit">{labels.get('buttons.view')}</Button>
          <Button className="btn sm-btn" data-testid="reset-button" onClick={onClear}>{labels.get('buttons.reset')}</Button>
        </div>
      </div>
    </Form>
  );
};

HeaderFilter.defaultProps = {
  filterParams: {},
  setFilterParams: () => { /* This is intentional */ },
  setAppointmentData: () => { /* This is intentional */ },
};

HeaderFilter.propTypes = {
  filterParams: PropTypes.objectOf(PropTypes.string),
  setAppointmentData: PropTypes.func,
  setFilterParams: PropTypes.func,
};

export default connect((state) => ({
  enumMaster: selectors.getEnumMaster(state),
}))(HeaderFilter);
