const createQueryParams = (params = {}) => {
  const keys = Object.keys(params);
  let queryParams = '';
  keys.forEach((x, index) => {
    if (index < (keys.length - 1)) {
      queryParams += `${x}=${params[x]}&`;
    } else {
      queryParams += `${x}=${params[x]}`;
    }
  });
  return queryParams;
};

export default createQueryParams;
