import React, {
  useRef, useState, useCallback, useMemo, useEffect,
} from 'react';
import classNames from 'classnames';
import { useDrop } from 'react-dnd';

import useCRUD from '../../../../../../hooks/useCRUD';
import { listIds } from '../../../../../../lib/constants';
import { apiUrls } from '../../../../../../api/constants';

import Icon from '../../../../../../components/Icon';
import ConfirmDialog from '../../../../../../components/ConfirmDialog';

import Card from '../Card';

import './list.scss';

const listOrders = [
  'CheckIn',
  'Workup',
  'ReadyForPhysician',
  'Physician',
  'CheckOut',
  'Completed',
];

const List = ({
  id, labels, data, onDrop, cardRef, time, onClick, onDoubleClick, acceptance,
}) => {
  const ref = useRef(null);
  const accept = useMemo(() => acceptance[id], [acceptance, id]);
  const idIndex = useMemo(() => listOrders.indexOf(id), [id]);

  const [,, updating] = useCRUD({
    id: listIds.SCHEDULER_UPDATE_STATUS,
    url: apiUrls.SCHEDULER_UPDATE_APPOINTMENT_STATUS,
    type: 'update',
  });

  const [cardLoading, setCardLoading] = useState();
  const [hoveringAbove, setHoveringAbove] = useState({});

  const [{ currentItem, isOver }, drop] = useDrop({
    accept,
    drop: (item) => {
      if (!ref.current) {
        return;
      }
      setCardLoading(item.id);
      const newItem = {
        ...item,
        type: id,
        status: id,
      };
      if (onDrop) {
        setHoveringAbove({});
        if (newItem.status !== 'New'
          && !(newItem.status === 'CheckIn' && item?.wasInPendingCheckout)
          && (
            (item.status === 'PendingCheckout') || (
              listOrders.indexOf(item.status) > idIndex
              && item.status !== 'Cancel'
              && item.status !== 'CheckOut'
            )
          )
        ) {
          let message = `${labels.get('message.dropConfirmationMessage')} '`;
          message += labels.get(`headers.status.${item.status}`);
          message += `' ${labels.get('message.dropConfirmationMessageSeparator')} '`;
          message += labels.get(`headers.status.${newItem.status}`);
          message += '\'?';
          ConfirmDialog({
            onOk: (close) => {
              onDrop(newItem, item.status, newItem.status);
              close();
            },
            okText: labels.get('buttons.move'),
            title: labels.get('titles.confirmationModal'),
            content: message,
            icon: <Icon name="ExclamationCircleOutlined" />,
          })();
        } else onDrop(newItem, item.status, newItem.status);
      }
    },
    collect: (monitor) => ({
      currentItem: monitor.getItem() || {},
      isOver: monitor.isOver(),
    }),
  });

  const onHover = useCallback((hoverId, index) => {
    if (hoverId && Number.isInteger(index)) {
      if (hoveringAbove.index !== index) {
        setHoveringAbove({ id: hoverId, index });
      }
    } else if (hoveringAbove.id) setHoveringAbove({});
  }, [hoveringAbove, setHoveringAbove]);

  const itemList = useMemo(() => {
    const newCardArray = [...data];
    if (isOver && hoveringAbove.id !== currentItem.id) {
      const indicator = {
        type: 'drop-indicator',
        height: cardRef && cardRef.current && cardRef.current.clientHeight,
      };
      if (hoveringAbove && Number.isInteger(hoveringAbove.index)) {
        newCardArray.splice(hoveringAbove.index, 0, indicator);
      } else newCardArray.push(indicator);
    }
    return newCardArray;
  }, [data, isOver, hoveringAbove, currentItem.id, cardRef]);

  useEffect(() => {
    if (!updating) setCardLoading();
  }, [updating]);

  drop(ref);

  return (
    <div ref={ref} className={classNames('dashboard-list', { hovering: isOver })}>
      {itemList.map((appointment, index) => (
        appointment.type === 'drop-indicator'
          ? <div className="drop-indicator-card" style={{ height: appointment.height }} />
          : (
            <Card
              key={appointment.id}
              index={index}
              data={appointment}
              onHover={onHover}
              onClick={onClick}
              onDoubleClick={onDoubleClick}
              time={time}
              accept={accept}
              loading={cardLoading === appointment.id}
            />
          )
      ))}
    </div>
  );
};

List.defaultProps = {
  id: '',
  data: [],
  onDrop: () => { /* This is intentional */ },
  onClick: () => { /* This is intentional */ },
};

export default List;
