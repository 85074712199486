import React from 'react';
import { Form, Checkbox } from 'antd';
import PropTypes from 'prop-types';
import './checkbox.scss';

const CheckBox = ({
  children, name, className, checkboxProps, isFormItem, dataTestId, ...props
}) => (isFormItem ? (
  <Form.Item className={className} name={name} {...props}>
    <Checkbox {...checkboxProps} data-testid={dataTestId || name}>{children}</Checkbox>
  </Form.Item>
)
  : (
    <Checkbox
      className={className}
      name={name}
      {...props}
      {...checkboxProps}
      data-testid={dataTestId || name}
    >
      {children}
    </Checkbox>
  ));

CheckBox.defaultProps = {
  className: '',
  name: '',
  children: [],
  isFormItem: true,
};

CheckBox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isFormItem: PropTypes.bool,
};

export default CheckBox;
