import isPlainObject from 'lodash/isPlainObject';

const toolbarTypes = [
  { propName: 'toolbarButtons', type: 'md' },
  { propName: 'toolbarButtonsMD', type: 'md' },
  { propName: 'toolbarButtonsSM', type: 'sm' },
  { propName: 'toolbarButtonsXS', type: 'xs' },
];

export const createToolbarTemplates = (template) => {
  const toolbarTemplate = {};
  toolbarTypes.forEach(({ propName, type }) => {
    toolbarTemplate[propName] = {};
    Object.keys(template).forEach((name) => {
      toolbarTemplate[propName][name] = {};
      Object.keys(template[name]).forEach((option) => {
        if (isPlainObject(template[name][option])) {
          toolbarTemplate[propName][name][option] = template[name][option][type];
        } else {
          toolbarTemplate[propName][name][option] = template[name][option];
        }
      });
    });
  });
  return toolbarTemplate;
};

export default { createToolbarTemplates };
