import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import AutoComplete from '../../../components/Form/AutoComplete/AntdAutocomplete';
import { apiUrls } from '../../../api/constants';
import Table from '../../../components/Table';

// eslint-disable-next-line import/no-cycle
import columns from './columns';
import './icd.scss';

const optionRenderer = (options = []) => {
  if (!options.length) return [];
  return [{
    label: (
      <div className="option-header-main-wrapper">
        <div className="option headerWrapper">
          <span className="header">
            Code
          </span>
          <span className="header" style={{ width: '73%' }}>
            Description
          </span>
        </div>
      </div>
    ),
    options: options && options.map((item) => (
      {
        value: item?.icdCode,
        item,
        label: (
          <div className="option">
            <span title={item?.icdCode}>
              {item?.icdCode}
            </span>
            <span title={item?.description} style={{ width: '73%' }}>
              {item?.description}
            </span>
          </div>
        ),
      }

    )),
  }];
};
const ICDLookup = ({
  type,
  labelSpan,
  data,
  onDelete,
  loading,
  onSelect,
  params,
  allowClearOnSelect,
  inputSpan,
  labels,
  isAddSynonym,
  otherUrl,
  valueInString,
  includeInActive,
  valueAccessor,
  hideDeleteIcon,
  toggleDxFavoriteModal,
  ...otherProps
}) => {
  const optionParser = (option) => ({
    value: option[valueAccessor],
    code: option.icdCode,
    desc: option.description,
    ...option,
  });

  const handleOnSelect = useCallback((value, allItems) => {
    const { item } = allItems;
    onSelect(item);
  }, [onSelect]);

  return (
    <div>
      <AutoComplete
        url={otherUrl || apiUrls.GET_SETTINGS_ICD}
        optionMaster="ICDCodeText"
        params={{ IncludeInActive: includeInActive, ...params }}
        optionParser={optionParser}
        onSelect={handleOnSelect}
        placeholder="Search ICD To Add"
        optionRenderer={optionRenderer}
        dropdownMatchSelectWidth={300}
        labelSpan={labelSpan}
        inputSpan={inputSpan}
        allowClearOnSelect={allowClearOnSelect}
        {...otherProps}
      />
      {type === 'multi' && data.length > 0
        ? (
          <Table
            columns={columns(labels, isAddSynonym, hideDeleteIcon, toggleDxFavoriteModal)}
            data={data}
            deleteOcularProblem={onDelete}
            loading={loading}
          />
        )
        : null}
    </div>
  );
};

ICDLookup.defaultProps = {
  labelSpan: 10,
  inputSpan: '24',
  type: 'multi',
  data: [],
  params: {},
  onSelect: () => { /* This is intentional */ },
  allowClearOnSelect: true,
  otherUrl: null,
  includeInActive: true,
  valueAccessor: 'id',
  hideDeleteIcon: false,
};

ICDLookup.propTypes = {
  type: PropTypes.string,
  labelSpan: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  params: PropTypes.instanceOf(Object),
  onSelect: PropTypes.func,
  allowClearOnSelect: PropTypes.bool,
  inputSpan: PropTypes.string,
  otherUrl: PropTypes.string,
  includeInActive: PropTypes.bool,
  valueAccessor: PropTypes.string,
  hideDeleteIcon: PropTypes.bool,
};

export default ICDLookup;
