import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

const Modals = (props) => {
  const {
    children, toggleModal, width, ...otherProps
  } = props;
  return (
    <Modal
      title=""
      centered
      onOk={() => toggleModal && toggleModal(true)}
      onCancel={() => toggleModal && toggleModal(false)}
      width={width}
      {...otherProps}
    >
      {children}
    </Modal>
  );
};

Modals.defaultProps = {
  width: 700,
  children: [],
  toggleModal: () => { /* This is intentional */ },

};

Modals.propTypes = {
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  toggleModal: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Modals;
