import React from 'react';
import TimeCell from '../../../../../../components/Cells/TimeCell';
import Icon from '../../../../../../components/Icon';
import iconNames from '../../../../../../lib/iconNames';
import { addTimeZoneInDate } from '../../../../../../lib/util';

const postedBatchColumns = (labels) => [
  {
    Header: labels.get('tableColumns.businessEntityName'),
    accessor: 'businessEntity',
    fixWidth: 150,
    sort: true,
  },
  {
    Header: labels.get('tableColumns.payerName'),
    accessor: 'insurancePayerName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.depositDate'),
    accessor: 'depositDate',
    sort: true,
    Cell: (e) => <TimeCell value={e?.row.original?.depositDate?.dateString} format="MM-DD-YYYY" />,
  },
  {
    Header: labels.get('tableColumns.postingDate'),
    accessor: 'modifiedDate',
    sort: true,
    Cell: (e) => <TimeCell value={e?.row.original?.postedOn === null ? e?.row.original?.modifiedDate?.dateString : addTimeZoneInDate(e?.row.original?.postedOn)} format={"MM-DD-YYYY"} />,
  },
  {
    Header: labels.get('tableColumns.source'),
    accessor: 'source',
    fixWidth: 100,
    sort: true,
  },
  {
    Header: labels.get('tableColumns.batchNumber'),
    accessor: 'batchNumber',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.batchAmount'),
    accessor: 'totalBatchAmount',
    Cell: ({ row: { original: { totalBatchAmount } } }) => (
      parseFloat(totalBatchAmount || 0).toFixed(2)
    ),
    className: 'text-align-right',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.applied'),
    accessor: 'enteredAmount',
    Cell: ({ row: { original: { enteredAmount } } }) => (
      parseFloat(enteredAmount || 0).toFixed(2)
    ),
    className: 'text-align-right',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.checkNumber'),
    accessor: 'checkNumber',
    className: 'text-align-right',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.checks'),
    accessor: 'checkCount',
    className: 'text-align-right',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.account'),
    accessor: 'account',
    Cell: ({ row: { original: { account } } }) => (
      parseFloat(account || 0).toFixed(2)
    ),
    className: 'text-align-right',
    sort: true,
  },
  {
    Header: '',
    accessor: 'paperClipIcon',
    Cell: ({ row: { original: { batchType } } }) => (
      <span className="flex justify-content-center">
        {batchType?.toLowerCase() === 'manual' && <Icon name={iconNames.paperClipOutlined} color="green" />}
        {batchType?.toLowerCase() === 'electronic' && <Icon name={iconNames.thunderboltOutlined} color="orange" />}
      </span>
    ),
    fixWidth: '60',
  },
];

export default postedBatchColumns;
