import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Row, Col } from 'antd';
import debounce from 'lodash/debounce';

import { apiUrls } from '../../../../../../api/constants';
import {
  listIds,
} from '../../../../../../lib/constants';
import useRedirect from '../../../../../../hooks/useRedirect';
import withQuery from '../../../../../../hoc/withQuery/withQuery';

import SearchBox from '../../../../../../components/Search';

import columns from './Columns/InsurancePayment';

const PaymentTable = withQuery({
  url: apiUrls.GET_CLAIMS_LIST,
  listId: listIds.FINANCIAL_INSURANCE_PAYMENT_LIST,
})();

const InsurancePaymentTable = ({
  labels, toggleModal, handleAddEncounter, ...props
}) => {
  const {
    params: { id },
  } = useRedirect();

  const [searchText, setSearchText] = useState();
  const [searchValue, setSearchValue] = useState();

  const handleSearchText = useMemo(() => debounce(setSearchText, 1500), []);
  const handleSearchValue = useCallback(({ target: { value } }) => setSearchValue(value), []);
  const handleRowClick = useCallback(({ original }) => handleAddEncounter(original),
    [handleAddEncounter]);

  useEffect(() => {
    handleSearchText(searchValue);
  }, [handleSearchText, searchValue]);

  return (
    <div className="insurance-payment-table-wrapper">
      <PaymentTable
        columns={columns(labels)}
        filters={{
          searchText,
          PatientId: id,
          IsPayerResponsibility: true,
          state: 'insurancepayment',
        }}
        onRowClick={handleRowClick}
        scrollId={`insurance-claim-table-${id}`}
        {...props}
      >
        {({ Component, totalCount }) => (
          <>
            <div className="mr-bt-16">
              <Row>
                <Col md={7} className="sm-search-design">
                  <SearchBox
                    label=""
                    name="payer"
                    required
                    id="19"
                    value={searchValue}
                    onChange={handleSearchValue}
                  />
                </Col>
              </Row>
            </div>
            <div className="modal-content-area">
              {Component}
            </div>
            <span className="footer-count-value">{`Total Count: ${totalCount}`}</span>
          </>
        )}
      </PaymentTable>
    </div>
  );
};

export default InsurancePaymentTable;
