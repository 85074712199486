import React, { useCallback, useState } from 'react';
import { Row } from 'antd';

import AutoComplete from '../../../../components/Form/AutoComplete/AntdAutocomplete';

import { apiUrls } from '../../../../api/constants';

import { labelPaths } from '../../../../lib/constants';

import WithLabel from '../../../../hoc/withLabel';

import PayerModalTable from '../PayerModalTable';

const CPTOptionRenderer = (options = []) => {
  if (!options.length) return [];
  return [{
    label: (
      <div className="option headerWrapper">
        <span className="header">
          CPT code
        </span>
        <span className="header">
          Description
        </span>
      </div>
    ),
    options: options && options.map((item) => (
      {
        value: item.cptCode,
        item,
        label: (
          <div className="option" key={item.cptCode}>
            <span>
              {item.cptCode}
            </span>
            <span>
              {item.description}
            </span>
          </div>
        ),
      }

    )),
  }];
};

function CPTPayerMapping(props) {
  const {
    selectedPayer = {},
    onSelectCPT,
    selectedMapping,
    labels,
    onICDCheckBoxClick,
  } = props;

  const { name, address } = selectedPayer;

  const { insurancePayerId, cptCode, description } = selectedMapping;

  const [icdFilters, setICDCodeFilter] = useState({ ICDCodeText: 'H00' });

  const onICDCodeFilterChange = useCallback((value) => {
    if (value && value.length >= 3) {
      setICDCodeFilter({
        ICDCodeText: value,
      });
    }
  }, [setICDCodeFilter]);

  const CPTOptionParser = useCallback((option) => ({
    name: option.cptCode,
    value: option.description,
    ...option,
  }), []);

  return (
    <div className="cpt-form">
      <Row>
        <div className="required-feilds top-feild-mng">
          <h2 className="h2-title">{labels.get('titles.payerMapping')}</h2>
        </div>
      </Row>
      <div className="merge-table">
        <div className="app-table cpt-table-wrap">
          <table>
            <thead>
              <tr>
                <th width="120">{labels.get('labels.payerName')}</th>
                <th width="120">{name}</th>
                <th width="120">
                  {labels.get('labels.payerAddress')}
                </th>
                <th width="120">
                  {address}
                </th>
              </tr>
              {insurancePayerId && (
              <tr>
                <th width="120">{labels.get('labels.cpt')}</th>
                <th colSpan={2}>
                  <div className="search-input mr-tb-4">
                    <AutoComplete
                      id="1"
                      placeholder={labels.get('placeHolders.cpt')}
                      onSelect={onSelectCPT}
                      url={apiUrls.GET_CPTS}
                      optionRenderer={CPTOptionRenderer}
                      optionMaster="CPTCodeText"
                      labelSpan="0"
                      inputSpan="24"
                      optionParser={CPTOptionParser}
                    />
                  </div>
                </th>
              </tr>
              )}
              {cptCode && (
                <tr>
                  <th width="120">{labels.get('labels.CPTCode')}</th>
                  <th>{cptCode}</th>
                  <th width="120">
                    {labels.get('labels.CPTDescription')}
                  </th>
                  <th>
                    {description}
                  </th>
                </tr>
              )}
              <tr>
                <th width="120">{labels.get('labels.ICDCode')}</th>
                <th colSpan={2}>
                  <div className="search-input mr-tb-4">
                    <div className="auto-complete-search">
                      <AutoComplete
                        placeholder={labels.get('placeHolders.icd')}
                        id="2"
                        name="icdCode"
                        labelSpan="0"
                        inputSpan="24"
                        onSearch={onICDCodeFilterChange}
                      />
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <PayerModalTable
          filters={{ ...icdFilters }}
          labels={labels}
          onICDCheckBoxClick={onICDCheckBoxClick}
          {...props}
        />
      </div>
    </div>
  );
}

export default WithLabel(CPTPayerMapping, labelPaths.ADD_ICD_CPT_MAPPING);
