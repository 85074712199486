import React, { useCallback, useEffect } from 'react';

import useRedirect from '../../../../../hooks/useRedirect';

import { apiUrls } from '../../../../../api/constants';
import iconNames from '../../../../../lib/iconNames';
import WarningMessages from '../../../../../lib/warningMessages';
import { UiRoutes } from '../../../../../lib/constants';
import SuccessMessages from '../../../../../lib/successMessages';

import Loader from '../../../../../components/Loader';
import Button from '../../../../../components/Button';
import Icon from '../../../../../components/Icon';
import Notification from '../../../../../components/Notification';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import TimeCell from '../../../../../components/Cells/TimeCell';
import useCRUD from '../../../../../hooks/useCRUD';
import { updateImageSrc } from '../../../../../lib/util';

const Header = ({
  templateDataLoading, toggleSettingsModal, templateData, labels, getHeaderHTML,
  getBodyHTML,
  getFooterHTML,
}) => {
  const { push, generatePath, params: { templateId } } = useRedirect();

  const [saveTemplateResponse, ,, saveTemplate, clearResponse] = useCRUD({ id: apiUrls.SAVE_TEMPLATE, url: apiUrls.SAVE_TEMPLATE, type: 'update' });

  useEffect(() => {
    if (saveTemplateResponse) {
      Notification({
        message: SuccessMessages.UPDATE_TEMPLATE,
        success: true,
      });
      clearResponse();
    }
  }, [clearResponse, saveTemplateResponse]);

  const goBack = useCallback(() => {
    ConfirmDialog({
      onOk: (close) => {
        push(generatePath(UiRoutes.composer));
        close();
      },
      okText: 'Go Back',
      title: 'Go Back?',
      content: WarningMessages.WANT_TO_GO_BACK_WITH_UNSAVED_CHANGES,
      icon: <Icon name="ExclamationCircleOutlined" />,
    })();
  }, [push, generatePath]);

  const saveTemplateData = useCallback(() => {
    // eslint-disable-next-line no-template-curly-in-string
    const updatedHeaderWithVariable = updateImageSrc(getHeaderHTML(), 'acuityEyeLogo', '{{acuityEyeLogo}}', null, true);

    saveTemplate(
      {
        ...templateData,
        templateName: templateData?.templateName,
        category: templateData?.category,
        categoryId: templateData?.categoryId,
        templateType: templateData?.templateType,
        isRequireProviderSignOff: templateData?.isRequireProviderSignOff,
        isActive: templateData?.isActive,
        mainText: getBodyHTML() || '',
        headerText: updatedHeaderWithVariable || '',
        footerText: getFooterHTML() || '',
      },
      `/${templateId}`,
    );
  }, [
    getBodyHTML,
    getFooterHTML,
    getHeaderHTML,
    saveTemplate,
    templateData,
    templateId,
  ]);

  const handleSubmit = useCallback(() => {
    if (templateId) {
      saveTemplateData();
    } else {
      toggleSettingsModal(!!templateId)();
    }
  }, [saveTemplateData, templateId, toggleSettingsModal]);

  return (
    <div className="template-header">
      {templateDataLoading && <Loader />}
      <h2 className="bold page-title back-arrow" onClick={goBack} role="presentation">{templateData?.templateName ? templateData?.templateName : labels.get('titles.template')}</h2>
      <div className="editor-header-options">
        {templateId && (
        <>
          <div className="label-container">
            <label htmlFor="last-modified-at">
              {`${labels.get('labels.lastEdited')} :`}
            </label>
            <span id="last-modified-at"><TimeCell value={templateData?.modifiedOn} /></span>
          </div>
          <div className="label-container">
            <label htmlFor="last-modified-by">
              {`${labels.get('labels.lastModifiedBy')} :`}
            </label>
            <span id="last-modified-by">{templateData?.modifiedByName || 'Admin'}</span>
          </div>
        </>
        )}
        <Button className="btn sm-btn mr-rt-8 settings-button" onClick={toggleSettingsModal(!!templateId)} title={labels.get('titles.settings')}>
          <Icon name={iconNames.settings} />
        </Button>
        <Button className="btn btn-success sm-btn mr-rt-12" type="submit" onClick={handleSubmit}>Save</Button>
        {templateId && <Button className="btn btn-success sm-btn" type="submit" onClick={toggleSettingsModal(false)}>Save As</Button>}
      </div>
    </div>
  );
};
export default Header;
