import ErrorMessages from './errorMessages';

export const toDateValidator = (getFieldValue, fieldName, errorMessage) => ({
  validator(rule, value) {
    if (!value || !getFieldValue(fieldName) || getFieldValue(fieldName).isBefore(value, 'day') || getFieldValue(fieldName).isSame(value, 'day')) {
      return Promise.resolve();
    }
    return Promise.reject(errorMessage || ErrorMessages.TO_DATE_VALIDATOR_ERROR);
  },
});

export const conFirmEmailValidator = (getFieldValue, fieldName) => ({
  validator(rule, value) {
    if (!value || !getFieldValue(fieldName) || getFieldValue(fieldName) === value) {
      return Promise.resolve();
    }
    return Promise.reject(ErrorMessages.CONFIRM_EMAIL_VALIDATION_ERROR);
  },
});

export const inputTrimValidator = (field) => ({
  validator(rule, value) {
    const error = `${field} ${ErrorMessages.FIELD_REQUIRED}`;
    if (value && value.trim()) {
      return Promise.resolve();
    }
    return Promise.reject(error);
  },
});
