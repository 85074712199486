import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tabs } from 'antd';
import './Tabs.scss';

const { TabPane } = Tabs;

const TabsWithoutUrlNavigation = ({
  selectedTab,
  handleChange,
  tabsList,
  selectedTabData,
  dataHandler,
  updateHandler,
  ...props
}) => {
  const tabIndex = tabsList.findIndex((data) => data.id === selectedTab);
  const Component = tabsList?.[tabIndex]?.component;

  return (
    <div>
      <div className="h-tabs">
        <div>
          <Tabs
            activeKey={selectedTab}
            onChange={handleChange}
            aria-label="simple tabs"
          >
            {
              tabsList?.length && tabsList.map((item) => {
                const name = item.name || '';
                const iconClassName = item.className || '';
                const tabLabel = name || item.id;
                return (
                  <TabPane
                    key={item?.id}
                    tab={item?.tab || (
                      <div className={classNames('tab-label', { 'tab-label-icon': iconClassName }, iconClassName)}>
                        <span>{tabLabel?.toUpperCase()}</span>
                      </div>
                    )}
                    id={item?.id}
                    disabled={item?.disabled}
                  />
                );
              })
            }
          </Tabs>
        </div>
      </div>
      <Component
        dataHandler={dataHandler}
        selectedTabData={selectedTabData}
        updateHandler={updateHandler}
        handleChange={handleChange}
        {...props}
      />
    </div>
  );
};

TabsWithoutUrlNavigation.defaultProps = {
  tabsList: [],
  selectedTabData: {},
  dataHandler: () => { /* This is intentional */ },
  updateHandler: () => { /* This is intentional */ },
  selectedTab: '',
};

TabsWithoutUrlNavigation.propTypes = {
  tabsList: PropTypes.instanceOf(Array),
  selectedTabData: PropTypes.instanceOf(Object),
  selectedTab: PropTypes.string,
  dataHandler: PropTypes.func,
  updateHandler: PropTypes.func,
};

export default TabsWithoutUrlNavigation;
