const hideComposerToolBar = (viewPanel) => {
  if (viewPanel?.current && viewPanel?.current?.querySelector('.fr-toolbar')) {
    viewPanel?.current?.querySelectorAll('.fr-toolbar')
    .forEach((item) => item.setAttribute('style', 'display: none'));
  }
  if (viewPanel?.current && viewPanel?.current?.querySelector('.fr-wrapper')) {
    viewPanel?.current?.querySelectorAll('.fr-wrapper')
    .forEach((item) => item.setAttribute('style', 'border: none'));
  }
};

const unHideComposerToolBar = (viewPanel) => {
  if (viewPanel?.current && viewPanel?.current?.querySelector('.fr-toolbar')) {
    viewPanel?.current?.querySelectorAll('.fr-toolbar')
    .forEach((item) => item.setAttribute('style', 'display: block'));
  }
  if (viewPanel?.current && viewPanel?.current?.querySelector('.fr-wrapper')) {
    viewPanel?.current?.querySelectorAll('.fr-wrapper')
    .forEach((item) => item.setAttribute('style', 'border: 0.1rem solid rgba(165, 188, 223, 0.7)'));
  }
  if (viewPanel?.current && viewPanel?.current?.querySelector('#body-container')?.querySelector('.fr-wrapper')) {
    viewPanel?.current?.querySelector('#body-container')?.querySelector('.fr-wrapper')?.setAttribute('style', 'border-bottom: none');
    viewPanel?.current?.querySelector('#body-container')?.querySelector('.fr-wrapper')?.setAttribute('style', 'border-top: none');
  }
};

export {
  hideComposerToolBar,
  unHideComposerToolBar,
};
