import React, { useCallback, useState } from 'react';

import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';

import CPTPayerMapping from '../../components/CPTPayerMapping';
import './style.scss';

const CPTPayerMappingModal = ({
  labels,
  isAddMappingModalVisible,
  onCloseModal,
  handleMappingUpdate,
  selectedPayer,
  onSelectCPT,
  setSelectedCPT,
  selectedMapping,
}) => {
  const [selectedICDCodes, setSelectedICDCodes] = useState({});
  const [icdCodes, setICDCodes] = useState({});

  const onICDCheckBoxClick = useCallback((data) => {
    const { id, icdCode } = data;
    const icdCodeObj = selectedICDCodes;
    const icdCodeNameObj = icdCodes;
    if (icdCodeObj[id]) {
      delete icdCodeObj[id];
      delete icdCodeNameObj[icdCode];
    } else {
      icdCodeObj[id] = !icdCodeObj[id];
      icdCodeNameObj[icdCode] = !icdCodeNameObj[icdCode];
    }
    setICDCodes({ ...icdCodeNameObj });
    setSelectedICDCodes({ ...icdCodeObj });
  }, [icdCodes, selectedICDCodes]);

  const handleModalClose = useCallback(() => onCloseModal(setSelectedICDCodes),
    [setSelectedICDCodes, onCloseModal]);
  const handleSave = useCallback(() => {
    handleMappingUpdate(icdCodes, setSelectedICDCodes, setICDCodes);
  },
  [handleMappingUpdate, icdCodes]);

  return (
    <Modal
      visible={isAddMappingModalVisible}
      toggleModal={handleModalClose}
      centered
      footer={[
        <div className="group-btns" key="footer">
          <Button
            className="btn min-wt-86"
            onClick={handleModalClose}
          >
            {labels.get('buttons.cancel')}
          </Button>
          <Button
            className="btn btn-success min-wt-86 inline"
            onClick={handleSave}
          >
            {labels.get('buttons.save')}
          </Button>
        </div>,
      ]}
    >
      <CPTPayerMapping
        title="Payer CPT ICD Mapping"
        selectedPayer={selectedPayer}
        onSelectCPT={onSelectCPT}
        onICDCheckBoxClick={onICDCheckBoxClick}
        checkedICDCodes={selectedICDCodes}
        setSelectedCPT={setSelectedCPT}
        selectedMapping={selectedMapping}
      />
    </Modal>
  );
};

export default CPTPayerMappingModal;
