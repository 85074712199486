import React, { useState, useEffect } from 'react';
import { Form } from 'antd';

import useRedirect from '../../../../../../hooks/useRedirect';

import UserInfo from './UserInfo';
import InsuranceInfo from './InsuranceInfo';
import SchedularInfo from './SchedularInfo';

const Patient = ({
  onClose,
  data,
  mode,
  labels,
  form,
  searchText,
  time,
  toggle,
  location,
  doctor,
  testType,
  visitType,
  reFetchData,
  paramsPatient,
  appointmentId,
  toggleInsurance,
  appointmentTime,
  isRepeatingAppointment,
}) => {
  const { patientId } = data || {};

  const { query: { insuranceId: paramsInsuranceId, insuranceDetailId } } = useRedirect();

  const [patientInfo, setPatientInfo] = useState({});
  const [insuranceId, setInsuranceId] = useState(null);
  const [authorizationId, setAuthorizationId] = useState(null);
  const [referredById, setReferredById] = useState(null);
  const [appointmentDetail, setAppointmentDetail] = useState({});
  const [insuranceForm] = Form.useForm();

  useEffect(() => {
    if (paramsInsuranceId && paramsInsuranceId !== 'undefined') {
      setInsuranceId(parseInt(paramsInsuranceId, 10));
    }
  }, []);

  return (
    <>
      {/* eslint-disable-next-line */}
      <div className="dialogue-modal" onClick={onClose} style={{ cursor: mode === 'edit' ? 'default' : 'pointer' }}>
        <p>
          {searchText}
          <span className="close-dialogue sprite-img-before" />
        </p>
      </div>
      <div className="appointment-content-wrap">
        <div className="appointment-rt-wrapper" data-testid="add-appointment-modal">
          <UserInfo
            labels={labels}
            patientInfo={patientInfo}
            setPatientInfo={setPatientInfo}
            data={data}
            paramsPatient={paramsPatient}
            appointmentTime={appointmentTime}
            appointmentId={appointmentId}
            appointmentDetail={appointmentDetail}
            reFetchData={reFetchData}
          />
          <InsuranceInfo
            patientInfo={patientInfo}
            labels={labels}
            patientId={patientId || paramsPatient}
            form={insuranceForm}
            setAuthorizationId={setAuthorizationId}
            setInsuranceId={setInsuranceId}
            setReferredById={setReferredById}
            referredById={referredById}
            insuranceId={insuranceId}
            appointmentDetail={appointmentDetail}
            toggleInsurance={toggleInsurance}
            insuranceDetailId={insuranceDetailId && insuranceDetailId !== 'undefined' ? parseInt(insuranceDetailId, 10) : undefined}
            showDefaultInsurance={!appointmentId}
            doctor={doctor}
          />
        </div>
        <SchedularInfo
          labels={labels}
          mode={mode}
          insuranceForm={insuranceForm}
          form={form}
          insuranceId={insuranceId}
          authorizationId={authorizationId}
          referredBy={referredById}
          patientId={patientId || paramsPatient}
          appointmentStartTime={time}
          toggle={toggle}
          doctor={doctor}
          location={location}
          reFetchData={reFetchData}
          visitType={visitType}
          testType={testType}
          appointmentDetail={appointmentDetail}
          appointmentId={appointmentId}
          setAppointmentDetail={setAppointmentDetail}
          isRepeatingAppointment={isRepeatingAppointment}
        />
      </div>
    </>
  );
};

export default Patient;
