import axios from 'axios';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import moment from 'moment';
import encryptData from '../lib/encryptData';
import Events from '../lib/events';

import { apiUrls, baseUrl, statusCodeForRetry } from './constants';

/**
 * Server address (for api)
 * @private
 * @constant
 */

const PROTOCOL = process.env.SSL ? 'https' : 'http';
const PATH = process.env.API_PATH ? `/${process.env.API_PATH}` : '';
const API = process.env.API ? `${PROTOCOL}://${process.env.API}${PATH}/` : baseUrl;

const API_PATH = API || (PATH ? `${PATH}/` : '');

const serverV2 = axios.create({
  baseURL: API_PATH,
  mode: 'cors',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const urlsNotEligibleForToken = [
  apiUrls.LOGIN,
  apiUrls.GET_MICROSOFT_LOGIN_DETAILS,
  apiUrls.RESET_PASSWORD,
  apiUrls.FORGOT_PASSWORD,
];

export const multipartServerV2 = axios.create({
  baseURL: API_PATH,
  mode: 'cors',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  },
});

let refreshInstance = false;
const getRefreshToken = () => {
  if (refreshInstance) {
    return refreshInstance;
  }

  refreshInstance = fetch(`${process.env.REACT_APP_API_PATH}/${apiUrls.REFRESH_TOKEN}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    xhrFields: { withCredentials: true },
    cache: 'no-cache',
    mode: 'cors',
    body: JSON.stringify({
      AccessToken: localStorage.getDecryptedData('token'),
      RefreshToken: localStorage.getDecryptedData('refreshToken'),
      Offset: encryptData(moment().utcOffset()),
    }),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  })
    .then((data) => data)
    .finally(() => { refreshInstance = false; })
    .catch((err) => {
      console.log('err', err);
      localStorage.clear();
      window.location.reload();
    });
  return refreshInstance;
};
const debouncedRefreshApiFn = debounce(getRefreshToken, 10000, { leading: true, trailing: false });

const isServerDownStatusCode = (statusCode) => statusCodeForRetry.includes(statusCode);

const getDataWhenServerDownAndCallAgain = (err, originalReq, alreadyCallCount,
  resolve, reject) => {
  if (alreadyCallCount === Number(process.env.REACT_APP_NO_OF_CALL_RETRY_ALLOW || 0)) {
    return reject(err);
  }
  return axios(originalReq).then((resp) => resolve(resp)).catch((error) => {
    if (isServerDownStatusCode(error?.response?.status)) {
      return getDataWhenServerDownAndCallAgain(error, originalReq, alreadyCallCount + 1,
        resolve, reject);
    }
    return reject(error);
  });
};

const refreshTokenHandler = async (err, resolve, reject) => {
  const originalReq = err.config;
  if (isServerDownStatusCode(err?.response?.status) && err.config
    && !process.env.TEST_ENV) {
    return getDataWhenServerDownAndCallAgain(err, originalReq, 0, resolve, reject);
  }
  // eslint-disable-next-line no-underscore-dangle
  if (err?.response?.status === 401 && err.config && !err.config.__isRetryRequest
    && !process.env.TEST_ENV) {
    const data = await debouncedRefreshApiFn();
    if (get(data, 'accessToken')) {
      const refreshToken = get(data, 'refreshToken');
      localStorage.setEncryptedData('token', get(data, 'accessToken'));
      localStorage.setEncryptedData('refreshToken', refreshToken);
      const accessTokenExpiration = get(data, 'accessTokenExpiration');
      localStorage.setEncryptedData('accessTokenExpiration', accessTokenExpiration);
      localStorage.setEncryptedData('accessTokenExpirationTime', moment(accessTokenExpiration).diff(moment(), 'minutes'));
      localStorage.setEncryptedData('userLoggedIn', true); // used by user settings reducer
      originalReq.headers.Authorization = `Bearer ${get(data, 'accessToken')}`;
      serverV2.defaults.headers.common.Authorization = `Bearer ${get(data, 'accessToken')}`;
      return axios(originalReq).then((resp) => resolve(resp)).catch((error) => reject(error));
    }
    Events.trigger('logout');
    return true;
  }
  return reject(err);
};

// Here we used this function to check token is present or not
const apiRequestHandler = (request) => {
  const token = localStorage.getDecryptedData('token');
  if (!token && !urlsNotEligibleForToken.includes(request.url)) {
    localStorage.clear();
    window.location.reload();
  }
  return request;
};

serverV2.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err.response && err.response.status === 500) {
      return Promise.resolve(err);
    }
    return new Promise((resolve, reject) => refreshTokenHandler(err, resolve, reject));
  },
);

serverV2.interceptors.request.use((request) => apiRequestHandler(request),
  (error) => Promise.reject(error));

multipartServerV2.interceptors.response.use((response) => response,
  (err) => new Promise((resolve, reject) => refreshTokenHandler(err, resolve, reject)));

export default serverV2;
