import React, { useCallback, useState, useEffect } from 'react';
import { Form as AntdForm, DatePicker as AntdDatePicker } from 'antd';
import moment from 'moment';

import { enums, listIds } from '../../../../../lib/constants';
import { apiUrls } from '../../../../../api/constants';
import useCRUD from '../../../../../hooks/useCRUD';

import Button from '../../../../../components/Button';
import Modals from '../../../../../components/Modal';
import Form from '../../../../../components/Form';
import SelectBox from '../../../../../components/SelectBox';
import DatePicker from '../../../../../components/DatePicker';
import WidgetLoader from '../../../../../components/WidgetLoader';

import EnumSelect from '../../../../../wiredComponents/Enum/Select';
import WiredSelect from '../../../../../wiredComponents/Select';
import ProviderLookup from '../../../../../wiredComponents/LookUp/ProviderLookup';
import Notification from '../../../../../components/Notification';
import SuccessMessages from '../../../../../lib/successMessages';

const { RangePicker } = AntdDatePicker;

const MassRebill = ({
  labels, visible, toggleModal, reFetch,
}) => {
  const [form] = AntdForm.useForm();

  const [payerId, setPayerId] = useState(0);
  const [providerId, setProviderId] = useState(0);

  const [rebill, , rebillLoading, processMassRebill, clearMassRebill] = useCRUD({
    id: listIds.PROCESSED_CLAIMS_FOR_MASS_REBILL,
    url: apiUrls.PROCESS_CLAIMS_FOR_MASS_REBILL,
    type: 'create',
  });

  const onPayerSelect = useCallback((payer) => {
    setPayerId(payer);
  }, []);

  const onProviderSelect = useCallback((data) => {
    setProviderId(data?.item?.providerId);
  }, []);

  const onSubmit = useCallback(() => {
    form.submit();
  }, [form]);

  const onFinish = useCallback(() => {
    const { date: [fromDate, toDate] = [], policyType } = form.getFieldsValue();
    const data = {
      payerId: payerId || null,
      providerId: (providerId && [providerId]) || null,
      insurancePolicyTypeID: (policyType && [policyType]) || null,
      fromDate: moment(fromDate).hours(0).minutes(0).seconds(0)
        .milliseconds(0)
        .format(),
      toDate: moment(toDate).hours(23).minutes(59).seconds(59)
        .milliseconds(0)
        .format(),
    };
    processMassRebill({ data });
  }, [form, payerId, providerId, processMassRebill]);

  useEffect(() => {
    if (rebill) {
      Notification({ message: SuccessMessages.MASS_REBILL_SUCCESSFULLY, success: true });
      clearMassRebill();
      toggleModal();
      reFetch();
    }
  }, [rebill]);

  return (
    <Modals
      visible={visible}
      toggleModal={toggleModal}
      className="create-new-batch"
      title="Mass Rebill"
      width={800}
      footer={[
        <div className="group-btns mng-footer-btn" key="footer">
          <Button className="btn min-wt-86 sm-btn" onClick={toggleModal}>
            {labels.get('buttons.cancel')}
          </Button>
          <Button className="btn btn-success min-wt-86 sm-btn" data-testid="save-btn" onClick={onSubmit}>
            {labels.get('buttons.confirm')}
          </Button>
        </div>,
      ]}
      destroyOnClose
    >
      {rebillLoading && <WidgetLoader />}
      <Form form={form} onFinish={onFinish}>
        <div className="input-wrap">

          <WiredSelect
            id="payer"
            name="payer"
            label="Payer"
            labelSpan="7"
            inputSpan="17"
            url={apiUrls.CONTRACT_PAYER_DROPDOWN}
            nameAccessor="payerName"
            valueAccessor="payerId"
            startCaseOptions
            Component={SelectBox}
            selectProps={{
              showSearch: true,
              dropdownMatchSelectWidth: 350,
              maxTagCount: 'responsive',
              mode: 'multiple',
              'data-testId': 'payer',
            }}
            onChange={(val) => onPayerSelect(val)}
            placeholder="Select Payer"
          />
        </div>
        <DatePicker
          name="date"
          label={labels.get('labels.date')}
          component={RangePicker}
          labelSpan="7"
          inputSpan="17"
          testId="date"
          required
        />
        <EnumSelect
          name="policyType"
          label={labels.get('labels.policyType')}
          enumName={enums.POLICY_TYPE}
          labelSpan="7"
          inputSpan="17"
          dataTestId="policyType"
          selectProps={{
            style: { minWidth: 120 },
            showArrow: true,
          }}
        />
        <ProviderLookup
          name="provider"
          label={labels.get('labels.provider')}
          isTableRequired={false}
          placeholder={labels.get('placeholders.provider')}
          labelSpan="7"
          inputSpan="17"
          onSelect={onProviderSelect}
        />
      </Form>
    </Modals>
  );
};

export default MassRebill;
