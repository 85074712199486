/* eslint-disable camelcase */
import React, { useCallback, useEffect, useMemo } from 'react';
import { Form } from 'antd';

import Table from '../../components/Table';
import Button from '../../components/Button';
import Input from '../../components/Form/Input';
import { apiUrls } from '../../api/constants';
import useCRUD from '../../hooks/useCRUD';
import { formatPhoneNumber, getName, getString } from '../../lib/util';

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    flex: 3,
    maxWidth: 'auto',
  },
  {
    Header: 'Specialty/NPI',
    accessor: ({ npi, speciality }, isTitle) => {
      if (isTitle) {
        if (npi && speciality) {
          return `${speciality}/${npi}`;
        }
        return npi || speciality || '';
      }
      if (npi && speciality) {
        return (
          <>
            <span>{speciality}</span>
            <br />
            <span>{npi}</span>
          </>
        );
      }
      return npi || speciality || '';
    },
    flex: 3,
    maxWidth: 'auto',
  },
  {
    Header: 'Phone/Fax',
    accessor: ({ phone, fax }, isTitle) => {
      if (isTitle) {
        if (phone && fax) {
          return `${formatPhoneNumber(phone)}/${fax}`;
        }
        return formatPhoneNumber(phone) || fax || '';
      }
      if (phone && fax) {
        return (
          <>
            <span>{formatPhoneNumber(phone)}</span>
            <br />
            <span>{fax}</span>
          </>
        );
      }
      return formatPhoneNumber(phone) || fax || '';
    },
    flex: 2,
    maxWidth: 'auto',
  },
  {
    Header: 'Address',
    accessor: 'address',
    flex: 5,
    maxWidth: 'auto',
  },
];

const AdvanceSearch = ({
  onPhysicianSelect,
  toggleModal,
  parentForm,
  name,
}) => {
  const [form] = Form.useForm();

  const [
    initialReferringPhysician,,
    loading,
    getInitialReferringPhysician,
    clear,
  ] = useCRUD({
    id: 'referring-physician-advance-search',
    url: apiUrls.GET_REFERRING_PHYSICIAN,
    type: 'read',
  });

  const onSearch = useCallback(() => {
    getInitialReferringPhysician({
      ...form.getFieldValue(),
      IsAdvanceSearch: true,
    });
  }, [form, getInitialReferringPhysician]);

  const parsedData = useMemo(() => initialReferringPhysician?.map((option) => ({
    ...option,
    address: option?.addresses?.map((item) => getString(
      [item?.address_1,
      item?.address_2,
      item?.city,
      item?.state,
      item?.postal_code],
    )).join('\n'),
    name: option.name || getName(option.firstName, option.middleName, option.lastName),
    phone: option?.addresses?.map((item) => item?.telephone_number).join('\n'),
    fax: option?.addresses?.map((item) => item?.fax_number).join('\n'),
    speciality: option.speciality,
    npi: option.npi || option.value,
    value: option.npi || option.value,
  })), [initialReferringPhysician]);

  useEffect(() => () => clear(true), []);

  const onRowClick = useCallback(({ original }) => {
    parentForm.setFields([{ name, value: original.npi || original.value }]);
    onPhysicianSelect(original);
    toggleModal();
  }, [name, onPhysicianSelect, parentForm, toggleModal]);

  return (
    <div className="referring-provider-advance-search">
      <Form form={form}>
        <div className="addition-header">
          <div className="lookup sprite-img-before">
            <p className="">Advance Search</p>
          </div>
        </div>
        <Input label="NPI Number" name="NPI" />
        <Input label="First Name" name="FirstName" />
        <Input label="Last Name" name="LastName" />
        <Input label="City" name="City" />
        <Input label="State" name="State" />
        <Input label="Postal Code" name="PostalCode" />
      </Form>
      <div className="flex justify-content-flex-end mr-bt-12 mr-top-12">
        <Button className="btn btn-success sm-btn" onClick={onSearch}>Search</Button>
      </div>
      <Table
        columns={columns}
        loading={loading}
        data={parsedData || []}
        scrollId="referring-provider-advance-search"
        onRowClick={onRowClick}
      />
    </div>
  );
};

export default AdvanceSearch;
