import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import { getUTCDate } from '../../../../../../lib/util';

const { Panel } = Collapse;

const TableWrapper = ({ Component, data, reFetch }) => {
  const [heading, setHeading] = useState(false);
  useEffect(() => {
    if (data?.length) {
      setHeading(`Activity Logs (Previously ${data?.[0]?.actionPerformed || ''} by ${data?.[0]?.actionPerformedBy || ''} at ${getUTCDate(data?.[0]?.actionPerformedDate?.dateString, 'MM-DD-YYYY HH:mm:ss')})`);
    }
  }, [data]);
  useEffect(() => {
    reFetch();
  }, []);
  return (
    <Collapse accordion>
      <Panel
        className="main-acc-header"
        header={heading || 'Activity Logs'}
        key="1"
      >
        {Component}
      </Panel>
    </Collapse>
  );
};

export default TableWrapper;
