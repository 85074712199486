import React, { useCallback, useEffect, useState } from 'react';

import { apiUrls } from '../../../../../api/constants';
import useRedirect from '../../../../../hooks/useRedirect';
import { enums, formId, UiRoutes } from '../../../../../lib/constants';
import SuccessMessages from '../../../../../lib/successMessages';

import Form from '../../../../../components/Form';
import Notification from '../../../../../components/Notification';
import { Input, Radio, CheckBox } from '../../../../../components/Form/inputFields';

import EnumSelect from '../../../../../wiredComponents/Enum/Select';
import { updateImageSrc } from '../../../../../lib/util';

const orientationOptions = [{ label: 'Portrait', value: 'portrait' }, { label: 'Landscape', value: 'landScape' }];

const SettingsForm = ({
  labels, form, getHTMLContent, templateData, isUpdate, toggleModal,
}) => {
  const [contentData, setContentData] = useState({});
  const [selectedCategory, setSelectedCategory] = useState();

  const {
    params: { templateType, templateId }, params, replace, generatePath,
  } = useRedirect();

  useEffect(() => {
    if (getHTMLContent) {
      setContentData(getHTMLContent);
    }
  }, []);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({
        message: isUpdate ? SuccessMessages.UPDATE_TEMPLATE : SuccessMessages.SAVE_TEMPLATE,
        success: true,
      });
      if (!isUpdate) {
        replace(generatePath(UiRoutes.editComposerTemplate, {
          ...params,
          templateId: response.composerTemplateId,
        }));
      }
      form.resetFields();
      toggleModal()();
    }
  }, [isUpdate, form, toggleModal, replace, generatePath, params]);

  const handleCategorySelect = useCallback((value, data) => {
    if (data?.children) {
      setSelectedCategory(data?.children);
    }
  }, []);

  const generateExtraData = () => ({
    ...contentData,
    // eslint-disable-next-line no-template-curly-in-string
    headerText: updateImageSrc(contentData?.headerText, 'acuityEyeLogo', '{{acuityEyeLogo}}', null, true),
    category: selectedCategory,
    templateType: '',
    isActive: true,
    method: isUpdate ? 'put' : 'post',
    templateName: templateType,
  });

  return (
    <Form
      form={form}
      formId={formId.UPDATE_COMPOSER_TEMPLATE}
      url={isUpdate ? `${apiUrls.SAVE_TEMPLATE}/${templateId}` : apiUrls.SAVE_TEMPLATE}
      getUrl={`${apiUrls.SAVE_TEMPLATE}/${templateId}`}
      isUpdate={isUpdate}
      onRequestComplete={onRequestComplete}
      extraData={generateExtraData}
      initialData={{
        documentOrientation: 'portrait',
        ...templateData,
      }}
    >
      <Form.Column>
        <div>
          <Input
            name="templateName"
            labelSpan="9"
            inputSpan="15"
            label={labels.get('labels.name')}
            required
          />
          <EnumSelect
            name="categoryId"
            enumName={enums.COMPOSER_TEMPLATE_CATEGORY}
            labelSpan="9"
            inputSpan="15"
            required
            label={labels.get('labels.category')}
            selectProps={{
              onSelect: handleCategorySelect,
            }}
          />
          <CheckBox
            name="isRequireProviderSignOff"
            label={labels.get('labels.requireProviderSign')}
            labelSpan="9"
            inputSpan="15"
            valuePropName="checked"
          />
          <Radio
            name="documentOrientation"
            labelSpan="9"
            inputSpan="15"
            label={labels.get('labels.Orientation')}
            options={orientationOptions}
            required
          />
        </div>
      </Form.Column>
      <Form.Column>
        <Input
          name="marginTop"
          labelSpan="9"
          inputSpan="15"
          label={labels.get('labels.marginTop')}
          numberOnly
          required
        />
        <Input
          name="marginBottom"
          labelSpan="9"
          inputSpan="15"
          label={labels.get('labels.marginBottom')}
          numberOnly
          required
        />
        <Input
          name="marginLeft"
          labelSpan="9"
          inputSpan="15"
          label={labels.get('labels.marginLeft')}
          numberOnly
          required
        />
        <Input
          name="marginRight"
          labelSpan="9"
          inputSpan="15"
          label={labels.get('labels.marginRight')}
          numberOnly
          required
        />
      </Form.Column>
    </Form>
  );
};

export default SettingsForm;
