import React, {
  useState, useCallback, useEffect, useMemo, useRef,
} from 'react';
import { InputNumber, Affix } from 'antd';

import isEmpty from 'lodash/isEmpty';
import WithLabel from '../../../../hoc/withLabel';
import useRedirect from '../../../../hooks/useRedirect';
import useCRUD from '../../../../hooks/useCRUD';

import { apiUrls } from '../../../../api/constants';

import Events from '../../../../lib/events';
import { labelPaths, UiRoutes, listIds } from '../../../../lib/constants';
import { checkFileUploadStatus } from '../../../../lib/util';

import useReduxState from '../../../../hooks/useReduxState';
import UploadFilePreview from '../../../../wiredComponents/UploadFilePreview';

import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import SavedNavigationBar from '../../../../components/SavedNavigationBar';

import BatchDetails from './Component/BatchDetails';

import Icon from '../../../../components/Icon';
import iconNames from '../../../../lib/iconNames';

import './remittancedetail.scss';

const Remittance = ({ labels }) => {
  const {
    params, push, generatePath,
  } = useRedirect();
  const { tabId, remittanceId } = params;

  const [layoutOptions, setLayoutOptions] = useReduxState('Remittance-Layout-Options', false);
  const [currentPageNoFromRedux, setCurrentPageNo] = useReduxState('Remittance-Current-Page-No');
  const [totalNoOfPages, setTotalNoOfPages] = useReduxState('Remittance-Total-Number-of-Pages', 0);
  let currentPageNo;
  const [isUpload, setIsUpload] = useState(false);
  const [documentData, setDocumentName] = useReduxState('remittance-batch-pdf', {});
  const inputNumberRef = useRef();
  const refreshPdf = useRef(true);
  if (!documentData?.documentUrl) {
    currentPageNo = 0;
  } else {
    currentPageNo = currentPageNoFromRedux || 1;
  }

  const onDocumentLoad = useCallback(({ numPages }) => {
    setTotalNoOfPages(numPages);
    setTimeout(() => {
      Events.trigger('PDF_VIEWER', { pageNo: currentPageNo });
    }, 500);
  }, [currentPageNo, setTotalNoOfPages]);

  const onClickGoBack = useCallback(() => {
    if (params?.claimFilter === 'worklist') {
      push(generatePath(UiRoutes.remittanceClaimsWorkList, { ...params, claimFilter: 'worklist', parentTab: 'suspense' }));
    } else {
      push(generatePath(UiRoutes.remittanceClaimsFilters, { ...params }));
    }
  }, [generatePath, params, push]);

  const [batchData, , batchLoading, getBatchData, clearBatchData] = useCRUD({
    id: `${listIds.REMITTANCE_BATCH_DETAILS}_${tabId}`,
    url: apiUrls.GET_REMITTANCE_BATCH_DETAILS,
    type: 'read',
  });

  useEffect(() => {
    if (remittanceId) {
      getBatchData({}, `/${remittanceId}`);
    }
  }, [remittanceId, tabId]);

  useEffect(() => {
    Events.on('refetchBatchDetails', 'refetchBatchDetails', ({ refetchPdf = false }) => {
      refreshPdf.current = refetchPdf;
      getBatchData({}, `/${remittanceId}`);
    });
    return () => {
      Events.remove('refetchBatchDetails', 'refetchBatchDetails');
      clearBatchData(true);
    };
  }, [remittanceId, tabId]);

  const isElectronicBatch = useMemo(() => (
    batchData?.remittanceBatchData?.batchType?.toLowerCase() === 'electronic'
  ), [batchData]);

  const shouldShowDocument = useMemo(() => (
    checkFileUploadStatus(batchData?.remittanceBatchData?.attachmentFlag)
  ), [batchData]);

  if (shouldShowDocument !== true) {
    setTotalNoOfPages(0);
  }

  useEffect(() => {
    if (!isEmpty(batchData)) {
      const isBatchElectronic = batchData?.remittanceBatchData?.batchType?.toLowerCase() === 'electronic';
      const isRefetchDocument = !isBatchElectronic ? documentData?.documentUrl
      !== batchData?.remittanceBatchData?.documentUrl : documentData?.documentUrl
      !== batchData?.remittanceBatchData?.ediPdfDocumentUrl;
      if (isRefetchDocument && refreshPdf.current) {
        setDocumentName({
          documentDisplayName: isBatchElectronic
            ? batchData?.remittanceBatchData?.ediPdfDocumentDisplayName
            : batchData?.remittanceBatchData?.documentDisplayName,
          documentUploadedName: isBatchElectronic
            ? batchData?.remittanceBatchData?.ediPdfDocumentUploadedName
            : batchData?.remittanceBatchData?.documentUploadedName,
          documentUrl: isBatchElectronic
            ? batchData?.remittanceBatchData?.ediPdfDocumentUrl
            : batchData?.remittanceBatchData?.documentUrl,
        });
      }
      refreshPdf.current = true;
    }
  }, [batchData]);

  const onPageChange = useCallback((pageNo) => {
    setCurrentPageNo(pageNo);
    Events.trigger('PDF_VIEWER', { pageNo });
    Events.trigger('insurance-claim-page-number', { pageNo });
    Events.trigger('patient-claim-page-number', { pageNo });
  }, [setCurrentPageNo]);

  const onPageScroll = useCallback((props) => {
    let pageNo = 1;
    let shouldTriggerClaimPage = true;
    if (typeof props === 'object') {
      pageNo = props?.pageNo;
      shouldTriggerClaimPage = props?.shouldTriggerClaimPage;
    } else {
      pageNo = props;
    }
    inputNumberRef?.current?.blur();
    if (pageNo !== currentPageNo) {
      if (shouldTriggerClaimPage) {
        Events.trigger('insurance-claim-page-number', { pageNo });
        Events.trigger('patient-claim-page-number', { pageNo });
      }
      setCurrentPageNo(pageNo);
    }
  }, [currentPageNo, setCurrentPageNo]);

  const toggleUploadBatchModal = useCallback(() => {
    setIsUpload(!isUpload);
  }, [isUpload]);

  const nextPage = useCallback(() => {
    onPageChange(Math.min(currentPageNo + 1, totalNoOfPages));
  }, [onPageChange, currentPageNo, totalNoOfPages]);

  const previousPage = useCallback(() => {
    onPageChange(Math.max(currentPageNo - 1, 1));
  }, [onPageChange, currentPageNo]);

  useEffect(() => {
    if (inputNumberRef?.current) {
      Events.on('PDF_VIEWER_PAGE_NO', tabId, onPageScroll);
    }
    return () => Events.remove('PDF_VIEWER_PAGE_NO');
  }, [tabId, inputNumberRef]);

  const expendRemittence = useCallback(() => {
    setLayoutOptions(!layoutOptions);
  }, [layoutOptions, setLayoutOptions]);

  const handleRefetchData = useCallback(() => {
    getBatchData({}, `/${remittanceId}`);
  }, [getBatchData, remittanceId]);

  return (
    <SavedNavigationBar
      listName="Claims List"
      parentPath={UiRoutes.claims}
    >
      {({ closeTab }) => (
        <div>
          <h2 className="back-arrow remittance-back-arrow" onClick={onClickGoBack} role="presentation">
            {labels.get('titles.remittance')}
          </h2>
          {batchLoading && <Loader />}
          <div className={`remittance-collection ${layoutOptions ? 'open' : 'close'}`}>
            <div className="left-area">
              <Affix>
                <div className="pannel remittance-panel">
                  <div className="pannel-heading">
                    <div className="remittance-head">
                      <Button className="lt-arrow sm-btn" onClick={previousPage} />
                      <div className="input-range-wrap">
                        <InputNumber
                          ref={inputNumberRef}
                          min={1}
                          max={totalNoOfPages}
                          step={-1}
                          value={currentPageNo}
                          onChange={onPageChange}
                        />
                        <span className="input-upto-range">{`/ ${totalNoOfPages}`}</span>
                      </div>
                      <Button className="rt-arrow sm-btn" onClick={nextPage} />
                      <Button
                        className="mr-bt-8 btn-success sm-btn refresh-btn"
                        onClick={handleRefetchData}
                      >
                        <Icon
                          name={iconNames.undo}
                          className="cursor-pointer"
                        />
                      </Button>
                      {!isElectronicBatch && (
                      <Button
                        onClick={toggleUploadBatchModal}
                        className="mr-bt-8 btn-success sm-btn upload-file-btn"
                      >
                        <Icon
                          name={iconNames.upload}
                          className="cursor-pointer"
                        />
                      </Button>
                      )}
                    </div>
                  </div>
                  <div className="panel-body">
                    <UploadFilePreview
                      collapsableView
                      showRotation
                      key={tabId}
                      onDocumentLoad={onDocumentLoad}
                      fileUrl={documentData?.documentUrl}
                      initialScale={0.70}
                      shouldShowDocument={shouldShowDocument}
                      isRemittanceDocument
                    />
                  </div>
                </div>
              </Affix>
            </div>
            {batchData && (
            <BatchDetails
              key={tabId}
              labels={labels}
              batchDetails={batchData}
              setCurrentPageNo={setCurrentPageNo}
              currentPageNo={currentPageNo}
              remittanceId={remittanceId}
              toggleUploadBatchModal={toggleUploadBatchModal}
              isUpload={isUpload}
              totalNoOfPages={totalNoOfPages}
              expendRemittence={expendRemittence}
              closeTab={closeTab}
              isElectronicBatch={isElectronicBatch}
            />
            )}
          </div>
        </div>
      )}
    </SavedNavigationBar>
  );
};
export default WithLabel(Remittance, labelPaths.REMITTANCE_BATCH_DETAILS);
