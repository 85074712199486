import React from 'react';
import TimeCell from '../../../../../../components/Cells/TimeCell';

const columns = (labels) => [
  {
    Header: labels.get('tableColumns.statementDate'),
    accessor: 'submittedDate',
    Cell: ({ row: { original } }) => (
      <TimeCell value={original?.submittedDate} />
    ),
    sort: true,
  },
  {
    Header: labels.get('tableColumns.status'),
    accessor: 'statusName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.method'),
    accessor: 'methodName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.amount'),
    accessor: 'currentAmount',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.sentToWaystar'),
    accessor: 'sentToWaystar',
    Cell: ({ row: { original: { sentToWaystar } } }) => (sentToWaystar
      ? <span>Sent to Waystar</span> : <span>Not Sent to Waystar</span>),
  },
];

export default columns;
