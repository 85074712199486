import React from 'react';
import TabsComponent from '../../../../components/Tabs';

import General from './General';
import MapInsurance from '../Components/MapInsurance';

const tabsList = [
  {
    id: 'general',
    name: 'General',
    component: General,
  },
  {
    id: 'mapInsurance',
    name: 'Map Insurance',
    component: MapInsurance,
  },
];

const EditPayerTabs = ({
  form, labels, navigateBack, isPayerRequestViewOnly, ...props
}) => (
  <div className="main-content-area">
    <div className="heading-area flex justify-content-sp-bt align-center">
      <h1>{labels.get('titles.editPayer')}</h1>
    </div>
    <div className="main-form-container">
      <TabsComponent
        tabsList={tabsList}
        id="edit-payer"
        className="main-tab-wrap"
        form={form}
        labels={labels}
        navigateBack={navigateBack}
        isPayerRequestViewOnly={isPayerRequestViewOnly}
        {...props}
      />
    </div>
  </div>
);
export default EditPayerTabs;
