import React, { useMemo } from 'react';

import { apiUrls } from '../../api/constants';
import { listIds, labelPaths } from '../../lib/constants';

import withQuery from '../../hoc/withQuery/withQuery';
import withReduxManager from '../../hoc/withReduxManager';

import columns from './columns';
import useRedirect from '../../hooks/useRedirect';
import WithLabel from '../../hoc/withLabel';

import './AppointmentTable.scss';

const initialSort = [{ id: 'appointmentStartTime', desc: true }];

const AppointmentTable = ({
  scrollId, appointmentColumns, labels, heading,
}) => {
  const { params } = useRedirect();
  const { id: patientId } = params;

  const CustomTable = useMemo(
    () => withReduxManager({
      listId: `${listIds.PATIENTS_LIST_APPOINTMENTS}_${patientId}`,
    })(
      withQuery({
        url: `${apiUrls.GET_PATIENT_LIST_APPOINTMENTS}/${patientId}`,
        listId: `${listIds.PATIENTS_LIST_APPOINTMENTS}_${patientId}`,
        alias: {
          time: 'appointmentStartTime',
        },
      })(),
    ),
    [patientId],
  );

  return (
    <div className="appointment-table">
      <CustomTable
        columns={appointmentColumns || columns(labels)}
        initialSort={initialSort}
        scrollId={scrollId}
      >
        {({ Component, totalCount }) => (
          <>
            <div className="table-filters">
              {heading ? (
                <span className="table-heading">
                  {`${labels.get(
                    'labels.lastAndNextAppointment',
                  )}`}
                </span>
              ) : <span />}
              <span className="table-count">
                <span>
                  {`${labels.get(
                    'labels.totalCount',
                  )}: ${totalCount}`}
                </span>
              </span>
            </div>
            {Component}
          </>
        )}
      </CustomTable>
    </div>
  );
};

export default WithLabel(AppointmentTable, labelPaths.PATIENT_LISTS);
