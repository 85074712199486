import React, { useCallback, useEffect, useState } from 'react';
import { Menu } from 'antd';

import { apiUrls } from '../../../../../../api/constants';
import withQuery from '../../../../../../hoc/withQuery/withQuery';
import withReduxManager from '../../../../../../hoc/withReduxManager';
import useRedirect from '../../../../../../hooks/useRedirect';
import useCRUD from '../../../../../../hooks/useCRUD';

import Notification from '../../../../../../components/Notification';
import Button from '../../../../../../components/Button';
import Loader from '../../../../../../components/Loader';
import ConfirmDialog from '../../../../../../components/ConfirmDialog';

import {
  enumMasterCode, enums, listId, responseType,
} from '../../../../../../lib/constants';
import SuccessMessages from '../../../../../../lib/successMessages';
import Events from '../../../../../../lib/events';

import columns from './columns';
import Icon from '../../../../../../components/Icon';
import useMasterId from '../../../../../../hooks/useMasterId';

const TableWrapper = ({
  Component, setReFetch, reFetch,
}) => {
  useEffect(() => {
    if (reFetch) {
      setReFetch(() => reFetch);
    }
  }, []);

  return Component;
};

const CustomPatientBillTable = withReduxManager({
  listId: listId.CLAIMS,
})(withQuery({
  url: apiUrls.GET_CLAIMS_LIST,
  listId: listId.CLAIMS,
})());

const masterCodes = [
  enumMasterCode.deliveryMethodPaper,
];

const enumNames = [
  enums.DELIVERY_METHOD,
];

const BillTable = ({ labels }) => {
  const {
    params: { id: patientId, tabId },
  } = useRedirect();
  const [reFetchClaims, setReFetchClaims] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const masterCodesWithId = useMasterId(masterCodes, enumNames);

  const [addToFaxQueueResponse, , addToFaxLoading, addToFax, clearFaxResponse] = useCRUD({
    id: listId.ADD_FAX_QUEUE_SHOPPING_CART,
    url: apiUrls.ADD_FAX_QUEUE_SHOPPING_CART,
    type: 'create',
  });
  const [
    claimPDFResponse, , ,
    getClaimPDF,
    clearClaimPDF,
  ] = useCRUD({
    id: apiUrls.DOWNLOAD_CLAIM_PROOF_OF_FILING,
    url: apiUrls.DOWNLOAD_CLAIM_PROOF_OF_FILING,
    type: 'read',
  });

  const [
    cmr1500Response, , ,
    getCMR1500Form,
    clear,
  ] = useCRUD({
    id: apiUrls.GET_CLAIMS_PDF,
    url: apiUrls.GET_CLAIMS_PDF,
    type: 'create',
  });

  const [
    claimPDFWithoutFormResponse, , ,
    getClaimPDFWithoutForm,
    clearClaimPDFWithoutForm,
  ] = useCRUD({
    id: apiUrls.GET_CLAIMS_PDF_WITHOUT_FORM,
    url: apiUrls.GET_CLAIMS_PDF_WITHOUT_FORM,
    type: 'read',
  });

  const [
    submitClaimsResponse, ,
    submitLoading,
    submitClaims,
    clearSubmitClaims,
  ] = useCRUD({
    id: apiUrls.SUBMIT_CLAIM,
    url: apiUrls.SUBMIT_CLAIM,
    type: 'update',
  });

  const [
    changeDeliveryMethodResponse,,
    changeMethodLoading,
    changeDeliveryMethod,
    clearChangeDeliveryMethod,
  ] = useCRUD({
    id: apiUrls.CHANGE_CLAIMS_DELIVERY_METHOD,
    url: apiUrls.CHANGE_CLAIMS_DELIVERY_METHOD,
    type: 'update',
  });

  // To maintain set state in tab change
  useEffect(() => {
    setSelectedRows([]);
  }, [tabId]);

  useEffect(() => {
    if (changeDeliveryMethodResponse && !Array.isArray(changeDeliveryMethodResponse)) {
      reFetchClaims();
      clearChangeDeliveryMethod();
    }
  }, [changeDeliveryMethodResponse, clearChangeDeliveryMethod]);

  useEffect(() => {
    if (submitClaimsResponse) {
      Notification({ message: SuccessMessages.SUBMIT_CLAIM_SUCCESSFULLY, success: true });
      reFetchClaims();
      clearSubmitClaims();
    }
  }, [clearSubmitClaims, submitClaimsResponse]);

  useEffect(() => {
    if (claimPDFWithoutFormResponse && !Array.isArray(claimPDFWithoutFormResponse)) {
      window.open(claimPDFWithoutFormResponse?.pdfUrl);
      clearClaimPDFWithoutForm(true);
    }
  }, [claimPDFWithoutFormResponse, clearClaimPDFWithoutForm]);

  useEffect(() => {
    if (cmr1500Response && !Array.isArray(cmr1500Response)) {
      window.open(cmr1500Response?.pdfUrl);
      clear();
    }
  }, [cmr1500Response, clear]);

  const handleCMR1500Preview = useCallback((original) => () => {
    getCMR1500Form({ data: { claimIds: original?.claimId, isClaimCorrected: false } });
  }, [getCMR1500Form]);

  const handleViewPreviewWithoutForm = useCallback((original) => () => {
    getClaimPDFWithoutForm({ claimIds: original?.claimId });
  }, [getClaimPDFWithoutForm]);

  const handleViewPreview = useCallback((original) => () => {
    getClaimPDF({ responseType: responseType.BLOB }, `/${original?.claimId}`);
  }, [getClaimPDF]);

  useEffect(() => {
    if (claimPDFResponse && !Array.isArray(claimPDFResponse)) {
      const file = new Blob([claimPDFResponse], { type: 'application/pdf' });
      // Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      // Open the URL on new Window
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
      clearClaimPDF(true);
    }
  }, [claimPDFResponse, clearClaimPDF]);

  const handleSubmitClaims = useCallback((original) => () => {
    submitClaims({ claimIds: original?.claimId });
  }, [submitClaims]);

  const handleChangeDeliveryMethod = useCallback((original) => () => {
    changeDeliveryMethod({}, `/${original?.claimId}/UpdateDeliveryMethod/${masterCodesWithId?.Paper}`);
  }, [changeDeliveryMethod, masterCodesWithId]);

  const rightClickMenu = useCallback(({ original }) => () => (
    <Menu className="profile-menu-container">
      <Menu.Item key="Edit Patient" onClick={handleCMR1500Preview(original)}>
        <span>Print with form</span>
      </Menu.Item>
      <Menu.Item key="view preview" onClick={handleViewPreviewWithoutForm(original)}>
        <span>Print without form</span>
      </Menu.Item>
      <Menu.Item key="view preview" onClick={handleViewPreview(original)}>
        <span>Proof of Filing</span>
      </Menu.Item>
      {original?.deliveryMethodCode?.toLowerCase()?.includes('electronic') && (
        <Menu.Item key="Change delivery method" onClick={handleChangeDeliveryMethod(original)}>
          <span>Change delivery method</span>
        </Menu.Item>
        )}
      {(original?.deliveryMethodCode?.toLowerCase()?.includes('paper')) && (
      <Menu.Item key="Mark as submitted" onClick={handleSubmitClaims(original)}>
        <span>Mark as submitted</span>
      </Menu.Item>
      )}
    </Menu>
  ), [handleCMR1500Preview, handleChangeDeliveryMethod,
    handleSubmitClaims, handleViewPreview, handleViewPreviewWithoutForm]);

  useEffect(() => {
    if (addToFaxQueueResponse?.isDuplicateExists) {
      ConfirmDialog({
        onOk: (close) => {
          addToFax({
            data: {
              FaxDocumentIds: selectedRows?.map(({ original }) => original?.claimId),
              faxSource: 'claim',
              patientId,
              includeDuplicate: true,
            },
          });
          close();
        },
        onCancel: (close) => {
          addToFax({
            data: {
              FaxDocumentIds: selectedRows?.map(({ original }) => original?.claimId),
              faxSource: 'claim',
              patientId,
              includeDuplicate: false,
            },
          });
          close();
        },
        okText: labels.get('buttons.ok'),
        title: labels.get('titles.warning'),
        content: labels.get('message.warningMessage'),
        icon: <Icon name="ExclamationCircleOutlined" />,
        cancelButtonProps: { className: 'ant-modal-continue-green-btn' },
        okButtonProps: { className: 'ant-modal-ok-white-btn' },
      })();
    } else if (addToFaxQueueResponse?.recordInserted > 0) {
      Notification({ message: SuccessMessages.DOCUMENT_ADDED_TO_FAX_SUCCESSFULLY, success: true });
      Events.trigger('get-patient-fax-document-count');
      clearFaxResponse(true);
    }
  }, [addToFaxQueueResponse]);

  const handleAddToFaxQueue = useCallback(() => {
    const claimIds = selectedRows?.map(({ original }) => original?.claimId);
    addToFax({
      data: {
        FaxDocumentIds: claimIds,
        faxSource: 'claim',
        patientId,
      },
    });
  }, [addToFax, patientId, selectedRows]);

  return (
    <>
      {(submitLoading || changeMethodLoading || addToFaxLoading) && <Loader />}
      <CustomPatientBillTable
        columns={columns(labels)}
        filters={{
          patientId,
          isPayerResponsibility: true,
        }}
        noDataText="Claims not found"
        rightClickMenu={rightClickMenu}
        initialSort={[{ id: 'claimDate', desc: true }]}
        showRowSelection
        setSelectedRow={setSelectedRows}
        checkedRowIds={selectedRows}
        scrollId="bills-claims-table"
      >
        {({ Component, reFetch, totalCount }) => (
          <div>
            <div className="inline-right">
              {selectedRows?.length ? (
                <div>
                  <Button className="sm-btn" onClick={handleAddToFaxQueue}>{labels.get('buttons.addToFax')}</Button>
                </div>
              ) : null}
              <span className="total-count-wrapper mr-bt-8">
                {`Total Count: ${totalCount}`}
              </span>
            </div>
            <TableWrapper
              Component={Component}
              reFetch={reFetch}
              setReFetch={setReFetchClaims}
            />
          </div>
        )}
      </CustomPatientBillTable>
    </>
  );
};

export default BillTable;
