/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import moment from 'moment';
import { Form as AntdForm } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import omit from 'lodash/omit';

import useCRUD from '../../../../../../hooks/useCRUD';
import useRedirect from '../../../../../../hooks/useRedirect';

import { apiUrls } from '../../../../../../api/constants';
import { disableDates } from '../../../../../../lib/util';

import { Input, Radio, DatePicker } from '../../../../../../components/Form/inputFields';
import Search from '../../../../../../components/Search';
import Modals from '../../../../../../components/Modal';
import Button from '../../../../../../components/Button';
import Form from '../../../../../../components/Form';
import WiredSelectWithoutTab from '../../../../../../wiredComponents/Select/selectBoxV2';
import useReduxState from '../../../../../../hooks/useReduxState';
import Notification from '../../../../../../components/Notification';
import ConfirmDialog from '../../../../../../components/ConfirmDialog';
import Icon from '../../../../../../components/Icon';
import WidgetLoader from '../../../../../../components/WidgetLoader';
import Table from '../../../../../../components/Table';

import columns from './Table/columns';
import providerColumns from './Table/providerColumns';
import providerLocationColumns from './Table/providerLocationColumns';

import './mappinghtml.scss';
import ErrorMessages from '../../../../../../lib/errorMessages';

const defaultLocationOptions = [{
  label: 'All Locations',
  name: 'allLocations',
  value: true,
}, {
  label: 'Specific Locations',
  name: 'specificLocations',
  value: false,
}];

const defaultProviderOptions = [{
  label: 'All Providers',
  name: 'allProviders',
  value: true,
}, {
  label: 'Specific Providers',
  name: 'specificProviders',
  value: false,
}];

const handleOptionsRender = (options) => {
  options?.unshift({
    locationId: 1000,
    locationName: 'Select All Locations',
    isDeleted: false,
    name: 'Select All Locations',
    sortName: 'Select All Locations',
    value: 1000,
  });
  return options;
};

const MappingHtml = () => {
  const { params } = useRedirect();
  const { id } = params;
  const [providerValue, setProviderValue] = useReduxState('provider-value');
  const [locationValue, setLocationValue] = useReduxState('location-value');
  const [addLocationModal, setAddLocationModal] = useState(false);
  const [addLocationModal2, setAddLocationModal2] = useState(false);
  const [locations, setLocations] = useReduxState('locations', '');
  const [initialEditData, setInitialEditData] = useReduxState('initial-edit', null);
  const [dataChanged, setDataChanged] = useReduxState('data-changed', { provider: false, location: false });
  const [emptySelect, setEmptySelect] = useReduxState('empty-select', null);
  const [clearTable, setClearTable] = useReduxState('clear-table', true);
  const [updatedData, setUpdatedData] = useReduxState('updated-data', []);
  const [refetchPayload, setRefetchPayload] = useReduxState('refetch-payload', true);
  const [form] = AntdForm.useForm();
  const [editForm] = AntdForm.useForm();
  const [addForm] = AntdForm.useForm();
  const [selectLocationForm] = AntdForm.useForm();

  const [contractResponse, , getContractLoading, getContractLocation] = useCRUD({
    id: 'contract-provider-location', url: `${apiUrls.GET_CONTRACT}/${id}`, type: 'read',
  });

  const [contractProviderResponse, ,
    updateContractLoading,
    updateContractProvider,
    clearUpdateResponse] = useCRUD({
    id: 'create-contract-provider', url: `${apiUrls.ADD_UPDATE_CONTRACT_PROVIDER}`, type: 'create',
  });

  useEffect(() => {
    getContractLocation();
  }, []);

  const updateContractData = useCallback((data) => {
    const updateData = { ...contractResponse, selectedContractProviders: data };
    updateData.appliedToAllLocations = locationValue;
    updateData.appliedToAllProvider = providerValue;
    updateContractProvider({ data: updateData });
    setAddLocationModal(false);
    setAddLocationModal2(false);
    setDataChanged({ location: false, provider: false });
    setClearTable(true);
    setLocations([]);
  }, [contractResponse, locationValue, providerValue, setClearTable,
    setDataChanged, setLocations, updateContractProvider]);

  useEffect(() => {
    if (contractProviderResponse) {
      Notification({ message: contractProviderResponse?.message, success: true });
      getContractLocation();
      setEmptySelect([]);
      setLocations([]);
      setUpdatedData([]);
      selectLocationForm.setFieldsValue({ locationIds: undefined });
    }
    return () => clearUpdateResponse();
  }, [clearUpdateResponse, contractProviderResponse]);

  useEffect(() => {
    if (contractResponse && !dataChanged?.provider
      && !dataChanged.location && contractResponse?.isContractMappingExist) {
      setProviderValue(contractResponse?.appliedToAllProvider);
      setLocationValue(contractResponse?.appliedToAllLocations);

      form.setFields([{
        name: 'effectiveFrom',
        value: contractResponse?.selectedContractProviders?.[0]?.effectiveFrom ? moment(
        contractResponse?.selectedContractProviders?.[0]?.effectiveFrom,
        ) : null,
      },
      {
        name: 'effectiveTo',
        value: contractResponse?.selectedContractProviders?.[0]?.effectiveTo ? moment(
          contractResponse?.selectedContractProviders?.[0]?.effectiveTo,
        ) : null,
      },
      { name: 'providers', value: contractResponse?.appliedToAllProvider },
      { name: 'locations', value: contractResponse?.appliedToAllLocations },
      ]);
    }
  }, [contractResponse, form]);

  const onChangeProviders = useCallback(({ target }) => {
    if (contractResponse?.isContractMappingExist) {
      ConfirmDialog({
        onOk: (close) => {
          setProviderValue(target.value);
          if (contractResponse?.appliedToAllProvider === target.value) {
            setDataChanged({ ...dataChanged, provider: false });
          } else {
            setDataChanged({ ...dataChanged, provider: true });
            setLocations([]);
          }
          close();
        },
        title: 'Change Mapping',
        content: 'There are existing appointments associated with this mapping "Do you still want to continue?"',
        icon: <Icon name="ExclamationCircleOutlined" />,
      })();
    } else {
      setProviderValue(target.value);
      if (contractResponse?.appliedToAllProvider === target.value) {
        setDataChanged({ ...dataChanged, provider: false });
      } else {
        setDataChanged({ ...dataChanged, provider: true });
        setLocations([]);
      }
    }
  }, [contractResponse, dataChanged, setDataChanged, setLocations, setProviderValue]);

  const onChangeLocation = useCallback(({ target }) => {
    if (contractResponse?.isContractMappingExist) {
      ConfirmDialog({
        onOk: (close) => {
          setLocationValue(target.value);
          if (contractResponse?.appliedToAllLocations === target.value) {
            setDataChanged({ ...dataChanged, location: false });
          } else {
            setDataChanged({ ...dataChanged, location: true });
            setLocations([]);
          }
          close();
        },
        title: 'Change Mapping',
        content: 'There are existing appointments associated with this mapping "Do you still want to continue?"',
        icon: <Icon name="ExclamationCircleOutlined" />,
      })();
    } else {
      setLocationValue(target.value);
      if (contractResponse?.appliedToAllLocations === target.value) {
        setDataChanged({ ...dataChanged, location: false });
      } else {
        setDataChanged({ ...dataChanged, location: true });
        setLocations([]);
      }
    }
  }, [contractResponse, dataChanged, setDataChanged, setLocationValue, setLocations]);

  const providerLocationTableData = useMemo(() => (
    contractResponse?.selectedContractProviders?.map((item, i) => ({ ...item, key: i + 1 }))
  ), [contractResponse]);

  const addLocationMethod = useCallback(() => {
    setAddLocationModal(!addLocationModal);
  }, [addLocationModal]);

  const addLocationMethod2 = useCallback((isEdit, record) => () => {
    setAddLocationModal2(!addLocationModal2);
    if (isEdit) {
      setInitialEditData({
        providerIds: record?.providerId,
        effectiveFrom: record?.effectiveFrom ? moment(record?.effectiveFrom) : null,
        effectiveTo: record?.effectiveTo ? moment(record?.effectiveTo) : null,
        providerName: record?.providerName,
      });
    }
  }, [addLocationModal2, setInitialEditData]);

  const handleEditProvider = useCallback(() => {
    const formData = editForm.getFieldsValue();
    const data = cloneDeep(contractResponse?.selectedContractProviders);
    const editData = data.filter((item) => item?.providerId === initialEditData?.providerIds);
    editData[0].providerId = formData?.providerIds;
    editData[0].effectiveFrom = formData?.effectiveFrom;
    editData[0].effectiveTo = formData?.effectiveTo;
    editData[0].providerName = formData?.providerName;
    editData[0].selectedLocationIds = editData?.[0]?.selectedContractLocations?.map((item) => item?.locationId).join(',');
    updateContractData(editData);
  }, [contractResponse, editForm, initialEditData, updateContractData]);

  const handleLocation = useCallback((data, isArray) => {
    const parsedLocations = [];
    const selectedProvidersData = [];
    if (isArray) { parsedLocations.push(...data); }
    if (providerValue && !locationValue) {
      if (contractResponse?.selectedContractProviders?.[0]?.selectedContractLocations
        && !dataChanged?.location && !dataChanged?.provider) {
        parsedLocations.push(
          ...contractResponse?.selectedContractProviders?.[0]?.selectedContractLocations?.map(
            (item) => item?.locationId
          ),
        );
        selectedProvidersData.push({
          ...contractResponse?.selectedContractProviders?.[0],
          selectedLocationIds: parsedLocations?.join(','),
          effectiveFrom: form.getFieldValue('effectiveFrom'),
          effectiveTo: form.getFieldValue('effectiveTo'),
        });
      } else {
        selectedProvidersData.push({
          selectedLocationIds: parsedLocations?.join(','),
          effectiveFrom: form.getFieldValue('effectiveFrom'),
          effectiveTo: form.getFieldValue('effectiveTo'),
        });
      }
    } else if (!providerValue && !locationValue) {
      selectedProvidersData.push(...data);
    }
    setEmptySelect(data);
    setLocations(selectedProvidersData);
  }, [contractResponse, dataChanged, form, locationValue, providerValue,
    setEmptySelect, setLocations]);

  const onAddProvider = () => {
    updateContractData([addForm.getFieldsValue()]);
    addLocationMethod();
  };

  const handleFormSubmit = useCallback(() => {
    const data = [];
    if (!dataChanged?.provider && !dataChanged.location && !locationValue && providerValue) {
      data.push(contractResponse?.selectedContractProviders?.[0]);
      data[0].selectedLocationIds = locations?.length === 0 ? contractResponse?.selectedContractProviders?.[0]?.selectedContractLocations?.map((item) => item?.locationId).join(',') : locations?.[0]?.selectedLocationIds;
      data[0].effectiveTo = form.getFieldValue('effectiveTo');
      data[0].effectiveFrom = form.getFieldValue('effectiveFrom');
    }
    if ((dataChanged?.provider || dataChanged.location) && !locationValue && providerValue) {
      if (locations?.length !== 0) {
        data.push(...locations);
        data[0].effectiveFrom = form.getFieldValue('effectiveFrom');
        data[0].effectiveTo = form.getFieldValue('effectiveTo');
      } else {
        Notification({ message: ErrorMessages.PLEASE_ADD_ONE_LOCATION, success: false });
        return null;
      }
    }
    if (!dataChanged?.provider && !dataChanged.location && locationValue && providerValue) {
      data.push({
        ...contractResponse?.selectedContractProviders?.[0],
        effectiveFrom: form.getFieldValue('effectiveFrom'),
        effectiveTo: form.getFieldValue('effectiveTo'),
        providerId: 0,
        providerName: null,
      });
    }
    if ((dataChanged?.provider || dataChanged.location) && locationValue && providerValue) {
      data.push({
        effectiveFrom: form.getFieldValue('effectiveFrom'),
        effectiveTo: form.getFieldValue('effectiveTo'),
        providerId: 0,
        providerName: null,
      });
    }
    if (!locationValue && !providerValue) {
      if (locations?.length) { data.push(...locations); } else {
        Notification({ message: ErrorMessages.PLEASE_ADD_ONE_LOCATION, success: false });
        return null;
      }
    }
    updateContractData(data);
    return null;
  }, [contractResponse, dataChanged, form,
    locationValue, locations, providerValue, updateContractData]);

  const onCancel = useCallback(() => {
    if (locationValue && providerValue) {
      form.setFieldsValue({
        effectiveFrom: moment(
          contractResponse?.selectedContractProviders?.[0]?.effectiveFrom,
        ) || null,
        effectiveTo: moment(
          contractResponse?.selectedContractProviders?.[0]?.effectiveTo,
        ) || null,
      });
    }
    if (providerValue && !locationValue) {
      selectLocationForm.setFieldsValue({ locationIds: undefined });
    }
    if (!providerValue && !locationValue) {
      setClearTable(true);
    }
  }, [contractResponse, form, locationValue, providerValue, selectLocationForm, setClearTable]);

  const handleLocationDelete = useCallback((rowData) => {
    ConfirmDialog({
      onOk: (close) => {
        const data = contractResponse?.selectedContractProviders;
        const parsedData = contractResponse?.selectedContractProviders
        ?.[0]?.selectedContractLocations?.filter(
          (_id) => _id?.locationId !== rowData?.original?.locationId);
        data[0].selectedContractLocations = parsedData;
        data[0].selectedLocationIds = parsedData?.map((_id) => _id?.locationId).join(',');
        updateContractData(data);
        close();
      },
      title: 'Delete Location',
      content: 'Do you want to delete this Location',
      icon: <Icon name="ExclamationCircleOutlined" />,
    })();
  }, [contractResponse, updateContractData]);

  const handleProviderDelete = useCallback((rowData) => {
    ConfirmDialog({
      onOk: (close) => {
        const parsedData = contractResponse?.selectedContractProviders
        ?.filter((item) => item?.providerId === rowData?.providerId);
        parsedData[0].selectedLocationIds = parsedData[0]?.selectedContractLocations?.map((_id) => _id?.locationId).join(',');
        parsedData[0].isDeleted = true;
        updateContractData(parsedData);
        close();
      },
      title: 'Delete Provider',
      content: 'Do you want to delete this Provider',
      icon: <Icon name="ExclamationCircleOutlined" />,
    })();
  }, [contractResponse, updateContractData]);

  const handleProviderLocationDelete = useCallback((rowData, rowProps) => {
    const data = contractResponse?.selectedContractProviders;
    ConfirmDialog({
      onOk: (close) => {
        const parsedRecord = rowData?.original?.locationId + rowProps?.providerId;
        const arr = [];
        data.forEach((dataItems, i) => {
          data[i].selectedContractLocations = dataItems?.selectedContractLocations?.filter(
            (item) => {
              if (item?.locationId + dataItems?.providerId === parsedRecord) {
                arr.push(data[i]);
              }
              return item.locationId !== rowData?.original?.locationId;
            });
        });
        arr[0].selectedLocationIds = arr[0]?.selectedContractLocations?.map((item) => item?.locationId).join(',');
        updateContractData(arr);
        close();
      },
      title: 'Delete Location',
      content: 'Do you want to delete this Location',
      icon: <Icon name="ExclamationCircleOutlined" />,
    })();
  }, [contractResponse, updateContractData]);

  useEffect(() => {
    if (providerLocationTableData && clearTable) {
      if (providerLocationTableData?.length !== 0) {
        providerLocationTableData.forEach((item, index) => {
          selectLocationForm.setFields([
            {
              name: ['location', index + 1],
              value: item?.appliedToAllLocations ? [1000] : undefined,
            },
          ]);
        });
      }
    }
  }, [providerLocationTableData, clearTable]);

  const handleLocationChange = useCallback((location, rowData) => {
    selectLocationForm.setFields([{ name: ['location', rowData.key], value: location }]);
    const clonedData = cloneDeep(providerLocationTableData);
    let editData = updatedData?.find((item) => rowData?.providerId === item?.providerId)
    || clonedData.find((item) => rowData?.providerId === item?.providerId) || {};
    const locationData = [];
    if (location?.length === 0) {
      const editsData = updatedData?.filter((item) => item?.providerId !== rowData?.providerId);
      setUpdatedData([...editsData]);
      setRefetchPayload(!refetchPayload);
      return null;
    }
    if (!editData?.selectedContractLocations) {
      editData.selectedContractLocations = [];
    }
    if (location.find((ele) => ele === 1000)) {
      editData.appliedToAllLocations = true;
      editData = omit(editData, 'selectedLocationIds');
    } else {
      editData.selectedContractLocations.push({ locationId: location[location.length - 1] });
      editData.selectedContractLocations = flatten(editData?.selectedContractLocations);
      editData.selectedLocationIds = editData?.selectedContractLocations?.map((item) => item?.locationId).join(',') || '';
      editData.appliedToAllLocations = false;
    }

    if (updatedData?.find((item) => item?.providerId === editData?.providerId)) {
      const changeIndex = updatedData?.findIndex(
        (item) => item?.providerId === editData?.providerId);
      updatedData[changeIndex] = editData;
      setUpdatedData(updatedData);
    } else {
      locationData.push(editData);
      setUpdatedData([...updatedData, ...locationData]);
    }
    setRefetchPayload(!refetchPayload);
    setClearTable(false);
    return null;
  }, [selectLocationForm, providerLocationTableData, updatedData,
    setRefetchPayload, refetchPayload, setClearTable, setUpdatedData]);

  useEffect(() => {
    handleLocation(updatedData, false);
  }, [refetchPayload]);

  const expandedRowRender = useCallback(({ row }) => (
    <div className="custom-fixed-table-height">
      <Table
        columns={columns}
        data={row?.original?.selectedContractLocations || []}
        isHeaderFixed={false}
        rowProps={row?.original}
        handleLocationDelete={handleProviderLocationDelete}
      />
    </div>
  ), [handleProviderLocationDelete]);

  const providerLocationTableColumns = useMemo(() => (providerLocationColumns), []);

  return (
    <>
      {(getContractLoading || updateContractLoading) && <WidgetLoader />}
      <Modals
        visible={addLocationModal}
        toggleModal={addLocationMethod}
        width={400}
        title="Add Provider"
        destroyOnClose
        className="provider-location-ui mapping-provider-ui"
        footer={[
          <div className="group-btns" key="footer">
            <Button
              className="btn min-wt-86"
              onClick={addLocationMethod}
            >
              Cancel
            </Button>
            <Button
              className="btn btn-success min-wt-86 inline"
              onClick={addForm.submit}
            >
              Save
            </Button>
          </div>,
        ]}
      >
        <div className="modal-content-weapper shadow-wrap fields-wrapper">
          <div className="modal-from-feilds">
            <Form form={addForm} onFinish={onAddProvider}>
              <Form.Column>
                <div className="provider-search-wrap">
                  <WiredSelectWithoutTab
                    id="doctor"
                    name="providerId"
                    className="default-value-as-placeholder"
                    url={apiUrls.GET_PROVIDER_WITH_SUGGESTIVE_SEARCH}
                    label="Providers"
                    placeholder="All Providers"
                    nameAccessor="providerName"
                    valueAccessor="providerId"
                    startCaseOptions
                    selectProps={{
                      showSearch: true,
                      showArrow: true,
                    }}
                    labelSpan="8"
                    inputSpan="16"
                    required
                  />
                </div>
                <DatePicker
                  name="effectiveFrom"
                  labelSpan="8"
                  inputSpan="16"
                  label="Effective From"
                  dateFormat="MM-DD-YYYY"
                  required
                  disabledDate={(current) => {
                    const effectiveFromDate = contractResponse?.effectiveFrom;
                    const effectiveToDate = addForm.getFieldValue('effectiveTo') ? addForm.getFieldValue('effectiveTo') : contractResponse?.effectiveTo;
                    return disableDates(effectiveToDate, effectiveFromDate, current);
                  }}
                />
                <DatePicker
                  name="effectiveTo"
                  labelSpan="8"
                  inputSpan="16"
                  label="Effective To"
                  dateFormat="MM-DD-YYYY"
                  disabledDate={(current) => {
                    const effectiveFromDate = addForm.getFieldValue('effectiveFrom') ? addForm.getFieldValue('effectiveFrom') : contractResponse?.effectiveFrom;
                    const effectiveToDate = contractResponse?.effectiveTo;
                    return disableDates(effectiveToDate, effectiveFromDate, current);
                  }}
                />
              </Form.Column>
            </Form>
          </div>
        </div>
      </Modals>

      <Modals
        visible={addLocationModal2}
        toggleModal={addLocationMethod2(false)}
        width={400}
        destroyOnClose
        className="provider-location-ui mapping-provider-ui"
        title="Edit Provider"
        footer={[
          <div className="group-btns" key="footer">
            <Button
              className="btn min-wt-86"
              onClick={() => setAddLocationModal2(!addLocationModal2)}
            >
              Cancel
            </Button>
            <Button
              className="btn btn-success min-wt-86 inline"
              onClick={editForm.submit}
            >
              Update
            </Button>
          </div>,
        ]}
      >
        <div className="modal-content-weapper shadow-wrap fields-wrapper">
          <div className="modal-from-feilds">
            <Form form={editForm} initialData={initialEditData} onFinish={handleEditProvider}>
              <Form.Column>
                <div className="provider-search-wrap">
                  <WiredSelectWithoutTab
                    id="doctor"
                    name="providerIds"
                    className="default-value-as-placeholder"
                    url={apiUrls.GET_PROVIDER_WITH_SUGGESTIVE_SEARCH}
                    label="Provider Name"
                    placeholder="Provider Name"
                    nameAccessor="providerName"
                    valueAccessor="providerId"
                    startCaseOptions
                    required
                    selectProps={{
                      showSearch: true,
                      showArrow: true,
                      maxTagCount: 'responsive',
                      dropdownMatchSelectWidth: true,
                    }}
                    labelSpan="8"
                    inputSpan="16"
                  />
                </div>
                <DatePicker
                  name="effectiveFrom"
                  labelSpan="8"
                  inputSpan="16"
                  label="Effective From"
                  dateFormat="MM-DD-YYYY"
                  required
                  disabledDate={(current) => {
                    const effectiveFromDate = contractResponse?.effectiveFrom;
                    const effectiveToDate = editForm.getFieldValue('effectiveTo') ? editForm.getFieldValue('effectiveTo') : contractResponse?.effectiveTo;
                    return disableDates(effectiveToDate, effectiveFromDate, current);
                  }}
                />
                <DatePicker
                  name="effectiveTo"
                  labelSpan="8"
                  inputSpan="16"
                  label="Effective To"
                  dateFormat="MM-DD-YYYY"
                  disabledDate={(current) => {
                    const effectiveFromDate = editForm.getFieldValue('effectiveFrom') ? editForm.getFieldValue('effectiveFrom') : contractResponse?.effectiveFrom;
                    const effectiveToDate = contractResponse?.effectiveTo;
                    return disableDates(effectiveToDate, effectiveFromDate, current);
                  }}
                />
                <div style={{ display: 'none' }}>
                  <Input
                    name="providerName"
                  />
                </div>
              </Form.Column>
            </Form>

          </div>
        </div>
      </Modals>
      <div className="mapping-header-top">
        {!providerValue && locationValue ? null : (
          <div className="date-section-button">
            <Button
              className="btn min-wt-86 sm-btn mr-rt-8"
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
            <Button
              className="btn btn-success min-wt-86 inline sm-btn"
              onClick={form.submit}
            >
              {!dataChanged.provider && !dataChanged?.location
                && contractResponse?.selectedContractProviders?.length !== 0 ? 'Update' : 'Save'}
            </Button>
          </div>
        )}
        <div className="group-shadow">
          <Form
            form={form}
            onFinish={handleFormSubmit}
          >
            <section className="mapping-filter-section">
              <div className="radio-section">
                <Radio
                  required
                  name="providers"
                  label="Providers"
                  options={defaultProviderOptions}
                  labelSpan={6}
                  inputSpan={18}
                  radioProps={{
                    value: providerValue,
                  }}
                  valuePropName="checked"
                  onChange={onChangeProviders}
                />
                <Radio
                  required
                  name="locations"
                  label="Locations"
                  options={defaultLocationOptions}
                  labelSpan={6}
                  inputSpan={18}
                  radioProps={{
                    value: locationValue,
                  }}
                  valuePropName="checked"
                  onChange={onChangeLocation}
                />
              </div>
              {((providerValue && !locationValue)
                || (providerValue && locationValue))
                && (
                  <div className="date-section fields-wrapper">
                    <DatePicker
                      label="Effective From"
                      placeholder="Effective From"
                      name="effectiveFrom"
                      required
                      labelSpan="8"
                      inputSpan="16"
                      disabledDate={(current) => {
                        const effectiveFromDate = contractResponse?.effectiveFrom;
                        const effectiveToDate = form.getFieldValue('effectiveTo') ? form.getFieldValue('effectiveTo') : contractResponse?.effectiveTo;
                        return disableDates(effectiveToDate, effectiveFromDate, current);
                      }}
                    />
                    <DatePicker
                      label="Effective To"
                      placeholder="Effective To"
                      name="effectiveTo"
                      labelSpan="8"
                      inputSpan="16"
                      disabledDate={(current) => {
                        const effectiveFromDate = form.getFieldValue('effectiveFrom') ? form.getFieldValue('effectiveFrom') : contractResponse?.effectiveFrom;
                        const effectiveToDate = contractResponse?.effectiveTo;
                        return disableDates(effectiveToDate, effectiveFromDate, current);
                      }}
                    />
                  </div>
                )}
            </section>
          </Form>
          {(providerValue && !locationValue)
            && (
              <>
                <hr className="provider-location-seperator" />
                <Form form={selectLocationForm}>
                  <div className="search-provider-wrap right">
                    <WiredSelectWithoutTab
                      id="location"
                      name="locationIds"
                      className="default-value-as-placeholder"
                      url={apiUrls.SCHEDULAR_LOCATION_DROPDOWN}
                      label="Add Location"
                      placeholder="All Locations"
                      nameAccessor="locationName"
                      valueAccessor="locationId"
                      startCaseOptions
                      selectProps={{
                        showSearch: true,
                        showArrow: true,
                        mode: 'multiple',
                        maxTagCount: 'responsive',
                        style: { width: 180 },
                        dropdownMatchSelectWidth: true,
                      }}
                      value={emptySelect}
                      labelSpan="8"
                      inputSpan="16"
                      onChange={(data) => handleLocation(data, true)}
                    />
                  </div>
                </Form>
                <div className="app-table provider-location-wrap">
                  <Table
                    columns={columns}
                    data={(!dataChanged.provider && !dataChanged?.location
                      && (contractResponse?.selectedContractProviders
                      ?.[0]?.selectedContractLocations)) || []}
                    updateContractData={updateContractData}
                    handleLocationDelete={handleLocationDelete}
                  />
                </div>

              </>
            )}

          {(!providerValue && locationValue)
            && (
              <>
                <hr className="provider-location-seperator" />
                <div className="search-provider-wrap">
                  <Search
                    className="patent-list-search"
                    placeholder="Search Provider"
                  />
                  <button onClick={addLocationMethod} type="button" className="btn sm-btn">Add Provider</button>
                </div>
                <div className="app-table provider-location-wrap">
                  <Table
                    columns={providerColumns}
                    data={(!dataChanged.provider && !dataChanged?.location
                      && (contractResponse?.selectedContractProviders)) || []}
                    updateContractData={updateContractData}
                    handleProviderDelete={handleProviderDelete}
                    onChangeHandle={addLocationMethod2}
                  />
                </div>

              </>
            )}
          {(!providerValue && !locationValue)
            && (
              <>
                <hr className="provider-location-seperator" />
                <div className="search-provider-wrap">
                  <Search
                    className="patent-list-search"
                    placeholder="Search Provider"
                  />
                  <button onClick={addLocationMethod} type="button" className="btn sm-btn">Add Provider</button>
                </div>
                <div className="app-table provider-location-wrap">
                  <Table
                    columns={providerLocationTableColumns}
                    data={(!dataChanged?.provider && !dataChanged?.location
                      && (providerLocationTableData)) || []}
                    updateContractData={updateContractData}
                    handleProviderLocation={handleLocation}
                    renderRowSubComponent={expandedRowRender}
                    handleProviderDelete={handleProviderDelete}
                    selectLocationForm={selectLocationForm}
                    onChangeHandle={addLocationMethod2}
                    handleOptionsRender={handleOptionsRender}
                    handleLocationChange={handleLocationChange}
                    scrollId="provider-location-table"
                  />
                </div>

              </>
            )}
        </div>
      </div>
    </>
  );
};

export default MappingHtml;
