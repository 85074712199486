import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Tabs from './Tabs';
import { UiRoutes, labelPaths } from '../../../../lib/constants';
import rights from '../../../../lib/rights';
import {
  clearSelectedProvider, getSelectedProvider,
} from '../../../../store/actions/providers';

import useRedirect from '../../../../hooks/useRedirect';
import useRights from '../../../../hooks/useRights';
import SavedNavigationBar from '../../../../components/SavedNavigationBar';

import WithLabel from '../../../../hoc/withLabel';

function EditProvider({
  setCurrentProviderById,
  clearCurrentProviderData,
  labels,
}) {
  const { params } = useRedirect();
  const { id, tab } = params || {};
  const [isProviderViewOnly, editProvider] = useRights([
    rights.provider_view_only, rights.provider]);

  useEffect(() => {
    if (id) {
      setCurrentProviderById(id);
    }
    return () => clearCurrentProviderData();
  }, [id, setCurrentProviderById, clearCurrentProviderData]);

  return (
    <div className="main-content-area">
      <SavedNavigationBar
        listName={labels.get('tabs.providerList')}
        parentPath={UiRoutes.provider}
        title={(<h1>Providers</h1>)}
      />
      <div className="main-form-container">
        <Tabs selectedTabData={{ tab }} isProviderViewOnly={isProviderViewOnly && !editProvider} />
      </div>
    </div>
  );
}

const mapStateToProps = ({ providers }) => ({
  currentProvider: providers.get('selectedProvider'),
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentProviderById: (providerId) => dispatch(getSelectedProvider(providerId)),
  clearCurrentProviderData: () => dispatch(clearSelectedProvider()),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(
  WithLabel(EditProvider, labelPaths.EDIT_PROVIDER),
);
