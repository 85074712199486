import React from 'react';
import moment from 'moment';
import Table from '../../../../../components/Table';

import StatusCell from '../../../../../components/StatusCell';

const columns = [

  {
    Header: 'Service Date',
    accessor: 'serviceDate',
    Cell: ({ row: { original: { serviceDate } } }) => (
      <span
        className="cursor-pointer"
      >
        {serviceDate ? moment.utc(serviceDate).format('MM-DD-YYYY') : ''}
      </span>
    ),
  },
  {
    Header: 'Procedure',
    accessor: 'cptCode',
  },
  {
    Header: 'Modifiers',
    accessor: 'billingEncounterModifiers',
    Cell: ({
      row: {
        original: { billingEncounterModifiers = [] },
      },
    }) => <span>{billingEncounterModifiers?.map((item, index) => `${item?.modifierCode}${index === billingEncounterModifiers.length - 1 ? '' : ', '}`)}</span>,
  },
  {
    Header: 'Diagnosis',
    accessor: 'billingEncounterDiagnosisCodes',
    Cell: ({
      row: {
        original: { billingEncounterDiagnosisCodes = [] },
      },
    }) => <span>{billingEncounterDiagnosisCodes?.map((item, index) => `${item?.icdCode}${index === billingEncounterDiagnosisCodes.length - 1 ? '' : ', '}`)}</span>,
  },
  {
    Header: 'Units',
    accessor: 'unit',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
  // {
  //   Header: 'Provider',
  //   accessor: 'provider',
  // },
  // {
  //   Header: 'Loc',
  //   accessor: 'loc',
  // },
  {
    Header: 'Ins Profile',
    accessor: 'insuranceProfileCategoryName',
  },
  {
    Header: 'Cap',
    accessor: 'cap',
    Cell: ({ row: { original: { cap } } }) => (
      <StatusCell active={cap} />
    ),
  },
];

function ViewTable({ data }) {
  return (
    <div className="mr-top-20">
      <Table
        columns={columns}
        data={data}
      />
    </div>
  );
}

export default ViewTable;
