import React from 'react';

import { enums as enumConstant } from '../../../../../../lib/constants';
import SmCheckbox from '../../../../../../components/SmCheckbox';
import { Radio } from '../../../../../../wiredComponents/Enum';

const Form = ({ labels }) => (
  <div className="mr-top-16">
    <p>{labels.get('titles.claimDelivered')}</p>
    <Radio enumName={enumConstant.CLAIMS_DELIVERY_METHOD} name="deliveryMethodId" labelSpan="0" inputSpan="24" />
    <SmCheckbox labelSpan="10" name="sendAsCorrectedClaim" inputSpan="1" labelAfter>
      {labels.get('labels.sendAsCorrectedClaim')}
    </SmCheckbox>
  </div>
);

export default Form;
