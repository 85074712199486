import React, { useCallback } from 'react';
import Button from '../../../../../../../../components/Button';
import SelectBox from '../../../../../../../../components/Form/SelectBox';
import Modals from '../../../../../../../../components/Modal';
import WidgetLoader from '../../../../../../../../components/WidgetLoader';

const MoveProcedureModal = ({
  visible, toggleModal, dxListItems, handleProcedureSet,
  moveDxProcdeureCodeData, setMoveDxProcdeureCodeData, loading,
}) => {
  const onChangeDX = useCallback((newPatientDiagnosisId, all) => {
    const { item: { groupId: newDxGroupId } } = all;
    setMoveDxProcdeureCodeData({
      ...moveDxProcdeureCodeData,
      NewPatientDiagnosisGroupId: newDxGroupId,
    });
  }, [setMoveDxProcdeureCodeData, moveDxProcdeureCodeData]);

  const dxOptionRenderer = (item) => ({
    value: item?.length ? item?.[0]?.diagnosisDetailsId : item?.diagnosisDetailsId,
    groupId: item?.length ? item?.[0]?.groupId : item?.groupId,
    item,
    name: (
      <div>
        {item?.length ? item?.map((option) => (
          <span className="flex" key={option?.diagnosisDetailsId}>
            {option?.description}
          </span>
        )) : <span>{item?.description}</span>}
      </div>
    ),
    label: item?.length ? item?.[0]?.description : item?.description,
    title: item?.length ? item?.[0]?.description : item?.description,
  });

  const dxDropDownRenderer = (options = []) => {
    if (!Object.values(options).length) return <div />;
    return (options);
  };

  return (
    <Modals
      visible={visible}
      width={425}
      toggleModal={toggleModal}
      destroyOnClose
      footer={[
        <div className="group-btns mng-footer-btn" key="footer">
          <Button className="btn min-wt-86" onClick={toggleModal}>Cancel</Button>
          <Button className="btn btn-success min-wt-86 inline" onClick={handleProcedureSet}>
            Continue
          </Button>
        </div>,
      ]}
    >
      <div className="addition-header">
        <div className="lookup sprite-img-before">
          <p className="">Confirm Removing Diagnosis</p>
        </div>
      </div>
      {loading && <WidgetLoader />}
      <p className="">There are orders associated with these diagnosis</p>
      <SelectBox
        placeholder="Select Dx"
        label="Move these orders to"
        name="dxProcedureCode"
        options={dxListItems}
        labelSpan={8}
        inputSpan={16}
        optionRenderer={dxOptionRenderer}
        selectProps={{
          optionLabelProp: 'label',
          dropdownRender: dxDropDownRenderer,
        }}
        onChange={onChangeDX}
      />
    </Modals>
  );
};

export default MoveProcedureModal;
