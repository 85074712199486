import React, { useState, useCallback, useEffect } from 'react';
import { Menu } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';

import { apiUrls } from '../../../api/constants';
import rights from '../../../lib/rights';
import { enums, listIds } from '../../../lib/constants';

import withQuery from '../../../hoc/withQuery/withQuery';
import withReduxManager from '../../../hoc/withReduxManager';
import useCRUD from '../../../hooks/useCRUD';
import useRights from '../../../hooks/useRights';
import useMasterId from '../../../hooks/useMasterId';
import Events from '../../../lib/events';

import FilterManager from '../../../components/FilterManager';
import FilterComponents from '../../../components/FilterComponents';
import HideColumns from '../../../components/HideColumns';
import Notification from '../../../components/Notification';
import WidgetLoader from '../../../components/WidgetLoader';
import ConfirmDialog from '../../../components/ConfirmDialog';
import Icon from '../../../components/Icon';
import CheckBox from '../../../components/SmCheckbox';
import Task from '../../../wiredComponents/Modal/Tasks';

import CancelAppointmentDialog from '../Views/Dialogs/CancelAppointmentDialog';
import AppointmentContextMenu from '../Views/AppointmentContextMenu';
import TableComponent from '../BookAppointment/Components/TableComponent';

import columns from './columns';

import './tableView.scss';

const initialSort = [{ id: 'name', desc: false }];

const CustomPatientTable = withReduxManager({
  listId: 'listId.PATIENTS',
})(withQuery({
  url: apiUrls.GET_LIST_VIEW_SCHEDULAR,
  listId: 'listId.PATIENTS',
  getRawFilter: (filters) => {
    if (filters?.searchStatusId && isArray(filters?.searchStatusId)) {
      // eslint-disable-next-line no-param-reassign
      filters.searchStatusId = filters?.searchStatusId.join(',');
    }
    return filters;
  },
  alias: {
    patientName: 'patientFirstName',
    timeString: 'appointmentStartDateTime',
  },
})());

const FilterCollection = FilterComponents([
  {
    type: 'search',
    filterProps: {
      id: 'schedularList_patientName',
      placeholder: 'Patient Name',
      name: 'SearchText',
    },
  },
  {
    type: 'enumSelect',
    filterProps: {
      placeholder: 'All',
      name: 'searchStatusId',
      id: 'appointment_status',
      selectProps: {
        allowClear: true,
        mode: 'multiple',
        maxTagCount: 'responsive',
        style: {
          minWidth: 200,
        },
      },
      enumName: enums.APPOINTMENT_STATUS,
    },
  },
]);

const SchedularTable = ({
  labels,
  schedulerData,
  selectedPayer,
  selectedLocation,
  selectedDoctor,
  selectedDate,
  visitType,
  testType,
  viewType,
  isVisible,
  setVisible,
  onRemove,
  handleReFetch,
  reFetch: reFetchData,
  ...props
}) => {
  const [isCancelModalVisible, toggleCancelModalVisible] = useState(false);
  const [cancelAppointmentInfo, setCancelAppointmentInfo] = useState(null);
  const [showAllAppointments, setAllAppointmentsVisibility] = useState(false);
  const [customizeColumns, setCustomizeColumns] = useState(columns(labels));
  const [selectedOptions, setSelectedOptions] = useState({
    appointmentStartDateTime: true,
    timeString: true,
    patientName: true,
    locationName: true,
    providerFirstName: true,
    examType: true,
    visitType: true,
    status: true,
    copayment: true,
    policyType: true,
    eligibility: true,
    insuranceVerificationStatus: true,
    sourceApplication: true,
    authorizationNumber: true,
    patientPrimaryPhone: true,
    isConfirmed: true,
    balance: true,
    actions: true,
  });
  const clonedSelectedDate = cloneDeep(selectedDate);
  const providerIds = selectedDoctor ? selectedDoctor.map((provider) => provider?.providerId) : [];
  const locationId = selectedLocation?.locationId;
  const examTypeId = testType?.examTypeId;
  const visitTypeId = visitType?.visitTypeId;
  let scheduleStartDate;
  let scheduleEndDate;
  const [appointmentDetail, setAppointmentDetail] = useState({});
  const [taskModalVisibility, setTaskModalVisibility] = useState(false);
  const { New: appointmentNewStatus } = useMasterId(['New'], [enums.APPOINTMENT_STATUS]);
  const [isUpdateAppointmentAuthenticated, isDeleteAppointmentAuthenticated] = useRights([
    rights.update_appointment,
    rights.delete_appointment]);

  if (viewType === 'days') {
    scheduleStartDate = clonedSelectedDate.startOf('day').format('YYYY-MM-DD HH:mm:ss');
    scheduleEndDate = clonedSelectedDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');
  } else if (viewType === 'weeks') {
    scheduleStartDate = clonedSelectedDate.startOf('week').format('YYYY-MM-DD HH:mm:ss');
    scheduleEndDate = clonedSelectedDate.endOf('week').format('YYYY-MM-DD HH:mm:ss');
  } else if (viewType === 'months') {
    scheduleStartDate = clonedSelectedDate.startOf('month').format('YYYY-MM-DD HH:mm:ss');
    scheduleEndDate = clonedSelectedDate.endOf('month').format('YYYY-MM-DD HH:mm:ss');
  }

  const [statusUpdateResponse, , updatingStatus, updateStatus, clearStatus] = useCRUD({
    id: listIds.SCHEDULER_UPDATE_STATUS,
    url: apiUrls.SCHEDULER_UPDATE_APPOINTMENT_STATUS,
    type: 'update',
  });

  const onShowAllAppointments = useCallback((resetFilter, filters) => (e) => {
    const { checked } = e?.target || {};
    const { SearchText } = filters;
    resetFilter({ SearchText });
    setAllAppointmentsVisibility(checked);
  }, []);

  const cancelAppointment = useCallback((e, original) => {
    e.stopPropagation();
    toggleCancelModalVisible(true);
    setCancelAppointmentInfo(original);
  }, []);

  const undoCancelAppointment = useCallback((e, original) => {
    e.stopPropagation();
    const { appointmentId } = original;
    ConfirmDialog({
      onOk: (close) => {
        updateStatus({ appointmentId, status: 'New' });
        close();
      },
      okText: 'Undo',
      title: 'Undo Cancellation',
      content: 'Undo cancelled appointment?',
      icon: <Icon name="ExclamationCircleOutlined" />,
    })();
  }, [updateStatus]);

  const handleCancelModal = useCallback(() => () => {
    toggleCancelModalVisible(!isCancelModalVisible);
  }, [isCancelModalVisible]);

  const toggleTaskModal = useCallback(() => {
    setTaskModalVisibility(!taskModalVisibility);
  }, [taskModalVisibility]);

  const onTaskClick = useCallback(({ original }) => (event) => {
    event.stopPropagation();
    setAppointmentDetail({ ...original } || {});
    toggleTaskModal();
  }, [toggleTaskModal]);

  useEffect(() => {
    if (statusUpdateResponse) {
      const { message } = statusUpdateResponse;
      Notification({ message, success: true });
      clearStatus(true);
      Events.trigger('refetchSchedulerAppointmentListViewTable');
    }
  }, [statusUpdateResponse]);

  const extraInfoMenu = useCallback(({ original }) => () => (
    <Menu className="appointment-extra-info-menu-container">
      <AppointmentContextMenu item={original} isListMenu reFetch={reFetchData} />
    </Menu>
  ), [reFetchData]);

  return (
    <>
      {appointmentNewStatus ? (
        <CustomPatientTable
          columns={customizeColumns}
          initialSort={initialSort}
          noDataText="Schedule not found"
          cancelAppointment={cancelAppointment}
          undoCancelAppointment={undoCancelAppointment}
          initialFilters={{
            searchStatusId: appointmentNewStatus,
          }}
          scrollId="schedularMonthlyView"
          filters={showAllAppointments ? {} : {
            locationId,
            examTypeId,
            visitTypeId,
            PayerId: selectedPayer,
            providerId: providerIds.join(','),
            scheduleStartDate,
            scheduleEndDate,
          }}
          rightClickMenu={extraInfoMenu}
          onTaskClick={onTaskClick}
          {...props}
          onRowClick={isUpdateAppointmentAuthenticated ? props?.onRowClick : () => { /** */ }}
          isCancelAppointment={isDeleteAppointmentAuthenticated}
        >
          {({
            Component, totalCount, initialFilters, onFiltersChange, reFetch,
          }) => (
            <FilterManager initialFilters={initialFilters} onChange={onFiltersChange}>
              {({ onFilterChange, filters, resetFilters }) => (
                <div className="schedular-table-filters">
                  {updatingStatus && <WidgetLoader />}
                  <div className="table-filters">
                    <div className="flex mr-top-8 mr-left-20">
                      <FilterCollection onFilterChange={onFilterChange} filters={filters} />
                      <CheckBox
                        value={showAllAppointments}
                        onChange={onShowAllAppointments(resetFilters, filters)}
                      >
                        Show all appointments
                      </CheckBox>
                    </div>
                    <div className="filter-right">
                      <div className="pannnel-content">
                        <div className="schedular-customize-columns">
                          <HideColumns
                            columns={columns(labels)}
                            setCustomizeColumns={setCustomizeColumns}
                            selectedOptions={selectedOptions}
                            setSelectedOptions={setSelectedOptions}
                            isVisible={isVisible}
                            setVisible={setVisible}
                          />
                        </div>
                      </div>
                      <span className="table-count">
                        <span>{`Total Count: ${totalCount}`}</span>
                      </span>
                    </div>
                  </div>
                  <TableComponent
                    reFetch={reFetch}
                    handleReFetch={handleReFetch}
                    Component={Component}
                  />
                </div>
              )}
            </FilterManager>
          )}
        </CustomPatientTable>
      ) : null}
      <CancelAppointmentDialog
        isVisible={isCancelModalVisible}
        toggleModal={handleCancelModal()}
        info={cancelAppointmentInfo}
        onRemove={onRemove}
        reFetch={reFetchData}
      />
      <Task
        toggleModal={toggleTaskModal}
        visible={taskModalVisibility}
        labels={labels}
        appointmentDetail={appointmentDetail}
        patientId={appointmentDetail?.patientId}
      />
    </>
  );
};

export default SchedularTable;
