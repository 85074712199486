/* eslint-disable no-nested-ternary */
import React from 'react';
import ErrorMessages from '../../../../lib/errorMessages';
import Tooltip from '../../../../components/Tooltip';
import Icon from '../../../../components/Icon';
import iconNames from '../../../../lib/iconNames';
import { RULES_ENGINE_EVALUATION_STATUS } from '../../../../lib/constants';

const EncounterEvaluationStatus = ({ ruleEvaluationStatus }) => (
  <span>
    {ruleEvaluationStatus === RULES_ENGINE_EVALUATION_STATUS.SUCCESS ? (
      <Tooltip content={ErrorMessages.PASSED_ALL_RULES}>
        <Icon
          name={iconNames.checkCircleOutlined}
          color="green"
        />
      </Tooltip>
    ) : ruleEvaluationStatus === RULES_ENGINE_EVALUATION_STATUS.FAILURE ? (
      <Tooltip content={ErrorMessages.FAILED_RULES}>
        <Icon name={iconNames.warningOutlined} color="#F9E076" />
      </Tooltip>
    ) : (
      <Tooltip content={ErrorMessages.RULES_NOT_EVALUATED}>
        <Icon
          name={iconNames.radiusSettingOutlined}
          color="orange"
        />
      </Tooltip>
    )}
  </span>
);

export default EncounterEvaluationStatus;
