import React, { useCallback } from 'react';
import { Form as AntdForm, Row, Col } from 'antd';

import WithLabel from '../../../../hoc/withLabel';
import { labelPaths } from '../../../../lib/constants';
import useRedirect from '../../../../hooks/useRedirect';

import Form from '../../../../components/Form';
import Button from '../../../../components/Button';

import UserProfile from '../UserProfile';
import LineItemsTable from './Components/LineItemsTable';
import PatientAdjustmentForm from './Components/AdjustmentForm';
import BalanceSheetTable from '../BalanceSheetTable';
import useReduxState from '../../../../hooks/useReduxState';

const PatientAdjustment = (props) => {
  const { labels, closeTab } = props;
  const [form] = AntdForm.useForm();
  const { params: { id, tabId } } = useRedirect();
  const [lineItemData, setLineItemData] = useReduxState(`patient-adjustment-${id}`, []);
  const handleCancel = useCallback(() => closeTab(tabId, true), [closeTab, tabId]);
  return (
    <div className="">
      <div className="patient-description">
        <Row>
          <Col md={24} xl={8}>
            <UserProfile labels={labels} />
          </Col>
          <Col md={24} xl={16}>
            <div className="modal-content-area mr-bt-24 custom-user-table">
              <BalanceSheetTable labels={labels} isCustomized={false} />
            </div>
          </Col>
        </Row>
      </div>
      <PatientAdjustmentForm
        form={form}
        labels={labels}
        lineItemData={lineItemData}
        closeTab={closeTab}
      />
      <div className="modal-content-area">
        <LineItemsTable
          labels={labels}
          form={form}
          setLineItemData={setLineItemData}
          lineItemData={lineItemData}
        />
      </div>
      <Form.Section>
        <div className="group-btns mr-top-12">
          <Button
            className="btn sm-btn"
            id="patients_financial_patientAdjustment_cancel"
            onClick={handleCancel}
          >
            {labels.get('buttons.cancel')}
          </Button>
          <Button
            className="btn btn-success sm-btn"
            id="patients_financial_patientAdjustment_save"
            onClick={form.submit}
            data-testid="patient-adjustment-save"
          >
            {labels.get('buttons.save')}
          </Button>
        </div>
      </Form.Section>
    </div>
  );
};

export default WithLabel(PatientAdjustment, labelPaths.FINANCIAL_POPUP_PATIENT_ADJUSTMENT);
