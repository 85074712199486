import React, { useCallback, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Upload } from 'antd';

import classNames from 'classnames';
import Events from '../../../lib/events';
import useCRUD from '../../../hooks/useCRUD';
import { apiUrls } from '../../../api/constants';
import useRedirect from '../../../hooks/useRedirect';
import withQuery from '../../../hoc/withQuery/withQuery';
import SuccessMessages from '../../../lib/successMessages';
import { contentType } from '../../../lib/constants';

import Loader from '../../../components/Loader';
import Button from '../../../components/Button';
import Notification from '../../../components/Notification';

import AddCPT from './AddCPT';
import columns from './columns';
import TableWrapper from './TableWrapper';
import FilterComponents from '../../../components/FilterComponents';
import FilterManager from '../../../components/FilterManager';
import EditCptModal from './AddCPT/EditCptGlobModal';
import { fileSizeCheck } from '../../../lib/util';
import useRights from '../../../hooks/useRights';
import rights from '../../../lib/rights';

const initialFilter = { includeInActive: false };

const CPTTable = withQuery({
  listId: 'CPT_TABLE',
  url: apiUrls.CPT_CODE_LIST,
})();

const FilterCollection = FilterComponents([
  {
    type: 'search',
    filterProps: {
      placeholder: 'Search CPT',
      name: 'CPTCodeText',
      id: 'cpt_filter_search',
    },
  },
  {
    type: 'checkbox',
    filterProps: {
      name: 'includeInActive',
      id: 'includeInActive',
      label: 'Inactive',
    },
  },
]);

const CPT = ({ labels }) => {
  const {
    push, path, generatePath, params: { tab }, params,
  } = useRedirect();

  const [isModalVisible, setModalVisibility] = useState(false);
  const [selectedCPT, setSelectedCPT] = useState({});

  const [cptImportData, , cptImportLoading, uploadFile, clearCPTImportData] = useCRUD({ id: 'import-cpt', url: apiUrls.IMPORT_CPT, type: 'create' });
  const [
    hideCPTSearchResponse,,
    hideCPTSearchLoading,
    hideCPTSearch,
    clearHideCPTSearch,
  ] = useCRUD({
    id: 'HIDE_CPT_SEARCH_SYNONYM', url: apiUrls.HIDE_ICD_CPT_SYNONYM_SEARCH, type: 'update',
  });

  const [
    updateCptResponse,,
    loading,
    updateCpt,
    clearUpdateCptResponse,
  ] = useCRUD({
    id: 'UPDATE_CPT',
    url: apiUrls.EDIT_CPT_CODE,
    type: 'update',
  });

  const onEditCptCode = useCallback((res) => {
    updateCpt({
      CPTCodeId: res.id,
      globalPeriod: res.globalPeriod,
      isGlobalPeriodEyeSpecific: res.isGlobalPeriodEyeSpecific,
      shouldImpactDoctor: res.shouldImpactDoctor,
    });
  }, [updateCpt]);

  useEffect(() => {
    if (updateCptResponse) {
      const { message } = updateCptResponse;
      Notification({ message, success: true });
      Events.trigger('reFetchCPTTable');
      clearUpdateCptResponse();
      setModalVisibility(false);
    }
  }, [updateCptResponse, clearUpdateCptResponse]);

  const onEditCpt = useCallback((data = {}) => {
    setSelectedCPT({ ...data, code: data?.cptId, isEdit: true });
    setModalVisibility(true);
  }, []);

  const handleAddCPT = useCallback(() => push(generatePath(`${path}/new`,
    { ...params })), [generatePath, params, path, push]);

  const navigateBack = useCallback(() => push(generatePath(path,
    { ...params })), [generatePath, params, path, push]);

  const onSelectFile = useCallback((fileData) => {
    uploadFile({
      data: {
        contentType: contentType.MULTIPART,
        File: fileData,
      },
    });
  }, [uploadFile]);

  useEffect(() => {
    if (cptImportData && !Array.isArray(cptImportData)) {
      Notification({ message: SuccessMessages.CPT_IMPORTED_SUCCESSFULLY, success: true });
      Events.trigger('reFetchCPTTable');
    }

    clearCPTImportData(true);
  }, [clearCPTImportData, cptImportData]);

  const onHideCPTSearch = useCallback((status, { id } = {}) => {
    hideCPTSearch({
      CPTCodeId: id,
      isActive: status,
    });
  }, [hideCPTSearch]);

  useEffect(() => {
    if (hideCPTSearchResponse) {
      const { message } = hideCPTSearchResponse;
      Notification({ message, success: true });
      Events.trigger('reFetchCPTTable');
      clearHideCPTSearch();
    }
  }, [hideCPTSearchResponse, clearHideCPTSearch]);

  const [
    isAccessToAddOrEditGlobalPeriod,
  ] = useRights([
    rights.access_to_add_or_edit_global_period,
  ]);

  return (
    <div>
      {(cptImportLoading || hideCPTSearchLoading || loading) && <Loader />}
      <Switch>
        <Route exact path={path}>
          <div className={classNames('heading-area flex align-center screen-title',
            { 'justify-content-sp-bt': !tab, 'justify-content-flex-end': tab })}
          >
            {!tab && <h2>CPT</h2>}
            <div className="group-btns">
              <Upload
                accept=".xlsx"
                transformFile={onSelectFile}
                multiple={false}
                showUploadList={false}
                beforeUpload={(file) => fileSizeCheck(file, 30)}
              >
                <Button className="btn btn-success sm-btn">Import</Button>
              </Upload>
              <Button
                onClick={handleAddCPT}
                className="btn btn-success sm-btn"
              >
                Add CPT
              </Button>
            </div>
          </div>
          <div className="cpt-icd-custom-table">
            <CPTTable
              columns={columns(onHideCPTSearch, isAccessToAddOrEditGlobalPeriod)}
              rowClickable={false}
              initialFilters={initialFilter}
              onEditCpt={onEditCpt}
            >
              {({
                Component, totalCount, initialFilters, onFiltersChange, reFetch,
              }) => (
                <FilterManager
                  initialFilters={initialFilters}
                  onChange={onFiltersChange}
                >
                  {({ onFilterChange, filters }) => (
                    <>
                      <div className="table-filters">
                        <FilterCollection
                          onFilterChange={onFilterChange}
                          filters={filters}
                          allowClear
                        />
                        <span className="table-count">
                          <span>{`Total Count: ${totalCount}`}</span>
                        </span>
                      </div>
                      <TableWrapper Component={Component} reFetch={reFetch} />
                    </>
                  )}
                </FilterManager>
              )}
            </CPTTable>
          </div>
        </Route>
        <Route exact path={`${path}/new`}>
          <AddCPT navigateBack={navigateBack} headingVisible={!tab} />
        </Route>
      </Switch>
      <EditCptModal
        labels={labels}
        isCptSynonym
        isModalVisible={isModalVisible}
        setModalVisibility={setModalVisibility}
        selectedCode={selectedCPT}
        updateCptTrigger={onEditCptCode}
      />
    </div>
  );
};

export default CPT;
