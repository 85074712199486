import React from 'react';
import '../bills.scss';

import BillTable from './billTable';

function BillsList({ labels }) {
  return (
    <div className="bills-wrap">
      <div className="encounter-list-table table-scroll-wrapper">
        <BillTable labels={labels} />
      </div>
    </div>
  );
}

export default BillsList;
