import React, { Suspense } from 'react';
import Loader from '../../components/Loader';

const ReactSuspense = (WrappedComponent) => function WithSuspenseHOC(props) {
  return (
    <Suspense fallback={<Loader />}>
      <WrappedComponent {...props} />
    </Suspense>
  );
};

export default ReactSuspense;
