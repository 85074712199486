import React from 'react';
import TimeCell from '../../../../../../components/Cells/TimeCell';
import { isDate } from '../../../../../../lib/util';

const CommonColumns = (labels, statusAccessor = 'stageMasterName') => [
  {
    Header: labels.get('tableColumns.payerName'),
    accessor: 'insurancePayerName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.status'),
    accessor: statusAccessor,
    sort: true,
  },
  {
    Header: labels.get('tableColumns.depositDate'),
    accessor: 'depositDate',
    sort: true,
    Cell: (e) => <TimeCell value={e?.row.original?.depositDate?.dateString} format="MM-DD-YYYY" />,
  },
  {
    Header: labels.get('tableColumns.createdDate'),
    accessor: 'createdDate',
    sort: true,
    Cell: (e) => <TimeCell value={e?.row.original?.createdDate?.dateString} format="MM-DD-YYYY" />,
  },
  {
    Header: labels.get('tableColumns.batchNumber'),
    accessor: 'batchNumber',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.assignedTo'),
    accessor: 'assignToUserName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.checks'),
    accessor: 'checkCount',
    className: 'text-align-right',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.checkDate'),
    accessor: 'checkDate',
    Cell: ({ row: { original: { checkDate } } }) => (
      isDate(checkDate) ? <TimeCell value={checkDate} format="MM-DD-YYYY" /> : checkDate
    ),
    sort: true,
  },
  {
    Header: labels.get('tableColumns.checkNumber'),
    accessor: 'checkNumber',
    className: 'text-align-right',
    sort: true,
  },
];
export default CommonColumns;
