import React, { useCallback } from 'react';
import moment from 'moment';

import { DatePicker } from '../../../../components/Form/inputFields';
import Button from '../../../../components/Button';
import './filters.scss';
import ActionIcons from '../../../../wiredComponents/ActionIcons';

const Filters = ({ labels, selectedDate, onChangeDate }) => {
  const goToPreviousDate = useCallback(() => {
    onChangeDate(moment(selectedDate).subtract(1, 'days'));
  }, [onChangeDate, selectedDate]);
  const goToNextDate = useCallback(() => {
    onChangeDate(moment(selectedDate).add(1, 'days'));
  }, [onChangeDate, selectedDate]);

  return (
    <div className="dashboard-filter-header-section" data-testid="filters-main-component">
      <h3 className="bold" data-testid="filtersHeading">{labels.get('titles.todaysSchedule')}</h3>
      <div className="filters-wrap">
        <div className="filters" data-testid="filters">
          <Button className="lt-arrow" onClick={goToPreviousDate} />
          <DatePicker
            name={null}
            defaultValue={selectedDate}
            value={selectedDate}
            allowClear={false}
            onChange={onChangeDate}
            labelSpan="0"
            inputSpan="24"
          />
          <Button className="rt-arrow" onClick={goToNextDate} />
        </div>
        <ActionIcons edit />
      </div>
    </div>
  );
};

Filters.defaultProps = {
  locations: [],
  doctors: [],
  selectedDoctors: [],
  onLocationSelect: () => {
    /* This is intentional */
  },
  onDoctorSelect: () => {
    /* This is intentional */
  },
};

export default Filters;
