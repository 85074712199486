import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import { Switch, Route } from 'react-router-dom';

import { Form as AntdForm, Upload } from 'antd';

import Events from '../../../lib/events';
import useCRUD from '../../../hooks/useCRUD';
import { apiUrls } from '../../../api/constants';
import { contentType } from '../../../lib/constants';
import SuccessMessages from '../../../lib/successMessages';

import Modals from '../../../components/Modal';
import Loader from '../../../components/Loader';
import Button from '../../../components/Button';
import Notification from '../../../components/Notification';

import CPTTable from './CPTTable';
import CPTICDMapping from './CPTICDMapping';

import './hierarchyTable.scss';
import useRedirect from '../../../hooks/useRedirect';
import AddCPT from '../CPT/AddCPT';
import { fileSizeCheck, getuuid } from '../../../lib/util';

const CPTICDCrosswalk = () => {
  const [form] = AntdForm.useForm();
  const { push, path } = useRedirect();
  const [cptIcdImportData, , cptIcdImportLoader,
    uploadFile, clearCptIcdImportData] = useCRUD({
    id: 'import-cpt-icd-mapping',
    url: apiUrls.IMPORT_CPT_ICD_MAPPING,
    type: 'create',
  });
  const [addCptIcdMappingResponse,, loading, addCptIcdMapping, clearCptIcdResponse,
  ] = useCRUD({ id: 'cpt-icd-mapping-crosswalk', url: apiUrls.ADD_CPT_ICD_CROSSWALK, type: 'create' });

  const [visiblePayerCptModal, togglePayerCptModal] = useState(false);
  const [selectedCPT, setSelectedCPT] = useState({});

  const cptIcdMappingId = useMemo(() => getuuid(), []);

  const validateOnAddCptIcdMapping = useCallback((selectedIcdCodes) => {
    let errMsg = false;
    if (!Object.keys(selectedIcdCodes).length && !Object.keys(selectedCPT).length) {
      errMsg = 'Both CPT and ICD code are required';
    } else if (!Object.keys(selectedIcdCodes).length) {
      errMsg = 'Please Select at least one ICD Code';
    } else if (!Object.keys(selectedCPT).length) {
      errMsg = 'Please Select CPT';
    }
    return errMsg;
  }, [selectedCPT]);

  const handleMappingUpdate = useCallback((selectedIcdCodes, setSelectedIcdCodes, setICDCodes) => {
    const validatedMapping = validateOnAddCptIcdMapping(selectedIcdCodes);
    if (!validatedMapping) {
      const { cptCode, isActive } = selectedCPT;
      addCptIcdMapping({
        id: cptIcdMappingId,
        data: {
          icdCodes: Object.keys(selectedIcdCodes),
          cptCodeId: cptCode,
          isActive,
        },
      });
      setSelectedCPT();
      setSelectedIcdCodes({});
      setICDCodes({});
    } else {
      Notification({ message: validatedMapping });
    }
  }, [addCptIcdMapping, cptIcdMappingId, selectedCPT, validateOnAddCptIcdMapping]);

  useEffect(() => {
    if (addCptIcdMappingResponse && addCptIcdMappingResponse.message) {
      Notification({ message: SuccessMessages.ADD_CPT_ICD_MAPPING, success: true });
      togglePayerCptModal(false);
      clearCptIcdResponse(true);
    }
  }, [addCptIcdMappingResponse]);

  const [selectedICDCodes, setSelectedICDCodes] = useState({});
  const [icdCodes, setICDCodes] = useState({});

  const onICDCheckBoxClick = useCallback((data) => {
    const { id, icdCode } = data;
    const icdCodeObj = selectedICDCodes;
    const icdCodeNameObj = icdCodes;
    if (icdCodeObj[id]) {
      delete icdCodeObj[id];
      delete icdCodeNameObj[icdCode];
    } else {
      icdCodeObj[id] = !icdCodeObj[id];
      icdCodeNameObj[icdCode] = !icdCodeNameObj[icdCode];
    }
    setICDCodes({ ...icdCodeNameObj });
    setSelectedICDCodes({ ...icdCodeObj });
  }, [icdCodes, selectedICDCodes]);

  const handleSave = useCallback(() => {
    handleMappingUpdate(icdCodes, setSelectedICDCodes, setICDCodes);
  },
  [handleMappingUpdate, icdCodes]);

  const onSelectFile = useCallback((fileData) => {
    uploadFile({
      data: {
        contentType: contentType.MULTIPART,
        File: fileData,
      },
    });
  }, [uploadFile]);

  useEffect(() => {
    if (cptIcdImportData && !Array.isArray(cptIcdImportData)) {
      Notification({ message: cptIcdImportData?.data?.message, success: true });
      Events.trigger('reFetchCPTICDTable');
    }
    clearCptIcdImportData(true);
  }, [clearCptIcdImportData, cptIcdImportData]);

  const handleAddCPT = useCallback(() => push(`${path}/new`), [path, push]);
  const navigateBack = useCallback(() => push(path), [path, push]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Switch>
      <Route exact path={path}>
        <div>
          {cptIcdImportLoader && <Loader />}
          <Modals
            visible={!!visiblePayerCptModal}
            toggleModal={togglePayerCptModal}
            footer={[
              <div className="group-btns" key="footer">
                <Button className="btn min-wt-86" onClick={() => togglePayerCptModal(false)}>Cancel</Button>
                <Button className="btn btn-success min-wt-86 inline" onClick={handleSave}>Save</Button>
              </div>,
            ]}
            destroyOnClose
          >
            <CPTICDMapping
              title="CPT ICD Mapping"
              level="CPT_LEVEL"
              onICDCheckBoxClick={onICDCheckBoxClick}
              checkedICDCodes={selectedICDCodes}
              selectedCPT={selectedCPT}
            />
          </Modals>
          <div className="heading-area flex justify-content-sp-bt align-center screen-title">
            <h2>CPT-ICD Crosswalk</h2>
            <div>
              <Upload
                accept=".xlsx"
                transformFile={onSelectFile}
                multiple={false}
                showUploadList={false}
                beforeUpload={(file) => fileSizeCheck(file, 30)}
              >
                <Button className="btn btn-success sm-btn" onClick={() => form.submit()}>Import</Button>
              </Upload>
              <Button
                onClick={handleAddCPT}
                className="btn btn-success sm-btn mr-lt-16"
              >
                Add CPT
              </Button>
            </div>
          </div>
          <CPTTable
            togglePayerCptModal={togglePayerCptModal}
            setSelectedCPT={setSelectedCPT}
          />
        </div>
      </Route>
      <Route exact path={`${path}/new`}>
        <AddCPT navigateBack={navigateBack} />
      </Route>
    </Switch>
  );
};

export default CPTICDCrosswalk;
