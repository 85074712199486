import React from 'react';

import TimeCell from '../../../../../components/Cells/TimeCell';

const columns = (labels) => [
  {
    Header: labels.get('columns.createdOn'),
    accessor: 'createdOn',
    Cell: (data) => <TimeCell value={data?.row?.original?.createdOn} />,
    fixWidth: '100',
  },
  {
    Header: labels.get('columns.createdBy'),
    accessor: 'createdByName',
  },
  {
    Header: labels.get('columns.details'),
    accessor: 'text',
  },
];

export default columns;
