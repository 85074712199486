import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import { apiUrls } from '../../../../../api/constants';
import ErrorMessages from '../../../../../lib/errorMessages';
import { enums, labelPaths } from '../../../../../lib/constants';
import SuccessMessages from '../../../../../lib/successMessages';

import { getEnumMasterData } from '../../../../../store/actions/enum';
import { getEnumMaster, getEnumOptions } from '../../../../../store/selectors';

import useCRUD from '../../../../../hooks/useCRUD';
import useRedirect from '../../../../../hooks/useRedirect';
import WithLabel from '../../../../../hoc/withLabel';

import Radio from '../../../../../components/Radio';
import Modals from '../../../../../components/Modal';
import Button from '../../../../../components/Button';
import Notification from '../../../../../components/Notification';
import WidgetLoader from '../../../../../components/WidgetLoader';

import PatientClaimTable from '../../../../../wiredComponents/CollectionClaimsTable';
import InsuranceDetailsSelect from '../../../../../wiredComponents/InsuranceDetailsSelect';
import Events from '../../../../../lib/events';

const ChangeResponsibilityModal = ({
  labels, isVisible, toggleModal, claimId, payerName, isPatientResponsibility,
  patientId, billingEncounterServiceLineId, billingEncounterId, shouldShowSecondary, dateOfService,
}) => {
  const { params } = useRedirect();
  const { financialId } = params;
  const [claimDelivery, setClaimDelivery] = useState();
  const [selectedRows, setSelectedRow] = useState([]);
  const [payerResponsibility, setResponsibility] = useState({});
  const dispatch = useDispatch();
  const enumMaster = useSelector((state) => getEnumMaster(state));
  const enumId = get(enumMaster, `${enums.SUBMISSION_METHOD}.enumId`);
  const submissionOptions = useSelector((state) => getEnumOptions(state)?.[enumId]?.data || []);

  const parsedSubmissionOptions = useMemo(() => {
    const parsedOptions = [];
      submissionOptions?.forEach((item) => {
        if (item?.masterCode?.toLowerCase() === 'paper' || item?.masterCode?.toLowerCase() === 'electronic') {
          parsedOptions.push({ label: item?.masterName, value: item?.masterId });
        }
      });
      return [
        ...parsedOptions,
        { label: 'No claim required', value: 0 },
      ];
  }, [submissionOptions]);

  const [response, , loading, updateResponsibility, clear] = useCRUD({
    id: apiUrls.CHANGE_RESPONSIBILITY_IN_FINANCIAL,
    url: apiUrls.CHANGE_RESPONSIBILITY_IN_FINANCIAL,
    type: 'update',
  });

  useEffect(() => {
    if (enumId && !submissionOptions.length) {
      dispatch(getEnumMasterData(enumId));
    }
  }, [enumId, submissionOptions.length]);

  useEffect(() => {
    if (response) {
      Notification({
        message: SuccessMessages.INSURANCE_RESPONSIBILITY_CHANGED_SUCCESSFULLY,
        success: true,
      });
      Events.trigger(`reFetchFinancialDetail-${financialId}`);
      clear();
      toggleModal();
    }
  }, [response]);

  const changeResponsibilityHandler = useCallback(() => {
    if (!selectedRows?.length) {
      return Notification({ message: ErrorMessages.AT_LEAST_ONE_CLAIM_SELECTED });
    }
    if (!payerResponsibility?.payerId) {
      return Notification({ message: 'Please select insurance payer.' });
    }
    if (!claimDelivery) {
      return Notification({ message: 'Please select delivery method.' });
    }
    return updateResponsibility({
      billingEncounterServiceLineIds: (
        selectedRows.map(({ original }) => original?.billingEncounterServiceLineId)
      ),
      deliveryMethodId: claimDelivery === 0 ? undefined : claimDelivery,
      isNoClaim: claimDelivery === 0 ? true : undefined,
      billingEncounterId,
      patientId,
      payerId: payerResponsibility?.payerId,
      insuranceProfileDetailId: payerResponsibility?.insuranceProfileDetailId,
      includeInactiveInsurance: true,
    }, `/${claimId}`);
  }, [billingEncounterId, claimDelivery, claimId, patientId,
    payerResponsibility, selectedRows, updateResponsibility]);

  const onInsuranceSelect = useCallback((id, _all) => {
    const { item } = _all;
    setResponsibility({
      payerId: item?.payerId,
      insuranceProfileDetailId: item?.insuranceProfileDetailId,
    });
  }, []);

  const handleRadio = useCallback(({ target: { value } }) => setClaimDelivery(value), []);

  return (
    <Modals
      visible={isVisible}
      width={1000}
      toggleModal={toggleModal}
      className="change-responsibility-modal"
      footer={[
        <div className="group-btns" key="footer">
          <Button
            className="btn btn-success min-wt-86 inline"
            onClick={changeResponsibilityHandler}
            data-testid="changeResponsibilityButton"
          >
            {labels.get('buttons.changeResponsibility')}
          </Button>
          <Button className="btn min-wt-86" onClick={toggleModal}>{labels.get('buttons.cancel')}</Button>
        </div>,
      ]}
      destroyOnClose
    >
      <div className="addition-header posi-relative">
        {loading && <WidgetLoader />}
        <div className="lookup sprite-img-before">
          <p>{labels.get('titles.changeResponsibility')}</p>
        </div>
      </div>
      <p className="current-payer">
        {`${labels.get('titles.currentPayerName')} ${isPatientResponsibility ? 'Patient' : (payerName || '')}`}
      </p>
      <div style={{ width: '50%' }} className="insurance-dropdown-wrapper">
        <InsuranceDetailsSelect
          id={`${apiUrls.INSURANCE_PROFILE_DROPDOWN}-${patientId}`}
          name="insurancedetailId"
          label="Insurance Info"
          labelSpan="6"
          inputSpan="18"
          patientId={patientId}
          selectProps={{ allowClear: false }}
          disableOtherThanPrimaryAndSelf={shouldShowSecondary}
          onSelect={onInsuranceSelect}
          includePayerName
          includeInActive
          dateOfService={dateOfService}
          dosRequired
        />
      </div>
      <PatientClaimTable
        labels={labels}
        claimNumber={claimId}
        showRowSelection
        isPatientResponsibility={shouldShowSecondary}
        setSelectedRow={setSelectedRow}
        checkedRowIds={selectedRows}
        initialFilters={{ billingEncounterServiceLineId }}
      />
      <div className="mr-top-12">
        <p>
          How would you like the claim to be delivered?
        </p>
        <Radio
          options={parsedSubmissionOptions}
          name="deliveryMethodId"
          labelSpan="0"
          inputSpan="16"
          onChange={handleRadio}
          value={claimDelivery}
        />
      </div>
    </Modals>
  );
};
export default WithLabel(ChangeResponsibilityModal, labelPaths.CHANGE_RESPONSIBILITY_POPUP);
