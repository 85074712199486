import React from 'react';
import ICDTable from '../../../ICDLookup/ICDTable';
import useRedirect from '../../../../../../../hooks/useRedirect';

const Crosswalk = ({
  questionnaireData, onICDCheckBoxClick, labels, icdFilters,
}) => {
  const { params: { encounterId, providerId } } = useRedirect();
  return (
    <div>
      <div className="json-tree-container remove-tree-switchers">
        <div className="procedure-tab-table-wrap">
          <ICDTable
            filters={{
              CPTCode: questionnaireData?.cptCode,
              encounterId,
              providerId,
              ...icdFilters,
            }}
            showCheckBox={false}
            onRowClick={onICDCheckBoxClick}
            scrollId="crosswalk-table"
            labels={labels}
            noDataText="Select CPT to see crosswalk"
          />
        </div>
      </div>
    </div>
  );
};

export default Crosswalk;
