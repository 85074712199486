import React, { useCallback } from 'react';

import useTabLink from '../../../../hooks/useTabLink';
import useRedirect from '../../../../hooks/useRedirect';

import { UiRoutes } from '../../../../lib/constants';

import EncounterTable from './EncounterTable/index';
import { getName } from '../../../../lib/util';
import usePatientDetail from '../../../../hooks/usePatientDetail';

function EncounterList({ parentParams, labels }) {
  const { params, push, generatePath } = useRedirect();
  const { id } = params;
  const [currentPatient] = usePatientDetail({ patientId: id });

  const { navigate } = useTabLink({
    to: UiRoutes.newEncounterWithTabId,
    id: UiRoutes.editPatientWithTabId,
    params: { ...parentParams },
  });

  const navigateToViewEncounter = useCallback(({ encounterId }) => {
    push(generatePath(UiRoutes.viewEncounterWithId, { ...params, encounterId }));
  }, [push, generatePath, params]);

  const navigateToForm = useCallback(() => {
    navigate({
      data: {
        name: `New Encounter-${getName(currentPatient?.firstName, currentPatient?.middleName, currentPatient?.lastName)}`,
      },
    });
  }, [navigate, currentPatient]);

  return (
    <div className="encounter-list-table">
      <EncounterTable
        labels={labels}
        navigateToForm={navigateToForm}
        navigateToViewEncounter={navigateToViewEncounter}
        id={id}
      />
    </div>
  );
}

export default EncounterList;
