import React, { useState, useEffect, useCallback } from 'react';

import useCRUD from '../../../../../../../hooks/useCRUD';
import useRedirect from '../../../../../../../hooks/useRedirect';
import { apiUrls } from '../../../../../../../api/constants';
import Notification from '../../../../../../../components/Notification';
import Button from '../../../../../../../components/Button';

import successMessages from '../../../../../../../lib/successMessages';
import { UiRoutes } from '../../../../../../../lib/constants';
import Loader from '../../../../../../../components/Loader';

import LeftTab from '../CustomDropppableTab';
import RightTab from './RightTab';
import AddItems from '../../AddItems';

import './procedures.scss';
import useCRUDWithoutTab from '../../../../../../../hooks/useCRUDWithoutTab';
import Events from '../../../../../../../lib/events';
import { customSort } from '../../../../../../../lib/util';

const Procedures = () => {
  const {
    params: { patientId: PatientId, encounterId: EncounterID, type },
    params,
    push,
    replace,
    generatePath,
  } = useRedirect();
  const [addOption, setAddOption] = useState(null);
  const [editProcedureData, setEditProcedureData] = useState({});
  const [notesList, setNotesList] = useState([]);
  const [questionnaireData, setQuestionnaireData] = useState();

  const selectedItem = {
    assessment: {
      title: 'Assessment',
    },
    plan: {
      title: 'Plan',
    },
    note: {
      title: 'Notes',
    },
    procedure: {
      title: 'Procedure',
    },
  };
  const [notesListResponse, , notesListLoading, getNotesList] = useCRUD({ id: 'get-notes-for-encounter', url: `${apiUrls.GET_PATIENT_ENCOUNTER_BY_ID}/${EncounterID}`, type: 'read' });
  const [
    responseSaveVersionedEncounter, ,
    versionedEncounterLoading,
    saveVersionedEncounter,
    clearSaveVersionedEncounter,
  ] = useCRUD({
    id: apiUrls.SAVE_VERSIONED_ENCOUNTER,
    url: apiUrls.SAVE_VERSIONED_ENCOUNTER,
    type: 'create',
  });
  useEffect(() => {
    if (responseSaveVersionedEncounter) {
      Notification({ message: successMessages.ENCOUNTER_SAVED_SUCCESSFULLY, success: true });
      clearSaveVersionedEncounter();
      replace(generatePath(UiRoutes.doctorWithPatientIDAndAddSimpleEncounter, { ...params, type: 'savedSimpleEncounter' }));
    }
  }, [clearSaveVersionedEncounter, generatePath, params, replace, responseSaveVersionedEncounter]);

  useEffect(() => {
    if (notesListResponse) {
      setNotesList(customSort(notesListResponse?.notes));
    }
  }, [notesListResponse]);

  const notesAdded = useCallback(() => {
    getNotesList();
  }, [getNotesList]);

  const [patientProcedures, , , getDetail] = useCRUD({
    id: apiUrls.DOCTOR_PROCEDURES,
    url: apiUrls.DOCTOR_PROCEDURES,
    type: 'read',
  });

  useEffect(() => {
    getNotesList();
  }, [EncounterID, getNotesList]);

  const [diagnosis, , , getDiagnosis] = useCRUDWithoutTab({
    id: `modifierList-${EncounterID}`,
    url: `${apiUrls.GET_OCULAR_PROBLEM_LIST}/${EncounterID}`,
    type: 'read',
  });
  const [outsideTesting, , , getOutsideTesting] = useCRUD({
    id: `${apiUrls.GET_OUTSIDE_TEST}/${EncounterID}`,
    url: `${apiUrls.GET_OUTSIDE_TEST}/${EncounterID}`,
    type: 'read',
  });

  const [procedureList, setProcedureList] = useState([]);
  const [dxList, setDxList] = useState([]);
  const [labsImagingList, setLabsImagingList] = useState([]);

  useEffect(() => {
    const procedureArray = patientProcedures?.result.map((data) => ({
      ...data,
      key: data.id,
      label: data.cptDescription,
    }));
    setProcedureList(procedureArray);
  }, [patientProcedures]);

  useEffect(() => {
    if (diagnosis?.length) {
      const diagnosisArray = diagnosis.map((data) => ({
        ...data,
        key: data.id,
        label: data.description,
      }));
      setDxList(diagnosisArray);
    }
  }, [diagnosis]);

  useEffect(() => {
    if (outsideTesting?.reponse?.length) {
      const labsImagingArray = outsideTesting.reponse.map((data) => ({
        ...data,
        key: data.id,
        label: `${data?.cptCode.cptCode} - ${data?.cptCode?.cptDescription}`,
      }));
      setLabsImagingList(labsImagingArray);
    }
  }, [outsideTesting]);

  useEffect(() => {
    getDetail({
      PatientId,
      EncounterID,
      IsUnique: true,
    });
    getDiagnosis({});
    getOutsideTesting({});
  }, [EncounterID, PatientId, getDetail, getDiagnosis, getOutsideTesting]);

  const [educationalMaterialList, setEducationalMaterialList] = useState([
    {
      key: 'Educational_Material_Child_1',
      label: 'Educational Material 1',
    },
    {
      key: 'Educational_Material_Child_2',
      label: 'Educational Material 2',
    },
    {
      key: 'Educational_Material_Child_3',
      label: 'Educational Material 3',
    },
    {
      key: 'Educational_Material_Child_4',
      label: 'Educational Material 4',
    },
  ]);

  const [procedureSetsList, setProcedureSetsList] = useState([]);
  const [labelList, setLabelsList] = useState([]);

  const handleProcedureClick = useCallback((node) => () => {
    setAddOption('procedure');
    setEditProcedureData(node);
  }, []);
  const saveVersion = useCallback(() => {
    saveVersionedEncounter({ data: {} }, `/${EncounterID}`);
  }, [EncounterID, saveVersionedEncounter]);
  const editSimpleEncounter = useCallback(() => {
    push(generatePath(UiRoutes.doctorWithPatientIDAndAddSimpleEncounter, { ...params, type: 'newSimpleEncounter', encounterId: EncounterID }));
  }, [generatePath, params, push, EncounterID]);

  const printSimpleEncounter = useCallback(() => {
    push(generatePath(UiRoutes.doctorWithPatientIDAndSignedEncounterId, {
      ...params, previousEncounterId: '__null', tabId: EncounterID,
    }));
  }, [EncounterID, generatePath, params, push]);

  useEffect(() => () => Events.trigger(`refetch-InOffice-ProcedureList-${EncounterID}`), []);

  useEffect(() => {
    if (editProcedureData) {
      setQuestionnaireData({ associatedProcedureId: editProcedureData?.associatedProcedureId });
    }
  }, [editProcedureData, labelList]);

  return (
    <div className="select-box-section outer-border-panel ap-tab">
      {versionedEncounterLoading && <Loader />}
      <div className="pannel back-pannel">
        <div className="pannel-heading justify-content-sp-bt">
          <p role="presentation">Assessment & Plan</p>
          {type === 'newSimpleEncounter'
            && (
              <div>
                <Button className="btn-success sm-btn" onClick={saveVersion}>Save</Button>
              </div>
            )}
          {type === 'savedSimpleEncounter'
            && (
              <div>
                <Button className="btn-success sm-btn" onClick={editSimpleEncounter}>Edit</Button>
                <Button className="btn-success sm-btn mr-lt-12" onClick={printSimpleEncounter}>Print</Button>
              </div>
            )}
        </div>
        <div className="pannel-body">
          <div className="procedures">
            <LeftTab
              dxList={dxList}
              setDxList={setDxList}
              procedureList={procedureList}
              setProcedureList={setProcedureList}
              labsImagingList={labsImagingList}
              setLabsImagingList={setLabsImagingList}
              educationalMaterialList={educationalMaterialList}
              setEducationalMaterialList={setEducationalMaterialList}
              addOption={addOption}
              setAddOption={setAddOption}
              labelList={labelList}
              setLabelsList={setLabelsList}
              selectedItem={selectedItem}
              type="ap"
              componentType={type}
              savedSimpleEncounter={type === 'savedSimpleEncounter'}
              notesList={notesList}
              notesListLoading={notesListLoading}
              handleProcedureClick={handleProcedureClick}
              notesAdded={notesAdded}
              questionnaireData={questionnaireData}
              setQuestionnaireData={setQuestionnaireData}
            />
            {type !== 'savedSimpleEncounter' && (addOption === null
              ? (
                <RightTab
                  dxList={dxList}
                  setDxList={setDxList}
                  procedureList={procedureList}
                  setProcedureList={setProcedureList}
                  procedureSetsList={procedureSetsList}
                  setProcedureSetsList={setProcedureSetsList}
                  labsImagingList={labsImagingList}
                  setLabsImagingList={setLabsImagingList}
                  educationalMaterialList={educationalMaterialList}
                  setEducationalMaterialList={setEducationalMaterialList}
                  addOption={addOption}
                  setAddOption={setAddOption}
                  getDiagnosis={getDiagnosis}
                />
              )
              : (
                <AddItems
                  addOption={addOption}
                  selectedItem={selectedItem[addOption]}
                  setAddOption={setAddOption}
                  dxList={dxList}
                  setDxList={setDxList}
                  labelList={labelList}
                  setLabelsList={setLabelsList}
                  notesAdded={notesAdded}
                  notesList={notesList}
                  editProcedureData={editProcedureData}
                  showJCodeFields
                  questionnaireData={questionnaireData}
                  type="AAndP"
                />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Procedures;
