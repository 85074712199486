import React, { useState, useCallback, useEffect } from 'react';
import { Row, Col, Form as AntdForm } from 'antd';
import isArray from 'lodash/isArray';

import Panel from '../../../../components/Panel';
import Button from '../../../../components/Button';
import Icon from '../../../../components/Icon';
import Form from '../../../../components/Form';
import Input from '../../../../components/Form/Input';
import WidgetLoader from '../../../../components/WidgetLoader';

import EnumSelect from '../../../../wiredComponents/Enum/Select';
import ProviderGridAutoComplete from '../../../../wiredComponents/ProviderGridAutoComplete';
import ReferringPhysicianGridAutoComplete from '../../../../wiredComponents/ReferringPhysicianGridAutoComplete';

import iconNames from '../../../../lib/iconNames';
import { enums } from '../../../../lib/constants';
import { apiUrls } from '../../../../api/constants';
import ErrorMessages from '../../../../lib/errorMessages';

import useRedirect from '../../../../hooks/useRedirect';
import useCRUD from '../../../../hooks/useCRUD';

import './referralPanel.scss';
import { getTimeString, lengthCheck } from '../../../../lib/util';
import formFieldValuesParser from '../../../../lib/formFieldValuesParser';

const getReferralString = (referral) => {
  const data = [];
  if (referral.referToInternalProviderName || referral.referToOutsideProviderName) {
    data.push(
      (
        <span>
          Referred to
          {' '}
          {referral.referToInternalProviderName || referral.referToOutsideProviderName}
          {' '}
          (
          {referral.providerSpecialityName}
          )
        </span>
      ),
    );
  } else {
    data.push(
      (
        <span>
          Referred to
          {' '}
          {referral.providerSpecialityName}
        </span>
      ),
    );
  }
  if (referral.referralReason) {
    data.push(
      (
        <span>
          {' '}
          for
          {' '}
          {referral.referralReason}
          .
        </span>
      ),
    );
  }
  if (referral.month || referral.week || referral.days) {
    data.push(
      (
        <p>
          Patient must be seen within
          {' '}
          {getTimeString(0, referral.month, referral.week, referral.days)}
          .
        </p>
      ),
    );
  }
  return data;
};

const ReferralsPanel = ({ setVisible, labels }) => {
  const {
    params: {
      patientId,
      providerId,
      encounterId,
    },
  } = useRedirect();

  const [form] = AntdForm.useForm();
  const [outsideProvider, setOutsideProvider] = useState(false);
  const [updateId, setUpdateId] = useState('');
  const [internalProvider, setInternalProvider] = useState({});
  const [outsideProviderValue, setOutsideProviderValue] = useState({});
  const [specialtyId, setSpecialtyId] = useState({});
  const [refereedProviderId, setRefereedProviderId] = useState(null);
  const [referralMasterCode, setReferralMasterCode] = useState();
  const [subSpeciality, setSubSpeciality] = useState(null);

  const [referralList, , referralLoading, getReferralList] = useCRUD({
    id: 'referralList', url: 'doctorservice/PatientEcounterReferralPhysician/ReferralList', type: 'read',
  });

  const onReferToChange = useCallback((value, all) => {
    form.setFieldsValue({ referToInternalProviderId: undefined });
    if (all?.item?.masterCode?.toLowerCase() === 'outsidereferrals') {
      setOutsideProvider(true);
      setRefereedProviderId(null);
    } else if (outsideProvider) setOutsideProvider(false);
    setSpecialtyId({ speciality: value });
    setReferralMasterCode(all?.item?.masterCode);
  }, [form, outsideProvider]);

  const handleAdd = useCallback(() => {
    setUpdateId('');
    setOutsideProvider(false);
    setInternalProvider({});
    setOutsideProviderValue({});
    setRefereedProviderId(null);
    form.resetFields();
  }, [form]);

  const handleEdit = useCallback((referral) => () => {
    const {
      referralId,
      providerSpecialityId,
      referToInternalProviderName,
      referToInternalProviderId,
      providerSpecialityMasterCode,
      referToOutsideProvider,
      referToOutsideProviderName,
    } = referral;
    if (`${referralId}`) {
      onReferToChange(providerSpecialityId, { item: { masterCode: providerSpecialityMasterCode } });
      setUpdateId(`${referralId}`);
      setSubSpeciality(referral?.subSpecialties?.map((item) => item?.id));
      form.setFieldsValue({
        ...referral,
        subSpecialityIds: referral?.subSpecialties?.map((item) => item?.id),
      });
      setInternalProvider({
        ...referral,
        value: referToInternalProviderId,
        name: referToInternalProviderName,
      });
      setOutsideProviderValue({
        value: referToOutsideProvider,
        name: referToOutsideProviderName,
      });
      setRefereedProviderId(referToInternalProviderId);
    }
  }, [form, onReferToChange]);

  const getReferrals = useCallback(() => {
    handleAdd();
    getReferralList({
      patientId,
      providerId,
      encounterId,
    });
  }, [handleAdd, getReferralList]);

  useEffect(() => {
    if (setVisible) getReferrals();
  }, [setVisible]);

  const handleCancel = useCallback((reset) => () => {
    reset();
    setUpdateId('');
    setOutsideProvider(false);
    setInternalProvider({});
    setOutsideProviderValue({});
    setRefereedProviderId(null);
  }, []);

  const parser = useCallback((values) => formFieldValuesParser(values), []);

  return (
    <Panel
      title="Referral"
      onClose={setVisible}
      className="referral-panel posi-relative"
    >
      <Form
        form={form}
        formId="test"
        url={`${apiUrls.DOCTOR_FOLLOW_UP_REFERRAL}/${updateId}`}
        extraData={{
          patientId: parseInt(patientId, 10),
          providerId: parseInt(providerId, 10),
          encounter_id: parseInt(encounterId, 10),
          method: updateId ? 'put' : 'post',
          referToInternalProviderId: parseInt(refereedProviderId, 10),
          providerSpecialityMasterCode: referralMasterCode,
          SubSpecialtyIds: isArray(subSpeciality) && subSpeciality?.length ? subSpeciality?.join(',') : null,
        }}
        parser={parser}
        onRequestComplete={getReferrals}
        loaderComponent={WidgetLoader}
      >
        {({ resetForm }) => (
          <>
            <div className="width-350">
              <Row>
                <span className="item-label mr-bt-3">{labels.get('labels.referTo')}</span>
              </Row>
              <EnumSelect
                form={form}
                required
                name="providerSpecialityId"
                labelSpan="0"
                inputSpan="24"
                enumName={enums.PROVIDER_SPECIALTY}
                onChange={onReferToChange}
                label={labels.get('labels.referTo')}
              />
              <Row>
                <span className="item-label mr-bt-3">{labels.get('labels.subspeciality')}</span>
              </Row>
              <div className="input-wrap sub-special-wrap">
                <EnumSelect
                  name="subSpecialityIds"
                  id="9"
                  enumName={enums.SUB_SPECIALITY}
                  form={form}
                  labelSpan="0"
                  inputSpan="24"
                  onChange={setSubSpeciality}
                  initialValue={subSpeciality}
                  selectProps={{
                    showArrow: true,
                    showSearch: false,
                    mode: 'multiple',
                    maxTagCount: 'responsive',
                  }}
                />
              </div>
              <Row>
                <span className="item-label mr-bt-3">{labels.get('labels.provider')}</span>
              </Row>
              {outsideProvider
                ? (
                  <ReferringPhysicianGridAutoComplete
                    name="referToOutsideProvider"
                    form={form}
                    labelSpan="0"
                    inputSpan="24"
                    required
                    label={labels.get('labels.provider')}
                    initialValue={outsideProviderValue}
                  />
                )
                : (
                  <ProviderGridAutoComplete
                    name="referToInternalProviderId"
                    inputSpan="24"
                    labelSpan="0"
                    initialValue={internalProvider}
                    params={specialtyId}
                    resetOnParamChange
                    placeholder="Search Provider"
                  />
                )}
              <Row>
                <span className="item-label mr-bt-3">{labels.get('labels.referralReason')}</span>
              </Row>
              <Input name="referralReason" labelSpan="0" inputSpan="24" label={labels.get('labels.referralReason')} placeholder="Referral Reason" maxValueLength={1000} />
              <Row>
                <span className="item-label mr-bt-3">{labels.get('labels.mustBeSeenWithin')}</span>
              </Row>
              <Row>
                <Col span={8}>
                  <Input
                    numberOnly
                    label={labels.get('labels.monthsLabel')}
                    name="month"
                    labelAfter
                    labelSpan="10"
                    inputSpan="10"
                    placeholder="xx"
                    rules={[
                      () => lengthCheck(
                        2, ErrorMessages.VALUE_CANNOT_BE_LONGER_THAN_2_DIGIT,
                      ),
                    ]}
                  />
                </Col>
                <Col span={7}>
                  <Input
                    numberOnly
                    label={labels.get('labels.weeksLabel')}
                    name="week"
                    labelAfter
                    labelSpan="11"
                    inputSpan="11"
                    placeholder="xxx"
                    rules={[
                      () => lengthCheck(
                        3, ErrorMessages.VALUE_CANNOT_BE_LONGER_THAN_3_DIGIT,
                      ),
                    ]}
                  />
                </Col>
                <Col span={9}>
                  <Input
                    numberOnly
                    label={labels.get('labels.daysLabel')}
                    name="days"
                    labelSpan="14"
                    labelAfter
                    inputSpan="8"
                    placeholder="xxx"
                    rules={[
                      () => lengthCheck(
                        3, ErrorMessages.VALUE_CANNOT_BE_LONGER_THAN_3_DIGIT,
                      ),
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <span className="item-label mr-bt-8">{labels.get('labels.notesForFrontDesk')}</span>
              </Row>
              <Input.TextArea
                name="noteToHelpdesk"
                labelSpan="0"
                inputSpan="24"
                maxValueLength={1000}
                rows={4}
                placeholder="Notes"
              />
            </div>
            <div className="flex referral-buttons">
              <Button className="btn btn-success sm-btn" type="submit">{updateId ? labels.get('buttons.update') : labels.get('buttons.save')}</Button>
              <Button className="mr-lt-10 sm-btn" onClick={handleCancel(resetForm)}>{labels.get('buttons.cancel')}</Button>
            </div>
            <div className="referrals">
              {referralLoading
                && <WidgetLoader />}
              {referralList && referralList.map((referral, index) => (
                <div className="referral-list mr-bt-16" key={referral.referralId}>
                  <div className="detail-wrapper">
                    <div className="name">{`Referral ${index + 1}`}</div>
                    <div className="desc">
                      {getReferralString(referral)}
                    </div>
                  </div>
                  <div className="actions">
                    <div className="space cursor-pointer">
                      <Icon name={iconNames.edit} onClick={handleEdit(referral)} />
                    </div>
                    {/* <div className="space cursor-pointer">
                <Icon name={iconNames.deleteIcon} onClick={handleDelete(referral)} />
              </div> */}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </Form>
    </Panel>
  );
};

export default ReferralsPanel;
