import React from 'react';

import TimeCell from '../../../../../components/Cells/TimeCell';

const columns = (labels) => [

  {
    Header: labels.get('tableColumns.effectiveFrom'),
    accessor: 'effectiveDateFrom',
    Cell: (e) => <TimeCell value={e?.row?.original?.effectiveDateFrom} />,
  },
  {
    Header: labels.get('tableColumns.effectiveTo'),
    accessor: 'effectiveDateTo',
    Cell: (e) => <TimeCell value={e.row.original?.effectiveDateTo} />,
  },
  {
    Header: labels.get('tableColumns.authorization'),
    accessor: 'name',
  },
  {
    Header: labels.get('tableColumns.number'),
    accessor: 'authorizationNo',
  },
  {
    Header: labels.get('tableColumns.unitsAllowed'),
    accessor: 'unitsAllowed',
  },
  {
    Header: labels.get('tableColumns.unitsConsumed'),
    accessor: 'unitsConsumed',
  },
];

export default columns;
