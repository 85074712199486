import React, { useCallback } from 'react';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

import FilterComponents from '../../../../components/FilterComponents';
import Icon from '../../../../components/Icon';

export const StatusCell = ({ status }) => {
  const statusList = [
    { label: 'Pending', className: 'cell-pending' },
    { label: 'Approved', className: 'cell-approved' },
    { label: 'Declined', className: 'cell-declined' },
  ];
  return (<span className={statusList[status]?.className}>{statusList[status]?.label}</span>);
};

export const columns = () => [
  {
    Header: 'Description Synonym',
    accessor: 'synonym',
  },
  {
    Header: 'Provider',
    accessor: 'providerName',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: (e) => <StatusCell status={e?.row?.original?.status} />,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    fixWidth: '150',
    Cell: ({
      row: { original }, onEditSynonym, onDeleteSynonym, onAcceptSynonym,
      onRejectSynonym, accessToAcceptRejectSynonym,
    }) => {
      const onEdit = useCallback(() => onEditSynonym(original), [original, onEditSynonym]);
      const onDelete = useCallback(() => onDeleteSynonym(original), [original, onDeleteSynonym]);
      const onAccept = useCallback(() => onAcceptSynonym(original), [original, onAcceptSynonym]);
      const onReject = useCallback(() => onRejectSynonym(original), [original, onRejectSynonym]);

      if (!accessToAcceptRejectSynonym) {
        return <span />;
      }
      if (original?.status === 0) {
        return (
          <span className="actions-container">
            <button className="btn btn-success action-button" type="button" onClick={onAccept}>Accept</button>
            <button className="btn action-button" type="button" onClick={onReject}>Reject</button>
            <Icon onClick={onEdit} name="EditOutlined" />
          </span>
        );
      }
      if (original?.status === 1) {
        return (
          <span className="actions-container">
            <Icon onClick={onDelete} name="DeleteOutlined" />
          </span>
        );
      }
      return <span />;
    },
  },
];

export const FilterCollection = FilterComponents([
  {
    type: 'search',
    filterProps: {
      placeholder: 'Search ICD',
      name: 'ICDCodeText',
      id: 'icd_filter_search',
    },
  },
  {
    type: 'checkbox',
    filterProps: {
      name: 'Pending',
      id: 'Pending',
      label: 'Pending',
    },
  },
  {
    type: 'checkbox',
    filterProps: {
      name: 'Approved',
      id: 'Approved',
      label: 'Approved',
    },
  },
  {
    type: 'checkbox',
    filterProps: {
      name: 'Declined',
      id: 'Declined',
      label: 'Declined',
    },
  },
]);

export const CPTFilterCollection = FilterComponents([
  {
    type: 'search',
    filterProps: {
      placeholder: 'Search CPT',
      name: 'CPTCodeText',
      id: 'icd_filter_search',
    },
  },
  {
    type: 'checkbox',
    filterProps: {
      name: 'Pending',
      id: 'Pending',
      label: 'Pending',
    },
  },
  {
    type: 'checkbox',
    filterProps: {
      name: 'Approved',
      id: 'Approved',
      label: 'Approved',
    },
  },
  {
    type: 'checkbox',
    filterProps: {
      name: 'Declined',
      id: 'Declined',
      label: 'Declined',
    },
  },
]);

export const commonCPTICDColumns = () => [
  {
    Header: '',
    accessor: 'expander',
    fixWidth: '50',
    Cell: ({ row }) => (
      <span role="presentation" {...row.getToggleRowExpandedProps()}>
        {row.isExpanded
          ? <MinusOutlined /> : <PlusOutlined />}
      </span>
    ),
  },
];

