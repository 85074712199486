import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import moment from 'moment';
import get from 'lodash/get';
import keys from 'lodash/keys';
import isObject from 'lodash/isObject';

import { useDispatch, useSelector } from 'react-redux';
import { deleteTabData } from '../../../../../../store/actions/navigation';
import { apiUrls } from '../../../../../../api/constants';
import useRedirect from '../../../../../../hooks/useRedirect';
import { formId, listId } from '../../../../../../lib/constants';
import successMessages from '../../../../../../lib/successMessages';
import ErrorMessages from '../../../../../../lib/errorMessages';
import ConfirmDialog from '../../../../../../components/ConfirmDialog';
import Icon from '../../../../../../components/Icon';

import Form from '../../../../../../components/Form';
import Input from '../../../../../../components/Form/Input';
import Notification from '../../../../../../components/Notification';

import WiredSelect from '../../../../../../wiredComponents/Select';

import useCRUD from '../../../../../../hooks/useCRUD';
import useCRUDWithoutTab from '../../../../../../hooks/useCRUDWithoutTab';

import ConfirmationHistoryTable from './ConfirmationHistoryTable';
import encode from '../../../../../../lib/encode';
import Events from '../../../../../../lib/events';

const initialDataParser = (data) => ({ ...data, totalDuration: data?.totalDoctorTime });

const SchedularInfo = ({
  labels,
  mode,
  form,
  insuranceId,
  authorizationId,
  referredBy,
  patientId,
  appointmentStartTime,
  toggle,
  doctor,
  location,
  testType,
  visitType,
  appointmentDetail,
  insuranceForm,
  setAppointmentDetail,
  reFetchData,
  appointmentId,
  // isRepeatingAppointment,
}) => {
  const [extraData, setExtraData] = useState({});
  const [isLilyLee, setIsLilyLee] = useState(false);
  const [initialSupervisingProviderFetch, setInitialSupervisingProviderFetch] = useState(true);
  const supressWarningRef = useRef(false);

  const { query, replace, path } = useRedirect();
  const dispatch = useDispatch();
  const patients = useSelector((state) => state?.navigation?.get('patients'));
  const [durationResponse, ,, getDuration] = useCRUD({ id: 'appointment-duration', url: apiUrls.GET_APPOINTMENT_DURATION, type: 'read' });
  const clearPatientData = useCRUDWithoutTab({
    id: `patient-detail-${patientId}`,
  })[4];

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      const successMessage = appointmentDetail && appointmentDetail.appointmentId
        ? successMessages.APPOINTMENT_UPDATED_SUCCESSFULLY
        : successMessages.APPOINTMENT_ADDED_SUCCESSFULLY;
      Notification({ message: successMessage, success: true });
      reFetchData();
      if (referredBy) {
        clearPatientData(true);
        if (patients) {
          Object.keys(patients || {}).forEach((key) => {
            if (patients[key]?.tabId && patients[key]?.tabId !== 'parent' && patients[key]?.path?.includes('demographic')) {
              dispatch(deleteTabData({ tabId: patients[key].tabId }));
            }
          });
        }
      }
      replace({
        search: encode({ ...query, patient: null }),
        pathname: path,
      });
      toggle(false);
      supressWarningRef.current = false;
      Events.trigger('refetchSchedulerAppointmentListViewTable');
    }
  }, [appointmentDetail, clearPatientData, dispatch, path,
    patients, query, reFetchData, referredBy, replace, toggle]);

  const onRequestFail = useCallback((message) => {
    if (message?.[0] === ErrorMessages.P_VERIFY_PENDING_ERROR
      || message?.[0] === ErrorMessages.P_VERIFY_NOT_VERIFIED_ERROR) {
      ConfirmDialog({
        onOk: (close) => {
          supressWarningRef.current = true;
          form.submit();
          close();
        },
        onCancel: (close) => {
          close();
        },
        okText: 'Continue',
        title: 'Warning',
        content: message?.[0],
        icon: <Icon name="ExclamationCircleOutlined" />,
      })();
    } else if (message?.length) {
      Notification({ message: message?.[0], success: false });
      supressWarningRef.current = false;
    }
  }, [form]);

  const handleLilyLeeVisitTypes = useCallback((visitName = '') => {
    setIsLilyLee(visitName?.toLowerCase()?.includes('lily'));
  }, []);

  const onGetResponseComplete = useCallback((data) => {
    form.setFieldsValue({ appointmentStatusId: data?.statusId });
    setAppointmentDetail({ ...data, appointmentStatusId: data?.statusId });
    handleLilyLeeVisitTypes(data?.visitType?.visitName);
  }, [form, setAppointmentDetail, handleLilyLeeVisitTypes]);

  useEffect(() => {
    if (doctor && (visitType || testType) && (mode !== 'edit')) {
      const examTypes = testType;
      const params = {
        providerId: get(doctor, 'providerId'),
        visitTypeId: get(visitType, 'visitTypeId'),
        examTypeId: examTypes && examTypes.map((item) => item.examTypeId).join(','),
      };
      if (params?.visitTypeId) {
        getDuration(params);
      }
    }
  }, [visitType, testType, getDuration, doctor]);

  useEffect(() => {
    if (isObject(durationResponse)) {
      form.setFieldsValue({ totalDuration: get(durationResponse, 'duration') });
    }
  }, [durationResponse, form]);

  useEffect(() => {
    let { providerName } = doctor;
    const {
      providerFirstName, providerLastName, providerMiddleName, providerId,
    } = appointmentDetail;
    if (mode === 'edit') {
      providerName = `${providerFirstName || ''} ${providerMiddleName || ''} ${providerLastName || ''}`;
    }
    let ids = [];
    if (keys(appointmentDetail).length) {
      ids = get(appointmentDetail, 'examType', []).map((item) => item.examTypeId);
      if (providerId && !isLilyLee && get(appointmentDetail, 'visitType.visitTypeId')) {
        const params = {
          providerId: get(appointmentDetail, 'providerId'),
          examTypeId: ids.join(','),
          visitTypeId: get(appointmentDetail, 'visitType.visitTypeId'),
        };
        getDuration(params);
      }
      form.setFieldsValue({
        examTypeId: ids.length ? ids : appointmentDetail.examTypeId,
        providerName,
        location: get(appointmentDetail, 'locationName') || get(appointmentDetail, 'location'),
        visitTypeId: get(appointmentDetail, 'visitType.visitTypeId') || get(appointmentDetail, 'visitTypeId'),
      });
    }
  }, [testType, appointmentDetail, form, mode, doctor, visitType, getDuration]);

  useEffect(() => {
    const data = {};
    if (insuranceId) {
      data.insuranceId = insuranceId;
    }
    if (authorizationId) {
      data.authorizationId = authorizationId;
    }
    if (referredBy) {
      data.referredBy = referredBy;
    }
    setExtraData(data);
  }, [insuranceId, authorizationId, referredBy]);

  const onExamTypeChange = useCallback((value, allValues) => {
    const visitTypeId = form.getFieldValue('visitTypeId');
    const examTypeIds = allValues && allValues.map(({ item }) => item.examTypeId);
    const params = {
      providerId: get(doctor, 'providerId'),
      visitTypeId,
      examTypeId: examTypeIds && examTypeIds.join(','),
    };
    form.setFieldsValue({ examTypeId: examTypeIds });
    if (visitTypeId) {
      getDuration(params);
    } else {
      Notification({ message: ErrorMessages.VISIT_TYPE_IS_REQUIRED });
    }
  }, [doctor, form, getDuration]);

  const onVisitTypeChange = useCallback((value, all = {}) => {
    const examTypes = form.getFieldValue('examTypeId') || [];
    if (get(all.item, 'visitTypeId')) {
      const params = {
        providerId: get(doctor, 'providerId'),
        visitTypeId: get(all.item, 'visitTypeId'),
        examTypeId: examTypes.join(','),
      };
      getDuration(params);
      handleLilyLeeVisitTypes(all?.item?.visitName);
    }
  }, [doctor, form, getDuration, handleLilyLeeVisitTypes]);

  const parseValue = useCallback((values) => {
    const insuranceDetails = insuranceForm.getFieldsValue() || {};
    if (supressWarningRef.current) {
      insuranceDetails.SupressPverifyWarning = supressWarningRef.current;
    }
    return {
      confirmationMethod: appointmentDetail?.confirmationMethod,
      confirmationMethodId: appointmentDetail?.confirmationMethodId,
      confirmationOutcome: appointmentDetail?.confirmationOutcome,
      confirmationOutcomeId: appointmentDetail?.confirmationOutcomeId,
      note: appointmentDetail?.note,
      ...values,
      ...insuranceDetails,
      examTypeId: values?.examTypeId?.length ? values?.examTypeId?.join(',') : null,
    };
  }, [appointmentDetail, insuranceForm]);

  const validator = useCallback((formData) => {
    const insuranceInstance = insuranceForm.getFieldInstance('insurancedetailId');
    const profileType = insuranceInstance?.props?.selectedInsurance?.item?.profileType;
    const isSelf = (profileType || '').toLowerCase() === 'self';
    if (isSelf) {
      // eslint-disable-next-line no-param-reassign
      formData.insuranceId = formData.insurancedetailId;
      // eslint-disable-next-line no-param-reassign
      delete formData.insurancedetailId;
    }
    if (!formData.insuranceId) {
      insuranceForm.setFields([{
        name: 'insurancedetailId',
        value: null,
        errors: ['Insurance is Required'],
      }]);
      return false;
    }
    if (parseInt((formData?.totalDuration || 0), 10) % 5) {
      Notification({ message: ErrorMessages.DURATION_FACTOR_OF(5) });
      return false;
    }
    // isRepeatingAppointment returns true if duplicates are present
    // return !isRepeatingAppointment(formData);
    return true;
  }, [insuranceForm]);

  useEffect(() => {
    if (visitType && mode === 'add') {
      handleLilyLeeVisitTypes(visitType?.visitName);
    }
  }, []);

  const handleOptionRenderer = useCallback((data) => {
    setInitialSupervisingProviderFetch(false);
    return data;
  }, []);

  return (
    <Form
      form={form}
      refetchId="refetchAppointmentDetails"
      url={apiUrls.ADD_APPOINTMENT}
      section
      formId={formId.ADD_APPOINTMENT}
      onRequestComplete={onRequestComplete}
      onRequestFail={onRequestFail}
      scrollToFirstErrors
      listId={listId.ADD_APPOINTMENT}
      isUpdate={mode === 'edit'}
      getUrl={`${apiUrls.GET_APPOINTMENT_DETAIL}/${appointmentId}`}
      onGetResponseComplete={onGetResponseComplete}
      parser={parseValue}
      initialDataParser={initialDataParser}
      initialData={{
        providerName: doctor.providerName,
        location: location && location.locationName,
        visitTypeId: visitType && visitType.visitTypeId,
        examTypeId: testType ? testType.map((item) => item.examTypeId) : [],
        supervisingProviderId: doctor?.providerTypeName?.toLowerCase() === 'provider' ? doctor?.providerId : undefined,
      }}
      extraData={{
        ...extraData,
        patientId,
        appointmentStartTime: moment(appointmentStartTime).format('YYYY-MM-DD HH:mm:ss'),
        offset: moment(appointmentStartTime).utcOffset(),
        providerId: doctor.providerId,
        locationId: location?.locationId || location?.location?.locationId,
        appointmentId: appointmentDetail && appointmentDetail.appointmentId,
      }}
      validator={validator}
    >
      <div className="appointment-lt-wrapper">
        <div className="appointment-scheduler-wrap">
          <div className="card-header-title">
            <p>{labels.get('titles.schedularInfo')}</p>
          </div>
          <div className="schedular-feild-area">
            <div className="schedular-feild-wraper">
              <div className="left-feilds">
                <div className="feild-wrap">
                  <Input
                    id="providerId"
                    name="providerName"
                    // url={apiUrls.GET_PROVIDERS}
                    label="Resource/Provider"
                    labelSpan="8"
                    inputSpan="16"
                    disabled
                    dataTestId="providerName"
                    placeholder="Enter Resource/Provider"
                  />
                </div>
                <div className="feild-wrap">
                  <WiredSelect
                    id="visitType"
                    name="visitTypeId"
                    url={apiUrls.GET_VISIT_TYPES}
                    label={labels.get('labels.visitType')}
                    nameAccessor="visitName"
                    valueAccessor="visitTypeId"
                    labelSpan="8"
                    inputSpan="16"
                    required
                    onChange={onVisitTypeChange}
                    // parser={(
                    //   data,
                    // ) => data.filter((item) => item.doctorTime <= visitType.doctorTime)}
                  />
                </div>
                <div className="feild-wrap">
                  <WiredSelect
                    id="examType"
                    name="examTypeId"
                    label={labels.get('labels.testType')}
                    url={apiUrls.GET_EXAM_TYPES}
                    nameAccessor="examName"
                    valueAccessor="examTypeId"
                    labelSpan="8"
                    inputSpan="16"
                    onChange={onExamTypeChange}
                    selectProps={{
                      mode: 'multiple',
                      maxTagCount: 'responsive', // maxTagCount : 1
                      style: { minWidth: 150 },
                    }}
                    placeholder="Enter Test Type"
                  />
                </div>
                <div className="feild-wrap">
                  <WiredSelect
                    id={`supervisingProviderId-${doctor?.providerId}-${location?.locationId
                      || location?.location?.locationId}`}
                    name="supervisingProviderId"
                    url={apiUrls.GET_SUPERVISING_PROVIDERS}
                    label="Provider"
                    placeholder="Provider"
                    nameAccessor="supervisingProviderName"
                    valueAccessor="supervisingProviderId"
                    startCaseOptions
                    selectProps={{
                      style: { minWidth: 150 },
                    }}
                    params={{
                      ResourceId: doctor?.providerId,
                      LocationId: location?.locationId || location?.location?.locationId,
                    }}
                    labelSpan="8"
                    inputSpan="16"
                    defaultSorting={false}
                    required
                    updateData={initialSupervisingProviderFetch}
                    optionRenderer={handleOptionRenderer}
                  />
                </div>
              </div>
              <div className="right-feilds">
                <div className="feild-wrap">
                  <Input
                    label={labels.get('labels.duration')}
                    name="totalDuration"
                    id="6"
                    disabled={!isLilyLee}
                    labelSpan="8"
                    inputSpan="16"
                    rules={[({ getFieldValue }) => ({
                      validator() {
                        if (getFieldValue('totalDuration') && getFieldValue('totalDuration') % 5) {
                          return Promise.reject(ErrorMessages.DURATION_FACTOR_OF(5));
                        }
                        return Promise.resolve();
                      },
                    })]}
                    placeholder="Enter Duration"
                  />
                </div>
                <div className="feild-wrap">
                  <Input
                    label={labels.get('labels.location')}
                    name="location"
                    disabled
                    id="7"
                    labelSpan="8"
                    inputSpan="16"
                    placeholder="Enter Location"
                  />
                </div>
                <div className="feild-wrap">
                  <Input
                    label="Reason For Visit"
                    name="reasonforVisit"
                    id="8"
                    // disabled={mode === 'edit'}
                    labelSpan="8"
                    inputSpan="16"
                    placeholder="Enter Reason For Visit"
                  />
                </div>
              </div>
            </div>
            <div className="textarea-wrappers">
              <div className="feild-wrap">
                <Input.TextArea
                  label={labels.get('labels.comments')}
                  name="comments"
                  id="9"
                  // disabled={mode === 'edit'}
                  maxValueLength={300}
                  autoSize={{ minRows: 1, maxRows: 3 }}
                  labelSpan="12"
                  inputSpan="24"
                  placeholder="Enter Comments"
                />
              </div>
              <div className="feild-wrap">
                <Input.TextArea
                  label={labels.get('labels.patientInstructions')}
                  name="patientInstructions"
                  id="10"
                  // disabled={mode === 'edit'}
                  maxValueLength={300}
                  autoSize={{ minRows: 1, maxRows: 3 }}
                  labelSpan="12"
                  inputSpan="24"
                  placeholder="Enter Patient Instructions"
                />
              </div>
            </div>
          </div>
        </div>
        {mode === 'edit' && (
        <div className="appointment-scheduler-wrap">
          <div className="card-header-title">
            <p>{labels.get('titles.confirmationHistory')}</p>
          </div>
          <div className="schedular-feild-area app-table">
            <ConfirmationHistoryTable
              labels={labels}
              patientId={patientId}
              appointmentId={appointmentId}
            />
          </div>
        </div>
        )}
      </div>
    </Form>
  );
};

export default SchedularInfo;
