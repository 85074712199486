import React from 'react';
import { Comment, Tooltip, Avatar } from 'antd';
import moment from 'moment';

import './commentCard.scss';

const CommentCard = ({
  comment = '', date, dataTestId, name = '_ _',
}) => (
  <Comment
    data-testid={dataTestId}
    author={`Comment by ${name}`}
    avatar={(
      <Avatar
        src={`${window.location.origin}/images/commentAvatar.gif`}
        alt={`Comment by ${name}`}
      />
      )}
    content={(
      <p>
        {comment}
      </p>
      )}
    datetime={(
      <Tooltip title={date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : moment().fromNow('YYYY-MM-DD HH:mm:ss')}>
        <span>{date ? moment(date).fromNow() : moment().fromNow()}</span>
      </Tooltip>
      )}
  />
);

export default CommentCard;
