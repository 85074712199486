import React, { useCallback, useEffect } from 'react';
import set from 'lodash/set';

import SelectBox from '../../../../components/OSODSelect';
import useReduxStoreWithId from '../../../../hooks/useReduxStoreWithId';
import useRedirect from '../../../../hooks/useRedirect';

const SelectWrapper = ({
  question, examId, scrollId, handleValues, values, savedValues, displayOnly,
}) => {
  const { params: { encounterId } } = useRedirect();
  const [savedSelectedValue,, setFormData] = useReduxStoreWithId({ id: displayOnly ? `slit-exam-${examId}-${encounterId}-${displayOnly}` : `slit-exam-${examId}-${encounterId}` });
  const formData = savedSelectedValue?.get('data') || {};

  const onChange = useCallback((name, value) => {
    const formValues = { ...value };
    if (formValues?.answers?.includes('Ptosis ') && formValues?.answers?.includes('MRD ')) {
      formValues.answers = formValues.answers.replace('MRD ', '');
    }
    if (formValues?.answers?.includes('Geographic atrophy ') && formValues?.answers?.includes('subfoveal')) {
      formValues.answers = formValues.answers.replace(', subfoveal', 'Y');
    }
    if (formValues?.answers?.includes('Geographic atrophy ') && formValues?.answers?.includes('not subfoveal')) {
      formValues.answers = formValues.answers.replace(', not subfoveal', 'N');
    }
    const updated = { ...formData, [name]: value };
    setFormData(updated);
    const clonedValues = { OD: {}, OS: {}, ...values };
    set(clonedValues, `${name}[${examId}]`, [{ Key: question.Key, answers: [{ Key: question.Key, Answer: formValues.answers }] }]);
    handleValues(clonedValues);
  }, [examId, formData, handleValues, question.Key, setFormData, values]);

  useEffect(() => {
    const OD = savedValues.OD && savedValues.OD[examId];
    const OS = savedValues.OS && savedValues.OS[examId];
    const parsedOD = {};
    const parsedOS = {};
    if (OD) {
      OD.forEach((od) => {
        if (od.answers) {
          let answers = '';
          od.answers.forEach((item) => {
            if (answers) {
              answers += ',';
            }

            if (item?.Answer?.includes('Ptosis ') && item?.Answer?.includes('mm')) {
              answers += `${item?.Answer?.slice(0, 6)} ${item?.Answer?.slice(7)}`;
            } else if (item?.Answer?.includes('Geographic atrophy ') && item?.Answer?.includes('Y')) {
              answers += item?.Answer?.replace('Y', ', subfoveal');
            } else if (item?.Answer?.includes('Geographic atrophy ') && item?.Answer?.includes('N')) {
              answers += item?.Answer?.replace('N', ', not subfoveal');
            } else {
              answers += item.Answer;
            }

            if (item.SelectedGradingscale && item.SelectedGradingscale.length) {
              answers = `${answers} ${item.SelectedGradingscale.join(' - ')}`;
            }
          });
          parsedOD.answers = answers;
          parsedOD.examId = examId;
        }
      });
    }
    if (OS) {
      OS.forEach((os) => {
        if (os.answers) {
          let answers = '';
          os.answers.forEach((item) => {
            if (answers) {
              answers += ',';
            }

            if (item?.Answer?.includes('Ptosis ') && item?.Answer?.includes('mm')) {
              answers += `${item?.Answer?.slice(0, 6)} ${item?.Answer?.slice(7)}`;
            } else if (item?.Answer?.includes('Geographic atrophy ') && item?.Answer?.includes('Y')) {
              answers += item?.Answer?.replace('Y', ', subfoveal');
            } else if (item?.Answer?.includes('Geographic atrophy ') && item?.Answer?.includes('N')) {
              answers += item?.Answer?.replace('N', ', not subfoveal');
            } else {
              answers += item?.Answer;
            }

            if (item.SelectedGradingscale && item.SelectedGradingscale.length) {
              answers = `${answers} ${item.SelectedGradingscale.join(' - ')}`;
            }
          });
          parsedOS.answers = answers;
          parsedOS.examId = examId;
        }
      });
    }
    setFormData({ OS: parsedOS, OD: parsedOD });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examId, savedValues]);

  const handleArrowClick = useCallback((fromField, toField) => {
    const clonedValues = { OD: {}, OS: {}, ...values };
    setFormData({ ...formData, [toField]: formData[fromField] });
    set(clonedValues, `${toField}[${examId}]`, clonedValues[fromField][examId]);
    handleValues(clonedValues);
  }, [values, setFormData, formData, examId, handleValues]);

  const OStoOD = useCallback(() => handleArrowClick('OS', 'OD'), [handleArrowClick]);
  const ODtoOS = useCallback(() => handleArrowClick('OD', 'OS'), [handleArrowClick]);

  return (
    <>
      <div className="select-box one">
        <div className="ant-select">
          <SelectBox
            id="5"
            name="OD"
            placeholder="Select an option"
            inputSpan="23"
            labelSpan="1"
            options={question.Options}
            nameAccessor="Value"
            valueAccessor="Value"
            gradingAccessor="Gradingscale"
            onValueChange={onChange}
            initialValue={formData.OD}
            questionKey={question.Key}
            scrollId={scrollId}
          />
        </div>
        <div className="right-arrow" role="presentation" onClick={ODtoOS} />
      </div>
      <div className="select-box two">
        <div className="ant-select">
          <SelectBox
            id="5"
            name="OS"
            placeholder="Select an option"
            inputSpan="23"
            labelSpan="1"
            options={question.Options}
            nameAccessor="Value"
            valueAccessor="Value"
            gradingAccessor="Gradingscale"
            onValueChange={onChange}
            initialValue={formData.OS}
            questionKey={question.Key}
            scrollId={scrollId}
          />
        </div>
        <div className="right-arrow" role="presentation" onClick={OStoOD} />
      </div>
    </>
  );
};

export default SelectWrapper;
