import { useEffect } from 'react';

import Events from '../../../../../lib/events';

const TableWrapper = ({ Component, reFetch }) => {
  useEffect(() => {
    Events.on('refetchRemittanceTable', 'refetchRemittanceTable', reFetch);
    return () => Events.remove('refetchRemittanceTable', 'refetchRemittanceTable');
  }, []);
  return (Component);
};

export default TableWrapper;
