import { useEffect } from 'react';
import { apiUrls } from '../api/constants';
import Notification from '../components/Notification';
import Events from '../lib/events';
import useCRUDWithoutTab from './useCRUDWithoutTab';

export default function usePatientDetail({
  patientId,
}) {
  // eslint-disable-next-line no-unused-vars
  const [data, error,, getDetail, clearResponse] = useCRUDWithoutTab({
    id: `patient-detail-${patientId}`, url: `${apiUrls.GET_PATIENT}/${patientId}`, type: 'read',
  });

  useEffect(() => {
    if (patientId) {
      getDetail();
      Events.on(`refetch-patient-info${patientId}`, `refetch-patient-info${patientId}`, getDetail);
    }
    return () => Events.remove(`refetch-patient-info${patientId}`, `refetch-patient-info${patientId}`);
  }, [patientId]);

  useEffect(() => {
    if (error) {
      Notification({ message: error });
      clearResponse(true);
    }
  }, [error, clearResponse]);
  return [data, clearResponse];
}
