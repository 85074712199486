/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';

import { connect, useDispatch } from 'react-redux';
import { Dropdown, Menu, Form as AntdForm } from 'antd';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';

import {
  enums as enumConstant, enums, enumMasterCode,
} from '../../../../lib/constants';
import useMasterId from '../../../../hooks/useMasterId';
import useReduxState from '../../../../hooks/useReduxState';
import { apiUrls } from '../../../../api/constants';
import Form from '../../../../components/Form';
import Icon from '../../../../components/Icon';
import AlertComponent from '../../../../components/Alert';

import {
  Input, CheckBox, DatePicker, AutoComplete, SelectBox,
} from '../../../../components/Form/inputFields';
import PhoneNumberInput from '../../../../components/Form/PhoneNumberInput';
import FormRadio from '../../../../components/Form/Radio';

import { getEnumOptions, getEnumMaster } from '../../../../store/selectors';
import { getEnumMasterData } from '../../../../store/actions/enum';

import { Select, Radio } from '../../../../wiredComponents/Enum';
import ReferringPhysicianAutoComplete from '../../../../wiredComponents/ReferringPhysicianGridAutoComplete';
import iconNames from '../../../../lib/iconNames';

const reasonsOptions = [{
  name: 'Deceased',
  value: 1,
}, {
  name: 'Moved',
  value: 2,
}, {
  name: 'Changed Physicians',
  value: 3,
}, {
  name: 'Insurance Not Contracted',
  value: 4,
}, {
  name: 'Removed from practice',
  value: 5,
}, {
  name: 'Duplicate Chart',
  value: 6,
}, {
  name: 'Other',
  value: 7,
}];

const initialValueCalculator = (physicianData) => {
  if (physicianData.providerName) {
    const clonedPhysician = physicianData;
    clonedPhysician.name = physicianData.providerName;
    clonedPhysician.value = physicianData.npi;
    clonedPhysician.npi = physicianData.npi;
    clonedPhysician.phone = Number(physicianData.primaryPhone);
    clonedPhysician.speciality = physicianData.specialty;
    return clonedPhysician;
  }
  return null;
};

const setReferringAndPrimaryPhysician = (patientCareTeams, masterCodesWithId) => {
  let primaryCarePhysician;
  let referringPhysician;
  const filteredData = [];
  patientCareTeams.forEach((careData) => {
    if (careData.providerTypeId === masterCodesWithId[enumMasterCode.ReferringPhysicianType]
    && careData.isActive === true
    && careData.latest === true) {
      referringPhysician = careData;
    } else if (careData.providerTypeId === masterCodesWithId[
      enumMasterCode.PrimaryCarePhysicianType]) {
      primaryCarePhysician = careData;
    } else if (careData.isActive === true) {
      filteredData.push(careData);
    }
  });
  return [(referringPhysician || {}), (primaryCarePhysician || {}), ...filteredData];
};

const masterCodes = [
  enumMasterCode.ReferringPhysicianType,
  enumMasterCode.PrimaryCarePhysicianType,
  enumMasterCode.statementMethodElectronic,
];

const enumNames = [
  enums.ADDRESS_BOOK_PROVIDER_TYPE,
  enums.STATEMENT_METHOD,
];

const PatientInformation = ({
  form, labels, data, isUpdate, enumOptions, enumMaster,
  isClear, setIsClear, formId, isAddForm, patientCareTeamsDataCopy, setPatientCareTeamsDataCopy,
}) => {
  const [referringPhysician, setReferringPhysician] = useReduxState('patientInformation-referringPhysician');
  const [primaryPhysician, setPrimaryPhysician] = useReduxState('patientInformation-primaryPhysician');
  const [patientCareTeamOptions, setPatientCareOptions] = useReduxState('patientInformation-patientCareTeamOptions', []);

  const masterCodesWithId = useMasterId(masterCodes, enumNames);

  const dispatch = useDispatch();

  const handlePhysicianCare = useCallback(({ key, item: { props: { data: { masterId } } } }) => {
    form.setFields([{
      name: ['patientCareTeamsForRedux', patientCareTeamOptions.length + 2, 'providerTypeId'],
      value: masterId,
    }]);
    setPatientCareOptions([...patientCareTeamOptions, { name: key, id: masterId }]);
  }, [form, patientCareTeamOptions, setPatientCareOptions]);

  const providerTypeEnumId = useMemo(() => get(enumMaster, `${enumConstant.PROVIDER_TYPE}.enumId`), [enumMaster]);
  const genderEnumId = useMemo(() => get(enumMaster, `${enumConstant.GENDER}.enumId`), [enumMaster]);

  const genderOptions = useMemo(() => {
    const genderData = enumOptions?.[genderEnumId]?.data;
    if (genderData?.length) {
      const genders = genderData?.reduce((acc, item) => {
        if (item?.masterName?.toLowerCase() !== 'unknown') {
          acc.push({
            value: item?.masterId,
            name: item?.masterName,
            label: item?.masterName,
            radioName: item?.masterCode,
          });
        }
        return acc;
      }, []);
      return genders;
    }
    return genderData;
  }, [enumOptions, genderEnumId]);

  const patientCareOptions = useMemo(() => (
    enumOptions?.[providerTypeEnumId]?.data
  ), [enumOptions, providerTypeEnumId]);

  const patientCareObject = useMemo(() => {
    if (patientCareOptions?.length) {
      return groupBy(patientCareOptions, 'masterId');
    }
    return {};
  }, [patientCareOptions]);

  const patientLabel = useMemo(
    () => data?.patientLabel?.map((item) => item?.id?.toString()), [data],
  );

  useEffect(() => {
    const patientCareTeamData = [];
    if (patientCareTeamsDataCopy) {
      form.customSetFieldsValue({ patientCareTeamsCopy: [...patientCareTeamsDataCopy] });
      form.customSetFieldsValue({ patientCareTeamsForRedux: [...patientCareTeamsDataCopy] });
      patientCareTeamsDataCopy.forEach((item, index) => {
        if (item) {
          if (index === 0) {
            setReferringPhysician(initialValueCalculator(item));
          } else if (index === 1) {
            setPrimaryPhysician(initialValueCalculator(item));
          } else {
            patientCareTeamData.push({
              name: patientCareObject?.[item.providerTypeId]?.[0].masterName,
              id: item.providerTypeId,
              initialValue: initialValueCalculator(item),
            });
          }
        }
      });
    }
    setPatientCareOptions([...patientCareTeamData]);
  }, [patientCareTeamsDataCopy]);

  const setFormValuesToInitial = useCallback(() => {
    if (!(patientCareTeamsDataCopy)) {
      if (data?.patientCareTeams?.length) {
        const filteredPatientCareTeamsData = setReferringAndPrimaryPhysician(
          data.patientCareTeams, masterCodesWithId,
        );
        setPatientCareTeamsDataCopy([...filteredPatientCareTeamsData]);
      }
    }
    if (data?.statementMethodId === masterCodesWithId?.Electronic) {
      form.setFieldsValue({ isOptOutEmail: false });
    }
  }, [data, form, masterCodesWithId, patientCareTeamsDataCopy, setPatientCareTeamsDataCopy]);

  useEffect(() => {
    if (isClear) {
      setFormValuesToInitial();
      setIsClear(false);
    }
  }, [isClear, setIsClear]);

  const onDeleteCareTeamPhysician = useCallback((careIndex) => {
    const patientCareTeamsFromRedux = [...patientCareTeamsDataCopy];
    patientCareTeamsFromRedux.splice(careIndex, 1);
    setPatientCareTeamsDataCopy(patientCareTeamsFromRedux);
    const clonedResponse = [...patientCareTeamsFromRedux];
    form.setFields([{ name: 'patientCareTeamsCopy', value: patientCareTeamsFromRedux }]);
    clonedResponse.splice(0, 2);
    setPatientCareOptions([...clonedResponse.map((item) => ({
      name: patientCareObject?.[item.providerTypeId]?.[0].masterName,
      id: item.providerTypeId,
      initialValue: initialValueCalculator(item),
    }))]);
  }, [patientCareTeamsDataCopy, setPatientCareTeamsDataCopy, form,
    setPatientCareOptions, patientCareObject]);

  useEffect(() => {
    if (!(patientCareOptions && patientCareOptions.length)) {
      dispatch(getEnumMasterData(providerTypeEnumId));
    }
  }, [providerTypeEnumId, patientCareOptions]);

  useEffect(() => {
    if (genderEnumId && !(genderOptions && genderOptions.length)) {
      dispatch(getEnumMasterData(genderEnumId));
    }
  }, [genderEnumId, genderOptions]);

  const patientCareMenu = useMemo(() => {
    if (patientCareOptions?.length) {
      const clonedOptions = [...patientCareOptions];
      clonedOptions.forEach((option, index) => {
        if (
          option.masterId === Number(masterCodesWithId[enumMasterCode.ReferringPhysicianType])
          || option.masterId === Number(masterCodesWithId[enumMasterCode.PrimaryCarePhysicianType])
        ) {
          clonedOptions.splice(index, 1);
        }
      });

      return (
        <Menu onClick={handlePhysicianCare}>
          {clonedOptions.map(({ masterName, masterId }) => (
            <Menu.Item key={masterName} data={{ masterName, masterId }}>{masterName}</Menu.Item>
          ))}
        </Menu>
      );
    } return <span />;
  }, [patientCareOptions, handlePhysicianCare, masterCodesWithId]);

  const onChangeEmailOptOut = useCallback(() => {
    if (form.getFieldValue('PreferredContactMethodId') === 370) {
      form.setFieldsValue({ email: undefined, PreferredContactMethodId: undefined });
    } else {
      form.setFieldsValue({ email: undefined });
    }
  }, [form]);

  const onChangeActive = useCallback(({ target: { checked } }) => {
    if (checked) {
      form.setFields([{ name: 'isPatientIsDeceased', value: false }]);
    }
  }, [form]);

  const onChangePatientDeceased = useCallback(({ target: { checked } }) => {
    if (checked) {
      form.setFields([{ name: 'doNotContact', value: true }]);
    }
  }, [form]);

  const onChangeReferringPhysician = useCallback(({ target: { checked } }) => {
    if (checked) {
      form.setFields([{ name: ['patientCareTeamsCopy', 0, 'npi'], value: '' }]);
      const careTeam = patientCareTeamsDataCopy || [];
      careTeam[0] = '';
      setPatientCareTeamsDataCopy(careTeam);
    }
  }, [form, patientCareTeamsDataCopy, setPatientCareTeamsDataCopy]);

  useEffect(() => {
    if (isUpdate
      && data
      && Object.values(data).length
      && patientCareObject
      && Object.values(patientCareObject).length) {
      setFormValuesToInitial();
    }
  }, [isUpdate, data, patientCareObject, patientCareTeamsDataCopy]);

  const onZipCodeSelect = useCallback((option = {}) => {
    form.setFields([{ name: 'city', value: option.city }]);
    form.setFields([{ name: 'stateId', value: option.stateId }]);
    form.setFields([{ name: 'countryId', value: option.countryId }]);
  }, [form]);

  const optionParser = useCallback((option) => ({
    name: option.zipCode,
    value: option.zipCode,
    ...option,
  }), []);

  const optionParserSelect = useCallback((isOptOutEmail) => (enumData) => {
    if (isOptOutEmail) {
      return enumData.filter((item) => item.masterName !== 'Email');
    }
    return enumData;
  }, []);

  const onSelectPatientCarePhysician = useCallback(
    (patientCareIndex, providerTypeId) => (physicianData) => {
      let primaryAddress;
      let secondaryAddress;
      physicianData.addresses.forEach((address) => {
        if (address.address_purpose === 'LOCATION') {
          primaryAddress = address;
        } else {
          secondaryAddress = address;
        }
      });
      const clonedPhysician = physicianData;
      clonedPhysician.providerName = physicianData.name;
      clonedPhysician.specialty = physicianData.speciality;
      clonedPhysician.npi = physicianData.npi;
      clonedPhysician.value = physicianData.npi;
      clonedPhysician.primaryPhone = primaryAddress?.telephone_number
       && Number(primaryAddress?.telephone_number.split('-').join(''));
      clonedPhysician.providerTypeId = providerTypeId;
      clonedPhysician.addressLine1 = primaryAddress?.address_1;
      clonedPhysician.addressLine2 = primaryAddress?.address_2;
      clonedPhysician.city = primaryAddress?.city;
      clonedPhysician.primaryFax = primaryAddress?.fax_number
        && Number(primaryAddress?.fax_number.split('-').join(''));
      clonedPhysician.stateCode = primaryAddress?.state;
      clonedPhysician.zip = primaryAddress?.postal_code?.slice(0, 5);
      const careTeamCopy = [...patientCareTeamsDataCopy || []];
      clonedPhysician.addresses = [primaryAddress];
      careTeamCopy[patientCareIndex] = clonedPhysician;
      setPatientCareTeamsDataCopy([...careTeamCopy]);
    }, [patientCareTeamsDataCopy, setPatientCareTeamsDataCopy],
  );

  const onStatementMethodChange = useCallback((statementMethodId) => {
    if (statementMethodId === masterCodesWithId?.Electronic) {
      form.setFieldsValue({ isOptOutEmail: false });
    }
  }, [masterCodesWithId, form]);

  const trimFunction = useCallback((event) => {
    if (event?.target?.name) {
      form.setFieldsValue({ [event?.target?.name]: event?.target?.value?.trim() });
    }
  }, [form]);

  return (
    <div className="demographic-wrap">
      <Form.Section>
        <div className="required-feilds top-feild-mng">
          <h2 className="h2-title ">{labels.get('titles.intakeInformation')}</h2>
        </div>
      </Form.Section>
      <Form.Section>
        <div className="required-feilds top-feild-mng contact-info">
          <h2 className="h2-title">{labels.get('titles.contactInformation')}</h2>
        </div>
      </Form.Section>
      <Form.Section noOfColumns={3}>
        <div className="group-shadow">
          <Form.Column>
            <Input
              label={labels.get('labels.firstName')}
              id="1"
              name="firstName"
              required
              labelSpan="8"
              inputSpan="16"
              maxValueLength={35}
              onBlur={trimFunction}
              placeholder="Enter First Name"
            />
            <Input
              label={labels.get('labels.middleName')}
              name="middleName"
              id="2"
              charOnly
              maxValueLength={35}
              labelSpan="8"
              inputSpan="16"
              onBlur={trimFunction}
              placeholder="Enter Middle Name"
            />
            <Input
              label={labels.get('labels.lastName')}
              name="lastName"
              id="3"
              required
              charOnly
              maxValueLength={35}
              labelSpan="8"
              inputSpan="16"
              onBlur={trimFunction}
              placeholder="Enter Last Name"
            />
            <DatePicker
              label={labels.get('labels.dob')}
              name="dateOfBirth"
              required
              id="19"
              labelSpan="8"
              inputSpan="9"
              disabledDate={(current) => current && (current >= moment().subtract(1, 'days') || current <= moment().subtract(150, 'years'))}
            />
            <FormRadio
              required
              label={labels.get('labels.gender')}
              name="genderId"
              options={genderOptions}
              labelSpan="8"
              inputSpan="16"
            />
            <Input
              label={labels.get('labels.ssn')}
              id="15"
              name="ssn"
              numberOnly
              maxValueLength={9}
              minValueLength={9}
              labelSpan="8"
              inputSpan="16"
              placeholder="Enter SSN"
            />
            <Select
              label={labels.get('labels.preferredLanguage')}
              name="preferredLanguageId"
              id="14"
              required
              enumName={enumConstant.PREFERRED_LANGUAGE}
              initialValue={form.getFieldValue('preferredLanguage') || 'English'}
              form={form}
              labelSpan="8"
              inputSpan="10"
              sortByKey="sortOrder"
              sortByFunction={(languages) => orderBy(languages, 'sortOrder', 'desc')}
            />
            <Select
              label="Label"
              name="label"
              form={form}
              labelSpan="8"
              inputSpan="16"
              enumName={enums.PATIENT_LABEL}
              initialValue={patientLabel}
              mode="multiple"
              selectProps={{
                showArrow: true,
                showSearch: true,
                maxTagCount: 'responsive',
              }}
            />
          </Form.Column>
        </div>
        <div className="group-shadow">
          <Form.Column>
            <Input
              label={labels.get('labels.address1')}
              name="addressLine1"
              id="4"
              required
              maxValueLength={35}
              labelSpan="8"
              inputSpan="16"
              onBlur={trimFunction}
              placeholder="Enter Address Line 1"
            />
            <Input
              label={labels.get('labels.address2')}
              name="addressLine2"
              id="4"
              maxValueLength={35}
              labelSpan="8"
              inputSpan="16"
              onBlur={trimFunction}
              placeholder="Enter Address Line 2"
            />
            <AutoComplete
              label={labels.get('labels.zip')}
              name="zip"
              optionParser={optionParser}
              url={apiUrls.ZIP_LOOKUP}
              required
              labelSpan="8"
              inputSpan="10"
              onSelect={onZipCodeSelect}
              optionMaster="zipcode"
            />
            <Input
              disabled
              label={labels.get('labels.city')}
              name="city"
              maxValueLength={35}
              alphaNumericOnly
              required
              labelSpan="8"
              inputSpan="10"
              placeholder="Enter City"
            />
            <AntdForm.Item
              noStyle
              shouldUpdate={(prevValues,
                currentValues) => (prevValues.countryId !== currentValues.countryId)}
            >
              {({ getFieldValue }) => (
                <Select
                  label={labels.get('labels.state')}
                  name="stateId"
                  placeholder={labels.get('placeholders.state')}
                  required
                  labelSpan="8"
                  inputSpan="10"
                  enumName={enumConstant.STATE}
                  parentId={getFieldValue('countryId')}
                  disabled
                />
              )}
            </AntdForm.Item>
            <Select
              label={labels.get('labels.country')}
              name="countryId"
              placeholder={labels.get('placeholders.country')}
              required
              labelSpan="8"
              inputSpan="16"
              initialValue={form.getFieldValue('countryId') || 'United States'}
              form={form}
              enumName={enumConstant.COUNTRY}
              disabled
            />
          </Form.Column>
        </div>
        <div className="group-shadow">
          <Form.Column>
            <div className="phone-group phone-group-validation together-fields">
              <PhoneNumberInput
                label={labels.get('labels.primaryPhone')}
                id="7"
                name="primaryPhone"
                required
                maxValueLength={10}
                minValueLength={10}
                labelSpan="8"
                inputSpan="10"
                placeholder="Enter Primary Phone"
              />
              <Input
                label={labels.get('labels.ext')}
                id="7"
                name="primaryPhoneExt"
                numberOnly
                labelSpan="9"
                inputSpan="15"
                maxValueLength={8}
                placeholder="Ext"
              />
            </div>
            <Select
              label={labels.get('labels.phoneType')}
              name="primaryPhoneTypeId"
              id="9"
              required
              enumName={enumConstant.PHONE_TYPE}
              initialValue={form.getFieldValue('primaryPhoneTypeId') || 'Home'}
              form={form}
              labelSpan="8"
              inputSpan="16"
            />
            <div className="phone-group phone-group-validation together-fields">
              <PhoneNumberInput
                label={labels.get('labels.secondaryPhone')}
                name="secondaryPhone"
                id="10"
                maxValueLength={10}
                minValueLength={10}
                labelSpan="8"
                inputSpan="10"
                placeholder="Enter Secondary Phone"
              />
              <Input
                label={labels.get('labels.ext')}
                id="8"
                name="secondaryPhoneExt"
                numberOnly
                labelSpan="9"
                inputSpan="15"
                maxValueLength={8}
                placeholder="Ext"
              />
            </div>
            <Select
              label={labels.get('labels.phoneType')}
              name="secondaryPhoneTypeId"
              id="9"
              enumName={enumConstant.PHONE_TYPE}
              initialValue={form.getFieldValue('secondaryPhoneTypeId') || 'Home'}
              form={form}
              labelSpan="8"
              inputSpan="16"
            />
            <div className="phone-group">
              <AntdForm.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => (
                  (prevValues.isOptOutEmail !== currentValues.isOptOutEmail)
                  || (prevValues.statementMethodId !== currentValues.statementMethodId))}
              >
                {({ getFieldValue }) => (
                  <Input
                    label={labels.get('labels.email')}
                    dependencies={['isOptOutEmail']}
                    id="15"
                    name="email"
                    rules={[{
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    }]}
                    required={!getFieldValue('isOptOutEmail') || (getFieldValue('statementMethodId') === masterCodesWithId?.Electronic)}
                    labelSpan="8"
                    inputSpan="10"
                    disabled={!(!getFieldValue('isOptOutEmail') || (getFieldValue('statementMethodId') === masterCodesWithId?.Electronic))}
                    hasFeedback={!getFieldValue('isOptOutEmail') && data?.isEmailVerified && data?.email === getFieldValue('email')}
                    {...{ [(!getFieldValue('isOptOutEmail') && data?.isEmailVerified && data?.email === getFieldValue('email')) && 'validateStatus']: 'success' }}
                    maxValueLength={35}
                    placeholder="Enter Email"
                  />
                )}
              </AntdForm.Item>
              <AntdForm.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => (
                  prevValues.statementMethodId !== currentValues.statementMethodId)}
              >
                {({ getFieldValue }) => (
                  <CheckBox
                    label={labels.get('labels.emailOptOut')}
                    valuePropName="checked"
                    name="isOptOutEmail"
                    disabled={getFieldValue('statementMethodId') === masterCodesWithId?.Electronic}
                    onChange={onChangeEmailOptOut}
                    labelSpan="16"
                    inputSpan="8"
                  />
                )}
              </AntdForm.Item>
            </div>
            <AntdForm.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => (
                (prevValues.isOptOutEmail !== currentValues.isOptOutEmail)
                || (prevValues.statementMethodId !== currentValues.statementMethodId))}
            >
              {({ getFieldValue }) => (getFieldValue('statementMethodId') === masterCodesWithId?.Electronic && !getFieldValue('email')) && (
                <div className="mr-bt-8">
                  <AlertComponent message="Email is required if statement method is electronic." closable={false} />
                </div>
              )}
            </AntdForm.Item>
            <AntdForm.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => (
                prevValues.isOptOutEmail !== currentValues.isOptOutEmail)}
            >
              {({ getFieldValue }) => (
                <Select
                  label={labels.get('labels.preferredContactMethod')}
                  name="preferredContactMethodId"
                  id="preferredContactMethodId"
                  optionParser={optionParserSelect(getFieldValue('isOptOutEmail'))}
                  enumName={enumConstant.PREFERRED_CONTACT_METHOD}
                  labelSpan="8"
                  inputSpan="16"
                />
              )}
            </AntdForm.Item>
          </Form.Column>
        </div>
      </Form.Section>
      <Form.Section>
        <div className="required-feilds top-feild-mng mr-top-12 ">
          <h2 className="h2-title">{labels.get('titles.generalInformation')}</h2>
        </div>
      </Form.Section>
      <Form.Section noOfColumns={3}>
        <div className="group-shadow">
          <h3 className="care-team-head">Care Team</h3>
          <Form.Column>
            <div className="phone-group">
              <AntdForm.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => (
                  prevValues.isOptOutReferringPhysician
                  !== currentValues.isOptOutReferringPhysician)}
              >
                {({ getFieldValue }) => (
                  <ReferringPhysicianAutoComplete
                    dependencies={['isOptOutReferringPhysician']}
                    label={labels.get('labels.referringPhysician')}
                    id="11"
                    name={['patientCareTeamsCopy', 0, 'npi']}
                    required={!getFieldValue('isOptOutReferringPhysician')}
                    labelSpan="8"
                    inputSpan="10"
                    disabled={getFieldValue('isOptOutReferringPhysician')}
                    form={form}
                    initialValue={referringPhysician}
                    onSelect={onSelectPatientCarePhysician(
                      0, masterCodesWithId[enumMasterCode.ReferringPhysicianType],
                    )}
                    allowClearOnBlur={false}
                    formId={formId}
                    valueInString
                  />
                )}
              </AntdForm.Item>
              <CheckBox
                label={labels.get('labels.referringPhysicianOptOut')}
                valuePropName="checked"
                name="isOptOutReferringPhysician"
                onChange={onChangeReferringPhysician}
                labelSpan="16"
                inputSpan="8"
              />
            </div>
            <div className="add-physician">
              <ReferringPhysicianAutoComplete
                label={labels.get('labels.primaryCarePhysician')}
                id="15"
                name={['patientCareTeamsCopy', 1, 'npi']}
                labelSpan="8"
                inputSpan="10"
                form={form}
                required={false}
                initialValue={primaryPhysician}
                valueInString
                onSelect={
                  onSelectPatientCarePhysician(
                    1, masterCodesWithId[enumMasterCode.PrimaryCarePhysicianType],
                  )
                }
                allowClearOnBlur={false}
                formId={formId}
              />
              <Dropdown overlay={patientCareMenu} trigger="click">
                <span className="add-icon" />
              </Dropdown>
            </div>
            {patientCareTeamOptions && patientCareTeamOptions.map(
              ({ name, id: providerTypeId, initialValue = null }, physicianIndex) => (
                <div className="add-physician" key={`${physicianIndex + 2}`}>
                  <ReferringPhysicianAutoComplete
                    label={name}
                    id={physicianIndex + 2}
                    name={['patientCareTeamsCopy', physicianIndex + 2, 'npi']}
                    labelSpan="8"
                    inputSpan="10"
                    form={form}
                    initialValue={initialValue}
                    onSelect={onSelectPatientCarePhysician(physicianIndex + 2, providerTypeId)}
                    allowClearOnBlur={false}
                    valueInString
                  />
                  <Icon
                    name={iconNames.deleteIcon}
                    onClick={() => onDeleteCareTeamPhysician(physicianIndex + 2)}
                    className="remove-icon"
                    formId={formId}
                  />
                </div>
              ),
            )}
            <Select
              label={labels.get('labels.hearAboutUs')}
              name="howDidYouHear"
              enumName={enumConstant.HOW_DID_YOU_HEAR_ABOUT_US}
              form={form}
              id="3"
              labelSpan="8"
              inputSpan="16"
              required
            />
          </Form.Column>
        </div>
        <div className="group-shadow">
          <Form.Column>
            <div className="phone-group together-fields wrap-side-three">
              <div className="search-input">
                <Input
                  label={labels.get('labels.ecName')}
                  id="11"
                  name="emergencyContactName"
                  labelSpan="8"
                  inputSpan="16"
                  placeholder="Enter Emergency Contact Name"
                />
                {/* <span className="search-icon sprite-img-before" /> */}
              </div>
              <Select
                label={labels.get('labels.ecRelationship')}
                name="emergencyContactRelationId"
                id="14"
                enumName={enumConstant.RELATION}
                labelSpan="8"
                inputSpan="16"
              />
              <PhoneNumberInput
                label={labels.get('labels.ecPhone')}
                name="emergencyContactPhone"
                id="11"
                maxValueLength={10}
                minValueLength={10}
                labelSpan="8"
                inputSpan="16"
                placeholder="Enter Emergency Contact Phone"
              />
            </div>
            <Radio
              label={labels.get('labels.birthSex')}
              name="birthSexId"
              enumName={enumConstant.GENDER}
              labelSpan="8"
              inputSpan="16"
            />
            <Select
              label={labels.get('labels.race')}
              id="race"
              name="raceId"
              labelSpan="8"
              inputSpan="16"
              enumName={enums.RACE}
            />
            <Select
              label={labels.get('labels.ethnicity')}
              id="ethnicity"
              name="ethnicityId"
              labelSpan="8"
              inputSpan="16"
              enumName={enums.ETHNICITY}
            />
          </Form.Column>
        </div>

        <div className="group-shadow">
          <Form.Column>
            <Select
              label={labels.get('labels.statementMethod')}
              name="statementMethodId"
              enumName={enumConstant.STATEMENT_METHOD}
              initialValue={form.getFieldValue('statementMethod') || 'Paper'}
              form={form}
              id="3"
              onChange={onStatementMethodChange}
              labelSpan="8"
              inputSpan="16"
            />
            <CheckBox label={labels.get('labels.statementHold')} valuePropName="checked" name="isStatementHold" labelSpan="8" inputSpan="16" />
            <CheckBox label={labels.get('labels.paymentPlan')} valuePropName="checked" name="isPaymentPlan" labelSpan="8" inputSpan="16" />
            <CheckBox label={labels.get('labels.patientCollection')} valuePropName="checked" name="isPatientCollectionsExclusion" labelSpan="8" inputSpan="16" />
            <CheckBox
              label={labels.get('labels.importPrescriptionConsent')}
              valuePropName="checked"
              name="canDrugDownload"
              inputSpan="16"
              labelSpan="8"
            />
            <CheckBox
              label={labels.get('labels.active')}
              name="isActive"
              valuePropName="checked"
              onChange={onChangeActive}
              labelSpan="8"
              inputSpan="16"
            />
            <AntdForm.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => (
                prevValues.isActive !== currentValues.isActive)}
            >
              {({ getFieldValue }) => !getFieldValue('isActive') && (
                <SelectBox
                  name="inActiveReasonId"
                  label={labels.get('labels.reasonForInactive')}
                  labelSpan="8"
                  inputSpan="16"
                  options={reasonsOptions}
                />
              )}
            </AntdForm.Item>
            {!isAddForm && (
            <>
              <CheckBox
                label="Patient Deceased"
                name="isPatientIsDeceased"
                valuePropName="checked"
                onChange={onChangePatientDeceased}
                labelSpan="8"
                inputSpan="16"
              />
              <AntdForm.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => (
                  prevValues.isPatientIsDeceased !== currentValues.isPatientIsDeceased)}
              >
                {({ getFieldValue }) => getFieldValue('isPatientIsDeceased') && (
                <DatePicker
                  label="Patient Deceased Date"
                  name="patientDeceasedDate"
                  labelSpan="8"
                  inputSpan="16"
                  required
                  disabledDate={(current) => current && (current >= moment().endOf('day'))}
                />
                )}
              </AntdForm.Item>
            </>
            )}
            <CheckBox
              label={labels.get('labels.doNotContact')}
              name="doNotContact"
              valuePropName="checked"
              labelSpan="8"
              inputSpan="16"
            />
          </Form.Column>
        </div>

      </Form.Section>
    </div>
  );
};
export default connect((state) => ({
  enumOptions: getEnumOptions(state),
  enumMaster: getEnumMaster(state),
}))(PatientInformation);
