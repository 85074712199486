import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { Menu } from 'antd';
import { apiUrls } from '../../../api/constants';

import { listId } from '../../../lib/constants';

import { getUserSettings } from '../../../store/actions/users';
import * as selectors from '../../../store/selectors';

import withQuery from '../../../hoc/withQuery/withQuery';
import withReduxManager from '../../../hoc/withReduxManager';

import FilterManager from '../../../components/FilterManager';

import columns from './columns';

import '../billing.scss';
import BillingEncounterFilter from './BillingEncounterFilter';
import FilterComponents from '../../../components/FilterComponents';
import useCRUD from '../../../hooks/useCRUD';
import useRights from '../../../hooks/useRights';
import rights from '../../../lib/rights';
import WidgetLoader from '../../../components/WidgetLoader';
import SuccessMessages from '../../../lib/successMessages';
import Notification from '../../../components/Notification';

const initialSort = [{ id: 'dateofservice', desc: true }];

const TypeFilters = [
  {
    placeholder: 'All',
    name: 'All',
    id: 'all',
  },
  {
    placeholder: 'Unfulfilled Encounters',
    name: 'New',
    id: 'unfulfilled_encounters',
  },
  {
    placeholder: 'Saved',
    name: 'Saved',
    id: 'saved',
  },
  {
    placeholder: 'On Hold',
    name: 'OnHold',
    id: 'on_hold',
  },
];

const CustomBillingTable = withReduxManager({
  listId: listId.BILLING,
})(withQuery({
  url: apiUrls.GET_PATIENT_ENCOUNTER,
  listId: listId.BILLING,
})());

const BillingEncounterTable = ({
  labels, dispatch, userSettings, ...props
}) => {
  const [
    updateBillingEncounterUnbillableResponse, ,
    updateBillingEncounterUnbillableLoading,
    updateBillingEncounterUnbillable, clearUpdateBillingEncounterUnbillable,
  ] = useCRUD({
    id: apiUrls.UPDATE_BILLING_ENCOUNTER_WITH_UNBILLABLE,
    url: apiUrls.UPDATE_BILLING_ENCOUNTER_WITH_UNBILLABLE,
    type: 'update',
  });

  const [showRightClick, setShowRightClick] = useState(false);

  const onChangeTab = useCallback((onFilterChange) => ({ target: { name } }) => {
    onFilterChange({ EncounterStatus: name });
    setShowRightClick(name === 'Saved');
  }, [updateBillingEncounterUnbillableResponse]);

  const memoizedSettings = useMemo(() => userSettings, [userSettings]);

  const [isAccessToNoBillingNeeded] = useRights([rights.access_to_no_billing_needed]);

  useEffect(() => {
    if (updateBillingEncounterUnbillableResponse) {
      Notification({ message: SuccessMessages.MARKED_ENCOUNTER_AS_UNBILL_SUCCESS, success: true });
      clearUpdateBillingEncounterUnbillable(true);
    }
  }, [updateBillingEncounterUnbillableResponse]);

  useEffect(() => {
    if (!localStorage.getDecryptedData('userSettingsData')) dispatch(getUserSettings());
  }, []);

  const FilterCollection = FilterComponents([
    {
      type: 'search',
      filterProps: {
        placeholder: 'Search Encounter',
        name: 'SearchText',
        id: 'encounter_filter_search',
      },
    },
  ]);

  const rightClickMenu = useCallback((item) => () => (
    item.original?.patientEncounterStatus?.toLowerCase() === 'signed' ? (
      <Menu className="profile-menu-container">
        <Menu.Item key="No billing needed" onClick={() => updateBillingEncounterUnbillable({ billingencounterid: item.original.encounterId, status: 'unbillable' })}>
          <span>No billing needed</span>
        </Menu.Item>
      </Menu>
    ) : <> </>
  ), []);

  return (
    <CustomBillingTable
      columns={columns(labels)}
      initialFilters={{ EncounterStatus: 'All' }}
      initialSort={initialSort}
      skipInitialFetch
      noDataText="Billing Encounter not found"
      rightClickMenu={(isAccessToNoBillingNeeded && showRightClick) ? rightClickMenu : null}
      {...props}
    >
      {({
        Component, initialFilters, onFiltersChange, totalCount, reFetch,
      }) => (
        <FilterManager initialFilters={initialFilters} onChange={onFiltersChange}>
          {({ onFilterChange, filters }) => (
            <>
              <div className="heading-area flex">
                { TypeFilters.map((value, index) => (
                  <button
                    type="button"
                    name={value.name}
                    className={classNames(
                      'btn btn-primary sm-btn filter-button', { 'mr-lt-10': index > 0 }, filters?.EncounterStatus === value.name ? 'active' : '',
                    )}
                    key={index + 1}
                    onClick={onChangeTab(onFilterChange)}
                  >
                    {value.placeholder}
                  </button>
                ))}
                <FilterCollection onFilterChange={onFilterChange} filters={filters} />
              </div>
              {updateBillingEncounterUnbillableLoading && <WidgetLoader />}
              {updateBillingEncounterUnbillableResponse && reFetch()}
              {filters?.EncounterStatus === 'Saved' && setShowRightClick(true)}
              <BillingEncounterFilter
                onFilterChange={onFilterChange}
                totalCount={totalCount}
                filters={filters}
                labels={labels}
                activeTab={filters?.EncounterStatus}
                memoizedSettings={memoizedSettings}
                reFetch={reFetch}
              />
              {Component}
            </>
          )}
        </FilterManager>
      )}
    </CustomBillingTable>
  );
};

export default connect((state) => ({
  userSettings: selectors.userSettings(state),
}))(BillingEncounterTable);
