import React, { useCallback } from 'react';

import { apiUrls } from '../../../../api/constants';
import { enums as enumConstant } from '../../../../lib/constants';

import {
  Input, AutoComplete, CheckBox,
} from '../../../../components/Form/inputFields';
import PhoneNumberInput from '../../../../components/Form/PhoneNumberInput';

import Form from '../../../../components/Form';

import { Select } from '../../../../wiredComponents/Enum';

const PayerForm = ({
  form, labels,
}) => {
  const isAdmin = true;

  const onZipCodeSelect = useCallback((option = {}) => {
    const zipCode = option.zipCode?.split('-');
    form.setFields([
      { name: 'city', value: option.city },
      { name: 'stateId', value: option.stateId },
      { name: 'countryId', value: option.countryId },
      { name: 'zip', value: zipCode?.[0] },
      { name: 'zip2', value: zipCode?.[1] },
    ]);
  }, [form]);

  const optionParser = useCallback((option) => ({
    name: option.zipCode,
    value: option.zipCode,
    ...option,
  }), []);

  return (
    <div className="demographic-wrap">
      <Form.Section noOfColumns={3}>
        <div className="group-shadow">
          <Form.Column>
            <Input
              id="1"
              label={labels.get('labels.name')}
              name="name"
              required
              charOnly
              maxValueLength={50}
            />
            <Input
              id="2"
              label={labels.get('labels.address1')}
              name="addressLine1"
              required
              maxValueLength={100}
              alphaNumericOnly
            />
            <Input
              id="3"
              label={labels.get('labels.address2')}
              name="addressLine2"
              maxValueLength={100}
              alphaNumericOnly
            />
            <div className="zip-search-wrap">
              <AutoComplete
                id="4"
                label={labels.get('labels.zip')}
                name="zip"
                optionParser={optionParser}
                url={apiUrls.ZIP_LOOKUP}
                required
                onSelect={onZipCodeSelect}
                optionMaster="zipcode"
                labelSpan="10"
                inputSpan="10"
              />
              <Input
                id="13"
                name="zip2"
                numberOnly
                maxValueLength={4}
                labelSpan="0"
                inputSpan="24"
              />
            </div>
            <Input
              id="5"
              disabled
              label={labels.get('labels.city')}
              name="city"
              required
            />
          </Form.Column>
        </div>
        <div className="group-shadow">
          <Form.Column>
            <Select
              id="6"
              label={labels.get('labels.state')}
              name="stateId"
              required
              disabled
              enumName={enumConstant.STATE}
            />
            <Select
              id="8"
              disabled
              label={labels.get('labels.country')}
              name="countryId"
              enumName={enumConstant.COUNTRY}
              required
            />
            <Select
              id="7"
              label={labels.get('labels.payerType')}
              name="insurancePayerTypeId"
              enumName={enumConstant.INSURANCE_PAYER_TYPE}
              required
            />
            <Input
              id="9"
              label={labels.get('labels.fax')}
              name="fax"
              minValueLength={10}
              maxValueLength={12}
              numberOnly
            />
            <PhoneNumberInput
              id="10"
              label={labels.get('labels.phone')}
              name="phone"
              minValueLength={10}
              maxValueLength={12}
              labelSpan={10}
              inputSpan={14}
              required
              numberOnly
            />
          </Form.Column>
        </div>
        <div className="group-shadow">
          <Form.Column>
            <Input
              id="11"
              label={labels.get('labels.ext')}
              name="ext"
              numberOnly
              maxValueLength={5}
              inputSpan="10"
            />
            <Input
              id="12"
              label={labels.get('labels.comments')}
              name="comments"
              maxValueLength={150}
            />
            {isAdmin && (
              <>
                <Select
                  id="11"
                  label={labels.get('labels.submissionMethod')}
                  name="submissionMethodId"
                  enumName={enumConstant.SUBMISSION_METHOD}
                  required
                />
                <Input
                  id="12"
                  label={labels.get('labels.payerId')}
                  name="payerId"
                  required
                />
                <CheckBox
                  id="12"
                  label={labels.get('labels.active')}
                  name="isActive"
                  valuePropName="checked"
                />
              </>
            )}
          </Form.Column>
        </div>
      </Form.Section>
    </div>
  );
};

export default PayerForm;
