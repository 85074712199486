import React, { useEffect, useState } from 'react';
import { Form as AntdForm } from 'antd';
import { useDispatch } from 'react-redux';
import { clearDataWithId } from '../../../../../../../store/actions/reduxStoreWithId';

import Form from '../../../../../../../components/Form';
import Button from '../../../../../../../components/Button';
import useMasterId from '../../../../../../../hooks/useMasterId';

import { apiUrls } from '../../../../../../../api/constants';
import {
  enumMasterCode, enums, formId, listIds,
} from '../../../../../../../lib/constants';

import useRedirect from '../../../../../../../hooks/useRedirect';

import AuthorizationInformation from '../Components/AuthorizationInformation';
import { beforeFinishParser } from '../helpers';

const AuthorizationForm = ({
  onRequestComplete, initialValues, labels,
  onCancelButtonClicked, destroyOnClose = false, patientId,
}) => {
  const { Authorization } = useMasterId([enumMasterCode.document_authorization],
    [enums.PATIENT_DOCUMENT_CATEGORY]);

  const [form] = AntdForm.useForm();
  const { params } = useRedirect();
  const dispatch = useDispatch();
  const [cptCodes, setCptCodes] = useState([]);
  const { id, tabId } = params;

  useEffect(() => {
    form.resetFields();
  }, [initialValues, form]);

  useEffect(() => () => {
    if (destroyOnClose) {
      form.completeReset();
      dispatch(clearDataWithId(`SELECTED_INSURANCE_DATA-${tabId}-${id}`));
    }
  }, []);

  return (
    <>
      <Form
        section
        url={apiUrls.ADD_AUTHORIZATION}
        onRequestComplete={onRequestComplete}
        parser={beforeFinishParser}
        form={form}
        formId={formId.ADD_AUTHORIZATION}
        name={`${formId.ADD_AUTHORIZATION}-${tabId}`}
        scrollToFirstErrors
        listId={listIds.AUTHORIZATION_LIST}
        initialData={{
          dosOptionsSelected: 'appointments',
        }}
        tabId={tabId}
        extraData={{
          patientId: id || patientId,
          cptCodes: cptCodes.map((item) => item.value),
          documentCategoryId: Authorization,
        }}
      >
        <AuthorizationInformation
          form={form}
          labels={labels}
          setCptCodes={setCptCodes}
          cptCodes={cptCodes}
          patientId={id}
          tabId={tabId}
        />
      </Form>
      <div className="btns-action">
        <Button className="btn btn-success min-wt-86" onClick={form.submit}>{labels.get('buttons.save')}</Button>
        <Button className="btn" onClick={onCancelButtonClicked}>{labels.get('buttons.cancel')}</Button>
      </div>
    </>
  );
};

export default AuthorizationForm;
