import React from 'react';
import './opendownloadbox.scss';
import { useSelector } from 'react-redux';
import {
  Drawer, Tooltip, Card, Tag,
} from 'antd';
import { SyncOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import dateFormatter from '../../lib/dateFormatter';
import { dateFormat } from '../../lib/constants';

function DownloadBox({ booleanDownloadBox, openDownloadBox }) {
  const superBillTasks = useSelector((state) => state.superBill.tasks);

  const statusIcon = (status) => {
    if (status === 'In Progress') {
      return <SyncOutlined spin />;
    } if (status === 'Completed') {
      return <SyncOutlined spin />;
    } if (status === 'Error') {
      return <CloseCircleOutlined style={{ color: 'red' }} />;
    } if (status === 'Downloaded') {
      return <CheckCircleOutlined style={{ color: 'green' }} />;
    }
    return null;
  };

  const STATUS_TEXT = {
    'In Progress': 'Generating...',
    Completed: 'Downloading...',
    Error: 'Download Failed',
    Downloaded: 'Download Complete',
  };

  return (
    <Drawer
      placement="left"
      width={500}
      className="app-open-downloadbox"
      closable={false}
      onClose={openDownloadBox}
      visible={booleanDownloadBox}
      zIndex={100}
      style={{ position: 'fixed' }}
    >
      <div className="header">
        <h1 className="title">Downloader</h1>
        <div className="close-back" onClick={openDownloadBox} role="presentation" data-testid="close-icon" />
      </div>

      <div className="tasks-container">
        {[...superBillTasks].reverse().map((task) => (
          <Card key={task.taskId} className={`task-card task-card-${task.status?.toLowerCase().replace(' ', '-')}`}>
            <div className="card-content">
              <Tag color="blue">Superbill</Tag>
              {
                task.query.providerNames && (
                <p className="card-content">{`Providers: ${task.query.providerNames.join(', ')}`}</p>
                )
              }
              {
                task.query.patientName && (
                  <>
                    <p className="card-content">{`Patient: ${task.query.patientName}`}</p>
                    <p className="card-text">
                      Latest Appointment
                    </p>
                  </>
                )
              }
              {
                task.query.providerNames && (
                  <p className="card-text">
                    From:
                    {' '}
                    <span>{dateFormatter(task.query.fromdate, dateFormat)}</span>
                    {' '}
                    To:
                    {' '}
                    <span>{dateFormatter(task.query.todate, dateFormat)}</span>
                  </p>

                )
              }
              <div className="status-container">
                <span>{STATUS_TEXT[task.status] || task.status}</span>
                <Tooltip title={task.status}>
                  {statusIcon(task.status)}
                </Tooltip>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </Drawer>

  );
}

export default DownloadBox;

