import React from 'react';
import TimeCell from '../../../../../../components/Cells/TimeCell';
import { isDate } from '../../../../../../lib/util';
import Icon from '../../../../../../components/Icon';
import iconNames from '../../../../../../lib/iconNames';

const inProgressColumns = (labels) => [
  {
    Header: labels.get('tableColumns.businessEntityName'),
    accessor: 'businessEntity',
    sort: true,
    Cell: ({ row: { original: { businessEntity, stageMasterName } } }) => (
      <span style={{ color: stageMasterName?.toLowerCase() === 'new' && 'red' }}>
        {businessEntity}
      </span>
    ),
  },
  {
    Header: labels.get('tableColumns.payerName'),
    accessor: 'insurancePayerName',
    sort: true,
    Cell: ({ row: { original: { insurancePayerName, stageMasterName } } }) => (
      <span style={{ color: stageMasterName?.toLowerCase() === 'new' && 'red' }}>
        {insurancePayerName}
      </span>
    ),
  },
  {
    Header: labels.get('tableColumns.status'),
    accessor: 'stageMasterName',
    sort: true,
    Cell: ({ row: { original: { stageMasterName } } }) => (
      <span style={{ color: stageMasterName?.toLowerCase() === 'new' && 'red' }}>
        {stageMasterName}
      </span>
    ),
  },
  {
    Header: labels.get('tableColumns.batchNumber'),
    accessor: 'batchNumber',
    sort: true,
    Cell: ({ row: { original: { batchNumber, stageMasterName } } }) => (
      <span style={{ color: stageMasterName?.toLowerCase() === 'new' && 'red' }}>
        {batchNumber}
      </span>
    ),
  },
  {
    Header: labels.get('tableColumns.receivedDate'),
    accessor: 'depositDate',
    sort: true,
    Cell: ({ row: { original: { depositDate, stageMasterName } } }) => (
      <span style={{ color: stageMasterName?.toLowerCase() === 'new' && 'red' }}>
        <TimeCell value={depositDate?.dateString} format="MM-DD-YYYY" />
      </span>
    ),
  },
  {
    Header: labels.get('tableColumns.checkDate'),
    accessor: 'checkDate',
    Cell: ({ row: { original: { checkDate, stageMasterName } } }) => (
      <span style={{ color: stageMasterName?.toLowerCase() === 'new' && 'red' }}>
        {isDate(checkDate) ? <TimeCell value={checkDate} format="MM-DD-YYYY" /> : checkDate}
      </span>
    ),
    sort: true,
  },
  {
    Header: labels.get('tableColumns.checkNumber'),
    accessor: 'checkNumber',
    sort: true,
    Cell: ({ row: { original: { checkNumber, stageMasterName } } }) => (
      <span style={{ color: stageMasterName?.toLowerCase() === 'new' && 'red' }}>
        {checkNumber}
      </span>
    ),
  },
  {
    Header: labels.get('tableColumns.amount'),
    accessor: 'totalBatchAmount',
    Cell: ({ row: { original: { totalBatchAmount, stageMasterName } } }) => (
      <span style={{ color: stageMasterName?.toLowerCase() === 'new' && 'red' }}>
        {parseFloat(totalBatchAmount || 0).toFixed(2)}
      </span>
    ),
    fixWidth: 150,
    className: 'text-align-right',
    sort: true,
  },
  {
    Header: '',
    accessor: 'paperClipIcon',
    Cell: ({ row: { original: { batchType } } }) => (
      <span className="flex justify-content-center">
        {batchType?.toLowerCase() === 'manual' && <Icon name={iconNames.paperClipOutlined} color="green" />}
        {batchType?.toLowerCase() === 'electronic' && <Icon name={iconNames.thunderboltOutlined} color="orange" />}
      </span>
    ),
    fixWidth: '60',
  },
];

export default inProgressColumns;
