import React, { useRef, useCallback, useLayoutEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  TimePicker,
} from 'antd-old';
import { Form, Col, Row } from 'antd';
import times from 'lodash/times';
import cloneDeep from 'lodash/cloneDeep';

import './timepicker.scss';

const defaultFormat = 'HH:mm';
const defaultMinuteStep = 15;

const TimePickerComponent = ({
  form,
  format,
  minuteStep,
  label,
  name,
  Component,
  rules,
  onChange,
  onSelect,
  required,
  labelSpan,
  inputSpan,
  disabledHours,
  disabledMinutes,
  hideDisabledOptions,
  showNow,
  shouldShowWarningText,
  timePickerProps,
  dataTestId,
  resetTimeOnCancel,
  ...props
}) => {
  const ref = useRef();
  const updated = useRef();
  const timeStamps = useRef([]);
  const inputs = useRef([]);

  const wakeEventListeners = useCallback((callback) => {
    times(inputs.current?.length, (index) => {
      callback(index, inputs.current[index]?.value);
      inputs.current[index].onblur = (element) => {
        callback(index, element?.target?.value);
      };
    });
  }, [inputs]);

  const onTimeChange = useCallback((date) => {
    updated.current = true;
    onChange(date);
    if (resetTimeOnCancel && !date) {
      timeStamps.current = [];
    }
  }, [onChange, resetTimeOnCancel]);

  const updateTimeStamps = useCallback((index, time) => {
    if (time) {
      const timeArray = [...timeStamps.current];
      timeArray[index] = moment(time, format);
      timeStamps.current = timeArray;
    }
  }, [format]);

  const onOpenChange = useCallback((isOpen) => {
    if (isOpen) wakeEventListeners(updateTimeStamps);
    if (!isOpen && !updated.current) {
      const { current = [] } = timeStamps;
      const data = [];
      if (current.length === 1) {
        onChange(moment(`${current[0]}`));
        data.push({ name, value: current[0] });
      } else {
        const currentTime = times(inputs.current?.length, (index) => inputs.current[index]?.value).map((item) => moment(`${moment().format('MM-DD-YYYY')} ${item}`));
        onChange(currentTime);
        data.push({ name, value: currentTime });
      }
      form.setFields(data);
    } else updated.current = false;
  }, [wakeEventListeners, updateTimeStamps, form, onChange, name]);

  useLayoutEffect(() => {
    inputs.current = ref.current?.getElementsByTagName('input') || [];
    timeStamps.current = times(inputs.current?.length || 0, () => undefined);
  }, []);

  const clonedRules = cloneDeep(rules);
  if (required) {
    clonedRules.push({
      required: true,
      message: shouldShowWarningText ? `${label} is required` : ' ',
    });
  }

  return (
    <div className="time-picker-wrap">
      <div className="custom-picker">
        <Row>
          <Col span={labelSpan}>
            <div className="ant-form-item-label ant-form-item-label-left">
              <label
                className={classNames('ant-form-item-no-colon')}
                title={label}
              >
                {label}
              </label>
              <span className={classNames('req-star', required && 'ant-form-item-required')} />
            </div>
          </Col>
          <Col ref={ref} span={inputSpan}>
            <Form.Item
              name={name}
              rules={[...clonedRules]}
              {...props}
            >
              <Component
                dropdownClassName="time-picker-component"
                popupClassName="time-picker-component"
                format={format}
                minuteStep={minuteStep}
                disabledHours={disabledHours}
                disabledMinutes={disabledMinutes}
                onChange={onTimeChange}
                hideDisabledOptions={hideDisabledOptions}
                showNow={showNow}
                onOpenChange={onOpenChange}
                onSelect={onSelect}
                data-testid={dataTestId}
                {...timePickerProps}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  );
};

TimePickerComponent.defaultProps = {
  format: defaultFormat,
  minuteStep: defaultMinuteStep,
  rules: [],
  Component: TimePicker,
  label: '',
  name: '',
  onChange: () => { /* This is intentional */ },
  onSelect: () => { /* This is intentional */ },
  disabledMinutes: () => { /* This is intentional */ },
  disabledHours: () => { /* This is intentional */ },
  showNow: true,
  hideDisabledOptions: false,
  shouldShowWarningText: false,
  resetTimeOnCancel: false,
  timePickerProps: {},
};

TimePickerComponent.propTypes = {
  format: PropTypes.string,
  minuteStep: PropTypes.number,
  rules: PropTypes.instanceOf(Array),
  Component: PropTypes.node,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  disabledHours: PropTypes.func,
  disabledMinutes: PropTypes.func,
  hideDisabledOptions: PropTypes.bool,
  showNow: PropTypes.bool,
  shouldShowWarningText: PropTypes.bool,
  resetTimeOnCancel: PropTypes.bool,
  timePickerProps: PropTypes.instanceOf(Object),
};

export default TimePickerComponent;
