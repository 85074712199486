/* eslint-disable no-param-reassign */

import React, {
  useState, useEffect, useCallback, useMemo, lazy, Suspense,
} from 'react';
import moment from 'moment';
import { Form } from 'antd';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import findIndex from 'lodash/findIndex';
import size from 'lodash/size';
import keys from 'lodash/keys';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isObject from 'lodash/isObject';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';
import clone from 'lodash/clone';
import isEmpty from 'lodash/isEmpty';

import WithLabel from '../../hoc/withLabel';

import useRedirect from '../../hooks/useRedirect';
import useCRUDWithoutTab from '../../hooks/useCRUDWithoutTab';

import { apiUrls } from '../../api/constants';

import encode from '../../lib/encode';
import decode from '../../lib/decode';
import {
  labelPaths, dateFormatStartsWithYear, UiRoutes, listIds,
} from '../../lib/constants';
import dateFormatter from '../../lib/dateFormatter';
import { scheduleDataParser, appointmentsParser } from '../../lib/schedulerHelpers';

import * as selectors from '../../store/selectors';

import Loader from '../../components/Loader';
import Notification from '../../components/Notification';
import Error from '../../components/Error';
import Icon from '../../components/Icon';
import ConfirmDialog from '../../components/ConfirmDialog';
import SavedNavigationBar from '../../components/SavedNavigationBar';

import SchedulerTitle from './SchedulerTitle';
import SchedulerHeader from './SchedulerHeader';
import SchedulerGrid from './SchedulerGrid';

import AddAppointmentDialog from './Views/Dialogs/AddAppointmentDialog';

import {
  getSelectedDoctorsData,
  setError,
  getSelectedDoctorsFreeSlots,
} from '../../store/actions/doctors';
import { getUserSettings } from '../../store/actions/users';

import './Scheduler.scss';
import rights from '../../lib/rights';
import WithRights from '../../hoc/withRights';
import WidgetLoader from '../../components/WidgetLoader';
import { getLoggedInProviderId, retry } from '../../lib/util';

const AppointmentExtraInfo = lazy(() => retry(() => import('./Views/AppointmentExtraInfo')));

const slotDuration = 10;
const minSlotTime = 5;
const visitTypeCoolDownTime = 5; // minutes

const filterDoctorByLocation = (
  { doctor: doc }, location,
) => !!(findIndex(doc.locations, location) + 1);

const getSchedulerDataParams = ({
  selectedDate, viewType, ids, visitType, testType, selectedLocation,
}) => {
  const clonedSelectedDate = cloneDeep(selectedDate);
  let scheduleStartDate;
  let scheduleEndDate;

  if (viewType === 'days') {
    scheduleStartDate = clonedSelectedDate.startOf('day').format('YYYY-MM-DD HH:mm:ss');
    scheduleEndDate = clonedSelectedDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');
  } else {
    scheduleStartDate = clonedSelectedDate.day('Monday').startOf('day').format('YYYY-MM-DD HH:mm:ss');
    scheduleEndDate = clonedSelectedDate.day('Saturday').endOf('day').format('YYYY-MM-DD HH:mm:ss');
  }
  const params = { providerId: ids && ids.join(','), scheduleStartDate, scheduleEndDate };
  if (visitType && visitType.visitTypeId) params.VisitTypeId = visitType.visitTypeId;
  if (testType && testType.length) params.ExamTypeId = testType.map((item) => item.examTypeId).join(',');
  if (selectedLocation
    && selectedLocation.locationId) params.locationId = selectedLocation.locationId;
  return params;
};

function Schedular(props) {
  const {
    doctors = [],
    locations = [],
    dispatch,
    labels,
    selectedSchedulerData,
    loading,
    error,
    userSettings,
  } = props;
  const {
    query: {
      doctor: paramsDoctors,
      location: paramsLocation,
      visitType: paramsVisitType,
      testType: paramsTestType,
      // payer: paramsPayer,
      date: paramsDate,
      mode,
    },
    query,
    replace,
    path,
  } = useRedirect();
  const [form] = Form.useForm();

  const [appointmentSettings,
    appointmentSettingsError,, getAppointmentSettings] = useCRUDWithoutTab({
    id: listIds.SCHEDULER_APPOINTMENT_SETTINGS, url: apiUrls.GET_BASICS, type: 'read',
  });

  const timeRange = useMemo(() => {
    const defaultTimes = { schedulerStartTime: '09:00:00', schedulerEndTime: '18:00:00' };
    if (!appointmentSettings) {
      if (appointmentSettingsError) {
        return {
          from: moment(defaultTimes.schedulerStartTime, 'HH:mm:ss'),
          to: moment(defaultTimes.schedulerEndTime, 'HH:mm:ss'),
        };
      }
      return {};
    }
    const {
      schedulerStartTime = defaultTimes.schedulerStartTime,
      schedulerEndTime = defaultTimes.schedulerEndTime,
    } = appointmentSettings;
    const from = moment(schedulerStartTime, 'HH:mm:ss');
    const to = moment(schedulerEndTime, 'HH:mm:ss');
    return { from, to };
  }, [appointmentSettings, appointmentSettingsError]);

  // ----------------------------------- < STATES > ------------------------------------

  const [selectedDate, setSelectedDate] = useState(moment(paramsDate));
  const [selectedDoctor, setSelectedDoctors] = useState([]);
  const [schedulerData, setSchedulerData] = useState({});
  const [location, setLocation] = useState(null);
  const [filteredDoctors, filterDoctors] = useState(doctors);
  const [viewType, setViewType] = useState('days');
  const [dataViewType, setSchedularDataViewType] = useState(mode || 'scheduler');
  const [visitType, setVisitType] = useState();
  const [selectedPayer, setSelectedPayer] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [testType, setTestType] = useState();
  const [allDoctors, setAllDoctors] = useState(null);
  const [allLocations, setAllLocations] = useState(null);
  const [allVisitType, setAllVisitType] = useState(null);
  const [allTestType, setAllTestType] = useState(null);
  const [listAppointmentData, setListAppointmentData] = useState(false);
  const [isAppointmentModalVisible, toggleAppointmentModalVisible] = useState(false);
  const [isInitialDoctorSet, SetIsInitialDoctorSet] = useState(false);
  const [isInitialLocationSet, SetIsInitialLocationSet] = useState(false);
  const { localStorageData, loggedInProviderId } = useMemo(() => ({ localStorageData: JSON.parse(localStorage.getDecryptedData('userSettingsData')), loggedInProviderId: getLoggedInProviderId() }), []);
  // ---------------------------------- < CALLBACKS > -----------------------------------

  const saveSchedulerFilters = useCallback(debounce((data) => {
    if (!loggedInProviderId) {
      const filters = decode(data?.search || '');
      const doctorFilter = filters.doctor?.split(',') || [];
      filters.doctor = doctorFilter;
      filters.testType = filters.testType?.split(',');
      const clonedLocalStorage = { ...localStorageData };
      if (clonedLocalStorage?.appliedFilters) {
        clonedLocalStorage.appliedFilters.doctor = parseInt(doctorFilter[
        doctorFilter?.length - 1], 10) || [];
        clonedLocalStorage.appliedFilters.location = parseInt(filters.location, 10)
        || undefined;
      } else {
        const appliedFilters = {
          doctor: parseInt(doctorFilter[doctorFilter?.length - 1], 10) || [],
          location: filters.location ? parseInt(filters.location, 10)
            : undefined,
        };
        clonedLocalStorage.appliedFilters = appliedFilters;
      }
      let { newSchedularFilters } = clonedLocalStorage?.current || {};
      if (!clonedLocalStorage?.current) {
        clonedLocalStorage.current = {};
      }
      newSchedularFilters = isObject(newSchedularFilters || {})
        ? newSchedularFilters || {} : JSON.parse(newSchedularFilters);
      newSchedularFilters = {
        doctor: doctorFilter
          ? doctorFilter.map((doctor) => parseInt(doctor, 10)) : [],
        location: parseInt(filters.location, 10)
        || undefined,
        visitType: filters?.visitType,
        testType: filters?.testType,
        payer: filters?.payer,
        region: filters?.region,
        mode: filters?.mode,
        date: filters?.date,
      };
      clonedLocalStorage.current.newSchedularFilters = newSchedularFilters;
      localStorage.setEncryptedData('userSettingsData', JSON.stringify({ ...clonedLocalStorage }));
    }
  }, 1000), []);

  const toggleAppointmentModal = useCallback(
    () => toggleAppointmentModalVisible(!isAppointmentModalVisible),
    [isAppointmentModalVisible],
  );

  const getSelectedSchedulerData = useCallback(
    (ids) => {
      if (!size(ids)) return;
      const params = getSchedulerDataParams({
        selectedDate, viewType, ids, visitType, testType, selectedLocation,
      });
      dispatch(getSelectedDoctorsData(params));
    },
    [dispatch, selectedDate, viewType, visitType, testType, selectedLocation],
  );
  const getSelectedSchedulerFreeSlots = useCallback(
    (ids) => {
      if (!size(ids)) return;
      const params = getSchedulerDataParams({
        selectedDate, viewType, ids, visitType, testType, selectedLocation,
      });
      if (params.providerId) {
        dispatch(getSelectedDoctorsFreeSlots(params));
      }
    },
    [dispatch, selectedDate, viewType, visitType, testType, selectedLocation],
  );

  // callback for selected doctors and location filter form the header
  // Will not be useful when the selected doctor's data will be available through API
  const schedulerHeaderHandler = useCallback((field) => (values) => {
    if (field === 'doctor') {
      setSelectedDoctors(values);
    } else if (field === 'location') {
      setLocation(values);
    }
  }, []);

  // Returns generic heading string for the scheduler according to the views
  const getHeading = useCallback(() => {
    if (selectedDoctor && selectedDoctor.length) {
      if (selectedDoctor.length === 1 || viewType !== 'days') return selectedDoctor[0]?.providerName;
      return "Dr.'s";
    }
    return null;
  }, [selectedDoctor, viewType]);

  const reFetchData = useCallback(() => getSelectedSchedulerData(selectedDoctor.map((
    doctor,
  ) => doctor.providerId)), [selectedDoctor, getSelectedSchedulerData]);

  const checkAppointmentRules = useCallback(({
    itemId, time, visit, doctor, callback = () => { /* This is intentional */ },
  }) => {
    if (!(appointmentSettings && time && visit && doctor)) return false;
    if (appointmentSettings?.canScheduleBackToBack) return true; // No validation required

    const { visitTypeId, visitName, doctorTime } = visit;
    const restrictedVisitTypes = appointmentSettings?.restrictedVisitType?.split(',');

    if (!restrictedVisitTypes.length) return true; // No validation required
    if (restrictedVisitTypes.every(
      (restrictedVisitType) => visitTypeId !== parseInt(restrictedVisitType, 10),
    )) return true; // Visit type is not restricted

    const { providerId } = doctor;

    if (visitTypeId && doctorTime && providerId) {
      const { appointments = [] } = schedulerData[providerId] || {};

      return appointments.every(({
        appointmentId, visitType: appointmentVisitType, startTime,
      }) => {
        if (appointmentId !== itemId && appointmentVisitType?.visitTypeId === visitTypeId) {
          const startDiff = moment(startTime).diff(moment(time), 'minutes');
          if (startDiff >= 0 && startDiff < visitTypeCoolDownTime) {
            const message = `${
              labels.get('messages.tooCloseForValid1')
            } ${visitTypeCoolDownTime} minutes ${
              labels.get('messages.tooCloseForValid2')
            } ${visitName}. ${
              labels.get(itemId ? 'messages.tooCloseForValidMoving' : 'messages.tooCloseForValidBooking')
            }`;
            ConfirmDialog({
              onOk: (close) => {
                close();
                callback();
              },
              okText: labels.get('buttons.override'),
              title: labels.get('titles.alert'),
              content: message,
              icon: <Icon name="ExclamationCircleOutlined" />,
            })();
            return false;
          }
        }
        return true;
      });
    }
    return false;
  }, [appointmentSettings, schedulerData, labels]);

  // ----------------------------------- < EFFECTS > ------------------------------------

  useEffect(() => {
    if (!localStorageData) {
      dispatch(getUserSettings());
    } // Update data on mount, even if we already have the settings
    getAppointmentSettings();
  }, []);

  const [dummyRender, setDummyRender] = useState(false);

  useEffect(() => {
    if (path !== UiRoutes.schedularWithExtraInfo
      && !query?.doctor
      && localStorageData
    ) {
      const {
        appliedFilters, current = {},
      } = { ...localStorageData };
      const {
        schedulerParams = '',
        doctor: doctorParams,
      } = current;
      const schedulerFilters = current?.newSchedularFilters || current?.schedulerFilters || {};
      if (schedulerFilters?.testType?.length && isObject(schedulerFilters.testType[0])) {
        setTestType(schedulerFilters.testType);
      }

      setSelectedPayer(parseInt(schedulerFilters?.payer, 10) || null);
      if (isEmpty(appliedFilters)) {
        replace({
          search: schedulerParams,
          pathname: path,
        });
      } else {
        let newDoctorParams = schedulerFilters?.doctor ? clone(schedulerFilters?.doctor) : [];
        let newParamsLocation;
        if (appliedFilters?.doctor) {
          if (newDoctorParams?.length === 5) {
            const index = newDoctorParams?.findIndex((doctor) => doctor === doctorParams);
            if (index > -1)newDoctorParams.splice(index, 1, appliedFilters?.doctor);
          } else newDoctorParams.push(appliedFilters?.doctor);
        } else { newDoctorParams.pop(); }
        newDoctorParams = uniq(compact(newDoctorParams));
        if (appliedFilters?.location && newDoctorParams?.length <= 1) {
          newParamsLocation = appliedFilters.location;
        }
        delete schedulerFilters.lasModified;
        localStorageData.appliedFilters = {
          doctor: newDoctorParams[newDoctorParams.length - 1],
          location: newParamsLocation,
        };
        if (!localStorageData.current) {
          localStorageData.current = {};
        }
        localStorageData.current.newSchedularFilters = {
          ...schedulerFilters,
          doctor: newDoctorParams,
        };
        localStorage.setEncryptedData('userSettingsData', JSON.stringify(localStorageData));
        replace({
          search: encode({
            ...schedulerFilters,
            doctor: newDoctorParams?.join(','),
            location: newParamsLocation,
            mode: dataViewType,
            testType: schedulerFilters?.testType?.join(','),
          }),
          pathname: path,
        });
      }
    }
  }, [userSettings, localStorageData, dummyRender]);

  const setDataViewType = useCallback((type) => {
    if (dataViewType === 'list') reFetchData();
    setSchedularDataViewType(type);
  }, [dataViewType, reFetchData]);

  useEffect(() => {
    if (query?.mode && ['scheduler', 'list'].indexOf(query.mode) !== -1) {
      if (dataViewType !== query?.mode) {
        setDataViewType(query?.mode || '');
      }
    }
  }, [query]);

  useEffect(() => {
    if (allDoctors && paramsDoctors && !isInitialDoctorSet) {
      const selectedDoctors = allDoctors.filter((item) => paramsDoctors.split(',')
        .find((doctor) => item.providerId === parseInt(doctor, 10)));
      setSelectedDoctors(selectedDoctors);
      if (allDoctors && Array.isArray(allDoctors)) {
        SetIsInitialDoctorSet(true);
      }
    }
  }, [allDoctors]);

  useEffect(() => {
    if (allLocations && paramsLocation && !isInitialLocationSet) {
      const selectedLocations = allLocations
        .filter((item) => parseInt(paramsLocation, 10) === item.locationId);
      setSelectedLocation(selectedLocations[0]);
      if (allLocations && Array.isArray(allLocations)) {
        SetIsInitialLocationSet(true);
      }
    }
  }, [allLocations]);

  useEffect(() => {
    if (allVisitType && paramsVisitType) {
      const selectedVisitType = allVisitType
        .filter((item) => parseInt(paramsVisitType, 10) === item.visitTypeId);
      setVisitType(selectedVisitType[0]);
    }
  }, [allVisitType]);

  useEffect(() => {
    if (allTestType && paramsTestType) {
      const selectedTestType = allTestType.filter((item) => paramsTestType.split(',')
        .find((testTypeId) => item.examTypeId === parseInt(testTypeId, 10)));

      setTestType(selectedTestType);
    }
  }, [allTestType]);

  // uses filterDoctors method to filter doctors according to the location (filter) selected
  // also it sets the needle position as the location tabs are removed on filter select
  useEffect(() => {
    if (location) {
      filterDoctors(doctors.filter(
        (doc) => !!(findIndex(doc.locations, location) + 1),
      ));
      setSchedulerData(schedulerData.filter((doc) => filterDoctorByLocation(doc, location)));
    } else filterDoctors(doctors);
  }, [location]);

  useEffect(() => {
    if (error) {
      let { message: messages } = error;
      if (get(error, 'response.data.Errors')) {
        messages = get(error, 'response.data.Errors', []);
      } else if (get(error, 'response.data.Message') || get(error, 'response.data.message')) {
        messages = get(error, 'response.data.Message') || get(error, 'response.data.message');
      }
      Notification({ message: (<Error messages={messages} />) });
      dispatch(setError(null));
    }
  }, [error, dispatch]);

  useEffect(() => {
    keys(selectedSchedulerData).forEach((date) => {
      keys(selectedSchedulerData[date]).forEach((id) => {
        appointmentsParser(selectedSchedulerData[date][id], timeRange, slotDuration);
        scheduleDataParser(selectedSchedulerData[date][id], timeRange, slotDuration);
      });
    });
    if (viewType === 'days') {
      const date = dateFormatter(selectedDate, dateFormatStartsWithYear);
      if (get(selectedSchedulerData, date)) {
        setSchedulerData(get(selectedSchedulerData, date));
      }
    } else {
      setSchedulerData(selectedSchedulerData);
    }
  }, [selectedSchedulerData, selectedDate, viewType, timeRange]);

  useEffect(() => {
    if (size(selectedDoctor) || selectedLocation) {
      getSelectedSchedulerData(selectedDoctor.map((doctor) => doctor.providerId));
    }
    // getSelectedSchedulerData not included in dependencies to avoid useless calls
  }, [selectedDate, selectedDoctor, viewType, selectedLocation]);

  useEffect(() => {
    // get available slot positions on filter change
    if (size(selectedDoctor)) {
      getSelectedSchedulerFreeSlots(selectedDoctor.map((doctor) => doctor.providerId));
    }
    // getSelectedSchedulerFreeSlots not included in dependencies to avoid useless calls
  }, [visitType, testType]);

  return (
    <>
      <div className="schedular-wrapper">
        <SchedulerHeader
          doctors={filteredDoctors}
          doctor={selectedDoctor && selectedDoctor.map((doctor) => get(doctor, 'providerId', ''))}
          selectedDoctor={selectedDoctor}
          locations={locations}
          location={location ? location.value : null}
          viewType={viewType}
          schedulerHeaderHandler={schedulerHeaderHandler}
          setVisitType={setVisitType}
          visitType={visitType}
          labels={labels}
          selectedPayer={selectedPayer}
          setSelectedPayer={setSelectedPayer}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          setTestType={setTestType}
          setAllDoctors={setAllDoctors}
          setAllLocations={setAllLocations}
          setAllVisitType={setAllVisitType}
          setAllTestType={setAllTestType}
          saveSchedulerFilters={saveSchedulerFilters}
          testType={testType}
          selectedDate={selectedDate}
          form={form}
          schedulerData={schedulerData}
          dataViewType={dataViewType}
        />
        <SavedNavigationBar parentPath={UiRoutes.schedular}>
          {({ closeTab, handleQuery }) => (
            <Switch>
              <Route exact path={[UiRoutes.schedular, UiRoutes.schedularWithId]}>
                {loading && <Loader />}
                <div className="schedular-title-wrapper">
                  <SchedulerTitle
                    selectedDate={selectedDate}
                    viewType={viewType}
                    setViewType={setViewType}
                    setSelectedDate={setSelectedDate}
                    doctorName={getHeading()}
                    labels={labels}
                    dataViewType={dataViewType}
                    setDataViewType={setDataViewType}
                    saveSchedulerFilters={saveSchedulerFilters}
                  />
                </div>
                {appointmentSettings ? (
                  <SchedulerGrid
                    setNavigationBarQuery={handleQuery}
                    slotDuration={slotDuration}
                    timeRange={timeRange}
                    minSlotTime={minSlotTime}
                    schedulerData={schedulerData}
                    reFetchData={reFetchData}
                    selectedDate={selectedDate}
                    selectedDoctor={selectedDoctor}
                    location={location}
                    testType={testType}
                    viewType={viewType}
                    dataViewType={dataViewType}
                    visitType={visitType}
                    selectedPayer={selectedPayer}
                    selectedLocation={selectedLocation}
                    setListAppointmentData={setListAppointmentData}
                    toggleAppointmentModal={toggleAppointmentModal}
                    getSchedulerDataParams={getSchedulerDataParams}
                    checkAppointmentRules={checkAppointmentRules}
                    allDoctors={allDoctors}
                    allLocations={allLocations}
                    form={form}
                  />
                ) : <span />}
              </Route>
              <Route path={UiRoutes.schedularWithExtraInfo}>
                <Suspense fallback={<WidgetLoader />}>
                  <AppointmentExtraInfo
                    closeTab={closeTab}
                    reFetchSchedulerData={reFetchData}
                    setDummyRender={setDummyRender}
                  />
                </Suspense>
              </Route>
            </Switch>
          )}
        </SavedNavigationBar>
      </div>
      {isAppointmentModalVisible && (
      <AddAppointmentDialog
        isVisible={isAppointmentModalVisible}
        toggle={toggleAppointmentModalVisible}
        mode="edit"
        data={listAppointmentData}
        minSlotTime={minSlotTime}
        visitType={visitType}
        reFetchData={reFetchData}
        testType={testType}
      />
      )}
    </>
  );
}

export default connect((state) => ({
  doctors: selectors.doctors(state),
  locations: selectors.locations(state),
  selectedSchedulerData: selectors.schedulerData(state),
  loading: selectors.schedulerLoading(state),
  error: selectors.schedulerError(state),
  userSettings: selectors.userSettings(state),
}))(WithRights(WithLabel(Schedular, labelPaths.SCHEDULAR), rights.access_to_schedular_app));
