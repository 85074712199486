/* eslint-disable eqeqeq */
import React, {
  useEffect, useCallback, useMemo, useState, useRef,
} from 'react';
import { connect, useSelector } from 'react-redux';
import { Tooltip, Form as AntdForm } from 'antd';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import debounce from 'lodash/debounce';
import clone from 'lodash/clone';
import uniqBy from 'lodash/uniqBy';
import compact from 'lodash/compact';

import { orderBy } from 'lodash';
import { cptWithSixCodes, JArray } from '../../../../../lib/util';
import Events from '../../../../../lib/events';
import { labelPaths, listIds } from '../../../../../lib/constants';

import { apiUrls } from '../../../../../api/constants';
import useRedirect from '../../../../../hooks/useRedirect';
import useCRUD from '../../../../../hooks/useCRUD';
import useCRUDWithoutTab from '../../../../../hooks/useCRUDWithoutTab';

import WithLabel from '../../../../../hoc/withLabel';

import Form from '../../../../../components/Form';
import Radio from '../../../../../components/Form/Radio';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Form/Input';
import SelectBox from '../../../../../components/Form/SelectBox';
import AutoComplete from '../../../../../components/Form/GridAutoComplete';
import Notification from '../../../../../components/Notification';
import Spinner from '../../../../../components/Spinner';
import SearchBox from '../../../../../components/Search';
import Table from '../../../../../components/Table';
import WidgetLoader from '../../../../../components/WidgetLoader';
import Tabs from '../../../../../components/Tabs/TabsWithoutUrlNavigation';
import { procedureParser as parser } from '../../../commonParser';
import TextBox from './Components/TextBox';
import DropDown from './Components/DropDown';

import * as selectors from '../../../../../store/selectors';

import ErrorMessages from '../../../../../lib/errorMessages';
import Icon from '../../../../../components/Icon';
import iconNames from '../../../../../lib/iconNames';
import ModifierGroup from '../../../../../wiredComponents/ModifierGroup';
import SetModal from '../../../Components/PatientDetails/Components/Tabs/CustomDropppableTab/Component/SetModal';
import AddSynonym from '../../../../../wiredComponents/AddSynonym';
import AddSynonymWithoutCode from '../../../../../wiredComponents/AddSynonym/AddSynonymWithoutCode';
import PreviousPatient from './Tabs/PreviousPatient';
import Global from './Tabs/Global';
import Crosswalk from './Tabs/Crosswalk';
import PreviousDxList from './Tabs/PreviousDxList';
import './ProcedureForm.scss';

const color = ['#2a4a79'];

const tabsList = [
  {
    id: 'ocularList',
    component: PreviousPatient,
    name: 'Ocular List',
  },
  {
    id: 'allPreviousDx',
    component: PreviousDxList,
    name: 'All Previous Dx',
  },
  {
    id: 'crosswalk',
    component: Crosswalk,
  },
  {
    id: 'global',
    component: Global,
  },
];

const optionParser = (option) => ({
  value: option.id,
  code: option.cptCode,
  name: option.cptCode,
  desc: option.description,
  ...option,
});

const lucentisOptions = [{ label: '0.3 mg', value: '0.3 mg' }, { label: '0.5 mg', value: '0.5 mg' }];

const columns = [
  {
    Header: 'Code',
    accessor: 'cptCode',
    flex: 1,
  },
  {
    Header: 'Description',
    accessor: 'description',
    flex: 5,
  },
];
const selectedCPTColumns = (onDeleteICD) => [
  {
    Header: 'Code',
    accessor: 'icdCode',
    Cell: ({ row: { original } }) => <span>{original?.icdCode || original?.icdCodeId}</span>,
    fixWidth: 100,
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: '',
    accessor: 'delete',
    Cell: ({ row: { index, original: { id } } }) => (
      <Icon
        name={iconNames.deleteIcon}
        onClick={() => onDeleteICD(index, id)}
      />
    ),
    fixWidth: 45,
  },
];
const selectedCPTColumns2 = [
  {
    Header: 'Code',
    accessor: 'icdCode',
    Cell: ({ row: { original } }) => <span>{original?.icdCode || original?.icdCodeId}</span>,
    fixWidth: 100,
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
];

const Components = {
  textbox: TextBox,
  dropdown: DropDown,
};

const StatusMaster = {
  documentAndBill: 'Document and Bill',
  documentOnly: 'Document Only',
};

const CustomError = ({ message }) => (
  <div className="ant-row">
    <div className="ant-row ant-form-item ant-form-item-with-help ant-form-item-has-error">
      <div className="ant-col ant-form-item-control">
        <div className="ant-form-item-explain">
          <div>{message}</div>
        </div>
      </div>
    </div>
  </div>
);

const NestedListWrapper = ({ add }) => {
  useEffect(() => {
    Events.on('addNestedListFields', 'addNestedListFields', add);
    return () => {
      Events.remove('addNestedListFields', 'addNestedListFields');
    };
  });
  return <span />;
};

const checkCPTQuestionareData = (data) => (data?.[0]?.Lable === 'J-code' || data?.[0]?.Lable === 'Power' || data?.[0]?.Lable === 'Lot #');
const ProcedureForm = ({
  labels,
  formData,
  form,
  loading,
  getQuestionnaireFromCPT,
  selectedICDCodes,
  setSelectedICDCodes,
  onICDCheckBoxClick,
  questionnaireData,
  encounterProcedureStatus,
  isEditFavourite,
  setQuestionnaireData,
  deleteCPTCodeId,
  disabled,
  isLoading,
  hideDiagnosis,
  showJCodeFields,
  isAutoSave,
  isEditProcedure,
  setSaveProcedureReference,
  isModalVisible,
  setModalVisibility,
  isAddFromOverview,
  parsedJcodeOptions,
  procedureData,
  setShowProcedureSets,
}) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedProcedureCPT, setSelectedProcedureCPT] = useState(null);
  const [procedureSearchText, setProcedureSearchText] = useState('');
  const [icdFilters, setICDCodeFilter] = useState({});
  const [labelList, setLabelsList] = useState([]);
  const [defaultCheckedProcedure, setDefaultCheckedProcedure] = useState(null);
  const [selectedProcedureDetails, setSelectedProcedureDetails] = useState(null);
  const [ODOSModifierOptions, , , getODOSModifierOptions] = useCRUDWithoutTab({ id: listIds.GET_MODIFIER_OPTIONS, url: apiUrls.GET_MODIFIER_OPTIONS, type: 'read' });
  const [, setHandleFirstModifierValue] = useState(null);
  const clonedNotes = useRef([]);

  const [modalInstanceForm] = AntdForm.useForm();

  const { params: { encounterId, providerId, patientId } } = useRedirect();

  const [selectedTab, setSelectedTab] = useState('ocularList');

  const { procedureListResponse, procedureListLoading } = useSelector((state) => {
    const cptDiagnosisList = selectors.getCrudWithoutTab(state, `get-diagnosis-procedure-list-${encounterId}`);
    return {
      procedureListResponse: cptDiagnosisList?.get('data'),
      procedureListLoading: !!cptDiagnosisList?.get('loading'),
    };
  });

  const getOcularProblemList = useCRUDWithoutTab({
    id: `modifierListA&P-${encounterId}`,
    url: `${apiUrls.CURRENT_ENCOUNTER_DIAGNOSIS}?EncounterId=${encounterId}`,
    type: 'read',
  })[3];

  const [,,, getResult, clearResult] = useCRUD({
    id: apiUrls.GET_ICD_WITH_FAVORITES,
    url: `${apiUrls.GET_ICD_WITH_FAVORITES}?PageSize=15`,
    type: 'read',
  });

  const [prevDiagnosisResponse, , prevDiagnosisLoading, getPreviousDiagnosis] = useCRUD({
    id: 'previous-encounter-dx-list-overview',
    url: apiUrls.GET_PREVIOUS_ENCOUNTER_DX_LIST,
    type: 'read',
  });

  useEffect(() => {
    if (!ODOSModifierOptions?.length) getODOSModifierOptions();
  }, [ODOSModifierOptions, getODOSModifierOptions]);

  useEffect(() => {
    if (!isEditProcedure && questionnaireData?.Questionnaire?.length && isAddFromOverview) {
      Events.trigger('addNestedListFields');
    }
  }, [questionnaireData]);

  useEffect(() => () => clearResult(true), [clearResult]);

  useEffect(() => {
    if (isAddFromOverview) {
      getPreviousDiagnosis({ patientId, isSignedEncounter: true });
    }
  }, []);

  const previousDiagnosisList = useMemo(() => {
    if (prevDiagnosisResponse?.result?.length) {
      const parsedDiagnosis = [];
        prevDiagnosisResponse?.result?.forEach((item) => {
          item?.procedureDetails?.forEach((proc) => {
            proc?.diagnosisList?.forEach((dg) => {
              parsedDiagnosis?.push(dg);
            });
          });
        });
        return orderBy(uniqBy(parsedDiagnosis, 'id'), ['icdCode'], ['asc']);
    }
    return [];
  }, [prevDiagnosisResponse]);

  const { ocularProblemListResponse, ocularProblemLoading } = useSelector((state) => {
    const ocularProblemList = selectors.getCrudWithoutTab(state, `modifierListA&P-${encounterId}`);
    let ocularList = ocularProblemList?.get('data');

    if (ocularList) {
      ocularList = orderBy(ocularList, ['icdCode'], ['asc']);
    }
    return {
      ocularProblemListResponse: ocularList,
      ocularProblemLoading: !!ocularProblemList?.get('loading'),
    };
  });

  const isDisabled = useMemo(
    () => (disabled || (formData?.encounterId && formData?.encounterId != encounterId)
    || (isAddFromOverview && formData?.isJcode)),
    [disabled, encounterId, formData, isAddFromOverview],
  );

  useEffect(() => {
    if (setShowProcedureSets) setShowProcedureSets(isDisabled);
  }, [isDisabled]);

  const procedureHeading = useMemo(() => {
    let cptDescription = '';
    if (questionnaireData?.cptDescription) {
      cptDescription = `- ${questionnaireData?.cptDescription?.split('\n')?.[0]}`;
    }
    return `${questionnaireData?.cptCode || ''} ${cptDescription}`;
  }, [questionnaireData]);

  useEffect(() => {
    if (procedureListResponse) {
      const defaultCheckedProcedureData = procedureListResponse?.procedureDetails?.find(
     ({ cptCode }) => (cptCode !== null && defaultCheckedProcedure !== null)
     && (cptCode === defaultCheckedProcedure));
      if (defaultCheckedProcedureData && selectedProcedureCPT) {
        setDefaultCheckedProcedure({
          cptCodeId: defaultCheckedProcedure?.cptCodeId,
          groupId: defaultCheckedProcedureData?.groupId,
          procedureDetailsId: defaultCheckedProcedureData?.procedureDetailsId,
          dxIds: defaultCheckedProcedureData?.diagnosisList?.map((item) => item?.id),
        });
      }
    }
  }, [defaultCheckedProcedure, procedureListResponse, selectedProcedureCPT]);

  useEffect(() => {
    if (procedureListResponse) {
      setLabelsList(parser(procedureListResponse));
    }
  }, [procedureListResponse]);

  useEffect(() => {
    if (formData.cptCode) {
      setSelectedProcedureCPT(formData.cptCode);
      setDefaultCheckedProcedure(formData?.cptCode);
    }
    if (questionnaireData) {
      if (formData?.result?.length && formData?.result?.[0]?.Prompt1) {
        form.setFieldsValue({ ...formData, results: formData?.result });
      } else {
        form.setFieldsValue(formData);
      }
    }
    if (formData?.modifiers?.length) {
      form.setFieldsValue({
        modifier1: formData?.modifiers?.[0] && parseInt(formData?.modifiers?.[0]?.modifierId, 10),
        modifier2: formData?.modifiers?.[1] && parseInt(formData?.modifiers?.[1]?.modifierId, 10),
        modifier3: formData?.modifiers?.[2] && parseInt(formData?.modifiers?.[2]?.modifierId, 10),
        modifier4: formData?.modifiers?.[3] && parseInt(formData?.modifiers?.[3]?.modifierId, 10),
        procedureDescription: formData?.procedureDescription,
        results: formData?.result,
      });
    }
  }, [formData, isAddFromOverview, questionnaireData]);

  useEffect(() => {
    Events.on('saveAsProcedureSet', 'saveAsProcedureSet', () => {
      setModalVisibility(!isModalVisible);
    });
    return () => Events.remove('saveAsProcedureSet', 'saveAsProcedureSet');
  }, [isModalVisible, setModalVisibility]);

  useEffect(() => {
    const {
      cptCode, cptCodeId, cptDescription, isJcode,
    } = procedureData || {};
    if (cptCode && questionnaireData?.cptCode !== cptCode) {
      getQuestionnaireFromCPT(cptCode, {
        item: {
          id: cptCodeId,
          cptCode,
          description: cptDescription,
          isJcode,
        },
      });
    }
  }, [procedureData]);

  const onFieldChange = useCallback(
    (type, fieldName = '', nestedFieldKey, data = {}) => {
      if (type === 'dropdown') {
        if (fieldName.toLowerCase() === 'j-code') {
          let dosageField = null;
          let unitField = null;
          if (questionnaireData?.Questionnaire?.length) {
            dosageField = questionnaireData?.Questionnaire.find(
                (field) => field.Lable === 'Dosage',
              );
            unitField = questionnaireData?.Questionnaire.find(
                (field) => field.Lable === 'Units',
              );
          } else {
            dosageField = JArray.find(
              (field) => field.Lable === 'Dosage',
            );
            unitField = JArray.find(
              (field) => field.Lable === 'Units',
            );
          }
          if (dosageField && dosageField.Key) {
            form.setFields([
              {
                name: ['results', nestedFieldKey, dosageField.Key],
                value: data?.item?.Dosage || data?.Dosage,
              },
              {
                name: ['results', nestedFieldKey, unitField.Key],
                value: data?.item?.Units || data?.Units,
              },
            ]);
          }
        }
      }
    },
    [form, questionnaireData],
  );

  useEffect(() => {
    if (formData?.Prompt1 && formData?.isJcode && !formData?.result?.length && showJCodeFields) {
      const prompt1Data = JArray.filter((field) => field?.Key?.toLowerCase() === 'prompt1');
      const selectedJcode = prompt1Data[0].Options?.filter((jcodeValue) => jcodeValue?.['J-code'] === formData?.Prompt1);
      if (selectedJcode?.length) {
        onFieldChange('dropdown', 'j-code', selectedJcode[0]);
      }
    }
  }, [form, formData, showJCodeFields]);

  const saveProcedure = useCallback(({ target }) => {
    if (isEmpty(questionnaireData)) {
      const {
        modifier1,
        modifier2,
        modifier4,
        modifier3,
      } = form.getFieldsValue();
      setErrorMessage('Procedure is required');
      if (!modifier1 || !modifier2 || !modifier3 || !modifier4) {
        form.submit();
        return;
      }
      Notification({
        message: ErrorMessages.PLEASE_FILL_ALL_REQUIRED_FIELDS,
      });
      return;
    }
    const status = encounterProcedureStatus.find(
      (procedureStatus) => procedureStatus?.masterName === StatusMaster[target?.name],
    );
    form.setFieldsValue({ status: status?.masterId });
    form.submit();
  },
  [encounterProcedureStatus, form, questionnaireData]);

  const onSelectProcedure = useCallback((item) => {
    setSelectedProcedureDetails(item);
    setSelectedProcedureCPT(item.cptCode);
    setDefaultCheckedProcedure(item.cptCode);
    setErrorMessage(null);
    setICDCodeFilter({ CPTCodeText: item?.cptCode });
    getQuestionnaireFromCPT(item.cptCode, { item });
    form.resetFields();
  },
  [form, getQuestionnaireFromCPT]);

  const onProcedureSearch = useCallback(debounce((searchText) => {
    setProcedureSearchText(searchText);
  }, 500), []);

  const handleDeleteProcedure = useCallback(() => {
    if (formData?.cptCodeId) {
      deleteCPTCodeId({
        procedureDetailsId: formData.procedureDetailsId,
        groupId: formData.groupId,
      });
      setSelectedProcedureCPT(null);
      setSelectedProcedureDetails(null);
      setQuestionnaireData({});
      setSelectedICDCodes([]);
    } else {
      setSelectedProcedureCPT(null);
      setSelectedProcedureDetails(null);
      setQuestionnaireData({});
    }
    form.resetFields();
  }, [deleteCPTCodeId, form, formData, setQuestionnaireData, setSelectedICDCodes]);

  const handleLucentisUnit = useCallback((key, event) => {
    const { target: { value } } = event;
    if (value === '0.3 mg') {
      form.setFields([{
        name: ['results', key, 'Prompt5'],
        value: '3',
      }]);
    }
    if (value === '0.5 mg') {
      form.setFields([{
        name: ['results', key, 'Prompt5'],
        value: '5',
      }]);
    }
  }, [form]);

  const debounceSearch = useMemo(() => debounce((value) => {
    if (value) {
      getResult({ ICDCodeText: value });
      getPreviousDiagnosis({ patientId, searchText: value, isSignedEncounter: true });
      getOcularProblemList({ searchText: value });
      setICDCodeFilter({
        ...icdFilters,
        ICDCodeText: value,
      });
    } else {
      setICDCodeFilter({
        ...icdFilters,
      });
      getPreviousDiagnosis({ patientId, isSignedEncounter: true });
      getOcularProblemList({});
    }
  }, 1500), [getOcularProblemList, getPreviousDiagnosis, getResult, icdFilters, patientId]);

  const onSearch = useCallback((event) => {
    event.persist();
    debounceSearch(event?.target?.value);
  }, [debounceSearch]);

  const onDeleteICD = useCallback((index, id) => {
    const clonedAllCPTs = clone(selectedICDCodes);
    delete clonedAllCPTs[id];
    setSelectedICDCodes(clonedAllCPTs);
  }, [selectedICDCodes, setSelectedICDCodes]);

  const toggleProcedureSetModal = useCallback(() => {
    if (isModalVisible) {
      setModalVisibility(false);
      setSelectedProcedureCPT(null);
    } else if (selectedProcedureCPT) {
      saveProcedure({ target: { name: 'documentAndBill' } });
      setSaveProcedureReference('saveAsProcedureSet');
    } else {
      setModalVisibility(!isModalVisible);
    }
  }, [isModalVisible, setModalVisibility, saveProcedure, selectedProcedureCPT]);

  const getProcedurePlaceholder = useCallback(() => {
    if (parseInt(selectedProcedureCPT, 10) >= 70000
     && parseInt(selectedProcedureCPT, 10) <= 90000) {
      return 'Notes for radiologist/pathologist /laboratory';
    }
    return 'Procedure Description';
  }, [selectedProcedureCPT]);

  const handleProcedureSaveOnly = useCallback(() => {
    saveProcedure({ target: { name: 'documentAndBill' } });
    setSelectedProcedureCPT(null);
    setDefaultCheckedProcedure(null);
  }, [saveProcedure]);

  const handleAddNestedList = useCallback(() => {
    Events.trigger('addNestedListFields');
  }, []);

  const handleCustomRemove = useCallback((fieldRowName) => {
    const value = form.getFieldValue('results');
    if (typeof value[fieldRowName] === 'object' && Object.keys(value[fieldRowName]).length !== 0 && value[fieldRowName]?.id) {
      value[fieldRowName] = Object.assign(value[fieldRowName] || {}, { action: 'delete' });
    } else {
      value.splice(fieldRowName, 1);
    }
    form.setFieldsValue({ results: value });
  }, [form]);

  const getJcodeModifierOptions = useCallback((isClear = false, key) => {
    if (isClear && isAddFromOverview) {
      const { [key]: Key, results } = form.getFieldsValue();
      results?.forEach((item, index) => {
        if (item?.modifier === Key) {
          form.setFields([{ name: ['results', index, 'modifier'], value: null }, { name: key, value: undefined }]);
        }
      });
    }
    const {
      modifier1, modifier2, modifier3, modifier4,
    } = form.getFieldsValue();
    const jcodeOptionsArray = [];
    const options = form.getFieldInstance('modifier2')?.props?.children?.props?.children?.[0];
    (options || []).forEach((element) => {
      if ([modifier1, modifier2, modifier3, modifier4].includes(element.props?.value)) {
        if (element?.props?.name === '50' || element?.props?.name === 50) {
          const ltPair = ODOSModifierOptions?.action?.find((item) => item?.modifierCode.toLowerCase() === 'lt');
          const rtPair = ODOSModifierOptions?.action?.find((item) => item?.modifierCode.toLowerCase() === 'rt');
          if (ltPair && rtPair) {
            jcodeOptionsArray.push(
              { name: ltPair?.description, value: ltPair?.modifierId },
              { name: rtPair?.description, value: rtPair?.modifierId },
            );
          }
        }
        if (ODOSModifierOptions?.action?.find(
          (item) => item?.modifierId === element?.props?.value)) {
          jcodeOptionsArray.push({ name: element.props.name, value: element.props.value });
        }
      }
    });
    if (jcodeOptionsArray?.length) {
      return uniqBy(jcodeOptionsArray, 'value');
    } if (!jcodeOptionsArray?.length && parsedJcodeOptions?.length) {
      return uniqBy(parsedJcodeOptions?.reduce((acc, element) => {
        if (element?.name === '50' || element?.name === 50) {
          const ltPair = ODOSModifierOptions?.action?.find((item) => item?.modifierCode.toLowerCase() === 'lt');
          const rtPair = ODOSModifierOptions?.action?.find((item) => item?.modifierCode.toLowerCase() === 'rt');
          if (ltPair && rtPair) {
            acc.push(
              { name: ltPair?.description, value: ltPair?.modifierId },
              { name: rtPair?.description, value: rtPair?.modifierId },
            );
          }
        }
        if (ODOSModifierOptions?.action?.find((item) => item?.modifierId === element?.value)) {
          acc.push({ name: element.name, value: element.value });
        }
        return acc;
      }, []), 'value');
    }
    return [];
  }, [ODOSModifierOptions, form, isAddFromOverview, parsedJcodeOptions]);

  const JcodeModifiersRequiredValidation = useCallback(() => {
    const {
      modifier1, modifier2, modifier3, modifier4,
    } = form.getFieldsValue();
    if (compact([modifier1, modifier2, modifier3, modifier4])?.length > 1) {
      return true;
    } if (parsedJcodeOptions?.length > 1) {
      return true;
    }
    return false;
  }, [form, parsedJcodeOptions]);

  const handleSelectModifiers = useCallback(() => {
    if (isAddFromOverview) {
      const {
        modifier1, modifier2, modifier3, modifier4, results,
      } = form.getFieldsValue();
      results?.forEach((item, index) => {
        if ([modifier1, modifier2, modifier3, modifier4]?.includes(item?.modifier)) {
          form.setFields([{ name: ['results', index, 'modifier'], value: item?.modifier }]);
        } else {
          form.setFields([{ name: ['results', index, 'modifier'], value: undefined }]);
        }
      });
    }
  }, [form, isAddFromOverview]);
  return (
    <div className="procedure-section-form procedure-modal-area">
      <div className="modal-area active">
        {isAddFromOverview && procedureListLoading && <WidgetLoader />}
        <Form.Section noOfColumns={2}>
          <Form.Column>
            {!(hideDiagnosis) && (
              <AutoComplete
                url={apiUrls.GET_CPT_PAGINATED}
                optionMaster="CPTCodeText"
                params={{
                  IncludeInActive: false,
                  PageSize: 20,
                  PageIndex: 0,
                  ExcludeLabImaging: true,
                  ExcludeEnMCodes: true,
                }}
                optionParser={optionParser}
                onSelect={onSelectProcedure}
                placeholder="Search Procedure"
                columns={columns}
                dropdownMatchSelectWidth={300}
                labelSpan="0"
                inputSpan="24"
                labelInValue
                selectProps={{
                  optionLabelProp: 'name',
                }}
                onSearchTextChange={onProcedureSearch}
                disabled={isDisabled || hideDiagnosis}
                notFoundContent={<AddSynonymWithoutCode synonym={procedureSearchText} />}
              />
            )}
            {errorMessage && <CustomError message={errorMessage} />}
          </Form.Column>
        </Form.Section>
        <Form.Section>
          <div className="procedure-heading flex justify-content-sp-bt">
            <p>
              {!isEmpty(trim(procedureHeading)) && (
                <span>
                  {procedureHeading}
                  {(!isDisabled && !hideDiagnosis) && (
                    <Tooltip placement="top" color={color} title="Delete">
                      <Icon
                        name={iconNames.deleteIcon}
                        className="delete-icon"
                        disabled={isDisabled}
                        onClick={handleDeleteProcedure}
                      />
                    </Tooltip>
                  )}
                </span>
              )}
            </p>
            {isAddFromOverview && questionnaireData?.Questionnaire?.length
              ? (
                !isDisabled && (
                <Tooltip placement="top" color={color} title="Add J-code">
                  <Icon
                    name={iconNames.plusIcon}
                    className="plus-icon"
                    onClick={handleAddNestedList}
                  />
                </Tooltip>
                )) : <span />}
          </div>
          <div style={{ display: 'none' }}>
            <Input name="status" />
          </div>
        </Form.Section>
        {(!formData?.isJcode && formData?.source?.toLowerCase() !== 'imaginglab') && (
        <Form.Section>
          <div className="single-line procedure-line justify-content-sp-bt custom-width-24">
            <span className="custom-label w-146 align-left">
              {labels.get('labels.modifiers')}
            </span>
            <ModifierGroup
              cptCode={selectedProcedureCPT}
              modifier1Props={{
                disabled: isDisabled,
                required: isAddFromOverview
                  ? !selectedProcedureDetails?.isJcode : !formData?.isJcode,
                selectProps: {
                  style: { maxWidth: 90, minWidth: 80 },
                  onSelect: () => { setHandleFirstModifierValue(); handleSelectModifiers('modifier2'); },
                  onClear: () => { getJcodeModifierOptions(true, 'modifier1'); },
                },
                filterModifierSearch: true,
              }}
              modifier2Props={{
                onSelect: () => { handleSelectModifiers('modifier2'); },
                disabled: isDisabled,
                selectProps: {
                  onClear: () => { getJcodeModifierOptions(true, 'modifier2'); },
                },
                filterModifierSearch: true,
              }}
              modifier3Props={{
                disabled: isDisabled,
                onSelect: () => { handleSelectModifiers('modifier3'); },
                selectProps: {
                  onClear: () => { getJcodeModifierOptions(true, 'modifier3'); },
                },
                filterModifierSearch: true,
              }}
              modifier4Props={{
                disabled: isDisabled,
                onSelect: () => { handleSelectModifiers('modifier4'); },
                selectProps: {
                  onClear: () => { getJcodeModifierOptions(true, 'modifier4'); },
                },
                filterModifierSearch: true,
              }}
              form={form}
            />
          </div>
        </Form.Section>
        )}
        {!formData?.isJcode && (
        <Form.Section>
          <Input.TextArea
            placeholder={getProcedurePlaceholder()}
            name="procedureDescription"
            label={labels.get('labels.procedureDescription')}
            labelSpan="0"
            inputSpan="24"
            size="large"
            // required={isAddFromOverview ?
            // !selectedProcedureDetails?.isJcode : !formData?.isJcode}
            disabled={isDisabled}
            maxValueLength={1000}
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Form.Section>
        )}
        {checkCPTQuestionareData(questionnaireData?.Questionnaire)
         && (isAddFromOverview || !cptWithSixCodes.includes(formData?.cptCode)) ? (
           <AntdForm.List name="results">
             {(fields, { add, remove }) => (
               <div className="single-line-wrap">
                 {fields.map((nestedField) => (form.getFieldValue('results')[nestedField.name]?.action
                      !== 'delete' && (
                      <div className="single-line input-fields-section" key={nestedField.key}>
                        {(!showJCodeFields
                        || (showJCodeFields && !cptWithSixCodes.includes(formData?.cptCode))) && (
                          (questionnaireData?.Questionnaire || []).map((field) => {
                            const QuestionnaireField = Components[field.Type.toLowerCase()];
                            if (QuestionnaireField) {
                              return (
                                <QuestionnaireField
                                  key={`${field.Key}_${field.Order}`}
                                  labels={labels}
                                  data={[nestedField.name, field]}
                                  jCodeDisable={isAutoSave}
                                  disabled={isDisabled}
                                  onFieldChange={onFieldChange}
                                  form={form}
                                  isDefaultJcode
                                  isEditProcedure={isEditProcedure}
                                />
                              );
                            }
                            return null;
                          })
                        )}
                        {questionnaireData?.Questionnaire?.[0]?.Lable === 'J-code' && (!showJCodeFields
                        || (showJCodeFields && !cptWithSixCodes.includes(formData?.cptCode))) && (
                        <div className="info-row">
                          <AntdForm.Item
                            noStyle
                            shouldUpdate={() => true}
                          >
                            {() => (
                              <SelectBox
                                name={[nestedField.name, 'modifier']}
                                id="23"
                                label="Modifiers"
                                options={getJcodeModifierOptions()}
                                required={JcodeModifiersRequiredValidation()}
                                labelSpan="7"
                                inputSpan="17"
                                disabled={isDisabled}
                              />
                            )}
                          </AntdForm.Item>
                          {(!showJCodeFields) && (
                            !isDisabled && (
                            <Tooltip placement="top" color={color} title="Delete">
                              <Icon
                                name={iconNames.deleteIcon}
                                className="delete-icon manage-dlt-icon"
                                onClick={() => (isEditProcedure
                                  ? handleCustomRemove(nestedField.name, remove)
                                  : remove(nestedField.name))}
                              />
                            </Tooltip>
                            )
                          )}
                        </div>
                        )}
                        <AntdForm.Item
                          noStyle
                          shouldUpdate={() => true}
                        >
                          {({ getFieldValue }) => ((formData?.cptCode === 'J2778' || formData?.cptCode === 'Q5128') || (getFieldValue('results')?.[nestedField.name].Prompt1 === 'J2778' || getFieldValue('results')?.[nestedField.name].Prompt1 === 'Q5128')) && (
                            <Radio
                              options={lucentisOptions}
                              name={[nestedField.name, 'Prompt4']}
                              onChange={(e) => handleLucentisUnit(nestedField.name, e)}
                              labelSpan="3"
                              inputSpan="18"
                              radioProps={{
                                disabled: isDisabled,
                              }}
                            />
                          )}
                        </AntdForm.Item>
                        {questionnaireData?.Questionnaire?.[0]?.Lable === 'J-code' && (!showJCodeFields
                        || (showJCodeFields && !cptWithSixCodes.includes(formData?.cptCode))) && (
                        <div className="info-row" style={{ width: '100%' }}>
                          <Input.TextArea
                            name={[nestedField.name, 'description']}
                            labelSpan="0"
                            inputSpan="24"
                            size="large"
                            placeholder="J-code Description"
                            onChange={onFieldChange}
                            maxValueLength={1000}
                            autoSize={{ minRows: 2, maxRows: 6 }}
                            disabled={isDisabled}
                          />
                        </div>
                        )}
                        {loading && <Spinner size="small" />}
                      </div>
                   )))}
                 <NestedListWrapper add={add} />
               </div>
             )}
           </AntdForm.List>
          ) : null}
        {!hideDiagnosis && (
        <Form.Section>
          <div className="pannel table-pannel ">
            <div className="pannel-heading">
              Selected Diagnosis
            </div>
            <Table
              columns={isDisabled ? selectedCPTColumns2 : selectedCPTColumns(onDeleteICD)}
              data={Object.values(selectedICDCodes)}
              scrollId="icd-table"
              labels={labels}
            />
          </div>
        </Form.Section>
        )}
        {!isEditFavourite
          && (formData?.encounterId == encounterId || !formData?.encounterId)
           && !formData?.isJcode && (
           <Form.Section>
             <div>
               {!hideDiagnosis && (
               <>
                 <div className="merge-fields-container">
                   <div className="temp-search-wrapper">
                     <Form.Section noOfColumns={1}>
                       <Form.Column>
                         <SearchBox
                           name="searchText"
                           onChange={onSearch}
                           style={{ width: '100%' }}
                         />
                       </Form.Column>
                     </Form.Section>
                   </div>
                   <Tabs
                     tabsList={tabsList}
                     className="main-tab-wrap"
                     handleChange={setSelectedTab}
                     selectedTab={selectedTab}
                     ocularProblemListResponse={ocularProblemListResponse}
                     ocularProblemLoading={ocularProblemLoading}
                     onICDCheckBoxClick={onICDCheckBoxClick}
                     labels={labels}
                     questionnaireData={questionnaireData}
                     icdFilters={icdFilters}
                     previousDiagnosisList={previousDiagnosisList}
                     prevDiagnosisLoading={prevDiagnosisLoading}
                   />
                 </div>
                 <div className="btn-procedure-group">
                   <Button
                     className="btn sm-btn procedure-save-btn procedure-btn"
                     name="documentAndBill"
                     onClick={handleProcedureSaveOnly}
                     disabled={isLoading}
                   >
                     {labels.get('buttons.save')}
                   </Button>
                   <Button
                     className="btn sm-btn procedure-btn"
                     name="Cancel"
                     onClick={handleDeleteProcedure}
                   >
                     {labels.get('buttons.cancel')}
                   </Button>
                   <div className="mr-top-8">
                     <AddSynonym
                       labels={labels}
                       buttonsText="addSynonym"
                       titleOfModal="addSynonym"
                       className="procedure-btn"
                       selectedCode={
                      procedureHeading.trim().length
                        ? {
                          ...questionnaireData,
                          code: questionnaireData?.cptCode,
                          id: questionnaireData?.cptCodeId,
                          description: questionnaireData?.cptDescription,
                        }
                        : {}
                    }
                       formTitleText=" Please enter an alternate description for the selected CPT code.
          Your suggestion will be sent to admin for approval."
                     />
                   </div>
                   <div>
                     <Button
                       className="btn sm-btn procedure-save-btn procedure-btn"
                       name="saveAsProcedureSet"
                       onClick={toggleProcedureSetModal}
                     >
                       Save As Procedure Set
                     </Button>
                   </div>
                 </div>
               </>
               )}
             </div>
           </Form.Section>
        )}
        {isModalVisible && (
          <SetModal
            isModalVisible={isModalVisible}
            toggleModal={toggleProcedureSetModal}
            form={modalInstanceForm}
            labelList={labelList}
            setLabelsList={setLabelsList}
            source="ProcedureSet"
            encounterId={encounterId}
            providerId={providerId}
            clonedNotes={clonedNotes}
            defaultCheckedProcedure={defaultCheckedProcedure}
            setDefaultCheckedDiagnosis={setDefaultCheckedProcedure}
          />
        )}
      </div>
    </div>
  );
};

export default connect((state) => ({
  enumOptions: selectors.getEnumOptions(state),
  enumMaster: selectors.getEnumMaster(state),
}))(WithLabel(ProcedureForm, labelPaths.ADD_PROCEDURE));
