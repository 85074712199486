import React from 'react';

import TimeCell from '../../../../../../../components/Cells/TimeCell';
import { schedulerDataDateFormat } from '../../../../../../../lib/constants';

const columns = (labels) => [
  {
    Header: labels.get('headers.dateTime'),
    accessor: 'createdOn',
    Cell: ({ row: { original } }) => (
      <TimeCell
        value={original?.createdOn}
        format={schedulerDataDateFormat}
      />
    ),
    fixWidth: 180,
  },
  {
    Header: labels.get('headers.method'),
    accessor: 'method',
  },
  {
    Header: labels.get('headers.outcome'),
    accessor: 'outcome',
  },
  {
    Header: labels.get('headers.by'),
    accessor: 'createdByName',
  },
];

export default columns;
