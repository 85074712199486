import { Map, fromJS } from 'immutable';

import {
  SET_SELECTED_PROVIDER,
  CLEAR_SELECTED_PROVIDER,
  CLEAR_OTHER_PROPS,
  GET_PROVIDERS,
  SET_ERROR,
  SET_LOADING,
  SET_UPDATE,
  SET_REDIRECT_KEY,
  SET_UPDATED_PROVIDER_IN_STORE,
} from '../actions/providers';

const initialState = Map({
  providers: [],
});

const actionsMap = {
  [GET_PROVIDERS]: (state) => Map({
    ...state,
    providers: [],
  }),
  [SET_SELECTED_PROVIDER]: (state, action) => {
    const clonedState = fromJS(state);
    const { provider } = action;
    return clonedState.set('selectedProvider', provider);
  },
  [SET_REDIRECT_KEY]: (state, action) => {
    const clonedState = fromJS(state);
    const { providerId } = action;
    return clonedState.set('redirectToEditKey', providerId);
  },
  [SET_UPDATED_PROVIDER_IN_STORE]: (state, action) => {
    const clonedState = fromJS(state);
    const { payload } = action;
    return clonedState.set('selectedProvider', payload);
  },
  [CLEAR_SELECTED_PROVIDER]: (state) => state.set('selectedProvider', {})
    .set('redirectToEditKey', null)
    .set('error', null)
    .set('loading', false)
    .set('update', false),
  [CLEAR_OTHER_PROPS]: (state) => state.set('error', null)
    .set('loading', false)
    .set('update', false),
  [SET_LOADING]: (state, action) => {
    const clonedState = fromJS(state);
    const { flag } = action;
    return clonedState.set('loading', flag);
  },
  [SET_ERROR]: (state, action) => {
    const clonedState = fromJS(state);
    const { error } = action;
    return clonedState.set('error', error);
  },
  [SET_UPDATE]: (state, action) => {
    const clonedState = fromJS(state);
    const { flag } = action;
    return clonedState.set('update', flag);
  },
};

export default function providers(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
