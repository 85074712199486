import React, { useEffect } from 'react';

import useCRUD from '../../../../../hooks/useCRUD';

import Form from '../../../../../components/Form';
import Input from '../../../../../components/Form/Input';
import PhoneNumberInput from '../../../../../components/Form/PhoneNumberInput';
import { apiUrls } from '../../../../../api/constants';
import dateFormatter from '../../../../../lib/dateFormatter';
import WidgetLoader from '../../../../../components/WidgetLoader';

const PersonDetailForm = ({
  labels, formFieldPrefix, personId, personType, form,
}) => {
  const crudReadId = `${formFieldPrefix}person-lookup`;
  const [data, , loading, getPersonData] = useCRUD({
    id: crudReadId,
    url: apiUrls.GET_RESPONSIBLE_PARTY_BY_ID,
    type: 'read',
  });

  useEffect(() => {
    if (!loading) {
      if (data && !Array.isArray(data)) {
        form.setFields([
          { name: `${formFieldPrefix}personId`, value: data.personId },
          { name: `${formFieldPrefix}type`, value: data.type },
          { name: `${formFieldPrefix}firstName`, value: data.firstName },
          { name: `${formFieldPrefix}lastName`, value: data.lastName },
          { name: `${formFieldPrefix}addressLine1`, value: data.addressLine1 },
          { name: `${formFieldPrefix}addressLine2`, value: data.addressLine2 },
          { name: `${formFieldPrefix}zip`, value: data.zip },
          { name: `${formFieldPrefix}city`, value: data.city },
          { name: `${formFieldPrefix}state`, value: data.stateName },
          { name: `${formFieldPrefix}country`, value: data.countryName },
          { name: `${formFieldPrefix}primaryPhone`, value: data.primaryPhone },
          { name: `${formFieldPrefix}primaryPhoneType`, value: data.primaryPhoneTypeName },
          { name: `${formFieldPrefix}secondaryPhone`, value: data.secondaryPhone },
          { name: `${formFieldPrefix}secondaryPhoneType`, value: data.secondaryPhoneTypeName },
          { name: `${formFieldPrefix}email`, value: data.emailAddress },
          { name: `${formFieldPrefix}dateOfBirth`, value: dateFormatter(data.dateOfBirth) },
          { name: `${formFieldPrefix}gender`, value: data.genderName },
          { name: `${formFieldPrefix}personName`, value: `${data.firstName} ${data.lastName}` },
        ]);
      }
    }
  }, [loading, data]);

  useEffect(() => {
    if (personId && personType && !data) {
      getPersonData({ PersonId: personId, Type: personType });
    }
  }, [personId]);

  return (data && personId) ? (
    <>
      {loading && <WidgetLoader />}
      <Form.Column>
        <div className="group-shadow">
          <Input
            className="non-editable"
            label={labels.get('labels.firstName')}
            name={`${formFieldPrefix}firstName`}
            inputSpan="14"
            labelSpan="9"
            disabled
          />
          <Input
            className="non-editable"
            label={labels.get('labels.lastName')}
            name={`${formFieldPrefix}lastName`}
            inputSpan="14"
            labelSpan="9"
            disabled
          />
          <Input
            className="non-editable"
            label={labels.get('labels.addressLine1')}
            id="23"
            name={`${formFieldPrefix}addressLine1`}
            inputSpan="14"
            labelSpan="9"
            disabled
          />
          <Input
            className="non-editable"
            label={labels.get('labels.addressLine2')}
            id="24"
            name={`${formFieldPrefix}addressLine2`}
            inputSpan="14"
            labelSpan="9"
            disabled
          />
          <Input
            className="non-editable"
            label={labels.get('labels.zip')}
            name={`${formFieldPrefix}zip`}
            inputSpan="14"
            labelSpan="9"
            disabled
          />
        </div>
      </Form.Column>
      <Form.Column>
        <div className="group-shadow">
          <Input
            className="non-editable"
            label={labels.get('labels.city')}
            name={`${formFieldPrefix}city`}
            inputSpan="14"
            labelSpan="9"
            disabled
          />
          <Input
            className="non-editable"
            label={labels.get('labels.state')}
            name={`${formFieldPrefix}state`}
            inputSpan="14"
            labelSpan="9"
            disabled
          />
          <Input
            className="non-editable"
            label={labels.get('labels.country')}
            id="22"
            name={`${formFieldPrefix}country`}
            inputSpan="14"
            labelSpan="9"
            disabled
          />
          <PhoneNumberInput
            className="non-editable"
            label={labels.get('labels.primaryPhone')}
            id="27"
            name={`${formFieldPrefix}primaryPhone`}
            inputSpan="14"
            labelSpan="9"
            disabled
          />
          <Input
            className="non-editable"
            label={labels.get('labels.primaryPhoneType')}
            name={`${formFieldPrefix}primaryPhoneType`}
            id="9"
            inputSpan="14"
            labelSpan="9"
            disabled
          />
        </div>
      </Form.Column>
      <Form.Column>
        <div className="group-shadow">
          <PhoneNumberInput
            className="non-editable"
            label={labels.get('labels.secondaryPhone')}
            id="28"
            name={`${formFieldPrefix}secondaryPhone`}
            inputSpan="14"
            labelSpan="9"
            disabled
          />
          <Input
            className="non-editable"
            label={labels.get('labels.secondaryPhoneType')}
            name={`${formFieldPrefix}secondaryPhoneType`}
            id="12"
            inputSpan="14"
            labelSpan="9"
            disabled
          />
          <Input
            className="non-editable"
            label={labels.get('labels.email')}
            id="29"
            name={`${formFieldPrefix}email`}
            inputSpan="14"
            labelSpan="9"
            disabled
          />
          <Input
            className="non-editable"
            label={labels.get('labels.dateOfBirth')}
            name={`${formFieldPrefix}dateOfBirth`}
            id="31"
            inputSpan="14"
            labelSpan="9"
            disabled
          />
          <Input
            className="non-editable"
            label={labels.get('labels.gender')}
            id="32"
            name={`${formFieldPrefix}gender`}
            inputSpan="14"
            labelSpan="9"
            disabled
          />
        </div>
      </Form.Column>
    </>
  ) : null;
};

export default PersonDetailForm;
