import React, { useEffect } from 'react';

import { apiUrls } from '../../../../../api/constants';
import { listIds } from '../../../../../lib/constants';
import Events from '../../../../../lib/events';

import withQuery from '../../../../../hoc/withQuery/withQuery';

import columns from './columns';

const CustomNotesTable = withQuery({
  url: apiUrls.GET_PATIENT_NOTES,
  listId: listIds.GET_PATIENT_NOTES,
})();

const initialSort = [{ id: 'createdOn', desc: true }];

const TableWrapper = ({ reFetch, Component }) => {
  useEffect(() => {
    Events.on('refetchNotesTable', 'refetchNotesTable', reFetch);
    return () => Events.remove('refetchNotesTable');
  }, []);
  return Component;
};

const NotesTable = ({
  labels, onRowClick, filters, scrollId,
}) => (
  <CustomNotesTable filters={filters} onRowClick={onRowClick} columns={columns(labels)} initialSort={initialSort} noDataText="Notes Data not found" scrollId={scrollId}>
    {({
      Component, reFetch,
    }) => (
      <TableWrapper
        Component={Component}
        reFetch={reFetch}
      />
    )}
  </CustomNotesTable>
);

export default NotesTable;
