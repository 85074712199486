import React, { useState, useCallback, useEffect } from 'react';
import { Form as AntdForm } from 'antd';

import Notification from '../../../../../components/Notification';
import Loader from '../../../../../components/Loader';

import { apiUrls } from '../../../../../api/constants';
import { listId, listIds } from '../../../../../lib/constants';
import SuccessMessages from '../../../../../lib/successMessages';
import formFieldValuesParser from '../../../../../lib/formFieldValuesParser';
import Events from '../../../../../lib/events';

import useCRUD from '../../../../../hooks/useCRUD';
import useRedirect from '../../../../../hooks/useRedirect';

import UploadFilePreview from '../../../../../wiredComponents/UploadFilePreview';

import UploadFileForm from '../components/UploadFileForm';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import Icon from '../../../../../components/Icon';
import Panel from '../../../../../components/Panel';
import Button from '../../../../../components/Button';

const { REACT_APP_FAKE } = process.env;

const DocumentsTable = ({ closeTab, isUpdateDocumentAuthenticated }) => {
  const {
    params: {
      documentId,
      id,
      tabId,
    },
  } = useRedirect();
  const [form] = AntdForm.useForm();

  const [uploadModalVisible, setUploadModalVisibility] = useState(false);
  const [document, setDocument] = useState(null);

  const [
    deleteDocumentResponse,,
    deleteLoading,
    deleteDocument,
    clearDeleteResponse,
  ] = useCRUD({
    id: listIds.DELETE_PATIENT_DOCUMENT,
    url: apiUrls.DELETE_PATIENT_DOCUMENT,
    type: 'create',
  });

  const [addToFaxQueueResponse, , , addToFax, clearFaxResponse] = useCRUD({
    id: listId.ADD_FAX_QUEUE_SHOPPING_CART,
    url: apiUrls.ADD_FAX_QUEUE_SHOPPING_CART,
    type: 'create',
  });

  const [remakePdfResponse, , remakePDFLoading, getRemakePdf, clearRemakePdfResponse] = useCRUD({
    id: apiUrls.REMAKE_SIGNED_PDF,
    url: `${apiUrls.REMAKE_SIGNED_PDF}`,
    type: 'update',
  });

  useEffect(() => {
    Events.trigger('get-patient-fax-document-count');
  }, []);

  useEffect(() => {
    if (remakePdfResponse) {
      Notification({ message: SuccessMessages.REFETCH_SIGNED_PDF_SUCCESSFULLY, success: true });
      Events.trigger('refetch-updated-patient-document-item');
    }
  }, [clearRemakePdfResponse, remakePdfResponse]);

  useEffect(() => {
    if (deleteDocumentResponse && deleteDocumentResponse.message) {
      clearDeleteResponse();
      closeTab(tabId);
      Notification({ message: SuccessMessages.DELETE_PATIENT_DOCUMENT_SUCCESSFUL, success: true });
    }
  }, [deleteDocumentResponse, clearDeleteResponse, closeTab, tabId]);

  const onFileDelete = useCallback(() => {
    deleteDocument({ data: { PatientId: id, CategoryId: document.categoryId } }, `/${documentId}`);
  }, [deleteDocument, id, document, documentId]);

  const toggleUploadModal = useCallback(() => {
    setUploadModalVisibility(!uploadModalVisible);
  }, [setUploadModalVisibility, uploadModalVisible]);

  const parser = useCallback((values) => formFieldValuesParser(values, {
    date: ['effectiveDate'],
  }), []);

  const initialDataParser = useCallback((values) => {
    const parsedValue = formFieldValuesParser(values, {
      date: ['effectiveDate'],
    });
    return parsedValue;
  }, []);

  useEffect(() => {
    if (addToFaxQueueResponse?.isDuplicateExists) {
      ConfirmDialog({
        onOk: (close) => {
          addToFax({
            data: {
              FaxDocumentIds: [document?.documentId],
              faxSource: 'patientDocument',
              patientId: id,
              includeDuplicate: true,
            },
          });
          close();
        },
        onCancel: (close) => {
          addToFax({
            data: {
              FaxDocumentIds: [document?.documentId],
              faxSource: 'patientDocument',
              patientId: id,
              includeDuplicate: false,
            },
          });
          close();
        },
        okText: 'Ok',
        title: 'Duplicate Documents',
        content: 'Document (s) already added to Fax queue, do you want to add the document again?',
        icon: <Icon name="ExclamationCircleOutlined" />,
        cancelButtonProps: { className: 'ant-modal-continue-green-btn' },
        okButtonProps: { className: 'ant-modal-ok-white-btn' },
      })();
    } else if (addToFaxQueueResponse?.recordInserted > 0) {
      Notification({ message: SuccessMessages.DOCUMENT_ADDED_TO_FAX_SUCCESSFULLY, success: true });
      Events.trigger('get-patient-fax-document-count');
      clearFaxResponse(true);
    }
  }, [addToFaxQueueResponse]);

  const handleAddToFaxQueue = useCallback(() => {
    addToFax({
      data: {
        FaxDocumentIds: [document?.documentId],
        faxSource: 'patientDocument',
        patientId: id,
      },
    });
  }, [addToFax, document, id]);

  const handleRemakePdf = useCallback(() => {
    getRemakePdf({ encounterId: [parseInt(document?.encounterId, 10)] });
  }, [getRemakePdf, document]);

  const initialData = REACT_APP_FAKE ? {
    categoryId: 313,
    documentDisplayName: 'REFRACTION',
  } : undefined;

  if (deleteLoading || remakePDFLoading) {
    return <Loader />;
  }
  return (
    <Panel
      className="signed-encounter-container posi-relative"
      showCloseIcon={false}
      buttons={(
        document?.encounterId && <Button onClick={handleRemakePdf} className="sm-btn btn-success">Remake Pdf</Button>
)}
    >
      <UploadFilePreview
        visible={uploadModalVisible}
        setVisibility={toggleUploadModal}
        fileUrl={document?.documentURL}
        type={document?.fileExt}
        onDelete={onFileDelete}
        isFax
        handleAddToFaxQueue={handleAddToFaxQueue}
      >
        <UploadFileForm
          documentId={documentId}
          parser={parser}
          initialDataParser={initialDataParser}
          form={form}
          document={document}
          setDocument={setDocument}
          patientId={id}
          tabId={tabId}
          initialData={initialData}
          isUpdateDocumentAuthenticated={isUpdateDocumentAuthenticated}
        />
      </UploadFilePreview>
    </Panel>
  );
};

export default DocumentsTable;
