import React, {
  useCallback, useState, useEffect, useMemo,
} from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import useCRUD from '../../../../../../../../hooks/useCRUD';

import { apiUrls } from '../../../../../../../../api/constants';
import ErrorMessages from '../../../../../../../../lib/errorMessages';
import SuccessMessages from '../../../../../../../../lib/successMessages';
import WarningMessages from '../../../../../../../../lib/warningMessages';

import Icon from '../../../../../../../../components/Icon';
import Button from '../../../../../../../../components/Button';
import Notification from '../../../../../../../../components/Notification';
import Loader from '../../../../../../../../components/Loader';
import ConfirmDialog from '../../../../../../../../components/ConfirmDialog';

import ExceptionComponent from './ExceptionComponent';
import ExceptionLabel from './ExceptionLabel';

import { validator, onChangeData, setFocusToFields } from './util';

const EditExceptionRow = ({
  rowData,
  key,
  labels,
  setEditRow,
  editRow,
  setPreviousRow,
  previousRow,
  onUpdateRecord,
  rowIndex,
  form,
  onDeleteRecord,
  contractId,
  contractTermId,
  parentFocusId,
}) => {
  const [exception, setExceptionData] = useState(rowData);
  const exceptionId = useMemo(() => rowData.contractTermExceptionId, [rowData]);

  const [isEdit, setEdit] = useState(false);
  const [dataError, setError] = useState({});
  const [data, setData] = useState({
    CPTCode: exception?.procedureFromCode,
    RateTypeId: exception?.feeScheduleTypeId,
    Modifier: exception?.modifier,
  });

  const [response, , loading, updateException, clearUpdate] = useCRUD({ id: `exception-update-${exceptionId}-${rowIndex}`, url: apiUrls.UPDATE_CONTRACT_TERMS_EXCEPTION, type: 'update' });
  const [deleteResponse, , deleteLoading, deleteException, clearDelete] = useCRUD({ id: `exception-delete-${exceptionId}-${rowIndex}`, url: apiUrls.DELETE_CONTRACT_TERMS_EXCEPTION, type: 'delete' });
  const [billingRates, , , getBillingRates, clearBillingRate] = useCRUD({ id: 'edit-get-billing-rate', url: apiUrls.GET_BILLING_RATES, type: 'read' });

  useEffect(() => {
    if (billingRates && !Array.isArray(billingRates)) {
      if (billingRates?.cptCode === exception?.procedureFromCode) {
        setExceptionData({
          ...exception,
          typeValue: billingRates?.rateValue,
        });
      }
      clearBillingRate(true);
    }
  }, [billingRates]);

  const onUpdateException = useCallback(() => {
    const isError = validator(exception);
    if (isEmpty(isError)) {
      updateException({
        ...exception,
        contractId,
        contractTermId,
      }, `/${exception.contractTermExceptionId}`);
    } else {
      setError(isError);
      setFocusToFields(isError, parentFocusId);
      if (isError?.multiplier) {
        Notification({
          message: ErrorMessages.SELECTED_PROCEDURE_DOES_NOT_EXIST_IN_FEE_SCHEDULE,
          success: false,
        });
      } else {
        Notification({ message: ErrorMessages.PLEASE_FILL_ALL_REQUIRED_FIELDS, success: false });
      }
    }
  }, [exception, updateException, parentFocusId]);

  useEffect(() => {
    if (response && !isArray(response)) {
      Notification({ message: SuccessMessages.EXCEPTION_UPDATED_SUCCESSFULLY, success: true });
      onUpdateRecord({ ...exception }, rowIndex);
      clearUpdate();
    }
  }, [response]);

  const onDeleteException = useCallback(() => {
    deleteException({}, `/${exception.contractTermExceptionId}`);
  }, [exception, deleteException]);

  useEffect(() => {
    if (deleteResponse && !isArray(deleteResponse)) {
      Notification({ message: SuccessMessages.EXCEPTION_DELETED_SUCCESSFULLY, success: true });
      onDeleteRecord(rowIndex);
      clearDelete();
    }
  }, [deleteResponse, clearDelete, onDeleteRecord, rowIndex]);

  const onChange = useCallback((label) => (event, item = {}) => {
    const clonedData = data;
    if (label === 'procedureFrom') {
      clonedData.CPTCode = event?.cptCode;
    }
    if (label === 'modifier') {
      clonedData.Modifier = event?.modifierCode;
    }
    if (label === 'feeSchedule') {
      clonedData.RateTypeId = event;
    }
    setData(clonedData);

    if (label === 'procedureFrom'
    || label === 'modifier'
     || label === 'feeSchedule') {
      getBillingRates(clonedData);
    }

    const { exceptionData, resetError } = onChangeData({
      label, event, item, exception, dataError,
    });
    setExceptionData(exceptionData);
    if (Object.keys(resetError).length) setError(resetError);
  }, [data, getBillingRates, exception, dataError]);

  const onEdit = useCallback(() => {
    if (editRow !== null) {
      ConfirmDialog({
        onOk: (close) => {
          setEditRow(rowData.contractTermExceptionId);
          setPreviousRow(editRow);
          close();
        },
        title: 'Warning',
        content: WarningMessages.LOST_CHANGES,
        icon: <Icon name="ExclamationCircleOutlined" />,
      })();
    } else {
      setEditRow(rowData.contractTermExceptionId);
    }
  }, [editRow, rowData.contractTermExceptionId, setEditRow, setPreviousRow]);

  const onClear = useCallback(() => {
    ConfirmDialog({
      onOk: (close) => {
        setExceptionData({ ...rowData });
        setEditRow(null);
        setPreviousRow(null);
        setError({});
        close();
      },
      title: 'Warning',
      content: WarningMessages.LOST_CHANGES,
      icon: <Icon name="ExclamationCircleOutlined" />,
    })();
  }, [rowData, setExceptionData, setEditRow]);

  useEffect(() => {
    const isRowEdit = editRow === rowData.contractTermExceptionId;
    if ((isRowEdit && !isEdit) || (!isRowEdit && isEdit)) setEdit(isRowEdit);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editRow]);

  useEffect(() => {
    if (rowData.contractTermExceptionId === previousRow) {
      setExceptionData({ ...rowData });
      setError({});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousRow]);

  useEffect(() => {
    setExceptionData({ ...rowData });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData]);
  return (
    <tr key={key}>
      {(loading || deleteLoading) && <Loader />}
      {isEdit && (
      <ExceptionComponent
        onChange={onChange}
        exception={exception}
        form={form}
        labels={labels}
        isEdit={isEdit}
        dataError={dataError}
        onDeleteException={onDeleteException}
        contractId={contractId}
        contractTermId={contractTermId}
        parentFocusId={parentFocusId}
        exceptionId={exceptionId}
      />
      )}
      {!isEdit && (
        <ExceptionLabel
          onChange={onChange}
          exception={exception}
          onDeleteException={onDeleteException}
        />
      )}
      <td>
        <div className="exception-action">
          {!isEdit && <Button className="btn btn-success sm-btn" onClick={onEdit} data-testid="exception-edit-btn">{labels.get('buttons.edit')}</Button>}
          {isEdit && get(exception, 'contractTermExceptionId') && (
            <>
              <Button className="btn btn-success sm-btn" onClick={onUpdateException}>{labels.get('buttons.update')}</Button>
              <Button className="mr-lt-5 sm-btn" onClick={onClear}>{labels.get('buttons.clear')}</Button>
            </>
          )}
        </div>
      </td>
    </tr>
  );
};

export default EditExceptionRow;
