import React, { useCallback, useEffect } from 'react';

import useCRUD from '../../../../../../../hooks/useCRUD';
import { listIds } from '../../../../../../../lib/constants';
import { apiUrls } from '../../../../../../../api/constants';
import useRedirect from '../../../../../../../hooks/useRedirect';
import successMessages from '../../../../../../../lib/successMessages';

import Loader from '../../../../../../../components/Loader';
import Button from '../../../../../../../components/Button';
import Notification from '../../../../../../../components/Notification';

const CheckEligibility = () => {
  const { params: { tabId } } = useRedirect();
  const [claimDetails] = useCRUD({ id: `${listIds.CLAIM_DETAILS}-${tabId}`, url: apiUrls.GET_CLAIM_DETAILS, type: 'read' });
  const { billingEncounterData } = claimDetails || {};

  const [
    checkEligibilityResponse,,
    checkEligibilityLoading,
    checkEligibility,
    clearCheckEligibility,
  ] = useCRUD({
    id: listIds.CHECK_ELIGIBILITY,
    url: apiUrls.MANUAL_P_VERIFY_VERIFICATION,
    type: 'create',
  });

  useEffect(() => {
    if (checkEligibilityResponse && !Array.isArray(checkEligibilityResponse)) {
      Notification({
        message: successMessages.CHECK_ELIGIBILITY_SUCCESSFULLY,
        success: true,
      });
      clearCheckEligibility(true);
    }
  }, [clearCheckEligibility, checkEligibilityResponse]);

  const handleClick = useCallback(() => {
    checkEligibility({
      data: {
        patientId: billingEncounterData?.patient?.patientId,
        insuranceProfileDetailId: billingEncounterData?.insurance?.insuranceProfileDetailId,
      },
    });
  }, [billingEncounterData, checkEligibility]);

  return (
    <>
      {checkEligibilityLoading && <Loader />}
      <Button onClick={handleClick}>Check Eligibility</Button>
    </>
  );
};

export default CheckEligibility;
