import React, { useCallback } from 'react';
import { Row, Col } from 'antd';

import { UiRoutes, labelPaths } from '../../../../../../lib/constants';
import Modal from '../../../../../../components/Modal';
import Select from '../../../../../../components/Form/SelectBox';
import DatePicker from '../../../../../../components/Form/DatePicker';
import Input from '../../../../../../components/Form/Input';
import useRedirect from '../../../../../../hooks/useRedirect';
import WithLabel from '../../../../../../hoc/withLabel';

const PaymentPaymentPopUp = ({ labels }) => {
  const { push, generatePath, params } = useRedirect();

  const toggleModal = useCallback(() => {
    push(generatePath(UiRoutes.claimsCollections, { ...params }));
  }, [generatePath, params, push]);

  return (
    <Modal
      visible
      toggleModal={toggleModal}
      title={labels.get('titles.patientPayment')}
    >
      <Row gutter={10}>
        <Col span={12}>
          <DatePicker
            label={labels.get('labels.effectiveDate')}
          />
        </Col>
        <Col span={12}>
          <Select
            label={labels.get('labels.provider')}
          />
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={12}>
          <Select
            label={labels.get('labels.transactionType')}
          />
        </Col>
        <Col span={12}>
          <Select
            label={labels.get('labels.location')}
          />
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={12}>
          <Input
            label={labels.get('labels.amount')}
          />
        </Col>
        <Col span={12}>
          <Input.TextArea
            label={labels.get('labels.comment')}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default WithLabel(PaymentPaymentPopUp, labelPaths.PATIENT_PAYMENT_POPUP);
