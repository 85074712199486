import React, {
  useState, useMemo, useCallback, useRef,
} from 'react';
import { Form as AntdForm } from 'antd';

import Form from '../../../../../../components/Form';
import Modals from '../../../../../../components/Modal';
import Button from '../../../../../../components/Button';
import Input from '../../../../../../components/Form/Input';
import DatePicker from '../../../../../../components/Form/DatePicker';
import WithLabel from '../../../../../../hoc/withLabel';
import { labelPaths } from '../../../../../../lib/constants';

import InsuranceRefundTable from './InsuranceRefundTable';
import ProviderGridAutoComplete from '../../../../../../wiredComponents/ProviderGridAutoComplete';
import LocationGridAutoComplete from '../../../../../../wiredComponents/LocationGridAutoComplete';
import PayerGridAutoComplete from '../../../../../../wiredComponents/PayerGridAutoComplete';
import { apiUrls } from '../../../../../../api/constants';
import SuccessMessages from '../../../../../../lib/successMessages';
import useRedirect from '../../../../../../hooks/useRedirect';
import Notification from '../../../../../../components/Notification';
import Events from '../../../../../../lib/events';
import { getDateWithZeroTimeFormat } from '../../../../../../lib/util';

const parser = (formValues) => ({
  ...formValues,
  effectiveDate: getDateWithZeroTimeFormat(formValues.effectiveDate),
  checkDate: getDateWithZeroTimeFormat(formValues.checkDate),
});

function InsuranceRefund({
  visible, toggleModal, labels, claimId,
}) {
  const [form] = AntdForm.useForm();
  const ref = useRef();
  const viewPanel = useRef(null);
  const { params: { id, financialId } } = useRedirect();
  const [lineItemData, setLineItemData] = useState([]);

  const lineItems = useMemo(() => lineItemData?.map((item) => ({
    financeId: item?.financeId,
    refund: item?.refund,
    paid: item?.paid,
  })), [lineItemData]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({
        message: SuccessMessages.INSURANCE_REFUND_ADDED_SUCCESSFULLY,
        success: true,
      });
      toggleModal(false);
      if (financialId) Events.trigger(`reFetchFinancialDetail-${financialId}`);
    }
  }, [financialId, toggleModal]);

  const handleSubmit = useCallback(() => {
    if (lineItems?.length) {
      if (ref.current.checkIfRefundGreaterThanPaid()) {
        Notification({ message: 'Refund amount cannot be more than paid amount' });
        return;
      }
      const totalApplied = lineItems?.reduce((sum, {
        refund =
        0,
      }) => sum + parseFloat(refund), 0) || 0;
      const amount = parseFloat(form.getFieldValue('amount') || 0);
      if (totalApplied > amount) {
        Notification({ message: 'Refund amount should not be less then total applied' });
      } else {
        form.submit();
      }
    } else {
      Notification({
        message: 'There must be at least one line item',
      });
    }
  }, [form, lineItems]);

  const getExtraData = useCallback(() => ({
    lineItems: lineItems.filter((lineItem) => lineItem.refund > 0),
    PaymentSource: 'OverTheCounter',
    PaymentMethod: 'Manual',
    patientId: id,
  }), [id, lineItems]);

  return (
    <>
      <div ref={viewPanel} className="display-none" />
      <Modals
        visible={!!visible}
        toggleModal={toggleModal}
        width="1000px"
        footer={[
          <div className="group-btns" key="footer">
            <Button
              id="patients_financial_insuranceRefund_cancel"
              className="btn min-wt-86"
              onClick={() => toggleModal(false)}
            >
              {labels.get('buttons.cancel')}
            </Button>
            <Button
              id="patients_financial_insuranceRefund_save"
              className="btn btn-success min-wt-86 inline"
              onClick={handleSubmit}
              data-testid="save-btn"
            >
              {labels.get('buttons.save')}
            </Button>
          </div>,
        ]}
        destroyOnClose
      >
        <Form
          section
          form={form}
          url={apiUrls.ADD_INSURANCE_REFUND}
          listId={apiUrls.ADD_INSURANCE_REFUND}
          formId={apiUrls.ADD_INSURANCE_REFUND}
          parser={parser}
          extraData={getExtraData}
          onRequestComplete={onRequestComplete}
        >
          <div className="addition-header">
            <div className="lookup sprite-img-before">
              <p>{labels.get('titles.insuranceRefund')}</p>
            </div>
          </div>
          <div className="modal-content-weapper shadow-wrap">
            <div className="modal-from-feilds">
              <Form.Section>
                <Form.Column noOfColumns="3">
                  <DatePicker
                    label={labels.get('labels.effectiveDate')}
                    name="effectiveDate"
                    required
                    id="19"
                    dataTestId="effectiveDate"
                  />
                  <Input
                    label={labels.get('labels.refundAmount')}
                    name="amount"
                    id="1"
                    required
                    numberWithDecimal
                    dataTestId="refundAmount"
                    placeholder="Enter Refund Amount"
                  />
                  <PayerGridAutoComplete
                    label={labels.get('labels.payer')}
                    id="19"
                    name="payerId"
                    labelSpan={10}
                    inputSpan={14}
                    dataTestId="payer"
                  />

                </Form.Column>
                <Form.Column noOfColumns="3">
                  <div>
                    <Input
                      label={labels.get('labels.batchIdentifier')}
                      name="batchIdentifier"
                      id="1"
                      maxValueLength={25}
                      dataTestId="batchIdentifier"
                      placeholder="Enter Batch Identifier"
                    />
                    <Input
                      label={labels.get('labels.checkAmount')}
                      name="checkAmount"
                      id="1"
                      required
                      numberWithDecimal
                      dataTestId="checkAmount"
                      placeholder="Enter Check Amount"
                    />
                    <DatePicker
                      label={labels.get('labels.checkDate')}
                      name="checkDate"
                      required
                      id="19"
                      dataTestId="checkDate"
                    />
                  </div>
                </Form.Column>
                <Form.Column noOfColumns="3">
                  <div>
                    <Input
                      label={labels.get('labels.checkNumber')}
                      name="checkNumber"
                      required
                      id="1"
                      maxValueLength={25}
                      dataTestId="checkNumber"
                      placeholder="Enter Check Number"
                    />
                    <ProviderGridAutoComplete
                      label={labels.get('labels.provider')}
                      name="providerId"
                      id="9"
                      labelSpan={10}
                      inputSpan={14}
                      dataTestId="provider"
                    />
                    <LocationGridAutoComplete
                      label={labels.get('labels.location')}
                      name="locationId"
                      id="9"
                      labelSpan={10}
                      inputSpan={14}
                      dataTestId="location"
                    />
                  </div>
                </Form.Column>
              </Form.Section>
            </div>
            <div className="modal-content-area">
              <InsuranceRefundTable
                labels={labels}
                lineItemData={lineItemData}
                setLineItemData={setLineItemData}
                ref={ref}
                claimId={claimId}
              />
            </div>
          </div>
        </Form>
      </Modals>
    </>
  );
}

export default React.memo(WithLabel(InsuranceRefund, labelPaths.FINANCIAL_POPUP_INSURANCE_REFUND));
