import React from 'react';

import TabsComponent from '../../../../../components/Tabs';
import General from './General';
import Billing from './Billing';
import FeePreferences from './FeePreferences';
import DirectAddress from './DirectAddress';

import { labelPaths } from '../../../../../lib/constants';
import WithLabel from '../../../../../hoc/withLabel';

const tabsList = [
  {
    id: 'general',
    component: General,
    className: 'dashboard-icon sprite-img-2-before',
  },
  {
    id: 'billing',
    component: Billing,
    className: 'insurance-icon sprite-img-before',
  },
  {
    id: 'feePreferences',
    component: FeePreferences,
    className: 'encounters-icon sprite-img-before',
  },
  {
    id: 'directAddress',
    component: DirectAddress,
    className: 'encounters-icon sprite-img-before',
  },
];

const Tabs = (props) => {
  const {
    selectedTabData = {}, dataHandler, updateHandler, labels, isProviderViewOnly,
  } = props;
  const clonedTabs = [];
  tabsList.forEach((tab) => {
    clonedTabs.push({ ...tab, name: labels.get(`tabs.${tab.id}`) });
  });
  return (
    <TabsComponent
      tabsList={clonedTabs}
      className="main-tab-wrap"
      selectedTabData={selectedTabData}
      dataHandler={dataHandler}
      updateHandler={updateHandler}
      id="providers"
      isProviderViewOnly={isProviderViewOnly}
    />
  );
};

export default WithLabel(Tabs, labelPaths.EDIT_PROVIDER);
