import React, { useCallback } from 'react';
import AutoComplete from '../../components/Form/GridAutoComplete';
import { apiUrls } from '../../api/constants';
import { isInputExistInMap } from '../../lib/util';
import { modifierDefaultProps, modifierDefaultPropsTypes } from '../ModifierCptSearch/commonModifierPropTypes';

const columns = [
  {
    Header: 'Code',
    accessor: 'modifierCode',
    flex: 1,
    maxWidth: 150,
  },
  {
    Header: 'Description',
    accessor: 'description',
    flex: 3,
    maxWidth: 250,
  },
];

const ModifierAutoComplete = ({
  disabled,
  required,
  initialValue,
  labelSpan,
  inputSpan,
  onSelect,
  styleWidth,
  name,
  className,
  dropdownMatchSelectWidth,
  selectProps,
  params,
  minCharLength,
  ...props
}) => {
  const modifierOptionParser = useCallback((option) => ({
    ...option,
    name: option.modifierCode,
    value: option.modifierId,
  }), []);

  return (
    <div className="filter-modifier-autocomplete">
      <AutoComplete
        name={name}
        fetchInitial
        url={apiUrls.GET_MODIFIERS}
        optionParser={modifierOptionParser}
        optionMaster="SearchText"
        labelSpan={labelSpan}
        inputSpan={inputSpan}
        disabled={disabled}
        required={required}
        columns={columns}
        style={styleWidth ? { width: '100%' } : {}}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        className={className}
        notFoundContent="Modifier is not present is the system."
        showArrow
        labelInValue
        selectProps={{
          ...selectProps,
          optionLabelProp: 'name',
          allowClear: true,
          onSearch: () => { /* This is intentional */ },
          dropdownMatchSelectWidth: 300,
          filterOption: (input, option) => isInputExistInMap(input, ['name', 'modifierCode', 'description'], option?.item),
        }}
        params={params}
        onSelect={onSelect}
        minCharLength={minCharLength}
        {...props}
      />
    </div>
  );
};

ModifierAutoComplete.defaultProps = {
  ...modifierDefaultProps,
};

ModifierAutoComplete.propTypes = {
  ...modifierDefaultPropsTypes,
};

export default ModifierAutoComplete;
