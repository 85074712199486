import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import useCRUD from '../../../../../hooks/useCRUD';
import { apiUrls } from '../../../../../api/constants';
import useRedirect from '../../../../../hooks/useRedirect';
import { UiRoutes, listIds } from '../../../../../lib/constants';

import WidgetLoader from '../../../../../components/WidgetLoader';

import Details from './details';
import ViewTable from './viewTable';

const ViewEncounter = ({ labels }) => {
  const { push, generatePath, params } = useRedirect();
  const [encounterData, setEncounterData] = useState({});

  const [
    encounterDetails,,
    encounterDetailsLoading,
    getEncounterDetails,
  ] = useCRUD({
    id: listIds.ENCOUNTER_DETAIL_BY_ID,
    url: apiUrls.GET_ENCOUNTER_LIST,
    type: 'read',
  });

  const tableData = useMemo(() => encounterData?.serviceLineItems?.filter(
      (item) => (!item?.deleted && item?.billingEncounterServiceLineId > 0)), [encounterData]);

  useEffect(() => {
    getEncounterDetails({}, `/${params?.encounterId}`);
  }, []);

  useEffect(() => {
    if (encounterDetails) {
      setEncounterData({
        ...encounterDetails?.encounterDetails,
        ...encounterDetails?.attendingProvider,
        ...encounterDetails?.authorization,
        ...encounterDetails?.insurance,
        ...encounterDetails?.insurancePayer,
        ...encounterDetails?.location,
        ...encounterDetails?.patient,
        ...encounterDetails?.primaryInsured,
        ...encounterDetails?.provider,
        ...encounterDetails?.referringPhysicianNPI,
        ...encounterDetails,
      });
    }
  }, [encounterDetails]);

  const navigateToEncounter = useCallback(() => {
    push(generatePath(UiRoutes.editPatientWithTabId, { ...params, tab: 'encounters' }));
  }, [push, generatePath, params]);

  return (
    <div className="pannel back-pannel mr-top-20">
      <div className="pannel-heading">
        <p role="presentation" id="patients_lists_authorization_goBack" className="back-arrow" onClick={navigateToEncounter}>{labels.get('buttons.goBack')}</p>
      </div>
      <div className="pannel-body">
        {encounterDetailsLoading ? <WidgetLoader /> : (
          <>
            <Details data={encounterData || {}} />
            <ViewTable data={tableData || []} />
          </>
        )}
      </div>
    </div>
  );
};
export default ViewEncounter;
