/* eslint-disable max-len */
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

import moment from 'moment';
import isEqual from 'lodash/isEqual';
import findLast from 'lodash/findLast';
import classNames from 'classnames';

import Modals from '../../../components/Modal';
import Radio from '../../../components/Radio';
import Input from '../../../components/Form/Input';
import Checkbox from '../../../components/Form/CheckBox';
import TimeCell from '../../../components/Cells/TimeCell';

import Icon from '../../../components/Icon';

import CPTAutocomplete from '../../CPTAutocomplete';
import CPTICDAutoComplete from '../../CPTICDAutoComplete';
import WiredModifier from '../../ModifierGroup';

import { getString } from '../../../lib/util';
import iconNames from '../../../lib/iconNames';

import Select from '../../Select/selectBoxV2';
import InsuranceDetailsSelect from '../../InsuranceDetailsSelect';
import { apiUrls } from '../../../api/constants';
import useRedirect from '../../../hooks/useRedirect';
import useCRUDWithoutTab from '../../../hooks/useCRUDWithoutTab';
import useCRUD from '../../../hooks/useCRUD';
import ErrorMessages from '../../../lib/errorMessages';
import NdcMapping from './ndcMapping';
import { RULES_ENGINE_HIGHLIGHT_KEYS, allowAmountValidationForManualEncounter, allowAmountValidationForMipsSources } from '../../../lib/constants';
import useReduxStoreWithId from '../../../hooks/useReduxStoreWithId';
import RcmWrapper from '../../../hoc/withRCM/RcmWrapper';
import { getRulesEngineOperandByKey } from './RulesEngine/RulesEngineEncounter';

const getModifiersCode = (modifiers) => (modifiers?.map(({ modifierId }) => modifierId).join(','));

const EditableCell = ({
  name, disabled, className, required, onChange, label, numberOnly, onBlur, rules, dataTestId,
}) => (
  <Input
    id="23"
    name={name}
    className={className}
    disabled={disabled}
    labelSpan="0"
    inputSpan="24"
    required={required}
    onChange={onChange}
    label={label}
    numberOnly={numberOnly}
    onBlur={onBlur}
    rules={rules}
    dataTestId={dataTestId}
  />
);

const EditableCheckBoxCell = ({
  name, disabled, onChange,
}) => (
  <Checkbox
    labelSpan="0"
    inputSpan="5"
    name={name}
    id="23"
    onChange={onChange}
    disabled={disabled}
    valuePropName="checked"
  />
);

const labelRender = (name, date, toDate) => (
  <div>
    <span>{name}</span>
    <span className="mr-lt-5">
      (
      <TimeCell value={date} />
      <span> to </span>
      {toDate && (
        <TimeCell
          value={toDate}
        />
      )}
      )
    </span>
  </div>
);

const authorizationOptionRenderer = (options, insuranceProfileDetailId) => {
  const newOptions = options?.filter(
    (item) => item?.insuranceProfileId === insuranceProfileDetailId);
  return newOptions.map((item) => ({
    value: item.value,
    item,
    name: (
      <div className="flex-space-between option manage-list-layout" key={item.authorizationId}>
        <span>
          {item.name}
        </span>
        <span>
          <TimeCell value={item?.dateOfServices?.[0]?.dateOfService} />
        </span>
      </div>
    ),
    label: labelRender(item.name, item?.dateOfServices?.[0]?.dateOfService),
  }));
};

const authorizationDropDownRenderer = (options = []) => {
  if (!Object.values(options).length) return <div />;
  return (
    <>
      <div className="option headerWrapper schedular-option-select">
        <span className="header">
          Authorization No.
        </span>
        <span className="header">
          Date Of Service
        </span>
      </div>
      <div>
        {options}
      </div>
    </>
  );
};

const getServiceDate = (serviceDate) => (
  (serviceDate && moment(serviceDate).format('MM-DD-YYYY')) || ''
);

const displayBlock = (deleted, billingEncounterServiceLineId) => (
  deleted || (billingEncounterServiceLineId === 0) ? 'none' : ''
);

const RenderLineItem = ({
  deleted,
  billingEncounterServiceLineId,
  serviceDate,
  index,
  labels,
  form,
  onSelectCPTCode,
  cptCodeVal,
  isFieldDisabled,
  ndcIconAndValue,
  isModifierDisabled,
  onChangeModifier,
  diagnosesCodes,
  onChangeDiagnosesCode,
  onBlurDiagnosisFields,
  onChangeField,
  handleUnitsChange,
  handleAmountChange,
  patientId,
  onSelectInsurance,
  insuranceId,
  insuranceProfileDetailId,
  handleAuthSelect,
  handleCapChanges,
  financialId,
  params,
  handleHoldItemChanges,
  disabled,
  onRemove,
  jcodeRef,
  jCodesWithNdc,
  isMappingModalVisible,
  handleNDCMappingModal,
  source = '',
  onChangeInsurance,
  selectedNDCForJcode,
  setSelectedNDCForJcode,
  isDosageModalVisible,
  handleDosageMappingModal,
  selectedDosageForLucentis,
  setSelectedDosageForLucentis,
  isCollectionCharges,
  stateMasterCode,
  isUpdateInsuranceData,
  setIsUpdateInsuranceData,
  businessEntityFetchComplete,
  isShowExtraColumnForAnesthesia,
  anesthesiaStartAndEndTimeDiffMin,
  handleBusinessEntitySelect,
  handleProviderSelect,
  tabId,
  isLineItemDisabled,
  selectedCptCode,
  cptCodeNotEditedRef,
  showRcmHighlight,
  finalRcmHighlightData,
  isDenial,
  isNewLineItem,
}) => {
  const getRules = useCallback((errorMessage) => {
    if (cptCodeVal?.id && (!deleted) && !((allowAmountValidationForMipsSources[source]
      && cptCodeNotEditedRef.current)
      || allowAmountValidationForManualEncounter[cptCodeVal?.cptCode])) {
      return [() => ({
        validator(rule, value) {
          if (value > 0) { return Promise.resolve(); }
          return Promise.reject(errorMessage);
        },
      })];
    }
    return [];
  }, [cptCodeNotEditedRef, cptCodeVal, deleted, source]);

  const handleLucentisDosage = useCallback((e) => {
    const { target: { value, type } = {} } = e;
    if (type === 'radio') {
      setSelectedDosageForLucentis(value);
    } else {
      setSelectedDosageForLucentis(`${value} mg`);
    }
  }, [setSelectedDosageForLucentis]);

  return (
    <>
      <tr style={{ display: displayBlock(deleted, billingEncounterServiceLineId) }}>
        <td>
          {getServiceDate(serviceDate)}
        </td>
        <td style={{ position: 'relative' }}>
          <div style={{ display: 'none' }}>
            <EditableCell name={['lineItems', index, 'serviceDate']} />
            <EditableCell name={['lineItems', index, 'NDCNumber']} />
            <EditableCell name={['lineItems', index, 'description']} />
            <EditableCell name={['lineItems', index, 'CPTCodeId']} />
            <EditableCell name={['lineItems', index, 'billingEncounterServiceLineId']} />
            <EditableCell name={['lineItems', index, 'cptCodeVal']} />
            <EditableCell name={['lineItems', index, 'disabled']} />
            <EditableCell name={['lineItems', index, 'deleted']} />
            <EditableCell name={['lineItems', index, 'isEdited']} />
            <EditableCell name={['lineItems', index, 'isActive']} />
            <EditableCell name={['lineItems', index, 'businessEntityName']} />
            <EditableCell name={['lineItems', index, 'providerName']} />
            <EditableCell name={['lineItems', index, 'insuranceProfileTypeName']} />
            <EditableCell name={['lineItems', index, 'status']} />
            {!isShowExtraColumnForAnesthesia && (
            <Select
              id="businessEntityId"
              name={['lineItems', index, 'businessEntityId']}
              url={apiUrls.GET_BUSINESS_ENTITY}
              inputSpan="15"
              labelSpan="0"
              nameAccessor="entityName"
              valueAccessor="businessEntityId"
              onFetchComplete={businessEntityFetchComplete}
              disabled={isLineItemDisabled || isCollectionCharges}
              selectProps={{
                style: { width: 150 },
                showSearch: true,
                showArrow: true,
              }}
              onChange={handleBusinessEntitySelect}
            />
            )}
            {!isShowExtraColumnForAnesthesia && <EditableCell name={['lineItems', index, 'providerId']} disabled={isLineItemDisabled || isCollectionCharges} />}
          </div>
          <RcmWrapper isVisible={showRcmHighlight && getRulesEngineOperandByKey(finalRcmHighlightData, RULES_ENGINE_HIGHLIGHT_KEYS.PROCEDURE)} finalRcmHighlightData={getRulesEngineOperandByKey(finalRcmHighlightData, RULES_ENGINE_HIGHLIGHT_KEYS.PROCEDURE)}>
            <div>
              <CPTAutocomplete
                name={['lineItems', index, 'cptCodeId']}
                labelSpan="0"
                inputSpan="24"
                label={labels.get('labels.procedure')}
                form={form}
                onSelect={onSelectCPTCode}
                required={!!cptCodeVal?.id && !deleted}
                type
                disabled={isLineItemDisabled || (isCollectionCharges && !isNewLineItem)}
                allowClearOnBlur={false}
                allowClearOnSelect={false}
                initialValue={cptCodeVal}
                params={anesthesiaStartAndEndTimeDiffMin}
                selectProps={{ 'data-testid': `addNewEncounter-${index}-procedureCodeSelect` }}
              />
              <div>
                {ndcIconAndValue?.icon}
              </div>
            </div>
          </RcmWrapper>
        </td>
        <td>
          <div style={{ display: 'none' }}>
            <EditableCell name={['lineItems', index, 'billingEncounterModifiers']} />
          </div>
          <RcmWrapper isVisible={showRcmHighlight && (getRulesEngineOperandByKey(finalRcmHighlightData, RULES_ENGINE_HIGHLIGHT_KEYS.MODIFIERS))} finalRcmHighlightData={getRulesEngineOperandByKey(finalRcmHighlightData, RULES_ENGINE_HIGHLIGHT_KEYS.MODIFIERS)}>
            <div className="input-groups" style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <WiredModifier
                cptCode={cptCodeVal?.id}
                isFirstAutocomplete
                finalRcmHighlightData={finalRcmHighlightData}
                showRcmHighlight={showRcmHighlight}
                modifier1Props={{
                  name: ['lineItems', index, 'modifiers', 0, 'modifierId'],
                  disabled: (isModifierDisabled || !stateMasterCode || (isLineItemDisabled && !isCollectionCharges) || (isCollectionCharges && !isDenial && !isNewLineItem)),
                  onSelect: (modifier) => onChangeModifier(modifier, 0),
                  selectProps: {
                    onClear: () => onChangeModifier({}, 0),
                  },
                  minCharLength: 2,
                  required: false,
                  filterModifierSearch: true,
                }}
                modifier2Props={{
                  name: ['lineItems', index, 'modifiers', 1, 'modifierId'],
                  disabled: (isModifierDisabled || !stateMasterCode || (isLineItemDisabled && !isCollectionCharges) || (isCollectionCharges && !isDenial && !isNewLineItem)),
                  onSelect: (modifier) => onChangeModifier(modifier, 1),
                  selectProps: {
                    onClear: () => onChangeModifier({}, 1),
                  },
                  minCharLength: 2,
                  filterModifierSearch: true,
                }}
                modifier3Props={{
                  name: ['lineItems', index, 'modifiers', 2, 'modifierId'],
                  disabled: (isModifierDisabled || !stateMasterCode || (isLineItemDisabled && !isCollectionCharges) || (isCollectionCharges && !isDenial && !isNewLineItem)),
                  onSelect: (modifier) => onChangeModifier(modifier, 2),
                  selectProps: {
                    onClear: () => onChangeModifier({}, 2),
                  },
                  minCharLength: 2,
                  filterModifierSearch: true,
                }}
                modifier4Props={{
                  name: ['lineItems', index, 'modifiers', 3, 'modifierId'],
                  disabled: (isModifierDisabled || !stateMasterCode || (isLineItemDisabled && !isCollectionCharges) || (isCollectionCharges && !isDenial && !isNewLineItem)),
                  onSelect: (modifier) => onChangeModifier(modifier, 3),
                  selectProps: {
                    onClear: () => onChangeModifier({}, 3),
                  },
                  minCharLength: 2,
                  filterModifierSearch: true,
                }}
              />
            </div>
          </RcmWrapper>
        </td>
        <td>
          <div style={{ display: 'none' }}>
            <EditableCell name={['lineItems', index, 'billingEncounterDiagnosisCodes']} />
            <EditableCell name={['lineItems', index, 'diagnosisText']} />
          </div>
          <RcmWrapper isVisible={showRcmHighlight && getRulesEngineOperandByKey(finalRcmHighlightData, RULES_ENGINE_HIGHLIGHT_KEYS.DIAGNOSIS)} finalRcmHighlightData={getRulesEngineOperandByKey(finalRcmHighlightData, RULES_ENGINE_HIGHLIGHT_KEYS.DIAGNOSIS)}>
            <div className="input-groups" style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <CPTICDAutoComplete
                name={['lineItems', index, 'diagnosesCodes', 0, 'id']}
                label={labels.get('labels.diagnosisCode')}
                params={{
                  CPTCode: cptCodeVal?.name, IncludeInactive: false, PageIndex: 0, PageSize: 1000,
                }}
                inputSpan="24"
                required={!!cptCodeVal?.id && !((allowAmountValidationForMipsSources[source]
               && cptCodeNotEditedRef.current)
                 || allowAmountValidationForManualEncounter[cptCodeVal?.cptCode])
                  && !deleted}
                selectProps={{
                  showArrow: true,
                  'data-testid': `addNewEncounter-${index}-cptCodeSelect`,
                }}
                defaultOptions={diagnosesCodes?.[0] && [diagnosesCodes?.[0]]}
                onSelect={(diagnosesData) => onChangeDiagnosesCode(diagnosesData, 0)}
                disabled={isModifierDisabled || !stateMasterCode || (isLineItemDisabled && !isCollectionCharges) || (isCollectionCharges && !isDenial && !isNewLineItem)}
                className="encounter-diagnoses-code"
                minCharLength={3}
                onBlur={() => onBlurDiagnosisFields(index, 0)}
                onChange={({ target: { value } }) => onChangeField(value, 0)}
              />
              <CPTICDAutoComplete
                name={['lineItems', index, 'diagnosesCodes', 1, 'id']}
                label={labels.get('labels.diagnosisCode')}
                params={{
                  CPTCode: cptCodeVal?.name, IncludeInactive: false, PageIndex: 0, PageSize: 1000,
                }}
                inputSpan="24"
                // required={!!CPTCode}
                selectProps={{
                  showArrow: true,
                }}
                defaultOptions={diagnosesCodes?.[1] && [diagnosesCodes?.[1]]}
                onSelect={(diagnosesData) => onChangeDiagnosesCode(diagnosesData, 1)}
                disabled={isModifierDisabled || !stateMasterCode || (isLineItemDisabled && !isCollectionCharges) || (isCollectionCharges && !isDenial && !isNewLineItem)}
                className="encounter-diagnoses-code"
                minCharLength={3}
                onBlur={() => onBlurDiagnosisFields(index, 1)}
                onChange={({ target: { value } }) => onChangeField(value, 1)}
              />
              <CPTICDAutoComplete
                name={['lineItems', index, 'diagnosesCodes', 2, 'id']}
                label={labels.get('labels.diagnosisCode')}
                params={{
                  CPTCode: cptCodeVal?.name, IncludeInactive: false, PageIndex: 0, PageSize: 1000,
                }}
                inputSpan="24"
                //  required={!!CPTCode}
                selectProps={{
                  showArrow: true,
                }}
                defaultOptions={diagnosesCodes?.[2] && [diagnosesCodes?.[2]]}
                onSelect={(diagnosesData) => onChangeDiagnosesCode(diagnosesData, 2)}
                disabled={isModifierDisabled || !stateMasterCode || (isLineItemDisabled && !isCollectionCharges) || (isCollectionCharges && !isDenial && !isNewLineItem)}
                className="encounter-diagnoses-code"
                minCharLength={3}
                onBlur={() => onBlurDiagnosisFields(index, 2)}
                onChange={({ target: { value } }) => onChangeField(value, 2)}
              />
              <CPTICDAutoComplete
                name={['lineItems', index, 'diagnosesCodes', 3, 'id']}
                label={labels.get('labels.diagnosisCode')}
                params={{
                  CPTCode: cptCodeVal?.name, IncludeInactive: false, PageIndex: 0, PageSize: 1000,
                }}
                inputSpan="24"
                //  required={!!CPTCode}
                selectProps={{
                  showArrow: true,
                }}
                defaultOptions={diagnosesCodes?.[3] && [diagnosesCodes?.[3]]}
                onSelect={(diagnosesData) => onChangeDiagnosesCode(diagnosesData, 3)}
                disabled={isModifierDisabled || !stateMasterCode || (isLineItemDisabled && !isCollectionCharges) || (isCollectionCharges && !isDenial && !isNewLineItem)}
                className="encounter-diagnoses-code"
                minCharLength={3}
                onBlur={() => onBlurDiagnosisFields(index, 3)}
                onChange={({ target: { value } }) => onChangeField(value, 3)}
              />
            </div>
          </RcmWrapper>
        </td>
        <td>
          {' '}
          <RcmWrapper isVisible={showRcmHighlight && getRulesEngineOperandByKey(finalRcmHighlightData, RULES_ENGINE_HIGHLIGHT_KEYS.UNIT)} finalRcmHighlightData={getRulesEngineOperandByKey(finalRcmHighlightData, RULES_ENGINE_HIGHLIGHT_KEYS.UNIT)}>
            <EditableCell
              name={['lineItems', index, 'unit']}
              onBlur={handleUnitsChange}
              required={!!cptCodeVal?.id && !deleted}
              label={labels.get('labels.unit')}
              numberOnly
              rules={getRules(ErrorMessages.UNIT_SHOULD_BE_GREATER_THAN_ZERO)}
              dataTestId={`addNewEncounter-${index}-unit`}
              disabled={!stateMasterCode || isLineItemDisabled || (isCollectionCharges && !isNewLineItem)}
            />
          </RcmWrapper>
        </td>
        <td>
          <EditableCell
            name={['lineItems', index, 'amount']}
            onBlur={handleAmountChange}
            required={(!!cptCodeVal?.id && !((allowAmountValidationForMipsSources[source]
              && cptCodeNotEditedRef.current)
              || allowAmountValidationForManualEncounter[cptCodeVal?.cptCode])
               && !deleted)}
            rules={getRules(ErrorMessages.AMOUNT_SHOULD_BE_GREATER_THAN_ZERO)}
            dataTestId={`addNewEncounter-${index}-amount`}
            disabled={!stateMasterCode || isLineItemDisabled || (isCollectionCharges && !isNewLineItem)}
          />
        </td>
        <td>
          <div style={{ display: 'none' }}>
            <EditableCell name={['lineItems', index, 'insuranceProfileId']} />
          </div>
          <RcmWrapper isVisible={showRcmHighlight && getRulesEngineOperandByKey(finalRcmHighlightData, RULES_ENGINE_HIGHLIGHT_KEYS.INSURANCE)} finalRcmHighlightData={getRulesEngineOperandByKey(finalRcmHighlightData, RULES_ENGINE_HIGHLIGHT_KEYS.INSURANCE)}>
            <InsuranceDetailsSelect
              name={['lineItems', index, 'insuranceProfileDetailId']}
              placeholder={labels.get('labels.insurance')}
              patientId={patientId}
              required={!!cptCodeVal?.id && !deleted}
              onSelect={onSelectInsurance}
              onChange={onChangeInsurance}
              label={labels.get('labels.insurance')}
              labelSpan="0"
              disabled={!stateMasterCode || isLineItemDisabled || isCollectionCharges}
              initialValue={insuranceId && {
                insuranceProfileId: insuranceId,
                insuranceProfileDetailId,
              }}
              includeInActive
              disableOtherThanPrimaryAndSelf={!params?.claimNumber || isCollectionCharges}
              includeSelfProfile
              isUpdateInsuranceData={isUpdateInsuranceData}
              setIsUpdateInsuranceData={setIsUpdateInsuranceData}
              tabId={tabId}
            />
          </RcmWrapper>
        </td>
        <td>
          <RcmWrapper isVisible={showRcmHighlight && getRulesEngineOperandByKey(finalRcmHighlightData, RULES_ENGINE_HIGHLIGHT_KEYS.AUTHORIZATION)} finalRcmHighlightData={getRulesEngineOperandByKey(finalRcmHighlightData, RULES_ENGINE_HIGHLIGHT_KEYS.AUTHORIZATION)}>
            <Select
              name={['lineItems', index, 'authorizationId']}
              id={`authorizationId-${patientId}`}
              url={`${apiUrls.AUTHORIZATION_LIST_DROPDOWN}`}
              nameAccessor="authorizationNo"
              valueAccessor="authorizationId"
              inputSpan="15"
              labelSpan="0"
              optionRenderer={(options) => authorizationOptionRenderer(options,
                insuranceProfileDetailId)}
              selectProps={{
                style: { width: 150 },
                optionLabelProp: 'label',
                dropdownRender: authorizationDropDownRenderer,
              }}
              disabled={!parseInt(insuranceId || 0, 10) || !stateMasterCode || isLineItemDisabled || isCollectionCharges}
              params={{ PatientId: patientId }}
              onChange={handleAuthSelect}
            />
          </RcmWrapper>
        </td>
        {isShowExtraColumnForAnesthesia && (
        <>
          <td>
            <Select
              id="businessEntityId"
              name={['lineItems', index, 'businessEntityId']}
              url={apiUrls.GET_BUSINESS_ENTITY}
              inputSpan="15"
              labelSpan="0"
              nameAccessor="entityName"
              valueAccessor="businessEntityId"
              onFetchComplete={businessEntityFetchComplete}
              selectProps={{
                style: { width: 150 },
                showSearch: true,
                showArrow: true,
              }}
              onChange={handleBusinessEntitySelect}
              disabled={isLineItemDisabled || isCollectionCharges}
            />
          </td>
          <td>
            <Select
              id="providerId"
              name={['lineItems', index, 'providerId']}
              url={apiUrls.GET_PROVIDERS}
              placeholder="Search Provider"
              nameAccessor="providerName"
              valueAccessor="providerId"
              selectProps={{
                style: { width: 200 },
                showSearch: true,
                dropdownMatchSelectWidth: 200,
              }}
              inputSpan="15"
              labelSpan="0"
              defaultSorting={false}
              onChange={handleProviderSelect}
              disabled={isLineItemDisabled || isCollectionCharges}
            />
          </td>
        </>
        )}
        <td>
          {' '}
          <EditableCheckBoxCell
            name={['lineItems', index, 'cap']}
            onChange={handleCapChanges}
            disabled={!stateMasterCode || isLineItemDisabled || isCollectionCharges}
          />

        </td>
        {!financialId && (
          <td>
            <EditableCheckBoxCell
              name={['lineItems', index, 'holdItem']}
              disabled={isFieldDisabled || !stateMasterCode || isLineItemDisabled || isCollectionCharges}
              onChange={handleHoldItemChanges}
            />
          </td>
        )}
        <td>
          {' '}
          <EditableCell name={['lineItems', index, 'totalValue']} disabled />
        </td>
        <td>
          {!financialId && !disabled && stateMasterCode && !isCollectionCharges
            && <Icon name={iconNames.deleteIcon} onClick={onRemove} />}
        </td>
      </tr>
      {isMappingModalVisible && (
        <NdcMapping
          isMappingModalVisible={isMappingModalVisible}
          toggleMappingModal={handleNDCMappingModal}
          jcodeRef={jcodeRef}
          jcodeMappingData={jCodesWithNdc[cptCodeVal?.cptCodeId] || cptCodeVal}
          flaskValue={ndcIconAndValue?.value}
          selectedNDCForJcode={selectedNDCForJcode}
          setSelectedNDCForJcode={setSelectedNDCForJcode}
          selectedDosageForLucentis={selectedDosageForLucentis}
        />
      )}
      {isDosageModalVisible && (
        <Modals
          visible={isDosageModalVisible}
          toggleModal={handleDosageMappingModal}
          destroyOnClose
          title={`Select Dosages For ${selectedCptCode}`}
          width={300}
          footer={[]}
          className="select-doses-modal"
        >
          <Radio
            options={[
              { label: '0.3 mg', value: '0.3 mg' },
              { label: '0.5 mg', value: '0.5 mg' },
            ]}
            label="Dosages:"
            value={selectedDosageForLucentis}
            onChange={handleLucentisDosage}
            labelSpan="5"
            inputSpan="17"
          />
          <Input
            label=""
            labelSpan="5"
            inputSpan="15"
            onChange={handleLucentisDosage}
            value={selectedDosageForLucentis}
            placeholder={selectedDosageForLucentis || ''}
          />
        </Modals>
      )}
    </>
  );
};

const LineItem = ({
  data,
  index,
  labels,
  form,
  patientId,
  providerId,
  locationId,
  onRemoveLineItem,
  onChangeRequiredFields,
  diagnosesCodesSet,
  financialId,
  jCodesWithNdc,
  serviceDate,
  jcodeRef,
  setDiagnosesCodes,
  isCollectionCharges,
  isUpdateInsuranceData,
  setIsUpdateInsuranceData,
  isShowExtraColumnForAnesthesia,
  anesthesiaStartAndEndTimeDiffMin,
  selectedCPTLensData,
  anesthesiaCPTCode,
  tabId,
  // isUpdate,
  showRcmHighlight,
  finalRcmHighlightData,
  isDenial,
}) => {
  const {
    cptCodeVal,
    diagnosesCodes,
    disabled,
    billingEncounterServiceLineId,
    // modifiers: initialModifiers,
    insuranceProfileId,
    insuranceProfileDetailId,
    authorizationId,
    deleted,
    // serviceDate,
    unit,
    // previousUnit,
    isAmountFixed,
    amount,
    totalValue,
    modifiers: dataModifiers,
    isEdit,
    isActive,
    source,
    ndcNumber,
    stateMasterCode,
    businessEntityId,
    providerId: lineItemProviderId,
    providerName: lineItemProviderName,
    status,
  } = data;
  const amountCallRef = useRef({
    providerId, insuranceProfileDetailId, locationId, dataModifiers, unit, cptCodeVal,
  });

  const [isMappingModalVisibleRedux,, setMappingModalVisible] = useReduxStoreWithId({ id: `MAPPING_MODAL_VISIBLE_CHECK_${tabId}` });
  const isMappingModalVisible = isMappingModalVisibleRedux?.get('data');
  const [isDosageModalVisibleRedux,, setDosageModalVisible] = useReduxStoreWithId({ id: `DOSAGE_MODAL_VISIBLE_CHECK_${tabId}` });
  const isDosageModalVisible = isDosageModalVisibleRedux?.get('data');
  const [selectedNDCForJcodeRedux,, setSelectedNDCForJcode] = useReduxStoreWithId({ id: `SELECTED_NDC_FOR_JCODE_${tabId}` });
  const selectedNDCForJcode = selectedNDCForJcodeRedux?.get('data');
  const [selectedDosageForLucentisRedux,, setSelectedDosageForLucentis] = useReduxStoreWithId({ id: `selectedDosageForLucentisRedux-${tabId}` });
  const selectedDosageForLucentis = selectedDosageForLucentisRedux?.get('data');
  const [selectedLenseRedux,, setSelectedLense] = useReduxStoreWithId({ id: `selectedLenseRedux-${tabId}` });
  const selectedLense = selectedLenseRedux?.get('data');
  const [selectedCptCode, SetSelectedCptCode] = useState(null);
  const cptCodeNotEditedRef = useRef(true);

  const isLineItemDisabled = stateMasterCode?.toLowerCase() === 'waystarclearinghouse' || stateMasterCode?.toLowerCase() === 'billingstatesubmitted';
  const isNewLineItem = (typeof billingEncounterServiceLineId === 'string' && billingEncounterServiceLineId?.includes('new')) || status?.toLowerCase() === 'd';

  const [ODOSModifierOptions] = useCRUDWithoutTab({
    id: apiUrls.GET_MODIFIERS,
    url: `${apiUrls.GET_MODIFIERS}?PageSize=1200`,
    type: 'read',
  });

  const { params } = useRedirect();

  const [
    getAmountResponse, , , getAmountAPI, ,
  ] = useCRUDWithoutTab({
    id: `ENCOUNTER_LINE_ITEM_GET_AMOUNT-${index}-${params.tabId}`,
    url: apiUrls.ENCOUNTER_LINE_ITEM_GET_AMOUNT,
    type: 'read',
  });

  const [cptResponse,,, getCptData] = useCRUD({
    id: `CPT_CODE-${index}`, url: apiUrls.GET_CPTS, type: 'read',
  });

  useEffect(() => {
    if (((data?.cptCode === 'J2778' || data?.CPTCode === 'J2778') || (data?.cptCode === 'Q5128' || data?.CPTCode === 'Q5128')) && data?.cptCodeDescription) {
      const descriptionForLucentis = data?.cptCodeDescription?.split(' ');
      if (descriptionForLucentis?.length > 1) { setSelectedDosageForLucentis(`${descriptionForLucentis?.[1] || ''} ${descriptionForLucentis?.[2] || ''}`); }
    }
  }, []);

  useEffect(() => {
    if (cptResponse?.length) {
      form.setFields([
        { name: ['lineItems', index, 'unit'], value: `${cptResponse[0]?.unit}` },
      ]);
      onChangeRequiredFields(index, {
        unit: `${cptResponse[0]?.unit}`, previousUnit: unit,
      });
    }
  }, [cptResponse]);

  useEffect(() => {
    if (cptCodeVal?.cptCode
      && anesthesiaCPTCode?.includes(cptCodeVal?.cptCode)) {
      const cptDataParams = {
        CPTCodeText: cptCodeVal?.cptCode,
        ...anesthesiaStartAndEndTimeDiffMin,
      };
      if (dataModifiers?.length) {
        cptDataParams.AnesthesiaFirstModifierId = dataModifiers[0]?.modifierId;
      }
      getCptData(cptDataParams);
    }
  }, [anesthesiaStartAndEndTimeDiffMin?.AnesthesiaMinutes]);

  const validateAmount = useCallback(() => {
    if (typeof amount === 'number' || !cptCodeVal?.id) {
      return [];
    }
    return ['required'];
  }, []);

  const handleNDCMappingModal = useCallback(() => {
    setMappingModalVisible(!isMappingModalVisible);
  }, [setMappingModalVisible, isMappingModalVisible]);

  const handleDosageMappingModal = useCallback(() => {
    setDosageModalVisible(!isDosageModalVisible);
  }, [isDosageModalVisible, setDosageModalVisible]);

  const ndcIconAndValue = useMemo(() => {
    const isDisabled = (isCollectionCharges && !isNewLineItem);
    if (cptCodeVal?.cptCode?.startsWith('J') || cptCodeVal?.cptCode === 'Q5128') {
      const activeBillingNDCs = jCodesWithNdc?.[cptCodeVal?.cptCodeId]
      ?.[0]?.billingNdcDetails?.filter((item) => item?.isActive);
      if (jCodesWithNdc[cptCodeVal?.cptCodeId] && activeBillingNDCs?.length) {
        let ndc;
        if (cptCodeVal?.cptCode === 'J2778' || cptCodeVal?.cptCode === 'Q5128') {
          ndc = findLast(activeBillingNDCs, (item) => (item?.businessEntityBillingNDCId === selectedNDCForJcode || (`${item?.ndcQuantity} mg` === selectedDosageForLucentis && !selectedNDCForJcode)));
        } else {
          ndc = activeBillingNDCs?.find(
            (item) => item?.businessEntityBillingNDCId === (
              selectedNDCForJcode
              || activeBillingNDCs?.[activeBillingNDCs?.length - 1]?.businessEntityBillingNDCId));
        }
        const ndcString = getString([
              ndc?.ndcManufacturerNumber, ndc?.ndcProductNumber, ndc?.ndcPackageNumber]);
        return {
          icon: <div className={classNames('flask-green', { 'cursor-not-allowed': isDisabled })} title={`NDC - ${ndcNumber || ndcString}`} role="presentation" onClick={!isDisabled && handleNDCMappingModal} />,
          value: 'green',
          ndc: ndcString?.split?.(' ').join?.(''),
        };
      }
      return {
        icon: <div className={classNames('flask-red', { 'cursor-not-allowed': isDisabled })} role="presentation" onClick={!isDisabled && handleNDCMappingModal} />,
        value: 'red',
      };
    }
    return {};
  }, [cptCodeVal, handleNDCMappingModal, isCollectionCharges, isNewLineItem, jCodesWithNdc, ndcNumber, selectedDosageForLucentis, selectedNDCForJcode]);

  useEffect(() => {
    const newDep = {
      providerId, insuranceProfileDetailId, locationId, dataModifiers, unit, cptCodeVal,
    };
    if (getAmountResponse && !isEqual(amountCallRef.current, newDep)) {
      amountCallRef.current = newDep;
      onChangeRequiredFields(index, {
        amount: getAmountResponse?.amount || 0,
        totalValue: getAmountResponse?.totalValue || 0,
      });
    }
  }, [getAmountResponse]);

  useEffect(() => {
    const newDep = {
      providerId, insuranceProfileDetailId, locationId, dataModifiers, unit, cptCodeVal,
    };
    if (cptCodeVal?.id && providerId && unit && !isEqual(amountCallRef.current, newDep)
    && !isLineItemDisabled && !(isCollectionCharges && !isNewLineItem)) {
      const amountAPIParams = {
        CPTCodeId: cptCodeVal?.id,
        ProviderId: providerId,
        Unit: unit,
        Modifiers: getModifiersCode(dataModifiers),
        LocationId: form.getFieldValue('locationId') || null,
        InsuranceProfileDetailId: insuranceProfileDetailId || null,
      };
      if (isAmountFixed && amount) {
        amountAPIParams.Amount = amount;
      }
      getAmountAPI(amountAPIParams);
    }
  }, [providerId, insuranceProfileDetailId, locationId, dataModifiers, unit, cptCodeVal]);

  useEffect(() => {
    const parsedValue = selectedDosageForLucentis && `Lucentis ${selectedDosageForLucentis}`;
    form.setFields([{
      name: ['lineItems', index, 'description'],
      value: parsedValue,
    }]);
    const element = document.querySelector(`[data-testid=addNewEncounter-${index}-procedureCodeSelect]`);
    const titleSpan = element.querySelector('.ant-select-selection-item');
    if (titleSpan) {
      titleSpan.title = parsedValue;
    }
  }, [selectedDosageForLucentis]);

  useEffect(() => {
    form.setFields([{
      name: ['lineItems', index, 'providerId'],
      value: lineItemProviderId,
    }, {
      name: ['lineItems', index, 'providerName'],
      value: lineItemProviderName,
    }]);
  }, [lineItemProviderId]);

  useEffect(() => {
    form.setFields([{
      name: ['lineItems', index, 'NDCNumber'],
      value: ndcNumber || ndcIconAndValue.ndc,
    }]);
  }, [ndcIconAndValue, ndcNumber]);

  useEffect(() => {
    form.setFields([{
      name: ['lineItems', index, 'deleted'],
      value: deleted,
    },
    ]);
  }, [deleted]);

  useEffect(() => {
    form.setFields([{
      name: ['lineItems', index, 'isEdited'],
      value: isEdit,
    },
    ]);
  }, [isEdit]);

  useEffect(() => {
    form.setFields([{
      name: ['lineItems', index, 'isActive'],
      value: isActive,
    },
    ]);
  }, [isActive]);

  useEffect(() => {
    form.setFields([{
      name: ['lineItems', index, 'lineItemDate'],
      value: moment(serviceDate),
    },
    {
      name: ['lineItems', index, 'serviceDate'],
      value: moment(serviceDate).toISOString(),
    },
    ]);
  }, [serviceDate]);

  useEffect(() => {
    form.setFields([{
      name: ['lineItems', index, 'amount'],
      value: amount,
      errors: validateAmount(),
    },
    ]);
  }, [amount]);

  useEffect(() => {
    form.setFields([{
      name: ['lineItems', index, 'totalValue'],
      value: totalValue,
    },
    ]);
  }, [totalValue]);

  useEffect(() => {
    form.setFields([{
      name: ['lineItems', index, 'authorizationId'],
      value: authorizationId === 0 ? null : authorizationId,
    },
    ]);
  }, [authorizationId]);

  useEffect(() => {
    if (insuranceProfileId) {
      form.setFields([
        {
          name: ['lineItems', index, 'insuranceProfileId'],
          value: insuranceProfileId,
        },
        {
          name: ['lineItems', index, 'insuranceProfileDetailId'],
          value: insuranceProfileDetailId,
        },
      ]);
    }
  }, []);

  const onRemove = useCallback(() => {
    onRemoveLineItem(index);
  }, [onRemoveLineItem, index]);

  const onSelectInsurance = useCallback((insuranceDetailsId, value = {}) => {
    const { pId, item } = value;
    onChangeRequiredFields(index, {
      insuranceProfileDetailId: insuranceDetailsId,
      insuranceProfileId: pId,
      isAmountFixed: false,
      insuranceProfileTypeName: item?.profileType,
    });
    form.setFields([{
      name: ['lineItems', index, 'insuranceProfileId'],
      value: pId,
    }]);
    form.setFields([{
      name: ['lineItems', index, 'insuranceProfileTypeName'],
      value: item?.profileType,
    }]);
    form.setFields([{
      name: ['lineItems', index, 'authorizationId'],
      value: undefined,
    }]);
  }, [onChangeRequiredFields, index, form]);

  const onChangeInsurance = useCallback((insuranceDetailsId) => {
    if (!insuranceDetailsId) {
      onChangeRequiredFields(index, {
        insuranceProfileDetailId: insuranceDetailsId,
        insuranceProfileId: insuranceDetailsId,
        isAmountFixed: false,
      });
      form.setFields([{
        name: ['lineItems', index, 'insuranceProfileId'],
        value: insuranceDetailsId,
      },
      {
        name: ['lineItems', index, 'authorizationId'],
        value: undefined,
      },
      ]);
    }
  }, [onChangeRequiredFields, index, form]);

  const insuranceId = insuranceProfileId;

  const onChangeDiagnosesCode = useCallback((diagnosesCodeData, diagnosesCodeIndex) => {
    const savedDiagnosesCodes = form.getFieldValue(['lineItems', index, 'diagnosesCodes']) || [];
    savedDiagnosesCodes[diagnosesCodeIndex] = {
      id: diagnosesCodeData.id,
      icD10CodeId: diagnosesCodeData.id,
      icdCode: diagnosesCodeData.icdCode,
      icdCodeDescription: diagnosesCodeData.description,
      description: diagnosesCodeData.description,
      name: diagnosesCodeData.icdCode,
      value: diagnosesCodeData.id,
    };
    const billingEncounterDiagnosisCodes = form.getFieldValue(['lineItems', index, 'billingEncounterDiagnosisCodes']) || [];
    billingEncounterDiagnosisCodes[diagnosesCodeIndex] = diagnosesCodeData;
    onChangeRequiredFields(index, {
      billingEncounterDiagnosisCodes,
      diagnosesCodes: savedDiagnosesCodes,
    });
    const tempDiagnosisCodeSet = Object.values(diagnosesCodesSet || {}).map((item) => item?.id);
    const diagnosisCodeSetLength = Object.keys(diagnosesCodesSet || {}).length;
    if (!tempDiagnosisCodeSet.includes(diagnosesCodeData?.id) && diagnosisCodeSetLength < 12) {
      setDiagnosesCodes({
        ...diagnosesCodesSet,
        [diagnosisCodeSetLength + 1]: diagnosesCodeData,
      });
    }
  }, [form, index, onChangeRequiredFields, diagnosesCodesSet, setDiagnosesCodes]);

  const onChangeField = useCallback((searchText, diagnosesCodeIndex) => {
    form.setFields([{
      name: ['lineItems', index, 'diagnosisText', diagnosesCodeIndex],
      value: searchText,
    }]);
  }, [form, index]);

  const onChangeModifier = useCallback((modifierData, modifierIndex) => {
    const modifiers = form.getFieldValue(['lineItems', index, 'modifiers']) || [];
    modifiers[modifierIndex] = modifierData;
    if (modifierIndex === 0 && anesthesiaCPTCode?.includes(cptCodeVal?.cptCode)) {
      getCptData({
        CPTCodeText: cptCodeVal?.cptCode,
        AnesthesiaFirstModifierId: modifierData?.modifierId,
        ...anesthesiaStartAndEndTimeDiffMin,
      });
    }
    onChangeRequiredFields(index, { modifiers: [...modifiers], isAmountFixed: false });
  }, [anesthesiaCPTCode, anesthesiaStartAndEndTimeDiffMin, cptCodeVal,
    form, getCptData, index, onChangeRequiredFields]);

  const setLineItemsValue = useCallback((cptCodeData, clonedCptCode, isSetUnit) => {
    form.setFields([
      { name: ['lineItems', index, 'cptCodeVal'], value: clonedCptCode },
      { name: ['lineItems', index, 'CPTCodeId'], value: cptCodeData.id },
      { name: ['lineItems', index, 'CPTCode'], value: cptCodeData.cptCode },
      { name: ['lineItems', index, 'modifiers'], value: [] },
      { name: ['lineItems', index, 'diagnosesCodes'], value: [] },
      { name: ['lineItems', index, 'billingEncounterDiagnosisCodes'], value: [] },
    ]);
    if (isSetUnit) {
      form.setFields([
        { name: ['lineItems', index, 'unit'], value: `${cptCodeData?.unit || ''}` },
      ]);
    }
    if (clonedCptCode.name === '92310' || clonedCptCode.name === '92072') {
      const lenses = selectedCPTLensData?.find(
        (it) => it?.CPTCode === cptCodeData?.cptCode)?.LensesType;
      const item = lenses?.find(
        (selectedLens) => selectedLens?.CPTCodeId === clonedCptCode?.id);
      const modifierId = ODOSModifierOptions?.result?.find(
        (it) => it?.modifierCode === item?.ModifierCode)?.modifierId;
      form.setFields([
        { name: ['lineItems', index, 'amount'], value: item?.Amount },
        { name: ['lineItems', index, 'totalValue'], value: item?.TotalValue },
        { name: ['lineItems', index, 'modifiers', 0, 'modifierId'], value: modifierId },
      ]);
    }
  }, [ODOSModifierOptions, form, index, selectedCPTLensData]);

  const onSelectCPTCode = useCallback((cptCodeData) => {
    cptCodeNotEditedRef.current = false;
    SetSelectedCptCode(cptCodeData.cptCode);
    const clonedCptCode = cptCodeData;
    clonedCptCode.name = cptCodeData.cptCode;
    clonedCptCode.value = cptCodeData.id;
    clonedCptCode.cptCodeId = cptCodeData.id;
    clonedCptCode.CPTCodeId = cptCodeData.id;
    const isUnitAutoFill = anesthesiaCPTCode?.includes(cptCodeData.cptCode);
    let addUnit = {};
    if (isUnitAutoFill) {
      addUnit = { unit: `${cptCodeData?.unit || ''}`, previousUnit: `${unit || ''}` };
    }
    setLineItemsValue(cptCodeData, clonedCptCode, isUnitAutoFill);
    onChangeRequiredFields(index, {
      cptCodeId: cptCodeData.id,
      CPTCodeId: cptCodeData.id,
      CPTCode: cptCodeData.cptCode,
      cptCodeVal: clonedCptCode,
      modifiers: [],
      diagnosesCodes: [],
      billingEncounterModifiers: [],
      billingEncounterDiagnosisCodes: [],
      isAmountFixed: false,
      ...addUnit,
    });
    if (cptCodeData?.cptCode === 'J2778' || cptCodeData?.cptCode === 'Q5128') {
      setDosageModalVisible(true);
    }
  }, [anesthesiaCPTCode, index, onChangeRequiredFields, setDosageModalVisible,
    setLineItemsValue, unit]);

  const onBlurDiagnosisFields = useCallback((lineItemIndex, diagnosisIndex) => {
    const diagnosesCode = form.getFieldValue(['lineItems', index, 'diagnosesCodes', diagnosisIndex]);
    const diagnosesCodeText = form.getFieldValue(['lineItems', index, 'diagnosisText', diagnosisIndex]);
    const billingEncounterDiagnosisCodes = form.getFieldValue(['lineItems', index, 'billingEncounterDiagnosisCodes']) || [];
    if (diagnosesCodeText) {
      const diagnosesCodeFromSet = diagnosesCodesSet?.[diagnosesCodeText?.trim()];
      if (diagnosesCodeFromSet && (!diagnosesCode
        || billingEncounterDiagnosisCodes[diagnosisIndex]?.id !== diagnosesCodeFromSet?.id)) {
        diagnosesCodeFromSet.name = diagnosesCodeFromSet?.icdCode;
        diagnosesCodeFromSet.value = diagnosesCodeFromSet?.id;
        billingEncounterDiagnosisCodes[diagnosisIndex] = diagnosesCodeFromSet;
        const savedDiagnosesCodes = form.getFieldValue(['lineItems', index, 'diagnosesCodes']) || [];
        savedDiagnosesCodes[diagnosisIndex] = {
          id: diagnosesCodeFromSet?.id,
          icD10CodeId: diagnosesCodeFromSet?.id,
          icdCode: diagnosesCodeFromSet?.icdCode,
          icdCodeDescription: diagnosesCodeFromSet?.description,
          description: diagnosesCodeFromSet?.description,
          name: diagnosesCodeFromSet?.icdCode,
          value: diagnosesCodeFromSet?.id,
        };
        onChangeRequiredFields(index, {
          diagnosesCodes: savedDiagnosesCodes,
          billingEncounterDiagnosisCodes,
        });
      }
    }
  }, [form, index, diagnosesCodesSet, onChangeRequiredFields]);

  const handleUnitsChange = useCallback((e) => {
    onChangeRequiredFields(index, { unit: `${e?.target?.value}`, previousUnit: unit });
  }, [index, onChangeRequiredFields, unit]);

  const handleAmountChange = useCallback((e) => {
    const value = e?.target?.value ? Number(e?.target?.value) : e?.target?.value;
    onChangeRequiredFields(index, { amount: value, isAmountFixed: !!value });
  }, [index, onChangeRequiredFields]);

  const handleAuthSelect = useCallback((authorizationID) => {
    onChangeRequiredFields(index, { authorizationId: authorizationID });
    form.setFields([{
      name: ['lineItems', index, 'authorizationId'],
      value: authorizationID,
    }]);
  }, [form, index, onChangeRequiredFields]);

  const handleBusinessEntitySelect = useCallback((businessEntityID, item) => {
    form.setFields([{
      name: ['lineItems', index, 'businessEntityName'],
      value: item?.title,
    }, {
      name: ['lineItems', index, 'businessEntityId'],
      value: businessEntityID,
    }]);
    onChangeRequiredFields(index, {
      businessEntityId: businessEntityID,
      businessEntityName: item?.title,
    });
  }, [form, index, onChangeRequiredFields]);

  const handleProviderSelect = useCallback((providerID, item) => {
    form.setFields([{
      name: ['lineItems', index, 'providerName'],
      value: item?.title,
    }]);
    onChangeRequiredFields(index, {
      providerId: providerID,
      providerName: item?.title,
    });
  }, [form, index, onChangeRequiredFields]);

  const handleCapChanges = useCallback((e) => {
    onChangeRequiredFields(index, {
      cap: e.target.checked,
    });
  }, [index, onChangeRequiredFields]);

  const handleHoldItemChanges = useCallback((e) => {
    onChangeRequiredFields(index, {
      holdItem: e.target.checked,
    });
  }, [index, onChangeRequiredFields]);

  const isFieldDisabled = useMemo(() => !financialId && disabled, [disabled, financialId]);

  const isModifierDisabled = useMemo(() => (
    !financialId && !cptCodeVal?.id), [cptCodeVal, financialId]);

  const disableStateMasterCodes = useMemo(() => (isCollectionCharges || (stateMasterCode?.toLowerCase() === 'billingstateready' || stateMasterCode?.toLowerCase() === 'billingstateerror' || stateMasterCode?.toLowerCase() === 'billingstateclearinghouse' || stateMasterCode?.toLowerCase() === 'waystarrejectionstate' || !stateMasterCode)), [isCollectionCharges, stateMasterCode]);

  const businessEntityFetchComplete = useCallback((businessEntityData = []) => {
    if (!businessEntityId && businessEntityData?.length) {
      const defaultBusinessEntity = businessEntityData.find(({ entityName }) => entityName === 'AcuityEye');
      onChangeRequiredFields(index, {
        businessEntityName: defaultBusinessEntity?.name,
        businessEntityId: defaultBusinessEntity?.businessEntityId,
      });
      form.setFields([{
        name: ['lineItems', index, 'businessEntityId'],
        value: defaultBusinessEntity?.businessEntityId,
      }, {
        name: ['lineItems', index, 'businessEntityName'],
        value: defaultBusinessEntity?.name,
      },
      ]);
    }
  }, [businessEntityId, form, index, onChangeRequiredFields]);

  return (
    <RenderLineItem
      deleted={deleted}
      billingEncounterServiceLineId={billingEncounterServiceLineId}
      serviceDate={serviceDate}
      index={index}
      labels={labels}
      form={form}
      onSelectCPTCode={onSelectCPTCode}
      cptCodeVal={cptCodeVal}
      ndcIconAndValue={ndcIconAndValue}
      isFieldDisabled={isFieldDisabled}
      isModifierDisabled={isModifierDisabled}
      onChangeModifier={onChangeModifier}
      diagnosesCodes={diagnosesCodes}
      onChangeDiagnosesCode={onChangeDiagnosesCode}
      onBlurDiagnosisFields={onBlurDiagnosisFields}
      onChangeField={onChangeField}
      handleUnitsChange={handleUnitsChange}
      handleAmountChange={handleAmountChange}
      patientId={patientId}
      onSelectInsurance={onSelectInsurance}
      onChangeInsurance={onChangeInsurance}
      insuranceId={insuranceId}
      insuranceProfileDetailId={insuranceProfileDetailId}
      handleAuthSelect={handleAuthSelect}
      handleBusinessEntitySelect={handleBusinessEntitySelect}
      handleProviderSelect={handleProviderSelect}
      handleCapChanges={handleCapChanges}
      financialId={financialId}
      params={params}
      handleHoldItemChanges={handleHoldItemChanges}
      disabled={disabled}
      onRemove={onRemove}
      jCodesWithNdc={jCodesWithNdc}
      jcodeRef={jcodeRef}
      isMappingModalVisible={isMappingModalVisible}
      handleNDCMappingModal={handleNDCMappingModal}
      selectedNDCForJcode={selectedNDCForJcode}
      setSelectedNDCForJcode={setSelectedNDCForJcode}
      selectedLense={selectedLense}
      setSelectedLense={setSelectedLense}
      isDosageModalVisible={isDosageModalVisible}
      handleDosageMappingModal={handleDosageMappingModal}
      selectedDosageForLucentis={selectedDosageForLucentis}
      setSelectedDosageForLucentis={setSelectedDosageForLucentis}
      source={source}
      isCollectionCharges={isCollectionCharges}
      stateMasterCode={disableStateMasterCodes}
      isUpdateInsuranceData={isUpdateInsuranceData}
      setIsUpdateInsuranceData={setIsUpdateInsuranceData}
      businessEntityFetchComplete={businessEntityFetchComplete}
      isShowExtraColumnForAnesthesia={isShowExtraColumnForAnesthesia}
      anesthesiaStartAndEndTimeDiffMin={anesthesiaStartAndEndTimeDiffMin}
      tabId={tabId}
      isLineItemDisabled={isLineItemDisabled}
      selectedCptCode={selectedCptCode}
      cptCodeNotEditedRef={cptCodeNotEditedRef}
      showRcmHighlight={showRcmHighlight}
      finalRcmHighlightData={finalRcmHighlightData}
      isDenial={isDenial}
      isNewLineItem={isNewLineItem}
    />
  );
};

export default LineItem;
