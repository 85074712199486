import React from 'react';
import Form from '../../../components/Form';
import Input from '../../../components/Form/Input';
import CheckBox from '../../../components/Form/CheckBox';

function ExamroomForm({
  labels,
}) {
  return (
    <Form.Column>
      <div className="group-shadow width-33-only">
        <div style={{ display: 'none' }}>
          <Input label="" name="locationId" inputSpan="16" labelSpan="8" disabled />
        </div>
        <Input
          label={labels.get('labels.name')}
          name="name"
          required
          labelSpan="8"
          inputSpan="16"
          maxValueLength={25}
        />
        <Input
          label={labels.get('labels.description')}
          name="description"
          required
          maxValueLength={100}
          labelSpan="8"
          inputSpan="16"
        />
        <CheckBox label={labels.get('labels.active')} valuePropName="checked" name="isActive" labelSpan="4" />
      </div>
    </Form.Column>
  );
}

export default ExamroomForm;
