import { useEffect } from 'react';
import Events from '../../../../lib/events';

const TableComponent = ({
  Component, reFetch, handleReFetch, data,
}) => {
  useEffect(() => {
    setTimeout(() => Events.trigger('selectedOutSideTest', data), 0);
  }, [data]);
  useEffect(() => {
    handleReFetch(reFetch);
    Events.on('refetchSchedulerAppointmentListViewTable', 'refetchSchedulerAppointmentListViewTable', reFetch);
    return () => Events.remove('refetchSchedulerAppointmentListViewTable');
  }, []);
  return (Component);
};

export default TableComponent;
