import React from 'react';

const NestedTable = ({ heading, colspan, nestedcolspan }) => (
  <td colSpan={colspan} style={{ padding: '4px' }}>
    <table>
      <tr>
        <td style={{ paddingRight: '4px' }}>
          {heading}
        </td>
        <td colSpan={nestedcolspan}>
          <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
        </td>
      </tr>
    </table>
  </td>
);

const TableRowWithCheckbox = () => (
  <tr>
    <td style={{ padding: '4px' }}>Neurologic Disease</td>
    <td style={{ padding: '4px' }}>
      <input type="checkbox" />
    </td>
    <td style={{ padding: '4px' }}>
      <input type="checkbox" />
    </td>
    <td style={{ padding: '4px' }}>Kidney Disease</td>
    <td style={{ padding: '4px' }}>
      <input type="checkbox" />
    </td>
    <td style={{ padding: '4px' }}>
      <input type="checkbox" />
    </td>
  </tr>
);

const TableRowWithInput = ({ heading }) => (
  <tr>
    <td style={{ padding: '4px' }}>{heading}</td>
    <td style={{ padding: '4px' }}><input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" /></td>
    <td style={{ padding: '4px' }}><input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" /></td>
    <td style={{ padding: '4px' }}><input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" /></td>
  </tr>
);

const TableRowWithInputLabel = ({ heading }) => (
  <td style={{ padding: '4px' }}>
    <table>
      <tr>
        <td>
          <input type="checkbox" id="warm" name="warm" value="warm" />
          <label htmlFor="warm">
            {heading}
          </label>
        </td>
      </tr>
    </table>
  </td>
);
const Preop = () => (
  <div>
    <div className="main-form-container">
      <div className="m-vertical-scroll">
        <table border="1" style={{ border: '1px solid #d2ddef', fontSize: '13px' }} cellPadding="0" cellSpacing="0" width="100%">
          <tr>
            <td colSpan="19">
              <p style={{
                maxWidth: '200px', margin: '0', padding: '8px 12px', fontSize: '12px', color: '2a4a79',
              }}
              >
                Drange ASC, LLC
                L310 W. Stewart Dr Suite 310
                Drange CA 92868
                714.633.1338
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="19" bgcolor="#2e79f8" align="center" style={{ height: '24px' }}>
              <p style={{ margin: '0', color: 'white', fontSize: '14px' }}>PRE-OPERATIVE ASSESSMENT</p>
            </td>
          </tr>
          <tr>
            <td colSpan="8" style={{ width: '100%', paddingLeft: '4px' }}>Nursing admission sate base</td>
            <td colSpan="2">
              <table>
                <tr>
                  <td style={{ padding: '4px 4px' }}>Date:</td>
                  <td style={{ padding: '4px 4px' }}>
                    <input style={{ width: '80px', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                  </td>
                  <td style={{ padding: '4px 4px', minWidth: '56px' }}>Time in:</td>
                  <td style={{ padding: '4px 4px' }}>
                    <input style={{ width: '80px', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td style={{ padding: '4px' }}>Vita Siggn:</td>
            <NestedTable heading="BP:" />
            <NestedTable heading="P" />
            <NestedTable heading="PO2" />
            <NestedTable heading="T" />
            <NestedTable heading="PR" />
            <NestedTable heading="Ht" />
            <NestedTable heading="Wt" />
            <NestedTable heading="Plain Level" />
            <NestedTable heading="Location" />
          </tr>
          <tr>
            <td style={{ padding: '4px' }}>Allergy/Reaction:</td>
            <NestedTable colspan="4" heading="Med" />
            <NestedTable colspan="4" heading="Food" />
            <NestedTable colspan="2" heading="Other" />
          </tr>
          <tr>
            <NestedTable colspan="10" nestedcolspan="8" heading="Admitting Diagnosis:" />
          </tr>
          <tr>
            <NestedTable colspan="10" nestedcolspan="8" heading="Proposed Procedure:" />
          </tr>
          <tr>
            <td colSpan="1" style={{ padding: '4px' }}>Skin Condition:</td>
            <td style={{ padding: '4px' }}>
              <table>
                <tr>
                  <td>
                    <input type="checkbox" id="dry" name="vehidrycle1" value="dry" />
                    <label htmlFor="dry"> Dry</label>
                  </td>
                </tr>
              </table>
            </td>
            <TableRowWithInputLabel heading="Warm" />
            <TableRowWithInputLabel heading="Pink" />
            <TableRowWithInputLabel heading="Pale" />
            <TableRowWithInputLabel heading="Cyanotic" />
            <TableRowWithInputLabel heading="Diaphoretic" />
            <td colSpan="3" style={{ padding: '4px' }}>
              <table>
                <tr>
                  <td>
                    <input type="checkbox" id="warm" name="warm" value="warm" />
                    <label htmlFor="warm"> Other</label>
                    <input
                      style={{
                        width: '212px', marginLeft: '12px', border: '1px solid #d2ddef', padding: '0 4px',
                      }}
                      type="text"
                    />
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colSpan="1" style={{ padding: '4px' }}>Abdominal Assessment:</td>
            <TableRowWithInputLabel heading="Flat" />
            <TableRowWithInputLabel heading="Soft" />
            <TableRowWithInputLabel heading="Firn" />
            <TableRowWithInputLabel heading="Tender" />
            <TableRowWithInputLabel heading="Distended" />
            <td colSpan="4" style={{ padding: '4px' }}>
              <table>
                <tr>
                  <td>
                    <input type="checkbox" id="warm" name="warm" value="warm" />
                    <label htmlFor="warm"> Other</label>
                    <input
                      style={{
                        width: '212px', marginLeft: '12px', border: '1px solid #d2ddef', padding: '0 4px',
                      }}
                      type="text"
                    />
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colSpan="1" style={{ padding: '4px' }}>Respiratory Assessment:</td>
            <td style={{ padding: '4px' }}>
              <table>
                <tr>
                  <td style={{ minWidth: '116px' }}>
                    <input type="checkbox" id="dry" name="vehidrycle1" value="dry" />
                    <label htmlFor="dry"> Regular/Normal</label>
                  </td>
                </tr>
              </table>
            </td>
            <TableRowWithInputLabel heading="Dyapnea" />
            <td colSpan="7" style={{ padding: '4px' }}>
              <table>
                <tr>
                  <td>
                    <input type="checkbox" id="warm" name="warm" value="warm" />
                    <label htmlFor="warm"> Other</label>
                    <input
                      style={{
                        width: '212px', marginLeft: '12px', border: '1px solid #d2ddef', padding: '0 4px',
                      }}
                      type="text"
                    />
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colSpan="1" style={{ padding: '4px' }}>Physical Limitation:</td>
            <TableRowWithInputLabel heading="None" />
            <td style={{ padding: '4px' }}>
              <table>
                <tr>
                  <td>
                    <input type="checkbox" id="warm" name="warm" value="warm" />
                    <label htmlFor="warm"> Visual</label>
                    <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                  </td>
                </tr>
              </table>
            </td>
            <td style={{ padding: '4px' }}>
              <table>
                <tr>
                  <td>
                    <input type="checkbox" id="warm" name="warm" value="warm" />
                    <label htmlFor="warm"> Mobility</label>
                    <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                  </td>
                </tr>
              </table>
            </td>
            <td style={{ padding: '4px' }}>
              <table>
                <tr>
                  <td>
                    <input type="checkbox" id="warm" name="warm" value="warm" />
                    <label htmlFor="warm"> Auditory</label>
                    <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                  </td>
                </tr>
              </table>
            </td>
            <td colSpan="6" style={{ padding: '4px' }}>
              <table>
                <tr>
                  <td>
                    <input type="checkbox" id="warm" name="warm" value="warm" />
                    <label htmlFor="warm"> Other</label>
                    <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colSpan="1" style={{ padding: '4px' }}>LOC:</td>
            <TableRowWithInputLabel heading="Alert" />
            <TableRowWithInputLabel heading="Calm" />
            <TableRowWithInputLabel heading="Oriented" />
            <TableRowWithInputLabel heading="Confused" />
            <TableRowWithInputLabel heading="Nonverbal" />

            <TableRowWithInputLabel heading="Agitated" />

            <td colSpan="6" style={{ padding: '4px' }}>
              <table>
                <tr>
                  <td>
                    <input type="checkbox" id="warm" name="warm" value="warm" />
                    <label htmlFor="warm"> Other</label>
                    <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colSpan="1" style={{ padding: '4px' }}>Language:</td>
            <TableRowWithInputLabel heading="English" />
            <TableRowWithInputLabel heading="Chinese" />
            <TableRowWithInputLabel heading="Spanish" />
            <TableRowWithInputLabel heading="Vietnamese" />

            <td style={{ padding: '4px' }}>
              <table>
                <tr>
                  <td>
                    <input type="checkbox" id="warm" name="warm" value="warm" />
                    <label htmlFor="warm"> Other</label>
                    <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                  </td>
                </tr>
              </table>
            </td>
            <td style={{ padding: '4px', minWidth: '152px' }}>
              <table>
                <tr>
                  <td>Interpreter</td>
                  <td>
                    <input type="checkbox" id="warm" name="warm" value="warm" />
                    <label htmlFor="warm"> Yes</label>
                  </td>
                  <td>
                    <input type="checkbox" id="warm" name="warm" value="warm" />
                    <label htmlFor="warm"> No</label>
                  </td>
                </tr>
              </table>
            </td>
            <td style={{ padding: '4px' }}>
              <table>
                <tr>
                  <td>By:</td>

                  <td>
                    <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                  </td>
                </tr>
              </table>
            </td>
            <td colSpan="6" style={{ padding: '4px' }}>
              <table>
                <tr>
                  <td>
                    <input type="checkbox" id="warm" name="warm" value="warm" />
                    <label htmlFor="warm"> Other</label>
                    <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colSpan="10">
              <table width="100%">
                <tr>
                  <td width="50%" style={{ verticalAlign: 'top' }}>
                    <table width="100%" border="1" style={{ border: '1px solid #d2ddef', fontSize: '13px' }}>
                      <tr bgcolor="#2e79f8" style={{ color: '#fff' }}>
                        <th style={{ padding: '4px' }}>PRE-OP CHECKLIST</th>
                        <th style={{ padding: '4px' }}>YES</th>
                        <th style={{ padding: '4px' }}>NO</th>
                        <th style={{ padding: '4px' }}>COMMENT</th>
                      </tr>
                      <tr>
                        <td style={{ padding: '4px' }}>I.D.band/Allergy band checked</td>
                        <td style={{ padding: '4px' }}>
                          <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                        </td>
                        <td style={{ padding: '4px' }}>
                          <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                        </td>
                        <td style={{ padding: '4px' }}>
                          <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '4px' }}>Surgical/Informed Consent</td>
                        <td style={{ padding: '4px' }}>
                          <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                        </td>
                        <td style={{ padding: '4px' }}>
                          <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                        </td>
                        <td style={{ padding: '4px' }}><input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" /></td>
                      </tr>
                      <TableRowWithInput heading="H&P Completed" />
                      <TableRowWithInput heading="Labs/EKG/CXR" />
                      <TableRowWithInput heading="Surgical/Bowel Pro" />
                      <TableRowWithInput heading="Surgical/Bowel Pro" />
                      <tr>
                        <td colSpan="2" style={{ padding: '4px' }}>
                          <table>
                            <tr>
                              <td>L.M.P:</td>
                              <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                            </tr>
                          </table>
                        </td>
                        <td colSpan="2" style={{ padding: '4px' }}>
                          <table>
                            <tr>
                              <td>Urine pregnancy Test:</td>
                              <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" style={{ padding: '4px' }}>
                          <table>
                            <tr>
                              <td>Accucheck Result:</td>
                              <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                            </tr>
                          </table>
                        </td>
                        <td colSpan="2" style={{ padding: '4px' }}>
                          <table>
                            <tr>
                              <td>Time:</td>
                              <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td width="50%" style={{ verticalAlign: 'top' }}>
                    <table width="100%" border="0">
                      <tr bgcolor="#2e79f8" style={{ color: '#fff' }}>
                        <th style={{ padding: '4px' }}>Disease: </th>
                        <th style={{ padding: '4px' }}>YES</th>
                        <th style={{ padding: '4px' }}>NO</th>
                        <th style={{ padding: '4px' }}>Disease: </th>
                        <th style={{ padding: '4px' }}>YES</th>
                        <th style={{ padding: '4px' }}>NO</th>
                      </tr>
                      <TableRowWithCheckbox />
                      <TableRowWithCheckbox />
                      <TableRowWithCheckbox />
                      <TableRowWithCheckbox />
                      <TableRowWithCheckbox />
                      <TableRowWithCheckbox />
                      <TableRowWithCheckbox />
                      <TableRowWithCheckbox />
                      <TableRowWithCheckbox />
                      <TableRowWithCheckbox />
                      <TableRowWithCheckbox />
                    </table>

                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colSpan="10">
              <table width="100%">
                <tr>
                  <td width="50%" style={{ verticalAlign: 'top' }}>
                    <table width="100%" border="1" style={{ border: '1px solid #d2ddef', fontSize: '13px' }}>
                      <tr bgcolor="#2e79f8" style={{ color: '#fff' }}>
                        <th style={{ padding: '4px' }}>PRE-OP CHECKLIST</th>
                        <th style={{ padding: '4px' }}>YES</th>
                        <th style={{ padding: '4px' }}>NO</th>
                        <th style={{ padding: '4px' }}>COMMENT</th>
                      </tr>
                      <tr>
                        <td style={{ padding: '4px' }}>I.D.band/Allergy band checked</td>
                        <td style={{ padding: '4px' }}>
                          <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                        </td>
                        <td style={{ padding: '4px' }}>
                          <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                        </td>
                        <td style={{ padding: '4px' }}>
                          <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '4px' }}>Surgical/Informed Consent</td>
                        <td style={{ padding: '4px' }}>
                          <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                        </td>
                        <td style={{ padding: '4px' }}>
                          <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                        </td>
                        <td style={{ padding: '4px' }}><input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" /></td>
                      </tr>
                      <TableRowWithInput heading="H&P Completed" />
                      <TableRowWithInput heading="Labs/EKG/CXR" />
                      <TableRowWithInput heading="Surgical/Bowel Pro" />
                      <TableRowWithInput heading="Surgical/Bowel Pro" />
                      <tr>
                        <td colSpan="2" style={{ padding: '4px' }}>
                          <table>
                            <tr>
                              <td>L.M.P:</td>
                              <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                            </tr>
                          </table>
                        </td>
                        <td colSpan="2" style={{ padding: '4px' }}>
                          <table>
                            <tr>
                              <td>Urine pregnancy Test:</td>
                              <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" style={{ padding: '4px' }}>
                          <table>
                            <tr>
                              <td>Accucheck Result:</td>
                              <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                            </tr>
                          </table>
                        </td>
                        <td colSpan="2" style={{ padding: '4px' }}>
                          <table>
                            <tr>
                              <td>Time:</td>
                              <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td width="50%" style={{ verticalAlign: 'top' }}>
                    <table width="100%" border="1" style={{ border: '1px solid #d2ddef', fontSize: '13px' }}>
                      <tr bgcolor="#2e79f8" style={{ color: '#fff' }}>
                        <th style={{ padding: '4px' }}>CURRENT MEDICATION</th>
                        <th style={{ padding: '4px' }}>LAST TAKEN</th>
                      </tr>
                      <tr>

                        <td style={{ padding: '4px' }}>
                          <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                        </td>
                        <td style={{ padding: '4px' }}>
                          <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                        </td>
                      </tr>
                      <tr>

                        <td style={{ padding: '4px' }}>
                          <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                        </td>
                        <td style={{ padding: '4px' }}><input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" /></td>
                      </tr>
                      <tr>
                        <td style={{ padding: '4px' }}><input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" /></td>
                        <td style={{ padding: '4px' }}><input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" /></td>
                      </tr>
                      <tr>
                        <td style={{ padding: '4px' }}><input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" /></td>
                        <td style={{ padding: '4px' }}><input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" /></td>
                      </tr>
                      <tr>
                        <td style={{ padding: '4px' }}><input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" /></td>
                        <td style={{ padding: '4px' }}><input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" /></td>
                      </tr>
                      <tr>
                        <td style={{ padding: '4px' }}><input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" /></td>
                        <td style={{ padding: '4px' }}><input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" /></td>
                      </tr>
                      <tr>
                        <td style={{ padding: '4px' }}><input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" /></td>
                        <td style={{ padding: '4px' }}><input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" /></td>
                      </tr>
                      <tr>
                        <td style={{ padding: '4px' }}><input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" /></td>
                        <td style={{ padding: '4px' }}><input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" /></td>
                      </tr>
                      <tr>
                        <td style={{ padding: '4px' }}><input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" /></td>
                        <td style={{ padding: '4px' }}><input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" /></td>
                      </tr>
                      <tr>
                        <td style={{ padding: '4px' }}><input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" /></td>
                        <td style={{ padding: '4px' }}><input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" /></td>
                      </tr>

                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colSpan="10">
              <table width="100%">
                <tr>
                  <td width="50%" style={{ verticalAlign: 'top' }}>
                    <table width="100%" border="1" style={{ border: '1px solid #d2ddef', fontSize: '13px' }}>
                      <tr bgcolor="#2e79f8" style={{ color: '#fff' }}>
                        <th style={{ padding: '4px' }}>PRE-OP CHECKLIST</th>
                        <th style={{ padding: '4px' }}>YES</th>
                        <th style={{ padding: '4px' }}>NO</th>
                        <th style={{ padding: '4px' }}>COMMENT</th>
                      </tr>
                      <tr>
                        <td style={{ padding: '4px' }}>I.D.band/Allergy band checked</td>
                        <td style={{ padding: '4px' }}>
                          <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                        </td>
                        <td style={{ padding: '4px' }}>
                          <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                        </td>
                        <td style={{ padding: '4px' }}>
                          <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '4px' }}>Surgical/Informed Consent</td>
                        <td style={{ padding: '4px' }}>
                          <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                        </td>
                        <td style={{ padding: '4px' }}>
                          <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                        </td>
                        <td style={{ padding: '4px' }}><input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" /></td>
                      </tr>
                      <TableRowWithInput heading="H&P Completed" />
                      <TableRowWithInput heading="Labs/EKG/CXR" />
                      <TableRowWithInput heading="Surgical/Bowel Pro" />
                      <TableRowWithInput heading="Surgical/Bowel Pro" />
                      <tr>
                        <td colSpan="2" style={{ padding: '4px' }}>
                          <table>
                            <tr>
                              <td>L.M.P:</td>
                              <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                            </tr>
                          </table>
                        </td>
                        <td colSpan="2" style={{ padding: '4px' }}>
                          <table>
                            <tr>
                              <td>Urine pregnancy Test:</td>
                              <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" style={{ padding: '4px' }}>
                          <table>
                            <tr>
                              <td>Accucheck Result:</td>
                              <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                            </tr>
                          </table>
                        </td>
                        <td colSpan="2" style={{ padding: '4px' }}>
                          <table>
                            <tr>
                              <td>Time:</td>
                              <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td width="50%" style={{ verticalAlign: 'top' }}>
                    <table width="100%">
                      <tr>
                        <td style={{ padding: '4px' }}>
                          <table width="100%">
                            <tr>
                              <td colSpan="2" style={{ paddingRight: '4px' }}>
                                <b>LAST FOOD/FLUID TAKEN:</b>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ padding: '4px 0', minWidth: '74px' }}>
                                <span>When:</span>
                              </td>
                              <td style={{ paddingRight: '20px' }}>
                                <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                              </td>
                              <td style={{ paddingRight: '4px', minWidth: '84px' }}>
                                <span>M.D Notified:</span>
                              </td>
                              <td>
                                <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                              </td>
                              <td style={{ paddingRight: '4px', minWidth: '72px' }}>
                                <span>What:</span>
                              </td>
                              <td>
                                <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ padding: '4px 0', minWidth: '74px' }}>
                                <span>I.V site:</span>
                              </td>
                              <td style={{ paddingRight: '20px' }}>
                                <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                              </td>
                              <td style={{ paddingRight: '4px', minWidth: '72px' }}>
                                <span>I.V Fluid:</span>
                              </td>
                              <td>
                                <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                              </td>
                              <td style={{ paddingRight: '4px', minWidth: '72px' }}>
                                <span>cash Size:</span>
                              </td>
                              <td>
                                <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ padding: '4px 0' }}>
                                <span>Time:</span>
                              </td>
                              <td style={{ paddingRight: '20px' }}>
                                <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                              </td>
                              <td style={{ paddingRight: '4px' }}>
                                <span>Started By:</span>
                              </td>
                              <td>
                                <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                              </td>
                              <td style={{ paddingRight: '4px' }}>
                                <span>Attempts:</span>
                              </td>
                              <td>
                                <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '4px' }}>
                          <table width="100%">
                            <tr>
                              <td colSpan="2" style={{ paddingRight: '4px' }}>
                                <b>Transportation:</b>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ padding: '4px 0', minWidth: '74px' }}>
                                <span>Name:</span>
                              </td>
                              <td style={{ paddingRight: '20px' }}>
                                <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                              </td>
                              <td style={{ paddingRight: '4px', minWidth: '84px' }}>
                                <span>Refationary:</span>
                              </td>
                              <td>
                                <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                              </td>
                              <td style={{ paddingRight: '4px', minWidth: '72px' }}>
                                <span>Phone:</span>
                              </td>
                              <td>
                                <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ padding: '4px 0', minWidth: '74px' }}>
                                <span>Waiting room:</span>
                              </td>
                              <td style={{ paddingRight: '20px' }}>
                                <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                              </td>
                              <td style={{ paddingRight: '4px', minWidth: '72px' }}>
                                <span>Shuttle:</span>
                              </td>
                              <td>
                                <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                              </td>
                              <td style={{ paddingRight: '4px', minWidth: '72px' }}>
                                <span>Texi:</span>
                              </td>
                              <td>
                                <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ padding: '4px 0' }}>
                                <span>Time:</span>
                              </td>
                              <td style={{ paddingRight: '20px' }}>
                                <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                              </td>
                              <td style={{ paddingRight: '4px' }}>
                                <span>Started By:</span>
                              </td>
                              <td>
                                <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                              </td>
                              <td style={{ paddingRight: '4px' }}>
                                <span>Attempts:</span>
                              </td>
                              <td>
                                <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
);

export default Preop;
