import React, {
  useCallback, useState, useEffect, useRef, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Dropdown, Input,
} from 'antd';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import size from 'lodash/size';
import findIndex from 'lodash/findIndex';
import isString from 'lodash/isString';

import isEmpty from 'lodash/isEmpty';
import SmCheckBox from '../SmCheckbox';

import './OSODSelect.scss';
import EventWrapper from '../EventWrapper';

const defaultSelectPositions = { parent: 0, child: 0 };
const regExp = /\(([^)]+)\)/;

const getValue = (selectedValue) => {
  const value = [];
  if (isString(selectedValue) || !selectedValue) {
    return selectedValue;
  }

  Object.keys(selectedValue)
    .forEach((item) => {
      if (selectedValue[item].checked) {
        const val = [];
        if (item === '0.1-0.5' || item === '0.6-0.99') { val.push(''); } else { val.push(item); }
        if (item === 'Ptosis' && Object.values(selectedValue[item]).filter((ptosisItem) => ptosisItem).length > 1) {
          val.push(' MRD');
        }
        Object.keys(selectedValue[item]).forEach((grade) => {
          if (grade !== 'checked' && selectedValue[item][grade]) {
            if (item === 'Ptosis') {
              if (val.length > 2) {
                const expression = regExp.exec(val[2])?.[1] ?? '';
                val[2] = (`(${expression} - ${grade})`);
              } else {
                val.push(`(${grade})`);
              }
            } else if (item === 'Geographic atrophy') {
              if (grade === 'Y') {
                if (val?.length > 1) {
                  const expression = regExp.exec(val[1])?.[1] ?? '';
                  val[1] = (`(${expression} - subfoveal)`);
                } else val.push(`${val.length > 1 ? ' - ' : ' '}(subfoveal)`);
              } else if (grade === 'N') {
                if (val.length > 1) {
                  const expression = regExp.exec(val[1])?.[1] ?? '';
                  val[1] = (`(${expression} - not subfoveal)`);
                } else val.push(`${val.length > 1 ? ' - ' : ' '}(not subfoveal)`);
              }
            } else if (val.length > 1) {
              const expression = regExp.exec(val[1])?.[1] ?? '';
              val[1] = (`(${expression} - ${grade})`);
            } else {
              val.push(`(${grade})`);
            }
          }
        });
        value.push(val.join(''));
      }
    });
  return size(value) ? value.join(', ') : '';
};

const OptionsMenu = ({
  options, nameAccessor,
  valueAccessor,
  selectedPosition,
  // gradingAccessor,
  selectedValue,
  value,
  clearSelectedValue,
  setClearSelectedValue,
  setSelectedValue,
  onValueChange,
  setSearchValue,
  name,
  autoCompleteRef,
  questionKey,
}) => {
  const onFocus = useCallback((e) => {
    e?.stopPropagation();
    autoCompleteRef.current.input.focus();
  }, [autoCompleteRef]);

  const onChange = useCallback((e) => {
    e?.stopPropagation();
    const {
      target: {
        checked, name: checkBoxName, parent, info, possibleMatch,
      },
      stopFocusEvent,
    } = e;
    if (!stopFocusEvent) {
      autoCompleteRef.current.input.focus();
    }
    let selectedOptionObj = {};
    let previousSelectedValue = selectedValue;
    if (clearSelectedValue) {
      setClearSelectedValue(false);
      previousSelectedValue = {};
      setSelectedValue({});
    }
    if (checked) {
      if (parent) {
        const parentInfo = previousSelectedValue[parent] || {};
        const selected = Object.keys(parentInfo).filter((item) => parentInfo[item]);
        if (selected.length < 3) {
          if (selected.length === 2) {
            const grading = info;
            const selectedGrading = Object.keys(parentInfo).filter((item) => parentInfo[item] && item !== 'checked')[0];
            const selectedIndex = findIndex(
              grading.Items,
              (item) => item?.Value === selectedGrading,
            );
            const toBeSelectedIndex = findIndex(
              grading.Items,
              (item) => item?.Value === checkBoxName,
            );
            const flag = !!(selectedIndex - 1 === toBeSelectedIndex
              || selectedIndex + 1 === toBeSelectedIndex);
            if (flag) {
              selectedOptionObj = {
                [parent]: {
                  ...parentInfo,
                  checked: true,
                  [checkBoxName]: true,
                },
              };
            }
          } else {
            selectedOptionObj = {
              [parent]: {
                ...parentInfo,
                checked: true,
                [checkBoxName]: true,
              },
            };
          }
        }
      } else {
        selectedOptionObj = {
          [checkBoxName]: {
            checked: true,
          },
        };
      }
    } else if (parent) {
      const parentInfo = previousSelectedValue[parent] || {};
      selectedOptionObj = {
        [parent]: {
          ...parentInfo,
          [checkBoxName]: false,
        },
      };
    } else {
      selectedOptionObj = {
        [checkBoxName]: {
          checked: false,
        },
      };
    }
    if (isFunction(onValueChange) && size(selectedOptionObj)) {
      const newValue = getValue({ ...previousSelectedValue, ...selectedOptionObj });
      setSelectedValue({ ...previousSelectedValue, ...selectedOptionObj });
      if (!possibleMatch) {
        setSearchValue({ value: newValue });
      } else {
        const checkboxNode = document.getElementById(name + checkBoxName);
        if (checkboxNode) {
          const topPos = checkboxNode.offsetTop;
          document.getElementById(name + questionKey).scrollTop = topPos;
        }
        setSearchValue({ ...value, value: newValue });
      }
    }
    return true;
  }, [selectedValue, clearSelectedValue,
    onValueChange, autoCompleteRef,
    setClearSelectedValue, setSelectedValue,
    setSearchValue, name, questionKey, value]);

  const selectedOptions = useMemo(() => {
    const { value: inputValue = '' } = value || {};
    const optionsToSelect = {};
    if (inputValue?.length) {
      const multipleSelectedOptions = inputValue.split(',');
      multipleSelectedOptions.forEach((item = '') => {
        // eslint-disable-next-line prefer-const
        let [parent = '', child = ''] = item.split('(');
        if (parent?.trim() === 'Ptosis MRD') {
          parent = parent.replace(' MRD', '');
        }
        if (child.length) {
          const childrenToAppend = child?.replace(')', '')?.trim()?.split(' - ')?.map((childItem) => {
            if (childItem?.trim() === 'subfoveal') return 'Y';
            if (childItem?.trim() === 'not subfoveal') return 'N';
            return childItem?.trim();
          });
          optionsToSelect[parent?.trim()] = [...childrenToAppend];
        } else {
          optionsToSelect[parent?.trim()] = [];
        }
      });
    }
    return optionsToSelect;
  }, [value?.value]);

  const displayOptions = useCallback(() => (options.map((levelOne, index) => {
    if (levelOne?.Option?.length) {
      return (
        <div className="custom-menu-items" id={name + levelOne?.Option[0].Value}>
          <div className="item-left item">
            {levelOne?.Option?.map((levelTwo) => (
                levelTwo?.Type === 'Checkbox'
                  ? (
                    <SmCheckBox
                      key={levelTwo.Value}
                      className={classNames(
                        { 'sm-checkbox-selected-active-outline': selectedPosition.parent === index + 1 && selectedPosition.child === 0 },
                      )}
                      onChange={onChange}
                      onFocus={onFocus}
                      name={levelTwo[valueAccessor]}
                      checked={
                        selectedOptions?.[levelTwo[valueAccessor]]
                      }
                      isFormItem={false}
                    >
                      {levelTwo[nameAccessor]}
                    </SmCheckBox>
                  )
                  : (
                    <div className="label-without-checkbox">
                      {levelTwo[valueAccessor]}
                    </div>
                  )
            ))}
          </div>
          {levelOne?.Option?.map((levelTwo) => {
            if (levelTwo?.Items?.length) {
              return (
                <div className="custom-menu-items">
                  <div className="item">
                    {levelTwo?.Items?.map((levelThree, number) => (
                        levelThree?.Type === 'Checkbox'
                          ? (
                            <SmCheckBox
                              className={classNames(
                                { 'sm-checkbox-selected-active-outline': selectedPosition.parent === index + 1 && selectedPosition.child === number + 1 },
                              )}
                              onChange={onChange}
                              onFocus={onFocus}
                              parent={levelTwo[valueAccessor]}
                              key={levelThree[valueAccessor]}
                              name={levelThree[valueAccessor]}
                              checked={
                              selectedOptions?.[
                                levelTwo[valueAccessor]
                              ]?.includes(levelThree[valueAccessor])
              }
                              isFormItem={false}
                              info={levelTwo}
                            >
                              {levelThree[valueAccessor]}
                            </SmCheckBox>
                          )
                          : (
                            <div className="label-without-checkbox">
                              {levelThree[valueAccessor]}
                            </div>
                          )
                    ))}
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      );
    }
    return null;
  }) || null
  ), [name, nameAccessor,
    onChange, options,
    selectedPosition.child,
    selectedPosition.parent, selectedValue,
    valueAccessor]);

  useEffect(() => {
    const optionArray = [];
    if (!value.previousSearch
      || value.previousSearch.toLowerCase().indexOf(value.searchValue) !== 0) {
      if (value.value
        && (
          (value.value.toLowerCase() === value.searchValue)
          || (value.value.toLowerCase().indexOf(value.searchValue) !== 0)
        )) {
        options.forEach((levelOne) => {
          if (levelOne?.Option?.length) {
            levelOne.Option.forEach((levelTwo) => {
              const optionName = levelTwo[nameAccessor]?.toLowerCase();
              if (value.searchValue
                && (optionName.indexOf(value.searchValue.toLowerCase()) === 0)) {
                optionArray.push(levelTwo);
              }
            });
          }
        });
        if (value.searchValue && optionArray?.length === 1) {
          if (!selectedValue || isEmpty(selectedValue)) {
            onChange({
              target: {
                name: optionArray?.[0]?.[valueAccessor],
                checked: true,
                possibleMatch: true,
              },
              stopFocusEvent: true,
              stopPropagation: () => {},
            });
          }
          if (value?.value?.toLowerCase() !== optionArray?.[0]?.[nameAccessor]?.toLowerCase()) {
            setSearchValue({ ...value, value: optionArray?.[0]?.[nameAccessor] });
          }
        }
      }
    }
  }, [value]);

  return (
    <div
      className="checkbox-menu test"
      id={name + questionKey}
      role="presentation"
      style={{ maxHeight: 176, overflow: 'hidden', overflowY: 'auto' }}
    >
      {displayOptions()}
    </div>
  );
};

const OSODSelect = ({
  label,
  required,
  labelSpan,
  inputSpan,
  className,
  nameAccessor,
  valueAccessor,
  gradingAccessor,
  options,
  // placeholder,
  initialValue: initialAnswer,
  onValueChange,
  questionKey,
  name,
  scrollId,
}) => {
  const selectedPositionRef = useRef(defaultSelectPositions);
  const autoCompleteRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState({});
  const [isSearchEnabled, setSearchEnabled] = useState(true);
  const [clearSelectedValue, setClearSelectedValue] = useState(true);
  const [value, setValue] = useState({});
  const [initialValue, setInitialValue] = useState(initialAnswer);
  const [selectedPosition, setSelectedPosition] = useState(defaultSelectPositions);
  const isVisible = useRef(false);

  useEffect(() => {
    setSearchEnabled(!(initialValue && initialValue.answers));
    setSearchEnabled(!(initialValue && initialValue.answers));
    setValue({
      value: initialValue?.answers,
      // searchValue: initialValue?.answers,
      // previousSearch: initialValue?.answers,
    });
  }, [initialValue]);

  useEffect(() => {
    if (value.searchValue
      && value.value
      && (value.searchValue !== value.value)
      && (value.value.toLowerCase().indexOf(value.searchValue) === 0)) {
      autoCompleteRef.current.input.setSelectionRange(value.searchValue.length, value.value.length);
    }
  }, [value]);

  useEffect(() => {
    setInitialValue(initialAnswer);
  }, [initialAnswer]);

  const updateSelectedPosition = useCallback((data = { parent: 0, child: 0 }) => {
    selectedPositionRef.current = { ...data };
    setSelectedPosition({ ...selectedPosition, ...data });
  }, [selectedPosition]);

  const updateSelectedValue = useCallback(() => {
    const selectedElement = document.querySelector(`#${name + questionKey} .sm-checkbox-selected-active-outline`);
    if (selectedElement) {
      const inputElement = selectedElement.querySelector('input');
      inputElement.click();
    }
  }, [name, questionKey]);

  const handleArrowKeyEvents = useCallback((e) => {
    const preventEvents = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Enter'];
    if (preventEvents.indexOf(e.key) >= 0) {
      const position = selectedPositionRef.current || { ...defaultSelectPositions };
      const { Items: grading = [] } = options[Math.max(position.parent - 1, 0)]?.Option?.[0];
      switch (e.key) {
        case preventEvents[0]:
          position.parent = Math.max(position.parent - 1, 0);
          position.child = 0;
          break;
        case preventEvents[1]:
          position.parent = Math.min(position.parent + 1, options.length);
          position.child = 0;
          break;
        case preventEvents[2]: position.child = Math.max(position.child - 1, 0);
          break;
        case preventEvents[3]:
          position.child = Math.min(position.child + 1, grading.length);
          break;
        default: updateSelectedValue();
          return;
      }
      updateSelectedPosition(position);
    }
  }, [options, updateSelectedPosition, updateSelectedValue]);

  const handleEventListeners = useCallback((assign) => {
    isVisible.current = assign;
    if (!assign) {
      onValueChange(name, { answers: value.value });
      updateSelectedPosition();
      setClearSelectedValue(true);
      setSelectedValue({});
    }
  }, [name, onValueChange, value, updateSelectedPosition]);

  const handleSearchValue = useCallback((e) => {
    const inputText = e.target.value && e.target.value.toLowerCase();
    if (inputText && value.value
      && (inputText !== value.value.toLowerCase())
      && (value.value.toLowerCase().indexOf(inputText) === 0)
      && value.searchValue
      && value.searchValue.indexOf(inputText) !== 0) {
      setValue({
        ...value,
        searchValue: inputText,
        previousSearch: value.searchValue,
      });
    } else {
      if (value.searchValue && value.searchValue.toLowerCase().indexOf(inputText) !== 0) {
        setSelectedValue({});
      }
      let previousSearch = value.searchValue;
      if (!previousSearch
        && value.value
        && (value.value.toLowerCase() !== inputText)
        && (value.value.toLowerCase().indexOf(inputText) === 0)) {
        previousSearch = inputText;
      }
      setValue({
        value: e.target.value,
        searchValue: inputText,
        previousSearch,
      });
    }
    if (!inputText) {
      setInitialValue({ answers: inputText });
      setSelectedValue({});
    }
  }, [value]);

  return (
    <div
      onKeyDown={handleArrowKeyEvents}
      role="presentation"
      className={classNames('custom-checkbox-select', className)}
    >
      <Row>
        <Col span={labelSpan || 10}>
          <div className="ant-form-item-label ant-form-item-label-left">
            <EventWrapper
              type="label"
              className={classNames('ant-form-item-no-colon', required && 'ant-form-item-required')}
              title={label}
            >
              {label}
            </EventWrapper>
          </div>
        </Col>
        <Col span={inputSpan || 14}>
          <div
            style={{
              position: 'relative',
            }}
          >
            <Dropdown
              getPopupContainer={() => (
                scrollId
                  ? document.getElementById(scrollId)
                  : document.body
              )}
              overlay={(
                <OptionsMenu
                  options={options}
                  nameAccessor={nameAccessor}
                  valueAccessor={valueAccessor}
                  selectedPosition={selectedPosition}
                  gradingAccessor={gradingAccessor}
                  value={value}
                  isSearchEnabled={isSearchEnabled}
                  clearSelectedValue={clearSelectedValue}
                  setClearSelectedValue={setClearSelectedValue}
                  setSelectedValue={setSelectedValue}
                  setSearchValue={setValue}
                  onValueChange={onValueChange}
                  name={name}
                  selectedValue={selectedValue}
                  autoCompleteRef={autoCompleteRef}
                  initialValue={initialValue}
                  questionKey={questionKey}
                  scrollId={scrollId}

                />
              )}
              trigger={['focus']}
              placement="bottomLeft"
              onVisibleChange={handleEventListeners}
            >
              <div className="custom-text-input">
                <Input
                  key={name}
                  value={value.value || ''}
                  title={value.value || ''}
                  showArrow={false}
                  removeIcon={null}
                  dropdownMatchSelectWidth={false}
                  open={false}
                  onChange={handleSearchValue}
                  ref={autoCompleteRef}
                  // onBlur={handleFocusOut}
                  // onFocus={() => setTimeout(() => (
                  //   !isVisible.current
                  //     && autoCompleteRef.current?.input.click()), 100)}
                />
              </div>
            </Dropdown>
          </div>
        </Col>
      </Row>
    </div>
  );
};

OSODSelect.defaultProps = {
  nameAccessor: 'name',
  valueAccessor: 'value',
  gradingAccessor: 'grading',
  options: [],
  rules: [],
  label: '',
  required: false,
  labelSpan: 10,
  inputSpan: 14,
  className: '',
  placeholder: '',
  initialValue: '',
  onValueChange: () => { /* This is intentional */ },
  name: '',
};

OSODSelect.propTypes = {
  nameAccessor: PropTypes.string,
  valueAccessor: PropTypes.string,
  gradingAccessor: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  rules: PropTypes.instanceOf(Array),
  label: PropTypes.string,
  required: PropTypes.bool,
  labelSpan: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  inputSpan: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onValueChange: PropTypes.func,
  name: PropTypes.string,
};

export default OSODSelect;
