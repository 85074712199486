const encode = (queryObj, nesting = '') => {
  if (!queryObj) {
    return '';
  }
  const pairs = Object.entries(queryObj).map(([key, val]) => {
    if (Array.isArray(val)) {
      return val.map((subVal) => [`${nesting + key}[]`, subVal].map(window.escape).join('=')).join('&');
    }
    if (typeof val === 'object') {
      return encode(val, `${nesting}${key}.`);
    }
    return [nesting + key, val].map(window.escape).join('=');
  });
  return pairs.join('&');
};

export default encode;
