import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import useRedirect from './useRedirect';
import { setNewPath } from '../store/actions/navigation';
import { setCurrentTab } from '../store/actions/genericAction';
import { store } from '../store';
import getTabId from '../components/SavedNavigationBar/tabIdGenerator';
/**
 * @class Components/SavedNavigationBar/Link
 */
const useTabLink = ({
  to, id, params, finalRedirectPath,
}) => {
  const saveNewLink = (linkDetails) => store.dispatch(setNewPath(linkDetails));
  const setTab = (tabId) => store.dispatch(setCurrentTab(tabId));
  const { tabs } = store.getState();
  const {
    params: routerParams, push, replace, path, generatePath,
  } = useRedirect();
  const navigate = useCallback((navigationParams) => {
    const toPath = navigationParams.to || to;

    const dummyTabId = 'tabId';
    let generatedPath = generatePath(toPath, {
      ...routerParams, ...params, ...navigationParams, tabId: dummyTabId,
    });
    const tabId = getTabId(generatedPath);
    generatedPath = generatedPath.replace('tabId', tabId);
    if (finalRedirectPath) {
      generatedPath = finalRedirectPath(generatedPath);
    }
    setTab(tabId);
    saveNewLink({
      id: id || toPath,
      tabId,
      path: generatedPath,
      params: { ...params, ...routerParams, ...navigationParams },
      tabName: tabs.get('tabModuleName'),
      parentInfo: { path: generatePath(path, routerParams), id: path, params: routerParams },
      previousTabId: routerParams.tabId,
    });
    if (toPath.indexOf(path) === 0) {
      replace(generatedPath);
    } else {
      push(generatedPath);
    }
  }, [finalRedirectPath, generatePath, id, params, path, push, replace, routerParams, tabs, to]);
  return {
    navigate,
  };
};

useTabLink.propTypes = {
  ...RouterLink.propTypes,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  root: PropTypes.bool,
};

useTabLink.defaultProps = {
  ...RouterLink.defaultProps,
  component: 'span',
  root: false,
};

export default useTabLink;
