import React, { useCallback, useMemo } from 'react';
import { Form as AntdForm } from 'antd';
import PropTypes from 'prop-types';

import Button from '../../../../../components/Button';
import { CPTICDSynonymStatus } from '../../../../../lib/constants';
import Modals from '../../../../../components/Modal';
import Input from '../../../../../components/Form/Input';
import Form from '../../../../../components/Form';

import AddSynonymForm from '../../../../../wiredComponents/AddSynonym/AddSynonymForm';

import useRedirect from '../../../../../hooks/useRedirect';

const AddSynonym = ({
  labels,
  isModalVisible,
  setModalVisibility,
  selectedCode,
  isIcdSynonym,
  showIcdLookup,
  showCptLookup,
  updateSynonym,
}) => {
  const [form] = AntdForm.useForm();
  const { params: { providerId } } = useRedirect();

  const toggleAddModal = useCallback((modalState, update) => {
    setModalVisibility(!isModalVisible, update);
  }, [isModalVisible, setModalVisibility]);

  const onComplete = useCallback(({ icdCptSynonymId }) => {
    const user = JSON.parse(localStorage.getDecryptedData('user'));
    if (user?.roleName?.toLowerCase() === 'admin') {
      updateSynonym({ status: CPTICDSynonymStatus?.approved, icdCptSynonymId });
    } else {
      updateSynonym({ status: CPTICDSynonymStatus?.pending, icdCptSynonymId });
    }
  }, [updateSynonym]);

  const extraDataProps = useMemo(() => {
    const data = { providerId };
    if (isIcdSynonym) {
      if (!showIcdLookup) data.icdId = selectedCode?.icdId;
    } else if (!showCptLookup) data.cptId = selectedCode?.cptId;
    return data;
  }, [isIcdSynonym, providerId, selectedCode, showCptLookup, showIcdLookup]);

  return (
    <Modals
      visible={isModalVisible}
      toggleModal={toggleAddModal}
      width={800}
      destroyOnClose
      className="schedular-patient-modal"
      footer={[
        <div className="group-btns" key="footer">
          <Button
            className="btn min-wt-86"
            onClick={toggleAddModal}
          >
            {labels.get('buttons.cancel')}
          </Button>
          <Button
            className="btn btn-success min-wt-86 inline"
            onClick={form.submit}
          >
            {selectedCode?.isEdit ? labels.get('buttons.update') : labels.get('buttons.save')}
          </Button>
        </div>,
      ]}
    >
      <div className="addition-header">
        <div className="lookup sprite-img-before">
          <p>{selectedCode?.isEdit ? labels.get('titles.editSynonym') : labels.get('titles.addSynonym')}</p>
        </div>
      </div>
      <div className="modal-content-weapper shadow-wrap">
        <div className="modal-from-feilds">
          <Form.Column>
            {selectedCode?.code && (
              <Input
                name={null}
                label="Code"
                value={selectedCode?.code}
                labelSpan="8"
                inputSpan="16"
                className="non-editable"
                disabled
              />
            )}
            {selectedCode?.description && (
              <Input
                name={null}
                label="Description"
                value={selectedCode?.description}
                inputSpan="16"
                labelSpan="8"
                className="non-editable"
                disabled
              />
            )}
          </Form.Column>
          <AddSynonymForm
            labels={labels}
            form={form}
            toggleModal={toggleAddModal}
            onComplete={onComplete}
            selectedCode={selectedCode}
            showIcdLookup={showIcdLookup}
            showCptLookup={showCptLookup}
            extraDataProps={extraDataProps}
            includeInActive={false}
          />
        </div>
      </div>
    </Modals>
  );
};

AddSynonym.defaultProps = {
  selectedCode: {},
};

AddSynonym.propTypes = {
  selectedCode: PropTypes.instanceOf(Object),
};

export default AddSynonym;
