import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import classNames from 'classnames';

import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

import { apiUrls } from '../../../../../../api/constants';
import { enums, listId, UiRoutes } from '../../../../../../lib/constants';

import withQuery from '../../../../../../hoc/withQuery/withQuery';

import columns from './columns';
import useRedirect from '../../../../../../hooks/useRedirect';
import withReduxManager from '../../../../../../hoc/withReduxManager';
import useTabLink from '../../../../../../hooks/useTabLink';
import { getEnumMaster, getEnumOptions } from '../../../../../../store/selectors';
import { getEnumMasterData } from '../../../../../../store/actions/enum';
import Button from '../../../../../../components/Button';
import Search from '../../../../../../components/Search';

import FilterComponents from '../../../../../../components/FilterComponents';
import FilterManager from '../../../../../../components/FilterManager';

import TableWrapper from './TableWrapper';

const assignToOptions = [
  { value: 'all', name: 'Assigned To All' },
  { value: 'me', name: 'Assigned To Me' },
  { value: 'other', name: 'Unassigned' },
];

const initialSort = [{ id: 'batchNumber', desc: true }];

const FilterCollection = FilterComponents([
  {
    type: 'select',
    filterProps: {
      placeholder: 'Assigned To',
      name: 'AssignedTo',
      id: 'Se',
      options: assignToOptions,
      selectProps: {
        style: {
          minWidth: 150,
          marginLeft: 10,
        },
      },
      dataTestId: 'additionalFilter',
    },
  },
  {
    type: 'search',
    filterProps: {
      placeholder: 'Batch Number',
      name: 'BatchNumber',
      id: 'batch_number_search',
    },
  },
  {
    type: 'search',
    filterProps: {
      placeholder: 'Payer',
      name: 'PayerName',
      id: 'payer_search',
    },
  },
]);

const CustomTable = withReduxManager({
  listId: listId.REMITTANCE_WORK_LIST_SUSPENSE_DETAIL,
})(withQuery({
  url: apiUrls.GET_REMITTANCE_WORK_LIST_REASON,
  listId: listId.REMITTANCE_WORK_LIST_SUSPENSE_DETAIL,
  accessor: (data) => ({ result: data }),
})());

const SuspenseDetailTable = ({
  labels, Queue, RemittanceReasonId, businessEntityId,
  selectedRow, searchText, toggleAssignToModal, ...props
}) => {
  const { params } = useRedirect();
  const dispatch = useDispatch();

  const enumMaster = useSelector((state) => getEnumMaster(state));
  const enumId = get(enumMaster, `${enums.WorkListStatus}.enumId`);
  const workListStatusOptions = useSelector((state) => getEnumOptions(state)?.[enumId]?.data || []);

  const [searchAmount, setSearchAmount] = useState();

  useEffect(() => {
    if (enumId && !workListStatusOptions.length) {
      dispatch(getEnumMasterData(enumId));
    }
  }, [enumId, workListStatusOptions.length]);

  const WorkListStatusId = useMemo(() => workListStatusOptions?.find(
    ({ masterCode }) => masterCode === Queue)?.masterId, [Queue, workListStatusOptions]);

  const { navigate: navigateToInsurancePayment } = useTabLink({
    to: UiRoutes.claimsRemittanceEditInsurance,
    id: UiRoutes.claimsRemittanceEditInsurance,
    params: { ...params, insurancePaymentPage: 'editPaymentDetails' },
  });
  const { navigate: navigateToPatientPayment } = useTabLink({
    to: UiRoutes.claimsRemittanceEditPatient,
    id: UiRoutes.claimsRemittanceEditPatient,
    params: { ...params, patientPaymentPage: 'editPaymentDetails' },
  });

  const onRowClick = useCallback(({
    original: {
      batchId, remittancePaymentId, remittancePaymentType, batchNumber,
    },
  }) => {
    if (remittancePaymentType?.toLowerCase() === 'insurancepayment') {
      navigateToInsurancePayment({
        remittanceId: batchId, tab: 'remittance', data: { name: `Remittance: ${batchNumber}` }, remittancePaymentId,
      });
    }
    if (remittancePaymentType?.toLowerCase() === 'patientpayment') {
      navigateToPatientPayment({
        remittanceId: batchId, tab: 'remittance', data: { name: `Remittance: ${batchNumber}` }, remittancePaymentId,
      });
    }
  }, [navigateToInsurancePayment, navigateToPatientPayment]);

  const debounced = useMemo(() => debounce(setSearchAmount, 1500), [setSearchAmount]);

  const handleSearch = useCallback(({ target: { value } }) => debounced(value), [debounced]);

  return (
    <CustomTable
      columns={columns(labels)}
      filters={{
        WorkListStatusId, RemittanceReasonId, businessEntityId, amount: searchAmount, searchText,
      }}
      onRowClick={onRowClick}
      noDataText="Data not found"
      showRowSelection
      initialSort={initialSort}
      checkedRowIds={selectedRow}
      {...props}
    >
      {({
        Component, reFetch, initialFilters, onFiltersChange,
      }) => (
        <FilterManager initialFilters={initialFilters} onChange={onFiltersChange}>
          {({ onFilterChange, filters }) => (
            <>
              <div className="table-filters" data-testid="contract-list-filters">
                <div className="flex">
                  <FilterCollection onFilterChange={onFilterChange} filters={filters} />
                  <div className="filter-ant-search">
                    <div className="ant-search">
                      <Search placeholder="Amount" name="amount" onChange={handleSearch} />
                    </div>
                  </div>
                </div>
                <div className="group-btns mr-bt-12">
                  <Button
                    className={classNames('btn btn-success sm-btn', {
                      'cursor-not-allowed': !selectedRow?.length,
                    })}
                    disabled={!selectedRow?.length}
                    id="assign_item"
                    onClick={toggleAssignToModal}
                  >
                    {labels.get('buttons.assignItem')}
                  </Button>
                </div>
              </div>
              <TableWrapper Component={Component} reFetch={reFetch} />
            </>
          )}
        </FilterManager>
      )}
    </CustomTable>
  );
};

export default SuspenseDetailTable;
