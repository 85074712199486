import React, {
  useState, useEffect, useMemo, useRef, useCallback,
} from 'react';
import moment from 'moment';
import inRange from 'lodash/inRange';

import LocationCell from './LocationCell';
import EventContainer from './EventContainer';
import EventDragLayer from './EventDragLayer';
import TimeSlotContainer from './TimeSlotContainer';
import TimeSlotCell from './TimeSlotCell';
import EventCollections from './EventCollections';

import AddAppointmentDialog from '../Dialogs/AddAppointmentDialog';
import CancelAppointmentDialog from '../Dialogs/CancelAppointmentDialog';

import TimeCell from '../../../../components/Cells/TimeCell';
import Notification from '../../../../components/Notification';

import ErrorMessages from '../../../../lib/errorMessages';
import dateFormatter from '../../../../lib/dateFormatter';
import {
  schedulerDataDateFormat, dateFormatStartsWithYear, timeFormat, dateFormatStartsWithYearSeconds,
} from '../../../../lib/constants';

function getTodayMoment(selectedDate, current) {
  const date = dateFormatter(selectedDate, dateFormatStartsWithYear);
  const time = dateFormatter(current, timeFormat);
  return moment(`${date} ${time}`, dateFormatStartsWithYearSeconds);
}

const getEventsByLocation = (appointments = [], location = '') => (
  !location ? appointments : appointments.filter(
    (appointment) => appointment.location && appointment.location.id === location.id,
  )
);

const DayView = ({
  index,
  viewType,
  selectedDate,
  selectedDoctor,
  timeRange,
  schedulerData = {},
  slotDuration,
  numberOfRows,
  minSlotTime,
  showDoctor,
  location,
  onUpdatePosition,
  onUpdateDetail,
  onRemove,
  visitType,
  reFetchData,
  testType,
  paramsPatient,
  checkAppointmentRules,
  form,
}) => {
  const { appointments = [], schedule = [], freeSlots = [] } = schedulerData;
  const timeContainerRef = useRef(null);
  const [fixHeaderWidth, setfixHeaderWidth] = useState(0);
  const fixHeaderWidthRef = useRef(null);
  const setResizeHeaderWidth = fixHeaderWidthRef.current;

  // filters appointments for the date assigned to the day view in selectedDate
  const filteredAppointments = useMemo(() => appointments.filter((
    appointment,
  ) => moment(
    appointment.startTime,
  ).isSame(selectedDate, 'day')), [appointments, selectedDate]);

  // events are the appointments filtered by the location selected and grouped into collections
  const events = useMemo(() => {
    const collections = [];
    const eventsAtLocation = getEventsByLocation(filteredAppointments, location);
    eventsAtLocation.sort((prev, next) => (
      new Date(prev?.appointmentStartTime).getTime()
      - new Date(next?.appointmentStartTime).getTime()
    ));
    eventsAtLocation.forEach((appointment) => {
      const { slotPositions: { startYAt: Astart, endYAt: Aend } } = appointment;
      let preferredIndex = collections.findIndex(({ startYAt: Cstart, endYAt: Cend }) => {
        // A part of or whole Appointment is inside Collection
        if (inRange(Astart, Cstart, Cend) || inRange(Aend, Cstart + 1, Cend + 1)) return true;
        // Whole Collection is inside Appointment
        if (Cstart > Astart && Cend < Aend) return true;
        return false;
      });
      if (preferredIndex < 0) {
        preferredIndex = collections.length;
        collections.push({
          key: 0, startYAt: Infinity, endYAt: 0, appointments: [],
        });
      }
      const collection = collections[preferredIndex];
      collection.startYAt = Math.min(collection.startYAt, Astart);
      collection.endYAt = Math.max(collection.endYAt, Aend);
      collection.key = `${collection.startYAt}-${collection.endYAt}`;
      collection.appointments.push(appointment);
      collections[preferredIndex] = collection;
    });
    return collections;
  }, [filteredAppointments, location]);

  const workupTime = useMemo(() => {
    const { workupTime: visitWorkupTime = 0 } = visitType || {};
    return (testType || []).reduce(
      (result, test) => (result + (test?.workupTime || 0)), visitWorkupTime,
    );
  }, [visitType, testType]);

  const [addAppointmentData, setAddAppointmentData] = useState(false);
  const [timeContainerDimensions, setTimeContainerDimensions] = useState(null);
  const [isAppointmentModalVisible, toggleAppointmentModalVisible] = useState(false);
  const [isCancelModalVisible, toggleCancelModalVisible] = useState(false);
  const [appointmentMode, setAppointmentMode] = useState('add');
  const [draggingEvent, setDraggingEvent] = useState(false);
  const [cancelAppointmentInfo, setCancelAppointmentInfo] = useState(null);
  const [isContextMenuActive, setContextMenuActive] = useState(false);

  // calculates and saves the view dimensions for further use
  useEffect(() => {
    const newDimensions = timeContainerRef && timeContainerRef.current ? {
      ref: timeContainerRef,
      height: timeContainerRef.current.clientHeight,
      width: timeContainerRef.current.clientWidth,
      cellHeight: 40,
      cellWidth: timeContainerRef.current.clientWidth / slotDuration,
    } : null;
    setTimeContainerDimensions(newDimensions);
  }, [timeContainerRef, numberOfRows, slotDuration]);

  const checkAppointmentOverlaps = useCallback((from, to, itemId) => {
    const newStartTimeMoment = moment(from).second(0).millisecond(0);
    const newEndTimeMoment = moment(to).second(0).millisecond(0);
    return events.every(({ id, startTime, endTime }) => {
      // dont perform any check with self
      if (id === itemId) return true;
      const startTimeMoment = moment(startTime).second(0).millisecond(0);
      const endTimeMoment = moment(endTime).second(0).millisecond(0);
      // item's start time is inside any appointment
      if (newStartTimeMoment.isSameOrAfter(startTimeMoment)
      && newStartTimeMoment.isBefore(endTimeMoment)) {
        return false;
      }
      // item's end time is inside any appointment
      if (newEndTimeMoment.isSameOrBefore(endTimeMoment)
      && newEndTimeMoment.isAfter(startTimeMoment)) {
        return false;
      }
      // whole item is inside any appointment
      if (newStartTimeMoment.isBefore(startTimeMoment)
      && newEndTimeMoment.isAfter(endTimeMoment)) {
        return false;
      }
      return true;
    });
  }, [events]);

  // this is the onDrop method which is fired when any appointment is dropped at any location
  // in the scheduler grid.
  // this method also validates the drop location and performs suitable actions
  const onDrop = useCallback(
    (item, prevItem) => {
      // 'item' object is the updated object for the selected appointment with all updated data
      // according to the drop position
      const {
        id: itemId, startTime: newStartTime, endTime: newEndTime, location: newLocation,
        totalWorkupTime,
      } = item;
      const newStartTimeMoment = moment(newStartTime).second(0).millisecond(0);
      const newEndTimeMoment = moment(newEndTime).second(0).millisecond(0);
      const patientReachTime = moment(newStartTime).subtract(totalWorkupTime, 'minutes');
      if (newStartTimeMoment.isBefore(moment())) return;
      if (moment(patientReachTime).isBefore(getTodayMoment(selectedDate, timeRange?.from))) {
        const timeString = newStartTimeMoment.format('HH:mm');
        Notification({
          message: `${ErrorMessages.SCHEDULER_SLOT_NOT_FREE_TO_MOVE}${timeString}. ${ErrorMessages.SCHEDULER_ARRIVAL_TIME_BEFORE_START_TIME}`,
        });
        return;
      }

      let isDropPossible = checkAppointmentOverlaps(newStartTimeMoment, newEndTimeMoment, itemId);
      // Check if the dropped appointment lies at the selected location, if selected
      if (isDropPossible && location) {
        isDropPossible = newLocation ? newLocation.id === location.id : false;
      } else if (!newLocation) {
        isDropPossible = false;
      }

      if (isDropPossible) {
        const newItem = {
          id: item.appointmentId,
          date: item.date,
          startTime: newStartTimeMoment.format(schedulerDataDateFormat),
          appointmentStartTime: moment(item.startTime).utc('0').format(),
          patientReachTime,
          appointmentStartDateTime: {
            year: newStartTimeMoment.get('year'),
            month: newStartTimeMoment.get('month') + 1,
            day: newStartTimeMoment.get('date'),
            hour: newStartTimeMoment.get('hours'),
            minute: newStartTimeMoment.get('minutes'),
          },
          endTime: newEndTimeMoment.format(schedulerDataDateFormat),
          appointmentEndTime: item.endTime,
          appointmentEndDateTime: {
            year: newEndTimeMoment.get('year'),
            month: newEndTimeMoment.get('month') + 1,
            day: newEndTimeMoment.get('date'),
            hour: newEndTimeMoment.get('hours'),
            minute: newEndTimeMoment.get('minutes'),
          },
          examType: item.examType,
          visitType: item.visitType,
          patient: item.patient,
          location: item.location,
          doctor: item.doctor,
          insuranceId: item.insuranceId,
          insurancedetailId: item.insurancedetailId,
          totalDuration: item?.totalDoctorTime,
        };
        if (checkAppointmentRules({
          itemId,
          time: newStartTimeMoment,
          visit: item.visitType,
          doctor: item.doctor,
          callback: viewType === 'days'
            ? () => onUpdatePosition(index, newItem, prevItem)
            : () => onUpdateDetail(newItem, prevItem),
        })) {
          // these update methods will be changed to API calls
          // if performed on different doctors (same or cross doctors)
          if (viewType === 'days') onUpdatePosition(index, newItem, prevItem);
          // if performed on different days (same or cross days)
          else onUpdateDetail(newItem, prevItem);
        }
      }
    }, [index, location, onUpdateDetail, onUpdatePosition, viewType,
      checkAppointmentOverlaps, checkAppointmentRules, timeRange, selectedDate],
  );

  const handleAddAppointmentOpen = (data, mode = 'add') => {
    if (mode === 'add' && !visitType) {
      form.getFieldInstance('visitType')?.props?.setVisitTypeValidationStatus('error');
      form.setFields([{
        name: 'visitType',
        value: undefined,
        touched: true,
        errors: ['Visit Type is required'],
      }]);
      Notification({ message: ErrorMessages.VISIT_TYPE_REQUIRED, success: false });
      return;
    }
    const { time } = data;
    const { doctorTime, visitName } = visitType || {};
    setAddAppointmentData(data);
    if (appointmentMode !== mode) {
      setAppointmentMode(mode);
    }
    if (mode === 'edit') {
      toggleAppointmentModalVisible(!isAppointmentModalVisible);
      return;
    }
    if (moment(time).isBefore(moment())) return;
    if (checkAppointmentOverlaps(moment(time), moment(time).add(doctorTime, 'minutes'))) {
      const isValid = checkAppointmentRules({
        ...data,
        visit: visitType,
        callback: () => toggleAppointmentModalVisible(!isAppointmentModalVisible),
      });
      if (isValid) toggleAppointmentModalVisible(!isAppointmentModalVisible);
    } else {
      Notification({ message: `${ErrorMessages.NOT_ENOUGH_SPACE_FOR_NEW_APPOINTMENT} ${visitName}`, success: false });
    }
  };
  useEffect(() => {
    let observer;
    if (setResizeHeaderWidth) {
      observer = new ResizeObserver(() => {
        setfixHeaderWidth(fixHeaderWidthRef?.current?.clientWidth);
      });

      observer.observe(setResizeHeaderWidth);
    }
  }, [setResizeHeaderWidth]);

  useEffect(() => {
    if (appointmentMode === 'edit' && addAppointmentData
      && addAppointmentData.time
      && moment().isAfter(addAppointmentData.time, 'day')) {
      Notification({ message: ErrorMessages.PAST_APPOINTMENTS_CANNOT_BE_EDITED, success: false });
      if (isAppointmentModalVisible) {
        toggleAppointmentModalVisible(!isAppointmentModalVisible);
      }
    }
  }, [addAppointmentData]);

  useEffect(() => {
    setfixHeaderWidth(fixHeaderWidthRef.current.clientWidth);
  });

  const handleCancelModal = useCallback((info = null) => (flag) => {
    toggleCancelModalVisible(flag);
    setCancelAppointmentInfo(info);
  }, []);

  const headerData = useMemo(() => (showDoctor && selectedDoctor?.name
    ? <div className="header-name-component">{selectedDoctor.name}</div>
    : <div className="header-date-component"><TimeCell value={selectedDate} format="MMM DD, ddd" /></div>), [selectedDate,
    selectedDoctor,
    showDoctor]);

  return (
    <div className="grid-container">
      <div className="affixed-header1" ref={fixHeaderWidthRef}>
        {viewType === 'days' ? (
          <div className="header-container" style={{ width: fixHeaderWidth }}>
            {headerData}
          </div>
        ) : (
          <div className="header-container" style={{ width: fixHeaderWidth }}>
            <div className="header-name-component"><TimeCell value={selectedDate} format="MMM DD, ddd" /></div>
          </div>
        )}
      </div>
      <div className="grid-day-view-container" style={{ gridTemplateColumns: location ? '0 auto' : '17px auto' }}>
        <div className="grid-location-container">
          {schedule.map(({
            location: { name = '' }, slotPositions: { startYAt = 0, endYAt = 0 }, hours, maxApptAllowedPerProvider: maxCount = 0,
          }, scheduleIndex) => (
            <LocationCell
              location={name}
              startYAt={startYAt}
              endYAt={endYAt}
              key={scheduleIndex}
              hours={hours}
              maxCount={maxCount}
            />
          ))}
        </div>
        <TimeSlotContainer
          ref={timeContainerRef}
          timeRange={timeRange}
          selectedDate={selectedDate}
          numberOfRows={numberOfRows}
          showNeedle={!showDoctor}
        >
          <TimeSlotCell
            numberOfRows={numberOfRows}
            slotDuration={slotDuration}
            timeRange={timeRange}
            selectedDate={selectedDate}
            minSlotTime={minSlotTime}
            location={location}
            locations={schedule}
            freeSlots={freeSlots}
          />
          {!!timeContainerDimensions && (
          <EventContainer
            onDrop={onDrop}
            viewDimentions={timeContainerDimensions}
            onClick={handleAddAppointmentOpen}
            slotDuration={slotDuration}
            selectedDate={selectedDate}
            selectedDoctor={selectedDoctor}
            timeRange={timeRange}
            schedule={schedule}
            freeSlots={freeSlots}
            minSlotTime={minSlotTime}
            workupTime={workupTime}
            getTodayMoment={getTodayMoment}
            isContextMenuActive={isContextMenuActive}
          >
            {(addAppointmentFromEvent) => (
              <>
                <EventDragLayer
                  viewDimentions={timeContainerDimensions}
                  draggingEvent={draggingEvent}
                />
                {events && events.map((event) => (
                  <EventCollections
                    key={event.key}
                    data={event}
                    onDragBegin={setDraggingEvent}
                    onDragEnd={setDraggingEvent}
                    onClick={addAppointmentFromEvent}
                    onRemove={onRemove}
                    onEdit={handleAddAppointmentOpen}
                    slotDuration={slotDuration}
                    handleCancelModal={handleCancelModal}
                    isContextMenuActive={isContextMenuActive}
                    onContextMenuActive={setContextMenuActive}
                    reFetchData={reFetchData}
                  />
                ))}
              </>
            )}
          </EventContainer>
          )}
        </TimeSlotContainer>
      </div>
      <AddAppointmentDialog
        isVisible={isAppointmentModalVisible}
        toggle={toggleAppointmentModalVisible}
        mode={appointmentMode}
        data={addAppointmentData}
        minSlotTime={minSlotTime}
        visitType={visitType}
        reFetchData={reFetchData}
        testType={testType}
        paramsPatient={paramsPatient}
      />
      <CancelAppointmentDialog
        isVisible={isCancelModalVisible}
        toggleModal={handleCancelModal()}
        info={cancelAppointmentInfo}
        onRemove={onRemove}
      />
    </div>
  );
};

export default DayView;
