import React, { useCallback, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Upload } from 'antd';

import classNames from 'classnames';
import Events from '../../../lib/events';
import useCRUD from '../../../hooks/useCRUD';
import { apiUrls } from '../../../api/constants';
import useRedirect from '../../../hooks/useRedirect';
import withQuery from '../../../hoc/withQuery/withQuery';
import SuccessMessages from '../../../lib/successMessages';
import { contentType } from '../../../lib/constants';
import { fileSizeCheck } from '../../../lib/util';

import Loader from '../../../components/Loader';
import Button from '../../../components/Button';
import Notification from '../../../components/Notification';

import AddICD from './AddICD';
import columns from './columns';
import TableWrapper from './TableWrapper';
import FilterManager from '../../../components/FilterManager';
import FilterComponents from '../../../components/FilterComponents';

const initialFilter = { includeInActive: false };

const ICDTable = withQuery({
  listId: 'ICD_TABLE',
  url: apiUrls.GET_ICD_CODE,
})();

const FilterCollection = FilterComponents([
  {
    type: 'search',
    filterProps: {
      placeholder: 'Search ICD',
      name: 'ICDCodeText',
      id: 'icd_filter_search',
    },
  },
  {
    type: 'checkbox',
    filterProps: {
      name: 'includeInActive',
      id: 'includeInActive',
      label: 'Inactive',
    },
  },
]);

const ICD = () => {
  const {
    push, params: { tab }, params, generatePath, path,
  } = useRedirect();

  const [icdImportData, , icdImportLoading, uploadFile, clearICDImportData] = useCRUD({ id: 'import-icd', url: apiUrls.IMPORT_ICD, type: 'create' });
  const [
    hideICDSearchResponse,,
    hideICDSearchLoading,
    hideICDSearch,
    clearHideICDSearch,
  ] = useCRUD({
    id: 'HIDE_ICD_SEARCH_SYNONYM', url: apiUrls.HIDE_ICD_CPT_SYNONYM_SEARCH, type: 'update',
  });

  const handleAddICD = useCallback(() => push(generatePath(`${path}/new`,
    { ...params })), [generatePath, params, path, push]);

  const navigateBack = useCallback(() => push(generatePath(path, params)), [push]);

  const onSelectFile = useCallback((fileData) => {
    uploadFile({
      data: {
        contentType: contentType.MULTIPART,
        File: fileData,
      },
    });
  }, [uploadFile]);

  useEffect(() => {
    if (icdImportData && !Array.isArray(icdImportData)) {
      Notification({ message: SuccessMessages.ICD_IMPORTED_SUCCESSFULLY, success: true });
      Events.trigger('reFetchICDTable');
    }
    clearICDImportData(true);
  }, [clearICDImportData, icdImportData]);

  const onHideICDSearch = useCallback((status, { id } = {}) => {
    hideICDSearch({
      ICDCodeId: id,
      isActive: status,
    });
  }, [hideICDSearch]);

  useEffect(() => {
    if (hideICDSearchResponse) {
      const { message } = hideICDSearchResponse;
      Notification({ message, success: true });
      Events.trigger('reFetchICDTable');
      clearHideICDSearch();
    }
  }, [hideICDSearchResponse, clearHideICDSearch]);

  return (
    <div>
      {(icdImportLoading || hideICDSearchLoading) && <Loader />}
      <Switch>
        <Route exact path={path}>
          <div className={classNames('heading-area flex align-center screen-title',
            { 'justify-content-sp-bt': !tab, 'justify-content-flex-end': tab })}
          >
            {!tab && <h2>ICD</h2>}
            <div className="group-btns">
              <Upload
                accept=".xlsx"
                transformFile={onSelectFile}
                multiple={false}
                showUploadList={false}
                beforeUpload={(file) => fileSizeCheck(file, 30)}
              >
                <Button className="btn btn-success sm-btn">Import</Button>
              </Upload>
              <Button
                onClick={handleAddICD}
                className="btn btn-success sm-btn"
              >
                Add ICD
              </Button>
            </div>
          </div>
          <div className="cpt-icd-custom-table">
            <ICDTable
              columns={columns(onHideICDSearch)}
              rowClickable={false}
              initialFilters={initialFilter}
            >
              {({
                Component, totalCount, initialFilters, onFiltersChange, reFetch,
              }) => (
                <FilterManager
                  initialFilters={initialFilters}
                  onChange={onFiltersChange}
                >
                  {({ onFilterChange, filters }) => (
                    <>
                      <div className="table-filters">
                        <FilterCollection
                          onFilterChange={onFilterChange}
                          filters={filters}
                          allowClear
                        />
                        <span className="table-count">
                          <span>{`Total Count: ${totalCount}`}</span>
                        </span>
                      </div>
                      <TableWrapper Component={Component} reFetch={reFetch} />
                    </>
                  )}
                </FilterManager>
              )}
            </ICDTable>
          </div>
        </Route>
        <Route exact path={`${path}/new`}>
          <AddICD navigateBack={navigateBack} headingVisible={!tab} />
        </Route>
      </Switch>
    </div>
  );
};

export default ICD;
