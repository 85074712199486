import React from 'react';
import { Route, Switch } from 'react-router-dom';

import useRedirect from '../../../../hooks/useRedirect';
import StatementDetails from './StatementDetails';
import { UiRoutes } from '../../../../lib/constants';
import List from './List';

const Bills = () => {
  const { path } = useRedirect();
  return (
    <Switch>
      <Route exact path={[path, UiRoutes.patientListWithListTabId]}>
        <List />
      </Route>
      <Route path={UiRoutes.viewStatementsWithTabId}>
        <StatementDetails />
      </Route>
    </Switch>
  );
};

export default Bills;
