import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import moment from 'moment';

import { apiUrls } from '../../../api/constants';
import { enums as enumConstant } from '../../../lib/constants';

import Input from '../../../components/Form/Input';
import PhoneNumberInput from '../../../components/Form/PhoneNumberInput';
import DatePicker from '../../../components/Form/DatePicker';
import CheckBox from '../../../components/Form/CheckBox';
import AutoComplete from '../../../components/Form/AutoComplete';

import { Select, Radio } from '../../Enum';

const optionParser = (option) => ({
  name: option.zipCode,
  value: option.zipCode,
  ...option,
});

const PersonForm = ({
  labels, form, isPolicyHolder, isRequiredFields,
}) => {
  const [country, setCountry] = useState(0);

  const onZipCodeSelect = useCallback((option = {}) => {
    form.setFields([{ name: 'city', value: option.city }]);
    form.setFields([{ name: 'stateId', value: option.stateId }]);
    form.setFields([{ name: 'countryId', value: option.countryId }]);
    setCountry(option.countryId);
  }, [form]);

  return (
    <Row gutter={16}>
      <Col md={8} className="gutter-row">
        <Input
          label={labels.get('labels.firstName')}
          name="firstName"
          required
          charOnly
          labelSpan="8"
          inputSpan="16"
          maxValueLength={50}
        />
        <Input
          label={labels.get('labels.lastName')}
          name="lastName"
          required
          charOnly
          maxValueLength={50}
          labelSpan="8"
          inputSpan="16"
        />
        <Input
          label={labels.get('labels.addressLine1')}
          id="23"
          name="addressLine1"
          maxValueLength={100}
          alphaNumericOnly
          labelSpan="8"
          inputSpan="16"
          required={!isPolicyHolder}
        />
        <Input
          label={labels.get('labels.addressLine2')}
          id="24"
          name="addressLine2"
          maxValueLength={100}
          alphaNumericOnly
          labelSpan="8"
          inputSpan="16"
        />
        <AutoComplete
          label={labels.get('labels.zip')}
          name="zip"
          optionParser={optionParser}
          url={apiUrls.ZIP_LOOKUP}
          labelSpan="8"
          inputSpan="16"
          onSelect={onZipCodeSelect}
          optionMaster="zipcode"
          required={!isPolicyHolder}
        />
        <CheckBox
          label={labels.get('labels.active')}
          name="isActive"
          labelSpan="8"
          inputSpan="16"
          valuePropName="checked"
        />
      </Col>
      <Col md={8} className="gutter-row">
        <Input
          label={labels.get('labels.city')}
          name="city"
          maxValueLength={25}
          alphaNumericOnly
          labelSpan="8"
          inputSpan="16"
          required={!isPolicyHolder}
          disabled
        />
        <Select
          label={labels.get('labels.state')}
          name="stateId"
          labelSpan="8"
          inputSpan="16"
          enumName={enumConstant.STATE}
          parentId={country}
          required={!isPolicyHolder}
          disabled
        />
        <Select
          label={labels.get('labels.country')}
          id="22"
          name="countryId"
          labelSpan="8"
          inputSpan="16"
          initialValue={form.getFieldValue('countryId') || 'United States'}
          form={form}
          enumName={enumConstant.COUNTRY}
          required={!isPolicyHolder}
          disabled
        />
        <PhoneNumberInput
          label={labels.get('labels.primaryPhone')}
          id="27"
          name="primaryPhone"
          maxValueLength={12}
          minValueLength={10}
          numberOnly
          labelSpan="8"
          inputSpan="16"
        />
        <Select
          label={labels.get('labels.primaryPhoneType')}
          name="primaryPhoneTypeId"
          id="9"
          enumName={enumConstant.PHONE_TYPE}
          initialValue={form.getFieldValue('primaryPhoneTypeId') || 'Home'}
          form={form}
          labelSpan="8"
          inputSpan="16"
        />
      </Col>
      <Col md={8} className="gutter-row">
        <PhoneNumberInput
          label={labels.get('labels.secondaryPhone')}
          id="28"
          name="secondaryPhone"
          maxValueLength={12}
          minValueLength={10}
          numberOnly
          labelSpan="8"
          inputSpan="16"
        />
        <Select
          label={labels.get('labels.secondaryPhoneType')}
          name="secondaryPhoneTypeId"
          id="12"
          enumName={enumConstant.PHONE_TYPE}
          initialValue={form.getFieldValue('secondaryPhoneTypeId') || 'Home'}
          form={form}
          labelSpan="8"
          inputSpan="16"
        />
        <Input
          label={labels.get('labels.email')}
          id="29"
          name="emailAddress"
          rules={[{
            type: 'email',
            message: 'The input is not valid E-mail!',
          }]}
          labelSpan="8"
          inputSpan="16"
        />
        <DatePicker
          label={labels.get('labels.dateOfBirth')}
          name="dateOfBirth"
          id="19"
          labelSpan="8"
          inputSpan="16"
          required={isRequiredFields}
          disabledDate={(current) => current && (current >= moment().subtract(1, 'days') || current <= moment().subtract(150, 'years'))}
        />
        <Radio
          label={labels.get('labels.gender')}
          name="genderId"
          enumName={enumConstant.GENDER}
          labelSpan="8"
          inputSpan="16"
          required={isRequiredFields}
        />
      </Col>
    </Row>
  );
};

PersonForm.defaultProps = {
  labels: {},
  form: {},
};

PersonForm.propTypes = {
  labels: PropTypes.objectOf(PropTypes.object),
  form: PropTypes.objectOf(PropTypes.object),
};

export default PersonForm;
