import JSEncrypt from 'jsencrypt';
import CryptoJS from 'crypto-js';

const getRandomString = (length = 16) => {
  const chars = '0123456789';
  let result = '';
  for (let i = length; i > 0; i -= 1) {
    result += chars[Math.round(Math.random() * (chars.length - 1))];
  }
  return result;
};

const queryStringGenerator = (params) => {
  const qs = Object.keys(params).reduce((acc, key) => {
    if (params[key] !== undefined && params[key] !== null) {
      acc.push(`${key}=${params[key]}`);
    }
    return acc;
  }, []);
  return `?${qs?.join('&')}`;
};

const dataEncryptor = (dataToBeEncrypted) => {
  let publicKey = '';
  const randomKey = getRandomString();
  const randomIV = getRandomString();
  const pKey = localStorage.getDecryptedData('pKey');
  const user = JSON.parse(localStorage.getDecryptedData('user'));
  if (pKey) {
    publicKey = pKey;
  } else {
    publicKey = user?.pKey;
  }
  const aesEncrypted = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(JSON.stringify(dataToBeEncrypted)),
    CryptoJS.enc.Utf8.parse(randomKey),
    {
      keySize: 128 / 8,
      iv: CryptoJS.enc.Utf8.parse(randomIV),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    },
  ).toString();
  const rsaEncrypt = new JSEncrypt();
  rsaEncrypt.setPublicKey(publicKey);
  const encryptedKey = rsaEncrypt.encrypt(randomKey);
  return { rq: aesEncrypted, rqk: encryptedKey, ivKey: randomIV };
};

const getEncryptedQueryParams = (data) => dataEncryptor(queryStringGenerator(data));

const getEncryptedParams = (data) => dataEncryptor(data);

export {
  getEncryptedParams,
  getEncryptedQueryParams,
};
