import React from 'react';

import Icon from '../../../../components/Icon';
import IconNames from '../../../../lib/iconNames';
// eslint-disable-next-line import/no-cycle
import AddSynonym from '../../../AddSynonym';

const columns = (labels, isAddSynonym, hideDeleteIcon, toggleDxFavoriteModal) => {
  const column = [
    {
      Header: 'ICD code',
      accessor: 'icdCode',
      fixWidth: '100',
    },
    {
      Header: 'Description',
      accessor: 'description',
      Cell: ({ row: { original } }) => (
        <div
          className="icd-code-overflow"
          title={original?.synonyms ? original?.synonyms?.trim()?.split('\n')?.[0] : original?.description}
        >
          { original?.synonyms ? original?.synonyms?.trim()?.split('\n')?.[0] : original?.description}
        </div>
      ),
    },
  ];
  if (!hideDeleteIcon) {
    column.push({
      Header: 'Delete',
      Cell: ({
        row: {
          original: {
            groupId, patientDiagnosisId,
          },
        },
        deleteOcularProblem,
      }) => (
        <Icon
          name={IconNames.deleteIcon}
          onClick={() => deleteOcularProblem && deleteOcularProblem({
            groupId,
            patientDiagnosisId,
          })}
        />
      ),
      fixWidth: '60',
    });
  }
  if (isAddSynonym) {
    column.splice(2, 0, {
      Header: '+Synonym',
      accessor: 'synonym',
      Cell: ({ row: { original, index } }) => (
        <div className="flex justify-content-sp-bt">
          <AddSynonym
            labels={labels}
            buttonComponent={(props) => <Icon name={IconNames.plusIcon} {...props} className="mr-lt-8" />}
            titleOfModal="addSynonym"
            selectedCode={{ ...original, code: original?.icdCode, id: original?.id }}
            isIcdSynonym
            formTitleText=" Please enter an alternate description for the selected ICD-10 code.
          Your suggestion will be sent to admin for approval."
          />
          {toggleDxFavoriteModal && (
          <span
            className="save-diagnosis-fav-ocular"
            onClick={() => toggleDxFavoriteModal(original, index)}
            // aria-hidden="true"
            role="presentation"
          />
          )}
        </div>
      ),
      fixWidth: '80',
    });
    return column;
  }
  return column;
};
export default columns;
