import React, {
  useCallback, useState, useEffect, useMemo,
} from 'react';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import useCRUD from '../../../../../../../../hooks/useCRUD';

import { apiUrls } from '../../../../../../../../api/constants';
import ErrorMessages from '../../../../../../../../lib/errorMessages';
import SuccessMessages from '../../../../../../../../lib/successMessages';
import WarningMessages from '../../../../../../../../lib/warningMessages';
import { enums, enumMasterCode } from '../../../../../../../../lib/constants';

import Icon from '../../../../../../../../components/Icon';
import Button from '../../../../../../../../components/Button';
import Notification from '../../../../../../../../components/Notification';
import Loader from '../../../../../../../../components/Loader';
import ConfirmDialog from '../../../../../../../../components/ConfirmDialog';
import { getEnumMaster, getEnumOptions } from '../../../../../../../../store/selectors';

import ExceptionComponent from './ExceptionComponent';

import { validator, onChangeData, setFocusToFields } from './util';
import useMasterId from '../../../../../../../../hooks/useMasterId';

const masterCodes = [
  enumMasterCode.contractTermExceptionTypeAmount,
];

const enumNames = [
  enums.CONTRACT_TERM_EXCEPTION_TYPE_VALUE_TYPE,
];

const AddExceptionRow = ({
  labels,
  setNewRecord,
  onAddNewRecord,
  editRow,
  setEditRow,
  form,
  contractId,
  contractTermId,
  parentFocusId,
  childKey,
}) => {
  const enumMaster = useSelector((state) => getEnumMaster(state));
  const enumId = get(enumMaster, `${enums.SCHEDULAR_PROVIDER}.enumId`);
  const feeSchduleEnumOptions = useSelector((state) => getEnumOptions(state)?.[enumId]?.data || []);
  const defaultRateTypeId = useMemo(() => feeSchduleEnumOptions.find((enumItem) => enumItem?.masterCode === 'CA MEDICARE')?.masterId, [feeSchduleEnumOptions]);
  const [data, setData] = useState({ RateTypeId: defaultRateTypeId });
  const masterCodesWithId = useMasterId(masterCodes, enumNames);

  const { CA_MEDICARE } = enumMasterCode;
  const [exception, setExceptionData] = useState({
    typeValueTypeId: masterCodesWithId?.Amount,
    typeValueTypeName: 'Amount',
  });
  const [dataError, setError] = useState({});

  const [response, , loading, createException, clearCreate] = useCRUD({ id: 'exception-create', url: apiUrls.ADD_CONTRACT_TERMS_EXCEPTION, type: 'create' });
  const [billingRates, , , getBillingRates, clearBillingRate] = useCRUD({ id: 'get-billing-rate', url: apiUrls.GET_BILLING_RATES, type: 'read' });

  useEffect(() => {
    setExceptionData({ ...exception, typeValueTypeId: masterCodesWithId?.Amount });
  }, [masterCodesWithId]);

  useEffect(() => {
    if (Array.isArray(feeSchduleEnumOptions)) {
      const defaultOptions = feeSchduleEnumOptions.find(
        (enumItem) => enumItem?.masterCode === CA_MEDICARE,
      );
      setExceptionData({
        ...exception,
        feeScheduleTypeId: defaultOptions?.masterId,
        feeScheduleTypeName: defaultOptions?.masterName,
      });
    }
  }, [feeSchduleEnumOptions]);

  useEffect(() => {
    if (billingRates && !Array.isArray(billingRates)) {
      setExceptionData({
        ...exception,
        typeValue: billingRates?.rateValue,
      });
      clearBillingRate(true);
    }
  }, [billingRates]);

  const onCreateException = useCallback(() => {
    const isError = validator(exception);
    if (isEmpty(isError)) {
      createException({
        data: {
          contractId,
          contractTermId,
          ...exception,
        },
      });
    } else {
      setError(isError);
      setFocusToFields(isError, parentFocusId);
      if (isError?.multiplier) {
        Notification({
          message: ErrorMessages.SELECTED_PROCEDURE_DOES_NOT_EXIST_IN_FEE_SCHEDULE,
          success: false,
        });
      } else {
        Notification({ message: ErrorMessages.PLEASE_FILL_ALL_REQUIRED_FIELDS });
      }
    }
  }, [exception, createException, contractId, contractTermId, parentFocusId]);

  useEffect(() => {
    if (response && !isArray(response)) {
      Notification({ message: SuccessMessages.EXCEPTION_ADDED_SUCCESSFULLY, success: true });
      onAddNewRecord({ ...exception, contractTermExceptionId: response.contractTermExceptionId });
      clearCreate();
    }
  }, [response, onAddNewRecord, clearCreate, exception]);

  const onChange = useCallback((label) => (event, item = {}) => {
    const clonedData = data;
    if (label === 'procedureFrom') {
      clonedData.CPTCode = event?.cptCode;
    }
    if (label === 'modifier') {
      clonedData.Modifier = event?.modifierCode;
    }
    if (label === 'feeSchedule') {
      clonedData.RateTypeId = event;
    }
    setData(clonedData);
    if (label === 'procedureFrom'
    || label === 'modifier'
     || label === 'feeSchedule') getBillingRates(clonedData);

    const { exceptionData, resetError } = onChangeData({
      label, event, item, exception, dataError,
    });
    setExceptionData(exceptionData);
    if (Object.keys(resetError).length) setError(resetError);
  }, [data, getBillingRates, exception, dataError]);

  const onCancel = useCallback(() => {
    ConfirmDialog({
      onOk: (close) => {
        setNewRecord(false);
        setEditRow(null);
        close();
      },
      title: 'Warning',
      content: WarningMessages.LOST_CHANGES,
      icon: <Icon name="ExclamationCircleOutlined" />,
    })();
  }, [setEditRow, setNewRecord]);

  useEffect(() => {
    if (editRow !== 'new') setNewRecord(false);
  }, [editRow, setNewRecord]);

  return (
    <tr key={childKey}>
      {loading && <Loader />}
      <ExceptionComponent
        onChange={onChange}
        exception={exception}
        form={form}
        labels={labels}
        isEdit
        dataError={dataError}
        contractId={contractId}
        contractTermId={contractTermId}
        parentFocusId={parentFocusId}
      />
      <td>
        <div className="exception-action">
          <div className="flex">
            <Button className="btn btn-success sm-btn" onClick={onCreateException}>{labels.get('buttons.save')}</Button>
            <Button className="mr-lt-5 sm-btn" onClick={onCancel}>{labels.get('buttons.cancel')}</Button>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default AddExceptionRow;
