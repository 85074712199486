import React, { useMemo } from 'react';

import withQuery from '../../../hoc/withQuery/withQuery';
import WithLabel from '../../../hoc/withLabel';

import { labelPaths, listId } from '../../../lib/constants';
import { apiUrls } from '../../../api/constants';

import Modals from '../../../components/Modal';
import columns from './columns';

const PverifyModal = ({
  labels, visible, toggleModal, patientName, patientId, props,
}) => {
  const PverifyCustomTable = useMemo(() => withQuery({
    url: `${apiUrls.GET_P_VERIFY_INTEGRATION}`,
    listId: listId.P_VERIFY_INTEGRATION,
    accessor: (data) => ({ result: data }),
  })(), []);

  return (
    <Modals
      visible={visible}
      width={1400}
      className="notes-modal"
      toggleModal={toggleModal}
      destroyOnClose
      footer={null}
    >
      <div className="pannel table-pannel mr-top-20">
        <div className="pannel-heading">
          {`Patient Name: ${patientName || ''}`}
        </div>
        <PverifyCustomTable
          columns={columns(labels)}
          noDataText="Insurance notes not found."
          scrollId="pverify-notes-list"
          initialFilters={{ PatientId: patientId }}
          {...props}
        >
          {({ Component }) => Component}
        </PverifyCustomTable>
      </div>
    </Modals>
  );
};
export default WithLabel(PverifyModal, labelPaths.PVERIFY_MODAL);
