import React from 'react';

import { apiUrls } from '../../../../../api/constants';
import { listId } from '../../../../../lib/constants';

import withQuery from '../../../../../hoc/withQuery/withQuery';
import withReduxManager from '../../../../../hoc/withReduxManager';

import FilterManager from '../../../../../components/FilterManager';
import FilterComponents from '../../../../../components/FilterComponents';

import columns from './columns';

const initialSort = [{ id: 'name', desc: false }];

const CustomPatientTable = withReduxManager({
  listId: listId.PATIENTS,
})(withQuery({
  url: apiUrls.GET_PATIENT,
  listId: listId.PATIENTS,
  alias: {
    name: 'Name',
    nextAppDate: 'NextAppDate',
    lastVisitedDate: 'LastVisitedDate',
    chartNumber: 'ChartNumber',
    patientBal: 'PatientBal',
    insuranceBal: 'InsuranceBal',
  },
})());

const statusOptions = [{
  name: 'Active',
  value: 'ExcludeInactivePatients',
  color: 'green',
}, {
  name: 'Inactive',
  value: 'InactivePatients',
  color: 'red',
}, {
  name: 'Do Not Contact',
  value: 'DoNotContact',
  color: 'geekblue',
}];

const FilterCollection = FilterComponents([
  {
    type: 'search',
    filterProps: {
      placeholder: 'Search',
      name: 'SearchText',
      id: 'patients_filter_search',
    },
  },
]);

const PatientTable = ({ labels, ...props }) => (
  <CustomPatientTable columns={columns(labels)} initialSort={initialSort} noDataText="Patient not found" statusOptions={statusOptions} {...props}>
    {({
      Component, initialFilters, onFiltersChange, totalCount,
    }) => (
      <FilterManager initialFilters={initialFilters} onChange={onFiltersChange}>
        {({ onFilterChange, filters }) => (
          <>
            <div className="table-filters">
              <FilterCollection onFilterChange={onFilterChange} filters={filters} />
              <span className="table-count">
                <span>{`${labels.get('titles.totalCount')}: ${totalCount}`}</span>
              </span>
            </div>
            {Component}
          </>
        )}
      </FilterManager>
    )}
  </CustomPatientTable>
);

export default PatientTable;
