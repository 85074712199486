import React from 'react';
import moment from 'moment';
import { Tooltip } from 'antd';

import { UiRoutes, dateFormat } from '../../../../../lib/constants';

import Link from '../../../../../components/Link';
import Button from '../../../../../components/Button';
import TimeCell from '../../../../../components/Cells/TimeCell';

import '../financial.scss';
import Icon from '../../../../../components/Icon';
import iconNames from '../../../../../lib/iconNames';

const isElectronic = (original) => ((original?.deliveryMethod && !original?.isPatientResponsibility
  && original?.deliveryMethod?.toLowerCase()?.trim()?.includes('electronic'))
  || (original?.remittanceBatchId && original?.paymentMethod?.toLowerCase()?.trim()?.includes('electronic')
    && original?.source?.toLowerCase()?.trim() === 'insurancepayment'));

const isManual = (original) => ((original?.deliveryMethod && !original?.isPatientResponsibility
   && original?.deliveryMethod?.toLowerCase()?.trim()?.includes('paper')));

const isRemittancePayment = (original) => ((original?.remittanceBatchId && original?.paymentMethod?.toLowerCase()?.trim()?.includes('manual')
&& original?.source?.toLowerCase()?.trim() === 'insurancepayment'));

const columns = (params, generatePath, labels) => [

  {
    Header: labels.get('tableColumns.effectiveDate'),
    accessor: 'effectiveDate',
    Cell: ({ row: { original: { financeId }, original } }) => (
      <Link
        to={generatePath(UiRoutes.viewFinancialDetailsWithId,
          { ...params, financialId: financeId })}
        className="cursor-pointer"
      >
        <TimeCell value={moment.utc(original?.effectiveDate)} format={dateFormat} />
      </Link>
    ),
  },
  {
    Header: labels.get('tableColumns.status'),
    accessor: 'transactionStatus',
    className: 'text-align-left',
  },
  // {
  //   Header: labels.get('tableColumns.chargesPostedDate'),
  //   accessor: 'chargesPostedDate',
  //   fixWidth: '140',
  //   Cell: ({ row: { original: { chargesPostedDate } } }) => (
  //     <TimeCell value={chargesPostedDate} format={dateFormatWithSlash} />
  //   ),
  // },
  // {
  //   Header: labels.get('tableColumns.paymentDate'),
  //   accessor: 'paymentDate',
  //   Cell: ({ row: { original: { paymentDate } } }) => (
  //     <TimeCell value={paymentDate} format={dateFormatWithSlash} />
  //   ),
  // },
  {
    Header: labels.get('tableColumns.type'),
    accessor: 'transactionType',
    className: 'text-align-left',
  },
  {
    Header: labels.get('tableColumns.description'),
    accessor: 'description',
    className: 'text-align-left',
    Cell: ({ row: { original: { description } } }) => <Tooltip placement="topLeft" title={description}>{description}</Tooltip>,
  },
  {
    Header: labels.get('tableColumns.provider'),
    accessor: 'providerName',
    className: 'text-align-left',
  },
  {
    Header: labels.get('tableColumns.location'),
    accessor: 'locationName',
    className: 'text-align-left',
  },
  {
    Header: labels.get('tableColumns.units'),
    accessor: 'unit',
    className: 'text-align-right',
    fixWidth: 60,
  },
  {
    Header: labels.get('tableColumns.amount'),
    accessor: 'amount',
    Cell: ({ row: { original } }) => (original?.amount?.toFixed(2)),
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.value'),
    accessor: 'value',
    Cell: ({ row: { original: { isPatientResponsibility, isCharge, value } } }) => (
      isPatientResponsibility && !isCharge ? '' : (value || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.patientBalanceUnapplied'),
    accessor: 'patientBalance',
    Cell: ({ row: { original: { patientBalance } } }) => (
      (patientBalance || 0).toFixed(2)
    ),
    className: 'text-align-right',
    fixWidth: 120,
  },
  {
    Header: labels.get('tableColumns.insuranceBalance'),
    accessor: 'insuranceBalance',
    Cell: ({ row: { original: { insuranceBalance } } }) => (
      (insuranceBalance || 0).toFixed(2)
    ),
    className: 'text-align-right',
    fixWidth: 80,
  },
  // {
  //   Header: labels.get('tableColumns.balUnapplied'),
  //   accessor: 'balance',
  //   Cell: ({ row: { original: { balance }, id } }) => (
  //     <Tooltip
  //       key={id}
  //       placement="left"
  //       title={<span>{balance}</span>}
  //     >
  //       <span>{balance}</span>
  //     </Tooltip>
  //   ),
  //   className: 'text-align-right',
  // },
  {
    Header: '',
    accessor: 'lightening',
    Cell: ({ row: { original } }) => (
      <div className="charge-table-icons">
        {isElectronic(original) && <Icon name={iconNames.thunderboltOutlined} color="orange" />}
        {isManual(original) && <Icon name={iconNames.pushpinOutlined} color="red" />}
        {isRemittancePayment(original) && <Icon name={iconNames.paperClipOutlined} color="red" />}
      </div>
    ),
    fixWidth: 60,
  },
  {
    Header: '',
    accessor: 'info',
    fixWidth: '48',
    Cell: ({ row: { original: { balance }, id } }) => (
      <Tooltip
        key={id}
        placement="left"
        title={<span>{balance}</span>}
      >
        <span className="info-img sprite-img-before" />
      </Tooltip>
    ),
  },
  {
    Header: '',
    accessor: 'receipt',
    Cell: ({ row, handlePrintReceipt }) => {
      const rowProps = row.getToggleRowSelectedProps();
      rowProps.onClick = (e) => e.stopPropagation();
      return (
        (row?.original?.transactionType?.toLowerCase() === 'patient payment') && (
          <span {...rowProps}>
            <Button
              className="btn btn-success sm-btn mr-rt-8"
              onClick={handlePrintReceipt(row?.original?.financeId)}
            >
              Print Receipt
            </Button>
          </span>
        ));
    },
    fixWidth: 120,
  },
  {
    Header: '',
    accessor: 'receipt1',
    Cell: ({ row, handleAddToFaxQueue }) => {
      const rowProps = row.getToggleRowSelectedProps();
      rowProps.onClick = (e) => e.stopPropagation();
      return (
        (row?.original?.transactionType?.toLowerCase() === 'patient payment') && (
          <span {...rowProps}>
            <Button
              className="sm-btn mr-rt-8"
              onClick={handleAddToFaxQueue(row?.original?.financeId)}
            >
              Add to Fax Queue
            </Button>
          </span>
        ));
    },
    fixWidth: 130,
  },
];

export default columns;
