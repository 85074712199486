import React, { useCallback, useEffect, useState } from 'react';
import {
  DatePicker as AntdDatePicker,
  Form as AntdForm,
} from 'antd';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

import { apiUrls } from '../../../api/constants';
import { responseType } from '../../../lib/constants';
import downloadFile from '../../../lib/downloadFile';

import useCrudWithEffects from '../../../hooks/useCrudWithEffects';

import Form from '../../../components/Form';
import Modals from '../../../components/Modal';
import Button from '../../../components/Button';
import DatePicker from '../../../components/DatePicker';
import Notification from '../../../components/Notification';
import { CheckBox } from '../../../components/Form/inputFields';

const { RangePicker } = AntdDatePicker;

const AppointmentListModal = ({
  labels, visible, toggleParentModal, toggleModal,
  patientId, locationIds, providerIds, memoizedDateRange,
}) => {
  const [form] = AntdForm.useForm();
  const [dateRange, setDateRange] = useState([]);

  useEffect(() => setDateRange(memoizedDateRange), [memoizedDateRange]);

  const handleAppointmentListPdf = useCallback((appointmentListResponse) => {
    downloadFile(appointmentListResponse, `appointmentList-${moment().format('DD-MM-YY hh-mm-ss')}.pdf`, 'application/pdf');
  }, []);

  const [getappointmentListResponse] = useCrudWithEffects({
    id: `appointment-list-data ${patientId}`,
    url: apiUrls.GET_APPOINTMENTS_LIST_DATA,
    callBack: handleAppointmentListPdf,
    type: 'read',
  });

  const getappointmentListPdf = useCallback(() => {
    const { includeCancelled } = form.getFieldsValue();
    const data = {
      patientId,
      includeCancelled: (includeCancelled === undefined ? false : includeCancelled),
      fromDate: moment(dateRange?.[0]).hours(0).minutes(0).seconds(0)
        .milliseconds(0)
        .format('YYYY-MM-DD'),
      toDate: moment(dateRange?.[1]).hours(23).minutes(59).seconds(59)
        .milliseconds(0)
        .format('YYYY-MM-DD'),
      locationIds,
      providerIds: isEmpty(providerIds) ? undefined : providerIds?.join(),
    };
    Notification({ message: 'Appointment list generation in progress', success: true });
    getappointmentListResponse({ ...data, responseType: responseType.BLOB });
    toggleModal();
    toggleParentModal();
  }, [dateRange, form, getappointmentListResponse, locationIds,
    patientId, providerIds, toggleModal, toggleParentModal]);

  const onDateSelect = useCallback((dates = []) => {
    if (Array.isArray(dates) && dates.length > 1) {
      const selectedRange = [
        moment(dates[0]).startOf('day'),
        moment(dates[1]).endOf('day'),
      ];
      setDateRange(selectedRange);
    }
  }, []);

  return (
    <Modals
      visible={visible}
      width={512}
      className="notes-modal m-notes-modal"
      toggleModal={toggleModal}
      destroyOnClose
      footer={[
        <div className="appointment-modal-footer" key="footer">
          <Button type="button" className="btn min-wt-86 mr-lt-16" onClick={toggleModal}>
            {labels.get('buttons.cancel')}
          </Button>
          <Button type="button" className="btn btn-success btn-primary" onClick={getappointmentListPdf}>
            {labels.get('buttons.print')}
          </Button>
        </div>,
      ]}
    >
      <div className="addition-header print-modal-header">
        <span className="print-modal-icon" />
        <p className="bold">{labels.get('titles.appointmentList')}</p>
      </div>
      <Form form={form}>
        <DatePicker
          labelSpan="8"
          inputSpan="16"
          component={RangePicker}
          label={labels.get('labels.appointmentFrom')}
          name="date"
          value={dateRange}
          onChange={onDateSelect}
        />
        <CheckBox
          labelSpan="7"
          inputSpan="17"
          valuePropName="checked"
          label={labels.get('labels.includeCancel')}
          name="includeCancelled"
        />
      </Form>
    </Modals>
  );
};
export default AppointmentListModal;
