import { Tooltip } from 'antd';
import React from 'react';
import Table from '../../../../../../../components/Table';
import WidgetLoader from '../../../../../../../components/WidgetLoader';
import RenderMultipleSynonyms from '../../../../../../../components/RenderMultipleSynonyms';

const icdColumns = (onRowClick, labels) => [
  {
    Header: labels.get('labels.icdCode'),
    fixWidth: '100',
    accessor: 'icdCode',
    Cell: (data) => {
      const { icdCode } = data?.row?.original || {};
      return (
        <div
          role="presentation"
          onClick={() => onRowClick(data?.row?.original)}
        >
          {icdCode}
        </div>
      );
    },
  },
  {
    Header: labels.get('labels.description'),
    accessor: 'description',
    Cell: ({ row: { original: { description, synonyms } } }) => (
      <Tooltip placement="topLeft" title={description}>
        <span className="flex flex-dir-row">
          {synonyms ? <RenderMultipleSynonyms synonyms={synonyms} /> : description}
        </span>
      </Tooltip>
    ),
  },
];

const PreviousDxList = (props) => {
  const {
    previousDiagnosisList, prevDiagnosisLoading, onICDCheckBoxClick, labels,
  } = props;
  return (
    <div>
      <div className="json-tree-container remove-tree-switchers">
        {prevDiagnosisLoading && <WidgetLoader />}
        {previousDiagnosisList && (
        <div className="procedure-tab-table-wrap">
          <Table
            columns={icdColumns(onICDCheckBoxClick, labels)}
            data={previousDiagnosisList}
            scrollId="previous-patient-dx-table"
            labels={labels}
          />
        </div>
        )}
      </div>
    </div>
  );
};

export default PreviousDxList;
