import React from 'react';
import { Tree } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isArray from 'lodash/isArray';

import isFunction from 'lodash/isFunction';
import * as selectors from '../../../../../../../../../store/selectors';

const { TreeNode } = Tree;

const renderTreeNodes = (data) => data.map((item) => {
  if (item?.subType && Array.isArray(item?.subType)) {
    return (
      <TreeNode
        title={item?.label || item?.description}
        key={item.key}
        id={item.label}
        dataRef={item}
        {...item}
      >
        {renderTreeNodes(item.subType)}
      </TreeNode>
    );
  }
  return (
    <TreeNode title={item?.label || item?.description} isLeaf key={item.key} {...item} />
  );
});

const JsonTree = ({
  list, search, setLabelsList, enumMaster, showCloseIcon, deleteHandler, isResult,
  enumOptions, tab, draggable, deleteProcedureSet, onClick, disableParentCloseIcon, ...otherProps
}) => {
  const handleDragStart = (event, data) => {
    event.dataTransfer.setData('text', event?.target?.id);
    event.dataTransfer.setData('type', 'acuity-eye-tree-leaf');
    event.dataTransfer.setData('draggedItem', tab);
    event.target.classList.add('dragging');
    event.dataTransfer.setData('draggedData', JSON.stringify(data));
  };

  const handleDragEnd = (event) => {
    event.target.classList.remove('dragging');
  };
  const getDescription = (node) => {
    const [code, description] = node.title.split('-');
    return (
      <div className="separate-code">
        <span className="label-text-width">{description?.split('\n')?.map((entry) => <span className="node-title">{entry}</span>)}</span>
        <span className="code-only separate-code-bg">{code}</span>
      </div>
    );
  };
  const customDescription = (node) => {
    const descriptionArray = node?.description && node?.description?.trim()?.split('\n');
    return (
      <div
        className="posi-relative"
        style={{
          border: '1px solid #a5bcdf',
          background: '#f7f9fb9c',
          padding: '0 0 0 8px',
          borderRadius: '3px',
          marginBottom: '4px',
          fontSize: '12px',
        }}
      >
        {isArray(descriptionArray) && descriptionArray?.map((item, index) => (
          <div style={{
            width: '100%',
            display: 'inline-block',
          }}
          >
            <div>
              {!index ? (
                <div className="separate-code">
                  <span className="label-text-width">{item}</span>
                  <span className="code-only separate-code-bg">{node?.icdCode}</span>
                </div>
              ) : (<div className="separate-code"><span>{item}</span></div>)}
            </div>
          </div>
        )
      )}
      </div>
    );
  };

  if (list?.length) {
    return (
      <Tree
        defaultExpandAll
        titleRender={(node) => (
          <div>
            <div
              role="presentation"
              className={node?.isLeaf ? 'tree-leaf-node' : 'tree-parent-node'}
              draggable={draggable}
              onDragStart={(e) => handleDragStart(e, node)}
              onDragEnd={(e) => handleDragEnd(e)}
              onClick={node?.isLeaf && isFunction(onClick) && onClick(node)}
              id={node?.key}
              {...node}
            >
              {isResult ? (
                <div style={{ width: '100%' }}>
                  {customDescription(node)}
                </div>
              ) : (
                <div className="flex flex-dir-column all-previous-dx">
                  {getDescription(node)}
                  {/*  */}
                </div>
              )}
            </div>
            {(!disableParentCloseIcon || node?.isLeaf)
             && <span className="dlt-list" role="presentation" onClick={deleteProcedureSet(node)} />}
            {showCloseIcon && (
              <i
                onClick={() => deleteHandler(node)}
                className="close-icon"
                aria-hidden="true"
                id={`${node?.key}_parent_cross_icon`}
              >
                x
              </i>
            )}
          </div>
        )}
        {...otherProps}
      >
        {renderTreeNodes(list)}
      </Tree>
    );
  }
  return (
    <span />
  );
};

JsonTree.defaultProps = {
  list: [],
  search: '',
  url: '',
  setLabelsList: () => { /* This is intentional */ },
  draggable: true,
  deleteProcedureSet: () => { /* This is intentional */ },
  showCloseIcon: false,
  deleteHandler: () => { /* This is intentional */ },
  isResult: false,
};

JsonTree.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  search: PropTypes.string,
  url: PropTypes.string,
  setLabelsList: PropTypes.func,
  draggable: PropTypes.bool,
  deleteProcedureSet: PropTypes.func,
  showCloseIcon: PropTypes.bool,
  deleteHandler: PropTypes.func,
  isResult: PropTypes.string,
};

export default connect((states) => ({
  enumOptions: selectors.getEnumOptions(states),
  enumMaster: selectors.getEnumMaster(states),
}))(JsonTree);
