import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { DatePicker as AntdDatePicker } from 'antd';

import SelectBox from '../Form/SelectBox';
import DatePicker from '../Form/DatePicker';
import { dateFormatStartsWithYearSeconds } from '../../lib/constants';

const dateFilterOptions = [{
  name: 'All Dates',
  value: 'allDates',
}, {
  name: 'Yesterday',
  value: 'yesterday',
}, {
  name: 'Current week',
  value: 'currentWeek',
},
{
  name: 'Current Month',
  value: 'currentMonth',
},
{
  name: 'Current year',
  value: 'currentYear',
},
{
  name: 'Last 30 days',
  value: 'last30days',
},
{
  name: 'Last 7 days',
  value: 'last7days',
},
{
  name: 'Last Month',
  value: 'lastMonth',
},
{
  name: 'Last year',
  value: 'lastYear',
},
{
  name: 'Specific date',
  value: 'specificDate',
},
{
  name: 'Date range',
  value: 'dateRange',
},
];

const getStartAndEndOf = (type, fromDate, toDate) => ({
  dateTo: moment(fromDate || undefined).endOf(type).format(dateFormatStartsWithYearSeconds),
  dateFrom: moment(toDate || undefined).startOf(type).format(dateFormatStartsWithYearSeconds),
});

const getSubtractedDate = (subtractNumberFrom, subtractType, subtractNumberTo) => {
  if (subtractNumberFrom && subtractType) {
    return getStartAndEndOf(subtractType,
      moment().subtract(subtractNumberTo, subtractType),
      moment().subtract(subtractNumberFrom, subtractType));
  }
  return moment();
};

const getAddedDate = (subtractNumberFrom, subtractType, subtractNumberTo) => {
  if (subtractType) {
    return getStartAndEndOf(subtractType,
      moment().add(subtractNumberTo, subtractType),
      moment().add(subtractNumberFrom, subtractType));
  }
  return moment();
};

const getDate = (type) => {
  let dateObject = {};
  switch (type) {
    case 'yesterday': dateObject = getSubtractedDate(1, 'days', 1); break;
    case 'currentWeek': dateObject = getStartAndEndOf('week'); break;
    case 'currentMonth': dateObject = getStartAndEndOf('month'); break;
    case 'currentYear': dateObject = getStartAndEndOf('year'); break;
    case 'last30days': dateObject = getSubtractedDate(29, 'days', 0); break;
    case 'last7days': dateObject = getSubtractedDate(6, 'days', 0); break;
    case 'lastMonth': dateObject = getSubtractedDate(1, 'month', 1); break;
    case 'lastYear': dateObject = getSubtractedDate(1, 'year', 1); break;
    case 'pastDue': dateObject = {
      dateFrom: '',
      dateTo: moment().subtract(1, 'days').endOf('days')
        .format(dateFormatStartsWithYearSeconds),
    }; break;
    case 'dueToday': dateObject = getAddedDate(0, 'days', 0); break;
    case 'next7Days': dateObject = getAddedDate(0, 'days', 6); break;
    case 'next14Days': dateObject = getAddedDate(0, 'days', 13); break;
    case 'next30Days': dateObject = getAddedDate(0, 'days', 29); break;
    default: dateObject = {};
  }
  return dateObject;
};

const DateFilter = ({
  label,
  onChange = () => { /* This is intentional */ },
  options,
  labelSpan,
  inputSpan,
  noDefaultValue,
}) => {
  const { RangePicker } = AntdDatePicker;
  const [selectedDateType, setDateType] = useState(null);

  const OnChangeFilter = useCallback((selectType) => {
    setDateType(selectType);
    if (selectType === 'allDates') {
      const dates = { dateFrom: '', dateTo: '' };
      onChange(dates);
    } else if (selectType !== 'specificDate' && selectType !== 'dateRange') {
      const dates = getDate(selectType);
      onChange(dates);
    }
  }, [onChange]);

  const onSpecificDateChange = useCallback((date) => {
    const dateObject = getStartAndEndOf('day', date, date);
    onChange(dateObject);
  }, [onChange]);

  const onRangeSelect = useCallback((dateRange) => {
    const dateObject = getStartAndEndOf('day', dateRange?.[1], dateRange?.[0]);
    onChange(dateObject);
  }, [onChange]);

  return (
    <>
      <SelectBox
        options={options || dateFilterOptions}
        name="dateEntered"
        label={label}
        onChange={OnChangeFilter}
        defaultValue={!noDefaultValue ? 'allDates' : undefined}
        labelSpan={labelSpan || 0}
        inputSpan={inputSpan || 24}
      />
      {selectedDateType === 'specificDate' && <div className="mr-left-20"><DatePicker name="dateFrom" id="date_from" onChange={onSpecificDateChange} labelSpan="0" inputSpan="24" allowClear={false} /></div> }
      {selectedDateType === 'dateRange' && (
      <div className="mr-left-20">
        <RangePicker
          dropdownClassName="dashboard-datepicker-dropdown"
          placeholder={['Start', 'End']}
          format="MMM D"
          onChange={onRangeSelect}
        />
      </div>
      ) }
    </>
  );
};

export default DateFilter;
