import React, { useEffect, useCallback, useMemo } from 'react';

import { apiUrls } from '../../../../api/constants';
import { listIds, labelPaths, contentType } from '../../../../lib/constants';
import SuccessMessages from '../../../../lib/successMessages';

import WithLabel from '../../../../hoc/withLabel';
import withQuery from '../../../../hoc/withQuery/withQuery';
import withReduxManager from '../../../../hoc/withReduxManager';

import useCRUD from '../../../../hooks/useCRUD';

import FilterManager from '../../../../components/FilterManager';
import FilterComponents from '../../../../components/FilterComponents';
import Notification from '../../../../components/Notification';
import Loader from '../../../../components/Loader';

import { payerColumns } from './columns';

import TableComponent from './TableComponent';
import ExpandedCPTCodesRender from './CPTCodeTable';
import useRights from '../../../../hooks/useRights';
import rights from '../../../../lib/rights';

const hiddenPayerColumns = ['addMapping', 'upload'];

const CustomPayerTable = withReduxManager({
  listId: listIds.GET_PAYER,
})(withQuery({
  url: apiUrls.GET_PAYERS,
  listId: listIds.GET_PAYER,
  alias: {
    address: 'AddressLine1',
  },
})());

const FilterCollection = FilterComponents([
  {
    type: 'search',
    filterProps: {
      placeholder: 'Search',
      name: 'SearchText',
      id: 'payer_filter_search',
    },
  },
]);

const initialSort = [{ id: 'name', desc: false }];

const PayerTable = ({
  onAddMappingIconClick, handleReFetch, labels, navigateToEditPayer, isPayerRequestViewOnly,
}) => {
  const [isPayerAuthenticated, isPayerViewOnlyAuthenticated] = useRights([rights.payer,
    rights.payer_view_only]);
  const [
    cptImportData,,
    cptImportLoading,
    uploadCptFile,
    clearCptData,
  ] = useCRUD({ id: 'import-cpt-code', url: apiUrls.CPT_IMPORT, type: 'create' });

  useEffect(() => {
    if (cptImportData) {
      Notification({ message: SuccessMessages.EXCEL_IMPORTED_SUCCESSFUL, success: true });
      clearCptData();
    }
  }, [cptImportData, clearCptData]);

  const onSelectFile = useCallback((fileData, payerId) => {
    uploadCptFile({
      data: {
        contentType: contentType.MULTIPART,
        File: fileData,
        DocumentDisplayName: fileData.name.substr(0, fileData.name.lastIndexOf('.')),
        payerId,
      },
    });
  }, [uploadCptFile]);

  const expandedRowRender = (props) => (
    <ExpandedCPTCodesRender
      onAddMappingIconClick={onAddMappingIconClick}
      labels={labels}
      tableContent={props}
      isPayerRequestViewOnly={isPayerRequestViewOnly}
    />
  );

  const isEditPermitted = useMemo(() => (isPayerAuthenticated
    || (isPayerAuthenticated && isPayerViewOnlyAuthenticated)
  ), [isPayerAuthenticated, isPayerViewOnlyAuthenticated]);

  return (
    <>
      {cptImportLoading && <Loader />}
      <CustomPayerTable
        columns={payerColumns(onAddMappingIconClick, labels)}
        noDataText="Payer not found"
        renderRowSubComponent={expandedRowRender}
        initialSort={initialSort}
        navigateToEditPayer={navigateToEditPayer}
        onSelectFile={onSelectFile}
        hiddenColumns={isPayerRequestViewOnly ? hiddenPayerColumns : []}
        isPayerAuthenticated={isPayerAuthenticated}
        isEditPermitted={isEditPermitted}
      >
        {({
          Component, reFetch, initialFilters, onFiltersChange, totalCount,
        }) => (
          <FilterManager initialFilters={initialFilters} onChange={onFiltersChange}>
            {({ onFilterChange, filters }) => (
              <>
                <div className="table-filters">
                  <FilterCollection onFilterChange={onFilterChange} filters={filters} />
                  <span className="table-count">
                    <span>{`Total Count: ${totalCount}`}</span>

                  </span>
                </div>
                <TableComponent
                  reFetch={reFetch}
                  handleReFetch={handleReFetch}
                  Component={Component}
                />
              </>
            )}
          </FilterManager>
        ) }
      </CustomPayerTable>
    </>
  );
};

export default WithLabel(PayerTable, labelPaths.VIEW_PAYER);
