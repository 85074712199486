import React, {
  useEffect, useCallback, useState, useMemo,
} from 'react';
import { Form as AntdForm } from 'antd';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import isFunction from 'lodash/isFunction';
import { apiUrls } from '../../api/constants';

import {
  listIds, UiRoutes,
} from '../../lib/constants';

import SuccessMessages from '../../lib/successMessages';

import withQuery from '../../hoc/withQuery/withQuery';
import withReduxManager from '../../hoc/withReduxManager';

import useCRUD from '../../hooks/useCRUD';
import useRedirect from '../../hooks/useRedirect';

import Button from '../../components/Button';
import Loader from '../../components/Loader';
import Modals from '../../components/Modal';
import Notification from '../../components/Notification';

import EnumForm from './EnumForm';
import masterColumns from './columns';
import EditModal from './EditModal';

let parentPath;

const CustomMasterTable = withReduxManager({
  listId: listIds.GET_PAYER,
})(withQuery({
  url: apiUrls.GET_ENUM_FIELDS,
  listId: listIds.GET_ENUM_FIELDS,
  accessor: (data) => ({ result: data }),
})());

const MasterTable = ({
  addMasterDataHandler,
  fields,
  children,
  enumIdAccessor,
  enumId,
  columns,
  filters,
  disabledRowClick,
  parentRedirectPath,
  title,
  disableTable,
  hiddenColumns,
}) => {
  const [form] = AntdForm.useForm();
  const [visible, toggleMasterDataModal] = useState(false);

  const {
    params, push, generatePath, path,
  } = useRedirect();

  const toggleModal = useCallback(() => {
    toggleMasterDataModal(!visible);
  }, [visible]);

  const enumMasterId = useMemo(() => {
    if (enumIdAccessor) return params[enumIdAccessor];
    return enumId;
  }, [enumIdAccessor, enumId, params]);

  useEffect(() => {
    parentPath = parentRedirectPath || path;
    addMasterDataHandler(toggleModal);
  }, []);

  const [
    deleteMasterResponse,,
    deleteLoading,
    deleteMasterDataAPI,
    clearDeleteResponse] = useCRUD({
    id: listIds.DELETE_MASTER_DATA,
    url: apiUrls.DELETE_ENUM_FIELDS,
    type: 'delete',
  });

  const navigateToParent = useCallback(() => {
    push(generatePath(parentPath, params));
  }, [push, generatePath, params]);

  const onRequestComplete = useCallback((reFetch) => ({ response }) => {
    if (response) {
      form.resetFields();
      toggleModal();
      Notification({ message: SuccessMessages.MASTER_DATA_ADDED_SUCCESSFULLY, success: true });
      reFetch();
    }
  }, [form, toggleModal]);

  const deleteMasterData = useCallback((id) => {
    if (id) {
      deleteMasterDataAPI({}, `/${id}`);
    }
  }, [deleteMasterDataAPI]);

  useEffect(() => {
    if (deleteMasterResponse && deleteMasterResponse.message) {
      Notification({ message: SuccessMessages.DELETE_MASTER_DATA_SUCCESSFUL, success: true });
      clearDeleteResponse();
    }
  }, [deleteMasterResponse, clearDeleteResponse]);

  const onRowClick = useCallback((row) => {
    push(generatePath(`${path}/:masterDataId`,
      { ...params, masterDataId: row?.original?.masterId }));
  }, [push, generatePath, params, path]);

  if (deleteLoading) {
    return <Loader />;
  }
  return (
    <>
      {children && isFunction(children) && children({ toggleModal })}
      <div className="mr-top-12">
        <CustomMasterTable
          columns={columns}
          noDataText={`${title} not found`}
          filters={{ EnumId: enumMasterId, ...filters }}
          onRowClick={!disabledRowClick && onRowClick}
          deleteMasterData={deleteMasterData}
          editMasterData={onRowClick}
          disableTable={disableTable}
          hiddenColumns={hiddenColumns}
        >
          {({
            Component, reFetch,
          }) => (
            <>
              <Modals
                visible={!!visible}
                toggleModal={() => { form.resetFields(); toggleModal(); }}
                destroyOnClose
                footer={[
                  <div className="group-btns" key="footer">
                    <Button className="btn min-wt-86" onClick={() => { toggleModal(); form.resetFields(); }}>Cancel</Button>
                    <Button className="btn btn-success min-wt-86 inline" onClick={() => form.submit()}>Save</Button>
                  </div>,
                ]}
              >
                <EnumForm
                  fields={fields}
                  form={form}
                  title={`Add ${title}`}
                  onRequestComplete={onRequestComplete(reFetch)}
                  enumId={enumMasterId}
                />
              </Modals>
              <Route path={[UiRoutes?.editBusinessEntityWithTabIdMasterId,
                UiRoutes.editMastersWithId]}
              >
                <EditModal
                  fields={fields}
                  enumMasterId={enumMasterId}
                  title={title}
                  navigateToParent={navigateToParent}
                  reFetch={reFetch}
                />
              </Route>
              {enumMasterId ? Component
                : <h3 className="heading-align-center"> Please select master type. </h3>}
            </>
          )}
        </CustomMasterTable>
      </div>
    </>
  );
};

MasterTable.defaultProps = {
  columns: masterColumns,
  addMasterDataHandler: () => { /* This is intentional */ },
  filters: {},
  disabledRowClick: false,
  title: 'Master Data',
  disableTable: false,
  hiddenColumns: [],
};
MasterTable.propTypes = {
  addMasterDataHandler: PropTypes.func,
  columns: PropTypes.func,
  filters: PropTypes.instanceOf(Object),
  disabledRowClick: PropTypes.bool,
  title: PropTypes.string,
  disableTable: PropTypes.bool,
  hiddenColumns: PropTypes.arrayOf(PropTypes.object),
};

export default MasterTable;
