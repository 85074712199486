import React from 'react';

import TimeCell from '../../../../../../components/Cells/TimeCell';
import { formatPhoneNumber, getDateString, getName } from '../../../../../../lib/util';

const PatientPaymentColumns = (labels) => [
  {
    Header: labels.get('columns.name'),
    accessor: 'firstName',
    Cell: ({
      row: {
        original: {
          firstName, middleName, lastName,
        },
      },
    }) => (
      <span>{getName(firstName, middleName, lastName)}</span>
    ),
    Footer: ({ data = [] }) => {
      let totalCount = 0;
      data.forEach(() => {
        totalCount += 1;
      });
      if (!totalCount) return null;
      return (
        <span style={{ fontWeight: 'bolder', color: '#2a4a79' }}>
          {totalCount}
          {' '}
          Items
        </span>
      );
    },
    fixWidth: '150',
  },
  {
    Header: labels.get('columns.dob'),
    accessor: 'dateOfBirth',
    Cell: ({
      row: {
        original: { dateOfBirth },
      },
    }) => <TimeCell value={getDateString(dateOfBirth)} />,
  },
  {
    Header: labels.get('columns.chartNo'),
    accessor: 'chartNumber',
  },
  {
    Header: labels.get('columns.patientNumber'),
    accessor: 'contact',
    Cell: ({ row: { original: { contact } } }) => <span>{formatPhoneNumber(contact)}</span>,
  },
  {
    Header: labels.get('columns.pBalance'),
    accessor: 'patientBal',
    Cell: ({ row: { original: { patientBal } } }) => (
      (patientBal || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
];

export default PatientPaymentColumns;
