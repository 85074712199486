import React from 'react';
import PropTypes from 'prop-types';

import dateFormatter from '../../../lib/dateFormatter';

const TimeCell = ({ value, format }) => (
  <span>
    {dateFormatter(value, format)}
  </span>
);

TimeCell.defaultProps = {
  format: '',
  value: '',
};

TimeCell.propTypes = {
  format: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default TimeCell;
