export const ADD_PROVIDER = '@provider/ADD_PROVIDER';
export const SET_SELECTED_PROVIDER = '@provider/SET_SELECTED_PROVIDER';
export const GET_SELECTED_PROVIDER = '@provider/GET_SELECTED_PROVIDER';
export const GET_PROVIDERS = '@provider/GET_PROVIDERS';
export const UPDATE_PROVIDER = '@provider/UPDATE_PROVIDER';
export const CLEAR_SELECTED_PROVIDER = '@provider/CLEAR_SELECTED_PROVIDER';
export const SET_LOADING = '@provider/SET_LOADING';
export const SET_REDIRECT_KEY = 'SET_REDIRECT_KEY';
export const SET_ERROR = '@provider/SET_ERROR';
export const SET_UPDATE = '@provider/SET_UPDATE';
export const SET_UPDATED_PROVIDER_IN_STORE = '@provider/SET_UPDATED_PROVIDER_IN_STORE';
export const CLEAR_OTHER_PROPS = '@provider/CLEAR_OTHER_PROPS';

export const getProvidersData = () => ({
  type: GET_PROVIDERS,
});

export const setSelectedProvider = (provider) => ({
  type: SET_SELECTED_PROVIDER,
  provider,
});

export const getSelectedProvider = (providerId) => ({
  type: GET_SELECTED_PROVIDER,
  providerId,
});

export const addProvider = (payload = {}) => ({
  type: ADD_PROVIDER,
  payload,
});

export const updateProvider = (payload) => ({
  type: UPDATE_PROVIDER,
  payload,
});

export const clearSelectedProvider = () => ({
  type: CLEAR_SELECTED_PROVIDER,
});

export const clearOtherProps = () => ({
  type: CLEAR_OTHER_PROPS,
});

export const setLoading = (flag) => ({
  type: SET_LOADING,
  flag,
});

export const setError = (error) => ({
  type: SET_ERROR,
  error,
});

export const setUpdate = (flag) => ({
  type: SET_UPDATE,
  flag,
});

export const setRedirectKey = (providerId) => ({
  type: SET_REDIRECT_KEY,
  providerId,
});

export const setUpdatedProviderInStore = (payload) => ({
  type: SET_UPDATED_PROVIDER_IN_STORE,
  payload,
});
