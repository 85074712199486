import React, { useCallback } from 'react';
import { Form as AntdForm } from 'antd';
import { useDispatch } from 'react-redux';

import Form from '../../../../../../../components/Form';
import Button from '../../../../../../../components/Button';
import Notification from '../../../../../../../components/Notification';

import { apiUrls } from '../../../../../../../api/constants';
import useRedirect from '../../../../../../../hooks/useRedirect';
import SuccessMessages from '../../../../../../../lib/successMessages';
import { formId, UiRoutes, listIds } from '../../../../../../../lib/constants';
import formFieldValueParser from '../../../../../../../lib/formFieldValuesParser';
import { deleteTabData } from '../../../../../../../store/actions/navigation';

import BillingForm from '../Component/BillingForm';

const AddBilling = ({ labels }) => {
  const {
    params, params: { id, tabId }, push, generatePath,
  } = useRedirect();
  const [form] = AntdForm.useForm();
  const dispatch = useDispatch();

  const goBack = useCallback(() => {
    dispatch(deleteTabData({ tabId }));
    push(generatePath(UiRoutes.editProviderWithTabId, { ...params }));
  }, [dispatch, tabId, push, generatePath, params]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.BILL_SAVED_SUCCESSFULLY, success: true });
      goBack();
    }
  }, [goBack]);

  const parser = useCallback((values) => formFieldValueParser(values, {
    date: [
      'effectiveDateFrom',
      'effectiveDateTo',
    ],
  }), []);

  return (
    <Form
      section
      form={form}
      tabId={tabId}
      name={formId.ADD_BILLING}
      onRequestComplete={onRequestComplete}
      url={apiUrls.ADD_BILLING}
      formId={formId.ADD_BILLING}
      parser={parser}
      listId={listIds.SETTING_PROVIDER_BILLING}
      extraData={{ providerId: id }}
      initialData={{ billtoSpeciality: true, billtoAddress: true, isAllPayers: false }}
    >
      <div className="pannel back-pannel mr-top-20">
        <div className="pannel-heading">
          <p role="presentation" id="provider_billing_goBack" className="back-arrow" onClick={goBack}>{labels.get('link.goBack')}</p>
        </div>
        <div className="pannel-body">
          <BillingForm
            form={form}
            labels={labels}
            goBack={goBack}
          />
          <div className="btns-action">
            <Button id="provider_billing_save" className="btn btn-success min-wt-86" type="submit">{labels.get('buttons.save')}</Button>
            <Button id="provider_billing_cancel" className="btn" onClick={() => form.resetFields()}>{labels.get('buttons.cancel')}</Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default AddBilling;
