import React, { useCallback, useState } from 'react';
import { Row } from 'antd';

import WithLabel from '../../../../hoc/withLabel';
import { labelPaths } from '../../../../lib/constants';

import Input from '../../../../components/Form/Input';

import PayerModalTable from '../PayerModalTable';

import './CPTICDMapping.scss';

const CPTPayerMapping = ({
  onSelectCPT,
  selectedCPT,
  labels,
  onICDCheckBoxClick,
  ...props
}) => {
  const { cptCode, description } = selectedCPT || {};

  const [icdFilters, setICDCodeFilter] = useState({ ICDCodeText: 'H00' });

  const onICDCodeFilterChange = useCallback((e) => {
    const { target: { value } } = e;
    if (value && value.length >= 3) {
      setICDCodeFilter({
        ICDCodeText: value,
      });
    }
  }, [setICDCodeFilter]);

  return (
    <div className="cpt-form cpt-icd-map">
      <Row>
        <div className="required-feilds top-feild-mng">
          <h2 className="h2-title">{labels.get('titles.cptIcdMapping')}</h2>
        </div>
      </Row>
      <div className="merge-table">
        <div className="app-table cpt-table-wrap">
          <table>
            <thead>
              {cptCode && (
                <tr>
                  <th width="120">{labels.get('labels.CPTCode')}</th>
                  <th width="120">{cptCode}</th>
                  <th width="120">
                    {labels.get('labels.CPTDescription')}
                  </th>
                  <th>
                    {description}
                  </th>
                </tr>
              )}
              <tr>
                <th width="120">{labels.get('labels.ICDCode')}</th>
                <th colSpan={2}>
                  <div className="search-input mr-tb-4">
                    <div className="auto-complete-search">
                      <Input
                        placeholder={labels.get('placeHolders.icd')}
                        id="2"
                        labelSpan="0"
                        inputSpan="24"
                        onChange={onICDCodeFilterChange}
                        isFormItem={false}
                      />
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <PayerModalTable
          filters={{ ...icdFilters }}
          labels={labels}
          onICDCheckBoxClick={onICDCheckBoxClick}
          {...props}
        />
      </div>
    </div>
  );
};

export default WithLabel(CPTPayerMapping, labelPaths.ADD_ICD_CPT_MAPPING);
