import React from 'react';

import { dateFormat } from '../../../../../lib/constants';
import dateFormatter from '../../../../../lib/dateFormatter';
import AmountAndBalance from './financialDetailsComponents/amountAndBalance';
import FinancialDetailCommonGroup from './financialDetailsComponents/financialDetailGroup1';
import FinancialDetailGroup2 from './financialDetailsComponents/financialDetailGroup2';
import {
  FinancialDetailGroup3,
  FinancialDetailWriteOffReason,
} from './financialDetailsComponents/financialDetailGroup3';
import FinancialDetailsHeader from './financialDetailsComponents/header';
import { addTimeZoneInDate } from '../../../../../lib/util';

const InsuranceAdjustmentForm = ({ financialDetail, isUseAdjustmentReason, isReversalForm }) => {
  const createdOn = financialDetail?.createdOn ? `on ${dateFormatter(addTimeZoneInDate(financialDetail.createdOn), dateFormat)}` : '';
  return (
    <div className="max-width-100 table-layout-field">
      <form className="max-width-100">
        <FinancialDetailsHeader />
        <div className="feild-row display-view  group-shadow">
          <AmountAndBalance
            financialDetail={financialDetail}
            insuranceAdjustment
            isReversalForm={isReversalForm}
          />
          <FinancialDetailWriteOffReason
            financialDetail={financialDetail}
            isUseAdjustmentReason={isUseAdjustmentReason}
          />
          <FinancialDetailGroup2 createdOn={createdOn} financialDetail={financialDetail} />
          <FinancialDetailGroup3 financialDetail={financialDetail} />
          <FinancialDetailCommonGroup financialDetail={financialDetail} />
        </div>
      </form>
    </div>
  );
};

export default InsuranceAdjustmentForm;
