import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  UiRoutes, formId, labelPaths, listId, locationAddressObjectKeys,
} from '../../../lib/constants';
import rights from '../../../lib/rights';
import SuccessMessages from '../../../lib/successMessages';
import formFieldValuesParser from '../../../lib/formFieldValuesParser';

import { apiUrls } from '../../../api/constants';
import useRedirect from '../../../hooks/useRedirect';
import useRights from '../../../hooks/useRights';

import RadioGroup from '../../../components/Form/RadioGroup';
import Notification from '../../../components/Notification';
import SavedNavigationBar from '../../../components/SavedNavigationBar';
import WithLabel from '../../../hoc/withLabel';
import { deleteTabData } from '../../../store/actions/navigation';
import { clearReadData } from '../../../store/actions/crudWithoutTab';

import General from './General';
import ExamRoomContainer from './ExamRoom';

import '../newLocation.scss';

const Components = {
  general: General,
  examRoom: ExamRoomContainer,
};

function Locations(props) {
  const {
    labels,
  } = props;
  const {
    params, replace, generatePath,
  } = useRedirect();
  const dispatch = useDispatch();
  const contract = useSelector((state) => state?.navigation?.get('contract'));
  const { id, tab, tabId } = params;
  const [isLocationViewOnly, isEditAllowed] = useRights([
    rights.location_view_only, rights.location]);

  const tabList = React.useMemo(() => [
    { value: 'general', label: labels.get('tabs.general') },
    { value: 'examRoom', label: labels.get('tabs.examRoom') },
  ], [labels]);

  const tabSwitchHandler = (event) => {
    const { value } = event.target;
    replace(generatePath(UiRoutes.editLocationWithTabId, { id, tabId, tab: value }));
  };

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.LOCATION_UPDATED_SUCCESSFULLY, success: true });
      dispatch(clearReadData('location'));
      dispatch(clearReadData('NextAppointmentLocation'));
      if (contract) {
        Object.keys(contract || {}).forEach((key) => {
          if (contract[key]?.tabId && contract[key]?.tabId !== 'parent') {
            dispatch(deleteTabData({ tabId: contract[key].tabId }));
          }
        });
      }
    }
  }, [contract, dispatch]);

  const parser = useCallback((locationsInfo) => {
    const formValues = formFieldValuesParser(locationsInfo, {
      int: [
        'primaryPhoneExt',
        'secondaryPhoneExt',
        'fda',
        'clia',
      ],
      bool: [
        'isDisplayinScheduler',
        'isCommunityView',
        'isActive',
        'autoEligibilityCheck',
        'isAutoConfirmation'],
    });
    const parsedJson = { location: {}, address: {} };
    Object.keys(formValues)?.forEach((key) => {
      if (locationAddressObjectKeys.includes(key)) {
        parsedJson.address[`${key}`] = formValues[key];
      } else {
        parsedJson.location[`${key}`] = formValues[key];
      }
    });
    return parsedJson;
  }, []);

  const initialDataParser = useCallback(
    (currentLocation) => formFieldValuesParser(currentLocation,
      {
        string: ['fda', 'clia', 'primaryPhoneExt', 'secondaryPhoneExt'],
      }), [],
  );

  const Active = Components[tab];

  return (
    <SavedNavigationBar
      listName="Locations List"
      parentPath={UiRoutes.locations}
      title={(<h1>{labels.get('header.locations')}</h1>)}
      rightChildren={(
        <RadioGroup
          tabList={tabList}
          onChange={tabSwitchHandler}
          value={tab}
          id="locations_tabs"
        />
      )}
    >
      <Active
        parser={parser}
        formId={formId.EDIT_LOCATION_GENERAL}
        initialDataParser={initialDataParser}
        getUrl={`${apiUrls.GET_LOCATION}/${id}`}
        url={`${apiUrls.UPDATE_LOCATION}/${id}`}
        onRequestComplete={onRequestComplete}
        editKey={id}
        listId={listId.LOCATIONS}
        isUpdate
        // tabId={tabId}
        isLocationViewOnly={isLocationViewOnly && !isEditAllowed}
      />
    </SavedNavigationBar>
  );
}

export default WithLabel(
  Locations, labelPaths.EDIT_LOCATION,
);
