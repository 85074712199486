import React, {
  useCallback, useMemo,
} from 'react';
import { Row, Col, Form as AntdForm } from 'antd';
import clone from 'lodash/clone';
import moment from 'moment';

import Events from '../../../../../../../lib/events';
import { getName } from '../../../../../../../lib/util';
import { toDateValidator } from '../../../../../../../lib/validator';
import dateFormatter from '../../../../../../../lib/dateFormatter';
import {
  timeFormat, dateFormatWithHour, enumMasterCode, enums,
} from '../../../../../../../lib/constants';

import useMasterId from '../../../../../../../hooks/useMasterId';

import { Input, DatePicker, Radio } from '../../../../../../../components/Form/inputFields';
import TimeCell from '../../../../../../../components/Cells/TimeCell';

import { apiUrls } from '../../../../../../../api/constants';

import WiredSelect from '../../../../../../../wiredComponents/Select';
import InsuranceDetailsSelect from '../../../../../../../wiredComponents/InsuranceDetailsSelect';
import LocationGridAutoComplete from '../../../../../../../wiredComponents/LocationGridAutoComplete';
import ProviderGridAutoComplete from '../../../../../../../wiredComponents/ProviderGridAutoComplete';
import ReferringPhysicianAutoComplete from '../../../../../../../wiredComponents/ReferringPhysicianGridAutoComplete';

import AllowedProcedure from './AllowedProcedure';
import PatientDocuments from './PatientDocuments';

import '../Edit/authorization.scss';

const labelRender = (fName, mName, lName, date) => (
  <div className="option headerWrapper">
    <span>{getName(fName, mName, lName)}</span>
    <span className="">
      (
      <TimeCell value={date} format={dateFormatWithHour} />
      )
    </span>
    <span>
      <TimeCell value={date} format={timeFormat} />
    </span>
  </div>
);

const dateOfServiceDropDownRenderer = (options = []) => {
  if (!Object.values(options).length) return <div />;
  return (
    <div className="option-header-main-wrapper">
      <div className="option headerWrapper schedular-option-select">
        <span className="header">
          Provider
        </span>
        <span className="header">
          DOS
        </span>
        <span className="header">
          Time
        </span>
      </div>
      <div>
        {options}
      </div>
    </div>
  );
};

const dateOfServiceOptionRenderer = (options) => options.map((item) => ({
  value: item.value,
  item,
  name: (
    <div className="option labelWrapper" key={item.appointmentId}>
      <span>
        {getName(item?.providerFirstName, item?.providerMiddleName, item?.providerLastName)}
      </span>
      <span>
        <TimeCell value={item?.appointmentStartDateTime?.dateString} />
      </span>
      <span>
        <TimeCell value={item?.appointmentStartDateTime?.dateString} format={timeFormat} />
      </span>
    </div>
  ),
  label: labelRender(
    item?.providerFirstName,
    item?.providerMiddleName,
    item?.providerLastName,
    item?.appointmentStartDateTime?.dateString,
  ),
}));

const dosOptions = [
  { label: 'DOS from appointments', value: 'appointments' },
  { label: 'Manual', value: 'manual' }];

const AuthorizationInformation = (props) => {
  const {
    labels, cptCodes, setCptCodes, patientId, formData, form, isUpdate, appointmentId, tabId,
  } = props;

  const { Authorization } = useMasterId([enumMasterCode.document_authorization],
    [enums.PATIENT_DOCUMENT_CATEGORY]);

  const handleDateOfServiceSelect = useCallback((value, data) => {
    const dateOfServices = data?.map((appt) => ({
      dateOfService: appt?.item?.item?.appointmentStartTime,
      appointmentId: appt?.item?.item?.appointmentId,
    }));
    form.customSetFieldsValue({ dateOfServices });
  }, [form]);

  const onSelectInsurance = useCallback((insuranceDetailsId, all) => {
    form.setFieldsValue({
      insuranceProfileId: insuranceDetailsId,
      insuranceId: all?.pId,
    });
  }, [form]);

  const handlePatientDocumentUploadSuccess = useCallback((response, fileInfo) => {
    if (response?.documentid) {
      const documentIds = clone(form.getFieldValue('documentIds') || []);
      documentIds.push(response.documentid);
      const patientDocumentList = clone(form.getFieldValue('patientDocumentList')) || [];
      patientDocumentList.push({
        documentId: response?.documentid,
        ...fileInfo,
      });
      form.customSetFieldsValue({ documentIds, patientDocumentList });
    }
    Events.trigger(`patient-document${patientId}`);
  }, [form, patientId]);

  const handleDocumentSelectChange = useCallback((a, allData) => {
    form.customSetFieldsValue({ patientDocumentList: allData?.map((data) => data.item) });
  }, [form]);

  const appointmentValue = useMemo(() => (appointmentId === 0
    ? null : appointmentId), [appointmentId]);

  const updateDetail = useMemo(() => {
    const createdBy = formData?.createdByName ? `by ${formData.createdByName}` : '';
    const createdDate = formData?.createdOn ? `on ${dateFormatter(formData.createdOn)}` : '';
    const modifiedBy = formData?.modifiedByName ? `by ${formData.modifiedByName}` : '';
    const modifiedOn = formData?.modifiedOn ? `on ${dateFormatter(formData.modifiedOn)}` : '';
    return {
      add: `${createdBy} ${createdDate}`,
      update: `${modifiedBy} ${modifiedOn}`,
    };
  }, [formData]);

  return (
    <>
      <div style={{ padding: '8px 8px 0 8px' }}>
        {isUpdate && updateDetail?.add && (
        <p className="paragraph">
          {`Added ${updateDetail?.add}.`}
        </p>
        )}
        {isUpdate
        && updateDetail?.update && (
        <p className="paragraph">
          {`Updated ${updateDetail?.update}.`}
        </p>
        )}
        <Row gutter={16}>
          <Col lg={8} md={12}>
            <Input
              id="2"
              label={labels.get('labels.authorizationNumber')}
              name="authorizationNo"
              required
              maxValueLength={20}
              placeholder="Enter Authorization No"
            />
            <Input
              id="3"
              label={labels.get('labels.name')}
              name="name"
              charOnly
              maxValueLength={20}
              placeholder="Enter Name"
            />

            <div className="display-none">
              <Input name="insuranceId" />
            </div>
            <InsuranceDetailsSelect
              selectProps={{
                showSearch: true,
              }}
              id="insuranceProfile"
              name="insuranceProfileId"
              patientId={patientId}
              label={labels.get('labels.insurancePolicy')}
              labelSpan={10}
              inputSpan={14}
              // selectNodes={[insuranceProfile?.InsuranceProfileId]}
              onSelect={onSelectInsurance}
              initialValue={{
                insuranceId: formData?.insuranceId,
                InsuranceProfileId: formData?.insuranceProfileId,
              }}
              tabId={tabId}
              // includeSelfProfile
              includeInActive
              includePayerName
              required
              placeholder="Select an option"
              extraClass="insurence-info-error"
            />
            <ReferringPhysicianAutoComplete
              label={labels.get('labels.referringPhysician')}
              id="referringPhysicianId"
              name="referringPhysicianNPI"
              labelSpan="10"
              inputSpan="14"
              form={form}
              initialValue={isUpdate && formData?.referringPhysicianNPI
                && formData?.referringPhysicianName && {
                name: formData?.referringPhysicianName,
                value: formData?.referringPhysicianNPI,
                npi: formData?.referringPhysicianNPI,
              }}
              valueInString
            />
            {Authorization && (
            <WiredSelect
              label={labels.get('titles.documents')}
              name="documentIds"
              url={apiUrls.GET_PATIENT_DOCUMENT}
              nameAccessor="documentDisplayName"
              valueAccessor="documentId"
              labelSpan={10}
              inputSpan={14}
              id={`patient-document${patientId}`}
              dataTestId="documentIds"
              selectProps={{
                mode: 'multiple',
              }}
              onChange={handleDocumentSelectChange}
              params={{
                patientId,
                categoryId: Authorization,
              }}
            />
            )}
          </Col>
          <Col lg={8} md={12}>
            <ProviderGridAutoComplete
              label={labels.get('labels.provider')}
              name="providerId"
              id="provider"
              labelSpan="10"
              inputSpan="14"
              placeholder="Select Provider"
              selectProps={{
                showSearch: true,
                getPopupContainer: (trigger) => trigger?.parentNode,
              }}
              params={{ IncludeInActive: false }}
              initialValue={formData?.providerId && formData?.providerName
                ? { name: formData.providerName, value: formData.providerId } : null}
            />
            <LocationGridAutoComplete
              id="location"
              label={labels.get('labels.location')}
              name="locationId"
              placeholder="Select Location"
              required
              selectProps={{
                showSearch: true,
                getPopupContainer: (trigger) => trigger?.parentNode,
              }}
              labelSpan="10"
              inputSpan="14"
              initialValue={formData?.locationId && formData?.locationName
                ? { name: formData.locationName, value: formData.locationId } : null}
            />
            <Input
              id="8"
              label={labels.get('labels.visitsAllowed')}
              name="visitedAllowed"
              numberOnly
              maxValueLength={4}
              placeholder="Enter Visits Allowed"
              required
            />
            <Input
              id="9"
              label={labels.get('labels.unitsAllowed')}
              name="unitsAllowed"
              numberOnly
              maxValueLength={4}
              placeholder="Enter Units Allowed"
            />
          </Col>
          <Col lg={8} md={12}>
            <Radio
              options={dosOptions}
              label={labels.get('labels.dateOfService')}
              name="dosOptionsSelected"
              labelSpan="10"
              inputSpan="14"
            />
            <AntdForm.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => (
                prevValues.dosOptionsSelected !== currentValues.dosOptionsSelected)}
            >
              {({ getFieldValue }) => {
                if (getFieldValue('dosOptionsSelected') === 'appointments') {
                  return (
                    <WiredSelect
                      name="appointments"
                      id="DateOfServices"
                      url={`${apiUrls.GET_APPOINTMENTS_LIST}/${patientId}`}
                      label="Select DOS"
                      nameAccessor="appointmentId"
                      valueAccessor="appointmentId"
                      required
                      optionRenderer={dateOfServiceOptionRenderer}
                      selectProps={{
                        dropdownRender: dateOfServiceDropDownRenderer,
                        mode: 'multiple',
                      }}
                      value={appointmentValue}
                      params={{ IsUpcomingAppointments: true }}
                      onChange={(value, data) => handleDateOfServiceSelect(value, data)}
                    />
                  );
                }
                return (
                  <DatePicker
                    id="12"
                    label="Select DOS"
                    name="dateOfServicesManual"
                    required
                    disabledDate={(current) => current && current <= moment().subtract(100, 'years')}
                  />
                );
              }}
            </AntdForm.Item>
            <Input
              id="10"
              label={labels.get('labels.unitsConsumed')}
              name="unitsConsumed"
              numberOnly
              disabled
              maxValueLength={4}
              placeholder="Enter Visits Consumed"
            />
            <DatePicker
              id="12"
              label={labels.get('labels.effectiveFrom')}
              name="effectiveDateFrom"
              required
              disabledDate={(current) => current && current <= moment().subtract(100, 'years')}
            />
            <DatePicker
              id="13"
              label={labels.get('labels.effectiveTo')}
              name="effectiveDateTo"
              rules={[({ getFieldValue }) => toDateValidator(getFieldValue, 'effectiveDateFrom')]}
              required
              dependencies={['effectiveDateFrom']}
              disabledDate={(current) => current && current <= moment().subtract(100, 'years')}
            />
          </Col>
        </Row>
      </div>
      <Row gutter={16}>
        <Col md={24}>
          <AntdForm.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => (
              prevValues.documentIds !== currentValues.documentIds)}
          >
            {({ getFieldValue }) => (
              <PatientDocuments
                title="View Selected Documents"
                patientId={patientId}
                categoryId={Authorization}
                labels={labels}
                documents={getFieldValue('patientDocumentList')}
                onUploadSuccess={handlePatientDocumentUploadSuccess}
              />
            )}
          </AntdForm.Item>

        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <AllowedProcedure
            labels={labels}
            allowedProcedure={cptCodes}
            setAllowedProcedure={setCptCodes}
          />
        </Col>
      </Row>
    </>
  );
};
export default AuthorizationInformation;
