import React, {
  useCallback, useState,
} from 'react';
import moment from 'moment';
import { Form as AntdForm } from 'antd';

import SelectWithoutTab from '../../../../../../wiredComponents/Select/selectBoxV2';
import Select from '../../../../../../wiredComponents/Select';
import SelectBox from '../../../../../../components/Form/SelectBox';
import Notification from '../../../../../../components/Notification';

import { apiUrls } from '../../../../../../api/constants';

import TimeCell from '../../../../../../components/Cells/TimeCell';
import AuthorizationForm from '../../../../../Dashboard/Tabs/Lists/components/Authorization/Add/AuthorizationForm';
import EditAuthorization from '../../../../../Dashboard/Tabs/Lists/components/Authorization/Edit/EditAuthorizationForm';
import Case from '../../../../../Dashboard/Tabs/Lists/components/Cases/CaseForm/Case';
import EditCase from '../../../../../Dashboard/Tabs/Lists/components/Cases/CaseForm/EditCase';

import useRights from '../../../../../../hooks/useRights';
import rights from '../../../../../../lib/rights';
import Modals from '../../../../../../components/Modal';
import WithLabel from '../../../../../../hoc/withLabel';
import { labelPaths } from '../../../../../../lib/constants';
import SuccessMessages from '../../../../../../lib/successMessages';

const AddAuthorization = WithLabel(AuthorizationForm, labelPaths.ADD_AUTHORIZATION);
const EditAuthorizationForm = WithLabel(EditAuthorization, labelPaths.EDIT_AUTHORIZATION);
const CaseForm = WithLabel(Case, labelPaths.PATIENT_LISTS);
const EditCaseForm = WithLabel(EditCase, labelPaths.PATIENT_LISTS);

let caseReFetchOptions = null;
let authorizationReFetchOptions = null;

const showNotificationAndRefetchData = ({
  response, toggleModal, id, callback, message, callbackProps = {},
}) => {
  if (response) {
    Notification({ message, success: true });
    if (toggleModal) toggleModal();
    if (callback) callback({ patientId: id, ...callbackProps });
  }
};

const labelRender = (name, date) => (
  <div>
    <span>{name}</span>
    {date?.dateOfService && (
    <span className="mr-lt-5">
      (
      <TimeCell value={date.dateOfService} />
      )
    </span>
)}
  </div>
);

const caseLabelRender = (name, date, toDate) => (
  <div>
    <span>{name}</span>
    <span className="mr-lt-5">
      (
      <TimeCell value={date} />
      <span> to </span>
      {toDate && (
        <TimeCell
          value={toDate}
        />
      )}
      )
    </span>
  </div>
);

const caseDropDownRenderer = (options = []) => {
  if (!Object.values(options).length) return <div />;
  return (
    <>
      <div className="option headerWrapper encounter-case schedular-option-select">
        <span className="header">
          Case Type
        </span>
        <span className="header">
          Effective Dates
        </span>
      </div>
      <div>
        {options}
      </div>
    </>
  );
};

const authorizationDropDownRenderer = (options = []) => {
  if (!Object.values(options).length) return <div />;
  return (
    <>
      <div className="option headerWrapper schedular-option-select">
        <span className="header">
          Authorization No.
        </span>
        <span className="header">
          Date Of Service
        </span>
      </div>
      <div>
        {options}
      </div>
    </>
  );
};

const caseOptionRenderer = (options) => options.map((item) => ({
  value: item.value,
  item,
  name: (
    <div className="option encounter-case-options" key={item.authorizationId}>
      <span>
        {item.name}
      </span>
      <span>
        <TimeCell value={item.effectiveDateFrom} />
        <span> to </span>
        <TimeCell value={item.effectiveDateTo} />
      </span>
    </div>
  ),
  label: caseLabelRender(item.name, item.effectiveDateFrom, item.effectiveDateTo),
}));

const AuthorizationAndCase = (props) => {
  const {
    patientId, insuranceProfileId, form,
    insuranceProfileDetailId, tabId, serviceDate,
  } = props;
  const [isShowAuthorizationModal, setAuthorizationModalVisibility] = useState(null);
  const [isShowEditAuthorizationModal, setEditAuthorizationModalVisibility] = useState(null);
  const [isShowCaseModal, setCaseModalVisibility] = useState(null);
  const [isShowEditCaseModal, setEditCaseModalVisibility] = useState(null);
  const editAuthorizationId = form.getFieldValue('authorizationId');
  const editCaseId = form.getFieldValue('caseId');
  const [
    isAddAuthorizationAuthenticated,
    isAddCaseAuthenticated,
  ] = useRights([rights.create_authorization, rights.create_clinical_cases]);

  const authorizationOptionRenderer = useCallback((options) => options?.map((item) => ({
    value: item.value,
    item,
    name: (
      <div className="flex-space-between option manage-list-layout" key={item.authorizationId}>
        <span className="flex">
          {item.name}
        </span>
        <span className="flex flex-dir-column">
          {item?.dateOfServices?.map(({ dateOfService }) => (
            <TimeCell value={dateOfService} />
          ))}
        </span>
      </div>
    ),
    label: labelRender(item.name, item?.dateOfServices?.[0]),
  })), []);

  const toggleEditAuthorizationModal = useCallback(() => {
    setEditAuthorizationModalVisibility((data) => !data);
  }, [setEditAuthorizationModalVisibility]);

  const toggleAuthorizationModal = useCallback(() => {
    setAuthorizationModalVisibility((data) => !data);
  }, [setAuthorizationModalVisibility]);

  const toggleCaseModal = useCallback(() => {
    setCaseModalVisibility((data) => !data);
  }, [setCaseModalVisibility]);

  const toggleEditCaseModal = useCallback(() => {
    setEditCaseModalVisibility((data) => !data);
  }, [setEditCaseModalVisibility]);

  const setAuthorizationReFetchOptions = useCallback((getOptions) => {
    authorizationReFetchOptions = getOptions;
  }, []);

  const setCaseReFetchOptions = useCallback((getOptions) => {
    caseReFetchOptions = getOptions;
  }, []);

  const onAddAuthorizationRequestComplete = useCallback(({ response }) => {
    showNotificationAndRefetchData({
      response,
      toggleModal: toggleAuthorizationModal,
      id: patientId,
      callback: authorizationReFetchOptions,
      message: SuccessMessages.AUTHORIZATION_SAVED_SUCCESSFULLY,
      callbackProps: { isReFetch: true },
    });
  }, [toggleAuthorizationModal, patientId]);

  const onEditAuthorizationRequestComplete = useCallback(({ response }) => {
    showNotificationAndRefetchData({
      response,
      message: SuccessMessages.AUTHORIZATION_UPDATED_SUCCESSFULLY,
      toggleModal: toggleEditAuthorizationModal,
      id: patientId,
      callback: authorizationReFetchOptions,
      callbackProps: { isReFetch: true },
    });
  }, [toggleEditAuthorizationModal, patientId]);

  const onAddCaseRequestComplete = useCallback(({ response }) => {
    showNotificationAndRefetchData({
      response,
      toggleModal: toggleCaseModal,
      id: patientId,
      callback: caseReFetchOptions,
      message: SuccessMessages.CASE_SAVED_SUCCESSFULLY,
    });
  }, [toggleCaseModal, patientId]);

  return (
    <>
      <AntdForm.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => (
          prevValues.authorizationId !== currentValues.authorizationId)}
      >
        {({ getFieldValue }) => (
          <div className="add-input add-input-gap auth-sel-box">
            <SelectWithoutTab
              id={`authorizationId-${patientId}`}
              name="authorizationId"
              url={`${apiUrls.AUTHORIZATION_LIST_DROPDOWN}`}
              label="Authorization"
              nameAccessor="authorizationNo"
              valueAccessor="authorizationId"
              reFetchOptions={setAuthorizationReFetchOptions}
              inputSpan="15"
              labelSpan="8"
              optionRenderer={authorizationOptionRenderer}
              selectProps={{
                optionLabelProp: 'label',
                dropdownRender: authorizationDropDownRenderer,
              }}
              params={patientId && {
                PatientId: patientId,
                insuranceProfileDetailId,
                insuranceProfileId,
              }}
            />
            {getFieldValue('authorizationId') ? (
              <span
                className="edit-icon sprite-img-before"
                onClick={toggleEditAuthorizationModal}
                role="presentation"
              />
            ) : null}
            {isAddAuthorizationAuthenticated ? (
              <span
                className="add-icon sprite-img-before"
                onClick={toggleAuthorizationModal}
                role="presentation"
              />
            ) : null}
          </div>
        )}
      </AntdForm.Item>
      <AntdForm.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => (
          prevValues.caseId !== currentValues.caseId)}
      >
        {({ getFieldValue }) => (
          <div className="add-input add-input-gap">
            <Select
              id={`caseId-${patientId}`}
              name="caseId"
              url={apiUrls.CASES_LIST}
              label="Case"
              nameAccessor="caseTypeName"
              valueAccessor="caseId"
              Component={SelectBox}
              reFetchOptions={setCaseReFetchOptions}
              inputSpan="15"
              labelSpan="8"
              params={{ patientId }}
              optionRenderer={caseOptionRenderer}
              selectProps={{
                optionLabelProp: 'label',
                dropdownRender: caseDropDownRenderer,
                dropdownMatchSelectWidth: 300,
              }}
            />
            {getFieldValue('caseId') && (
            <span
              className="edit-icon sprite-img-before"
              onClick={toggleEditCaseModal}
              role="presentation"
            />
            )}
            {isAddCaseAuthenticated && (
            <span
              className="add-icon sprite-img-before"
              onClick={toggleCaseModal}
              role="presentation"
            />
            )}
          </div>
        )}
      </AntdForm.Item>

      <Modals
        visible={isShowAuthorizationModal}
        toggleModal={toggleAuthorizationModal}
        destroyOnClose
        footer={null}
        width="1200px"
        wrapClassName="add-authorization-modal"
        title="Add Authorization"
      >
        <AddAuthorization
          onRequestComplete={onAddAuthorizationRequestComplete}
          onCancelButtonClicked={toggleAuthorizationModal}
          patientId={patientId}
          destroyOnClose
        />
      </Modals>
      <Modals
        visible={isShowEditAuthorizationModal}
        toggleModal={toggleEditAuthorizationModal}
        destroyOnClose
        footer={null}
        width="1200px"
        wrapClassName="add-authorization-modal"
        title="Edit Authorization"
      >
        <EditAuthorizationForm
          onRequestComplete={onEditAuthorizationRequestComplete}
          onCancelButtonClicked={toggleEditAuthorizationModal}
          destroyOnClose
          params={{ id: patientId, tabId, authorizationId: editAuthorizationId }}
        />
      </Modals>
      <Modals
        visible={isShowCaseModal}
        toggleModal={toggleCaseModal}
        destroyOnClose
        footer={null}
        width="1200px"
        wrapClassName="add-case-modal"
        title="Add Case"
      >
        <CaseForm
          onAddRequestComplete={onAddCaseRequestComplete}
          onCancelButtonClicked={toggleCaseModal}
          params={{ id: patientId, tabId }}
          destroyOnClose
          serviceDate={serviceDate && moment(serviceDate)}
        />
      </Modals>
      <Modals
        visible={isShowEditCaseModal}
        toggleModal={toggleEditCaseModal}
        destroyOnClose
        footer={null}
        width="1200px"
        wrapClassName="add-case-modal"
        title="Edit Case"
      >
        <EditCaseForm
          onUpdateRequestComplete={onAddCaseRequestComplete}
          onCancelButtonClicked={toggleEditCaseModal}
          destroyOnClose
          params={{ id: patientId, tabId, caseId: editCaseId }}
        />
      </Modals>
    </>
  );
};

export default AuthorizationAndCase;
