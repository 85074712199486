import { Form as AntdForm } from 'antd';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import orderBy from 'lodash/orderBy';
import findLast from 'lodash/findLast';

import { apiUrls } from '../../../api/constants';
import { getString } from '../../../lib/util';
import useCRUD from '../../../hooks/useCRUD';
import useReduxStoreWithId from '../../../hooks/useReduxStoreWithId';
import useCrudWithEffects from '../../../hooks/useCrudWithEffects';

import Button from '../../../components/Button';
import Form from '../../../components/Form';
import Modals from '../../../components/Modal';
import SelectBox from '../../../components/SelectBox';
import WithLabel from '../../../hoc/withLabel';
import { formId, labelPaths, listIds } from '../../../lib/constants';
import NDCForm from '../../../screens/Settings/BusinessEntity/EntityDetails/Forms/Billing/Tabs/Ndc/Components/NDCForm';

const AddNDCForm = ({
  form, labels, handleNDCComponent,
}) => (
  <div className="pannel back-pannel mr-top-20">
    <div className="pannel-body">
      <NDCForm labels={labels} />
      <Form.Section>
        <div className="group-btns">
          <Button id="exam_type_save" className="btn btn-success min-wt-86" onClick={() => form.submit()}>{labels.get('buttons.save')}</Button>
          <Button
            id="exam_type_cancel"
            className="btn min-wt-86"
            onClick={handleNDCComponent}
          >
            {labels.get('buttons.cancel')}
          </Button>
        </div>
      </Form.Section>
    </div>
  </div>
);

const NdcMapping = ({
  labels, jcodeMappingData, isMappingModalVisible, toggleMappingModal,
  flaskValue, jcodeRef, selectedNDCForJcode, setSelectedNDCForJcode, selectedDosageForLucentis,
}) => {
  const [form] = AntdForm.useForm();
  const [businessEntityIdRedux,, setBusinessEntityId] = useReduxStoreWithId({ id: 'businessEntityId-Encounter-NDCMapping' });
  const businessEntityId = businessEntityIdRedux?.get('data');
  const [isNDCVisible, setNDCVisible] = useState(false);
  const [ndcFormDataRedux,, setNDCFormData] = useReduxStoreWithId({ id: 'ndcFormData-Encounter-NDCMapping' });
  const ndcFormData = ndcFormDataRedux?.get('data') || [];

  const handleBusinessEntityData = useCallback((businessEntityData) => {
    const parentId = businessEntityData?.result?.find(
      ({ parentEntityId }) => parentEntityId === null)?.businessEntityId;
    setBusinessEntityId(parentId);
  }, [setBusinessEntityId]);

  const [getBusinessEntityData] = useCrudWithEffects({
    id: listIds.BUSINESS_ENTITIES,
    url: apiUrls.GET_BUSINESS_ENTITY,
    callBack: handleBusinessEntityData,
    type: 'read',
  });

  const getAssociateNDCData = useCRUD({
    id: listIds.GET_ASSOCIATE_NDC_FOR_JCODES,
    url: apiUrls.GET_ASSOCIATE_NDC_FOR_JCODES,
    type: 'read',
  })[3];

  useEffect(() => {
    if (flaskValue === 'green') {
      const ndcDosageData = findLast(ndcFormData, (item) => `${item?.ndcQuantity} mg` === selectedDosageForLucentis);
      form.setFieldsValue({
        ndcId: selectedNDCForJcode
        || ndcDosageData?.businessEntityBillingNDCId,
      });
    }
  }, [flaskValue, ndcFormData, selectedNDCForJcode, selectedDosageForLucentis, form]);

  useEffect(() => {
    getBusinessEntityData();
  }, []);

  useEffect(() => {
    const parsedData = jcodeMappingData?.[0]?.billingNdcDetails?.filter((item) => item?.isActive);
    setNDCFormData((parsedData || []));
  }, [jcodeMappingData]);

  const handleNDCComponent = useCallback(() => {
    setNDCVisible(!isNDCVisible);
  }, [isNDCVisible, setNDCVisible]);

  const saveNdcData = useCallback((formValues) => {
    const clonedData = ndcFormData || [];
    clonedData.push(formValues);
    setNDCFormData(clonedData);
  }, [ndcFormData, setNDCFormData]);

  const parser = useCallback((formValues) => {
    saveNdcData({ ...formValues, isActive: formValues?.isActive ?? false });
    const parsedData = {
      billingNdcProcedureId: jcodeMappingData?.[0]?.billingNdcProcedureId,
      procedureId: jcodeMappingData?.[0]?.procedureId || jcodeMappingData?.id,
      billingNdcDetails: ndcFormData,
      businessEntityId,
    };
    return parsedData;
  }, [businessEntityId, jcodeMappingData, ndcFormData, saveNdcData]);

  const onRequestComplete = useCallback(() => {
    getAssociateNDCData({ jCodes: jcodeRef.current?.join(',') || jcodeMappingData?.id });
    setNDCVisible(false);
  }, [getAssociateNDCData, jcodeMappingData, jcodeRef, setNDCVisible]);

  const ndcOptions = useMemo(() => orderBy(ndcFormData, 'createdOn', 'desc')?.map((item) => ({
    name: getString([item?.ndcManufacturerNumber, item?.ndcProductNumber, item?.ndcPackageNumber, item?.drugName], '-'),
    value: item?.businessEntityBillingNDCId,
    ...item,
  }
  )), [ndcFormData]);

  const handleNDCSelection = useCallback((id, _all) => {
    const { item } = _all;
    setSelectedNDCForJcode(item?.businessEntityBillingNDCId);
  }, [setSelectedNDCForJcode]);
  return (
    <Modals
      width={600}
      destroyOnClose
      visible={isMappingModalVisible}
      toggleModal={toggleMappingModal}
      title="NDC Mapping"
      footer={null}
      afterClose={() => setNDCVisible(!isNDCVisible)}
    >
      <Form
        form={form}
        url={apiUrls.SAVE_BUSINESS_ENTITY_BILLING_NDC}
        formId={formId.SAVE_BUSINESS_ENTITY_BILLING_NDC}
        parser={parser}
        onRequestComplete={onRequestComplete}
        section
      >
        <div>
          <div className="j-code-ndc-wrap-main">
            <div className="j-code-ndc-wrap">
              <div className="key">Jcode</div>
              <div className="value">
                {getString([jcodeMappingData?.[0]?.procedureCode || jcodeMappingData?.cptCode, selectedDosageForLucentis || ''], ' - ')}
              </div>
            </div>
            <SelectBox
              label="NDC"
              name="ndcId"
              labelSpan={8}
              inputSpan={16}
              options={ndcOptions}
              onChange={handleNDCSelection}
            />
          </div>
          <div className="btn-right">
            <Button
              title="Add NDC"
              className="btn btn-success"
              onClick={handleNDCComponent}
            >
              Add NDC
            </Button>
          </div>
          {isNDCVisible && (
          <AddNDCForm
            form={form}
            labels={labels}
            handleNDCComponent={handleNDCComponent}
          />
          )}
        </div>
      </Form>
    </Modals>
  );
};
export default WithLabel(NdcMapping, labelPaths.SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_NDC);
