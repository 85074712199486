import React, { useEffect } from 'react';
import Loader from '../../components/Loader';

import useRedirect from '../../hooks/useRedirect';
import useCRUD from '../../hooks/useCRUD';
import { apiUrls } from '../../api/constants';
import Alert from '../../components/Alert';

import './EmailVerification.scss';

export default function EmailVerification() {
  const { location } = useRedirect();
  const { search } = location;

  const params = JSON.parse(`{"${decodeURI(search.substr(1)).replace(/&/g, '","').replace(/=/g, '":"')}"}`);
  const { code } = params;

  const [response, error, loading, verifyEmail] = useCRUD({
    id: 'verify-email',
    url: `${apiUrls.CONFIRM_EMAIL}?code=${code}`,
    type: 'read',
    shouldClearError: false,
  });
  useEffect(() => {
    verifyEmail();
  }, [verifyEmail]);

  return (
    <>
      {loading && <Loader />}
      <div className="message-area">
        <div className="message">{response && <Alert message="Email verified successfully" type="success" closable={false} />}</div>
        <div className="message">{error && <Alert message={error} type="error" closable={false} />}</div>
      </div>
    </>
  );
}
