import React, { useEffect } from 'react';
import Form from '../../../../../components/Form';
import WidgetLoader from '../../../../../components/WidgetLoader';
import useReduxStoreWithId from '../../../../../hooks/useReduxStoreWithId';
import useRedirect from '../../../../../hooks/useRedirect';
import useCRUD from '../../../../../hooks/useCRUD';
import { apiUrls } from '../../../../../api/constants';

const noOfColumns = 2;

const InsuranceInfo = ({ labels }) => {
  const [insuranceRedux,, setInsurance] = useReduxStoreWithId({ id: 'insurance-Encounter-Demographic' });
  const insurance = insuranceRedux?.get('data') || {};
  const { params: { id: PatientId } } = useRedirect();
  const [insuranceResponse, , loading, getInsuranceData, clear] = useCRUD({ id: `insurance-summary-${PatientId}`, url: apiUrls.GET_PATIENT_INSURANCE, type: 'read' });
  const [insuranceData, , insuranceLoading, getInsurance, clearInsurance] = useCRUD({ id: `insurance-details-${PatientId}`, url: apiUrls.GET_PATIENT_INSURANCE, type: 'read' });

  useEffect(() => {
    if (PatientId) {
      getInsuranceData({ PatientId });
    }
  }, [PatientId, getInsuranceData]);

  useEffect(() => {
    if (insuranceResponse && Array.isArray(insuranceResponse) && insuranceResponse.length) {
      const insuranceId = insuranceResponse?.find(({ isDefault, profileType, profileDetails }) => (isDefault || (profileType !== 'Self' && profileDetails?.length)))?.insuranceProfileId;
      if (insuranceId) getInsurance({}, `/${insuranceId}`);
      clear(true);
    }
  }, [insuranceResponse, getInsurance, clear]);

  useEffect(() => {
    if (
      insuranceData
      && Object.keys(insuranceData).length
    ) {
      const { insuraceProfileDetail } = insuranceData;
      setInsurance(insuraceProfileDetail.length ? insuraceProfileDetail[0] : []);
      clearInsurance(true);
    }
  }, [clearInsurance, insuranceData]);

  return (
    <div className="pannel">
      <div className="pannel-heading">
        <p>{labels.get('titles.insuranceInfo')}</p>
      </div>
      <div className="pannel-body">
        {(insuranceLoading || loading) && <WidgetLoader />}
        <Form
          noOfColumns={noOfColumns}
        >
          <Form.Column>
            <div>
              {/* <div className="info-row">
                <label>{labels.get('labels.policyType')}</label>
                <div className="lavel-value">Blue Cross Blue Shield</div>
              </div> */}
              <div className="info-row">
                <label>{labels.get('labels.payerName')}</label>
                <div className="lavel-value">{insurance?.payerName}</div>
              </div>
              <div className="info-row">
                <label>{labels.get('labels.groupName')}</label>
                <div className="lavel-value">{insurance?.groupName}</div>
              </div>

            </div>
          </Form.Column>
          <Form.Column>
            <div>
              <div className="info-row">
                <label>{labels.get('labels.memberNumber')}</label>
                <div className="lavel-value">{insurance?.memberNumber}</div>
              </div>
              <div className="info-row">
                <label>{labels.get('labels.groupNumber')}</label>
                <div className="lavel-value">{insurance?.groupNumber}</div>
              </div>
            </div>
          </Form.Column>
          <Form.Column>
            <div>
              <div className="info-row">
                <label>{labels.get('labels.coPayment')}</label>
                <div className="lavel-value">{insurance.coPayment?.toFixed(2)}</div>
              </div>
            </div>
          </Form.Column>
        </Form>
      </div>
    </div>
  );
};

export default InsuranceInfo;
