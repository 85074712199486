import React, { useMemo } from 'react';

import { apiUrls } from '../../../api/constants';
import { listId } from '../../../lib/constants';

import withQuery from '../../../hoc/withQuery/withQuery';
import withReduxManager from '../../../hoc/withReduxManager';

import FilterManager from '../../../components/FilterManager';
import FilterComponents from '../../../components/FilterComponents';

import columns from './columns';
import useRights from '../../../hooks/useRights';
import rights from '../../../lib/rights';

const initialSort = [{ id: 'locationName', desc: false }];

const CustomLocationsTable = withReduxManager({
  listId: listId.LOCATIONS,
})(withQuery({
  url: apiUrls.GET_LOCATION,
  listId: listId.LOCATIONS,
  alias: {
    locationName: 'LocationName',
    locationTypeName: 'LocationType',
    modified: 'Modified',
    modifiedByName: 'modifiedByName',
  },
})());

const FilterCollection = FilterComponents([
  {
    type: 'search',
    filterProps: {
      id: 'locations_filters_location_name',
      placeholder: 'Location Name',
      name: 'LocationName',
    },
  },
  {
    type: 'search',
    filterProps: {
      id: 'locations_filters_location_type',
      placeholder: 'Location Type',
      name: 'LocationType',
    },
  },
  {
    type: 'search',
    filterProps: {
      id: 'locations_filters_modified_by',
      placeholder: 'Modified By',
      name: 'ModifiedBy',
    },
  },
  {
    type: 'filterButton',
    filterProps: {
      placeholder: 'Exclude Inactive Location',
      name: 'ExcludeInactiveLocation',
      id: 'location_filter_inactive',
    },
  },
]);

const LocationsTable = (props) => {
  const { labels } = props;
  const [isLocationViewOnlyAuthenticated, isLocationAuthenticated] = useRights(
    [rights.location_view_only, rights.location],
  );

  const isPermitted = useMemo(() => (isLocationAuthenticated
    || (isLocationAuthenticated && isLocationViewOnlyAuthenticated)
  ), [isLocationAuthenticated, isLocationViewOnlyAuthenticated]);

  return (
    <CustomLocationsTable columns={columns(labels, isPermitted)} initialSort={initialSort} noDataText="Location not found" {...props}>
      {({
        Component, initialFilters, onFiltersChange, totalCount,
      }) => (
        <FilterManager initialFilters={initialFilters} onChange={onFiltersChange}>
          {({ onFilterChange, filters }) => (
            <>
              <div className="table-filters">
                <FilterCollection onFilterChange={onFilterChange} filters={filters} />
                <span className="table-count">
                  <span>{`${labels.get('titles.totalCount')}: ${totalCount}`}</span>
                </span>
              </div>
              {Component}
            </>
          )}
        </FilterManager>
      )}
    </CustomLocationsTable>
  );
};

export default LocationsTable;
