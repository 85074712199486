import React from 'react';
import { connect } from 'react-redux';

import { labelPaths } from '../../../../lib/constants';
import WithClearCurrentTab from '../../../../hoc/withClearCurrentTab';
import WithLabel from '../../../../hoc/withLabel';

import PatientTable from './Table';

function Patients(props) {
  const { labels } = props;
  return (
    <div>
      <div className="heading-area flex justify-content-sp-bt align-center patient-screen">
        <h2>Surgery Patients</h2>
      </div>
      <PatientTable labels={labels} />
    </div>

  );
}

const mapStateToProps = (state) => ({
  patients: state.patients && state.patients.get('patients'),
});

export default WithClearCurrentTab(connect(mapStateToProps)(WithLabel(Patients,
  labelPaths.PATIENT_LIST)));
