import React from 'react';
import { Route, Switch } from 'react-router-dom';

import useRedirect from '../../../../../../hooks/useRedirect';

import { UiRoutes } from '../../../../../../lib/constants';

import EditResponsibleParty from '../../../../../Dashboard/Tabs/Insurance/ResponsibleParty/Edit';
import ViewResponsibleParty from '../../../../../Dashboard/Tabs/Insurance/ResponsibleParty/View';
import AddResponsibleParty from '../../../../../Dashboard/Tabs/Insurance/ResponsibleParty/Add';
import InsuranceInformation from '../../../../../Dashboard/Tabs/Insurance/ViewProfile';
import AddNewProfile from '../../../../../Dashboard/Tabs/Insurance/AddNewProfile';
import AddNewPolicy from '../../../../../Dashboard/Tabs/Insurance/AddNewPolicy';
import EditPolicy from '../../../../../Dashboard/Tabs/Insurance/EditPolicy';
import SideBar from '../../../../../Dashboard/Tabs/Insurance/SideBar';
import AddHistory from '../../../../../Dashboard/Tabs/Insurance/AddHistory/AddHistory';

export default function Insurance(props) {
  const {
    params, path,
  } = useRedirect();

  return (
    <div className="claim-insurance-wrap">
      <div className="insurance-wrapper-all">
        <SideBar
          baseRoute={UiRoutes.claimInsuranceBaseRoute}
          parentParams={params}
          {...props}
        />
        <div className="required-feilds">
          <Switch>
            <Route path={`${path}/profile/view/:profileId`}>
              <InsuranceInformation
                {...props}
                parentParams={params}
                baseRoute={UiRoutes.claimInsuranceBaseRoute}
              />
            </Route>
            <Route path={`${path}/profile/history/:profileId`}>
              <AddHistory
                {...props}
                parentParams={params}
                baseRoute={UiRoutes.claimInsuranceBaseRoute}
              />
            </Route>
            <Route exact path={`${path}/profile/new`}>
              <AddNewProfile
                {...props}
                parentParams={params}
                baseRoute={UiRoutes.claimInsuranceBaseRoute}
              />
            </Route>
            <Route path={`${path}/profile/edit/:profileId/:policyId`}>
              <EditPolicy
                {...props}
                parentParams={params}
                baseRoute={UiRoutes.claimInsuranceBaseRoute}
              />
            </Route>
            <Route path={`${path}/profile/new/:profileId/:policyId`}>
              <AddNewPolicy
                {...props}
                parentParams={params}
                baseRoute={UiRoutes.claimInsuranceBaseRoute}
              />
            </Route>
            <Route path={`${path}/rp/add`}>
              <AddResponsibleParty
                {...props}
                parentParams={params}
                baseRoute={UiRoutes.claimInsuranceBaseRoute}
              />
            </Route>
            <Route path={`${path}/rp/edit/:rpId`}>
              <EditResponsibleParty
                {...props}
                parentParams={params}
                baseRoute={UiRoutes.claimInsuranceBaseRoute}
              />
            </Route>
            <Route path={`${path}/rp/view/:rpId`}>
              <ViewResponsibleParty
                {...props}
                parentParams={params}
                baseRoute={UiRoutes.claimInsuranceBaseRoute}
              />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}
