import React from 'react';

import WithClearCurrentTab from '../../hoc/withClearCurrentTab';

import All from './All';

import './billing.scss';

const Tasks = () => (
  <div className="task-screen">
    <h2>Task</h2>
    <All />
  </div>
);
export default WithClearCurrentTab(Tasks);

