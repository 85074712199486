import React, { useState, useCallback, useEffect } from 'react';
import { Form } from 'antd';

import WarningMessages from '../../../../../../../../lib/warningMessages';

import Table from '../../../../../../../../components/Table';
import Button from '../../../../../../../../components/Button';

import Loader from '../../../../../../../../components/Loader';
import ConfirmDialog from '../../../../../../../../components/ConfirmDialog';
import Icon from '../../../../../../../../components/Icon';

import AddExceptionRow from './AddExceptionRow';
import EditExceptionRow from './EditExceptionRow';

import columns from '../columns/exceptions';
import useRedirect from '../../../../../../../../hooks/useRedirect';

const ExceptionTable = ({
  data, labels, onSort, initialSort, loading, isUpdateContractAuthenticated,
}) => {
  const [form] = Form.useForm();

  const { params: { id: contractId, termId: contractTermId } } = useRedirect();

  const [tableData, setTableData] = useState([]);
  const [isNewRecord, setNewRecord] = useState(false);
  const [editRow, setEditRow] = useState(null);
  const [previousRow, setPreviousRow] = useState(null);

  useEffect(() => {
    if (data && data.length) setTableData(data);
  }, [data]);

  const newException = useCallback(() => {
    if (!isNewRecord) {
      if (editRow === null) {
        setEditRow('new');
        setNewRecord(true);
      } else {
        ConfirmDialog({
          onOk: (close) => {
            setEditRow('new');
            setNewRecord(true);
            close();
          },
          title: 'Warning',
          content: WarningMessages.LOST_CHANGES,
          icon: <Icon name="ExclamationCircleOutlined" />,
        })();
      }
    }
  }, [isNewRecord, editRow]);

  useEffect(() => {
    onSort(initialSort);
  }, []);

  const onAddNewRecord = useCallback((newRecord) => {
    tableData.unshift(newRecord);
    setNewRecord(false);
    setEditRow(null);
    setTableData([...tableData]);
  }, [tableData]);

  const onUpdateRecord = useCallback((record, rowIndex) => {
    tableData[rowIndex] = record;
    setEditRow(null);
    setTableData(tableData);
  }, [tableData]);

  const onDeleteRecord = useCallback((rowIndex) => {
    tableData.splice(rowIndex, 1);
    setTableData([...tableData]);
  }, [tableData]);

  return (
    <div className="mng-exception-tab move-btn-top contract-term-exception manage-para-test">
      {loading && <Loader />}
      {isUpdateContractAuthenticated && (
        <Button id="provider_assistants_add" className="btn-success btn sm-btn" onClick={newException}>{labels.get('link.addException')}</Button>
      )}
      <div className="table-filters">
        <span className="table-count">
          <span>{`Total Count: ${tableData && tableData.length}`}</span>
        </span>
      </div>
      <Table
        columns={columns(labels)}
        data={tableData}
        showNoDataText={(!isNewRecord && tableData && tableData.length === 0)}
        isHeaderFixed={false}
        disableTable={!isUpdateContractAuthenticated}
      >
        {({ rows }) => (
          <>
            {isNewRecord && (
            <AddExceptionRow
              labels={labels}
              key="exception_new"
              childKey="exception_new"
              setNewRecord={setNewRecord}
              editRow={editRow}
              onAddNewRecord={onAddNewRecord}
              setEditRow={setEditRow}
              form={form}
              contractId={contractId}
              contractTermId={contractTermId}
              parentFocusId="exception_new"
            />
            )}
            {(rows && rows.map(({ original }, rowIndex) => (
              <EditExceptionRow
                labels={labels}
                key={`exception_${rowIndex}`}
                rowData={original}
                setEditRow={setEditRow}
                rowIndex={rowIndex}
                editRow={editRow}
                setNewRecord={setNewRecord}
                onAddNewRecord={onAddNewRecord}
                setPreviousRow={setPreviousRow}
                previousRow={previousRow}
                onUpdateRecord={onUpdateRecord}
                form={form}
                onDeleteRecord={onDeleteRecord}
                contractId={contractId}
                contractTermId={contractTermId}
                parentFocusId={`exception_${rowIndex}`}
              />
            )))}
          </>
        )}
      </Table>
    </div>
  );
};

export default ExceptionTable;
