import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { clearReadData } from '../../../../../../../store/actions/crudWithoutTab';

import Notification from '../../../../../../../components/Notification';

import SuccessMessages from '../../../../../../../lib/successMessages';
import {
  labelPaths, UiRoutes,
} from '../../../../../../../lib/constants';
import { apiUrls } from '../../../../../../../api/constants';

import useRedirect from '../../../../../../../hooks/useRedirect';

import WithLabel from '../../../../../../../hoc/withLabel';
import AuthorizationForm from './AuthorizationForm';

const AddAuthorization = ({
  initialValues, labels,
}) => {
  const { params, generatePath, push } = useRedirect();
  const dispatch = useDispatch();

  const { id } = params;
  const navigateToAuthorization = useCallback(() => {
    push(generatePath(`${UiRoutes.patientAuthorizationListWithTabId}`, { ...params, tab: 'lists' }));
  }, [push, generatePath, params]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.AUTHORIZATION_SAVED_SUCCESSFULLY, success: true });
      dispatch(clearReadData(`${apiUrls.AUTHORIZATION_LIST_DROPDOWN}-${id}`));
      navigateToAuthorization();
    }
  }, [dispatch, id, navigateToAuthorization]);

  return (
    <div className="pannel back-pannel mr-top-20">
      <div className="pannel-heading">
        <p role="presentation" id="patients_lists_authorization_goBack" className="back-arrow" onClick={navigateToAuthorization}>{labels.get('buttons.goBack')}</p>
      </div>
      <div className="pannel-body">
        <AuthorizationForm
          onRequestComplete={onRequestComplete}
          labels={labels}
          initialValues={initialValues}
          onCancelButtonClicked={navigateToAuthorization}
          destroyOnClose
        />
      </div>
    </div>
  );
};

export default WithLabel(AddAuthorization, labelPaths.ADD_AUTHORIZATION);
