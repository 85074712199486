import React from 'react';
import TimeCell from '../../components/Cells/TimeCell';

const columns = (labels) => [
  {
    Header: labels.get('tableColumns.dos'),
    accessor: 'serviceDate',
    Cell: ({ row: { original } }) => <TimeCell value={original?.serviceDateString?.dateString} />,
  },
  {
    Header: labels.get('tableColumns.procedure'),
    accessor: 'cptCode',
  },
  {
    Header: labels.get('tableColumns.description'),
    accessor: 'cptCodeDescription',
  },
  {
    Header: labels.get('tableColumns.diagnosis'),
    accessor: 'billingEncounterDiagnosisCodes',
    Cell: ({ row: { original: { billingEncounterDiagnosisCodes: diagnosesCodes = [] } } }) => (
      <span>{diagnosesCodes?.length && diagnosesCodes.map((item) => item.icdCode).toString()}</span>
    ),
  },
  {
    Header: labels.get('tableColumns.responsibility'),
    accessor: 'responsibility',
  },
  {
    Header: labels.get('tableColumns.amount'),
    accessor: 'amount',
    Cell: ({ row: { original: { amount } } }) => (
      <span>
        {(amount || 0)?.toFixed(2)}
      </span>
    ),
    Footer: ({ data = [] }) => {
      let finalAmount = 0;
      data.forEach(({ amount }) => {
        if (amount) finalAmount += parseFloat(amount);
      });
      if (!finalAmount) return null;
      return <span>{finalAmount?.toFixed(2)}</span>;
    },
  },
  {
    Header: labels.get('tableColumns.balance'),
    accessor: 'balance',
    Cell: ({ row: { original: { balance } } }) => (
      <span>
        {(balance || 0)?.toFixed(2)}
      </span>
    ),
    Footer: ({ data = [] }) => {
      let finalBalance = 0;
      data.forEach(({ balance }) => {
        if (balance) finalBalance += parseFloat(balance);
      });
      if (!finalBalance) return null;
      return <span>{finalBalance?.toFixed(2)}</span>;
    },
  },
];

export default columns;
