const WarningMessages = {
  LOST_CHANGES: 'You have some unsaved changes. Are you sure you want to discard them?',
  AT_LEAST_FIVE_FIELDS_ARE_IMPORTANT: 'At least five fields are required to save changes. You have unsaved changes. Are you sure ?',
  PRESSURE_NOT_RECORDED_YET: 'Pressure not recorded yet',
  VISION_NOT_RECORDED_YET: 'Vision not recorded yet',
  CARE_TEAM_NOT_RECORDED_YET: 'Care Team not recorded yet',
  CREATE_OR_SELECT_EXCEPTION: 'Please create a new or select an existing schedule first',
  INFORMATION_IS_BEING_CHANGED: 'Information is being changed, do you want to reset',
  MULTIPLE_OF_FOUR: 'Time should be multiple of 4',
  MULTIPLE_OF_FIVE: 'Time should be multiple of 5',
  NO_ACTIVITY_FOR_THIS_TASK: 'There is no activity for this task.',
  NO_ACTIVITY_FOR_THIS_REPORT: 'There is no activity for this report.',
  WANT_TO_DELETE: 'Are you sure, you want to delete this record?',
  // Akshat Garg - 10/7/24 - adding new message 
  WANT_TO_MOVE: 'Are you sure, you want to move this record to unbatched?',
  WANT_TO_GO_BACK_WITH_UNSAVED_CHANGES: 'Are you sure you want to go back? All the unsaved changes will be discarded.',
  PAYMENT_WITH_SAME_CHECK_ALREADY_POSTED: 'Payment with the same check no. and amount has already been added. Are you sure you want to continue completing the batch?',
  REFUND_AMOUNT_SHOULD_NOT_BE_LESS_THEN_TOTAL_REFUND: 'Refund amount should not be less then total refund',
  CAN_NOT_REFUND_MORE_THAN_APPLIED_AMOUNT: 'Can not refund more than applied amount',
  PLEASE_ENTER_APPROPRIATE_REFUND_AMOUNTS_IN_EACH_CPT_CODE: 'Please enter appropriate refund amounts in each cpt code',
  SYSTEM_CAN_NOT_REFUND_MORE_UNAPPLIED_AMOUNT: 'System can not refund more unapplied amount than applied in patient payment',
};

export default WarningMessages;
