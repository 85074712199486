import React, { useCallback } from 'react';
import {
  Row, Col, Menu, Dropdown, Form as AntdForm,
} from 'antd';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';
import classNames from 'classnames';
import Form from '../Form';
import Button from '../Button';
import Checkbox from '../Checkbox';
import EventWrapper from '../EventWrapper';
import './hideColumns.scss';
import Icon from '../Icon';

const HideColumns = ({
  columns,
  setCustomizeColumns,
  selectedOptions,
  setSelectedOptions,
  inputSpan,
  labelSpan,
  label,
  className,
  required,
  isVisible,
  setVisible,
}) => {
  const [form] = AntdForm.useForm();

  const onFinish = useCallback((formData) => {
    const cloned = [...columns];
    columns.forEach((item) => {
      if (!formData[item.accessor]) {
        const index = findIndex(cloned, (temp) => temp.accessor === item.accessor);
        cloned.splice(index, 1);
      }
    });
    setCustomizeColumns(cloned);
    setVisible(false);
    setSelectedOptions(formData);
  }, [columns, setCustomizeColumns, setSelectedOptions, setVisible]);

  const menu = (
    <Menu>
      <Form
        form={form}
        section
        onFinish={onFinish}
        initialData={selectedOptions}
      >
        <div className="main-content-area">
          <Form.Section>
            <div className="checkbox-menu" id="area1">
              {columns.map((item) => (
                <div className="custom-menu-items" key={item.accessor}>
                  <div className="item-left">
                    <Checkbox
                      name={`${item.accessor}`}
                      valuePropName="checked"
                    >
                      {item.Header}
                    </Checkbox>
                  </div>
                </div>
              ))}
            </div>
          </Form.Section>
          <Form.Section>
            <div className="btns-action mr-left-60 mr-bt-12">
              <Button className="btn btn-success min-wt-86" type="submit">Apply</Button>
              <Button className="btn" onClick={() => setVisible(false)}>Cancel</Button>

            </div>
          </Form.Section>
        </div>
      </Form>
    </Menu>
  );

  return (
    <div className={classNames('custom-checkbox-select', className)}>
      <Row>
        <Col span={labelSpan || 10}>
          <div className="ant-form-item-label ant-form-item-label-left">
            <EventWrapper
              type="label"
              className={classNames('ant-form-item-no-colon', required && 'ant-form-item-required')}
              title={label}
            >
              {label}
            </EventWrapper>
          </div>
        </Col>
        <Col span={inputSpan || 14}>
          <div
            style={{
              position: 'relative',
            }}
            id="area1"
          >
            <Dropdown
              overlay={menu}
              visible={isVisible}
            >
              <Icon name="SettingOutlined" onClick={() => setVisible(!isVisible)} style={{ fontSize: '16px', marginTop: '5px' }} />
              {/* <div
                role="presentation"
                className="collapse-window sprite-img-before"
                onClick={() => setVisible(!isVisible)}
              /> */}
            </Dropdown>
          </div>
        </Col>
      </Row>
    </div>
  );
};

HideColumns.defaultProps = {
  columns: [],
  setCustomizeColumns: () => { /* This is intentional */ },
  selectedOptions: {},
  setSelectedOptions: () => { /* This is intentional */ },
  inputSpan: 14,
  labelSpan: 10,
  label: '',
  className: '',
  required: false,
};

HideColumns.propTypes = {
  columns: PropTypes.instanceOf(Array),
  setCustomizeColumns: PropTypes.func,
  selectedOptions: PropTypes.instanceOf(Object),
  setSelectedOptions: PropTypes.func,
  inputSpan: PropTypes.number,
  labelSpan: PropTypes.number,
  label: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
};

export default HideColumns;
