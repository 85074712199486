import React, { useCallback, useMemo } from 'react';
import { Form as AntdForm } from 'antd';

import { apiUrls } from '../../api/constants';
import { formId } from '../../lib/constants';

import useRedirect from '../../hooks/useRedirect';

import Button from '../../components/Button';
import Form from '../../components/Form';
import WidgetLoader from '../../components/WidgetLoader';
import Notification from '../../components/Notification';

import './synonym.scss';

const AddSynonymWithoutCode = ({ mode, synonym, minTextLength }) => {
  const [form] = AntdForm.useForm();
  const { params: { providerId } } = useRedirect();

  const catalogName = useMemo(() => (mode === 'cpt' ? 'CPT' : 'ICD-10'), [mode]);
  const extraData = useMemo(() => (mode === 'cpt' ? { cptId: null, CodeType: 'CPT' } : { icdId: null, CodeType: 'ICD' }), [mode]);

  const onRequestComplete = useCallback(({ response } = {}) => {
    if (response?.message) {
      Notification({ message: response.message, success: true });
    }
  }, []);

  if (!synonym || synonym.length < minTextLength) return <span>Type to initiate search</span>;
  return (
    <Form
      form={form}
      section
      formId={formId.ADD_SYNONYM_CPT_ICD}
      className="add-synonym-without-code-container"
      extraData={{ synonym, providerId, ...extraData }}
      url={apiUrls.ADD_SYNONYM_FOR_CPT_ICD}
      loaderComponent={WidgetLoader}
      onRequestComplete={onRequestComplete}
    >
      <div className="no-results">{`No results found for "${synonym}".`}</div>
      <div className="no-results-message">
        {`Would you like to submit this term to the admin to add it to the ${catalogName} catalog?`}
      </div>
      <div className="no-results-warning">
        *Please do not submit word fragments, abbreviations are okay.
      </div>
      <div className="no-results-actions">
        <Button className="btn-success sm-btn" type="submit">Add synonym</Button>
      </div>
    </Form>
  );
};

AddSynonymWithoutCode.defaultProps = {
  mode: 'cpt',
  synonym: 'new synonym',
  minTextLength: 3,
};

export default AddSynonymWithoutCode;
