import React, { useCallback, useState } from 'react';
import AutoComplete from '../../components/Form/AutoComplete';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { apiUrls } from '../../api/constants';
import { commonReffPhysicianAndAddressBookAutoCompleteDefaultPropType } from '../commonDefaultPropType';
import { formatPhoneNumber } from '../../lib/util';

const providerOptionRenderer = (item) => (
  <div className="option" key={item?.addressBookId}>
    <span title={item?.providerName}>
      {item?.providerName}
    </span>
    <span title={item?.npi}>
      {item?.npi}
    </span>
    <span title={item?.organisation}>
      {item?.organisation}
    </span>
    <span title={item?.connectedAcuityClinicName}>
      {item?.connectedAcuityClinicName}
    </span>
    <span title={item?.specialty}>
      {item?.specialty}
    </span>
    <span title={formatPhoneNumber(item?.primaryPhone)}>
      {formatPhoneNumber(item?.primaryPhone)}
    </span>
    <span title={formatPhoneNumber(item?.primaryFax)}>
      {formatPhoneNumber(item?.primaryFax)}
    </span>
    <span title={`${item?.address || ''} ${item?.city || ''} ${item?.zip || ''}`}>
      {`${item?.address || ''} ${item?.city || ''} ${item?.zip || ''}`}
    </span>
  </div>
);
const optionHeaderRenderer = (options) => (options?.length ? (
  <div className="option headerWrapper">
    <span className="header">
      Name
    </span>
    <span className="header">
      NPI
    </span>
    <span className="header">
      Organization
    </span>
    <span className="header">
      Clinic
    </span>
    <span className="header">
      Specialty
    </span>
    <span className="header">
      Phone
    </span>
    <span className="header">
      Fax
    </span>
    <span className="header">
      Address
    </span>
  </div>
) : '');

const AddressBookAutoComplete = ({
  labels,
  form,
  disabled,
  required,
  initialValue,
  labelSpan,
  inputSpan,
  onSelect,
  styleWidth,
  name,
  className,
  dropdownMatchSelectWidth,
  selectProps,
  ...props
}) => {
  const [setAutoCompleteValue, handleAutoCompleteValue] = useState(null);
  const patientOptionParser = useCallback((option) => ({
    name: option.name,
    value: option.npi,
    ...option,
  }), []);

  const onPatientSelect = useCallback((item) => {
    if (onSelect) onSelect(item, { item });
    form.setFields([{ name: 'addressBook', value: item && (item.providerName || item.npi) }]);
  }, [form, onSelect]);

  const setError = useCallback(() => {
    if (required) {
      form.setFields([{
        name,
        value: null,
        errors: ['address book is Required'],
      }]);
    }
  }, [form, name, required]);

  useUpdateEffect(() => {
    if (!required && disabled) {
      setAutoCompleteValue(null);
      form.setFields([{
        name,
        value: null,
        errors: [],
      }]);
    } else if (required) {
      form.setFields([{
        name,
        value: null,
        errors: ['address book is Required'],
      }]);
    }
  }, [required]);

  return (
    <div className="filter-patient-autocomplete">
      <AutoComplete
        label=""
        placeholder=""
        name={name}
        url={apiUrls.GET_PROVIDERS_ADDRESS_BOOK}
        optionParser={patientOptionParser}
        optionHeaderRenderer={optionHeaderRenderer}
        optionMaster="SearchText"
        labelSpan={labelSpan}
        inputSpan={inputSpan}
        disabled={disabled}
        required={required}
        initialValue={initialValue}
        optionRenderer={providerOptionRenderer}
        style={styleWidth ? { width: '100%' } : {}}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        onSelect={onPatientSelect}
        setAutoCompleteValue={handleAutoCompleteValue}
        setError={setError}
        className={className}
        notFoundContent="Provider is not present is the system."
        showArrow
        labelInValue
        selectProps={{ ...selectProps, optionLabelProp: 'name', allowClear: true }}
        {...props}
      />
    </div>
  );
};

AddressBookAutoComplete.defaultProps = {
  disabled: false,
  required: false,
  initialValue: {},
  labelSpan: '10',
  inputSpan: '13',
  onSelect: () => { /* This is intentional */ },
  styleWidth: true,
  name: 'patientName',
  className: '',
  dropdownMatchSelectWidth: 350,
};

AddressBookAutoComplete.propTypes = {
  ...commonReffPhysicianAndAddressBookAutoCompleteDefaultPropType,
};

export default AddressBookAutoComplete;
