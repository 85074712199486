import React, { useCallback, useMemo, useState } from 'react';
import { Form as AntdForm } from 'antd';
import zxcvbn from 'zxcvbn';

import Modals from '../../../../components/Modal';
import Button from '../../../../components/Button';
import Notification from '../../../../components/Notification';

import SuccessMessages from '../../../../lib/successMessages';
import ErrorMessages from '../../../../lib/errorMessages';
import { formId } from '../../../../lib/constants';
import { apiUrls } from '../../../../api/constants';
import Form from '../../../../components/Form';
import Input from '../../../../components/Input';
import Error from '../../../../components/Error';
import formFieldValuesParser from '../../../../lib/formFieldValuesParser';
import encryptData from '../../../../lib/encryptData';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import Icon from '../../../../components/Icon';

const ChangePasswordModal = ({
  toggleModal,
  isVisible,
  selectedUsers,
  setSelectedUsers,
}) => {
  const parser = useCallback((data) => {
    if (data) {
      return formFieldValuesParser(
        {
          userIds: selectedUsers,
          password: encryptData(data?.newPassword.trim()),
          confirmPassword: encryptData(data?.confirmPassword.trim()),
        },
        {
          toBeRemove: ['captchaFormItem'],
        },
      );
    }
    return null;
  }, [selectedUsers]);

  const [form] = AntdForm.useForm();
  const [passwordStrength, setPasswordStrength] = useState(0);

  const handlePasswordChange = useCallback((e) => {
    const password = e.target.value;
    const { score } = zxcvbn(password);
    setPasswordStrength(score);
  }, []);

  const resetFunctionality = () => {
    toggleModal();
    form.resetFields();
    setPasswordStrength(0);
    setSelectedUsers([]);
  };

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.PASSWORD_CHANGED_SUCCESSFULLY, success: true });
      resetFunctionality();
    }
  }, [toggleModal]);

  const onRequestFail = useCallback((messages) => {
    Notification({ message: (<Error messages={messages} />) });
    resetFunctionality();
  }, [toggleModal]);

  const submitForm = useCallback(() => {
    form.validateFields().then(() => {
      ConfirmDialog({
        onOk: (close) => {
          form.submit();
          close();
        },
        onCancel: (close) => {
          resetFunctionality();
          close();
        },
        okText: 'Continue',
        content: 'This is a non-reversible process. Do you want to change password of selected Users?',
        title: 'Warning',
        icon: <Icon name="ExclamationCircleOutlined" />,
        cancelButtonProps: { className: 'ant-modal-continue-green-btn' },
        okButtonProps: { className: 'ant-modal-ok-white-btn' },
      })();
    }).catch((error) => {
      console.log('error-in-change-password', error);
    });
  }, [form, parser, toggleModal]);

  const getStrengthMeterStyle = useMemo(() => {
    const strengthColors = ['red', 'red', 'orange', 'yellow', 'green'];
    const color = strengthColors[passwordStrength];
    const width = `${(passwordStrength + 1) * 20}%`;
    return {
      backgroundColor: color,
      width,
      height: '10px',
      marginTop: '30px',
      marginBottom: '10px',
      position: 'relative',
    };
  }, [passwordStrength]);

  const getStrengthText = useMemo(() => {
    const strengthText = ['Very Weak', 'Weak', 'Fair', 'Good', 'Strong'];
    return strengthText[passwordStrength];
  }, [passwordStrength]);

  return (
    <Modals
      visible={isVisible}
      toggleModal={toggleModal}
      destroyOnClose
      title="Reset Password"
      width="500px"
      footer={[]}
    >
      <Form
        shouldShowLoader={false}
        form={form}
        section
        formId={formId.CHANGE_PASSWORD}
        url={apiUrls.CHANGE_PASSWORD}
        onRequestComplete={onRequestComplete}
        onRequestFail={onRequestFail}
        parser={parser}
      >
        <AntdForm.Item
          name="newPassword"
          label="New Password"
          rules={[
            { required: true, message: 'Please enter a new password' },
            () => ({
              validator(_, value) {
                if (value) {
                  const trimedValue = value && value.trim();
                  const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

                  if (trimedValue && !passwordRegex.test(trimedValue)) {
                    return Promise.reject(ErrorMessages.PASSWORD_NOT_VALID);
                  }

                  if (passwordStrength < 2) {
                    return Promise.reject(ErrorMessages.PASSWORD_NOT_STRONG);
                  }
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            type="password"
            placeholder="New Password"
            labelSpan={0}
            inputSpan={24}
            applyValidation={false}
            showInputTooltip={false}
            onChange={handlePasswordChange}
          />
        </AntdForm.Item>
        <AntdForm.Item
          name="confirmPassword"
          label="Confirm Password"
          dependencies={['newPassword']}
          rules={[
            { required: true, message: 'Please confirm your new password' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const trimedValue = value && value.trim();
                if (!trimedValue || getFieldValue('newPassword') === trimedValue) {
                  return Promise.resolve();
                }
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject(ErrorMessages.PASSWORD_DO_NOT_MATCH);
              },
            }),
          ]}
        >
          <Input
            type="password"
            placeholder="Confirm Password"
            labelSpan={0}
            inputSpan={24}
            applyValidation={false}
            showInputTooltip={false}
          />
        </AntdForm.Item>

        <div className="password-strength-meter" style={getStrengthMeterStyle}>
          <span className="password-strength-text">{getStrengthText}</span>
        </div>

        <div className="group-btns flex justify-content-flex-end" key="footer">
          <Button className="btn min-wt-86" onClick={() => { toggleModal(); setPasswordStrength(0); }}>
            Cancel
          </Button>
          <Button className="btn btn-success min-wt-86 inline" onClick={submitForm}>
            Save
          </Button>
        </div>
      </Form>
    </Modals>
  );
};
export default ChangePasswordModal;
