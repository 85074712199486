import React from 'react';
import PropTypes from 'prop-types';

import { Alert as AlertComponent } from 'antd';

const Alert = ({
  message, type, ...otherProps
}) => (
  <AlertComponent message={message} type={type} {...otherProps} />
);

Alert.defaultProps = {
  closable: true,
  showIcon: true,
  message: '',
  type: 'warning',
};

Alert.propTypes = {
  message: PropTypes.string,
  showIcon: PropTypes.bool,
  closable: PropTypes.bool,
  type: PropTypes.string,
};

export default Alert;
