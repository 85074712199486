import React from 'react';

import TimeCell from '../../../../../../components/Cells/TimeCell';

const columns = (labels) => ([
  {
    Header: labels.get('tableColumns.date'),
    accessor: 'date',
    Cell: ({ row: { original } }) => <TimeCell value={original?.effectiveDate} />,
  },
  {
    Header: labels.get('tableColumns.procedureDescription'),
    accessor: 'description',
  },
  {
    Header: labels.get('tableColumns.charges'),
    accessor: 'amount',
    Cell: ({ row: { original: { amount } } }) => (
      (amount || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.credits'),
    accessor: 'paid',
    Cell: ({ row: { original: { paid } } }) => (
      (paid || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.balance'),
    accessor: 'balance',
    Cell: ({ row: { original: { balance } } }) => (
      (balance || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
]);

export default columns;
