import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tabs } from 'antd';

import useRedirect from '../../hooks/useRedirect';
import idCalculator from '../../lib/idCalculator';

import './Tabs.scss';

const { TabPane } = Tabs;

const TabsComponent = ({
  tabsList,
  indicatorColor,
  className,
  selectedTabData,
  dataHandler,
  updateHandler,
  id,
  isParams,
  routerParamsPropsName,
  ...props
}) => {
  const {
    params, url, location, replace, path, generatePath,
  } = useRedirect();
  let tabIndex = 0;
  if (!params[routerParamsPropsName]) {
    replace(`${url}/${tabsList[0].id}`);
  } else {
    for (let index = 0; index < tabsList.length; index += 1) {
      const element = tabsList[index];
      if (element.id === params[routerParamsPropsName]) {
        tabIndex = index;
        break;
      }
    }
  }
  const Component = tabsList[tabIndex].component;
  const handleChange = useCallback((pathId) => {
    const newPath = generatePath(path, { ...params, [routerParamsPropsName]: pathId });
    replace(newPath);
  }, [generatePath, params, path, replace]);

  return (
    <div>
      <div className="h-tabs">
        <div>
          <Tabs
            activeKey={params[routerParamsPropsName]}
            value={tabIndex}
            onChange={handleChange}
            aria-label="simple tabs"
          >
            {
              tabsList && tabsList.length && tabsList.map((item) => {
                const name = item.name || '';
                const iconClassName = item.className || '';
                const tabLabel = name || item.id;
                return (
                  <TabPane
                    key={item.id}
                    tab={item.tab || (
                      <div className={classNames('tab-label', { 'tab-label-icon': iconClassName }, iconClassName)}>
                        <span>{tabLabel?.toUpperCase()}</span>
                      </div>
                    )}
                    id={idCalculator(id, tabLabel?.toLowerCase())}
                  />
                );
              })
            }
          </Tabs>
        </div>
      </div>
      <Component
        path={location.pathname}
        dataHandler={dataHandler}
        selectedTabData={selectedTabData}
        updateHandler={updateHandler}
        {...props}
      />
    </div>
  );
};

TabsComponent.defaultProps = {
  indicatorColor: 'primary',
  tabsList: [],
  selectedTabData: {},
  className: '',
  dataHandler: () => { /* This is intentional */ },
  updateHandler: () => { /* This is intentional */ },
  isParams: true,
  id: 'tabs',
  routerParamsPropsName: 'tab',
};

TabsComponent.propTypes = {
  indicatorColor: PropTypes.string,
  tabsList: PropTypes.instanceOf(Array),
  selectedTabData: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  routerParamsPropsName: PropTypes.string,
  dataHandler: PropTypes.func,
  updateHandler: PropTypes.func,
  isParams: PropTypes.bool,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default TabsComponent;
