import React from 'react';
import { apiUrls } from '../../../../api/constants';
import Button from '../../../../components/Button';
import CheckBox from '../../../../components/Form/CheckBox';
import DatePicker from '../../../../components/Form/DatePicker';
import Input from '../../../../components/Form/Input';
import PhoneNumberInput from '../../../../components/Form/PhoneNumberInput';
import Uploads from '../../../../components/Upload';
import { enums } from '../../../../lib/constants';
import { Radio } from '../../../Enum';
import InsuranceDetailsSelect from '../../../InsuranceDetailsSelect';
import ProcedureGridAutoComplete from '../../../ProcedureGridAutoComplete';
import { getName, getuuid } from '../../../../lib/util';
import LocationGridAutoComplete from '../../../LocationGridAutoComplete';

import ProviderGridAutoComplete from '../../../ProviderGridAutoComplete';
import WiredSelect from '../../../Select';

const getProviderInitialValue = ({ taskId, formData, appointmentResponse }) => (
  ((taskId
    && formData?.providerId
    && formData?.providerName) || (appointmentResponse?.providerId
       && appointmentResponse?.providerFirstName))
    ? {
      name: formData?.providerName || getName(appointmentResponse?.providerFirstName,
          appointmentResponse?.providerMiddleName, appointmentResponse?.providerLastName),
      value: formData?.providerId || appointmentResponse?.providerId,
      providerName: formData?.providerName
        || getName(appointmentResponse?.providerFirstName,
          appointmentResponse?.providerMiddleName,
           appointmentResponse?.providerLastName),
    } : null
);

const getLocationInitialValue = ({ taskId, formData, appointmentResponse }) => (
  ((taskId
    && formData?.locationId
    && formData?.locationName) || (appointmentResponse?.locationId
      && appointmentResponse?.locationName))
    ? {
      name: formData?.locationName || appointmentResponse?.locationName,
      value: formData?.locationId
         || appointmentResponse?.locationId,
    } : null
);

const TaskRightSection = ({
  labels,
  patientId,
  onInsuranceSelect,
  formData,
  appointmentResponse,
  subjectId,
  taskId,
  form,
  onFileUpload,
  onDeleteTaskFile,
  selectedProcedures,
  setSelectedProcedures,
  selectedRows,
  setSelectedRow,
  patient,
  insuranceInitialValue,
  changeTaskFields,
}) => (
  <div className="group-shadow tasks-modal-form-sections">
    <Input
      label={labels.get('labels.chartNumber')}
      name="chartNumber"
      required
      disabled
      placeholder="Enter Chart NUmber"
    />
    { !changeTaskFields && (
    <Input
      label={labels.get('labels.patientName')}
      name="patientName"
      placeholder="Enter Patient Name"
      disabled
    />
    )}
    { changeTaskFields && (
      <>
        <Input
          label={labels.get('labels.patientFirstName')}
          name="patientFirstName"
          placeholder="Enter Patient Name"
        />
        <Input
          label={labels.get('labels.patientLastName')}
          name="patientLastName"
          placeholder="Enter Patient Name"
        />
        <Input
          label={labels.get('labels.memberId')}
          name="insuranceMemberNumber"
          placeholder="Enter Member Id"
          disabled
        />
      </>
    )}
    <DatePicker
      label={labels.get('labels.dob')}
      name="dob"
      disabled
    />
    {!changeTaskFields && (
    <InsuranceDetailsSelect
      selectProps={{
        showSearch: true,
      }}
      id="payer"
      name="insuranceId"
      placeholder={labels.get('labels.insurance')}
      patientId={patientId}
      label={labels.get('labels.insurance')}
      labelSpan={10}
      inputSpan={14}
      onSelect={onInsuranceSelect}
        // includeSelfProfile
      initialValue={insuranceInitialValue}
      tabId={taskId || getuuid()}
      required
      includeSelfProfile
    />
    )}
    {((subjectId?.name?.toLowerCase() !== 'prescription refill')
      && (subjectId?.name?.toLowerCase() !== 'authorization')) && (
      <>
        {!changeTaskFields && (
        <Input
          label={labels.get('labels.insuranceId')}
          name="insuranceNumber"
          disabled
          placeholder="Enter Insurance Number"
        />
        )}
        <PhoneNumberInput
          label={labels.get('labels.insurancePhoneNumber')}
          name={changeTaskFields ? 'healthPlanPhoneNumber' : 'insurancePhoneNumber'}
          labelSpan="10"
          inputSpan="14"
          minValueLength={10}
          maxValueLength={12}
          placeholder="Enter Insurance Phone Number"
          disabled
        />
        <Input
          label={labels.get('labels.insurancePayerName')}
          name={changeTaskFields ? 'healthPlan' : 'insurancePayerName'}
          placeholder="Enter Insurance Payer Name"
          disabled
        />
      </>
    )}
    <ProviderGridAutoComplete
      id="provider"
      name="providerId"
      label={labels.get('labels.provider')}
      placeholder="Select Provider"
      selectProps={{
        size: 'middle',
        showSearch: true,
      }}
      labelSpan="10"
      inputSpan="14"
      required
      initialValue={getProviderInitialValue({ taskId, formData, appointmentResponse })}
      dataTestId="providerId"
    />
    <LocationGridAutoComplete
      name="locationId"
      label={labels.get('labels.location')}
      form={form}
      minCharLength={3}
      labelSpan={10}
      inputSpan={14}
      header={false}
      initialValue={getLocationInitialValue({ taskId, formData, appointmentResponse })}
      required
      dataTestId="locationId"
      placeholder="Enter Location"
    />
    {subjectId?.name?.toLowerCase() === 'refund' && (
    <>
      <Input
        label={labels.get('labels.amount')}
        name="amount"
        placeholder="Enter Amount"
      />
      <CheckBox
        label={labels.get('labels.materialInvolved')}
        name="materialInvolved"
        valuePropName="checked"
        labelSpan="10"
        inputSpan="14"
      />
      <Radio
        label={labels.get('labels.paymentType')}
        name="paymentTypeId"
        labelSpan="10"
        inputSpan="14"
        enumName={enums.TASK_PAYMENT_TYPE}
        required
      />
    </>
    )}
    <Uploads
      accept="image/png, image/jpeg, .pdf"
      label={labels.get('labels.file')}
      name="files"
      listType="picture"
      multiple
      labelSpan="10"
      inputSpan="14"
      onChange={onFileUpload}
      onRemove={onDeleteTaskFile}
      form={form}
      sizeLimit={30}
      className="tasks-modal-file-upload"
    >
      <Button className="btn btn-success min-wt-86 sm-btn">{labels.get('buttons.upload')}</Button>
    </Uploads>
    {subjectId?.name?.toLowerCase() === 'authorization' && (
    <ProcedureGridAutoComplete
      name="procedures"
      label={labels.get('labels.proceduresPlanned')}
      form={form}
      minCharLength={3}
      labelSpan={10}
      inputSpan={14}
      header={false}
      tableData={selectedProcedures}
      setTableData={setSelectedProcedures}
      selectedRows={selectedRows}
      setSelectedRow={setSelectedRow}
    />
  )}
    {subjectId?.name?.toLowerCase() === 'prescription refill' && (
    <WiredSelect
      id="task-prescriptions"
      label={labels.get('labels.prescriptionRequested')}
      name="prescriptionRequested"
      url={apiUrls.GET_TASK_PRESCRIPTIONS}
      nameAccessor="drugName"
      valueAccessor="drugName"
      startCaseOptions
      labelSpan={10}
      inputSpan={14}
      selectProps={{
        showArrow: true,
        dropdownMatchSelectWidth: false,
        mode: 'multiple',
      }}
      params={{ patientId: patient?.patientId, isSaveOnly: false }}
      placeholder="Select prescriptions"
    />
    )}
  </div>
);

export default TaskRightSection;
