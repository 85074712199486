import { useEffect } from 'react';

const TableWrapper = ({
  Component, setReFetch, reFetch, data, isAllClaimCheckSelected, setSelectedClaims,
}) => {
  useEffect(() => {
    if (reFetch) {
      setReFetch(() => reFetch);
    }
  }, []);

  useEffect(() => {
    if (data?.length && isAllClaimCheckSelected) {
      setSelectedClaims(data?.map((value, index) => ({ index, id: `${index}`, original: value })));
    }
  }, [data]);

  return Component;
};

export default TableWrapper;
