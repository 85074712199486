import React from 'react';

import Label from '../../../../../components/Label';
import Form from '../../../../../components/Form';
import TimeCell from '../../../../../components/Cells/TimeCell';

const StatementForm = ({ labels, statementDetail }) => (
  <div>
    <Form.Section>
      <div className="feild-row justify-content-sp-bt align-center no-top-mr">
        <p className="back-headings">{labels.get('titles.statementDetails')}</p>
      </div>
    </Form.Section>
    <Form.Section noOfColumns={2}>
      <Form.Column>
        <div className="group-shadow">
          <div className="posi-relative">
            <Label label={labels.get('labels.name')} />
            <div className="name-view">{statementDetail?.patientName ?? ''}</div>
          </div>
          <div className="posi-relative">
            <Label label={labels.get('labels.statementDate')} />
            <div className="name-view">
              <TimeCell value={statementDetail?.submittedDate} />
            </div>
          </div>
          <div className="posi-relative">
            <Label label="Chart Number" />
            <div className="name-view">{ statementDetail?.chartNumber ?? ''}</div>
          </div>
        </div>
      </Form.Column>
      <Form.Column>
        <div className="group-shadow">
          <div className="posi-relative">
            <Label label={labels.get('labels.current')} />
            <div className="name-view">{(statementDetail?.currentAmount || 0).toFixed(2)}</div>
          </div>
          <div className="posi-relative">
            <Label label={labels.get('labels.pastDue')} />
            <div className="name-view">{(statementDetail?.pastDueAmount || 0).toFixed(2)}</div>
          </div>
          <div className="posi-relative">
            <Label label={labels.get('labels.total')} />
            <div className="name-view">{(statementDetail?.totalAmount || 0).toFixed(2)}</div>
          </div>
        </div>
      </Form.Column>
    </Form.Section>
  </div>
);

export default StatementForm;
