import React, { useMemo } from 'react';

import useCRUD from '../../../../../../hooks/useCRUD';

import { listIds } from '../../../../../../lib/constants';
import { apiUrls } from '../../../../../../api/constants';

import CollectionEncounter from '../../../../../../wiredComponents/AddNewEncounter';
import useRedirect from '../../../../../../hooks/useRedirect';

const ChargesEncounter = () => {
  const {
    params,
  } = useRedirect();
  const [claimsData] = useCRUD({
    id: `${listIds.CLAIM_DETAILS}-${params?.tabId}`,
    url: apiUrls.GET_CLAIM_DETAILS,
    type: 'read',
  });

  const { patient, billingEncounterData } = useMemo(() => ({
    patient: claimsData?.patient,
    billingEncounterData: claimsData?.billingEncounterData,
  }), [claimsData]);

  return (
    <div className="posi-relative">
      <CollectionEncounter
        patientIdProps={patient?.patientId}
        encounterIdProp={billingEncounterData?.billingEncounterId}
        showCancel={false}
        isCollectionCharges
      />
    </div>
  );
};

export default ChargesEncounter;
