import compact from 'lodash/compact';

const idCalculator = (...keys) => {
  const keysWithoutUndefined = compact(keys);
  if (!keysWithoutUndefined.length) {
    return false;
  }

  return keysWithoutUndefined.join('_').split(' ').join('_')
    .toLowerCase();
};

export default idCalculator;
