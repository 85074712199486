import React, { useCallback, useEffect } from 'react';
import moment from 'moment';
import { Tooltip } from 'antd';

import { enums as enumConstant } from '../../../../../lib/constants';
import { toDateValidator } from '../../../../../lib/validator';
import useReduxState from '../../../../../hooks/useReduxState';

import Form from '../../../../../components/Form';
import Upload from '../../../../../components/Upload';
import Input from '../../../../../components/Form/Input';
import CheckBox from '../../../../../components/Form/CheckBox';
import DatePicker from '../../../../../components/Form/DatePicker';
import { Select, Radio } from '../../../../../wiredComponents/Enum';
import Notification from '../../../../../components/Notification';

const disableDate = (current) => current && current <= moment().subtract(150, 'years');
const disableDOBDate = (current) => current && (current >= moment().subtract(1, 'days') || current <= moment().subtract(100, 'years'));
const disableFutureDate = (current) => current && (current >= moment() || current <= moment().subtract(150, 'years'));

const copyOptions = [
  { label: 'Assessment', value: 'A' },
  { label: 'Plan', value: 'P' },
  { label: 'Notes', value: 'N' },
];

const specialityOptionParser = (options) => options?.filter((item) => item?.masterCode?.toLowerCase() !== 'outsidereferrals');
const ProviderInformation = ({
  form, isUpdate, data, labels, isClear, setClear, disableEmail,
}) => {
  const [isSignatureOnFile, setSignatureOnFile] = useReduxState('provider-general-isSignatureOnFile', false);
  const [isValuesSet, setValues] = useReduxState('provider-general-isValuesSet', false);
  const [patientAgeLimit, setPatientAgeLimit] = useReduxState('provider-general-patientAgeLimit', null);
  const [subSpeciality, setSubSpeciality] = useReduxState('provider-general-subSpeciality', null);
  const [providerDegree, setProviderDegree] = useReduxState('provider-general-providerDegree', null);

  const onChangeSignature = useCallback(() => {
    if (isUpdate) {
      form.setFieldsValue({ IsSignatureFileUploaded: true });
    }
  }, [form, isUpdate]);

  const isSignatureFileUpload = useCallback(({ target: { checked } }) => {
    setSignatureOnFile(checked);
  }, [setSignatureOnFile]);

  const onSelect = () => {
    Notification({ message: 'Please check the “Signature on file” checkbox' });
  };

  useEffect(() => {
    if (isUpdate && data && Object.values(data).length && !isValuesSet) {
      const { signatureOnFile } = data;
      setSignatureOnFile(signatureOnFile);
      if (data.patientAgeLimit) {
        const ids = data.patientAgeLimit.map((item) => item.id);
        setPatientAgeLimit(ids);
      }
      if (data?.subSpecialties) {
        const ids = data?.subSpecialties.map((item) => item?.id?.toString());
        setSubSpeciality(ids);
      }
      if (data?.providerDegree) {
        const ids = data?.providerDegree?.map((item) => item?.id?.toString());
        setProviderDegree(ids);
      }
      form.setFieldsValue({ IsSignatureFileUploaded: false });
      setValues(true);
    }
  }, [isUpdate, data, setValues, isValuesSet, setSignatureOnFile, form]);

  useEffect(() => {
    if (isClear) {
      const currentData = form.getFieldsValue();
      const { signatureOnFile } = currentData;
      setSignatureOnFile(signatureOnFile);
      setClear(false);
    }
  }, [isClear, setClear, form]);

  return (
    <>
      <div style={{ display: 'none' }}>
        <CheckBox
          name="IsSignatureFileUploaded"
          valuePropName="checked"
        />
        <Input name="signatureFileUrl" id="17" />
        <Input name="signatureFileType" id="18" />
        <Input name="signatureFileName" id="19" />
      </div>
      <Form.Section>
        <div className="feild-row required-feilds top-feild-mng">
          <h2 className="h2-title" data-testid="provider-title">{labels.get('titles.basicInformation')}</h2>
        </div>
      </Form.Section>
      <Form.Section noOfColumns={3}>
        <div className="group-shadow">
          <Form.Column>
            <Input
              label={labels.get('labels.firstName')}
              name="firstName"
              id="firstName"
              required
              charOnly
              maxValueLength={50}
              labelSpan="8"
              inputSpan="16"
            />
            <Input
              label={labels.get('labels.middleName')}
              name="middleName"
              id="middleName"
              charOnly
              maxValueLength={50}
              labelSpan="8"
              inputSpan="16"
            />
            <Input
              label={labels.get('labels.lastName')}
              name="lastName"
              id="lastName"
              required
              charOnly
              maxValueLength={50}
              labelSpan="8"
              inputSpan="16"
            />
            <Input
              label={labels.get('labels.suffix')}
              name="suffix"
              id="suffix"
              charOnly
              maxValueLength={50}
              labelSpan="8"
              inputSpan="16"
            />
            <Input
              label={labels.get('labels.email')}
              name="email"
              id="email"
              rules={[{
                type: 'email',
                message: 'The input is not valid E-mail!',
              }]}
              labelSpan="8"
              inputSpan="16"
              required
              disabled={disableEmail}
            />
            <Radio
              required
              enumName={enumConstant.GENDER}
              label={labels.get('labels.gender')}
              name="genderId"
              labelSpan="8"
              inputSpan="16"
            />
            <DatePicker
              label={labels.get('labels.dob')}
              id="dob"
              name="dob"
              disabledDate={disableDOBDate}
              labelSpan="8"
              inputSpan="16"
              dataTestId="dob"
            />
          </Form.Column>
        </div>
        <div className="group-shadow">
          <Form.Column>
            <div>
              <Select
                label={labels.get('labels.specialty')}
                name="specialtyId"
                required
                optionMaster="searchtext"
                enumName={enumConstant.PROVIDER_SPECIALTY}
                form={form}
                optionParser={specialityOptionParser}
                labelSpan="8"
                inputSpan="16"
              />
              <Select
                label={labels.get('labels.subSpeciality')}
                name="subSpecialtyIds"
                id="subSpecialtyIds"
                enumName={enumConstant.SUB_SPECIALITY}
                form={form}
                labelSpan="8"
                inputSpan="16"
                mode="multiple"
                initialValue={subSpeciality}
                selectProps={{ showArrow: true, showSearch: false }}
              />
              <Select
                label={labels.get('labels.patientAgeLimit')}
                name="patientAgeLimitIds"
                id="patientAgeLimitIds"
                enumName={enumConstant.PATIENT_AGE_LIMIT}
                form={form}
                labelSpan="8"
                inputSpan="16"
                mode="multiple"
                initialValue={patientAgeLimit}
                selectProps={{ showArrow: true, showSearch: false }}
              />
              <Select
                label={labels.get('labels.degree')}
                name="providerDegreeIds"
                id="providerDegreeIds"
                optionMaster="searchtext"
                enumName={enumConstant.Provider_Degree}
                initialValue={providerDegree}
                form={form}
                labelSpan="8"
                inputSpan="16"
                mode="multiple"
                selectProps={{
                  showArrow: true,
                  showSearch: true,
                  maxTagCount: 'responsive',
                }}
              />
              <CheckBox
                id="signatureOnFile"
                label={labels.get('labels.signatureOnFile')}
                name="signatureOnFile"
                valuePropName="checked"
                onClick={isSignatureFileUpload}
                labelSpan="8"
                inputSpan="16"
              />
              <div className="custom-uploader-file provider-file-upload">
                <Tooltip title={!isSignatureOnFile ? labels.get('labels.signatureTooltip') : ''}>
                  <Upload
                    accept=".png, .jpeg, .jpg, .gif"
                    label={labels.get('labels.signature')}
                    name="signatureDocument"
                    listType="picture"
                    multiple={false}
                    disabled={!isSignatureOnFile}
                    labelSpan="8"
                    inputSpan="16"
                    required={isSignatureOnFile}
                    form={form}
                    sizeLimit={30}
                    onChange={onChangeSignature}
                    onClick={!isSignatureOnFile ? onSelect : ''}
                  />
                </Tooltip>
              </div>
            </div>
          </Form.Column>
        </div>
        <div className="group-shadow">
          <Form.Column>
            <div>
              <Input
                label={labels.get('labels.npi')}
                required
                id="npi"
                maxValueLength={10}
                minValueLength={10}
                name="npi"
                labelSpan="8"
                inputSpan="16"
              />
              <Input
                label={labels.get('labels.licenseNumber')}
                id="licenseNumber"
                name="licenseNumber"
                required
                labelSpan="8"
                inputSpan="16"
              />
              <Input
                label={labels.get('labels.deaNumber')}
                name="deaNumber"
                id="deaNumber"
                labelSpan="8"
                inputSpan="16"
              />
              <Select
                label={labels.get('labels.providerType')}
                name="providerTypeId"
                optionMaster="searchtext"
                enumName={enumConstant.PROVIDER_TYPES}
                required
                form={form}
                labelSpan="8"
                inputSpan="16"
              />
              <CheckBox
                name="copyNotes"
                label="Copy Notes"
                labelSpan="8"
                inputSpan="16"
                component={CheckBox.Group}
                options={copyOptions}
              />
            </div>
          </Form.Column>
        </div>
      </Form.Section>
      <Form.Section>
        <div className="feild-row required-feilds">
          <h2 className="h2-title" data-testid="secondary-title">{labels.get('titles.generalInformation')}</h2>
        </div>
      </Form.Section>
      <Form.Section noOfColumns={3}>
        <div className="group-shadow">
          <Form.Column>
            <span className="input-field-title">{labels.get('subtitles.submitClaim')}</span>
            <DatePicker
              label={labels.get('labels.from')}
              id="submitClaimsDateFrom"
              name="submitClaimsDateFrom"
              required
              disabledDate={disableDate}
              labelSpan="8"
              inputSpan="16"
              dataTestId="submitClaimsDateFrom"
            />
            <DatePicker
              label={labels.get('labels.to')}
              id="submitClaimsDateTo"
              name="submitClaimsDateTo"
              dependencies={['submitClaimsDateFrom']}
              rules={[
                ({ getFieldValue }) => toDateValidator(getFieldValue, 'submitClaimsDateFrom'),
              ]}
              disabledDate={disableDate}
              labelSpan="8"
              inputSpan="16"
            />
          </Form.Column>
        </div>
        <div className="group-shadow">
          <Form.Column>
            <span className="input-field-title">{labels.get('subtitles.schedulePatients')}</span>
            <div>
              <DatePicker
                label={labels.get('labels.from')}
                id="schedulePatientsDateFrom"
                name="schedulePatientsDateFrom"
                required
                disabledDate={disableDate}
                labelSpan="8"
                inputSpan="16"
                dataTestId="schedulePatientsDateFrom"
              />
              <DatePicker
                label={labels.get('labels.to')}
                id="schedulePatientsDateTo"
                name="schedulePatientsDateTo"
                dependencies={['schedulePatientsDateFrom']}
                rules={[
                  ({ getFieldValue }) => toDateValidator(getFieldValue, 'schedulePatientsDateFrom'),
                ]}
                disabledDate={disableDate}
                labelSpan="8"
                inputSpan="16"
              />
            </div>
          </Form.Column>
        </div>
        <div className="group-shadow">
          <Form.Column>
            <span className="input-field-title">{labels.get('subtitles.providerActiveSince')}</span>
            <div>
              <DatePicker
                label={labels.get('labels.from')}
                id="providerActiveSinceFrom"
                name="providerActiveSinceFrom"
                required
                disabledDate={disableFutureDate}
                labelSpan="8"
                inputSpan="16"
                dataTestId="providerActiveSinceFrom"
              />
              <DatePicker
                label={labels.get('labels.to')}
                id="providerActiveSinceTo"
                name="providerActiveSinceTo"
                dependencies={['providerActiveSinceFrom']}
                rules={[
                  ({ getFieldValue }) => toDateValidator(getFieldValue, 'providerActiveSinceFrom'),
                ]}
                labelSpan="8"
                inputSpan="16"
              />
              <CheckBox
                label={labels.get('labels.active')}
                name="isActive"
                valuePropName="checked"
                labelSpan="8"
                inputSpan="16"
              />
            </div>
          </Form.Column>
        </div>
      </Form.Section>

    </>
  );
};

ProviderInformation.defaultProps = {
  data: {},
};
export default ProviderInformation;
