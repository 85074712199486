import React from 'react';
import useRights from '../../hooks/useRights';

const WithRights = (WrappedComponent, accessId) => function WithRightsHOC(props) {
  const isPermitted = useRights(Array.isArray(accessId) ? accessId : [accessId]);
  const isAuthorize = Array.isArray(isPermitted)
    ? isPermitted?.some((item) => item === true) : isPermitted;
  if (!isAuthorize) {
    return (
      <div>
        You are not permitted to access this page.
      </div>
    );
  }
  return <WrappedComponent {...props} />;
};

export default WithRights;
