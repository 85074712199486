export default {
  access_to_batch_entry: 'access_to_batch_entry',
  access_to_billing_app: 'access_to_billing_app',
  access_to_analytics_app: 'access_to_analytics_app',
  'access_to_chart2.0': 'access_to_chart2.0',
  access_to_clinical_templates: 'access_to_clinical_templates',
  access_to_collections: 'access_to_collections',
  access_to_contract_management: 'access_to_contract_management',
  access_to_document_management_app: 'access_to_document_management_app',
  access_to_patient_app: 'access_to_patient_app',
  add_refund: 'add_refund',
  assemble_all_claims: 'assemble_all_claims',
  assign_electronic_fax_number: 'assign_electronic_fax_number',
  assign_remittance_batches: 'assign_remittance_batches',
  business_entity_setup: 'business_entity_setup',
  business_entity_setup_view_only: 'business_entity_setup_view_only',
  can_create_and_edit_contracts: 'can_create_and_edit_contracts',
  can_delete_remittances: 'can_delete_remittances',
  can_edit_batch: 'can_edit_batch',
  'can_post_e-remittance_batches': 'can_post_e-remittance_batches',
  can_post_remittance_batches: 'can_post_remittance_batches',
  can_schedule_value_recalculation: 'can_schedule_value_recalculation',
  create_appointment: 'create_appointment',
  create_authorization: 'create_authorization',
  create_batch: 'create_batch',
  create_claims: 'create_claims',
  create_clinical_cases: 'create_clinical_cases',
  create_document: 'create_document',
  create_manual_incident: 'create_manual_incident',
  create_new_patient: 'create_new_patient',
  create_statements: 'create_statements',
  delete_document: 'delete_document',
  delete_patient: 'delete_patient',
  delete_appointment: 'delete_appointment',
  edit_submitted_procedures: 'edit_submitted_procedures',
  enter_charges: 'enter_charges',
  enter_insurance_transaction: 'enter_insurance_transaction',
  enter_patient_transaction: 'enter_patient_transaction',
  export_reports: 'export_reports',
  generate_letters_and_forms: 'generate_letters_and_forms',
  global_patient_search: 'global_patient_search',
  invite_patient: 'invite_patient',
  location: 'location',
  location_view_only: 'location_view_only',
  manage_letter_and_form_templates: 'manage_letter_and_form_templates',
  outbound_faxing: 'outbound_faxing',
  override_edits: 'override_edits',
  'payer-setup(all_types)': 'payer-setup(all_types)',
  perform_insurance_adjustment: 'perform_insurance_adjustment',
  physicians: 'physicians',
  physicians_view_only: 'physicians_view_only',
  provider: 'provider',
  provider_view_only: 'provider_view_only',
  read_document: 'read_document',
  read_rights: 'read_rights',
  read_roles: 'read_roles',
  reverse_insurance_patient_adjustment: 'reverse_insurance_patient_adjustment',
  reverse_patient_adjustment: 'reverse_patient_adjustment',
  reverse_patient_payment: 'reverse_patient_payment',
  reverse_simple_charge: 'reverse_simple_charge',
  run_accounts_receivable_reports: 'run_accounts_receivable_reports',
  run_appointments_reports: 'run_appointments_reports',
  run_authorizations: 'run_authorizations',
  run_claims_report: 'run_claims_report',
  run_clinical_reports: 'run_clinical_reports',
  run_management_reports: 'run_management_reports',
  run_patients_report: 'run_patients_report',
  run_requests: 'run_requests',
  run_transactions_reports: 'run_transactions_reports',
  save_reports: 'save_reports',
  send_electronic_referral: 'send_electronic_referral',
  super_user: 'super_user',
  update_appointment: 'update_appointment',
  update_appointment_templates: 'update_appointment_templates',
  update_blockouts: 'update_blockouts',
  update_document: 'update_document',
  update_patient: 'update_patient',
  update_preferences: 'update_preferences',
  manage_rights: 'manage_rights',
  manage_roles: 'manage_roles',
  user_rights_view_only: 'user_rights_view_only',
  user_setup: 'user_setup',
  user_setup_view_only: 'user_setup_view_only',
  access_to_schedular_app: 'access_to_schedular_app',
  force_overbook: 'force_overbook',
  show_manage_doctor_favorites: 'show_manage_doctor_favorites',
  access_to_case_management: 'access_to_case_management',
  access_to_edi: 'access_to_edi',
  access_medications_chart_gadget: 'access_medications_chart_gadget',
  access_to_remittance: 'access_to_remittance',
  access_to_claims_app: 'access_to_claims_app',
  access_to_composer: 'access_to_composer',
  access_to_masters: 'access_to_masters',
  access_to_cpt_icd_synonym: 'access_to_cpt_icd_synonym',
  access_to_consent_form: 'access_to_consent_form',
  access_to_fee_preferences: 'access_to_fee_preferences',
  payer: 'payer',
  payer_view_only: 'payer_view_only',
  access_to_appointment_app: 'access_to_appointment_app',
  access_to_doctor_app: 'access_to_doctor_app',
  access_to_task_management_app: 'access_to_task_management_app',
  access_to_manual_intervention_queue_app: 'access_to_manual_intervention_queue_app',
  access_to_technician_app: 'access_to_technician_app',
  override_default_medical_insurance_profile: 'override_default_medical_insurance_profile',
  payer_request: 'payer_request',
  access_to_manage_practice_favourite: 'access_to_manage_practice_favourite',
  access_to_dashboard: 'access_to_dashboard',
  access_to_settings: 'access_to_settings',
  manage_cpt_icd_synonym: 'manage_cpt_icd_synonym',
  delete_favourites: 'delete_practice_favorites',
  access_to_apply_unapplied_credit: 'access_to_apply_unapplied_credit',
  bulk_change_password: 'bulk_change_password',
  access_to_no_billing_needed: 'access_to_no_billing_needed',
  access_to_add_or_edit_global_period: 'access_to_add_edit_global_period',
  access_to_override_rules_engine: 'access_to_override_rules_engine',
  access_to_add_or_edit_denial_threshold_amount: 'access_to_add_or_edit_denial_threshold_amount',
  ability_to_turn_features_on_off: 'ability_to_turn_features_on_off',
  access_to_fee_schedule: 'access_to_fee_schedule',
  waystar_rejection_override: 'waystar_rejection_override',
};
