import React, {
  useState, useEffect, useCallback, useMemo, lazy,
} from 'react';
import {
  Row, Col, Form as AntdForm,
} from 'antd';
import find from 'lodash/find';
import debounce from 'lodash/debounce';
import uniq from 'lodash/uniq';
import { useSelector } from 'react-redux';

import Input from '../../../../components/Form/Input';
import Form from '../../../../components/Form';
import Panel from '../../../../components/Panel';
import SMCheckBox from '../../../../components/SmCheckbox';
import WidgetLoader from '../../../../components/WidgetLoader';
import Select from '../../../../components/Form/SelectBox';

import WiredSelect from '../../../../wiredComponents/Select';

import {
  formId, labelPaths, listIds, enumMasterCode, enums,
} from '../../../../lib/constants';
import { apiUrls } from '../../../../api/constants';
import WithLabel from '../../../../hoc/withLabel';

import PopOver from '../../../../components/PopOver';
import useRedirect from '../../../../hooks/useRedirect';
import useCRUD from '../../../../hooks/useCRUD';
import Events from '../../../../lib/events';
import { retry } from '../../../../lib/util';
import ReferralsPanel from './ReferralsPanel';

import './billingmodal.scss';
import { getCrudData } from '../../../../store/selectors';
import useCRUDWithoutTab from '../../../../hooks/useCRUDWithoutTab';
import useMasterId from '../../../../hooks/useMasterId';

const FOLLOW_UP_SHORT = 'followup - short';
let previouslySelectedProcedures = [];

const masterCodes = [
  enumMasterCode.providerRetinaSpeciality,
];

const enumNames = [
  enums.PATIENT_ENCOUNTER_EXAM_TYPE,
];

const FollowUp = ({
  labels, hideSignBill = false, disabledAll = false, isOrderSet,
  // maxTagCount will come as props in case antd version will downgrade
}) => {
  const {
    params: {
      patientId, providerId, encounterId, tabId,
    },
  } = useRedirect();

  const [form] = AntdForm.useForm();
  const [isVisible, setVisible] = useState(false);
  const [followUpId, setFollowUpId] = useState();
  const [billVisibility, toggleBillModal] = useState(false);
  const masterCodesWithId = useMasterId(masterCodes, enumNames);
  const isLoading = useSelector((state) => state?.formHandler?.get(formId.DOCTOR_FOLLOW_UP_FORM)
    ?.loading);

  const formUpdatedData = useSelector((state) => (state.form.get(tabId)
    ? state.form.get(tabId)?.get(`followup-${encounterId}`)
    : state.form?.get(`followup-${encounterId}`)));

  const debouncedSetFields = useMemo(() => debounce((fieldsData) => {
    Events.trigger(`set-slit-orderset-data-${encounterId}`, ({ followUp: fieldsData }));
    form.setFieldsValue(fieldsData);
  }, 500),
  [encounterId, form]);

  useEffect(() => {
    if (isOrderSet && formUpdatedData && Object.values(formUpdatedData)?.length) {
      debouncedSetFields(formUpdatedData);
    }
  }, [debouncedSetFields, formUpdatedData, isOrderSet]);

  const billModal = (() => {
    toggleBillModal(!billVisibility);
  });

  const [
    plannedProcedures, ,
    plannedProceduresLoading,
    getPlannedProcedures,
  ] = useCRUD({
    id: `followup-planned-procedures-${providerId}`,
    url: apiUrls.DOCTOR_FOLLOW_UP_TOP_TEN_PROCEDURES,
    type: 'read',
  });

  useEffect(() => {
    getPlannedProcedures({
      ProviderId: providerId,
      PatientId: patientId,
      encounterId,
    });
    Events.on('reFetchProcedureTestingSelect', 'reFetchProcedureTestingSelect', () => getPlannedProcedures({
      ProviderId: providerId,
      PatientId: patientId,
      encounterId,
    }));
    Events.on('refetch-followup-planned-procedures', 'refetch-followup-planned-procedures', () => getPlannedProcedures({
      ProviderId: providerId,
      PatientId: patientId,
      EncounterId: encounterId,
    }));
    return () => {
      Events.remove('reFetchProcedureTestingSelect', 'reFetchProcedureTestingSelect');
      Events.remove('refetch-followup-planned-procedures', 'refetch-followup-planned-procedures');
    };
  }, []);

  const allPlannedProcedures = useMemo(() => {
    const plannedProceduresList = [];
    if (plannedProcedures) {
      const selectedItems = [];
      plannedProcedures.forEach((item) => {
        if (item?.isSelected) {
          selectedItems.push(item.cptCode);
          plannedProceduresList.unshift({
            ...item,
            value: item.cptCode,
            name: `${item.cptCode} (${item.cptDescription})`,
          });
        } else {
          plannedProceduresList.push({
            ...item,
            value: item.cptCode,
            name: `${item.cptCode} (${item.cptDescription})`,
          });
        }
      });
      form.setFieldsValue({
        ...form.getFieldsValue(),
        procedureIds: [...new Set(selectedItems)],
      });
      previouslySelectedProcedures = [...new Set(selectedItems)];
      return plannedProceduresList;
    }
    return [];
  }, [form, plannedProcedures]);

  const [
    followUpGetResponse,,
    loading,
    getFollowUp,
  ] = useCRUD({ id: 'doctor-follow-up', url: apiUrls.GET_DOCTOR_FOLLOW_UP, type: 'read' });

  const [providerData] = useCRUDWithoutTab({ id: `${listIds.DOCTOR_DETAILS}_${providerId}`, url: apiUrls.GET_PROVIDER, type: 'read' });

  const visitTypeList = useSelector((state) => getCrudData(state, 'visit-type-follow-up'));

  const defaultVisitType = useMemo(() => find(visitTypeList,
    ({ visitName }) => visitName?.toLowerCase()?.trim() === FOLLOW_UP_SHORT)
    ?.visitTypeId, [visitTypeList]);

  const debouncedGetSuperBillCall = useCallback(debounce(() => Events.trigger('refetch-superBill-planned-procedures'), 1000), []);

  const [
    applySuperBillCodesResponse,,,
    applySuperBillCodes,
    applySuperBillCodesClear,
  ] = useCRUD({
    id: 'doctor-apply-super-bill-codes',
    url: apiUrls.APPLY_SUPER_BILL_CODES,
    type: 'create',
  });

  useEffect(() => {
    if (applySuperBillCodesResponse) {
      debouncedGetSuperBillCall();
    }
    applySuperBillCodesClear(true);
  }, [applySuperBillCodesClear, applySuperBillCodesResponse]);

  useEffect(() => {
    getFollowUp({
      PatientId: patientId,
      ProviderId: providerId,
      EncounterId: encounterId,
    });
  }, [patientId, providerId, encounterId, getFollowUp]);

  useEffect(() => {
    Events.on('reFetchFollowUp', 'reFetchFollowUp', () => getFollowUp({
      PatientId: patientId,
      ProviderId: providerId,
      EncounterId: encounterId,
    }));
    return () => Events.remove('reFetchFollowUp', 'reFetchFollowUp');
  }, []);

  useEffect(() => {
    if (followUpGetResponse && !loading) {
      const followUp = { ...followUpGetResponse };
      form.setFieldsValue(followUp);
      setFollowUpId(followUpGetResponse.followupId);
    }
    if (!followUpGetResponse && !loading && defaultVisitType) {
      form.setFieldsValue({ visitTypeId: defaultVisitType });
    }
  }, [followUpGetResponse, form, defaultVisitType, loading]);

  useEffect(() => {
    if (providerData?.specialtyId
      && !followUpId
      && providerData?.specialtyId === masterCodesWithId?.['207WX0107X']
    ) {
      form.setFieldsValue({ dilation: true });
    }
  }, [followUpId, form, providerData]);

  const onSubmit = useCallback(debounce(() => {
    form.submit();
  }, 1000), [form]);
  // commented up one due to scrolling up on follow up input value change need to check

  const onRequestComplete = useCallback(({ response }) => {
    if (response.followupId) {
      setFollowUpId(response?.followupId);
    }
  }, []);

  const handleSuperBillCodes = useCallback((selectedValues) => {
    if (previouslySelectedProcedures?.length !== selectedValues?.length) {
      const diffArray = uniq([...previouslySelectedProcedures, ...selectedValues]);
      const domElements = diffArray.reduce((acc, item) => {
        document.querySelectorAll(`.cpt${item} .ant-checkbox-input`)?.forEach((nodeItem) => {
          const [name, sectionId] = nodeItem.getAttribute('data-testid')?.split(' ');
          if (selectedValues.includes(item)) {
            acc[sectionId] = { ...(acc[sectionId] || {}), [name]: true };
          } else {
            acc[sectionId] = { ...(acc[sectionId] || {}), [name]: false };
          }
        });
        return acc;
      }, {});
      Object.entries(domElements).forEach(([key, value]) => Events.trigger(`set-form-fields${key}`, value));
      previouslySelectedProcedures = selectedValues;
      const procedureIds = form.getFieldValue('procedureIds');
      applySuperBillCodes({
        data: {
          procedureCodes: procedureIds,
          patientId,
          encounterId,
          providerId,
        },
      });
    }
  }, [applySuperBillCodes, encounterId, form, patientId, providerId]);

  const removeProcedureIds = useCallback((data) => {
    // eslint-disable-next-line no-param-reassign
    delete data.procedureIds;
    return data;
  }, []);

  const BillModal = useMemo(() => {
    if (hideSignBill === false) {
      return lazy(() => retry(() => import('./BillingModal')));
    }
    return null;
  }, [hideSignBill]);

  const isDisabledAndPost = (isLoading && !followUpId);

  return (
    <div className="posi-relative">
      <Form
        formId={formId.DOCTOR_FOLLOW_UP_FORM}
        form={form}
        parser={removeProcedureIds}
        name={`followup-${encounterId}`}
        tabId={encounterId}
        extraData={{
          patientId,
          providerId,
          encounter_id: encounterId,
        }}
        onRequestComplete={onRequestComplete}
        isUpdate={!!followUpId}
        url={followUpId ? `${apiUrls.UPDATE_DOCTOR_FOLLOW_UP}/${followUpId}` : apiUrls.ADD_DOCTOR_FOLLOW_UP}
        disableForm={isDisabledAndPost}
        onValueChange={onSubmit}
        shouldShowLoader={false}
      >
        <Panel title="Follow up" showCloseIcon={false} className="follow-up posi-relative">
          {(loading || isDisabledAndPost) && <WidgetLoader />}
          <div className="follow-up-section-wrap">
            <Row className="follow-up-label">
              <Col>
                <span className="item-label">{labels.get('titles.followUp')}</span>
              </Col>
              <Col span={5} className="space">
                <Input
                  label={labels.get('labels.year')}
                  placeholder="xx"
                  labelAfter
                  name="year"
                  labelSpan="10"
                  inputSpan="11"
                  numberWithHyphen
                  disabled={isDisabledAndPost}
                  maxValueLength={2}
                />
              </Col>
              <Col span={5}>
                <Input
                  label={labels.get('labels.months')}
                  placeholder="xx"
                  labelAfter
                  name="month"
                  labelSpan="11"
                  inputSpan="11"
                  numberWithHyphen
                  disabled={isDisabledAndPost}
                  maxValueLength={2}
                />
              </Col>
              <Col span={5}>
                <Input
                  label={labels.get('labels.weeks')}
                  placeholder="xxx"
                  labelAfter
                  name="week"
                  labelSpan="11"
                  inputSpan="11"
                  numberWithHyphen
                  disabled={isDisabledAndPost}
                  maxValueLength={3}
                />
              </Col>
              <Col span={5}>
                <Input
                  label={labels.get('labels.days')}
                  placeholder="xxx"
                  labelAfter
                  name="days"
                  labelSpan="11"
                  inputSpan="11"
                  numberWithHyphen
                  disabled={isDisabledAndPost}
                  maxValueLength={3}
                />
              </Col>
            </Row>
            <div className="followup-row">
              <div className="row-1">
                <div className="column-1">
                  <WiredSelect
                    label={labels.get('labels.visitTypePlanned')}
                    name="visitTypeId"
                    id="visit-type-follow-up"
                    labelSpan="10"
                    inputSpan="14"
                    url={apiUrls.GET_DOCTOR_FOLLOW_UP_VISIT_TYPE}
                    valueAccessor="visitTypeId"
                    nameAccessor="visitName"
                    disabled={isDisabledAndPost}
                  />
                </div>
                {/* <div className="column-2">
                <SMCheckBox
                  className="labelLeft"
                  name="dilation"
                  disabled={isDisabledAndPost}
                >
                  {labels.get('labels.dilation')}
                </SMCheckBox>
                <SMCheckBox
                  className="labelLeft"
                  name="refract"
                  disabled={isDisabledAndPost}
                >
                  {labels.get('labels.refract')}
                </SMCheckBox>
              </div> */}
              </div>

            </div>

            <div className="followup-row">
              <div className="row-2">
                <Select
                  label="Authorizations for next visit"
                  name="procedureIds"
                  id="9"
                  options={allPlannedProcedures}
                  disabled={isDisabledAndPost}
                  selectProps={{
                    mode: 'multiple',
                    maxTagCount: 'responsive',
                    showArrow: true,
                    loading: plannedProceduresLoading,
                  }}
                  onChange={handleSuperBillCodes}
                  labelSpan="10"
                  inputSpan="14"
                />
              </div>
            </div>
            <div className="followup-row">
              <div className="row-2">
                <Input.TextArea
                  name="notes"
                  label={labels.get('labels.notesForFrontDesk')}
                  labelSpan="10"
                  inputSpan="14"
                  maxValueLength={1000}
                  rows={4}
                  placeholder="Notes"
                />
              </div>
            </div>
            <div className="follow-up-check-box">
              <SMCheckBox
                className="labelLeft"
                name="dilation"
                disabled={isDisabledAndPost}
              >
                {labels.get('labels.dilation')}
              </SMCheckBox>
              <SMCheckBox
                className="labelLeft"
                name="refract"
                disabled={isDisabledAndPost}
              >
                {labels.get('labels.refract')}
              </SMCheckBox>
            </div>
            <div className="followup-row">
              <div className="row-3">
                {!disabledAll && (
                <div className="row-btn-wrap">
                  <button
                    className="btn btn-success btn-block sm-btn"
                    onClick={setVisible}
                    type="button"
                  >
                    {labels.get('buttons.referrals')}
                  </button>
                  <button
                    className="btn btn-success sm-btn"
                    type="button"
                  >
                    {labels.get('buttons.scheduleSurgery')}
                  </button>
                </div>
                )}
                { !hideSignBill && (
                <button
                  className="btn btn-success btn-block sm-btn"
                  onClick={
                  billModal
                }
                  type="button"
                >
                  {labels.get('buttons.signNBill')}
                </button>
                )}
              </div>
            </div>
          </div>

          <Row className="mr-bt-8">
            <Col span={14}>
              <PopOver
                visible={isVisible}
                content={<ReferralsPanel setVisible={setVisible} labels={labels} />}
                placement="topLeft"
              />
            </Col>

          </Row>
          <Form.Section>
            <div className="btm-follow-up" />
          </Form.Section>
        </Panel>
      </Form>
      {!hideSignBill && (
      <BillModal
        billVisibility={billVisibility}
        toggleBillModal={billModal}
      />
      )}
    </div>
  );
};

export default React.memo(WithLabel(FollowUp, labelPaths.DOCTOR_FOLLOW_UP));
