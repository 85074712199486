import React from 'react';
import Label from '../../../../../components/Label';

import { dateFormat } from '../../../../../lib/constants';
import dateFormatter from '../../../../../lib/dateFormatter';

import '../financial.scss';

const SimpleChargeForm = ({ financialDetail }) => {
  const createdOn = financialDetail?.createdOn ? `on ${dateFormatter(financialDetail?.createdOn, dateFormat)}` : '';
  return (
    <div className="max-width-100 table-layout-field">
      <form>
        <div className="feild-row justify-content-sp-bt align-center no-top-mr">
          <h2>Financial Details</h2>
        </div>
        <div className="feild-row display-view  group-shadow">
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Type" />
              :
            </div>
            <div className="name-view">{financialDetail?.transactionType ?? ''}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Provider" />
              :
            </div>
            <div className="name-view">{financialDetail?.providerName ?? ''}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Location" />
              :
            </div>
            <div className="name-view">{financialDetail?.locationName ?? ''}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Entered By" />
              :
            </div>
            <div className="name-view">{`${financialDetail?.enteredBy ?? ''} ${createdOn}`}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Units" />
              :
            </div>
            <div className="name-view">{financialDetail?.unit ?? ''}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="RVU" />
              :
            </div>
            <div className="name-view">{financialDetail?.rvu ?? ' - '}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Amount" />
              :
            </div>
            <div className="name-view">{(financialDetail?.amount || 0).toFixed(2)}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Balance" />
              :
            </div>
            <div className="name-view">{(financialDetail?.balance || 0).toFixed(2)}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Responsibility" />
              :
            </div>
            <div className="name-view">{financialDetail?.isPatientResponsibility ? 'Patient' : 'Insurance'}</div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default SimpleChargeForm;
