import React from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';

import './tag.scss';

const CustomTag = (props) => {
  const {
    color, label, className, onClick, tagClassName,
  } = props;
  return (
    <Tag color={color} onClick={onClick} className={tagClassName}><span className={className || 'tag-label'}>{label}</span></Tag>
  );
};

CustomTag.defaultProps = {
  color: '',
  label: 'Tag',
  className: '',
};

CustomTag.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default CustomTag;
