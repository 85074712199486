import React, {
  useCallback, useEffect, useState, useMemo,
} from 'react';
import { Form as AntdForm, Pagination } from 'antd';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

import Form from '../../../../../components/Form';
import InsuranceMapTable from '../../../../../components/Table';
import Notification from '../../../../../components/Notification';
import Button from '../../../../../components/Button';
import Search from '../../../../../components/Search';
import Loader from '../../../../../components/Loader';
import WiredSelect from '../../../../../wiredComponents/Select';
import { apiUrls } from '../../../../../api/constants';
import { formId, listId } from '../../../../../lib/constants';
import Events from '../../../../../lib/events';
import useCRUD from '../../../../../hooks/useCRUD';
import useRedirect from '../../../../../hooks/useRedirect';
import columns from '../../AddPayer/columns';
import MapAsAnotherPayerModal from './MapAsAnotherPayerModal';
import './mapInsurance.scss';

const convertKeyInLowerCaseAndParse = (data) => JSON.parse(data);

const MapInsurance = ({ navigateBack, labels, payerId }) => {
  const [form] = AntdForm.useForm();
  const { params } = useRedirect();

  const { id } = params;
  const insurancePayerId = payerId || id;
  const [mapAsAnotherPayerModalVisible, setMapAsAnotherPayerModalVisible] = useState(false);
  const [mapWithOtherPayerData, setMapWithOtherPayerData] = useState();
  const [keyForAutoCompleteField, setKeyForAutoCompleteField] = useState([]);
  const [historyDataForTable, setHistoryDataForTable] = useState([]);
  const [filterHistoryDataForTable, setFilterHistoryDataForTable] = useState([]);
  const [alreadyFilterData, setAlreadyFilterData] = useState([]);
  const [selectedField, setSelectedField] = useState();
  const [sliceIndex, setSliceIndex] = useState(50);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataFetchPageIndexes, setDataFetchPageIndexes] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const [customColumn, setCustomColumn] = useState(columns());
  const [searchTextData, setSearchTextData] = useState();
  const [historyData,, loading, getHistoryData, clearHistoryData] = useCRUD({
    id: 'mapping-json-data', url: apiUrls.INSURANCE_VERIFICATION_HISTORY, type: 'read',
  });

  const [mapOtherPayerInsuranceResponse,, mappingLoading, mapAnotherPayerInsurance,
    clearMapOtherPayerInsuranceResponse] = useCRUD({
    id: 'copy-configuration', url: apiUrls.COPY_CONFIGURATION, type: 'create',
  });

  const [mappingKey,, mappingKeyLoading, getMappingKey] = useCRUD({
    id: 'mapping-key', url: apiUrls.GET_QUESTIONNAIRES_MASTER_DATA, type: 'read',
  });

  useEffect(() => {
    if (mappingKey?.length) {
      const Questionnaire = JSON.parse(mappingKey[0]?.Questionnaire);
      const SummaryResponseKeys = Questionnaire?.SummaryResponseKeys;
      if (historyData?.result?.length) {
        const mappingData = [];
        const keyForAutoComplete = [];
        const data = historyData.result || [];
        const parseData = {};
        data?.forEach((item, index) => {
          parseData[`data${(pageIndex - 1) * 10 + (index + 1)}`] = convertKeyInLowerCaseAndParse(item.summaryResponseJson);
        });
       SummaryResponseKeys?.forEach((key) => {
         const response1 = get(parseData?.[`data${(pageIndex - 1) * 10 + 1}`] || {}, key, null);
         if (response1 === null || response1 === undefined || typeof response1 === 'string'
         || typeof response1 === 'number' || typeof response1 === 'boolean') {
           const responseData = {};
           responseData[`response${(pageIndex - 1) * 10 + 1}`] = response1;
           responseData[`response${(pageIndex - 1) * 10 + 2}`] = get(parseData?.[`data${(pageIndex - 1) * 10 + 2}`] || {}, key, null);
           responseData[`response${(pageIndex - 1) * 10 + 3}`] = get(parseData?.[`data${(pageIndex - 1) * 10 + 3}`] || {}, key, null);
           responseData[`response${(pageIndex - 1) * 10 + 4}`] = get(parseData?.[`data${(pageIndex - 1) * 10 + 4}`] || {}, key, null);
           responseData[`response${(pageIndex - 1) * 10 + 5}`] = get(parseData?.[`data${(pageIndex - 1) * 10 + 5}`] || {}, key, null);
           responseData[`response${(pageIndex - 1) * 10 + 6}`] = get(parseData?.[`data${(pageIndex - 1) * 10 + 6}`] || {}, key, null);
           responseData[`response${(pageIndex - 1) * 10 + 7}`] = get(parseData?.[`data${(pageIndex - 1) * 10 + 7}`] || {}, key, null);
           responseData[`response${(pageIndex - 1) * 10 + 8}`] = get(parseData?.[`data${(pageIndex - 1) * 10 + 8}`] || {}, key, null);
           responseData[`response${(pageIndex - 1) * 10 + 9}`] = get(parseData?.[`data${(pageIndex - 1) * 10 + 9}`] || {}, key, null);
           responseData[`response${(pageIndex - 1) * 10 + 10}`] = get(parseData?.[`data${(pageIndex - 1) * 10 + 10}`] || {}, key, null);
           mappingData.push({
             key,
             ...responseData,
           });
           keyForAutoComplete.push({ name: key, value: key });
         }
       });
       setDataFetchPageIndexes({
         ...dataFetchPageIndexes,
         [historyData?.pageIndex + 1]: mappingData,
       });
       setHistoryDataForTable(mappingData);
       if (searchTextData?.length) {
         const filterData = mappingData?.filter(
          (value) => value?.key?.toLowerCase()?.includes(searchTextData?.toLowerCase()));
         setAlreadyFilterData(filterData);
         setFilterHistoryDataForTable(filterData.slice(0, 50));
       } else {
         setFilterHistoryDataForTable(mappingData.slice(0, 50));
       }
       setKeyForAutoCompleteField(keyForAutoComplete);
      } else {
        const mappingData = [];
        const keyForAutoComplete = [];
        SummaryResponseKeys?.forEach((key) => {
          mappingData.push({
            key,
          });
          keyForAutoComplete.push({ name: key, value: key });
        });
        setHistoryDataForTable(mappingData);
        setFilterHistoryDataForTable(mappingData.slice(0, 50));
        setKeyForAutoCompleteField(keyForAutoComplete);
      }
    }
  }, [historyData, mappingKey]);

  useEffect(() => {
    getHistoryData({
      healthPlanId: insurancePayerId, SortBy: 'modifiedOn', SortOrder: 'desc', pageIndex: 0, pageSize: 10,
    });
    return () => clearHistoryData(true);
  }, []);

  useEffect(() => {
    if (!mappingKey) {
      getMappingKey({ Descriptor: 'pverifyresponse' });
    }
  }, [mappingKey]);

  useEffect(() => {
    if (mapOtherPayerInsuranceResponse) {
      Notification({ message: mapOtherPayerInsuranceResponse, success: true });
      setMapAsAnotherPayerModalVisible(!mapAsAnotherPayerModalVisible);
      clearMapOtherPayerInsuranceResponse();
      Events.trigger('refetch-payer-mapping');
    }
  }, [mapOtherPayerInsuranceResponse]);

  const handleMapAsAnotherPayerModalVisible = useCallback(() => {
    setMapAsAnotherPayerModalVisible(!mapAsAnotherPayerModalVisible);
  }, [mapAsAnotherPayerModalVisible]);

  const mapOtherPayerInsurance = useCallback(() => {
    if (mapWithOtherPayerData) {
      mapAnotherPayerInsurance({
        data: {
          copyFromPayerId: mapWithOtherPayerData,
          copyToPayerId: insurancePayerId,
        },
      });
    }
  }, [insurancePayerId, mapAnotherPayerInsurance, mapWithOtherPayerData]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: response, success: true });
    }
  }, []);

  const parser = useCallback((formValues) => ({
    ...formValues,
    payerId: insurancePayerId,
  }), [insurancePayerId]);

  const onGetResponseComplete = useCallback((response) => {
    if (response?.result?.length) {
      const payerMappingConfiguration = response.result[0]?.payerMappingConfiguration;
      if (payerMappingConfiguration) {
        form.setFieldsValue({
          coInsurance: payerMappingConfiguration?.coInsurance,
          coPayment: payerMappingConfiguration?.coPayment,
          deductible: payerMappingConfiguration?.deductible,
          effectiveFrom: payerMappingConfiguration?.effectiveFrom,
          effectiveTo: payerMappingConfiguration?.effectiveTo,
          groupName: payerMappingConfiguration?.groupName,
          groupNumber: payerMappingConfiguration?.groupNumber,
          outDeductible: payerMappingConfiguration?.outDeductible,
          payerName: payerMappingConfiguration?.payerName,
          planName: payerMappingConfiguration?.planName,
          policyType: payerMappingConfiguration?.policyType,
        });
      } else {
        form.setFieldsValue({
          coPayment: 'SpecialistOfficeSummary.CoPayInNet.Value',
          deductible: 'HBPC_Deductible_OOP_Summary.IndividualDeductibleInNet.Value',
          outDeductible: 'HBPC_Deductible_OOP_Summary.IndividualDeductibleRemainingInNet.Value',
          coInsurance: 'SpecialistOfficeSummary.CoInsInNet.Value',
          effectiveFrom: 'PlanCoverageSummary.EffectiveDate',
          effectiveTo: 'PlanCoverageSummary.ExpiryDate',
        });
      }
    }
  }, [form]);

  const selectDataForCopy = useCallback((data) => {
    if (selectedField) {
      form.setFieldsValue({ [selectedField]: data });
    }
  }, [form, selectedField]);

  const handleOnFocus = useCallback((key) => () => {
    setSelectedField(key);
  }, []);

  const handleFilterData = useCallback((searchText) => {
    if (searchText?.length) {
      const filterData = historyDataForTable.filter(
        (value) => value?.key?.toLowerCase()?.includes(searchText?.toLowerCase()),
      );
      setAlreadyFilterData(filterData);
      setFilterHistoryDataForTable(filterData.slice(0, 50));
      setSliceIndex(50);
      if (filterData?.length < 50) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } else {
      setFilterHistoryDataForTable(historyDataForTable.slice(0, 50));
      setAlreadyFilterData([]);
      setSliceIndex(50);
      setHasMore(true);
    }
    setSearchTextData(searchText);
  }, [historyDataForTable]);

  const debouncedSearch = useMemo(() => debounce(handleFilterData, 500), [handleFilterData]);

  const handleOnChange = useCallback(({ target: { value } }) => {
    debouncedSearch(value);
  }, [debouncedSearch]);

  const onFetchMore = useCallback(() => {
    if (!loading || !mappingKeyLoading) {
      const tableData = alreadyFilterData.length ? alreadyFilterData : historyDataForTable;
      if (tableData[sliceIndex + 50]) {
        setTimeout(() => {
          setFilterHistoryDataForTable(
            tableData.slice(0, sliceIndex + 50),
          );
          setSliceIndex(sliceIndex + 50);
        }, 500);
      } else {
        setFilterHistoryDataForTable(
          tableData.slice(0),
        );
        setHasMore(false);
      }
    }
  }, [alreadyFilterData, historyDataForTable, loading, mappingKeyLoading, sliceIndex]);

  const onChange = useCallback(
    (data) => {
      if (dataFetchPageIndexes[data]) {
        if (searchTextData?.length) {
          const filterData = dataFetchPageIndexes[data]?.filter(
            (value) => value?.key?.toLowerCase()?.includes(searchTextData?.toLowerCase())
          );
          setAlreadyFilterData(filterData);
          setFilterHistoryDataForTable(filterData?.slice(0, 50));
        } else {
          setFilterHistoryDataForTable(dataFetchPageIndexes[data]?.slice(0, 50));
        }
        setHistoryDataForTable(dataFetchPageIndexes[data]);
      } else {
        getHistoryData({
          healthPlanId: insurancePayerId, SortBy: 'modifiedOn', SortOrder: 'desc', pageIndex: data - 1, pageSize: 10,
        });
      }
      setPageIndex(data);
      const newCol = [];
      const startIndex = (data - 1) * 10 + 1;
      for (let i = startIndex; i < startIndex + 10; i += 1) {
        newCol.push({
          Header: `Response ${i}`,
          accessor: `response${i}`,
          Cell: ({ row: { original } }) => (
            <span>
              {
            original && original[`response${i}`]?.toString()
              }
            </span>
          ),
        });
      }
      setCustomColumn(newCol);
    },
    [dataFetchPageIndexes, getHistoryData, insurancePayerId, searchTextData],
  );

  return (
    <>
      <div>
        <Form
          form={form}
          section
          isUpdate
          formId={formId.ADD_PAYER_MAPPING}
          url={apiUrls.REQUEST_PAYER_MAPPING}
          listId={listId.ADD_PAYER_MAPPING}
          getUrl={id && `${apiUrls.GET_PAYER_MAPPING}?InsurancePayerId=${id}`}
          parser={parser}
          onRequestComplete={onRequestComplete}
          onGetResponseComplete={onGetResponseComplete}
          refetchId="refetch-payer-mapping"
          extraData={{ method: 'post' }}
          initialData={{
            coPayment: 'SpecialistOfficeSummary.CoPayInNet.Value',
            deductible: 'HBPC_Deductible_OOP_Summary.IndividualDeductibleInNet.Value',
            outDeductible: 'HBPC_Deductible_OOP_Summary.IndividualDeductibleRemainingInNet.Value',
            coInsurance: 'SpecialistOfficeSummary.CoInsInNet.Value',
            effectiveFrom: 'PlanCoverageSummary.EffectiveDate',
            effectiveTo: 'PlanCoverageSummary.ExpiryDate',
          }}
        >
          <div className="sift-right group-btns">
            <Button className="btn min-wt-86 sm-btn" id="patients_add_cancel" onClick={navigateBack}>{labels.get('buttons.cancel')}</Button>
            <Button className="btn btn-success min-wt-86 sm-btn" id="patients_add_save" type="submit">{id ? labels.get('buttons.update') : labels.get('buttons.save')}</Button>
            <Button className="btn min-wt-86 sm-btn mr-bt-8 btn-success" onClick={handleMapAsAnotherPayerModalVisible}>{labels.get('buttons.mapAsAnotherPayer')}</Button>
          </div>
          <div className="main-form-container">
            <div className="add-patient-cl">

              <Form.Section noOfColumns={3}>
                <div className="group-shadow">
                  <Form.Column>
                    <div className="wrap-insurence-map">
                      <WiredSelect
                        id="payerName"
                        label={labels.get('labels.payerName')}
                        name="payerName"
                        placeholder="Select Payer Name"
                        labelSpan={8}
                        inputSpan={16}
                        defaultOptions={keyForAutoCompleteField}
                        minCharLength={2}
                        selectProps={{
                          showSearch: true,
                          onFocus: handleOnFocus('payerName'),
                          dropdownMatchSelectWidth: true,
                        }}
                      />
                    </div>
                    <WiredSelect
                      id="policyType"
                      label={labels.get('labels.policyType')}
                      name="policyType"
                      placeholder="Select P-verify Policy Type"
                      labelSpan={8}
                      inputSpan={16}
                      defaultOptions={keyForAutoCompleteField}
                      minCharLength={2}
                      selectProps={{
                        showSearch: true,
                        onFocus: handleOnFocus('policyType'),
                        dropdownMatchSelectWidth: true,
                      }}
                    />
                    <WiredSelect
                      id="planName"
                      label={labels.get('labels.planName')}
                      name="planName"
                      placeholder="Select P-verify Plan Name"
                      labelSpan={8}
                      inputSpan={16}
                      defaultOptions={keyForAutoCompleteField}
                      minCharLength={2}
                      selectProps={{
                        showSearch: true,
                        onFocus: handleOnFocus('planName'),
                        dropdownMatchSelectWidth: true,
                      }}
                    />
                    <WiredSelect
                      id="groupNumber"
                      label={labels.get('labels.groupNumber')}
                      name="groupNumber"
                      placeholder="Select Group Number"
                      labelSpan={8}
                      inputSpan={16}
                      defaultOptions={keyForAutoCompleteField}
                      minCharLength={2}
                      selectProps={{
                        showSearch: true,
                        onFocus: handleOnFocus('groupNumber'),
                        dropdownMatchSelectWidth: true,
                      }}
                    />
                  </Form.Column>
                </div>
                <div className="group-shadow">
                  <Form.Column>
                    <WiredSelect
                      id="groupName"
                      label={labels.get('labels.groupName')}
                      name="groupName"
                      placeholder="Select Group Name"
                      labelSpan={8}
                      inputSpan={16}
                      defaultOptions={keyForAutoCompleteField}
                      minCharLength={2}
                      selectProps={{
                        showSearch: true,
                        onFocus: handleOnFocus('groupName'),
                        dropdownMatchSelectWidth: true,
                      }}
                    />
                    <WiredSelect
                      id="coPayment"
                      label={labels.get('labels.coPayment')}
                      name="coPayment"
                      placeholder="Select Co-Payment"
                      labelSpan={8}
                      inputSpan={16}
                      defaultOptions={keyForAutoCompleteField}
                      minCharLength={2}
                      selectProps={{
                        showSearch: true,
                        onFocus: handleOnFocus('coPayment'),
                        dropdownMatchSelectWidth: true,
                      }}
                    />
                    <WiredSelect
                      id="deductible"
                      label={labels.get('labels.deductible')}
                      name="deductible"
                      placeholder="Select Deductible"
                      labelSpan={8}
                      inputSpan={16}
                      defaultOptions={keyForAutoCompleteField}
                      minCharLength={2}
                      selectProps={{
                        showSearch: true,
                        onFocus: handleOnFocus('deductible'),
                        dropdownMatchSelectWidth: true,
                      }}
                    />
                    <WiredSelect
                      id="outDeductible"
                      label={labels.get('labels.outDeductible')}
                      name="outDeductible"
                      placeholder="Select Out. Deductible"
                      labelSpan={8}
                      inputSpan={16}
                      defaultOptions={keyForAutoCompleteField}
                      minCharLength={2}
                      selectProps={{
                        showSearch: true,
                        onFocus: handleOnFocus('outDeductible'),
                        dropdownMatchSelectWidth: true,
                      }}
                    />
                  </Form.Column>
                </div>
                <div className="group-shadow">
                  <Form.Column>
                    <WiredSelect
                      id="coInsurance"
                      label={labels.get('labels.coInsurance')}
                      name="coInsurance"
                      placeholder="Select Co-Insurance"
                      labelSpan={8}
                      inputSpan={16}
                      defaultOptions={keyForAutoCompleteField}
                      minCharLength={2}
                      selectProps={{
                        showSearch: true,
                        onFocus: handleOnFocus('coInsurance'),
                        dropdownMatchSelectWidth: true,
                      }}
                    />
                    <WiredSelect
                      id="effectiveFrom"
                      label={labels.get('labels.effectiveFrom')}
                      name="effectiveFrom"
                      placeholder="Select Effective From"
                      labelSpan={8}
                      inputSpan={16}
                      defaultOptions={keyForAutoCompleteField}
                      minCharLength={2}
                      selectProps={{
                        showSearch: true,
                        onFocus: handleOnFocus('effectiveFrom'),
                        dropdownMatchSelectWidth: true,
                      }}
                    />
                    <WiredSelect
                      id="effectiveTo"
                      label={labels.get('labels.effectiveTo')}
                      name="effectiveTo"
                      placeholder="Select Effective To"
                      labelSpan={8}
                      inputSpan={16}
                      defaultOptions={keyForAutoCompleteField}
                      minCharLength={2}
                      selectProps={{
                        showSearch: true,
                        onFocus: handleOnFocus('effectiveTo'),
                        dropdownMatchSelectWidth: true,
                      }}
                    />
                  </Form.Column>
                </div>
              </Form.Section>
            </div>
          </div>
        </Form>
      </div>
      <div className="mr-top-12 with-pagination">
        {(loading || mappingKeyLoading) && <Loader />}
        <Search
          className="patent-list-search mr-bt-10"
          placeholder="Search key"
          onChange={handleOnChange}
        />
        <div className="insurance-mapping-key-table-wrapper">
          <InsuranceMapTable
            columns={[{
              Header: 'Key',
              accessor: 'key',
              Cell: ({
                row: {
                  original: {
                    key,
                  },
                },
              }) => {
                const onIconClick = useCallback(() => {
                  selectDataForCopy(key);
                }, [key]);
                return <span role="presentation" onClick={onIconClick}>{key}</span>;
              },
            }, ...customColumn]}
            data={filterHistoryDataForTable}
            onFetchMore={onFetchMore}
            hasMore={hasMore}
            scrollId="mapping-table"
          />
        </div>
        <Pagination
          onChange={onChange}
          total={historyData?.totalCount}
          showSizeChanger={false}
        />
      </div>
      {mapAsAnotherPayerModalVisible && (
        <MapAsAnotherPayerModal
          labels={labels}
          isVisible={mapAsAnotherPayerModalVisible}
          handleMapAsAnotherPayerModalVisible={handleMapAsAnotherPayerModalVisible}
          setMapAsAnotherPayerModalVisible={setMapAsAnotherPayerModalVisible}
          mapWithOtherPayerData={mapWithOtherPayerData}
          setMapWithOtherPayerData={setMapWithOtherPayerData}
          mapOtherPayerInsurance={mapOtherPayerInsurance}
          mappingLoading={mappingLoading}
        />
      )}
    </>
  );
};

export default MapInsurance;
