import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Menu, Dropdown, Collapse } from 'antd';
import get from 'lodash/get';
import moment from 'moment';
import set from 'lodash/set';

import isFunction from 'lodash/isFunction';
import Events from '../../../../../lib/events';
import useCRUD from '../../../../../hooks/useCRUD';
import useRedirect from '../../../../../hooks/useRedirect';
import { apiUrls } from '../../../../../api/constants';
import { listIds, enums } from '../../../../../lib/constants';

import Spinner from '../../../../../components/Spinner';
import Notification from '../../../../../components/Notification';

import { getEnumMaster } from '../../../../../store/selectors';
import SlitExamSelect from '../Components/CommonExamSelect';
import WidgetLoader from '../../../../../components/WidgetLoader';
import useCRUDWithoutTab from '../../../../../hooks/useCRUDWithoutTab';

const SlitExam = ({
  enumMaster, scrollId, fetchCall = true, slitExamData, setOrderSetData, displayOnly,
}) => {
  const { Panel } = Collapse;

  const { params: { patientId, encounterId, previousEncounterId } } = useRedirect();
  const [formData, setFormData] = useState({});
  const [values, setValues] = useState({});
  const [savedValues, setSavedValues] = useState({});

  const [
    enumResponse,
    enumError,
    enumLoading,
    getEnumData,
    clearEnum,
  ] = useCRUDWithoutTab({
    id: listIds.SLIT_EXAM_ENUM,
    url: apiUrls.GET_ENUM_FIELDS,
    type: 'read',
  });

  const [
    savedSlitExams,, clearSavedSlitLoading,
    getSavedSlitExams,
  ] = useCRUD({
    id: listIds.GET_SLIT_EXAM,
    url: apiUrls.GET_SLIT_EXAM,
    type: 'read',
  });

  const [
    setDefaultResponse,, setDefaultLoading,
    setDefaultValues,
    clearDefaultValueResponse,
  ] = useCRUD({
    id: listIds.DEFAULT_SLIT_VALUES,
    url: apiUrls.DEFAULT_SLIT_VALUES,
    type: 'update',
  });

  const [
    clearResponse,, clearLoading,
    clearValues,
    clearClearResponse,
  ] = useCRUD({
    id: listIds.CLEAR_SLIT_VALUES,
    url: apiUrls.CLEAR_SLIT_VALUES,
    type: 'create',
  });

  const [
    copyPreviousResponse,, copyPreviousLoading,
    copyPreviousValues,
    clearCopyPreviousResponse,
  ] = useCRUD({
    id: listIds.COPY_PREVIOUS_SLIT,
    url: apiUrls.COPY_PREVIOUS_SLIT,
    type: 'update',
  });

  const [
    encounterList,
    encounterListError,
    encounterListLoading,
    getEncounterList,
    clearEncounterList,
  ] = useCRUDWithoutTab({
    id: `${listIds.ENCOUNTER_LIST}-${patientId}`,
    url: apiUrls.GET_PATIENT_ENCOUNTER_LIST,
    type: 'read',
  });

  const [
    ,,,
    saveSlit,
  ] = useCRUD({
    id: listIds.SAVE_SLIT_EXAM,
    url: apiUrls.SAVE_SLIT_EXAM,
    type: 'createWithDebounce',
  });

  useEffect(() => {
    if (copyPreviousResponse && !Array.isArray(copyPreviousResponse)) {
      getSavedSlitExams({
        patientId,
        EncounterId: encounterId,
      });
      clearCopyPreviousResponse(true);
    }
  }, [copyPreviousResponse]);

  useEffect(() => {
    if (clearResponse && !Array.isArray(clearResponse)) {
      getSavedSlitExams({
        patientId,
        EncounterId: encounterId,
      });
      clearClearResponse(true);
    }
  }, [clearResponse]);

  useEffect(() => {
    if (setDefaultResponse && !Array.isArray(setDefaultResponse)) {
      getSavedSlitExams({
        patientId,
        EncounterId: encounterId,
      });
      clearDefaultValueResponse(true);
    }
  }, [setDefaultResponse]);

  useEffect(() => {
    if (fetchCall) {
      getSavedSlitExams({
        patientId,
        EncounterId: encounterId,
      });
    }
  }, [encounterId, getSavedSlitExams, patientId]);

  useEffect(() => {
    if (!enumResponse) {
      getEnumData({
        EnumId: get(enumMaster, `${enums.PATIENT_QUESTIONNAIRES_MASTER_SLIT_EXAM}.enumId`),
      });
    }
  }, [enumMaster, getEnumData]);

  useEffect(() => {
    if (enumError) {
      Notification({ message: enumError });
      clearEnum(true);
    }
  }, [enumError, clearEnum]);

  useEffect(() => {
    Events.on('reFetchSlitExam', 'reFetchSlitExam', () => getSavedSlitExams({
      patientId,
      EncounterId: encounterId,
    }));
    return () => Events.remove('reFetchSlitExam', 'reFetchSlitExam');
  }, []);

  useEffect(() => {
    if (!encounterList) {
      getEncounterList({ PatientId: patientId });
    }
  }, [encounterList]);

  useEffect(() => {
    if (encounterListError) {
      Notification({ message: encounterListError });
      clearEncounterList(true);
    }
  }, [encounterListError, clearEncounterList]);

  const setDefault = useCallback((eyeType) => () => {
    setDefaultValues({
      patientId: parseInt(patientId, 10),
      encounterId: parseInt(encounterId, 10),
      eyeType,
    });
  }, [encounterId, patientId, setDefaultValues]);

  const copyPrevious = useCallback(({ key }) => {
    copyPreviousValues({
      patientId: parseInt(patientId, 10),
      encounterId: parseInt(encounterId, 10),
      previousEncounterId: parseInt(key, 10),
    });
  }, [copyPreviousValues, encounterId, patientId]);

  const clear = useCallback((EyeType) => () => {
    clearValues({
      data: {
        patientId: parseInt(patientId, 10),
        encounterId: parseInt(encounterId, 10),
        EyeType,
      },
    });
  }, [clearValues, encounterId, patientId]);

  const setDefaultODAndOS = useCallback(() => {
    setDefault('OD')();
    setDefault('OS')();
  }, [setDefault]);

  const handleSave = useCallback((value) => {
    setValues(value);
    const slitExamList = [
      ...Object.keys(value.OS).map((item) => ({ eyeType: 'OS', examId: item, result: value.OS[item] })),
      ...Object.keys(value.OD).map((item) => ({ eyeType: 'OD', examId: item, result: value.OD[item] })),
    ];
    if (setOrderSetData && isFunction(setOrderSetData)) {
      setOrderSetData({
        slitExamList,
      });
    } else {
      Events.trigger(`set-slit-orderset-data-${encounterId}`, { slitExamList });
    }
    saveSlit({
      data: {
        patientId,
        encounterId,
        list: slitExamList,
      },
    });
  }, [encounterId, patientId, saveSlit, setOrderSetData]);

  const handleCopyAll = useCallback((fromField, toField) => {
    const clonedFormData = { OD: {}, OS: {}, ...values };
    setFormData({ ...formData, [toField]: formData[fromField] });
    set(clonedFormData, `${toField}`, formData[fromField]);
    setSavedValues(clonedFormData);
    handleSave(clonedFormData);
  }, [formData, handleSave, values]);

  const CopyODtoOS = useCallback(() => handleCopyAll('OD', 'OS'), [handleCopyAll]);
  const CopyOStoOD = useCallback(() => handleCopyAll('OS', 'OD'), [handleCopyAll]);

  return (
    <Collapse
      className="info-accordian collapse-wrapper"
      expandIconPosition="right"
      defaultActiveKey={['1']}
    >
      <Panel className="main-acc-header posi-relative exam-heading" key="1" header="Slit Lamp Exam">
        {(enumLoading
          || clearSavedSlitLoading
          || setDefaultLoading
          || copyPreviousLoading
          || clearLoading) && <WidgetLoader />}
        <>
          <div className="header-pannel">
            <div className="head box-one centter-align" role="presentation">
              <span
                className="check-icon"
                onClick={setDefaultODAndOS}
                role="presentation"
                title="Make all slit-lamp exams normal"
              />
            </div>
            <div className="head box-two copy-all-os-od-wrap">
              <div className="od">OD</div>
              {/* <CheckBox className="prescription-checkbox" /> */}
              <div className="close-circle" onClick={clear('OD')} role="presentation" title="Clear" />
              <div className="head box-one centter-align" role="presentation">
                <span className="check-icon" onClick={setDefault('OD')} role="presentation" title="Make all slit-lamp exams normal" />
              </div>
              <div className="head box-two centter-align" role="presentation">
                <span className="right-arrow copy-all-os-od-right" title="Copy OD to OS" role="presentation" onClick={CopyODtoOS} />
              </div>
              {previousEncounterId !== '__null' && (
              <Dropdown
                overlay={(encounterListLoading ? <Spinner />
                  : (
                    <Menu onClick={copyPrevious}>
                      {encounterList && encounterList.map((encounterData) => {
                        if (`${encounterData.encounterId}` !== `${encounterId}`) {
                          return (
                            <Menu.Item key={encounterData.encounterId}>
                              {`${moment(encounterData.serviceDateString || encounterData.serviceDate).format('MM-DD-YYYY')} - ${encounterData.visitType} , ${encounterData.providerFirstName || ''} ${encounterData.providerMiddleName || ''} ${encounterData.providerLastName || ''}`}
                            </Menu.Item>
                          );
                        }
                        return null;
                      })}
                    </Menu>
                  )
              )}
                trigger={['click']}
              >
                <div
                  className="copy-icon"
                  title="Copy last Slit-Lamp exam"
                />
              </Dropdown>
              )}
            </div>
            <div className="head box-three copy-all-os-od-wrap">
              <div className="os">OS</div>
              {/* <CheckBox className="prescription-checkbox" /> */}
              <div className="close-circle " onClick={clear('OS')} role="presentation" title="Clear" />
              <div className="head box-two centter-align" role="presentation">
                <span className="check-icon check-icon-right" onClick={setDefault('OS')} role="presentation" title="Make all slit-lamp exams normal" />
              </div>
              <div className="head box-one centter-align" role="presentation">
                <span className="left-arrow copy-all-os-od" title="Copy OS to OD" role="presentation" onClick={CopyOStoOD} />
              </div>
            </div>
          </div>
          {enumResponse && enumResponse.map(({ masterId }) => (
            <SlitExamSelect
              masterId={masterId}
              key={masterId}
              savedExamResult={!fetchCall ? slitExamData : savedSlitExams}
              scrollId={scrollId}
              formData={formData}
              setFormData={setFormData}
              values={values}
              setValues={setValues}
              handleSave={handleSave}
              savedValues={savedValues}
              setSavedValues={setSavedValues}
              id={`${listIds.SLIT_EXAM_SELECT}_${masterId}`}
              apiUrl={apiUrls.GET_QUESTIONNAIRES_MASTER_DATA}
              displayOnly={displayOnly}
            />
          ))}
        </>
      </Panel>
    </Collapse>
  );
};

const mapStateToProps = (state) => ({
  enumMaster: getEnumMaster(state),
});

export default connect(mapStateToProps)(React.memo(SlitExam));
