import React from 'react';

import { labelPaths } from '../../../../lib/constants';
import rights from '../../../../lib/rights';

import WithLabel from '../../../../hoc/withLabel';
import useRights from '../../../../hooks/useRights';

import TabsComponent from '../../../../components/Tabs';

import Terms from './Terms';
import General from './General';
import Mapping from './Mapping';

const tabsList = [
  {
    id: 'general',
    component: General,
  },
  {
    id: 'mapping',
    component: Mapping,
  },
  {
    id: 'terms',
    component: Terms,
  },
];

const Tabs = ({ labels = [] }) => {
  const [isUpdateContractAuthenticated] = useRights([rights.can_create_and_edit_contracts]);
  const clonedTabList = [];

  tabsList.forEach((tab) => {
    clonedTabList.push({ ...tab, name: labels.get(`tabs.${tab.id}`) });
  });
  return (
    <TabsComponent tabsList={clonedTabList} className="main-tab-wrap" isUpdateContractAuthenticated={isUpdateContractAuthenticated} />
  );
};

export default WithLabel(Tabs, labelPaths.EDIT_CONTRACT);
