import React, { } from 'react';

import {
  labelPaths,
} from '../../../../../lib/constants';
import WithLabel from '../../../../../hoc/withLabel';
import MappingHtml from './MappingHtml';

function Mapping() {
  return (
    <MappingHtml />
  );
}

export default WithLabel(Mapping, labelPaths.EDIT_CONTRACT);
