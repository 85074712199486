import React, { useCallback, useMemo } from 'react';
import { Form as AntdForm } from 'antd';

import UserAutoComplete from '../../../../../wiredComponents/UserAutoComplete';
import {
  formId,
} from '../../../../../lib/constants';
import SuccessMessage from '../../../../../lib/successMessages';
import Events from '../../../../../lib/events';
import Modals from '../../../../../components/Modal';
import Button from '../../../../../components/Button';
import Form from '../../../../../components/Form';
import { apiUrls } from '../../../../../api/constants';
import Notification from '../../../../../components/Notification';
import ErrorMessages from '../../../../../lib/errorMessages';

const AssignItem = ({
  isVisible, labels, selectedRow, toggleAssignToModal,
}) => {
  const [form] = AntdForm.useForm();
  const workListIds = useMemo(() => {
    const selectedWorkListIds = [];
    if (selectedRow?.length) {
      selectedRow.map(({ original: { workListId } }) => selectedWorkListIds.push(workListId));
    }
    return selectedWorkListIds;
  }, [selectedRow]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessage.WORK_LIST_ASSIGNED_SUCCESSFULLY, success: true });
      Events.trigger('refetchSuspenseDetailTable');
      toggleAssignToModal();
    }
  }, [toggleAssignToModal]);

  const submitForm = useCallback(() => {
    const formValue = form.getFieldsValue();
    if (workListIds?.length) {
      if (formValue.assignedTo) form.submit();
      else Notification({ message: ErrorMessages.SELECT_AT_LEAST_ONE_WORK_LIST_ITEM });
    } else Notification({ message: ErrorMessages.SELECT_USER_TO_ASSIGN_WORK_LIST });
  }, [form, workListIds]);

  return (
    <Modals
      visible={isVisible}
      title={labels.get('titles.assignWorkListItem')}
      toggleModal={toggleAssignToModal}
      destroyOnClose
      width={400}
      footer={[
        <Button key="cancel_workList" onClick={toggleAssignToModal}>{labels.get('buttons.cancel')}</Button>,
        <Button key="add_workList" className="btn btn-success" onClick={submitForm}>{labels.get('buttons.add')}</Button>,
      ]}
    >
      <Form
        form={form}
        formId={formId.SUSPENSE_ASSIGN_TO}
        url={apiUrls.ASSIGN_WORK_LIST}
        onRequestComplete={onRequestComplete}
        extraData={{ workListIds }}
        isUpdate
      >
        <UserAutoComplete
          name="assignedTo"
          label={labels.get('labels.assignedTo')}
          form={form}
          minCharLength={3}
          labelSpan={10}
          inputSpan={14}
          header={false}
        />
      </Form>
    </Modals>
  );
};
export default AssignItem;
