import React from 'react';
import Icon from '../../../../../components/Icon';
import iconNames from '../../../../../lib/iconNames';

export default [
  {
    Header: 'Name',
    accessor: 'roleName',
  },
  {
    Header: 'Locations',
    accessor: 'locationName',
  },
  {
    Header: 'Action',
    accessor: 'operation',
    Cell: ({ isManagePermitted, row, handleDeleteRole }) => {
      if (isManagePermitted) {
        return (
          <span className="add-location-feild-wrap">
            <span className="delete-icon">
              <Icon
                name={iconNames.deleteIcon}
                onClick={() => handleDeleteRole(row?.original?.roleId, false)}
              />
            </span>
          </span>
        );
      }
      return null;
    },
  },
];
