import React from 'react';
import {
  Form,
} from 'antd';
import { apiUrls } from '../../../../api/constants';
import { listId } from '../../../../lib/constants';

import withQuery from '../../../../hoc/withQuery/withQuery';
import withReduxManager from '../../../../hoc/withReduxManager';

import FilterManager from '../../../../components/FilterManager';
import CheckBox from '../../../../components/Form/CheckBox';

const columns = (onICDCheckBoxClick, labels) => [
  {
    Header: labels.get('tables.select'),
    accessor: 'selectAll',
    fixWidth: '50',
    Cell: ({ row: { original }, checkedICDCodes }) => (
      <Form>
        <CheckBox
          label=""
          labelSpan="0"
          inputSpan="2"
          checked={!!checkedICDCodes[original.id]}
          onChange={() => { onICDCheckBoxClick(original); }}
        />
      </Form>
    ),
  },
  {
    Header: labels.get('tables.ICDCode'),
    fixWidth: '100',
    accessor: 'icdCode',
  },
  {
    Header: labels.get('tables.ICDDescription'),
    accessor: 'description',
  },
];

const initialSort = [{ id: 'icdCode', desc: false }];

const CustomPayerModalTable = withReduxManager({
  listId: listId.PAYER_CPT_ICD,
})(withQuery({
  url: apiUrls.GET_SETTINGS_ICD,
  listId: listId.PAYER_CPT_ICD,
  accessor: (data) => ({ result: data }),
})());

const PayerModalTable = ({
  labels, filters, onICDCheckBoxClick, checkedICDCodes, ...props
}) => (
  <CustomPayerModalTable scrollId="cpt-payer-mapping-table" columns={columns(onICDCheckBoxClick, labels)} filters={filters} checkedICDCodes={checkedICDCodes} initialSort={initialSort} noDataText="ICD code not found" {...props}>
    {({
      Component, initialFilters, onFiltersChange,
    }) => (
      <FilterManager initialFilters={initialFilters} onChange={onFiltersChange}>
        {() => Component}
      </FilterManager>
    )}
  </CustomPayerModalTable>
);

export default PayerModalTable;
