import React, { useCallback, useMemo } from 'react';

import useRedirect from '../../../../hooks/useRedirect';
import useReduxState from '../../../../hooks/useReduxState';
import useRights from '../../../../hooks/useRights';

import Cases from './components/Cases';
import RadioGroup from '../../../../components/RadioGroup';
import Appointments from './components/Appointments';
import Authorizations from './components/Authorization';

import './lists.scss';
import { UiRoutes } from '../../../../lib/constants';
import rights from '../../../../lib/rights';

const ButtonText = (labels) => ({
  cases: labels.get('labels.addNewCase'),
  appointments: labels.get('labels.addNewAppointment'),
  authorization: labels.get('labels.addNewAuthorization'),
});

const tabList = (labels) => [
  { value: 'cases', label: labels.get('labels.cases') },
  { value: 'authorization', label: labels.get('labels.authorizations') },
  { value: 'appointments', label: labels.get('labels.appointments') },
];

const List = (props) => {
  const { labels } = props;
  const {
    params, push, generatePath,
  } = useRedirect();
  const { id: patientId, listTab = 'cases' } = params;

  const [ActiveComponent, setActiveComponent] = useReduxState('patient-details-lists', listTab);
  const [
    isAddAuthorizationAuthenticated,
    isAddCaseAuthenticated,
  ] = useRights([rights.create_authorization, rights.create_clinical_cases]);

  const tabSwitchHandler = useCallback(({ target: { value } }) => {
    setActiveComponent(value);
    push(generatePath(UiRoutes.patientListWithListTabId, { ...params, listTab: value }));
  }, [setActiveComponent, push, generatePath, params]);

  const navigateToNewAuthorization = useCallback(() => {
    push(generatePath(UiRoutes.newAuthorizationWithTabId, { ...params }));
  }, [push, generatePath, params]);

  const navigateToEditAuthorization = useCallback(({ authorizationId }) => {
    push(generatePath(UiRoutes.editAuthorizationWithTabId, { ...params, authorizationId }));
  }, [push, generatePath, params]);

  const navigateToNewCase = useCallback(() => {
    push(generatePath(UiRoutes.newListCasesWithTabId, { ...params }));
  }, [push, generatePath, params]);

  const navigateToEditCase = useCallback((caseId) => {
    push(generatePath(UiRoutes.editListCasesWithTabId, { ...params, caseId }));
  }, [push, generatePath, params]);

  const addButtonEvent = useMemo(() => (ActiveComponent === 'cases' ? navigateToNewCase : navigateToNewAuthorization), [ActiveComponent,
    navigateToNewAuthorization, navigateToNewCase]);

  return (
    <div className="lists-wrap">
      <div className="top-section">
        {(ActiveComponent === 'cases' && isAddCaseAuthenticated)
          || (ActiveComponent === 'authorization' && isAddAuthorizationAuthenticated) ? (
            <button
              type="button"
              onClick={addButtonEvent}
              className="btn btn-success sm-btn"
            >
              {ButtonText(labels)[ActiveComponent]}
            </button>
          ) : <span />}
        <RadioGroup
          tabList={tabList(labels)}
          onChange={tabSwitchHandler}
          value={ActiveComponent}
        />
      </div>
      {ActiveComponent === 'cases' && (
        <Cases
          labels={labels}
          patientId={patientId}
          navigateToEditCase={navigateToEditCase}
        />
      )}
      {ActiveComponent === 'authorization' && (
        <Authorizations
          labels={labels}
          patientId={patientId}
          navigateToEditAuthorization={navigateToEditAuthorization}
        />
      )}
      {ActiveComponent === 'appointments' && (
        <Appointments
          labels={labels}
          patientId={patientId}
        />
      )}
    </div>
  );
};

export default List;
