import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form as AntdForm } from 'antd';

import {
  UiRoutes, formId, labelPaths, listId,
  locationAddressObjectKeys,
} from '../../../lib/constants';
import SuccessMessages from '../../../lib/successMessages';
import formFieldValuesParser from '../../../lib/formFieldValuesParser';

import useRedirect from '../../../hooks/useRedirect';

import General from '../components/General';
import Form from '../../../components/Form';
import Button from '../../../components/Button';
import useTabLink from '../../../hooks/useTabLink';
import Notification from '../../../components/Notification';
import { apiUrls } from '../../../api/constants';
import WithLabel from '../../../hoc/withLabel';
import { deleteTabData } from '../../../store/actions/navigation';
import { clearReadData } from '../../../store/actions/crudWithoutTab';

function AddLocation(props) {
  const { labels } = props;
  const [form] = AntdForm.useForm();
  const { navigate } = useTabLink({ to: UiRoutes.editLocationWithTabId, params: { tab: 'general', data: { name: '' } } });
  const { push } = useRedirect();
  const navigateToForm = useCallback(() => push(UiRoutes.locations), [push]);
  const dispatch = useDispatch();
  const contract = useSelector((state) => state?.navigation?.get('contract'));

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.LOCATION_SAVED_SUCCESSFULLY, success: true });
      navigate({
        // Need to remove after fake work done
        id: response.locationId || response.id,
        data: {
          name: form.getFieldValue('locationName'),
        },
      });
      dispatch(clearReadData('location'));
      dispatch(clearReadData('NextAppointmentLocation'));
      if (contract) {
        Object.keys(contract || {}).forEach((key) => {
          if (contract[key]?.tabId && contract[key].tabId !== 'parent') {
            dispatch(deleteTabData({ tabId: contract[key].tabId }));
          }
        });
      }
    }
  }, [navigate, form, contract, dispatch]);

  const parser = useCallback((locationsInfo) => {
    const formValues = formFieldValuesParser(locationsInfo, {
      int: [
        'primaryPhoneExt',
        'secondaryPhoneExt',
        'fda',
        'clia',
      ],
      bool: [
        'isDisplayinScheduler',
        'isCommunityView',
        'isActive',
        'autoEligibilityCheck',
        'isAutoConfirmation'],
    });
    const parsedJson = { location: {}, address: {} };
    Object.keys(formValues)?.forEach((key) => {
      if (locationAddressObjectKeys.includes(key)) {
        parsedJson.address[`${key}`] = formValues[key];
      } else {
        parsedJson.location[`${key}`] = formValues[key];
      }
    });
    return parsedJson;
  }, []);

  return (
    <Form
      form={form}
      section
      formId={formId.ADD_LOCATION}
      url={apiUrls.ADD_LOCATIONS}
      onRequestComplete={onRequestComplete}
      parser={parser}
      listId={listId.LOCATIONS}
    >
      <div className="main-content-area">
        <div className="heading-area flex justify-content-sp-bt align-center no-h2-margin">
          <h2 className="bold">{labels.get('header.addLocation')}</h2>
          <div className="group-btns">
            <Button className="btn min-wt-86 sm-btn" onClick={navigateToForm}>{labels.get('buttons.cancel')}</Button>
            <Button className="btn btn-success min-wt-86 sm-btn" type="submit">{labels.get('buttons.save')}</Button>
          </div>
        </div>
        <div className="main-form-container">
          <General form={form} labels={labels} />
        </div>
      </div>
    </Form>
  );
}

export default WithLabel(AddLocation, labelPaths.ADD_LOCATION);
