import React, { useCallback, useState, useEffect } from 'react';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import useCRUD from '../../../../../../../../hooks/useCRUD';

import { apiUrls } from '../../../../../../../../api/constants';
import ErrorMessages from '../../../../../../../../lib/errorMessages';
import SuccessMessages from '../../../../../../../../lib/successMessages';
import WarningMessages from '../../../../../../../../lib/warningMessages';

import Icon from '../../../../../../../../components/Icon';
import Button from '../../../../../../../../components/Button';
import Notification from '../../../../../../../../components/Notification';
import Loader from '../../../../../../../../components/Loader';
import ConfirmDialog from '../../../../../../../../components/ConfirmDialog';

import ModifierComponent from './ModifierComponent';

import { validator, onChangeData, setFocusToFields } from './util';

const AddModifierRow = ({
  key,
  labels,
  setNewRecord,
  onAddNewRecord,
  editRow,
  setEditRow,
  form,
  contractId,
  contractTermId,
  parentFocusId,
}) => {
  const [modifier, setModifierData] = useState({});
  const [dataError, setError] = useState({});

  const [response, , loading, createModifier, clearCreate] = useCRUD({ id: 'modifier-create', url: apiUrls.ADD_CONTRACT_TERMS_MODIFIER, type: 'create' });

  const onCreateModifier = useCallback(() => {
    const clonedModifier = { ...modifier };
    const isError = validator(clonedModifier);
    if (isEmpty(isError)) {
      delete clonedModifier.modifierDescription;
      delete clonedModifier.modifierCPTCode;
      delete clonedModifier.modifierTier;
      createModifier({
        data: {
          contractId,
          contractTermId,
          ...clonedModifier,
        },
      });
    } else {
      setError(isError);
      setFocusToFields(isError, parentFocusId);
      Notification({ message: ErrorMessages.PLEASE_FILL_ALL_REQUIRED_FIELDS });
    }
  }, [modifier, createModifier, contractId, contractTermId, parentFocusId]);

  useEffect(() => {
    if (response && !isArray(response)) {
      Notification({ message: SuccessMessages.MODIFIER_ADDED_SUCCESSFULLY, success: true });
      onAddNewRecord({
        ...modifier,
        contractTermModifierExceptionId: response.contractTermModifierExceptionId,
      });
      clearCreate();
    }
  }, [response, onAddNewRecord, clearCreate, modifier]);

  const onChange = useCallback((label) => (event) => {
    const { modifierData, resetError } = onChangeData({
      label, event, modifier, dataError,
    });
    setModifierData(modifierData);
    if (Object.keys(resetError).length) setError(resetError);
  }, [setModifierData, setError, dataError, modifier]);

  const onCancel = useCallback(() => {
    ConfirmDialog({
      onOk: (close) => {
        setNewRecord(false);
        setEditRow(null);
        close();
      },
      title: 'Warning',
      content: WarningMessages.LOST_CHANGES,
      icon: <Icon name="ExclamationCircleOutlined" />,
    })();
  }, [setNewRecord]);

  useEffect(() => {
    if (editRow !== 'new') setNewRecord(false);
  }, [editRow, setNewRecord]);

  return (
    <tr key={key}>
      {loading && <Loader />}
      <ModifierComponent
        onChange={onChange}
        modifier={modifier}
        form={form}
        labels={labels}
        isEdit
        dataError={dataError}
        parentFocusId={parentFocusId}
      />
      <td>
        <div className="flex">
          <Button className="btn btn-success sm-btn" onClick={onCreateModifier}>{labels.get('buttons.save')}</Button>
          <Button className="mr-lt-5 sm-btn" onClick={onCancel}>{labels.get('buttons.cancel')}</Button>
        </div>
      </td>
    </tr>
  );
};

export default AddModifierRow;
