import React from 'react';
import useRedirect from '../../../../../../../hooks/useRedirect';
import withQuery from '../../../../../../../hoc/withQuery/withQuery';
import columns from './columns';
import withReduxManager from '../../../../../../../hoc/withReduxManager';
import { apiUrls } from '../../../../../../../api/constants';
import FilterManager from '../../../../../../../components/FilterManager';
import FilterComponents from '../../../../../../../components/FilterComponents';
import { listIds } from '../../../../../../../lib/constants';
import Button from '../../../../../../../components/Button';

const initialSort = [{ id: 'payerName', desc: false }];

const CustomProviderBillingTable = withReduxManager({
  listId: listIds.SETTING_PROVIDER_BILLING,
  pageSize: 30,
})(withQuery({
  url: apiUrls.GET_BILLINGS,
  listId: listIds.SETTING_PROVIDER_BILLING,
})());

const FilterCollection = FilterComponents([
  {
    type: 'search',
    filterProps: {
      placeholder: 'Payer',
      name: 'PayerName',
    },
  },
]);

const BillingTable = ({
  labels, providerId, navigateToForm, isProviderViewOnly,
}) => {
  const { params, generatePath } = useRedirect();

  return (
    <CustomProviderBillingTable
      columns={columns(params, generatePath, labels)}
      initialSort={initialSort}
      noDataText="Billing Config not found"
      filters={{ ProviderId: providerId }}
    >
      {({
        Component, initialFilters, onFiltersChange, totalCount,
      }) => (
        <FilterManager initialFilters={initialFilters} onChange={onFiltersChange}>
          {({ onFilterChange, filters }) => (
            <>
              <div className="table-filters">
                <div className="table-filter-inner">
                  <FilterCollection onFilterChange={onFilterChange} filters={filters} />
                  {!isProviderViewOnly && (
                    <Button id="provider_billing_add" className="btn-success btn sm-btn" onClick={navigateToForm}>{labels.get('buttons.addPayerConfiguration')}</Button>
                  )}
                </div>
                <span className="table-count">
                  <span>{`${labels.get('titles.totalCount')}: ${totalCount}`}</span>
                </span>
              </div>
              {Component}
            </>
          )}
        </FilterManager>
      )}
    </CustomProviderBillingTable>
  );
};

export default BillingTable;
