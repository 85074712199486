import React, { useCallback } from 'react';

import { Dropdown, Menu, Tooltip } from 'antd';
import Icon from '../../../../../components/Icon';
import iconNames from '../../../../../lib/iconNames';
import TimeCell from '../../../../../components/Cells/TimeCell';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import Upload from '../../../../../components/Upload';
import { apiUrls } from '../../../../../api/constants';
import useRedirect from '../../../../../hooks/useRedirect';
import useCRUD from '../../../../../hooks/useCRUD';
import { getUTCDate } from '../../../../../lib/util';

const ParentDocumentsSection = ({
  documentId, navigateToEditDocument,
  rootEncounterId, encounterId, isSignedPdf,
}) => {
  const { params } = useRedirect();

  const [mipsParentDocumentsData,,, getMipsParentDocumentsData] = useCRUD({
    id: 'get-mips-document-by-parent-id',
    url: apiUrls.GET_MIPS_PARENT_DOCUMENT_BY_ID,
    type: 'read',
  });

  const handleParentDocumentsData = useCallback((e) => {
    e.stopPropagation();
    if (isSignedPdf) {
      getMipsParentDocumentsData({
        patientId: params?.id,
        categoryId: params?.categoryId,
        rootEncounterId,
      });
    } else {
      getMipsParentDocumentsData({
        patientId: params?.id,
        categoryId: params?.categoryId,
        ParentDocumentId: documentId,
      });
    }
  }, [documentId, getMipsParentDocumentsData, isSignedPdf, params, rootEncounterId]);

  const handleMenuClick = useCallback((id, name, createdOn) => () => {
    navigateToEditDocument({
      documentId: id,
      uniqueTabId: id,
      documentName: name,
      createdOn,
    });
  }, [navigateToEditDocument]);

  const menu = useCallback(() => (
    <Menu onClick={handleMenuClick}>
      {mipsParentDocumentsData?.map((documentData) => {
        if (documentData?.encounterId !== encounterId) {
          return (
            <Menu.Item
              onClick={handleMenuClick(
            documentData?.documentId,
            documentData?.documentDisplayName,
            documentData?.createdOn,
              )}
              key={documentData?.documentId}
            >
              {isSignedPdf ? getUTCDate(documentData?.createdOn, 'MM-DD-YYYY, hh:mm A') : documentData?.documentDisplayName}
            </Menu.Item>
          );
        }
        return null;
      })}
    </Menu>
  ), [encounterId, handleMenuClick, isSignedPdf, mipsParentDocumentsData]);

  return (
    <Dropdown
      overlay={menu}
      onClick={handleParentDocumentsData}
      trigger={['click']}
    >
      <Icon
        name={iconNames.profileOutlined}
      />
    </Dropdown>
  );
};

const columns = () => [

  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({
      row: { original: { documentId, documentDisplayName, createdOn } },
      navigateToEditDocument, disableNavigation,
    }) => (
      <span
        className="cursor-pointer"
        role="presentation"
        onClick={(e) => {
          if (disableNavigation) {
            e.preventDefault();
          } else {
            navigateToEditDocument({
              documentId,
              uniqueTabId: documentId,
              documentName: documentDisplayName,
              createdOn,
            });
          }
        }}
      >
        {documentDisplayName}
      </span>
    ),
  },
  {
    Header: 'Category',
    accessor: 'categoryName',
    Cell: ({
      row: { original: { categoryName } },
      selectedCategoryName,
    }) => categoryName || selectedCategoryName,
  },
  {
    Header: 'Type',
    accessor: 'fileExt',
  },
  {
    Header: 'Modified On',
    accessor: 'modifiedOn',
    Cell: ({ row: { original: { modifiedOn } } }) => <TimeCell value={modifiedOn} />,
  },
  {
    Header: 'Modified By',
    accessor: 'modifiedByName',
  },
  {
    Header: 'Actions',
    accessor: 'remove',
    Cell: ({
      row: {
        original: { documentId, categoryId },
        id,
      },
      deletePatientDocument, changeActiveStatus, disabledRows,
    }) => {
      const disabled = disabledRows.indexOf(id) >= 0;
      const switchActive = useCallback((e) => {
        e.stopPropagation();
        changeActiveStatus(id);
      }, [changeActiveStatus, id]);

      return (
        <div className="actions-container">
          <Icon
            className="cursor-pointer stop-propagate"
            onClick={(event) => {
              event.stopPropagation();
              ConfirmDialog({
                onOk: (close) => {
                  if (deletePatientDocument) {
                    deletePatientDocument(documentId, categoryId);
                  }
                  close();
                },
                title: 'Do you want to delete this item?',
                content: 'When clicked the OK button, item will be deleted permanently.',
                icon: <Icon name="ExclamationCircleOutlined" />,
              })();
            }}
            name="DeleteOutlined"
          />
          <button className="btn action-button" type="button" onClick={switchActive}>{disabled ? 'Active' : 'Inactive'}</button>
        </div>
      );
    },
  },
];

export const mipsColumns = () => [
  {
    Header: '',
    accessor: 'dummyCol',
    Cell: ({
      row, row: {
        original: {
          documentId,
          mipsType, hasChildDocuments, rootEncounterId, encounterId,
        },
      }, navigateToEditDocument,
    }) => {
      const rowProps = row.getToggleRowSelectedProps();
      rowProps.onClick = (e) => e.stopPropagation();
      if (mipsType?.toLowerCase() === 'referralletter' || hasChildDocuments) {
        return (
          <div className="care-team-letter-icon">
            <ParentDocumentsSection
              documentId={documentId}
              navigateToEditDocument={navigateToEditDocument}
              hasChildDocuments={hasChildDocuments}
              rootEncounterId={rootEncounterId}
              encounterId={encounterId}
              isSignedPdf={!!rootEncounterId}
            />
          </div>
        );
      }
      return null;
    },
    fixWidth: 40,
  },
  ...columns(),
  {
    Header: '',
    accessor: 'mipsType',
    Cell: ({ row, row: { original: { documentId, mipsType } }, handleCareTeamLetterUpload }) => {
      const rowProps = row.getToggleRowSelectedProps();
      rowProps.onClick = (e) => e.stopPropagation();
      if (mipsType?.toLowerCase() === 'referralletter') {
        return (
          <span className="care-team-upload-icon" {...rowProps} title="">
            <Upload
              accept="image/png, image/jpeg, .doc, .docx, .pdf, .DOC, .DOCX, .PDF"
              onChange={handleCareTeamLetterUpload(documentId)}
              multiple={false}
              showUploadList={false}
              name={null}
              labelSpan="0"
            >
              <Tooltip placement="right" title="Upload Documents">
                <Icon
                  name={iconNames.upload}
                />
              </Tooltip>
            </Upload>
          </span>
        );
      }
      return null;
    },
    fixWidth: 40,
  },
];

export default columns;
