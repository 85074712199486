import React from 'react';

import List from '../Components/List';

import './physician.scss';

const Physician = ({ labels, data, ...otherProps }) => (
  <div className="dashboard-physician-category-container dash-category-container">
    <h4>{labels.get('headers.status.Physician')}</h4>
    <div className="dashboard-list-container">
      {data.map((section) => (
        <List
          key={section.status}
          id={section.status}
          data={section.appointments}
          labels={labels}
          {...otherProps}
        />
      ))}
    </div>
  </div>
);
Physician.defaultProps = {
  data: [],
  onDrop: () => { /* This is intentional */ },
};

export default Physician;
