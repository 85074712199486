import React, { useCallback, useState } from 'react';
import TimeCell from '../../../../../components/Cells/TimeCell';
import Label from '../../../../../components/Label';
import { dateFormat } from '../../../../../lib/constants';
import dateFormatter from '../../../../../lib/dateFormatter';
import ChangeResponsibilityModal from './changeResponsibilityModal';
import BillPatientModal from './billPatientModal';
import { addTimeZoneInDate } from '../../../../../lib/util';

const ChargeFormDetails = ({
  financialDetail, createdOn,
  handleBillPatientPopUp, handleChangeResponsiblityPopUp,
}) => {
  const {
    encounterModifiers,
    icdCode,
    providerName,
    attendingprovider,
    locationName,
    transactionStatus,
    enteredBy,
    insuranceProfileCategoryName,
    referringPhysician,
    auhtorization,
    effectiveDate,
    value,
    outstandingValue,
    unit,
    rvu,
    amount,
    adjustment,
    balance,
    isPatientResponsibility,
    claimPostedDate,
    chargePostedDate,
    insuranceProfileTypeName,
    totalPaidOnCharge,
    withHold,
    description,
  } = financialDetail || {};

  const formattedIcdCode = icdCode ? `${icdCode?.split(',')?.join(', ')}` : '';
  const formattedModifiers = encounterModifiers ? `/ ${encounterModifiers?.split(',')?.join(', ')}` : '';
  return (
    <div className="feild-row display-view  group-shadow">
      <div className="input-wrap right-align-financial-charge" data-testid="procedure">
        <div className="label-wrap">
          <Label label="Procedure" />
          :
        </div>
        <div className="name-view" data-testid="procedureValue">
          {description || ''}
        </div>
      </div>
      <div className="input-wrap right-align-financial-charge" data-testid="diagnosisModifier">
        <div className="label-wrap">
          <Label label="Diagnosis/Modifier" />
          :
        </div>
        <div className="name-view" data-testid="diagnosisModifierValue">{`${formattedIcdCode} ${formattedModifiers ?? ''}`}</div>
      </div>
      <div className="input-wrap right-align-financial-charge" data-testid="provider">
        <div className="label-wrap">
          <Label label="Provider" />
          :
        </div>
        <div className="name-view" data-testid="providerValue">{providerName}</div>
      </div>
      <div className="input-wrap right-align-financial-charge" data-testid="attendingProvider">
        <div className="label-wrap">
          <Label label="Attending Provider" />
          :
        </div>
        <div className="name-view" data-testid="attendingProviderValue">{attendingprovider}</div>
      </div>
      <div className="input-wrap right-align-financial-charge" data-testid="location">
        <div className="label-wrap">
          <Label label="Location" />
          :
        </div>
        <div className="name-view" data-testid="locationValue">{locationName}</div>
      </div>
      <div className="input-wrap right-align-financial-charge" data-testid="status">
        <div className="label-wrap">
          <Label label="Status" />
          :
        </div>
        <div className="name-view" data-testid="statusValue">{transactionStatus}</div>
      </div>
      <div className="input-wrap right-align-financial-charge" data-testid="enteredBy">
        <div className="label-wrap">
          <Label label="Entered By" />
          :
        </div>
        <div className="name-view" data-testid="enteredByValue">{`${enteredBy} ${createdOn}`}</div>
      </div>
      <div className="input-wrap right-align-financial-charge" data-testid="insuranceProfile">
        <div className="label-wrap">
          <Label label="Insurance Profile" />
          :
        </div>
        <div className="name-view" data-testid="insuranceProfileValue">{insuranceProfileCategoryName}</div>
      </div>
      <div className="input-wrap right-align-financial-charge" data-testid="referringPhysician">
        <div className="label-wrap">
          <Label label="Referring Physician" />
          :
        </div>
        <div className="name-view" data-testid="referringPhysicianValue">{referringPhysician}</div>
      </div>
      <div className="input-wrap right-align-financial-charge" data-testid="authorization">
        <div className="label-wrap">
          <Label label="Authorization" />
          :
        </div>
        <div className="name-view" data-testid="authorizationValue">{auhtorization}</div>
      </div>
      <div className="input-wrap right-align-financial-charge" data-testid="dateOfService">
        <div className="label-wrap">
          <Label label="Date of Service" />
          :
        </div>
        <div className="name-view" data-testid="dateOfServiceValue"><TimeCell value={effectiveDate} /></div>
      </div>
      <div className="input-wrap right-align-financial-charge" data-testid="postingDate">
        <div className="label-wrap">
          <Label label="Posting Date" />
          :
        </div>
        <div className="name-view" data-testid="postingDateValue">
          {transactionStatus === 'Posted' ? <TimeCell value={isPatientResponsibility ? addTimeZoneInDate(chargePostedDate) : addTimeZoneInDate(claimPostedDate)} /> : ''}
        </div>
      </div>
      <div className="input-wrap right-align-financial-charge" data-testid="value">
        <div className="label-wrap">
          <Label label="Value" />
          :
        </div>
        <div className="name-view" data-testid="val">{(value || 0).toFixed(2)}</div>
      </div>
      <div className="input-wrap right-align-financial-charge" data-testid="outstanding">
        <div className="label-wrap">
          <Label label="Outstanding Value" />
          :
        </div>
        <div className="name-view" data-testid="outstandingValue">{(outstandingValue || 0).toFixed(2)}</div>
      </div>
      <div className="input-wrap right-align-financial-charge" data-testid="units">
        <div className="label-wrap">
          <Label label="Units" />
          :
        </div>
        <div className="name-view" data-testid="unitsValue">{unit}</div>
      </div>
      <div className="input-wrap right-align-financial-charge" data-testid="RVU">
        <div className="label-wrap">
          <Label label="RVU" />
          :
        </div>
        <div className="name-view" data-testid="ruvValue">{rvu}</div>
      </div>
      <div className="input-wrap right-align-financial-charge" data-testid="amount">
        <div className="label-wrap">
          <Label label="Amount" />
          :
        </div>
        <div className="name-view" data-testid="amountValue">{(amount || 0).toFixed(2)}</div>
      </div>
      <div className="input-wrap right-align-financial-charge" data-testid="payment">
        <div className="label-wrap">
          <Label label="Payments" />
          :
        </div>
        <div className="name-view" data-testid="paymentValue">{(totalPaidOnCharge || 0).toFixed(2)}</div>
      </div>
      <div className="input-wrap right-align-financial-charge" data-testid="adjustment">
        <div className="label-wrap">
          <Label label="Adjustments" />
          :
        </div>
        <div className="name-view" data-testid="adjustmentValue">{(adjustment || 0).toFixed(2)}</div>
      </div>
      <div className="input-wrap right-align-financial-charge" data-testid="balance">
        <div className="label-wrap">
          <Label label="Balance" />
          :
        </div>
        <div className="name-view" data-testid="balanceValue">{(balance || 0).toFixed(2)}</div>
      </div>
      <div className="input-wrap right-align-financial-charge" data-testid="responsibility">
        <div className="label-wrap">
          <Label label="Responsibility" />
          :
        </div>
        <div className="flex">
          <div className="name-view name-view-align-center flex flex-wrap ">
            <span data-testid="responsibilityValue" className="responsibility-value">
              {isPatientResponsibility ? 'Patient' : insuranceProfileTypeName}
            </span>
            {financialDetail?.transactionStatus?.toLowerCase() === 'posted' ? (
              <div className="patient-responsibility-wrap">
                {!isPatientResponsibility && <span role="presentation" data-testid="billPatient" onClick={handleBillPatientPopUp} className="text-decoration-underline cursor-pointer">Bill Patient</span>}
                <span role="presentation" data-testid="changeResponsibility" onClick={handleChangeResponsiblityPopUp} className="text-decoration-underline cursor-pointer">Change Responsibility</span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="input-wrap right-align-financial-charge" data-testid="Withhold">
        <div className="label-wrap">
          <Label label="Withhold" />
          :
        </div>
        <div className="name-view" data-testid="withholdValue">{(withHold || 0).toFixed(2)}</div>
      </div>
    </div>
  );
};

const ChargeForm = ({ financialDetail }) => {
  const [changeResponsibilityPopupVisible,
    setChangeResponsibilityPopUpVisibility] = useState(false);
  const [billPatientPopupVisible, setBillPatientPopUpVisibility] = useState(false);

  const handleChangeResponsiblityPopUp = useCallback(() => {
    setChangeResponsibilityPopUpVisibility((value) => !value);
  }, []);

  const handleBillPatientPopUp = useCallback(() => {
    setBillPatientPopUpVisibility((value) => !value);
  }, []);

  const createdOn = financialDetail?.createdOn ? `on ${dateFormatter(addTimeZoneInDate(financialDetail.createdOn), dateFormat)}` : '';

  return (
    <>
      <div className="max-width-100 posi-relative table-layout-field">
        <form>
          <div className="feild-row justify-content-sp-bt align-center no-top-mr" data-testid="form-heading">
            <h2>Financial Details</h2>
          </div>
          <ChargeFormDetails
            financialDetail={financialDetail}
            createdOn={createdOn}
            handleBillPatientPopUp={handleBillPatientPopUp}
            handleChangeResponsiblityPopUp={handleChangeResponsiblityPopUp}
          />
        </form>
      </div>
      {billPatientPopupVisible && (
        <BillPatientModal
          isVisible={billPatientPopupVisible}
          toggleModal={handleBillPatientPopUp}
          claimId={financialDetail?.claimId}
          payerName={financialDetail?.payerName}
          billingEncounterServiceLineId={financialDetail?.billingEncounterServiceLineId}
        />
      )}
      {changeResponsibilityPopupVisible && (
      <ChangeResponsibilityModal
        isVisible={changeResponsibilityPopupVisible}
        toggleModal={handleChangeResponsiblityPopUp}
        claimId={financialDetail?.claimId}
        billingEncounterServiceLineId={financialDetail?.billingEncounterServiceLineId}
        payerName={financialDetail?.payerName}
        patientId={financialDetail?.patientId}
        shouldShowSecondary={financialDetail?.isPatientResponsibility}
        billingEncounterId={financialDetail?.billingEncounterId}
        isPatientResponsibility={financialDetail?.isPatientResponsibility}
        dateOfService={financialDetail?.effectiveDate}
      />
      )}
    </>
  );
};

export default ChargeForm;
