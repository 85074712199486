import React from 'react';
import './PatientList.scss';
import PatientTable from './Table';

function PatientList(props) {
  return (
    <div className="patient-list-wrapper">
      <PatientTable {...props} />
    </div>
  );
}

export default PatientList;
