import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import moment from 'moment';
import { Form as AntdForm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import classNames from 'classnames';

import { apiUrls } from '../../../../../../../api/constants';
import Events from '../../../../../../../lib/events';
import downloadFile from '../../../../../../../lib/downloadFile';
import SuccessMessages from '../../../../../../../lib/successMessages';
import {
  dateFormatStartsWithYearSeconds, enums, listIds, responseType,
} from '../../../../../../../lib/constants';
import ErrorMessages from '../../../../../../../lib/errorMessages';

import { getCrudWithoutTab, getEnumMaster, getEnumOptions } from '../../../../../../../store/selectors';
import { getEnumMasterData } from '../../../../../../../store/actions/enum';
import { procedureParser as parser } from '../../../../../commonParser';

import useCRUD from '../../../../../../../hooks/useCRUD';
import useRedirect from '../../../../../../../hooks/useRedirect';
import useCRUDWithoutTab from '../../../../../../../hooks/useCRUDWithoutTab';
import useAnesthesiaCptCode from '../../../../../../../hooks/useAnesthesiaCptCode';

import Icon from '../../../../../../../components/Icon';
import Button from '../../../../../../../components/Button';
import Notification from '../../../../../../../components/Notification';
import WidgetLoader from '../../../../../../../components/WidgetLoader';
import ConfirmDialog from '../../../../../../../components/ConfirmDialog';
import ApplyExamFindingModal from '../AP/RightTab/Tabs/OrderSets/applyExamFindingModal';

import SetModal from './Component/SetModal';
import MoveProcedureModal from './Component/MoveProcedureModal';
import CopyPreviousANPModal from './Component/CopyPreviousANP';
import useOrderSet from './Component/useOrderSet';
import GlobalPeriodModal from './Component/globalPeriodModal';
import JsonTree from './DroppableJsonTree';

const combineKeys = (key, params) => `${key}=${params[key]}`;
const qs = (params) => `?${Object.keys(params).map((key) => combineKeys(key, params)).join('&')}`;

const isAuthorized = () => {
  const { providerId } = JSON.parse(localStorage.getDecryptedData('user') || '{}');
  return !!providerId;
};

const LeftTab = ({
  dxList,
  setDxList,
  labelList,
  setLabelsList,
  procedureList,
  setProcedureList,
  labsImagingList,
  setLabsImagingList,
  educationalMaterialList,
  setEducationalMaterialList,
  setAddOption,
  selectedItem,
  type,
  savedSimpleEncounter,
  notesList,
  notesListLoading,
  handleProcedureClick,
  notesAdded,
  // procedureSetsList,
  componentType,
  questionnaireData,
  setQuestionnaireData,
  isOverviewOrderSet = false,
  selectedProvider,
}) => {
  const [form] = AntdForm.useForm();
  const clonedNotes = useRef([]);
  const {
    params: {
      encounterId, patientId, providerId, tabId,
    },
  } = useRedirect();
  const [isModalVisible, setModalVisibility] = useState(false);
  const [isDiagnosisModal, setDiagnosisModal] = useState(false);
  const [dxListItems, setDxListItems] = useState([]);
  const [hoveredOn, setHoveredOn] = useState(null);
  const [moveDxProcdeureCodeData, setMoveDxProcdeureCodeData] = useState({});
  const [oldDiagnosisId, setOldDiagnosisId] = useState(null);
  const [draggedProcedureData, setDraggedProcedureData] = useState(null);
  const [defaultCheckedDiagnosis, setDefaultCheckedDiagnosis] = useState(null);
  const [jcodeModifierData, setJcodeModiferData] = useState({});
  const [orderSetData, handleOrderSetData] = useOrderSet({ type, tabId, providerId });
  const [examFindingModalState, setExamFindingModalState] = useState({ visible: false, id: null });
  const [addProcedureData, setAddProcedureData] = useState({});
  const [applyOrderSetPayload, setApplyOrderSetPayload] = useState({});
  const [applyProcedureSetPayload, setApplyProcedureSetPayload] = useState({});
  const [subSetPayload, setSubSetPayload] = useState({});
  const appliedOrderSetInfo = useRef({});

  const toggleModal = useCallback(() => {
    clonedNotes.current = [];
    setModalVisibility((isVisible) => !isVisible);
  }, []);

  const toggleDiagnosisModal = useCallback(() => {
    setDiagnosisModal(!isDiagnosisModal);
  }, [isDiagnosisModal]);

  const [dxResponse, , dxLoading, saveDx, clearDxResponse] = useCRUD({ id: 'save-dx-in-order-procedure', url: apiUrls.SAVE_IN_ORDER_PROCEDURES, type: 'create' });
  const [procedureListResponse, , procedureLoading, getProcedureList] = useCRUD({ id: 'get-diagnosis-procedure-list', url: apiUrls.GET_DIAGNOSIS_PROCEDURE_LIST, type: 'read' });
  const [procedureResponse,
    addProcedureError, addProcedureLoading, addProcedureToDx, clearProcedureResponse] = useCRUD({
    id: 'add-in-order-procedure-to-dx-list', url: apiUrls.ADD_PROCEDURES_IN_DX, type: 'create', shouldClearError: false,
  });
  const [deleteProcedure, , deleteProcedureLoading, deleteProcedureCode, clearDeleteResponse] = useCRUD({ id: `delete-encounter-saved-procedures-${isOverviewOrderSet}`, url: apiUrls.DELETE_IN_OFFICE_PROCEDURES, type: 'delete' });
  const [deleteDiagnosisResponse, , deleteDiagnosisLoading, deleteDiagnosisCode, clearDiagnosisResponse] = useCRUD({ id: 'delete-diagnosis-code', url: apiUrls.DELETE_DIAGNOSIS_CODE, type: 'delete' });
  const [deleteNotesResponse, , deleteNotesLoading, deleteNotes, clearNotesResponse] = useCRUD({ id: 'delete-notes', url: apiUrls.DELETE_AP_NOTES, type: 'delete' });
  const [moveCodeResponse, , codeMoveLoading, moveCode] = useCRUD({ id: 'move-procedures-in-order-procedures', url: apiUrls.MOVE_IN_ORDER_PROCEDURES, type: 'create' });
  const [applyProcedureSetResponse,
    applyProcedureSetError,
    applyProcedureSetLoading,
    applyProcedureSet,
    clearapplyProcedureSetResponse] = useCRUD({
    id: 'apply-procedure-set', url: apiUrls.APPLY_PROCEDURE_ORDER_SET, type: 'create', shouldClearError: false,
  });
  const [applyOrderSetResponse,
    applyOrderSetError,
    applyOrderSetLoading,
    applyOrderSet,
    clearApplyOrderSetResponse] = useCRUD({
    id: 'apply-order-set', url: apiUrls.APPLY_AP_ORDER_SET, type: 'create', shouldClearError: false,
  });
  const [moveDxProcedureRes, , movedxLoading, moveDxProcedure, clearMoveDxProcedure] = useCRUD({ id: 'move-dxprocedures-in-order-procedures', url: apiUrls.MOVE_IN_ORDER_PROCEDURES, type: 'create' });
  const [reorderGroupResponse, , reorderGroupLoadingLoading, reorderGroup, clearReorderGroup] = useCRUD({ id: 're-order-groups', url: apiUrls.REORDER_GROUP, type: 'update' });
  const [downloadImagingFileResponse, , downloadImagingFileLoading, downloadImagingFile, clearDownloadImagingFile] = useCRUD({ id: 'download-imaging-file', url: apiUrls.DOWNLOAD_IMAGING, type: 'read' });
  const [dropSubsetRes,
    dropSubSetError,
    subSetDropLoading,
    dropSubSet,
    clearSubsetDropRes] = useCRUD({
    id: 'drop-favorite-sub-set', url: apiUrls.APPLY_SUB_SET, type: 'create', shouldClearError: false,
  });
  const [appliedDiagnosisFolderResponse, , appliedDiagnosisFolderLoading, applyDiagnosisFolder, clearAppliedDiagnosisFolder] = useCRUD({ id: 'apply-diagnosis-favorite-folder', url: apiUrls.APPLY_DIAGNOSIS_FAVORITE_FOLDER, type: 'create' });
  const [questionnaire, , questionnaireLoading, getQuestionnaire, clearQuestionnaireData] = useCRUD({ id: `${listIds.DOCTOR_QUESTIONNAIRES_MASTER}-jcode-drag`, url: apiUrls.GET_QUESTIONNAIRES_MASTER_DATA, type: 'read' });
  const [createProcedureResponse,, createProcedureLoading, createNewProcedure, clearCreateProcedureResponse] = useCRUD({ id: `${listIds.NEW_PATIENT_PROCEDURE}-drag`, url: `${apiUrls.ADD_PATIENT_PROCEDURE}/EncounterDiagnosisProcedure`, type: 'update' });
  const [ODOSModifierOptions, , , getODOSModifierOptions] = useCRUDWithoutTab({ id: listIds.GET_MODIFIER_OPTIONS, url: apiUrls.GET_MODIFIER_OPTIONS, type: 'read' });
  const [questionnaireDataForMoveProcedure, , questionnaireMoveProcedureLoading, getQuestionnaireForMoveProcedure, clearQuestionnaireDataForMoveProcedure] = useCRUD({ id: 'questionarie-data-in-move-procedure', url: apiUrls.GET_QUESTIONNAIRES_MASTER_DATA, type: 'read' });

  const anesthesiaCPTCode = useAnesthesiaCptCode();
  const dispatch = useDispatch();
  const enumMaster = useSelector((state) => getEnumMaster(state));
  const questionnaireEnumId = get(enumMaster, `${enums.PROCEDURE_QUESTIONNAIRES}.enumId`);
  const enumOptions = useSelector(
    (state) => getEnumOptions(state)?.[questionnaireEnumId]?.data || [],
  );

  const procedureQuestionnairesMasterId = enumOptions?.[0]?.masterId;

  useEffect(() => {
    if (dropSubSetError?.toLowerCase()?.includes(ErrorMessages?.SAME_PROCEDURE_ERROR)
      || dropSubSetError?.toLowerCase()?.includes(ErrorMessages?.TIME_ERROR_FOR_PROCEDURE)
      || dropSubSetError?.toLowerCase()
      ?.includes(ErrorMessages?.ERROR_FOR_ALREADY_USED_PROCEDURE)) {
      GlobalPeriodModal(
        dropSubSetError,
        dropSubSet,
        subSetPayload,
        clearSubsetDropRes,
      );
    } else if (dropSubSetError) {
      Notification({ message: dropSubSetError, success: false });
      clearSubsetDropRes(true);
    }
  }, [dropSubSetError]);

  useEffect(() => {
    if (addProcedureError?.toLowerCase()?.includes(ErrorMessages?.SAME_PROCEDURE_ERROR)
      || addProcedureError?.toLowerCase()?.includes(ErrorMessages?.TIME_ERROR_FOR_PROCEDURE)
      || addProcedureError?.toLowerCase()
      ?.includes(ErrorMessages?.ERROR_FOR_ALREADY_USED_PROCEDURE)) {
      GlobalPeriodModal(
        addProcedureError,
        addProcedureToDx,
        addProcedureData,
        clearProcedureResponse,
      );
    } else if (addProcedureError) {
      Notification({ message: addProcedureError, success: false });
      clearProcedureResponse(true);
    }
  }, [addProcedureError]);

  useEffect(() => {
    if (!ODOSModifierOptions?.length) getODOSModifierOptions();
  }, [ODOSModifierOptions, getODOSModifierOptions]);

  useEffect(() => {
    if (!(procedureQuestionnairesMasterId && procedureQuestionnairesMasterId.length)) {
      dispatch(getEnumMasterData(questionnaireEnumId));
    }
  }, [questionnaireEnumId, procedureQuestionnairesMasterId]);

  const { procedureDiagnosisList, procedureListLoading } = useSelector((state) => {
    if (type === 'inOffice') {
      const cptDiagnosisList = getCrudWithoutTab(state, `get-diagnosis-procedure-list-${encounterId}`);
      return {
        procedureDiagnosisList: cptDiagnosisList?.get('data'),
        procedureListLoading: !!cptDiagnosisList?.get('loading'),
      };
    }
    return {};
  });

  useEffect(() => {
    const error = applyProcedureSetError || applyOrderSetError;
    if (error?.toLowerCase()?.includes(ErrorMessages?.SAME_PROCEDURE_ERROR)
        || error?.toLowerCase()?.includes(ErrorMessages?.TIME_ERROR_FOR_PROCEDURE)
        || error?.toLowerCase()
        ?.includes(ErrorMessages?.ERROR_FOR_ALREADY_USED_PROCEDURE)) {
      GlobalPeriodModal(
        error,
        applyProcedureSetError ? applyProcedureSet : applyOrderSet,
        applyProcedureSetError ? applyProcedureSetPayload : applyOrderSetPayload,
        applyProcedureSetError ? clearapplyProcedureSetResponse : clearApplyOrderSetResponse,
      );
    } else if (error) {
      Notification({ message: error, success: false });
      clearapplyProcedureSetResponse(true);
      clearApplyOrderSetResponse(true);
    }
  }, [applyProcedureSetError, applyOrderSetError]);

  useEffect(() => {
    Events.on('delete-notes', 'delete-notes', (noteId) => {
      deleteNotes({}, `/${noteId}`);
    });
    return () => Events.remove('delete-notes');
  }, []);

  const reFetchProcedureAAndPList = () => {
    const obj = { encounterId, source: 'AAndP' };
    if (type === 'inOffice') {
      Events.trigger(`refetch-InOffice-ProcedureList-${encounterId}`);
    } else getProcedureList(obj);
  };

  useEffect(() => {
    if (createProcedureResponse) {
      setJcodeModiferData({});
      clearQuestionnaireData(true);
      clearCreateProcedureResponse(true);
      reFetchProcedureAAndPList();
      Notification({ message: SuccessMessages.PROCEDURE_UPDATED_SUCCESSFULLY, success: true });
      Events.trigger('reFetchProcedureHistoryTable');
    }
  }, [clearCreateProcedureResponse, clearQuestionnaireData,
    createProcedureResponse, getProcedureList]);

  useEffect(() => {
    if (downloadImagingFileResponse && !Array.isArray(downloadImagingFileResponse)) {
      downloadFile(downloadImagingFileResponse, 'imagingLab.pdf', 'application/pdf');
    }
    clearDownloadImagingFile(true);
  }, [downloadImagingFileResponse]);

  useEffect(() => {
    if (reorderGroupResponse) {
      clearReorderGroup();
      reFetchProcedureAAndPList();
    }
  }, [reorderGroupResponse]);

  useEffect(() => {
    if (dropSubsetRes) {
      Notification({
        message: dropSubsetRes?.message
          || 'Favorite has been applied successfully',
        success: true,
      });
      reFetchProcedureAAndPList();
      clearSubsetDropRes();
    }
  }, [dropSubsetRes, encounterId, type]);

  useEffect(() => {
    if (applyProcedureSetResponse || applyOrderSetResponse) {
      reFetchProcedureAAndPList();
      if (!isEmpty(appliedOrderSetInfo?.current)) {
        /** for physician  and billing tab */
        const phys = document.getElementById('Phys');
        if (phys) {
          document.getElementById('Phys').style.color = '#32c788';
        }
        const billing = document.getElementById('Billing');
        if (billing) {
          document.getElementById('Billing').style.color = '#32c788';
        }
      }
      appliedOrderSetInfo.current = {};
      setExamFindingModalState({ visible: false, id: null });
      if (applyProcedureSetResponse) {
        Notification({
          message: SuccessMessages.PROCEDURE_SET_APPLIED_SUCCESSFULLY,
          success: true,
        });
        clearapplyProcedureSetResponse(true);
      } else {
        Notification({ message: SuccessMessages.ORDER_SET_APPLIED_SUCCESSFULLY, success: true });
        clearApplyOrderSetResponse(true);
        notesAdded();
      }
    }
  }, [applyOrderSetResponse, applyProcedureSetResponse]);

  useEffect(() => {
    if (deleteNotesResponse) {
      Notification({ message: `${startCase(deleteNotesResponse?.noteType || 'notes')} has been deleted successfully`, success: true });
      notesAdded();
      clearNotesResponse();
      setAddOption(null);
    }
  }, [clearNotesResponse, deleteNotesResponse]);

  useEffect(() => {
    if (moveDxProcedureRes) {
      const obj = { encounterId, source: 'AAndP' };
      Notification({ message: SuccessMessages.PROCEDURE_CODE_MOVED_SUCCESSFULLY, success: true });
      clearMoveDxProcedure();
      toggleDiagnosisModal();
      if (type === 'inOffice') {
        Events.trigger(`refetch-InOffice-ProcedureList-${encounterId}`);
      } else getProcedureList(obj);
    }
  }, [moveDxProcedureRes, clearMoveDxProcedure]);

  useEffect(() => {
    Events.on(`refetchInOfficeProcedureList-${encounterId}`, `refetchInOfficeProcedureList-${encounterId}`, () => {
      reFetchProcedureAAndPList();
    });
    Events.on(`set-slit-orderset-data-${encounterId}`, `set-slit-orderset-data-${encounterId}`, handleOrderSetData);
    return () => {
      Events.trigger('reFetchOcularProblem');
      Events.remove(`refetchInOfficeProcedureList-${encounterId}`);
      Events.remove(`set-slit-orderset-data-${encounterId}`);
    };
  }, [encounterId, getProcedureList, type]);

  useEffect(() => {
    const obj = { encounterId, source: 'AAndP' };
    if (type === 'ap' && encounterId) getProcedureList(obj);
    else {
      Events.trigger(`refetch-InOffice-ProcedureList-${encounterId}`);
    }
  }, [encounterId, type]);

  useEffect(() => {
    if (moveCodeResponse) {
      reFetchProcedureAAndPList();
    }
  }, [moveCodeResponse]);

  useEffect(() => {
    if (appliedDiagnosisFolderResponse) {
      Notification({
        message: SuccessMessages.DIAGNOSIS_FAVOURITE_APPLIED_SUCCESSFULLY,
        success: true,
      });
      clearAppliedDiagnosisFolder();
      reFetchProcedureAAndPList();
    }
  }, [appliedDiagnosisFolderResponse]);

  useEffect(() => {
    if (dxResponse || procedureResponse) {
      Notification({
        message: dxResponse ? SuccessMessages.DX_ADDED_SUCCESSFULLY
          : SuccessMessages.PROCEDURE_CREATED_SUCCESSFULLY,
        success: true,
      });
      reFetchProcedureAAndPList();
      if (dxResponse) clearDxResponse();
      else clearProcedureResponse();
    }
    if (procedureResponse) {
      handleProcedureClick({
        ...draggedProcedureData,
        groupId: procedureResponse?.groupId,
        patientProcedureCodeId: procedureResponse?.procedureDetailsId,
        isJCode: draggedProcedureData?.isJcode,
        associatedProcedureId: procedureResponse?.associatedProcedureId,
      })();
      clearProcedureResponse(true);
    }
  }, [dxResponse, encounterId,
    patientId, procedureResponse, type, draggedProcedureData]);

  useEffect(() => {
    if (deleteProcedure
      || deleteDiagnosisResponse) {
      Notification({
        message: deleteDiagnosisResponse
          ? SuccessMessages.DIAGNOSIS_DELETED_SUCCESSFULLY
          : SuccessMessages.PROCEDURE_DELETED_SUCCESSFULLY,
        success: true,
      });
      if (isDiagnosisModal) {
        toggleDiagnosisModal();
      }
      reFetchProcedureAAndPList();
      setAddOption(null);
    }
    if (deleteProcedure) {
      Events.trigger('disable-other-doctor-components', { isApplied: false });
      Events.trigger('reFetchProcedureHistoryTable');
      setAddOption(null);
      clearDeleteResponse();
      setAddOption(null);
    } else clearDiagnosisResponse();
  }, [deleteProcedure, deleteDiagnosisResponse, isDiagnosisModal]);

  useEffect(() => {
    if (procedureListResponse || procedureDiagnosisList) {
      setLabelsList(parser(type === 'inOffice' ? procedureDiagnosisList : procedureListResponse));
    }
  }, [procedureListResponse, procedureDiagnosisList]);

  const onAddItems = useCallback((data) => {
    setAddOption(data);
  }, [setAddOption]);

  const onDragEnter = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const toggleApplyExamFindingModal = useCallback(() => {
    setExamFindingModalState({ visible: false, id: null });
  }, []);

  useEffect(() => {
    if (questionnaire) {
      if (questionnaire?.[0]?.Questionnaire?.length && isEmpty(jcodeModifierData)) {
        const validateOptions = JSON.parse(questionnaire?.[0]?.Questionnaire);
        const validateDrop = validateOptions?.[0]?.Options?.find((item) => item?.['J-code'] === questionnaireData?.data?.cptCode);
        if (validateDrop) {
          const data = {
            groupId: questionnaireData?.groupId,
            CPTCodeId: questionnaireData?.data?.cptCodeId || questionnaireData?.data?.id,
            patientId,
            encounterId,
            isBillable: true,
            associatedProcedureId: questionnaireData?.associatedProcedureId,
            units: anesthesiaCPTCode?.includes(questionnaireData?.data?.cptCode)
              ? (questionnaireData?.data?.units || questionnaireData?.data?.units) : undefined,
          };
          setAddProcedureData({ ...data });
          addProcedureToDx({
            data,
          });
          Events.trigger('reFetchProcedureHistoryTable');
        } else if (validateDrop === undefined) {
          Notification({ message: ErrorMessages.JCODE_DROP_VALIDATION_ERROR, success: false });
        }
      } else if (questionnaire?.[0]?.Questionnaire?.length && !isEmpty(jcodeModifierData)) {
        createNewProcedure({
          providerId,
          cptCodeId: jcodeModifierData?.cptCodeId,
          patientId: parseInt(patientId, 10),
          encounterId: parseInt(encounterId, 10),
          results: jcodeModifierData?.result?.map(
            (item) => ({ Key: item?.key, answer: item?.answer })),
          modifiers: [jcodeModifierData?.modifierkey],
          description: jcodeModifierData?.procedureDescription,
          QuestonarieMasterId: questionnaire?.[0]?.QuestionnairesMasterId,
          status: jcodeModifierData?.status,
          isJcode: true,
          groupId: jcodeModifierData?.groupId,
          source: jcodeModifierData?.source,
          associatedProcedureId: jcodeModifierData?.associatedProcedureId,
        }, `/${jcodeModifierData?.procedureDetailsId}`);
        clearQuestionnaireData(true);
      } else {
        Notification({ message: ErrorMessages.JCODE_DROP_VALIDATION_ERROR, success: false });
      }
    }
    return () => clearQuestionnaireData(true);
  }, [questionnaire, jcodeModifierData]);

  // this work has been done for only move procedure validation implementation.
  const [moveProcedureDataPayload, setMoveProcedureDataPayload] = useState();
  useEffect(() => {
    if (questionnaireDataForMoveProcedure) {
      if (questionnaireDataForMoveProcedure?.[0]?.Questionnaire?.length) {
        const validateOptions = JSON.parse(questionnaireDataForMoveProcedure?.[0]?.Questionnaire);
        const validateDrop = validateOptions?.[0]?.Options?.find((item) => item?.['J-code'] === moveProcedureDataPayload?.data?.cptCode);
        if (validateDrop) {
          moveCode({
            data: {
              OldPatientDiagnosisGroupId: moveProcedureDataPayload?.data.groupId,
              NewPatientDiagnosisGroupId: moveProcedureDataPayload?.groupId,
              EncounterId: encounterId,
              PatientId: patientId,
              associatedProcedureId: moveProcedureDataPayload?.associatedProcedureId,
              ProcedureDetailIds:
            moveProcedureDataPayload?.data?.procedureDetailsId
              ? [moveProcedureDataPayload?.data?.procedureDetailsId] : [],
            },
          });
        } else {
          Notification({ message: ErrorMessages.JCODE_DROP_VALIDATION_ERROR, success: false });
        }
      } else {
        Notification({ message: ErrorMessages.JCODE_DROP_VALIDATION_ERROR, success: false });
      }
    }
    clearQuestionnaireDataForMoveProcedure(true);
  }, [questionnaireDataForMoveProcedure]);

  const handleMoveProcedure = useCallback((data, associatedProcedureId,
    onDraggedCptCode, groupId, onDraggedLabel) => {
    if (data?.isJcode && associatedProcedureId) {
      setMoveProcedureDataPayload({ data, groupId, associatedProcedureId });
      getQuestionnaireForMoveProcedure({
        TypeId: procedureQuestionnairesMasterId,
        Descriptor: onDraggedCptCode,
      });
    } else if (!associatedProcedureId && data?.isJcode && onDraggedLabel === 'parentlabel') {
      Notification({ message: ErrorMessages.JCODE_DROP_VALIDATION_ERROR, success: false });
    } else if (!data.isJcode && onDraggedCptCode === 'drag') {
      moveCode({
        data: {
          OldPatientDiagnosisGroupId: data.groupId,
          EncounterId: encounterId,
          PatientId: patientId,
          ProcedureDetailIds: data?.procedureDetailsId ? [data?.procedureDetailsId] : [],
        },
      });
    } else if (!data.isJcode && onDraggedLabel !== undefined) {
      moveCode({
        data: {
          OldPatientDiagnosisGroupId: data.groupId,
          NewPatientDiagnosisGroupId: groupId,
          EncounterId: encounterId,
          PatientId: patientId,
          ProcedureDetailIds: data?.procedureDetailsId ? [data?.procedureDetailsId] : [],
        },
      });
    }
  }, [encounterId, getQuestionnaireForMoveProcedure, moveCode,
    patientId, procedureQuestionnairesMasterId]);
  // ************************************************ //

  const onDragLeave = useCallback(() => setHoveredOn(null), []);
  const handleDrop = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.getData('type', 'acuity-eye-tree-leaf');
    const data = JSON.parse(event.dataTransfer.getData('draggedData'));
    event.target.classList.remove('in-between-space-hover');
    const draggedItem = event.dataTransfer.getData('draggedItem');
    const sortOrder = event?.target?.id?.split('_');
    sortOrder[1] = parseFloat(sortOrder[2]);
    sortOrder[2] = parseFloat(sortOrder[3]);
    if (data?.dragGroup || draggedItem === 'current-set') {
      if (event?.target?.id?.split('_')[1] === 'drag') {
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(sortOrder[2]) && !isNaN(sortOrder[3])) {
          reorderGroup({
            encounterId,
            groupId: data?.groupId,
            sortOrder: parseFloat((sortOrder[1] + sortOrder[2]) / 2),
          });
        }
      }
      return null;
    }
    // eslint-disable-next-line radix
    const groupId = parseInt(event?.target?.id?.split('_')[0], 0) || null;
    if (draggedItem === 'dx') {
      if (event.target.id && event?.target?.id?.split('_')[1] !== 'drag') {
        const labelListIndex = labelList.findIndex(
          (labelData) => parseInt(labelData.groupId, 10) === parseInt(groupId, 10),
        );
        const dianosisLength = labelList[labelListIndex]?.diagnosisList?.length || 0;
        if (dianosisLength === 4) {
          Notification({ message: 'Maximum diagnosis limit reached in a group.', success: false });
          return null;
        }
        const isDiagnosisDuplicate = labelList[labelListIndex]?.diagnosisList.findIndex(
          (diagnosisData) => diagnosisData.id === data.icdCodeId,
        );
        if (isDiagnosisDuplicate !== -1) {
          Notification({ message: 'Diagnosis already exist', success: false });
          return null;
        }
        if (groupId) {
          return saveDx({
            data: {
              patientId,
              encounterId,
              icdCodeId: data?.icdCodeId || data?.id,
              groupId,
              IsActive: true,
              providerId: isAuthorized() ? selectedProvider || providerId : null,
              sortOrder: parseFloat((sortOrder[1] + sortOrder[2]) / 2),
            },
          });
        }
        return saveDx({
          data: {
            patientId,
            encounterId,
            icdCodeId: data?.icdCodeId || data?.id,
            IsActive: true,
            providerId: isAuthorized() ? selectedProvider || providerId : null,
            sortOrder: parseFloat((sortOrder[1] + sortOrder[2]) / 2),
          },
        });
      }
      return saveDx({
        data: {
          patientId,
          encounterId,
          icdCodeId: data?.icdCodeId || data?.id,
          IsActive: true,
          providerId: isAuthorized() ? selectedProvider || providerId : null,
          sortOrder: parseFloat((sortOrder[1] + sortOrder[2]) / 2),
        },
      });
    }
    const {
      source: cptSource, cptCode, cptCodeId, cptDescription, isJcode, id: cptId, description,
      unit, units,
    } = data;
    if (draggedItem === 'procedure') {
      setDraggedProcedureData({
        source: cptSource,
        cptCode,
        cptCodeId: cptCodeId || cptId,
        cptDescription: cptDescription || description,
        id: cptCode,
        isJcode,
        setJcode: true,
        encounterId,
        units: unit || units,
      });
      if (data?.isJcode) {
        if ((event.target.id && event?.target?.id?.split('_')[1] === 'drag') || (
          event.target.id && event?.target?.id?.split('_')[2] === 'parentlabel') || event.target.id === ''
          || (event?.target?.id?.split('_')[2] === 'showChild') || (event?.target?.id?.split('_')[2] === 'modifiers') || (event?.target?.id?.split('_')[2] === 'drag')
        ) {
          Notification({ message: ErrorMessages.JCODE_DROP_VALIDATION_ERROR, success: false });
        } else {
          const onDraggedCptCode = event?.target?.id?.split('_')[1];
          const associatedProcedureId = event?.target?.id?.split('_')[3];
          getQuestionnaire({
            TypeId: procedureQuestionnairesMasterId,
            Descriptor: onDraggedCptCode,
          });
          setQuestionnaireData({ groupId, associatedProcedureId, data });
        }
      } else if (event.target.id && event?.target?.id?.split('_')[1] !== 'drag') {
        const payload = {
          groupId,
          CPTCodeId: data?.cptCodeId || data?.id,
          patientId,
          encounterId,
          isBillable: true,
          units: anesthesiaCPTCode?.includes(data?.cptCode)
            ? (data?.unit || data?.units) : undefined,
        };
        setAddProcedureData({ ...payload });
        addProcedureToDx({
          data: payload,
        });
      } else {
        const payload = {
          CPTCodeId: data?.cptCodeId || data?.id,
          patientId,
          encounterId,
          isBillable: true,
          units: anesthesiaCPTCode?.includes(data?.cptCode)
            ? (data?.unit || data?.units) : undefined,
        };
        setAddProcedureData({ ...payload });
        addProcedureToDx({
          data: payload,
        });
      }
    } else if (draggedItem === 'labsImaging') {
      setDraggedProcedureData({
        setJcode: true,
        isJcode,
        cptCode,
        cptCodeId: cptId,
        id: cptCode,
        cptDescription,
        source: 'ImagingLab',
      });
      const payload = {
        groupId,
        CPTCodeId: data?.cptCodeId || data?.id,
        patientId,
        encounterId,
        source: 'ImagingLab',
        isBillable: true,
        units: anesthesiaCPTCode?.includes(data?.cptCode) ? (data?.unit || data?.units) : undefined,
      };
      setAddProcedureData({ ...payload });
      addProcedureToDx({
        data: payload,
      });
    } else if (draggedItem === 'current-procedure') {
      const associatedProcedureId = event?.target?.id?.split('_')?.[3];
      const onDraggedCptCode = event?.target?.id?.split('_')[1];
      const onDraggedLabel = event?.target?.id?.split('_')[2];
      handleMoveProcedure(data, associatedProcedureId, onDraggedCptCode, groupId, onDraggedLabel);
    } else if (draggedItem === 'procedureSets') {
      const id = event.dataTransfer.getData('text');
      const payload = {
        FavoriteId: id,
        PatientId: patientId,
        EncounterId: encounterId,
        providerId,
        start: sortOrder[1],
        end: sortOrder[2],
      };
      setApplyProcedureSetPayload(payload);
      applyProcedureSet({
        data: payload,
      });
    } else if (draggedItem === 'orderSets') {
      const id = event.dataTransfer.getData('text');
      const isExamExist = JSON.parse(event.dataTransfer.getData('orderSetExamFindings'));
      const isFollowUpExist = JSON.parse(event.dataTransfer.getData('orderSetFollowUpFindings'));

      if (isExamExist && isFollowUpExist) {
        appliedOrderSetInfo.current = { isFollowUpExist, isExamExist };
      }

      if (isExamExist) {
        setExamFindingModalState({ visible: true, id });
        return true;
      }
      const payload = {
        FavoriteId: id,
        PatientId: patientId,
        EncounterId: encounterId,
      };
      setApplyOrderSetPayload(payload);
      applyOrderSet({
        data: payload,
      });
    } else if (draggedItem === 'diagnosis-procedure-set') {
      const payload = {
        favoriteId: data?.favoriteId,
        groupId: data?.groupId,
        patientId,
        encounterId,
        providerId,
      };
      setSubSetPayload(payload);
      dropSubSet({
        data: payload,
      });
    } else if (draggedItem === 'apply-diagnosis-folder-favorite') {
      ConfirmDialog({
        onOk: (close) => {
          applyDiagnosisFolder({
            data: {
              favoriteId: data?.favoriteId,
              patientId,
              encounterId,
              providerId,
              start: sortOrder[2],
              end: sortOrder[1],
            },
          });
          close();
        },
        onCancel: (close) => {
          close();
        },
        okText: 'Continue',
        title: 'Warning',
        content: 'Are you sure you would like to apply the complete folder of Dx?',
        icon: <Icon name="ExclamationCircleOutlined" />,
      })();
    }

    return true;
  }, [reorderGroup, encounterId, saveDx, patientId, providerId, labelList,
    getQuestionnaire, procedureQuestionnairesMasterId, setQuestionnaireData,
    anesthesiaCPTCode, addProcedureToDx, handleMoveProcedure, applyProcedureSet,
    applyOrderSet, dropSubSet, applyDiagnosisFolder]);

  const setDxDropdownList = useCallback((PatientDiagnosisGroupId, DiagnosisDetailsId) => {
    const dxdropDownList = [];
    const patientProcedureCodes = [];
    labelList.forEach((groupItem) => {
      if (groupItem.groupId !== PatientDiagnosisGroupId) {
        dxdropDownList.push(groupItem.diagnosisList);
      }
    });

    labelList.forEach((groupItem) => {
      if (groupItem?.groupId === PatientDiagnosisGroupId) {
        groupItem.subType.forEach((subItem) => {
          patientProcedureCodes.push(subItem?.procedureDetailsId);
        });
      }
    });
    setMoveDxProcdeureCodeData({
      OldPatientDiagnosisGroupId: PatientDiagnosisGroupId,
      ProcedureDetailIds: patientProcedureCodes,
      EncounterId: encounterId,
      PatientId: patientId,
      DeleteAllProcedures: true,
    });
    setOldDiagnosisId(DiagnosisDetailsId);
    setDxListItems(dxdropDownList);
  }, [setDxListItems, labelList, setMoveDxProcdeureCodeData, setOldDiagnosisId]);

  const handleProcedureSet = useCallback(() => {
    if (moveDxProcdeureCodeData?.NewPatientDiagnosisGroupId) {
      moveDxProcedure({
        data: moveDxProcdeureCodeData,
      });
    } else {
      deleteDiagnosisCode({}, qs({
        diagnosisDetailsId: oldDiagnosisId,
        groupId: moveDxProcdeureCodeData?.OldPatientDiagnosisGroupId,
        EncounterId: encounterId,
      }));
    }
  }, [moveDxProcdeureCodeData, moveDxProcedure, deleteDiagnosisCode, oldDiagnosisId, encounterId]);
  const deleteDiagnosis = useCallback((PatientDiagnosisGroupId, DiagnosisDetailsId) => {
    const isShowDxModal = labelList.find((dxItem) => (
      dxItem?.groupId === PatientDiagnosisGroupId
      && dxItem?.subType?.length
      && dxItem.diagnosisList?.length === 1));
    if (isShowDxModal) {
      setDxDropdownList(PatientDiagnosisGroupId, DiagnosisDetailsId);
      toggleDiagnosisModal();
    } else {
      deleteDiagnosisCode({}, qs({
        DiagnosisDetailsId,
        groupId: PatientDiagnosisGroupId,
        EncounterId: encounterId,
      }));
    }
  }, [deleteDiagnosisCode, encounterId, labelList]);

  const deleteProcedures = useCallback((groupId, procedureDetailsId, isProcedureWithJcode) => {
    ConfirmDialog({
      onOk: (close) => {
        deleteProcedureCode({}, qs({
          groupId,
          procedureDetailsId,
          EncounterId: encounterId,
        }));
        close();
      },
      okText: 'Ok',
      title: 'Warning',
      content: isProcedureWithJcode ? 'Are you sure, you want to delete this procedure and adjoining Jcode?' : 'Are you sure, you want to delete this procedure?',
      icon: <Icon name="ExclamationCircleOutlined" />,
    })();
  }, [deleteProcedureCode, encounterId]);

  const noteTypePresent = useMemo(() => (notesList
    || []).map((value) => value?.note?.length && (value?.noteType?.toLowerCase() === 'notes'
    ? 'note' : value?.noteType?.toLowerCase())), [notesList]);

  const handleDeleteNotes = useCallback((data) => () => {
    if (data?.id) {
      ConfirmDialog({
        onOk: (close) => {
          deleteNotes({}, `/${data.id}`);
          close();
        },
        okText: 'Ok',
        title: 'Warning',
        content: 'Are you sure, you want to delete this note?',
        icon: <Icon name="ExclamationCircleOutlined" />,
      })();
    }
  }, [deleteNotes]);

  const saveFavorite = useCallback((obj) => {
    setDefaultCheckedDiagnosis(obj);
    toggleModal();
  }, [toggleModal]);

  const downloadImaging = useCallback((cptId, groupId) => {
    downloadImagingFile({
      cptId,
      groupId,
      encounterId,
      patientId,
      providerId,
      responseType: responseType.BLOB,
      ReportGenerationTime: moment().format(dateFormatStartsWithYearSeconds),
    });
  }, [downloadImagingFile, encounterId, patientId, providerId]);

  useEffect(() => {
    Events.on('delete-procedure-with-groupId', 'delete-procedure-with-groupId', ({ groupId, procedureDetailsId, patientProcedureCodeId }) => {
      deleteProcedureCode({}, qs({
        groupId,
        procedureDetailsId: procedureDetailsId || patientProcedureCodeId,
        EncounterId: encounterId,
      }));
    });
    return () => Events.remove('delete-procedure-with-groupId');
  }, [deleteProcedureCode, encounterId]);

  const handleJcodeModifierClick = useCallback(async (e, data) => {
    e.stopPropagation();
    getQuestionnaire({
      TypeId: procedureQuestionnairesMasterId,
      Descriptor: data?.cptCode,
    });
    setJcodeModiferData({ ...data, modifierkey: parseInt(e?.target?.value, 10) });
    setAddOption(null);
  }, [getQuestionnaire, procedureQuestionnairesMasterId, setAddOption]);

  return (
    <>
      <div
        className="side-tab posi-relative drag-section"
        onDrop={handleDrop}
        onDragEnter={onDragEnter}
        // onDragOver={onDragOver}
        onDragLeave={onDragLeave}
      >
        {(procedureLoading || procedureListLoading || dxLoading || questionnaireMoveProcedureLoading
          || addProcedureLoading || notesListLoading || codeMoveLoading
          || deleteProcedureLoading || deleteDiagnosisLoading || createProcedureLoading
          || deleteNotesLoading || applyProcedureSetLoading || reorderGroupLoadingLoading
          || applyOrderSetLoading || downloadImagingFileLoading || questionnaireLoading
          || subSetDropLoading || appliedDiagnosisFolderLoading) && <WidgetLoader />}
        <div
          className={`${!savedSimpleEncounter && 'pannel'}`}
        >
          {!savedSimpleEncounter && (
            <div className="pannel-heading flex justify-content-sp-bt">
              <div className="assement-clipbox flex">
                {type === 'ap' && Object.keys(selectedItem).filter((keys) => keys !== 'procedure').map((e) => (
                  <div
                    key={e}
                    role="presentation"
                    className={classNames('clipbox',
                      { 'cursor-not-allowed': noteTypePresent.includes(e?.toLowerCase()), 'disabled-btn': noteTypePresent.includes(e?.toLowerCase()) })}
                    onClick={() => !noteTypePresent.includes(e?.toLowerCase()) && onAddItems(e)}
                  >
                    <span>{selectedItem[e].title}</span>
                  </div>
                ))}
              </div>
              {type === 'ap' && <CopyPreviousANPModal />}
            </div>
          )}
          <div className="pannel-body" onDrop={handleDrop}>
            <div className="merge-fields-container height-to-gap-button">
              <div
                className="json-tree-container wrapper-to-fix-height"
              >
                <JsonTree
                  setLabelsList={setLabelsList}
                  labelList={labelList}
                  selectable={false}
                  dxList={dxList}
                  setDxList={setDxList}
                  procedureList={procedureList}
                  labsImagingList={labsImagingList}
                  setProcedureList={setProcedureList}
                  setLabsImagingList={setLabsImagingList}
                  educationalMaterialList={educationalMaterialList}
                  setEducationalMaterialList={setEducationalMaterialList}
                  draggable={!savedSimpleEncounter}
                  savedSimpleEncounter={savedSimpleEncounter}
                  selectedItem={selectedItem}
                  onAddItems={onAddItems}
                  hoveredOn={hoveredOn}
                  deleteDiagnosis={deleteDiagnosis}
                  deleteProcedure={deleteProcedures}
                  notesList={notesList}
                  handleProcedureClick={handleProcedureClick}
                  setAddOption={setAddOption}
                  type={type}
                  handleDeleteNotes={handleDeleteNotes}
                  showNotesDeleteIcon
                  saveFavorite={saveFavorite}
                  downloadImaging={downloadImaging}
                  ODOSModifierOptions={ODOSModifierOptions}
                  handleJcodeModifierClick={handleJcodeModifierClick}
                />
              </div>
            </div>
            {!savedSimpleEncounter && !componentType && (
              <Button className="btn btn-success min-wt-86" onClick={toggleModal}>
                {type === 'ap' ? ' Save as Order Set' : 'Save as Procedure Set'}
              </Button>
            )}
          </div>
        </div>

      </div>
      {isModalVisible && (
        <SetModal
          type={type}
          isModalVisible={isModalVisible}
          toggleModal={toggleModal}
          form={form}
          labelList={labelList}
          setLabelsList={setLabelsList}
          notesList={notesList}
          downloadImaging={downloadImaging}
          source={type === 'inOffice' ? 'ProcedureSet' : 'OrderSet'}
          encounterId={encounterId}
          providerId={providerId}
          clonedNotes={clonedNotes}
          defaultCheckedDiagnosis={defaultCheckedDiagnosis}
          setDefaultCheckedDiagnosis={setDefaultCheckedDiagnosis}
          orderSetData={orderSetData}
        />
      )}
      {isDiagnosisModal && (
        <MoveProcedureModal
          visible={isDiagnosisModal}
          toggleModal={toggleDiagnosisModal}
          oldDiagnosisId={oldDiagnosisId}
          deleteOcularProblem={deleteDiagnosisCode}
          dxListItems={dxListItems}
          moveDxProcdeureCodeData={moveDxProcdeureCodeData}
          setMoveDxProcdeureCodeData={setMoveDxProcdeureCodeData}
          handleProcedureSet={handleProcedureSet}
          loading={movedxLoading}
        />
      )}
      {examFindingModalState?.visible && (
      <ApplyExamFindingModal
        isVisible={examFindingModalState.visible}
        favouriteId={examFindingModalState.id}
        patientId={patientId}
        encounterId={encounterId}
        toggleModal={toggleApplyExamFindingModal}
        applyOrderSet={applyOrderSet}
        setApplyOrderSetPayload={setApplyOrderSetPayload}
      />
      )}
    </>
  );
};

export default LeftTab;
