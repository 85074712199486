import React, { useCallback } from 'react';

import Button from '../../../../../../components/Button';
import Form from '../../../../../../components/Form';

import { apiUrls } from '../../../../../../api/constants';
import { enumMasterCode, enums, formId } from '../../../../../../lib/constants';

import useRedirect from '../../../../../../hooks/useRedirect';

import PersonForm from '../../PersonForm';
import RenameProfile from '../Rename';
import useMasterId from '../../../../../../hooks/useMasterId';
import Loader from '../../../../../../components/Loader';

const AddForm = ({
  form,
  labels,
  onCancel,
  formName,
  onRequestComplete,
  onRelationSelect,
  self,
}) => {
  const { params, params: { tabId } } = useRedirect();
  const insuranceRelationSelfMasterId = useMasterId(
    [enumMasterCode.InsuranceRelationSelf],
    [enums.RELATION],
  );

  const getExtraData = useCallback(() => {
    const personId = form.getFieldValue('personId');
    const personType = form.getFieldValue('type');
    const personPartyType = personType || 'Patient';
    return {
      patientId: params.id,
      responsiblePersonId: personPartyType !== 'Patient' ? (personId) : null,
      responsiblePatientId: personPartyType === 'Patient' ? (personId || params.id) : null,
      responsiblePartyType: personPartyType,
    };
  }, [form, params.id]);

  if (!insuranceRelationSelfMasterId[enumMasterCode.InsuranceRelationSelf]) {
    return <Loader />;
  }
  return (
    <div className="required-feilds">
      <div className="complete-form-wrap insurance-aside-wrap posi-relative">
        <Form
          form={form}
          name={`${formName}-${tabId}`}
          tabId={tabId}
          url={apiUrls.ADD_SELF_INSURANCE}
          formId={formId.ADD_RESPONSIBLE_PARTY}
          extraData={getExtraData}
          initialData={{
            isActive: true,
            responsibleRelationId:
             insuranceRelationSelfMasterId[enumMasterCode.InsuranceRelationSelf],
          }}
          onRequestComplete={onRequestComplete}
          section
        >
          <RenameProfile labels={labels} form={form} self={self} />
          <div className="feild-row justify-content-sp-bt align-center">
            <h2 className="h2-title">{labels.get('titles.responsibleParty')}</h2>
          </div>
          <PersonForm
            form={form}
            labels={labels}
            onRelationSelect={onRelationSelect}
          />
          <div className="btns-action">
            <Button className="btn btn-success min-wt-86" id="patients_insurance_party_save" type="submit">{labels.get('buttons.save')}</Button>
            <Button className="btn min-wt-86" id="patients_insurance_party_save" onClick={onCancel}>{labels.get('buttons.cancel')}</Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddForm;
