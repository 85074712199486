import React from 'react';

const columns = (labels, onRowClick) => [
  // {
  //   Header: () => <Checkbox />,
  //   accessor: 'checkbox',
  //   Cell: () => <Checkbox />,
  //   fixWidth: '60',
  // },
  {
    Header: labels.get('tableColumns.items'),
    sort: true,
    accessor: 'items',
    Cell: (data) => {
      const {
        itemId, item, agingMasterCode,
      } = data?.row?.original || {};
      return (
        <div
          role="presentation"
          onClick={() => onRowClick(item, itemId, data.prevFilters, agingMasterCode)}
        >
          {item}
        </div>
      );
    },
  },
  {
    Header: labels.get('tableColumns.claims'),
    sort: true,
    accessor: 'claims',
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.billed'),
    sort: true,
    accessor: 'billed',
    Cell: ({ row: { original: { billed } } }) => (
      (billed || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.balance'),
    sort: true,
    accessor: 'balance',
    Cell: ({ row: { original: { balance } } }) => (
      (balance || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.valueBalance'),
    sort: true,
    accessor: 'valueBalance',
    Cell: ({ row: { original: { valueBalance } } }) => (
      (valueBalance || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.percentage'),
    sort: true,
    accessor: 'percentage',
    Cell: ({ row: { original: { percentage } } }) => (
      (percentage || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
];

export default columns;
