import React from 'react';
import Button from '../../../components/Button';

import TimeCell from '../../../components/Cells/TimeCell';
import { getUTCDate } from '../../../lib/util';

const columns = (labels) => [
  {
    Header: labels.get('columns.assignedTo'),
    accessor: 'assignedTo',
    Cell: ({ row: { original } }) => original?.taskDetails?.assignedToName ?? '',
    sort: true,
  },
  {
    Header: labels.get('columns.chartNumber'),
    accessor: 'chartNumber',
    Cell: ({ row: { original } }) => original?.taskDetails?.chartNumber ?? '',
  },
  {
    Header: labels.get('columns.patientName'),
    accessor: 'patientName',
    Cell: ({ row: { original } }) => original?.taskDetails?.patientName ?? '',
    sort: true,
  },
  {
    Header: labels.get('columns.taskIdentifier'),
    accessor: 'taskIdentifier',
    sort: true,
  },
  {
    Header: labels.get('columns.insurance'),
    accessor: 'insuranceName',
    Cell: ({ row: { original } }) => original?.taskDetails?.insuranceName ?? '',
    sort: true,
  },
  {
    Header: labels.get('columns.insuranceId'),
    accessor: 'insuranceId',
    Cell: ({ row: { original } }) => original?.taskDetails?.insuranceId ?? '',
  },
  {
    Header: labels.get('columns.cptCodes'),
    accessor: 'cptCodes',
    Cell: ({ row: { original } }) => {
      const { proceduresRequested } = original?.taskDetails;
      if (proceduresRequested?.trim()?.length) {
        const parsedProcedures = JSON.parse(proceduresRequested);
        return (
          <div>
            {parsedProcedures.map((cptObject, index) => (
              <p key={index + 1} className="mr-bt-2">
                {cptObject.name}
              </p>
            ))}
          </div>
        );
      }
      return <span />;
    },
  },
  {
    Header: labels.get('columns.priority'),
    accessor: 'priority',
    Cell: ({ row: { original } }) => original?.taskPriority || '',
    sort: true,
  },
  {
    Header: labels.get('columns.status'),
    accessor: 'status',
    Cell: ({ row: { original } }) => original?.taskStatus || '',
    sort: true,
  },
  {
    Header: labels.get('columns.location'),
    accessor: 'location',
    Cell: ({ row: { original } }) => original?.taskDetails?.locationName || '',
    sort: true,
  },
  {
    Header: labels.get('columns.dueDate'),
    accessor: 'dueDate',
    Cell: ({ row: { original } }) => <TimeCell value={original?.taskDueDate?.dateString} format="MM-DD-YYYY" />,
    sort: true,
  },
  {
    Header: labels.get('columns.createdBy'),
    accessor: 'createdByName',
    sort: true,
  },
  {
    Header: labels.get('columns.createdOn'),
    accessor: 'createdOn',
    Cell: ({ row: { original } }) => getUTCDate(original?.createdOn, 'MM-DD-YYYY'),
    sort: true,
  },
  {
    Header: '',
    accessor: 'takeAction',
    Cell: ({
      row: { original }, handleTakeAction, navigateToEditTask,
    }) => (
      <>
        {(original?.categoryName?.toLowerCase() === 'authorization'
        || original?.categoryName?.toLowerCase() === 'verification') && (
        <Button
          className="btn btn btn-success min-wt-86 sm-btn mr-rt-8"
          data-testid={`take-action-button-${original?.taskId}`}
          onClick={handleTakeAction(original)}
        >
          Take Action
        </Button>
        )}
        <Button
          className="btn btn btn-success min-wt-86 sm-btn mr-rt-8"
          data-testid={`update-button-${original?.taskId}`}
          onClick={navigateToEditTask(original)}
        >
          Update
        </Button>
      </>
    ),
    fixWidth: '230',
  },
];

export default columns;
