/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Row, Col } from 'antd';
import isFunction from 'lodash/isFunction';
import find from 'lodash/find';

import { apiUrls } from '../../api/constants';

import useUpdateEffect from '../../hooks/useUpdateEffect';

import AutoComplete from '../../components/Form/AutoComplete/AntdAutocomplete';
import Tooltip from '../../components/Tooltip';
import SmCheckbox from '../../components/SmCheckbox';

import './autocomplete.scss';
import { formatPhoneNumber, getName, getString } from '../../lib/util';

const physicianOptionRenderer = (options = []) => {
  if (!options.length) return [];
  return [{
    label: (
      <div className="option-header-main-wrapper">
        <div className="option headerWrapper">
          <span className="header" style={{ flex: 2 }}>
            Name
          </span>
          <span className="header" style={{ flex: 2 }}>
            Specialty
          </span>
          <span className="header" style={{ flex: 2 }}>
            NPI
          </span>
          <span className="header" style={{ flex: 2 }}>
            Phone
          </span>
          <span className="header" style={{ flex: 2 }}>
            Fax
          </span>
          <span className="header" style={{ flex: 5 }}>
            Address
          </span>
        </div>
      </div>
    ),
    options: options && options.map((item) => (
      {
        value: item.name || getName(item.firstName, item.middleName, item.lastName),
        item: {
          ...item,
          name: item.name || getName(item.firstName, item.middleName, item.lastName),
          address: getName(item.address, item.address1, item.address2),
        },
        label: (
          <div className="option" key={item.insurancePayerId}>
            <span
              title={item.name || getName(item.firstName, item.middleName, item.lastName)}
              style={{ flex: 2 }}
            >
              {item.name || getName(item.firstName, item.middleName, item.lastName)}
            </span>
            <span title={item.speciality} style={{ flex: 2 }}>
              {item.speciality}
            </span>
            <span title={item.npi} style={{ flex: 2 }}>
              {item.npi}
            </span>
            <span title={item.phone} style={{ flex: 2 }}>
              {formatPhoneNumber(item.phone)}
            </span>
            <span title={item.fax} style={{ flex: 2 }}>
              {item.fax}
            </span>
            <span title={`${getName(item.address, item.address1, item.address2)} ${item.zip || ''}`} style={{ flex: 5 }}>
              {`${getName(item.address, item.address1, item.address2)} ${item.city || ''} ${item.stateCode || ''} ${item.zip || ''}`}
            </span>
          </div>
        ),
      }

    )),
  }];
};

const tooltipContent = (data) => (
  <>
    <div>
      {data.title}
    </div>
    <h4>
      {data.name}
    </h4>
    <p>
      {data.speciality}
    </p>
    <p>
      {`${data.address} ${data.city || ''} ${data.stateCode || ''} ${data.zip || ''}`}
    </p>
    <p>
      {data.phone}
    </p>
    <p>
      NPI:
      {' '}
      {data.npi}
    </p>
  </>
);

const getAddressDetails = (addresses = []) => {
  if (addresses.length > 1) {
    const mailingAddress = find(addresses, (item) => item?.address_purpose?.toLowerCase() === 'mailing');
    return {
      address: getString([mailingAddress?.address_1, mailingAddress?.address_2]),
      addressLine2: mailingAddress?.address_2,
      zip: mailingAddress?.postal_code,
      fax: mailingAddress?.fax_number,
      phone: mailingAddress?.telephone_number,
    };
  }
  return {
    address: getString([addresses?.[0]?.address_1, addresses?.[0]?.address_2]),
    zip: addresses?.[0]?.postal_code,
    fax: addresses?.[0]?.fax_number,
    phone: addresses?.[0]?.telephone_number,
  };
};

const ReferringPhysicianAutoComplete = ({
  label,
  placeholder,
  form,
  disabled,
  required,
  initialValue,
  labelSpan = '10',
  inputSpan = '13',
  onSelect,
  name,
  onChange,
  setIsResetFields,
  allowClearOnBlur,
  disabledButNotClear,
  selectClassName,
}) => {
  const [setAutoCompleteValue, handleAutoCompleteValue] = useState(null);
  const [tooltipData, setTooltipData] = useState(initialValue);
  const [isHovering, setHovering] = useState(false);
  const [includeAllStates, setIncludeAllStates] = useState(false);

  useEffect(() => {
    if (initialValue) {
      setTooltipData(initialValue);
    }
  }, [initialValue]);

  const physicianOptionParser = useCallback((option) => ({
    name: option.name,
    value: option.npi,
    ...getAddressDetails(option.addresses),
    ...option,
  }), []);

  const onPhysicianSelect = useCallback((value, allItems) => {
    const { item } = allItems;
    form.setFieldsValue({ [name]: item.npi });
    if (onSelect) {
      onSelect(item);
    }
    setTooltipData(item);
  }, [form, name, onSelect]);

  const onChangeSelectedValue = useCallback((data) => {
    if (tooltipData) {
      setTooltipData(null);
    }
    if (onChange && typeof onChange === 'function') {
      onChange(data);
    }
  }, [onChange, tooltipData]);

  const setError = useCallback(() => {
    if (required) {
      form.setFields([{
        name,
        value: null,
        errors: [`${label} is Required`],
      }]);
    } else {
      form.setFields([{
        name,
        value: null,
      }]);
    }
  }, [required, form, name, label]);

  useEffect(() => {
    if (initialValue && Object.values(initialValue).length) {
      if (isFunction(setIsResetFields)) setIsResetFields(true);
    }
  }, [initialValue, form]);

  useUpdateEffect(() => {
    if (!required && disabled) {
      setAutoCompleteValue(null);
      form.setFields([{
        name,
        value: null,
        errors: [],
      }]);
    } else if (required) {
      form.setFields([{
        name,
        value: null,
        errors: [`${label} is Required`],
      }]);
    }
  }, [required, disabled]);

  const handleCheckbox = useCallback(({ target: { checked } }) => setIncludeAllStates(checked), []);

  return (
    <div
      className="referring-physician-autocomplete-container"
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <Tooltip
        overlayClassName="tooltip-container"
        title={(
        tooltipData
        && Object.values(tooltipData).length
          ? tooltipContent({ ...tooltipData, title: label || '' }) : ''
      )}
      >
        <AutoComplete
          label={label}
          placeholder={placeholder}
          name={name}
          url={apiUrls.GET_REFERRING_PHYSICIAN}
          optionParser={physicianOptionParser}
          optionMaster="SearchText"
          labelSpan={labelSpan}
          inputSpan={inputSpan}
          onChange={onChangeSelectedValue}
          disabled={disabled || disabledButNotClear}
          required={required}
          initialValue={initialValue}
          style={{ width: '100%' }}
          onSelect={onPhysicianSelect}
          optionRenderer={physicianOptionRenderer}
          dropdownMatchSelectWidth={735}
          setError={setError}
          setAutoCompleteValue={handleAutoCompleteValue}
          virtual={false}
          allowClearOnBlur={allowClearOnBlur}
          params={{ IncludeAllStates: includeAllStates, PageSize: 250 }}
        />
      </Tooltip>
      <Row className="all-states-checkbox-row">
        <Col span={inputSpan} offset={labelSpan}>
          <SmCheckbox
            name="all"
            className={classnames(selectClassName, 'all-states-checkbox', { show: isHovering })}
            isFormItem={false}
            checked={includeAllStates}
            onChange={handleCheckbox}
          >
            All States
          </SmCheckbox>
        </Col>
      </Row>
    </div>
  );
};

ReferringPhysicianAutoComplete.defaultProps = {
  form: {},
  disabled: false,
  required: false,
  initialValue: {},
  labelSpan: '10',
  inputSpan: '13',
  onSelect: () => { /* This is intentional */ },
  name: '',
  onChange: null,
  label: '',
  placeholder: '',
  allowClearOnBlur: true,
};

ReferringPhysicianAutoComplete.propTypes = {
  form: PropTypes.objectOf(PropTypes.object),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  initialValue: PropTypes.objectOf(PropTypes.any),
  labelSpan: PropTypes.string,
  inputSpan: PropTypes.string,
  onSelect: PropTypes.func,
  name: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  allowClearOnBlur: PropTypes.bool,
};

export default ReferringPhysicianAutoComplete;
