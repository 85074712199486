import React from 'react';

import TimeCell from '../../../../../components/Cells/TimeCell';

const columns = (labels) => [

  {
    Header: labels.get('tableColumns.dos'),
    accessor: 'serviceDate',
    Cell: ({ row: { original: { serviceDate } } }) => (
      <span
        className="cursor-pointer"
      >
        <TimeCell value={serviceDate} />
      </span>
    ),
    sort: true,
  },
  {
    Header: labels.get('tableColumns.procedure'),
    accessor: 'procedureCode',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.description'),
    accessor: 'description',
  },
  {
    Header: labels.get('tableColumns.modifierCodes'),
    accessor: 'modifierCodeList',
    Cell: ({
      row: {
        original: { modifierCodeList = [] },
      },
    }) => <span>{modifierCodeList?.map((item, index) => `${item}${index === modifierCodeList.length - 1 ? '' : ', '}`)}</span>,
  },
  {
    Header: labels.get('tableColumns.provider'),
    accessor: 'providerName',
    Cell: ({
      row: {
        original:
      { providerFirstName, providerLastName, providerMiddleName },
      },
    }) => `${providerFirstName || ''} ${providerMiddleName || ''} ${providerLastName || ''}`,
  },
  {
    Header: labels.get('tableColumns.location'),
    accessor: 'locationName',
  },
  {
    Header: labels.get('tableColumns.diagnosisCodes'),
    accessor: 'diagnosesCode',
    Cell: ({
      row: {
        original: { diagnosesCode = [] },
      },
    }) => <span>{diagnosesCode?.map((item, index) => `${item}${index === diagnosesCode.length - 1 ? '' : ', '}`)}</span>,
  },
  {
    Header: labels.get('tableColumns.units'),
    accessor: 'unit',
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.amount'),
    accessor: 'amount',
    Cell: ({ row: { original: { amount } } }) => (
      (amount || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.balance'),
    accessor: 'balance',
    Cell: ({ row: { original: { balance } } }) => (
      (balance || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.status'),
    accessor: 'status',
  },
];

export default columns;
