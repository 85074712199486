const ErrorMessages = {
  PLEASE_FILL_ALL_REQUIRED_FIELDS: 'Please enter all required fields.',
  TO_DATE_VALIDATOR_ERROR: 'To date must be greater than from date.',
  UPLOAD_FILE_ERROR: 'You can upload only .pdf .png .jpeg .jpg files.',
  CONFIRM_EMAIL_VALIDATION_ERROR: 'Email should be same.',
  FILE_NOT_FOUND: 'File not found.',
  AUTHORIZATION_EXPIRED: 'The validation for this authorization is expired',
  LOGIN_EXPIRED: 'Your session has been expired. please login again',
  VISIT_TYPE_REQUIRED: 'Visit type is required',
  NOT_ENOUGH_SPACE_FOR_NEW_APPOINTMENT: 'There is\'nt enough space to book an appointment of visit type',
  SCHEDULER_SLOT_NOT_FREE: 'Cannot add appointment at ',
  SCHEDULER_SLOT_NOT_FREE_TO_MOVE: 'Cannot move appointment at ',
  SCHEDULER_ARRIVAL_TIME_BEFORE_START_TIME: 'Expected arrival time for this slot is before opening time.',
  SCHEDULER_CANNOT_ADD_BEFORE: 'Cannot add appointment in the past.',
  SCHEDULER_CANNOT_SELECT_MORE_THAN_5: 'Cannot select more than 5 providers',
  VALID_EMAIL_ADDRESS: 'Please enter a valid email.',
  MINIMUM_FOUR_FIELDS_REQUIRED: 'Please enter minimum four fields before going further',
  DOCTOR_REQUIRED: 'Please select a doctor.',
  VALUE_SHOULD_BE_NOT_LESS_THAN_5: 'Value should not be less than 5',
  VALUE_SHOULD_NOT_BE_GREATER_THAN_800: 'Value should not be greater than 800',
  PAST_APPOINTMENTS_CANNOT_BE_EDITED: 'Past appointment cannot be edited',
  UNABLE_TO_UPDATE_FIELD: 'An error occurred while updating. Please try again.',
  DRAW_TAB_CANNOT_ADD_MORE_THAN_5: 'Cannot add more than 5 tab',
  DRAW_IMAGE_CANNOT_ADD_MORE_THAN_1: 'Cannot add more than 1 image',
  FIELD_REQUIRED: 'is required',
  VALUE_SHOULD_NOT_BE_GREATER_THAN: (value) => `Value should not be greater than ${value}`,
  VALUE_SHOULD_NOT_BE_LESS_THAN: (value) => `Value should not be less than ${value}`,
  AT_LEAST_ONE_FOLDER_SELECTED: 'At least one folder should be selected to move favorites',
  AT_LEAST_ONE_CLAIM_SELECTED: 'At least one claim should be selected',
  SELECT_AT_LEAST_ONE_TASK: 'Please select at least one task',
  ENTER_CLAIM_NUMBER_FIRST: 'Please enter claim number first',
  SELECT_ATLEAST_ONE_OCULAR_PROBLEM_IN_FAVOURITE_OUTSIDE_TESTING: 'Please select at least one outside test and one associated ICD-10 before making it favorite',
  SELECT_ATLEAST_ONE_OCULAR_PROBLEM_IN_FAVOURITE: 'Please select at least one ocular problem before making it favorite',
  ENTER_MINIMUM_FOUR_HPI_VALUE: 'Please enter minimum four hpi values',
  PLEASE_ENTER_HPI_VALUE: 'Please add Data to HPI field before making it favorite',
  SELECT_AT_LEAST_ONE_WORK_LIST_ITEM: 'Please select at least one work list item',
  SELECT_USER_TO_ASSIGN_WORK_LIST: 'Please select a user to assign the work list items',
  PRODUCT_NOT_FOUND: 'Product Not Found. Please Create a Record or Enter a Valid Barcode',
  SELECT_AT_LEAST_ONE_INVENTORY_ITEM: 'Please select at least one inventory item',
  CANNOT_DELETE_INCOMING_INVENTORY_ITEM: 'Cannot delete incoming inventory item',
  FROM_DATE_VALIDATOR_ERROR: 'From date must be less than from date.',
  SELECT_AN_IMAGE: 'Please select an image to make it default',
  CANNOT_BOOK_APPOINTMENT_WITH_SAME_DETAILS: 'You have already booked an appointment for this Patient with the same Provider and Insurance. Can\'t book another appointment with the same details.',
  PLEASE_SELECT_AT_LEAST_ONE_ERROR_ITEM: 'Please select at least one error item.',
  PREVIOUS_ENCOUNTER_DOES_NOT_EXIST: 'Previous record does not exist',
  PLEASE_SELECT_AT_LEAST_ONE_TABLE_ITEM: 'Please select at least one table item',
  AMOUNT_ENTERED_MORE_THAN_EXPECTED: 'Entered amount in line items is more than the amount entered in the insurance amount',
  REFUND_AMOUNT_NOT_GREATER_THAN_REFUNDABLE_BALANCE: 'Amount is greater than refundable balance',
  COLLECT_PAYMENT_OR_CONFIRM_NOT_PAID: 'Please collect the payment or mark that you could not collect the payment.',
  OD_OD_ONE_REQUIRED: 'OS or OD is required',
  DURATION_FACTOR_OF: (factor) => `Duration should be a factor of ${factor}`,
  PLEASE_ENTER_NUMBER_ONLY: 'Please enter numbers only',
  UNIT_SHOULD_BE_GREATER_THAN_ZERO: 'Unit should be greater than zero.',
  AMOUNT_SHOULD_BE_GREATER_THAN_ZERO: 'Amount should be greater than zero.',
  PLEASE_ENTER_LINEITEM: 'Please fill atleast one lineItem.',
  DELIVERY_METHOD_CHANGE: 'Please select claims with same delivery method',
  SEND_TO_WAY_STAR_ERROR: 'Only Electronic claims can be sent to waystar. Please deselect all Paper claims and try again',
  MARK_AS_SUBMITTED_ERROR: 'Only Paper claims can be marked as submitted. Please deselect all Electronic claims and try again',
  UPLOAD_IMAGE_TESTTYPE_ERROR: 'Please select a Test Type or an already added Imaging to upload',
  SELECT_A_SUPERVISING_PROVIDER_ID: 'Please select supervising provider',
  VISIT_TYPE_IS_REQUIRED: 'VisitType is required',
  LOCATION_IS_REQUIRED: 'Location is required',
  PROVIDER_IS_REQUIRED: 'Provider is required',
  INCH_CAN_NOT_BE_GREATER_THAN_2_DIGITS: 'Height(Inch) can not be greater than 2 digits',
  FEET_CAN_NOT_BE_GREATER_THAN_1_DIGIT: 'Height(Feet) can not be greater than 1 digit',
  SYSTOLIC_BP_CANNOT_BE_LONGER_THAN_3_DIGITS: 'Systolic(bp) can not be greater than 3 digits',
  DIASTOLIC_BP_CANNOT_BE_LONGER_THAN_3_DIGITS: 'Diastolic(bp) can not be greater than 3 digits',
  WEIGHT_CANNOT_BE_LONGER_THAN_3_DIGITS: 'Weight can not be greater than 3 digits',
  TOTAL_AMOUNT_SHOULD_BE_EQUAL_TO_BATCH_AMOUNT: 'Total amount should be equal to batch amount',
  VALUE_SHOULD_BE_GREATER_THAN_ZERO: 'Value should be greater than 0',
  PRESSURE_VALUE_CAN_ONLY_BE_2_DIGITS: 'Value cannot be longer than 2 digits',
  PLEASE_ENTER_CORRECT_VALUES_IN_FIELDS: 'Please enter correct values in the fields',
  VALUE_CANNOT_BE_LONGER_THAN_4_DIGITS: 'Value cannot be longer than 4 digits',
  VALUE_CANNOT_BE_LONGER_THAN_5_DIGITS: 'Value cannot be longer than 5 digits',
  VALUE_CANNOT_BE_LONGER_THAN_1_DIGIT: 'Value cannot be longer than 1 digit',
  VALUE_CANNOT_BE_LONGER_THAN_3_DIGIT: 'Value cannot be longer than 3 digit',
  VALUE_CANNOT_BE_LONGER_THAN_2_DIGIT: 'Value cannot be longer than 2 digit',
  VALUE_CANNOT_BE_LONGER_THAN_8_CHARACTERS: 'Value cannot be longer than 8 characters',
  VALUE_CANNOT_BE_LONGER_THAN_30_CHARACTERS: 'Value cannot be longer than 30 characters',
  VALUE_CANNOT_BE_LONGER_THAN_150_CHARACTERS: 'Value cannot be longer than 150 characters',
  SOME_AMOUNT_IS_PENDING_TO_BE_COLLECTED: 'Amount should be collected before check-out',
  ASSIGN_ERROR: 'No available time slots exist as per your templates for the current encounters date and time of service',
  PATIENT_ALREADY_EXISTS: 'This record already exists in our system. Do you want to continue ?',
  P_VERIFY_PENDING_ERROR: 'The selected insurance has not been verified yet. Do you want to continue?',
  TASK_ALREADY_EXISTS: 'Task already exists. Do you still like to continue ?',
  P_VERIFY_NOT_VERIFIED_ERROR: 'Insurance selected has error and/or inactive. Do you want to continue?',
  CHART_NUMBER_SHOULD_BE_GREATER_THAN_LAST_CHART_NUMBER: (chartNumber) => `Entered chart number should be greater than ${chartNumber}`,
  PROVIDER_IS_NOT_CONTRACTED_FOR_LOCATION: 'The selected provider is not contracted for the location and/or payer',
  CONTRACT_EXPIRED_ERROR: 'Cannot Post Charges as contract has been expired',
  SELECTED_PROCEDURE_DOES_NOT_EXIST_IN_FEE_SCHEDULE: 'Selected procedure does not exist in the selected fee schedule, please pick a different fee schedule or add the amount directly',
  VALUE_AFTER_DECIMAL_PLACES_CANNOT_BE_GREATER: (decimalPlaces) => `Value should contain numbers with upto ${decimalPlaces} decimal places`,
  VALUE_BEFORE_DECIMAL_PLACES: 'Digit before decimal: 1',
  CAN_NOT_INACTIVE_PROFILE: 'Cannot Inactivate the profile as there are active insurance associated with this.',
  SAME_PROCEDURE_ERROR: 'same procedure with clashing',
  TIME_ERROR_FOR_PROCEDURE: 'procedure with clashing global period',
  ERROR_FOR_ALREADY_USED_PROCEDURE: 'the procedure being added is clashing',
  JCODE_DROP_VALIDATION_ERROR: 'You can not add a J-code directly. Please add a recognized parent 6-code first and then add the J-code on it.',
  NOT_PERMISSION_TO_OVERRIDE_INSURANCE: "You don't have permission to override default medical insurance profile",
  LETTER_PRINT_FAILED: 'The letter has been failed to print',
  LETTER_SENT_FAILED: 'The letter has been failed to send',
  TOTAL_LETTER_PRINT_FAILED: (letterCount) => `${letterCount} letters are failed to print`,
  TOTAL_LETTER_SENT_FAILED: (letterCount) => `${letterCount} letters are failed to send`,
  ANESTHESIA_GREATER_OR_EQUAL_MESSAGE: 'Anesthesia start time can not be greater than or equal to anesthesia end time',
  ANESTHESIA_LESS_OR_EQUAL_MESSAGE: 'Anesthesia end time can not be less or equal to anesthesia start time',
  ANESTHESIA_START_TIME_REQUIRED: 'Please enter anesthesia start time',
  ANESTHESIA_END_TIME_REQUIRED: 'Please enter anesthesia end time',
  FILES_TYPE_NOT_SUPPORTED: 'These type of files are not supported',
  VISION_FORM_OD_OS_REQUIRED_VALIDATION: 'Either OD or OS, atleast one value is required',
  VISION_FORM_OD_VALUE_RANGE_VALIDATION: 'OD value must be either 5 to 800',
  VISION_FORM_OS_VALUE_RANGE_VALIDATION: 'OS value must be either 5 to 800',
  IT_IS_NOT_A_VALID_VALUE: 'Time selected is not valid',
  VALUE_SELECTED_IS_HIGHER_THAN_END_TIME: 'Start time is higher than end time',
  VALUE_SELECTED_IS_LOWER_THAN_START_TIME: 'End time is lower than start time',
  PLEASE_ADD_ONE_LOCATION: 'Please add atleast one location',
  PLEASE_SELECT_ATLEASET_1_CLAIM: 'Please select at least one claim',
  PLEASE_ADD_CHECK_DETAILS: 'Please add check details',
  PLEASE_ADD_A_FILE: 'Please add a pdf file to upload',
  FILE_SIZE_SHOULD_NOT_BE_LARGER_THAN_SIZE: (size) => `File size must not be larger than ${size}MB`,
  PASSWORD_DO_NOT_MATCH: 'The two passwords do not match',
  PASSWORD_CHANGE_FAILED: 'Passwords change failed',
  PASSWORD_NOT_VALID: 'Password should contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 symbol.',
  PASSWORD_NOT_STRONG: 'Password is not strong. Please use strong password.',
  UNABLE_TO_SEND_FAX_ON_ENTERED_NUMBER: 'Unable to send fax on the entered destination number.',
  PLEASE_SELECT_A_DOCUMENT_TO_FAX: 'Please select a document to fax.',
  PLEASE_SELECT_A_DEST_FAX_NUMBER: 'Please select/enter a destination Fax number',
  AT_LEAST_TWO_DOC_REQ_TO_CREATE_BUNDLE: 'At least 2 documents required to create bundle.',
  PLEASE_SELECT_COVER_PAGE_FOR_PREVIEW: 'Please select coverpage for preview',
  PLEASE_SELECT_DOCUMENT_FOR_PREVIEW: 'Please select document for preview',
  PLEASE_SELECT_A_LOCATION: 'Please select a location',
  RULES_NOT_EVALUATED: 'No Rules Evaluated yet',
  PASSED_ALL_RULES: 'Encounter passed all applicable rules',
  RULES_NOT_APPLICABLE: 'No Rules are applicable for this encounter',
  NO_MATCHING_RULES: "One or More Procedure code's does not have any matching Rules",
  FAILED_RULES: 'Failed Rules',
  DOWNLOAD_EDI_MAX_CLAIM_REACHED: 'You can only print 10 claims at a time.',
  NO_MATCHING_RULES_ALL_CPT_CODES: 'No matching Rules for any Procedure code',
};

export default ErrorMessages;
