import React, { useState, useCallback, useMemo } from 'react';
import { Row, Col } from 'antd';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';

import { apiUrls } from '../../../../api/constants';
import { listIds, enumMasterCode } from '../../../../lib/constants';
import Events from '../../../../lib/events';
import dateFormatter from '../../../../lib/dateFormatter';
import SuccessMessages from '../../../../lib/successMessages';

import Form from '../../../../components/Form';
import Input from '../../../../components/Form/Input';
import Button from '../../../../components/Button';
import Notification from '../../../../components/Notification';

const parser = (values) => ({ ...values });

const { PATIENTS } = enumMasterCode;

const AddNote = ({
  labels, form, noteId, patientId, handleCancel, categoryId, toggleState, notesCategory,
  isPverifyNotes, handleRefetch,
}) => {
  const [formData, setFormData] = useState({});
  const [textNoteData, setTextNoteData] = useState('');

  const handleFormData = useCallback((formValues = {}) => {
    const { result } = formValues;
    setFormData({ ...result });
    setTextNoteData(result?.text);
    form.setFieldsValue({ text: result?.text });
  }, [form]);

  const handleNoteChange = useCallback(({ target: { value } }) => {
    setTextNoteData(value);
  }, []);

  const isNotesEdited = useMemo(() => (
    noteId ? textNoteData !== formData?.text : true
  ), [formData, noteId, textNoteData]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({
        message: noteId
          ? SuccessMessages.NOTE_UPDATED_SUCCESSFULLY : SuccessMessages.NOTE_ADDED_SUCCESSFULLY,
        success: true,
      });
      if (isFunction(toggleState)) toggleState();
      if (noteId) {
        Events.trigger('refetchNotesTable');
      }
      if (notesCategory?.toLowerCase() === PATIENTS) {
        Events.trigger('refetchNotesData');
      }
      if (isPverifyNotes) {
        handleRefetch();
      }
    }
  }, [handleRefetch, isPverifyNotes, noteId, notesCategory, toggleState]);

  const refactorModifiedByName = formData?.modifiedByName || formData?.createdByName;
  const refactorModifiedOn = dateFormatter(formData?.modifiedOn || formData?.createdOn);
  return (
    <div className="add-notes-content group-shadow">
      {noteId ? (
        <p className="paragraph">
          Edit Note
        </p>
      ) : (
        <p className="paragraph">
          Add Note
        </p>
      )}
      <Form
        form={form}
        url={apiUrls.ADD_PATIENT_NOTES}
        getUrl={`${apiUrls.GET_NOTES_BY_ID}?NoteId=${noteId}`}
        extraData={{
          PatientId: parseInt(patientId, 10),
          noteId: !isPverifyNotes ? noteId : null,
          categoryId,
          method: 'post',
        }}
        parser={parser}
        formId={noteId ? 'edit-patient-notes' : listIds.ADD_PATIENT_NOTES}
        onRequestComplete={onRequestComplete}
        isUpdate={!!noteId}
        onGetResponseComplete={handleFormData}
        initialData={{
          text: formData?.text,
        }}
        key={noteId}
      >
        <div>
          {noteId && refactorModifiedByName && refactorModifiedOn && (
          <p className="paragraph">
            {`Last updated by ${refactorModifiedByName || ''} on ${refactorModifiedOn || ''}.`}
          </p>
          )}
          <Row gutter={16}>
            <Col span={24}>
              <Input.TextArea
                name="text"
                autoSize={{ minRows: 10, maxRows: 10 }}
                labelSpan="0"
                inputSpan="24"
                onChange={handleNoteChange}
                placeholder="Enter Here"
              />
              <div className="group-btns">
                <Button
                  className={classNames('btn min-wt-86 inline', { 'btn-success': isNotesEdited, 'cursor-not-allowed': !isNotesEdited })}
                  disabled={!isNotesEdited}
                  onClick={form.submit}
                >
                  {labels.get(noteId ? 'buttons.update' : 'buttons.save')}
                </Button>
                <Button
                  className={classNames('btn min-wt-86', { 'cursor-not-allowed': !isNotesEdited })}
                  disabled={!isNotesEdited}
                  onClick={handleCancel}
                >
                  {labels.get('buttons.cancel')}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default AddNote;
