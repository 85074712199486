import React, { useCallback, useState } from 'react';
import { Form as AntdForm } from 'antd';
import { useDispatch } from 'react-redux';

import { apiUrls } from '../../../../../../../api/constants';
import useRedirect from '../../../../../../../hooks/useRedirect';
import SuccessMessages from '../../../../../../../lib/successMessages';
import { formId, UiRoutes, listIds } from '../../../../../../../lib/constants';
import formFieldValueParser from '../../../../../../../lib/formFieldValuesParser';
import { deleteTabData } from '../../../../../../../store/actions/navigation';

import Form from '../../../../../../../components/Form';
import Button from '../../../../../../../components/Button';
import Notification from '../../../../../../../components/Notification';

import BillingForm from '../Component/BillingForm';

const EditBilling = ({ labels, isProviderViewOnly }) => {
  const {
    params, params: { id, tabId, billingId }, push, generatePath,
  } = useRedirect();
  const [form] = AntdForm.useForm();
  const [formData, setFormData] = useState({});
  const [isClear, setClear] = useState(false);
  const dispatch = useDispatch();

  const onGetResponseComplete = useCallback((billingData = {}) => {
    if (Object.values(billingData).length) {
      setFormData(billingData);
    }
  }, []);

  const goBack = useCallback(() => {
    dispatch(deleteTabData({ tabId }));
    push(generatePath(UiRoutes.editProviderWithTabId, { ...params }));
  }, [dispatch, tabId, push, generatePath, params]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.BILL_UPDATED_SUCCESSFULLY, success: true });
      goBack();
    }
  }, [goBack]);

  const parser = useCallback((values) => formFieldValueParser(values, {
    date: [
      'effectiveDateFrom',
      'effectiveDateTo',
    ],
  }), []);

  const handleClear = useCallback((resetForm) => {
    resetForm();
    setClear(true);
  }, []);

  return (
    <Form
      section
      form={form}
      onRequestComplete={onRequestComplete}
      initialData={
          { billtoSpeciality: true, billtoAddress: true }
        }
      initialDataParser={parser}
      url={`${apiUrls.UPDATE_BILLINGS}/${billingId}`}
      getUrl={`${apiUrls.GET_BILLINGS}/${billingId}`}
      name={formId.PROVIDER_EDIT_BILLING}
      formId={formId.PROVIDER_EDIT_BILLING}
      parser={parser}
      listId={listIds.SETTING_PROVIDER_BILLING}
      isUpdate
      editKey={billingId}
      tabId={tabId}
      extraData={{ providerId: id }}
      onGetResponseComplete={onGetResponseComplete}
      disableForm={isProviderViewOnly}
    >
      {({ resetForm }) => (
        <div className="pannel back-pannel mr-top-20">
          <div className="pannel-heading">
            <p role="presentation" id="provider_billing_goBack" className="back-arrow" onClick={goBack}>{labels.get('link.goBack')}</p>
          </div>
          <div className="pannel-body">
            <BillingForm
              form={form}
              labels={labels}
              goBack={goBack}
              isClear={isClear}
              setClear={setClear}
              formData={formData}
              onGetResponseComplete={onGetResponseComplete}
              isUpdate
            />
            <div className="btns-action">
              <Button id="provider_billing_save" className="btn btn-success min-wt-86" type="submit">{labels.get('buttons.update')}</Button>
              <Button id="provider_billing_cancel" className="btn" onClick={() => handleClear(resetForm)}>{labels.get('buttons.clear')}</Button>
            </div>
          </div>
        </div>
      )}
    </Form>
  );
};

export default EditBilling;
