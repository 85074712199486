import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import uniqBy from 'lodash/uniqBy';

import AutoComplete from '../../components/Form/GridAutoComplete';
import { apiUrls } from '../../api/constants';
import Table from '../../components/Table';
import ProcedureColumns from './columns';

const defaultColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    flex: 1,
  },
  {
    Header: 'Description',
    accessor: 'description',
    flex: 5,
  },
];

const ProcedureGridAutoComplete = ({
  form,
  disabled,
  required,
  initialValue = {},
  labelSpan = '10',
  inputSpan = '13',
  styleWidth,
  className,
  selectProps,
  dropdownMatchSelectWidth,
  valueAccessor,
  nameAccessor,
  params,
  name,
  columns,
  onSelect,
  tableData,
  setTableData,
  placeholder,
  selectedRows,
  setSelectedRow,
  isSetToInitial,
  ...props
}) => {
  const onProcedureSelect = useCallback((value) => {
    if (isFunction(setTableData)) {
      setTableData(uniqBy([...tableData,
        {
          name: value?.name,
          description: value?.description,
          id: value?.id,
          value: value.id,
          isActive: value?.isActive,
          isAuthorize: false,
        }]), 'value');
      setTimeout(() => form.setFieldsValue({ [name]: undefined }), 0);
    }
    if (isFunction(onSelect)) {
      onSelect(value?.value, value);
    }
  }, [setTableData, onSelect, tableData, form, name]);

  const subTaskOptionParser = useCallback((option) => ({
    name: option[nameAccessor] || option.cptCode,
    value: option[valueAccessor] || option.id,
    ...option,
  }), [valueAccessor, nameAccessor]);

  const deleteProcedure = useCallback((id) => {
    setTableData((prev) => prev.filter((dd) => Number(dd.id) !== Number(id)));
  }, [setTableData, tableData]);

  const checkedRowId = useMemo(() => {
    if (tableData?.length) {
      const checkedIds = {};
      tableData.map((row, index) => {
        if (row?.isAuthorize) {
          checkedIds[index.toString()] = true;
          return checkedIds[index.toString()];
        }
        return 0;
      });
      return checkedIds;
    }
    return [];
  }, [tableData]);

  return (
    <div>
      <AutoComplete
        name={name}
        url={apiUrls.GET_CPTS}
        optionParser={subTaskOptionParser}
        optionMaster="CPTCodeText"
        params={params}
        labelSpan={labelSpan}
        inputSpan={inputSpan}
        disabled={disabled}
        required={required}
        className={className}
        initialValue={initialValue}
        onSelect={onProcedureSelect}
        columns={defaultColumns}
        labelInValue
        placeholder={placeholder}
        showArrow
        notFoundContent="Procedure is not present is the system."
        style={styleWidth ? { width: '100%' } : {}}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        selectProps={{ ...selectProps, optionLabelProp: 'name', allowClear: true }}
        {...props}
        header
      />
      {tableData?.length > 0
        ? (
          <div className="mr-bt-12">
            <Table
              columns={ProcedureColumns}
              data={tableData}
              deleteProcedure={deleteProcedure}
              showRowSelection
              selectedRows={selectedRows}
              setSelectedRow={setSelectedRow}
              checkedRowIds={checkedRowId}
            />
          </div>
        ) : null}
    </div>
  );
};

ProcedureGridAutoComplete.defaultProps = {
  labels: {},
  form: {},
  disabled: false,
  required: false,
  initialValue: {},
  labelSpan: '10',
  inputSpan: '13',
  onSelect: () => { /* This is intentional */ },
  styleWidth: true,
  dropdownMatchSelectWidth: 400,
  columns: defaultColumns,
  placeholder: 'Search Procedure',
};

ProcedureGridAutoComplete.propTypes = {
  labels: PropTypes.objectOf(PropTypes.object),
  form: PropTypes.objectOf(PropTypes.object),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  initialValue: PropTypes.objectOf(PropTypes.any),
  labelSpan: PropTypes.string,
  inputSpan: PropTypes.string,
  onSelect: PropTypes.func,
  styleWidth: PropTypes.bool,
  dropdownMatchSelectWidth: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
};

export default ProcedureGridAutoComplete;
