import React from 'react';
import { Radio as RadioButton } from 'antd';
import './radio.scss';

const Radio = ({ label, ...props }) => (

  <div className="custom-radio-btn">
    <label className="ant-form-item-no-colon">{label}</label>
    <RadioButton.Group {...props} />
  </div>

);

export default Radio;
