import React, { useCallback } from 'react';
import { Form as AntdForm } from 'antd';

import Button from '../../../../components/Button';
import Modals from '../../../../components/Modal';

import AddPatient from './AddPatient';

const AddPatientModal = (props) => {
  const {
    visibility, labels, toggleModal, reFetch,
  } = props;
  const [form] = AntdForm.useForm();

  const handleClose = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  return (
    <Modals
      visible={visibility}
      toggleModal={handleClose}
      width={1200}
      className="schedular-patient-modal"
      destroyOnClose
      footer={[
        <div className="group-btns" key="footer">
          <Button className="btn min-wt-86" onClick={handleClose}>{labels.get('buttons.cancel')}</Button>
          <Button className="btn btn-success min-wt-86 inline" onClick={() => form.submit()}>{labels.get('buttons.save')}</Button>
        </div>,
      ]}
    >
      <div className="addition-header">
        <div className="lookup sprite-img-before">
          <p>{labels.get('titles.addPatient')}</p>
        </div>
      </div>
      <div className="modal-content-weapper shadow-wrap">
        <div className="modal-from-feilds">
          <AddPatient reFetch={reFetch} form={form} toggleModal={toggleModal} />
        </div>
      </div>
    </Modals>
  );
};

export default AddPatientModal;
