import React from 'react';

import BillingEncounter from '../../../wiredComponents/AddNewEncounter';
import SavedNavigationBar from '../../../components/SavedNavigationBar';
import { UiRoutes } from '../../../lib/constants';
import ActionIcons from '../../../wiredComponents/ActionIcons';
import useRedirect from '../../../hooks/useRedirect';
import useReduxStoreWithId from '../../../hooks/useReduxStoreWithId';

const EditBillingEncounter = () => {
  const { params } = useRedirect();
  const [savedEncounterData] = useReduxStoreWithId({ id: `BILLING_ENCOUNTER_LINE_ITEM_DATA-${params.tabId}` });
  const savedEncounter = savedEncounterData?.get('data');

  return (
    <SavedNavigationBar
      listName="Billing List"
      parentPath={UiRoutes.billing}
      title={(<h1>Billing</h1>)}
      rightChildren={(
        <ActionIcons
          userNotes
          edit
          demoGraphic
          documentsWithDropDown
          insurance
          patient={{
            patientId: params.id,
            patientFirstName: savedEncounter?.patientFirstName,
            patientMiddleName: savedEncounter?.patientMiddleName,
            patientLastName: savedEncounter?.patientLastName,
          }}
          patientId={params?.id}
          notesProps={{
            page: 'encounters',
          }}
        />
)}
    >
      {({ closeTab }) => (
        <div className="main-form-container">
          <BillingEncounter
            goBackPath={UiRoutes.billing}
            closeTab={closeTab}
          />
        </div>
      )}
    </SavedNavigationBar>
  );
};

export default EditBillingEncounter;
