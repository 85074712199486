import React from 'react';

import TabsComponent from '../../../../../../../components/Tabs';
import { labelPaths } from '../../../../../../../lib/constants';

import { tabsList } from '../../../../../../Dashboard/Tabs';
import WithLabel from '../../../../../../../hoc/withLabel';

const Tabs = (props) => {
  const { labels, closeTab } = props;
  const clonedTabs = [];
  tabsList.forEach((tab) => {
    clonedTabs.push({ ...tab, name: labels.get(`tabs.${tab.id}`) });
  });
  return (
    <TabsComponent tabsList={clonedTabs} id="patients" className="main-tab-wrap" closeTab={closeTab} />
  );
};
export default WithLabel(Tabs, labelPaths.EDIT_PATIENT);
