import React from 'react';
import classNames from 'classnames';

import './legends.scss';

const legendsData = [
  { label: 'labels.10Min', className: 'color-item-10min' },
  { label: 'labels.20Min', className: 'color-item-20min' },
  { label: 'labels.30Min', className: 'color-item-30min' },
  { label: 'labels.pending', className: 'color-item-New' },
  { label: 'labels.cancelled', className: 'color-item-Cancel' },
];

const Legends = ({ labels }) => (
  <div className="dashboard-legends-container">
    {legendsData.map(({ label, className }) => (
      <div key={label} className="legends-item">
        <div className={classNames('color-item', className)} />
        <div className="color-label">{labels.get(label)}</div>
      </div>
    ))}
  </div>
);

export default Legends;
