import { SUPERBILL_ACTIONS } from '../actions/superBillActions';

const initialState = {
  tasks: [],
  timePeriod: 'days',
  isPrintButtonDisabled: false,
};

const superBillReducer = (state = initialState, action) => {
  let updatedTasks;

  switch (action.type) {
    case SUPERBILL_ACTIONS.SET_STATUS:
      updatedTasks = state.tasks.map((task) => (task.taskId === action.payload.taskId
        ? { ...task, status: action.payload.status }
        : task));

      localStorage.setEncryptedData('superBillTasks', JSON.stringify(updatedTasks));
      return { ...state, tasks: updatedTasks };

    case SUPERBILL_ACTIONS.SET_DOWNLOAD_URL:
      updatedTasks = state.tasks.map((task) => (task.taskId === action.payload.taskId
        ? { ...task, downloadUrl: action.payload.downloadUrl }
        : task));

      localStorage.setEncryptedData('superBillTasks', JSON.stringify(updatedTasks));
      return { ...state, tasks: updatedTasks };

    case SUPERBILL_ACTIONS.ADD_TASK:
      updatedTasks = [...state.tasks, {
        taskId: action.payload.taskId,
        status: action.payload.status,
        downloadUrl: action.payload.downloadUrl,
        query: action.payload.query,
        polling: false,
        downloading: false,
      }];

      localStorage.setEncryptedData('superBillTasks', JSON.stringify(updatedTasks));
      return { ...state, tasks: updatedTasks };

    case SUPERBILL_ACTIONS.HYDRATE_TASKS:
      return { ...state, tasks: action.payload };

    case SUPERBILL_ACTIONS.RESET_TASK:
      return {
        ...state,
        tasks: state.tasks.filter((task) => task.taskId !== action.taskId),
      };
    case SUPERBILL_ACTIONS.SET_POLLING:
      return {
        ...state,
        tasks: state.tasks.map((task) => (task.taskId === action.payload.taskId
          ? { ...task, polling: action.payload.polling }
          : task)),
      };

    case SUPERBILL_ACTIONS.SET_DOWNLOADING:
      return {
        ...state,
        tasks: state.tasks.map((task) => (task.taskId === action.payload.taskId
          ? { ...task, downloading: action.payload.downloading }
          : task)),
      };

    case SUPERBILL_ACTIONS.SET_TIME_PERIOD:
      return { ...state, timePeriod: action.payload };

    case SUPERBILL_ACTIONS.DISABLE_PRINT_BUTTON:
      return { ...state, isPrintButtonDisabled: true };

    case SUPERBILL_ACTIONS.ENABLE_PRINT_BUTTON:
      return { ...state, isPrintButtonDisabled: false };

    default:
      return state;
  }
};

export default superBillReducer;

