import React, { useCallback, useEffect, useState } from 'react';

import { apiUrls } from '../../../../../../../api/constants';
import useCRUD from '../../../../../../../hooks/useCRUD';
import useRedirect from '../../../../../../../hooks/useRedirect';

import LeftTab from '../CustomDropppableTab';
import RightTab from './RightTab';
import AddItems from '../../AddItems';
import './procedures.scss';

const Procedures = ({ selectedProvider }) => {
  const { params: { patientId: PatientId, encounterId: EncounterID } } = useRedirect();
  const [addOption, setAddOption] = useState(null);
  const [editProcedureData, setEditProcedureData] = useState({});
  const [questionnaireData, setQuestionnaireData] = useState();
  const selectedItem = {
    procedure: {
      title: 'Procedure',
    },
  };

  const [patientProcedures, , , getDetail] = useCRUD({
    id: apiUrls.DOCTOR_PROCEDURES,
    url: apiUrls.DOCTOR_PROCEDURES,
    type: 'read',
  });

  const [procedureList, setProcedureList] = useState([]);
  const [dxList, setDxList] = useState([]);
  useEffect(() => {
    const procedureArray = patientProcedures?.result.map((data) => ({
      ...data,
      key: data.id,
      label: data.cptDescription,
    }));
    setProcedureList(procedureArray);
  }, [patientProcedures]);

  useEffect(() => {
    getDetail({
      PatientId,
      EncounterID,
      IsUnique: true,
    });
  }, [EncounterID, PatientId, getDetail]);

  const [procedureSetsList, setProcedureSetsList] = useState([]);
  const [labelList, setLabelsList] = useState([]);

  const handleProcedureClick = useCallback((node) => () => {
    setAddOption('procedure');
    setEditProcedureData(node);
  }, []);

  useEffect(() => {
    if (editProcedureData) {
      setQuestionnaireData({ associatedProcedureId: editProcedureData?.associatedProcedureId });
    }
  }, [editProcedureData, labelList]);

  return (
    <div className="select-box-section outer-border-panel">
      <div className="pannel back-pannel">
        <div className="pannel-heading"><p role="presentation">In Office Procedures</p></div>
        <div className="pannel-body">
          <div className="procedures">
            <LeftTab
              dxList={dxList}
              setDxList={setDxList}
              procedureList={procedureList}
              setProcedureList={setProcedureList}
              procedureSetsList={procedureSetsList}
              labelList={labelList}
              setLabelsList={setLabelsList}
              addOption={addOption}
              setAddOption={setAddOption}
              handleProcedureClick={handleProcedureClick}
              questionnaireData={questionnaireData}
              setQuestionnaireData={setQuestionnaireData}
              type="inOffice"
              selectedProvider={selectedProvider}
            />
            {addOption === null ? (
              <RightTab
                dxList={dxList}
                setDxList={setDxList}
                procedureList={procedureList}
                setProcedureList={setProcedureList}
                procedureSetsList={procedureSetsList}
                setProcedureSetsList={setProcedureSetsList}
                addOption={addOption}
                setAddOption={setAddOption}
                selectedItem={selectedItem}
              />
            ) : (
              <AddItems
                addOption={addOption}
                selectedItem={selectedItem[addOption]}
                setAddOption={setAddOption}
                dxList={dxList}
                setDxList={setDxList}
                labelList={labelList}
                setLabelsList={setLabelsList}
                editProcedureData={editProcedureData}
                encounterId={EncounterID}
                questionnaireData={questionnaireData}
                showJCodeFields
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Procedures;
