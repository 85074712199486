import React, { useCallback } from 'react';
import { Form as AntForm } from 'antd';

import Notification from '../../../../../../components/Notification';
import Loader from '../../../../../../components/Loader';

import useRedirect from '../../../../../../hooks/useRedirect';
import useCRUD from '../../../../../../hooks/useCRUD';

import SuccessMessages from '../../../../../../lib/successMessages';
import { labelPaths } from '../../../../../../lib/constants';

import { apiUrls } from '../../../../../../api/constants';

import WithLabel from '../../../../../../hoc/withLabel';

import AddForm from './AddForm';

const ResponsibleParty = (props) => {
  const {
    labels, parentParams, baseRoute, queryString, self, showInActiveInsuranceData, formName,
  } = props;

  const [form] = AntForm.useForm();

  const { params, generatePath, push } = useRedirect();

  const { id } = params;

  const [
    insuranceData,,
    insuranceLoading,
    requestInsuranceData,
  ] = useCRUD({
    id: 'patient-insurance-rp', url: apiUrls.GET_PATIENT_INSURANCE, type: 'read',
  });

  const onRequestComplete = ({ response }) => {
    if (response && response.insuranceProfileId) {
      Notification({
        message: SuccessMessages.INSURANCE_PROFILE_ADDED_SUCCESSFULLY, success: true,
      });
      requestInsuranceData({ PatientId: id, includeinactive: showInActiveInsuranceData });
      if (response.insuranceProfileType === 'Self') {
        push({
          pathname: generatePath(`${baseRoute}/rp/view/:rpId`, {
            ...params, rpId: response.InsuranceProfileType,
          }),
          search: queryString,
        });
      } else {
        push({ pathname: generatePath(`${baseRoute}/profile/view/:profileId`, { ...params, profileId: response.insuranceProfileId }), search: queryString });
      }
    }
  };

  const onCancel = useCallback(() => {
    if (insuranceData && insuranceData.length) {
      const defaultProfile = insuranceData.filter((data) => data.isDefault);
      if (defaultProfile && defaultProfile.length) {
        push(
          {
            pathname: generatePath(`${baseRoute}/profile/view/:profileId`,
              { ...parentParams, profileId: defaultProfile[0].insuranceProfileId }),
            search: queryString,
          },
        );
      } else {
        push({
          pathname: generatePath(baseRoute, { ...params }),
          search: queryString,
        });
      }
    } else {
      push({
        pathname: generatePath(baseRoute, { ...params }),
        search: queryString,
      });
    }
  }, [insuranceData, push, generatePath, baseRoute, parentParams, queryString, params]);

  if (insuranceLoading) return <Loader />;

  return (
    <AddForm
      form={form}
      onRequestComplete={onRequestComplete}
      labels={labels}
      onCancel={onCancel}
      self={self}
      formName={formName}
    />
  );
};

export default WithLabel(ResponsibleParty, labelPaths.EDIT_RESPONSIBLE_PARTY);
