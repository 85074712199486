import React, { useMemo } from 'react';

import { Tooltip } from 'antd';
import sortBy from 'lodash/sortBy';
import { apiUrls } from '../../../../../api/constants';

import withQuery from '../../../../../hoc/withQuery/withQuery';
import withReduxManager from '../../../../../hoc/withReduxManager';

import FilterManager from '../../../../../components/FilterManager';
import CheckBox from '../../../../../components/Checkbox';
import RenderMultipleSynonyms from '../../../../../components/RenderMultipleSynonyms';

const columns = (onICDCheckBoxClick, showCheckBox, onRowClick, labels) => {
  const icdColumns = [
    {
      Header: labels.get('labels.icdCode'),
      fixWidth: '100',
      accessor: 'icdCode',
      Cell: ({ row: { original } }) => (
        <div
          role="presentation"
          onClick={() => onRowClick(original)}
        >
          {original?.icdCode}
        </div>
      ),
    },
    {
      Header: labels.get('labels.description'),
      accessor: 'description',
      Cell: ({ row: { original: { description, synonyms } } }) => (
        <Tooltip placement="topLeft" title={description}>
          <span className="flex flex-dir-row">
            {synonyms ? <RenderMultipleSynonyms synonyms={synonyms} /> : description}
          </span>
        </Tooltip>
      ),
    },
  ];
  if (showCheckBox) {
    icdColumns.unshift({
      Header: labels.get('labels.select'),
      accessor: 'selectAll',
      fixWidth: '50',
      Cell: ({ row: { original }, checkedICDCodes, disabled }) => (
        <CheckBox
          label=""
          labelSpan="0"
          inputSpan="2"
          id={original.id}
          checked={!!checkedICDCodes[original.id]}
          checkboxProps={{
            onChange: () => { onICDCheckBoxClick(original); },
          }}
          isFormItem={false}
          disabled={disabled}
        />
      ),
    });
  }
  return icdColumns;
};

const initialSort = [{ id: 'icdCode', desc: false }];

const ICDTable = ({
  labels, filters, onICDCheckBoxClick, checkedICDCodes, showCheckBox = true, onRowClick, noDataText = 'ICD code not found', sortDescription, ...props
}) => {
  const CustomICDTable = useMemo(() => withReduxManager({
    listId: apiUrls.GET_AS_SORTED_CPT_ICD_CODE_LIST,
  })(withQuery({
    url: apiUrls.GET_AS_SORTED_CPT_ICD_CODE_LIST,
    listId: apiUrls.GET_AS_SORTED_CPT_ICD_CODE_LIST,
    accessor: (data) => {
      const clonedResultData = Array.isArray(data) ? { result: data } : (data || {});

      if (sortDescription) {
        clonedResultData.result = sortBy(clonedResultData.result,
          (item) => (item.synonyms?.[0]?.toLowerCase() || item.description?.toLowerCase()));
      } else {
        clonedResultData.result = sortBy(clonedResultData.result, 'icdCode');
      }

      return clonedResultData;
    },
  })()), [sortDescription]);

  return (
    <CustomICDTable
      columns={columns(onICDCheckBoxClick, showCheckBox, onRowClick, labels)}
      filters={filters}
      checkedICDCodes={checkedICDCodes}
      initialSort={initialSort}
      noDataText={noDataText}
      {...props}
    >
      {({
        Component, initialFilters, onFiltersChange,
      }) => (
        <FilterManager initialFilters={initialFilters} onChange={onFiltersChange}>
          {() => Component}
        </FilterManager>
      )}
    </CustomICDTable>
  );
};

export default ICDTable;
