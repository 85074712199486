import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import findIndex from 'lodash/findIndex';
import moment from 'moment';
import debounce from 'lodash/debounce';

import { apiUrls } from '../../../../../../api/constants';

import Events from '../../../../../../lib/events';
import { crudSelector } from '../../../../../../lib/util';
import downloadFile from '../../../../../../lib/downloadFile';
import {
  dateFormatStartsWithYearSeconds,
  listId, responseType,
} from '../../../../../../lib/constants';

import useCRUD from '../../../../../../hooks/useCRUD';
import useRedirect from '../../../../../../hooks/useRedirect';

import Input from '../../../../../../components/Form/Input';
import Notification from '../../../../../../components/Notification';
import WidgetLoader from '../../../../../../components/WidgetLoader';
import Spinner from '../../../../../../components/Spinner';

import AddProcedure from '../../../../ProcedureHistory/EditProcedure';

const CommonTextBox = ({ onChange, text }) => (
  <Input.TextArea
    label=""
    name={null}
    labelSpan="0"
    inputSpan="24"
    autoSize={{ minRows: 4 }}
    onChange={onChange}
    value={text}
  />
);

const ShowComponent = ({
  onChange,
  text,
  addOption,
  editProcedureData,
  encounterId,
  setAddOption,
  procedureValue,
  showJCodeFields,
  setProcedureData,
  type,
  associatedJcodeDetails,
  isAutoSave,
  ...otherProps
}) => {
  if (addOption === 'procedure') {
    return (
      <AddProcedure
        procedureData={editProcedureData || {}}
        updateUrl={apiUrls.UPDATE_IN_OFFICE_PROCEDURE}
        extraUrl={`/${editProcedureData?.procedureDetailsId || editProcedureData?.patientProcedureCodeId}`}
        hideDiagnosis
        hideFavorites
        procedureValue={procedureValue}
        showJCodeFields={showJCodeFields}
        isAutoSave={isAutoSave}
        encounterId={encounterId}
        key={editProcedureData?.procedureDetailsId}
        isEditProcedure={false}
        setAutoSaveData={setProcedureData}
        hideProcedurePanel
        tabType={type}
        setAddOption={setAddOption}
        associatedJcodeDetails={associatedJcodeDetails}
        isAddFromOverview={false}
        {...otherProps}
      />
    );
  }
  return <CommonTextBox onChange={onChange} text={text} />;
};
const AddItems = ({
  addOption,
  selectedItem,
  setAddOption,
  editProcedureData,
  notesAdded,
  notesList,
  showJCodeFields,
  type,
  questionnaireData,
  ...otherProps
}) => {
  const [text, setText] = useState({});
  const [procedureValue, updateProceduresValue] = useState(0);
  const [procedureData, setProcedureData] = useState(editProcedureData);
  const isLoading = useSelector((state) => !!(crudSelector(state, listId.NEW_PATIENT_PROCEDURE, 'update')?.get('loading')));
  const [isAnpNotesAutoSave, setAnpNotesAutoSave] = useState(false);
  const [isAutoSave, setIsAutoSave] = useState(true);

  const { params: { patientId, encounterId: EncounterId, providerId } } = useRedirect();

  useEffect(() => {
    if (notesList?.length) {
      const index = findIndex(notesList,
        (note) => note?.noteType?.toLowerCase() === selectedItem?.title?.toLowerCase());
      if (index > -1) {
        setText({
          [selectedItem?.title?.toLowerCase()
        === 'notes' ? 'note' : selectedItem?.title?.toLowerCase()
          ]: notesList[index]?.note,
        });
      } else {
        setText({});
      }
    }
  }, [notesList, selectedItem]);

  const [
    saveResponse, ,
    saveLoading, saveNotes,
    clearSaveResponse] = useCRUD({ id: apiUrls.SAVE_AP_NOTES, url: apiUrls.SAVE_AP_NOTES, type: 'create' });
  const [
    downloadImagingFileResponse,,
    downloadImagingFileLoading,
    downloadImagingFile,
    clearDownloadImagingFile,
  ] = useCRUD({ id: 'download-imaging-file-add-item', url: apiUrls.DOWNLOAD_IMAGING, type: 'read' });

  useEffect(() => {
    if (downloadImagingFileResponse && !Array.isArray(downloadImagingFileResponse)) {
      downloadFile(downloadImagingFileResponse, 'imagingLab.pdf', 'application/pdf');
    }
    clearDownloadImagingFile(true);
  }, [clearDownloadImagingFile, downloadImagingFileResponse]);

  useEffect(() => {
    if (saveResponse && !isAnpNotesAutoSave) {
      Notification({ message: `${selectedItem?.title} added successfully.`, success: true });
      setText(null);
      setAddOption(null);
      clearSaveResponse();
      notesAdded();
    }
  }, [clearSaveResponse, saveResponse, setAddOption]);

  const debounced = useMemo(() => debounce(saveNotes, 1000), [saveNotes]);
  const onChange = useCallback((event) => {
    const { target: { value } } = event;
    setAnpNotesAutoSave(true);
    setText({ [addOption]: event?.target?.value });
    debounced({
      data: {
        patientId,
        encounterId: EncounterId,
        noteType: selectedItem?.title.toLowerCase(),
        Note: value?.trim(),
      },
    });
  }, [addOption, debounced, patientId, EncounterId, selectedItem]);

  const addTextHandler = useCallback(() => {
    setAnpNotesAutoSave(false);
    if (text[addOption]?.trim()?.length) {
      saveNotes({
        data: {
          patientId,
          EncounterId,
          noteType: selectedItem?.title.toLowerCase(),
          Note: text[addOption]?.trim(),
        },
      });
    }
  }, [EncounterId, addOption, patientId, saveNotes, selectedItem, text]);

  const updateProcedures = useCallback(() => {
    updateProceduresValue(procedureValue + 1);
  }, [procedureValue]);

  const downloadImaging = useCallback(() => {
    downloadImagingFile({
      cptId: editProcedureData?.cptCodeId,
      EncounterId,
      patientId,
      providerId,
      responseType: responseType.BLOB,
      ReportGenerationTime: moment().format(dateFormatStartsWithYearSeconds),
    });
  }, [EncounterId, downloadImagingFile, editProcedureData, patientId, providerId]);

  const deleteProcedure = useCallback(() => {
    if (selectedItem?.title.toLowerCase() === 'procedure') {
      Events.trigger('delete-procedure-with-groupId', editProcedureData);
    } else {
      const noteId = notesList?.find(
        (element) => element?.noteType?.toLowerCase() === selectedItem?.title?.toLowerCase(),
      )?.id;
      if (noteId) {
        Events.trigger('delete-notes', noteId);
      }
    }
  }, [editProcedureData, notesList, selectedItem]);

  const isProcedureFormFilled = useMemo(() => (
    ((procedureData?.modifier1
      || procedureData?.modifier2
      || procedureData?.modifier3
      || procedureData?.modifier4
    ) && procedureData?.procedureDescription) || procedureData?.results?.length
  ), [procedureData]);

  const onTickClick = useCallback(() => {
    if (selectedItem?.title === 'Procedure') {
      updateProcedures();
      setIsAutoSave(false);
    } else {
      addTextHandler();
    }
  }, [addTextHandler, selectedItem, updateProcedures]);

  const onCloseClickForNotes = useCallback(() => {
    setText(null);
    setAddOption(null);
    clearSaveResponse();
    notesAdded();
  }, [clearSaveResponse, notesAdded, setAddOption]);

  return (
    <div className="add-assessment-area">
      <div className="pannel back-pannel">
        <div className="pannel-heading justify-content-sp-bt">
          <p role="presentation">{selectedItem?.title}</p>
          <div className="actions">
            {editProcedureData?.source === 'ImagingLab' && (
              <span
                className="download-tree mr-rt-28"
                onClick={downloadImaging}
                aria-hidden="true"
              />
            )}
            <span
              data-testid="procedure-trash-icon"
              className={`procedure-trash-icon mr-rt-8 ${(!isProcedureFormFilled && addOption === 'procedure') ? 'no-cross-icon' : ''}`}
              onClick={deleteProcedure}
              aria-hidden="true"
            />
            {isProcedureFormFilled && <span className="close" role="presentation" onClick={() => setAddOption(null)} />}
            {addOption !== 'procedure' && <span className="close" role="presentation" onClick={onCloseClickForNotes} />}
            <span className="tick-with-spinner">
              {isLoading || saveLoading ? <Spinner size="small" /> : (
                <span
                  className="tick"
                  role="presentation"
                  onClick={onTickClick}
                />
              )}

            </span>
          </div>
        </div>
        <div className="pannel-body posi-relative">
          {((saveLoading && !isAnpNotesAutoSave) || downloadImagingFileLoading) && <WidgetLoader />}
          <ShowComponent
            onChange={onChange}
            text={text[addOption]}
            addOption={addOption}
            editProcedureData={editProcedureData}
            encounterId={EncounterId}
            setAddOption={setAddOption}
            procedureValue={procedureValue}
            showJCodeFields={showJCodeFields}
            setProcedureData={setProcedureData}
            type={type}
            associatedJcodeDetails={questionnaireData}
            isAutoSave={isAutoSave}
            {...otherProps}
          />
        </div>
      </div>
    </div>
  );
};
export default AddItems;
