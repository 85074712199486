import React from 'react';

import WithLabel from '../../../../../hoc/withLabel';
import useRights from '../../../../../hooks/useRights';
import TabsComponent from '../../../../../components/Tabs';
import { labelPaths } from '../../../../../lib/constants';
import rights from '../../../../../lib/rights';

import Demographic from '../../../../Dashboard/Tabs/Demographic';
import Insurance from './Insurance';
import Charges from './Charges';
import Overview from './Overview';

const tabsList = [
  {
    id: 'overview',
    component: Overview,
    className: 'dashboard-icon sprite-img-2-before',
  },
  {
    id: 'demographic',
    component: Demographic,
    className: 'demographic-icon sprite-img-before',
  },
  {
    id: 'insurance',
    component: Insurance,
    className: 'insurance-icon sprite-img-before',
  },
  {
    id: 'charges',
    component: Charges,
    className: 'encounters-icon sprite-img-before',
  },
];

const Tabs = () => {
  const clonedTabs = [];
  const [isUpdatePatientAuthenticated] = useRights([rights.update_patient]);
  tabsList.forEach((tab) => {
    clonedTabs.push({ ...tab, name: tab.id });
  });
  return (
    <div className="top-tabs">
      <TabsComponent tabsList={clonedTabs} id="patients" className="main-tab-wrap" isUpdatePatientAuthenticated={isUpdatePatientAuthenticated} isFromCollection />
    </div>
  );
};
export default WithLabel(Tabs, labelPaths.EDIT_PATIENT);
