import React, { useMemo } from 'react';
import {
  Row, Col, Form as AntdForm,
} from 'antd';

import Input from '../../../../../../../../../../../../components/Form/Input';
import Form from '../../../../../../../../../../../../components/Form';
import Panel from '../../../../../../../../../../../../components/Panel';
import SMCheckBox from '../../../../../../../../../../../../components/SmCheckbox';
import Select from '../../../../../../../../../../../../components/Form/SelectBox';

import WiredSelect from '../../../../../../../../../../../../wiredComponents/Select';

import {
  formId, labelPaths,
} from '../../../../../../../../../../../../lib/constants';
import { apiUrls } from '../../../../../../../../../../../../api/constants';
import WithLabel from '../../../../../../../../../../../../hoc/withLabel';
import { getString } from '../../../../../../../../../../../../lib/util';

const FollowUpNonEditable = ({
  labels, disabledAll = false, followUpData, id,
}) => {
  const [form] = AntdForm.useForm();

  const { options, initialData } = useMemo(() => {
    if (followUpData && followUpData?.constructor?.name === 'Object') {
      const reducedOptionsWithSelectedValues = followUpData?.
      procedurePlanned?.reduce((acc, item) => {
        acc.selectOptions.push({
          name: getString([item?.cptCode,
          item?.procedureDescription], ' - '),
          value: item?.cptCode,
        });
        acc.selectedValues.push(item?.cptCode);
        return acc;
      }, { selectOptions: [], selectedValues: [] });
      return {
        options: reducedOptionsWithSelectedValues?.selectOptions || [],
        initialData: {
          ...followUpData,
          procedureIds: reducedOptionsWithSelectedValues?.selectedValues || [],
        },
      };
    }
    return {
      options: [],
      initialData: {},
    };
  }, [followUpData, id]);

  return (
    <div>
      <Form
        formId={`${formId.DOCTOR_FOLLOW_UP_FORM}-${id}`}
        form={form}
        name={`${formId.DOCTOR_FOLLOW_UP_FORM}-${id}`}
        initialData={initialData}
        disableForm={disabledAll}
      >
        <Panel title="Follow up" showCloseIcon={false} className="follow-up">
          <Row className="follow-up-label">
            <Col>
              <span className="item-label">{labels.get('titles.followUp')}</span>
            </Col>
            <Col span={5} className="space">
              <Input
                label={labels.get('labels.year')}
                placeholder="xx"
                labelAfter
                name="year"
                labelSpan="10"
                inputSpan="11"
                numberWithHyphen
                maxValueLength={2}
              />
            </Col>
            <Col span={5}>
              <Input
                label={labels.get('labels.months')}
                placeholder="xx"
                maxValueLength={2}
                labelAfter
                name="month"
                labelSpan="11"
                inputSpan="11"
                numberWithHyphen
              />
            </Col>
            <Col span={5}>
              <Input
                label={labels.get('labels.weeks')}
                placeholder="xxx"
                maxValueLength={2}
                labelAfter
                name="week"
                labelSpan="11"
                inputSpan="11"
                numberWithHyphen
              />
            </Col>
            <Col span={5}>
              <Input
                label={labels.get('labels.days')}
                placeholder="xxx"
                maxValueLength={3}
                labelAfter
                name="days"
                labelSpan="11"
                inputSpan="11"
                numberWithHyphen
              />
            </Col>
          </Row>
          <div className="followup-row">
            <div className="row-1">
              <div className="column-1">
                <WiredSelect
                  label={labels.get('labels.visitTypePlanned')}
                  name="visitTypeId"
                  id="visit-type-follow-up"
                  labelSpan="10"
                  inputSpan="14"
                  url={apiUrls.GET_DOCTOR_FOLLOW_UP_VISIT_TYPE}
                  valueAccessor="visitTypeId"
                  nameAccessor="visitName"
                />
              </div>
              {/* <div className="column-2">
                <SMCheckBox
                  className="labelLeft"
                  name="dilation"
                >
                  {labels.get('labels.dilation')}
                </SMCheckBox>
                <SMCheckBox
                  className="labelLeft"
                  name="refract"
                >
                  {labels.get('labels.refract')}
                </SMCheckBox>
              </div> */}
            </div>
          </div>
          <div className="followup-row">
            <div className="row-2">
              <Select
                label="Authorizations for next visit"
                name="procedureIds"
                id="9"
                labelSpan="10"
                inputSpan="14"
                options={options}
                selectProps={{
                  mode: 'multiple',
                  maxTagCount: 'responsive',
                  showArrow: true,
                }}
              />
            </div>
          </div>
          <div className="follow-up-check-box">
            <SMCheckBox
              className="labelLeft"
              name="dilation"
            >
              {labels.get('labels.dilation')}
            </SMCheckBox>
            <SMCheckBox
              className="labelLeft"
              name="refract"
            >
              {labels.get('labels.refract')}
            </SMCheckBox>
          </div>
          <div className="followup-row">
            <div className="row-2">
              <Input.TextArea
                name="notes"
                label="Notes for front desk"
                labelSpan="10"
                inputSpan="14"
                maxValueLength={1000}
                rows={4}
                placeholder="Notes"
              />
            </div>
          </div>
        </Panel>
      </Form>
    </div>
  );
};

export default React.memo(WithLabel(FollowUpNonEditable, labelPaths.DOCTOR_FOLLOW_UP));
