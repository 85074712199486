import { useEffect } from 'react';
import Events from '../../../../lib/events';

const TableWrapper = ({
  Component, reFetch,
}) => {
  useEffect(() => {
    Events.on('reFetchCPTICDTable', 'reFetchCPTICDTable', reFetch);
    return () => Events.remove('reFetchCPTICDTable');
  }, []);
  return Component;
};

export default TableWrapper;
