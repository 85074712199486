import CommonColumns from './commonColumns';

const insurancePaymentColumn = (labels) => [
  ...CommonColumns(labels),
  {
    Header: labels.get('tableColumns.withhold'),
    accessor: 'withHold',
    Cell: ({ row: { original: { withHold } } }) => (
      parseFloat(withHold || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.adjusted'),
    accessor: 'adjustment',
    Cell: ({ row: { original: { adjustment } } }) => (
      parseFloat(adjustment || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.insuranceBalance'),
    accessor: 'insuranceBalance',
    Cell: ({
      row: {
        original: {
          isPatientResponsibility, balance,
        },
      },
    }) => (
      !isPatientResponsibility ? parseFloat(balance || 0).toFixed(2) : parseFloat(0).toFixed(2)
    ),
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.patientBalance'),
    accessor: 'patientBalance',
    Cell: ({
      row: {
        original: {
          isPatientResponsibility, patientBalance, balance,
        },
      },
    }) => (!isPatientResponsibility
      ? parseFloat(patientBalance || 0).toFixed(2) : parseFloat(balance || 0).toFixed(2)),
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.enteredBy'),
    accessor: 'enteredBy',
    className: 'text-align-left',
  },
  {
    Header: labels.get('tableColumns.payer'),
    accessor: 'payer',
    className: 'text-align-left',
    Cell: ({ row: { original: { financeDetails } } }) => financeDetails?.payerName ?? '',
  },
  {
    Header: labels.get('tableColumns.profileType'),
    accessor: 'profileType',
    className: 'text-align-left',
    Cell: ({ row: { original: { financeDetails } } }) => financeDetails?.profileType ?? '',

  },
];

export default insurancePaymentColumn;
