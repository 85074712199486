import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import { Form as AntdForm } from 'antd';

import findIndex from 'lodash/findIndex';
import { apiUrls } from '../../../../../api/constants';
import useCRUD from '../../../../../hooks/useCRUD';
import Events from '../../../../../lib/events';
import SuccessMessages from '../../../../../lib/successMessages';
import iconNames from '../../../../../lib/iconNames';

import Button from '../../../../../components/Button';
import SelectBox from '../../../../../components/SelectBox';
import Notification from '../../../../../components/Notification';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import Icon from '../../../../../components/Icon';
import Form from '../../../../../components/Form';

import RenameTemplateModal from './RenameTemplateModal';

const FooterMenu = ({
  visible,
  onSelect,
  toggleSaveModal,
  labels,
  setFooterHTML,
  savedHeaderFooterId,
  setSavedHeaderFooterId,
}) => {
  const [parsedFooterList, setParsedFooterList] = useState([]);
  const [isRenameModalVisible, setRenameModalVisibility] = useState(false);
  const [footerTemplateData, setFooterTemplateData] = useState(null);
  const [form] = AntdForm.useForm();

  const toggleRenameModal = useCallback(() => {
    setRenameModalVisibility(!isRenameModalVisible);
  }, [isRenameModalVisible]);

  const [
    footerList,,
    footerListLoading,
    getFooterList,
  ] = useCRUD({
    id: 'get-template-footer-list',
    url: apiUrls.GET_TEMPLATE_HEADER_FOOTER_LIST,
    type: 'read',
  });

  const [
    deleteTemplateResponse,,,
    deleteHeaderFooterTemplate,
    clearDeleteResponse,
  ] = useCRUD({
    id: 'delete-selected-template-footer',
    url: apiUrls.DELETE_HEADER_FOOTER_TEMPLATE,
    type: 'delete',
  });

  useEffect(() => {
    if (deleteTemplateResponse) {
      Notification({ message: SuccessMessages.TEMPLATE_DELETED_SUCCESSFULLY, success: true });
      Events.trigger('reFetchFooterList');
      form.resetFields();
      setSavedHeaderFooterId({ ...savedHeaderFooterId, footer: null });
      clearDeleteResponse();
      setFooterTemplateData(null);
      if (setFooterHTML) {
        setFooterHTML('');
      }
    }
  }, [clearDeleteResponse, deleteTemplateResponse, savedHeaderFooterId, form]);

  useEffect(() => {
    getFooterList({}, '/footer');
  }, [getFooterList]);

  useEffect(() => {
    if (footerList) {
      setParsedFooterList(footerList?.map((item) => ({
        ...item,
        name: item?.name,
        value: item?.composerTemplateHeaderFooterId,
      })));
    }
  }, [footerList]);

  useEffect(() => {
    if (savedHeaderFooterId?.footer && !footerListLoading) {
      form.setFieldsValue({ footerSelect: savedHeaderFooterId?.footer });
      const index = findIndex(parsedFooterList,
        // eslint-disable-next-line max-len
        (headerOption) => headerOption?.composerTemplateHeaderFooterId === savedHeaderFooterId?.footer);
      setFooterTemplateData(parsedFooterList[index] || {});
    }
  }, [form, parsedFooterList, savedHeaderFooterId, footerListLoading]);

  useEffect(() => {
    Events.on('reFetchFooterList', 'reFetchFooterList', () => getFooterList({}, '/footer'));
    return () => Events.remove('reFetchFooterList');
  }, []);

  const onChangeTemplate = useCallback((value, data) => {
    setFooterTemplateData(data?.item);
  }, []);

  const handleDelete = useCallback(() => {
    ConfirmDialog({
      onOk: (close) => {
        deleteHeaderFooterTemplate({}, `/Footer/${footerTemplateData?.composerTemplateHeaderFooterId}`);
        close();
      },
      okText: labels.get('buttons.delete'),
      title: labels.get('titles.warning'),
      content: labels.get('message.warningMessage'),
      icon: <Icon name="ExclamationCircleOutlined" />,
    })();
  }, [deleteHeaderFooterTemplate, footerTemplateData, labels]);

  return (
    <>
      <div className={classNames('extra-options-container footer-options-container', { visible })}>
        <Form form={form}>
          <SelectBox
            name="footerSelect"
            placeholder="Load Previous"
            onChange={onChangeTemplate}
            selectProps={{
              loading: footerListLoading,
              onSelect,
              allowClear: false,
            }}
            options={parsedFooterList}
          />
        </Form>
        <Button className="btn btn-success sm-btn" type="submit" onClick={() => toggleSaveModal('footer')}>Save Footer</Button>
        {footerTemplateData && (
          <>
            <Button className="btn sm-btn mr-lt-10" onClick={toggleRenameModal}>
              <Icon className="cursor-pointer" name={iconNames.edit} />
            </Button>
            <Button className="btn sm-btn mr-lt-10" onClick={handleDelete}>
              <Icon className="cursor-pointer" name={iconNames.deleteIcon} />
            </Button>
          </>
        )}
      </div>
      <RenameTemplateModal
        sectionName="Footer"
        isVisible={isRenameModalVisible}
        toggleModal={toggleRenameModal}
        labels={labels}
        selectedTemplate={footerTemplateData}
        reFetchEvent="reFetchFooterList"
      />
    </>
  );
};

export default FooterMenu;
