import React, { useCallback, useState, useMemo } from 'react';
import { Form as AntdForm } from 'antd';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../../components/Button';
import Modals from '../../components/Modal';
// eslint-disable-next-line import/no-cycle
import AddSynonymForm from './AddSynonymForm';
import useRedirect from '../../hooks/useRedirect';
import Input from '../../components/Form/Input';
import Form from '../../components/Form';

import './synonym.scss';

const AddSynonym = ({
  labels,
  buttonsText,
  buttonComponent: ButtonComponent,
  titleOfModal,
  selectedCode,
  showIcdLookup,
  isIcdSynonym,
  formTitleText,
  showCptLookup,
}) => {
  const [form] = AntdForm.useForm();
  const [isModalVisible, setModalVisibility] = useState(false);
  const { params: { providerId } } = useRedirect();

  const toggleAddModal = useCallback(() => {
    setModalVisibility(!isModalVisible);
  }, [isModalVisible]);

  const extraDataProps = useMemo(() => {
    if (isIcdSynonym) {
      return {
        providerId,
        icdId: selectedCode.id,
      };
    }
    return { providerId, cptId: selectedCode?.id };
  }, [isIcdSynonym, providerId, selectedCode]);

  return (
    <>
      <ButtonComponent
        className={classNames('min-wt-86 sm-btn btn',
          { 'btn-success': Object.keys(selectedCode).length, 'cursor-not-allowed': !(Object.keys(selectedCode).length || undefined) })}
        disabled={!Object.keys(selectedCode).length}
        onClick={toggleAddModal}
      >
        {labels.get(`buttons.${buttonsText}`)}
      </ButtonComponent>
      <Modals
        visible={isModalVisible}
        toggleModal={toggleAddModal}
        width={800}
        destroyOnClose
        className="schedular-patient-modal"
        footer={[
          <div className="group-btns" key="footer">
            <Button className="btn min-wt-86" onClick={toggleAddModal}>{labels.get('buttons.cancel')}</Button>
            <Button className="btn btn-success min-wt-86 inline" onClick={form.submit}>{labels.get('buttons.save')}</Button>
          </div>,
        ]}
      >
        <div className="addition-header">
          <div className="lookup sprite-img-before">
            <p>{labels.get(`titles.${titleOfModal}`)}</p>
          </div>
        </div>
        <div className="modal-content-weapper shadow-wrap">
          <div className="modal-from-feilds">
            <p className="add-synonym-heading">
              {formTitleText}
            </p>
            <Form.Column>
              <Input
                name={null}
                label="Code"
                value={selectedCode?.code}
                labelSpan="8"
                inputSpan="16"
                className="non-editable"
                disabled
              />
              <Input
                name={null}
                label="Description"
                value={selectedCode?.description}
                inputSpan="16"
                labelSpan="8"
                className="non-editable"
                disabled
              />
            </Form.Column>
            <AddSynonymForm
              labels={labels}
              form={form}
              toggleModal={toggleAddModal}
              selectedCode={selectedCode}
              showIcdLookup={showIcdLookup}
              showCptLookup={showCptLookup}
              extraDataProps={extraDataProps}
            />
          </div>
        </div>
      </Modals>
    </>
  );
};

AddSynonym.defaultProps = {
  selectedCode: {},
  buttonComponent: Button,
};

AddSynonym.propTypes = {
  selectedCode: PropTypes.instanceOf(Object),
  buttonComponent: PropTypes.func,
};

export default AddSynonym;
