import React, { useCallback, useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';

import WithRights from '../../../hoc/withRights';
import useCRUD from '../../../hooks/useCRUD';
import useRights from '../../../hooks/useRights';
import useRedirect from '../../../hooks/useRedirect';

import rights from '../../../lib/rights';
import { UiRoutes } from '../../../lib/constants';
import iconNames from '../../../lib/iconNames';
import { apiUrls } from '../../../api/constants';

import Icon from '../../../components/Icon';
import Button from '../../../components/Button';
import TextInput from '../../../components/Form/Input';
import WidgetLoader from '../../../components/WidgetLoader';
import Notification from '../../../components/Notification';
import ConfirmDialog from '../../../components/ConfirmDialog';

import UserRoleLocationTable from './Components/UserRoleLocationTable';

import Rights from '../Users/EditUser/Rights';
import './userroles.scss';

const UserRoleCreation = () => {
  const [newUserRoleName, setNewUserRoleName] = useState();
  const [userRights, setRights] = useState({});
  const { location, generatePath, push } = useRedirect();

  const [
    isUserRightsViewOnly,
    isUserRightsAuthenticated,
    isUserRolesAuthenticated,
  ] = useRights([
    rights.user_rights_view_only,
    rights.manage_rights,
    rights.manage_roles,
  ]);

  const [newUserRoleResponse,,
    newUserRoleLoading,
    newUserRole,
    clearNewUserRoleResponse,
  ] = useCRUD({
    id: `${apiUrls.USER_RIGHTS_DATA}/addNewResponse`, url: apiUrls.USER_RIGHTS_DATA, type: 'create',
  });

  const [userRightsUpdate,,
    updateUserRightsLoading,
    updateUserRights,
    clearUpdateResponse] = useCRUD({
    id: `${apiUrls.USER_RIGHTS_DATA}/updateResponse`, url: apiUrls.USER_RIGHTS_DATA, type: 'update',
  });

  const onNameChange = useCallback((event) => {
    setNewUserRoleName(event?.target?.value);
  }, []);

  const navigateBack = useCallback(() => {
    push(generatePath(UiRoutes.UserRoles));
  }, [generatePath, push]);

  useEffect(() => {
    if (userRightsUpdate && userRightsUpdate?.message) {
      Notification({ message: userRightsUpdate?.message, success: true });
      clearUpdateResponse();
    }
    if (newUserRoleResponse) {
      Notification({ message: newUserRoleResponse?.message, success: true });
      clearNewUserRoleResponse();
      navigateBack();
    }
  }, [userRightsUpdate, newUserRoleResponse]);

  const handleOnSave = useCallback(() => {
    if (location?.state?.permissionSetId) {
      ConfirmDialog({
        onOk: (close) => {
          updateUserRights({
            masterClaimsIds: Object.keys(userRights).map((item) => parseInt(item, 10)),
            roleId: location?.state?.permissionSetId,
          });
          close();
        },
        okText: 'Ok',
        title: 'Warning',
        content: 'If this child role is associated with user(s) then this update  will get updated for them as well , would you still like to continue?',
        icon: <Icon name={iconNames.exclamationCircleOutlined} />,
      })();
      return;
    }
    newUserRole({
      data: {
        roleName: newUserRoleName,
        masterClaimsIds: Object.keys(userRights).map((item) => parseInt(item, 10)),
      },
    });
  }, [location, updateUserRights, userRights, newUserRole, newUserRoleName]);

  return (
    <>
      <div className="heading-area flex justify-content-sp-bt align-center user-screen">
        <h2>User Role</h2>
      </div>
      <div className="user-role-wrapper">
        <div>
          <TextInput
            label="Name of role"
            placeholder="Please enter name of user role"
            name="roleName"
            required
            onBlur={onNameChange}
            inputProps={{ defaultValue: location?.state?.permissionSetName }}
            disabled={location?.state?.permissionSetName}
            labelSpan={8}
            inputSpan={16}
            maxValueLength={50}
          />
        </div>
        <div className="group-btns">
          <Button className="btn min-wt-86 sm-btn" onClick={navigateBack}>cancel</Button>
          <Button className="btn min-wt-86 btn-success sm-btn" onClick={handleOnSave}>{location?.state?.permissionSetId ? 'update' : 'save'}</Button>
        </div>
      </div>
      <div className="user-role-container">
        <div className="">
          {(newUserRoleLoading || updateUserRightsLoading) && <WidgetLoader />}
          <Rights
            isUserRightsViewOnly={isUserRightsViewOnly
               && (!isUserRightsAuthenticated && !isUserRolesAuthenticated)}
            isUserRole
            rights={userRights}
            setRights={setRights}
            permissionSetId={location?.state?.permissionSetId}
          />
        </div>
      </div>
    </>
  );
};

const UserRole = () => (
  <Switch>
    <Route path={[UiRoutes.UserRolesAdd, UiRoutes.UserRolesEdit]}>
      <UserRoleCreation />
    </Route>
    <Route path={UiRoutes.UserRoles} exact>
      <UserRoleLocationTable />
    </Route>
  </Switch>
);

export default WithRights(UserRole, [
  rights.read_rights,
  rights.read_roles,
  rights.manage_rights,
  rights.manage_roles,
  rights.user_rights_view_only,
]);
