import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { UiRoutes, labelPaths } from '../../../lib/constants';

import WithLabel from '../../../hoc/withLabel';

import SavedNavigationBar from '../../../components/SavedNavigationBar';

import Tabs from './Tabs';

const EditContract = ({ labels }) => (
  <SavedNavigationBar
    listName={labels.get('navigationTabTitle.contractsList')}
    parentPath={UiRoutes.contract}
    title={(<h1>Contracts List</h1>)}
  >
    <Switch>
      {/* <Route path={UiRoutes.addContractTermsWithTabId}>
        <AddTerms />
      </Route> */}
      {/* <Route path={UiRoutes.editContractTermsWithTabId}>
        <EditTerms />
      </Route> */}
      <Route>
        <div className="main-form-container">
          <Tabs />
        </div>
      </Route>
    </Switch>
  </SavedNavigationBar>
);

export default WithLabel(EditContract, labelPaths.EDIT_CONTRACT);
