import React, { useCallback, useEffect, useMemo } from 'react';

import { apiUrls } from '../../../../api/constants';

import useCRUD from '../../../../hooks/useCRUD';
import Events from '../../../../lib/events';

import Button from '../../../../components/Button';
import Notification from '../../../../components/Notification';

import SuccessMessages from '../../../../lib/successMessages';

const MarkAsClosed = ({
  selectedTask, labels, setSelectedTasks, table,
}) => {
  const [closeResponse, ,, markAsClosedUnread, clearCloseResponse] = useCRUD({ id: 'mark-as-closed', url: apiUrls.MARK_AS_CLOSED, type: 'update' });

  const taskIds = useMemo(() => {
    if (selectedTask && selectedTask.length) {
      return selectedTask.map(({ original }) => original.taskId);
    }
    return [];
  }, [selectedTask]);

  useEffect(() => {
    if (closeResponse) {
      Notification({
        message: SuccessMessages.TASK_CLOSED_SUCCESSFULLY, success: true,
      });
      clearCloseResponse();
      Events.trigger('refetchTaskManagementList');
      setSelectedTasks([]);
      table.resetFields();
    }
  }, [closeResponse]);

  const handleMarkAsClosed = useCallback(() => {
    if (taskIds?.length) {
      const data = { taskIds };
      markAsClosedUnread(data);
    }
  }, [markAsClosedUnread, taskIds]);

  return (
    <div className="btns-action mr-bt-12 ">
      <Button className="btn btn-success min-wt-86 sm-btn" onClick={handleMarkAsClosed}>{labels.get('buttons.closed')}</Button>
    </div>
  );
};

export default MarkAsClosed;
