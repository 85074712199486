import React from 'react';

import { apiUrls } from '../../../api/constants';
import { listId } from '../../../lib/constants';

import withQuery from '../../../hoc/withQuery/withQuery';
import withReduxManager from '../../../hoc/withReduxManager';

import FilterManager from '../../../components/FilterManager';
import FilterComponents from '../../../components/FilterComponents';

import columns from './columns';

const initialSort = [{ id: 'FirstName', desc: false }];

const CustomPatientTable = withReduxManager({
  listId: listId.PATIENTS,
})(withQuery({
  url: apiUrls.GET_PATIENT,
  listId: listId.PATIENTS,
  alias: {
    firstName: 'FirstName',
    nextAppDate: 'NextAppDate',
    lastVisitedDate: 'LastVisitedDate',
    chartNumber: 'ChartNumber',
    patientBal: 'PatientBal',
    insuranceBal: 'InsuranceBal',
  },
  pageSize: 30,
})());

const statusOptions = [{
  name: 'Active',
  value: 'ExcludeInactivePatients',
  color: 'green',
}, {
  name: 'Inactive',
  value: 'InactivePatients',
  color: 'red',
}, {
  name: 'Do Not Contact',
  value: 'DoNotContact',
  color: 'geekblue',
}];

const FilterCollection = FilterComponents([
  {
    type: 'filterButton',
    filterProps: {
      placeholder: 'Recently Viewed',
      name: 'RecentlyViewed',
      id: 'patients_recently_viewed',
    },
  },
  {
    type: 'filterButton',
    filterProps: {
      placeholder: 'Recently Created',
      name: 'RecentlyCreated',
      id: 'patients_recently_created',
    },
  },
  // {
  //   type: 'filterButton',
  //   filterProps: {
  //     placeholder: 'Exclude Inactive Patients',
  //     name: 'ExcludeInactivePatients',
  //     id: 'patient_filter_inactive',
  //   },
  // },
  {
    type: 'search',
    filterProps: {
      placeholder: 'Search',
      name: 'SearchText',
      id: 'patients_filter_search',
    },
  },
  {
    type: 'checkbox',
    filterProps: {
      name: 'ExcludeInactivePatients',
      id: 'exclude_inactive_patients',
      label: 'Active',
    },
  },
  {
    type: 'checkbox',
    filterProps: {
      name: 'InactivePatients',
      id: 'inactive',
      label: 'Inactive',
    },
  },
  {
    type: 'checkbox',
    filterProps: {
      name: 'DoNotContact',
      id: 'do_not_contact',
      label: 'Do Not Contact',
    },
  },
]);

const PatientTable = ({ labels, ...props }) => (
  <CustomPatientTable initialFilters={{ ExcludeInactivePatients: true }} columns={columns(labels)} initialSort={initialSort} noDataText="Patient not found" statusOptions={statusOptions} {...props}>
    {({
      Component, initialFilters, onFiltersChange, totalCount,
    }) => (
      <FilterManager initialFilters={initialFilters} onChange={onFiltersChange}>
        {({ onFilterChange, filters }) => (
          <>
            <div className="table-filters" data-testid="patient-table-filters">
              <FilterCollection onFilterChange={onFilterChange} filters={filters} allowClear />
              <span className="table-count">
                <span>{`${labels.get('titles.totalCount')}: ${totalCount}`}</span>
              </span>
            </div>
            {Component}
          </>
        )}
      </FilterManager>
    )}
  </CustomPatientTable>
);

export default PatientTable;
