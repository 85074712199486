import React, { useState, useCallback } from 'react';

import TabLeft from '../../../components/TabLeft';

import Diagnosis from './Diagnosis';
import Balance from './Balance';
import Cases from './Cases';
import Demographic from './Demographic';
import Authorization from './Authorization';
import History from './History';
import AnesthesiaBilling from './AnesthesiaBilling';

const tabsList = [
  {
    name: '',
    toolTipTitle: 'Diagnosis',
    component: Diagnosis,
    key: '1',
    iconClassName: 'diagnosis-icon sprite-img-before',
  },
  {
    name: '',
    toolTipTitle: 'History',
    component: History,
    key: '2',
    iconClassName: 'history-icon sprite-img-before',
  },
  {
    name: '',
    toolTipTitle: 'Cases',
    component: Cases,
    key: '3',
    iconClassName: 'cases-icon sprite-img-before',
  },
  {
    name: '',
    toolTipTitle: 'Authorization',
    component: Authorization,
    key: '4',
    iconClassName: 'doctor-icon sprite-img-before',
  },
  {
    name: '',
    toolTipTitle: 'Balances',
    component: Balance,
    key: '5',
    iconClassName: 'balance-icon sprite-img-before',
  },
  {
    name: '',
    toolTipTitle: 'Demographic',
    component: Demographic,
    key: '6',
    iconClassName: 'demographic-icon sprite-img-before',
  },
  {
    name: '',
    toolTipTitle: 'Anesthesia billing',
    component: AnesthesiaBilling,
    key: '7',
    iconClassName: 'anesthesia-icon sprite-img-next-before',
  },
];

const EncounterTabs = ({
  setDiagnosesCodes, diagnosesCodes, anesthesia, handleParentDiagonsisChange, isCollectionCharges,
  setAnesthesia, encounterId, handleAnesthesiaAutoSave, loading, patientDateOfBirth, lineItemsData,
  claimFilter,
}) => {
  const [accordianOpen, setaccordianOpen] = useState(false);

  const toggleAccordian = useCallback(() => {
    setaccordianOpen(!accordianOpen);
  }, [accordianOpen]);

  return (
    <div className={accordianOpen ? 'left-tab-section active' : 'left-tab-section'}>
      <p className="accordian-title">Title text</p>
      <TabLeft
        tabList={tabsList}
        setDiagnosesCodes={setDiagnosesCodes}
        diagnosesCodes={diagnosesCodes}
        handleParentDiagonsisChange={handleParentDiagonsisChange}
        showToolTip
        anesthesia={anesthesia}
        setAnesthesia={setAnesthesia}
        encounterId={encounterId}
        handleAnesthesiaAutoSave={handleAnesthesiaAutoSave}
        loading={loading}
        patientDateOfBirth={patientDateOfBirth}
        isCollectionCharges={isCollectionCharges}
        lineItemsData={lineItemsData}
        isDisabled={isCollectionCharges && claimFilter === 'completed'}
      />
      <div className="accordian-btn" onClick={toggleAccordian} role="presentation" />
    </div>
  );
};

export default EncounterTabs;
