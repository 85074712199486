import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BillingList from './BillingList';
import useRedirect from '../../../../../../hooks/useRedirect';
import AddBilling from './AddBilling';
import EditBilling from './EditBilling';

import withLabel from '../../../../../../hoc/withLabel';
import { labelPaths } from '../../../../../../lib/constants';

function Billing({ labels, isProviderViewOnly }) {
  const { path } = useRedirect();
  return (
    <Switch>
      <Route exact path={path}>
        <BillingList labels={labels} isProviderViewOnly={isProviderViewOnly} />
      </Route>
      <Route path={`${path}/new`}>
        <div className="mr-top-20">
          <AddBilling labels={labels} />
        </div>
      </Route>
      <Route path={`${path}/edit/:billingId`}>
        <EditBilling labels={labels} isProviderViewOnly={isProviderViewOnly} />
      </Route>
    </Switch>
  );
}

export default withLabel(Billing, labelPaths.EDIT_PROVIDER_BILLING);
