import React, { useState, useCallback } from 'react';
import TabLeft from '../../../../../components/TabLeft';
import FollowUp from '../../../../Doctor/Components/FollowUp';
import ExamFind from './Tabs/ExamFind';
import ProcedureDx from './Tabs/ProcedureDx';
import OrderSet from '../../../../Doctor/Components/PatientDetails/Components/Tabs/AP/RightTab/Tabs/OrderSets';
import Input from '../../../../../components/Form/Input';
import Radio from '../../../../../components/Form/Radio';
import Button from '../../../../../components/Button';
import './orderset.scss';

const radioOptions = [{ name: 'practice', value: 'practice', label: 'Practice' }, { name: 'provider', value: 'provider', label: 'Provider' }];

const ManageOrderSet = () => {
  const [createOrderValue, setCreateOrderValue] = useState(true);
  const [procedureSetsList, setProcedureSetsList] = useState([]);
  const createOderSet = useCallback(() => {
    setCreateOrderValue(!createOrderValue);
  }, [createOrderValue]);
  const tabList = [
    {
      name: 'Procedure and Dx',
      component: ProcedureDx,
      key: '1',
    },
    {
      name: 'Exam findings',
      component: ExamFind,
      key: '2',
    },
    {
      name: 'Followup',
      component: FollowUp,
      key: '3',
    },
  ];
  return (
    <>
      <div>
        { createOrderValue
          ? (
            <OrderSet
              setProcedureSetsList={setProcedureSetsList}
              procedureSetsList={procedureSetsList}
            />
          ) : (
            <div className="create-order-set-wrap">
              <div className="header-options">
                <Input name="favouriteName" label="Order Set Name" labelSpan={8} inputSpan={16} />
                <Radio
                  options={radioOptions}
                  labelSpan={0}
                  inputSpan={24}
                  label=""
                  valuePropName="checked"
                />
              </div>
              <div className="order-set-inn-wrap">
                <TabLeft
                  tabList={tabList}
                />
              </div>
            </div>
          )}
        {/* this weill be remove */}
        <Button onClick={createOderSet} className="btn-success sm-btn on-top-for-now">Create</Button>
        {/* this weill be remove */}
      </div>
      <div className="save-cancel-wrap">
        <Button className="btn-outline sm-btn">Cancel</Button>
        <Button className="btn-success sm-btn"> Save</Button>
      </div>
    </>
  );
};

export default ManageOrderSet;
