import { Map } from 'immutable';

import {
  SET_SKETCHES,
} from '../actions/sketches';

const initialState = Map({

});

const actionsMap = {
  [SET_SKETCHES]: (state, { tabs }) => Map({ ...tabs }),
};

export default function sketches(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
