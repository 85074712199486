import React from 'react';
import { Radio, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import TimeCell from '../../../../../components/Cells/TimeCell';
import { getName } from '../../../../../lib/util';

const columns = () => [
  {
    accessor: 'checked',
    Cell: ({ row: { original: { patientId, zip } }, onSelect, selectedPatient }) => (
      <Radio
        checked={selectedPatient === patientId}
        labelSpan={0}
        inputSpan={24}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSelect(e, { item: { patientId, zip } });
        }}
      />
    ),
    fixWidth: 48,
  },
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({
      row: {
        original: {
          firstName, middleName, lastName,
        },
      },
    }) => (
      <span role="presentation" className="cursor-pointer">
        <Avatar className="list-avatar" size={28} icon={<UserOutlined />} />
        {getName(firstName, middleName, lastName)}
      </span>
    ),
    // sort: true,
  },
  {
    Header: 'DOB',
    accessor: 'dateOfBirth',
    Cell: ({ row: { original: { dateOfBirth } } }) => <TimeCell value={dateOfBirth?.split('T')?.[0]} />,
  },
  {
    Header: 'Current Doctor',
    accessor: 'lastProviderName',
  },
  {
    Header: 'Chart #',
    accessor: 'chartNumber',
    fixWidth: '80',
    // sort: true,
  },
];

export default columns;
