import React, { useCallback, useEffect, useState } from 'react';
import { Form as AntdForm } from 'antd';
import moment from 'moment';
import omit from 'lodash/omit';
import find from 'lodash/find';

import {
  enums, formId,
} from '../../../../../../lib/constants';
import { apiUrls } from '../../../../../../api/constants';
import useRedirect from '../../../../../../hooks/useRedirect';
import Events from '../../../../../../lib/events';
import SuccessMessages from '../../../../../../lib/successMessages';

import Form from '../../../../../../components/Form';
import Modals from '../../../../../../components/Modal';
import Button from '../../../../../../components/Button';
import Input from '../../../../../../components/Form/Input';
import Notification from '../../../../../../components/Notification';

import WiredSelect from '../../../../../../wiredComponents/Enum/Select';
import WiredSelectWithoutTab from '../../../../../../wiredComponents/Select/selectBoxV2';
import { isPageRange } from '../../../../../../lib/util';

const parseInitialData = (formData = {}) => omit({ ...formData }, ['pageNo']);

const WorkList = ({
  labels, isVisible, toggle, formData, navigateToPostedClaims, totalNoOfPages, location = undefined,
  isElectronicBatch, setNeedToScroll = () => {},
}) => {
  const [form] = AntdForm.useForm();
  const {
    params,
  } = useRedirect();
  const [defaultBusinessEntity, setDefaultBusinessEntity] = useState();

  useEffect(() => {
    if (defaultBusinessEntity) {
      form.setFieldsValue({
        businessEntityId: defaultBusinessEntity?.businessEntityId,
      });
    }
  }, [defaultBusinessEntity]);

  const handleSubmit = useCallback(() => {
    form.submit();
  }, [form]);

  const onRequestComplete = useCallback(() => {
    toggle();
    Notification({ success: true, message: SuccessMessages.WORKLIST_ADDED_SUCCESSFULLY });
    // Events.trigger(`set-page-range${params?.remittancePaymentId}`);
    Events.trigger(`refetch-view-entered-item-details${params?.remittancePaymentId}`);
    Events.trigger('refetchBatchDetails');
    setNeedToScroll(false);
    navigateToPostedClaims();
  }, [navigateToPostedClaims, params, setNeedToScroll, toggle]);

  const parser = (values) => {
    const clonedValues = { ...values, locationId: values.locationIdWorklist };
    delete clonedValues.locationIdWorklist;
    return clonedValues;
  };

  const fetchingComplete = useCallback((data) => {
    setDefaultBusinessEntity(find(data, { parentEntityId: null }));
  }, []);

  return (
    <Modals
      visible={isVisible}
      title={labels.get('titles.addToWorkList')}
      toggleModal={toggle}
      width={400}
      destroyOnClose
      footer={[
        <Button key="cancel_workList" onClick={toggle}>{labels.get('buttons.cancel')}</Button>,
        <Button key="add_workList" className="btn btn-success" onClick={handleSubmit}>{labels.get('buttons.add')}</Button>,
      ]}
    >
      <Form
        form={form}
        section
        formId={formId.REMITTANCE_INSURANCE_WORK_LIST}
        url={apiUrls.ADD_WORK_LIST}
        extraData={{
          batchId: params?.remittanceId,
          remittancePaymentId: params?.remittancePaymentId,
          checkDate: moment.utc(formData?.checkDate).local().format('MM-DD-YYYY hh:mm:ss a'),
          payerId: formData?.payerId,
        }}
        onRequestComplete={onRequestComplete}
        initialData={parseInitialData(formData)}
        parser={parser}
      >
        <WiredSelectWithoutTab
          required
          id="businessEntityId"
          label={labels.get('businessEntity')}
          url={apiUrls.GET_BUSINESS_ENTITY}
          name="businessEntityId"
          nameAccessor="entityName"
          valueAccessor="businessEntityId"
          onFetchComplete={fetchingComplete}
          selectProps={{
            showSearch: true,
            showArrow: true,
          }}
        />
        <div className="with-clear-wrapper">
          <WiredSelectWithoutTab
            id="provider"
            name="providerId"
            url={apiUrls.SCHEDULAR_PROVIDER_DROPDOWN}
            label={labels.get('provider')}
            placeholder="Select Provider"
            nameAccessor="providerName"
            valueAccessor="providerId"
            startCaseOptions
            selectProps={{
              maxTagCount: 1,
              style: { width: '100%', maxWidth: 215 },
              showSearch: true,
              dropdownMatchSelectWidth: 250,
              getPopupContainer: (trigger) => trigger?.parentNode,
            }}
            defaultSorting={false}
            dataTestId="providerId"
          />
        </div>
        <div className="with-clear-wrapper">
          <WiredSelectWithoutTab
            id="location"
            name="locationIdWorklist"
            url={apiUrls.SCHEDULAR_LOCATION_DROPDOWN}
            label={labels.get('location')}
            placeholder="Select Location"
            nameAccessor="locationName"
            valueAccessor="locationId"
            startCaseOptions
            selectProps={{
              size: 'middle',
              style: { width: '100%', maxWidth: 215 },
              showSearch: true,
              dropdownMatchSelectWidth: 250,
              getPopupContainer: (trigger) => trigger?.parentNode,
            }}
            initialValue={location}
            dataTestId="locationId"
          />
        </div>
        <WiredSelect
          required
          label={labels.get('reason')}
          enumName={enums.WORKLIST_REASONS}
          name="remittanceReasonId"
        />
        <Input
          required
          label={labels.get('amount')}
          name="amount"
          numberWithDecimal
          placeholder="Enter Number"
          rules={[
            ({ getFieldValue }) => ({
              validator() {
                if (parseFloat(getFieldValue('amount')) > parseFloat(formData?.checkAmount)) {
                  return Promise.reject(new Error(`Amount cannot be greater than ${formData?.checkAmount}`));
                }
                return Promise.resolve();
              },
            }),
          ]}
        />
        <Input
          alphaNumericOnly
          maxValueLength={50}
          required={formData?.paymentTypeId === 949}
          label={labels.get('checkNumber')}
          name="checkNumber"
          placeholder="Enter Check Number"
          disabled
        />
        <Input
          required={!isElectronicBatch}
          label={labels.get('eobPageNumber')}
          name="pageNumber"
          placeholder="Enter EOB Page Number"
          rules={[
            ({ getFieldValue }) => isPageRange(getFieldValue('pageNumber'), labels.get('eobPageNumber'), totalNoOfPages),
          ]}
        />
      </Form>
    </Modals>
  );
};
export default WorkList;
