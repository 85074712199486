import React, {
  useCallback, useState, useMemo, useEffect,
} from 'react';
import { Switch, Route } from 'react-router-dom';
import { Upload, Form as AntdForm } from 'antd';

import { apiUrls } from '../../../api/constants';
import SuccessMessages from '../../../lib/successMessages';
import {
  labelPaths, UiRoutes, contentType, responseType,
} from '../../../lib/constants';
import rights from '../../../lib/rights';

import useCRUD from '../../../hooks/useCRUD';
import useRedirect from '../../../hooks/useRedirect';
import useRights from '../../../hooks/useRights';
import WithRights from '../../../hoc/withRights';

import Notification from '../../../components/Notification';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';

import WithLabel from '../../../hoc/withLabel';
import downloadFile from '../../../lib/downloadFile';

import AddPayer from './AddPayer';
import EditPayer from './EditPayer';
import PayerTable from './PayerTable';
import CPTPayerMappingModal from './CPTPayerMappingModal';

import '../CPTICDCrosswalk/hierarchyTable.scss';
import { fileSizeCheck, getuuid } from '../../../lib/util';

const isPayerRequestViewOnly = false;

const Payer = ({ labels }) => {
  const [form] = AntdForm.useForm();

  const [isAddMappingModalVisible, setMappingModalVisible] = useState(false);
  const [selectedMapping, setSelectedMapping] = useState({});
  const [selectedPayer, setSelectedPayer] = useState({});
  const [selectedCPT, setSelectedCPT] = useState({});
  const [reFetch, setReFetch] = useState(null);
  const [isPayerAuthenticated] = useRights([rights.payer]);

  const [addCptIcdMappingResponse, , loading, addCptIcdMapping] = useCRUD({ id: 'cpt-icd-mapping-payer', url: apiUrls.ADD_CPT_ICD_MAPPING, type: 'create' });
  const [payerExportData, , , requestPayerExport, clearPayerExportData] = useCRUD({ id: 'export-insurance-payer', url: apiUrls.PAYER_EXPORT, type: 'read' });
  const [payerImportData, , , uploadFile, clearPayerImportData] = useCRUD({ id: 'import-insurance-payer', url: apiUrls.PAYER_IMPORT, type: 'create' });

  const cptIcdMappingId = useMemo(() => getuuid(), []);

  const { location, generatePath, push } = useRedirect();

  useEffect(() => {
    if (payerExportData) {
      downloadFile(payerExportData, 'ExportPayerList.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      clearPayerExportData(true);
    }
  }, [payerExportData, clearPayerExportData]);

  useEffect(() => {
    if (payerImportData) {
      Notification({ message: SuccessMessages.PAYER_IMPORTED_SUCCESSFULLY, success: true });
      clearPayerImportData(true);
    }
  }, [payerImportData]);

  const onAddMappingIconClick = useCallback((mappings, currentMapping, toggleRequired = true) => {
    setSelectedMapping(currentMapping);
    mappings.forEach((item) => {
      const { payer, cptCode, insurancePayerId } = item;
      if (payer && payer.insurancePayerId) {
        setSelectedPayer(item.payer);
      } else if (insurancePayerId) {
        setSelectedPayer(item);
      } else if (cptCode) {
        setSelectedCPT(item);
      }
    });
    if (toggleRequired) {
      setMappingModalVisible(!isAddMappingModalVisible);
    }
  }, [isAddMappingModalVisible]);

  const onSelectCPT = useCallback((_, allItems) => {
    const { item } = allItems;
    setSelectedCPT(item);
  }, []);

  const handleReFetch = useCallback((reFetchFunction) => {
    if (!reFetch) {
      setReFetch(() => reFetchFunction);
    }
  }, [reFetch, setReFetch]);

  const validateOnAddCptIcdMapping = useCallback((selectedICDCodes) => {
    let errMsg = false;
    if (!Object.keys(selectedICDCodes).length && !Object.keys(selectedCPT).length) {
      errMsg = 'Both CPT and ICD code are required';
    } else if (!Object.keys(selectedICDCodes).length) {
      errMsg = 'Please Select at least one ICD Code';
    } else if (!Object.keys(selectedCPT).length) {
      errMsg = 'Please Select CPT';
    }
    return errMsg;
  }, [selectedCPT]);

  const handleMappingUpdate = useCallback((selectedICDCodes, setSelectedICDCodes, setICDCodes) => {
    const validatedMapping = validateOnAddCptIcdMapping(selectedICDCodes);
    if (!validatedMapping) {
      const { insurancePayerId } = selectedPayer;
      const { id, isActive } = selectedCPT;
      addCptIcdMapping({
        id: cptIcdMappingId,
        data: {
          icdCodes: Object.keys(selectedICDCodes),
          cptCodeId: id,
          payerId: insurancePayerId,
          isActive,
          modifiedBy: 0,
        },
      });
      setMappingModalVisible(false);
      setSelectedPayer();
      setSelectedCPT();
      setSelectedICDCodes({});
      setICDCodes({});
      if (reFetch) {
        reFetch();
      }
    } else {
      Notification({ message: validatedMapping });
    }
  }, [
    validateOnAddCptIcdMapping,
    selectedPayer,
    selectedCPT,
    addCptIcdMapping,
    cptIcdMappingId,
    reFetch,
  ]);

  useEffect(() => {
    if (addCptIcdMappingResponse && addCptIcdMappingResponse.message) {
      Notification({ message: SuccessMessages.ADD_CPT_ICD_MAPPING, success: true });
    }
  }, [addCptIcdMappingResponse]);

  const onCloseModal = useCallback((setSelectedICDCodes) => {
    setMappingModalVisible(false);
    setSelectedICDCodes({});
    setSelectedCPT({});
  }, []);

  const navigateToNewPayer = useCallback(() => {
    push(generatePath(UiRoutes.newPayer));
  }, [generatePath, push]);

  const navigateBack = useCallback(() => {
    push(generatePath(UiRoutes.payer));
  }, [generatePath, push]);

  const navigateToEditPayer = useCallback((id) => {
    push(generatePath(UiRoutes.editPayer, { id, tab: 'general' }));
  }, [generatePath, push]);

  const exportPayer = useCallback(() => {
    requestPayerExport({ responseType: responseType.ARRAY_BUFFER });
  }, [requestPayerExport]);

  const onSelectFile = useCallback((fileData) => {
    uploadFile({
      data: {
        contentType: contentType.MULTIPART,
        File: fileData,
        DocumentDisplayName: fileData.name.substr(0, fileData.name.lastIndexOf('.')),
      },
    });
  }, [uploadFile]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Switch location={location}>
      <Route path={UiRoutes.newPayer} exact>
        <AddPayer
          labels={labels}
          form={form}
          navigateBack={navigateBack}
        />
      </Route>
      <Route path={UiRoutes.payer} exact>
        <CPTPayerMappingModal
          labels={labels}
          isAddMappingModalVisible={isAddMappingModalVisible}
          onCloseModal={onCloseModal}
          handleMappingUpdate={handleMappingUpdate}
          selectedPayer={selectedPayer}
          onSelectCPT={onSelectCPT}
          setSelectedCPT={setSelectedCPT}
          selectedMapping={selectedMapping}
        />
        <div className="heading-area flex justify-content-sp-bt align-center payer-screen">
          <h2>{labels.get('titles.payer')}</h2>
          {isPayerAuthenticated && (
            <div className="group-btns">
              <Upload
                accept=".xlsx"
                transformFile={onSelectFile}
                multiple={false}
                showUploadList={false}
                beforeUpload={(file) => fileSizeCheck(file, 30)}
              >
                <Button className="btn btn-success sm-btn">{labels.get('buttons.import')}</Button>
              </Upload>
              <Button className="btn btn-success sm-btn" onClick={exportPayer}>{labels.get('buttons.export')}</Button>
              <Button className="btn btn-success sm-btn" onClick={navigateToNewPayer}>{labels.get('buttons.addPayer')}</Button>
            </div>
          )}
        </div>
        <PayerTable
          handleReFetch={handleReFetch}
          onAddMappingIconClick={onAddMappingIconClick}
          navigateToEditPayer={navigateToEditPayer}
          isPayerRequestViewOnly={isPayerRequestViewOnly}
        />
      </Route>
      <Route path={UiRoutes.editPayer}>
        <EditPayer
          labels={labels}
          form={form}
          navigateBack={navigateBack}
          isPayerRequestViewOnly={isPayerRequestViewOnly}
        />
      </Route>
    </Switch>
  );
};

export default WithRights(WithLabel(Payer, labelPaths.ADD_PAYER),
  [rights.payer, rights.payer_view_only]);
