import { useEffect } from 'react';
import Events from '../../../../lib/events';

const TableWrapper = ({
  Component, reFetch,
}) => {
  useEffect(() => {
    Events.on('refetchTaskManagementList', 'refetchTaskManagementList', reFetch);
    return () => Events.remove('refetchTaskManagementList', 'refetchTaskManagementList');
  }, []);
  return (Component);
};

export default TableWrapper;
