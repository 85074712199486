import React from 'react';

import TimeCell from '../../../../../../../components/Cells/TimeCell';

const columns = (labels) => [

  {
    Header: labels.get('tableColumns.type'),
    accessor: 'caseTypeName',
    Cell: ({ row: { original: { caseTypeName, caseId, id } }, navigateToEditCase }) => (
      <div role="presentation" className="cursor-pointer" onClick={() => navigateToEditCase(caseId || id)}>
        {caseTypeName}
      </div>
    ),
    sort: true,
  },
  {
    Header: labels.get('tableColumns.startDate'),
    accessor: 'effectiveDateFrom',
    sort: true,
    Cell: ({ row: { original: { effectiveDateFrom } } }) => <TimeCell value={effectiveDateFrom} />,
  },
  {
    Header: labels.get('tableColumns.endDate'),
    accessor: 'effectiveDateTo',
    sort: true,
    Cell: ({ row: { original: { effectiveDateTo } } }) => <TimeCell value={effectiveDateTo} />,
  },
  {
    Header: labels.get('tableColumns.provider'),
    accessor: 'providerName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.lastUsed'),
    accessor: 'lastSeenDate',
    sort: true,
    Cell: ({ row: { original: { lastSeenDate } } }) => <TimeCell value={lastSeenDate} />,
  },
];

export default columns;
