import get from 'lodash/get';

export default class Label {
  constructor(labels) {
    this.labels = labels;
  }

  get(path) {
    return get(this.labels, path, path);
  }
}
