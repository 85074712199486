/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-unused-state */
import React from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';

import Table from '../../components/Table';

import { getListData, setListData } from '../../store/actions/queryApi';
import * as selectors from '../../store/selectors';

/**
 * The table works with sort array (by the key "sortBy")
 * This is HOF because we don't have all the required arguments in table component
 */

const getSortValue = ({ sortBy }) => {
  if (sortBy[0]?.reverseSort) {
    return sortBy[0].desc ? 'asc' : 'desc';
  }

  return sortBy[0].desc ? 'desc' : 'asc';
};

function onSort({
  url,
  listId,
  accessor,
  initialVariables,
  alias,
}, isReFetch = false) {
  return (sortBy, skipInitialFetch, isMounted, isSortingApply) => {
    // Parsing the sort so that BE can understand
    const {
      dispatch, onSortChange, filters: defaultFilters, customSort, list = {},
    } = this.props;
    const {
      pageSize, filters, paredSortBy, data,
    } = this.state;
    const sortParameter = paredSortBy;
    if (sortBy?.length) {
      const sortKey = sortBy[0].id;
      sortParameter.SortBy = alias[sortKey] || sortKey;
      sortParameter.SortOrder = getSortValue({ sortBy });
    }
    if (list?.data && isSortingApply && isFunction(customSort)) {
      const {
        totalPages, otherTableData = {}, totalCount = 0,
      } = list;
      dispatch(setListData(customSort(sortBy, data), listId, true,
        false, totalCount, totalPages, otherTableData));
      return;
    }
    this.setState({ sortBy, page: 0, paredSortBy: sortParameter }, () => {
      // calling the BE through redux
      if (!skipInitialFetch || isMounted) {
        dispatch(getListData(url, listId, accessor, {
          ...initialVariables,
          page: 0,
          pageSize,
          sortBy: sortParameter,
          filters: this.getRawFilter({ ...filters, ...defaultFilters }),
        }));
      }
      if (typeof onSortChange === 'function' && !isReFetch) {
        onSortChange(sortBy);
      }
    });
  };
}

function onFetchMore({
  url,
  listId,
  accessor,
  initialVariables,
}) {
  return () => {
    const { dispatch, onPageChange, filters: defaultFilters } = this.props;
    const {
      page, pageSize, filters, paredSortBy,
    } = this.state;
    this.setState({ page: page + 1 }, () => {
      dispatch(getListData(url, listId, accessor, {
        ...initialVariables,
        page: page + 1,
        pageSize,
        sortBy: paredSortBy,
        filters: this.getRawFilter({ ...filters, ...defaultFilters }),
      }));
      if (typeof onPageChange === 'function') {
        onPageChange(page);
      }
    });
  };
}

function onFiltersChange({
  url,
  listId,
  accessor,
  initialVariables,
}) {
  return (filters) => {
    const { dispatch, onFiltersChange: onChangeFilter, filters: defaultFilters } = this.props;
    const {
      pageSize, paredSortBy,
    } = this.state;
    this.setState({ filters, page: 0 }, () => {
      dispatch(getListData(url, listId, accessor, {
        ...initialVariables,
        page: 0,
        pageSize,
        sortBy: paredSortBy,
        filters: this.getRawFilter({ ...filters, ...defaultFilters }),
      }));
      if (typeof onChangeFilter === 'function') {
        onChangeFilter(filters);
      }
    });
  };
}

const defaultOptions = {
  accessor: (data) => data,
  initialVariables: {},
  alias: {},
};

const getState = (props) => ({
  sortBy: props?.sortBy?.length ? props.sortBy : props.initialSort,
  paredSortBy: {},
  initialSort: props?.sortBy?.length ? props.sortBy : props.initialSort,
  filters: { ...props.initialFilters, ...props.prevFilters },
  pageSize: props.pageSize,
  page: props.page,
  data: (props?.list?.data) || [],
});

const withQuery = (options) => {
  const {
    url,
    listId,
    accessor,
    initialVariables,
    alias,
    getRawFilter = (filter) => filter,
  } = { ...defaultOptions, ...options };
  if (!url && !listId) {
    return () => null;
  }
  const getEssential = () => ({
    url, listId, accessor, initialVariables, alias,
  });
  return () => {
    class Query extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          ...getState(props),
        };
        this.onSort = onSort.bind(this);
        this.onFetchMore = onFetchMore.bind(this);
        this.onFiltersChange = onFiltersChange.bind(this);
        this.getRawFilter = getRawFilter;
      }

      componentDidMount() {
        if (this.props?.initialFetch) {
          const { filters, dispatch } = this.props;
          const { pageSize, initialSort, filters: manualFilter } = this.state;
          dispatch(getListData(url, listId, accessor, {
            ...initialVariables,
            page: 0,
            pageSize,
            sortBy: { SortBy: initialSort?.[0]?.id, SortOrder: initialSort?.[0]?.desc ? 'desc' : 'asc' },
            filters: { ...filters, ...manualFilter },
          }));
        }
      }

      // fetches data as filters get changed
      componentDidUpdate(prevProps) {
        const { filters, dispatch } = this.props;
        const { pageSize, paredSortBy, filters: manualFilter } = this.state;
        if (!isEqual(filters, prevProps.filters)) {
          this.setState({ page: 0 });
          dispatch(getListData(url, listId, accessor, {
            ...initialVariables,
            page: 0,
            pageSize,
            sortBy: paredSortBy,
            filters: { ...filters, ...manualFilter },
          }));
        }
      }

      static getDerivedStateFromProps(props, state) {
        if (props.list && !isEqual(props.list.data, state.data)) {
          return {
            data: props.list.data,
          };
        }
        return null;
      }

      render() {
        const {
          children, Component, customData, list = {}, ...props
        } = this.props;

        const {
          filters, initialSort, data,
        } = this.state;
        const {
          loading, hasMore, totalPages, otherTableData = {}, totalCount = 0,
        } = list;

        return (
          children({
            Component: <Component
              {...props}
              onSort={this.onSort(getEssential())}
              onFetchMore={this.onFetchMore(getEssential())}
              data={customData || data}
              initialSort={initialSort}
              loading={loading}
              hasMore={hasMore}
              fetch={this.onSort(getEssential())}
              customData={customData}
              totalCount={totalCount}
              otherTableData={otherTableData}
            />,
            initialFilters: filters,
            onFiltersChange: this.onFiltersChange(getEssential()),
            totalCount,
            otherTableData,
            totalPages,
            reFetch: this.onSort(getEssential(), true),
            onSort: this.onSort(getEssential()),
            onFetchMore: this.onFetchMore(getEssential()),
            data,
            initialSort,
            loading,
            hasMore,
          })
        );
      }
    }

    Query.defaultProps = {
      Component: Table,
      page: 0,
      pageSize: 50,
      initialSort: [],
      filterParams: {},
      initialFilters: {},
      prevFilters: {},
    };

    const mapStateToProps = (state) => ({
      list: selectors.getTableDataById(state, listId),
    });
    return connect(mapStateToProps)(Query);
  };
};

export default withQuery;
