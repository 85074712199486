import React from 'react';

import List from '../Components/List';

import './workup.scss';

const Workup = ({ labels, data, ...otherProps }) => (
  <div className="dashboard-workup-category-container dash-category-container">
    <h4>{labels.get('headers.status.Workup')}</h4>
    <div className="dashboard-list-container">
      {data.map((section) => (
        <List
          key={section.status}
          id={section.status}
          data={section.appointments}
          labels={labels}
          {...otherProps}
        />
      ))}
    </div>
  </div>
);
Workup.defaultProps = {
  data: [],
  onDrop: () => { /* This is intentional */ },
};

export default Workup;
