import React, {
  useState, useCallback, useEffect,
} from 'react';
import {
  Row, Col, Form as AntdForm, Tooltip,
} from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import get from 'lodash/get';

import { apiUrls } from '../../../../../../api/constants';

import {
  formName, formId, listId, dateFormatWith12Hour, dateFormat,
} from '../../../../../../lib/constants';
import formFieldValueParser from '../../../../../../lib/formFieldValuesParser';
import dateFormatter from '../../../../../../lib/dateFormatter';
import { formatPhoneNumber, getString } from '../../../../../../lib/util';

import Form from '../../../../../../components/Form';
import PhoneNumberInput from '../../../../../../components/Form/PhoneNumberInput';

import Image from '../../../../../../components/Image';
import Button from '../../../../../../components/Button';
import { DatePicker, Input as FormInput } from '../../../../../../components/Form/inputFields';
import WidgetLoader from '../../../../../../components/WidgetLoader';
import StatusDropDown from '../../../../StatusDropDown';
import Events from '../../../../../../lib/events';
import useCRUD from '../../../../../../hooks/useCRUD';
import { getCrudWithoutTab } from '../../../../../../store/selectors';

const color = ['#2a4a79'];

const initialDataParser = (patientInfo) => {
  const updated = formFieldValueParser(patientInfo, {
    date: ['patientDeceasedDate'],
    string: ['ssn'],
  });
  return {
    ...updated,
    dateOfBirth: moment.utc(patientInfo?.dateOfBirth),
    age: moment().diff(patientInfo.dateOfBirth, 'years'),
  };
};
const parser = (patientUpdatedData) => formFieldValueParser(patientUpdatedData, {
  int: [
    'primaryPhoneExt',
    'secondaryPhoneExt',
  ],
  date: ['dateOfBirth', 'patientDeceasedDate'],
});

const UserInfo = ({
  labels, data,
  paramsPatient, setPatientInfo,
  patientInfo = {},
  appointmentTime,
  appointmentId,
  appointmentDetail,
  reFetchData,
}) => {
  const [editable, setEditable] = useState(false);
  const [form] = AntdForm.useForm();
  const toggleEditable = useCallback(() => setEditable(!editable), [editable]);
  const { patientId } = data || {};
  const patientIdInfo = paramsPatient || patientId;
  const patientData = useSelector((state) => getCrudWithoutTab(state, `patient-detail-${paramsPatient || patientId}`)?.get('data'));

  const [financialInfo, , loading, getFinancialDetail] = useCRUD({
    id: apiUrls.GET_FINANCIAL_COLLECTION,
    url: apiUrls.GET_FINANCIAL_COLLECTION,
    type: 'read',
  });

  const onAddConfirmation = useCallback(() => {
    Events.trigger('refetchAppointmentConfirmationTable');
    Events.trigger('refetchAppointmentDetails');
    reFetchData();
  }, [reFetchData]);

  useEffect(() => {
    if (patientId) getFinancialDetail({}, `?PatientId=${patientId}&AppointmentId=${appointmentDetail?.appointmentId}`);
  }, [appointmentDetail, patientId]);

  useEffect(() => {
    if (patientData) {
      const parsedData = initialDataParser(patientData);
      setPatientInfo(parsedData || {});
      form.setFieldsValue(parsedData);
    }
  }, [patientData]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Events.trigger(`refetch-patient-info${patientId}`, ({ isReFetch: true }));
      setEditable(false);
    }
  }, [patientId]);

  const toFixedPatientBalance = get(financialInfo, 'patientBalance', '0');
  const toFixedInsuranceBalance = get(financialInfo, 'insuranceBalance', '0');

  return (
    <Form
      form={form}
      section
      isUpdate
      url={`${apiUrls.UPDATE_PATIENT}/${patientIdInfo}`}
      parser={parser}
      formId={formId.SCHEDULAR_ADD_APPOINTMENT_PATIENT}
      name={formName.patientForm}
      listId={listId.PATIENTS}
      extraData={patientInfo}
      editKey={patientIdInfo}
      onRequestComplete={onRequestComplete}
      loaderComponent={WidgetLoader}
    >
      <div className="appointment-user-wrap rr">
        <div className="user-top">
          <div className="flex">
            <div className="user-img">
              <Image url={patientInfo?.patientImageUrl} name="placeholder-img.png" alt="logo" />
            </div>
            <div className="user-detail-list">
              <p className="title">
                {getString(
                  [patientInfo?.firstName, patientInfo?.middleName, patientInfo?.lastName],
                )}
              </p>
              {/* don't know which date */}
              <p className="date">{dateFormatter(appointmentTime, dateFormatWith12Hour)}</p>
              {appointmentDetail?.appointmentId
            && (
            <p>
              <span className="name">{labels.get('labels.status')}</span>
              {' '}
              <Tooltip placement="top" color={color} title={appointmentDetail?.confirmationOutcome}>
                <span className="value pending">{appointmentDetail?.confirmationOutcome}</span>
              </Tooltip>
              <StatusDropDown
                patientId={patientIdInfo}
                appointmentId={appointmentId}
                onFinish={onAddConfirmation}
              />
            </p>
            )}
              <p>
                <span className="name">{labels.get('labels.chartNo')}</span>
                {' '}
                <span className="value">{patientInfo.chartNumber}</span>
              </p>
            </div>
          </div>
          <div className="user-boxes posi-relative">
            {loading && <WidgetLoader />}
            <div className="box first">
              <p className="title" title={Number(toFixedPatientBalance)?.toFixed(2)}>
                {Number(toFixedPatientBalance)?.toFixed(2)}
              </p>
              <p>{labels.get('labels.patient')}</p>
              <p>{labels.get('labels.balance')}</p>
            </div>
            <div className="box second">
              <p
                className="title"
                title={Number(toFixedInsuranceBalance)?.toFixed(2)}
              >
                {Number(toFixedInsuranceBalance)?.toFixed(2)}
              </p>
              <p>{labels.get('labels.insurance')}</p>
              <p>{labels.get('labels.balance')}</p>
            </div>
            <div className="box third tt">
              <p
                className="title"
                title={parseFloat(parseFloat(toFixedPatientBalance) + parseFloat(toFixedInsuranceBalance))?.toFixed(2) || '0'}
              >
                {parseFloat(parseFloat(toFixedPatientBalance) + parseFloat(toFixedInsuranceBalance))?.toFixed(2) || '0'}
              </p>
              <p>{labels.get('labels.account')}</p>
              <p>{labels.get('labels.balance')}</p>
            </div>
            {!editable && <div role="presentation" className="edit-icon1" onClick={toggleEditable} />}
          </div>
        </div>
        {editable
          ? (
            <div className="user-btm">
              <Row gutter={20}>
                <Col md={12}>
                  <FormInput
                    label={labels.get('labels.ssn')}
                    name="ssn"
                    required
                    numberOnly
                    maxValueLength={9}
                    minValueLength={9}
                    placeholder="Enter SSN"
                  />
                  <DatePicker
                    label={labels.get('labels.dob')}
                    name="dateOfBirth"
                    required
                    id="19"
                    // form={form}
                    disabledDate={(current) => current && (current >= moment().subtract(1, 'days') || current <= moment().subtract(150, 'years'))}
                  />
                  <FormInput
                    label={labels.get('labels.age')}
                    name="age"
                    numberOnly
                    placeholder="Enter Age"
                    disabled
                  />
                  {/* <FormSelectBox
                    label={labels.get('labels.case')}
                    name="case"
                    options={[{ name: 'None', value: 'none' }]}
                  /> */}
                </Col>
                <Col md={12}>
                  <PhoneNumberInput
                    label={labels.get('labels.primaryPhone')}
                    name="primaryPhone"
                    required
                    numberOnly
                    maxValueLength={12}
                    minValueLength={10}
                    labelSpan={10}
                    inputSpan={14}
                    placeholder="Enter Primary Phone"
                  />
                  <PhoneNumberInput
                    label={labels.get('labels.secondaryPhone')}
                    name="secondaryPhone"
                    id="10"
                    maxValueLength={12}
                    minValueLength={10}
                    numberOnly
                    labelSpan={10}
                    inputSpan={14}
                    placeholder="Enter Secondary Phone"
                  />
                  <FormInput
                    label={labels.get('labels.email')}
                    name="email"
                    disabled={patientInfo?.isOptOutEmail}
                    rules={[{
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    }]}
                    placeholder="Enter Email"
                  />
                  {/* <FormInput
                    label={labels.get('labels.confirmation')}
                    name="confirmation"
                  /> */}
                </Col>
              </Row>
              <Row justify="end">
                <Button className="btn sm-btn" style={{ marginRight: 10 }} onClick={toggleEditable}>
                  {labels.get('buttons.close')}
                </Button>
                <Button className="btn btn-success sm-btn" type="submit">
                  {labels.get('buttons.save')}
                </Button>
              </Row>
            </div>
          )
          : (
            <div className="user-btm flex">
              <div className="user-name">
                <div className="feilds">
                  <p className="name">{labels.get('labels.ssn')}</p>
                  <p className="value">{patientInfo.ssn}</p>
                </div>
                <div className="feilds">
                  <p className="name">{labels.get('labels.dob')}</p>
                  <p className="value">{moment.utc(patientInfo?.dateOfBirth).format(dateFormat)}</p>
                </div>
                <div className="feilds">
                  <p className="name">{labels.get('labels.age')}</p>
                  <p className="value">{patientInfo.dateOfBirth && moment().diff(patientInfo.dateOfBirth, 'years')}</p>
                </div>
                {/* <div className="feilds select-feilds-wrap">
                  <SelectBox label="Case" id="12" options={[{ name: 'None', value: 'none' }]} />
                </div> */}
              </div>
              <div className="user-detail">
                <div className="feilds">
                  <p className="name">{labels.get('labels.primaryPhone')}</p>
                  <p className="value">{formatPhoneNumber(patientInfo?.primaryPhone)}</p>
                </div>
                <div className="feilds">
                  <p className="name">{labels.get('labels.secondaryPhone')}</p>
                  <p className="value">{formatPhoneNumber(patientInfo?.secondaryPhone)}</p>
                </div>
                <div className="feilds">
                  <p className="name">{labels.get('labels.email')}</p>
                  <p className="value">{patientInfo.email}</p>
                </div>
                {/* <div className="feilds">
                  <p className="name">{labels.get('labels.confirmation')}</p>
                  <p className="value">{labels.get('labels.unConfirmed')}</p>
                </div> */}
              </div>
            </div>
          )}
      </div>
    </Form>
  );
};
export default UserInfo;
