import React from 'react';
import TimeCell from '../../../../../components/Cells/TimeCell';
import Label from '../../../../../components/Label';

import { dateFormat } from '../../../../../lib/constants';
import dateFormatter from '../../../../../lib/dateFormatter';
import FinancialDetailCommonGroup from './financialDetailsComponents/financialDetailGroup1';
import FinancialDetailsHeader from './financialDetailsComponents/header';
import { addTimeZoneInDate } from '../../../../../lib/util';

const InsurancePaymentForm = ({ financialDetail }) => {
  const createdOn = financialDetail?.createdOn ? `on ${dateFormatter(addTimeZoneInDate(financialDetail.createdOn), dateFormat)}` : '';
  return (
    <div className="max-width-100 table-layout-field">
      <form>
        <FinancialDetailsHeader />
        <div className="feild-row display-view  group-shadow">
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Payment Amount" />
              :
            </div>
            <div className="name-view">{financialDetail?.amount < 0 ? `(${parseFloat(`${financialDetail.amount}`.replace('-', '')).toFixed(2)})` : (financialDetail?.amount || 0).toFixed(2)}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Un-Applied Credit" />
              :
            </div>
            <div className="name-view">{financialDetail?.unAppliedCredit ?? ''}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Payment Type" />
              :
            </div>
            <div className="name-view">{financialDetail?.financeDetails?.paymentTypeName ?? ''}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Entered By" />
              :
            </div>
            <div className="name-view">{`${financialDetail?.enteredBy ?? ''} ${createdOn}`}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Effective Date" />
              :
            </div>
            <div className="name-view"><TimeCell value={financialDetail?.effectiveDate} /></div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Posting Date" />
              :
            </div>
            <div className="name-view"><TimeCell value={addTimeZoneInDate(financialDetail?.createdOn)} /></div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Payment Method" />
              :
            </div>
            <div className="name-view">{financialDetail?.paymentMethod ?? ''}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Batch Number" />
              :
            </div>
            <div className="name-view">{financialDetail?.financeDetails?.batchNumber ?? ''}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Check Date" />
              :
            </div>
            <div className="name-view"><TimeCell value={financialDetail?.financeDetails?.checkDate} /></div>
          </div>
          <FinancialDetailCommonGroup financialDetail={financialDetail} />
        </div>
      </form>
    </div>
  );
};

export default InsurancePaymentForm;
