import { Map } from 'immutable';

import {
  SET_TAB_MODULE_NAME,
} from '../actions/tabs';

const initialState = Map({
});

const actionsMap = {
  [SET_TAB_MODULE_NAME]: (state, { tabModuleName }) => Map({ tabModuleName }),
};

export default function tabs(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
