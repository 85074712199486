import React from 'react';

import WiredAppointmentTable from '../../../../../../wiredComponents/AppointmentTable';

import { labelPaths } from '../../../../../../lib/constants';

import WithLabel from '../../../../../../hoc/withLabel';

import columns from './columns';

const PatientAppointmentTable = ({ labels }) => (
  <div className="appointment-table">
    <WiredAppointmentTable
      scrollId="patient-summary-appointment"
      appointmentColumns={columns(labels)}
      heading
    />
  </div>
);

export default WithLabel(PatientAppointmentTable, labelPaths.PATIENT_LISTS);
