import React from 'react';

import PolicyHolder from './PolicyHolder';
import PPO from './PPO';

const ViewInsurancePolicy = (props) => {
  const { selectedData } = props;
  return (
    <div className="complete-form-wrap insurance-aside-wrap posi-relative">
      <PPO policyData={selectedData} {...props} />
      <PolicyHolder policyData={selectedData} {...props} />
    </div>
  );
};

export default ViewInsurancePolicy;
