/*
* Removing use of react-to-print and now using print-js
* Previously print depended upon ui and a dummy component created for
  print pdf using react-to-print
*/
import React, {
  useState, useCallback, useRef, useEffect,
} from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { List, AutoSizer } from 'react-virtualized';
import printJS from 'print-js';
import throttle from 'lodash/throttle';

import iconNames from '../../lib/iconNames';
import Events from '../../lib/events';

import Icon from '../../components/Icon';
import WidgetLoader from '../../components/WidgetLoader';
import ConfirmDialog from '../../components/ConfirmDialog';
import { getBase64DataFromPDFjsDocument, getuuid } from '../../lib/util';

const scrollThrottle = 100;
const rotationEnum = [0, 90, 180, 270];

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfPreview = (props) => {
  const {
    id, fileUrl, onDelete, collapsableView,
    onDocumentLoad, showRotation, showPrint, hideAllIcon, initialScale, ...otherProps
  } = props;
  const [numPages, setNumPages] = useState(0);
  const [documentData, setDocumentData] = useState();
  const [isPdfPrintLoading, setIsPdfPrintLoading] = useState(false);
  const [scale, setScale] = useState(initialScale || 1.2);
  const [rotation, setRotation] = useState(0);
  const [rowHeight, setRowHeight] = useState(0);
  const virtualListRef = useRef(null);
  const ignoreScrollEvents = useRef(false);
  const previousScale = useRef(initialScale || 1.2);
  const previousScaleForCalculateWidth = useRef(initialScale || 1.2);
  const previousWidth = useRef(0);
  const previousHeight = useRef(0);
  const unqiueId = useRef(getuuid());

  const onPageScroll = useCallback((event) => {
    if (ignoreScrollEvents.current) ignoreScrollEvents.current = false;
    else {
      const { scrollTop = 0, clientHeight } = event || {};
      const currentPage = Math.round((scrollTop + clientHeight) / rowHeight);
      if (currentPage && currentPage !== Infinity) Events.trigger(`${id}_PAGE_NO`, currentPage);
    }
  }, [id, rowHeight]);

  useEffect(() => {
    Events.on(id, 'PDF_VIEWER', ({ pageNo = 1 }) => {
      ignoreScrollEvents.current = true;
      let desiredNumber = parseInt(pageNo, 10);
      if (desiredNumber < 1) {
        desiredNumber = 1;
      } else if (desiredNumber > numPages) {
        desiredNumber = numPages;
      }
      virtualListRef.current?.scrollToPosition((desiredNumber - 1) * rowHeight);
    });
    return () => Events.remove(id);
  }, [numPages, rowHeight]);

  const handleNewWidth = useCallback((scaleProps) => {
    const newWidth = (previousWidth.current / previousScaleForCalculateWidth.current) * scaleProps;
    previousScaleForCalculateWidth.current = scaleProps;
    if (newWidth !== previousWidth.current) {
      previousWidth.current = newWidth;
      const parentElement = document.getElementById(unqiueId.current);
      const newClassElement = parentElement?.querySelector('.ReactVirtualized__Grid__innerScrollContainer');
      if (newClassElement) {
        newClassElement.style.maxWidth = (`${newWidth}px`);
        newClassElement.style.width = (`${newWidth}px`);
      }
    }
  }, []);

  const handleNewRowHeight = useCallback((previousScaleRef, scaleProps) => {
    const newHeight = (previousHeight.current / previousScaleRef) * scaleProps;
    if (newHeight !== previousHeight.current) {
      previousHeight.current = newHeight;
      setRowHeight(Math.floor(newHeight + 20));
    }
  }, []);

  const incrementScale = useCallback(() => {
    const previousScaleRef = previousScaleForCalculateWidth.current;
    const newScale = scale + 0.50;
    setScale(newScale);
    handleNewWidth(newScale);
    handleNewRowHeight(previousScaleRef, newScale);
  }, [scale, handleNewWidth, handleNewRowHeight]);

  const decrementScale = useCallback(() => {
    const previousScaleRef = previousScaleForCalculateWidth.current;
    const newScale = (scale - 0.50) || scale;
    if (newScale > 0) {
      setScale(newScale);
      handleNewWidth(newScale);
      handleNewRowHeight(previousScaleRef, newScale);
    }
  }, [scale, handleNewWidth, handleNewRowHeight]);

  const rotateLeft = useCallback(() => {
    const newRotation = rotationEnum.indexOf(rotation);
    setRotation(newRotation
      ? rotationEnum[newRotation - 1]
      : rotationEnum[rotationEnum.length - 1]);
  }, [rotation]);

  const rotateRight = useCallback(() => {
    const newRotation = rotationEnum.indexOf(rotation);
    setRotation(rotationEnum[(newRotation + 1) % rotationEnum.length]);
  }, [rotation]);

  const resetScaleAndRotation = useCallback(() => {
    setScale(1.2);
    setRotation(0);
  }, []);

  const rowRenderer = useCallback(({ index, key, style }) => (
    <div
      key={key}
      id={`pdf-page${index + 1}`}
      style={{ ...style }}
    >
      <Page
        className="pdf-page"
        pageNumber={index + 1}
        scale={scale}
        rotate={rotation}
        renderAnnotationLayer={false}
        renderTextLayer={false}
        onLoadSuccess={(page) => {
          const { height, width } = page?.getViewport({ scale });
          previousHeight.current = height;
          const newHeight = Math.floor(height + 20);
          if (width !== previousWidth.current) {
            previousWidth.current = width;
            const parentElement = document.getElementById(unqiueId.current);
            const newClassElement = parentElement?.querySelector('.ReactVirtualized__Grid__innerScrollContainer');
            if (newClassElement) {
              newClassElement.style.maxWidth = (`${width}px`);
              newClassElement.style.width = (`${width}px`);
            }
          }
          if (newHeight > rowHeight || previousScale.current !== scale) {
            setRowHeight(newHeight);
            previousScale.current = scale;
          }
        }}
      />
    </div>
  ), [rotation, rowHeight, scale]);

  const onDocumentLoadSuccess = useCallback((document) => {
    const { numPages: pagesNum } = document;
    setNumPages(pagesNum);
    setDocumentData(document);
    onDocumentLoad(document);
  }, [onDocumentLoad]);

  const onPrintClick = useCallback(async () => {
    if (documentData) {
      let base64Data;
      try {
        /**
         * data is Uint8Array type
         */
        const data = await documentData?.getData();
        base64Data = await getBase64DataFromPDFjsDocument(data);
      } catch (e) {
        /* in error */
      }
      if (base64Data) {
        printJS({
          printable: base64Data,
          type: 'pdf',
          base64: true,
        });
      } else if (fileUrl || otherProps?.file) {
        /**
       * if base 64 data is not  found then use url for print pdf
       */
        printJS({
          printable: fileUrl || otherProps?.file,
          type: 'pdf',
          onLoadingStart: () => {
            setIsPdfPrintLoading(true);
          },
          onLoadingEnd: () => {
            setIsPdfPrintLoading(false);
          },
        });
      }
    }
  }, [documentData, fileUrl, otherProps]);

  useEffect(() => {
    Events.on('open-print-module', 'open-print-module', onPrintClick);
  }, []);

  const debounceScroll = throttle(onPageScroll, scrollThrottle);

  return (
    <div className="pdf-wrapper" style={{ height: '100%', width: '100%', overflow: 'hidden' }}>
      {isPdfPrintLoading && <WidgetLoader />}
      <div className="preview-image-tools">
        {!hideAllIcon && (
        <>
          {otherProps?.isFax ? (
            <Icon
              title="Add to Fax Queue"
              name={iconNames.shopTwoTone}
              onClick={otherProps?.handleAddToFaxQueue}
            />
                ) : null}
            {!collapsableView && (
            <Icon
              title="Delete"
              name={iconNames.deleteIcon}
              onClick={ConfirmDialog({
                onOk: (close) => {
                  onDelete();
                  close();
                },
                title: 'Do you want to delete this item?',
                content: 'When clicked the OK button, item will be deleted permanently.',
                icon: <Icon name="ExclamationCircleOutlined" />,
              })}
            />
            )}
          {showPrint && <Icon title="Print" name={iconNames.print} onClick={onPrintClick} />}
          <Icon title="Reset Size" onClick={resetScaleAndRotation} name={iconNames.undo} />
          <Icon title="Zoom In" onClick={incrementScale} name={iconNames.plusIcon} />
          <Icon title="Zoom Out" onClick={decrementScale} name={iconNames.minus} />
            {showRotation && (
            <>
              <Icon title="Rotate Left" name={iconNames.rotateLeft} onClick={rotateLeft} />
              <Icon title="Rotate Right" name={iconNames.rotateRight} onClick={rotateRight} />
            </>
            )}
        </>
        )}
      </div>
      <div className="pdf-document" style={{ height: '100%', width: '100%' }}>
        <Document
          file={fileUrl || otherProps?.file}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<WidgetLoader />}
        >
          <AutoSizer>
            {({ height, width }) => (
              <List
                ref={virtualListRef}
                id={unqiueId.current}
                onScroll={debounceScroll}
                height={height || 500}
                rowCount={numPages}
                rowHeight={rowHeight || 570}
                rowRenderer={rowRenderer}
                width={width || 500}
                overscanRowCount={6}
              />
            )}
          </AutoSizer>
        </Document>
      </div>
    </div>
  );
};

PdfPreview.defaultProps = {
  id: 'PDF_VIEWER', // change ids for multiple viewers on the same page
  onDocumentLoad: () => { /* This is intentional */ },
  showRotation: false,
  showPrint: true,
};

export default React.memo(PdfPreview,
  (prevProps, nextProps) => (prevProps?.fileUrl !== nextProps?.fileUrl
    && prevProps?.file !== nextProps?.file));
