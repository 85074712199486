import React from 'react';
import moment from 'moment';
import TimeCell from '../../../../../components/Cells/TimeCell';

const columns = (labels, navigateToViewEncounter) => [
  {
    Header: labels.get('tableColumns.dateOfService'),
    accessor: 'dateofservice',
    Cell: ({
      row: {
        original: {
          dateOfService,
          encounterId,
          id,
        },
      },
    }) => (
      <span
        className="cursor-pointer"
        role="presentation"
        onClick={() => {
          navigateToViewEncounter({ encounterId: id || encounterId });
        }}
      >
        {dateOfService ? moment.utc(dateOfService).format('MM-DD-YYYY') : ''}
      </span>
    ),
    sort: true,
  },
  {
    Header: labels.get('tableColumns.origin'),
    accessor: 'origin',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.visitType'),
    accessor: 'visitType',
  },
  {
    Header: labels.get('tableColumns.createdAt'),
    accessor: 'createdOn',
    Cell: ({
      row: {
        original: {
          createdOn = {},
        },
      },
    }) => (
      <TimeCell value={createdOn?.dateString} format="MM-DD-YYYY" />
    ),
    sort: true,
  },
  {
    Header: labels.get('tableColumns.createdBy'),
    accessor: 'createdByName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.status'),
    accessor: 'status',
  },
  {
    Header: labels.get('tableColumns.provider'),
    accessor: 'providerName',
    Cell: ({
      row: {
        original:
      { providerFirstName, providerLastName, providerMiddleName },
      },
    }) => `${providerFirstName || ''} ${providerMiddleName || ''} ${providerLastName || ''}`,
  },
  {
    Header: labels.get('tableColumns.location'),
    accessor: 'locationName',
  },
  {
    Header: labels.get('tableColumns.amount'),
    accessor: 'unbilled',
    sort: true,
    Cell: ({ row: { original: { unbilled } } }) => (
      (unbilled || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
];

export default columns;
