import React from 'react';

import TimeCell from '../../../../../../components/Cells/TimeCell';

const holdReadyColumns = (labels) => [
  {
    Header: labels.get('tableColumns.patientName'),
    accessor: 'patientName',
    sort: true,
    Footer: ({ totalCount }) => <span>{`${totalCount} Statements`}</span>,
  },
  {
    Header: labels.get('tableColumns.method'),
    accessor: 'methodName',
  },
  {
    Header: labels.get('tableColumns.lastStatementDate'),
    accessor: 'lastStatementDate',
    Cell: ({ row: { original } }) => <TimeCell value={original?.lastStatementDate} />,
  },
  {
    Header: labels.get('tableColumns.totalAmount'),
    accessor: 'currentAmount',
    className: 'text-align-right',
    Cell: ({ row: { original: { totalAmount } } }) => (
      (totalAmount || 0).toFixed(2)
    ),
    Footer: ({ otherTableData: { totalAmount } }) => <span className="inline-right">{totalAmount}</span>,
    fixWidth: 190,
  },
];

export default holdReadyColumns;
