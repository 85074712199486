import React, { lazy, useState } from 'react';
import { Select } from 'antd';

import { retry } from '../../../lib/util';
import rights from '../../../lib/rights';
import WithRights from '../../../hoc/withRights';

import './concernform.scss';

const BillRights = lazy(() => retry(() => import('./BillRights')));
const Lifestyle = lazy(() => retry(() => import('./Lifestyle')));
const DialationForm1 = lazy(() => retry(() => import('./Dialationform1')));
const Enrollment = lazy(() => retry(() => import('./Enrollment')));
const ReleaseForm = lazy(() => retry(() => import('./ReleaseForm')));
const Rxform = lazy(() => retry(() => import('./RxForm')));
const PatientReferral = lazy(() => retry(() => import('./PatientReferral')));
const ProcedureForm = lazy(() => retry(() => import('./ProcedureForm')));
const TeleHealthConsentForm = lazy(() => retry(() => import('./360Form')));
const ICAF = lazy(() => retry(() => import('./ICAF')));
const CcsForm = lazy(() => retry(() => import('./Ccs/Index')));
const GeneralPreOpForm = lazy(() => retry(() => import('./GeneralPreOp')));

const { Option } = Select;
function ConcernForm() {
  const [selectVal, setSelectVal] = useState('');
  function handleChange(value) {
    setSelectVal(value);
  }
  return (
    <div>
      <div className="select-top">
        <Select defaultValue="default" style={{ width: 280 }} onChange={handleChange}>
          <Option value="default">Select Consent Form</Option>
          <Option value="form1">Patient Referral Form</Option>
          <Option value="form2">Procedure Verification form</Option>
          <Option value="form3">360 form</Option>
          <Option value="form4">ICAF</Option>
          <Option value="form5">Dilation</Option>
          <Option value="form10">Dilation Form1</Option>
          <Option value="form11">Dilation Form2</Option>
          <Option value="form6">General pre-op</Option>
          <Option value="form7">Eylea</Option>
          <Option value="form12">Eylea2</Option>
          <Option value="form8">Bill Rights</Option>
          <Option value="form9">Patient Consent Form</Option>
          <Option value="form13">Release Form</Option>
          <Option value="form14">Rx Form Avastin</Option>
          <Option value="form15">Rx Form Eylea</Option>
          <Option value="form16">Rx Form Iluvien</Option>
          <Option value="form17">Rx Form Lucentis 3 Units</Option>
          <Option value="form18">Rx Form Lucentis 5 Units</Option>
          <Option value="form19">Rx Form Ozurdex</Option>
          <Option value="form20">Ccs Form</Option>
        </Select>
      </div>
      <div className="pdfscreens" style={{ background: '#ffffff' }}>
        {(() => {
          switch (selectVal) {
            case 'form1': return (
              <div>
                <PatientReferral />
              </div>
            );
            case 'form2': return (
              <div>
                <ProcedureForm />
              </div>
            );
            case 'form3': return (
              <div>
                <TeleHealthConsentForm />
              </div>
            );
            case 'form4': return (
              <div>
                <ICAF />
              </div>
            );
            case 'form10':
            case 'form11':
            case 'form5': return (
              <div>
                <DialationForm1 />
              </div>
            );
            case 'form6': return (
              <div>
                <GeneralPreOpForm />
              </div>
            );
            case 'form12':
            case 'form7': return (
              <div>
                <Enrollment />
              </div>
            );
            case 'form8': return (
              <div>
                <BillRights />
              </div>
            );
            case 'form9': return (
              <div>
                <Lifestyle />
              </div>
            );
            case 'form13': return (
              <div>
                <ReleaseForm />
              </div>
            );
            case 'form14': return (
              <div>
                <Rxform
                  medicationPlaceHolder="Avastin (J9035)"
                />
              </div>
            );
            case 'form15': return (
              <div>
                <Rxform
                  medicationPlaceHolder="Eylea 2 units"
                />
              </div>
            );
            case 'form16': return (
              <div>
                <Rxform
                  medicationPlaceHolder="Iluvien (J7313)X19 Units"
                />
              </div>
            );
            case 'form17': return (
              <div>
                <Rxform
                  medicationPlaceHolder="Lucentis(J2778)X3 Units"
                />
              </div>
            );
            case 'form18': return (
              <div>
                <Rxform
                  medicationPlaceHolder="Lucentis(J2778)X5 Units"
                />
              </div>
            );
            case 'form19': return (
              <div>
                <Rxform
                  medicationPlaceHolder="Ozurdex (J7312)X 7 units"
                />
              </div>
            );
            case 'form20': return (
              <div>
                <CcsForm />
              </div>
            );
            default: return (
              <div />
            );
          }
        })()}

      </div>
    </div>
  );
}

export default WithRights(ConcernForm, rights.access_to_consent_form);
