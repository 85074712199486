/* eslint-disable no-plusplus */
import { useMemo } from 'react';
import inRange from 'lodash/inRange';
import checkCellsIntersect from './utils/checkCellsIntersect';

const useMakeEventsSerialized = (appointments) => useMemo(() => {
  const columns = [];
  appointments.forEach((appointment) => {
    const { slotPositions: { startYAt: Astart, endYAt: Aend } } = appointment;
    let preferredColumn = columns.findIndex((columnRows) => columnRows.every(
      ({ slotPositions: { startYAt: Rstart, endYAt: Rend } }) => !(
        inRange(Astart, Rstart, Rend) || inRange(Aend, Rstart + 1, Rend + 1)
      ),
    ));
    if (preferredColumn < 0) {
      preferredColumn = columns.length;
      columns.push([]);
    }
    columns[preferredColumn].push(appointment);
  });
  columns.forEach((column, index) => {
    column.forEach((cell) => {
      // eslint-disable-next-line no-param-reassign
      cell.endXAt = columns.length + 1;

      for (let i = index + 1; i < columns.length; i++) {
        let doesCellsIntersect = false;
        for (let j = 0; j < columns[i].length; j++) {
          doesCellsIntersect = checkCellsIntersect(
            cell.appointmentStartDateTime.hour * 60
            + cell.appointmentStartDateTime.minute,
            columns[i][j].appointmentStartDateTime.hour * 60
            + columns[i][j].appointmentStartDateTime.minute,
            cell.appointmentEndDateTime.hour * 60
            + cell.appointmentEndDateTime.minute,
            columns[i][j].appointmentEndDateTime.hour * 60
            + columns[i][j].appointmentEndDateTime.minute,
          );

          if (doesCellsIntersect) {
            // eslint-disable-next-line no-param-reassign
            cell.endXAt = i + 1;
            break;
          }
        }
        if (doesCellsIntersect) break;
      }
    });
  });
  return columns;
}, [appointments]);

export default useMakeEventsSerialized;
