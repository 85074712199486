import React from 'react';

import BookAppointment from './BookAppointment';

function EditPatient() {
  return (
    <BookAppointment />
  );
}

export default EditPatient;
