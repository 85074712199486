import React, {
  useRef, useMemo, useEffect, useCallback, useState,
} from 'react';
import classNames from 'classnames';
import { Alert, Menu } from 'antd';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import moment from 'moment';
import debounce from 'lodash/debounce';

import AppointmentContextMenu from '../../AppointmentContextMenu';
import SingleLineComponent from './SingleLineComponent';
import { VerificationStatusForTooltip } from '../../../../../lib/util';

const tooltipContent = ({
  title,
  name,
  patientTime,
  doctorTime,
  examType,
  visitType,
  comments,
  note,
  confirmationMethod,
  confirmationOutcome,
  chartNumber,
  insurancedetailId,
  insuranceVerificationDate,
  insuranceVerificationStatus,
  getOrangeClassName,
}) => (
  <>
    <div>{title}</div>
    <h4>{name}</h4>
    {patientTime && <p>{`Patient Time: ${patientTime}`}</p>}
    {doctorTime && <p>{`Doctor Time: ${doctorTime}`}</p>}
    {examType && <p>{`Exam Type: ${examType}`}</p>}
    {visitType && <p>{`Visit Type: ${visitType}`}</p>}
    {comments && <p>{`Comments: ${comments}`}</p>}
    {note && <p>{`Notes: ${note}`}</p>}
    {chartNumber && <p>{`Chart Number: ${chartNumber}`}</p>}
    {insurancedetailId && insuranceVerificationDate ? (
      <p className={classNames(getOrangeClassName(insuranceVerificationStatus))}>
        {`Last Verified: ${moment(insuranceVerificationDate).format('MM-DD-YYYY hh:mm A')}`}
      </p>
    ) : null}
    {insurancedetailId ? <p className={classNames(getOrangeClassName(insuranceVerificationStatus))}>{`Status: ${VerificationStatusForTooltip[insuranceVerificationStatus]}`}</p> : null}
    {confirmationOutcome && confirmationMethod
    && (
    <Alert
      message={`Confirmation (${confirmationOutcome})`}
      description={`Confirmation by ${confirmationMethod}`}
      type="info"
    />
    )}
  </>
);

const EventCell = ({
  item,
  isDraggable,
  isEditable,
  onClick = () => { /* This is intentional */ },
  onDragBegin,
  onDragEnd,
  onRemove,
  onEdit,
  handleCancelModal,
  isContextMenuActive,
  onContextMenuActive,
  reFetchData,
  startXAt, endXAt,
  hideRightClickMenu,
}) => {
  const {
    slotPositions: { startYAt, endYAt }, startTime, patient,
  } = item;
  const ref = useRef(null);

  const [isCurrentContextMenuActive, onCurrentContextMenuActive] = useState(false);

  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: 'CARD', ...item,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => (isDraggable && moment(startTime).isSameOrAfter(moment(), 'day')),
    begin: () => onDragBegin(true),
    end: () => onDragEnd(false),
  });

  const opacity = isDragging ? 0 : 1;

  const isRemovable = useMemo(
    () => moment(startTime).isAfter(moment().hours(0).minutes(0)), [startTime],
  );
  const isAppointmentEditable = useMemo(
    () => isEditable && moment(startTime).isAfter(moment().hours(0).minutes(0)),
    [isEditable, startTime],
  );

  const onAppointmentEdit = useCallback((e) => {
    e.stopPropagation();
    if (!isContextMenuActive && onEdit) {
      const {
        id, location, doctor, visitType, examType,
      } = item;
      onEdit({
        id,
        time: moment(startTime),
        location: location ? { location } : location,
        doctor,
        patient,
        visitType,
        examType,
      }, 'edit');
    }
  }, [isContextMenuActive, item, onEdit, patient, startTime]);

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const dropdownProps = useMemo(() => ({
    overlay: (
      <Menu className="appointment-extra-info-menu-container">
        <AppointmentContextMenu
          item={item}
          visible={isCurrentContextMenuActive}
          reFetch={reFetchData}
        />
      </Menu>
    ),
    disabled: moment(startTime).isBefore(moment(), 'day'),
    onVisibleChange: debounce((status) => {
      onContextMenuActive(status);
      onCurrentContextMenuActive(status);
    }, 200),
  }), [isCurrentContextMenuActive,
    item,
    onContextMenuActive,
    reFetchData,
    startTime]);

  drag(ref);
  return (
    // eslint-disable-next-line
    <div
      ref={ref}
      className="grid-event-view"
      style={{
        opacity,
        gridArea: `${startYAt} / ${startXAt + 1} / ${endYAt} / ${endXAt}`,
      }}
      onClick={onClick}
    >
      <SingleLineComponent
        item={item}
        onRemove={isRemovable && onRemove}
        onEdit={isAppointmentEditable && onAppointmentEdit}
        handleCancelModal={handleCancelModal}
        tooltipContent={!(isDragging || isContextMenuActive) && tooltipContent}
        dropdownProps={dropdownProps}
        hideRightClickMenu={hideRightClickMenu}
      />
    </div>
  );
};

EventCell.defaultProps = {
  isDraggable: true,
  isEditable: true,
  hideRightClickMenu: false,
  onEdit: () => { /* This is intentional */ },
  onClick: () => { /* This is intentional */ },
  onDragBegin: () => { /* This is intentional */ },
  onDragEnd: () => { /* This is intentional */ },
  handleCancelModal: () => { /* This is intentional */ },
  onContextMenuActive: () => { /* This is intentional */ },
};

export default EventCell;
