import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Form as AntdForm } from 'antd';
import moment from 'moment';
import values from 'lodash/values';
import compact from 'lodash/compact';
import debounce from 'lodash/debounce';
import clone from 'lodash/clone';

import Collapse from '../../../../components/Collapse';
import Button from '../../../../components/Button';
import Form from '../../../../components/Form';

import FilterForm from './FilterForm';
import FilterFormForManualInterventionQueue from './FilterFormForManualInterventionQueue';
import { dateFormatStartsWithYearSeconds } from '../../../../lib/constants';

const filterKeys = ['AssignedTo', 'CreatedBy', 'LocationId', 'InsurancePayerId', 'TaskPriorityId', 'TaskStatusId', 'OutsideReferral', 'FromDueDate', 'ToDueDate', 'TaskIdentifier', 'SubCategoryId', 'healthPlanId', 'ProviderId'];

const FilterPanel = ({
  labels, isVisible, setAdditionalFilters, additionalFilters,
  filterForManualInterventionQueue, toggleFilterModal,
}) => {
  const [form] = AntdForm.useForm();
  const filterData = useRef({});
  const [initialData, setInitialData] = useState({});
  const handleApplyFilters = useCallback(debounce(() => {
    const filtersData = form.getFieldsValue();
    const clonedFilters = clone(filterData?.current);
    filterKeys.forEach((key) => {
      if (key === 'TaskIdentifier') clonedFilters[key] = filtersData[key]?.trim();
      else if (filtersData[key]) clonedFilters[key] = filtersData[key];
    });
    if (filtersData?.CreatedFrom) clonedFilters.CreatedFrom = moment(filtersData?.CreatedFrom).format('YYYY-MM-DD').toString();
    if (filtersData?.CreatedTo) clonedFilters.CreatedTo = moment(filtersData?.CreatedTo).format('YYYY-MM-DD').toString();
    if (filtersData?.FromDueDate) clonedFilters.FromDueDate = moment(filtersData?.FromDueDate).startOf('day').format(dateFormatStartsWithYearSeconds)?.toString();
    if (filtersData?.ToDueDate) {
      clonedFilters.ToDueDate = moment(filtersData?.ToDueDate)
        .format(dateFormatStartsWithYearSeconds)?.toString();
    }
    setAdditionalFilters({
      AssignedTo: undefined,
      CreatedBy: undefined,
      LocationId: undefined,
      InsurancePayerId: undefined,
      TaskPriorityId: undefined,
      TaskStatusId: undefined,
      OutsideReferral: undefined,
      FromDueDate: undefined,
      ToDueDate: undefined,
      TaskIdentifier: undefined,
      SubCategoryId: undefined,
      healthPlanId: undefined,
      ProviderId: undefined,
      CreatedFrom: undefined,
      CreatedTo: undefined,
      dateEntered: undefined,
      ...clonedFilters,
    });
  }, 1000), [form, setAdditionalFilters]);

  const onDueDateChange = useCallback((dateObject) => {
    const clonedFilterData = clone(filterData?.current);
    if (dateObject?.dateFrom) clonedFilterData.FromDueDate = dateObject?.dateFrom.toString();
    else delete clonedFilterData.FromDueDate;
    if (dateObject?.dateTo) clonedFilterData.ToDueDate = dateObject?.dateTo.toString();
    else delete clonedFilterData.ToDueDate;
    filterData.current = clonedFilterData;
    handleApplyFilters();
  }, [handleApplyFilters]);

  const resetFormData = useCallback((resetForm) => () => {
    resetForm();
    filterData.current = {};
    const formData = {
      AssignedTo: undefined,
      CreatedBy: undefined,
      LocationId: undefined,
      InsurancePayerId: undefined,
      TaskPriorityId: undefined,
      TaskStatusId: undefined,
      OutsideReferral: undefined,
      FromDueDate: undefined,
      ToDueDate: undefined,
      TaskIdentifier: undefined,
      SubCategoryId: undefined,
      healthPlanId: undefined,
      ProviderId: undefined,
      CreatedFrom: undefined,
      CreatedTo: undefined,
      dateEntered: undefined,
    };
    setAdditionalFilters(formData);
    form.setFieldsValue(formData);
  }, [form, setAdditionalFilters]);

  useEffect(() => {
    const filters = compact(values({
      ...(additionalFilters || {}),
      IsMyQueue: undefined,
      TaskCategoryId: undefined,
      SearchText: undefined,
    }));

    if (toggleFilterModal && additionalFilters && filters?.length) {
      toggleFilterModal();
      const initialDataObject = {
        AssignedTo: additionalFilters?.AssignedTo,
        CreatedBy: additionalFilters?.CreatedBy,
        LocationId: additionalFilters?.LocationId,
        InsurancePayerId: additionalFilters?.InsurancePayerId,
        TaskPriorityId: additionalFilters?.TaskPriorityId,
        TaskStatusId: additionalFilters?.TaskStatusId,
        OutsideReferral: additionalFilters?.OutsideReferral,
        CreatedFrom: additionalFilters?.CreatedFrom && moment(additionalFilters?.CreatedFrom),
        CreatedTo: additionalFilters?.CreatedTo && moment(additionalFilters?.CreatedTo),
        TaskIdentifier: additionalFilters?.TaskIdentifier,
        SubCategoryId: additionalFilters?.SubCategoryId,
        healthPlanId: additionalFilters?.healthPlanId,
        ProviderId: additionalFilters?.ProviderId,
        ToDueDate: additionalFilters?.ToDueDate && moment(additionalFilters?.ToDueDate),
        FromDueDate: additionalFilters?.FromDueDate && moment(additionalFilters?.FromDueDate),
      };
      filterData.current = {
        ToDueDate: additionalFilters?.ToDueDate,
        FromDueDate: additionalFilters?.FromDueDate,
      };
      if (additionalFilters?.ToDueDate) {
        if (!additionalFilters?.FromDueDate) {
          initialDataObject.dateEntered = 'pastDue';
        }
        if (additionalFilters?.FromDueDate) {
          const days = moment(additionalFilters.ToDueDate).diff(moment(additionalFilters.FromDueDate), 'days');
          if (days === 0) {
            initialDataObject.dateEntered = 'dueToday';
          }
          if (days === 6) {
            initialDataObject.dateEntered = 'next7Days';
          }
          if (days === 13) {
            initialDataObject.dateEntered = 'next14Days';
          }
          if (days === 29) {
            initialDataObject.dateEntered = 'next30Days';
          }
        }
      }
      setInitialData(initialDataObject);
    }
  }, []);

  return (
    <Collapse
      bordered={false}
      className="tasks-filter-panel-container"
      activeKey={isVisible ? ['tasks-filter-panel'] : []}
      panelKey="tasks-filter-panel"
      ghost
    >
      <Form form={form} formId="task-management-filters-form" section initialData={initialData}>
        {({ resetForm }) => (
          <div className="task-filter-form group-shadow">
            { filterForManualInterventionQueue ? (
              <FilterFormForManualInterventionQueue
                form={form}
                labels={labels}
                onDueDateChange={onDueDateChange}
                onFieldChange={handleApplyFilters}
                additionalFilters={additionalFilters}
              />
            ) : (
              <FilterForm
                form={form}
                labels={labels}
                onDueDateChange={onDueDateChange}
                onFieldChange={handleApplyFilters}
                additionalFilters={additionalFilters}
              />
            )}
            <div className="inline-right">
              <Button className="btn sm-btn btn-success" onClick={resetFormData(resetForm)}>{labels.get('buttons.clearFilters')}</Button>
            </div>
          </div>
        )}
      </Form>
    </Collapse>
  );
};

export default FilterPanel;
