import React, { useState, useCallback } from 'react';
import RadioGroup from '../../../../../../components/Form/RadioGroup';
import General from './General';
import Exceptions from './Exceptions';

import withLabel from '../../../../../../hoc/withLabel';
import { labelPaths } from '../../../../../../lib/constants';

const Components = {
  General,
  Exceptions,
};

const tabList = (labels) => [
  { value: 'General', label: labels.get('tabs.general') },
  { value: 'Exceptions', label: labels.get('tabs.exception') },
];

function FeePreferences(props) {
  const { labels, isProviderViewOnly } = props;
  const [activeTab, setActiveTab] = useState(() => 'General');
  const getTabComponent = useCallback(() => Components[activeTab], [activeTab]);
  const tabSwitchHandler = (event) => {
    const { value } = event.target;
    setActiveTab(() => value);
  };

  const TabComponent = getTabComponent();

  return (
    <div className="main-content-area">
      <div className="top-section">
        <span />
        <RadioGroup id="provider_feePreferences" tabList={tabList(labels)} onChange={tabSwitchHandler} value={activeTab} />
      </div>
      <TabComponent labels={labels} isProviderViewOnly={isProviderViewOnly} />
    </div>
  );
}

export default withLabel(FeePreferences, labelPaths.EDIT_PROVIDER_FEEPREFRENCES);
