import React, { useCallback } from 'react';

import BillingTable from './BillingTable';
import useRedirect from '../../../../../../hooks/useRedirect';
import { UiRoutes } from '../../../../../../lib/constants';

function BillingList(props) {
  const { labels, isProviderViewOnly } = props;
  const { params, push, generatePath } = useRedirect();
  const { id } = params;
  const navigateToForm = useCallback(() => {
    push(generatePath(UiRoutes.newBillingWithTabId, { ...params }));
  }, [push, params, generatePath]);

  return (
    <div className="documents-wrap">
      <div className="billing-list-table billing-filter-gap-mng">
        <BillingTable
          labels={labels}
          providerId={id}
          navigateToForm={navigateToForm}
          isProviderViewOnly={isProviderViewOnly}
        />
      </div>
    </div>
  );
}

export default BillingList;
