import React, { useCallback, useEffect, useState } from 'react';
import { Form as AntdForm } from 'antd';
import get from 'lodash/get';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { clearDataWithId } from '../../../../../../../store/actions/reduxStoreWithId';

import useMasterId from '../../../../../../../hooks/useMasterId';
import Button from '../../../../../../../components/Button';
import Form from '../../../../../../../components/Form';

import formFieldValueParser from '../../../../../../../lib/formFieldValuesParser';
import {
  formId, listIds, formName, enumMasterCode, enums,
} from '../../../../../../../lib/constants';

import { apiUrls } from '../../../../../../../api/constants';

import AuthorizationInformation from '../Components/AuthorizationInformation';
import { beforeFinishParser } from '../helpers';

const EditAuthorizationForm = ({
  labels, clearButtonRef, params, onRequestComplete, destroyOnClose = false,
}) => {
  const [form] = AntdForm.useForm();
  const dispatch = useDispatch();
  const { id, authorizationId, tabId } = params;
  const [cptCodes, setCptCodes] = useState([]);
  const [icdCodes, setIcdCodes] = useState([]);
  const [formData, setFormData] = useState({});
  const { Authorization } = useMasterId([enumMasterCode.document_authorization],
    [enums.PATIENT_DOCUMENT_CATEGORY]);

  useEffect(() => () => {
    if (destroyOnClose) {
      form.completeReset();
      dispatch(clearDataWithId(`SELECTED_INSURANCE_DATA-${tabId}-${id}`));
    }
  }, []);

  const initialDataParser = useCallback((entity) => {
    const data = formFieldValueParser(entity, {
      date: ['effectiveDateFrom',
        'effectiveDateTo', 'dateOfService', 'createdOn', 'modifiedOn'],
      string: ['visitedAllowed', 'unitsAllowed', 'unitsConsumed'],
    });
    data.appointments = data.dateOfServices?.filter((app) => app.appointmentId);
    data.appointments = data.appointments?.map((app) => app.appointmentId);
    if (data?.appointments?.length) {
      data.dosOptionsSelected = 'appointments';
    } else {
      data.dosOptionsSelected = 'manual';
      data.dateOfServicesManual = data?.dateOfServices?.length
      && moment(data.dateOfServices[0].dateOfService);
    }

    if (data?.patientDocumentList) {
      data.documentIds = data?.patientDocumentList?.map((item) => item?.documentId);
    }
    return data;
  },
  []);

  const handleFormData = useCallback((value = {}) => {
    setFormData({ ...value });
    setIcdCodes(get(value, 'icdCodes', []));
    setCptCodes(get(value, 'cptCodes', []));
  }, []);

  return (
    <Form
      section
      url={apiUrls.UPDATE_AUTHORIZATION}
      isUpdate
      getUrl={`${apiUrls.GET_AUTHORIZATION_BY_ID}/${authorizationId}`}
      onRequestComplete={onRequestComplete}
      parser={beforeFinishParser}
      initialDataParser={initialDataParser}
      onGetResponseComplete={handleFormData}
      form={form}
      formId={`${formId.EDIT_AUTHORIZATION}_${tabId}`}
      scrollToFirstErrors
      listId={listIds.AUTHORIZATION_LIST}
      editKey={authorizationId}
      name={formName.patientAuthorizationForm}
      tabId={tabId}
      extraData={{
        patientId: id,
        icdCodes: icdCodes.map((item) => item.id),
        cptCodes: cptCodes.map((item) => item.id),
        authorizationId,
        documentCategoryId: Authorization,
      }}
    >
      {({ resetForm }) => (
        <>
          <AuthorizationInformation
            labels={labels}
            setCptCodes={setCptCodes}
            setIcdCodes={setIcdCodes}
            icdCodes={icdCodes}
            cptCodes={cptCodes}
            patientId={id}
            formData={formData}
            form={form}
            tabId={tabId}
            isUpdate
          />
          <div className="btns-action">
            <Button className="btn btn-success min-wt-86" onClick={form.submit}>{labels.get('buttons.update')}</Button>
            <Button ref={clearButtonRef} className="btn" onClick={resetForm}>{labels.get('buttons.clear')}</Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default EditAuthorizationForm;
