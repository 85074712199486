import React, {
  lazy,
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import useCRUD from '../../../../../hooks/useCRUD';
import useRedirect from '../../../../../hooks/useRedirect';
import WithLabel from '../../../../../hoc/withLabel';
import { apiUrls, filePathsInGCP } from '../../../../../api/constants';
import { getEnumMaster } from '../../../../../store/selectors';
import { enums, labelPaths } from '../../../../../lib/constants';

import Search from '../../../../../components/Search';
import JsonTree from '../../../../../components/JsonTree';

import SettingsModal from '../Settings/SettingsModal';
import SaveHeaderFooterModal from '../SaveHeaderFooterModal';

import { createToolbarTemplates } from './utils';
import { retry, updateImageSrc } from '../../../../../lib/util';
import { header, body, footer } from './toolbars.json';

import HeaderMenu from './HeaderMenu';
import FooterMenu from './FooterMenu';

import './newTemplate.scss';
import TemplateHeader from './Header';

const Editor = lazy(() => retry(() => import('../../../../../components/CustomEditor')));

const NewTemplate = ({ labels }) => {
  const { params } = useRedirect();

  const [labelList, setLabelsList] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [headerOptionsVisible, setHeaderOptionsVisible] = useState();
  const [footerOptionsVisible, setFooterOptionsVisible] = useState();

  const [getHeaderHTML, setGetHeaderHTMLFunction] = useState();
  const [getBodyHTML, setGetBodyHTMLFunction] = useState();
  const [getFooterHTML, setGetFooterHTMLFunction] = useState();

  const [setHeaderHTML, setSetHeaderHTMLFunction] = useState();
  const [setBodyHTML, setSetBodyHTMLFunction] = useState();
  const [setFooterHTML, setSetFooterHTMLFunction] = useState();

  const [isSettingsModalVisible, setSettingsModalVisibility] = useState(false);
  const [isSaveModalVisible, setSaveModalVisibility] = useState(false);
  const [bodyName, setBodyName] = useState('header');
  const [selectedHeaderId, setSelectedHeaderId] = useState();
  const [selectedFooterId, setSelectedFooterId] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [savedHeaderFooterId, setSavedHeaderFooterId] = useState({
    header: null,
    footer: null,
  });

  const enumMaster = useSelector((state) => getEnumMaster(state));
  const enumId = get(enumMaster, `${enums.COMPOSER_TEMPLATE_TYPES}.enumId`);

  const headerToolbarTemplate = useMemo(() => createToolbarTemplates(header), []);
  const bodyToolbarTemplate = useMemo(() => createToolbarTemplates(body), []);
  const footerToolbarTemplate = useMemo(() => createToolbarTemplates(footer), []);
  const [formattedLogo, setFormattedLogo] = useState(null);

  const [
    selectedHeaderData, , ,
    getSelectedHeader,
    clearSelectedHeaderData,
  ] = useCRUD({
    id: 'get-selected-header',
    url: apiUrls.GET_TEMPLATE_HEADER_FOOTER_LIST,
    type: 'read',
  });

  const [
    selectedFooterData, , ,
    getSelectedFooter,
    clearSelectedFooterData,
  ] = useCRUD({
    id: 'get-selected-footer',
    url: apiUrls.GET_TEMPLATE_HEADER_FOOTER_LIST,
    type: 'read',
  });

  const [
    templateData,,
    templateDataLoading,
    getTemplateData,
    clearTemplateData,
  ] = useCRUD({
    id: `get-selected-template_${params?.templateId}`,
    url: apiUrls.GET_COMPOSER_TEMPLATE,
    type: 'read',
  });

  const [
    templateTypes,,,
    getTemplateTypes,
  ] = useCRUD({
    id: 'templates-type',
    url: apiUrls.GET_ENUM_FIELDS,
    type: 'read',
  });

  const [
    headerLogo,,,
    getHeaderLogo,
  ] = useCRUD({
    id: 'get_business_entity_logo',
    url: apiUrls.GET_BUSINESS_ENTITY_LOGO,
    type: 'read',
  });

  useEffect(() => {
    if (headerLogo) {
      const BusinessEntityLogo = headerLogo.replace(/^"|"$/g, '');
      const base64Image = `data:image/png;base64,${BusinessEntityLogo}`;
      setFormattedLogo(base64Image);
    }
  }, [headerLogo]);
  useEffect(() => {
    getTemplateTypes({ EnumId: enumId });
  }, []);

  useEffect(() => {
    getHeaderLogo({
      filename: filePathsInGCP.acuityEyeLogo,
    });
  }, [getHeaderLogo]);

  useEffect(() => {
    if (selectedHeaderId) {
      getSelectedHeader({}, `/${selectedHeaderId}`);
    }
    return () => clearSelectedHeaderData(true);
  }, [selectedHeaderId]);

  useEffect(() => {
    if (selectedFooterId) {
      getSelectedFooter({}, `/${selectedFooterId}`);
    }
    return () => clearSelectedFooterData(true);
  }, [selectedFooterId]);

  useEffect(() => {
    if (params?.templateId) {
      getTemplateData({}, `/${params?.templateId}`);
    } else if (params?.templateTypeId) {
      const templateId = parseInt(params?.templateTypeId, 10);
      // eslint-disable-next-line no-unused-expressions
      templateTypes?.forEach((item) => {
        if (templateId === item?.masterId) {
          if (setBodyHTML) {
            setBodyHTML(item?.masterDescription);
          }
          if (item?.masterId !== 1837) {
            if (setHeaderHTML && formattedLogo) {
              setHeaderHTML(`<p style="text-align: center;"><img id="acuityEyeLogo" src="${formattedLogo}" /></p>`);
            }
          } else {
            if (setHeaderHTML) {
              setHeaderHTML('');
            }
            if (setFooterHTML) {
              setFooterHTML('');
            }
          }
        }
      });
    }
  }, [getTemplateData, templateTypes, setBodyHTML, params, setHeaderHTML, formattedLogo]);

  useEffect(() => {
    if (selectedHeaderData && !Array.isArray(selectedHeaderData) && setHeaderHTML) {
      setHeaderHTML(selectedHeaderData?.templateText || '');
    }
  }, [selectedHeaderData, setHeaderHTML]);

  useEffect(() => {
    if (selectedFooterData && !Array.isArray(selectedFooterData) && setFooterHTML) {
      setFooterHTML(selectedFooterData?.templateText || '');
    }
  }, [selectedFooterData, setFooterHTML]);

  useEffect(() => {
    if (templateData && !Array.isArray(templateData)) {
      if (setHeaderHTML) {
        if (setHeaderHTML && formattedLogo) {
          const updatedHeaderHtmlWithImage = updateImageSrc(templateData.headerText, 'acuityEyeLogo', null, formattedLogo, false);
          setHeaderHTML(updatedHeaderHtmlWithImage);
        }
      }
      if (setBodyHTML) {
        setBodyHTML(templateData?.mainText);
      }
      if (setFooterHTML) {
        setFooterHTML(templateData?.footerText);
      }
    }
  }, [templateData, formattedLogo]);

  useEffect(() => () => clearTemplateData(true), []);

  const getHTMLContent = useCallback(() => {
    const headerHTML = getHeaderHTML ? getHeaderHTML() : '';
    const bodyHTML = getBodyHTML ? getBodyHTML() : '';
    const footerHTML = getFooterHTML ? getFooterHTML() : '';
    return ({
      mainText: bodyHTML,
      headerText: headerHTML,
      footerText: footerHTML,
    });
  }, [getBodyHTML, getFooterHTML, getHeaderHTML]);

  const onSearch = useCallback((event) => {
    const { value } = event.target;
    setSearchString(value || '');
  }, []);

  const onEditorFocus = useCallback((editorId) => () => {
    if (editorId === 'header') setHeaderOptionsVisible(true);
    if (editorId === 'footer') setFooterOptionsVisible(true);
  }, []);

  const onEditorBlur = useCallback((editorId) => () => {
    setTimeout(() => {
      if (editorId === 'header') setHeaderOptionsVisible(false);
      if (editorId === 'footer') setFooterOptionsVisible(false);
    }, 100);
  }, []);

  const toggleSettingsModal = useCallback((update) => () => {
    setSettingsModalVisibility(!isSettingsModalVisible);
    setIsUpdate(update);
  }, [isSettingsModalVisible]);

  const toggleSaveModal = useCallback((value) => {
    if (!isSaveModalVisible) setBodyName(value);
    setSaveModalVisibility(!isSaveModalVisible);
  }, [isSaveModalVisible]);

  return (
    <>
      <div className="new-template-container">
        <TemplateHeader
          toggleSettingsModal={toggleSettingsModal}
          templateDataLoading={templateDataLoading}
          templateData={templateData}
          labels={labels}
          getHeaderHTML={getHeaderHTML}
          getBodyHTML={getBodyHTML}
          getFooterHTML={getFooterHTML}
        />
        <div className="template-body">
          <div className="merge-fields-container">
            <div className="temp-search-wrapper">
              <Search
                placeholder="Search Fields"
                onChange={onSearch}
              />
            </div>
            <div className="json-tree-container">
              <JsonTree
                url={apiUrls.GET_COMPOSER_LABELS}
                setLabelsList={setLabelsList}
                list={labelList}
                search={searchString}
                selectable={false}
              />
            </div>
          </div>
          <div className="editor-container">
            <Editor
              setHTML={setSetHeaderHTMLFunction}
              getHTML={setGetHeaderHTMLFunction}
              name="header-container"
              placeholderText="Header"
              events={{
                focus: onEditorFocus('header'),
                blur: onEditorBlur('header'),
              }}
              {...headerToolbarTemplate}
            />
            <HeaderMenu
              visible={headerOptionsVisible}
              onSelect={setSelectedHeaderId}
              toggleSaveModal={toggleSaveModal}
              labels={labels}
              setHeaderHTML={setHeaderHTML}
              savedHeaderFooterId={savedHeaderFooterId}
              setSavedHeaderFooterId={setSavedHeaderFooterId}
            />
            <Editor
              setHTML={setSetBodyHTMLFunction}
              getHTML={setGetBodyHTMLFunction}
              name="body-container"
              placeholderText="Main Text"
              {...bodyToolbarTemplate}
            />
            <FooterMenu
              onSelect={setSelectedFooterId}
              visible={footerOptionsVisible}
              toggleSaveModal={toggleSaveModal}
              labels={labels}
              setFooterHTML={setFooterHTML}
              savedHeaderFooterId={savedHeaderFooterId}
              setSavedHeaderFooterId={setSavedHeaderFooterId}
            />
            <Editor
              setHTML={setSetFooterHTMLFunction}
              getHTML={setGetFooterHTMLFunction}
              name="footer-container"
              placeholderText="Footer"
              events={{
                focus: onEditorFocus('footer'),
                blur: onEditorBlur('footer'),
              }}
              {...footerToolbarTemplate}
            />
          </div>
        </div>
      </div>
      <SettingsModal
        isVisible={isSettingsModalVisible}
        toggleModal={toggleSettingsModal}
        labels={labels}
        getHTMLContent={getHTMLContent}
        templateData={templateData}
        isUpdate={isUpdate}
      />
      <SaveHeaderFooterModal
        isVisible={isSaveModalVisible}
        toggleModal={toggleSaveModal}
        isHeader={bodyName === 'header'}
        labels={labels}
        headerId={selectedHeaderId}
        footerId={selectedFooterId}
        getHeaderHTML={getHeaderHTML}
        getFooterHTML={getFooterHTML}
        setSavedHeaderFooterId={setSavedHeaderFooterId}
        savedHeaderFooterId={savedHeaderFooterId}
      />
    </>
  );
};

export default WithLabel(NewTemplate, labelPaths.SETTINGS_COMPOSER_NEW);
