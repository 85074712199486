import React from 'react';
import { useDragLayer } from 'react-dnd';

import SingleLineComponent from './EventCell/SingleLineComponent';

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

const itemStyles = {
  overflow: 'hidden',
  boxShadow: '5px 5px 15px gray',
};

// creates magnet like effects for draggable components that fits into the droppable position
// preffer not to use
// function snapToGrid(x, y, height, width) {
//   const snappedX = Math.round(x / width) * width;
//   const snappedY = Math.round(y / height) * height;
//   return [snappedX, snappedY];
// }

const EventDragLayer = ({ viewDimentions, draggingEvent }) => {
  const { cellHeight, cellWidth, ref } = viewDimentions;
  const {
    itemType,
    isDragging,
    item,
    initialOffset,
    currentOffset,
  } = useDragLayer((monitor) => {
    // returns live stats for the dragging component and it is being dragged
    let offset = monitor.getSourceClientOffset();
    if (draggingEvent && offset) {
      let { x, y } = offset;
      x -= initialOffset.x;
      y -= initialOffset.y;
      // snapping to grid
      // [x, y] = snapToGrid(x, y, cellHeight, cellWidth * minSlotTime);
      offset = { x, y };
    }

    return ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: offset,
      isDragging: monitor.isDragging(),
    });
  });

  const getItemStyles = () => {
    if (!initialOffset || !currentOffset) { return { display: 'none' }; }

    const { slotPositions: { startYAt, endYAt } } = item;
    const { clientWidth = cellWidth * 10 } = ref?.current || {};

    let { x, y } = currentOffset;
    x += initialOffset.x;
    y += initialOffset.y;

    const height = (endYAt - startYAt) * (cellHeight / 2);

    const transform = `translate(${x}px, ${y}px)`;
    return {
      transform,
      WebkitTransform: transform,
      height,
      width: clientWidth,
      ...itemStyles,
    };
  };

  const renderItem = () => {
    if (itemType === 'CARD') return <SingleLineComponent item={item} style={getItemStyles()} />;
    return null;
  };

  if (!isDragging || !draggingEvent) {
    return null;
  }
  return (
    <div className="grid-event-draggable-view" style={layerStyles}>
      {renderItem()}
    </div>
  );
};

export default EventDragLayer;
