import React, { useMemo } from 'react';
import { Tag } from 'antd';

import { UiRoutes } from '../../../lib/constants';

import Link from '../../../components/SavedNavigationBar/Link';
import TimeCell from '../../../components/Cells/TimeCell';
import { formatPhoneNumber, getDateString, getName } from '../../../lib/util';

export const commonPatientColumns = (labels) => [
  {
    Header: labels.get('tableColumns.contact'),
    accessor: 'contact',
    Cell: ({ row: { original: { contact } } }) => <span>{formatPhoneNumber(contact)}</span>,
  },
  {
    Header: labels.get('tableColumns.email'),
    accessor: 'email',
  },
  {
    Header: labels.get('tableColumns.next'),
    accessor: 'nextAppDate',
    sort: true,
    Cell: ({ row: { original: { nextAppDate } } }) => <TimeCell value={nextAppDate} />,
  },
  {
    Header: labels.get('tableColumns.lastClinic'),
    accessor: 'lastLocationName',
  },
  {
    Header: labels.get('tableColumns.last'),
    accessor: 'lastVisitedDate',
    sort: true,
    Cell: ({ row: { original: { lastVisitedDate } } }) => <TimeCell value={lastVisitedDate} />,
  },
  {
    Header: labels.get('tableColumns.current'),
    accessor: 'lastProviderName',
  },
  {
    Header: 'DOB',
    accessor: 'dateOfBirth',
    Cell: ({
      row: {
        original: { dateOfBirth },
      },
    }) => <TimeCell value={getDateString(dateOfBirth)} />,
  },
  {
    Header: labels.get('tableColumns.chart'),
    accessor: 'chartNumber',
  },
  {
    Header: labels.get('tableColumns.pBalance'),
    accessor: 'patientBal',
    Cell: ({ row: { original: { patientBal } } }) => (
      (patientBal || 0).toFixed(2)
    ),
    className: 'text-align-right',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.iBalance'),
    accessor: 'insuranceBal',
    Cell: ({ row: { original: { insuranceBal } } }) => (
      (insuranceBal || 0).toFixed(2)
    ),
    className: 'text-align-right',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.status'),
    accessor: 'status',
    Cell: ({ row: { original: { isActive, doNotContact } }, statusOptions }) => {
      const status = useMemo(() => [isActive, !isActive, doNotContact], [isActive, doNotContact]);
      return (
        statusOptions.map(({ name, value, color }, index) => (
          status[index] ? <Tag key={value} color={color}>{name}</Tag> : <span />
        ))
      );
    },
  },
];
const columns = (labels) => [
  {
    Header: labels.get('tableColumns.name'),
    accessor: 'firstName',
    Cell: ({
      row: {
        original: {
          patientId, firstName, middleName, lastName, id,
        },
      },
    }) => (
      <Link
        to={`${UiRoutes.editPatientWithTabId}`}
        params={{ id: patientId || id, tab: 'summary', data: { name: getName(firstName, middleName, lastName) } }}
      >
        {getName(firstName, middleName, lastName)}
      </Link>
    ),
    sort: true,
  },
  ...commonPatientColumns(labels),
];

export default columns;
