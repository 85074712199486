import React, { useCallback } from 'react';

import Button from '../../../components/Button';
import { UiRoutes, labelPaths } from '../../../lib/constants';
import rights from '../../../lib/rights';

import WithClearCurrentTab from '../../../hoc/withClearCurrentTab';
import withLabel from '../../../hoc/withLabel';
import WithRights from '../../../hoc/withRights';

import useRedirect from '../../../hooks/useRedirect';
import useRights from '../../../hooks/useRights';
import SavedNavigationBar from '../../../components/SavedNavigationBar';

import ProviderTable from './ProviderTable';

import './provider.scss';

const Providers = ({ labels }) => {
  const { push } = useRedirect();
  const [isProviderAuthenticated] = useRights([rights.provider]);

  const navigateToForm = useCallback(() => push({ pathname: UiRoutes.newProvider }), [push]);
  return (
    <SavedNavigationBar
      parentPath={UiRoutes.provider}
      myPath={UiRoutes.editProviderWithTabId}
    >
      <div>
        <div className="heading-area flex justify-content-sp-bt align-center provider-screen">
          <h2>{labels.get('titles.providers')}</h2>
          <div className="group-btns">
            {isProviderAuthenticated && (
              <Button id="provider_add" className="btn btn-success sm-btn" onClick={navigateToForm}>
                {labels.get('buttons.addProvider')}
              </Button>
            )}
          </div>
        </div>
        <ProviderTable labels={labels} />
      </div>
    </SavedNavigationBar>
  );
};

export default WithClearCurrentTab(WithRights(withLabel(Providers,
  labelPaths.PROVIDER_LIST), [rights.provider, rights.provider_view_only]));
