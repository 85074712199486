import React, { useCallback, useState } from 'react';

import Tabs from '../../../../components/Tabs/TabsWithoutUrlNavigation';
import MapInsurance from '../Components/MapInsurance';
import General from '../Components/General';

const AddPayer = ({
  form, labels, navigateBack, ...props
}) => {
  const [selectedTab, setSelectedTab] = useState('general');
  const [payerId, setPayerId] = useState();

  const tabsList = [
    {
      id: 'general',
      name: 'General',
      disabled: payerId,
      component: General,
    },
    {
      id: 'mapInsurance',
      name: 'Map Insurance',
      disabled: !payerId,
      component: MapInsurance,
    },
  ];
  const handleChangeTab = useCallback((tabId) => {
    setSelectedTab(tabId);
  },
  []);

  return (
    <div className="main-content-area">
      <div className="heading-area flex justify-content-sp-bt align-center add-payer-screen">
        <h2>{labels.get('titles.addPayer')}</h2>
      </div>
      <div className="main-form-container">
        <Tabs
          id="addPayer"
          tabsList={tabsList}
          selectedTab={selectedTab}
          handleChange={setSelectedTab}
          labels={labels}
          navigateBack={navigateBack}
          setPayerId={setPayerId}
          handleChangeTab={handleChangeTab}
          payerId={payerId}
          {...props}
        />
      </div>
    </div>
  );
};

export default AddPayer;
