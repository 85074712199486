import React from 'react';

import { Radio } from '../../../../../../wiredComponents/Enum';
import SmCheckbox from '../../../../../../components/SmCheckbox';
import { enums as enumConstant } from '../../../../../../lib/constants';

const Form = ({
  labels,
  handleRadio,
  claimDelivery,
  isCorrected,
  handleCheckbox,
}) => (
  <div className="mr-top-16">
    <p>{labels.get('titles.claimDelivered')}</p>
    <Radio
      enumName={enumConstant.SUBMISSION_METHOD}
      name="deliveryMethodId"
      labelSpan="0"
      inputSpan="24"
      onChange={handleRadio}
      value={claimDelivery}
    />
    <div className="mr-top-8">
      <SmCheckbox
        labelSpan="10"
        name="sendAsCorrectedClaim"
        inputSpan="1"
        labelAfter
        onChange={handleCheckbox}
        value={isCorrected}
      >
        Send as corrected claim
      </SmCheckbox>
    </div>
  </div>
);
export default Form;
