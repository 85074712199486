import React, { useCallback } from 'react';

import Input from '../../../../../components/Form/Input';
import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import Notification from '../../../../../components/Notification';
import ErrorMessages from '../../../../../lib/errorMessages';

const claimNumberRegex = /^[a-zA-Z0-9]+$/;
const NO_SPACES_ALLOWED = 'No spaces are allowed';
const valueChecker = (rules, value) => {
  if (!value) return Promise.resolve();
  if (claimNumberRegex.test(value)) return Promise.resolve();
  return Promise.reject(NO_SPACES_ALLOWED);
};

const IncidentForm = ({
  onChange,
  labels,
  claimNo,
  isIncident,
  setIsIncident,
}) => {
  const onCreateIncident = useCallback(() => {
    if (claimNo && claimNumberRegex.test(claimNo)) {
      setIsIncident(!isIncident);
    } else {
      Notification({ message: ErrorMessages.ENTER_CLAIM_NUMBER_FIRST });
    }
  }, [claimNo, isIncident, setIsIncident]);

  return (
    <Form.Column>
      {!isIncident && (
      <>
        <Input
          label={labels.get('labels.enterClaimNumber')}
          name="claimNumber"
          onChange={onChange}
          required
          rules={[{ validator: valueChecker }]}
        />
        <div className="btns-action flex justify-content-flex-end">
          <Button className="btn btn-success min-wt-86" onClick={onCreateIncident}>{labels.get('buttons.createIncident')}</Button>
        </div>
      </>
      )}
      {isIncident && (
      <Input.TextArea
        label={labels.get('labels.denialReason')}
        name="denialReason"
        required
        size="large"
        autoSize={{ minRows: 2, maxRows: 6 }}
        alphaNumericOnly
      />
      )}
    </Form.Column>
  );
};

export default IncidentForm;
