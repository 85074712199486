import React, { useCallback } from 'react';
import { apiUrls } from '../../api/constants';

import Form from '../../components/Form';
import Input from '../../components/Form/Input';
import Notification from '../../components/Notification';
import { formId } from '../../lib/constants';
import SuccessMessages from '../../lib/successMessages';
import AutoComplete from '../../components/Form/GridAutoComplete';
// eslint-disable-next-line import/no-cycle
import ICDLookup from '../LookUp/ICDLookup';

const optionParser = (option) => ({
  value: option.id,
  code: option.cptCode,
  name: option.cptCode,
  desc: option.description,
  ...option,
});

const columns = [
  {
    Header: 'Code',
    accessor: 'cptCode',
    flex: 1,
  },
  {
    Header: 'Description',
    accessor: 'description',
    flex: 5,
  },
];

const AddSynonymForm = (props) => {
  const {
    form,
    labels,
    toggleModal,
    showIcdLookup,
    showCptLookup,
    extraDataProps,
    selectedCode,
    onComplete,
    includeInActive,
  } = props;

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      const message = selectedCode?.isEdit
        ? response.message
        : SuccessMessages.SYNONYM_SAVED_SUCCESSFULLY;
      Notification({ message, success: true });
      toggleModal(false, true); // (modalState, updateTable)
      if (onComplete) onComplete(response);
    }
  }, [onComplete, selectedCode, toggleModal]);

  const setIcdLookupValue = useCallback(({ value }) => {
    form.setFieldsValue({ icdId: value });
  }, [form]);

  return (
    <Form
      form={form}
      onRequestComplete={onRequestComplete}
      formId={formId.ADD_SYNONYM_CPT_ICD}
      initialData={selectedCode?.isEdit ? selectedCode : undefined}
      url={selectedCode?.isEdit
        ? `${apiUrls.UPDATE_SYNONYM_FOR_CPT_ICD}/${selectedCode?.icdCptSynonymId}`
        : apiUrls.ADD_SYNONYM_FOR_CPT_ICD}
      extraData={{
        ...extraDataProps,
      }}
    >
      <Form.Column>
        {showIcdLookup && (
        <ICDLookup
          name="icdId"
          label={labels.get('labels.icdLookup')}
          placeholder={labels.get('placeHolders.icdLookup')}
          labelSpan="8"
          inputSpan="14"
          allowClearOnSelect={false}
          onSelect={setIcdLookupValue}
          includeInActive={includeInActive}
        />
        )}
        {showCptLookup && (
        <AutoComplete
          name="cptId"
          url={apiUrls.GET_CPTS}
          optionMaster="CPTCodeText"
          params={{ IncludeInActive: includeInActive }}
          optionParser={optionParser}
          placeholder={labels.get('placeHolders.cptLookup')}
          columns={columns}
          dropdownMatchSelectWidth={300}
          labelSpan="8"
          inputSpan="14"
          label={labels.get('labels.cptLookup')}
          labelInValue
          selectProps={{
            optionLabelProp: 'name',
          }}
        />
        )}
        <Input label={labels.get('labels.synonym')} name="synonym" inputSpan={14} labelSpan={8} />
      </Form.Column>
    </Form>
  );
};

AddSynonymForm.defaultProps = {
  includeInActive: true,
};

export default AddSynonymForm;
