import React, { useCallback } from 'react';
import { Form as AntdForm } from 'antd';

import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import Notification from '../../../../../components/Notification';

import { apiUrls } from '../../../../../api/constants';
import { formId, listId } from '../../../../../lib/constants';
import SuccessMessages from '../../../../../lib/successMessages';
import AddPayerForm from '../PayerForm';

const General = ({
  navigateBack, labels, payerId, setPayerId, handleChangeTab, ...props
}) => {
  const [form] = AntdForm.useForm();

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.PAYER_SUCCESSFULLY_ADDED, success: true });
      setPayerId(response?.insurancePayerId);
    }
  }, [setPayerId]);

  const parser = useCallback((formValues) => ({
    ...formValues,
    zip: formValues.zip2 ? formValues.zip.concat('-', formValues.zip2) : formValues.zip,
  }), []);
  const handleSaveAndUpdate = useCallback(() => {
    if (payerId) {
      handleChangeTab('mapInsurance');
    } else {
      form.submit();
    }
  },
  [form, handleChangeTab, payerId]);

  return (
    <div>
      <Form
        form={form}
        section
        scrollToFirstErrors
        formId={formId.ADD_PAYER}
        url={apiUrls.REQUEST_NEW_PAYER}
        listId={listId.ADD_PAYER}
        parser={parser}
        onRequestComplete={onRequestComplete}
      >
        <Form.Section>
          <div className="group-btns sift-right heading-area">
            <Button className="btn min-wt-86 sm-btn" id="patients_add_cancel" onClick={navigateBack}>{labels.get('buttons.cancel')}</Button>
            <Button className="btn btn-success min-wt-86 sm-btn" id="patients_add_save" onClick={handleSaveAndUpdate}>{payerId ? labels.get('buttons.next') : labels.get('buttons.save')}</Button>
          </div>
        </Form.Section>
        <div className="main-form-container">
          <div className="add-patient-cl">
            <AddPayerForm form={form} labels={labels} {...props} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default General;
