import React from 'react';

import Icon from '../../../../components/Icon';
import IconNames from '../../../../lib/iconNames';
import InputCell from '../../../../components/Cells/Input';

const provider = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Effective From',
    accessor: 'effectiveFrom',
    Cell: () => (
      <div className="input-height-28">
        {' '}
        <InputCell />
      </div>
    ),
    fixWidth: '100',
  },
  {
    Header: 'Effective To',
    accessor: 'effectiveTo',
    Cell: () => (
      <div className="input-height-28">
        {' '}
        <InputCell />
      </div>
    ),
    fixWidth: '80',
  },
  {
    Header: 'Action',
    Cell: ({
      row: { original: { id } },
      deleteProvider,
      addLocationMethod,
      addLocationMethodCopy,
    }) => (
      <div className="custom-action-icon">
        <Icon
          name={IconNames.deleteIcon}
          onClick={() => deleteProvider && deleteProvider(id)}
        />
        <Icon
          name={IconNames.plusIcon}
          onClick={() => addLocationMethod()}
        />
        <Icon
          name={IconNames.plusIcon}
          onClick={() => addLocationMethodCopy()}
        />
      </div>
    ),
    fixWidth: '60',
  },
];

export default provider;
