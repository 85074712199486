import React from 'react';
import { Select } from '../../../../../../wiredComponents/Enum';

const TableRowWithMutipleCheckbox = ({ span1, span2, span3 }) => (
  <tr>
    <td style={{ padding: '4px 0' }}>
      <input type="checkbox" />
      <span>{span1}</span>
    </td>
    <td style={{ padding: '4px 0' }}>
      <input type="checkbox" />
      <span>{span2}</span>
    </td>
    <td style={{ padding: '4px 0' }}>
      <input type="checkbox" />
      <span>{span3}</span>
    </td>
  </tr>
);

const NestedTable = ({ span1, span2 }) => (
  <table width="100%">
    <tr>
      <td style={{ padding: '4px 0' }}>
        <span>{span1}</span>
      </td>
      <td style={{ paddingRight: '20px' }}>
        <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
      </td>
      <td style={{ paddingRight: '4px' }}>
        <span>{span2}</span>
      </td>
      <td>
        <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
      </td>
    </tr>
  </table>
);
const TableRow = () => (
  <tr>
    <td style={{ padding: '4px 0' }}>
      <input type="checkbox" />
      <span>% Lidocaine</span>
      <input style={{ width: '54px', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
      <span>cc with or without epi</span>
    </td>
  </tr>
);

const Intraop = () => (
  <div>
    <div className="main-form-container">
      <div className="select-area-box">
        <Select
          label="Forms"
          name=""
          id="3"
          labelSpan="8"
          inputSpan="16"
        />
        <button type="button" className="btn btn btn-success min-wt-86 sm-btn mr-lt-12">Download</button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p style={{ color: '#2a4a79', fontSize: '12px', padding: '12px 0' }}>
          Orange Asc, LLC
          <br />
          1310 W. Stewart Dr. Suite #310
          <br />
          Orange, CA 92868
          <br />
          (714)633-1338
        </p>
        <p style={{ fontSize: '18px', fontWeight: 'bold', color: '#2a4a79' }}>Intraoperative Record</p>
      </div>
      <div className="m-vertical-scroll">
        <table
          border="1"
          style={{ border: '1px solid #d2ddef', fontSize: '13px' }}
          cellPadding="0"
          cellSpacing="0"
          width="100%"
        >
          <tr>
            <td colSpan="10">
              <table width="100%">
                <tr>
                  <td width="50%" style={{ verticalAlign: 'top', borderRight: '1px solid #d2ddef', paddingLeft: '3px' }}>
                    <table width="100%">
                      <tr>
                        <td>
                          <table width="100%">
                            <tr>
                              <td colSpan="2" style={{ paddingRight: '4px' }}>
                                <b>Pre-op Diagnoses:</b>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table width="100%">
                            <tr>
                              <td>
                                <table width="100%">
                                  <TableRowWithMutipleCheckbox span1="Right" span2="Left" span3="Both" />
                                  <TableRowWithMutipleCheckbox span1="Contract" span2="Pteryglum" span3="Btepharoptosis" />
                                  <TableRowWithMutipleCheckbox span1="Rentinal Datechment" span2="Macular Pucker" span3="Macufar Hole" />
                                  <tr>
                                    <td style={{ padding: '4px 0' }}>
                                      <input type="checkbox" />
                                      <span>Other</span>
                                    </td>
                                    <td style={{ padding: '4px 0' }}>
                                      <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table width="100%">
                            <tr>
                              <td colSpan="2" style={{ paddingRight: '4px' }}>
                                <b>Post-op Diagnoses:</b>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table width="100%">
                            <tr>
                              <td>
                                <table width="100%">
                                  <TableRowWithMutipleCheckbox span1="Same" span2="Other" span3="" />
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                    <table width="100%" style={{ borderTop: '1px solid #d2ddef' }}>
                      <tr>
                        <td>
                          <table width="100%">
                            <tr>
                              <td colSpan="2" style={{ paddingRight: '4px' }}>
                                <b>Operative Procedure:</b>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table width="100%">
                            <tr>
                              <td>
                                <table width="100%">
                                  <TableRowWithMutipleCheckbox span1="Right" span2="Left" span3="Both" />
                                  <TableRowWithMutipleCheckbox span1="Contract" span2="Pteryglum" span3="Btepharoptosis" />
                                  <TableRowWithMutipleCheckbox span1="Rentinal Datechment" span2="Macular Pucker" span3="Macufar Hole" />
                                  <tr>
                                    <td style={{ padding: '4px 0' }}>
                                      <input type="checkbox" />
                                      <span>Other</span>
                                    </td>
                                    <td style={{ padding: '4px 0' }}>
                                      <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                                    </td>

                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                    <table width="100%" style={{ borderTop: '1px solid #d2ddef' }}>
                      <tr>
                        <td>
                          <table width="100%">
                            <tr>
                              <td colSpan="2" style={{ paddingRight: '4px' }}>
                                <b>Equipment:</b>
                                <span>( check one )</span>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table width="100%">
                            <tr>
                              <td>
                                <table width="100%">
                                  <tr>
                                    <td style={{ padding: '4px 0' }}>
                                      <input type="checkbox" />
                                      <span>EKg, NIBP, Sao2</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ padding: '4px 0' }}>
                                      <input type="checkbox" />
                                      <span>Phase1  US Time:</span>
                                      <input style={{ width: '100px', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                    <table width="100%" style={{ borderTop: '1px solid #d2ddef' }}>
                      <tr>
                        <td style={{ borderRight: '1px solid #d2ddef' }}>
                          <table width="100%">
                            <tr>
                              <td>
                                <table width="100%">
                                  <tr>
                                    <td colSpan="2" style={{ paddingRight: '4px' }}>
                                      <b>Pathology:</b>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2" style={{ paddingRight: '4px' }}>
                                      <span>Source:</span>
                                      <input style={{ width: '100px', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <table width="100%">
                                  <tr>
                                    <td>
                                      <table width="100%">
                                        <tr>
                                          <td style={{ padding: '4px 0' }}>
                                            <input type="checkbox" />
                                            <span>Sent to lab</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style={{ padding: '4px 0' }}>
                                            <input type="checkbox" />
                                            <span>Discarded</span>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>

                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td>
                          <table width="100%" style={{ paddingTop: '12px' }}>
                            <tr>
                              <td>
                                <table width="100%">
                                  <tr>
                                    <td colSpan="2" style={{ paddingRight: '4px' }}>
                                      <b>Culture:</b>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2" style={{ paddingRight: '4px' }}>
                                      <span>Source:</span>
                                      <input style={{ width: '100px', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <table width="100%">
                                  <tr>
                                    <td>
                                      <table width="100%">
                                        <tr>
                                          <td style={{ padding: '4px 0' }}>
                                            <input type="checkbox" />
                                            <span>Sent to lab</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style={{ padding: '4px 0' }}>
                                            <input type="checkbox" />
                                            <span>Discarded</span>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>

                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ borderRight: '1px solid #d2ddef' }}>
                          <table width="100%">
                            <tr>
                              <td>
                                <table width="100%">
                                  <tr>
                                    <td colSpan="2" style={{ paddingRight: '4px' }}>
                                      <b>Pathology:</b>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2" style={{ paddingRight: '4px' }}>
                                      <span>Source:</span>
                                      <input style={{ width: '100px', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <table width="100%">
                                  <tr>
                                    <td>
                                      <table width="100%">
                                        <tr>
                                          <td style={{ padding: '4px 0' }}>
                                            <input type="checkbox" />
                                            <span>Sent to lab</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style={{ padding: '4px 0' }}>
                                            <input type="checkbox" />
                                            <span>Discarded</span>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>

                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>

                  <td width="50%" style={{ verticalAlign: 'top', paddingLeft: '3px' }}>
                    <table width="100%">
                      <tr>
                        <td>
                          <table width="100%">
                            <tr>
                              <td colSpan="2" style={{ paddingRight: '4px' }}>
                                <b>Team:</b>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table width="100%">
                            <tr>
                              <td>
                                <NestedTable span1="Surgeon:" span2="Anesthesia Provider:" />
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>

                          <table width="100%">
                            <tr>
                              <td>
                                <NestedTable span1="Circufating Nurse:" span2="Serub:" />
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table width="100%">
                            <tr>
                              <td>
                                <table width="50%">
                                  <tr>
                                    <td style={{ padding: '4px 0' }}>
                                      <span>Other:</span>
                                    </td>
                                    <td style={{ paddingRight: '20px' }}>
                                      <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                    <table width="100%" style={{ borderTop: '1px solid #d2ddef' }}>
                      <tr>
                        <td>
                          <table width="100%">
                            <tr>
                              <td colSpan="2" style={{ paddingRight: '4px' }}>
                                <b>Type of Anesthesia:</b>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table width="100%">
                            <tr>
                              <td>
                                <table width="100%">
                                  <tr>
                                    <td style={{ padding: '4px 0' }}>
                                      <input type="checkbox" />
                                      <span>MAC</span>
                                    </td>
                                    <td style={{ padding: '4px 0' }}>
                                      <input type="checkbox" />
                                      <span>Local</span>
                                    </td>
                                    <td style={{ padding: '4px 0' }}>
                                      <input type="checkbox" />
                                      <span>Block</span>
                                    </td>
                                    <td style={{ padding: '4px 0' }}>
                                      <input type="checkbox" />
                                      <span>General</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2" style={{ paddingRight: '4px' }}>
                                      <b>Ocular Anesthesia:</b>
                                      <span>(check all the apply)</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2" style={{ paddingRight: '4px' }}>
                                      <input type="checkbox" />
                                      <span style={{ paddingLeft: '6px', display: 'inlineBlock' }}>
                                        Topical w/Tetracaine 0.5%
                                        <input style={{ width: '54px', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                                        gtts.
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2" style={{ paddingRight: '4px' }}>
                                      <input type="checkbox" />
                                      <span style={{ paddingLeft: '6px', display: 'inlineBlock' }}>
                                        Topical w/Tetracaine 0.5%
                                        <input style={{ width: '54px', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                                        gtts.
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ padding: '4px 0' }}>
                                      <input type="checkbox" />
                                      <span>Retrobulbar</span>
                                    </td>
                                    <td style={{ padding: '4px 0' }}>
                                      <input type="checkbox" />
                                      <span>Perlbulbar Injection</span>
                                    </td>
                                  </tr>
                                  <TableRow />
                                  <TableRow />
                                  <TableRow />
                                  <TableRow />
                                  <tr>
                                    <td style={{ padding: '4px 0' }}>
                                      <span>Block done by: </span>
                                      <input type="checkbox" />
                                      <span>Surgeon</span>
                                      <input type="checkbox" />
                                      Othre
                                      <input style={{ width: '100px', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                    <table width="100%" style={{ borderTop: '1px solid #d2ddef' }}>
                      <tr>
                        <td>
                          <table width="100%">
                            <tr>
                              <td colSpan="2" style={{ paddingRight: '4px' }}>
                                <b>Strikers:</b>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table width="100%">
                            <tr>
                              <td>
                                <table width="100%">
                                  <tr>
                                    <td>
                                      <span style={{
                                        marginLeft: '240px',
                                        display: 'inline-block',
                                        width: '200px',
                                        height: '54px',
                                        border: '3px solid #d2ddef',
                                        textAlign: 'center',
                                        paddingTop: '14px',
                                        borderRadius: '4px',
                                      }}
                                      >
                                        Implant (s)
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <span style={{
                                        display: 'inline-block', width: '200px', height: '54px', border: '3px solid #d2ddef', textAlign: 'center', paddingTop: '14px', borderRadius: '4px',
                                      }}
                                      >
                                        Patient ID
                                      </span>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
        <table style={{ fontSize: '12px' }}>
          <tr>
            <td style={{ paddingRight: '4px', paddingTop: '16px' }}>
              <b>Medications:</b>
              <span>( check all that apply )</span>
            </td>
          </tr>
          <tr>
            <td style={{ paddingRight: '4px' }}>
              <input type="checkbox" />
              <span style={{ paddingLeft: '6px', display: 'inlineBlock' }}>
                BSS Plain/Plus 500cc, Lot #
                <input
                  style={{
                    width: '54px', border: '1px solid #d2ddef', padding: '0 4px', margin: '0 4px',
                  }}
                  type="text"
                />
                w/ept 1:1000,w/Vanco50mg/ml
                <input
                  style={{
                    width: '54px', border: '1px solid #d2ddef', padding: '0 4px', margin: '0 4px',
                  }}
                  type="text"
                />
                cc.
              </span>
            </td>
          </tr>
        </table>

        <table width="100%">
          <tr>
            <td>
              <table width="100%">
                <tr>
                  <td colSpan="2" style={{ paddingRight: '4px' }}>
                    <b>Viscoelasticst:</b>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table width="100%">
                <tr>
                  <td>
                    <table width="100%">
                      <TableRowWithMutipleCheckbox span1="Right" span2="Left" span3="Both" />
                      <TableRowWithMutipleCheckbox span1="Contract" span2="Pteryglum" span3="Btepharoptosis" />
                      <TableRowWithMutipleCheckbox span1="Rentinal Datechment" span2="Macular Pucker" span3="Macufar Hole" />
                      <tr>
                        <td style={{ padding: '4px 0' }}>
                          <input type="checkbox" />
                          <span>Other</span>
                          <input
                            style={{
                              width: '70px', border: '1px solid #d2ddef', padding: '0 4px', margin: '0 4px',
                            }}
                            type="text"
                          />
                          cc.
                        </td>
                        <td style={{ padding: '4px 0' }}>
                          <div style={{ display: 'flex' }}>
                            <span style={{ display: 'inline-block', paddingRight: '12px' }}>Signature</span>
                            <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                          </div>
                        </td>

                      </tr>
                      <tr>
                        <td style={{ padding: '4px 0' }}>
                          <input type="checkbox" />
                          <span>Other</span>
                        </td>
                        <td style={{ padding: '4px 0' }}>
                          <div style={{ display: 'flex' }}>
                            <span style={{ display: 'inline-block', paddingRight: '12px' }}>Signature</span>
                            <input style={{ width: '100%', border: '1px solid #d2ddef', padding: '0 4px' }} type="text" />
                          </div>
                        </td>

                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
);

export default Intraop;
