import { useEffect } from 'react';
import isFunction from 'lodash/isFunction';
import useCRUDWithoutTab from '../../../hooks/useCRUDWithoutTab';
import Notification from '../../../components/Notification';

const useUpdateAppointmentStatus = ({
  id, url, callBack, type,
}) => {
  const [response, error, loading, updateAppointmentStatus, clearResponse] = useCRUDWithoutTab({
    id, url, type: type || 'update',
  });

  useEffect(() => {
    if (response) {
      if (isFunction(callBack)) callBack(response);
      clearResponse();
    }
  }, [callBack, response]);

  useEffect(() => {
    if (error) {
      Notification({ message: error });
      clearResponse();
    }
  }, [clearResponse, error]);

  return [updateAppointmentStatus, loading];
};

export default useUpdateAppointmentStatus;
