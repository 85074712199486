import React, {
  useCallback, useEffect, useState, useMemo,
} from 'react';
import debounce from 'lodash/debounce';

import useCRUD from '../../../../hooks/useCRUD';
import useRedirect from '../../../../hooks/useRedirect';

import Events from '../../../../lib/events';
import { apiUrls } from '../../../../api/constants';
import SuccessMessages from '../../../../lib/successMessages';
import ErrorMessages from '../../../../lib/errorMessages';

import Icon from '../../../../components/Icon';
import Form from '../../../../components/Form';
import Button from '../../../../components/Button';
import Search from '../../../../components/Search';
import RadioGroup from '../../../../components/RadioGroup';
import WidgetLoader from '../../../../components/WidgetLoader';
import Notification from '../../../../components/Notification';
import ConfirmDialog from '../../../../components/ConfirmDialog';

import DroppableJsonTree from './Components/JsonTree';
import GlobalPeriodModal from '../../Components/PatientDetails/Components/Tabs/CustomDropppableTab/Component/globalPeriodModal';

import { libraryParser, procedureSetParser as parser } from '../../commonParser';

const tabList = [
  { label: 'Practice', name: 'practice', value: 'practice' },
  { label: 'Provider', name: 'provider', value: 'provider' },
  { label: 'Library', name: 'library', value: 'library' },
];

const ProcedureSets = ({
  procedureSetsList, setProcedureSetsList, setFormData, setSelectedICDCodes,
}) => {
  const { params: { providerId, encounterId, patientId } } = useRedirect();
  const [currentTab, setCurrentTab] = useState('provider');
  const [activeIds, setActiveIds] = useState(false);
  const [showHideCount, setShowHide] = useState(0);
  const [libraryList, setLibraryList] = useState();
  const [showAllExpanded, setShowAllExpanded] = useState('');
  const [applyProcedureSetPayload, setApplyProcedureSetPayload] = useState({});

  const [
    copyProcedureSets,,
    copyLoading,
    copyProcedures,
    clearCopyProcedureSets,
  ] = useCRUD({
    id: apiUrls.COPY_PROVIDERS_FAVORITE_SET,
    url: apiUrls.COPY_PROVIDERS_FAVORITE_SET,
    type: 'create',
  });

  useEffect(() => {
    if (copyProcedureSets) {
      Notification({
        message: SuccessMessages.PROCEDURE_SET_COPY_FAVORITE_SUCCESSFULLY,
        success: true,
      });
    }
    clearCopyProcedureSets(true);
  }, [clearCopyProcedureSets, copyProcedureSets]);

  const [
    applyProcedureSetResponse,
    applyProcedureSetError,
    applyLoading,
    applyProcedureSet,
    clearProcedureSetResponse,
  ] = useCRUD({
    id: 'apply-procedure-set',
    url: apiUrls.APPLY_PROCEDURE_ORDER_SET,
    type: 'create',
    shouldClearError: false,
  });

  useEffect(() => {
    if (applyProcedureSetResponse) {
      Notification({ message: SuccessMessages.PROCEDURE_SET_APPLIED_SUCCESSFULLY, success: true });
      Events.trigger('reFetchProcedures');
      Events.trigger(`refetch-InOffice-ProcedureList-${encounterId}`);
      clearProcedureSetResponse(true);
    }
  }, [applyProcedureSetResponse, encounterId]);

  const [
    deleteResponse,,
    deleteLoading,
    deleteInOfficeProcedureSet,
    clearDeleteResponse] = useCRUD({
    id: 'delete-procedure-set',
    url: apiUrls.DELETE_FAVOURITE_SET,
    type: 'create',
  });

  const [
    inOfficeProcedureSets,,
    loading,
    getInOfficeProcedureSet,
    clearInofficeProcedures,
  ] = useCRUD({
    id: 'get-procedure-set',
    url: apiUrls.GET_IN_OFFICE_PROCEDURE_SETS,
    type: 'read',
  });

  useEffect(() => {
    getInOfficeProcedureSet({ IsActive: true, ProviderId: providerId, source: 'ProcedureSet' });
  }, []);

  const debounceFunction = useMemo(() => debounce((value) => {
    const data = { IsActive: true, source: 'ProcedureSet' };
    if (currentTab === 'provider') {
      data.providerId = providerId;
    }
    if (currentTab === 'library') {
      data.ProviderId = providerId;
      data.isLibrary = true;
    }
    data.SearchText = value;
    setShowAllExpanded(value);
    getInOfficeProcedureSet(data);
  }, 1500), [currentTab, getInOfficeProcedureSet, providerId]);

  const onSearch = useCallback((event) => {
    event.persist();
    if (event?.target?.value?.trim?.()?.length) {
      debounceFunction(event?.target?.value?.trim?.());
    } else {
      debounceFunction('');
    }
  }, [debounceFunction]);

  const deleteProcedureSet = useCallback((node, event) => {
    event.stopPropagation();
    if (node) {
      ConfirmDialog({
        onOk: (close) => {
          deleteInOfficeProcedureSet({ data: { favoriteId: node } });
          close();
        },
        okText: 'Ok',
        title: 'Warning',
        content: 'Are you sure, you want to delete this procedure set?',
        icon: <Icon name="ExclamationCircleOutlined" />,
      })();
    }
  }, [deleteInOfficeProcedureSet]);

  useEffect(() => {
    if (deleteResponse) {
      Notification({ message: 'Procedure set has been deleted successfully', success: true });
      clearDeleteResponse(true);
      const data = { IsActive: true, source: 'ProcedureSet' };
      if (currentTab === 'provider') {
        data.providerId = providerId;
      }
      getInOfficeProcedureSet(data);
    }
  }, [clearDeleteResponse, deleteResponse, currentTab, getInOfficeProcedureSet, providerId]);

  useEffect(() => {
    if (inOfficeProcedureSets && currentTab !== 'library') {
      setProcedureSetsList(parser(inOfficeProcedureSets));
    }
    if (inOfficeProcedureSets && currentTab === 'library') {
      setLibraryList(libraryParser(inOfficeProcedureSets));
    }
  }, [inOfficeProcedureSets, setProcedureSetsList]);

  const onNodeClick = useCallback((node, diagnosisList) => () => {
    setFormData(node);
    const ICDObject = {};
    // eslint-disable-next-line no-unused-expressions
    diagnosisList?.forEach((element) => {
      ICDObject[element.id] = element;
    });
    setSelectedICDCodes(ICDObject);
  }, [setFormData, setSelectedICDCodes]);

  useEffect(() => {
    Events.on(`fetch-procedure-sets-${encounterId}`, `fetch-procedure-sets-${encounterId}`, () => {
      const data = { IsActive: true, source: 'ProcedureSet' };
      if (currentTab === 'provider') {
        data.providerId = providerId;
      }
      getInOfficeProcedureSet(data);
    });
    return Events.remove(`fetch-procedure-sets-${encounterId}`);
  }, [encounterId, providerId, currentTab]);

  const tabSwitchHandler = useCallback(({ target: { value } }) => {
    setCurrentTab(value);
    clearInofficeProcedures(true);
    const data = { IsActive: true, source: 'ProcedureSet' };
    if (value === 'provider') {
      data.providerId = providerId;
    }
    if (value === 'library') {
      data.providerId = providerId;
      data.isLibrary = true;
    }
    getInOfficeProcedureSet(data);
  }, [clearInofficeProcedures, getInOfficeProcedureSet, providerId]);

  const applyButtonClick = useCallback((event, id) => {
    event.stopPropagation();
    const payload = {
      FavoriteId: id,
      PatientId: patientId,
      EncounterId: encounterId,
      providerId,
    };
    setApplyProcedureSetPayload(payload);
    applyProcedureSet({
      data: payload,
    });
  }, [applyProcedureSet, encounterId, patientId, providerId]);

  useEffect(() => {
    if (applyProcedureSetError?.toLowerCase()?.includes(ErrorMessages?.SAME_PROCEDURE_ERROR)
        || applyProcedureSetError?.toLowerCase()?.includes(ErrorMessages?.TIME_ERROR_FOR_PROCEDURE)
        || applyProcedureSetError?.toLowerCase()
        ?.includes(ErrorMessages?.ERROR_FOR_ALREADY_USED_PROCEDURE)) {
      GlobalPeriodModal(
        applyProcedureSetError,
        applyProcedureSet,
        applyProcedureSetPayload,
        clearProcedureSetResponse,
      );
    } else if (applyProcedureSetError) {
      Notification({ message: applyProcedureSetError, success: false });
      clearProcedureSetResponse(true);
    }
  }, [applyProcedureSetError]);

  const showHideOrderSets = useCallback((val) => {
    if (val) {
      setActiveIds(true);
    } else {
      setActiveIds(false);
    }
    setShowHide(showHideCount + 1);
  }, [showHideCount]);

  const copyProviderAllProcedureSets = useCallback((data, event) => {
    event.stopPropagation();
    copyProcedures({ data });
  }, [copyProcedures]);

  return (
    <div className="flex flex-dir-column mr-top-8">
      <div className="pannel">
        <div className="pannel-heading">Procedure Sets</div>
      </div>
      <div className="mr-bt-8 flex justify-content-flex-end">
        <RadioGroup
          tabList={tabList}
          onChange={tabSwitchHandler}
          value={currentTab}
          id="overview_procedure_tabs"
        />
      </div>
      <div className="posi-relative procedure-right-section overview-tree-ui">
        { (deleteLoading || loading || applyLoading || copyLoading)
        && <WidgetLoader />}
        <div className="side-tab">
          <div className="merge-fields-container">
            <div className="temp-search-wrapper">
              <Form.Section noOfColumns={1}>
                <Form.Column>
                  <Search name="searchText" onChange={onSearch} style={{ width: '100%' }} />
                </Form.Column>
              </Form.Section>
            </div>
            <div className="wrap-expend-collapse">
              <Button className="btn btn btn-ghost sm-btn" onClick={() => showHideOrderSets(true)}>Expand All</Button>
              <Button className="btn btn btn-ghost sm-btn" onClick={() => showHideOrderSets(false)}>Collapse All</Button>
            </div>
            <div className=" mr-top-8 json-tree-container">
              <DroppableJsonTree
                setLabelsList={setProcedureSetsList}
                labelList={procedureSetsList}
                selectable={false}
                draggable={false}
                deleteOrderSet={deleteProcedureSet}
                onClick={onNodeClick}
                onApplyButtonClick={applyButtonClick}
                disableParentCloseIcon
                activeIds={activeIds}
                showHideCount={showHideCount}
                currentTab={currentTab}
                isLibrary={currentTab === 'library'}
                libraryList={libraryList}
                copyProviderAllProcedureSets={copyProviderAllProcedureSets}
                showAllExpanded={showAllExpanded}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcedureSets;
