import React, { useCallback } from 'react';

import { UiRoutes, labelPaths } from '../../../../lib/constants';
import rights from '../../../../lib/rights';

import useRedirect from '../../../../hooks/useRedirect';
import useRights from '../../../../hooks/useRights';
import withLabel from '../../../../hoc/withLabel';
import SavedNavigationBar from '../../../../components/SavedNavigationBar';

import General from './General';

function EditUser({ labels }) {
  const {
    push,
  } = useRedirect();

  const navigateToForm = useCallback(() => push(UiRoutes.users), [push]);

  const [
    isUserRightsViewOnly,
    isUserRightsAuthenticated,
  ] = useRights([
    rights.user_rights_view_only,
    rights.user_rights,
  ]);

  return (
    <SavedNavigationBar
      listName={labels.get('panelName.name')}
      parentPath={UiRoutes.users}
      title={(<h1>Users</h1>)}
    >
      <div className="main-form-container">
        <General
          navigateToForm={navigateToForm}
          isUserRightsViewOnly={isUserRightsViewOnly && !isUserRightsAuthenticated}
        />
      </div>
    </SavedNavigationBar>
  );
}

export default withLabel(EditUser, labelPaths.EDIT_USER);
