/* eslint-disable import/no-cycle */
import React, {
  useCallback, useEffect, useState, useMemo, useRef,
} from 'react';
import debounce from 'lodash/debounce';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';

import useCRUD from '../../../../../../../../../../hooks/useCRUD';
import useRedirect from '../../../../../../../../../../hooks/useRedirect';

import Events from '../../../../../../../../../../lib/events';
import { getuuid } from '../../../../../../../../../../lib/util';
import { apiUrls } from '../../../../../../../../../../api/constants';
import SuccessMessages from '../../../../../../../../../../lib/successMessages';
import ErrorMessages from '../../../../../../../../../../lib/errorMessages';

import Icon from '../../../../../../../../../../components/Icon';
import Button from '../../../../../../../../../../components/Button';
import Search from '../../../../../../../../../../components/Search';
import RadioGroup from '../../../../../../../../../../components/RadioGroup';
import Notification from '../../../../../../../../../../components/Notification';
import WidgetLoader from '../../../../../../../../../../components/WidgetLoader';
import ConfirmDialog from '../../../../../../../../../../components/ConfirmDialog';
import GlobalPeriodModal from '../../../../CustomDropppableTab/Component/globalPeriodModal';

import { libraryParser } from '../../../../../../../../commonParser';
import DroppableJsonTree from '../../../../Procedures/RightTab/Tabs/ProcedureSets/Components/DroppableJsonTree';
import LibraryDroppableTree from '../../../../Procedures/RightTab/Tabs/ProcedureSets/Components/DroppableJsonTree/LibraryDroppableTree';
import ApplyExamFindingModal from './applyExamFindingModal';
import './customDroppableTab.scss';
import sortWithoutSymbols from '../../../../../../../../../../lib/sortWithoutSymbols';

export const parser = (orderSets) => {
  const parsed = [];
  orderSets?.[0]?.tags?.[0]?.favourites?.forEach((data) => {
    parsed.push({
      id: data.favouriteId,
      orderSetId: data.favouriteId,
      orderSetName: data.favouriteName?.toLowerCase(),
      key: getuuid(),
      copiedId: data.copiedId,
      createdOn: data?.createdOn,
      fromProviderName: data?.fromProviderName,
      assessmentNotes: data?.notes,
      followUp: data?.followUp,
      fundusExamList: data?.fundusExamList,
      slitExamList: data?.slitExamList,
      isFollowUpExist: data?.isFollowUpExist,
      isExamExist: !!(data?.isFundusExamExist || data?.isSlitExamExist),
    });
  });
  return orderBy(parsed, (item) => sortWithoutSymbols(item.orderSetName), 'asc');
};

const tabList = [
  { label: 'Practice', name: 'practice', value: 'practice' },
  { label: 'Provider', name: 'provider', value: 'provider' },
  { label: 'Library', name: 'library', value: 'library' },
];

const OrderSets = ({
  setProcedureSetsList,
  procedureSetsList,
  searchProps,
  isAAndP,
  isOverviewOrderSet,
}) => {
  const [currentTab, setCurrentTab] = useState('provider');
  const { params: { providerId, encounterId, patientId } } = useRedirect();
  const [activeIds, setActiveIds] = useState(false);
  const [showHideCount, setShowHide] = useState(0);
  const [libraryList, setLibraryList] = useState();
  const [showAllExpended, setShowAllExpended] = useState('');
  const [examFindingModalState, setExamFindingModalState] = useState({ visible: false, id: null });
  const [applyOrderSetPayload, setApplyOrderSetPayload] = useState({});
  const appliedOrderSetInfo = useRef({});

  const getProcedureHistory = useCRUD({ id: 'procedure-history-table-crud-data', url: apiUrls.DOCTOR_PROCEDURES, type: 'read' })[3];
  const [
    orderSetList,,
    loading,
    getOrderSetList,
    clearResponse] = useCRUD({
    id: 'get-order-set-list-ap',
    url: apiUrls.GET_IN_OFFICE_PROCEDURE_SETS,
    type: 'read',
  });

  const [
    deleteResponse,,
    deleteLoading,
    deleteOrderSet,
    clearDeleteResponse] = useCRUD({
    id: 'delete-order-set-ap',
    url: apiUrls.DELETE_FAVOURITE_SET,
    type: 'create',
  });

  const [
    applyOrderSetResponse, applyOrderSetError,
    applyOrderSetLoading,
    applyOrderSet,
    clearApplyOrderSetResponse,
  ] = useCRUD({
    id: 'apply-order-set-button',
    url: apiUrls.APPLY_AP_ORDER_SET,
    type: 'create',
    shouldClearError: false,
  });

  const getProcedureList = useCRUD({
    id: 'get-diagnosis-procedure-list',
    url: apiUrls.GET_DIAGNOSIS_PROCEDURE_LIST,
    type: 'read',
  })[3];

  const [
    copyProcedureSets,,
    copyLoading,
    copyProcedures,
    clearCopyProcedureSets,
  ] = useCRUD({
    id: apiUrls.COPY_PROVIDERS_FAVORITE_SET,
    url: apiUrls.COPY_PROVIDERS_FAVORITE_SET,
    type: 'create',
  });

  useEffect(() => {
    if (copyProcedureSets) {
      Notification({
        message: SuccessMessages.ORDER_SET_COPY_FAVORITE_SUCCESSFULLY,
        success: true,
      });
    }
    clearCopyProcedureSets(true);
  }, [clearCopyProcedureSets, copyProcedureSets]);

  useEffect(() => {
    if (applyOrderSetResponse) {
      Notification({ message: SuccessMessages.ORDER_SET_APPLIED_SUCCESSFULLY, success: true });
      setExamFindingModalState({ visible: false, id: null });
      if (!isEmpty(appliedOrderSetInfo?.current)) {
        /** for physician  and billing tab */
        const phys = document.getElementById('Phys');
        if (phys) {
          document.getElementById('Phys').style.color = '#32c788';
        }
        const billing = document.getElementById('Billing');
        if (billing) {
          document.getElementById('Billing').style.color = '#32c788';
        }
      }
      getProcedureList({ encounterId, source: 'AAndP' });
      Events.trigger('refetch-encounter-notes-overview');
      getProcedureHistory({
        SortBy: 'createdOn',
        SortOrder: 'desc',
        PatientId: patientId,
        EncounterID: encounterId,
        Source: 'Overview',
        PageIndex: 0,
        PageSize: 999,
      });
      if (isOverviewOrderSet) {
        Events.trigger('reFetchFollowUp');
        Events.trigger('reFetchSlitExam');
        Events.trigger('reFetchFundusExam');
        Events.trigger('refetch-followup-planned-procedures');
        Events.trigger('reFetchProcedures');
        Events.trigger('reFetchOcularProblem');
        Events.trigger('reFetchOutsideTesting');
        Events.trigger('reFetchAssessmentValue');
        Events.trigger('reFetchProceduresSelect');
        Events.trigger('refetch-encounter-notes-overview');
        Events.trigger(`refetch-InOffice-ProcedureList-${encounterId}`);
      }
      appliedOrderSetInfo.current = {};
      clearApplyOrderSetResponse();
    }
  }, [applyOrderSetResponse]);

  useEffect(() => {
    if (orderSetList && currentTab !== 'library') {
      setProcedureSetsList(parser(orderSetList));
    }
    if (orderSetList && currentTab === 'library') {
      setLibraryList(libraryParser(orderSetList));
    }
  }, [orderSetList, setProcedureSetsList]);

  useEffect(() => {
    if (deleteResponse) {
      Notification({ message: 'Order-set has been deleted successfully', success: true });
      clearResponse(true);
      const data = {};
      if (currentTab === 'provider') {
        data.providerId = providerId;
      }
      getOrderSetList({ ...data, source: 'OrderSet' });
      clearDeleteResponse();
    }
  }, [deleteResponse, currentTab, clearResponse, getOrderSetList, clearDeleteResponse, providerId]);

  useEffect(() => {
    if (providerId) {
      getOrderSetList({ ProviderId: providerId, source: 'OrderSet' });
    }
  }, [getOrderSetList, patientId, providerId]);

  useEffect(() => {
    Events.on(`fetch-order-sets-${encounterId}`, `fetch-order-sets-${encounterId}`, () => {
      const data = {};
      if (currentTab === 'provider') {
        data.ProviderId = providerId;
      }
      getOrderSetList({ ...data, source: 'OrderSet' });
    });
    return () => Events.remove(`fetch-order-sets-${encounterId}`);
  }, [encounterId, providerId, currentTab]);

  const debounceFunction = useMemo(() => debounce((value) => {
    const data = { source: 'OrderSet' };
    if (currentTab === 'provider') {
      data.ProviderId = providerId;
    }
    if (currentTab === 'library') {
      data.ProviderId = providerId;
      data.isLibrary = true;
    }
    data.SearchText = value;
    setShowAllExpended(value);
    getOrderSetList(data);
  }, 1500), [currentTab, getOrderSetList, providerId]);

  const onSearch = useCallback((event) => {
    event.persist();
    if (event?.target?.value?.trim?.()) {
      debounceFunction(event?.target?.value?.trim?.());
    } else {
      debounceFunction('');
    }
  }, [debounceFunction]);
  const handleDeleteSet = useCallback((key) => {
    ConfirmDialog({
      onOk: (close) => {
        deleteOrderSet({ data: { favoriteId: key } });
        close();
      },
      okText: 'Ok',
      title: 'Warning',
      content: 'Are you sure, you want to delete this order set?',
      icon: <Icon name="ExclamationCircleOutlined" />,
    })();
  }, [deleteOrderSet]);

  const tabSwitchHandler = useCallback(({ target: { value } }) => {
    clearResponse(true);
    setCurrentTab(value);
    const data = { source: 'OrderSet' };
    if (value === 'provider') {
      data.ProviderId = providerId;
    }
    if (value === 'library') {
      data.ProviderId = providerId;
      data.isLibrary = true;
    }
    getOrderSetList(data);
  }, [clearResponse, getOrderSetList, providerId]);

  const toggleApplyExamFindingModal = useCallback(() => {
    setExamFindingModalState({ visible: false, id: null });
  }, []);

  const onNodeClick = useCallback((event, id, isFollowUpExist = false, isExamExist = false) => {
    event.stopPropagation();
    if (isFollowUpExist && isExamExist) {
      appliedOrderSetInfo.current = { isFollowUpExist, isExamExist };
    }
    if (isExamExist) {
      setExamFindingModalState({ visible: true, id });
      return true;
    }
    const payload = {
      FavoriteId: id,
      PatientId: patientId,
      EncounterId: encounterId,
    };
    setApplyOrderSetPayload(payload);
    applyOrderSet({
      data: payload,
    });
    return true;
  }, [applyOrderSet, encounterId, patientId]);

  useEffect(() => {
    if (applyOrderSetError?.toLowerCase()?.includes(ErrorMessages?.SAME_PROCEDURE_ERROR)
    || applyOrderSetError?.toLowerCase()?.includes(ErrorMessages?.TIME_ERROR_FOR_PROCEDURE)
    || applyOrderSetError?.toLowerCase()
    ?.includes(ErrorMessages?.ERROR_FOR_ALREADY_USED_PROCEDURE)) {
      GlobalPeriodModal(
        applyOrderSetError,
        applyOrderSet,
        applyOrderSetPayload,
        clearApplyOrderSetResponse,
      );
    } else if (applyOrderSetError) {
      Notification({ message: applyOrderSetError, success: false });
      clearApplyOrderSetResponse(true);
    }
  }, [applyOrderSetError, applyOrderSetPayload]);

  const showHideOrderSets = useCallback((val) => {
    if (val) {
      setActiveIds(true);
    } else {
      setActiveIds(false);
    }
    setShowHide(showHideCount + 1);
  }, [showHideCount]);

  const copyProviderAllProcedureSets = useCallback((data, event) => {
    event.stopPropagation();
    copyProcedures({ data });
  }, [copyProcedures]);

  return (
    <div className="side-tab posi-relative">
      {(loading || deleteLoading || applyOrderSetLoading || copyLoading) && <WidgetLoader />}
      <div className="mr-bt-8 flex justify-content-flex-end">
        <RadioGroup
          tabList={tabList}
          onChange={tabSwitchHandler}
          value={currentTab}
          id="contract_terms_tabs"
        />
      </div>
      <div className="merge-fields-container">
        <div className="temp-search-wrapper">
          <Search
            placeholder="Search Fields"
            onChange={onSearch}
            style={searchProps}
          />
        </div>
        <div className="wrap-expend-collapse">
          <Button className="btn btn btn-ghost sm-btn" onClick={() => showHideOrderSets(true)}>Expand All</Button>
          <Button className="btn btn btn-ghost sm-btn" onClick={() => showHideOrderSets(false)}>Collapse All</Button>
        </div>
        <div className="json-tree-container order-set-tree-wrap">
          {currentTab === 'library'
            ? (
              <LibraryDroppableTree
                type="orderSets"
                showFollowUpExamFinding
                libraryList={libraryList}
                activeIds={activeIds}
                showHideCount={showHideCount}
                copyProviderAllProcedureSets={copyProviderAllProcedureSets}
              />
            ) : (
              <DroppableJsonTree
                labelList={procedureSetsList || []}
                type="orderSets"
                deleteOrderSet={handleDeleteSet}
                showFollowUpExamFinding
                activeIds={activeIds}
                showHideCount={showHideCount}
                onNodeClick={onNodeClick}
                currentTab={currentTab}
                showAllExpended={showAllExpended}
                showCloseIcon={false}
                isAAndP={isAAndP}
              />
            )}
        </div>
      </div>
      {examFindingModalState?.visible && (
        <ApplyExamFindingModal
          isVisible={examFindingModalState.visible}
          favouriteId={examFindingModalState.id}
          patientId={patientId}
          encounterId={encounterId}
          toggleModal={toggleApplyExamFindingModal}
          applyOrderSet={applyOrderSet}
          setApplyOrderSetPayload={setApplyOrderSetPayload}
        />
      )}
    </div>
  );
};

export default OrderSets;
