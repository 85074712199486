
import React, {
  useCallback, useEffect, useMemo,
  useState,
} from 'react';
import { Form as AntdForm } from 'antd';
import classNames from 'classnames';

import get from 'lodash/get';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';

import Form from '../../../../components/Form';
import WithLabel from '../../../../hoc/withLabel';
import { labelPaths } from '../../../../lib/constants';
import ICDLookup from '../../../CPTICDAutoComplete';
import { apiUrls } from '../../../../api/constants';
import Notification from '../../../../components/Notification';
import useCRUD from '../../../../hooks/useCRUD';
import useRedirect from '../../../../hooks/useRedirect';

import Loader from '../../../../components/WidgetLoader';
import useRights from '../../../../hooks/useRights';
import rights from '../../../../lib/rights';
import Events from '../../../../lib/events';
import useReduxStoreWithId from '../../../../hooks/useReduxStoreWithId';

const noOfColumns = 2;
const cptCodeColumn1 = [
  {
    name: 1,
    label: 'one',
  },
  {
    name: 2,
    label: 'two',
  },
  {
    name: 3,
    label: 'three',
  },
  {
    name: 4,
    label: 'four',
  },
  {
    name: 5,
    label: 'five',
  },
  {
    name: 6,
    label: 'six',
  },
];

const cptCodeColumn2 = [{
  name: 7,
  label: 'seven',
}, {
  name: 8,
  label: 'eight',
},
{
  name: 9,
  label: 'nine',
},
{
  name: 10,
  label: 'ten',
},
{
  name: 11,
  label: 'eleven',
},
{
  name: 12,
  label: 'twelve',
},
];

const Diagnosis = React.memo(({
  labels,
  setDiagnosesCodes,
  diagnosesCodes,
  handleParentDiagonsisChange,
  isCollectionCharges,
  lineItemsData,
}) => {
  const [form] = AntdForm.useForm();
  const { params } = useRedirect();
  const {
    id, encounterId, tabId, type, claimFilter,
  } = params;
  const [disabledCpt, setDisabledCpt] = useState({});
  const [customDiagnosisCodeRedux,, setCustomDiagnosisCode] = useReduxStoreWithId({ id: 'customDiagnosisCodeRedux-Encounter-Diagnosis' });
  const customDiagnosisCode = customDiagnosisCodeRedux?.get('data') || {};
  const [claimServiceLineItemReduxData] = useReduxStoreWithId({ id: `claim-service-line-item-${tabId}` });
  const claimServiceLineItemData = claimServiceLineItemReduxData?.get('data') || {};

  const [isEnterBillingChargesPermitted] = useRights([rights.enter_charges]);
  const onSelect = useCallback((value, item) => {
    const { name } = item;
    if (customDiagnosisCode[value?.icdCode]
      && name !== customDiagnosisCode[value?.icdCode]?.index) {
      Notification({ message: 'Diagnosis code already selected' });
      form.setFields([{ name, value: null }]);
      return;
    }
    if (diagnosesCodes?.[name]) {
      handleParentDiagonsisChange(diagnosesCodes[name]);
    }
    setDiagnosesCodes({ ...diagnosesCodes, [name]: value });
  }, [customDiagnosisCode, diagnosesCodes, form, setDiagnosesCodes, handleParentDiagonsisChange]);

  const [
    lastUseDiagnosis,,
    lastUseDiagnosisLoading,
    getLastUseDiagnosis,
    clearLastUseDiagnosis,
  ] = useCRUD({
    id: apiUrls.GET_USE_LAST_DIAGNOSIS + encounterId,
    url: apiUrls.GET_USE_LAST_DIAGNOSIS,
    type: 'read',
  });

  useEffect(() => {
    const codes = {};
    if (diagnosesCodes) {
      forEach(Object.values(diagnosesCodes), (item) => {
        codes[item?.icdCode] = { ...item, index: Object.keys(codes).length + 1 };
      });
    }
    setCustomDiagnosisCode(codes);
  }, [diagnosesCodes]);

  useEffect(() => {
    if (lastUseDiagnosis) {
      const mappedDiagnosisCodes = {};
      forEach(lastUseDiagnosis, (item, index) => {
        mappedDiagnosisCodes[(index + 1)] = {
          code: item.icdCode,
          desc: item.icdCodeDescription,
          description: item.icdCodeDescription,
          icdCode: item.icdCode,
          id: item.icD10CodeId,
          isActive: true,
          name: item.icdCode,
          value: item.icD10CodeId,
        };
      });
      setDiagnosesCodes({ ...mappedDiagnosisCodes });
      clearLastUseDiagnosis();
    }
  }, [clearLastUseDiagnosis, lastUseDiagnosis, setDiagnosesCodes]);

  useEffect(() => {
    if (!isEmpty(diagnosesCodes)) {
      const keys = Object.keys(diagnosesCodes);
      const dxIndexKeys = [...cptCodeColumn1, ...cptCodeColumn2]
        .map((dxItem) => dxItem?.name).filter((key) => !keys.includes(`${key}`));

      const codes = {};
      forEach(Object.values(diagnosesCodes), (item) => {
        codes[item?.icdCode] = item;
      });
      const tempData = [];
      forEach(Object.values(codes), (item, index) => {
        tempData.push({ name: keys[index], value: item?.id });
      });
      dxIndexKeys.forEach((item) => tempData.push({ name: item, value: undefined }));
      form.setFields(tempData);
    } else {
      form.resetFields();
    }
  }, [diagnosesCodes]);

  useEffect(() => {
    if (lineItemsData?.length && isCollectionCharges && type === 'Denial' && claimFilter !== 'completed') {
      const disableCptObject = {};
      lineItemsData?.forEach((item) => {
        if (!claimServiceLineItemData[item?.billingEncounterServiceLineId]) {
          item?.diagnosesCodes?.forEach((newItem) => {
            disableCptObject[newItem?.icD10CodeId] = true;
          });
        }
      });
      setDisabledCpt(disableCptObject);
    }
  }, [lineItemsData]);

  const handleLastUseClick = useCallback(() => {
    getLastUseDiagnosis({ patientId: id, encounterId });
  }, [encounterId, getLastUseDiagnosis, id]);

  const handleClear = useCallback(() => {
    setDiagnosesCodes({ });
  }, [setDiagnosesCodes]);

  const handleSpecificClear = useCallback((name) => () => {
    handleParentDiagonsisChange(diagnosesCodes[name]);
    // eslint-disable-next-line no-param-reassign
    delete diagnosesCodes[name];
    const lastDiagnosisCodeIndex = Object.keys(diagnosesCodes || {}).pop();
    const keyValues = Object.keys(diagnosesCodes || {}).map((dxCodeNameIndex) => {
      if (dxCodeNameIndex > name) {
        Events.trigger(`resetForm-${dxCodeNameIndex - 1}`);
        return { [dxCodeNameIndex - 1]: diagnosesCodes[dxCodeNameIndex] };
      }
      return { [dxCodeNameIndex]: diagnosesCodes[dxCodeNameIndex] };
    });
    if (lastDiagnosisCodeIndex) {
      Events.trigger(`resetForm-${lastDiagnosisCodeIndex}`);
    }
    const newDiagnosesCodes = Object.assign({}, ...keyValues);
    setDiagnosesCodes({ ...newDiagnosesCodes });
  }, [handleParentDiagonsisChange, diagnosesCodes, setDiagnosesCodes]);

  const getCptCodeColumns = useMemo(() => (cptCodeColumn) => cptCodeColumn?.map((item) => {
    const data = get(Object.values(customDiagnosisCode), item.name - 1);
    return (
      <div className="tab-info" key={item.name}>
        <div className="info-row encounter-diagnosis-row">
          <ICDLookup
            label={labels.get(`labels.${item.label}`)}
            placeholder="ICD"
            name={item.name}
            dropdownMatchSelectWidth={500}
            form={form}
            labelSpan="3"
            inputSpan="20"
            style={{
              width: '120px',
              marginRight: '20px',
            }}
            onSelect={(value) => {
              onSelect(value, item);
            }}
            url={apiUrls.GET_SETTINGS_ICD}
            initialValue={data}
            selectProps={{ onClear: handleSpecificClear(item.name) }}
            disabled={(isEnterBillingChargesPermitted ? (item.name !== 1
              && !get(Object.values(customDiagnosisCode), item.name - 2)) : true)
               || (data && isCollectionCharges && disabledCpt[data?.value]) || (isCollectionCharges && claimFilter === 'completed')}
          />
          {data
          && <div className="info">{get(data, 'description')}</div>}
        </div>
      </div>
    );
  }), [claimFilter, customDiagnosisCode, disabledCpt, form, handleSpecificClear,
    isCollectionCharges, isEnterBillingChargesPermitted, labels, onSelect]);

  return (
    <div className="form-container diagnosis-wrapper">
      {lastUseDiagnosisLoading && <Loader />}
      <div className="action-btns">
        <span role="presentation" className={classNames({ 'cursor-not-allowed': isCollectionCharges })} onClick={!isCollectionCharges && handleClear}>{labels.get('buttons.clearAll')}</span>
        <span role="presentation" className={classNames({ 'cursor-not-allowed': isCollectionCharges })} onClick={!isCollectionCharges && handleLastUseClick}>{labels.get('buttons.useLast')}</span>
      </div>
      <div className="demograohic-form-wrap">
        <Form
          form={form}
          noOfColumns={noOfColumns}
          onFinish={() => { /* This is intentional */ }}
          name={`DIAGNOSIS_ENCOUNTER_HISTORY${encounterId}`}
          formId={`DIAGNOSIS_ENCOUNTER_HISTORY${encounterId}`}
        >
          <Form.Column>
            <div className="demographic-form" data-testid="diagnosis-cptSelect1">
              {getCptCodeColumns(cptCodeColumn1)}
            </div>
          </Form.Column>
          <Form.Column>
            <div className="demographic-form" data-testid="diagnosis-cptSelect2">
              {getCptCodeColumns(cptCodeColumn2)}
            </div>
          </Form.Column>
        </Form>
      </div>

    </div>
  );
});

export default WithLabel(Diagnosis, labelPaths.ENCOUNTERS_DEMOGRAPHIC);
