import React, { useCallback } from 'react';
import EventCell from '../EventCell';
import useMakeEventsSerialized from './Hooks/useMakeEventsSerialized';

const EventCollections = ({
  data, onClick, handleCancelModal, collectionsState, ...otherProps
}) => {
  const { startYAt, endYAt, appointments } = data;

  const serializedEvents = useMakeEventsSerialized(appointments);

  const getEventItem = useCallback((item) => ({
    ...item,
    slotPositions: {
      startYAt: (item?.slotPositions?.startYAt ? (item.slotPositions.startYAt - startYAt + 1) : 0),
      endYAt: (item?.slotPositions?.endYAt ? (item.slotPositions.endYAt - startYAt + 1) : 0),
    },
  }), [startYAt]);

  const onEventClick = useCallback((item) => (e) => {
    e.stopPropagation();
    if (e.nativeEvent) {
      const { offsetY } = e.nativeEvent;
      const { slotPositions: { startYAt: Astart } = {} } = item || {};
      const newOffsetY = ((Astart - 1) * 20) + offsetY;
      onClick({ nativeEvent: { offsetY: newOffsetY } });
    }
  }, [onClick]);

  return (
    <div className="grid-event-collections" style={{ gridArea: `${startYAt} / 1 / ${endYAt} / 2`, gridTemplateColumns: `repeat(${serializedEvents.length},1fr)` }}>
      {serializedEvents.map((column, index) => (
        // <div key={index} className="grid-event-column">
        // {
        column.map((item) => (
          <EventCell
            endXAt={item.endXAt}
            startXAt={index}
            item={getEventItem(item)}
            key={item && item.id}
            onClick={onEventClick(item)}
            handleCancelModal={handleCancelModal(item)}
            {...otherProps}
          />
        ))
        // }
        // </div>
      ))}
    </div>
  );
};

EventCollections.defaultProps = {
  data: {},
  onClick: () => { /* This is intentional */ },
  handleCancelModal: () => () => { /* This is intentional */ },
};

export default EventCollections;
