import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { apiUrls } from '../../../../../../api/constants';

import Button from '../../../../../../components/Button';
import AutoComplete from '../../../../../../components/Form/GridAutoComplete';
import Icon from '../../../../../../components/Icon';
import Modals from '../../../../../../components/Modal';
import withQuery from '../../../../../../hoc/withQuery/withQuery';
import useRedirect from '../../../../../../hooks/useRedirect';
import iconNames from '../../../../../../lib/iconNames';
import useReduxStoreWithId from '../../../../../../hooks/useReduxStoreWithId';

const denialColumns = [
  {
    Header: 'Code',
    accessor: 'reasonCode',
    flex: 1,
  },
  {
    Header: 'Category Name',
    accessor: 'category',
    flex: 1,
  },
  {
    Header: 'Description',
    accessor: 'description',
    flex: 2,
  },
];

const reasonColumns = [
  {
    Header: 'Code',
    accessor: 'reasonCode',
    fixWidth: 30,
  },
  {
    Header: 'Category',
    accessor: 'category',
    fixWidth: 200,
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
];

const ReasonTable = withQuery({
  url: apiUrls.GET_DENIAL_REASONS,
  listId: apiUrls.GET_DENIAL_REASONS,
})();

const optionParser = (option) => ({
  ...option,
  name: option?.category,
  value: option?.id,
});

const DenialReasonComponent = ({
  item, index, handleLineItemData, name, reasonIdName, getPopupContainer, dataTestId, id = 'denial',
}) => {
  const { params } = useRedirect();
  const [isModalVisible, setModalVisibility] = useState();
  const [initialValue, setInitialValue] = useState();
  const [defaultOptionsData,, setDefaultOptions] = useReduxStoreWithId({ id: `denial-reason-${id}` });
  const defaultOptions = defaultOptionsData?.get('data');

  useEffect(() => {
    if (item?.denialCategory && item?.denialReasonId
       && !(defaultOptions && defaultOptions[0]?.denialReasonId !== item?.denialReasonId)) {
      setInitialValue({
        name: item?.denialCategory,
        value: item?.denialReasonId,
        category: item?.denialCategory,
        reasonCode: item?.denialReasonCode,
        description: item?.denialReason,
      });
    }
  }, []);

  const toggleModal = useCallback(() => {
    setModalVisibility(!isModalVisible);
  }, [isModalVisible]);

  const onRowClick = useCallback(({ original }) => {
    handleLineItemData(index)({
      target: {
        value: original?.id ?? undefined, name: reasonIdName, category: original?.category,
      },
    });

    setDefaultOptions(original ? [{
      ...original,
      value: original?.id,
      name: original?.category,
    }] : null);
    toggleModal();
  }, [handleLineItemData, index, reasonIdName, setDefaultOptions, toggleModal]);

  const handleIconClick = useCallback(() => {
    if (!(item?.isPosted)) {
      toggleModal();
    }
  }, [item, toggleModal]);

  return (
    <>
      <div className="flex denialForm">
        <AutoComplete
          url={apiUrls.GET_DENIAL_REASONS}
          name={name}
          optionMaster="searchtext"
          labelSpan={1}
          inputSpan={23}
          disabled={item?.isPosted}
          optionParser={optionParser}
          initialValue={initialValue}
          value={item?.[reasonIdName] || undefined}
          columns={denialColumns}
          minCharLength={1}
          style={{ minWidth: 80 }}
          isFormItem={false}
          dropdownMatchSelectWidth={400}
          notFoundContent="Denial Code is not present is the system."
          showArrow
          labelInValue
          defaultOptions={defaultOptions}
          selectProps={{
            style: { minWidth: 80 },
            optionLabelProp: 'name',
            allowClear: true,
            onClear: () => {
              handleLineItemData(index)({
                target: {
                  value: undefined, name: reasonIdName,
                },
              });
            },
            getPopupContainer,
          }}
          params={params}
          header
          placeholder="Select a denial reason"
          onSelect={(data) => {
            handleLineItemData(index)({
              target: {
                value: data?.value ?? undefined, name: reasonIdName,
              },
            });
            setDefaultOptions();
          }}
          dataTestId={dataTestId}
        />
        <div className="mr-top-4 mr-lt-10 add-icon-on-right">
          <Icon
            className={classNames({
              'cursor-not-allowed': item?.isPosted,
            })}
            name={iconNames.plusIcon}
            onClick={handleIconClick}
            color="#32c788"
            data-testid={`plus-icon-${index}`}
          />
        </div>
        {/* <Button onClick={toggleModal}>See All</Button> */}
      </div>
      <Modals
        visible={isModalVisible}
        toggleModal={toggleModal}
        // className="check-in-modal"
        width="1110px"
        maskClosable={false}
        destroyOnClose
        footer={[
          <div className="group-btns" key="footer">
            <Button className="btn min-wt-86" onClick={toggleModal}>Cancel</Button>
          </div>,
        ]}
      >
        <div className="addition-header">
          <div className="lookup sprite-img-before">
            <p>Denial Reason</p>
          </div>
        </div>
        <div className="table-max-height-800">
          <ReasonTable
            columns={reasonColumns}
            onRowClick={onRowClick}
            scrollId="denial-reason-table-in-financials"
          >
            {({ Component }) => Component}
          </ReasonTable>
        </div>
      </Modals>
    </>
  );
};

export default DenialReasonComponent;
