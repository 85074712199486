import React from 'react';

import AuthorizationTable from '../../../../../../wiredComponents/AuthorizationTable';

import columns from './AuthorizationTable/columns';

const Authorization = ({ labels, patientId, navigateToEditAuthorization }) => (
  <AuthorizationTable
    labels={labels}
    navigateToEditAuthorization={navigateToEditAuthorization}
    patientId={patientId}
    columns={columns}
  />
);

export default Authorization;
