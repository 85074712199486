/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useDrop } from 'react-dnd';
import { Collapse, Input } from 'antd';
import classNames from 'classnames';
import sortBy from 'lodash/sortBy';

import * as selectors from '../../../../../../../../../../../../store/selectors';
import '../../../../../../CustomDropppableTab/customDroppableTab.scss';
import Icon from '../../../../../../../../../../../../components/Icon';
import iconNames from '../../../../../../../../../../../../lib/iconNames';
import PatientFollowUp from './followUpNonEditableForm';
import SlitExam from '../../../../../../../../../ODOS/SlitExam';
import FundusExam from '../../../../../../../../../ODOS/Fundus';
import { diagnosisParser, procedureDiagnosisMapping } from '../../../../../../../../../../commonParser';
import { parser as orderSetParser } from '../../../../../../AP/RightTab/Tabs/OrderSets';
import useCRUD from '../../../../../../../../../../../../hooks/useCRUD';
import { apiUrls } from '../../../../../../../../../../../../api/constants';
import { DiagnosisProcedureMappingComponent } from './index';
import RenderMultipleSynoynms from '../../../../../../../../../../../../components/RenderMultipleSynonyms';

const { Panel } = Collapse;
const LibraryJsonTree = ({
  type, showFollowUpExamFinding, activeIds, showHideCount,
  libraryList, copyProviderAllProcedureSets, handleApplyDiagnosis,
  searchText,
}) => {
  const [panelsList, setPanelsList] = useState([]);
  const [procedureDiagnosisMappingData, setProcedureDiagnosisMappingData] = useState({});
  const [diagnosisMappingData, setDiagnosisMappingData] = useState({});

  const [procedureSetsResponse,,, getProcedureSetById] = useCRUD({
    id: 'get-doctor-screen-library-sets',
    url: apiUrls.GET_PROCEDURE_SET_BY_ID,
    type: 'read',
  });

  useEffect(() => {
    if (procedureSetsResponse) {
      setProcedureDiagnosisMappingData({
        ...procedureDiagnosisMappingData,
        [procedureSetsResponse.favouriteId]: procedureDiagnosisMapping(procedureSetsResponse),
      });
      setDiagnosisMappingData({
        ...diagnosisMappingData,
        [procedureSetsResponse.favouriteId]: procedureSetsResponse?.procedureDiagnosis,
      });
    }
  }, [procedureSetsResponse]);

  const [, drop] = useDrop({
    accept: 'CARD',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const currentProviderData = useMemo(() => {
    const user = localStorage.getDecryptedData('user');
    const { providerDetail = {} } = JSON.parse(user);
    return {
      name: providerDetail?.providerName,
      id: providerDetail?.providerId,
      specialityId: providerDetail?.specialtyId,
      specialityName: providerDetail?.specialtyName,
    };
  }, []);

  useEffect(() => {
    if (showHideCount) {
      if (activeIds && libraryList?.length) {
        setPanelsList(libraryList?.map((libraryItem) => libraryItem.specialityId));
      } else {
        setPanelsList([]);
      }
    }
  }, [showHideCount, activeIds, libraryList]);

  const handleProcedureSetById = useCallback((favouriteId) => {
    if (!procedureDiagnosisMappingData[favouriteId]) {
      const searchParams = type === 'diagnosisFavorite' ? {
        id: favouriteId,
        searchText,
        source: 'Diagnosis',
      } : {
        id: favouriteId,
      };
      getProcedureSetById(searchParams);
    }
  }, [getProcedureSetById, procedureDiagnosisMappingData, searchText, type]);

  const openClosePanel = useCallback((key) => {
    if (panelsList.includes(key)) {
      setPanelsList([...panelsList.filter((e) => e !== key)]);
    } else {
      setPanelsList([...panelsList, key]);
    }
  }, [panelsList]);

  const openCloseNestedPanel = useCallback((tagId, key) => {
    if (key) {
      if (panelsList.includes(key)) {
        setPanelsList([...panelsList.filter((e) => e !== key)]);
      } else {
        setPanelsList([...panelsList, key]);
        handleProcedureSetById(tagId);
      }
    } else if (panelsList.includes(tagId)) {
      setPanelsList([...panelsList.filter((e) => e !== tagId)]);
    } else {
      setPanelsList([...panelsList, tagId]);
      handleProcedureSetById(tagId);
    }
  }, [handleProcedureSetById, panelsList]);

  const handleDragStart = useCallback((event) => {
    event.dataTransfer.setData('text', event?.target?.id);
    event.dataTransfer.setData('type', 'acuity-eye-tree-leaf');
    event.target.classList.add('dragging');
    event.dataTransfer.setData('draggedItem', type);
    event.dataTransfer.setData('draggedData', '{}');
  }, [type]);

  const handleFavoriteDragStart = (event) => {
    event.dataTransfer.setData('text', event?.target?.id);
    event.dataTransfer.setData('type', 'acuity-eye-tree-leaf');
    event.dataTransfer.setData('draggedItem', 'apply-diagnosis-folder-favorite');
    event.target.classList.add('dragging');
    event.dataTransfer.setData('draggedData', JSON.stringify({ favoriteId: event?.target?.id }));
  };

  const handleDiagnosisDragStart = (event) => {
    event.stopPropagation();
    event.dataTransfer.setData('text', event?.target?.id);
    event.dataTransfer.setData('type', 'acuity-eye-tree-leaf');
    event.dataTransfer.setData('draggedItem', 'dx');
    event.target.classList.add('dragging');
    event.dataTransfer.setData('draggedData', JSON.stringify({ icdCodeId: event.target?.id?.split(' ')[1] }));
  };

  const handleDragEnd = useCallback((event) => {
    event.target.classList.remove('dragging');
  }, []);

  if (['orderSets', 'procedureSets'].includes(type)) {
    return (
      <div className="ap-accordion-tree">
        {(libraryList || [])?.length > 0 && (
            libraryList.map((data) => (
              <div
                className="right-tree"
                key={data?.id}
              >
                <Collapse
                  accordion
                  activeKey={panelsList?.includes(data?.specialityId)
                    ? data?.specialityId : `${data?.specialityId}_i`}
                  onChange={() => openClosePanel(data?.specialityId)}
                >
                  <Panel
                    header={data?.specialityName}
                    key={data?.specialityId}
                  >
                    <div className="line-tree-view" style={{ paddingLeft: '1.6rem' }}>
                      {data?.providers?.map((provider) => (
                        <Collapse>
                          <Panel
                            header={provider?.name}
                            key={provider?.id}
                            extra={(
                              <Icon
                                className="mr-rt-12"
                                name={iconNames.copyOutlined}
                                onClick={(event) => copyProviderAllProcedureSets({
                                  fromProvider: provider?.id,
                                  fromProviderName: provider?.name,
                                  toProvider: currentProviderData?.id,
                                  toProviderName: currentProviderData?.name,
                                  source: provider?.source,
                                  specialityId: currentProviderData?.specialityId,
                                  specialityName: currentProviderData?.specialityName,
                                }, event)}
                              />
                            )}
                          >
                            <div style={{ paddingLeft: '16px' }} className="sub-tree-left-gap">
                              {type === 'procedureSets'
                                ? (provider?.sets?.map((tagDetails) => (
                                    tagDetails?.tags?.map((tag) => (
                                      <div key={tag?.tagId} className=" right-tree">
                                        <Collapse>
                                          <Panel
                                            header={tag?.tagName}
                                            key={tag?.tagId}
                                            extra={(
                                              <Icon
                                                className="mr-rt-12"
                                                name={iconNames.copyOutlined}
                                                onClick={(event) => copyProviderAllProcedureSets({
                                                  fromTag: tag?.tagName,
                                                  fromProvider: provider?.id,
                                                  fromProviderName: provider?.name,
                                                  toProvider: currentProviderData?.id,
                                                  toProviderName: currentProviderData?.name,
                                                  source: provider?.source,
                                                  specialityId: currentProviderData?.specialityId,
                                                  specialityName: currentProviderData?.specialityName,
                                                }, event)}
                                              />
                                            )}
                                          >
                                            <div className="right-tree-view procedure-right ap-tree inner-sub-child-icon">
                                              {(tag?.favourites || [])?.map((fav) => (
                                                <div
                                                  key={fav.favouriteId}
                                                  draggable
                                                  id={fav.favouriteId}
                                                  onDragStart={(e) => handleDragStart(e)}
                                                  onDragEnd={(e) => handleDragEnd(e)}
                                                >
                                                  <Collapse
                                                    onChange={() => handleProcedureSetById(fav?.favouriteId)}
                                                  >
                                                    <Panel
                                                      header={fav?.favouriteName}
                                                      key={fav?.favouriteId}
                                                      extra={(
                                                        <Icon
                                                          className="mr-rt-12"
                                                          name={iconNames.copyOutlined}
                                                          onClick={(e) => copyProviderAllProcedureSets({
                                                            favouriteId: fav?.favouriteId,
                                                            toProvider: currentProviderData?.id,
                                                            toProviderName: currentProviderData?.name,
                                                            source: provider?.source,
                                                            fromProvider: provider?.id,
                                                            fromProviderName: provider?.name,
                                                            specialityId: currentProviderData?.specialityId,
                                                            specialityName: currentProviderData?.specialityName,
                                                          }, e)}
                                                        />
                                                        )}
                                                    >
                                                      {procedureDiagnosisMappingData?.[fav.favouriteId]?.result?.length
                                                        ? procedureDiagnosisMappingData?.[fav.favouriteId]?.result?.map((subType, subTypeIndex) => (
                                                          <DiagnosisProcedureMappingComponent
                                                            data={subType}
                                                            key={subTypeIndex + 1}
                                                            showChild
                                                            favoriteId={fav?.favouriteId}
                                                            handleDragEnd={handleDragEnd}
                                                            drop={drop}
                                                          />
                                                        )) : <span>No Data Found</span>}
                                                    </Panel>
                                                  </Collapse>
                                                </div>
                                              ))}
                                            </div>
                                          </Panel>
                                        </Collapse>
                                      </div>
                                    ))))) : orderSetParser(provider?.sets)?.map((ord) => (
                                      <div
                                        key={ord.orderSetId}
                                        className="right-tree"
                                        draggable
                                        id={ord.orderSetId}
                                        onDragStart={(e) => handleDragStart(e)}
                                        onDragEnd={(e) => handleDragEnd(e)}
                                      >
                                        {ord?.orderSetName && (
                                        <Collapse>
                                          <Panel
                                            header={ord?.orderSetName}
                                            key={ord?.orderSetId}
                                            extra={(
                                              <div className="flex mr-top-4">
                                                {showFollowUpExamFinding && (
                                                <div className="flex">
                                                  <span
                                                    title={`Follow-up-${ord?.
                                                      isFollowUpExist ? 'Included' : 'Excluded'}`}
                                                    className={classNames(
                                                      ord?.
                                                      isFollowUpExist ? 'follow-up-bind-green-label' : 'follow-up-bind-grey-label',
                                                    )}
                                                  >
                                                    F
                                                  </span>
                                                  <span
                                                    title={`Exam Findings-${ord?.isExamExist ? 'Included' : 'Excluded'}`}
                                                    className={classNames(
                                                      ord?.isExamExist ? 'follow-up-bind-green-label' : 'follow-up-bind-grey-label',
                                                    )}
                                                  >
                                                    E
                                                  </span>
                                                </div>
                                                )}
                                                <Icon
                                                  className="mr-rt-12"
                                                  name={iconNames.copyOutlined}
                                                  onClick={(event) => copyProviderAllProcedureSets({
                                                    fromProvider: provider?.id,
                                                    fromProviderName: provider?.name,
                                                    favouriteId: ord?.orderSetId,
                                                    toProvider: currentProviderData?.id,
                                                    toProviderName: currentProviderData?.name,
                                                    source: provider?.source,
                                                    specialityId: currentProviderData?.specialityId,
                                                    specialityName:
                                                  currentProviderData?.specialityName,
                                                  }, event)}
                                                />
                                              </div>
                                          )}
                                          >
                                            <Collapse
                                              onChange={() => openCloseNestedPanel(ord.orderSetId, data.key)}
                                            >
                                              <Panel
                                                header="Procedures"
                                                key={data.key}
                                              >
                                                <div className="right-tree-view procedure-right ap-tree">
                                                  {procedureDiagnosisMappingData?.[ord.orderSetId]?.result?.length
                                                    ? procedureDiagnosisMappingData?.[ord.orderSetId]?.result?.map((subType, subTypeIndex) => (
                                                      <DiagnosisProcedureMappingComponent
                                                        data={subType}
                                                        key={subTypeIndex + 1}
                                                        showChild
                                                        favoriteId={ord?.orderSetId}
                                                        handleDragEnd={handleDragEnd}
                                                        drop={drop}
                                                      />
                                                    )) : <span draggable={false}>No Data Found</span>}
                                                </div>
                                                {procedureDiagnosisMappingData?.[ord?.orderSetId]?.notes?.map((notesData) => (
                                                  <div
                                                    role="presentation"
                                                    key={notesData.id}
                                                    className="tree-panel-box"
                                                  >
                                                    <div className="pannel">
                                                      <div className="pannel-heading">
                                                        <div className="width-100-pr flex justify-content-sp-bt">
                                                          <div>
                                                            {notesData.noteType}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="pannel-body">
                                                        <Input.TextArea
                                                          label=""
                                                          name={null}
                                                          labelSpan="0"
                                                          inputSpan="24"
                                                          autoSize={{ minRows: 1 }}
                                                          value={notesData.note}
                                                          disabled
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                ))}
                                              </Panel>
                                              <Panel
                                                header="Follow up"
                                                key="2"
                                              >
                                                <div className="follow-up-bind">
                                                  <PatientFollowUp
                                                    followUpData={procedureDiagnosisMappingData?.
                                                         [ord?.orderSetId]?.followUp}
                                                    maxTagCount={2}
                                                    disabledAll
                                                    id={ord?.orderSetId}
                                                    key={ord?.orderSetId}
                                                  />
                                                </div>
                                              </Panel>
                                              <Panel
                                                header="Exam findings"
                                                key="3"
                                              >
                                                <div
                                                  className="select-box-section"
                                                  id="select-box-section-doctor"
                                                  style={{ position: 'relative', pointerEvents: 'none', opacity: '0.6' }}
                                                >
                                                  <SlitExam scrollId="phy-exam-tab-slit-and-fundus" fetchCall={false} slitExamData={procedureDiagnosisMappingData?.[ord?.orderSetId]?.slitLampList || []} displayOnly={data?.orderSetId} />
                                                  <FundusExam scrollId="phy-exam-tab-slit-and-fundus" fetchCall={false} fundusExamData={procedureDiagnosisMappingData?.[ord?.orderSetId]?.fundusExamList || []} displayOnly={data?.orderSetId} />
                                                </div>
                                              </Panel>
                                            </Collapse>
                                          </Panel>
                                        </Collapse>
                                    )}
                                      </div>
                                    ))}
                            </div>
                          </Panel>
                        </Collapse>
                      ))}
                    </div>
                  </Panel>
                </Collapse>
              </div>
            ))
          )}
      </div>
    );
  }
  return (
    <div className="ap-accordion-tree">
      {(libraryList || [])?.length > 0 && (
        libraryList.map((data) => (
          <div
            className="right-tree"
            key={data?.id}
          >
            <Collapse>
              <Panel
                header={data?.specialityName}
                key={data?.specialityId}
              >
                <div className="line-tree-view" style={{ paddingLeft: '1.6rem' }}>
                  {data?.providers?.map((provider) => (
                    <Collapse>
                      <Panel
                        header={provider?.name}
                        key={provider?.id}
                        extra={(
                          <Icon
                            className="mr-rt-12"
                            name={iconNames.copyOutlined}
                            onClick={(event) => copyProviderAllProcedureSets({
                              fromProvider: provider?.id,
                              toProvider: currentProviderData?.id,
                              toProviderName: currentProviderData?.name,
                              source: provider?.source,
                              specialityId: currentProviderData?.specialityId,
                              specialityName: currentProviderData?.specialityName,
                            }, event)}
                          />
                        )}
                      >
                        <div style={{ paddingLeft: '1.6rem' }}>
                          {diagnosisParser(provider?.sets)?.map((ord) => (
                            <div
                              key={ord.tagId}
                              className="right-tree"
                              draggable
                              id={ord.tagId}
                              onDragStart={(e) => handleFavoriteDragStart(e)}
                              onDragEnd={(e) => handleDragEnd(e)}
                            >
                              {ord?.title && (
                                <Collapse
                                  onChange={() => handleProcedureSetById(ord?.tagId)}
                                >
                                  <Panel
                                    header={ord?.title}
                                    key={ord?.tagId}
                                    extra={(
                                      <Icon
                                        className="mr-rt-12"
                                        name={iconNames.copyOutlined}
                                        onClick={(event) => copyProviderAllProcedureSets({
                                          fromProvider: provider?.id,
                                          fromProviderName: provider?.name,
                                          favouriteId: ord?.tagId,
                                          toProvider: currentProviderData?.id,
                                          toProviderName: currentProviderData?.name,
                                          source: provider?.source,
                                          specialityId: currentProviderData?.specialityId,
                                          specialityName: currentProviderData?.specialityName,
                                        }, event)}
                                      />
                                    )}
                                  >
                                    <div className="hhh" id={`${ord?.subType?.groupId}_parent`}>
                                      {diagnosisMappingData[ord.tagId]?.length
                                        ? sortBy((diagnosisMappingData[ord.tagId]),
                                          (item) => (item?.synonyms?.trim()?.toLowerCase()
                                          || item.description?.toLowerCase()))?.map((element) => (
                                            <div className="library-tree-list-code" key={`${element?.groupId}`}>
                                              <div
                                                id={`${element?.groupId} ${element?.icdCodeId}`}
                                                aria-hidden="true"
                                                ref={drop}
                                                className="positive-relative"
                                                draggable
                                                onDragStart={handleDiagnosisDragStart}
                                                onClick={() => handleApplyDiagnosis(
                                                  element?.icdCodeId,
                                                )}
                                              >
                                                <p id={`${element?.groupId}_parentlabel`} aria-hidden="true">
                                                  <div className="separate-code m-r-0">
                                                    <span className="label-text-width">{element?.synonyms ? <RenderMultipleSynoynms synonyms={element?.synonyms} /> : (element?.label || element?.description || '')}</span>
                                                    <span className="code-only separate-code-bg">
                                                      {element?.icdCode}
                                                      <Icon
                                                        name={iconNames.copyOutlined}
                                                        onClick={(event) => copyProviderAllProcedureSets({
                                                          favouriteId: ord?.favouriteId,
                                                          toProvider: currentProviderData?.id,
                                                          toProviderName: currentProviderData?.name,
                                                          source: provider?.source,
                                                          specialityId: currentProviderData?.specialityId,
                                                          specialityName: currentProviderData?.specialtyName,
                                                          dxId: element?.icdCodeId,
                                                          fromProvider: provider?.id,
                                                          fromProviderName: provider?.name,
                                                        }, event)}
                                                      />
                                                    </span>
                                                  </div>
                                                </p>
                                              </div>
                                              <span className="square-line" />
                                            </div>
                                          )) : <span draggable={false}>No Data Found</span>}
                                    </div>
                                  </Panel>
                                </Collapse>
                              )}
                            </div>
                          ))}
                        </div>
                      </Panel>
                    </Collapse>
                  ))}
                </div>
              </Panel>
            </Collapse>
          </div>
        ))
      )}
    </div>
  );
};

LibraryJsonTree.defaultProps = {
  list: [],
  search: '',
  url: '',
  setLabelsList: () => { /* This is intentional */ },
  draggable: true,
  showCloseIcon: true,
  onAddItems: () => { /* This is intentional */ },
  handleProcedureClick: () => { /* This is intentional */ },
  showSelection: false,
};

LibraryJsonTree.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  search: PropTypes.string,
  url: PropTypes.string,
  setLabelsList: PropTypes.func,
  draggable: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
  onAddItems: PropTypes.func,
  handleProcedureClick: PropTypes.func,
  showSelection: PropTypes.bool,
};

export default connect((states) => ({
  enumOptions: selectors.getEnumOptions(states),
  enumMaster: selectors.getEnumMaster(states),
}))(LibraryJsonTree);

