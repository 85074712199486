import React, { useEffect, useCallback, useState } from 'react';
import { Tooltip } from 'antd';

import useCRUD from '../../../hooks/useCRUD';
import useRedirect from '../../../hooks/useRedirect';

import { apiUrls } from '../../../api/constants';
import { I_FRAME_URL } from '../../../lib/constants';
import { setURLToLocalStorage } from '../../../lib/localStorageUtil';

import Modals from '../../../components/Modal';
import WidgetLoader from '../../../components/WidgetLoader';

const color = ['#2a4a79'];

const AddMedicationModal = (props) => {
  const {
    toggleModal, isVisible, setSession, widget, id, modalTitle = 'Medications', panelId,
  } = props;
  const { params } = useRedirect();
  const { patientId, providerId, encounterId } = params;
  const [iframeURL, setIframeURL] = useState();
  const [loaderState, setLoaderState] = useState(true);

  const [url, , , getMedicationUrl, clearMedicationUrl] = useCRUD({
    id: `I_FRAME_URL-${panelId}`,
    url: apiUrls.GET_IFRAME_URL,
    type: 'read',
  });

  const refreshUrl = useCallback(() => {
    getMedicationUrl({
      PatientId: id || patientId, ProviderId: providerId, Widget: widget || 'Prescription', EncounterId: encounterId,
    });
    setLoaderState(true);
  }, [encounterId, getMedicationUrl, id, patientId, providerId, widget]);

  useEffect(() => {
    if (isVisible && (patientId || id) && (providerId || widget)) {
      refreshUrl();
    }
  }, [patientId, providerId, isVisible, id]);

  useEffect(() => {
    if (url && iframeURL !== url) {
      setIframeURL(url);
      if (!widget) {
        setURLToLocalStorage(providerId, patientId, url, I_FRAME_URL);
      }
      setSession(url);
    }
  }, [url, iframeURL, providerId, patientId, setSession]);

  const unsetLoading = useCallback(() => {
    setLoaderState(false);
  }, []);

  return (
    <Modals
      visible={isVisible}
      title={modalTitle}
      onOk={toggleModal}
      onCancel={toggleModal}
      width="100vw"
      className="refresh-modal"
      destroyOnClose
      afterClose={() => { setIframeURL(); clearMedicationUrl(true); }}
    >
      <Tooltip placement="right" color={color} title="Refresh">
        <div role="presentation" onClick={refreshUrl} className="btn mr-lt-10 refresh-icon" />
      </Tooltip>
      <div className="posi-relative">
        {loaderState && <WidgetLoader /> }
        {iframeURL && (
          <iframe
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            onLoad={unsetLoading}
            className="mr-top-8"
            src={iframeURL}
            title="iframe"
            width="100%"
            height="400px"
          />
        )}
      </div>
    </Modals>
  );
};
export default AddMedicationModal;
