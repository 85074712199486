import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Financials from './Financials';
import FinancialDetails from './FinancialDetails';
import useRedirect from '../../../../hooks/useRedirect';
import WithLabel from '../../../../hoc/withLabel';
import { labelPaths, UiRoutes } from '../../../../lib/constants';

const Finance = (props) => {
  const { params, path } = useRedirect();
  return (
    <Switch>
      <Route exact path={path}>
        <Financials {...props} parentParams={params} />
      </Route>
      <Route path={UiRoutes.viewFinancialDetailsWithId}>
        <FinancialDetails {...props} parentParams={params} />
      </Route>
    </Switch>
  );
};
export default WithLabel(Finance, labelPaths.PATIENT_FINANCIAL);
