import React, { useCallback } from 'react';
import { Route, Switch } from 'react-router-dom';

import { labelPaths, UiRoutes } from '../../../../lib/constants';

import WithLabel from '../../../../hoc/withLabel';

import useRedirect from '../../../../hooks/useRedirect';

import StatementTable from './Table';

const Claims = ({ labels, ...otherProps }) => {
  const {
    replace, generatePath, params, path,
  } = useRedirect();

  const routeToTable = useCallback(() => {
    replace(generatePath(UiRoutes.statementClaimsFilters, { ...params, claimFilter: 'ready' }));
  }, [generatePath, params, replace]);

  return (
    <Switch>
      <Route path={UiRoutes.claimsFilters}>
        <StatementTable labels={labels} {...otherProps} />
      </Route>
      <Route exact path={path}>
        {routeToTable}
      </Route>
    </Switch>
  );
};

export default WithLabel(Claims,
  labelPaths.STATEMENT_LIST);
