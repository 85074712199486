import React, { useCallback } from 'react';
import { Route, Switch } from 'react-router-dom';

import {
  UiRoutes, labelPaths,
} from '../../../../../lib/constants';

import useRedirect from '../../../../../hooks/useRedirect';

import WithLabel from '../../../../../hoc/withLabel';

import AddTerm from './AddTerms';
import EditTerm from './EditTerms';

import TermsTable from './TermsTable';

const Terms = (props) => {
  const { isUpdateContractAuthenticated } = props;
  const {
    params, location, push, generatePath,
  } = useRedirect();

  const navigateToEditContractTerm = useCallback(({ termId }) => {
    push(generatePath(UiRoutes.editContractTermsWithTabId, { ...params, termId, termsTab: 'general' }));
  }, [push, generatePath, params]);

  const goBack = useCallback(() => {
    push(generatePath(UiRoutes.editContractWithTabId, { ...params, termsTab: 'general' }));
  }, [push, generatePath, params]);

  return (
    <Switch location={location}>
      <Route path={UiRoutes.addContractTermsWithTabId} exact>
        <div className="pannel back-pannel mr-top-20">
          <div className="pannel-heading">
            <p
              className="back-arrow"
              id="patients_financial_detail_goBack"
              onClick={goBack}
              role="presentation"
            >
              Go Back
            </p>
          </div>
          <div>
            <AddTerm />
          </div>
        </div>
      </Route>
      <Route path={UiRoutes.editContractWithTabId} exact>
        <div>
          <TermsTable
            navigateToEditContractTerm={navigateToEditContractTerm}
            params={params}
            isUpdateContractAuthenticated={isUpdateContractAuthenticated}
          />
        </div>
      </Route>
      <Route path={UiRoutes.editContractTermsWithTabId}>
        <div className="pannel back-pannel mr-top-20">
          <div className="pannel-heading">
            <p className="back-arrow" id="patients_financial_detail_goBack" onClick={goBack} role="presentation">
              Terms
            </p>
          </div>
          <div>
            <EditTerm isUpdateContractAuthenticated={isUpdateContractAuthenticated} />
          </div>
        </div>
      </Route>
    </Switch>
  );
};

export default WithLabel(Terms, labelPaths.EDIT_CONTRACT);
