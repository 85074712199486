export const ADD_USERS = 'ADD_USERS';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const GET_SELECTED_USER = 'GET_SELECTED_USER';
export const GET_USERS = 'GET_USERS';
export const GET_USER_SETTINGS = 'GET_USER_SETTINGS';
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS';
export const UPDATE_USERS = 'UPDATE_USERS';
export const SET_UPDATED_USER_IN_STORE = 'SET_UPDATED_USER_IN_STORE';
export const CLEAR_SELECTED_USER = 'CLEAR_SELECTED_USER';
export const SET_ERROR = 'SET_ERROR';
export const SET_UPDATE = 'SET_UPDATE';
export const SET_LOADING = 'SET_LOADING';
export const SET_REDIRECT_KEY = 'SET_REDIRECT_KEY';

export const getUsersData = () => ({
  type: GET_USERS,
});

export const setSelectedUser = (user) => ({
  type: SET_SELECTED_USER,
  user,
});

export const getSelectedUser = (userId) => ({
  type: GET_SELECTED_USER,
  userId,
});

export const addUser = (payload = {}) => ({
  type: ADD_USERS,
  payload,
});

export const updateUser = (payload) => ({
  type: UPDATE_USERS,
  payload,
});

export const clearSelectedUser = () => ({
  type: CLEAR_SELECTED_USER,
});

export const setLoading = (flag) => ({
  type: SET_LOADING,
  flag,
});

export const setError = (error) => ({
  type: SET_ERROR,
  error,
});

export const setUpdate = (flag) => ({
  type: SET_UPDATE,
  flag,
});

export const setRedirectKey = (userId) => ({
  type: SET_REDIRECT_KEY,
  userId,
});

export const setUpdatedUserInStore = (payload) => ({
  type: SET_UPDATED_USER_IN_STORE,
  payload,
});

export const getUserSettings = () => ({
  type: GET_USER_SETTINGS,
});

export const setUserSettings = (payload) => ({
  type: SET_USER_SETTINGS,
  payload,
});

export const updateUserSettings = (payload) => ({
  type: UPDATE_USER_SETTINGS,
  payload,
});
