import { useEffect } from 'react';

import Events from '../../../../../../../lib/events';

const TableWrapper = ({ reFetch, Component }) => {
  useEffect(() => {
    Events.on('refetchAppointmentConfirmationTable', 'refetchAppointmentConfirmationTable', reFetch);
    return () => Events.remove('refetchAppointmentConfirmationTable');
  }, []);
  return Component;
};

export default TableWrapper;
