import React from 'react';
import TimeCell from '../../../../../../components/Cells/TimeCell';
import Icon from '../../../../../../components/Icon';
import iconNames from '../../../../../../lib/iconNames';

const errorColumns = (labels, toggleErrorPopup) => [
  {
    Header: '',
    accessor: 'warning',
    Cell: ({ row: { original } }) => (
      <Icon name={iconNames.warningOutlined} onClick={toggleErrorPopup(original)} color="yellow" />
    ),
    fixWidth: 50,
  },
  {
    Header: labels.get('tableColumns.patientName'),
    accessor: 'patientName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.status'),
    accessor: 'status',
  },
  {
    Header: labels.get('tableColumns.payer'),
    accessor: 'insurancePayerName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.profileType'),
    accessor: 'insuranceProfileCategoryName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.policyType'),
    accessor: 'insurancePolicyTypeName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.type'),
    accessor: 'profileTypeName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.dos'),
    accessor: 'billingservicelineitemcreateddate.timestemp',
    Cell: (e) => <TimeCell value={e?.row.original?.billingServiceLineItemCreatedDate?.dateString} format="MM-DD-YYYY" />,
    sort: true,
  },
  {
    Header: labels.get('tableColumns.provider'),
    accessor: 'providerName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.location'),
    accessor: 'locationName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.format'),
    accessor: 'format',
    Cell: ({ row: { original: { deliveryMethodName } } }) => <span>{deliveryMethodName?.split('/')?.[0]}</span>,
  },
  {
    Header: labels.get('tableColumns.deliveryMethod'),
    accessor: 'deliveryMethodName',
    Cell: ({ row: { original: { deliveryMethodName } } }) => <span>{deliveryMethodName?.split('/')?.[1]}</span>,
  },
  {
    Header: labels.get('tableColumns.amount'),
    accessor: 'amount',
    Cell: ({ row: { original: { amount } } }) => (
      (amount || 0).toFixed(2)
    ),
    className: 'text-align-right',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.balance'),
    accessor: 'balance',
    Cell: ({ row: { original: { balance } } }) => (
      (balance || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
];

export default errorColumns;
