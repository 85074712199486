import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import { connect } from 'react-redux';
import { useDrop } from 'react-dnd';
import classNames from 'classnames';
import uniqBy from 'lodash/uniqBy';

import * as selectors from '../../../../../../../../store/selectors';
import '../customDroppableTab.scss';
import SMCheckBox from '../../../../../../../../components/SmCheckbox';
import Input from '../../../../../../../../components/Form/Input';

const JsonTree = ({
  labelList, draggable, hoveredOn, deleteDiagnosis,
  deleteProcedure, notesList, handleProcedureClick, saveFavorite,
  setAddOption, showSelection, handleCheckBoxClick, checkedValues, showCloseIcon,
  handleDeleteNotes, showNotesDeleteIcon, savedSimpleEncounter, downloadImaging,
  showAddedProcedures, type, handleJcodeModifierClick, ODOSModifierOptions,
}) => {
  const [, drop] = useDrop({
    accept: 'CARD',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const getModifiersAccordingtoParent = useCallback((data) => uniqBy(data?.reduce(
    (acc, element) => {
      if (element?.modifierCode === '50' || element?.modifierCode === 50) {
        const ltPair = ODOSModifierOptions?.action?.find((item) => item?.modifierCode.toLowerCase() === 'lt');
        const rtPair = ODOSModifierOptions?.action?.find((item) => item?.modifierCode.toLowerCase() === 'rt');
        if (ltPair && rtPair) {
          acc.push(
            { modifierCode: ltPair?.description, modifierId: ltPair?.modifierId },
            { modifierCode: rtPair?.description, modifierId: rtPair?.modifierId },
          );
        }
      }
      if (ODOSModifierOptions?.action?.find((item) => item?.modifierId === element?.modifierId)) {
        acc.push({ modifierCode: element.description, modifierId: element.modifierId });
      }
      return acc;
    }, []), 'modifierId'), [ODOSModifierOptions]);

  const handleDragStart = useCallback((event, data, groupId) => {
    event.stopPropagation();
    event.dataTransfer.setData('text', event?.target?.id);
    event.dataTransfer.setData('type', 'acuity-eye-tree-leaf');
    event.dataTransfer.setData('draggedItem', 'current-set');
    event.target.classList.add('dragging');
    event.dataTransfer.setData('draggedData', JSON.stringify({ ...data, groupId }));
  }, []);

  const handleProcedureDragStart = useCallback((event, data, groupId) => {
    event.stopPropagation();
    event.dataTransfer.setData('text', event?.target?.id);
    event.dataTransfer.setData('type', 'acuity-eye-tree-leaf');
    event.dataTransfer.setData('draggedItem', 'current-procedure');
    event.target.classList.add('dragging');
    event.dataTransfer.setData('draggedData', JSON.stringify({ ...data, groupId }));
  }, []);
  const handleDragEnd = useCallback((event) => {
    event.target.classList.remove('dragging');
  }, []);
  const Compo = ({
    data, subtype, groupId, associatedJcodes, parentModifiers,
  }) => {
    let showArrow = false;
    if (data?.groupId) {
      if (data?.subType?.length) {
        showArrow = true;
      }
      return (
        <div
          id={data?.groupId}
          draggable={draggable}
          onDragStart={(e) => {
            e.stopPropagation();
            handleDragStart(e, { dragGroup: true }, data?.groupId);
          }}
          onDragEnd={(e) => handleDragEnd(e)}
        >
          <div className="hhh" id={`${data?.groupId}_parent`}>
            {data.diagnosisList.map((element, index) => (
              <div className="add-alert-bg j-code-based-icon" key={`${data?.groupId}_${element.id}`} id={`${data?.groupId}_${element.id}_H`}>
                {
                index === 0
                && (
                <div
                  id={`${data?.groupId}_${element.id}_up`}
                  className={
                    `${data?.groupId}_${element.id}_up` === hoveredOn
                      ? 'in-between-space-hover'
                      : 'in-between-space'
                    }
                />
                )
                }
                <div
                  id={`${data?.groupId}_${element.id}`}
                  aria-hidden="true"
                  ref={drop}
                  className="positive-relative"
                >
                  {showSelection && (
                  <SMCheckBox
                    className="labelLeft"
                    name={null}
                    onChange={handleCheckBoxClick(data?.groupId, element, true)}
                    checked={checkedValues?.[data?.groupId]?.dxIds?.includes(element?.id)}
                  />
                  )}
                  <p id={`${data?.groupId}_${element.id}_parentlabel`} aria-hidden="true">
                    {`${(element?.synonyms && element?.synonyms?.trim()?.split('\n')?.[0]) ?? (element?.label || element?.description)} - ${element?.icdCode}`}
                  </p>
                  {showCloseIcon && !savedSimpleEncounter && (
                  <i
                    onClick={() => deleteDiagnosis(data?.groupId, element?.diagnosisDetailsId)}
                    className="arrow-close"
                    aria-hidden="true"
                    id={`${data?.groupId}_${element.id}_parent_cross_icon`}
                  >
                    x
                  </i>
                  )}
                </div>
                { !savedSimpleEncounter && (
                <span
                  id={`${data?.groupId}_${element.id}_fav`}
                  className="save-diagnosis-fav"
                  onClick={() => saveFavorite({
                    IcdCodeId: element?.id,
                    IsActive: true,
                    groupId: data?.groupId,
                  })}
                  aria-hidden="true"
                />
                )}
                <div
                  id={`${data?.groupId}_${element.id}_down`}
                  className={
                  `${data?.groupId}_${element.id}_down` === hoveredOn
                    ? 'in-between-space-hover'
                    : 'in-between-space'
                  }
                />
              </div>
            ))}

            {showArrow
                && (
                <Compo
                  subtype={data?.subType}
                  groupId={data?.groupId}
                />
                )}
          </div>
        </div>
      );
    }
    if (data?.key) {
      return (
        <div>
          <b>{data?.type}</b>
          <p>{data?.label}</p>
        </div>
      );
    }
    if (subtype?.length && showAddedProcedures) {
      return (
        <div className="sub-l-child-group" style={{ marginLeft: '28px' }}>
          <span className="hide-line" />
          {subtype.map((subtypeElement, index) => !subtypeElement.associatedProcedureId && !(type === 'inOffice' && subtypeElement?.source === 'ImagingLab') && (
            <div
              key={subtypeElement.id}
              id={`${groupId}_${subtypeElement.id}_showChild`}
              draggable={draggable}
              onDragStart={(e) => {
                e.stopPropagation();
                handleProcedureDragStart(e, subtypeElement, groupId);
              }}
            >
              { index === 0 && (
                <div
                  id={`${groupId}_${subtypeElement.id}_up`}
                  className={
                    `${groupId}_${subtypeElement.id}_up` === hoveredOn
                      ? 'in-between-space-hover'
                      : 'in-between-space'
                    }
                />
              )}
              <div
                onClick={handleProcedureClick({ groupId, ...subtypeElement })}
                onDragStart={(e) => {
                  e.stopPropagation();
                  handleProcedureDragStart(e, subtypeElement, groupId);
                }}
                onDragEnd={(e) => handleDragEnd(e)}
                id={`${groupId}_${subtypeElement.id}_drag`}
                draggable={draggable}
                aria-hidden="true"
                className="positive-relative"
              >
                {showSelection && (
                <SMCheckBox
                  className="labelLeft"
                  name={null}
                  onChange={handleCheckBoxClick(groupId, subtypeElement, false)}
                  checked={checkedValues?.[groupId]?.patientProcedureCodeIds?.
                    includes(subtypeElement.procedureDetailsId)}
                />
                )}
                <p id={`${groupId}_${subtypeElement.id || subtypeElement?.cptCode}_para_${subtypeElement.procedureDetailsId}`}>
                  {subtypeElement?.label}
                  {subtypeElement.source === 'ImagingLab' ? '' : ` - ${subtypeElement?.cptCode}`}
                  <p id={`${groupId}_${subtypeElement.id || subtypeElement?.cptCode}_modifiers`}>
                    {subtypeElement?.modifiers?.map(
                    ({ modifierCode }) => <span>{modifierCode}</span>)}
                  </p>
                </p>

                {subtypeElement.source === 'ImagingLab' && (
                  <i className="download-tree" onClick={() => downloadImaging(subtypeElement?.cptCodeId, groupId)} aria-hidden="true" />
                )}
                {showCloseIcon && !savedSimpleEncounter && (
                <i
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteProcedure(
                      groupId,
                      subtypeElement?.procedureDetailsId,
                      !!subtypeElement?.associatedJcodes?.length,
                    );
                  }}
                  className="arrow-close"
                  aria-hidden="true"
                  id={`${groupId}_${subtypeElement.id}_cross_icon`}
                >
                  x
                </i>
                )}
              </div>
              <div
                id={`${groupId}_${subtypeElement.id}_down`}
                className={
                    `${groupId}_${subtypeElement.id}_down` === hoveredOn
                      ? 'in-between-space-hover'
                      : 'in-between-space'
                    }
              />
              <Compo
                associatedJcodes={subtypeElement?.associatedJcodes}
                groupId={groupId}
                parentModifiers={subtypeElement?.modifiers}
              />
            </div>
          ))}
        </div>
      );
    }
    if (associatedJcodes?.length) {
      return (
        <div className="sub-l-child-group j-code-sub-child" style={{ marginLeft: '28px' }}>
          <span className="hide-line" />
          {associatedJcodes?.map((subtypeElement, index) => !(type === 'inOffice' && subtypeElement?.source === 'ImagingLab') && (
          <div key={subtypeElement.id} id={`${groupId}_${subtypeElement.id}_showChild`}>
            { index === 0 && (
            <div
              id={`${groupId}_${subtypeElement.id}_up`}
              className={
                `${groupId}_${subtypeElement.id}_up` === hoveredOn
                  ? 'in-between-space-hover'
                  : 'in-between-space'
                }
            />
            )}
            <div
              onClick={handleProcedureClick({ groupId, ...subtypeElement })}
              onDragStart={(e) => {
                e.stopPropagation();
                handleProcedureDragStart(e, subtypeElement, groupId);
              }}
              onDragEnd={(e) => handleDragEnd(e)}
              id={`${groupId}_${subtypeElement.id}`}
              draggable={draggable}
              aria-hidden="true"
              className="positive-relative"
            >
              {showSelection && (
              <SMCheckBox
                className="labelLeft"
                name={null}
                onChange={handleCheckBoxClick(groupId, subtypeElement, false)}
                checked={checkedValues?.[groupId]?.patientProcedureCodeIds?.
                includes(subtypeElement.procedureDetailsId)}
              />
              )}
              <p id={`${groupId}_${subtypeElement.id || subtypeElement?.cptCode}_para`}>
                {subtypeElement?.label}
                {subtypeElement.source === 'ImagingLab' ? '' : ` - ${subtypeElement?.cptCode}`}
                {parentModifiers?.length > 1 ? (
                  <p
                    key={`${subtypeElement?.cptCode}_jcodes`}
                    id={`${groupId}_${subtypeElement.id || subtypeElement?.cptCode}_modifiers`}
                    role="presentation"
                  >
                    {getModifiersAccordingtoParent(parentModifiers)?.map(
                    ({ modifierCode, modifierId }, iter) => (
                      <button
                        // eslint-disable-next-line no-nested-ternary
                        className={`code-btn ${(subtypeElement?.modifiers?.[0]?.modifierId === modifierId) ? 'active' : !subtypeElement?.modifiers?.length ? '' : 'active-inactive'}`}
                        style={{
                          marginRight: '2px',
                        }}
                        onClick={(e) => handleJcodeModifierClick(e, { ...subtypeElement, groupId })}
                        id={`${subtypeElement?.cptCode}-btn-${iter + 1}`}
                        type="button"
                        value={modifierId}
                      >
                        {modifierCode}
                      </button>
                    ))}
                  </p>
                     ) : null}
              </p>
              {subtypeElement.source === 'ImagingLab' && (
              <i className="download-tree" onClick={() => downloadImaging(subtypeElement?.cptCodeId, groupId)} aria-hidden="true" />
              )}
              {showCloseIcon && !savedSimpleEncounter && (
              <i
                onClick={(e) => {
                  e.stopPropagation();
                  deleteProcedure(groupId, subtypeElement?.procedureDetailsId);
                }}
                className="arrow-close"
                aria-hidden="true"
                id={`${groupId}_${subtypeElement.id}_cross_icon`}
              >
                x
              </i>
              )}
            </div>
            <div
              id={`${groupId}_${subtypeElement.id}_down`}
              className={
                `${groupId}_${subtypeElement.id}_down` === hoveredOn
                  ? 'in-between-space-hover'
                  : 'in-between-space'
                }
            />
          </div>
          ))}
        </div>
      );
    }
    return null;
  };
  const dragEnter = useCallback((event) => {
    event.target.classList.add('in-between-space-hover');
  }, []);
  const dragExit = useCallback((event) => {
    event.target.classList.remove('in-between-space-hover');
  }, []);

  return (
    <div>
      {(labelList || [])?.length > 0 && (
          labelList.map((data, index) => (
            <>
              {index === 0 && <div onDragEnter={dragEnter} onDragLeave={dragExit} className="in-between-space" id={`${data?.groupId}_drag_${data?.sortOrder}_0`} />}
              <div
                key={labelList.groupId}
                className="ap-parent diagnosis-group-arrow rr info-alert"
              >
                {data?.subType && data?.subType?.length !== 0 && (<i className="arrow down" />)}
                <Compo
                  data={data}
                />
              </div>
              <div onDragEnter={dragEnter} onDragLeave={dragExit} className="in-between-space" id={`${data?.groupId}_drag_${data?.sortOrder}_${labelList?.[index + 1]?.sortOrder || data?.sortOrder + 2}`} />
            </>
          ))
        )}
      {((notesList || [])?.length > 0) && (
        notesList.map((data) => (data?.note?.length ? (
          <div
            role="presentation"
            key={data.id}
            className={classNames('cursor-pointer block-ui-display', { 'with-checkbox': showSelection })}
            onClick={() => setAddOption(data.noteType?.toLowerCase() === 'notes' ? 'note'
              : data.noteType?.toLowerCase())}
          >
            <div className="pannel">
              <div className="pannel-heading">
                {showSelection && (
                <SMCheckBox
                  className="labelLeft"
                  name={null}
                  onChange={handleCheckBoxClick(data?.id, data, null)}
                  checked={checkedValues?.
                    noteNames?.includes(data?.noteType)}
                />
                )}
                <div className="width-100-pr flex justify-content-sp-bt">
                  <div>
                    {capitalize(data.noteType)}
                  </div>
                  {showNotesDeleteIcon && (
                  <div className="close-icon-arrow">
                    <i
                      onClick={handleDeleteNotes(data)}
                      className="arrow-close"
                      aria-hidden="true"
                    />
                  </div>
                  )}
                </div>
              </div>
              <div className="pannel-body">
                <Input.TextArea
                  label=""
                  name={null}
                  labelSpan="0"
                  inputSpan="24"
                  autoSize={{ minRows: 1 }}
                  value={data.note}
                  disabled
                  onClick={() => setAddOption(data.noteType?.toLowerCase() === 'note' ? 'note'
                    : data.noteType?.toLowerCase())}
                />
              </div>
            </div>
          </div>
        ) : null))
      )}
    </div>
  );
};

JsonTree.defaultProps = {
  list: [],
  search: '',
  url: '',
  setLabelsList: () => { /* This is intentional */ },
  draggable: true,
  showCloseIcon: true,
  onAddItems: () => { /* This is intentional */ },
  handleProcedureClick: () => { /* This is intentional */ },
  showSelection: false,
  setAddOption: () => { /* This is intentional */ },
  showAddedProcedures: true,
  handleJcodeModifierClick: () => { /* This is intentional */ },
};

JsonTree.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  search: PropTypes.string,
  url: PropTypes.string,
  setLabelsList: PropTypes.func,
  draggable: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
  onAddItems: PropTypes.func,
  handleProcedureClick: PropTypes.func,
  showSelection: PropTypes.bool,
  setAddOption: PropTypes.func,
  showAddedProcedures: PropTypes.bool,
  handleJcodeModifierClick: PropTypes.func,
};

export default connect((states) => ({
  enumOptions: selectors.getEnumOptions(states),
  enumMaster: selectors.getEnumMaster(states),
}))(JsonTree);
