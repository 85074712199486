/* eslint-disable max-len */
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { Collapse } from 'antd';
import { orderBy } from 'lodash';
import iconNames from '../../../../../../../../../../lib/iconNames';
import Icon from '../../../../../../../../../../components/Icon';
import RenderMultipleSynoynms from '../../../../../../../../../../components/RenderMultipleSynonyms';
import { apiUrls } from '../../../../../../../../../../api/constants';
import useCRUD from '../../../../../../../../../../hooks/useCRUD';
import WidgetLoader from '../../../../../../../../../../components/WidgetLoader';
import ConfirmDialog from '../../../../../../../../../../components/ConfirmDialog';
import sortWithoutSymbols from '../../../../../../../../../../lib/sortWithoutSymbols';

const { Panel } = Collapse;

const handleFavoriteDragStart = (event) => {
  event.dataTransfer.setData('text', event?.target?.id);
  event.dataTransfer.setData('type', 'acuity-eye-tree-leaf');
  event.dataTransfer.setData('draggedItem', 'apply-diagnosis-folder-favorite');
  event.target.classList.add('dragging');
  event.dataTransfer.setData('draggedData', JSON.stringify({ favoriteId: event?.target?.id }));
};

const handleDiagnosisDragStart = (event) => {
  event.stopPropagation();
  event.dataTransfer.setData('text', event?.target?.id);
  event.dataTransfer.setData('type', 'acuity-eye-tree-leaf');
  event.dataTransfer.setData('draggedItem', 'dx');
  event.target.classList.add('dragging');
  event.dataTransfer.setData('draggedData', JSON.stringify({ icdCodeId: event.target?.id?.split(' ')[1] }));
};

const DxCollpasaable = ({
  item, handleApplyDiagnosis, deleteDiagnosis, showingDelete, searchText,
}) => {
  const [procedureSet, setProcedureSet] = useState(null);
  const [procedureSetsResponse, , procedureSetsLoading, getProcedureSetById] = useCRUD({
    id: `get-doctor-screen-dx-sets-${item.tagId}`,
    url: apiUrls.GET_PROCEDURE_SET_BY_ID,
    type: 'read',
  });

  useEffect(() => {
    if (procedureSetsResponse) {
      const sortedProcedureSet = orderBy(procedureSetsResponse.procedureDiagnosis, (e) => sortWithoutSymbols(e.synonyms) || sortWithoutSymbols(e.description));
      setProcedureSet({ ...procedureSetsResponse, procedureDiagnosis: sortedProcedureSet });
    }
  }, [procedureSetsResponse]);

  useEffect(() => {
    getProcedureSetById({ id: item.tagId, searchText, source: 'Diagnosis' });
  }, [getProcedureSetById, item.tagId, searchText]);

  return (
    <div
      key={item?.tagId}
      style={{ cursor: 'pointer' }}
      className="right-tree-view procedure-right ap-tree gap-on-dx-list"
    >
      {procedureSetsLoading && <WidgetLoader size="small" />}
      {procedureSet?.procedureDiagnosis?.map((diagnosis) => (
        <div
          key={`${diagnosis?.groupId}-${diagnosis?.diagnosisDetailsId}`}
          className="pannel-body posi-relative"
          draggable
          id={`${diagnosis?.groupId} ${diagnosis?.icdCodeId}`}
          onDragStart={handleDiagnosisDragStart}
          role="presentation"
        >
          <div
            className="flex flex-space-between min-tree-node-height"
          >
            <span className="label-text-width align-text-mid">
              {diagnosis?.synonyms ? <RenderMultipleSynoynms synonyms={diagnosis?.synonyms} />
                : diagnosis?.description}
            </span>
            <div className="separate-code separate-code-bg p-lr-4">
              <span className="code-only">{diagnosis?.icdCode}</span>
              {showingDelete ? (
                <Icon
                  className="mr-rt-12"
                  name={iconNames.closeCircleOutlined}
                  onClick={() => deleteDiagnosis(diagnosis?.diagnosisDetailsId,
                        item?.favouriteId)}
                />
              ) : null}
              <Icon
                className="mr-rt-12 left-margin-add-icon"
                name={iconNames.deliveredProcedureOutlined}
                onClick={() => {
                  handleApplyDiagnosis(diagnosis?.icdCodeId);
                }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const DxJsonTree = ({
  list, deleteFolderSet, deleteDiagnosis, showingDelete, searchText,
  showAllExpanded, handleApplyDiagnosis, handleApplyDiagnosisFolder,
}) => {
  const [openPanelIds, setOpenPanelIds] = useState({});

  useEffect(() => {
    if (showAllExpanded && list?.length) {
      setOpenPanelIds(list.reduce((acc, item) => {
        if (item.tagId) acc[item.tagId] = true;
        return acc;
      }, {}));
    } else {
      setOpenPanelIds({});
    }
  }, [list]);

  const handlePanelChange = useCallback((tag) => {
    if (openPanelIds[tag]) {
      setOpenPanelIds({ ...openPanelIds, [tag]: false });
    } else {
      setOpenPanelIds({ ...openPanelIds, [tag]: true });
    }
  }, [openPanelIds, setOpenPanelIds]);

  const genExtra = useCallback((id) => (
    <div className="flex mr-top-2">
      {showingDelete ? (
        <Icon
          className="mr-rt-12"
          name={iconNames.closeCircleOutlined}
          onClick={(event) => { event.stopPropagation(); deleteFolderSet(id); }}
        />
      ) : null}
      <Icon
        className="mr-rt-8 mr-lt-8"
        name={iconNames.deliveredProcedureOutlined}
        onClick={() => {
          ConfirmDialog({
            onOk: (close) => {
              handleApplyDiagnosisFolder(id);
              close();
            },
            onCancel: (close) => {
              close();
            },
            okText: 'Continue',
            title: 'Warning',
            content: 'Are you sure you would like to apply the complete folder of Dx',
            icon: <Icon name="ExclamationCircleOutlined" />,
          })();
        }}
      />
    </div>
  ), [deleteFolderSet, handleApplyDiagnosisFolder, showingDelete]);

  if (list?.length) {
    return list?.map((item) => (
      <div className="sub-menu-collapse square-view-box gap-on-dx-list-parent">
        <div
          key={item?.tagId}
          className=" right-tree"
          draggable
          id={item?.tagId}
          onDragStart={handleFavoriteDragStart}
        >
          <Collapse
            accordion
            id={item?.tagId}
            activeKey={openPanelIds[item?.tagId] ? `${item?.tagId}` : null}
            onChange={() => handlePanelChange(item?.tagId)}
          >
            <Panel
              header={item?.tagName}
              key={item?.tagId}
              extra={genExtra(item?.tagId)}
            >
              {openPanelIds[item?.tagId] ? (
                <DxCollpasaable
                  item={item}
                  handleApplyDiagnosis={handleApplyDiagnosis}
                  deleteDiagnosis={deleteDiagnosis}
                  showingDelete={showingDelete}
                  searchText={searchText}
                />
              ) : null}
            </Panel>
          </Collapse>
        </div>
      </div>
    ));
  }
  return null;
};

export default DxJsonTree;
