import React, {
  useEffect, useState, useCallback,
} from 'react';

import Modals from '../../../components/Modal';
import Table from '../../../components/Table';
import WidgetLoader from '../../../components/WidgetLoader';
import Button from '../../../components/Button';

import useCRUD from '../../../hooks/useCRUD';
import WithLabel from '../../../hoc/withLabel';
import { apiUrls } from '../../../api/constants';
import { labelPaths, UiRoutes, schedulerDataDateFormat } from '../../../lib/constants';
import { getUTCDate } from '../../../lib/util';

import useRedirect from '../../../hooks/useRedirect';

const columns = (labels) => [
  {
    Header: labels.get('tableColumns.modifiedBy'),
    accessor: 'modifiedByName',
  },
  {
    Header: labels.get('tableColumns.modifiedOn'),
    accessor: 'modifiedOn',
    fixWidth: 160,
    Cell: ({
      row: {
        original,
      },
    }) => (
      <span>{getUTCDate(original?.createdOnDateModel?.dateString, schedulerDataDateFormat)}</span>
    ),

  },
  {
    Header: labels.get('tableColumns.role'),
    accessor: 'modifiedByRole',
  },
  {
    Header: labels.get('tableColumns.status'),
    accessor: 'status',
  },
];

const ActivityLog = ({
  labels,
}) => {
  const [logs, setLog] = useState([1]);
  const { params, replace } = useRedirect();

  const appointmentId = params?.activityLogAppointmentId;
  const [
    activityResponse,,
    activityLoading,
    getActivity,
    clearActivity,
  ] = useCRUD({
    id: `appointment-activity-${appointmentId}`, url: apiUrls.GET_ACTIVITY, type: 'read',
  });

  useEffect(() => {
    if (appointmentId) {
      getActivity({ SortBy: 'modifiedOn', SortOrder: 'desc' }, `/${appointmentId}/Activity`);
    }
  }, [appointmentId]);

  useEffect(() => {
    if (activityResponse) {
      setLog(activityResponse);
      clearActivity(true);
    }
  }, [activityResponse]);

  const onClose = useCallback(() => {
    replace(UiRoutes.dashboard);
    setLog([]);
  }, [replace]);

  return (
    <Modals
      className="posi-relative"
      toggleModal={onClose}
      visible={appointmentId}
      title={labels.get('titles.activityLog')}
      footer={<Button className="btn min-wt-86" onClick={onClose}>{labels.get('buttons.close')}</Button>}
    >
      {activityLoading && <WidgetLoader />}
      {!!logs?.length && (
      <div className="activity-log-table">
        <Table data={logs} columns={columns(labels)} scrollId="dashboard-activity-log" />
      </div>
      )}
    </Modals>
  );
};

export default React.memo(WithLabel(ActivityLog, labelPaths.DASHBOARD_ACTIVITY_LOG));
