import moment from 'moment';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';

import {
  dateFormatStartsWithYear,
  dateFormatStartsWithYearSeconds,
  schedularLocationColorRules,
  timeFormat,
} from './constants';

export const statusBasedClasses = {
  CheckIn: 'check-in',
  New: 'pending',
  Cancel: 'canceled',
  Workup: 'check-in',
  ReadyForPhysician: 'check-in',
  Physician: 'check-in',
  PendingCheckout: 'pending-checkout',
  CheckOut: 'check-out',
  Reschedule: 'reschedule',
  Billed: 'billed',
  BilledAndPendingCheckout: 'billedandpendingcheckout',
};

export const statusBasedTexts = {
  CheckIn: 'Checked In',
  New: 'New',
  Cancel: 'Cancelled',
  Workup: 'Workup',
  ReadyForPhysician: 'Ready For Physician',
  Physician: 'Physician',
  PendingCheckout: 'Pending Checkout',
  CheckOut: 'Checked Out',
  Reschedule: 'Reschedule',
  Billed: 'Billed',
  BilledAndPendingCheckout: 'Billed And Pending Checkout',
};

const isApptWithinStartEndHrs = (appointment, i, slotDuration) => {
  const {
    slotPositions: {
      startYAt: apptStartYAt, endYAt: apptEndYAt, endXAt, startXAt,
    },
  } = appointment;
  const startHour = i;
  const endHour = i + 12;
  let apptDuration = 0;
  let duration = 0;
  let appointmentCount = 0;
  if ((apptStartYAt >= startHour && apptStartYAt <= endHour)
  || (apptEndYAt > startHour && apptEndYAt <= endHour)) {
    if (apptStartYAt > startHour && apptEndYAt > endHour) {
      apptDuration += (endHour - apptStartYAt) * slotDuration;
      appointmentCount += 1;
    } else if (apptStartYAt < startHour && apptEndYAt < endHour) {
      apptDuration += (apptEndYAt - startHour) * slotDuration;
      appointmentCount += 1;
    } else {
      apptDuration += (apptEndYAt - apptStartYAt) * slotDuration;
      appointmentCount += 1;
    }
    duration += apptDuration;

    if ((endXAt === (slotDuration / 2) + 1
    || startXAt === (slotDuration / 2) + 1) && apptDuration > 0) {
      duration -= slotDuration / 2;
    }
  }
  return { calculatedDuration: duration, calculatedAppointmentCount: appointmentCount };
};

export const locationColorCalculator = (
  { slotPositions, groupByAppointments, locationId }, slotDuration,
) => {
  const { startYAt, endYAt } = slotPositions;
  const hours = [];
  const locationAppointments = get(groupByAppointments, locationId, []);
  for (let i = startYAt; i < endYAt; i += 12) {
    let duration = 0;
    let appointmentCount = 0;
    locationAppointments.forEach((appointment) => {
      const {
        calculatedDuration,
        calculatedAppointmentCount,
      } = isApptWithinStartEndHrs(appointment, i, slotDuration);
      duration += calculatedDuration;
      appointmentCount += calculatedAppointmentCount;
    });
    const bookPercentage = (duration * 100) / 60 || 0;
    const filteredRule = schedularLocationColorRules.filter((rule) => (
      bookPercentage >= rule?.minPercent && bookPercentage < rule?.maxPercent
    ));

    let end = i + 12;

    if (end > endYAt) {
      end = endYAt;
    }
    hours.push({
      start: i, end, count: appointmentCount, background: get(filteredRule, '[0].color', ''),
    });
  }
  return hours;
};

export function slotCalculator(startTime, endTime, timeRange, slotDuration = 10) {
  const timeOfSlotsPerRow = slotDuration / 2;
  const startMoment = moment(startTime);
  const endMoment = moment(endTime);
  const numberOfSlotsPerHour = 60 / timeOfSlotsPerRow;
  const { from } = timeRange;
  const fromThisDate = moment(
    `${moment(startMoment).format(dateFormatStartsWithYear)} ${moment(from).format(timeFormat)}`,
    dateFormatStartsWithYearSeconds,
  );
  const startDuration = moment.duration(moment(startMoment).diff(fromThisDate));
  const endDuration = moment.duration(moment(endMoment).diff(fromThisDate));

  const startY = (startDuration.hours() * numberOfSlotsPerHour)
      + Math.floor(startDuration.minutes() / timeOfSlotsPerRow) + 1;

  const endY = (endDuration.hours() * numberOfSlotsPerHour)
      + Math.ceil(endDuration.minutes() / timeOfSlotsPerRow) + 1;

  return {
    startYAt: startY > 0 ? startY : 1,
    startXAt: 1,
    endYAt: endY > 0 ? endY : 1,
    endXAt: 11,
  };
}

export const scheduleDataParser = (scheduleData, timeRange, slotDuration = 10) => {
  const { schedule = [], appointments } = scheduleData;
  const groupByAppointments = groupBy(appointments, 'locationId');
  const schedulesWithSlot = schedule.map((element) => {
    const { locationId } = element;
    const from = moment(element.slotDateString);
    const fromTime = moment(element.slotStartTime, 'hh:mm:ss');
    from.set({
      hour: fromTime.get('hour'),
      minute: fromTime.get('minute'),
      second: fromTime.get('second'),
    });

    const to = moment(element.slotDateString);

    const toTime = moment(element.slotEndTime, 'hh:mm:ss');
    to.set({
      hour: toTime.get('hour'),
      minute: toTime.get('minute'),
      second: toTime.get('second'),
    });
    const slotPositions = slotCalculator(from, to, timeRange, slotDuration);
    const hours = locationColorCalculator(
      { groupByAppointments, slotPositions, locationId }, slotDuration,
    );
    return {
      ...element,
      from,
      to,
      slotPositions,
      hours,
    };
  });
    // eslint-disable-next-line no-param-reassign
  scheduleData.schedule = schedulesWithSlot;
};

export const appointmentsParser = (scheduleData, timeRange, slotDuration) => {
  const { appointments = [] } = scheduleData;
  const appointmentsWithSlot = appointments.map((element) => {
    const slotPositions = slotCalculator(
      element.startTime, element.endTime, timeRange, slotDuration,
    );
    return {
      ...element,
      slotPositions,
    };
  });
    // eslint-disable-next-line no-param-reassign
  scheduleData.appointments = appointmentsWithSlot;
};

export const getContextMenuItems = (appointment, isUpdateAppointmentAuthenticated) => {
  if (!appointment) return [];
  const {
    isCheckedIn,
    isCheckedOut,
    status,
    appointmentStartDateTime,
  } = appointment;
  const menuItems = [];
  if (status?.toLowerCase() === 'billed') {
    return menuItems;
  }

  if (status?.toLowerCase() === 'billedandpendingcheckout') {
    menuItems.push({ name: 'Check-out', tab: 'check-out', type: 'link' });
    menuItems.push({ name: 'Create new follow-up appointment', tab: 'check-out', type: 'link' });
    return menuItems;
  }

  if (status?.toLowerCase() === 'pendingcheckout') {
    menuItems.push({ name: 'Edit Check-in', tab: 'check-in', type: 'link' });
    menuItems.push({ name: 'Check-out', tab: 'check-out', type: 'link' });
    menuItems.push({ name: 'Create new follow-up appointment', tab: 'check-out', type: 'link' });
    return menuItems;
  }

  const isInFuture = moment(appointmentStartDateTime?.dateString).isAfter(moment(), 'day');
  if (status?.toLowerCase() === 'cancel' || isInFuture) {
    menuItems.push({ name: 'Reschedule', tab: 'check-in', type: 'link' });
    return menuItems;
  }

  if (!isCheckedOut) {
    if (isCheckedIn) {
      // checked in but not checked out
      menuItems.push({ name: 'Undo Check-in', tab: 'undo-check-in', type: 'li' });
      menuItems.push({ name: 'Edit Check-in', tab: 'check-in', type: 'link' });
      menuItems.push({ name: 'Check-out', tab: 'check-out', type: 'link' });
    }
  } else {
    // checked out
    menuItems.push({ name: 'Undo Check-out', tab: 'undo-check-out', type: 'li' });
  }
  if (!isCheckedIn) {
    // not checked in
    if (isUpdateAppointmentAuthenticated) {
      menuItems.push({ name: 'Check-in', tab: 'check-in', type: 'link' });
      menuItems.push({ name: 'Reschedule', tab: 'check-in', type: 'link' });
    }
  } else {
    // checked in
    menuItems.push({ name: 'Create new follow-up appointment', tab: 'check-out', type: 'link' });
  }
  return menuItems;
};

export default {
  scheduleDataParser,
  appointmentsParser,
};
