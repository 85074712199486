import React, { useState, useEffect, useCallback } from 'react';
import { VideoCameraOutlined } from '@ant-design/icons';

import { apiUrls } from '../../../api/constants';
import SuccessMessages from '../../../lib/successMessages';
import { contentType } from '../../../lib/constants';
import Events from '../../../lib/events';

import Modals from '../../../components/Modal';
import Notification from '../../../components/Notification';
import WidgetLoader from '../../../components/WidgetLoader';
import UploadProfilePic from '../../../components/Upload';

import WebcamCapture from './Components/WebcamCapture';

import useCRUD from '../../../hooks/useCRUD';
import useRedirect from '../../../hooks/useRedirect';

import './photo.scss';

const UploadPatientPhoto = ({
  labels, visible, toggleModal, ...otherProps
}) => {
  const { params } = useRedirect();
  const [patientId, setSelectedPatientId] = useState(null);
  const [isWebcamEnable, setWebcamMode] = useState(false);

  const [uploadResponse, , uploadLoading,
    uploadFile,
    clearUploadResponse] = useCRUD({
    id: `upload-user-profile-pic-${patientId}`,
    url: apiUrls.UPLOAD_PATIENT_IMAGE,
    type: 'create',
  });

  const toggleWebCamMode = useCallback(() => {
    setWebcamMode(!isWebcamEnable);
  }, [isWebcamEnable]);

  const onClose = useCallback(() => {
    if (isWebcamEnable) toggleWebCamMode();
    toggleModal();
    if (!params?.id && !params?.patientId) setSelectedPatientId(null);
  }, [isWebcamEnable, params, toggleWebCamMode, toggleModal]);

  useEffect(() => {
    if (otherProps?.patientId) setSelectedPatientId(otherProps.patientId);
  }, [otherProps, otherProps.patientId]);

  useEffect(() => {
    if (uploadResponse) {
      Notification({
        message: SuccessMessages.PROFILE_PIC_UPLOADED_SUCCESSFULLY,
        success: true,
      });
      onClose();
      Events.trigger(`refetch-patient-info${patientId}`, ({ isReFetch: true }));
      clearUploadResponse(true);
    }
  }, [uploadResponse]);

  const onSelectFile = useCallback(({ file: fileData }) => {
    uploadFile({
      data: {
        contentType: contentType.MULTIPART,
        PatientImage: fileData,
        PatientId: patientId,
        DocumentDisplayName: fileData?.name?.substr(0, fileData?.name?.lastIndexOf('.')),
      },
    });
    return true;
  }, [uploadFile, patientId]);

  return (
    <Modals
      visible={visible}
      width={350}
      toggleModal={onClose}
      destroyOnClose
      footer={null}
    >
      <div>
        { uploadLoading && <WidgetLoader /> }
        <div className="addition-header photo-modal-header">
          <p className="bold">Add Profile Picture</p>
        </div>
        {!isWebcamEnable ? (
          <div>
            <div className="photo-content-area">
              <UploadProfilePic
                accept="image/png, image/jpeg, image/png"
                title="Choose a file from your computer"
                labelSpan="0"
                inputSpan="24"
                onChange={onSelectFile}
                multiple={false}
                name={null}
                showUploadList={false}
              />
              <div className="content-area-message">
                <p className="content-type-message">You can upload a JPG, GIF or PNG file.</p>
                <p className="horizontal-content-message"><span>or</span></p>
              </div>
              <div className="webcam-content-area" role="presentation" onClick={toggleWebCamMode}>
                <VideoCameraOutlined />
                <span className="webcam-content-message">Take a photo from your webcam</span>
              </div>
            </div>
          </div>
        ) : (
          <WebcamCapture uploadFile={onSelectFile} />
        )}
      </div>
    </Modals>
  );
};

export default UploadPatientPhoto;

