import React, { useCallback } from 'react';
import { Form as AntdForm } from 'antd';

import useRedirect from '../../hooks/useRedirect';
import Modals from '../../components/Modal';
import EnumForm from './EnumForm';
import Button from '../../components/Button';
import SuccessMessages from '../../lib/successMessages';
import Notification from '../../components/Notification';

const EditModal = ({
  fields, enumMasterId, title, navigateToParent, reFetch,
}) => {
  const { params: { masterDataId } } = useRedirect();
  const [form] = AntdForm.useForm();

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.MASTER_DATA_UPDATED_SUCCESSFULLY, success: true });
      form.resetFields();
      navigateToParent();
      reFetch();
    }
  }, [form, navigateToParent, reFetch]);

  return (
    <Modals
      visible={!!masterDataId}
      destroyOnClose
      toggleModal={() => {
        form.resetFields();
        navigateToParent();
      }}
      footer={[
        <div className="group-btns" key="footer">
          <Button className="btn min-wt-86" onClick={() => { form.resetFields(); }}>Clear</Button>
          <Button className="btn btn-success min-wt-86 inline" onClick={() => form.submit()}>Update</Button>
        </div>,
      ]}
    >
      {masterDataId && (
        <EnumForm
          key={masterDataId}
          title={`Edit ${title}`}
          form={form}
          isUpdate
          onRequestComplete={onRequestComplete}
          fields={fields}
          enumId={enumMasterId}
        />
      )}
    </Modals>
  );
};

export default EditModal;
