/* eslint-disable max-len */
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { getuuid } from '../../lib/util';
import sortWithoutSymbols from '../../lib/sortWithoutSymbols';

export const procedureSources = ['Gonioscopy', 'Sensorimotor', 'ExamDrawing', 'Cycloplegic', 'DrawingOfOpticNerve/Macula',
  'ScleralDepressionPerformed', 'ENMSignAndBill', 'EncounterImages', 'LabImaging', 'Systolic', 'Diastolic', 'BMI', 'Medication'];

export const getSubtypes = (obj, e, cptData = {}) => {
  if (obj[e.groupId]?.subType.length) {
    const index = obj[e.groupId]?.subType?.findIndex(
      (item) => item?.procedureDetailsId === cptData?.patientProcedureCodeId);
    if (!cptData?.procedureDetailsId) { return [...obj[e.groupId]?.subType]; }
    if (index < 0) {
      return [
        ...obj[e.groupId]?.subType,
        cptData,
      ];
    }
  }
  return cptData?.procedureDetailsId ? [
    { ...cptData },
  ] : [];
};

export const getSubTypesWithJcode = (obj, e, cptData = {}) => {
  const subTypes = getSubtypes(obj, e, cptData);
  const groupByJcode = groupBy(subTypes, 'associatedProcedureId');
  subTypes?.forEach((item) => {
    if (groupByJcode[item.procedureDetailsId]) {
      // eslint-disable-next-line no-param-reassign
      item.associatedJcodes = groupByJcode[item.procedureDetailsId];
    }
  });
  return subTypes;
};

export const diagnosisProcedureSets = (data) => {
  if (data?.length) {
    const obj = {};
    data.forEach((e) => {
      if (e?.procedureList?.length) {
        e.procedureList.forEach((proc) => {
          const { result = [] } = proc;
          const dynamicFormFields = keyBy(result, (element) => element.key);
          Object.keys(dynamicFormFields).forEach((key) => {
            dynamicFormFields[key] = dynamicFormFields[key].answer;
          });
          const cptData = {
            ...proc,
            cptCode: proc.cptCode,
            label: proc.cptDescription,
            cptCodeId: proc.cptCodeId,
            cptDescription: proc.cptDescription,
            patientProcedureCodeId: proc.procedureDetailsId,
            procedureDescription: e?.procedureDescription,
            modifier1: proc.modifier1,
            modifier2: proc.modifier2,
            modifier3: proc.modifier3,
            modifier4: proc.modifier4,
            modifiers: proc.modifiers,
            ...dynamicFormFields,
          };
          if (obj[e.groupId]) {
            obj[e.groupId] = {
              ...obj[e.groupId],
              diagnosisList: uniqBy([...obj[e?.groupId].diagnosisList, { ...e, label: e?.description }], 'diagnosisDetailsId'),
              subType: getSubTypesWithJcode(obj, e, cptData),
            };
          } else {
            obj[e.groupId] = {
              groupId: proc.groupId,
              diagnosisList: [{ ...e, label: e?.description }],
              subType: proc.cptCode ? [{
                id: proc.procedureDetailsId,
                ...cptData,
              }] : [],
            };
          }
        });
      } else {
        obj[e.groupId] = {
          groupId: e.groupId,
          diagnosisList: [...(obj[e.groupId]?.diagnosisList || []),
            { ...e, label: e?.description }],
          subType: [],
        };
      }
    });
    const sortedGroups = Object.values(obj).map((group) => ({
      ...group,
      diagnosisList: group.diagnosisList,
      subType: orderBy(group.subType, (e) => sortWithoutSymbols(e.cptDescription)).map((sub) => ({
        ...sub,
        associatedJcodes: orderBy(sub.associatedJcodes, (e) => sortWithoutSymbols(e.synonyms) || sortWithoutSymbols(e.cptDescription)),
      })),
    }));

    return sortedGroups;
  }
  return [];
};

export const procedureDiagnosisMapping = (procedureSets) => {
  const procObj = {};
  procedureSets?.procedureDetails?.forEach((proc) => {
    if (procObj[proc?.groupId]) {
      procObj[proc?.groupId].push(proc);
    } else {
      procObj[proc?.groupId] = [proc];
    }
  });
  return {
    result: diagnosisProcedureSets(procedureSets?.procedureDiagnosis?.map((item) => ({
      ...item,
      procedureList: procObj[item?.groupId] ?? [],
      procedureDescription: procObj[item?.groupId]?.[0]?.procedureDescription,
    }))),
    slitLampList: procedureSets?.slitExamList,
    fundusExamList: procedureSets?.fundusExamList,
    followUp: procedureSets?.followUp,
    notes: procedureSets?.notes,
  };
};

export const procedureSetParser = (procedureSets) => {
  const tagData = procedureSets?.reduce((accumulator, data) => data?.tags?.reduce((acc, tag) => {
    const existingTagIndex = acc.findIndex(
      (existingTag) => existingTag?.tagName?.toLowerCase() === tag?.tagName?.toLowerCase(),
    );

    if (existingTagIndex > -1) {
      acc[existingTagIndex].favourites = [
        ...acc[existingTagIndex].favourites,
        ...tag?.favourites || [],
      ];
    } else {
      acc.push({
        key: getuuid(),
        tagId: tag.tagId,
        tagName: tag?.tagName?.toLowerCase(),
        favourites: tag?.favourites || [],
      });
    }
    return acc;
  }, accumulator), []);

  return tagData
    .map((tag) => ({
      ...tag,
      favourites: orderBy(tag.favourites, (fav) => sortWithoutSymbols(fav.favouriteName)),
    }))
    .sort((a, b) => sortWithoutSymbols(a.tagName).localeCompare(sortWithoutSymbols(b.tagName)));
};

export const procedureParser = (data) => {
  if (data?.procedureDetails?.length) {
    const obj = {};
    data?.procedureDetails?.forEach((e) => {
      const { result = [] } = e;
      const dynamicFormFields = keyBy(result, (element) => element.key);
      Object.keys(dynamicFormFields).forEach((key) => {
        dynamicFormFields[key] = dynamicFormFields[key].answer;
      });
      const cptData = {
        cptCode: e.cptCode,
        label: e.cptDescription,
        cptCodeId: e.cptCodeId,
        cptDescription: e.cptDescription,
        procedureDetailsId: e.procedureDetailsId,
        associatedProcedureId: e.associatedProcedureId,
        modifier1: e.modifiers?.[0],
        modifier2: e.modifiers?.[1],
        modifier3: e.modifiers?.[2],
        modifier4: e.modifiers?.[3],
        modifiers: e.modifiers,
        source: e.source,
        procedureDescription: e.procedureDescription,
        jCode: e.jCode,
        lot: e.lot,
        units: e.units,
        expiration: e.expiration,
        dosage: e.dosage,
        isJcode: e.isJcode,
        result: e.result,
        sortOrder: e.sortOrder,
        encounterId: data?.encounterId,
        ...dynamicFormFields,
      };
      if (!procedureSources?.includes(e?.source)) {
        if (e?.groupId) {
          if (obj[e.groupId]) {
            obj[e.groupId] = {
              ...obj[e.groupId],
              sortOrder: e.sortOrder,
              subType: getSubTypesWithJcode(obj, e, cptData),
            };
          } else {
            obj[e.groupId] = {
              groupId: e.groupId,
              sortOrder: e.sortOrder,
              diagnosisList: e.diagnosisList,
              subType: e.cptCode ? [{
                id: e.cptCode,
                ...cptData,
              }] : [],
            };
          }
        }
      }
    });
    return sortBy(Object.values(obj), (item) => item.sortOrder);
  }
  return [];
};

export const libraryParser = (inputData) => {
  const groupByAndSort = (data, key) => {
    const groupedData = groupBy(data, key);
    return orderBy(Object.entries(groupedData), ([entryKey]) => sortWithoutSymbols(entryKey));
  };

  const sortTags = (tags) => tags.map((tag) => ({
    ...tag,
    tags: orderBy(tag.tags.map((tag1) => ({
      ...tag1,
      favourites: orderBy(tag1.favourites, (e) => sortWithoutSymbols(e.favouriteName)),
    })), (e) => sortWithoutSymbols(e.tagName)),
  }));

  const groupedBySpeciality = groupByAndSort(inputData, 'specialityName');

  const sortedSpecialities = groupedBySpeciality.map(([specialityName, providers]) => {
    if (!providers || providers.length === 0) {
      return null;
    }

    const { specialityId } = providers[0];
    const groupedByProvider = groupByAndSort(providers, 'providerName');

    const sortedProviders = groupedByProvider.map(([providerName, tags]) => {
      if (!tags || tags.length === 0) {
        return null;
      }

      const { providerId, source } = tags[0];
      const sortedTags = sortTags(tags);

      return {
        name: providerName,
        id: providerId,
        source,
        sets: sortedTags,
      };
    }).filter(Boolean);

    return {
      specialityName,
      specialityId,
      providers: sortedProviders,
    };
  }).filter(Boolean);

  return sortedSpecialities.filter((item) => item.specialityName !== 'null');
};

export const diagnosisParser = (diagnosisList) => {
  const parsedDiagnosis = diagnosisList.map((item) => ({
    ...item,
    tagId: item?.tags?.[0]?.favourites?.[0]?.favouriteId,
    title: item?.tags?.[0]?.tagName?.toLowerCase(),
  }));
  return orderBy(parsedDiagnosis, 'title');
};
