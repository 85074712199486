import { useDispatch } from 'react-redux';

import { updateCurrentTabName } from '../store/actions/navigation';
import useRedirect from './useRedirect';
import { store } from '../store';

const useUpdateTabName = () => {
  const dispatch = useDispatch();
  const { path } = useRedirect();
  const { tabs } = store.getState();
  return (name) => dispatch(updateCurrentTabName(path, name, tabs.get('tabModuleName')));
};

export default useUpdateTabName;
