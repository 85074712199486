import React, { useState, useCallback } from 'react';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Form/Input';
import Radio from '../../../../../components/Form/Radio';
import '../OrderSet/orderset.scss';

const radioOptions = [{ name: 'practice', value: 'practice', label: 'Practice' }, { name: 'provider', value: 'provider', label: 'Provider' }];
const DxFavorite = () => {
  const [createOrderValue, setCreateOrderValue] = useState(true);
  const createOderSet = useCallback(() => {
    setCreateOrderValue(!createOrderValue);
  }, [createOrderValue]);
  return (
    <>
      <div>
        { createOrderValue
          ? <p>DxFavorite</p> : (
            <div className="create-order-set-wrap">
              <div className="header-options">
                <Input name="favouriteName" label="Order Set Name" labelSpan={8} inputSpan={16} />
                <Radio
                  options={radioOptions}
                  labelSpan={0}
                  inputSpan={24}
                  label=""
                  valuePropName="checked"
                />
              </div>
            </div>
          )}
        <Button onClick={createOderSet} className="btn-success sm-btn on-top-for-now">Create</Button>
      </div>
      <div className="save-cancel-wrap">
        <Button className="btn-outline sm-btn">Cancel</Button>
        <Button className="btn-success sm-btn"> Save</Button>
      </div>
    </>
  );
};

export default DxFavorite;
