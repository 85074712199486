import React, {
  useCallback, useRef,
} from 'react';
import {
  DatePicker as AntdDatePicker,
  Form as AntdForm,
} from 'antd';
import moment from 'moment';

import { listId, responseType } from '../../../lib/constants';
import { apiUrls } from '../../../api/constants';
import downloadFile from '../../../lib/downloadFile';

import useCrudWithEffects from '../../../hooks/useCrudWithEffects';

import Form from '../../../components/Form';
import Button from '../../../components/Button';
import Modals from '../../../components/Modal';
import DatePicker from '../../../components/DatePicker';
import WidgetLoader from '../../../components/WidgetLoader';
import { CheckBox } from '../../../components/Form/inputFields';

import WiredSelect from '../../Select';

const { RangePicker } = AntdDatePicker;

const DetailStatementModal = ({
  labels, visible, toggleModal, patientId,
}) => {
  const viewPanel = useRef();
  const [form] = AntdForm.useForm();

  const handlePrintPdf = useCallback((detailStatementResponse) => {
    downloadFile(detailStatementResponse, `detailStatement-${moment().format('DD-MM-YY hh-mm-ss')}.pdf`, 'application/pdf');
    toggleModal();
  }, [toggleModal]);

  const [getdetailStatementResponse, detailStatementLoading] = useCrudWithEffects({
    id: `detail-statment-pdf-${patientId}`,
    url: apiUrls.GET_PATIENT_DETAIL_STATEMENT_PDF,
    callBack: handlePrintPdf,
    type: 'read',
  });

  const getDetailStatementPdf = useCallback(() => {
    const { effective: [fromDate, toDate] = [], isCharge, insuranceId } = form.getFieldsValue();
    const data = {
      insuranceProfileId: insuranceId,
      patientId,
      isCharge: (isCharge === undefined ? false : isCharge),
      effectiveFrom: fromDate && moment(fromDate).hours(0).minutes(0).seconds(0)
        .milliseconds(0)
        .format('YYYY-MM-DD'),
      effectiveTo: toDate && moment(toDate).hours(23).minutes(59).seconds(59)
        .milliseconds(0)
        .format('YYYY-MM-DD'),
    };
    getdetailStatementResponse({
      ...data,
      responseType: responseType.BLOB,
      isReFetch: true,
    });
  }, [form, getdetailStatementResponse, patientId]);

  return (
    <>
      <div ref={viewPanel} className="display-none" />
      <Modals
        visible={visible}
        width={512}
        className="notes-modal"
        toggleModal={toggleModal}
        destroyOnClose
        footer={[
          <div className="appointment-modal-footer" key="footer">
            <Button type="button" className="btn min-wt-86 mr-lt-16" onClick={toggleModal}>
              {labels.get('buttons.cancel')}
            </Button>
            <Button type="button" className="btn btn-success btn-primary" onClick={getDetailStatementPdf}>
              {labels.get('buttons.print')}
            </Button>
          </div>,
        ]}
      >
        {detailStatementLoading && <WidgetLoader />}
        <div className="addition-header print-modal-header">
          <span className="print-modal-icon" />
          <p className="bold">{labels.get('titles.detailStatement')}</p>
        </div>
        <Form form={form}>
          <DatePicker
            labelSpan="8"
            inputSpan="16"
            component={RangePicker}
            label={labels.get('labels.effectiveDates')}
            name="effective"
          />
          <WiredSelect
            name="insuranceId"
            id={`${listId.INSURANCE_PROFILE_DETAILS_SELECT}-${patientId}`}
            url={apiUrls.GET_PATIENT_INSURANCE}
            labelSpan="7"
            inputSpan="17"
            label={labels.get('labels.insuranceProfile')}
            valueAccessor="insuranceProfileId"
            nameAccessor="profileName"
            selectProps={{
              className: 'insurance-select-component',
              dropdownClassName: 'insurance-select-dropdown',
            }}
            params={{
              PatientId: patientId,
              IncludeInActive: true,
            }}
            allowClear={false}
          />
          <CheckBox
            labelSpan="7"
            inputSpan="17"
            label={labels.get('labels.charges')}
            valuePropName="checked"
            name="isCharge"
          />
        </Form>
      </Modals>
    </>
  );
};
export default DetailStatementModal;
