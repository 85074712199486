const modifiers = (labels) => [
  {
    Header: labels.get('tableColumns.modifiers'),
    accessor: 'modifierCPTCode',
    fixWidth: '220',
  },
  {
    Header: labels.get('tableColumns.description'),
    accessor: 'modifierDescription',
  },
  {
    Header: labels.get('tableColumns.tier'),
    accessor: 'modifierTier',
    fixWidth: '120',
  },
  {
    Header: labels.get('tableColumns.multiplierValue'),
    accessor: 'multiplier',
    fixWidth: '200',
  },
  {
    Header: labels.get('tableColumns.delete'),
    accessor: 'delete',
    fixWidth: '50',
  },
  {
    Header: labels.get('tableColumns.action'),
    accessor: 'action',
    fixWidth: '200',
  },
];

export default modifiers;
