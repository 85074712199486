import React, { useCallback } from 'react';
import { Tooltip } from 'antd';

import StatusCell from '../../../components/StatusCell';
import '../CPT/CPTTable.scss';

export const cptIcdCommonColumns = [
  {
    Header: 'Description',
    accessor: 'description',
    Cell: ({ row: { original: { isActive, description } } }) => (
      <div className={!isActive ? 'backColor' : 'noBackColor'}>
        {description}
      </div>
    ),
  },
  {
    Header: 'Status',
    accessor: 'isActive',
    fixWidth: 200,
    Cell: ({ row: { original: { isActive } } }) => (
      <div className={!isActive ? 'backColor' : 'noBackColor'}>
        <StatusCell active={isActive} />
      </div>
    ),
  },
];

const columns = (hideICDSearch) => [
  {
    Header: 'ICD Code',
    accessor: 'icdCode',
    fixWidth: 200,
    Cell: ({ row: { original: { isActive, icdCode } } }) => (
      <div className={!isActive ? 'backColor' : 'noBackColor'}>
        {icdCode}
      </div>
    ),
  },
  ...cptIcdCommonColumns,
  {
    Header: '',
    accessor: 'hideIcdCodeSearch',
    fixWidth: '50',
    Cell: ({ row: { original } }) => {
      const { isActive } = original;
      const onhideIcdSearchClick = useCallback((e, status) => {
        e.stopPropagation();
        hideICDSearch(status, original);
      }, [original]);
      return (
        <div className={!isActive ? 'backColor' : 'noBackColor'}>
          {isActive ? (
            <Tooltip title="Hide term from search results">
              <i
                className="row-icdSearch-hide"
                onClick={(e) => onhideIcdSearchClick(e, false)}
                role="presentation"
              >
                X
              </i>
            </Tooltip>
          ) : (
            <Tooltip title="Reappear term in search results">
              <i
                className="tick"
                onClick={(e) => onhideIcdSearchClick(e, true)}
                role="presentation"
              />
            </Tooltip>
          )}
        </div>
      );
    },
  },
];

export default columns;
