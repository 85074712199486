import has from 'lodash/has';
import set from 'lodash/set';
import get from 'lodash/get';

const decode = (queryString) => {
  if (!queryString) {
    return null;
  }

  const queryStringPieces = queryString.split('&');

  return queryStringPieces.reduce((decoded, piece) => {
    // eslint-disable-next-line prefer-const
    let [key, value = ''] = piece.split('=').map(window.unescape);
    const isArray = key.endsWith('[]');
    if (isArray) {
      key = key.replace('[]', '');
    }
    if (has(decoded, key)) {
      const currentValueForKey = get(decoded, key);
      if (!Array.isArray(currentValueForKey)) {
        set(decoded, key, [currentValueForKey, value]);
      } else {
        currentValueForKey.push(value);
      }
    } else {
      set(decoded, key, isArray ? [value] : value);
    }
    return decoded;
  }, {});
};

export default decode;
