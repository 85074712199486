import React, { useEffect, useCallback } from 'react';
import { Collapse } from 'antd';

import lowerCase from 'lodash/lowerCase';
import { FileImageOutlined, FilePdfOutlined } from '@ant-design/icons';

import Upload from '../../../../../../../../components/Upload';
import Loader from '../../../../../../../../components/Loader';

import { apiUrls } from '../../../../../../../../api/constants';

import { contentType } from '../../../../../../../../lib/constants';

import useCRUD from '../../../../../../../../hooks/useCRUD';

import './patientDocument.scss';
import { fileSizeCheck } from '../../../../../../../../lib/util';

let fileInfo;

const PatientDocuments = (props) => {
  const {
    title, patientId, categoryId, onUploadSuccess, documents,
  } = props;
  const { Panel } = Collapse;

  const [uploadResponse,, uploadLoading,
    uploadFile,
    clearUploadResponse] = useCRUD({
    id: `${patientId}-new-document`,
    url: apiUrls.UPLOAD_PATIENT_DOCUMENT,
    type: 'create',
  });

  useEffect(() => {
    if (uploadResponse) {
      clearUploadResponse(true);
      onUploadSuccess?.(uploadResponse, fileInfo);
    }
  }, [clearUploadResponse, uploadResponse]);

  const onDocumentNameClicked = useCallback((documentURL) => {
    window.open(documentURL, '_blank');
  }, []);

  const getFileIcon = (item) => {
    const ext = lowerCase(item.fileExt);
    if (ext.indexOf('pdf') > -1) {
      return (
        <FilePdfOutlined />
      );
    }
    if (ext.indexOf('png') > -1 || ext.indexOf('jpeg') > -1 || ext.indexOf('gif') > -1 || ext.indexOf('jpg') > -1) {
      return (
        <FileImageOutlined />
      );
    }
    return null;
  };

  if (uploadLoading && !uploadResponse) {
    return <Loader />;
  }

  const onSelectFile = (fileData) => {
    if (!fileSizeCheck(fileData, 120)) {
      return false;
    }
    fileInfo = {
      contentType: contentType.MULTIPART,
      File: fileData,
      CategoryId: categoryId,
      PatientId: patientId,
      documentDisplayName: fileData.name.substr(0, fileData?.name?.lastIndexOf('.')),
      documentURL: URL.createObjectURL(fileData),
    };
    uploadFile({
      data: fileInfo,
    });
    return true;
  };

  const customHeader = (
    <div className="custom-upload-wrapper">
      <div className="feild-row">
        <span>{title}</span>
        <Upload
          className="cursor-pointer upload-design"
          accept="image/png, image/jpeg, .doc, .docx, .pdf"
          transformFile={onSelectFile}
          multiple={false}
          name={null}
          labelSpan="0"
          inputSpan="24"
          showUploadList={false}
          dataTestId="uploadDocuments"
        />
      </div>
    </div>
  );

  return (
    <div className="info-accordian collapse-wrapper">
      <Collapse accordion>
        <Panel className="main-acc-header" header={customHeader} key="1">
          {
            !documents?.length ? (
              <div className="no-documents-wrapper">
                <span>No Documents Found</span>
              </div>
            ) : (
              <div className="documents-wrapper" data-testid="documents-list">
                {documents.map((item, index) => (
                  <div
                    className="document"
                    onClick={() => onDocumentNameClicked(item.documentURL, item.new)}
                    role="presentation"
                    key={`document${index}`}
                  >
                    <div className="icon">
                      {getFileIcon(item)}
                    </div>
                    <div className="document-name">
                      {item.documentDisplayName}
                    </div>
                  </div>
                ))}

              </div>
            )
          }

        </Panel>
      </Collapse>
    </div>
  );
};

PatientDocuments.defaultProps = {
  data: [],
  url: apiUrls.GET_PATIENT_DOCUMENT,

};

export default PatientDocuments;
