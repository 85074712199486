import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import AutoComplete from '../../components/Form/GridAutoComplete';
import { apiUrls } from '../../api/constants';
import '../PayerAutoComplete/payerAutoComplete.scss';
import { formatPhoneNumber } from '../../lib/util';

const defaultColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    flex: 3,
  },
  {
    Header: 'Phone',
    accessor: ({ phone }) => formatPhoneNumber(phone),
    flex: 2,
  },
  {
    Header: 'Submission Method',
    accessor: 'submissionMethodName',
    flex: 3,
  },
  {
    Header: 'Address',
    accessor: 'address',
    flex: 5,
  },
];

const PayerGridAutoComplete = ({
  disabled,
  required,
  initialValue = {},
  labelSpan = '10',
  inputSpan = '13',
  onSelect,
  styleWidth,
  className,
  selectProps,
  dropdownMatchSelectWidth,
  valueAccessor,
  nameAccessor,
  params,
  name,
  columns,
  ...props
}) => {
  const payerOptionParser = useCallback((option) => ({
    name: option[nameAccessor] || option.name,
    value: option[valueAccessor] || option.insurancePayerId,
    ...option,
  }), [valueAccessor, nameAccessor]);

  return (
    <AutoComplete
      name={name}
      url={apiUrls.GET_PAYERS}
      optionParser={payerOptionParser}
      optionMaster="SearchText"
      labelSpan={labelSpan}
      inputSpan={inputSpan}
      disabled={disabled}
      required={required}
      initialValue={initialValue}
      columns={columns}
      style={styleWidth ? { width: '100%' } : {}}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      className={className}
      notFoundContent="Payer is not present in the system."
      showArrow
      labelInValue
      selectProps={{ ...selectProps, optionLabelProp: 'name', allowClear: true }}
      params={params}
      onSelect={onSelect}
      {...props}
    />
  );
};

PayerGridAutoComplete.defaultProps = {
  labels: {},
  form: {},
  disabled: false,
  required: false,
  initialValue: {},
  labelSpan: '10',
  inputSpan: '13',
  onSelect: () => { /* This is intentional */ },
  styleWidth: true,
  dropdownMatchSelectWidth: 400,
  columns: defaultColumns,
};

PayerGridAutoComplete.propTypes = {
  labels: PropTypes.objectOf(PropTypes.object),
  form: PropTypes.objectOf(PropTypes.object),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  initialValue: PropTypes.objectOf(PropTypes.any),
  labelSpan: PropTypes.string,
  inputSpan: PropTypes.string,
  onSelect: PropTypes.func,
  styleWidth: PropTypes.bool,
  dropdownMatchSelectWidth: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.object),
};

export default PayerGridAutoComplete;
