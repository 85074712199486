import pick from 'lodash/pick';

const parsedLineItemData = (lineItems = [], isEditTransaction = false) => {
  const filteredArray = [];
    lineItems?.forEach((item) => {
      if (!item?.isPosted && (isEditTransaction
        ? (item?.approved || (item?.denialReasonId && item?.approved === '0')) : item?.isEdited)) {
        const updatedPaymentObject = {
          ...item,
          coInsurance: item?.coIns,
          value: item?.balance,
          deductible: item?.deduct,
          adjust: item?.approved ? item?.adjust : parseFloat(0).toFixed(2),
          adjustment: item?.approved ? item?.adjust : parseFloat(0).toFixed(2),
          applied: item.paid || item.applied,
        };
        const nonEmptyStrings = Object.keys(
          updatedPaymentObject,
        ).filter((key) => (
          updatedPaymentObject[key] !== ''
           && updatedPaymentObject[key] !== undefined
           && updatedPaymentObject[key] !== null));
        filteredArray.push(pick(updatedPaymentObject, nonEmptyStrings));
      }
    });
    return filteredArray;
};

const parsedDecimalAmount = (amount) => (amount || 0).toFixed(2);

export {
  parsedLineItemData,
  parsedDecimalAmount,
};
