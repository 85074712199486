import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';

import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';

import dateFormatter from '../../lib/dateFormatter';

import AutoComplete from '../../components/Form/GridAutoComplete';
import { apiUrls } from '../../api/constants';
import useCRUD from '../../hooks/useCRUD';

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    flex: 2,
  },
  {
    Header: 'Phone',
    accessor: 'primaryPhone',
    flex: 2,
  },
  {
    Header: 'Date of Birth',
    accessor: 'personDateOfBirth',
    flex: 2,
  },
  {
    Header: 'Address',
    accessor: 'address',
    flex: 3,
  },
];

function PersonAutoComplete({
  labels,
  form,
  disabled,
  required,
  onSelect,
  initialValue,
  labelSpan,
  inputSpan,
  formFieldPrefix,
  dropdownMatchSelectWidth,
  className,
  styleWidth,
  selectProps,
  params,
  ...otherProps
}) {
  const wasLoading = useRef(false);

  const [data, , loading, getPersonData] = useCRUD({
    id: `${formFieldPrefix}person-lookup`,
    url: apiUrls.GET_RESPONSIBLE_PARTY_BY_ID,
    type: 'read',
  });

  const [selectedPartyType, setPartyType] = useState(null);

  const personOptionParser = useCallback((option) => ({
    value: `${option.firstName} ${option.lastName}`,
    name: `${option.firstName} ${option.lastName}`,
    personDateOfBirth: dateFormatter(option.dateOfBirth),
    address: `${option.addressLine1 || ''} ${option.addressLine2 || ''}`,
    ...option,
  }), []);

  const onPersonSelect = useCallback((personData) => {
    if (personData.personId) {
      getPersonData({ PersonId: personData.personId, Type: personData.type });
      if (isFunction(onSelect)) {
        onSelect({ partyType: personData.type, ...personData });
      }
      setPartyType(personData.type);
    }
  }, [getPersonData, onSelect]);

  useEffect(() => {
    if (!loading && wasLoading.current) {
      if (data && !Array.isArray(data)) {
        onSelect({ partyType: selectedPartyType, ...data });
      }
    }
    wasLoading.current = loading;
  }, [loading, onSelect]);

  return (
    <AutoComplete
      name={`${formFieldPrefix}personName`}
      label={labels.get('labels.person')}
      url={apiUrls.GET_RESPONSIBLE_PARTY}
      optionParser={personOptionParser}
      optionMaster="SearchText"
      labelSpan={labelSpan}
      inputSpan={inputSpan}
      disabled={disabled}
      required={required}
      initialValue={initialValue}
      columns={columns}
      style={styleWidth ? { width: '100%' } : {}}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      className={className}
      notFoundContent="Person is not present in the system."
      showArrow
      labelInValue
      selectProps={{ ...selectProps, optionLabelProp: 'name', allowClear: true }}
      params={params}
      onSelect={onPersonSelect}
      {...otherProps}
    />

  );
}

PersonAutoComplete.defaultProps = {
  labels: {},
  form: {},
  disabled: false,
  required: false,
  initialValue: {},
  labelSpan: '10',
  inputSpan: '13',
  onSelect: () => { /* This is intentional */ },
  formFieldPrefix: '',
  minCharLength: 3,
  dropdownMatchSelectWidth: 405,
  styleWidth: true,
};

PersonAutoComplete.propTypes = {
  labels: PropTypes.objectOf(PropTypes.object),
  form: PropTypes.objectOf(PropTypes.object),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  initialValue: PropTypes.objectOf(PropTypes.any),
  labelSpan: PropTypes.string,
  inputSpan: PropTypes.string,
  onSelect: PropTypes.func,
  formFieldPrefix: PropTypes.string,
  minCharLength: PropTypes.number,
  dropdownMatchSelectWidth: PropTypes.number,
  styleWidth: PropTypes.bool,
};

export default PersonAutoComplete;
