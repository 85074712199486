const iconNames = {
  deleteIcon: 'DeleteOutlined',
  checkBoxIcon: 'BorderOutlined',
  listIcon: 'UnorderedListOutlined',
  schedularIcon: 'ScheduleOutlined',
  arrowLeft: 'ArrowLeftOutlined',
  arrowRight: 'ArrowRightOutlined',
  mergeCells: 'MergeCellsOutlined',
  inbox: 'InboxOutlined',
  zoomIn: 'ZoomInOutlined',
  zoomOut: 'ZoomOutOutlined',
  close: 'CloseOutlined',
  table: 'TableOutlined',
  search: 'SearchOutlined',
  rightCircle: 'RightCircleOutlined',
  leftCircle: 'LeftCircleOutlined',
  plusIcon: 'PlusOutlined',
  print: 'PrinterOutlined',
  minus: 'MinusOutlined',
  undo: 'UndoOutlined',
  edit: 'EditOutlined',
  fitContent: 'ArrowsAltOutlined',
  mailFill: 'MailFilled',
  printFill: 'PrinterFilled',
  rotateLeft: 'RotateLeftOutlined',
  rotateRight: 'RotateRightOutlined',
  dollar: 'DollarCircleFilled',
  checkOutlined: 'CheckOutlined',
  barcode: 'BarcodeOutlined',
  download: 'DownloadOutlined',
  settings: 'SettingOutlined',
  warningOutlined: 'WarningOutlined',
  warningFilled: 'WarningFilled',
  share: 'ShareAltOutlined',
  schedule: 'ScheduleOutlined',
  borderOuter: 'BorderOuterOutlined',
  email: 'MailOutlined',
  profileOutlined: 'ProfileOutlined',
  thunderboltOutlined: 'ThunderboltOutlined',
  fileTextOutlined: 'FileTextOutlined',
  pushpinOutlined: 'PushpinOutlined',
  checkCircleOutlined: 'CheckCircleOutlined',
  closeCircleOutlined: 'CloseCircleOutlined',
  deliveredProcedureOutlined: 'DeliveredProcedureOutlined',
  copyOutlined: 'CopyOutlined',
  minusCircleOutlined: 'MinusCircleOutlined',
  upload: 'UploadOutlined',
  exclamationCircleOutlined: 'ExclamationCircleOutlined',
  paperClipOutlined: 'PaperClipOutlined',
  shopTwoTone: 'ShopTwoTone',
  deleteFilled: 'DeleteFilled',
  radiusSettingOutlined: 'RadiusSettingOutlined',
  questionCircleOutlined: 'QuestionCircleOutlined',
  // Akshat Garg - 10/7/24 - adding entry in icons
  rollBack: "RollbackOutlined"
};

export default iconNames;
