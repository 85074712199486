
const columns = (labels) => [
  {
    Header: labels.get('tableColumns.businessEntityName'),
    accessor: 'businessEntityName',
    fixWidth: '100',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.provider'),
    accessor: 'providerName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.location'),
    accessor: 'locationName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.checkNumber'),
    accessor: 'checkNumber',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.agedDays'),
    accessor: 'agedDays',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.checkAmount'),
    accessor: 'amount',
    Cell: ({ row: { original: { amount } } }) => (
      parseFloat(amount || 0).toFixed(2)
    ),
    className: 'text-align-right',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.balanceAmount'),
    accessor: 'balance',
    Cell: ({ row: { original: { balance } } }) => (
      (balance || 0).toFixed(2)
    ),
    className: 'text-align-right',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.status'),
    accessor: 'status',
    fixWidth: 80,
    sort: true,
  },
  {
    Header: labels.get('tableColumns.source'),
    accessor: 'source',
    fixWidth: 80,
    sort: true,
  },
  {
    Header: labels.get('tableColumns.batchNumber'),
    accessor: 'batchNumber',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.assignTo'),
    accessor: 'assignedToName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.insurancePayer'),
    accessor: 'payerName',
    sort: true,
  },
];

export default columns;
