import React, { useCallback } from 'react';

import Button from '../../../../components/Button';

import useRedirect from '../../../../hooks/useRedirect';
import WithLabel from '../../../../hoc/withLabel';

import { UiRoutes, labelPaths } from '../../../../lib/constants';

import ExamRoomList from './List';

function ExamRoom({
  labels, isLocationViewOnly,
}) {
  const { params, push, generatePath } = useRedirect();
  const { id } = params;

  const navigateToAddNewExamRoom = useCallback(() => {
    push(generatePath(UiRoutes.newExamRoomWithTabId, { ...params }));
  }, [push, generatePath, params]);

  const navigateToEditExamRoom = useCallback((examId) => {
    push(generatePath(UiRoutes.editExamRoomWithTabId, { ...params, examId }));
  }, [push, generatePath, params]);

  return (
    <>
      <div className="mr-bt-8 mr-top-12">
        {!isLocationViewOnly && (
          <Button className="btn btn-success sm-btn" type="primary" onClick={navigateToAddNewExamRoom} id="locations_exam_room_new">{labels.get('buttons.newExamRoom')}</Button>
        )}
      </div>
      <div className="examroom-table">
        <ExamRoomList
          navigateToEditExamRoom={navigateToEditExamRoom}
          labels={labels}
          id={id}
        />
      </div>
    </>
  );
}

export default WithLabel(ExamRoom, labelPaths.EDIT_LOCATION_EXAMROOM_LIST);
