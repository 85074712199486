import {
  put, takeEvery, call, delay,
} from 'redux-saga/effects';
import { superBillActions, SUPERBILL_ACTIONS } from '../actions/superBillActions';
import { apiUrls } from '../../api/constants';
import api from '../../api/index';
import Notification from '../../components/Notification';

function* fetchSuperBillStatus(actionOrTaskId) {
  const taskId = typeof actionOrTaskId === 'object' ? actionOrTaskId.payload.taskId : actionOrTaskId;

  // If no taskId is provided, end the saga
  if (!taskId) return;

  let delayDuration = 30000; // Default delay
  yield put(superBillActions.setSuperBillPolling(taskId, true)); // Start polling
  while (true) {
    const response = yield call(api.get, {
      version: 2,
      token: localStorage.getDecryptedData('token'),
      url: `${process.env.REACT_APP_API_PATH}/${apiUrls.CHECK_SUPERBILL_STATUS}/${taskId}`,
      params: { responseType: 'json' },
    });

    try {
      if (response.status === 200) {
        yield put(superBillActions.setSuperBillStatus('Completed', taskId));
        yield put(superBillActions.setSuperBillDownloadUrl(response.data.signedUrl, taskId));
        return;
      }
      if (response.status === 202) {
        yield put(superBillActions.setSuperBillStatus('In Progress', taskId));
      } else if (response.status >= 500) {
        yield put(superBillActions.setSuperBillStatus('Error', taskId));
        return;
      } else {
        throw new Error('Something went wrong');
      }
    } catch (error) {
      if (response.status && response.status === 401) {
        delayDuration = 5000; // Shorter delay for 401 error
      } else {
        yield put(superBillActions.setSuperBillStatus('Error', taskId));
        return;
      }
    }
    yield delay(delayDuration);
    delayDuration = 30000; // reset the delay back to the default
  }
}

function* fetchSuperBillTask(action) {
  const actionPayload = {
    ...action.payload,
    responseType: 'json',
  };

  const { providerNames } = actionPayload;
  const { patientName } = actionPayload;
  delete actionPayload.providerNames;
  delete actionPayload.patientName;

  try {
    yield put(superBillActions.disablePrintButton());
    const response = yield call(api.get, {
      version: 2,
      token: localStorage.getDecryptedData('token'),
      url: apiUrls.GET_SUPERBILL_DATA,
      params: actionPayload,
    });
    yield put(superBillActions.enablePrintButton());

    if (response.status === 200) {
      Notification({
        message:
          'Superbills are printing in the background. You can continue with your tasks and monitor the progress in the download box located in the left menu.',
        success: true,
      });

      // Add the provider names to the query before saving to Redux
      const queryWithProviderNames = {
        ...actionPayload,
        providerNames,
        patientName,
      };
      yield put(
        superBillActions.addSuperBillTask({
          taskId: response.data.taskId,
          status: response.data.message,
          query: queryWithProviderNames,
        }),
      );
    } else if (response.status === 204) {
      Notification({
        message:
          'No encounters are available for this timeframe or provider, Cannot generate Superbill.',
        success: false,
      });
    } else {
      Notification({ message: 'Something went wrong', success: false });
    }
  } catch (error) {
    yield put(superBillActions.setSuperBillStatus(error.message, actionPayload));
  }
}

function* superBillSaga() {
  yield takeEvery(SUPERBILL_ACTIONS.GET_SUPERBILL_TASK, fetchSuperBillTask);
  yield takeEvery(SUPERBILL_ACTIONS.POLL_SUPERBILL_STATUS, fetchSuperBillStatus);
}

export default superBillSaga;
