import { useReactToPrint } from 'react-to-print';

import { hideComposerToolBar, unHideComposerToolBar } from '../lib/composerToolBarUtil';

const useHtmlPrint = (viewPanel, signatureRef, bodyClass = '') => useReactToPrint({
  content: () => viewPanel.current,
  copyStyles: true,
  pageStyle: () => { /* This is intensional */ },
  onBeforeGetContent: () => {
    if (signatureRef?.current?.isEmpty()) {
      const canvas = signatureRef.current.getCanvas();
      canvas?.setAttribute('style', 'display:none');
    }
    hideComposerToolBar(viewPanel);
  },
  onAfterPrint: () => {
    const canvas = signatureRef?.current?.getCanvas();
    canvas?.setAttribute('style', 'display:block');
    unHideComposerToolBar(viewPanel);
  },
  bodyClass,
});

export default useHtmlPrint;
