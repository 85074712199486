import React, { useCallback, useEffect } from 'react';

import useRedirect from '../../../../../hooks/useRedirect';

import { UiRoutes } from '../../../../../lib/constants';

import RadioGroup from '../../../../../components/Form/RadioGroup';

import Errors from './Errors';
import Payments from './Payments';

const Components = {
  payments: Payments,
  errors: Errors,
};

const tabList = [
  { name: 'payments', value: 'payments', label: 'Payments' },
  { name: 'errors', value: 'errors', label: 'Errors' },
];

const Tabs = ({
  labels, defaultTab, setCurrentPath, remittanceBatchData, ...otherProps
}) => {
  const {
    replace, params: { remittanceId, remittancePaymentId }, path, params, generatePath,
  } = useRedirect();

  const tabSwitchHandler = useCallback((event) => {
    const { value } = event.target;
    replace(generatePath(`${UiRoutes.claimsRemittanceErrorEditInsurance}/error`, {
      ...params,
      errorTab: value,
    }));
  }, [generatePath, params, replace]);

  useEffect(() => {
    if (defaultTab && setCurrentPath) {
      setCurrentPath(generatePath(path, params));
    }
  }, []);

  const ActiveComponent = Components[defaultTab];

  return (
    <div>
      <div className="flex justify-content-flex-end align-center mr-bt-16">
        <RadioGroup
          tabList={tabList}
          onChange={tabSwitchHandler}
          value={defaultTab}
          labels={labels}
          id="remittance_error_tabs"
        />
      </div>
      {defaultTab && (
        <ActiveComponent
          labels={labels}
          remittanceId={remittanceId}
          remittancePaymentId={remittancePaymentId}
          remittanceBatchData={remittanceBatchData}
          currentTab={defaultTab}
          {...otherProps}
        />
      )}
    </div>
  );
};

export default Tabs;
