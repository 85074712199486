import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import AutoComplete from '../../components/Form/GridAutoComplete';
import { apiUrls } from '../../api/constants';

const columns = [
  {
    Header: 'Code',
    accessor: 'cptCode',
    flex: 1,
  },
  {
    Header: 'Description',
    accessor: 'description',
    flex: 3,
  },
  {
    Header: 'Details',
    accessor: 'fullDescription',
    flex: 3,
  },
];

const CPTAutoComplete = ({
  disabled,
  required,
  initialValue,
  labelSpan,
  inputSpan,
  onSelect,
  styleWidth,
  name,
  className,
  dropdownMatchSelectWidth,
  selectProps,
  params,
  showTotalCount,
  ...props
}) => {
  const cptOptionParser = useCallback((option) => ({
    ...option,
    name: option.cptCode,
    value: option.id,
  }), []);

  return (
    <div className="filter-modifier-autocomplete">
      <AutoComplete
        name={name}
        url={apiUrls.GET_CPTS}
        optionParser={cptOptionParser}
        optionMaster="CPTCodeText"
        labelSpan={labelSpan}
        inputSpan={inputSpan}
        disabled={disabled}
        required={required}
        initialValue={initialValue}
        columns={columns}
        style={styleWidth ? { width: '100%' } : {}}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        className={className}
        notFoundContent="Procedure is not present is the system."
        showArrow
        labelInValue
        selectProps={{ ...selectProps, optionLabelProp: 'name', allowClear: true }}
        params={params}
        onSelect={onSelect}
        showTotalCount={showTotalCount}
        tooltipKey="description"
        customInnerTextKey="name"
        {...props}
      />
    </div>
  );
};

CPTAutoComplete.defaultProps = {
  disabled: false,
  required: false,
  initialValue: {},
  labelSpan: '0',
  inputSpan: '24',
  onSelect: () => { /* This is intentional */ },
  styleWidth: true,
  name: 'procedure',
  className: '',
  dropdownMatchSelectWidth: 500,
  params: {},
  placeholder: 'Code',
  label: 'Procedure',
  showTotalCount: false,
};

CPTAutoComplete.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  initialValue: PropTypes.objectOf(PropTypes.any),
  labelSpan: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  inputSpan: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  onSelect: PropTypes.func,
  styleWidth: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
  dropdownMatchSelectWidth: PropTypes.oneOf([PropTypes.number, PropTypes.bool]),
  params: PropTypes.objectOf(PropTypes.string),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  showTotalCount: PropTypes.bool,
};

export default CPTAutoComplete;
