import React, { useCallback, useEffect, useState } from 'react';
import { Form as AntdForm } from 'antd';

import Form from '../../../../components/Form';
import WithLabel from '../../../../hoc/withLabel';
import { labelPaths } from '../../../../lib/constants';
import useCRUD from '../../../../hooks/useCRUD';
import useRedirect from '../../../../hooks/useRedirect';
import { apiUrls } from '../../../../api/constants';
import WidgetLoader from '../../../../components/WidgetLoader';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';

import PatientAdjustment from '../../../Adustment/Components/PatientAdjustment';

import PatientPayment from '../../../../screens/Dashboard/Tabs/Financial/Popups/Patient/PatientPayment';

const noOfColumns = 2;

const Balance = ({ isDisabled }) => {
  const [form] = AntdForm.useForm();
  const { params: { id } } = useRedirect();

  const [isPatientAdjustmentVisible, setPatientAdjustmentVisibility] = useState(false);
  const [isPatientPaymentVisible, setPatientPaymentVisibility] = useState(false);

  const [
    appliedBalance,,
    loading,
    getAppliedBalance,
  ] = useCRUD({
    id: `patient-balance-${id}`,
    url: apiUrls.GET_PATIENT_APPLIED_BALANCE,
    type: 'read',
  });

  useEffect(() => {
    getAppliedBalance({
      PatientId: id,
    });
  }, [id]);

  const togglePatientPayment = useCallback(() => setPatientPaymentVisibility(
    !isPatientPaymentVisible,
  ), [isPatientPaymentVisible]);

  const togglePatientAdjustment = useCallback(() => setPatientAdjustmentVisibility(
    !isPatientAdjustmentVisible,
  ), [isPatientAdjustmentVisible]);
  return (
    <div className="form-container">
      <Modal
        visible={isPatientAdjustmentVisible}
        footer={null}
        width="600"
        toggleModal={togglePatientAdjustment}
      >
        <div className="addition-header">
          <div className="lookup sprite-img-before">
            <p>Patient Adjustment</p>
          </div>
        </div>
        <PatientAdjustment closeTab={togglePatientAdjustment} />
      </Modal>
      <PatientPayment
        isBillingFinancial
        visible={isPatientPaymentVisible}
        toggleModal={togglePatientPayment}
      />
      {loading && <WidgetLoader />}
      <div className="pannel">
        <div className="pannel-body">
          <Form
            noOfColumns={noOfColumns}
            form={form}
          >
            <Form.Column>
              <div>
                <div className="info-row">
                  <label htmlFor="Charges">Charges</label>
                  <div className="lavel-value">{parseFloat(appliedBalance?.patientCharge || 0, 10).toFixed(2)}</div>
                </div>
                <div className="info-row">
                  <label htmlFor="Applied Payments">Applied Payments</label>
                  <div className="lavel-value">{parseFloat(appliedBalance?.patientPayment || 0, 10).toFixed(2)}</div>
                </div>
                <div className="info-row">
                  <label htmlFor="Applied Adjustments">Applied Adjustments</label>
                  <div className="lavel-value">{parseFloat(appliedBalance?.patientAdjustment || 0, 10).toFixed(2)}</div>
                </div>
                <div className="info-row">
                  <label htmlFor="Net">Net</label>
                  <div className="lavel-value">
                    {(parseFloat(appliedBalance?.patientCharge || 0)
                    + parseFloat(appliedBalance?.patientPayment || 0)
                    + parseFloat(appliedBalance?.patientAdjustment || 0)).toFixed(2)}
                  </div>
                </div>
              </div>
            </Form.Column>
          </Form>
        </div>
      </div>
      <div className="balance-btns mr-top-12">
        <Button
          className="btn btn-success mr-rt-12 sm-btn"
          onClick={togglePatientPayment}
          id="patients_encounter_tab_balance_payment"
          disabled={isDisabled}
        >
          Add Payment
        </Button>
        <Button
          className="btn btn-success sm-btn"
          id="patients_encounter_tab_balance_adjustment"
          onClick={togglePatientAdjustment}
          disabled={isDisabled}
        >
          Add Adjustment
        </Button>
      </div>
    </div>
  );
};

export default WithLabel(Balance, labelPaths.ENCOUNTERS_BALANCE);
