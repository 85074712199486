import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function Label({
  required, value, label, title, id, isUnbillable = false,
}) {
  return (
    <div className="ant-row ant-form-item">
      <div className={classNames('ant-col ant-form-item-label', {
        'marked-unbillable': isUnbillable,
      })}
      >
        <label className={required && 'ant-form-item-required'} title={title || label} htmlFor={id}>{label}</label>
      </div>
      <div className="ant-col ant-form-item-control">
        <div id={id} className="ant-form-item-control-input">
          {value}
        </div>
      </div>
    </div>
  );
}

Label.defaultProps = {
  required: false,
  value: '',
  label: '',
  title: '',
  id: '',
};

Label.propTypes = {
  required: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};
