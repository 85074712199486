import React from 'react';

import Label from '../../../../../../components/Label';

const financialDetailGroup1 = ({ financialDetail = {} }) => (
  <>
    <div className="input-wrap right-align-financial-charge">
      <div className="label-wrap">
        <Label label="Check Number" />
        :
      </div>
      <div className="name-view">{financialDetail?.financeDetails?.checkNumber ?? ''}</div>
    </div>
    <div className="input-wrap right-align-financial-charge">
      <div className="label-wrap">
        <Label label="Batch Identifier" />
        :
      </div>
      <div className="name-view">{financialDetail?.financeDetails?.batchIdentifier ?? ''}</div>
    </div>
    <div className="input-wrap right-align-financial-charge border-right-only">
      <div className="label-wrap">
        <Label label="Payment Source" />
        :
      </div>
      <div className="name-view">{financialDetail?.paymentSource ?? ''}</div>
    </div>
  </>
);

export default financialDetailGroup1;
