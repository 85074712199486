import React, { useCallback, useEffect } from 'react';

import classNames from 'classnames';

import { Input } from '../../../../../../../../components/Form/inputFields';
import Smcheckbox from '../../../../../../../../components/SmCheckbox';
import Icon from '../../../../../../../../components/Icon';
import IconNames from '../../../../../../../../lib/iconNames';
import CPTAutocomplete from '../../../../../../../../wiredComponents/CPTAutocomplete';
import SelectBox from '../../../../../../../../wiredComponents/Enum/Select';
import { enums } from '../../../../../../../../lib/constants';

const EditableCPTCell = ({
  name,
  label,
  required,
  editable,
  initialValue,
  onSelect,
}) => (
  <CPTAutocomplete
    disabled={!editable}
    className={classNames({ 'non-editable': !editable })}
    name={name}
    labelSpan="0"
    inputSpan="24"
    label={label}
    required={required}
    initialValue={initialValue}
    onSelect={onSelect}
    onClick={() => { /* This is intentional */ }}
    allowClearOnBlur={false}
  />
);

const EditableInputCell = ({
  name,
  label,
  required,
  editable,
  numberOnly,
}) => (
  <Input
    disabled={!editable}
    className={classNames({ 'non-editable': !editable })}
    name={name}
    labelSpan="0"
    inputSpan="24"
    label={label}
    required={required}
    numberOnly={numberOnly}
  />
);

const EditableCheckBoxCell = ({
  name,
  onChange,
  ...props
}) => (
  <Smcheckbox labelSpan="0" inputSpan="14" valuePropName="checked" onChange={onChange} name={name} {...props} />
);

const EditableSelectCell = ({
  name,
  value,
  editable,
  enumName,
  parentId,
}) => (
  <SelectBox
    disabled={!editable}
    className={classNames({ 'non-editable': !editable })}
    value={value}
    labelSpan="0"
    name={name}
    inputSpan="24"
    enumName={enumName}
    selectProps={{
      showArrow: true,
    }}
    placeholder=""
    parentId={parentId}
    onClick={() => { /* This is intentional */ }}
  />
);

const isEditable = (editedRows, exceptionId) => (!!editedRows[exceptionId]);

const columns = (labels, form) => [
  {
    Header: labels.get('tableColumns.range'),
    accessor: 'isRange',
    fixWidth: '50',
    Cell: ({
      row: {
        index, original: { feePreferencesExceptionId },
      }, onRangeSelect,
    }) => (
      <>
        <div style={{ display: 'none' }}>
          <EditableInputCell
            name={['exceptions', index, 'feePreferencesExceptionId']}
          />
          <EditableInputCell
            name={['exceptions', index, 'fromProcedureCode']}
          />
          <EditableInputCell
            name={['exceptions', index, 'toProcedureCode']}
          />
        </div>
        <EditableCheckBoxCell
          name={['exceptions', index, 'isRange']}
          onChange={(event) => onRangeSelect(event, [index, 'isRange'], feePreferencesExceptionId)}
        />
      </>
    ),
  },
  {
    Header: labels.get('tableColumns.procedure'),
    accessor: 'procedureCode',
    fixWidth: '350',
    Cell: ({
      row: {
        index, original: {
          feePreferencesExceptionId,
          isRange,
          fromProcedureCode,
          fromProcedureId,
          fromProcedureDescription,
          toProcedureCode,
          toProcedureDescription,
          toProcedureId,
        },
      }, editedRows,
    }) => {
      useEffect(() => {
        if (fromProcedureCode) {
          form.setFields([{
            name: ['exceptions', index, 'fromProcedureCode'],
            value: fromProcedureCode,
          }]);
        }
      }, [fromProcedureCode, index]);

      useEffect(() => {
        if (toProcedureCode) {
          form.setFields([{
            name: ['exceptions', index, 'toProcedureCode'],
            value: toProcedureCode,
          }]);
        }
      }, [toProcedureCode, index]);

      const onProcedureFromChange = useCallback((procedure) => {
        form.setFields([{
          name: ['exceptions', index, 'fromProcedureDescription'],
          value: procedure?.description,
        },
        {
          name: ['exceptions', index, 'fromProcedureCode'],
          value: procedure?.cptCode,
        },
        ]);
      }, [index]);

      const onProcedureToChange = useCallback((procedure) => {
        form.setFields([{
          name: ['exceptions', index, 'toProcedureDescription'],
          value: procedure?.description,
        },
        {
          name: ['exceptions', index, 'toProcedureCode'],
          value: procedure?.cptCode,
        },
        ]);
      }, [index]);

      return (
        <div className="field-row align-items-baseline">
          <div className="col">
            <EditableCPTCell
              name={['exceptions', index, 'fromProcedureId']}
              editable={isEditable(editedRows, feePreferencesExceptionId)}
              label={labels.get('labels.procedure')}
              required
              form={form}
              onSelect={onProcedureFromChange}
              initialValue={fromProcedureId && {
                name: fromProcedureCode,
                value: fromProcedureId,
                cptCode: fromProcedureCode,
                description: fromProcedureDescription,
              }}
            />
          </div>
          {isRange
                && (
                <>
                  <div className="to">To</div>
                  <div className="col">
                    <EditableCPTCell
                      form={form}
                      editable={isEditable(editedRows, feePreferencesExceptionId)}
                      name={['exceptions', index, 'toProcedureId']}
                      required
                      labels={labels}
                      onSelect={onProcedureToChange}
                      initialValue={toProcedureId && {
                        name: toProcedureCode,
                        value: toProcedureId,
                        cptCode: toProcedureCode,
                        description: toProcedureDescription,
                      }}
                    />
                  </div>
                </>
                )}
        </div>
      );
    },
  },
  {
    Header: labels.get('tableColumns.procedureDescription'),
    accessor: 'fromProcedureDescription',
    fixWidth: '400',
    Cell: ({
      row: { index, original: { fromProcedureDescription, toProcedureDescription, isRange } },
    }) => (

      <div className="">
        <div className={isRange ? 'exception-description' : 'exception-description-full'}>
          <EditableInputCell
            name={['exceptions', index, 'fromProcedureDescription']}
            editable={false}
            value={fromProcedureDescription}
          />
          <EditableInputCell
            name={['exceptions', index, 'toProcedureDescription']}
            editable={false}
            value={toProcedureDescription}
          />
        </div>
      </div>
    ),
  },
  {
    Header: labels.get('tableColumns.modifier'),
    accessor: 'modifierId',
    fixWidth: '140',
    Cell: ({
      row: { index, original: { feePreferencesExceptionId } }, editedRows,
    }) => (
      <EditableSelectCell
        editable={isEditable(editedRows, feePreferencesExceptionId)}
        name={['exceptions', index, 'modifierId']}
        label={labels.get('labels.modifier')}
        enumName={enums.MODIFIER}
        parentId={0}
      />
    ),
  },
  {
    Header: labels.get('tableColumns.facilityType'),
    accessor: 'facilityTypeId',
    fixWidth: '140',
    Cell: ({
      row: { index, original: { feePreferencesExceptionId } }, editedRows,
    }) => (
      <EditableSelectCell
        editable={isEditable(editedRows, feePreferencesExceptionId)}
        name={['exceptions', index, 'facilityTypeId']}
        label={labels.get('labels.facilityType')}
        enumName={enums.FACILITY_TYPE}
      />
    ),
  },
  {
    Header: labels.get('tableColumns.value'),
    accessor: 'fieldValue',
    fixWidth: '120',
    Cell: ({
      row: {
        index, original: {
          feePreferencesExceptionId,
        },
      }, editedRows,
    }) => (
      <EditableInputCell
        editable={isEditable(editedRows, feePreferencesExceptionId)}
        name={['exceptions', index, 'fieldValue']}
        label={labels.get('labels.value')}
        required
        numberOnly
      />
    ),
  },
  {
    Header: labels.get('tableColumns.valueType'),
    accessor: 'valueTypeId',
    fixWidth: '120',
    Cell: ({
      row: {
        index, original: {
          feePreferencesExceptionId,
        },
      }, editedRows,
    }) => (
      <EditableSelectCell
        editable={isEditable(editedRows, feePreferencesExceptionId)}
        name={['exceptions', index, 'valueTypeId']}
        label={labels.get('labels.valueType')}
        enumName={enums.VALUE_TYPE}
      />
    ),
  },
  {
    Header: labels.get('tableColumns.active'),
    accessor: 'isActive',
    fixWidth: '50',
    Cell: ({
      row: { index }, onRangeSelect,
    }) => (
      <EditableCheckBoxCell
        name={['exceptions', index, 'isActive']}
        onChange={(event) => onRangeSelect(event, [index, 'isActive'])}
      />
    ),
  },
  {
    Header: labels.get('tableColumns.delete'),
    accessor: 'delete',
    fixWidth: '50',
    Cell: ({ row: { index }, onRemoveException }) => {
      const onClick = useCallback(() => { onRemoveException(index); }, [onRemoveException, index]);
      return (
        <Icon
          name={IconNames.deleteIcon}
          onClick={onClick}
        />
      );
    },
  },
];

export default columns;
