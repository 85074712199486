import React, { useCallback, useEffect, useState } from 'react';
import { Dropdown, Menu } from 'antd';
import toString from 'lodash/toString';
import classNames from 'classnames';
import moment from 'moment';

import useCRUD from '../../../../../../../../hooks/useCRUD';
import useCRUDWithoutTab from '../../../../../../../../hooks/useCRUDWithoutTab';
import useRedirect from '../../../../../../../../hooks/useRedirect';

import Events from '../../../../../../../../lib/events';
import { getString } from '../../../../../../../../lib/util';
import { listIds } from '../../../../../../../../lib/constants';
import { apiUrls } from '../../../../../../../../api/constants';
import Modals from '../../../../../../../../components/Modal';
import Button from '../../../../../../../../components/Button';

const CopyPreviousANPModal = () => {
  const [isCopyPreviousModalVisible, setCopyPreviousVisible] = useState(false);
  const [previousEncounterId, setPreviousEncounterId] = useState();
  const [isClicked, setIsClicked] = useState();
  const { params: { encounterId, patientId, providerId } } = useRedirect();

  const [encounterList] = useCRUDWithoutTab({
    id: `${listIds.ENCOUNTER_LIST}-${patientId}`,
    url: apiUrls.GET_PATIENT_ENCOUNTER_LIST,
    type: 'read',
  });

  const [
    copyOcularProblemResponse, , ,
    copyOcularProblem,
    clearCopyOcularProblem,
  ] = useCRUD({
    id: apiUrls.COPY_PREVIOUS_PATIENT_ENCOUNTER,
    url: apiUrls.COPY_PREVIOUS_PATIENT_ENCOUNTER,
    type: 'create',
  });

  useEffect(() => {
    if (copyOcularProblemResponse && !Array.isArray(copyOcularProblemResponse)) {
      Events.trigger('reFetchOcularProblem');
      Events.trigger('reFetchAssessmentValue');
      Events.trigger(`refetchInOfficeProcedureList-${encounterId}`);
      Events.trigger(`refetch-InOffice-ProcedureList-${encounterId}`);
      Events.trigger('refetch-encounter-notes-overview');
      clearCopyOcularProblem(true);
      setCopyPreviousVisible(false);
    }
  }, [encounterId, copyOcularProblemResponse, clearCopyOcularProblem]);

  const handleOnClick = useCallback(({ key }) => {
    setCopyPreviousVisible(true);
    setPreviousEncounterId(key);
  }, []);

  const menu = useCallback(() => (
    <Menu onClick={handleOnClick}>
      {encounterList?.length && encounterList.map((encounterData) => {
        if (toString(encounterData.encounterId) !== toString(encounterId)) {
          return (
            <Menu.Item key={encounterData.encounterId}>
              {`${moment(encounterData.serviceDateString || encounterData.serviceDate).format('MM-DD-YYYY')} - ${encounterData.visitType} , ${getString([encounterData.providerFirstName, encounterData.providerMiddleName, encounterData.providerLastName])}`}
            </Menu.Item>
          );
        }
        return null;
      })}
    </Menu>
  ), [encounterId, encounterList, handleOnClick]);

  const onAddClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const toggleCopyPreviousModal = useCallback(() => setCopyPreviousVisible((value) => !value), []);

  const handleCopyPreviousOptions = useCallback(({ target: { name } }) => {
    setIsClicked(name);
    copyOcularProblem({
      data: {
        patientid: patientId,
        previousEncounterId,
        currentEncounterId: encounterId,
        providerId,
        CopyOption: name,
      },
    });
  }, [copyOcularProblem, encounterId, patientId, previousEncounterId, providerId]);

  return (
    <>
      <Dropdown
        overlay={menu}
        onClick={onAddClick}
        trigger={['click']}
      >
        <button type="button" className="drop-down-type-small-btn">  Previous A&P</button>
      </Dropdown>
      <Modals
        width={400}
        destroyOnClose
        visible={isCopyPreviousModalVisible}
        toggleModal={toggleCopyPreviousModal}
        title="Copy Previous Encounter Details"
        footer={null}
      >
        <div className="flex justify-content-sp-bt">
          <Button
            title="Add selected Dx and Assessment, Notes and Plan text to current encounters details"
            name="addToCurrent"
            className={classNames(isClicked === 'addToCurrent' ? 'btn btn-success' : '')}
            onClick={handleCopyPreviousOptions}
          >
            Add to Current
          </Button>
          <Button
            title="Replace existing Dx and Assessment, Notes and Plan text with selected encounters details"
            name="replaceCurrent"
            className={classNames(isClicked === 'replaceCurrent' ? 'btn btn-success' : '')}
            onClick={handleCopyPreviousOptions}
          >
            Replace Current
          </Button>
        </div>
      </Modals>
    </>
  );
};

export default CopyPreviousANPModal;
