import React, { useCallback, useEffect } from 'react';
import SelectBox from '../../../../../../components/Form/SelectBox';

const DropDown = ({
  data,
  onFieldChange,
  jCodeDisable,
  form,
  isDefaultJcode,
  isEditProcedure,
  isDisabled,
  ...props
}) => {
  const { Key, Lable, Options } = data?.[1];
  const getJCodeOptions = (options) => options.map(
    (option) => ({
      ...option,
      name: `${option['J-code']} - ${option['Medication name']}`,
      label: option['Medication name'],
      value: option['J-code'],
    }),
  );
  const options = Lable === 'J-code' ? getJCodeOptions(Options) : Options;

  useEffect(() => {
    if (data?.[1]?.Default && isDefaultJcode && Lable === 'J-code' && options?.length === 1 && !isEditProcedure) {
      form.setFields([
        { name: ['results', 0, Key], value: options?.[0]?.['J-code'] },
      ]);
      onFieldChange('dropdown', 'j-code', data?.[0], options[0]);
    }
  }, []);

  const onChange = useCallback(
    (value, item) => {
      if (Lable === 'J-code') {
        onFieldChange('dropdown', Lable, data?.[0], item);
      }
    },
    [Lable, data, onFieldChange],
  );

  return (
    <div className="info-row">
      <SelectBox
        label={Lable}
        name={[data?.[0], Key]}
        options={options}
        onChange={onChange}
        labelSpan="7"
        inputSpan="17"
        disabled={Lable === 'J-code' && isDisabled}
        {...props}
      />
      {/* <div className="info info-label">{labels.get('labels.mj')}</div> */}
    </div>
  );
};

export default DropDown;
