import React from 'react';

import WithLabel from '../../../../hoc/withLabel';
import { labelPaths } from '../../../../lib/constants';
import BasicInfo from './Components/BasicInfo';
import InsuranceInfo from './Components/InsuranceInfo';

const Demographic = ({ labels }) => (
  <div className="form-container">
    <BasicInfo labels={labels} />
    <InsuranceInfo labels={labels} />
  </div>
);
export default WithLabel(Demographic, labelPaths.ENCOUNTERS_DIAGNOSIS);

