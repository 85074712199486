import React from 'react';
import PropTypes from 'prop-types';

import { Input } from 'antd';

const { Search } = Input;
const SearchBox = ({
  placeholder, style, allowClear, dataTestId, ...otherProps
}) => (
  <Search
    placeholder={placeholder}
    style={style}
    allowClear={allowClear}
    data-testid={dataTestId}
    {...otherProps}
  />
);

SearchBox.defaultProps = {
  style: { width: 200 },
  allowClear: true,
  placeholder: 'Input search text',
};

SearchBox.propTypes = {
  style: PropTypes.instanceOf(Object),
  allowClear: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default SearchBox;
