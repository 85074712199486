import React, { useState, useCallback, useEffect } from 'react';
import { Form } from 'antd';

import WithLabel from '../../../hoc/withLabel';
import WithRights from '../../../hoc/withRights';

import { labelPaths, UiRoutes } from '../../../lib/constants';
import encode from '../../../lib/encode';
import rights from '../../../lib/rights';

import Button from '../../../components/Button';

import useRedirect from '../../../hooks/useRedirect';
import useRights from '../../../hooks/useRights';

import DoctorList from './DoctorList';
import PatientList from './PatientList';
import MapContainer from './MapContainer';
import HeaderFilter from './HeaderFilter';
import AddPatientModal from './Components/AddPatientModal';

import './BookAppointment.scss';
import Notification from '../../../components/Notification';
import AppointmentTitle from './Components/AppointmentTitle';

const BookAppointment = ({ labels }) => {
  const [form] = Form.useForm();
  const { replace, path, query } = useRedirect();

  const [visibility, setVisibility] = useState(false);
  const [appointmentData, setAppointmentData] = useState([]);
  const [filterParams, setFilterParams] = useState({});
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [reFetch, setReFetch] = useState(null);
  const [isCreatePatientAuthenticated] = useRights([rights.create_new_patient]);

  const toggleModal = useCallback(() => {
    setVisibility(!visibility);
  }, [setVisibility, visibility]);

  const handleReFetch = useCallback(
    (reFetchFunction) => {
      if (!reFetch) {
        setReFetch(() => reFetchFunction);
      }
    },
    [reFetch, setReFetch],
  );

  const scheduleAppointment = useCallback(
    (providerId) => {
      const search = {};
      if (!selectedPatient) {
        return Notification({ message: 'Please select a patient' });
      }
      search.patient = selectedPatient;
      const {
        payerId, Radius, SpecialityId, zipcode, insuranceDetailId, insuranceId,
      } = filterParams;
      if (providerId) search.doctor = providerId;
      if (payerId) search.payer = payerId;
      if (SpecialityId) search.speciality = SpecialityId;
      if (zipcode) search.zipcode = zipcode;
      if (Radius) search.radius = Radius;
      if (insuranceDetailId) search.insuranceDetailId = insuranceDetailId;
      if (insuranceId) search.insuranceId = insuranceId;
      return replace({
        search: encode(search),
        pathname: UiRoutes.schedular,
      });
    },
    [filterParams, selectedPatient, replace],
  );

  useEffect(() => {
    const lastSetting = localStorage.getDecryptedData('book-appointment-settings');
    if (lastSetting && query && !Object.keys(query).length) {
      replace({
        search: lastSetting,
        pathname: path,
      });
    }
  }, []);

  return (
    <>
      <div className="ba-wrapper">
        <div className="header-section">
          <AppointmentTitle labels={labels} />
          <div>
            {isCreatePatientAuthenticated && (
              <Button className="header-btn btn btn-success sm-btn" onClick={toggleModal}>
                {labels.get('buttons.addPatient')}
              </Button>
            )}
          </div>
        </div>
        <div className="filter-header">
          <div className="filter-left custom-ui-fix-header">
            <HeaderFilter
              form={form}
              labels={labels}
              setAppointmentData={setAppointmentData}
              setFilterParams={setFilterParams}
              setSelectedPatient={setSelectedPatient}
            />
          </div>
        </div>
        <div className="content">
          <div className="doctor-list-section">
            <DoctorList
              data={appointmentData}
              labels={labels}
              scheduleAppointment={scheduleAppointment}
            />
          </div>
          <div className="map-section">
            <MapContainer appointmentData={appointmentData} />
          </div>
          <div className="patient-section">
            <PatientList
              handleReFetch={handleReFetch}
              setSelectedPatient={setSelectedPatient}
              labels={labels}
              form={form}
              selectedPatient={selectedPatient}
            />
          </div>
        </div>
      </div>
      <AddPatientModal
        visibility={visibility}
        toggleModal={toggleModal}
        labels={labels}
        reFetch={reFetch}
      />
    </>
  );
};

export default WithRights(
  WithLabel(BookAppointment, labelPaths.BOOK_APPOINTMENT),
  rights.access_to_appointment_app,
);
