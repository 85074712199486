import React from 'react';
import get from 'lodash/get';

import Smcheckbox from '../../../../../../../../components/SmCheckbox';
import Icon from '../../../../../../../../components/Icon';

import IconNames from '../../../../../../../../lib/iconNames';

const Label = ({ value, ...otherProps }) => (
  <div className="ant-row">
    <div className="ant-form-item-label ant-form-item-label-left">
      <p className="ant-form-item-no-colon" title={value} {...otherProps}>
        {value}
      </p>
    </div>
  </div>
);

const EditableCheckBoxCell = ({
  checked,
  onChange,
  disabled,
}) => (
  <Smcheckbox labelSpan="0" inputSpan="14" id="23" checked={checked} onChange={onChange} disabled={disabled} />
);

const ExceptionLabel = ({
  exception, onChange, onDeleteException = () => {
    // This is intentional
  },
}) => (
  <>
    <td>
      <EditableCheckBoxCell
        checked={get(exception, 'isRange')}
        onChange={onChange('isRange')}
        disabled
      />
    </td>
    <td>
      <div className="field-row flex align-center exception-term-para">
        <div className="col">
          <Label value={exception.procedureFromCode} />
        </div>
        {exception.procedureToCode && (
        <>
          <div className="to">To</div>
          <div className="col">
            <Label value={exception.procedureToCode} />
          </div>
        </>
        )}
      </div>
    </td>
    <td>
      <div className={get(exception, 'isRange') ? 'exception-description' : 'exception-description-full'}>
        <Label value={exception.procedureFromDescription} />
        <Label value={exception.procedureToDescription} />
      </div>
    </td>
    <td>
      <Label value={exception?.feeScheduleTypeName} />
    </td>
    <td>
      <Label value={exception?.typeName} />
    </td>
    <td>
      <Label value={exception?.modifierCode || exception?.modifier} />
    </td>
    <td>
      <Label value={exception?.typeValue} />
    </td>
    <td>
      <Label value={exception?.typeValueTypeName} />
    </td>
    <td>
      <Icon name={IconNames.deleteIcon} className="cursor-pointer" onClick={onDeleteException} />
    </td>
  </>
);

export default ExceptionLabel;
