import React from 'react';

import { listIds } from '../../../../../../lib/constants';
import { apiUrls } from '../../../../../../api/constants';
import withQuery from '../../../../../../hoc/withQuery/withQuery';

import columns from './columns';
import TableWrapper from './TableWrapper';

const CustomActivityLog = withQuery({
  url: apiUrls.GET_ACTIVITY_LOGS,
  listId: listIds.GET_ACTIVITY_LOGS,
  accessor: (data) => data?.result,
})();

const ActivityLog = ({ patientId }) => (
  <section className="info-accordian activity-logs">
    <CustomActivityLog
      columns={columns}
      filters={{ patientId }}
      scrollId="activity-log"
    >
      {({ Component, data, reFetch }) => (
        <TableWrapper
          Component={Component}
          data={data}
          reFetch={reFetch}
        />
      )}
    </CustomActivityLog>
  </section>
);

export default ActivityLog;
