/* eslint-disable no-unused-expressions */
import React, {
  useCallback, useEffect, useRef,
} from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { PublicClientApplication } from '@azure/msal-browser';
import { connect } from 'react-redux';
import {
  Tooltip, Menu, Dropdown, Avatar,
} from 'antd';

import LogoutOutlined from '@ant-design/icons/LogoutOutlined';
import { clearLoginData, logout } from '../../store/actions/login';

import { msalConfig } from '../../api/authConfig';
import { apiUrls } from '../../api/constants';
import rights from '../../lib/rights';
import { getInitials, getString } from '../../lib/util';

import useCRUD from '../../hooks/useCRUD';
import useRights from '../../hooks/useRights';

import Link from '../../components/Link';
import Image from '../../components/Image';

import './sidebar.scss';

/*
TODO: this file needs improvements a new component needs
to designed to handle link and span and is Active check.
*/

const color = ['#2a4a79'];

function update() {
  document.getElementById('sidebar-clock-date').textContent = moment().format('MM/DD/YY');
  document.getElementById('sidebar-clock-time').textContent = moment().format('hh:mm A');
}

const ProfileMenu = ({ loginUser, ...props }) => (
  <div className="user-profile-menu">
    <div style={{ padding: '8px', borderBottom: '1px solid #d2ddef' }}>
      <span>
        {getString([loginUser?.firstName, loginUser?.middleName, loginUser?.lastName])}
      </span>
    </div>
    <Menu className="profile-menu-container" {...props}>
      <Menu.Item key="logout">
        <LogoutOutlined />
        <span data-testid="logOut">Log out</span>
      </Menu.Item>
    </Menu>
  </div>
);

function Sidebar({
  // eslint-disable-next-line max-len
  location, history, dataTestId, openMenuClass, handleTabModule, loginUser, clearLogin, logoutUser, openDownloadBox,
}) {
  const { pathname } = location;
  const msalInstance = useRef(new PublicClientApplication(msalConfig));

  const isActive = useCallback((path) => {
    const arr = pathname.split('/');
    return (arr[1] === path);
  }, [pathname]);

  const [userLogoutResponse,,, userLogout, clearUserLogoutResponse] = useCRUD({
    id: apiUrls.LOGOUT,
    url: apiUrls.LOGOUT,
    type: 'create',
  });

  const [
    isDashboardAuthenticted,
    isSchedularAuthenticated,
    isPatientAuthenticated,
    isBillingAuthenticated,
    isClaimsAuthenticated,
    isContractAuthenticated,
    isReportsAuthenticated,
    isDoctorAuthenticated,
    isAppointmentAuthenticated,
    isTaskManagementAuthenticated,
    isManualInterventionQueueAuthenticated,
    isTechnicianAuthenticated,
    isSettingsAuthenticated,
  ] = useRights(
    [
      rights.access_to_dashboard,
      rights.access_to_schedular_app,
      rights.access_to_patient_app,
      rights.access_to_billing_app,
      rights.access_to_claims_app,
      rights.access_to_contract_management,
      rights.access_to_analytics_app,
      rights.access_to_doctor_app,
      rights.access_to_appointment_app,
      rights.access_to_task_management_app,
      rights.access_to_manual_intervention_queue_app,
      rights.access_to_technician_app,
      rights.access_to_settings,
    ],
  );

  useEffect(() => {
    if (userLogoutResponse) {
      clearLogin();
      logoutUser();
      localStorage.clear();
      window.location = `${location.href}?eraseCache=true`;
      clearUserLogoutResponse(true);
    }
  }, [userLogoutResponse]);

  const onProfileMenuSelect = useCallback(({ key }) => {
    if (key === 'logout') {
      const isMicroSoftLogin = localStorage.getDecryptedData('isMicroSoftLogin');
      if (isMicroSoftLogin) {
        msalInstance.current?.logoutPopup().then(() => {
          userLogout({
            data: {
              accessToken: localStorage.getDecryptedData('token'),
            },
          });
        }).catch((e) => {
          console.log('error-in-logout', e);
        });
      } else {
        userLogout({
          data: {
            accessToken: localStorage.getDecryptedData('token'),
          },
        });
      }
    }
  }, [userLogout]);

  useEffect(() => {
    const arr = pathname.split('/');
    if (arr[1] === 'scheduler') arr[1] = 'scheduler';
    if (arr[1] === 'appointment') arr[1] = 'Schedule Appointments';
    if (arr?.length) {
      handleTabModule(arr[1] !== 'settings' ? arr[1] : arr[2])();
    }
  }, []);

  const onTasksClick = useCallback((event) => {
    event?.preventDefault();
    handleTabModule('Task')();
    history.push('/tasks', { lastPath: location?.pathname });
  }, [handleTabModule, history, location]);

  useEffect(() => {
    update();
    const interval = setInterval(update, 2000);
    return () => clearInterval(interval);
  });

  return (
    <div className="app-sidebar" id="focus-app-sidebar" data-testid={dataTestId}>
      <div className="side-logo">
        {process.env.REACT_APP_DEMO === 'true' && <Image data-testid="focus-after-login-logo" name="logo.svg" alt="logo" />}
      </div>
      <Scrollbars autoHide style={{ height: process.env.REACT_APP_FIX_VERSION ? 'calc(100% - 178px)' : 'calc(100% - 124px)' }}>
        <div className="navigation-list">
          <ul>
            {isAppointmentAuthenticated && (
            <Link component="li" to="/appointment" onClick={handleTabModule('appointment')}>
              <Tooltip placement="right" color={color} title="Appointment">
                <span data-testid="appointment" className={classNames('app-sidebar-item ba sprite-img', { active: isActive('appointment') })} />
              </Tooltip>
            </Link>
            )}
            {isDashboardAuthenticted && (
            <Link component="li" to="/dashboard" onClick={handleTabModule('dashboard')}>
              <Tooltip placement="right" color={color} title="Dashboard">
                <span data-testid="dashboard" className={classNames('app-sidebar-item dashboard sprite-img', { active: isActive('dashboard') })} />
              </Tooltip>
            </Link>
            )}
            {isSchedularAuthenticated && (
            <Link component="li" root to="/scheduler" onClick={handleTabModule('scheduler')}>
              <Tooltip placement="right" color={color} title="Scheduler">
                <span data-testid="scheduler" className={classNames('app-sidebar-item calender sprite-img', { active: isActive('scheduler') })} />
              </Tooltip>
            </Link>
            )}
            { isPatientAuthenticated && (
            <Link component="li" root to="/patients" onClick={handleTabModule('patients')}>
              <Tooltip placement="right" color={color} title="Patients">
                <span data-testid="patients" className={classNames('app-sidebar-item patient sprite-img', { active: isActive('patients') })} />
              </Tooltip>
            </Link>
            ) }
            { isBillingAuthenticated && (
            <Link component="li" root to="/billing" onClick={handleTabModule('billing')}>
              <Tooltip placement="right" color={color} title="Billing Encounter">
                <span
                  data-testid="billing"
                  className={classNames('app-sidebar-item billing-icon sprite-img-2',
                    { active: isActive('billing') })}
                />
              </Tooltip>
            </Link>
            ) }
            { isClaimsAuthenticated && (
            <Link component="li" root to="/claims/claims" onClick={handleTabModule('claims')}>
              <Tooltip placement="right" color={color} title="Claims">
                <span
                  data-testid="claims"
                  className={classNames('app-sidebar-item infromation sprite-img',
                    { active: isActive('claims') })}
                />
              </Tooltip>
            </Link>
            )}
            {isDoctorAuthenticated && (
              <Link component="li" root to="/doctor" onClick={handleTabModule('doctor')}>
                <Tooltip placement="right" color={color} title="Doctor">
                  <span data-testid="doctor" className={classNames('app-sidebar-item doctor sprite-img', { active: isActive('doctor') })} />
                </Tooltip>
              </Link>
            )}
            {isTechnicianAuthenticated && (
            <Link component="li" root to="/technician" onClick={handleTabModule('technician')}>
              <Tooltip placement="right" color={color} title="Technician">
                <span data-testid="technician" className={classNames('app-sidebar-item users sprite-img', { active: isActive('technician') })} />
              </Tooltip>
            </Link>
            )}
            {/* <Link
              component="li"
              root
              to="/technicianLilyLee"
              onClick={handleTabModule('technicianLilyLee')}
            >
              <Tooltip placement="right" color={color} title="Technician Lily Lee">
                <span
                  data-testid="technicianLilyLee"
                  className={classNames('app-sidebar-item user-lily-lee sprite-img-2',
                    { active: isActive('technicianLilyLee') })}
                />
              </Tooltip>
            </Link> */}
            {isContractAuthenticated && (
            <Link component="li" root to="/contract" onClick={handleTabModule('contract')}>
              <Tooltip placement="right" color={color} title="Contract">
                <span data-testid="contract" className={classNames('app-sidebar-item user-des sprite-img', { active: isActive('contract') })} />
              </Tooltip>
            </Link>
            )}
            {isReportsAuthenticated && (
            <Link component="li" root to="/reports" onClick={handleTabModule('Reports')}>
              <Tooltip placement="right" color={color} title="Reports">
                <span data-testid="reports" className={classNames('app-sidebar-item report-icon sprite-img', { active: isActive('reports') })} />
              </Tooltip>
            </Link>
            )}
            {isTaskManagementAuthenticated && (
            <Link component="li" root to="/tasks" onClick={onTasksClick}>
              <Tooltip placement="right" color={color} title="Task Management">
                <span data-testid="tasks" className={classNames('app-sidebar-item task-icon sprite-img', { active: isActive('tasks') })} />
              </Tooltip>
            </Link>
            )}
            {isManualInterventionQueueAuthenticated && (
              <Link component="li" root to="/pverify" onClick={handleTabModule('pverify')}>
                <Tooltip placement="right" color={color} title="Manual Intervention Queue">
                  <span data-testid="pverify" className={classNames('app-sidebar-item pverify-icon sprite-img-2', { active: isActive('pverify') })} />
                </Tooltip>
              </Link>
            )}
            {isSettingsAuthenticated && (
            <li className="cursor-pointer">
              <Tooltip placement="right" color={color} title="Settings">
                <a href="#">
                  <span data-testid="settings" role="presentation" className={classNames('app-sidebar-item settings sprite-img', { active: isActive('setting') })} onClick={openMenuClass} />
                </a>
              </Tooltip>
            </li>
            )}
            <li className="cursor-pointer">
              <Tooltip placement="right" color={color} title="Downloader">
                <a href="#downloader">
                  <span data-testid="download" className={classNames('app-sidebar-item download-box', { active: isActive('downloadbox') })} role="presentation" onClick={openDownloadBox} />
                </a>
              </Tooltip>
            </li>

          </ul>
        </div>
      </Scrollbars>
      <div className="header-menus">
        <ul>
          {process.env.REACT_APP_FIX_VERSION && (
          <li className="cursor-pointer version-container">
            <div className="fix-version">{`Ver: ${process.env.REACT_APP_FIX_VERSION}`}</div>
            <div className="fix-version">{`(${process.env.REACT_APP_VERSION_DATE})`}</div>
          </li>
          )}
          <li className="cursor-pointer">
            <span id="sidebar-clock-date" className="date-time-style" />
          </li>
          <li className="cursor-pointer">
            <span id="sidebar-clock-time" className="date-time-style" />
          </li>
          <li className="user-icon">
            <div className="user-img">
              <Dropdown
                getPopupContainer={() => document.getElementById('focus-app-sidebar')}
                overlay={<ProfileMenu loginUser={loginUser} onClick={onProfileMenuSelect} />}
                placement="topLeft"
                trigger="click"
              >
                <Tooltip
                  placement="right"
                  color={color}
                  title={getString([loginUser?.firstName,
                    loginUser?.middleName,
                    loginUser?.lastName])}
                >
                  <Avatar>
                    {getInitials([loginUser?.firstName,
                      loginUser?.middleName,
                      loginUser?.lastName])}
                  </Avatar>
                </Tooltip>
              </Dropdown>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loginUser: state.login.get('current'),
});
const mapDispatchToProps = (dispatch) => ({
  clearLogin: () => dispatch(clearLoginData()),
  logoutUser: () => dispatch(logout('REQUEST_CANCELLED_SESSION_TIMEOUT')),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));

