import CryptoJS from 'crypto-js';

const localStorageEncryption = (data) => CryptoJS.AES.encrypt(`${data}`, `${process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION_KEY}}`).toString();

const localStorageDecryption = (data) => {
  const bytes = CryptoJS.AES.decrypt(data.toString(), `${process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION_KEY}}`);
  return bytes.toString(CryptoJS.enc.Utf8);
};

const localStorageKeyEncryption = (key) => CryptoJS.enc.Base64.stringify(
  CryptoJS.enc.Utf8.parse(key),
);

const setEncryptedLocalStorageData = (key, data) => {
  const encryptedKey = localStorageKeyEncryption(key);
  if (data) {
    const encryptedData = localStorageEncryption(data);
    localStorage.setItem(encryptedKey, encryptedData);
  } else {
    localStorage.setItem(encryptedKey, data);
  }
};

const getDecryptedLocalStorageData = (key) => {
  const encryptedKey = localStorageKeyEncryption(key);
  const item = localStorage.getItem(encryptedKey);
  return item && localStorageDecryption(item);
};

const removeEncryptedLocalStorageData = (key) => {
  const encryptedKey = localStorageKeyEncryption(key);
  localStorage.removeItem(encryptedKey);
};

Storage.prototype.setEncryptedData = (key, data) => setEncryptedLocalStorageData(key, data);
Storage.prototype.getDecryptedData = (key) => getDecryptedLocalStorageData(key);
Storage.prototype.removeEncryptedData = (key) => removeEncryptedLocalStorageData(key);

export {
  setEncryptedLocalStorageData,
  getDecryptedLocalStorageData,
};
