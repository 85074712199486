import React, { useCallback, useMemo } from 'react';

import { labelPaths, UiRoutes } from '../../../../../lib/constants';
import useRedirect from '../../../../../hooks/useRedirect';

import WithLabel from '../../../../../hoc/withLabel';

import EventWrapper from '../../../../../components/EventWrapper';

import ClaimsTable from './Table';

const claimFilterMap = {
  new: 'New',
  inProgress: 'In Progress',
  needsAction: 'Needs Action',
  smallBalance: 'Small Balance',
  completed: 'Completed',
};

const Claims = (props) => {
  const { labels } = props;
  const { params, replace, generatePath } = useRedirect();
  const { claimFilter, type, collectionId } = params;
  const onBack = useCallback(() => {
    replace(generatePath(UiRoutes.collectionClaimsFilters, params));
  }, [generatePath, params, replace]);

  const filterString = useMemo(() => [(claimFilterMap[claimFilter] || ''),
    ' > ', type, (collectionId !== '0' ? `: ${collectionId}` : '')].join(''), [claimFilter, collectionId, type]);

  return (
    <>
      <div className="heading-area">
        <EventWrapper className="back-arrow suspense-back-arrow" onClick={onBack}>
          {filterString}
        </EventWrapper>
      </div>
      <ClaimsTable labels={labels} />
    </>
  );
};

export default WithLabel(Claims,
  labelPaths.COLLECTION_CLAIMS_LIST);
