/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
import { Map, fromJS } from 'immutable';
import isEqual from 'lodash/isEqual';

import moment from 'moment';
import set from 'lodash/set';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import {
  GET_DOCTORS,
  GET_DOCTORS_SCHEDULES,
  GET_DOCTORS_APPOINTMENTS,
  SET_SELECTED_DOCTOR,
  REMOVE_APPOINTMENT,
  ADD_APPOINTMENT,
  SET_SELECTED_APPOINTMENT,
  UPDATE_APPOINTMENT,
  UPDATE_APPOINTMENT_POSITION,
  UPDATE_APPOINTMENT_DETAIL,
  UPDATE_APPOINTMENT_STATUS,
  GET_SELECTED_DOCTOR_SCHEDULE,
  GET_SELECTED_DOCTOR_APPOINTMENT,
  SET_SELECTED_DOCTORS_SCHEDULER_DATA,
  SET_SELECTED_DOCTORS_FREE_SLOTS,
  SET_ERROR,
  SET_LOADING,
  RESET_APPOINTMENT,
  UPDATE_APPOINTMENT_ID,
} from '../actions/doctors';
import { schedulerDataDateFormat } from '../../lib/constants';

const schedulerDataParser = ({ data }) => {
  const obj = {};
  data.forEach((item) => {
    const scheduleDate = moment(item.scheduleDate).format('YYYY-MM-DD');
    if (!get(obj, scheduleDate)) {
      set(obj, `${scheduleDate}`, {});
    }
    item.providersSchedule.forEach((schedule) => {
      schedule.schedule.forEach((scheduleItem) => {
        // eslint-disable-next-line no-param-reassign
        scheduleItem.location = {
          id: scheduleItem.locationId,
          locationId: scheduleItem.locationId,
          name: scheduleItem.locationName,
        };
      });
      schedule.appointments.forEach((appointment) => {
        appointment.id = appointment.appointmentId;
        appointment.patient = {
          name: `${appointment.patientFirstName} ${appointment.patientLastName}`,
          id: appointment.patientId,
          patientId: appointment.patientId,
        };

        appointment.location = {
          name: appointment.locationName,
          id: appointment.locationId,
          locationId: appointment.locationId,
        };

        appointment.doctor = {
          name: `${appointment.providerFirstName} ${appointment.providerLastName}`,
          id: appointment.providerId,
          providerId: appointment.providerId,
        };
        appointment.startTime = get(appointment, 'appointmentStartDateTime.dateString', appointment.appointmentStartTime);
        appointment.endTime = get(appointment, 'appointmentEndDateTime.dateString', appointment.appointmentEndTime);
        appointment.patientReachTime = moment(appointment.startTime).subtract(appointment.totalWorkupTime, 'minutes');
      });
      set(obj, `${scheduleDate}.${schedule.providerId}`, schedule);
    });
  });
  return obj;
};

const updateAppointments = ({
  schedulerData, prevDate, date, currentAppointment, doctor, prevDoctor,
}) => {
  if (doctor.id !== prevDoctor.id) {
    const appointments = get(schedulerData, `${moment(date, schedulerDataDateFormat).format('YYYY-MM-DD')}.${get(prevDoctor, 'id')}.appointments`, []);
    const index = findIndex(
      appointments, (appointment) => appointment.id === currentAppointment.id,
    );
    const newAppointment = {
      ...appointments[index],
      appointmentStartTime: currentAppointment.startTime,
      appointmentEndTime: currentAppointment.endTime,
      location: currentAppointment.location,
      doctor: currentAppointment.doctor,
      ...currentAppointment,
    };
    appointments.splice(index, 1);
    set(schedulerData, `${moment(date, schedulerDataDateFormat).format('YYYY-MM-DD')}.${get(prevDoctor, 'id')}.appointments`, appointments);
    const currentAppointments = get(schedulerData, `${moment(date, schedulerDataDateFormat).format('YYYY-MM-DD')}.${get(doctor, 'id')}.appointments`, []);
    set(schedulerData, `${moment(date, schedulerDataDateFormat).format('YYYY-MM-DD')}.${get(doctor, 'id')}.appointments`, [...currentAppointments, newAppointment]);
  } else if (moment(prevDate, schedulerDataDateFormat).format('YYYY-MM-DD') === moment(date, schedulerDataDateFormat).format('YYYY-MM-DD')) {
    const appointments = get(schedulerData, `${moment(date, schedulerDataDateFormat).format('YYYY-MM-DD')}.${get(doctor, 'id')}.appointments`, []);
    const updatedAppointments = appointments.map((appointment) => {
      if (appointment.id === currentAppointment.id) {
        return {
          ...appointment,
          appointmentStartTime: currentAppointment.startTime,
          appointmentEndTime: currentAppointment.endTime,
          location: currentAppointment.location,
          ...currentAppointment,
        };
      }
      return appointment;
    });
    set(schedulerData, `${moment(date, schedulerDataDateFormat).format('YYYY-MM-DD')}.${get(doctor, 'id')}.appointments`, updatedAppointments);
  } else {
    const appointments = get(schedulerData, `${moment(prevDate, schedulerDataDateFormat).format('YYYY-MM-DD')}.${get(doctor, 'id')}.appointments`, []);
    const index = findIndex(
      appointments, (appointment) => appointment.id === currentAppointment.id,
    );
    const newAppointment = {
      ...appointments[index],
      appointmentStartTime: currentAppointment.startTime,
      appointmentEndTime: currentAppointment.endTime,
      location: currentAppointment.location,
      ...currentAppointment,
    };
    appointments.splice(index, 1);
    set(schedulerData, `${moment(prevDate, schedulerDataDateFormat).format('YYYY-MM-DD')}.${get(doctor, 'id')}.appointments`, appointments);
    const currentAppointments = get(schedulerData, `${moment(date, schedulerDataDateFormat).format('YYYY-MM-DD')}.${get(doctor, 'id')}.appointments`, []);
    set(schedulerData, `${moment(date, schedulerDataDateFormat).format('YYYY-MM-DD')}.${get(doctor, 'id')}.appointments`, [...currentAppointments, newAppointment]);
  }
};
const schedulerFreeSlotsParser = (clonedState, { data }) => {
  // Get already saved scheduler data
  const obj = clonedState.get('schedulerData') || {};
  data.forEach((item) => {
    const scheduleDate = moment(item.scheduleDate).format('YYYY-MM-DD');
    // Find the date object in the scheduler data or create one
    if (!get(obj, `${scheduleDate}`)) set(obj, `${scheduleDate}`, {});
    item.providersFreeSlots.forEach((slot) => {
      // Find the provider object in the specific date scheduler data or create one
      if (!get(obj, `${scheduleDate}.${slot.providerId}`)) set(obj, `${scheduleDate}.${slot.providerId}`, {});
      // Inject free slots data in provider's object
      set(obj, `${scheduleDate}.${slot.providerId}.freeSlots`, slot.freeSlots, []);
    });
  });
  return { ...obj };
};

const initialState = Map({
  doctors: [],
  doctorsAppointments: [],
  doctorsSchedulesData: [],
  locations: [],
  schedulerData: {},
});

const actionsMap = {
  [GET_DOCTORS]: (state) => Map({ ...state, doctors: getDoctorsData() }),
  [GET_DOCTORS_APPOINTMENTS]: (state) => Map({
    ...state,
    doctorsAppointments: [],
  }),
  [GET_DOCTORS_SCHEDULES]: (state) => Map({
    ...state,
    doctorsSchedulesData: [],
  }),
  [SET_SELECTED_DOCTOR]: (state, action) => {
    const clonedState = fromJS(state);
    const { doctorId } = action;
    const selectedDoctor = clonedState.get('doctors')[doctorId];
    return clonedState.set('selectedDoctor', selectedDoctor);
  },
  [SET_SELECTED_DOCTORS_SCHEDULER_DATA]: (state, data) => {
    const clonedState = fromJS(state);
    return clonedState.set('schedulerData', schedulerDataParser(data));
  },
  [SET_SELECTED_DOCTORS_FREE_SLOTS]: (state, data) => state.set('schedulerData', schedulerFreeSlotsParser(state, data)),
  [SET_SELECTED_APPOINTMENT]: (state, action) => {
    const clonedState = fromJS(state);
    const { appointmentId } = action;
    const doctorsAppointmentsData = clonedState.get('doctorsAppointments');
    const currentDoctorAppointmentsIndex = doctorsAppointmentsData.findIndex(
      (doctor) => doctor.id === newAppointment.doctor,
    );
    const currentDoctorAppointments = doctorsAppointments[currentDoctorAppointmentsIndex];
    const selectedAppointment = currentDoctorAppointments[appointmentId]; // picking by index TO DO:
    return clonedState.set('selectedAppointment', selectedAppointment);
  },
  [ADD_APPOINTMENT]: (state, { payload }) => {
    const {
      doctorsAppointments: doctorsAppointment,
      selectedDoctorAppointment,
      ...others
    } = state.toJS();
    const { doctor } = payload;
    let updateAppointment = [];
    const updated = doctorsAppointment.map((item) => {
      if (isEqual(item.doctor, doctor)) {
        updateAppointment = Array.isArray(item.appointments)
          ? item.appointments
          : [];
        updateAppointment.push(payload);
        return ({
          doctor: item.doctor,
          appointments: updateAppointment,
        });
      }
      return item;
    });
    const selectedUpdated = selectedDoctorAppointment.map((item) => {
      if (isEqual(item.doctor, doctor)) {
        return ({
          doctor: item.doctor,
          appointments: updateAppointment,
        });
      }
      return item;
    });
    return Map({
      ...others,
      selectedDoctorAppointment: selectedUpdated,
      doctorsAppointments: updated,
    });
  },
  [UPDATE_APPOINTMENT]: (state, action) => {
    const clonedState = fromJS(state);
    const updateAppointmentData = { ...action.payload };
    const doctorsAppointmentsData = clonedState.get('doctorsAppointments');
    const currentDoctorAppointmentsIndex = doctorsAppointmentsData.findIndex(
      (doctor) => doctor.id === updateAppointmentData.doctor,
    );
    const currentDoctorAppointments = doctorsAppointments[currentDoctorAppointmentsIndex];
    currentDoctorAppointments[updateAppointmentData.id] = updateAppointmentData;
    doctorsAppointmentsData[currentDoctorAppointmentsIndex] = currentDoctorAppointments;
    return clonedState.set('providers', doctorsAppointmentsData);
  },
  [GET_SELECTED_DOCTOR_SCHEDULE]: (state, { payload }) => {
    const clonedState = fromJS(state);
    const { doctorId } = payload;
    const doctorsSchedule = clonedState.get('doctorsSchedulesData');
    if (Array.isArray(doctorId)) {
      const selectedDoctorSchedules = doctorId.map(
        (id) => doctorsSchedule.filter((item) => item.doctor.id === id)[0],
      );
      return clonedState.set('selectedDoctorSchedule', selectedDoctorSchedules);
    }
    const selectedDoctorSchedule = doctorsSchedule.filter((item) => item.doctor.id === id)[0];
    return clonedState.set('selectedDoctorSchedule', [selectedDoctorSchedule]);
  },
  [GET_SELECTED_DOCTOR_APPOINTMENT]: (state, { payload }) => {
    const clonedState = fromJS(state);
    const { doctorId } = payload;
    const doctorsAppointmentsData = clonedState.get('doctorsAppointments');
    if (Array.isArray(doctorId)) {
      const selectedDoctorAppointments = doctorId.map(
        (id) => doctorsAppointmentsData.filter((item) => item.doctor.id === id)[0],
      );
      return clonedState.set('selectedDoctorAppointment', selectedDoctorAppointments);
    }
    const selectedDoctorAppointment = doctorsAppointmentsData.filter(
      (item) => item.doctor.id === id,
    )[0];
    return clonedState.set('selectedDoctorAppointment', [selectedDoctorAppointment]);
  },
  [UPDATE_APPOINTMENT_POSITION]: (state, { payload }) => {
    const { currentAppointment, prevAppointment } = payload;
    const { schedulerData, ...others } = state.toJS();
    const { startTime: date, doctor } = currentAppointment;
    const { startTime: prevDate, doctor: prevDoctor } = prevAppointment;

    updateAppointments({
      currentAppointment, prevDate, date, doctor, schedulerData, prevDoctor,
    });
    return Map({ ...others, schedulerData });
  },
  [UPDATE_APPOINTMENT_DETAIL]: (state, { payload }) => {
    const { currentAppointment, prevAppointment } = payload;
    const { schedulerData, ...others } = state.toJS();
    const { startTime: date, doctor } = currentAppointment;
    const { startTime: prevDate, doctor: prevDoctor } = prevAppointment;

    updateAppointments({
      currentAppointment, prevDate, date, doctor, schedulerData, prevDoctor,
    });
    return Map({ schedulerData, ...others });
  },
  [UPDATE_APPOINTMENT_STATUS]: (state, { payload }) => {
    const { data, replaceWith } = payload;
    const { doctorsAppointments: allDoctorAppointment, ...others } = state.toJS();
    if (data.id !== replaceWith) {
      allDoctorAppointment.every((appointment, appointmentIndex) => {
        if (isEqual(appointment.doctor, data.doctor)) {
          const newAppointments = appointment.appointments.filter(
            (element) => element.id !== data.id,
          );
          const newIndex = replaceWith ? appointment.appointments.findIndex(
            (element) => element.id === replaceWith,
          ) : newAppointments.length;
          newAppointments.splice(Math.max(newIndex, 0), 0, data);
          allDoctorAppointment[appointmentIndex].appointments = newAppointments;
          return false;
        }
        return true;
      });
    }
    return Map({ ...others, doctorsAppointments: allDoctorAppointment });
  },
  [REMOVE_APPOINTMENT]: (state, { payload }) => {
    const { data } = payload;
    const { appointmentStartDateTime: { year, month, day }, appointmentId, providerId } = data;
    // format date for correct padding
    const date = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
    const { schedulerData, ...others } = state.toJS();
    const appointmentDataForSingleDay = schedulerData[date];
    if (appointmentDataForSingleDay) {
      const appointmentData = appointmentDataForSingleDay[providerId];
      const { appointments = [] } = appointmentData || {};
      const updated = appointments.filter((item) => item.appointmentId !== appointmentId);
      return Map({
        ...others,
        schedulerData: {
          ...schedulerData,
          [date]: {
            ...appointmentDataForSingleDay,
            [providerId]: { ...appointmentData, appointments: updated },
          },
        },
      });
    }
    return Map({
      ...others,
      ...schedulerData,
    });
  },
  [SET_LOADING]: (
    state, { flag },
  ) => state.set('loading', flag),
  [SET_ERROR]: (state, { error }) => state.set('error', error),
  [RESET_APPOINTMENT]: (state, { payload: paylod }) => {
    const { currentAppointment, prevAppointment } = paylod;
    const { schedulerData, ...others } = state.toJS();
    const { startTime: date, doctor } = currentAppointment;
    const { startTime: prevDate, doctor: prevDoctor } = prevAppointment;

    updateAppointments({
      currentAppointment, prevDate, date, doctor, schedulerData, prevDoctor,
    });
    return Map({ schedulerData, ...others });
  },
  [UPDATE_APPOINTMENT_ID]: (state, { payload }) => {
    const { currentAppointment, newAppointmentId } = payload;
    const { date, doctor, id } = currentAppointment;
    const { schedulerData, ...others } = state.toJS();

    // eslint-disable-next-line no-shadow
    const appointments = get(schedulerData, `${moment(date).format('YYYY-MM-DD')}.${get(doctor, 'id')}.appointments`, []);
    const updatedAppointments = appointments.map((appointment) => {
      if (appointment.id === id) {
        return {
          ...appointment,
          id: newAppointmentId,
          appointmentId: newAppointmentId,
        };
      }
      return appointment;
    });
    set(schedulerData, `${moment(date).format('YYYY-MM-DD')}.${get(doctor, 'id')}.appointments`, updatedAppointments);
    return Map({ schedulerData, ...others });
  },
};

export default function doctors(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
