import React from 'react';
import '../bills.scss';

import BillStatementTable from './BillStatementTable';

const BillDetails = ({ labels, onEdit }) => (
  <div className="bills-wrap">
    <div className="patient-statement-list-table">
      <BillStatementTable labels={labels} onEdit={onEdit} />
    </div>
  </div>
);

export default BillDetails;
