import React from 'react';
import get from 'lodash/get';

import { apiUrls } from '../../../../api/constants';
import { listIds } from '../../../../lib/constants';

import withQuery from '../../../../hoc/withQuery/withQuery';

import { columns } from './columns';

const hiddenICDColumns = ['remove'];

const ICDCodeTable = (payerId, labels, onDeleteICD, isPayerRequestViewOnly) => (props) => {
  const cptId = get(props, 'row.original.id');

  const CustomICDCodesTable = withQuery({
    url: apiUrls.GET_CPT_PAYER_MAPPING,
    listId: `${listIds.SETTING_GET_ICD_CODE_MAPPING}_${cptId}_${payerId}`,
    accessor: (data) => ({ result: get(data, 'result[0].cptCodes[0].icdCodes', []) }),
  })();

  const filters = {
    PayerId: payerId,
    CPTCodeId: cptId,
  };

  return (
    <CustomICDCodesTable
      columns={columns(labels)}
      noDataText="ICD Codes not found"
      filters={filters}
      onDeleteICD={onDeleteICD}
      cptId={cptId}
      hiddenColumns={isPayerRequestViewOnly ? hiddenICDColumns : []}
    >
      {({ Component }) => Component}
    </CustomICDCodesTable>
  );
};

export default ICDCodeTable;
