import {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';

import { getCrudDataWithOrWithoutTabId } from '../../../../../../../../store/selectors';
import { parseResult } from '../../../../../../../../lib/util';

const useOrderSet = ({ type, tabId, providerId }) => {
  const [orderSetData, setOrderSetData] = useState({});

  const slitExamListData = useSelector((state) => type !== 'inOffice' && getCrudDataWithOrWithoutTabId(state, 'get-slit-exam', tabId));
  const fundusExamListData = useSelector((state) => type !== 'inOffice' && getCrudDataWithOrWithoutTabId(state, 'get-fundus-exam', tabId));
  const followUpData = useSelector((state) => type !== 'inOffice' && getCrudDataWithOrWithoutTabId(state, 'doctor-follow-up', tabId));
  const plannedProcedureCodes = useSelector((state) => type !== 'inOffice' && getCrudDataWithOrWithoutTabId(state, `followup-planned-procedures-${providerId}`, tabId));

  const plannedCPTCodes = useMemo(() => {
    if (plannedProcedureCodes) {
      return plannedProcedureCodes.reduce((acc, item) => {
        if (item?.isSelected) {
          // eslint-disable-next-line no-param-reassign
          acc = acc?.length ? `${acc},${item.cptCode}` : `${item.cptCode}`;
        }
        return acc;
      }, '');
    }
    return [];
  }, [plannedProcedureCodes]);

  const handleOrderSetData = useCallback((values) => {
    if (values?.followUp) {
      const clonedValues = { ...values?.followUp };
      if (clonedValues.procedureIds && Array.isArray(clonedValues.procedureIds)) clonedValues.procedureIds = clonedValues?.procedureIds?.join(',');
      setOrderSetData((data) => ({
        ...data,
        followUp: { ...data.followUp, ...clonedValues },
      }));
    } else setOrderSetData((data) => ({ ...data, ...values }));
  }, []);

  useEffect(() => {
    if (type !== 'inOffice') {
      setOrderSetData({
        ...orderSetData,
        followUp: {
          ...pick({ ...followUpData, ...orderSetData?.followUp }, ['year', 'month', 'week', 'days', 'visitTypeId', 'visitCptId', 'testTypeIds',
            'dilation', 'refract', 'refractCptCodeId', 'workUpTime', 'isDeleted', 'notes']),
          procedureIds: ((isEmpty(orderSetData?.followUp?.procedureIds) ? '' : orderSetData?.followUp?.procedureIds) || plannedCPTCodes || ''),
        },
        // notes: customSort(notesListResponse?.notes),
        slitExamList: slitExamListData?.length ? slitExamListData.map(parseResult)
          : [],
        fundusExamList: fundusExamListData?.length ? fundusExamListData.map(parseResult) : [],
      });
    }
  }, [slitExamListData, fundusExamListData, followUpData, type, plannedCPTCodes]);

  return [orderSetData, handleOrderSetData];
};

export default useOrderSet;
