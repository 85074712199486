import React, { useCallback, useMemo } from 'react';
import { Form as AntdForm } from 'antd';

import useRedirect from '../../../../../hooks/useRedirect';
import { formId, UiRoutes, enums } from '../../../../../lib/constants';
import SuccessMessages from '../../../../../lib/successMessages';

import Modals from '../../../../../components/Modal';
import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';

import Select from '../../../../../wiredComponents/Enum/Select';
import Notification from '../../../../../components/Notification';
import { apiUrls } from '../../../../../api/constants';

const source = {
  'patient adjustment': 'PatientAdjustmentReversal',
  'patient payment': 'PatientPaymentReversal',
  'insurance adjustment': 'InsuranceAdjustmentReversal',
  'insurance payment': 'InsurancePaymentReversal',
};

const ReversePaymentModal = ({
  labels, toggleModal, visible, type,
}) => {
  const [form] = AntdForm.useForm();
  const {
    params: { financialId, id: patientId }, params, replace, generatePath,
  } = useRedirect();

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.PAYMENT_REVERSED_SUCCESSFULLY, success: true });
      toggleModal();
      replace(generatePath(UiRoutes.editPatientWithTabId, params));
    }
  }, [generatePath, params, replace, toggleModal]);
  const title = useMemo(() => {
    if (type?.toLowerCase() === 'patient adjustment') {
      return labels.get('titles.reverseAdjustment');
    }
    return labels.get('titles.reversePayment');
  }, [labels, type]);

  return (
    <Modals
      visible={visible}
      toggleModal={toggleModal}
      destroyOnClose
      footer={[
        <div className="group-btns" key="footer">
          <Button className="btn min-wt-86">{labels.get('buttons.cancel')}</Button>
          <Button className="btn btn-success min-wt-86 inline" onClick={form?.submit}>{labels.get('buttons.save')}</Button>
        </div>,
      ]}
    >
      <div className="addition-header">
        <div className="lookup sprite-img-before">
          <p>{title}</p>
        </div>
      </div>
      <div className="modal-content-weapper shadow-wrap">
        <div className="modal-from-feilds">
          <Form
            form={form}
            url={apiUrls.ADD_PAYMENT_REVERSAL}
            formId={`${formId.REVERSE_PAYMENT}-${patientId}`}
            onRequestComplete={onRequestComplete}
            extraData={{
              paymentId: financialId,
              source: source[type?.toLowerCase()],
            }}
          >
            <Select label={labels.get('labels.reason')} name="reversalReasonId" enumName={enums.REVERSAL_REASON} />
          </Form>
        </div>
      </div>
    </Modals>
  );
};

export default ReversePaymentModal;
