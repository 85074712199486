import React from 'react';
import classNames from 'classnames';
import { Tooltip as AntdTooltip } from 'antd';

import './tooltip.scss';

const Tooltip = ({
  className, content, children, ...otherProps
}) => (
  <AntdTooltip
    overlayClassName={classNames('tooltip-container', className)}
    color="#ffffff"
    title={content || ''}
    {...otherProps}
  >
    {children}
  </AntdTooltip>
);

export default Tooltip;
