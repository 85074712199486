import React, { useMemo } from 'react';

import withQuery from '../../hoc/withQuery/withQuery';
import withReduxManager from '../../hoc/withReduxManager';

import { apiUrls } from '../../api/constants';

import { listIds } from '../../lib/constants';

const initialSort = [{ id: 'CaseTypeName', desc: false }];

const Cases = ({
  patientId, labels, columns, scrollId, className, ...props
}) => {
  const CustomTable = useMemo(() => withReduxManager({
    listId: `${listIds.LIST_CASES}-${patientId}`,
  })(withQuery({
    url: apiUrls.CASES_LIST,
    listId: `${listIds.LIST_CASES}-${patientId}`,
  })()), [patientId]);

  return (
    <CustomTable
      columns={columns(labels)}
      initialSort={initialSort}
      filters={{ PatientId: patientId }}
      scrollId={scrollId}
      {...props}
    >
      {({
        Component, totalCount,
      }) => (
        <>
          <div className="table-filters">
            <span />
            <span className="table-count">
              <span>
                {`${labels.get('titles.totalCount')}`}
                :
                {' '}
                <span className="count-value">{`${totalCount}`}</span>
              </span>
            </span>
          </div>
          <div className={className}>
            {Component}
          </div>
        </>
      )}
    </CustomTable>
  );
};

export default Cases;
