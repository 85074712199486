import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';

const ButtonGroup = ({
  onCancel, submit, onSave, saveVisible, cancelVisible,
}) => (
  <div className="group-btns">
    {cancelVisible && <Button className="btn min-wt-86" onClick={onCancel}>Cancel</Button>}
    {saveVisible && <Button className="btn btn-success min-wt-86" onClick={onSave} type={submit && 'submit'}>Save</Button>}
  </div>
);

ButtonGroup.defaultProps = {
  submit: false,
  onCancel: () => { /* This is intentional */ },
  onSave: () => { /* This is intentional */ },
  cancelVisible: true,
  saveVisible: true,
};

ButtonGroup.propTypes = {
  submit: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  cancelVisible: PropTypes.bool,
  saveVisible: PropTypes.bool,
};

export default ButtonGroup;
