import React, { useCallback, useEffect, useRef } from 'react';
import Modals from '../../../components/Modal';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import useCRUD from '../../../hooks/useCRUD';
import useHtmlPrint from '../../../hooks/useHtmlPrint';
import { apiUrls } from '../../../api/constants';
import PdfView from './pdfView';

const PdfViewModal = ({
  isVisible, toggleModal, data, labels, twoPagePdf, sixPagePdf, getData, subCategoryName,
}) => {
  const pdfViewRef = useRef();
  const [pdfViewResponse,, loading, getPdfViewData, clearHistoryData] = useCRUD({
    id: 'pdf-view-data', url: apiUrls.INSURANCE_VERIFICATION_HISTORY, type: 'read',
  });
  const htmlPrint = useHtmlPrint(pdfViewRef, null, 'p-verify-response');
  const pdfData = getData
    ? pdfViewResponse?.result?.length && pdfViewResponse?.result[0] : data;

  const pdfViewData = pdfData?.summaryResponseJson ? JSON.parse(pdfData.summaryResponseJson) : {};

  useEffect(() => {
    if (getData && data?.insuranceVerificationId) {
      getPdfViewData({ insuranceVerificationId: data?.insuranceVerificationId });
    }
    return () => clearHistoryData();
  }, []);

  const printPdf = useCallback(() => {
    htmlPrint();
  }, [htmlPrint]);

  return (
    <Modals
      visible={isVisible}
      width={1140}
      toggleModal={toggleModal}
      destroyOnClose
      className="add-user-role-modal"
      footer={[
        <div className="group-btns" key="footer">
          <Button
            className="btn min-wt-86"
            onClick={toggleModal}
          >
            {labels.get('buttons.cancel')}
          </Button>
          <Button
            className="btn btn-success min-wt-86 inline"
            onClick={printPdf}
          >
            {labels.get('buttons.print')}
          </Button>
        </div>,
      ]}
    >
      <div className="addition-header">
        <div className="lookup sprite-img-before">
          <p>{labels.get('titles.pdfViewTitle')}</p>
        </div>
      </div>
      {loading && <Loader />}
      <div ref={pdfViewRef} className="modal-content-weapper shadow-wrap">
        <PdfView
          pdfHeaderData={pdfData}
          pdfViewData={pdfViewData}
          twoPagePdf={twoPagePdf}
          sixPagePdf={sixPagePdf}
          subCategoryName={subCategoryName}
        />
      </div>
    </Modals>
  );
};

export default PdfViewModal;
