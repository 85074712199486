import React, { useCallback } from 'react';
import moment from 'moment';

import Image from '../../../../../../components/Image';
import dateFormatter from '../../../../../../lib/dateFormatter';
import { formatPhoneNumber, getString } from '../../../../../../lib/util';

const Information = ({ currentPatient, labels }) => {
  const calculateAge = useCallback(() => {
    const birthYear = moment.utc(currentPatient.dateOfBirth).format('MM/DD/YYYY');
    return moment().diff(birthYear, 'years');
  }, [currentPatient.dateOfBirth]);
  return (
    <div className="info-wrapper shadow-wrap mr-rt-16 profile-container">
      <div className="profile-wrap">
        <div className="user-img">
          <Image url={currentPatient?.patientImageUrl || undefined} name="placeholder-img.png" alt="logo" />
        </div>
        <p className="title">
          {
          getString([
            currentPatient?.firstName,
            currentPatient?.middleName,
            currentPatient?.lastName,
          ])
        }
        </p>
        <p className="sub-title">
          <label>{labels.get('labels.chart')}</label>
          <p className="value padding-left-0">{currentPatient.chartNumber}</p>
        </p>
      </div>
      <div className="general-info">
        <p className="title">{labels.get('titles.generalInfo')}</p>
        <div className="row-feilds">
          <label>{labels.get('labels.dob')}</label>
          <p className="value">
            {dateFormatter(currentPatient?.dateOfBirth?.split('T')?.[0])}
          </p>
        </div>
        <div className="row-feilds">
          <label>{labels.get('labels.age')}</label>
          <p className="value">{calculateAge()}</p>
        </div>
        <div className="row-feilds">
          <label>Gender:</label>
          <p className="value">{currentPatient?.genderName}</p>
        </div>
        <p className="title mr-top-8">{labels.get('titles.address')}</p>
        <div className="address">
          <p className="flex flex-wrap">
            <div>
              <div>
                {getString([currentPatient?.addressLine1, currentPatient?.addressLine2])}
              </div>
              <div>
                {getString([
                  currentPatient?.city,
                ])}
                ,
                &nbsp;
                {getString([
                  currentPatient?.stateCode,
                  currentPatient?.zip,
                ])}
              </div>
            </div>
          </p>
        </div>
      </div>
      <div className="general-info">
        <p className="title">{labels.get('titles.contactInfo')}</p>
        <div className="row-feilds flex">
          <label>{labels.get('labels.email')}</label>
          <p title={currentPatient.email} className="value profile-content-wrap align-self-center">
            {currentPatient.email}
          </p>
        </div>
        <div className="row-feilds flex">
          <label>{labels.get('labels.primaryPhone')}</label>
          <p title={formatPhoneNumber(currentPatient.primaryPhone)} className="value width-148 align-self-center profile-content-wrap">
            {formatPhoneNumber(currentPatient.primaryPhone)}
          </p>
        </div>
        <div className="row-feilds flex">
          <label>{labels.get('labels.emergencyPhone')}</label>
          <p title={formatPhoneNumber(currentPatient.emergencyContactPhone)} className="value width-118 align-self-center profile-content-wrap">
            {formatPhoneNumber(currentPatient.emergencyContactPhone)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Information;
