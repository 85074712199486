import React from 'react';

import { apiUrls } from '../../../../../../../api/constants';
import { listIds, labelPaths } from '../../../../../../../lib/constants';

import withQuery from '../../../../../../../hoc/withQuery/withQuery';
import withReduxManager from '../../../../../../../hoc/withReduxManager';
import WithLabel from '../../../../../../../hoc/withLabel';

import columns from './columns';
import TableWrapper from './TableWrapper';

const initialSort = [{ id: 'createdOn', desc: false }];

const CustomTable = withReduxManager({
  listId: `${listIds.GET__APPOINTMENT_CONFIRMATION_HISTORY}`,
})(withQuery({
  url: `${apiUrls.GET__APPOINTMENT_CONFIRMATION_HISTORY}`,
  listId: `${listIds.GET__APPOINTMENT_CONFIRMATION_HISTORY}`,
  alias: {
    time: 'appointmentStartTime',
  },
  accessor: (data) => ({ result: data }),
})());

const ConfirmationHistoryTable = ({ labels, patientId, appointmentId }) => (
  <div className="appointment-table">
    <CustomTable
      columns={columns(labels)}
      initialSort={initialSort}
      filters={{ patientId, appointmentId }}
      scrollId="confirmation-history-table-scroll"
    >
      {({
        Component, reFetch,
      }) => (
        <TableWrapper
          Component={Component}
          reFetch={reFetch}
        />
      )}
    </CustomTable>
  </div>
);

export default WithLabel(ConfirmationHistoryTable, labelPaths.ADD_APPOINTMENT_SCHEDULAR);
