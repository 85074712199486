import React from 'react';
import TimeCell from '../../../../../components/Cells/TimeCell';
import { getDateString } from '../../../../../lib/util';
import { columnsSharedWithAddAppointmentDialog } from '../../../../../wiredComponents/PatientTable/columns';

const columns = (onRowClick, labels) => [
  ...columnsSharedWithAddAppointmentDialog(onRowClick, labels),
  {
    Header: labels.get('tableHeadings.dob'),
    accessor: 'dateOfBirth',
    Cell: ({
      row: {
        original: { dateOfBirth },
      },
    }) => <TimeCell value={getDateString(dateOfBirth)} />,
  },
  {
    Header: labels.get('tableHeadings.chart'),
    accessor: 'chartNumber',
  },
  {
    Header: labels.get('tableHeadings.patientBal'),
    accessor: 'patientBal',
    sort: true,
    Cell: ({ row: { original: { patientBal } } }) => (
      (patientBal || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableHeadings.insuranceBal'),
    accessor: 'insuranceBal',
    sort: true,
    Cell: ({ row: { original: { insuranceBal } } }) => (
      (insuranceBal || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
];

export default columns;
