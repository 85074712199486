/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import { GatewayProvider } from 'react-gateway';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { connect } from 'react-redux';

import classNames from 'classnames';
import Sidebar from './Sidebar';
import OpenSidebar from './OpenSidebar';
import Content from './Content';
import ErrorBoundary from '../components/ErrorBoundary';
import SuperBillDownloader from './SuperbillDownloader';

import '../css/style.scss';
import '../css/form.scss';
import '../css/mixin.scss';
import '../css/button.scss';
import '../css/modal.scss';
import '../css/antd-overrides.less';
import './App.scss';
import { listIds, UiRoutes } from '../lib/constants';
import { apiUrls } from '../api/constants';
import Login from '../screens/Login';
import ForgotPassword from '../screens/ForgotPassword';
import ResetPassword from '../screens/ResetPassword';
import EmailVerification from '../screens/EmailVerification';
import { setTabModuleName } from '../store/actions/tabs';
import Events from '../lib/events';
import useCRUD from '../hooks/useCRUD';
import DownloadBox from './OpenDownloadBox';

function App({ dispatch }) {
  const [downloadBox, setDownloadBox] = useState(false);

  const openDownloadBox = useCallback(() => {
    setDownloadBox(!downloadBox);
  }, [downloadBox]);

  const [menu, setMenu] = useState(false);
  const [applyDisableCss, setApplyDisableCss] = useState(false);
  const formInstanceRef = useRef();

  const logErrorBoundaryMessage = useCRUD({
    id: listIds.LOG_ERROR_BOUNDARY_ERROR_MESSAGE,
    url: apiUrls.LOG_ERROR_BOUNDARY_ERROR_MESSAGE,
    type: 'create',
  })[3];

  const openMenu = useCallback(() => {
    setMenu(!menu);
  }, [menu]);

  const handleTabModule = useCallback((menuId) => (tabName) => () => {
    dispatch(setTabModuleName(tabName));
    if (menuId === 'sidebar') setMenu(false);
    else if (menuId === 'openSidebar') setMenu((pre) => !pre);
  }, [dispatch]);

  useEffect(() => {
    Events.on('disable-other-doctor-components', 'disable-other-doctor-components', ({ isApplied, formInstance }) => {
      setApplyDisableCss(isApplied);
      if (formInstance) formInstanceRef.current = formInstance;
    });
    return () => Events.remove('disable-other-doctor-components');
  }, []);

  const onOverlayClick = () => {
    // eslint-disable-next-line no-unused-expressions
    formInstanceRef.current?.submit?.();
  };

  return (
    <BrowserRouter>
      <GatewayProvider>
        <DndProvider backend={Backend}>
          <SuperBillDownloader />
          <div className="app">
            <Switch>
              <Route path="/health">
                <h3>Hey There!!! The App is Healthy</h3>
              </Route>
              <Route exact path={[UiRoutes.login, UiRoutes.supportLogin]} component={Login} key="main page" />
              <Route path={UiRoutes.forgotPassword} component={ForgotPassword} />
              <Route path={UiRoutes.resetPassword} component={ResetPassword} />
              <Route exact path={UiRoutes.EmailVerification} component={EmailVerification} />
              <Route path="/" key="main page">
                <>
                  <div>
                    <Sidebar openDownloadBox={openDownloadBox} openMenuClass={openMenu} handleTabModule={handleTabModule('sidebar')} />
                    <OpenSidebar
                      booleanMenu={menu}
                      openMenuClass={openMenu}
                      handleTabModule={handleTabModule('openSidebar')}
                    />
                    <DownloadBox booleanDownloadBox={downloadBox} openDownloadBox={openDownloadBox} />
                  </div>
                  <div className={menu ? 'right-content open-menu' : 'right-content'}>
                    <ErrorBoundary
                      logErrorBoundaryMessage={logErrorBoundaryMessage}
                    >
                      <Content />
                    </ErrorBoundary>
                  </div>
                </>
              </Route>
            </Switch>
            <div role="presentation" onClick={applyDisableCss && onOverlayClick} className={classNames({ 'shadow-overlay-1': applyDisableCss })} />
            <div role="presentation" onClick={applyDisableCss && onOverlayClick} className={classNames({ 'shadow-overlay-2': applyDisableCss })} />
            <div role="presentation" onClick={applyDisableCss && onOverlayClick} className={classNames({ 'shadow-overlay-3': applyDisableCss })} />
            {/* <div role="presentation" onClick={applyDisableCss && onOverlayClick}
             className={classNames({ 'shadow-overlay-4': applyDisableCss })} /> */}
          </div>
        </DndProvider>
      </GatewayProvider>
    </BrowserRouter>
  );
}

export default connect()(App);
