import React from 'react';

const commonOptionRenderer = (options = []) => {
  if (!options.length) return [];
  return [{
    label: (
      <div className="option-header-main-wrapper">
        <div className="option headerWrapper">
          <span className="header">
            Name
          </span>
        </div>
      </div>
    ),
    options: options && options.map((item) => (
      {
        value: item.name,
        item,
        label: (
          <div className="option" data-testid={item.name}>
            <span>
              {item.name}
            </span>
          </div>
        ),
      }

    )),
  }];
};

export default commonOptionRenderer;
