import { useEffect } from 'react';
import isFunction from 'lodash/isFunction';

import useCRUDWithoutTab from './useCRUDWithoutTab';
import Notification from '../components/Notification';

const useCrudWithEffects = ({
  id, url, callBack, type,
}) => {
  const [response, error, loading, updateStatus, clearResponse] = useCRUDWithoutTab({
    id, url, type: type || 'update',
  });

  useEffect(() => {
    if (response) {
      if (isFunction(callBack)) callBack(response);
      clearResponse(type === 'read');
    }
  }, [callBack, response]);

  useEffect(() => {
    if (error) {
      Notification({ message: error });
      clearResponse(type === 'read');
    }
  }, [clearResponse, error]);

  return [updateStatus, loading];
};

export default useCrudWithEffects;
