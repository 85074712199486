import React, { useCallback } from 'react';
import { Form as AntdForm } from 'antd';

import { apiUrls } from '../../../../../api/constants';
import SuccessMessages from '../../../../../lib/successMessages';
import Events from '../../../../../lib/events';

import Modals from '../../../../../components/Modal';
import Form from '../../../../../components/Form';
import { Input } from '../../../../../components/Form/inputFields';
import Button from '../../../../../components/Button';
import Notification from '../../../../../components/Notification';

const RenameTemplateModal = ({
  toggleModal, isVisible, labels, reFetchEvent, sectionName, selectedTemplate,
}) => {
  const [form] = AntdForm.useForm();

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.TEMPLATE_UPDATED_SUCCESSFULLY, success: true });
      Events.trigger(reFetchEvent);
      toggleModal();
    }
  }, [reFetchEvent, toggleModal]);

  return (
    <Modals
      visible={isVisible}
      toggleModal={toggleModal}
      destroyOnClose
      footer={[
        <div className="btns-action mr-left-60 mr-bt-12" key="footer">
          <Button className="btn btn-success min-wt-86" onClick={form.submit}>{labels.get('buttons.save')}</Button>
          <Button className="btn" onClick={toggleModal}>{labels.get('buttons.cancel')}</Button>
        </div>,
      ]}
      title={`${labels.get('titles.rename')} ${sectionName}`}
    >
      <Form
        form={form}
        url={`${apiUrls.RENAME_HEADER_FOOTER_TEMPLATE}/${sectionName}`}
        formId={`${apiUrls.RENAME_HEADER_FOOTER_TEMPLATE}/${sectionName}`}
        onRequestComplete={onRequestComplete}
        initialData={{ newName: selectedTemplate?.name }}
        extraData={{
          composerTemplateHeaderFooterId:
          selectedTemplate?.composerTemplateHeaderFooterId,
          method: 'put',
        }}
      >
        <Form.Column>
          <Input
            name="newName"
            labelSpan="8"
            inputSpan="14"
            label={labels.get('labels.newName')}
            required
          />
        </Form.Column>
      </Form>
    </Modals>
  );
};

export default RenameTemplateModal;
