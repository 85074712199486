import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import moment from 'moment';

import Checkbox from '../../../../components/SmCheckbox';
import Loader from '../../../../components/Loader';

import useRedirect from '../../../../hooks/useRedirect';
import useCRUD from '../../../../hooks/useCRUD';
import useReduxStoreWithId from '../../../../hooks/useReduxStoreWithId';

import { labelPaths } from '../../../../lib/constants';
import Events from '../../../../lib/events';

import WithLabel from '../../../../hoc/withLabel';

import { apiUrls } from '../../../../api/constants';

import ViewResponsibleParty from './ResponsibleParty/View/View';
import ViewInsurancePolicy from './ViewInsuranceProfile';

import './insurance.scss';

const getInsurancePolicyArray = (ipData, isHideActive) => {
  const activeProfileDetails = [];
  if (ipData && ipData?.insuraceProfileDetail?.length) {
    ipData.insuraceProfileDetail.forEach((insurance) => {
      if (!isHideActive) {
        if (
          (insurance?.effectiveTo && moment(insurance?.effectiveTo).isBefore(moment()))
        || (!insurance?.effectiveTo && !insurance?.effectiveFrom)
        || (moment(moment()).isBefore(insurance?.effectiveFrom))) return false;
        activeProfileDetails.push(insurance);
        return true;
      }
      activeProfileDetails.push(insurance);
      return true;
    });
  }
  return activeProfileDetails.sort(
    (a, b) => a?.profileTypeId - b?.profileTypeId,
  );
};

function InsuranceInformation(props) {
  const {
    labels, showProfileId, isUpdatePatientAuthenticated,
  } = props;

  const { params } = useRedirect();
  const [showInActiveInsuranceData] = useReduxStoreWithId({ id: params.id });
  const isHideActive = showInActiveInsuranceData?.get('data');

  const { profileId = showProfileId } = params;

  const [ipData, , ipLoading, requestIP] = useCRUD({
    id: 'patient-insurance-rp-id', url: `${apiUrls.GET_SELF_PAY_INSURANCE_PROFILE_BY_ID}/${profileId}`, type: 'read',
  });
  const requestInsuranceData = useCRUD({
    id: 'patient-insurance-rp', url: apiUrls.GET_PATIENT_INSURANCE, type: 'read',
  })[3];
  const [updateResponse, , ipUpdateLading, updateIP] = useCRUD({ id: 'patient-insurance-profile-defaults', url: 'masterservice/PatientInsurance/SetDefaultInsuranceProfile', type: 'update' });

  useEffect(() => {
    Events.on('update-insurance-data', 'update-insurance-data', () => requestIP());
    return () => Events.remove('update-insurance-data');
  }, []);

  useEffect(() => {
    if (profileId || updateResponse) {
      requestIP();
    }
    if (updateResponse) {
      requestInsuranceData({ patientId: params?.id, includeinactive: isHideActive });
    }
  }, [profileId, updateResponse]);

  const insurancePolicyArray = useMemo(() => getInsurancePolicyArray(ipData, isHideActive),
    [ipData, isHideActive]);

  const setDefaultProfile = useCallback((event) => {
    const { insuranceProfileId, patientId } = ipData;
    updateIP({ insuranceProfileId, patientId, isDefault: event.target.checked });
  }, [ipData, updateIP]);

  if (ipLoading || ipUpdateLading) return <Loader />;

  return (
    <>
      <div className="default-profile-checkbox">
        <Checkbox
          checked={ipData?.isDefault}
          onChange={setDefaultProfile}
          valuePropName="checked"
          disabled={!isUpdatePatientAuthenticated}
        >
          {labels.get('titles.defaultProfile')}
        </Checkbox>
      </div>
      <ViewResponsibleParty
        data={ipData?.responsibleParty}
        profileName={ipData?.profileName}
        createdBy={ipData?.createdByName}
        createdOn={ipData?.createdOn}
        modifiedBy={ipData?.modifiedByName}
        modifiedOn={ipData?.modifiedOn}
        labels={labels}
        showResponsibleParty={(ipData?.relationName) && ipData.relationName.toLowerCase() !== 'self'}
      />
      {insurancePolicyArray
        && insurancePolicyArray.map((insurance) => (
          <ViewInsurancePolicy
            selectedData={insurance}
            labels={labels}
            key={insurance && insurance.insuranceProfileDetailId}
          />
        ))}
    </>
  );
}

export default WithLabel(InsuranceInformation, labelPaths.VIEW_INSURANCE_PROFILE);
