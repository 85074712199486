import React from 'react';

import TimeCell from '../../../../../../components/Cells/TimeCell';

const columns = (labels) => [
  {
    Header: labels.get('tableColumns.claimDate'),
    accessor: 'claimDate',
    Cell: ({ row: { original } }) => (
      <TimeCell value={original?.postedDate?.dateString} />
    ),
    sort: true,
  },
  {
    Header: labels.get('tableColumns.status'),
    accessor: 'stageMasterName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.number'),
    accessor: 'claimNo',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.payer'),
    accessor: 'insurancePayerName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.provider'),
    accessor: 'providerName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.location'),
    accessor: 'locationName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.amount'),
    accessor: 'amount',
    Cell: ({ row: { original: { amount } } }) => (
      (amount || 0).toFixed(2)
    ),
    className: 'text-align-right',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.value'),
    accessor: 'valueBalance',
    Cell: ({ row: { original: { valueBalance } } }) => (
      (valueBalance || 0).toFixed(2)
    ),
    className: 'text-align-right',
    sort: true,
  },
];

export default columns;
