import React, {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { Form as AntdForm } from 'antd';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

import { useSelector } from 'react-redux';
import {
  UiRoutes, formId, labelPaths, listId, listIds,
} from '../../../lib/constants';
import SuccessMessages from '../../../lib/successMessages';
import formFieldValueParser from '../../../lib/formFieldValuesParser';

import useCRUD from '../../../hooks/useCRUD';
import useRedirect from '../../../hooks/useRedirect';
import useTabLink from '../../../hooks/useTabLink';

import Notification from '../../../components/Notification';
import Button from '../../../components/Button';
import Form from '../../../components/Form';
import Loader from '../../../components/Loader';
import ConfirmDialog from '../../../components/ConfirmDialog';
import Icon from '../../../components/Icon';

import PatientInformation from '../../Dashboard/Tabs/Demographic/PatientInformation';
import { apiUrls } from '../../../api/constants';
import WithLabel from '../../../hoc/withLabel';
import { getName } from '../../../lib/util';
import { getTableDataById } from '../../../store/selectors';
import ErrorMessages from '../../../lib/errorMessages';
import useReduxState from '../../../hooks/useReduxState';

const AddPatient = ({ labels }) => {
  const [form] = AntdForm.useForm();
  const addDuplicatePatientRef = useRef(false);
  const [patientCareTeamsDataCopy, setPatientCareTeamsDataCopy] = useReduxState('patient-demographic-patientCareTeamsDataCopy');

  const [businessData, , ,
    getBusinessDetail] = useCRUD({
    id: listIds.BUSINESS_ENTITIES,
    url: apiUrls.GET_BUSINESS_ENTITY,
    type: 'read',
  });

  const businessEntityList = useSelector((state) => getTableDataById(state,
    listIds.BUSINESS_ENTITIES)?.data || null);

  const parentId = useMemo(() => {
    if (businessEntityList?.length || businessData?.result?.length) {
      const tempData = businessEntityList || businessData?.result;
      for (let i = 0; i < tempData?.length; i += 1) {
        if (!tempData[i]?.parentEntityId) return tempData[i]?.businessEntityId;
      }
    }
    return null;
  }, [businessData, businessEntityList]);

  const [data, , loading, getDetail] = useCRUD({
    id: `${apiUrls.GET_BUSINESS_ENTITY_PATIENT_BASICS}/${parentId}`,
    url: `${apiUrls.GET_BUSINESS_ENTITY_PATIENT_BASICS}/${parentId}`,
    type: 'read',
  });

  useEffect(() => {
    if (!businessEntityList && !parentId) {
      getBusinessDetail();
    }
  }, [businessEntityList, getBusinessDetail, parentId]);

  useEffect(() => {
    if (data && !Array.isArray(data)) {
      form.setFieldsValue({ ...data, PreferredContactMethodId: data.contactMethodId });
    }
  }, [data]);

  useEffect(() => {
    if (parentId) {
      getDetail();
    }
  }, [parentId]);

  const { push } = useRedirect();
  const { navigate } = useTabLink({
    to: UiRoutes.editPatientWithTabId,
    finalRedirectPath: (path) => path.replace('dashboard', 'demographic'),
    // Added above function to maintain tab
    // uniqueness from patient list as well because patient list
    // redirect use to dashboard and this page requires redirection on demographic so that why
    // I am doing this hack. (Ramesh Bansal)
    params: { tab: 'dashboard', data: { name: '' } },
  });

  const parser = useCallback((patientInfo) => {
    const parsedValues = formFieldValueParser(patientInfo, {
      int: [
        'primaryPhoneExt',
        'secondaryPhoneExt',
      ],
    });
    const parsedDateOfBirth = moment(patientInfo?.dateOfBirth || '').format('MM-DD-YYYY');

    const filteredData = [];
    if (patientCareTeamsDataCopy.length) {
      patientCareTeamsDataCopy.forEach((item, index) => {
        const physician = patientCareTeamsDataCopy[index];
        if (physician?.providerName) filteredData.push(physician);
      });
    }
    if (addDuplicatePatientRef.current) {
      parsedValues.ProceedIfDuplicate = addDuplicatePatientRef.current;
    }
    parsedValues.patientCareTeams = filteredData;
    parsedValues.dateOfBirth = parsedDateOfBirth;
    parsedValues.label = !isEmpty(parsedValues.label) ? parsedValues?.label?.join(',') : undefined;
    return parsedValues;
  }, [patientCareTeamsDataCopy]);

  const navigateToForm = useCallback(() => push(UiRoutes.patients), [push]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.PATIENT_SAVED_SUCCESSFULLY, success: true });
      navigate({
        id: response.patientId || response.id,
        data: {
          name: getName(form.getFieldValue('firstName'), form.getFieldValue('middleName'), form.getFieldValue('lastName')),
        },
      });
    }
  }, [form, navigate]);

  const onRequestFail = useCallback((message) => {
    if (message?.[0] === ErrorMessages.PATIENT_ALREADY_EXISTS) {
      ConfirmDialog({
        onOk: (close) => {
          addDuplicatePatientRef.current = true;
          form.submit();
          close();
        },
        onCancel: (close) => {
          close();
        },
        okText: 'Continue',
        title: 'Warning',
        content: ErrorMessages.PATIENT_ALREADY_EXISTS,
        icon: <Icon name="ExclamationCircleOutlined" />,
        cancelButtonProps: { className: 'ant-modal-continue-green-btn' },
        okButtonProps: { className: 'ant-modal-ok-white-btn' },
      })();
    } else if (message?.length) {
      Notification({ message: message?.[0], success: false });
    }
  }, [form]);

  return (
    <div className="main-content-area">
      {loading && <Loader /> }
      <Form
        form={form}
        section
        formId={formId.ADD_PATIENT}
        url={apiUrls.ADD_PATIENT}
        parser={parser}
        onRequestComplete={onRequestComplete}
        onRequestFail={onRequestFail}
        scrollToFirstErrors
        listId={listId.PATIENTS}
        initialValues={{
          isActive: true,
          primaryPhoneType: 'Home',
          secondaryPhoneType: 'Home',
        }}
      >
        <div className="heading-area flex justify-content-sp-bt align-center">
          <h2>Add Patient</h2>
          <div className="group-btns">
            <Button className="btn min-wt-86 sm-btn" id="patients_add_cancel" onClick={navigateToForm}>{labels.get('buttons.cancel')}</Button>
            <Button className="btn btn-success min-wt-86 sm-btn" id="patients_add_save" type="submit">{labels.get('buttons.save')}</Button>
          </div>
        </div>
        <div className="main-form-container">
          <div className="add-patient-cl">
            <PatientInformation
              form={form}
              labels={labels}
              isAddForm
              patientCareTeamsDataCopy={patientCareTeamsDataCopy}
              setPatientCareTeamsDataCopy={setPatientCareTeamsDataCopy}

            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default WithLabel(AddPatient, labelPaths.ADD_PATIENT);
