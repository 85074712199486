export const GET_ENUM_FIELD_DATA = 'GET_ENUM_FIELD_DATA';
export const SET_ENUM_FIELD_DATA = 'SET_ENUM_FIELD_DATA';
export const GET_ENUM_MASTER_DATA = '@enum/GET_ENUM_MASTER_DATA';
export const SET_ENUM_MASTER_DATA = '@enum/SET_ENUM_MASTER_DATA';
export const SET_ERROR = '@enum/SET_ERROR';
export const SET_LOADING = '@enum/SET_LOADING';

export const getEnumMasterData = (enumId, parentId) => ({
  type: GET_ENUM_FIELD_DATA,
  enumId,
  parentId,
});

export const setEnumFieldData = (enumId, parentId, data) => ({
  type: SET_ENUM_FIELD_DATA,
  enumId,
  parentId,
  data,
});

export const setEnumData = (enums) => ({
  type: SET_ENUM_MASTER_DATA,
  enums,
});

export const getEnumData = () => ({
  type: GET_ENUM_MASTER_DATA,
});

export const setError = (error) => ({
  type: SET_ERROR,
  error,
});

export const setLoading = (flag) => ({
  type: SET_LOADING,
  flag,
});
