import React, { useEffect, useMemo } from 'react';
import get from 'lodash/get';
import moment from 'moment';

import { apiUrls } from '../../../../../../api/constants';
import { listIds, labelPaths } from '../../../../../../lib/constants';
import Events from '../../../../../../lib/events';
import { formatPhoneNumber, getString, getUTCDate } from '../../../../../../lib/util';
import WithLabel from '../../../../../../hoc/withLabel';

import useRedirect from '../../../../../../hooks/useRedirect';
import Table from '../../../../../../components/Table';

import columns from './columns';
import './overview.scss';
import useCRUD from '../../../../../../hooks/useCRUD';
import Loader from '../../../../../../components/Loader';
import useReduxStoreWithId from '../../../../../../hooks/useReduxStoreWithId';

const ClaimOverview = ({
  labels, claimDetail, billingEncounterData, billingClaimedAmount,
}) => (
  <div className="pannel mr-top-16 panel-border">
    <div className="pannel-heading">{labels.get('titles.claimInformation')}</div>
    <div className="pannel-body">
      <div className="overview-panel-details">
        <div className="row">
          <div className="col">
            <div className="key">{labels.get('labels.claimNumber')}</div>
            <div className="value" data-testid="claimNumber">{get(claimDetail, 'claimNo', '')}</div>
          </div>
          <div className="col">
            <div className="key">{labels.get('labels.patientName')}</div>
            <div className="value">{get(billingEncounterData, 'patient.patientFullName', '')}</div>
          </div>
          <div className="col">
            <div className="key">MRN</div>
            <div className="value">{get(billingEncounterData, 'patient.chartNumber', '')}</div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="key">{labels.get('labels.claimSubmissionDate')}</div>
            <div className="value" data-testid="claimSubmissionDate">
              {get(claimDetail, 'claimSubmissionDate.dateString', '')
               && moment(get(claimDetail, 'claimSubmissionDate.dateString', '')).format('MM-DD-YYYY')}
            </div>
          </div>
          <div className="col">
            <div className="key">{labels.get('labels.patientDOB')}</div>
            <div className="value">{get(billingEncounterData, 'patient.dateOfBirth.dateString', '') && moment(get(billingEncounterData, 'patient.dateOfBirth.dateString', '')).format('MM-DD-YYYY')}</div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="key">
              {labels.get('labels.claimBilledAmount')}
            </div>
            <div className="value" data-testid="billingClaimedAmount">
              {`$ ${billingClaimedAmount?.toFixed(2)}`}
            </div>
          </div>
          <div className="col">
            <div className="key">{labels.get('labels.patientSSN')}</div>
            <div className="value" data-testid="patientSSN">{get(billingEncounterData, 'patient.ssn')}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const ProviderOverview = ({ labels, billingEncounterData }) => (
  <div className="panel-wrapper">
    <div className="pannel panel-border mr-top-8 mr-rt-16">
      <div className="pannel-heading">{labels.get('labels.renderingProvider')}</div>
      <div className="pannel-body">
        <div className="overview-panel-details">
          <div className="row">
            <div className="col">
              <div className="key">{labels.get('labels.name')}</div>
              <div className="value">
                {getString([get(billingEncounterData, 'provider.providerFirstName'),
                  get(billingEncounterData, 'provider.providerMiddleName'),
                  get(billingEncounterData, 'provider.providerLastName')])}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="key">{labels.get('labels.nPI')}</div>
              <div className="value" data-testid="renderingProviderNPI">{get(billingEncounterData, 'provider.providerNPI')}</div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="key">{labels.get('labels.stateLicenseNumber')}</div>
              <div className="value" data-testid="stateLicenseNumber">{get(billingEncounterData, 'provider.licenseNumber')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="pannel panel-border mr-top-8">
      <div className="pannel-heading">{labels.get('labels.billingProvider')}</div>
      <div className="pannel-body">
        <div className="overview-panel-details">
          <div className="row">
            <div className="col">
              <div className="key">{labels.get('labels.name')}</div>
              <div className="value">
                {get(billingEncounterData, 'encounterDetails.businessEntityName', '')}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="key">{labels.get('labels.nPI')}</div>
              <div className="value" data-testid="billingProviderNPI">
                {get(billingEncounterData, 'encounterDetails.businessEntityNpi', '')}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="key">{labels.get('labels.employerIdentification')}</div>
              <div className="value" data-testid="employerIdentification">
                {get(billingEncounterData, 'encounterDetails.credentials', '')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
const Overview = ({ labels }) => {
  const {
    params: {
      claimNumber,
      tabId,
    },
  } = useRedirect();
  const [claimDetail,, claimLoading, getClaimsData] = useCRUD({ id: `${listIds.CLAIM_DETAILS}-${tabId}`, url: apiUrls.GET_CLAIM_DETAILS, type: 'read' });
  const [, , setClaimServiceLineItemData] = useReduxStoreWithId({ id: `claim-service-line-item-${tabId}` });

  useEffect(() => {
    Events.on('refetch-claim-overview', 'refetch-claim-overview', () => {
      getClaimsData({}, `/${claimNumber}`);
    });
    return () => Events.remove('refetch-claim-overview');
  }, []);

  useEffect(() => {
    if (claimDetail) {
      const denialData = {};
      claimDetail.billingEncounterData?.serviceLineItems?.forEach((item = {}) => {
        denialData[item.billingEncounterServiceLineId] = item.denialReasonId;
      });
      setClaimServiceLineItemData(denialData);
    }
  }, [claimDetail]);

  const { billingEncounterData } = claimDetail || {};
  const billingClaimedAmount = useMemo(() => billingEncounterData?.serviceLineItems?.reduce(
    (acc, { amount }) => acc + amount, 0), [billingEncounterData]);

  const denialReasons = useMemo(
    () => billingEncounterData?.serviceLineItems?.reduce((acc, item) => {
      if (item?.status?.toLowerCase() !== 'posted' && item?.status?.toLowerCase() !== 'closed') {
        if (item?.denialReasonId) {
          if (acc[item.denialReasonId]) {
            acc[item.denialReasonId] = { ...acc[item.denialReasonId], cptCode: `${acc[item.denialReasonId]?.cptCode},${item?.cptCode}` };
          } else {
            acc[item.denialReasonId] = {
              cptCode: item?.cptCode,
              denialName: item?.claimDetails?.denialName,
              denialType: item?.claimDetails?.denialType,
              denialReason: item?.claimDetails?.denialReason,
              denialTypeId: item?.denialReasonId,
            };
          }
        } else {
          acc[item.claimDetails.denialReason] = {
            denialReason: item?.claimDetails?.denialReason,
            cptCode: acc[item.claimDetails.denialReason]?.cptCode
              ? `${acc[item.claimDetails.denialReason]?.cptCode},${item?.cptCode}` : item?.cptCode,
          };
        }
      }
      return acc;
    }, {}), [billingEncounterData],
  );
  return (
    <div>
      {claimLoading && <Loader />}
      <div className="tab-content-wrapper">
        <div className="overview-panel">
          <div>
            <h3>{labels.get('titles.denialReason')}</h3>
            {Object.values(denialReasons || {})?.map((reason) => (reason.denialTypeId ? (
              <p>
                {getString([reason?.denialType,
                 reason?.denialName, reason?.denialReason, reason?.cptCode], '-')}
              </p>
            ) : <p>{getString([reason?.denialReason, reason?.cptCode], '-')}</p>))}
          </div>
        </div>
        <ClaimOverview
          labels={labels}
          claimDetail={billingEncounterData?.serviceLineItems?.[0]?.claimDetails}
          billingEncounterData={billingEncounterData}
          billingClaimedAmount={billingClaimedAmount}
        />
        <ProviderOverview
          labels={labels}
          billingEncounterData={billingEncounterData}
        />
        <div className="panel-wrapper">
          <div className="pannel panel-border mr-top-8 mr-rt-16">
            <div className="pannel-heading">
              <div className="header-with-icon">
                <span>Payer</span>
                {/* <span className="edit-icon" /> */}
              </div>
            </div>
            <div className="pannel-body">
              <div className="overview-panel-details">
                <div className="row">
                  <div className="col">
                    <div className="key">{labels.get('labels.name')}</div>
                    <div className="value">{`${get(billingEncounterData, 'insurancePayer.insurancePayerName')}`}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="key">{labels.get('labels.address')}</div>
                    <div className="value" data-testid="insurancePayerAddress">{get(billingEncounterData, 'insurancePayer.payerAddress')}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="key">{labels.get('labels.phoneNumber')}</div>
                    <div className="value">{formatPhoneNumber(get(billingEncounterData, 'insurancePayer.payerPhone'))}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="key">{labels.get('labels.deliveryMethod')}</div>
                    <div className="value">{get(billingEncounterData, 'insurancePayer.deliveryMethodName')}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="key">{labels.get('labels.serialNumber')}</div>
                    <div className="value">{get(billingEncounterData, 'insurance.payerId')}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pannel mr-top-8 panel-border">
            <div className="pannel-heading">{labels.get('labels.policy')}</div>
            <div className="pannel-body">
              <div className="overview-panel-details">

                <div className="row">
                  <div className="col">
                    <div className="key">{labels.get('labels.memberNumber')}</div>
                    <div className="value" data-testid="memberNumber">
                      {get(billingEncounterData, 'insurance.memberNumber')}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="key">{labels.get('labels.orderNumber')}</div>
                    <div className="value">{get(billingEncounterData, 'insurance.groupNumber')}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="key">{labels.get('labels.groupName')}</div>
                    <div className="value" data-testid="groupName">{get(billingEncounterData, 'insurance.memberNumber')}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="key">{labels.get('labels.policyHolder')}</div>
                    <div className="value">{get(billingEncounterData, 'insurancePayer.deliveryMethodName')}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="key">{labels.get('labels.relationshipToPatient')}</div>
                    <div className="value" data-testid="relationShipToPatient">{get(billingEncounterData, 'insurance.relationshipToPatient')}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pannel panel-border mr-top-8 service-panel">
          <div className="pannel-heading">{labels.get('titles.service')}</div>
          <div className="pannel-body">
            <div className="overview-panel-details">
              <div className="row">
                <div className="col">
                  <div className="key">{labels.get('labels.dos')}</div>
                  <div className="value" data-testid="serviceDate">{get(billingEncounterData, 'encounterDetails.serviceDateString.dateString', '') && moment(get(billingEncounterData, 'encounterDetails.serviceDateString.dateString', '')).format('MM-DD-YYYY')}</div>
                </div>
                <div className="col">
                  <div className="key">{labels.get('labels.location')}</div>
                  <div className="value">{get(billingEncounterData, 'location.locationName')}</div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="key">{labels.get('labels.placeOfServiceCode')}</div>
                  <div className="value" data-testid="placeOfServiceName">{get(billingEncounterData, 'location.placeOfServiceName')}</div>
                </div>
                <div className="col">
                  <div className="key">{labels.get('labels.referringPhysician')}</div>
                  <div className="value" data-testid="referringPhysicianName">{get(billingEncounterData, 'referringPhysicianNPI.referringPhysicianName')}</div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="key">{labels.get('labels.authorization')}</div>
                  <div className="value">{get(billingEncounterData, 'authorization.authorizationName')}</div>
                </div>
                <div className="col">
                  <div className="key">{labels.get('labels.referringPhysicianNPI')}</div>
                  <div className="value" data-testid="referringPhysicianNPI">{get(billingEncounterData, 'referringPhysicianNPI.referringPhysicianNPI')}</div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="key">{labels.get('labels.reservedField')}</div>
                  <div className="value" />
                </div>
                <div className="col">
                  <div className="key" />
                  <div className="value" />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="key">{labels.get('labels.lastSeenDate')}</div>
                  <div className="value">{getUTCDate(billingEncounterData?.serviceLineItems?.[0]?.serviceDate, 'MM-DD-YYYY')}</div>
                </div>
                <div className="col">
                  <div className="key">{labels.get('labels.hospitalization')}</div>
                  <div className="value" />
                </div>
              </div>
            </div>
            <div className="service-table">
              <Table
                columns={columns()}
                data={get(billingEncounterData, 'serviceLineItems', [])}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WithLabel(Overview,
  labelPaths.CLAIM_OVERVIEW);
