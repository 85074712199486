import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { clearData, setReadData } from '../store/actions/reduxStoreWithId';

export const selector = (state, id) => {
  if (!id) {
    return null;
  }
  if (state.reduxStoreWithId) {
    if (state.reduxStoreWithId.get('currentTab')) {
      return state.reduxStoreWithId.get(state.reduxStoreWithId.get('currentTab')) && state.reduxStoreWithId.get(state.reduxStoreWithId.get('currentTab'))
        .get(id) && state.reduxStoreWithId.get(state.reduxStoreWithId.get('currentTab'))
        .get(id);
    }
    return state.reduxStoreWithId.get(id);
  }
  return true;
};

export default function useReduxStoreWithId({ id }) {
  const dispatch = useDispatch();
  const data = useSelector((state) => selector(state, id));

  const set = useCallback((newData) => {
    dispatch(setReadData(id, newData));
  }, [id, dispatch]);

  const clear = useCallback(() => {
    dispatch(clearData(id));
  }, [dispatch, id]);

  return [data, null, set, clear];
}
