import React, { useCallback } from 'react';
import { Form as Antd } from 'antd';

import { apiUrls } from '../../../../api/constants';
import {
  UiRoutes, formId, labelPaths, listId,
} from '../../../../lib/constants';
import formFieldValueParser from '../../../../lib/formFieldValuesParser';
import SuccessMessages from '../../../../lib/successMessages';

import Notification from '../../../../components/Notification';
import Form from '../../../../components/Form';
import Button from '../../../../components/Button';
import useRedirect from '../../../../hooks/useRedirect';
import useTabLink from '../../../../hooks/useTabLink';
import withLabel from '../../../../hoc/withLabel';

import GeneralForm from '../Components/GeneralForm';

const parser = (userInfo) => {
  const parsedValues = formFieldValueParser(userInfo, {
    int: [
      'primaryPhoneExt',
      'secondaryPhoneExt',
      'zip',
      'scriptsureId',
    ],
    date: ['dateOfBirth', 'dateOfSendInvitation'],
    bool: ['isActive'],
  });
  return { ...parsedValues, locationIds: parsedValues?.locationIds?.join(','), roleIds: parsedValues?.roleIds?.join(',') };
};

function AddUser({ labels }) {
  const [form] = Antd.useForm();
  const { push } = useRedirect();
  const { navigate } = useTabLink({ to: UiRoutes.editUserWithTabId, params: { tab: 'general', data: { name: '' } } });
  const navigateToForm = useCallback(() => push(UiRoutes.users), [push]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.USER_SAVED_SUCCESSFULLY, success: true });
      navigate({
        id: response?.userResponse?.id,
        data: {
          name: form.getFieldValue('firstName'),
        },
      });
    }
  }, [navigate, form]);

  return (
    <Form
      form={form}
      scrollToFirstError
      formId={formId.ADD_USER}
      parser={parser}
      url={apiUrls.ADD_USER}
      onRequestComplete={onRequestComplete}
      listId={listId.SETTINGS_USERS}
    >
      <div className="main-content-area">
        <div className="heading-area flex justify-content-sp-bt align-center">
          <h1>{labels.get('titles.addUsers')}</h1>
          <div className="group-btns">
            <Button className="btn min-wt-86" onClick={navigateToForm} id="users_cancel" data-testid="users_cancel">{labels.get('buttons.cancel')}</Button>
            <Button className="btn btn-success min-wt-86" type="submit" id="users_save" data-testid="users_save">{labels.get('buttons.save')}</Button>
          </div>
        </div>
        <div className="main-form-container">
          <GeneralForm
            form={form}
            labels={labels}
          />
        </div>
      </div>
    </Form>
  );
}

export default withLabel(AddUser, labelPaths.ADD_USER);
