import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  requestRead, requestCreate, requestUpdate, clearData, requestDelete, clearReadData,
} from '../store/actions/crudWithoutTab';

const selector = (state, id, type) => {
  if (!id) {
    return null;
  }
  return state.crudWithoutTab.get(id) && state.crudWithoutTab.get(id).get(type);
};

export default function useCRUDWithoutTab({
  id, url, type,
}) {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [isClearCalled, setClearCalled] = useState(null);
  const response = useSelector((state) => selector(state, id, type));
  const op = {};
  /* Function to make read request to server for type READ */
  op.read = useCallback((params = {}, extraURL = '', newId = '') => {
    if (newId) setError(`This component has been changed, new id is not longer supported. ID: ${newId}`);
    setClearCalled(false);
    dispatch(requestRead(id, `${url}${extraURL}`, params));
  }, [id, url, dispatch]);
  /* ******************* */
  /* Function to make read request to server for type CREATE */
  op.create = useCallback((params = {}, extraURL = '', newId = '', cacheResponse = false) => {
    if (newId) setError(`This component has been changed, new id is not longer supported. ID: ${newId}`);
    setClearCalled(false);
    dispatch(requestCreate(id, `${url}${extraURL}`, params, cacheResponse));
  }, [id, url, dispatch]);
  /* ******************* */
  /* Function to make read request to server for type UPDATE */
  op.update = useCallback((params = {}, extraURL = '', newId = '', cacheResponse = false) => {
    if (newId) setError(`This component has been changed, new id is not longer supported. ID: ${newId}`);
    setClearCalled(false);
    dispatch(requestUpdate(id, `${url}${extraURL}`, params, cacheResponse));
  }, [id, url, dispatch]);
  /* ******************* */
  /* Function to make read request to server for type DELETE */
  op.delete = useCallback((params = {}, extraURL = '', newId = '') => {
    if (newId) setError(`This component has been changed, new id is not longer supported. ID: ${newId}`);
    setClearCalled(false);
    dispatch(requestDelete(id, `${url}${extraURL}`, params));
  }, [id, url, dispatch]);
  /* ******************* */
  useEffect(() => {
    if (response && !isClearCalled) {
      if (response.get('error')) {
        const { message } = response.get('error');
        setError(response.get('error')?.response?.data?.Errors?.[0] || message);
      }
    }
  }, [response, isClearCalled]);
  const clear = useCallback((read) => {
    setClearCalled(true);
    setError(null);
    dispatch(clearData(id));
    if (read) dispatch(clearReadData(id));
  }, [dispatch, id]);
  return [response?.get('data'), error, !!response?.get('loading'), op[type], clear];
}
