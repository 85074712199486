import React, { useState, useCallback } from 'react';
import { useReactToPrint } from 'react-to-print';

import Loader from '../../../components/Loader';
import Icon from '../../../components/Icon';
import iconNames from '../../../lib/iconNames';
import ConfirmDialog from '../../../components/ConfirmDialog';

import DocxStyles from './DocxStyle';
import useDocx from '../../../hooks/useDocx';

const Docx = ({
  path, onDelete, collapsableView, ...otherProps
}) => {
  const container = useDocx(path);

  const [scale, setScale] = useState(0.7);

  const handlePrint = useReactToPrint({
    content: () => container.current,
    copyStyles: false,
  });

  const incrementScale = useCallback(() => {
    setScale(scale + 0.10);
  }, [scale]);

  const decrementScale = useCallback(() => {
    setScale(scale - 0.10);
  }, [scale]);

  const resetScale = useCallback(() => {
    setScale(0.7);
  }, []);

  const onPrintClick = useCallback(() => {
    resetScale();
    handlePrint();
  }, [resetScale, handlePrint]);

  return (
    <>
      <div className="preview-image-tools">
        {otherProps?.isFax ? (
          <Icon
            title="Add to Fax Queue"
            name={iconNames.shopTwoTone}
            onClick={otherProps?.handleAddToFaxQueue}
          />
        ) : null}
        {!collapsableView && (
        <Icon
          title="Delete"
          name={iconNames.deleteIcon}
          onClick={ConfirmDialog({
            onOk: (close) => {
              onDelete();
              close();
            },
            title: 'Do you want to delete this item?',
            content: 'When clicked the OK button, item will be deleted permanently.',
            icon: <Icon name="ExclamationCircleOutlined" />,
          })}
        />
        )}
        <Icon title="Print" name={iconNames.print} onClick={onPrintClick} />
        <Icon title="Reset Size" onClick={resetScale} name={iconNames.undo} />
        <Icon title="Zoom In" onClick={incrementScale} name={iconNames.plusIcon} />
        <Icon title="Zoom Out" onClick={decrementScale} name={iconNames.minus} />
      </div>
      <DocxStyles>
        <div
          className="document-container"
          ref={container}
          style={{ zoom: scale }}
        >
          <Loader />
        </div>
      </DocxStyles>
    </>
  );
};

export default Docx;
