import React, { useCallback } from 'react';
import classNames from 'classnames';

import { PolicySubMenuItem } from './profileSubmenu';

const getAbb = (name) => {
  if (name) {
    return name[0].toUpperCase();
  }
  return 'N/A';
};

const PolicyList = (props) => {
  const {
    profileId, navigateToAddNewPolicy, policiesData = [], policyLevel = [],
    patientId, moveItem, isUpdatePatientAuthenticated,
  } = props;
  const policyLevelValue = policyLevel
        && policyLevel[policiesData && policiesData.length]
        && policyLevel[policiesData && policiesData.length].masterName;

  const policyLevelKey = policyLevel
        && policyLevel[policiesData && policiesData.length]
        && policyLevel[policiesData && policiesData.length].masterId;
  return (
    <>
      {policiesData && policiesData.length ? policiesData.map((data, index) => (
        <PolicySubMenuItem
          title={`(${getAbb(data.profileType)}) ${data.policyType}`}
          policyId={data.insuranceProfileDetailId}
          classKey={`${'policy-edit-'}${data.profileTypeId}`}
          key={`${'policy-edit-'}${data.insuranceProfileDetailId}`}
          className={classNames({ 'policy-invalid-wrapper': data?.isValid === false })}
          prevPolicyTypeId={policiesData?.[index - 1]?.profileTypeId || null}
          nextPolicyTypeId={policiesData?.[index + 1]?.profileTypeId || null}
          patientId={patientId}
          moveItem={moveItem}
          isUpdatePatientAuthenticated={isUpdatePatientAuthenticated}
          isValidProfile={data?.isValid}
          verificationStatus={data?.verificationStatus}
          lastVerificationDate={data?.lastVerificationDate}
          {...props}
        />
      )) : ''}
      {
        (policyLevel.length > policiesData.length)
        && (
        <AddPolicyLink
          profileId={profileId}
          navigateToAddNewPolicy={navigateToAddNewPolicy}
          title={`Add ${policyLevelValue} Policy`}
          policyLevelId={policyLevelKey}
          isUpdatePatientAuthenticated={isUpdatePatientAuthenticated}
        />
        )
      }
    </>
  );
};

const AddPolicyLink = ({
  profileId, navigateToAddNewPolicy, title, policyLevelId, isUpdatePatientAuthenticated,
}) => {
  const onAddPolicy = useCallback((e) => {
    e.preventDefault();
    navigateToAddNewPolicy(profileId, policyLevelId);
  }, [navigateToAddNewPolicy, policyLevelId, profileId]);
  return (
    <a
      href=" "
      style={!isUpdatePatientAuthenticated ? { cursor: 'not-allowed' } : {}}
      onClick={(e) => (isUpdatePatientAuthenticated ? onAddPolicy(e) : e.preventDefault())}
    >
      {title}
    </a>
  );
};

export default PolicyList;
