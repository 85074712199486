import React, { useCallback, useState } from 'react';
import { Form as AntdForm } from 'antd';

import { apiUrls } from '../../../../../api/constants';
import Button from '../../../../../components/Button';
import Form from '../../../../../components/Form';
import TextInput from '../../../../../components/Form/Input';
import Radio from '../../../../../components/Form/Radio';
import Modals from '../../../../../components/Modal';
import { formId } from '../../../../../lib/constants';
import WiredSelect from '../../../../../wiredComponents/Select';
import Notification from '../../../../../components/Notification';

const locationOptions = [
  { label: 'All Location', name: 'AllLocation', value: true },
  { label: 'Specific Location', name: 'SpecificLocation', value: false },
];

const UserLocationModal = ({
  isVisible, toggleModal, selectedPermissionSetData,
  setSelectedPermissionSetData, getRoleUserLocation,
}) => {
  const [form] = AntdForm.useForm();
  const [locationValue, setLocationValue] = useState(true);

  const onChangeLocation = useCallback((e) => {
    setLocationValue(e.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    form.submit();
  }, [form]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: response?.message, success: true });
      toggleModal();
      setSelectedPermissionSetData({});
      getRoleUserLocation();
    }
  }, [getRoleUserLocation, setSelectedPermissionSetData, toggleModal]);

  const parser = useCallback((data) => {
    const newValues = data;
    if (locationValue === true) {
      newValues.isAllLocationSelected = true;
    }
    return {
      ...newValues,
      permissionSetId: selectedPermissionSetData?.permissionSetId,
    };
  }, [locationValue, selectedPermissionSetData]);

  return (
    <Modals
      visible={isVisible}
      toggleModal={toggleModal}
      width={450}
      destroyOnClose
      className="provider-location-ui add-child-role-modal"
      footer={[
        <div className="group-btns" key="footer">
          <Button
            className="btn min-wt-86"
            onClick={toggleModal}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-success min-wt-86 inline"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>,
      ]}
    >
      <div className="addition-header">
        <div className="lookup sprite-img-before">
          <p>Add Child Role</p>
        </div>
      </div>
      <div className="modal-content-weapper shadow-wrap fields-wrapper">
        <div className="modal-from-feilds">
          <Form
            form={form}
            formId={formId.ADD_CHILD_ROLE}
            url={apiUrls.ADD_USER_LOCATION_CHILD_ROLE}
            onRequestComplete={onRequestComplete}
            parser={parser}
            initialData={{ addlocationId: true }}
          >
            <Form.Column>
              <TextInput
                name="roleName"
                label="Name"
                labelSpan="8"
                inputSpan="16"
                placeholder="Enter Name"
                maxValueLength={50}
                required
              />
              <Radio
                required
                label="Locations"
                name="addlocationId"
                labelSpan="8"
                inputSpan="16"
                options={locationOptions}
                onChange={onChangeLocation}
                valuePropName="checked"
                radioProps={{
                  value: locationValue,
                }}
              />
              {locationValue === false && (
                <div className="hide-label">
                  <WiredSelect
                    id="location"
                    name="locationIds"
                    className="default-value-as-placeholder"
                    url={apiUrls.SCHEDULAR_LOCATION_DROPDOWN}
                    placeholder="All Locations"
                    nameAccessor="locationName"
                    valueAccessor="locationId"
                    startCaseOptions
                    selectProps={{
                      showSearch: true,
                      showArrow: true,
                      mode: 'multiple',
                      maxTagCount: 'responsive',
                    }}
                    labelSpan="8"
                    inputSpan="16"
                    label="Location"
                    required
                  />
                </div>
              )}
            </Form.Column>
          </Form>
        </div>
      </div>
    </Modals>
  );
};

export default UserLocationModal;
