const columns = (labels) => [
  {
    Header: labels.get('tableColumns.accountReason'),
    accessor: 'remittanceReasonName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.totalItems'),
    accessor: 'totalItems',
    sort: true,
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.totalBatches'),
    accessor: 'totalBatches',
    sort: true,
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.totalAmount'),
    accessor: 'totalAmount',
    className: 'text-align-right',
    Cell: ({ row: { original: { totalAmount } } }) => (
      parseFloat(totalAmount || 0).toFixed(2)
    ),
    sort: true,
  },
];

export default columns;
