import React from 'react';
import { Tooltip } from 'antd';
import moment from 'moment';
import { getShieldIconClassName, VerificationStatusForTooltip } from '../../../../lib/util';

const InsuranceVerificationShieldIcon = ({
  verificationStatus, lastVerificationDate,
}) => (verificationStatus ? (
  <Tooltip title={lastVerificationDate && (
    <div>
      <span>Last Verified:</span>
      <br />
      <span>{moment(lastVerificationDate).format('MM-DD-YYYY hh:mm A')}</span>
      <br />
      <span>{`Status: ${VerificationStatusForTooltip[verificationStatus]}`}</span>
    </div>
  )}
  >
    <span
      className={getShieldIconClassName(verificationStatus)}
    />
  </Tooltip>
) : null
);

export default InsuranceVerificationShieldIcon;
