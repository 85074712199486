import React from 'react';
import { apiUrls } from '../../../../../api/constants';
import { listIds } from '../../../../../lib/constants';

import withQuery from '../../../../../hoc/withQuery/withQuery';
import withReduxManager from '../../../../../hoc/withReduxManager';

import FilterManager from '../../../../../components/FilterManager';
import Button from '../../../../../components/Button';

import columns from './columns';
import useRights from '../../../../../hooks/useRights';
import rights from '../../../../../lib/rights';

const CustomPatientEncounterTable = withReduxManager({
  listId: listIds.PATIENT_ENCOUNTER_LIST,
})(withQuery({
  url: apiUrls.GET_ENCOUNTER_LIST,
  listId: listIds.PATIENT_ENCOUNTER_LIST,
})());

const initialSort = [{ id: 'dateofservice', desc: true }];

const EncounterTable = ({
  labels, id, navigateToForm, navigateToViewEncounter, ...props
}) => {
  const [isEnterBillingChargesPermitted] = useRights([rights.enter_charges]);
  return (
    <CustomPatientEncounterTable
      columns={columns(labels, navigateToViewEncounter)}
      filters={{ PatientId: id, CalledFromPatientEncounter: true }}
      initialSort={initialSort}
      pageSize={30}
      {...props}
    >
      {({
        Component, totalCount,
      }) => (
        <FilterManager>
          {() => (
            <>
              <div className="table-filters mr-bt-8 newEncounter-btn">
                <div className="group-btns mb-8">
                  {isEnterBillingChargesPermitted ? (
                    <Button id="patients_encounters_new" className="btn btn-success sm-btn" onClick={navigateToForm}>
                      {labels.get('buttons.newEncounter')}
                    </Button>
                  ) : <span />}
                </div>
                <span className="table-count newEncounter-btn">
                  {`Total Count: ${totalCount}`}
                </span>
              </div>
              {Component}
            </>
          )}
        </FilterManager>
      )}
    </CustomPatientEncounterTable>
  );
};

export default EncounterTable;
