import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';

import isFunction from 'lodash/isFunction';
import debounce from 'lodash/debounce';
import { useSelector } from 'react-redux';
import Input from '../../../../components/Form/Input';

import { enums as enumConstant } from '../../../../lib/constants';
import { getDateString } from '../../../../lib/util';
import useEntityDetail from '../../../../hooks/usePatientDetail';
import { apiUrls } from '../../../../api/constants';
import useCRUD from '../../../../hooks/useCRUD';
import { getEnumMaster, getEnumOptions } from '../../../../store/selectors';
import TaskRightSection from './TaskRightSection';
import TaskLeftSection from './TaskLeftSection';
import useCrudWithEffects from '../../../../hooks/useCrudWithEffects';
import useReduxStoreWithId from '../../../../hooks/useReduxStoreWithId';
import Activity from './Activity';

const dueDateDisableFunc = (current) => current && (current <= moment().subtract(1, 'days'));

const TaskForm = ({
  labels,
  form,
  patientId,
  onAddComment,
  comments,
  taskId,
  formData,
  setFileUpdate,
  setDeletedTaskFiles,
  deletedTaskFile,
  selectedProcedures,
  setSelectedProcedures,
  selectedRows, setSelectedRow,
  appointmentDetail,
  appointmentResponse,
  changeTaskFields,
}) => {
  const [subjectId, setSubjectId] = useState({});
  const [patient] = useEntityDetail({ patientId });
  const subCategoryAssignedUserRef = useRef({});
  const [savedSelectedValue,, setSelectedValue] = useReduxStoreWithId({ id: `gridautocomplete_${apiUrls.USER_SUGGESTIVE_SEARCH}_assignedTo` });
  const setProviderSelectedValue = useReduxStoreWithId({ id: `gridautocomplete_${apiUrls.GET_PROVIDERS}_providerId` })[2];
  const setLocationSelectedValue = useReduxStoreWithId({ id: `gridautocomplete_${apiUrls.GET_LOCATIONS}_locationId` })[2];
  const selectedValue = savedSelectedValue?.get('data');

  const responseCallback = useCallback((response = {}, isCached) => {
    if (!isCached) subCategoryAssignedUserRef.current[response.taskSubCategoryId] = response;
    form.setFieldsValue({ assignedTo: response?.defaultAssigneeId });
    setSelectedValue({
      ...response,
      name: response?.defaultAssigneeName,
      value: response?.defaultAssigneeId,
    });
  }, [form, setSelectedValue]);

  const [getAssignedUserDetails] = useCrudWithEffects({
    id: 'task-assigned-user-in-sub-category',
    url: apiUrls.GET_TASK_SUB_CATEGORY_DETAILS,
    type: 'read',
    callBack: responseCallback,
  });

  const priorityIds = useSelector((state) => {
    const enumId = getEnumMaster(state)?.[enumConstant?.TASK_PRIORITY_IDS]?.enumId;
    const priorities = {};
    const { data = [] } = getEnumOptions(state)?.[enumId] || [];
    data.forEach((enumOption) => {
      priorities[enumOption?.masterCode?.toUpperCase()] = enumOption?.masterId;
    });
    return priorities;
  });

  const [insuranceDetail,,,
    getInsuranceDetail,
  ] = useCRUD({ id: 'insurance-detail-task', url: apiUrls.GET_PATIENT_INSURANCE, type: 'read' });

  const onFileUpload = useCallback(() => {
    if (isFunction(setFileUpdate)) setFileUpdate(true);
  }, [setFileUpdate]);

  const calculatePriority = useCallback((date, rangeFrom, rangeTo) => {
    if (appointmentDetail?.appointmentId && appointmentDetail?.status?.toLowerCase()?.trim() === 'checkin') {
      form.setFieldsValue({ taskPriorityId: priorityIds.PRIORITY });
    } else if (rangeFrom && date.diff(moment(), 'hours') <= rangeFrom) {
      form.setFieldsValue({ taskPriorityId: priorityIds.PRIORITY });
    } else if (date.diff(moment(), 'hours') <= rangeTo) {
      form.setFieldsValue({ taskPriorityId: priorityIds.URGENT });
    } else {
      form.setFieldsValue({ taskPriorityId: priorityIds.STANDARD });
    }
  }, [appointmentDetail, form, priorityIds.PRIORITY, priorityIds.STANDARD, priorityIds.URGENT]);

  const handleDueDateChange = useCallback(() => {
    const date = form.getFieldValue('dueDate');
    const categoryName = form.getFieldValue('taskCategoryName');
    if (date && categoryName?.length) {
      if (categoryName.toLowerCase() === 'authorization') {
        calculatePriority(date, 72, 168);
      } else calculatePriority(date, 0, 48);
    }
  }, [calculatePriority, form]);

  const onInsuranceSelect = useCallback((value, allItem) => {
    if (allItem?.item?.insuranceProfileId) {
      getInsuranceDetail({}, `/${allItem?.item?.insuranceProfileId}`);
    }
  }, [getInsuranceDetail]);

  useEffect(() => {
    if (appointmentResponse) {
      getInsuranceDetail({}, `/${appointmentResponse?.insuranceId}`);
    }
  }, [appointmentResponse]);

  const onDeleteTaskFile = useCallback((deletedFile) => {
    const { taskDocumentId } = deletedFile;
    if (taskDocumentId) {
      setDeletedTaskFiles([...deletedTaskFile, taskDocumentId]);
    }
  }, [setDeletedTaskFiles, deletedTaskFile]);

  const onCategoryChange = useCallback((value, allItem) => {
    setSubjectId(allItem?.item);
    form.setFieldsValue({ taskCategoryName: allItem?.item?.name?.toLowerCase() });
    handleDueDateChange();
  }, [form, handleDueDateChange]);

  useEffect(() => {
    if (patient && !taskId) {
      setTimeout(() => {
        form.setFieldsValue({
          chartNumber: patient.chartNumber,
          patientName: patient?.fullName,
          dob: patient?.dateOfBirth ? moment(getDateString(patient.dateOfBirth)) : undefined,
        });
      }, 0);
    }
  }, [patient, form, taskId]);

  useEffect(() => {
    if (formData && Object.keys(formData).length) {
      setSubjectId({ value: formData?.categoryId, name: formData?.categoryName });
      form.setFieldsValue({ taskCategoryName: formData?.categoryName?.toLowerCase() });
      setSelectedValue({
        name: formData?.assignedToName,
        value: formData?.assignedTo,
      });
      setProviderSelectedValue({
        name: formData?.providerName,
        value: formData?.providerId,
      });
      setLocationSelectedValue({
        name: formData?.locationName,
        value: formData?.locationId,
      });
    }
  }, [form, formData]);

  useEffect(() => {
    if (insuranceDetail?.insuraceProfileDetail?.[0]) {
      const { insuraceProfileDetail } = insuranceDetail;
      form.setFieldsValue({
        insuranceNumber: insuraceProfileDetail?.[0]?.insuranceProfileDetailId,
        policyTypeId: insuraceProfileDetail?.[0]?.policyTypeId,
        insurancePhoneNumber: insuraceProfileDetail?.[0]?.payerPrimaryPhone,
        insurancePayerName: insuraceProfileDetail?.[0]?.payerName,
      });
    } else if (insuranceDetail?.insuranceProfileType?.toLowerCase()?.trim() === 'self' && insuranceDetail?.responsibleParty) {
      form.setFieldsValue({
        insuranceNumber: insuranceDetail?.insuranceProfileId,
        insurancePhoneNumber: insuranceDetail?.responsibleParty?.primaryPhone,
        insurancePayerName: undefined,
      });
    }
  }, [insuranceDetail]);

  const onDosChange = useCallback((value) => {
    form.setFieldsValue({ dueDate: value });
    handleDueDateChange();
  }, [form, handleDueDateChange]);

  const onChangeSubCategory = useCallback(debounce((value) => {
    if (!value) return null;
    if (!subCategoryAssignedUserRef.current[value]) {
      subCategoryAssignedUserRef.current[value] = {};
      return getAssignedUserDetails({}, `/${value}`);
    }
    return responseCallback(subCategoryAssignedUserRef.current[value], true);
  }, 500), []);

  return (
    <Row gutter={16}>
      <Col span={24}>
        <div style={{ display: 'none' }}>
          <Input name="taskCategoryName" />
        </div>
        <div className="tasks-modal-form-sections-wrap">
          <TaskLeftSection
            labels={labels}
            onCategoryChange={onCategoryChange}
            subjectId={subjectId}
            onDosChange={onDosChange}
            form={form}
            taskId={taskId}
            formData={formData}
            handleDueDateChange={handleDueDateChange}
            dueDateDisableFunc={dueDateDisableFunc}
            onChangeSubCategory={onChangeSubCategory}
            assignedToUserInitialValue={selectedValue}
          />
          <TaskRightSection
            labels={labels}
            patientId={patientId}
            onInsuranceSelect={onInsuranceSelect}
            formData={formData}
            appointmentResponse={appointmentResponse}
            subjectId={subjectId}
            taskId={taskId}
            form={form}
            onFileUpload={onFileUpload}
            onDeleteTaskFile={onDeleteTaskFile}
            selectedProcedures={selectedProcedures}
            setSelectedProcedures={setSelectedProcedures}
            selectedRows={selectedRows}
            setSelectedRow={setSelectedRow}
            patient={patient}
            insuranceInitialValue={{
              insuranceProfileId: formData?.insuranceProfileId
              || appointmentResponse?.insurancedetailId || appointmentResponse?.insuranceId,
              insuranceProfileDetailId: formData?.insuranceProfileDetailId
              || appointmentResponse?.insuranceProfileName,
            }}
            changeTaskFields={changeTaskFields}
          />
        </div>
      </Col>
      { (subjectId?.name?.toLowerCase() !== 'prescription refill') && (
        <Col span={24}>
          <Activity
            taskId={taskId}
            onAddComment={onAddComment}
            labels={labels}
            comments={comments}
          />
        </Col>
      )}
    </Row>
  );
};

export default React.memo(TaskForm);
