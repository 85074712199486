import React, { useState, useCallback, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { Form } from 'antd';

import Events from '../../../lib/events';
import rights from '../../../lib/rights';

import useRights from '../../../hooks/useRights';

import Modal from '../../../components/Modal';
import Search from '../../../components/Search';
import Button from '../../../components/Button';
import Smcheckbox from '../../../components/SmCheckbox';

import AddPatient from '../../Schedular/BookAppointment/Components/AddPatient';
import PatientTable from '../../../wiredComponents/PatientTable';

import './PatientListModal.scss';

const PatientListModal = ({
  labels,
  isPatientListVisible,
  togglePatientList,
  onRowClick,
  search,
  setSearch,
}) => {
  const [isExclude, setExclude] = useState(true);
  const [isNewPatient, setNewPatient] = useState(false);

  const [form] = Form.useForm();

  const debounced = useMemo(() => debounce(setSearch, 1500), [setSearch]);

  const handleSave = useCallback(() => form.submit(), [form]);
  const reFetch = useCallback(() => Events.trigger('refetchModalPatientList'), []);
  const handleExclude = useCallback(({ target: { checked } }) => setExclude(checked), []);
  const handleNewPatient = useCallback(() => setNewPatient(!isNewPatient), [isNewPatient]);
  const handleSearch = useCallback(({ target: { value } }) => debounced(value), [debounced]);
  const [isCreatePatientAuthenticated] = useRights([rights.create_new_patient]);

  return (
    <Modal
      visible={isPatientListVisible}
      toggleModal={togglePatientList}
      title={isNewPatient ? 'Add Patient' : 'Patient List'}
      width={1200}
      afterClose={() => { setNewPatient(false); }}
      destroyOnClose
      footer={isNewPatient ? [
        <div className="group-btns" key="footer">
          <Button className="btn min-wt-86" onClick={handleNewPatient}>{labels.get('buttons.cancel')}</Button>
          <Button className="btn btn-success min-wt-86 inline" onClick={handleSave}>{labels.get('buttons.save')}</Button>
        </div>,
      ] : null}
    >
      {isNewPatient ? (
        <div className="modal-content-weapper shadow-wrap">
          <div className="modal-from-feilds">
            <AddPatient
              reFetch={reFetch}
              form={form}
              toggleModal={handleNewPatient}
              shouldNavigate={false}
            />
          </div>
        </div>
      ) : (
        <div className="patient-list-modal">
          <div className="header-wrapper">
            <div className="filter-wrapper">
              <Search
                className="patent-list-search"
                onChange={handleSearch}
                placeholder="Search"
              />
              <Smcheckbox
                isFormItem={false}
                checked={isExclude}
                onChange={handleExclude}
                className="exclude-filter"
              >
                {labels.get('excludeInactive')}
              </Smcheckbox>
            </div>
            {isCreatePatientAuthenticated && (
              <Button
                className="btn btn-success sm-btn"
                onClick={handleNewPatient}
              >
                {labels.get('newPatient')}
              </Button>
            )}
          </div>
          <PatientTable
            labels={labels}
            onRowClick={onRowClick}
            filters={{ SearchText: search?.trim(), ExcludeInactivePatients: isExclude }}
          />
        </div>
      )}
    </Modal>
  );
};

export default PatientListModal;
