import React from 'react';

const locationOptionRenderer = (options = []) => {
  if (!options.length) return [];
  return [{
    label: (
      <div className="option-header-main-wrapper">
        <div className="option headerWrapper">
          <span className="header">
            Name
          </span>
          <span className="header">
            Address
          </span>
        </div>
      </div>
    ),
    options: options && options.map((item) => (
      {
        value: item.locationName,
        item,
        label: (
          <div className="option">
            <span>
              {item.locationName}
            </span>
            <span>
              {item.addressLine1}
            </span>
          </div>
        ),
      }

    )),
  }];
};

export default locationOptionRenderer;
