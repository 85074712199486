import React from 'react';

import TimeCell from '../../../../../components/Cells/TimeCell';
import Button from '../../../../../components/Button';

const columns = [
  {
    Header: 'Payer Name',
    sort: true,
    fixWidth: '120',
    accessor: 'insurancePayerName',
    Cell: ({
      row: {
        original: {
          insuranceProfileDetail,
        },
      },
    }) => <div>{insuranceProfileDetail?.insurancePayerName}</div>,
  },
  {
    Header: 'Health Plan Verified',
    accessor: 'healthPlan',
    Cell: ({
      row: { original },
    }) => <span>{original?.insuranceProfileDetail?.healthPlanPayerName}</span>,
    sort: true,
    fixWidth: '150',
  },
  {
    Header: 'Date/Time',
    accessor: 'modifiedOn',
    sort: true,
    Cell: ({ row: { original: { modifiedOn } } }) => <TimeCell value={modifiedOn} format="MM-DD-YYYY hh:mm A" />,
  },
  {
    Header: 'Verification Trigger',
    accessor: 'source',
    sort: true,
  },
  {
    Header: 'Status',
    accessor: 'insuranceStatus',
    sort: true,
  },
  {
    Header: 'Action',
    accessor: '6',
    width: '60px',
    Cell: ({ row: { original }, handleResponseMethod }) => (
      <Button
        type="button"
        className="sm-btn btn-success"
        onClick={() => handleResponseMethod(original)}
      >
        Response

      </Button>
    ),
  },
];

export default columns;

