import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { UiRoutes } from '../../../../../../lib/constants';

import Edit from '../../../../../Dashboard/withoutTabs/documents/Edit';
import ViewEncounter from '../../../../../Dashboard/withoutTabs/encounters/EncounterDetails';

import NewEncounter from '../../../../../../wiredComponents/AddNewEncounter';
import NewPatientAdjustment from '../../../../../../wiredComponents/Adustment/Components/PatientAdjustment';
import NewInsuranceAdjustment from '../../../../../../wiredComponents/Adustment/Components/InsuranceAdjustment';

import Tabs from './Tabs';

import '../../../../../Dashboard/dashboard.scss';

function EditPatient() {
  return (
    <div className="main-form-container">
      <Switch>
        <Route>
          <Tabs />
        </Route>
        <Route exact path={UiRoutes.editPatientDocumentWithTabId}>
          <Edit />
        </Route>
        <Route path={UiRoutes.viewEncounterWithId}>
          <ViewEncounter />
        </Route>
        <Route path={UiRoutes.newEncounterWithTabId}>
          <NewEncounter />
        </Route>
        <Route path={UiRoutes.newPatientAdjustmentWithTabId}>
          <NewPatientAdjustment />
        </Route>
        <Route path={UiRoutes.newInsuranceAdjustmentWithTabId}>
          <NewInsuranceAdjustment />
        </Route>
      </Switch>
    </div>

  );
}

export default EditPatient;
