import React from 'react';
import moment from 'moment';
import TimeCell from '../../../../../../components/Cells/TimeCell';

const readyColumns = (labels) => [
  {
    Header: labels.get('tableColumns.patientName'),
    accessor: 'patientName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.payer'),
    accessor: 'insurancePayerName',
    sort: true,
  },

  {
    Header: labels.get('tableColumns.policyType'),
    accessor: 'insurancePolicyTypeName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.type'),
    accessor: 'profileTypeName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.profileType'),
    accessor: 'insuranceProfileCategoryName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.dos'),
    accessor: 'billingservicelineitemcreateddate.timestemp',
    Cell: (e) => <TimeCell value={e?.row.original?.billingServiceLineItemCreatedDate?.dateString} format="MM-DD-YYYY" />,
    sort: true,
  },
  {
    Header: labels.get('tableColumns.provider'),
    accessor: 'providerName',
  },
  {
    Header: labels.get('tableColumns.location'),
    accessor: 'locationName',
  },
  {
    Header: labels.get('tableColumns.enteredOn'),
    accessor: 'postedDate.dateString',
    Cell: (e) => <TimeCell value={moment.utc(e?.row.original?.postedDate?.dateString).local()} format="MM-DD-YYYY" />,
    sort: true,
  },
  {
    Header: labels.get('tableColumns.format'),
    accessor: 'format',
    Cell: ({ row: { original: { deliveryMethodName } } }) => <span>{deliveryMethodName?.split('/')?.[0]}</span>,
  },
  {
    Header: labels.get('tableColumns.deliveryMethod'),
    accessor: 'deliveryMethodName',
    Cell: ({ row: { original: { deliveryMethodName } } }) => <span>{deliveryMethodName?.split('/')?.[1]}</span>,
  },
  {
    Header: labels.get('tableColumns.amount'),
    accessor: 'amount',
    Cell: ({ row: { original: { amount } } }) => (
      (amount || 0).toFixed(2)
    ),
    thClassName: 'align-right-side',
    sort: true,
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.balance'),
    accessor: 'balance',
    thClassName: 'align-right-side',
    Cell: ({ row: { original: { balance } } }) => (
      (balance || 0).toFixed(2)
    ),
    sort: true,
    className: 'text-align-right',
  },
];

export default readyColumns;
