import React, {
  useCallback, useState, useEffect, useMemo,
} from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import useCRUD from '../../../../../../../../hooks/useCRUD';

import { apiUrls } from '../../../../../../../../api/constants';
import ErrorMessages from '../../../../../../../../lib/errorMessages';
import SuccessMessages from '../../../../../../../../lib/successMessages';
import WarningMessages from '../../../../../../../../lib/warningMessages';

import Icon from '../../../../../../../../components/Icon';
import Button from '../../../../../../../../components/Button';
import Notification from '../../../../../../../../components/Notification';
import Loader from '../../../../../../../../components/Loader';
import ConfirmDialog from '../../../../../../../../components/ConfirmDialog';

import ModifierComponent from './ModifierComponent';
import ModifierLabel from './ModifierLabel';

import { validator, onChangeData, setFocusToFields } from './util';

const EditModifierRow = ({
  rowData,
  key,
  labels,
  setEditRow,
  editRow,
  setPreviousRow,
  previousRow,
  onUpdateRecord,
  rowIndex,
  form,
  onDeleteRecord,
  parentFocusId,
}) => {
  const [modifier, setModifierData] = useState(rowData);
  const modifierExceptionId = useMemo(() => rowData.contractTermModifierExceptionId, [rowData]);
  const [isEdit, setEdit] = useState(false);
  const [dataError, setError] = useState({});

  const [response, , loading, updateModifier, clearUpdate] = useCRUD({ id: `modifier-update-${modifierExceptionId}`, url: apiUrls.UPDATE_CONTRACT_TERMS_MODIFIER, type: 'update' });
  const [deleteResponse, , deleteLoading, deleteModifier, clearDelete] = useCRUD({ id: `modifier-delete-${modifierExceptionId}`, url: apiUrls.DELETE_CONTRACT_TERMS_MODIFIER, type: 'delete' });

  const onUpdateModifier = useCallback(() => {
    const isError = validator(modifier);
    if (isEmpty(isError)) {
      updateModifier({
        ...modifier,
      }, `/${modifier.contractTermModifierExceptionId}`);
    } else {
      setError(isError);
      setFocusToFields(isError, parentFocusId);
      Notification({ message: ErrorMessages.PLEASE_FILL_ALL_REQUIRED_FIELDS, success: true });
    }
  }, [modifier, updateModifier, parentFocusId]);

  useEffect(() => {
    if (response && !isArray(response)) {
      Notification({ message: SuccessMessages.MODIFIER_UPDATED_SUCCESSFULLY, success: true });
      onUpdateRecord({ ...modifier }, rowIndex);
      clearUpdate();
    }
  }, [response, clearUpdate, modifier, onUpdateRecord, rowIndex]);

  const onDeleteModifier = useCallback(() => {
    deleteModifier({}, `/${modifier.contractTermModifierExceptionId}`);
  }, [modifier, deleteModifier]);

  useEffect(() => {
    if (deleteResponse && !isArray(deleteResponse)) {
      Notification({ message: SuccessMessages.MODIFIER_DELETED_SUCCESSFULLY, success: true });
      onDeleteRecord(rowIndex);
      clearDelete();
    }
  }, [deleteResponse, clearDelete, onDeleteRecord, rowIndex]);

  const onChange = useCallback((label) => (event) => {
    const { modifierData, resetError } = onChangeData({
      label, event, modifier, dataError,
    });
    setModifierData(modifierData);
    if (Object.keys(resetError).length) setError(resetError);
  }, [setModifierData, setError, dataError, modifier]);

  const onEdit = useCallback(() => {
    if (editRow !== null) {
      ConfirmDialog({
        onOk: (close) => {
          setEditRow(rowData.contractTermModifierExceptionId);
          setPreviousRow(editRow);
          close();
        },
        title: 'Warning',
        content: WarningMessages.LOST_CHANGES,
        icon: <Icon name="ExclamationCircleOutlined" />,
      })();
    } else {
      setEditRow(rowData.contractTermModifierExceptionId);
    }
  }, [editRow, setEditRow]);

  const onClear = useCallback(() => {
    ConfirmDialog({
      onOk: (close) => {
        setModifierData({ ...rowData });
        setEditRow(null);
        setPreviousRow(null);
        setError({});
        close();
      },
      title: 'Warning',
      content: WarningMessages.LOST_CHANGES,
      icon: <Icon name="ExclamationCircleOutlined" />,
    })();
  }, [rowData, setModifierData, setEditRow]);

  useEffect(() => {
    const isRowEdit = editRow === rowData.contractTermModifierExceptionId;
    if ((isRowEdit && !isEdit) || (!isRowEdit && isEdit)) setEdit(isRowEdit);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editRow]);

  useEffect(() => {
    if (rowData.contractTermModifierExceptionId === previousRow) {
      setModifierData({ ...rowData });
      setError({});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousRow]);

  useEffect(() => {
    setModifierData({ ...rowData });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData]);

  return (
    <tr key={key}>
      {(loading || deleteLoading) && <Loader />}
      {isEdit && (
      <ModifierComponent
        onChange={onChange}
        modifier={modifier}
        form={form}
        labels={labels}
        isEdit={isEdit}
        dataError={dataError}
        onDeleteModifier={onDeleteModifier}
        parentFocusId={parentFocusId}
      />
      )}
      {!isEdit && (
        <ModifierLabel
          onChange={onChange}
          modifier={modifier}
          onDeleteModifier={onDeleteModifier}
        />
      )}
      <td>
        <div className="flex">
          {!isEdit && <Button className="btn btn-success sm-btn" onClick={onEdit}>{labels.get('buttons.edit')}</Button>}
          {isEdit && get(modifier, 'contractTermModifierExceptionId') && (
            <>
              <Button className="btn btn-success sm-btn" onClick={onUpdateModifier}>{labels.get('buttons.update')}</Button>
              <Button className="mr-lt-5 sm-btn" onClick={onClear}>{labels.get('buttons.clear')}</Button>
            </>
          )}
        </div>
      </td>
    </tr>
  );
};

export default EditModifierRow;
