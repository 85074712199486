import React, { useCallback, useEffect } from 'react';

import useCRUD from '../../../../hooks/useCRUD';
import WithLabel from '../../../../hoc/withLabel';
import useRedirect from '../../../../hooks/useRedirect';
import useReduxState from '../../../../hooks/useReduxState';

import { apiUrls } from '../../../../api/constants';
import { labelPaths, listIds, UiRoutes } from '../../../../lib/constants';

import Notification from '../../../../components/Notification';
import WidgetLoader from '../../../../components/WidgetLoader';
import RadioGroup from '../../../../components/Form/RadioGroup';
import Button from '../../../../components/Button';

import BillsList from './BillsList';
import BillDetails from './BillDetails';

const tabList = (labels) => [
  { value: 'BillsList', label: labels.get('labels.claims') },
  { value: 'BillDetails', label: labels.get('labels.statements') },
];

function List({ labels }) {
  const {
    params, push, generatePath,
  } = useRedirect();
  const { id: patientId, listTab = 'BillsList' } = params;
  const [ActiveComponent, setActiveComponent] = useReduxState(`patient-dashboard-bills-${patientId}`, listTab);

  const [manualStatementResponse,,
    manualStatementLoading, getManualStatements, clearManualStatements] = useCRUD({
    id: listIds.CREATE_MANUAL_STATEMENT,
    url: apiUrls.GENERATE_MANUAL_STATEMENT,
    type: 'create',
  });

  useEffect(() => {
    if (manualStatementResponse) {
      Notification({ message: manualStatementResponse?.message, success: true });
    }
    return () => { clearManualStatements(); };
  }, [manualStatementResponse]);

  const tabSwitchHandler = useCallback(({ target: { value } }) => {
    setActiveComponent(value);
    push(generatePath(UiRoutes.patientListWithListTabId, { ...params, listTab: value }));
  }, [generatePath, push, params]);

  const handleManualStatement = useCallback(() => {
    getManualStatements({ data: {} }, `/${patientId}`);
  }, [getManualStatements, patientId]);

  return (
    <div className="lists-wrap">
      {manualStatementLoading && <WidgetLoader />}
      <div className="flex justify-content-sp-bt mr-btm-6">
        <RadioGroup
          tabList={tabList(labels)}
          id="patients_bills"
          onChange={tabSwitchHandler}
          value={ActiveComponent}
        />
        {ActiveComponent === 'BillDetails'
        && <Button className="btn sm-btn btn-success" onClick={handleManualStatement}>{labels.get('buttons.manualStatement')}</Button>}
      </div>
      {ActiveComponent === 'BillsList' && (
        <BillsList
          labels={labels}
          patientId={patientId}
        />
      )}
      {ActiveComponent === 'BillDetails' && (
        <BillDetails
          labels={labels}
          patientId={patientId}
        />
      )}
    </div>
  );
}

export default WithLabel(List, labelPaths.PATIENT_BILLS);
