import React, { useCallback, useState } from 'react';
import { Form as AntdForm } from 'antd';

import Notification from '../../../../../../../components/Notification';
import Button from '../../../../../../../components/Button';
import Form from '../../../../../../../components/Form';

import { apiUrls } from '../../../../../../../api/constants';
import { formId } from '../../../../../../../lib/constants';
import SuccessMessages from '../../../../../../../lib/successMessages';
import formFieldValuesParser from '../../../../../../../lib/formFieldValuesParser';
import useRedirect from '../../../../../../../hooks/useRedirect';

import GeneralForm from './GeneralForm';

const parser = (values) => formFieldValuesParser(values, {
  bool: [
    'isRoundFee',
  ],
  int: [
    'multiplier',
  ],
});

const General = ({
  labels, isProviderViewOnly,
}) => {
  const [form] = AntdForm.useForm();

  const {
    params, params: { id, tabId }, path, replace, generatePath,
  } = useRedirect();

  const [data, setData] = useState();

  const onRequestComplete = useCallback(({ response }) => {
    if (response && response.feePreferencesGeneralId) {
      Notification({
        message: SuccessMessages.FEE_PREFERENCES_GENERAL_UPDATED_SUCCESSFULLY,
        success: true,
      });
    }
  }, []);

  const onGetResponseComplete = useCallback((response) => {
    setData(response);
  }, []);

  const onCancel = useCallback(() => {
    replace(generatePath(path, { ...params, tab: 'general' }));
  }, [generatePath, params, path, replace]);

  return (
    <div className="">
      <Form
        section
        form={form}
        parser={parser}
        onRequestComplete={onRequestComplete}
        onGetResponseComplete={onGetResponseComplete}
        getUrl={`${apiUrls.GET_FEE_PREFERENCE_GENERAL}/${id}`}
        url={`${apiUrls.UPDATE_FEE_PREFERENCE_GENERAL}/${id}`}
        formId={formId.UPDATE_PROVIDER_FEE_PREFERENCE_GENERAL}
        name={formId.UPDATE_PROVIDER_FEE_PREFERENCE_GENERAL}
        isUpdate
        editKey={id}
        extraData={{
          providerId: parseInt(id, 10),
        }}
        tabId={tabId}
        initialData={{
          isRoundFee: true,
          isBillingYear: 0,
          isFacility: 0,
          isLocality: 0,
        }}
        disableForm={isProviderViewOnly}
      >
        <GeneralForm data={data} labels={labels} form={form} />
        <Form.Section>
          <div className="btns-action">
            <Button id="provider_feePreferences_general_save" className="btn btn-success min-wt-86" type="submit">{labels.get('buttons.save')}</Button>
            <Button id="provider_feePreferences_general_cancel" className="btn" onClick={onCancel}>{labels.get('buttons.cancel')}</Button>
          </div>
        </Form.Section>
      </Form>
    </div>
  );
};

export default General;
