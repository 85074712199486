import CryptoJS from 'crypto-js';

const encryptData = (data) => {
  const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_LOGIN_ENCRYPTION_KEY);
  const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_LOGIN_ENCRYPTION_IV);
  return CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), key,
    {
      keySize: 128 / 8,
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
};

export default encryptData;
