import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';

import iconMap from './icon.json';

const { TabPane } = Tabs;

const BlankComponent = () => <div />;

const TabLeft = ({
  tabList = [],
  id,
  component,
  nameAccessor,
  valueAccessor,
  componentDataAccessor,
  handleTabChange,
  ...props
}) => {
  const [activeTabValue, setActiveTabValue] = useState(tabList[0] && tabList[0][valueAccessor]);

  useEffect(() => {
    if (tabList[0]) {
      handleTabChange(tabList[0][valueAccessor]);
      setActiveTabValue(`${tabList[0][valueAccessor]}`);
    }
  }, [tabList]);

  const onChange = (activeTabKey) => {
    setActiveTabValue(activeTabKey);
    if (handleTabChange) {
      handleTabChange(activeTabKey);
    }
  };
  return (
    <Tabs
      tabPosition="left"
      onChange={onChange}
      activeKey={activeTabValue}
      id={id}
    >
      {tabList && tabList.length && tabList.map((item) => {
        const name = item[nameAccessor] || '';
        const iconClassName = iconMap[item[nameAccessor]] || '';
        const TabComponent = (item.component || component) || BlankComponent;
        return (
          <TabPane
            tab={(
              <div className={iconClassName}>
                {name}
              </div>
            )}
            key={item[valueAccessor]}
            style={{ marginBottom: 0 }}
          >
            <TabComponent data={item[componentDataAccessor]} {...props} />
          </TabPane>
        );
      })}
    </Tabs>
  );
};

TabLeft.defaultProps = {
  nameAccessor: 'name',
  valueAccessor: 'key',
};

export default TabLeft;
