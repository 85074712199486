import {
  takeLatest, put, call, all, select,
} from 'redux-saga/effects';
import { batchActions } from 'redux-batched-actions';
import { fromJS } from 'immutable';
import {
  setError,
  setLoading,
  setRedirectKey,
  setSelectedProvider,
  setUpdatedProviderInStore,
  ADD_PROVIDER,
  GET_SELECTED_PROVIDER,
  UPDATE_PROVIDER,
  setUpdate,
} from '../actions/providers';
import { mergeUpdatedData, logoutHandler } from './util';
import { formName } from '../../lib/constants';
import api from '../../api';
import { apiUrls } from '../../api/constants';
import { clearFormData } from '../actions/form';

/* *******Selectors to access state ******** */
const getProvider = (state) => state.providers;
const getFormData = (state) => state.form;
/* **************************************** */

const getProviderIndex = (
  providers, providerId,
) => providers.findIndex((element) => element.id === parseInt(providerId, 10));

const addProvider = (providersData, providersState) => new Promise((resolve, reject) => {
  try {
    /* Below LocalStorage work will replace with server call */
    const clonedState = fromJS(providersState);
    const newProviderRecord = { lastModified: new Date(), lastModifiedBy: 'Loretta Rois', ...providersData };
    const providers = clonedState.get('providers');
    providers.unshift(newProviderRecord);
    setTimeout(() => {
      resolve(newProviderRecord.id);
    }, 500);
  } catch (error) {
    reject(error);
  }
});

const getCurrentProvider = (currentProviderId, providersState) => new Promise((resolve, reject) => {
  try {
    const clonedState = fromJS(providersState);
    const providers = clonedState.get('providers');
    const providersIndex = getProviderIndex(providers, currentProviderId);
    const providerData = providers[providersIndex];
    setTimeout(() => {
      resolve({ provider: providerData });
    }, 500);
  } catch (error) {
    reject(error);
  }
});

const updateProvider = (updatedProviderData, providersState) => new Promise((resolve, reject) => {
  try {
    const clonedState = fromJS(providersState);
    const providers = clonedState.get('providers');
    const updatedProvidersIndex = getProviderIndex(providers, updatedProviderData.id);
    providers[updatedProvidersIndex] = updatedProviderData;
    clonedState.set('providers', providers);
    setTimeout(() => {
      resolve({ updatedProviderData });
    }, 500);
  } catch (error) {
    reject(error);
  }
});

/* ******************************************************************** */
/* ****************************PROVIDERS SAGA******************************* */
/* ******************************************************************** */

export const addProviderSaga = function* ({ payload: providersPayload }) {
  yield put(setLoading(true));
  try {
    const providers = yield select(getProvider);
    const { providerId } = yield call(api.post, { data: providersPayload, url: apiUrls.ADD_PROVIDER, token: localStorage.getDecryptedData('token') });
    // eslint-disable-next-line no-param-reassign
    providersPayload.id = providerId;
    yield call(addProvider, providersPayload, providers);
    yield put(batchActions([
      setRedirectKey(providerId),
      setError(null),
    ]));
  } catch (e) {
    yield put(batchActions([logoutHandler(e),
      setError(e)]));
  } finally {
    yield put(setLoading(false));
  }
};

export const getCurrentProviderSaga = function* ({ providerId }) {
  yield put(setLoading(true));
  try {
    const providers = yield select(getProvider);
    const form = yield select(getFormData);
    const { provider } = yield call(getCurrentProvider, providerId, providers);
    const updatedProvider = yield call(mergeUpdatedData, provider, form, formName.providerForm);
    yield put(batchActions([
      setSelectedProvider(updatedProvider),
      setError(null),
    ]));
  } catch (e) {
    yield put(setError(e));
  } finally {
    yield put(setLoading(false));
  }
};

export const updateProviderSaga = function* ({ payload: updatedProviderPayload }) {
  yield put(setLoading(true));
  yield put(setUpdate(false));
  try {
    const providers = yield select(getProvider);
    const { updatedProviderData } = yield call(updateProvider, updatedProviderPayload, providers);
    yield put(batchActions([
      setUpdatedProviderInStore(updatedProviderData),
      setUpdate(true),
      setError(null),
      clearFormData(formName.providerForm),
    ]));
  } catch (e) {
    yield put(setError(e));
  } finally {
    yield put(setLoading(false));
  }
};

export default function* root() {
  yield all([
    takeLatest(ADD_PROVIDER, addProviderSaga),
    takeLatest(GET_SELECTED_PROVIDER, getCurrentProviderSaga),
    takeLatest(UPDATE_PROVIDER, updateProviderSaga),
  ]);
}
