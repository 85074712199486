import React, { useCallback } from 'react';
import { Row, Col, Form as AntdForm } from 'antd';
import moment from 'moment';

import {
  labelPaths,
  enums as enumConstant,
  enumMasterCode,
} from '../../../../../../lib/constants';
import useMasterId from '../../../../../../hooks/useMasterId';
import { toDateValidator } from '../../../../../../lib/validator';
import WithLabel from '../../../../../../hoc/withLabel';
import PolicyLookup from '../../../../../../wiredComponents/LookUp/PolicyLookup';
import PlanLookup from '../../../../../../wiredComponents/LookUp/PlanLookup';
import {
  Select,
  Radio as RadioBox,
} from '../../../../../../wiredComponents/Enum';
import WiredSelectWithoutTab from '../../../../../../wiredComponents/Select/selectBoxV2';
import {
  Input,
  DatePicker,
  CheckBox,
  Radio,
} from '../../../../../../components/Form/inputFields';
import { apiUrls } from '../../../../../../api/constants';

const claimOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const masterCodes = [
  enumMasterCode.Capitation_Visit_Arrangement_Per_Visit,
  enumMasterCode.Contract_Term_Plan_Type_Category_All,
  enumMasterCode.allPlacesOfService,
  enumMasterCode.Contract_Term_Type_Capitation,
  enumMasterCode.Contract_Term_Type_Fee_For_Service,
  enumMasterCode.contractSpecificPlacesOfService,
  enumMasterCode.contractTermsSpecificLocations,
];

const enumNames = [
  enumConstant.CAPITATION_PAYMENT_ARRANGEMENT,
  enumConstant.CONTRACT_TERM_PLAN_TYPE_CATEGORY,
  enumConstant.CONTRACT_TERM_PLACES_OF_SERVICE,
  enumConstant.CONTRACT_TERM_TYPE,
];

const newMasterCodes = [
  enumMasterCode.Contract_Term_Policy_Type_Category_All,
  enumMasterCode.Contract_Term_Policy_Type_Category_Specific,
];

const newEnumNames = [
  enumConstant.CONTRACT_TERM_POLICY_OF_CATEGORY,
];

const TermsGeneralForm = ({
  labels, form, termsTableResponse,
}) => {
  const masterCodesWithId = useMasterId(masterCodes, enumNames);
  const newMasterCodesWithId = useMasterId(newMasterCodes, newEnumNames);
  const planTypeMasterCodesWithId = useMasterId(['Specific'], [enumConstant.CONTRACT_TERM_PLAN_TYPE_CATEGORY]);
  const contractTermPlacesOfServicesWithId = useMasterId(['Specific Places of Service', 'Specific Location'], [enumConstant.CONTRACT_TERM_PLACES_OF_SERVICE]);
  const feeForServicePaymentAgreementsWithId = useMasterId(['Based on Fee Schedule', 'BasedOnRVU'], [enumConstant.FEE_FOR_SERVICES_PAYMENT_ARRANGEMENT]);
  const feeForServiceValueIfNoProcedure = useMasterId(['PercentageofBilledAmount', 'FeeScheduleType'], [enumConstant.FEE_FOR_SERVICE_VALUE_IF_NO_PROCEDURE]);

  const getSelectedOption = useCallback((name, value) => {
    let option = { [name]: value };
    if (name === 'placesOfServiceId') {
      option = {
        [name]: value === masterCodesWithId?.['All Places of Service'],
        specificPlacesOfServiceId: value === masterCodesWithId?.['Specific Places of Service'],
        specificLocationId: value === masterCodesWithId?.['Specific Location'],
      };
    } else if (name === 'feeForServiceValueIfNoProcedureId') {
      option = {
        [name]: value,
      };
    } else if (name === 'policyTypeId') {
      option = {
        [name]: value === newMasterCodesWithId?.All,
      };
    } else if (name === 'planId') {
      option = {
        [name]: value === masterCodesWithId?.All,
      };
    }
    return option;
  }, [masterCodesWithId, newMasterCodesWithId]);

  const onChange = useCallback((name) => ({ target: { value } }) => {
    const option = getSelectedOption(name, value);
    if (name === 'policyTypeId') {
      if (option?.policyTypeId) {
        form.setFieldsValue({ AppliedToAllPolicyTypes: true });
      } else {
        form.setFieldsValue({ AppliedToAllPolicyTypes: false });
      }
    }
    if (name === 'planId') {
      if (option?.planId) {
        form.setFieldsValue({ AppliedToAllPlans: true });
      } else {
        form.setFieldsValue({ AppliedToAllPlans: false });
      }
    }
    if (name === 'placesOfServiceId') {
      if (option?.placesOfServiceId) {
        form.setFieldsValue({ AppliedToAllPlacesOfService: true });
      } else {
        form.setFieldsValue({ AppliedToAllPlacesOfService: false });
      }
    }
  },
  [form, getSelectedOption]);

  const setSelectedPlans = useCallback((data) => {
    form.customSetFieldsValue({ selectedPlans: data });
  }, [form]);

  const setSelectedPolicies = useCallback((data) => {
    form.customSetFieldsValue({ selectedPolicies: data });
  }, [form]);

  const locationsOptions = useCallback((options) => {
    const parsedOptions = termsTableResponse?.selectedContractLocations?.map((item) => {
      const parsedLocation = { ...item };
      parsedLocation.value = item.locationId;
      parsedLocation.name = item.locationName;
      return parsedLocation;
    });
    return termsTableResponse?.appliedToAllLocations
      ? options : parsedOptions;
  },
  [termsTableResponse]);

  const placesOfServicesOptions = useCallback((options) => {
    if (!termsTableResponse?.appliedToAllLocations) {
      return termsTableResponse?.placeOfServices?.map((item) => {
        const parsedLocation = { ...item };
        parsedLocation.value = item?.masterId;
        parsedLocation.name = item?.masterName;
        return parsedLocation;
      });
    }
    return options;
  },
  [termsTableResponse]);

  return (
    <Row gutter={16}>
      <Col md={15}>
        <div className="group-shadow">
          <div style={{ display: 'none' }}>
            <Input name="contractTermId" />
          </div>
          <Row gutter={16}>
            <Col md={12}>
              <Input
                label={labels.get('labels.name')}
                maxValueLength={45}
                charOnly
                name="name"
                required
                labelSpan="7"
                inputSpan="17"
                placeholder={labels.get('placeholders.name')}
              />
              {/* <PayerLookup
                label={labels.get('titles.payerTypes')}
                inputSpan="17"
                labelSpan="7"
              /> */}
              <Select
                label={labels.get('labels.types')}
                labelSpan="7"
                inputSpan="17"
                name="typeId"
                required
                enumName={enumConstant.CONTRACT_TERM_TYPE}
              />
              <RadioBox
                enumName={enumConstant.CONTRACT_TERM_POLICY_OF_CATEGORY}
                label={labels.get('labels.policyType')}
                name="policyTypeId"
                labelSpan="7"
                inputSpan="17"
                onChange={onChange('policyTypeId')}
                required
              />
              <div className="display-none">
                <Input
                  name="AppliedToAllPlacesOfService"
                />
                <Input
                  name="AppliedToAllPlans"
                />
                <Input
                  name="AppliedToAllPolicyTypes"
                />
              </div>
              <AntdForm.Item
                noStyle
                shouldUpdate={(prev, next) => ((prev.policyTypeId !== next.policyTypeId)
                  || (prev.selectPolicies?.length !== next.selectPolicies?.length))}
              >
                {({ getFieldValue }) => getFieldValue('policyTypeId') === newMasterCodesWithId[enumMasterCode.Contract_Term_Policy_Type_Category_Specific] && (
                  <PolicyLookup
                    label={labels.get('titles.policy')}
                    inputSpan="17"
                    labelSpan="7"
                    required
                    form={form}
                    initialData={getFieldValue('selectedPolicies')}
                    setParentData={setSelectedPolicies}
                  />
                )}
              </AntdForm.Item>
            </Col>
            <Col md={12}>
              <RadioBox
                enumName={enumConstant.CONTRACT_TERM_PLAN_TYPE_CATEGORY}
                label={labels.get('labels.plans')}
                name="planId"
                labelSpan="7"
                inputSpan="17"
                onChange={onChange('planId')}
                required
              />
              <AntdForm.Item
                noStyle
                shouldUpdate={(prev, next) => ((prev.planId !== next.planId)
                  || (prev.selectedPlans?.length !== next.selectedPlans?.length))}
              >
                {({ getFieldValue }) => getFieldValue('planId') === planTypeMasterCodesWithId.Specific && (
                <PlanLookup
                  label={labels.get('titles.plans')}
                  inputSpan="17"
                  labelSpan="7"
                  required
                  form={form}
                  initialData={getFieldValue('selectedPlans')}
                  setParentData={setSelectedPlans}
                />
                )}
              </AntdForm.Item>
              <div className="custom-radio">
                <RadioBox
                  enumName={enumConstant.CONTRACT_TERM_PLACES_OF_SERVICE}
                  label={labels.get('labels.placesOfService')}
                  name="placesOfServiceId"
                  labelSpan="7"
                  inputSpan="17"
                  onChange={onChange('placesOfServiceId')}
                  required
                />
                <AntdForm.Item
                  noStyle
                  shouldUpdate={(prev, next) => prev.placesOfServiceId !== next.placesOfServiceId}
                >
                  {({ getFieldValue }) => {
                    if (getFieldValue('placesOfServiceId') === contractTermPlacesOfServicesWithId['Specific Location']) {
                      return (
                        <WiredSelectWithoutTab
                          id="location"
                          className="default-value-as-placeholder"
                          name="specificLocationId"
                          url={apiUrls.SCHEDULAR_LOCATION_DROPDOWN}
                          label={labels.get('labels.specificLocation')}
                          placeholder={labels.get('placeholders.allLocations')}
                          nameAccessor="locationName"
                          valueAccessor="locationId"
                          optionRenderer={locationsOptions}
                          startCaseOptions
                          selectProps={{
                            showSearch: true,
                            showArrow: true,
                            mode: 'multiple',
                            maxTagCount: 'responsive',
                          }}
                          labelSpan="7"
                          inputSpan="17"
                        />
                      );
                    }
                    if (getFieldValue('placesOfServiceId') === contractTermPlacesOfServicesWithId['Specific Places of Service']) {
                      return (
                        <Select
                          label={labels.get('labels.specificPlacesOfService')}
                          labelSpan="7"
                          inputSpan="17"
                          name="specificPlacesOfServiceId"
                          required
                          nameAccessor="masterName"
                          valueAccessor="masterId"
                          optionParser={placesOfServicesOptions}
                          enumName={enumConstant.CONTRACT_TERM_SPECIFIC_PLACES_OF_SERVICE}
                          mode="multiple"
                          form={form}
                        />
                      );
                    }
                    return null;
                  }}
                </AntdForm.Item>
              </div>
              <DatePicker
                label={labels.get('labels.effectiveFrom')}
                placeholder={labels.get('placeholders.effectiveFrom')}
                name="effectiveFrom"
                required
                labelSpan="7"
                inputSpan="17"
                disabledDate={(current) => current <= moment().subtract(100, 'years')}
              />
              <DatePicker
                label={labels.get('labels.effectiveTo')}
                placeholder={labels.get('labels.effectiveTo')}
                name="effectiveTo"
                labelSpan="7"
                inputSpan="17"
                dependencies={['effectiveFrom']}
                rules={[
                  ({ getFieldValue }) => toDateValidator(getFieldValue, 'effectiveFrom'),
                ]}
                disabledDate={(current) => current <= moment().subtract(100, 'years')}
              />
              <CheckBox
                label={labels.get('labels.status')}
                name="isActive"
                valuePropName="checked"
                labelSpan="7"
                inputSpan="17"
              />
            </Col>
          </Row>
        </div>
      </Col>
      <Col md={9}>
        <AntdForm.Item
          noStyle
          shouldUpdate={(prev, next) => prev.typeId !== next.typeId}
        >
          {({ getFieldValue }) => {
            if (getFieldValue('typeId') === masterCodesWithId?.Capitation) {
              return (
                <div className="group-shadow">
                  <span className="caption">
                    {labels.get('componentTitle.capitation')}
                  </span>
                  <div className="caption-border">
                    <div className="custom-radio">
                      <RadioBox
                        enumName={enumConstant.CAPITATION_PAYMENT_ARRANGEMENT}
                        label={labels.get('labels.paymentArrangement')}
                        name="capitationPaymentArrangementId"
                        required
                        labelSpan="7"
                        inputSpan="17"
                      />
                    </div>
                    <Input
                      label={labels.get('labels.amount')}
                      name="capitationAmount"
                      required
                      numberOnly
                      decimalPlaces={2}
                      maxValueLength={11}
                      labelSpan="7"
                      inputSpan="17"
                      placeholder={labels.get('placeholders.amount')}
                    />
                    <Radio
                      label={labels.get('labels.adjustClaim')}
                      name="capitationAdjustClaim"
                      required
                      options={claimOptions}
                      labelSpan="7"
                      inputSpan="17"
                      onChange={onChange('capitationAdjustClaim')}
                    />
                    <AntdForm.Item
                      noStyle
                      shouldUpdate={
                        (prev, next) => prev.capitationAdjustClaim !== next.capitationAdjustClaim
                      }
                    >
                      {({ getFieldValue: getNestedFieldValue }) => getNestedFieldValue('capitationAdjustClaim') && (
                      <Select
                        enumName={enumConstant.CAPITATION_ADJUST_CLAIM_REASON}
                        label="Capitation"
                        labelSpan="7"
                        inputSpan="17"
                        name="adjustClaimReasonCode"
                      />
                      )}
                    </AntdForm.Item>
                    <Radio label={labels.get('labels.submitClaim')} name="capitationSubmitClaims" required options={claimOptions} labelSpan="7" inputSpan="17" />
                    <Input label={labels.get('labels.comment')} name="capitationComments" labelSpan="7" inputSpan="17" />
                  </div>
                </div>
              );
            }
            if (getFieldValue('typeId') === masterCodesWithId[enumMasterCode.Contract_Term_Type_Fee_For_Service]) {
              return (
                <div className="group-shadow">
                  <span className="caption">
                    {labels.get('componentTitle.feeForService')}
                  </span>
                  <div className="caption-border">
                    <div className="custom-radio">
                      <RadioBox
                        enumName={enumConstant.FEE_FOR_SERVICES_PAYMENT_ARRANGEMENT}
                        required
                        name="feeForServicePaymentArrangementId"
                        label={labels.get('labels.paymentArrangement')}
                        labelSpan="7"
                        inputSpan="17"
                      />
                    </div>
                    <AntdForm.Item
                      noStyle
                      shouldUpdate={(prev, next) => prev.feeForServicePaymentArrangementId
                      !== next.feeForServicePaymentArrangementId}
                    >
                      {({ getFieldValue: getNestedFieldValue }) => {
                        if (getNestedFieldValue('feeForServicePaymentArrangementId') === feeForServicePaymentAgreementsWithId['Based on Fee Schedule']) {
                          return (
                            <>
                              <Select
                                label={labels.get('labels.feeScheduleType')}
                                enumName={enumConstant.SCHEDULAR_PROVIDER}
                                name="feeForServiceFeeScheduleTypeId"
                                required
                                labelSpan="7"
                                inputSpan="17"
                              />
                              <AntdForm.Item
                                noStyle
                                shouldUpdate={(prev, next) => prev.feeForServiceFeeScheduleTypeId
                            !== next.feeForServiceFeeScheduleTypeId}
                              >
                                {({ getFieldValue: getDoubleNestedFieldValue }) => getDoubleNestedFieldValue('feeForServiceFeeScheduleTypeId') && (
                                <>
                                  <Select
                                    enumName={enumConstant.CONTRACT_TERM_YEAR}
                                    label={labels.get('labels.year')}
                                    name="feeForServiceYearId"
                                    required
                                    labelSpan="7"
                                    inputSpan="17"
                                  />
                                  <Select
                                    enumName={enumConstant.CONTRACT_TERM_LOCALITY}
                                    label={labels.get('labels.locality')}
                                    name="feeForServiceLocalityId"
                                    required
                                    labelSpan="7"
                                    inputSpan="17"
                                  />
                                </>
                                )}
                              </AntdForm.Item>
                              <Input
                                label={labels.get('labels.multiplier')}
                                name="feeForServiceMultiplier"
                                labelSpan="7"
                                inputSpan="17"
                                numberOnly
                                maxValueLength={11}
                                decimalPlaces={2}
                                placeholder={labels.get('placeholders.multiplier')}
                              />
                              <RadioBox
                                label={labels.get('labels.dateDriver')}
                                name="dateDriverId"
                                enumName={enumConstant.Date_Driver}
                                labelSpan="7"
                                inputSpan="17"
                                required
                              />
                              <RadioBox
                                label={labels.get('labels.valueIfNoProcedure')}
                                name="feeForServiceValueIfNoProcedureId"
                                enumName={enumConstant.FEE_FOR_SERVICE_VALUE_IF_NO_PROCEDURE}
                                labelSpan="7"
                                inputSpan="17"
                                onChange={onChange('feeForServiceValueIfNoProcedureId')}
                              />
                              <AntdForm.Item
                                noStyle
                                shouldUpdate={(prev, next) => prev.feeForServiceValueIfNoProcedure
                              !== next.feeForServiceValueIfNoProcedureId}
                              >
                                {({ getFieldValue: getTripleNestedFieldValue }) => getTripleNestedFieldValue('feeForServiceValueIfNoProcedureId') === feeForServiceValueIfNoProcedure.PercentageofBilledAmount && (
                                <Input
                                  name="percentageOfBilledAmount"
                                  labelSpan="7"
                                  inputSpan="17"
                                  numberOnly
                                  decimalPlaces={2}
                                  placeholder={labels.get('placeholders.percentageOfBilledAmount')}
                                />
                                )}
                              </AntdForm.Item>
                            </>
                          );
                        }
                        if (getNestedFieldValue('feeForServicePaymentArrangementId') === feeForServicePaymentAgreementsWithId.BasedOnRVU) {
                          return (
                            <Input
                              label="RVU"
                              name="amountPerRVU"
                              labelSpan="7"
                              inputSpan="17"
                              numberOnly
                              decimalPlaces={2}
                              placeholder={labels.get('placeholders.rvu')}
                            />
                          );
                        }
                        return null;
                      }}
                    </AntdForm.Item>
                  </div>
                  <AntdForm.Item
                    noStyle
                    shouldUpdate={(prev, next) => (
                      (prev.feeForServicePaymentArrangementId
                        !== next.feeForServicePaymentArrangementId)
                      || (prev.feeForServiceValueIfNoProcedureId
                      !== next.feeForServiceValueIfNoProcedureId)
                    )}
                  >
                    {({ getFieldValue: getNestedFieldValue }) => getNestedFieldValue('feeForServiceValueIfNoProcedureId') === feeForServiceValueIfNoProcedure.FeeScheduleType && (
                    <>
                      <span className="caption">
                        {labels.get('componentTitle.procedureNotFound')}
                      </span>
                      <div className="caption-border">
                        <Select
                          label={labels.get('labels.feeScheduleType')}
                          name="feeScheduleTypeId"
                          enumName={enumConstant.SCHEDULAR_PROVIDER}
                          required
                          labelSpan="7"
                          inputSpan="17"
                        />
                        <Select
                          label={labels.get('labels.year')}
                          name="feeScheduleTypeYearId"
                          required
                          enumName={enumConstant.CONTRACT_TERM_YEAR}
                          labelSpan="7"
                          inputSpan="17"
                        />
                        <Select
                          label={labels.get('labels.locality')}
                          name="feeScheduleTypeLocalityId"
                          required
                          enumName={enumConstant.CONTRACT_TERM_LOCALITY}
                          labelSpan="7"
                          inputSpan="17"
                        />
                        <Input
                          label={labels.get('labels.multiplier')}
                          required
                          name="feeScheduleTypeMultiplier"
                          labelSpan="7"
                          inputSpan="17"
                          placeholder={labels.get('placeholders.multiplier')}
                        />
                      </div>
                    </>
                    )}
                  </AntdForm.Item>

                </div>
              );
            }
            return null;
          }}
        </AntdForm.Item>
      </Col>
    </Row>
  );
};

export default WithLabel(
  TermsGeneralForm,
  labelPaths.EDIT_CONTRACT_TERMS_GENERAL,
);
