import React, { useCallback } from 'react';
import isFunction from 'lodash/isFunction';

import PersonForm from '../PersonForm';

const PolicyHolder = ({
  form, labels, formFieldPrefix, setRelation, ...otherProps
}) => {
  const onRelationChange = useCallback((value, { children: name }) => {
    if (isFunction) {
      setRelation(name);
    }
  }, [setRelation]);

  return (
    <div className="form-container">
      <div className="feild-row justify-content-sp-bt align-center">
        <h2 className="h2-title">{labels.get('titles.policyHolder')}</h2>
      </div>
      <PersonForm
        form={form}
        labels={labels}
        onRelationSelect={onRelationChange}
        formFieldPrefix={formFieldPrefix}
        {...otherProps}
      />
    </div>
  );
};

export default PolicyHolder;
