import React, { useCallback, useEffect } from 'react';
import { Form } from 'antd';
import size from 'lodash/size';

import { apiUrls } from '../../../../api/constants';
import ErrorMessages from '../../../../lib/errorMessages';

import SelectBox from '../../../../components/SelectBox';
import Notification from '../../../../components/Notification';
import WiredSelect from '../../../../wiredComponents/Select/selectBoxV2';

import './header.scss';

const Header = ({
  labels, location, selectedDoctors, onDoctorSelect, onLocationSelect, filterSetter,
}) => {
  const [form] = Form.useForm();
  const handleSelectDoctor = useCallback(
    (doctorIds) => {
      if (doctorIds && doctorIds.length < 6) {
        filterSetter('doctor', doctorIds);
        onDoctorSelect(doctorIds);
        if (doctorIds.length > 1) {
          filterSetter('location', undefined);
          onLocationSelect();
        }
      } else {
        form.setFieldsValue({ doctor: doctorIds.slice(0, 5).map((item) => item) });
        Notification({ message: ErrorMessages.SCHEDULER_CANNOT_SELECT_MORE_THAN_5 });
      }
    },
    [filterSetter, onDoctorSelect, onLocationSelect, form],
  );

  const handleSelectLocation = useCallback((value) => {
    filterSetter('location', value);
    onLocationSelect(value);
    if (selectedDoctors.length > 1) {
      form.setFieldsValue({ doctor: [] });
      filterSetter('doctor', []);
      onDoctorSelect([]);
    }
  },
  [filterSetter, onLocationSelect, selectedDoctors.length, form, onDoctorSelect]);

  useEffect(() => {
    if (location) {
      form.setFieldsValue({ location });
    }
    if (selectedDoctors?.length) {
      form.setFieldsValue({ doctor: selectedDoctors });
    }
    if (selectedDoctors?.length > 1) {
      form.setFieldsValue({ location: 'Any' });
    }
  }, [location, selectedDoctors, form]);

  return (
    <Form form={form}>
      <div className="dashboard-header-section">
        <h2 className="bold">{labels.get('titles.dashboard')}</h2>
        <div className="flex align-center">
          <div className="mng-select-gaps">
            <WiredSelect
              id="location"
              name="location"
              label={labels.get('labels.location')}
              placeholder={labels.get('placeholders.location')}
              url={apiUrls.SCHEDULAR_LOCATION_DROPDOWN}
              nameAccessor="locationName"
              valueAccessor="locationId"
              Component={SelectBox}
              startCaseOptions
              selectProps={{
                size: 'middle',
                style: { width: 180 },
                showSearch: true,
              }}
              onChange={handleSelectLocation}
              labelSpan="8"
              inputSpan="16"
              params={{
                ProviderId: size(selectedDoctors) === 1 ? selectedDoctors[0] : undefined,
                PageSize: 1000,
              }}
              updateDate
            />
          </div>
          <div className="mng-select-gaps mng-select-text mr-lt-40">
            <WiredSelect
              id="doctor"
              name="doctor"
              label={labels.get('labels.doctor')}
              placeholder={labels.get('placeholders.doctor')}
              url={apiUrls.SCHEDULAR_PROVIDER_DROPDOWN}
              nameAccessor="providerName"
              valueAccessor="providerId"
              Component={SelectBox}
              startCaseOptions
              selectProps={{
                mode: 'multiple',
                maxTagCount: 1,
                style: { minWidth: 230 },
                showSearch: true,
              }}
              onChange={handleSelectDoctor}
              labelSpan="8"
              inputSpan="16"
              params={{
                LocationId: location,
                PageSize: 1000,
              }}
              defaultSorting={false}
            />
          </div>
        </div>
      </div>
    </Form>
  );
};

export default Header;
