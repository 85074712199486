import React from 'react';
import Label from '../../../../../components/Label';

import { dateFormat } from '../../../../../lib/constants';
import dateFormatter from '../../../../../lib/dateFormatter';
import AmountAndBalance from './financialDetailsComponents/amountAndBalance';
import FinancialDetailCommonGroup from './financialDetailsComponents/financialDetailGroup1';
import FinancialDetailGroup2 from './financialDetailsComponents/financialDetailGroup2';
import FinancialDetailsHeader from './financialDetailsComponents/header';
import { addTimeZoneInDate } from '../../../../../lib/util';

const PatientPaymentForm = ({ financialDetail, isReversalForm }) => {
  const createdOn = financialDetail?.createdOn ? `on ${dateFormatter(addTimeZoneInDate(financialDetail.createdOn), dateFormat)}` : '';
  return (
    <div className="max-width-100 table-layout-field">
      <form>
        <FinancialDetailsHeader />
        <div className="feild-row display-view  group-shadow">
          <AmountAndBalance financialDetail={financialDetail} isReversalForm={isReversalForm} />
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Payment Type" />
              :
            </div>
            <div className="name-view">{financialDetail?.financeDetails?.paymentTypeName ?? ''}</div>
          </div>
          <FinancialDetailGroup2 financialDetail={financialDetail} createdOn={createdOn} />
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Refundable Amount" />
              :
            </div>
            <div className="name-view">
              {isReversalForm ? (0).toFixed(2)
                : parseFloat(financialDetail?.refund ?? 0)?.toFixed(2)}
            </div>
          </div>
          <FinancialDetailCommonGroup financialDetail={financialDetail} />
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Comment" />
              :
            </div>
            <div className="name-view">{financialDetail?.financeDetails?.comments ?? ''}</div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PatientPaymentForm;
