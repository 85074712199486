import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Collapse } from 'antd';
import debounce from 'lodash/debounce';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';

import Events from '../../../../../../../../../../lib/events';
import rights from '../../../../../../../../../../lib/rights';
import sortWithoutSymbols from '../../../../../../../../../../lib/sortWithoutSymbols';
import { getString } from '../../../../../../../../../../lib/util';
import SuccessMessages from '../../../../../../../../../../lib/successMessages';
import { apiUrls } from '../../../../../../../../../../api/constants';

import useCRUD from '../../../../../../../../../../hooks/useCRUD';
import useRights from '../../../../../../../../../../hooks/useRights';
import useRedirect from '../../../../../../../../../../hooks/useRedirect';

import Icon from '../../../../../../../../../../components/Icon';
import Form from '../../../../../../../../../../components/Form';
import Search from '../../../../../../../../../../components/Search';
import Collapsable from '../../../../../../../../../../components/Collapse';
import RadioGroup from '../../../../../../../../../../components/RadioGroup';
import WidgetLoader from '../../../../../../../../../../components/WidgetLoader';
import Notification from '../../../../../../../../../../components/Notification';
import ConfirmDialog from '../../../../../../../../../../components/ConfirmDialog';

import DxJsonTree from './dxJsonTree';
import JsonTree from '../../JsonTree';
import { libraryParser } from '../../../../../../../../commonParser';
import LibraryDroppableTree from '../ProcedureSets/Components/DroppableJsonTree/LibraryDroppableTree';

const { Panel } = Collapse;

const tabList = [
  { label: 'Practice', name: 'practice', value: 'practice' },
  { label: 'Provider', name: 'provider', value: 'provider' },
  { label: 'Library', name: 'library', value: 'library' },
];

const Dx = ({
  dxList, setDxList, showPreviousForPatient = true, showResult = true, searchProps,
}) => {
  const { params: { providerId, patientId, encounterId } } = useRedirect();
  const [isDeleteFavouriteSetAuthenticated] = useRights([rights.delete_favourites]);
  const [currentTab, setCurrentTab] = useState('practice');
  const [searchText, setSearchText] = useState('');
  const [providerIcdWithFavourites, setProviderIcdWithFavourites] = useState(null);

  const [
    result, , resultLoading,
    getResult,
    clearResult,
  ] = useCRUD({
    id: apiUrls.GET_ICD_WITH_FAVORITES,
    url: apiUrls.GET_ICD_WITH_FAVORITES,
    type: 'read',
  });

  const [diagnosis, , prevDiagnosisLoading, getPreviousDiagnosis] = useCRUD({
    id: 'previous-encounter-dx-list',
    url: apiUrls.GET_PREVIOUS_ENCOUNTER_DX_LIST,
    type: 'read',
  });

  useEffect(() => {
    getPreviousDiagnosis({ patientId, isSignedEncounter: true });
  }, [patientId]);

  useEffect(() => {
    if (result) {
      const sortedProviderIcd = orderBy(result.result, (e) => sortWithoutSymbols(e.description));
      setProviderIcdWithFavourites({ ...result, result: sortedProviderIcd });
    }
  }, [result]);

  useEffect(() => {
    if (diagnosis?.result?.length) {
      const parsedDiagnosis = [];
      diagnosis?.result?.forEach((item) => {
        item?.procedureDetails?.forEach((proc) => {
          proc?.diagnosisList?.forEach((dg) => {
            parsedDiagnosis?.push({
              ...dg,
              key: dg.diagnosisDetailsId,
              label: getString([dg?.icdCode, dg.description], ' - '),
            });
          });
        });
      });
      setDxList(uniqBy(parsedDiagnosis, 'id'));
    }
  }, [diagnosis]);

  const sortedDxList = orderBy(dxList, (e) => sortWithoutSymbols(e.description));

  const [show, showList] = useState(null);

  const [
    deleteFavoritesResponse,, deleteFavoritesLoading,
    deleteFavorites,
    clearDiagnosisFavoriteResult,
  ] = useCRUD({
    id: apiUrls.DELETE_FAVOURITE_SET,
    url: apiUrls.DELETE_FAVOURITE_SET,
    type: 'create',
  });

  const [deleteFavoriteDiagnosisResponse,, deleteFavoriteDiagnosisLoading,
    deleteFavoriteDiagnosis, clearFavoriteDignosisResult] = useCRUD({
    id: apiUrls.DELETE_FAVOURITE_DIAGNOSIS,
    url: apiUrls.DELETE_FAVOURITE_DIAGNOSIS,
    type: 'create',
  });

  const [
    inDiagnosisProcedureSets,,
    loading,
    getInDiagnosisProcedureSet,
    clearIndiagnosisProcedureSet,
  ] = useCRUD({
    id: apiUrls.GET_IN_OFFICE_PROCEDURE_SETS,
    url: apiUrls.GET_IN_OFFICE_PROCEDURE_SETS,
    type: 'read',
  });

  const [
    diagnosisLibrary,,
    loadingLibrary,
    getDiagnosisLibrary,
    // clearInofficeProcedures,
  ] = useCRUD({
    id: 'get-diagnosis-library',
    url: apiUrls.GET_IN_OFFICE_PROCEDURE_SETS,
    type: 'read',
  });

  const [
    copyProcedureSets,,
    copyLoading,
    copyProcedures,
    clearCopyProcedureSets,
  ] = useCRUD({
    id: apiUrls.COPY_PROVIDERS_FAVORITE_SET,
    url: apiUrls.COPY_PROVIDERS_FAVORITE_SET,
    type: 'create',
  });
  const [applyDxResponse,, applyDxLoading, saveDx, clearResponse] = useCRUD({
    id: 'save-dx-in-order-procedure-apply',
    url: apiUrls.SAVE_IN_ORDER_PROCEDURES,
    type: 'create',
  });

  const [applyDxFolderResponse,,
    applyDxFolderLoading, saveDxFolder, clearDxFolderResponse] = useCRUD({
    id: 'save-dx-folder-in-order-procedure',
    url: apiUrls.APPLY_DIAGNOSIS_FAVORITE_FOLDER,
    type: 'create',
  });

  const getInOfficeProcedureSetParams = () => (currentTab === 'provider' ? `?Source=Diagnosis&providerId=${providerId}` : '?Source=Diagnosis');

  useEffect(() => {
    if (applyDxResponse) {
      Events.trigger('reFetchOcularProblem');
      Events.trigger(`refetch-InOffice-ProcedureList-${encounterId}`);
      Events.trigger(`refetchInOfficeProcedureList-${encounterId}`);
      clearResponse();
    }
  }, [applyDxResponse]);

  useEffect(() => {
    if (applyDxFolderResponse) {
      Events.trigger('reFetchOcularProblem');
      Events.trigger(`refetch-InOffice-ProcedureList-${encounterId}`);
      Events.trigger(`refetchInOfficeProcedureList-${encounterId}`);
      clearDxFolderResponse();
    }
  }, [applyDxFolderResponse]);

  useEffect(() => {
    if (copyProcedureSets) {
      Notification({ message: 'Diagnosis Favorite has been copied successfully', success: true });
      clearCopyProcedureSets();
    }
  }, [copyProcedureSets]);

  useEffect(() => {
    if (currentTab === 'library') {
      const paramsObject = {
        IsActive: true,
        source: 'Diagnosis',
        providerId,
        isLibrary: true,
      };
      if (searchText?.length) {
        paramsObject.SearchText = searchText;
      }
      getDiagnosisLibrary(paramsObject);
    } else {
      const obj = { Source: 'Diagnosis' };
      if (currentTab === 'provider') { obj.ProviderId = providerId; }
      if (searchText?.length) {
        obj.SearchText = searchText;
      }
      getInDiagnosisProcedureSet(obj);
    }
  }, [currentTab, searchText]);

  const showingDelete = useMemo(() => {
    if (currentTab === 'practice') {
      return isDeleteFavouriteSetAuthenticated;
    } return true;
  }, [currentTab, isDeleteFavouriteSetAuthenticated]);

  useEffect(() => {
    Events.on('get-diagnosis-list', 'get-diagnosis-list', () => {
      getInDiagnosisProcedureSet({}, getInOfficeProcedureSetParams());
    });
    return () => Events.remove('get-diagnosis-list');
  }, [getInDiagnosisProcedureSet, deleteFavoritesResponse, getInOfficeProcedureSetParams]);

  useEffect(() => {
    if (deleteFavoriteDiagnosisResponse || deleteFavoritesResponse) {
      clearIndiagnosisProcedureSet(true);
      Events.trigger('get-diagnosis-list');
      Notification({
        message: SuccessMessages.DIAGNOSIS_FAVOURITE_DELETED_SUCCESSFULLY,
        success: true,
      });
      if (deleteFavoriteDiagnosisResponse) {
        clearFavoriteDignosisResult(true);
      }
      if (deleteFavoritesResponse) {
        clearDiagnosisFavoriteResult(true);
      }
    }
  }, [deleteFavoritesResponse, deleteFavoriteDiagnosisResponse]);

  const handleDeleteFavourites = useCallback((favoriteId) => {
    if (favoriteId) {
      ConfirmDialog({
        onOk: (close) => {
          deleteFavorites({ data: { favoriteId: `${favoriteId}` } });
          close();
        },
        okText: 'Ok',
        title: 'Warning',
        content: 'Are you sure, you want to delete this favorite procedure set?',
        icon: <Icon name="ExclamationCircleOutlined" />,
      })();
    }
  }, [deleteFavorites]);

  const deleteDiagnosis = useCallback((diagnosisId, favoriteId) => {
    if (diagnosisId && favoriteId) {
      ConfirmDialog({
        onOk: (close) => {
          deleteFavoriteDiagnosis({ data: { diagnosisDetailsId: diagnosisId, favoriteId: `${favoriteId}` } });
          close();
        },
        okText: 'Ok',
        title: 'Warning',
        content: 'Are you sure, you want to delete this favourite procedure?',
        icon: <Icon name="ExclamationCircleOutlined" />,
      })();
    }
  }, [deleteFavoriteDiagnosis]);

  const debounceFunction = useMemo(() => debounce((value) => {
    if (value?.trim()?.length) {
      setSearchText(value);
      getResult({ ICDCodeText: value, providerId });
      getPreviousDiagnosis({ searchText: value, patientId, isSignedEncounter: true });
      showList(true);
    } else {
      setSearchText('');
      clearResult(true);
      getPreviousDiagnosis({ patientId, isSignedEncounter: true });
      showList(false);
    }
  }, 1500), [getResult, providerId, getPreviousDiagnosis, patientId, clearResult]);

  const onSearch = useCallback((event) => {
    event.persist();
    debounceFunction(event?.target?.value);
  }, [debounceFunction]);

  const parsedDiagnosisList = useMemo(() => {
    const obj = {};
    inDiagnosisProcedureSets?.forEach((tagDetails) => {
      tagDetails?.tags?.forEach((item) => {
        if (obj[item?.tagName]) {
          obj[item?.tagName].tagId = item?.favourites?.[0]?.favouriteId;
        } else {
          obj[item?.tagName] = {
            tagName: item.tagName,
            tagId: item?.favourites?.[0]?.favouriteId,
            ...item?.favourites?.[0],
          };
        }
      });
    });
    return orderBy(Object.values(obj), (e) => sortWithoutSymbols(e.tagName));
  }, [inDiagnosisProcedureSets]);

  const tabSwitchHandler = useCallback(({ target: { value } }) => {
    clearIndiagnosisProcedureSet(true);
    setCurrentTab(value);
  }, [clearIndiagnosisProcedureSet]);

  const libraryList = useMemo(() => libraryParser(diagnosisLibrary), [diagnosisLibrary]);

  const copyProviderAllProcedureSets = useCallback((data, event) => {
    event.stopPropagation();
    copyProcedures({ data });
  }, [copyProcedures]);

  const handleApplyDiagnosis = useCallback((id) => {
    saveDx({
      data: {
        IsActive: true,
        encounterId,
        icdCodeId: id,
        patientId,
        providerId,
      },
    });
  }, [encounterId, patientId, providerId, saveDx]);

  const handleApplyDiagnosisFolder = useCallback((id) => {
    saveDxFolder({
      data: {
        encounterId,
        patientId,
        providerId,
        favoriteId: id,
      },
    });
  }, [encounterId, patientId, providerId, saveDxFolder]);

  return (
    <div className="side-tab">
      <div className="merge-fields-container">
        <div className="temp-search-wrapper">
          <Form.Section noOfColumns={2}>
            <Form.Column>
              <Search name="searchText" onChange={onSearch} style={searchProps} />
            </Form.Column>
          </Form.Section>
        </div>
        <div className="wrap-dx-accordion">
          <Collapse
            className="ae-accordian posi-relative mr-bt-8 favorite-panel-head"
            key={(parsedDiagnosisList?.length) ? 'Favorites' : 'Favorite'}
            defaultActiveKey="Favorites"
            expandIconPosition="right"
          >
            <Panel
              header={(
                <div className="flex justify-content-sp-bt align-baseline">
                  <div>Favorites</div>
                  <div className="flex justify-content-flex-end mr-rt-12">
                    <RadioGroup
                      tabList={tabList}
                      onChange={tabSwitchHandler}
                      value={currentTab}
                      id="procedure_dx_tabs"
                    />
                  </div>
                </div>
            )}
              key="Favorites"
              defaultActiveKey={['Favorites']}
            >
              <div className="pannel-body posi-relative">
                { (loading || deleteFavoritesLoading
                || deleteFavoriteDiagnosisLoading || loadingLibrary
                || copyLoading || prevDiagnosisLoading || applyDxLoading
                || applyDxFolderLoading) && <WidgetLoader />}
                <div className="json-tree-container remove-tree-switchers diagnosis">
                  {currentTab === 'library' ? (
                    <div className="json-tree-container library-tree-ui">
                      <LibraryDroppableTree
                        type="diagnosisFavorite"
                        showFollowUpExamFinding={false}
                        libraryList={libraryList}
                        searchText={searchText}
                        copyProviderAllProcedureSets={copyProviderAllProcedureSets}
                        handleApplyDiagnosis={handleApplyDiagnosis}
                      />
                    </div>
                  )
                    : (
                      <DxJsonTree
                        deleteFolderSet={handleDeleteFavourites}
                        deleteDiagnosis={deleteDiagnosis}
                        list={parsedDiagnosisList}
                        selectable={false}
                        tab="dx"
                        showCloseIcon
                        showingDelete={showingDelete}
                        showAllExpanded={searchText?.length || currentTab === 'practice'}
                        searchText={searchText}
                        handleApplyDiagnosis={handleApplyDiagnosis}
                        handleApplyDiagnosisFolder={handleApplyDiagnosisFolder}
                      />
                    )}
                </div>
              </div>
            </Panel>
          </Collapse>
          {showPreviousForPatient && (
          <Collapsable
            className="ae-accordian posi-relative mr-bt-8"
            header="Previous For Patient"
            panelKey={show === true ? 'OcularProblemList' : 'OcularProblemLists'}
            defaultActiveKey={['OcularProblemList']}
          >
            <div className="pannel-body">
              <div className="json-tree-container remove-tree-switchers">
                { prevDiagnosisLoading && <WidgetLoader />}
                <JsonTree
                  url={apiUrls.GET_COMPOSER_LABELS}
                  setLabelsList={setDxList}
                  list={sortedDxList}
                  selectable={false}
                  tab="dx"
                />
              </div>
            </div>
          </Collapsable>
          )}
          {showResult && (
          <Collapsable
            className="ae-accordian posi-relative manage-ui-block"
            header="Result"
            panelKey={show === true ? 'result' : 'results'}
            defaultActiveKey={['result']}
          >
            {resultLoading && <WidgetLoader />}
            <JsonTree
              list={providerIcdWithFavourites?.result || []}
              selectable={false}
              tab="dx"
              isResult
            />
          </Collapsable>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dx;
