import React from 'react';

import { enums as enumConstant } from '../../../../../lib/constants';
import { conFirmEmailValidator } from '../../../../../lib/validator';

import Form from '../../../../../components/Form';
import { Input, CheckBox } from '../../../../../components/Form/inputFields';

import SelectWithoutTab from '../../../../../wiredComponents/Select/selectBoxV2';
import { Select } from '../../../../../wiredComponents/Enum';
import WiredSelect from '../../../../../wiredComponents/Select';

import { apiUrls } from '../../../../../api/constants';
import { getString, lengthCheck } from '../../../../../lib/util';
import ErrorMessages from '../../../../../lib/errorMessages';

import '../../../Settings.scss';

export const GeneralFormFields = ({
  labels, rolesOptionParser,
}) => (
  <div className="group-shadow">
    <Input
      label={labels.get('labels.firstName')}
      id="1"
      name="firstName"
      maxValueLength={50}
      required
      charOnly
      labelSpan="8"
      inputSpan="16"
    />
    <Input
      label={labels.get('labels.middleName')}
      name="middleName"
      id="2"
      charOnly
      maxValueLength={5}
      labelSpan="8"
      inputSpan="16"
    />
    <Input
      label={labels.get('labels.lastName')}
      name="lastName"
      id="3"
      charOnly
      maxValueLength={50}
      labelSpan="8"
      inputSpan="16"
    />
    <WiredSelect
      id="Roles"
      name="roleIds"
      label={labels.get('labels.roles')}
      url={apiUrls.GET_CHILD_ROLES}
      nameAccessor="name"
      valueAccessor="id"
      inputSpan="16"
      labelSpan="8"
      startCaseOptions
      isStatCaseOptions={false}
      required
      parser={rolesOptionParser}
      selectProps={{
        mode: 'multiple',
        maxTagCount: 'responsive',
        style: { minWidth: 130 },
        showArrow: true,
      }}
    />
  </div>
);

const rolesOptionParser = (options) => options.map((item) => ({ ...item, name: getString([item.parentRoleName, item.childRoleName], ' : ') }));

const General = ({
  labels, formData, emailDisable,
}) => (
  <>
    <Form.Section>
      <Form.Column>
        <div className="feild-row required-feilds top-feild-mng">
          <h2 className="h2-title">{labels.get('titles.basicInformation')}</h2>
        </div>
      </Form.Column>
    </Form.Section>
    <Form.Section noOfColumns={3}>
      <Form.Column>
        <GeneralFormFields
          labels={labels}
          formData={formData}
          rolesOptionParser={rolesOptionParser}
        />
      </Form.Column>
      <Form.Column>
        <div className="group-shadow">
          <Select
            label={labels.get('labels.gender')}
            name="genderId"
            labelSpan="8"
            inputSpan="16"
            enumName={enumConstant.GENDER}
          />
          <Select
            label={labels.get('labels.sendInvitation')}
            name="dateToSendInvitationId"
            enumName={enumConstant.INVITATION_SEND_TYPE}
            required
            labelSpan="8"
            inputSpan="16"
          />
          <Select
            label={labels.get('labels.defaultApp')}
            name="defaultAppId"
            enumName={enumConstant.DEFAULT_APP}
            labelSpan="8"
            inputSpan="16"
          />
          <Input
            label={labels.get('labels.scriptsureId')}
            id="1"
            name="scriptsureId"
            rules={[
              () => lengthCheck(
                8, ErrorMessages.VALUE_CANNOT_BE_LONGER_THAN_8_CHARACTERS,
              ),
            ]}
            numberOnly
            labelSpan="8"
            inputSpan="16"
          />
          <Select
            label={labels.get('labels.identityProvider')}
            name="identityProviderId"
            enumName={enumConstant.Identity_Provider}
            labelSpan="8"
            required
            inputSpan="16"
          />
        </div>
      </Form.Column>
      <Form.Column>
        <div className="group-shadow">
          <CheckBox label={labels.get('labels.active')} name="isActive" valuePropName="checked" labelSpan="8" inputSpan="16" />
          <Input
            label={labels.get('labels.email')}
            id="15"
            required
            name="email"
            labelSpan="8"
            inputSpan="16"
            disabled={emailDisable}
            rules={[{
              type: 'email',
              message: 'The input is not valid E-mail!',
            }]}
          />
          <Input
            label={labels.get('labels.confirmEmail')}
            id="15"
            required
            name="confirmEmail"
            labelSpan="8"
            inputSpan="16"
            disabled={emailDisable}
            dependencies={['email']}
            rules={[{
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            ({ getFieldValue }) => conFirmEmailValidator(getFieldValue, 'email')]}
          />
          <SelectWithoutTab
            id="location"
            name="locationIds"
            label="Home locations"
            url={apiUrls.SCHEDULAR_LOCATION_DROPDOWN}
            placeholder="Select locations"
            nameAccessor="locationName"
            valueAccessor="locationId"
            labelSpan="8"
            inputSpan="16"
            startCaseOptions
            selectProps={{
              mode: 'multiple',
              maxTagCount: 'responsive',
              showSearch: true,
              dropdownMatchSelectWidth: 250,
            }}
            params={{
              PageSize: 1000,
            }}
          />
        </div>
      </Form.Column>
    </Form.Section>
  </>
);

export default General;
