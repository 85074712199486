import React from 'react';
import { Form as AntForm } from 'antd';

import Notification from '../../../../../../components/Notification';

import useRedirect from '../../../../../../hooks/useRedirect';
import useCRUD from '../../../../../../hooks/useCRUD';

import SuccessMessages from '../../../../../../lib/successMessages';
import { labelPaths } from '../../../../../../lib/constants';

import { apiUrls } from '../../../../../../api/constants';

import WithLabel from '../../../../../../hoc/withLabel';

import EditForm from './EditForm';

const ResponsibleParty = (props) => {
  const {
    labels, baseRoute, queryString, self,
    isUpdatePatientAuthenticated, showInActiveInsuranceData, formName,
  } = props;

  const [form] = AntForm.useForm();

  const { params, generatePath, push } = useRedirect();

  const requestInsuranceData = useCRUD({
    id: 'patient-insurance-rp', url: apiUrls.GET_PATIENT_INSURANCE, type: 'read',
  })[3];

  const onRequestComplete = ({ response }) => {
    if (response && response.insuranceProfileId) {
      Notification({
        message: SuccessMessages.INSURANCE_PROFILE_UPDATED_SUCCESSFULLY, success: true,
      });
      requestInsuranceData({ PatientId: params?.id, includeinactive: showInActiveInsuranceData });
      if (response.InsuranceProfileType === 'Self') {
        push({
          pathname: generatePath(`${baseRoute}/rp/view/:rpId`, {
            ...params, rpId: response.insuranceProfileId,
          }),
          search: queryString,
        });
      } else {
        push({ pathname: generatePath(`${baseRoute}/profile/view/:profileId`, { ...params, profileId: response.insuranceProfileId }), search: queryString });
      }
    }
  };

  return (
    <EditForm
      self={self}
      form={form}
      labels={labels}
      formName={formName}
      isUpdatePatientAuthenticated={isUpdatePatientAuthenticated}
      onRequestComplete={onRequestComplete}
    />
  );
};

export default WithLabel(ResponsibleParty, labelPaths.EDIT_RESPONSIBLE_PARTY);
