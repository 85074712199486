import React from 'react';

const DisplayLocationAndCount = ({
  item, location, maxCount, index,
}) => {
  const max = maxCount ? `/${maxCount}` : '';
  return (
    <div
      key={index}
      className="location-hour"
      style={{ height: (item.end - item.start) * 20, background: item.background }}
    >
      {!!item.count && <div className="location-count">{`(${item.count}${max})`}</div>}
      <div className="location-name">
        {location}
      </div>
    </div>
  );
};

const LocationCell = ({
  location, startYAt, endYAt, hours, maxCount,
}) => (
  <div className="grid-location-view" style={{ gridRowStart: startYAt, gridRowEnd: endYAt }}>
    <div className="location-hour-wrapper">
      {hours && hours.map((item, index) => (
        <DisplayLocationAndCount
          key={index}
          item={item}
          index={index}
          location={location}
          maxCount={maxCount}
        />
      ))}
    </div>
  </div>
);

export default LocationCell;
