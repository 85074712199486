import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Form as Antd } from 'antd';
import mapKeys from 'lodash/mapKeys';

import useTabLink from '../../../../../../hooks/useTabLink';
import useUpdateTabName from '../../../../../../hooks/useUpdateTabName';
import {
  formName, formId, contentType, labelPaths, listId, UiRoutes,
} from '../../../../../../lib/constants';
import Events from '../../../../../../lib/events';
import SuccessMessages from '../../../../../../lib/successMessages';
import formFieldValueParser from '../../../../../../lib/formFieldValuesParser';
import { getName } from '../../../../../../lib/util';

import useRedirect from '../../../../../../hooks/useRedirect';
import Form from '../../../../../../components/Form';
import Button from '../../../../../../components/Button';
import Notification from '../../../../../../components/Notification';
import ProviderInformation from '../../../Components/ProviderInformation';

import { clearOtherProps } from '../../../../../../store/actions/providers';
import { apiUrls } from '../../../../../../api/constants';
import WithLabel from '../../../../../../hoc/withLabel';
import { clearReadData } from '../../../../../../store/actions/crudWithoutTab';

const fileInfoType = ['signaturedocument', 'isfileuploaded'];

function General({
  clearOtherPropsOfProvider, labels, isProviderViewOnly,
}) {
  const [formData, setFormData] = useState({});
  const [isClear, setClear] = useState(false);
  const [form] = Antd.useForm();
  const { params } = useRedirect();
  const { navigate: navigateToInsuranceProfile } = useTabLink({
    to: UiRoutes.editUserWithTabId,
  });
  const dispatch = useDispatch();
  const setUpdatedNameInTab = useUpdateTabName();
  const { id, tabId } = params;
  useEffect(() => () => clearOtherPropsOfProvider(), [id, clearOtherPropsOfProvider]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      const name = getName(form.getFieldValue('firstName'), form.getFieldValue('middleName'), form.getFieldValue('lastName'));
      Notification({ message: SuccessMessages.PROVIDER_UPDATE_SUCCESSFULLY, success: true });
      Events.trigger('refetchProviderData');
      setUpdatedNameInTab(name);
      dispatch(clearReadData('schedular-doctor'));
      dispatch(clearReadData('provider'));
      dispatch(clearReadData('doctor'));
    }
  }, [dispatch, form, setUpdatedNameInTab]);

  const parser = useCallback((values) => {
    const formValues = formFieldValueParser({
      ...values,
      copyNotes: values?.copyNotes?.join(''),
      providerTypeId: values.providerTypeId ? values.providerTypeId : null,
    }, {
      date: [
        'submitClaimsDateFrom',
        'submitClaimsDateTo',
        'schedulePatientsDateFrom',
        'schedulePatientsDateTo',
        'providerActiveSinceFrom',
        'providerActiveSinceTo',
        'dob',
      ],
      file: [
        'signatureDocument',
      ],
      isDateToString: true,
      string: ['patientAgeLimitIds', 'subSpecialtyIds'],
    });
    return mapKeys(formValues, (value, key) => {
      if (key?.toLowerCase?.() === 'signaturedocument') {
        return 'FileInfo.File';
      }
      if (fileInfoType.includes(key?.toLowerCase())) {
        return `FileInfo.${key}`;
      }
      return `Provider.${key}`;
    });
  }, []);

  const initialDataParser = useCallback((providerInfo) => {
    const signatureDocument = [];
    if (providerInfo.signatureOnFile) {
      signatureDocument.push({
        uid: '-1',
        name: providerInfo.signatureFileName,
        status: 'done',
        url: providerInfo.signatureFileURL,
        type: providerInfo.signatureFileType,
      });
    }
    const otherFields = formFieldValueParser(providerInfo, {
      date: [
        'submitClaimsDateFrom',
        'submitClaimsDateTo',
        'schedulePatientsDateFrom',
        'schedulePatientsDateTo',
        'providerActiveSinceFrom',
        'providerActiveSinceTo',
        'dob',
      ],
    });
    return {
      ...otherFields,
      signatureDocument,
      copyNotes: otherFields?.copyNotes?.split(''),
      providerTypeId: otherFields?.providerTypeId ? otherFields?.providerTypeId : null,
    };
  }, []);

  const handleFormData = useCallback((value) => {
    setFormData({ ...value });
  }, []);

  const onClear = useCallback((resetForm) => {
    setClear(true);
    resetForm();
  }, [setClear]);

  const navigateToAddUser = useCallback(() => {
    localStorage.setEncryptedData('providerFormData', JSON.stringify(formData));
    navigateToInsuranceProfile({
      ...params,
      id: formData?.userId,
      data: {
        name: formData?.firstName || `${formData?.middleName}` || `${formData?.lastName}` || '',
        showFullTitle: true,
      },
    });
  }, [formData, navigateToInsuranceProfile, params]);

  return (
    <Form
      section
      form={form}
      parser={parser}
      initialDataParser={initialDataParser}
      name={formName.providerForm}
      listId={listId.PROVIDERS}
      formId={formId.EDIT_PROVIDER_GENERAL}
      tabId={tabId}
      onRequestComplete={onRequestComplete}
      isUpdate
      getUrl={`${apiUrls.GET_PROVIDER}/${id}`}
      url={`${apiUrls.UPDATE_PROVIDER}/${id}`}
      contentType={contentType.MULTIPART}
      onGetResponseComplete={handleFormData}
      disableForm={isProviderViewOnly}
      refetchId="refetchProviderData"
    >
      {({ resetForm }) => (
        <div className="main-content-area">
          <div className="add-patient-cl">
            <ProviderInformation
              form={form}
              isUpdate
              data={formData}
              labels={labels}
              isClear={isClear}
              setClear={setClear}
            />
          </div>
          <Form.Section>
            <div className="btns-action">
              <Button id="provider_general_update" className="btn btn-success min-wt-86" data-testid="provider-update-button" type="submit">{labels.get('buttons.update')}</Button>
              <Button id="provider_general_clear" className="btn" data-testid="provider-clear-button" onClick={() => onClear(resetForm)}>{labels.get('buttons.clear')}</Button>
              {formData?.userId && <Button className="btn" onClick={navigateToAddUser} id="users_general_cancel">{labels.get('buttons.addUser')}</Button>}
            </div>
          </Form.Section>
        </div>
      )}
    </Form>
  );
}

const mapDispatchToProps = (dispatch) => ({
  clearOtherPropsOfProvider: () => dispatch(clearOtherProps()),
});

export default connect(
  null, mapDispatchToProps,
)(WithLabel(General, labelPaths.EDIT_PROVIDER_GENERAL));
