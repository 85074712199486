import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import Button from '../../../../../../../../../../components/Button';
import Modals from '../../../../../../../../../../components/Modal';

const ApplyExamFindingModal = ({
  isVisible, toggleModal, patientId, encounterId,
  favouriteId, applyOrderSet, setApplyOrderSetPayload,
}) => {
  const [isClicked, setIsClicked] = useState();

  const handleCopyPreviousOptions = useCallback(({ target: { name } }) => {
    setIsClicked(name);
    const payload = {
      patientid: patientId,
      encounterId,
      favoriteId: favouriteId,
      copyOption: name,
    };
    setApplyOrderSetPayload(payload);
    applyOrderSet({
      data: payload,
    });
  }, [applyOrderSet, encounterId, favouriteId, patientId]);

  return (
    <Modals
      width={400}
      destroyOnClose
      visible={isVisible}
      toggleModal={toggleModal}
      title="“Replace” or “Add to” your exam findings?"
      footer={null}
      className="order-set-copy-ui"
    >
      <p>
        There is an exam finding attached to this order set
        would you like to “Replace” or “Add to” your exam findings?

      </p>
      <div className="flex justify-content-sp-bt">
        <Button
          name="addToCurrent"
          className={classNames(isClicked === 'addToCurrent' ? 'btn btn-success' : '')}
          onClick={handleCopyPreviousOptions}
        >
          Add to Current
        </Button>
        <Button
          name="replaceCurrent"
          className={classNames(isClicked === 'replaceCurrent' ? 'btn btn-success' : '')}
          onClick={handleCopyPreviousOptions}
        >
          Replace Current
        </Button>
      </div>
    </Modals>
  );
};

export default ApplyExamFindingModal;
