import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import { useSelector } from 'react-redux';
import get from 'lodash/get';
import WithLabel from '../../../../hoc/withLabel';
import useRedirect from '../../../../hooks/useRedirect';
import {
  labelPaths, UiRoutes, listIds, enums, enumMasterCode,
} from '../../../../lib/constants';

import MarkForFollowUp from './Components/MarkForFollowUp';
import PatientPaymentPopUp from './Components/PatientPaymentPopUp';
import CreateNewClaimsPopup from './Components/CreateNewClaimPopup';
import ChangeResponsibilityPopup from './Components/ChangeResponsibility';
import BillPatientPopup from './Components/BillPatient';

import Tabs from './Tabs';
import SideTabs from './SideTabs';

import './Collection.scss';

import { apiUrls } from '../../../../api/constants';
import ActionIcons from '../../../../wiredComponents/ActionIcons';
import SavedNavigationBar from '../../../../components/SavedNavigationBar';
import { getCrudDataById } from '../../../../store/selectors';
import useCRUD from '../../../../hooks/useCRUD';
import useMasterId from '../../../../hooks/useMasterId';

const keysToMapStage = {
  Denial: 'BillingStageDenial',
  Manual: 'BillingStageManual',
  'No Response': 'BillingStageNoResponse',
};

const keysToMapState = {
  new: 'BillingStateNew',
  inProgress: 'BillingStateInProgress',
  needsAction: 'BillingStateNeedAction',
  smallBalance: 'BillingStateSmallBalance',
  completed: 'BillingStateCompleted',
};

const masterCodes = [
  enumMasterCode.billingStateNew,
  enumMasterCode.billingStateInProgress,
  enumMasterCode.billingStateNeedAction,
  enumMasterCode.billingStateSmallBalance,
  enumMasterCode.billingStateCompleted,
  enumMasterCode.billingStageDenial,
  enumMasterCode.billingStageManual,
  enumMasterCode.billingStageNoResponse,
  enumMasterCode.collectionQueue,
];

const claimFilterMap = {
  new: 'New',
  inProgress: 'In Progress',
  needsAction: 'Needs Action',
  smallBalance: 'Small Balance',
  completed: 'Completed',
};

const typeMap = {
  denial: 'Denial',
  noResponse: 'No Response',
};

const Collection = () => {
  const {
    params: {
      claimFilter, type, claimNumber, tabId, id: patientId,
    },
  } = useRedirect();

  const masterCodesWithId = useMasterId(masterCodes,
    [enums.BILLING_STATE, enums.BILLING_STAGE, enums.CLAIMS_QUEUE]);

  const stateId = masterCodesWithId[keysToMapState[claimFilter]];
  const stageId = masterCodesWithId[keysToMapStage[type]];
  const queueId = masterCodesWithId?.CollectionQueue;

  const [claimData,,, getClaimsData, clear] = useCRUD({ id: `${listIds.CLAIM_DETAILS}-${tabId}`, url: apiUrls.GET_CLAIM_DETAILS, type: 'read' });
  const savedClaimData = useSelector((state) => getCrudDataById(state, 'patient-claim-details', tabId));
  const { patient = {} } = savedClaimData || {};

  useEffect(() => {
    getClaimsData({}, `/${claimNumber}`);
    return () => clear(true);
  }, [claimNumber]);

  return (
    <SavedNavigationBar
      listName="Claims List"
      parentPath={UiRoutes.claims}
      rightChildren={(
        <ActionIcons
          print
          userNotes
          edit
          documentsWithDropDown
          transaction
          auditLogs
          claimsPreview
          printProps={{
            patientId: patientId || patient?.patientId,
            superbill: true,
            facesheet: true,
            detailsStatement: true,
            appointment: true,
            clinicalSummary: true,
            includeLatestAppointmentSuperBill: true,
          }}
          patient={patient}
          notesProps={{
            page: 'claims',
          }}
          patientId={patient?.patientId || patientId}
          claimNumber={claimNumber}
        />
)}
    >
      {({ closeTab }) => (
        <div className="white-tabs">
          <div className="collection-tab">
            <div className="flex justify-content-sp-bt">
              <div className="mr-bt-8">
                {claimFilterMap[claimFilter] || ''}
                {' '}
                {'>'}
                {typeMap[type] || ''}
                {'  '}
                Claim:
                {' '}
                {get(claimData?.billingEncounterData?.serviceLineItems?.[0]?.claimDetails, 'claimNo', '')}
              </div>
            </div>
            <div className="claim-container">
              <SideTabs />
              <Tabs />
            </div>
            <Switch>
              <Route path={UiRoutes.createNewClaimsPopup}>
                <CreateNewClaimsPopup
                  stateId={stateId}
                  stageId={stageId}
                  queueId={queueId}
                />
              </Route>
              <Route path={UiRoutes.markForFollowUpPopUp}>
                <MarkForFollowUp closeTab={closeTab} />
              </Route>
              <Route path={UiRoutes.patientPaymentPopUp}>
                <PatientPaymentPopUp />
              </Route>
              <Route path={UiRoutes.changeResponsibilityPopUp}>
                <ChangeResponsibilityPopup
                  stateId={stateId}
                  stageId={stageId}
                  queueId={queueId}
                />
              </Route>
              <Route path={UiRoutes.billPatientPopUp}>
                <BillPatientPopup
                  stateId={stateId}
                  stageId={stageId}
                  queueId={queueId}
                />
              </Route>
            </Switch>
          </div>
        </div>
      )}
    </SavedNavigationBar>
  );
};
export default WithLabel(Collection, labelPaths.CLAIMS);
