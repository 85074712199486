import React from 'react';

import { UiRoutes } from '../../../../../lib/constants';
import { getName } from '../../../../../lib/util';

import Link from '../../../../../components/SavedNavigationBar/Link';
import { commonPatientColumns } from '../../../../Patients/Table/columns';

const columns = (labels) => [
  {
    Header: labels.get('tableColumns.name'),
    accessor: 'firstName',
    Cell: ({
      row: {
        original: {
          patientId, firstName, middleName, lastName, id,
        },
      },
    }) => (
      <Link
        to={UiRoutes.SurgerySteps}
        params={{
          id: patientId || id, tab: 'summary', parentTab: 'patient-information', data: { name: getName(firstName, middleName, lastName) },
        }}
      >
        {getName(firstName, middleName, lastName)}
      </Link>
    ),
    sort: true,
  },
  ...commonPatientColumns(labels),
];

export default columns;
