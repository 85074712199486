import PropTypes from 'prop-types';

const commonDefaultPropType = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  initialValue: PropTypes.objectOf(PropTypes.any),
  labelSpan: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  inputSpan: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  onSelect: PropTypes.func,
  styleWidth: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
  dropdownMatchSelectWidth: PropTypes.oneOf([PropTypes.number, PropTypes.bool]),
  params: PropTypes.objectOf(PropTypes.string),
  placeholder: PropTypes.string,
  label: PropTypes.string,
};

export const commonReffPhysicianAndAddressBookAutoCompleteDefaultPropType = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  initialValue: PropTypes.objectOf(PropTypes.any),
  labelSpan: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  inputSpan: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  onSelect: PropTypes.func,
  styleWidth: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
  dropdownMatchSelectWidth: PropTypes.oneOf([PropTypes.number, PropTypes.bool]),
};

export default commonDefaultPropType;
