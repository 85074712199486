import React from 'react';
import { Input } from 'antd';

import './index.scss';

const TextInput = ({ label, Component, ...props }) => (
  <div className="custom-text-input">
    <label className="ant-form-item-no-colon">{label}</label>
    <Component {...props} />
  </div>
);

TextInput.defaultProps = {
  Component: Input,
};

export default React.memo(TextInput);
