import React from 'react';

import { UiRoutes } from '../../../lib/constants';

import TimeCell from '../../../components/Cells/TimeCell';
import StatusCell from '../../../components/StatusCell';
import Link from '../../../components/SavedNavigationBar/Link';

const columns = (labels) => ([
  {
    Header: labels.get('tableColumns.name'),
    accessor: 'name',
    sort: true,
    Cell: ({ row: { original: { name, contractId } } }) => (
      <Link
        to={UiRoutes.editContractWithTabId}
        className="blue-link"
        params={{ id: contractId, tab: 'general', data: { name } }}
      >
        {name}
      </Link>
    ),
  },
  {
    Header: labels.get('tableColumns.status'),
    accessor: 'status',
    reverseSort: true,
    Cell: ({ row: { original: { status } } }) => <StatusCell active={status} />,
  },
  {
    Header: labels.get('tableColumns.lastModified'),
    accessor: 'modifiedOn',
    sort: true,
    Cell: ({ row: { original: { modifiedOn } } }) => <TimeCell value={modifiedOn} />,
  },
  {
    Header: labels.get('tableColumns.lastModifiedBy'),
    accessor: 'modifiedByName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.pendingRecalculation'),
    accessor: 'pendingRecalculation',
    sort: true,
    Cell: ({ row: { original: { pendingRecalculation } } }) => {
      const value = pendingRecalculation ? 'Yes' : 'No';
      return (
        <span>
          {value}
        </span>
      );
    },
  },
]);

export default columns;
