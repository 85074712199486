import React, { useCallback } from 'react';
import { Form as AntdForm } from 'antd';

import Notification from '../../../../components/Notification';
import Button from '../../../../components/Button';
import Form from '../../../../components/Form';
import useRedirect from '../../../../hooks/useRedirect';
import WithLabel from '../../../../hoc/withLabel';
import {
  UiRoutes, labelPaths, listIds, formId,
} from '../../../../lib/constants';
import SuccessMessages from '../../../../lib/successMessages';
import { apiUrls } from '../../../../api/constants';
import formFieldValueParser from '../../../../lib/formFieldValuesParser';

import ExamroomForm from '../../components/ExamroomForm';

function EditExamroom({
  parentParams,
  labels,
  isLocationViewOnly,
}) {
  const { params, push, generatePath } = useRedirect();
  const { examId } = params;
  const [form] = AntdForm.useForm();

  const goBack = useCallback(() => {
    push(generatePath(UiRoutes.editLocationWithTabId, { ...parentParams }));
  }, [push, generatePath, parentParams]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.EXAM_ROOM_UPDATED_SUCCESSFULLY, success: true });
      goBack();
    }
  }, [goBack]);

  const parser = useCallback((values) => formFieldValueParser(values, {
    bool: [
      'isActive',
    ],
  }), []);
  return (
    <Form
      form={form}
      noOfColumns={2}
      onRequestComplete={onRequestComplete}
      listId={listIds.LOCATION_EXAMROOM_LIST}
      formId={formId.EDIT_EXAM_ROOM}
      parser={parser}
      initialDataParser={parser}
      initialData={{ isActive: false }}
      url={`${apiUrls.UPDATE_EXAMROOM}/${examId}`}
      getUrl={`${apiUrls.GET_EXAMROOM_DETAIL}/${examId}`}
      isUpdate
      editKey={examId}
      disableForm={isLocationViewOnly}
    >
      {({ resetForm }) => (
        <div className="pannel back-pannel">
          <div className="pannel-heading">
            <p role="presentation" className="back-arrow" onClick={goBack} id="locations_exam_room_back">{labels.get('buttons.goBack')}</p>
          </div>
          <div className="pannel-body">
            <ExamroomForm
              form={form}
              labels={labels}
              goBack={goBack}
            />
            <div className="form-btns">
              <Button className="btn btn-success" type="primary" htmlType="submit" id="locations_exam_room_save">{labels.get('buttons.update')}</Button>
              <Button className="btn btn-ghost" onClick={resetForm} id="locations_exam_room_cancel">{labels.get('buttons.clear')}</Button>
            </div>
          </div>
        </div>
      )}
    </Form>
  );
}

export default (WithLabel(EditExamroom, labelPaths.EDIT_LOCATION_EDIT_EXAMROOM));
