import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form as AntdForm } from 'antd';

import isFunction from 'lodash/isFunction';
import Notification from '../../../../../components/Notification';
import Input from '../../../../../components/Form/Input';
import Form from '../../../../../components/Form';
import { AutoComplete } from '../../../../../components/Form/inputFields';
import PhoneNumberInput from '../../../../../components/Form/PhoneNumberInput';
import { formId, enums as enumConstant, labelPaths } from '../../../../../lib/constants';
import SuccessMessages from '../../../../../lib/successMessages';

import { apiUrls } from '../../../../../api/constants';

import { Select } from '../../../../Enum';
import WithLabel from '../../../../../hoc/withLabel';
import Button from '../../../../../components/Button';

const RequestNewPayerForm = (props) => {
  const {
    labels, toggleModal,
  } = props;
  const [form] = AntdForm.useForm();
  const [country, setCountry] = useState(0);
  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      form.resetFields();
      if (isFunction(toggleModal)) {
        toggleModal();
      }
      Notification({ message: SuccessMessages.PAYER_REQUESTED_SUCCESSFULLY, success: true });
    }
  }, [form, toggleModal]);

  const onZipCodeSelect = useCallback((option = {}) => {
    form.setFields([{ name: 'city', value: option.city }]);
    form.setFields([{ name: 'stateId', value: option.stateId }]);
    form.setFields([{ name: 'countryId', value: option.countryId }]);
    setCountry(option.countryId);
  }, [form]);

  const optionParser = useCallback((option) => ({
    name: option.zipCode,
    value: option.zipCode,
    ...option,
  }), []);

  return (
    <>
      <Form
        section
        form={form}
        listId="PAYERS"
        formId={formId.REQUEST_NEW_PAYER}
        url={apiUrls.REQUEST_NEW_PAYER}
        onRequestComplete={onRequestComplete}
      >
        <div className="pannel back-pannel">
          <div className="pannel-body">
            <Form.Section noOfColumns={2}>
              <Form.Column>
                <Input
                  label={labels.get('labels.name')}
                  id="1"
                  name="name"
                  required
                  charOnly
                />
                <Input
                  label={labels.get('labels.additionalName')}
                  name="additionalName"
                  id="2"
                  charOnly
                />
                <Input
                  label={labels.get('labels.addressLine1')}
                  name="addressLine1"
                  id="3"
                  required
                />
                <Input
                  label={labels.get('labels.addressLine2')}
                  name="addressLine2"
                  id="3"
                />
                <Select
                  label={labels.get('labels.country')}
                  name="countryId"
                  id="9"
                  required
                  initialValue={form.getFieldValue('countryId') || 'United States'}
                  form={form}
                  enumName={enumConstant.COUNTRY}
                  disabled
                />
                <Input
                  label={labels.get('labels.comments')}
                  name="comments"
                  id="3"
                />
              </Form.Column>
              <Form.Column>
                <AutoComplete
                  label={labels.get('labels.zip')}
                  name="zip"
                  id="3"
                  labelSpan="10"
                  inputSpan="14"
                  optionParser={optionParser}
                  url={apiUrls.ZIP_LOOKUP}
                  required
                  onSelect={onZipCodeSelect}
                  optionMaster="zipcode"
                />
                <Input
                  disabled
                  label={labels.get('labels.city')}
                  name="city"
                  id="3"
                  required
                />
                <Select
                  label={labels.get('labels.state')}
                  name="stateId"
                  id="9"
                  required
                  enumName={enumConstant.STATE}
                  parentId={country}
                  disabled
                />
                <PhoneNumberInput
                  label={labels.get('labels.phone')}
                  name="phone"
                  id="3"
                  numberOnly
                  required
                  inputSpan="14"
                  labelSpan="10"
                  maxValueLength={10}
                  minValueLength={10}
                  inputProps={{ 'data-testid': 'phoneNumber-input' }}
                />
                <Input
                  label={labels.get('labels.ext')}
                  name="ext"
                  id="3"
                  numberOnly
                  maxValueLength={10}
                />
                <Input
                  label={labels.get('labels.fax')}
                  name="fax"
                  id="3"
                  maxValueLength={12}
                  minValueLength={10}
                />
              </Form.Column>
            </Form.Section>
          </div>
        </div>
      </Form>
      <div className="ant-modal-footer ">
        <div className="group-btns">
          <Button id="provider_credentials_cancel" className="btn min-wt-86" onClick={toggleModal}>{labels.get('buttons.cancel')}</Button>
          <Button id="provider_credentials_save" className="btn btn-success min-wt-86" onClick={() => form.submit()}>{labels.get('buttons.save')}</Button>
        </div>
      </div>
    </>
  );
};

RequestNewPayerForm.defaultProps = {
  toggleModal: () => { /* This is intentional */ },
  labels: {},
};

RequestNewPayerForm.propTypes = {
  toggleModal: PropTypes.func,
  labels: PropTypes.objectOf(PropTypes.object),
};

export default WithLabel(RequestNewPayerForm, labelPaths.PAYER_MODAL);
