import React from 'react';

import useMasterId from '../../../../../hooks/useMasterId';
import { enumMasterCode, enums } from '../../../../../lib/constants';

import InsuranceAdjustmentForm from './insuranceAdjustmentForm';

const masterCodes = [
  enumMasterCode.patientAdjustment,
  enumMasterCode.patientAdjustmentReversed,
];

const enumNames = [
  enums.CHARGE_TYPE,
];
const PatientAdjustment = ({ financialDetail, isReversalForm }) => {
  const transactionTypeMasterId = useMasterId(masterCodes, enumNames);
  return (
    <InsuranceAdjustmentForm
      financialDetail={financialDetail}
      isUseAdjustmentReason={transactionTypeMasterId?.PatientAdjustment
        === financialDetail?.transactionTypeId || transactionTypeMasterId?.PatientAdjustmentReversed
        === financialDetail?.transactionTypeId}
      isReversalForm={isReversalForm}
    />
  );
};

export default PatientAdjustment;
