import React, {
  useCallback, useState, useEffect, useMemo,
} from 'react';

import Modals from '../../../../../../components/Modal';
import Button from '../../../../../../components/Button';
import Notification from '../../../../../../components/Notification';
import WidgetLoader from '../../../../../../components/WidgetLoader';

import ErrorMessages from '../../../../../../lib/errorMessages';
import SuccessMessages from '../../../../../../lib/successMessages';
import {
  labelPaths, UiRoutes, listId, listIds,
} from '../../../../../../lib/constants';

import WithLabel from '../../../../../../hoc/withLabel';

import useRedirect from '../../../../../../hooks/useRedirect';
import useCRUD from '../../../../../../hooks/useCRUD';

import { apiUrls } from '../../../../../../api/constants';

import PatientClaimTable from '../../../../../../wiredComponents/CollectionClaimsTable';
import Events from '../../../../../../lib/events';

const ChangeResponsibilityPopup = ({
  labels, queueId, stateId, stageId,
}) => {
  const [selectedRows, setSelectedRow] = useState([]);

  const {
    params, push, generatePath,
  } = useRedirect();

  const { claimNumber, tabId } = params;
  const [claimsData] = useCRUD({ id: `${listIds.CLAIM_DETAILS}-${tabId}`, url: apiUrls.GET_CLAIM_DETAILS, type: 'read' });

  const {
    patientName, claimId, currentPayerName, encounterId,
  } = useMemo(() => {
    if (claimsData) {
      const { billingEncounterData } = claimsData;
      let patientFullName = billingEncounterData?.patient?.patientFirstName;
      if (billingEncounterData?.patient?.patientMiddleName) patientFullName += ` ${billingEncounterData?.patient?.patientMiddleName}`;
      if (billingEncounterData?.patient?.patientLastName) patientFullName += ` ${billingEncounterData?.patient?.patientLastName}`;
      return {
        patientName: patientFullName,
        claimId: billingEncounterData?.serviceLineItems[0]?.claimDetails?.claimId,
        currentPayerName: billingEncounterData?.insurancePayer?.payerName || billingEncounterData?.insurancePayer?.insurancePayerName || '',
        encounterId: billingEncounterData?.billingEncounterId,
      };
    }
    return {};
  }, [claimsData]);

  const [, , , getEncounter] = useCRUD({
    url: `${apiUrls.GET_PATIENT_ENCOUNTER}`,
    id: `${apiUrls.GET_PATIENT_ENCOUNTER}-billing-encounter-${encounterId}`,
    type: 'read',
  });

  const [response, , loading, updateResponsibility, clear] = useCRUD({
    id: listId.CHANGE_PATIENT_RESPONSIBILITY,
    url: apiUrls.CHANGE_PATIENT_RESPONSIBILITY,
    type: 'update',
  });

  const onCancel = useCallback(() => {
    push(generatePath(UiRoutes.claimsCollections, { ...params }));
  }, [push, generatePath, params]);

  useEffect(() => {
    if (response) {
      Notification({
        message: SuccessMessages.PAYMENT_RESPONSIBILITY_CHANGED_SUCCESSFULLY,
        success: true,
      });
      Events.trigger('collectionClaimTable');
      Events.trigger('refetch-claim-overview');
      if (encounterId) {
        getEncounter({ isCollectionCharges: true }, `/${encounterId}`);
      }
      clear();
      onCancel();
    }
  }, [response]);

  const changeResponsibilityHandler = useCallback(() => {
    if (!selectedRows?.length) {
      return Notification({ message: ErrorMessages.AT_LEAST_ONE_CLAIM_SELECTED });
    }
    return updateResponsibility({
      billingEncounterServiceLineIds: (
        selectedRows.map(({
          original: { billingEncounterServiceLineId },
        }) => billingEncounterServiceLineId)
      ),
      queueId,
      stageId,
      stateId,
      IsCollectionCharges: true,
    }, `/${claimId}`);
  }, [claimId, queueId, selectedRows, stageId, stateId, updateResponsibility]);

  return (
    <Modals
      visible
      width={1000}
      toggleModal={onCancel}
      className="change-responsibility-modal"
      footer={[
        <div className="group-btns" key="footer">
          <Button className="btn btn-success min-wt-86 inline" onClick={changeResponsibilityHandler}>
            {labels.get('buttons.changeResponsibility')}
          </Button>
          <Button className="btn min-wt-86" onClick={onCancel}>{labels.get('buttons.cancel')}</Button>
        </div>,
      ]}
    >
      <div className="addition-header posi-relative">
        {loading && <WidgetLoader />}
        <div className="lookup sprite-img-before">
          <p>{labels.get('titles.changeResponsibility')}</p>
        </div>
      </div>
      <p className="current-payer">
        {`${labels.get('titles.currentPayerName')} ${currentPayerName}`}
      </p>
      <p className="current-payer">
        {`Move Responsibility To : ${patientName}`}
      </p>
      <PatientClaimTable
        labels={labels}
        claimNumber={claimNumber}
        showRowSelection
        setSelectedRow={setSelectedRow}
        checkedRowIds={selectedRows}
        isCollectionCharges
      />
    </Modals>
  );
};

export default WithLabel(ChangeResponsibilityPopup, labelPaths.BILL_PATIENT_POPUP);
