import React, { useCallback, useEffect } from 'react';

import useCRUD from '../../../../../../../hooks/useCRUD';
import { apiUrls } from '../../../../../../../api/constants';
import downloadFile from '../../../../../../../lib/downloadFile';
import useRedirect from '../../../../../../../hooks/useRedirect';
import successMessages from '../../../../../../../lib/successMessages';
import { listIds, responseType } from '../../../../../../../lib/constants';

import Loader from '../../../../../../../components/Loader';
import Button from '../../../../../../../components/Button';
import Notification from '../../../../../../../components/Notification';

const ProofOfFilling = () => {
  const { params: { claimNumber } } = useRedirect();

  const [
    proofOfFillingResponse,,
    proofOfFillingLoading,
    proofOfFilling,
    clearProofOfFilling,
  ] = useCRUD({
    id: listIds.PROOF_OF_FILLING,
    url: apiUrls.PROOF_OF_FILLING,
    type: 'read',
  });

  useEffect(() => {
    if (proofOfFillingResponse && !Array.isArray(proofOfFillingResponse)) {
      Notification({
        message: successMessages.PROOF_OF_FILLING_SUCCESSFULLY,
        success: true,
      });
      downloadFile(proofOfFillingResponse, 'proofOfFilling.pdf', 'application/pdf');
      clearProofOfFilling(true);
    }
  }, [clearProofOfFilling, proofOfFillingResponse]);

  const handleClick = useCallback(() => {
    proofOfFilling({ responseType: responseType.ARRAY_BUFFER }, `/${claimNumber}`);
  }, [claimNumber, proofOfFilling]);

  return (
    <>
      {proofOfFillingLoading && <Loader />}
      <Button onClick={handleClick}>Proof of Filing</Button>
    </>
  );
};

export default ProofOfFilling;
