import React, { useCallback } from 'react';
import classNames from 'classnames';
import useRedirect from '../../../../../hooks/useRedirect';
import InsuranceVerificationShieldIcon from '../InsuranceVerificationShieldIcon';

const SubMenu = (props) => {
  const {
    title, children, profileId, onClick, isDefault, isActive, myPath, ...otherProps
  } = props;
  const onProfileClick = useCallback(() => {
    onClick(profileId);
  }, [onClick, profileId]);

  const active = isActive(myPath, { rpId: profileId, profileId });
  return (
    <li className={classNames('ant-menu-submenu ant-menu-submenu-inline', { 'active-default': isDefault })}>
      <div
        className={classNames('ant-menu-submenu-title', { 'ant-menu-item-selected': true, active })}
        style={{ paddingLeft: 8 }}
        onClick={onProfileClick}
        role="presentation"
        {...otherProps}
      >
        <span>
          {title}
        </span>
      </div>
      <ul className="ant-menu ant-menu-sub ant-menu-inline" role="menu">
        {children}
      </ul>
    </li>
  );
};

export const PolicySubMenuItem = (props) => {
  const {
    title,
    profileId,
    policyId,
    setActiveClassName,
    classKey,
    className,
    isActive,
    myPath,
    children,
    prevPolicyTypeId,
    nextPolicyTypeId,
    patientId,
    requestInsuranceData,
    moveItem,
    isUpdatePatientAuthenticated,
    isValidProfile,
    verificationStatus,
    lastVerificationDate,
    ...otherProps
  } = props;

  const { push, generatePath, params } = useRedirect();

  const active = isActive(myPath, { profileId, policyId, rpId: profileId });

  const navigateToEditPolicy = useCallback(() => {
    push(generatePath(myPath, { ...params, profileId, policyId }));
  }, [generatePath, myPath, params, policyId, profileId, push]);
  return (
    <div className="list-arrow-wrap">
      {isValidProfile && (
      <div className="wrap-up-down">
        {prevPolicyTypeId && isUpdatePatientAuthenticated && (
        <p
          className="ups"
          onClick={() => moveItem(policyId, profileId, prevPolicyTypeId)}
          aria-hidden
        />
        )}
        {nextPolicyTypeId && isUpdatePatientAuthenticated && (
        <p
          className="downs"
          onClick={() => moveItem(policyId, profileId, nextPolicyTypeId)}
          aria-hidden
        />
        )}
      </div>
      )}
      <li
        className={classNames('ant-menu-item ant-menu-item-only-child', { active }, className)}
        style={{ paddingLeft: 48 }}
        onClick={navigateToEditPolicy}
        role="presentation"
        title={title}
        {...otherProps}
      >
        {title}
      </li>
      <InsuranceVerificationShieldIcon
        verificationStatus={verificationStatus}
        lastVerificationDate={lastVerificationDate}
      />
    </div>
  );
};

export default SubMenu;
