import React, {
  useCallback, useState, useMemo, useEffect,
} from 'react';
import { Form as AntdForm } from 'antd';
import get from 'lodash/get';

import Form from '../../../../../../components/Form';
import Modals from '../../../../../../components/Modal';
import Button from '../../../../../../components/Button';

import {
  labelPaths, UiRoutes, listIds, formId,
} from '../../../../../../lib/constants';

import WithLabel from '../../../../../../hoc/withLabel';
import Notification from '../../../../../../components/Notification';

import useRedirect from '../../../../../../hooks/useRedirect';

import PatientClaimTable from '../../../../../../wiredComponents/CollectionClaimsTable';
import CreateNewClaimForm from './form';
import SuccessMessages from '../../../../../../lib/successMessages';
import Events from '../../../../../../lib/events';
import { apiUrls } from '../../../../../../api/constants';
import ErrorMessages from '../../../../../../lib/errorMessages';
import useCRUD from '../../../../../../hooks/useCRUD';
import Loader from '../../../../../../components/Loader';
import CreatePayerClaimPopup from './createPayerClaimPopup';
import AuthorizationAndCase from '../AuthorizationAndCase';

const CreateNewClaimPopup = (props) => {
  const [form] = AntdForm.useForm();
  const [payerClaimForm] = AntdForm.useForm();
  const {
    params, push, generatePath,
  } = useRedirect();
  const [tableSubTitle, setTableSubTitle] = useState();
  const {
    labels, queueId, stateId, stageId,
  } = props;
  const { claimNumber, tabId } = params;
  const [selectedRows, setSelectedRow] = useState([]);
  const [createPayerClaimPopUpVisibility, setCreatePayerClaimPopUpVisibility] = useState(false);
  const [isPayerClaimAdded, setIsPayerClaimAdded] = useState(false);
  const [claimDetails] = useCRUD({ id: `${listIds.CLAIM_DETAILS}-${tabId}`, url: apiUrls.GET_CLAIM_DETAILS, type: 'read' });

  const {
    claimId, patientId, insuranceProfileId, insuranceProfileDetailId, encounterId,
  } = useMemo(() => {
    if (claimDetails) {
      const { billingEncounterData } = claimDetails;
      return {
        claimId: billingEncounterData?.serviceLineItems[0]?.claimDetails?.claimId,
        patientId: billingEncounterData?.patient?.patientId,
        insuranceProfileId: billingEncounterData?.insurance?.insuranceProfileId,
        insuranceProfileDetailId: billingEncounterData?.insurance?.insuranceProfileDetailId,
        encounterId: billingEncounterData?.billingEncounterId,
      };
    }
    return {};
  }, [claimDetails]);

  const [encounterDataResponse, , , getEncounter] = useCRUD({
    url: `${apiUrls.GET_PATIENT_ENCOUNTER}`,
    id: `${apiUrls.GET_PATIENT_ENCOUNTER}-billing-encounter-${encounterId}`,
    type: 'read',
  });

  const [
    claimPDFResponse,,
    claimPDFLoader,
    getClaimPDF,
    clearClaimPDF,
  ] = useCRUD({
    id: apiUrls.GET_CLAIMS_PDF,
    url: apiUrls.GET_CLAIMS_PDF,
    type: 'create',
  });

  useEffect(() => {
    if (encounterId && !encounterDataResponse) {
      getEncounter({ isCollectionCharges: true }, `/${encounterId}`);
    }
  }, [encounterId]);

  useEffect(() => {
    if (encounterDataResponse && form && encounterId) {
      form.setFieldsValue({
        authorizationId: encounterDataResponse.authorization?.authorizationId,
        caseId: encounterDataResponse.encounterDetails?.caseId,
      });
    }
  }, [encounterDataResponse, form]);

  const submitClaim = useCallback(() => {
    if (form?.getFieldValue('sendAsCorrectedClaim') && !isPayerClaimAdded) {
      setCreatePayerClaimPopUpVisibility(true);
    } else if (!selectedRows?.length) {
      Notification({ message: ErrorMessages.AT_LEAST_ONE_CLAIM_SELECTED });
    } else {
      form.submit();
    }
  }, [form, isPayerClaimAdded, selectedRows]);

  const handlePayerClaimSave = useCallback(() => {
    if (!selectedRows?.length) Notification({ message: ErrorMessages.AT_LEAST_ONE_CLAIM_SELECTED });
    else form.submit();
  }, [form, selectedRows]);

  const toggleCreatePayerClaimPopup = useCallback(() => {
    setCreatePayerClaimPopUpVisibility((value) => !value);
  }, []);

  const handleSubmitClaim = useCallback(() => {
    if (!payerClaimForm.getFieldValue('payerClaimNo')) {
      return Notification({ message: ErrorMessages.PLEASE_FILL_ALL_REQUIRED_FIELDS });
    }
    setCreatePayerClaimPopUpVisibility(true);
    setIsPayerClaimAdded(true);
    toggleCreatePayerClaimPopup();
    handlePayerClaimSave();
    return null;
  }, [handlePayerClaimSave, payerClaimForm, toggleCreatePayerClaimPopup]);

  const onCancel = useCallback(() => {
    push(generatePath(UiRoutes.claimsCollections, { ...params }));
  }, [push, generatePath, params]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.CLAIM_ADDED_SUCCESSFULLY, success: true });
      if (encounterId) {
        getEncounter({ isCollectionCharges: true }, `/${encounterId}`);
      }
      onCancel();
      Events.trigger('refetch-claim-overview');
    }
  }, [encounterId, getEncounter, onCancel]);

  useEffect(() => {
    if (claimPDFResponse && !Array.isArray(claimPDFResponse)) {
      window.open(claimPDFResponse?.pdfUrl);
      clearClaimPDF(true);
    }
  }, [claimPDFResponse, clearClaimPDF]);

  /*
    * Whenever we add Payer Claim our New Claim Form should be submitted Automatically.
  */

  const handleViewPreview = useCallback(() => {
    const isClaimCorrected = form.getFieldValue('sendAsCorrectedClaim');
    getClaimPDF({ data: { claimIds: params?.claimNumber, isClaimCorrected } });
  }, [form, getClaimPDF, params.claimNumber]);

  return (
    <>
      <Modals
        visible
        toggleModal={onCancel}
        width={1000}
        footer={[
          <div className="group-btns" key="footer">
            <Button className="btn btn-success min-wt-86 inline" onClick={handleViewPreview}>{labels.get('buttons.viewPreview')}</Button>
            <Button className="btn btn-success min-wt-86 inline" onClick={submitClaim}>{labels.get('buttons.submitClaim')}</Button>
            <Button className="btn min-wt-86" onClick={onCancel}>{labels.get('buttons.cancel')}</Button>
          </div>,
        ]}
      >
        {claimPDFLoader && <Loader />}
        <div className="addition-header">
          <div className="lookup sprite-img-before">
            <p>{labels.get('titles.createNewClaim')}</p>
          </div>
        </div>
        <p>
          {`${labels.get('titles.subtitle')} ${tableSubTitle}`}
        </p>
        <Form
          form={form}
        >
          <AuthorizationAndCase
            key="create-new-claim"
            patientId={patientId}
            insuranceProfileId={insuranceProfileId}
            insuranceProfileDetailId={insuranceProfileDetailId}
            form={form}
            tabId={tabId}
          />
        </Form>
        <PatientClaimTable
          labels={labels}
          claimNumber={claimNumber}
          showRowSelection
          setSelectedRow={setSelectedRow}
          setTableSubTitle={setTableSubTitle}
          isCollectionCharges
          checkedRowIds={selectedRows}
        />
        <Form
          form={form}
          onRequestComplete={onRequestComplete}
          isUpdate
          formId={formId.NEW_CLAIM}
          url={`${apiUrls.CREATE_NEW_CLAIM}/${claimId}`}
          extraData={{
            billingEncounterServiceLineIds:
           selectedRows.map(({
             original: { billingEncounterServiceLineId },
           }) => billingEncounterServiceLineId),
            billingEncounterId: get(claimDetails?.billingEncounterData, 'billingEncounterId'),
            existingBillingClaimId: claimId,
            queueId,
            stateId,
            stageId,
            isCollectionCharges: true,
            payerClaimNo: payerClaimForm.getFieldValue('payerClaimNo'),
          }}
        >
          <CreateNewClaimForm
            labels={labels}
          />
        </Form>
      </Modals>
      {createPayerClaimPopUpVisibility && (
      <CreatePayerClaimPopup
        isVisible={createPayerClaimPopUpVisibility}
        toggleModal={toggleCreatePayerClaimPopup}
        claimNumber={claimNumber}
        form={payerClaimForm}
        handlePayerClaimSave={handleSubmitClaim}
      />
      )}
    </>
  );
};

export default WithLabel(CreateNewClaimPopup, labelPaths.CREATE_NEW_CLAIM_POPUP);
