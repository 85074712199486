import React, { useCallback, useEffect } from 'react';
import { Form as AntdForm } from 'antd';

import Events from '../../../../../lib/events';
import useCRUD from '../../../../../hooks/useCRUD';
import { formId } from '../../../../../lib/constants';
import { apiUrls } from '../../../../../api/constants';
import SuccessMessages from '../../../../../lib/successMessages';

import Form from '../../../../../components/Form';
import Modals from '../../../../../components/Modal';
import Loader from '../../../../../components/Loader';
import Button from '../../../../../components/Button';
import Notification from '../../../../../components/Notification';

import SaveForm from './SaveForm';

import '../../composer.scss';

const SaveHeaderFooterModal = ({
  toggleModal,
  isVisible,
  labels,
  isHeader,
  headerId,
  footerId,
  getHeaderHTML,
  getFooterHTML,
  savedHeaderFooterId,
  setSavedHeaderFooterId,
}) => {
  const [form] = AntdForm.useForm();

  const [
    saveHeaderFooterResponse,,
    saveHeaderFooterLoading,
    saveHeaderFooter,
    clearHeaderFooterResponse,
  ] = useCRUD({
    id: 'save-header-footer',
    url: apiUrls.GET_TEMPLATE_HEADER_FOOTER_LIST,
    type: 'create',
  });

  const [
    updateHeaderFooterResponse,,
    updateHeaderFooterLoading,
    updateHeaderFooter,
    clearHeaderFooterUpdateResponse,
  ] = useCRUD({
    id: 'update-header-footer',
    url: apiUrls.GET_TEMPLATE_HEADER_FOOTER_LIST,
    type: 'update',
  });

  useEffect(() => {
    if (saveHeaderFooterResponse && !Array.isArray(saveHeaderFooterResponse)) {
      Notification({
        message: isHeader
          ? SuccessMessages.HEADER_SAVED_SUCCESSFULLY
          : SuccessMessages.FOOTER_SAVED_SUCCESSFULLY,
        success: true,
      });
      if (isHeader) {
        setSavedHeaderFooterId({
          ...savedHeaderFooterId,
          header: saveHeaderFooterResponse?.composerTemplateHeaderFooterId,
        });
      } else {
        setSavedHeaderFooterId({
          ...savedHeaderFooterId,
          footer: saveHeaderFooterResponse?.composerTemplateHeaderFooterId,
        });
      }
      clearHeaderFooterResponse();
      if (isHeader) { Events.trigger('reFetchHeaderList'); } else { Events.trigger('reFetchFooterList'); }
      toggleModal();
    }
  }, [saveHeaderFooterResponse]);

  useEffect(() => {
    if (updateHeaderFooterResponse && !Array.isArray(updateHeaderFooterResponse)) {
      Notification({
        message: isHeader
          ? SuccessMessages.HEADER_UPDATED_SUCCESSFULLY
          : SuccessMessages.FOOTER_UPDATED_SUCCESSFULLY,
        success: true,
      });
      clearHeaderFooterUpdateResponse();
      if (isHeader) { Events.trigger('reFetchHeaderList'); } else { Events.trigger('reFetchFooterList'); }
      toggleModal();
    }
  }, [updateHeaderFooterResponse]);

  const onFinish = useCallback((data) => {
    if (isHeader) {
      if (data?.replaceExistingHeader) {
        updateHeaderFooter({
          name: data?.newHeaderName,
          templateText: getHeaderHTML(),
        }, `/header/${data?.existingHeader}`);
      } else {
        saveHeaderFooter({
          data: {
            name: data?.newHeaderName,
            templateText: getHeaderHTML(),
          },
        }, '/header');
      }
    } else if (data?.replaceExistingFooter) {
      updateHeaderFooter({
        name: data?.newFooterName,
        templateText: getFooterHTML(),
      }, `/footer/${data?.existingFooter}`);
    } else {
      saveHeaderFooter({
        data: {
          name: data?.newFooterName,
          templateText: getFooterHTML(),
        },
      }, '/footer');
    }
  }, [getFooterHTML, getHeaderHTML, isHeader, saveHeaderFooter, updateHeaderFooter]);

  return (
    <Modals
      visible={isVisible}
      toggleModal={toggleModal}
      destroyOnClose
      width={800}
      footer={[
        <div className="btns-action mr-left-60 mr-bt-12" key="footer">
          <Button className="btn btn-success min-wt-86" onClick={form.submit}>{labels.get('buttons.save')}</Button>
          <Button className="btn" onClick={toggleModal}>{labels.get('buttons.cancel')}</Button>
        </div>,
      ]}
      title={isHeader
        ? labels.get('titles.saveHeaderTemplate')
        : labels.get('titles.saveFooterTemplate')}
    >
      {(saveHeaderFooterLoading || updateHeaderFooterLoading) && <Loader />}
      <div className="mr-bt-20 settings-title-composer">
        {isHeader
          ? labels.get('titles.headerLabel')
          : labels.get('titles.footerLabel')}
      </div>
      <Form
        form={form}
        formId={isHeader ? formId.SAVE_COMPOSER_HEADER : formId.SAVE_COMPOSER_FOOTER}
        initialData={isHeader ? { existingHeader: headerId } : { existingFooter: footerId }}
        onFinish={onFinish}
      >
        <SaveForm labels={labels} isHeader={isHeader} />
      </Form>
    </Modals>
  );
};

export default SaveHeaderFooterModal;
