import React from 'react';
import TimeCell from '../../../../../components/Cells/TimeCell';
import Label from '../../../../../components/Label';

import { dateFormat } from '../../../../../lib/constants';
import dateFormatter from '../../../../../lib/dateFormatter';

const WithHoldForm = ({ financialDetail }) => {
  const createdOn = financialDetail?.createdOn ? `on ${dateFormatter(financialDetail?.createdOn, dateFormat)}` : '';
  return (
    <div className="max-width-100 table-layout-field">
      <form>
        <div className="feild-row justify-content-sp-bt align-center no-top-mr">
          <h2>Financial Details</h2>
        </div>
        <div className="feild-row display-view group-shadow">
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Payment Amount" />
              :
            </div>
            <div className="name-view">{(financialDetail?.amount || 0).toFixed(2)}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Un-Applied Credit" />
              :
            </div>
            <div className="name-view">{financialDetail?.unAppliedCredit ?? ''}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Payment Type" />
              :
            </div>
            <div className="name-view">{financialDetail?.financeDetails?.paymentTypeName ?? ''}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Entered By" />
              :
            </div>
            <div className="name-view">{`${financialDetail?.enteredBy ?? ''} ${createdOn}`}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Effective Date" />
              :
            </div>
            <div className="name-view"><TimeCell value={financialDetail?.effectiveDate} /></div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Posting Date" />
              :
            </div>
            <div className="name-view">11/10/2019</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Payment Method" />
              :
            </div>
            <div className="name-view">{financialDetail?.paymentMethod ?? ''}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Batch Identifier" />
              :
            </div>
            <div className="name-view">{financialDetail?.financeDetails?.batchIdentifier ?? ''}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Check Date" />
              :
            </div>
            <div className="name-view"><TimeCell value={financialDetail?.financeDetails?.checkDate} /></div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Check Amount" />
              :
            </div>
            <div className="name-view">{(financialDetail?.financeDetails?.checkAmount || 0).toFixed(2)}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Check Number" />
              :
            </div>
            <div className="name-view">{financialDetail?.financeDetails?.checkNumber ?? ''}</div>
          </div>
          <div className="input-wrap right-align-financial-charge">
            <div className="label-wrap">
              <Label label="Payment Source" />
              :
            </div>
            <div className="name-view">{financialDetail?.paymentSource ?? ''}</div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default WithHoldForm;
