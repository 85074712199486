import React from 'react';

import TimeCell from '../../components/Cells/TimeCell';
import { getName } from '../../lib/util';

export const columnsSharedWithAddAppointmentDialog = (onRowClick, labels) => [
  {
    Header: labels.get('tableHeadings.name'),
    accessor: 'name',
    Cell: (e) => {
      const { firstName, middleName, lastName } = e.row.original;
      if (typeof onRowClick === 'function') {
        return <span role="presentation" onClick={() => onRowClick(e.row.original)}>{getName(firstName, middleName, lastName)}</span>;
      }
      return <span>{getName(firstName, middleName, lastName)}</span>;
    },
    sort: true,
  },
  {
    Header: labels.get('tableHeadings.nextAppt'),
    accessor: 'nextAppDate',
    sort: true,
    Cell: ({ row: { original: { nextAppDate } } }) => <TimeCell value={nextAppDate} />,
  },
  {
    Header: labels.get('tableHeadings.lastClinicVisited'),
    accessor: 'lastLocationName',
  },
  {
    Header: labels.get('tableHeadings.lastVisited'),
    accessor: 'lastVisitedDate',
    sort: true,
    Cell: ({ row: { original: { lastVisitedDate } } }) => <TimeCell value={lastVisitedDate} />,
  },
];

const columns = (onRowClick, labels) => [
  ...columnsSharedWithAddAppointmentDialog(onRowClick, labels),
  {
    Header: labels.get('tableHeadings.chart'),
    accessor: 'chartNumber',
  },
  {
    Header: labels.get('tableHeadings.patientBal'),
    accessor: 'patientBal',
    sort: true,
    Cell: ({ row: { original: { patientBal } } }) => (
      (patientBal || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableHeadings.insuranceBal'),
    accessor: 'insuranceBal',
    Cell: ({ row: { original: { insuranceBal } } }) => (
      (insuranceBal || 0).toFixed(2)
    ),
    className: 'text-align-right',
    sort: true,
  },
];

export default columns;
