import React from 'react';

import StatusCell from '../../../../components/StatusCell';

import { UiRoutes } from '../../../../lib/constants';

import Link from '../../../../components/SavedNavigationBar/Link';
import TimeCell from '../../../../components/Cells/TimeCell';

const columns = (labels, isPermitted) => [
  {
    Header: labels.get('tableColumns.name'),
    accessor: 'providerName',
    Cell: ({ row: { original: { onRowClick } }, row }) => {
      if (!isPermitted) {
        return (
          <span>
            {row.original.providerName}
          </span>
        );
      }
      if (typeof onRowClick === 'function') {
        return (
          <Link onClick={() => onRowClick(row.original)}>
            {row.original.providerName}
          </Link>
        );
      }
      return <Link to={`${UiRoutes.editProviderWithTabId}`} params={{ id: row.original.providerId || row.original.id, tab: 'general', data: { name: row.original.name || row.original.providerName } }}>{row.original.name || row.original.providerName}</Link>;
    },
    sort: true,
  },
  {
    Header: labels.get('tableColumns.specialty'),
    accessor: 'specialtyName',
  },
  {
    Header: labels.get('tableColumns.status'),
    accessor: 'isActive',
    Cell: ({ row: { original: { isActive } } }) => <StatusCell active={isActive} />,
  },
  {
    Header: labels.get('tableColumns.lastModified'),
    accessor: 'modifiedOn',
    sort: true,
    Cell: ({ row: { original: { modifiedOn } } }) => <TimeCell value={modifiedOn} />,
  },
  {
    Header: labels.get('tableColumns.lastModifiedBy'),
    accessor: 'modifiedByName',
    sort: true,
  },
];

export default columns;
