import React from 'react';

import Icon from '../../../../components/Icon';
import IconNames from '../../../../lib/iconNames';

const payer = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Delete',
    Cell: ({
      row: { original: { insurancePayerId, contractPayerId } },
      deletePayer,
    }) => (
      <Icon
        name={IconNames.deleteIcon}
        onClick={() => deletePayer && deletePayer(insurancePayerId || contractPayerId)}
      />
    ),
    fixWidth: '60',
  },
];

export default payer;
