export const GET_PATHS = '@navigation/GET_PATHS';
export const NEW_PATH = '@navigation/NEW_PATH';
export const EDIT_PATH = '@navigation/EDIT_PATH';
export const DELETE_PATH = '@navigation/DELETE_PATH';
export const UPDATE_CURRENT_TAB_NAME = '@navigation/UPDATE_CURRENT_TAB_NAME';

export const getPaths = (payload) => ({
  type: GET_PATHS,
  id: payload.id,
});

export const setNewPath = (payload) => ({
  type: NEW_PATH,
  id: payload.id,
  data: payload,
});

export const editPath = (payload) => ({
  type: EDIT_PATH,
  id: payload.id,
  data: payload,
});

export const deletePath = (payload) => ({
  type: DELETE_PATH,
  id: payload.id,
  data: payload,
});

export const deleteTabData = (payload) => ({
  type: 'CLEAR_TAB_DATA',
  tabId: payload.tabId,
});
export const clearCurrentTab = (payload) => ({
  type: 'CLEAR_CURRENT_TAB',
  tabId: payload && payload.tabId,
});

export const updateCurrentTabName = (id, name, tabModuleName) => ({
  type: UPDATE_CURRENT_TAB_NAME,
  id,
  name,
  tabModuleName,
});
