import React, {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

import { useSelector } from 'react-redux';
import {
  formId, labelPaths, listId, listIds, UiRoutes,
} from '../../../../../lib/constants';
import SuccessMessages from '../../../../../lib/successMessages';
import formFieldValueParser from '../../../../../lib/formFieldValuesParser';
import ErrorMessages from '../../../../../lib/errorMessages';
import { getTableDataById } from '../../../../../store/selectors';

import Form from '../../../../../components/Form';
import Notification from '../../../../../components/Notification';
import Loader from '../../../../../components/Loader';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import Icon from '../../../../../components/Icon';

import PatientInformation from '../../../../Dashboard/Tabs/Demographic/PatientInformation';
import { apiUrls } from '../../../../../api/constants';
import WithLabel from '../../../../../hoc/withLabel';
import useTabLink from '../../../../../hooks/useTabLink';
import useCRUD from '../../../../../hooks/useCRUD';
import useReduxState from '../../../../../hooks/useReduxState';

function AddPatient(props) {
  const {
    labels, toggleModal, form, reFetch, shouldNavigate,
  } = props;
  const addDuplicatePatientRef = useRef(false);
  const [patientCareTeamsDataCopy, setPatientCareTeamsDataCopy] = useReduxState('patient-demographic-patientCareTeamsDataCopy');

  const [businessData, , ,
    getBusinessDetail] = useCRUD({
    id: listIds.BUSINESS_ENTITIES,
    url: apiUrls.GET_BUSINESS_ENTITY,
    type: 'read',
  });

  const businessEntityList = useSelector((state) => getTableDataById(state,
    listIds.BUSINESS_ENTITIES)?.data || null);

  const parentId = useMemo(() => {
    if (businessEntityList?.length || businessData?.result?.length) {
      const tempData = businessEntityList || businessData?.result;
      for (let i = 0; i < tempData?.length; i += 1) {
        if (!tempData[i]?.parentEntityId) return tempData[i]?.businessEntityId;
      }
    }
    return null;
  }, [businessData, businessEntityList]);

  const [businessEntitydata, , loading, getDetail] = useCRUD({
    id: `${apiUrls.GET_BUSINESS_ENTITY_PATIENT_BASICS}/${parentId}`,
    url: `${apiUrls.GET_BUSINESS_ENTITY_PATIENT_BASICS}/${parentId}`,
    type: 'read',
  });

  const parser = useCallback((patientInfo) => {
    const parsedValues = formFieldValueParser(patientInfo, {
      int: [
        'primaryPhoneExt',
        'secondaryPhoneExt',
      ],
      date: ['patientDeceasedDate'],
    });
    const filteredData = [];
    if (patientCareTeamsDataCopy && patientCareTeamsDataCopy.length) {
      patientCareTeamsDataCopy.forEach((data, index) => {
        const physician = patientCareTeamsDataCopy[index];
        if (physician?.providerName) filteredData.push(physician);
      });
    }
    parsedValues.patientCareTeams = filteredData;
    parsedValues.label = !isEmpty(parsedValues.label) ? parsedValues?.label?.join(',') : undefined;
    return parsedValues;
  }, [patientCareTeamsDataCopy]);

  useEffect(() => {
    if (!businessEntityList && !parentId) {
      getBusinessDetail();
    }
  }, [businessEntityList, getBusinessDetail, parentId]);

  useEffect(() => {
    if (businessEntitydata && !Array.isArray(businessEntitydata)) {
      form.setFieldsValue({
        ...businessEntitydata,
        PreferredContactMethodId: businessEntitydata.contactMethodId,
      });
    }
  }, [businessEntitydata]);

  useEffect(() => {
    if (parentId) {
      getDetail();
    }
  }, [parentId]);

  const { navigate: navigateToEditPatient } = useTabLink({
    to: UiRoutes.editPatientWithTabId,
  });

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.PATIENT_SAVED_SUCCESSFULLY, success: true });
      const careTeamData = isArray(patientCareTeamsDataCopy) && [...patientCareTeamsDataCopy];
      if (reFetch) {
        reFetch();
      }
      toggleModal();
      if (shouldNavigate) {
        navigateToEditPatient({
          data: {
            showFullTitle: true,
            name: `Insurance:${form.getFieldValue('firstName')}`,
          },
          id: response?.patientId,
          tab: 'insurance',
        });
      }
      setPatientCareTeamsDataCopy(careTeamData);
      form.resetFields();
    }
  }, [form, navigateToEditPatient, patientCareTeamsDataCopy, reFetch,
    setPatientCareTeamsDataCopy, shouldNavigate, toggleModal]);

  const onRequestFail = useCallback((message) => {
    if (message?.[0] === ErrorMessages.PATIENT_ALREADY_EXISTS) {
      ConfirmDialog({
        onOk: (close) => {
          addDuplicatePatientRef.current = true;
          form.submit();
          close();
        },
        onCancel: (close) => {
          close();
        },
        okText: 'Continue',
        title: 'Warning',
        content: ErrorMessages.PATIENT_ALREADY_EXISTS,
        icon: <Icon name="ExclamationCircleOutlined" />,
        cancelButtonProps: { className: 'ant-modal-continue-green-btn' },
        okButtonProps: { className: 'ant-modal-ok-white-btn' },
      })();
    } else if (message?.length) {
      Notification({ message: message?.[0], success: false });
    }
  }, [form]);

  return (
    <div className="main-content-area">
      {loading && <Loader /> }
      <Form
        form={form}
        section
        formId={formId.ADD_PATIENT}
        url={apiUrls.ADD_PATIENT}
        parser={parser}
        onRequestComplete={onRequestComplete}
        onRequestFail={onRequestFail}
        scrollToFirstErrors
        listId={listId.PATIENTS}
        initialValues={{
          isActive: true,
          primaryPhoneType: 'Home',
          secondaryPhoneType: 'Home',
        }}
      >
        <PatientInformation
          form={form}
          labels={labels}
          isAddForm
          patientCareTeamsDataCopy={patientCareTeamsDataCopy}
          setPatientCareTeamsDataCopy={setPatientCareTeamsDataCopy}
        />
      </Form>
    </div>
  );
}

AddPatient.defaultProps = {
  shouldNavigate: true,
};

export default WithLabel(AddPatient, labelPaths.ADD_PATIENT);
