import React, { useCallback, useEffect } from 'react';
import { Form as AntdForm } from 'antd';

import { apiUrls } from '../../../../../../../api/constants';
import formFieldValuesParser from '../../../../../../../lib/formFieldValuesParser';
import {
  formName,
} from '../../../../../../../lib/constants';

import Form from '../../../../../../../components/Form';
import Button from '../../../../../../../components/Button';

import CaseForm from './CaseForm';

const parser = (values) => formFieldValuesParser(values, {
  date: [
    'effectiveDateFrom',
    'effectiveDateTo',
    'lastSeenDate',
    'onSetDate',
    'hospitalizationDateFrom',
    'hospitalizationDateTo',
    'accidentDate',
  ],
});

const Case = ({
  labels, params, navigateToCases, onCancelButtonClicked,
  serviceDate, clearButtonRef, onAddRequestComplete, destroyOnClose = false,
}) => {
  const [form] = AntdForm.useForm();

  const { id: patientId, tabId } = params;

  useEffect(() => () => { if (destroyOnClose) { form.completeReset(); } }, []);

  const handleCaseTypeSelect = useCallback((value, allItem) => {
    const { item } = allItem || {};
    if (serviceDate && (['clinicaltrial', 'outpatient'].indexOf(item?.masterCode?.toLowerCase()) > -1)) {
      form.setFieldsValue({ onSetDate: serviceDate });
    } else {
      form.setFieldsValue({ onSetDate: '' });
    }
  }, [form, serviceDate]);

  return (
    <Form
      form={form}
      extraData={{
        patientId,
        caseTypeName: 'OutPatient', // this property will remove with fake
      }}
      parser={parser}
      formId={`${formName.patientCaseForm}-${patientId}`}
      name={`${formName.patientCaseForm}-${patientId}-${tabId}`}
      url={apiUrls.ADD_NEW_CASE}
      onRequestComplete={onAddRequestComplete}
      tabId={tabId}
    >
      <div>
        <CaseForm
          labels={labels}
          handleCaseTypeSelect={handleCaseTypeSelect}
          form={form}
          patientId={patientId}
        />
        <div className="btns-action">
          <Button className="btn btn-success min-wt-86" onClick={form.submit}>{labels.get('buttons.save')}</Button>
          <Button ref={clearButtonRef} className="btn" onClick={onCancelButtonClicked || navigateToCases}>{labels.get('buttons.cancel')}</Button>
        </div>
      </div>

    </Form>
  );
};

export default Case;
