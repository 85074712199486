import React, { useCallback, useState } from 'react';
import { apiUrls } from '../../../../../api/constants';
import { formId, listId, UiRoutes } from '../../../../../lib/constants';
import SuccessMessages from '../../../../../lib/successMessages';
import useRedirect from '../../../../../hooks/useRedirect';
import formFieldValueParser from '../../../../../lib/formFieldValuesParser';
import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import Notification from '../../../../../components/Notification';
import AddPayerForm from '../../Components/PayerForm';
import ContractList from '../ContractList';

const onRequestComplete = ({ response }) => {
  if (response) {
    Notification({ message: SuccessMessages.PAYER_UPDATED_SUCCESSFULLY, success: true });
  }
};

const General = ({
  form, labels, navigateBack, isPayerRequestViewOnly, ...props
}) => {
  const { params, push, generatePath } = useRedirect();
  const [contractTableData, setContractTableData] = useState([]);
  const initialParser = (values) => {
    const formData = formFieldValueParser(values, {
      string: ['ext'],
    });
    const zipCode = formData?.zip?.split('-');
    setContractTableData(formData.contracts);
    return {
      ...formData,
      zip: zipCode?.[0],
      zip2: zipCode?.[1],
    };
  };

  const { id } = params;
  const goBack = useCallback(() => {
    push(generatePath(UiRoutes.payer, { ...params }));
  }, [push, generatePath, params]);

  const parser = useCallback((formValues) => ({
    ...formValues,
    zip: formValues.zip2 ? formValues.zip.concat('-', formValues.zip2) : formValues.zip,
  }), []);

  return (
    <div className="main-content-area">
      <Form
        form={form}
        section
        scrollToFirstErrors
        formId={formId.EDIT_PAYER}
        url={`${apiUrls.EDIT_PAYER}/${id}`}
        listId={listId.EDIT_PAYER}
        getUrl={`${apiUrls.GET_PAYERS}/${id}`}
        onRequestComplete={onRequestComplete}
        isUpdate
        initialDataParser={initialParser}
        parser={parser}
        disableForm={isPayerRequestViewOnly}
      >
        {({ resetForm }) => (
          <div className="pannel back-pannel mr-top-20">
            <div className="pannel-heading">
              <p id="visit_type_goBack" className="back-arrow" onClick={goBack} role="presentation">{labels.get('buttons.goBack')}</p>
            </div>
            <div className="pannel-body">
              {/* <div className="main-form-container"> */}
              <div className="add-patient-cl">
                <AddPayerForm form={form} labels={labels} {...props} />
              </div>
              <div className="group-btns mr-top-12">
                <Button className="btn min-wt-86" id="patients_add_cancel" onClick={resetForm}>{labels.get('buttons.clear')}</Button>
                <Button className="btn btn-success min-wt-86" id="patients_add_save" type="submit">{labels.get('buttons.update')}</Button>
              </div>
              <div className="mr-top-20">
                <ContractList data={contractTableData} />
              </div>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

export default General;
