import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Dropdown } from 'antd';
import moment from 'moment';
import get from 'lodash/get';

import WithLabel from '../../../../../hoc/withLabel';

import { labelPaths } from '../../../../../lib/constants';
import iconNames from '../../../../../lib/iconNames';
import { statusBasedClasses } from '../../../../../lib/schedulerHelpers';
import rights from '../../../../../lib/rights';

import Tooltip from '../../../../../components/Tooltip';
import Icon from '../../../../../components/Icon';

import useRights from '../../../../../hooks/useRights';

const SingleLineComponent = ({
  item, onRemove, onEdit, handleCancelModal, labels, tooltipContent,
  dropdownProps, hideRightClickMenu, ...otherProps
}) => {
  const {
    startTime,
    examType = [],
    visitType = {},
    comments,
    confirmationMethod,
    confirmationOutcome,
    status,
    note,
    totalDoctorTime,
    totalWorkupTime,
    status: currentAppointmentStatus,
    insurancedetailId,
    insuranceVerificationDate,
    insuranceVerificationStatus,
  } = item;
  const [isHovered, isHovering] = useState(false);
  const patientTimeString = moment(startTime).format('h:mm A');
  const doctorTimeString = moment(startTime).add('minute', totalWorkupTime).format('h:mm A');
  const timeString = `${patientTimeString} | ${doctorTimeString}`;
  const examTypeString = Array.isArray(examType) && examType.length ? examType.map(({ examName }) => examName).join('|') : '';
  const visitTypeString = visitType ? get(visitType, 'visitName', '') : '';
  const commentsTypeString = comments || '';

  const onCancelAppointment = useCallback((e) => {
    e.stopPropagation();
    handleCancelModal(true);
  }, [handleCancelModal]);
  const [
    isDeleteAppointmentAuthenticated,
    isUpdateAppointmentAuthenticated,
  ] = useRights([rights.delete_appointment, rights.update_appointment]);

  const getOrangeClassName = useCallback((verificationStatus) => {
    let className = '';
    if (verificationStatus === 'Not verified' || verificationStatus === 'Verified with issues' || verificationStatus === 'Verification in progress') {
      className = 'appointment-orange-border';
    }
    return className;
  }, []);

  const handleHover = useCallback((isHover) => () => {
    isHovering(isHover);
  }, []);

  return (
    <Tooltip
      className="appointment-tooltip-container"
      content={tooltipContent && tooltipContent({
        title: labels.get('titles.tooltip'),
        name: item.patient && item.patient.name,
        patientTime: patientTimeString,
        doctorTime: doctorTimeString,
        visitType: visitTypeString,
        examType: examTypeString,
        comments: commentsTypeString,
        chartNumber: item?.patientChartNumber,
        status,
        note,
        confirmationMethod,
        confirmationOutcome,
        insurancedetailId,
        insuranceVerificationDate,
        insuranceVerificationStatus,
        getOrangeClassName,
      })}
    >
      <Dropdown trigger={hideRightClickMenu ? [] : ['contextMenu']} {...dropdownProps}>
        <div
          className={classNames(
            'grid-event-single-line-component',
            statusBasedClasses[status],
            getOrangeClassName(insuranceVerificationStatus),
            { 'one-liner': totalDoctorTime <= 5 },
          )}
          {...otherProps}
          onMouseEnter={handleHover(true)}
          onMouseLeave={handleHover(false)}
          data-testid="dropdown-trigger"
        >
          <div className="patient-name">
            {item.patient && item.patient.name}
          </div>
          <div className="patient-info">
            {timeString}
            {visitTypeString && ` | ${visitTypeString}`}
            {examTypeString && ` | ${examTypeString}`}
            {commentsTypeString && ` | ${commentsTypeString}`}
          </div>
          {!!onEdit && currentAppointmentStatus?.toLowerCase() === 'new' && isUpdateAppointmentAuthenticated && (
          <button
            style={{ opacity: isHovered ? 1 : 0, right: 20 }}
            onClick={onEdit}
            title="Edit Appointment"
            type="button"
          >
            <Icon name={iconNames.edit} />
          </button>
          )}
          {!!onRemove && !['billed', 'billedandpendingcheckout'].includes(status?.toLowerCase()) && isDeleteAppointmentAuthenticated && (
          <button
            style={{ opacity: isHovered ? 1 : 0 }}
            onClick={onCancelAppointment}
            title="Remove Appointment"
            type="button"
          >
            ×
          </button>
          )}
        </div>
      </Dropdown>
    </Tooltip>
  );
};

export default WithLabel(SingleLineComponent, labelPaths.APPOINTMENT_COMPONENT_SCHEDULAR);
