import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Form, DatePicker as DatePickerComponent } from 'antd';

import './datePicker.scss';

const DatePicker = (props) => {
  const {
    label,
    name,
    showTime = false,
    dateFormat,
    required,
    rules,
    onChange,
    value,
    className,
    component: Component,
    allowClear,
    disabled,
    disabledDate,
    testId,
    ...otherProps
  } = props;

  return (
    <Form.Item
      label={label}
      name={name || label}
      rules={[{ required }, ...rules]}
      {...otherProps}
    >
      <Component
        value={value}
        defaultValue={value}
        format={dateFormat}
        showTime={showTime}
        onChange={onChange}
        className={classNames('date-picker', className)}
        allowClear={allowClear}
        disabled={disabled}
        disabledDate={disabledDate}
        data-testId={testId}
      />
    </Form.Item>
  );
};

DatePicker.defaultProps = {
  label: '',
  required: false,
  showTime: false,
  dateFormat: 'MMM DD, YYYY',
  onChange: () => { /* This is intentional */ },
  rules: [],
  name: '',
  value: '',
  className: '',
  allowClear: true,
  disabled: false,
  component: DatePickerComponent,
  disabledDate: () => { /* This is intentional */ },
};

DatePicker.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  showTime: PropTypes.bool,
  allowClear: PropTypes.bool,
  dateFormat: PropTypes.string,
  onChange: PropTypes.func,
  rules: PropTypes.instanceOf(Array),
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  component: PropTypes.node,
  disabledDate: PropTypes.func,
};

export default React.memo(DatePicker);
