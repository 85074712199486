import React from 'react';

import AutoComplete from '../../../../../components/Form/AutoComplete';

import { apiUrls } from '../../../../../api/constants';

const optionParser = (option) => ({
  name: option.name,
  value: option.id,
  ...option,
});

const AssignToModal = () => (
  <AutoComplete
    id="userId"
    name="userId"
    url={apiUrls.USER_SUGGESTIVE_SEARCH}
    label="Assign To"
    optionMaster="searchText"
    optionParser={optionParser}
    inputSpan="17"
    labelSpan="7"
    required
    dropdownMatchSelectWidth
  />
);

export default AssignToModal;
