import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import classNames from 'classnames';

import api from '../../../../../api';
import { apiUrls } from '../../../../../api/constants';

import encode from '../../../../../lib/encode';
import { getName } from '../../../../../lib/util';
import { listId, UiRoutes } from '../../../../../lib/constants';

import useTabLink from '../../../../../hooks/useTabLink';
import useRedirect from '../../../../../hooks/useRedirect';

import withQuery from '../../../../../hoc/withQuery/withQuery';
import withReduxManager from '../../../../../hoc/withReduxManager';

import Button from '../../../../../components/Button';
import PatientAutoComplete from '../../../../../wiredComponents/PatientAutoComplete';

import TableComponent from '../../Components/TableComponent';

import columns from './columns';

const initialSort = [{ id: 'FirstName', desc: false }];

const CustomPatientTable = withReduxManager({
  listId: listId.RECENTLY_CREATED_PATIENTS,
})(withQuery({
  url: apiUrls.GET_PATIENT,
  listId: listId.BA_PATIENT,
  alias: {
  },
})());

const PatientTable = (props) => {
  const {
    handleReFetch, labels, form, setSelectedPatient, selectedPatient,
  } = props;
  const filtersRef = useRef();
  const [selectedPatientData, setSelectedPatientData] = useState({});

  const {
    replace, path, query, query: { patient }, location: { search },
  } = useRedirect();

  const { navigate: navigateToEditPatient } = useTabLink({
    to: UiRoutes.editPatientWithTabId,
  });

  const onRowClick = useCallback(({
    original: {
      patientId, firstName, middleName, lastName,
    },
  }) => {
    navigateToEditPatient({
      data: {
        showFullTitle: true,
        name: `Demographic:${getName(firstName, middleName, lastName)}`,
      },
      id: patientId,
      tab: 'demographic',
    });
  }, [navigateToEditPatient]);

  const onSelectPatient = useCallback((event, { item }) => {
    setSelectedPatientData(item || {});
    if (event?.target && event !== undefined) {
      const { target: { checked } } = event;
      if (checked) {
        setSelectedPatient(item?.patientId || '');
        replace({
          search: encode({ ...query, patient: item?.patientId || '', zipcode: item?.zip }),
          pathname: path,
        });
      } else {
        setSelectedPatient();
        replace({
          search: encode({ ...query, patient: undefined, zipcode: undefined }),
          pathname: path,
        });
      }
      return;
    }
    setSelectedPatient(item?.patientId || '');
    replace({
      search: encode({ ...query, patient: item?.patientId || '', zipcode: item?.zip }),
      pathname: path,
    });
  }, [path, query, replace, setSelectedPatient]);

  const openPatientDemographics = useCallback(async () => {
    if (patient) {
      let data = selectedPatientData;
      if (!selectedPatientData?.id) {
        data = await api.get({
          url: `${apiUrls.GET_PATIENT}/${patient}`,
          token: localStorage.getDecryptedData('token'),
        });
      }
      const { firstName, middleName, lastName } = data;
      navigateToEditPatient({
        data: {
          name: getName(firstName, middleName, lastName),
        },
        id: patient,
        tab: 'demographic',
      });
      localStorage.setEncryptedData('book-appointment-settings', search.substr(1));
    } else if (filtersRef.current) {
      const [input] = filtersRef.current.getElementsByTagName('input');
      if (input) input.focus();
    }
  }, [navigateToEditPatient, patient, search, selectedPatientData]);

  useEffect(() => {
    if (patient) {
      setSelectedPatient(parseInt(patient, 10));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomPatientTable
      onSelect={onSelectPatient}
      selectedPatient={selectedPatient}
      columns={columns()}
      initialSort={initialSort}
      noDataText="Patient not found"
      filters={{ RecentlyCreated: true }}
      {...props}
      onRowClick={onRowClick}
      rowClickable={false}
    >
      {({
        Component, reFetch,
      }) => (
        <>
          <div className="table-filters" id="ba-patient-filters-select-container">
            <div ref={filtersRef} className="filters-container">
              <PatientAutoComplete
                labels={labels}
                labelSpan="0"
                inputSpan="24"
                form={form}
                className="patient-autocomplete"
                onSelect={onSelectPatient}
                dropdownMatchSelectWidth={650}
                placeholder="Search Patient"
                isInputTextEditable
                params={{ PageSize: 50 }}
                valueAccessor="patientId"
              />
              <Button
                className={classNames(
                  'btn-success sm-btn patient-demographics-button',
                  { disabled: !patient },
                )}
                title={patient ? 'Patient Demographics' : 'Please Select Patient'}
                onClick={openPatientDemographics}
              >
                <span className="sprite-img redirect-patient-icon" />
              </Button>
            </div>
            <p className="title">Recently Saved Patients</p>

          </div>
          <TableComponent
            reFetch={reFetch}
            handleReFetch={handleReFetch}
            Component={Component}
          />
        </>
      )}
    </CustomPatientTable>
  );
};

export default PatientTable;
