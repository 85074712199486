import React from 'react';
import { apiUrls } from '../../../../../../../api/constants';
import TimeCell from '../../../../../../../components/Cells/TimeCell';
import Form from '../../../../../../../components/Form';
import Icon from '../../../../../../../components/Icon';
import WiredSelect from '../../../../../../../wiredComponents/Select';
import iconNames from '../../../../../../../lib/iconNames';

const providerLocationColumns = ([
  {
    Header: '',
    fixWidth: '50',
    accessor: 'expander',
    Cell: ({ row }) => {
      const expanderProps = row.getToggleRowExpandedProps();
      if (expanderProps.onClick) {
        const { onClick } = expanderProps;
        expanderProps.onClick = (e) => {
          e.stopPropagation();
          onClick(e);
        };
      }
      return (
        <span {...expanderProps} title="">
          <div>
            <Icon
              name={row?.isExpanded ? iconNames.minus : iconNames.plusIcon}
            />
          </div>
        </span>
      );
    },
  },
  {
    Header: 'Providers',
    accessor: 'providerName',
  },
  {
    Header: 'Effective From',
    accessor: 'effectiveFrom',
    Cell: ({ row: { original: { effectiveFrom } } }) => (
      <TimeCell value={effectiveFrom} format="MM-DD-YYYY hh:mm A" />
    ),
  },
  {
    Header: 'Effective To',
    accessor: 'effectiveTo',
    Cell: ({ row: { original: { effectiveTo } } }) => (
      <TimeCell value={effectiveTo} format="MM-DD-YYYY hh:mm A" />
    ),
  },
  {
    Header: 'Action',
    width: 220,
    Cell: ({
      row: { original }, handleProviderDelete, onChangeHandle,
      selectLocationForm, handleOptionsRender, handleLocationChange,
    }) => (
      <div className="provider-location-column-wrap">
        <Form form={selectLocationForm}>
          <WiredSelect
            id="location"
            name={['location', original?.key]}
            className="default-value-as-placeholder"
            url={apiUrls.SCHEDULAR_LOCATION_DROPDOWN}
            label=""
            placeholder="Add Locations"
            nameAccessor="locationName"
            valueAccessor="locationId"
            startCaseOptions
            selectProps={{
              showSearch: true,
              showArrow: true,
              style: { width: 180 },
              mode: 'multiple',
              maxTagCount: 'responsive',
            }}
            optionRenderer={handleOptionsRender}
            labelSpan="0"
            inputSpan="5"
            onChange={(location) => handleLocationChange(location, original)}
          />
        </Form>
        <span className="delete-icon">
          <Icon
            name={iconNames.edit}
            onClick={onChangeHandle(true, original)}
            color="#32c788"
          />
        </span>
        <span className="delete-icon">
          <Icon
            name={iconNames.deleteIcon}
            onClick={() => handleProviderDelete(original)}
            color="#32c788"
          />
        </span>
      </div>
    ),
  },
]);
export default providerLocationColumns;
