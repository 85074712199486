import React, { useCallback } from 'react';
import { Form as AntdForm } from 'antd';

import Notification from '../../../../components/Notification';
import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader';
import Form from '../../../../components/Form';

import useCRUD from '../../../../hooks/useCRUD';
import useRedirect from '../../../../hooks/useRedirect';
import useMasterId from '../../../../hooks/useMasterId';

import {
  labelPaths, formId, enumMasterCode, enums,
} from '../../../../lib/constants';
import { getInsuranceProfileRelationDetails, getSystemDate } from '../../../../lib/util';
import formFieldValuesParser from '../../../../lib/formFieldValuesParser';
import SuccessMessages from '../../../../lib/successMessages';

import WithLabel from '../../../../hoc/withLabel';

import { apiUrls } from '../../../../api/constants';

import PolicyForm from './PolicyForm';

const parser = (values) => {
  const parsedValues = formFieldValuesParser(values, {
    int: [
      'deductible',
      'coPayment',
      'outDeductible',
    ],
  });
  if (parsedValues?.effectiveFrom) {
    parsedValues.effectiveFrom = getSystemDate(parsedValues.effectiveFrom);
  }

  if (parsedValues?.effectiveTo) {
    parsedValues.effectiveTo = getSystemDate(parsedValues.effectiveTo);
  }

  parsedValues.primaryInsuredRelationId = parsedValues.responsibleRelationId;
  return parsedValues;
};

function NewPolicy(props) {
  const {
    labels, baseRoute, queryString, showInActiveInsuranceData,
  } = props;

  const { params, push, generatePath } = useRedirect();
  const {
    profileId, policyId, tabId, id,
  } = params;

  const [form] = AntdForm.useForm();
  const insuranceRelationSelfMasterId = useMasterId(
    [enumMasterCode.InsuranceRelationSelf],
    [enums.RELATION],
  );

  const requestInsuranceData = useCRUD({
    id: 'patient-insurance-rp', url: apiUrls.GET_PATIENT_INSURANCE, type: 'read',
  })[3];

  const onRequestComplete = ({ response }) => {
    if (response && response.insuranceProfileDetailId) {
      Notification({
        message: SuccessMessages.INSURANCE_POLICY_ADDED_SUCCESSFULLY, success: true,
      });
      requestInsuranceData({ PatientId: params.id, includeinactive: showInActiveInsuranceData });
      push({
        pathname: generatePath(`${baseRoute}/profile/view/:profileId`, {
          ...params,
        }),
        search: queryString,
      });
    }
  };

  const getExtraData = useCallback(() => {
    const policyEnumData = form.getFieldInstance('policyTypeId')?.props?.enumData;
    const selectedPolicy = form.getFieldInstance('policyTypeId')?.props?.value;
    const relationInstance = form.getFieldInstance('responsibleRelationId');
    const relationDetail = getInsuranceProfileRelationDetails(
      relationInstance?.props?.enumData,
      relationInstance?.props?.value,
    );
    const personId = form.getFieldValue('personId');
    const personType = form.getFieldValue('type');

    const selectedPolicyData = policyEnumData.filter((policy) => policy.value === selectedPolicy);
    let insurerType = '';
    if (personType) {
      insurerType = personType;
    } else {
      insurerType = 'Patient';
    }
    return {
      coPayment: 0,
      deductible: 0,
      primaryInsuredType: insurerType,
      profileCategoryId: profileId,
      profileName: relationDetail?.masterName,
      patientId: id,
      profileTypeId: policyId,
      policyTypeCode: selectedPolicyData[0]?.masterCode,
      planId: 1,
      primaryInsuredPatientId: insurerType === 'Patient' ? (personId || id) : null,
      primaryInsuredPersonId: insurerType !== 'Patient' ? (personId) : null,
    };
  }, [form, id, policyId, profileId]);

  const handleClear = useCallback((resetForm) => {
    resetForm();
  }, []);

  if (!insuranceRelationSelfMasterId[enumMasterCode.InsuranceRelationSelf]) {
    return <Loader />;
  }
  return (
    <Form
      section
      form={form}
      parser={parser}
      tabId={tabId}
      url={apiUrls.ADD_SECONDARY_INSURANCE_POLICY}
      formId={formId.ADD_SECONDARY_POLICY}
      onRequestComplete={onRequestComplete}
      extraData={getExtraData}
      initialData={{
        isActive: true,
        responsibleRelationId: insuranceRelationSelfMasterId[enumMasterCode.InsuranceRelationSelf],
      }}
    >
      {({ resetForm }) => (
        <div className="complete-form-wrap insurance-aside-wrap posi-relative">
          <Form.Section>
            <PolicyForm
              form={form}
              labels={labels}
              onRequestComplete={onRequestComplete}
              isPolicyHolder
              newPolicy
            />
          </Form.Section>
          <Form.Section>
            <div className="btns-action">
              <Button
                className="btn btn-success min-wt-86"
                id="patients_insurance_newPolicy_save"
                type="submit"
              >
                {labels.get('buttons.save')}
              </Button>
              <Button
                className="btn"
                id="patients_insurance_newPolicy_clear"
                onClick={() => handleClear(resetForm)}
              >
                {labels.get('buttons.clear')}
              </Button>
            </div>
          </Form.Section>
        </div>
      )}
    </Form>
  );
}

export default WithLabel(NewPolicy, labelPaths.ADD_INSURANCE_POLICY);
