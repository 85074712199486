import React from 'react';
import moment from 'moment';
import { addTimeZoneInDate } from '../../../../../../lib/util';

const columns = ({ labels, stateId }) => {
  const claimTotal = ({ totalCount }) => <span>{`${totalCount} Claims`}</span>;
  const allColumn = [
    {
      Header: labels.get('tableColumns.claimNumber'),
      accessor: 'claimNo',
      sort: true,
      Footer: (stateId !== 'BillingStateInProgress' && claimTotal),
      fixWidth: 140,
    },
    {
      Header: labels.get('tableColumns.patientName'),
      sort: true,
      accessor: 'patientName',
    },
    {
      Header: labels.get('tableColumns.dos'),
      sort: true,
      accessor: 'billingServiceLineItemCreatedDate',
      Cell: ({ row: { original: { billingServiceLineItemCreatedDate } } }) => (billingServiceLineItemCreatedDate?.dateString ? moment(billingServiceLineItemCreatedDate?.dateString).format('MM-DD-YYYY') : null),
    },
    {
      Header: labels.get('tableColumns.originalSubmitted'),
      sort: true,
      accessor: 'claimSubmissionDate',
      Cell: ({ row: { original: { claimSubmissionDate } } }) => (claimSubmissionDate?.dateString ? moment(addTimeZoneInDate(claimSubmissionDate.dateString)).format('MM-DD-YYYY') : null),
    },
    {
      Header: labels.get('tableColumns.payer'),
      sort: true,
      accessor: 'insurancePayerName',
    },
    {
      Header: 'Policy Type',
      accessor: 'insurancePolicyTypeName',
    },
    {
      Header: labels.get('tableColumns.responsibility'),
      sort: true,
      accessor: 'profileTypeName',
    },
    {
      Header: labels.get('tableColumns.incidentDate'),
      sort: true,
      accessor: 'incidentDate',
      Cell: ({ row: { original: { claimIncidentDate } } }) => (claimIncidentDate ? moment(addTimeZoneInDate(claimIncidentDate)).format('MM-DD-YYYY') : null),
    },
    {
      Header: labels.get('tableColumns.provider'),
      sort: true,
      accessor: 'providerName',
    },
    {
      Header: labels.get('tableColumns.location'),
      sort: true,
      accessor: 'locationName',
    },
    {
      Header: labels.get('tableColumns.billed'),
      sort: true,
      accessor: 'amount',
      Cell: ({ row: { original: { amount } } }) => (
        (amount || 0).toFixed(2)
      ),
      className: 'text-align-right',
      Footer: ({ otherTableData: { totalBilledAmount } }) => <span className="inline-right">{totalBilledAmount}</span>,
      fixWidth: 80,
    },
    {
      Header: labels.get('tableColumns.balance'),
      sort: true,
      accessor: 'balance',
      Cell: ({ row: { original: { balance } } }) => (
        (balance || 0).toFixed(2)
      ),
      className: 'text-align-right',
      Footer: ({ otherTableData: { totalBalance } }) => <span className="inline-right">{totalBalance}</span>,
      fixWidth: 80,
    },
    {
      Header: labels.get('tableColumns.valueBalance'),
      sort: true,
      accessor: 'valueBalance',
      Cell: ({ row: { original: { totalValueBalance } } }) => (
        (totalValueBalance || 0).toFixed(2)
      ),
      className: 'text-align-right',
      Footer: ({ otherTableData: { totalValueBalance } }) => <span className="inline-right">{totalValueBalance}</span>,
      fixWidth: 140,
    },
  ];

  if (stateId === 'BillingStateInProgress') {
    allColumn.unshift({
      Header: labels.get('tableColumns.FollowUpdate'),
      accessor: 'followUpdate',
      sort: true,
      Cell: ({ row: { original: { followUpDate } } }) => (followUpDate?.dateString ? moment(followUpDate?.dateString).format('MM-DD-YYYY') : null),
      Footer: ({ totalCount }) => <span>{`${totalCount} Claims`}</span>,
      fixWidth: 140,
    });
  }
  return allColumn;
};

export default columns;
