import { useEffect } from 'react';

import Events from '../../../../../../lib/events';

const TableWrapper = ({ reFetch, Component }) => {
  useEffect(() => {
    Events.on('refetchSuspenseDetailTable', 'refetchSuspenseDetailTable', reFetch);
    return () => Events.remove('refetchSuspenseDetailTable');
  }, []);
  return Component;
};

export default TableWrapper;
