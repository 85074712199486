import React, { } from 'react';
import { Tooltip } from 'antd';
import { orderBy } from 'lodash';
import Table from '../../../../../../../components/Table';
import WidgetLoader from '../../../../../../../components/WidgetLoader';
import useCRUD from '../../../../../../../hooks/useCRUD';
import { apiUrls } from '../../../../../../../api/constants';
import RenderMultipleSynonyms from '../../../../../../../components/RenderMultipleSynonyms';

const icdColumns = (onRowClick, labels) => [
  {
    Header: labels.get('labels.icdCode'),
    fixWidth: '100',
    accessor: 'icdCode',
    Cell: (data) => {
      const { icdCode } = data?.row?.original || {};
      return (
        <div
          role="presentation"
          onClick={() => onRowClick(data?.row?.original)}
        >
          {icdCode}
        </div>
      );
    },
  },
  {
    Header: labels.get('labels.description'),
    accessor: 'description',
    Cell: ({ row: { original: { description, synonyms } } }) => (
      <Tooltip placement="topLeft" title={description}>
        <span className="flex flex-dir-row">
          {synonyms ? <RenderMultipleSynonyms synonyms={synonyms} /> : description}
        </span>
      </Tooltip>
    ),
  },
];

const Global = (props) => {
  const [
    result, , resultLoading,
  ] = useCRUD({
    id: apiUrls.GET_ICD_WITH_FAVORITES,
    url: `${apiUrls.GET_ICD_WITH_FAVORITES}?PageSize=15`,
    type: 'read',
  });

  const sortedResult = orderBy(result?.result || [], ['icdCode'], ['asc']);
  const {
    onICDCheckBoxClick, labels,
  } = props;
  return (
    <div>
      {resultLoading && <WidgetLoader />}
      <div className="procedure-tab-table-wrap">
        <Table
          columns={icdColumns(onICDCheckBoxClick, labels)}
          data={sortedResult}
          scrollId="icd-all-table"
          labels={labels}
        />
      </div>
    </div>
  );
};

export default Global;

