import React from 'react';

import Form from '../../../../components/Form';
import UserAutoComplete from '../../../UserAutoComplete';

const ReassignForm = ({ form, labels }) => (
  <Form.Column>
    <UserAutoComplete
      name="assignedTo"
      label={labels.get('labels.newUser')}
      form={form}
      minCharLength={3}
      labelSpan={6}
      inputSpan={18}
      header={false}
      dataTestId="assignedTo"
      required
    />
  </Form.Column>
);

export default ReassignForm;
