import React, { useCallback, useState } from 'react';

import { apiUrls } from '../../../api/constants';

import {
  UiRoutes,
} from '../../../lib/constants';

import rights from '../../../lib/rights';
import useRedirect from '../../../hooks/useRedirect';
import WithRights from '../../../hoc/withRights';

import Button from '../../../components/Button';
import Select from '../../../wiredComponents/Select';
import MasterTable from '../../../wiredComponents/MasterTable';
import SelectBox from '../../../components/SelectBox';
import Smcheckbox from '../../../components/SmCheckbox';

const MasterDataTable = () => {
  const { params, push, generatePath } = useRedirect();
  const [isExclude, setExclude] = useState(true);

  const handleSelect = useCallback((id) => {
    if (id) {
      push(generatePath(UiRoutes.mastersWithId, { id }));
    } else {
      push(UiRoutes.masters);
    }
  }, [generatePath, push]);

  const handleExclude = useCallback(({ target: { checked } }) => setExclude(checked), []);

  return (
    <MasterTable parentRedirectPath={UiRoutes.mastersWithId} enumIdAccessor="id" filters={{ IsActive: isExclude, parentId: 0 }}>
      {({ toggleModal }) => (
        <>
          <div className="heading-area flex align-center screen-title mobile-gap-manage">
            <h2>Masters</h2>
            <Select
              id="masters"
              name="masters"
              label=""
              placeholder="Select master"
              url={apiUrls.GET_ENUM}
              nameAccessor="screenHeader"
              valueAccessor="enumId"
              Component={SelectBox}
              isFormItem={false}
              value={params.id ? parseInt(params.id, 10) : undefined}
              selectProps={{
                size: 'middle',
                style: { width: 240, marginLeft: 16 },
                showSearch: true,

              }}
              onChange={handleSelect}
              labelSpan="5"
              inputSpan="16"
            />

          </div>
          {params.id
          && (
            <div className="table-filters">
              <div className="group-btns feild-row flex-space-between">
                <Smcheckbox
                  checked={isExclude}
                  onChange={handleExclude}
                  style={{ marginLeft: 8 }}
                >
                  Active
                </Smcheckbox>
                <Button className="btn btn-success sm-btn" onClick={toggleModal}>Add</Button>
              </div>
            </div>
          )}
        </>
      )}
    </MasterTable>
  );
};

export default WithRights(MasterDataTable, rights.access_to_masters);
