import React, { useCallback, useMemo } from 'react';
import debounce from 'lodash/debounce';

import { orderBy } from 'lodash';
import JsonTree from '../../JsonTree';
import { apiUrls } from '../../../../../../../../../../api/constants';
import useCRUD from '../../../../../../../../../../hooks/useCRUD';

import Search from '../../../../../../../../../../components/Search';
import WidgetLoader from '../../../../../../../../../../components/WidgetLoader';
import Collapsable from '../../../../../../../../../../components/Collapse';
import sortWithoutSymbols from '../../../../../../../../../../lib/sortWithoutSymbols';

const LabsImaging = () => {
  const [result, , resultLoading, getResult, clear] = useCRUD({
    id: apiUrls.GET_CPT_IN_RANGE, url: apiUrls.GET_CPT_IN_RANGE, type: 'read',
  });

  const debounceFunction = useMemo(() => debounce((value) => {
    getResult({
      CPTCodeText: value, RangeFrom: 70000, RangeTo: 90000,
    });
  }, 500), [getResult]);

  const sortedResult = orderBy(
    result,
    (e) => sortWithoutSymbols(e.synonyms) || sortWithoutSymbols(e.description),
  );

  const onSearch = useCallback((event) => {
    event.persist();
    if (event?.target?.value) {
      debounceFunction(event?.target?.value);
    } else {
      clear(true);
    }
  }, [clear, debounceFunction]);

  return (
    <div className="side-tab">
      <div className="merge-fields-container">
        <div className="temp-search-wrapper">
          <Search placeholder="Search Fields" onChange={onSearch} />
        </div>
        {/* <Collapsable
          className="ae-accordian posi-relative mr-bt-8"
          header="Labs Imaging"
          panelKey="LabsImaging"
          defaultActiveKey={['LabsImaging']}
        >
          <div className="pannel-body table-max-height-200 posi-relative">
            <JsonTree
              url={apiUrls.GET_COMPOSER_LABELS}
              setLabelsList={setLabsImagingList}
              list={labsImagingList}
            // search={searchString}
              selectable={false}
              tab="labsImaging"
            />
          </div>
        </Collapsable> */}
        <Collapsable
          className="ae-accordian posi-relative manage-ui-block"
          header="Result"
          panelKey="result"
          defaultActiveKey={['result']}
        >
          {resultLoading && <WidgetLoader />}
          <JsonTree
            list={sortedResult || []}
            selectable={false}
            tab="labsImaging"
            isResult
          />
        </Collapsable>
      </div>
    </div>
  );
};

export default LabsImaging;
