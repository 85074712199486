/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from 'react';
import Label from '../../classes/Label';

const WithLabel = (WrappedComponent, path) => {
  const labels = require(`../../../src/settings/theme/acuityEye/${path}`);
  const labelsWithClass = new Label(labels);
  return function WithLabelHOC(props) {
    return <WrappedComponent {...props} labels={labelsWithClass} />;
  };
};

export default WithLabel;
