import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';

export default function ModalCustomFooter({
  onCancel, submit, onSave, saveVisible, cancelVisible, saveDataTestId, cancelDataTestId,
}) {
  return (
    <div className="group-btns">
      {cancelVisible && <Button className="btn min-wt-86" onClick={onCancel} data-testid={cancelDataTestId}>Cancel</Button>}
      {saveVisible && <Button className="btn btn-success min-wt-86" onClick={onSave} type={submit && 'submit'} data-testid={saveDataTestId}>Save</Button>}
    </div>
  );
}

ModalCustomFooter.defaultProps = {
  submit: false,
  onCancel: () => { /* This is intentional */ },
  onSave: () => { /* This is intentional */ },
  cancelVisible: true,
  saveVisible: true,
};

ModalCustomFooter.propTypes = {
  submit: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  cancelVisible: PropTypes.bool,
  saveVisible: PropTypes.bool,
};
