import React, { useCallback, useEffect, useState } from 'react';
import { Form as AntdForm } from 'antd';
import mapKeys from 'lodash/mapKeys';

import { useDispatch } from 'react-redux';
import {
  UiRoutes, formId, contentType, labelPaths, listId,
} from '../../../../lib/constants';
import { getString } from '../../../../lib/util';
import SuccessMessages from '../../../../lib/successMessages';
import formFieldValuesParser from '../../../../lib/formFieldValuesParser';
import { clearReadData } from '../../../../store/actions/crudWithoutTab';

import useTabLink from '../../../../hooks/useTabLink';
import useRedirect from '../../../../hooks/useRedirect';

import Form from '../../../../components/Form';
import Button from '../../../../components/Button';
import Notification from '../../../../components/Notification';
import ProviderInformation from '../Components/ProviderInformation';
import { apiUrls } from '../../../../api/constants';

import WithLabel from '../../../../hoc/withLabel';

const fileInfoType = ['signaturedocument', 'isfileuploaded'];

function AddProvider(props) {
  const { labels } = props;

  const [form] = AntdForm.useForm();
  const { goBack } = useRedirect();
  const [disableEmail, setEmailDisable] = useState(false);

  useEffect(() => {
    const data = JSON.parse(localStorage.getDecryptedData('userFormData'));
    if (data) {
      form.setFields([{ name: 'email', value: data?.email },
        { name: 'firstName', value: data?.firstName },
        { name: 'middleName', value: data?.middleName },
        { name: 'lastName', value: data?.lastName },
      ]);
      setEmailDisable(true);
      localStorage.removeEncryptedData('userFormData');
    }
  }, []);

  const dispatch = useDispatch();
  const navigateToForm = React.useCallback(
    () => goBack(), [goBack],
  );
  const { navigate } = useTabLink({ to: UiRoutes.editProviderWithTabId, params: { tab: 'general', data: { name: '' } } });

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.PROVIDER_SAVE_SUCCESSFULLY, success: true });
      navigate({
        id: response?.provider?.providerId || response?.id,
        data: {
          name: form.getFieldValue('providerName') || getString([
            form.getFieldValue('firstName'),
            form.getFieldValue('middleName'),
            form.getFieldValue('lastName'),
          ]),
        },
      });
      dispatch(clearReadData('schedular-doctor'));
      dispatch(clearReadData('provider'));
      dispatch(clearReadData('doctor'));
    }
  }, [dispatch, form, navigate]);

  const parser = useCallback((values) => {
    const formValues = formFieldValuesParser({ ...values, copyNotes: values?.copyNotes?.join('') }, {
      date: [
        'submitClaimsDateFrom',
        'submitClaimsDateTo',
        'schedulePatientsDateFrom',
        'schedulePatientsDateTo',
        'providerActiveSinceFrom',
        'providerActiveSinceTo',
        'dob',
      ],
      file: [
        'signatureDocument',
      ],
      isDateToString: true,
      string: ['patientAgeLimitIds', 'subSpecialtyIds'],
    });
    return mapKeys(formValues, (value, key) => {
      if (key?.toLowerCase?.() === 'signaturedocument') {
        return 'FileInfo.File';
      }
      if (fileInfoType.includes(key?.toLowerCase())) {
        return `FileInfo.${key}`;
      }
      return `Provider.${key}`;
    });
  }, []);

  return (
    <Form
      form={form}
      formId={formId.ADD_PROVIDER}
      parser={parser}
      url={apiUrls.ADD_PROVIDER}
      onRequestComplete={onRequestComplete}
      scrollToFirstError
      listId={listId.PROVIDERS}
      contentType={contentType.MULTIPART}
      initialData={{
        isActive: true,
      }}
    >
      <div className="main-content-area">
        <div className="heading-area flex justify-content-sp-bt align-center">
          <h1 data-testid="provider-heading">Add Provider</h1>
          <div className="group-btns">
            <Button id="provider_cancel" className="btn min-wt-86" onClick={navigateToForm} data-testid="provider-cancel-button">{labels.get('buttons.cancel')}</Button>
            <Button id="provider_save" className="btn btn-success min-wt-86" type="submit" data-testid="provider-save-button">{labels.get('buttons.save')}</Button>
          </div>
        </div>
        <div className="main-form-container">
          <div className="main-content-area">
            <div className="add-patient-cl">
              <ProviderInformation form={form} labels={labels} disableEmail={disableEmail} />
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}

export default WithLabel(AddProvider, labelPaths.ADD_PROVIDER);
