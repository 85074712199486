import React, { useMemo } from 'react';
import Input from '../../../../../../components/Form/Input';

const TextBox = ({ data, ...props }) => {
  const textLabelMaxLength = useMemo(() => {
    if (data?.[1]?.Lable?.toLowerCase() === 'units') {
      return 4;
    }
    if (data?.[1]?.Lable?.toLowerCase() === 'lot #') {
      return 50;
    }
    return undefined;
  }, [data]);
  return (
    <div className="info-row">
      <Input
        label={data?.[1]?.Lable}
        name={[data?.[0], data?.[1]?.Key]}
        labelSpan="7"
        inputSpan="17"
        maxValueLength={textLabelMaxLength}
        naturalNumberOnly={data?.[1]?.Lable?.toLowerCase() === 'units'}
        {...props}
      />
    </div>
  );
};

export default TextBox;
