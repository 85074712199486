import React from 'react';
import { Select } from '../../../../../../wiredComponents/Enum';
import { Input } from '../../../../../../components/Form/inputFields';

function AnesthesiaIntraop() {
  return (
    <div>
      <div className="main-form-container">
        <div className="m-vertical-scroll">
          <table className="aldrete-table" border="1" style={{ border: '1px solid #d2ddef', fontSize: '13px' }} cellPadding="0" cellSpacing="0" width="100%">
            <thead style={{ background: '#2e79f8', color: '#fff' }}>
              <tr colSpan="3" width="100%">
                <th style={{ padding: '6px' }}>
                  Time
                </th>
                <th style={{ padding: '6px' }}>
                  BP
                </th>
                <th style={{ padding: '6px' }}>
                  Resp
                </th>
                <th style={{ padding: '6px' }}>
                  Temperature
                </th>
                <th style={{ padding: '6px' }}>
                  O2 Sat
                </th>
                <th style={{ padding: '6px' }}>
                  HR/ PR
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: '6px' }}>
                  <Input
                    label="Time"
                    id="1"
                    name="Time"
                    labelSpan="10"
                    inputSpan="14"
                  />
                </td>
                <td style={{ padding: '6px' }}>
                  <Input
                    label="Systolic"
                    id="2"
                    name="Time"
                    labelSpan="10"
                    inputSpan="14"
                  />
                </td>
                <td style={{ padding: '6px' }}>
                  <Input
                    label="Diastolic"
                    id="3"
                    name="Diastolic"
                    labelSpan="10"
                    inputSpan="14"
                  />
                </td>
                <td style={{ padding: '6px' }}>
                  <Input
                    label="HR"
                    id="4"
                    name="HR"
                    labelSpan="10"
                    inputSpan="14"
                  />
                </td>
                <td style={{ padding: '6px' }}>
                  <Input
                    label="Resp"
                    id="5"
                    name="Resp"
                    labelSpan="10"
                    inputSpan="14"
                  />
                </td>
                <td style={{ padding: '6px' }}>
                  <Input
                    label="Resp"
                    id="5"
                    name="Resp"
                    labelSpan="10"
                    inputSpan="14"
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="6" align="right">
                  <span className="add-row-button">
                    +
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <table className="aldrete-table" border="1" style={{ border: '1px solid #d2ddef', fontSize: '13px' }} cellPadding="0" cellSpacing="0" width="100%">
            <thead style={{ background: '#2e79f8', color: '#fff' }}>
              <tr colSpan="3" width="100%">
                <th style={{ padding: '6px' }}>
                  Evaluation Parameter
                </th>
                <th style={{ padding: '6px' }}>
                  In
                </th>
                <th style={{ padding: '6px' }}>
                  Out
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: '6px' }}>
                  Activity
                </td>
                <td style={{ padding: '6px' }}>
                  <Select
                    label=""
                    name=""
                    id=""
                    labelSpan="0"
                    inputSpan="16"
                    required
                  />
                </td>
                <td style={{ padding: '6px' }}>
                  <Select
                    label=""
                    name=""
                    id=""
                    labelSpan="0"
                    inputSpan="16"
                    required
                  />
                </td>
              </tr>
              <tr>
                <td style={{ padding: '6px' }}>
                  Respiration
                </td>
                <td style={{ padding: '6px' }}>
                  <Select
                    label=""
                    name=""
                    id=""
                    labelSpan="0"
                    inputSpan="16"
                    required
                  />
                </td>
                <td style={{ padding: '6px' }}>
                  <Select
                    label=""
                    name=""
                    id=""
                    labelSpan="0"
                    inputSpan="16"
                    required
                  />
                </td>
              </tr>
              <tr>
                <td style={{ padding: '6px' }}>
                  Circulation
                </td>
                <td style={{ padding: '6px' }}>
                  <Select
                    label=""
                    name=""
                    id=""
                    labelSpan="0"
                    inputSpan="16"
                    required
                  />
                </td>
                <td style={{ padding: '6px' }}>
                  <Select
                    label=""
                    name=""
                    id=""
                    labelSpan="0"
                    inputSpan="16"
                    required
                  />
                </td>
              </tr>
              <tr>
                <td style={{ padding: '6px' }}>
                  Consciousness
                </td>
                <td style={{ padding: '6px' }}>
                  <Select
                    label=""
                    name=""
                    id=""
                    labelSpan="0"
                    inputSpan="16"
                    required
                  />
                </td>
                <td style={{ padding: '6px' }}>
                  <Select
                    label=""
                    name=""
                    id=""
                    labelSpan="0"
                    inputSpan="16"
                    required
                  />
                </td>
              </tr>
              <tr>
                <td style={{ padding: '6px' }}>
                  Color
                </td>
                <td style={{ padding: '6px' }}>
                  <Select
                    label=""
                    name=""
                    id=""
                    labelSpan="0"
                    inputSpan="16"
                    required
                  />
                </td>
                <td style={{ padding: '6px' }}>
                  <Select
                    label=""
                    name=""
                    id=""
                    labelSpan="0"
                    inputSpan="16"
                    required
                  />
                </td>
              </tr>
              <tr>
                <td style={{ padding: '6px' }}>
                  Post Anesthesia Recovery Score Total
                </td>
                <td style={{ padding: '6px' }}>
                  Total value
                </td>
                <td style={{ padding: '6px' }}>
                  Total value
                </td>
              </tr>
              <tr>
                <td style={{ padding: '6px' }}>
                  Pain Assessment
                </td>
                <td style={{ padding: '6px' }}>
                  <Input
                    label=""
                    labelSpan="0"
                    inputSpan="16"
                  />
                </td>
                <td style={{ padding: '6px' }}>
                  <Input
                    label=""
                    labelSpan="0"
                    inputSpan="16"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table className="aldrete-table" border="1" style={{ border: '1px solid #d2ddef', fontSize: '13px' }} cellPadding="0" cellSpacing="0" width="100%">
            <thead style={{ background: '#2e79f8', color: '#fff' }}>
              <tr colSpan="3" width="100%">
                <th style={{ padding: '6px' }}>
                  Ordering Physician
                </th>
                <th style={{ padding: '6px' }}>
                  Drug Ordered
                </th>
                <th style={{ padding: '6px' }}>
                  Dose
                </th>
                <th style={{ padding: '6px' }}>
                  Route
                </th>
                <th style={{ padding: '6px' }}>
                  Time w/ Rn Initials
                </th>
                <th style={{ padding: '6px' }}>
                  Result
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: '6px' }}>
                  <Input
                    label=""
                    id="1"
                    name="Time"
                    labelSpan="0"
                    inputSpan="24"
                  />
                </td>
                <td style={{ padding: '6px' }}>
                  <Input
                    label=""
                    id="1"
                    name="Time"
                    labelSpan="0"
                    inputSpan="24"
                  />
                </td>
                <td style={{ padding: '6px' }}>
                  <Input
                    label=""
                    id="1"
                    name="Time"
                    labelSpan="0"
                    inputSpan="24"
                  />
                </td>
                <td style={{ padding: '6px' }}>
                  <Input
                    label=""
                    id="1"
                    name="Time"
                    labelSpan="0"
                    inputSpan="24"
                  />
                </td>
                <td style={{ padding: '6px' }}>
                  <Input
                    label=""
                    id="1"
                    name="Time"
                    labelSpan="0"
                    inputSpan="24"
                  />
                </td>
                <td style={{ padding: '6px' }}>
                  <Input
                    label=""
                    id="1"
                    name="Time"
                    labelSpan="0"
                    inputSpan="24"
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="6" align="right">
                  <span className="add-row-button">
                    +
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <table className="aldrete-table" border="1" style={{ border: '1px solid #d2ddef', fontSize: '13px' }} cellPadding="0" cellSpacing="0" width="100%">
            <thead style={{ background: '#2e79f8', color: '#fff' }}>
              <tr colSpan="3" width="100%">
                <th style={{ padding: '6px' }}>
                  Time
                </th>
                <th style={{ padding: '6px' }}>
                  Nurses Notes
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: '6px' }}>
                  <Input
                    label="Time"
                    id="1"
                    name="Time"
                    labelSpan="4"
                    inputSpan="10"
                  />
                </td>
                <td style={{ padding: '6px' }}>
                  <Input
                    label="Systolic"
                    id="2"
                    name="Time"
                    labelSpan="4"
                    inputSpan="10"
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="6" align="right">
                  <span className="add-row-button">
                    +
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AnesthesiaIntraop;
