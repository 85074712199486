import React from 'react';

import Link from '../../../../../../../components/Link';
import { UiRoutes } from '../../../../../../../lib/constants';

const columns = (params, generatePath, labels) => [
  {
    Header: labels.get('tableColumns.payer'),
    accessor: 'payerName',
    sort: true,
    Cell: ({ row: { original: { providerBillingId, payerName, isAllPayers } } }) => (
      <Link to={generatePath(UiRoutes.editBillingWithTabId,
        { ...params, billingId: providerBillingId })}
      >
        {(isAllPayers && 'All Payers') || payerName}
      </Link>
    ),
  },
  {
    Header: labels.get('tableColumns.billToProvider'),
    accessor: 'billtoProviderName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.payToProvider'),
    accessor: 'paytoProviderName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.lastModifiedBy'),
    accessor: 'modifiedByName',
    sort: true,
  },
];

export default columns;
