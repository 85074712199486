import React from 'react';
import Icon from '../../../../../../components/Icon';
import iconNames from '../../../../../../lib/iconNames';
import CommonColumns from './commonColumns';

const outBoxColumns = (labels) => [
  {
    Header: labels.get('tableColumns.businessEntityName'),
    accessor: 'businessEntity',
    fixWidth: 150,
    sort: true,
  },
  {
    Header: labels.get('tableColumns.source'),
    accessor: 'source',
    fixWidth: 100,
    sort: true,
  },
  ...CommonColumns(labels, 'stageMasterName'),
  {
    Header: labels.get('tableColumns.amount'),
    accessor: 'totalBatchAmount',
    Cell: ({ row: { original: { totalBatchAmount } } }) => (
      parseFloat(totalBatchAmount || 0).toFixed(2)
    ),
    className: 'text-align-right',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.entered'),
    accessor: 'enteredAmount',
    Cell: ({ row: { original: { enteredAmount } } }) => (
      parseFloat(enteredAmount || 0).toFixed(2)
    ),
    className: 'text-align-right',
    sort: true,
  },
  {
    Header: '',
    accessor: 'paperClipIcon',
    Cell: ({ row: { original: { batchType } } }) => (
      <span className="flex justify-content-center">
        {batchType?.toLowerCase() === 'manual' && <Icon name={iconNames.paperClipOutlined} color="green" />}
        {batchType?.toLowerCase() === 'electronic' && <Icon name={iconNames.thunderboltOutlined} color="orange" />}
      </span>
    ),
    fixWidth: '60',
  },
  {
    Header: '',
    accessor: 'delete',
    Cell: ({ row, handleDeleteRemittanceBatch, batchDeletePermitted }) => {
      const rowProps = row.getToggleRowSelectedProps();
      rowProps.onClick = (e) => e.stopPropagation();
      return (
        <span
          {...rowProps}
          className="flex justify-content-center"
          title={
            row?.original?.batchType?.toLowerCase() === 'electronic'
              ? 'Move Batch'
              : 'Delete Batch'
          }
        >
          { row?.original?.batchType?.toLowerCase() !== 'electronic' &&
          batchDeletePermitted ? (
            <Icon
              name={iconNames.deleteIcon}
              onClick={handleDeleteRemittanceBatch(
                row?.original?.remittanceBatchId,
                false
              )}
            />
          ) : (
            <Icon
              name={iconNames.rollBack}
              onClick={handleDeleteRemittanceBatch(
                row?.original?.remittanceBatchId,
                true
              )}
            />
          )}
        </span>
      );
    },
    fixWidth: '60',
  },
];

export default outBoxColumns;
