import PropTypes from 'prop-types';

export const modifierDefaultProps = {
  disabled: false,
  required: false,
  initialValue: {},
  labelSpan: '0',
  inputSpan: '24',
  onSelect: () => { /* This is intentional */ },
  styleWidth: true,
  name: 'modifier',
  className: '',
  dropdownMatchSelectWidth: false,
  params: {},
  placeholder: 'Code',
  label: 'Modifier',
  minCharLength: 2,
  filterModifierSearch: false,
  optionKey: 'modifierCode',
};
export const modifierDefaultPropsTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  initialValue: PropTypes.objectOf(PropTypes.any),
  labelSpan: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  inputSpan: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  onSelect: PropTypes.func,
  styleWidth: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
  dropdownMatchSelectWidth: PropTypes.oneOf([PropTypes.number, PropTypes.bool]),
  params: PropTypes.objectOf(PropTypes.string),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  minCharLength: PropTypes.number,
  filterModifierSearch: PropTypes.bool,
  optionKey: PropTypes.string,
};
