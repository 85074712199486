/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import { Form as AntdForm } from 'antd';
import moment from 'moment';
import classNames from 'classnames';

import Notification from '../../../components/Notification';
import SelectBox from '../../../components/Form/SelectBox';
import Modals from '../../../components/Modal';
import Form from '../../../components/Form';
import TimeCell from '../../../components/Cells/TimeCell';

import ReferringPhysicianAutoComplete from '../../ReferringPhysicianGridAutoComplete';
import Select from '../../Select';
import SelectWithoutTab from '../../Select/selectBoxV2';

import { apiUrls } from '../../../api/constants';

import SuccessMessages from '../../../lib/successMessages';
import { RULES_ENGINE_HIGHLIGHT_KEYS, labelPaths } from '../../../lib/constants';
import rights from '../../../lib/rights';
import { parseReferringPhysician } from '../../../lib/util';

import WithLabel from '../../../hoc/withLabel';
import useRights from '../../../hooks/useRights';

import AuthorizationForm from '../../../screens/Dashboard/Tabs/Lists/components/Authorization/Add/AuthorizationForm';
import EditAuthorization from '../../../screens/Dashboard/Tabs/Lists/components/Authorization/Edit/EditAuthorizationForm';
import Case from '../../../screens/Dashboard/Tabs/Lists/components/Cases/CaseForm/Case';
import InsuranceDetailsSelect from '../../InsuranceDetailsSelect';
import Events from '../../../lib/events';
import DatePicker from '../../../components/Form/DatePicker';
import Radio from '../../../components/Form/Radio';
import useReduxStoreWithId from '../../../hooks/useReduxStoreWithId';
import EditCase from '../../../screens/Dashboard/Tabs/Lists/components/Cases/CaseForm/EditCase';
import { getRulesEngineOperandByKey } from './RulesEngine/RulesEngineEncounter';

const AddAuthorization = WithLabel(AuthorizationForm, labelPaths.ADD_AUTHORIZATION);
const EditAuthorizationForm = WithLabel(EditAuthorization, labelPaths.EDIT_AUTHORIZATION);
const CaseForm = WithLabel(Case, labelPaths.PATIENT_LISTS);
const EditCaseForm = WithLabel(EditCase, labelPaths.PATIENT_LISTS);
let caseReFetchOptions = null;
let authorizationReFetchOptions = null;

const showNotificationAndRefetchData = ({
  response, toggleModal, id, callback, message, callbackProps = {},
}) => {
  if (response) {
    Notification({ message, success: true });
    if (toggleModal) toggleModal();
    if (callback) callback({ patientId: id, ...callbackProps });
  }
};

const caseLabelRender = (name, date, toDate) => (

  <div>
    <span>{name}</span>
    <span className="mr-lt-5">
      (
      <TimeCell value={date} />
      <span> to </span>
      {toDate && (
      <TimeCell
        value={toDate}
      />
      )}
      )
    </span>
  </div>
);

const labelRender = (name, date) => (
  <div>
    <span>{name}</span>
    <span className="mr-lt-5">
      (
      <TimeCell value={date?.dateOfService} />
      )
    </span>
  </div>
);

const authorizationDropDownRenderer = (options = []) => {
  if (!Object.values(options).length) return <div />;
  return (
    <>
      <div className="option headerWrapper schedular-option-select">
        <span className="header">
          Authorization No.
        </span>
        <span className="header">
          Date Of Service
        </span>
      </div>
      <div>
        {options}
      </div>
    </>
  );
};

const caseOptionRenderer = (options) => options.map((item) => ({
  value: item.value,
  item,
  name: (
    <div className="option encounter-case-options" key={item.authorizationId}>
      <span>
        {item.name}
      </span>
      <span>
        <TimeCell value={item.effectiveDateFrom} />
        <span> to </span>
        <TimeCell value={item.effectiveDateTo} />
      </span>
    </div>
  ),
  label: caseLabelRender(item.name, item.effectiveDateFrom, item.effectiveDateTo),
}));

const caseDropDownRenderer = (options = []) => {
  if (!Object.values(options).length) return <div />;
  return (
    <>
      <div className="option headerWrapper encounter-case schedular-option-select">
        <span className="header">
          Case Type
        </span>
        <span className="header">
          Effective Dates
        </span>
      </div>
      <div>
        {options}
      </div>
    </>
  );
};

const toggleReferingPhysicianOptions = [
  { label: 'NPI', value: 'npi' },
  { label: 'Provider', value: 'provider' },
  { label: 'Self', value: 'self' },
];

const NewEncounterForm = ({
  labels, formData = {}, params, form, setParentDataItems, tabId,
  handleParentInsuranceChange, handleParentAuthorizationChange,
  formId, financialId, setAuthorizationOptions,
  billingEncounterId, serviceDate, isCollectionCharges, handleParentProviderChange, isUpdate,
  showRcmHighlight, finalRcmHighlightData,
}) => {
  const { id } = params;
  const [isShowAuthorizationModal, setAuthorizationModalVisibility] = useState(null);
  const [isShowEditAuthorizationModal, setEditAuthorizationModalVisibility] = useState(null);
  const [isShowCaseModal, setCaseModalVisibility] = useState(null);
  const [isShowEditCaseModal, setEditCaseModalVisibility] = useState(null);
  const [providersDataRedux,, setProvidersData] = useReduxStoreWithId({ id: 'providersData-Encounter-Form' });
  const providersData = providersDataRedux?.get('data') || [];
  const editAuthorizationId = form.getFieldValue('authorizationId');
  const editCaseId = form.getFieldValue('caseId');
  const [
    isAddAuthorizationAuthenticated,
    isAddCaseAuthenticated,
  ] = useRights([rights.create_authorization, rights.create_clinical_cases]);

  const toggleAuthorizationModal = useCallback(() => {
    setAuthorizationModalVisibility(!isShowAuthorizationModal);
  }, [setAuthorizationModalVisibility, isShowAuthorizationModal]);

  const authorizationOptionRenderer = useCallback((options) => {
    setAuthorizationOptions(options);
    return options.map((item) => ({
      value: item.value,
      item,
      name: (
        <div className="flex-space-between option manage-list-layout" key={item.authorizationId}>
          <span className="flex">
            {item.name}
          </span>
          <span className="flex flex-dir-column">
            {item?.dateOfServices?.map(({ dateOfService }) => (
              <TimeCell value={dateOfService} />
            ))}
          </span>
        </div>
      ),
      label: labelRender(item.name, item?.dateOfServices?.[0]),
    }));
  }, [setAuthorizationOptions]);

  const toggleEditAuthorizationModal = useCallback(() => {
    setEditAuthorizationModalVisibility(!isShowEditAuthorizationModal);
  }, [setEditAuthorizationModalVisibility, isShowEditAuthorizationModal]);

  const onAddAuthorizationRequestComplete = useCallback(({ response }) => {
    showNotificationAndRefetchData({
      response,
      toggleModal: toggleAuthorizationModal,
      id,
      callback: authorizationReFetchOptions,
      message: SuccessMessages.AUTHORIZATION_SAVED_SUCCESSFULLY,
      callbackProps: { isReFetch: true },
    });
  }, [toggleAuthorizationModal, id]);

  const onEditAuthorizationRequestComplete = useCallback(({ response }) => {
    showNotificationAndRefetchData({
      response,
      message: SuccessMessages.AUTHORIZATION_UPDATED_SUCCESSFULLY,
      toggleModal: toggleEditAuthorizationModal,
      id,
      callback: authorizationReFetchOptions,
      callbackProps: { isReFetch: true },
    });
  }, [toggleEditAuthorizationModal, id]);

  const toggleCaseModal = useCallback(() => {
    setCaseModalVisibility(!isShowCaseModal);
  }, [setCaseModalVisibility, isShowCaseModal]);

  const toggleEditCaseModal = useCallback(() => {
    setEditCaseModalVisibility(!isShowEditCaseModal);
  }, [setEditCaseModalVisibility, isShowEditCaseModal]);

  const onAddCaseRequestComplete = useCallback(({ response }) => {
    showNotificationAndRefetchData({
      response,
      toggleModal: toggleCaseModal,
      id,
      callback: caseReFetchOptions,
      message: SuccessMessages.CASE_SAVED_SUCCESSFULLY,
    });
  }, [toggleCaseModal, id]);

  const onEditCaseRequestComplete = useCallback(({ response }) => {
    showNotificationAndRefetchData({
      response,
      toggleModal: toggleEditCaseModal,
      id,
      callback: caseReFetchOptions,
      message: SuccessMessages.CASE_SAVED_SUCCESSFULLY,

    });
  }, [toggleEditCaseModal, id]);

  const setCaseReFetchOptions = useCallback((getOptions) => {
    caseReFetchOptions = getOptions;
  }, []);

  const setAuthorizationReFetchOptions = useCallback((getOptions) => {
    authorizationReFetchOptions = getOptions;
  }, []);

  const handleSelectedProvider = useCallback((providerId, _all) => {
    const { item } = _all || {};
    Events.trigger('reCalculateAmounts', providerId);
    handleParentProviderChange(providerId, item?.providerName);
    if (form.getFieldValue('toggleReferringPhysician') === 'self') {
      form.setFieldsValue({ referringPhysicianId: item?.providerNPI });
    }
  }, [form, handleParentProviderChange]);

  const handleSelectedAttendingProvider = useCallback((attendingProviderId) => {
    setParentDataItems({ attendingProviderId });
  }, [setParentDataItems]);

  const handleSelectedRefPhy = useCallback((item) => {
    const data = {
      referringPhyscianName: item?.name,
      referringPhysicianId: item?.npi,
      referringPhysicianNPI: item?.npi,
      referringPhysicianJson: JSON.stringify(parseReferringPhysician(item)),
    };
    setParentDataItems(data);
  }, [setParentDataItems]);

  const handleInAppReffProvider = useCallback((reffProviderNPI, _all) => {
    const { item } = _all || {};
    const data = {
      referringPhyscianName: item?.providerName,
      referringPhysicianId: reffProviderNPI,
      referringPhysicianNPI: reffProviderNPI,
    };
    setParentDataItems(data);
  }, [setParentDataItems]);

  const handleSelectedLocation = useCallback((locationId) => {
    setParentDataItems({ locationId });
  }, [setParentDataItems]);

  const handleCaseSelect = useCallback((caseId) => {
    setParentDataItems({ caseId });
  }, [setParentDataItems]);

  const handleAuthSelect = useCallback((authorizationId) => {
    handleParentAuthorizationChange(authorizationId);
  }, [handleParentAuthorizationChange]);

  const onSelectInsurance = useCallback((insuranceDetailsId, all) => {
    handleParentInsuranceChange(insuranceDetailsId, all?.pId);
    setParentDataItems({
      insuranceProfileDetailId: insuranceDetailsId,
      insuranceProfileId: all?.pId || 1,
    });
    if (!all?.pId) {
      form.setFieldsValue({ authorizationId: undefined });
    }
  }, [form, handleParentInsuranceChange, setParentDataItems]);

  const handleInsuranceChange = useCallback((insuranceId) => {
    if (!insuranceId) {
      handleParentInsuranceChange();
      setParentDataItems({
        insuranceProfileDetailId: undefined,
        insuranceProfileId: undefined,
      });
      form.setFieldsValue({ authorizationId: undefined });
    }
  }, [form, handleParentInsuranceChange, setParentDataItems]);

  const onDateChange = useCallback((dateObject) => {
    setParentDataItems({ serviceDate: dateObject });
  }, [setParentDataItems]);

  const onProviderFetchComplete = useCallback((data) => {
    setProvidersData(data);
  }, [setProvidersData]);

  const toggleReferingPhysician = useCallback(({ target: { value } }) => {
    const updates = {
      toggleReferringPhysician: value,
      referringPhysicianJson: undefined,
    };

    if (value === formData.savedToggleReferringPhysician) {
      updates.referringPhysicianId = formData.savedReferringPhysicianId;
      form.setFieldsValue({ referringPhysicianId: formData.savedReferringPhysicianId });
    } else if (value === 'self') {
      const providerId = form.getFieldValue('providerId');
      // eslint-disable-next-line eqeqeq
      const providerInfo = providersData?.find((provider) => provider.providerId == providerId);
      if (providerInfo?.providerNPI) {
        updates.referringPhysicianId = providerInfo.providerNPI;
        form.setFieldsValue({ referringPhysicianId: providerInfo.providerNPI });
      }
    } else {
      updates.referringPhysicianId = null;
      form.setFieldsValue({ referringPhysicianId: null });
    }
    setParentDataItems(updates);
  }, [form, formData.savedReferringPhysicianId,
    formData.savedToggleReferringPhysician, providersData, setParentDataItems]);

  const setRefPhyToSelf = () => {
    const providerId = form.getFieldValue('providerId');
    // eslint-disable-next-line eqeqeq
    const providerInfo = providersData?.find((provider) => provider.providerId == providerId);
    if (!isCollectionCharges && (!formData.toggleReferringPhysician || (formData.toggleReferringPhysician === 'npi' && !formData.referringPhysicianNPI))) {
      const updates = {};
      if (providerInfo?.providerNPI) {
        updates.referringPhysicianId = providerInfo.providerNPI;
        form.setFieldsValue({ toggleReferringPhysician: 'self' });
        form.setFieldsValue({ referringPhysicianId: providerInfo.providerNPI });
      }
      setParentDataItems(updates);
    }
  };

  useEffect(() => {
    if (isUpdate) {
      setRefPhyToSelf();
    } else {
      form.setFieldsValue({ toggleReferringPhysician: 'self' });
    }
  }, [serviceDate, providersDataRedux]);

  return (
    <>
      <Form.Column>
        <div>
          <SelectWithoutTab
            id="providerId"
            name="providerId"
            url={apiUrls.GET_PROVIDERS}
            placeholder="Search Provider"
            label={labels.get('labels.provider')}
            nameAccessor="providerName"
            valueAccessor="providerId"
            selectProps={{
              style: { width: isCollectionCharges ? '100%' : 250 },
              showSearch: true,
              dropdownMatchSelectWidth: 250,
            }}
            onChange={handleSelectedProvider}
            disabled={isCollectionCharges}
            labelSpan="8"
            inputSpan="16"
            required
            defaultSorting={false}
            onFetchComplete={onProviderFetchComplete}
            showRcmHighlight={showRcmHighlight && getRulesEngineOperandByKey(finalRcmHighlightData, RULES_ENGINE_HIGHLIGHT_KEYS.PROVIDER)}
            finalRcmHighlightData={getRulesEngineOperandByKey(finalRcmHighlightData, RULES_ENGINE_HIGHLIGHT_KEYS.PROVIDER)}
          />
          <SelectWithoutTab
            id="attendingProviderId"
            name="attendingProviderId"
            url={apiUrls.GET_PROVIDERS}
            placeholder="Search Provider"
            label={labels.get('labels.attendingProvider')}
            nameAccessor="providerName"
            valueAccessor="providerId"
            selectProps={{
              style: { width: isCollectionCharges ? '100%' : 250 },
              showSearch: true,
              dropdownMatchSelectWidth: 250,
            }}
            onChange={handleSelectedAttendingProvider}
            disabled={isCollectionCharges}
            labelSpan="8"
            inputSpan="16"
            defaultSorting={false}
          />
          <SelectWithoutTab
            id="locationId"
            name="locationId"
            url={apiUrls.GET_LOCATIONS}
            placeholder="Search Location"
            label={labels.get('labels.location')}
            nameAccessor="locationName"
            valueAccessor="locationId"
            selectProps={{
              style: { width: isCollectionCharges ? '100%' : 250 },
              showSearch: true,
              dropdownMatchSelectWidth: 250,
            }}
            onChange={handleSelectedLocation}
            disabled={isCollectionCharges}
            labelSpan="8"
            inputSpan="16"
            required
            defaultSorting={false}
          />
        </div>
      </Form.Column>
      <Form.Column>
        <Radio
          options={toggleReferingPhysicianOptions}
          label="Reff. Physician Search"
          name="toggleReferringPhysician"
          labelSpan="8"
          inputSpan="16"
          onChange={toggleReferingPhysician}
          radioProps={{ 'data-testid': 'addNewEncounter-radioBtn', disabled: isCollectionCharges }}
        />
        <AntdForm.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => (
            prevValues.toggleReferringPhysician !== currentValues.toggleReferringPhysician)}
        >
          {({ getFieldValue }) => {
            if (getFieldValue('toggleReferringPhysician') === 'npi') {
              return (
                <ReferringPhysicianAutoComplete
                  label={labels.get('labels.referringPhysician')}
                  name="referringPhysicianId"
                  initialValue={
                    getFieldValue('referringPhysicianId') ? {
                      id: getFieldValue('referringPhysicianId'),
                      value: getFieldValue('referringPhysicianId'),
                      npi: getFieldValue('referringPhysicianId'),
                    } : null
                  }
                  labelSpan="8"
                  inputSpan="16"
                  form={form}
                  onSelect={handleSelectedRefPhy}
                  formId={formId}
                  disabled={isCollectionCharges}
                />
              );
            }
            if ((getFieldValue('toggleReferringPhysician') === 'provider' || getFieldValue('toggleReferringPhysician') === 'self')) {
              return (
                <SelectWithoutTab
                  id="providerId"
                  name="referringPhysicianId"
                  url={apiUrls.GET_PROVIDERS}
                  placeholder="Search Provider"
                  label={labels.get('labels.referringPhysician')}
                  nameAccessor="providerName"
                  valueAccessor="providerNPI"
                  selectProps={{
                    style: { width: isCollectionCharges ? '100%' : 250 },
                    showSearch: true,
                    dropdownMatchSelectWidth: 250,
                  }}
                  onChange={handleInAppReffProvider}
                  labelSpan="8"
                  inputSpan="16"
                  defaultSorting={false}
                  disabled={getFieldValue('toggleReferringPhysician') === 'self' || isCollectionCharges}
                  dataTestId="providerReferringPhysicianId"
                />
              );
            }
            return null;
          }}
        </AntdForm.Item>
        <div className="add-input add-input-gap auth-sel-box">
          <SelectWithoutTab
            id={`authorizationId-${id}`}
            name="authorizationId"
            url={`${apiUrls.AUTHORIZATION_LIST_DROPDOWN}`}
            label={labels.get('labels.authorization')}
            nameAccessor="authorizationNo"
            valueAccessor="authorizationId"
            reFetchOptions={setAuthorizationReFetchOptions}
            inputSpan="15"
            labelSpan="8"
            optionRenderer={authorizationOptionRenderer}
            selectProps={{
              optionLabelProp: 'label',
              dropdownRender: authorizationDropDownRenderer,
            }}
            disabled={!parseInt(formData?.lineItems?.[0]?.insuranceProfileId || 0, 10) || isCollectionCharges}
            params={formData?.insuranceProfileDetailId && formData?.insuranceProfileId && {
              PatientId: id,
              insuranceProfileDetailId: formData?.insuranceProfileDetailId,
              insuranceProfileId: formData?.insuranceProfileId,
            }}
            onChange={handleAuthSelect}
            showRcmHighlight={showRcmHighlight && getRulesEngineOperandByKey(finalRcmHighlightData, RULES_ENGINE_HIGHLIGHT_KEYS.AUTHORIZATION)}
            finalRcmHighlightData={getRulesEngineOperandByKey(finalRcmHighlightData, RULES_ENGINE_HIGHLIGHT_KEYS.AUTHORIZATION)}
          />
          {editAuthorizationId ? (
            <span
              className={classNames('edit-icon sprite-img-before', { 'cursor-not-allowed': isCollectionCharges })}
              onClick={!isCollectionCharges && toggleEditAuthorizationModal}
              role="presentation"
            />
          ) : null}
          {(isAddAuthorizationAuthenticated && !isCollectionCharges) ? (
            <span
              className="add-icon sprite-img-before"
              onClick={toggleAuthorizationModal}
              role="presentation"
            />
          ) : null}
        </div>
      </Form.Column>
      <Form.Column>
        <div>
          <DatePicker
            id="23"
            name="serviceDate"
            label="Service Date"
            onChange={onDateChange}
            disabled={(financialId || billingEncounterId)}
            labelSpan="8"
            inputSpan="16"
            required
            dataTestId="addNew-encounter-serviceDataPicker"
          />
          <InsuranceDetailsSelect
            selectProps={{
              showSearch: true,
            }}
            id="payer"
            name="insuranceProfileDetailId"
            placeholder={labels.get('labels.insurance')}
            patientId={id}
            label={labels.get('labels.insuranceProfile')}
            labelSpan={8}
            inputSpan={16}
            // selectNodes={[formData?.insuranceProfileId]}
            onSelect={onSelectInsurance}
            onChange={handleInsuranceChange}
            initialValue={{
              insuranceProfileId: formData?.insuranceProfileId,
              insuranceProfileDetailId: formData?.insuranceProfileDetailId,
            }}
            includeInActive
            disableOtherThanPrimaryAndSelf={!params?.claimNumber || isCollectionCharges}
            includeSelfProfile
            required
            dataTestId="addNewEncounter-insuranceSelect"
            tabId={`${tabId}-addNewEncounter`}
            disabled={isCollectionCharges}
          />
          <div className="add-input add-input-gap">
            <Select
              id={`caseId-${id}`}
              name="caseId"
              url={apiUrls.CASES_LIST}
              label={labels.get('labels.case')}
              nameAccessor="caseTypeName"
              valueAccessor="caseId"
              Component={SelectBox}
              reFetchOptions={setCaseReFetchOptions}
              inputSpan="15"
              labelSpan="8"
              params={{ patientId: id }}
              optionRenderer={caseOptionRenderer}
              selectProps={{
                optionLabelProp: 'label',
                dropdownRender: caseDropDownRenderer,
                dropdownMatchSelectWidth: 300,
              }}
              onChange={handleCaseSelect}
              disabled={isCollectionCharges}
            />
            {editCaseId && (
              <span
                className={classNames('edit-icon sprite-img-before', { 'cursor-not-allowed': isCollectionCharges })}
                onClick={!isCollectionCharges && toggleEditCaseModal}
                role="presentation"
              />
            )}
            {(isAddCaseAuthenticated && !isCollectionCharges) && (
              <span
                className="add-icon sprite-img-before"
                onClick={toggleCaseModal}
                role="presentation"
              />
            )}
          </div>

        </div>
      </Form.Column>
      <Modals
        visible={isShowAuthorizationModal}
        toggleModal={toggleAuthorizationModal}
        destroyOnClose
        footer={null}
        width="1200px"
        wrapClassName="add-authorization-modal"
        title={labels.get('titles.addAuthorization')}
      >
        <AddAuthorization
          onRequestComplete={onAddAuthorizationRequestComplete}
          onCancelButtonClicked={toggleAuthorizationModal}
          destroyOnClose
        />
      </Modals>
      <Modals
        visible={isShowEditAuthorizationModal}
        toggleModal={toggleEditAuthorizationModal}
        destroyOnClose
        footer={null}
        width="1200px"
        wrapClassName="add-authorization-modal"
        title={labels.get('titles.editAuthorization')}
      >
        <EditAuthorizationForm
          onRequestComplete={onEditAuthorizationRequestComplete}
          onCancelButtonClicked={toggleEditAuthorizationModal}
          destroyOnClose
          params={{ ...params, authorizationId: editAuthorizationId }}
        />
      </Modals>
      <Modals
        visible={isShowCaseModal}
        toggleModal={toggleCaseModal}
        destroyOnClose
        footer={null}
        width="1200px"
        wrapClassName="add-case-modal"
        title={labels.get('titles.addCase')}
      >
        <CaseForm
          onAddRequestComplete={onAddCaseRequestComplete}
          onCancelButtonClicked={toggleCaseModal}
          params={params}
          destroyOnClose
          serviceDate={serviceDate && moment(serviceDate)}
        />
      </Modals>
      <Modals
        visible={isShowEditCaseModal}
        toggleModal={toggleEditCaseModal}
        destroyOnClose
        footer={null}
        width="1200px"
        wrapClassName="add-case-modal"
        title={labels.get('titles.editCase')}
      >
        <EditCaseForm
          onUpdateRequestComplete={onEditCaseRequestComplete}
          onCancelButtonClicked={toggleEditCaseModal}
          destroyOnClose
          params={{ ...params, caseId: editCaseId }}
        />
      </Modals>
    </>
  );
};

export default NewEncounterForm;
