import React from 'react';

import FilterComponents from '../../../components/FilterComponents';
import FilterManager from '../../../components/FilterManager';

import withQuery from '../../../hoc/withQuery/withQuery';

import { apiUrls } from '../../../api/constants';

import {
  listIds,
} from '../../../lib/constants';

import columns from './columns';

const initialSort = [{ id: 'name', desc: false }];

const filterOptions = [
  {
    name: 'All',
    value: 'all',
  },
  {
    name: 'Active',
    value: 'active',
  },
  {
    name: 'Inactive',
    value: 'inactive',
  },
];

const CustomContractTable = withQuery({
  url: apiUrls.GET_CONTRACT,
  listId: listIds.CONTRACT,
})();

const FilterCollection = FilterComponents([
  {
    type: 'search',
    filterProps: {
      placeholder: 'Contract Name ',
      name: 'SearchText',
      id: 'contract_filter_search',
    },
  },
  {
    type: 'select',
    filterProps: {
      placeholder: 'Select an Option',
      name: 'FilterName',
      id: 'contracts_all',
      options: filterOptions,
      selectProps: {
        defaultValue: 'all',
        allowClear: false,
      },
      style: {
        minWidth: 150,
      },
    },
  },
]);

const ContractTable = ({ labels, ...props }) => (
  <CustomContractTable
    columns={columns(labels)}
    initialSort={initialSort}
    noDataText="Contracts not found"
    {...props}
  >
    {({
      Component, initialFilters, onFiltersChange, totalCount,
    }) => (
      <FilterManager initialFilters={initialFilters} onChange={onFiltersChange}>
        {({ onFilterChange, filters }) => (
          <>
            <div className="table-filters" data-testid="contract-list-filters">
              <FilterCollection onFilterChange={onFilterChange} filters={filters} />
              <span className="table-count" data-testid="table-count">
                <span>{`${labels.get('titles.totalCount')}: ${totalCount}`}</span>
              </span>
            </div>
            {Component}
          </>
        )}
      </FilterManager>
    )}
  </CustomContractTable>
);

export default ContractTable;
