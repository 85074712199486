import React from 'react';
import StatusCell from '../../components/StatusCell';

const columns = [
  {
    Header: 'Id',
    accessor: 'masterId',
    fixWidth: '100',
  },
  {
    Header: 'Name',
    accessor: 'masterName',
  },
  {
    Header: 'Master Code',
    accessor: 'masterCode',
  },
  {
    Header: 'Active',
    accessor: 'status',
    Cell: (e) => {
      const { isActive } = e.row.original;
      return <StatusCell active={isActive} />;
    },
  },
];

export default columns;
