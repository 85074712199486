import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import AutoComplete from '../../components/Form/GridAutoComplete';
import { apiUrls } from '../../api/constants';

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    flex: 1,
    maxWidth: 'auto',
  },
];

const ProviderGridAutoComplete = ({
  disabled,
  required,
  initialValue,
  labelSpan,
  inputSpan,
  onSelect,
  styleWidth,
  name,
  className,
  dropdownMatchSelectWidth,
  selectProps,
  params,
  notFoundContent,
  dataTestId,
  ...props
}) => {
  const providerOptionParser = useCallback((option) => ({
    ...option,
    name: option.providerName,
    value: option.providerId,
  }), []);

  return (
    <div>
      <AutoComplete
        name={name}
        url={apiUrls.GET_PROVIDERS}
        optionParser={providerOptionParser}
        optionMaster="SearchText"
        labelSpan={labelSpan}
        inputSpan={inputSpan}
        disabled={disabled}
        required={required}
        minCharLength={1}
        initialValue={initialValue}
        columns={columns}
        style={styleWidth ? { width: '100%' } : {}}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        className={className}
        notFoundContent={notFoundContent}
        showArrow
        labelInValue
        selectProps={{ ...selectProps, optionLabelProp: 'name', allowClear: true }}
        params={params}
        onSelect={onSelect}
        data-testid={dataTestId}
        {...props}
      />
    </div>
  );
};

ProviderGridAutoComplete.defaultProps = {
  disabled: false,
  required: false,
  initialValue: {},
  labelSpan: '0',
  inputSpan: '24',
  onSelect: () => { /* This is intentional */ },
  styleWidth: true,
  name: 'provider',
  className: '',
  dropdownMatchSelectWidth: false,
  params: {},
  placeholder: '',
  label: 'Provider',
  notFoundContent: 'Provider is not present in the system.',
  dataTestId: '',
};

ProviderGridAutoComplete.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  initialValue: PropTypes.objectOf(PropTypes.any),
  labelSpan: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  inputSpan: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  onSelect: PropTypes.func,
  styleWidth: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
  dropdownMatchSelectWidth: PropTypes.oneOf([PropTypes.number, PropTypes.bool]),
  params: PropTypes.objectOf(PropTypes.string),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  notFoundContent: PropTypes.string,
  dataTestId: PropTypes.string,
};

export default ProviderGridAutoComplete;
