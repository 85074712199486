import React, { useCallback, useEffect, useState } from 'react';

import ButtonGroup from '../../../../components/ButtonGroup';

import Radio from '../../../../components/Form/Radio';
import Modals from '../../../../components/Modal';
import WidgetLoader from '../../../../components/WidgetLoader';

const UpdateOptions = ({
  isVisible,
  toggleModal,
  form,
  optionChange,
  loading,
  setSelectedOption,
}) => {
  const [defaultUpdateOptions, setDefaultUpdateOptions] = useState([{
    label: 'Change secondary profile to primary profile',
    name: 'Change secondary profile to primary profile',
    value: 'secondaryToPrimary',
  }, {
    label: 'Create new Primary profile',
    name: 'Create new Primary profile',
    value: 'newPrimaryProfile',
  }, {
    label: 'Mark all the profile Inactive',
    name: 'Mark all the profile Inactive',
    value: 'profileInactive',
  }]);

  useEffect(() => {
    if (optionChange) {
      setDefaultUpdateOptions(defaultUpdateOptions.slice(1, defaultUpdateOptions.length));
    }
  }, [optionChange]);

  const handleRadioChanged = useCallback(({ target }) => {
    const { value } = target;
    setSelectedOption(value);
  }, [setSelectedOption]);

  const onSubmitForm = useCallback(() => {
    form.submit();
    toggleModal(false);
  }, [toggleModal, form]);

  return (
    <Modals
      visible={isVisible}
      toggleModal={toggleModal}
      destroyOnClose
      width="400px"
      className="new-profile-instant"
      title="Would you like to create new profile instant"
      footer={(
        <ButtonGroup
          saveVisible
          cancelVisible
          onCancel={() => toggleModal(false) && setSelectedOption()}
          onSave={onSubmitForm}
        />
  )}
    >
      <div>
        <Radio
          required
          name="updatedOptions"
          label=""
          className="vertical-radio"
          options={defaultUpdateOptions}
          labelSpan="0"
          inputSpan="24"
          valuePropName="checked"
          radioProps={{
            defaultValue: 'profileInactive',
          }}
          onChange={handleRadioChanged}
        />
      </div>
      {loading && <WidgetLoader />}
    </Modals>
  );
};

export default UpdateOptions;
