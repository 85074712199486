import {
  takeLatest, put, call, all, select,
} from 'redux-saga/effects';
import { batchActions } from 'redux-batched-actions';
import {
  setError,
  setLoading,
  setRedirectKey,
  setSelectedUser,
  setUpdatedUserInStore,
  ADD_USERS,
  GET_SELECTED_USER,
  UPDATE_USERS,
  // GET_USER_SETTINGS,
  UPDATE_USER_SETTINGS,
  setUserSettings,
  setUpdate,
} from '../actions/users';
import { mergeUpdatedData } from './util';
import { encodeUserSettings } from '../../lib/util';
import { formName } from '../../lib/constants';
import { clearFormData } from '../actions/form';

/* *******Selectors to access state ******** */
const getUser = (state) => state.users;
const getFormData = (state) => state.form;
/* **************************************** */

const getUserIndex = (
  users, userId,
) => users.findIndex(
  (element) => element.id === parseInt(userId, 10),
);

const addUser = (userData, usersState) => new Promise((resolve, reject) => {
  try {
    /* Below LocalStorage work will replace with server call */
    const newUser = { lastModified: new Date(), lastModifiedBy: 'Loretta Rois', ...userData };
    const users = usersState.get('users');
    newUser.id = users.length;
    users.unshift(newUser);
    resolve(newUser.id);
  } catch (error) {
    reject(error);
  }
});

const getCurrentUser = (currentUserId, usersState) => new Promise((resolve, reject) => {
  try {
    const users = usersState.get('users');
    const userIndex = getUserIndex(users, currentUserId);
    const userData = users[userIndex];
    resolve({ user: userData });
  } catch (error) {
    reject(error);
  }
});

const updateUser = (updatedUserData, usersState) => new Promise((resolve, reject) => {
  try {
    const users = usersState.get('users');
    const updateUserIndex = getUserIndex(users, updatedUserData.id);
    users[updateUserIndex] = updatedUserData;
    usersState.set('users', users);
    resolve({ updatedUserData });
  } catch (error) {
    reject(error);
  }
});

/* ******************************************************************** */
/* ****************************USER SAGA******************************* */
/* ******************************************************************** */

export const addUserSaga = function* ({ payload: userPayload }) {
  yield put(setLoading(true));
  try {
    const users = yield select(getUser);
    const newUserId = yield call(addUser, userPayload, users);
    yield put(batchActions([
      setRedirectKey(newUserId),
      setError(null),
    ]));
  } catch (e) {
    yield put(setError(e));
  } finally {
    yield put(setLoading(false));
  }
};

export const getCurrentUserSaga = function* ({ userId }) {
  yield put(setLoading(true));
  try {
    const users = yield select(getUser);
    const form = yield select(getFormData);
    const { user } = yield call(getCurrentUser, userId, users);
    const updatedUser = yield call(mergeUpdatedData, user, form, formName.usersForm);
    yield put(batchActions([
      setSelectedUser(updatedUser),
      setError(null),
    ]));
  } catch (e) {
    yield put(setError(e));
  } finally {
    yield put(setLoading(false));
  }
};

export const updateUserSaga = function* ({ payload: updateUserPayload }) {
  yield put(setLoading(true));
  yield put(setUpdate(false));
  try {
    const users = yield select(getUser);
    const { updatedUserData } = yield call(updateUser, updateUserPayload, users);
    yield put(batchActions([
      setUpdatedUserInStore(updatedUserData),
      setUpdate(true),
      setError(null),
      clearFormData(formName.usersForm),
    ]));
  } catch (e) {
    yield put(setError(e));
  } finally {
    yield put(setLoading(false));
  }
};

export const updateUserSettings = function* ({ payload }) {
  try {
    const users = yield select(getUser);
    const oldFilters = users.get('userSettings');
    delete oldFilters.current;

    const newFilters = {};
    Object.entries(payload).forEach(([key, value]) => {
      newFilters[key] = encodeUserSettings(value);
    });
    const data = { ...oldFilters, ...newFilters };
    // yield call(api.post, {
    //   data,
    //   url: apiUrls.UPDATE_USER_SETTINGS,
    //   token: localStorage.getDecryptedData('token'),
    // });
    yield put(setUserSettings(data));
  } catch (e) {
    console.log('e', e);
  }
  // finally {
  // }
};

export default function* root() {
  yield all([
    takeLatest(ADD_USERS, addUserSaga),
    takeLatest(GET_SELECTED_USER, getCurrentUserSaga),
    takeLatest(UPDATE_USERS, updateUserSaga),
    // takeLatest(GET_USER_SETTINGS, getUserSettings),
    takeLatest(UPDATE_USER_SETTINGS, updateUserSettings),
  ]);
}
