/* eslint no-use-before-define: 2 */
import React, {
  lazy, useCallback, useEffect, useState,
} from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { useIdleTimer, workerTimers } from 'react-idle-timer';
import moment from 'moment';
import get from 'lodash/get';

import { UiRoutes } from '../../lib/constants';
import { retry } from '../../lib/util';
import {
  clearLoginData, setCurrentData, logout,
} from '../../store/actions/login';
import { getEnumData } from '../../store/actions/enum';

import useRedirect from '../../hooks/useRedirect';

import ReactSuspense from '../../hoc/Suspense';

import ErrorMessages from '../../lib/errorMessages';
import Notification from '../../components/Notification';

import './content.scss';

import AppointmentDashboard from '../../screens/AppointmentDashboard';
import Dashboard from '../../screens/Dashboard';
import Schedular from '../../screens/Schedular';
import Patients from '../../screens/Patients';
import Billing from '../../screens/Billing';
import NewBillingEncounter from '../../screens/Billing/AddBilling';
import EditBillingEncounter from '../../screens/Billing/EditBilling';

import Claims from '../../screens/Claims';
import CollectionWithoutTab from '../../screens/Claims/withoutTabs/Collection';
import RemittanceWithoutTab from '../../screens/Claims/withoutTabs/Remittance';
import Contract from '../../screens/Contract';
import AddPatient from '../../screens/Patients/AddPatient';
import Provider from '../../screens/Settings/Provider';
import CPT from '../../screens/Settings/CPT';
import CPTICDCrosswalk from '../../screens/Settings/CPTICDCrosswalk';
import ICD from '../../screens/Settings/ICD';
import Synonyms from '../../screens/Settings/Synonyms';
import ConcernForm from '../../screens/Settings/ConcernForm';
import SurgeryPatient from '../../screens/Settings/Surgery/SurgeryPatient';
import SurgerySteps from '../../screens/Settings/Surgery/SurgerySteps';

import Payer from '../../screens/Settings/Payer';
import AddProvider from '../../screens/Settings/Provider/AddProvider';
import EditProvider from '../../screens/Settings/Provider/EditProvider';
import Users from '../../screens/Settings/Users';
import AddUser from '../../screens/Settings/Users/AddUser';
import Theme from '../../screens/Theme';
import EditUser from '../../screens/Settings/Users/EditUser';
import NewContract from '../../screens/Contract/NewContract';
import EditContract from '../../screens/Contract/EditContract';
import Locations from '../../screens/Locations';
import AddLocation from '../../screens/Locations/AddLocation';
import EditLocation from '../../screens/Locations/EditLocation';
import BookAppointment from '../../screens/Schedular/BookAppointment';
import Masters from '../../screens/Settings/Masters';
import Tasks from '../../wiredComponents/Tasks';
import Composer from '../../screens/Settings/Composer';
import UserRoles from '../../screens/Settings/UserRoles';
import ManageFavDoc from '../../screens/Settings/ManageFavDoc';
import SessionModal from './SessionModal';
import Events from '../../lib/events';
import api from '../../api';
import { apiUrls } from '../../api/constants';
import encryptData from '../../lib/encryptData';

const Doctor = lazy(() => retry(() => import('../../screens/Doctor')));
const Technician = lazy(() => retry(() => import('../../screens/Technician')));
const TechnicianLilyLee = lazy(() => retry(() => import('../../screens/TechnicianLilyLee')));
const Pverify = lazy(() => retry(() => import('../../screens/Pverify')));
const BusinessEntity = lazy(() => retry(() => import('../../screens/Settings/BusinessEntity')));
const Reports = lazy(() => retry(() => import('../../screens/Reports')));

const refreshToken = async () => {
  const refreshTokenData = await api.post({
    url: apiUrls.REFRESH_TOKEN,
    data: {
      AccessToken: localStorage.getDecryptedData('token'),
      RefreshToken: localStorage.getDecryptedData('refreshToken'),
      Offset: encryptData(moment().utcOffset()),
    },
    token: localStorage.getDecryptedData('token'),
  });
  localStorage.setEncryptedData('token', get(refreshTokenData, 'accessToken'));
  localStorage.setEncryptedData('refreshToken', get(refreshTokenData, 'refreshToken'));
  localStorage.setEncryptedData('accessTokenExpirationTime', moment(get(refreshTokenData, 'accessTokenExpiration')).diff(moment(), 'minutes'));
  localStorage.setEncryptedData('userLoggedIn', true); // used by user settings reducer
};

function Content(props) {
  const {
    method, clearLogin, getEnums, setCurrentUserData, logoutUser,
  } = props;
  const { push, location } = useRedirect();
  const [isVisible, setModalVisible] = useState(false);
  const [count, setCount] = useState(0);
  const tokenExpirationTime = localStorage.getDecryptedData('accessTokenExpirationTime') || 41;

  const onIdle = useCallback(() => {
    setModalVisible(false);
    logoutUser();
    setCount(0);
  }, [logoutUser]);

  const onActive = useCallback(() => {
    setModalVisible(false);
    setCount(0);
  }, []);

  const handleOnPrompt = useCallback(() => {
    setModalVisible(true);
    setCount(60);
  }, []);

  const { getRemainingTime, isPrompted, reset } = useIdleTimer({
    timeout: tokenExpirationTime * 60 * 1000,
    promptTimeout: 1000 * 60,
    onPrompt: handleOnPrompt,
    onIdle,
    onActive,
    crossTab: {
      emitOnAllTabs: true,
    },
    timers: workerTimers,
    syncTimers: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setCount(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  const toggleModal = useCallback(() => {
    if (isVisible) {
      refreshToken();
      setModalVisible(false);
      reset();
    }
  }, [isVisible, reset]);

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (location.pathname != UiRoutes.login) {
      const token = localStorage.getDecryptedData('token');
      if (!token) {
        push(UiRoutes.login);
      } else {
        const user = JSON.parse(localStorage.getDecryptedData('user'));
        setCurrentUserData(user, token);
        getEnums();
      }
    }
  }, []);

  useEffect(() => {
    if (method === 'REQUEST_CANCELLED_SESSION_TIMEOUT') {
      push(UiRoutes.login);
      Notification({ message: ErrorMessages.LOGIN_EXPIRED, success: false });
      clearLogin();
      window.location = `${location.href}?eraseCache=true`;
    }
  }, [method]);

  useEffect(() => {
    Events.on('logout', 'logout', () => {
      logoutUser();
      window.location = `${location.href}?eraseCache=true`;
    });
    return () => Events.remove('logout');
  }, []);

  return (
    <div className="app-content">
      <div className="page-container" data-testid="content-index">
        <Switch location={location}>
          <Route exact path="/appointment" component={BookAppointment} />
          <Route
            path={[UiRoutes.dashboardWithAppointment, UiRoutes.dashboard]}
            component={AppointmentDashboard}
          />
          <Route exact path={UiRoutes.schedularWithExtraInfo} component={Schedular} />
          <Route path={UiRoutes.schedular} component={Schedular} />
          {/* <Route path={UiRoutes.schedularWithId} component={Schedular} /> */}
          {/* <Route path={'/schedular1'} component={Schedular} /> */}
          {/* <Route path={'/newEncounter'} component={NewEncounter} /> */}
          <Route exact path={UiRoutes.locations} component={Locations} />
          <Route path={UiRoutes.newLocations} component={AddLocation} />
          <Route exact path={UiRoutes.editLocations} component={EditLocation} />
          <Route path={UiRoutes.editLocationWithTabId} component={EditLocation} />
          {/* <Route exact path={UiRoutes.addExamroom} component={EditLocation} /> */}
          {/* <Route exact path={UiRoutes.editExamroom} component={EditLocation} /> */}
          <Route path="/theme" component={Theme} />
          <Route exact path={UiRoutes.patients} component={Patients} />
          <Route exact path={UiRoutes.editPatient} component={Dashboard} />
          <Route path={UiRoutes.editPatientWithTabId} component={Dashboard} />
          <Route path={UiRoutes.newPatient} component={AddPatient} />
          <Route path={UiRoutes.claimsCollections} component={CollectionWithoutTab} />
          <Route path={UiRoutes.claimsRemittance} component={RemittanceWithoutTab} />
          <Route path={UiRoutes.claims} component={Claims} />
          {/* <Route path={UiRoutes.newClaims} component={NewClaims} /> */}
          <Route path={UiRoutes.doctor} component={Doctor} />
          <Route path={UiRoutes.technician} component={Technician} />
          <Route path={UiRoutes.technicianLilyLee} component={TechnicianLilyLee} />
          <Route path={UiRoutes.pverify} component={Pverify} />
          <Route exact path={UiRoutes.contract} component={Contract} />
          <Route path={UiRoutes.reportsMain} component={Reports} />
          <Route path={UiRoutes.newContract} component={NewContract} />
          <Route exact path={UiRoutes.editContract} component={EditContract} />
          <Route path={UiRoutes.editContractWithTabId} component={EditContract} />
          <Route exact path={UiRoutes.billing} component={Billing} />
          <Route exact path={UiRoutes.addBilling} component={NewBillingEncounter} />
          <Route
            exact
            path={UiRoutes.editBillingEncounterWithTabId}
            component={EditBillingEncounter}
          />
          <Route path={UiRoutes.tasksWithTabId} component={Tasks} />
          <Route path={UiRoutes.businessEntity} component={BusinessEntity} />
          <Route exact path={UiRoutes.provider} component={Provider} />
          <Route path={UiRoutes.CPT} component={CPT} />
          <Route path={UiRoutes.CPT_ICD_CROSSWALK} component={CPTICDCrosswalk} />
          <Route path={UiRoutes.ICD} component={ICD} />
          <Route path={UiRoutes.Synonyms} component={Synonyms} />
          <Route path={UiRoutes.ConcernForm} component={ConcernForm} />
          <Route exact path={UiRoutes.SurgeryPatient} component={SurgeryPatient} />
          <Route path={UiRoutes.SurgerySteps} component={SurgerySteps} />
          <Route path={UiRoutes.payer} component={Payer} />
          <Route path={UiRoutes.newProvider} component={AddProvider} />
          <Route exact path={UiRoutes.editProvider} component={EditProvider} />
          <Route path={UiRoutes.editProviderWithTabId} component={EditProvider} />
          <Route path={UiRoutes.editProvider} component={AddProvider} />
          <Route exact path={UiRoutes.users} component={Users} />
          <Route path={UiRoutes.newUser} component={AddUser} />
          <Route exact path={UiRoutes.editUser} component={EditUser} />
          <Route exact path={UiRoutes.editUserWithTabId} component={EditUser} />
          <Route
            path={[UiRoutes.editMastersWithId, UiRoutes.mastersWithId, UiRoutes.masters]}
            component={Masters}
          />
          <Route path={UiRoutes.composer} component={Composer} />
          <Route path={UiRoutes.UserRoles} component={UserRoles} />
          <Route path={UiRoutes.ManageFavDoc} component={ManageFavDoc} />
        </Switch>
        <SessionModal
          isVisible={isVisible}
          toggleModal={toggleModal}
          count={count}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loginUser: state.login.get('current'),
  loading: state.login.get('loading'),
  method: state.login.get('method'),
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUserData: (response, token) => dispatch(setCurrentData(response, token)),
  clearLogin: () => dispatch(clearLoginData()),
  getEnums: () => dispatch(getEnumData()),
  logoutUser: () => dispatch(logout('REQUEST_CANCELLED_SESSION_TIMEOUT')),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReactSuspense(Content));
