import React, { useMemo, useCallback, useEffect } from 'react';

import { apiUrls } from '../../../../../../api/constants';
import useRedirect from '../../../../../../hooks/useRedirect';
import withQuery from '../../../../../../hoc/withQuery/withQuery';
import {
  listId,
} from '../../../../../../lib/constants';
import { isDecimal } from '../../../../../../lib/util';
import columns from '../../PatientPayment/ClaimsTable/columns';

const ClaimTable = ({
  labels, searchText, setCurrentPath, onClick, setBillingEncounterId, formId,
  patientId: propsPatientId, isEditTransaction, setPatientId,
}) => {
  const {
    replace, path, generatePath, params,
  } = useRedirect();

  const Table = useMemo(() => withQuery({
    url: apiUrls.GET_CLAIMS_LIST,
    listId: `${listId.CLAIMS}-${formId}`,
  })(), [formId]);

  const onRowClick = useCallback(({ original: { claimId, patientId, billingEncounterId } }) => {
    if (setPatientId) setPatientId(patientId);
    if (setBillingEncounterId) setBillingEncounterId(billingEncounterId);
    if (onClick) {
      onClick(claimId, patientId);
    } else {
      replace(
        generatePath(
          `${path}/:claimId/:id/:encounterId/:isRemittanceAddPayment`,
          {
            ...params,
            claimId,
            id: patientId,
            encounterId: billingEncounterId,
            isRemittanceAddPayment: true,
          },
        ),
      );
    }
  }, [setPatientId, setBillingEncounterId, onClick, replace, generatePath, path, params]);

  useEffect(() => {
    if (setCurrentPath) {
      setCurrentPath(path);
    }
  }, []);

  const searchFilter = useMemo(() => {
    const searchTextFilter = { SearchText: searchText };
    if (isEditTransaction) {
      return {
        ...searchTextFilter,
        PatientId: propsPatientId,
        EditTransaction: true,
      };
    }
    return searchTextFilter;
  }, [isEditTransaction, propsPatientId, searchText]);

  return (
    <Table
      columns={columns(labels)}
      scrollId="claims-remittance-search"
      filters={{
        state: 'insurancepayment',
        isAmountSearchText: searchText && isDecimal(searchText),
        isRemittanceClaimSearch: true,
        ...searchFilter,
      }}
      footer
      key={formId}
      skipInitialFetch
      onRowClick={onRowClick}
      customData={searchText ? null : []}
    >
      {({ Component }) => Component}
    </Table>
  );
};

export default ClaimTable;
