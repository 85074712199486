import React from 'react';
import { Route } from 'react-router-dom';

import rights from '../../../lib/rights';
import { labelPaths, UiRoutes } from '../../../lib/constants';
import WithLabel from '../../../hoc/withLabel';
import WithRights from '../../../hoc/withRights';

import TaskTable from '../TaskTable';

import '../billing.scss';
import EditTaskModal from './Components/EditTaskModal';

const All = ({ labels }) => (
  <div className="mr-rt-16 mr-bt-16">
    <TaskTable labels={labels} />
    <Route path={UiRoutes.tasksDetailWithTabId}>
      <EditTaskModal
        visible
        labels={labels}
      />
    </Route>
  </div>
);

export default WithRights(
  WithLabel(All, labelPaths.BILLING_TASKS_ALL), rights.access_to_task_management_app,
);
