import React from 'react';

import Form from '../../../../components/Form';
import Select from '../../../Enum/Select';
import DatePicker from '../../../../components/Form/DatePicker';

import { enums as enumConstant } from '../../../../lib/constants';

import LocationGridAutoComplete from '../../../LocationGridAutoComplete';
import UserAutoComplete from '../../../UserAutoComplete';
import PayerGridAutoComplete from '../../../PayerGridAutoComplete';
import ProviderGridAutoComplete from '../../../ProviderGridAutoComplete';
import '../../billing.scss';

const FilterForm = ({
  labels, form, onFieldChange,
}) => (
  <Form.Section noOfColumns={2}>
    <Form.Column>
      <UserAutoComplete
        name="AssignedTo"
        label={labels.get('labels.assignedTo')}
        placeholder="Select Assigned To"
        form={form}
        minCharLength={3}
        labelSpan={10}
        inputSpan={14}
        header={false}
        onSelect={onFieldChange}
      />
      <LocationGridAutoComplete
        name="LocationId"
        label={labels.get('labels.location')}
        placeholder="Select Location"
        form={form}
        minCharLength={3}
        labelSpan={10}
        inputSpan={14}
        header={false}
        onSelect={onFieldChange}
      />
      <PayerGridAutoComplete
        label={labels.get('labels.payer')}
        name="InsurancePayerId"
        placeholder="Enter Payer Name"
        nameAccessor="name"
        valueAccessor="id"
        notFoundContent="Payer is not present is the system."
        labelSpan="10"
        inputSpan="14"
        onSelect={onFieldChange}
      />
      <PayerGridAutoComplete
        name="healthPlanId"
        label={labels.get('labels.healthPlan')}
        nameAccessor="payerName"
        valueAccessor="id"
        notFoundContent="Health plan is not present is the system."
        labelSpan="10"
        inputSpan="14"
        onSelect={onFieldChange}
      />
    </Form.Column>
    <Form.Column>
      <div>
        <Select
          label={labels.get('labels.issue')}
          name="SubCategoryId"
          enumName={enumConstant.TASK_SUB_CATEGORIES}
          onChange={onFieldChange}
        />
        <ProviderGridAutoComplete
          id="provider"
          name="ProviderId"
          label={labels.get('labels.resource')}
          placeholder="Select Resource"
          notFoundContent="Resource is not present is the system."
          selectProps={{
            size: 'middle',
            showSearch: true,
          }}
          labelSpan="10"
          inputSpan="14"
          onSelect={onFieldChange}
        />
        <DatePicker
          label={labels.get('labels.fromDueDate')}
          name="FromDueDate"
          onChange={onFieldChange}
        />
        <DatePicker
          label={labels.get('labels.toDueDate')}
          name="ToDueDate"
          onChange={onFieldChange}
        />
      </div>
    </Form.Column>
  </Form.Section>
);

export default FilterForm;
