import React from 'react';

import CasesTable from '../../../../../../wiredComponents/CasesTable';
import columns from './Components/columns';

const Cases = (props) => (
  <CasesTable {...props} columns={columns} />
);

export default Cases;
