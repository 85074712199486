import React from 'react';

import classNames from 'classnames';

import PropTypes from 'prop-types';
import Icon from '../Icon';
import iconNames from '../../lib/iconNames';
import './panel.scss';

const Panel = ({
  title, onClose, children, className, headerRightProps, buttons, showCloseIcon,
}) => (
  <div className={classNames('panel', className)}>
    <div className="panel-heading">
      <div>{title}</div>
      <div className="flex">
        <div>{headerRightProps}</div>
        {buttons}
        {showCloseIcon && (
        <div className="space cursor-pointer">
          {onClose && <Icon name={iconNames.close} onClick={() => onClose(false)} />}
        </div>
        )}
      </div>
    </div>
    <div className="panel-body">
      {children}
    </div>
  </div>
);

Panel.defaultProps = {
  title: '',
  onClose: () => { /* This is intentional */ },
  children: [],
  className: '',
  headerRightProps: '',
  buttons: [],
  showCloseIcon: true,
};

Panel.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  headerRightProps: PropTypes.string,
  buttons: PropTypes.instanceOf(Array),
  showCloseIcon: PropTypes.bool,
};

export default Panel;
