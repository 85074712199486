import React, { useEffect, useState } from 'react';
import { Tree } from 'antd';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { connect, useDispatch } from 'react-redux';

import { enums } from '../../lib/constants';

import { getEnumMasterData } from '../../store/actions/enum';
import * as selectors from '../../store/selectors';

const { TreeNode } = Tree;

const handleDragStart = (event) => {
  event.dataTransfer.setData('text', event.target.id);
  event.dataTransfer.setData('type', 'acuity-eye-tree-leaf');
  event.target.classList.add('dragging');
};

const handleDragEnd = (event) => {
  event.target.classList.remove('dragging');
};

const filterNodes = (searchString, data = []) => data.map((item) => {
  if (item?.subType) {
    const subType = filterNodes(searchString, item?.subType);
    if (subType.length) {
      return { ...item, subType };
    }
  }
  if ((item.label).toLowerCase().indexOf(searchString) > -1) { // leaf
    return item;
  }
  return null;
}).filter((item) => item);

const renderTreeNodes = (data) => data.map((item) => {
  if (item?.subType && Array.isArray(item?.subType)) {
    return (
      <TreeNode title={item.label} key={item.key} id={item.label} dataRef={item}>
        {renderTreeNodes(item.subType)}
      </TreeNode>
    );
  }
  return (
    <TreeNode title={item.label} isLeaf key={item.key} />
  );
});

const parseData = (labelsArray) => labelsArray.map((label) => {
  const labelArray = JSON.parse(label.optionalValues);
  return labelArray[0];
});

const JsonTree = ({
  list, search, setLabelsList, enumMaster, enumOptions, ...otherProps
}) => {
  const [filteredList, setFilteredList] = useState('');

  const dispatch = useDispatch();

  const enumId = get(enumMaster, `${enums.COMPOSER_LABELS}.enumId`);

  useEffect(() => {
    dispatch(getEnumMasterData(enumId));
  }, [enumId, dispatch]);

  useEffect(() => {
    if (enumOptions[enumId]?.data?.length) {
      const parsedJson = parseData(enumOptions[enumId].data);
      setLabelsList(parsedJson);
      setFilteredList(parsedJson);
    }
  }, [enumId, enumOptions, setLabelsList]);

  useEffect(() => {
    setFilteredList(filterNodes((search).toLowerCase(), list));
  }, [search]);

  if (filteredList?.length) {
    return (
      <Tree
        defaultExpandAll
        titleRender={(node) => (
          <div
            className={node?.isLeaf ? 'tree-leaf-node' : 'tree-parent-node'}
            draggable={node?.isLeaf}
            onDragStart={(e) => handleDragStart(e)}
            onDragEnd={(e) => handleDragEnd(e)}
            id={node?.key}
          >
            {node?.title}
          </div>
        )}
        {...otherProps}
      >
        {renderTreeNodes(filteredList || list)}
      </Tree>
    );
  }
  return (
    <span />
  );
};

JsonTree.defaultProps = {
  list: [],
  search: '',
  url: '',
  setLabelsList: () => { /* This is intentional */ },
};

JsonTree.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  search: PropTypes.string,
  url: PropTypes.string,
  setLabelsList: PropTypes.func,
};

export default connect((states) => ({
  enumOptions: selectors.getEnumOptions(states),
  enumMaster: selectors.getEnumMaster(states),
}))(JsonTree);
