import React, { useCallback, useState } from 'react';

import Modal from '../../../../../../../components/Modal';
import Button from '../../../../../../../components/Button';

import InsuranceAdjustment from '../../../../../../../wiredComponents/Adustment/Components/InsuranceAdjustment';

const EnterAdjustment = ({ stateId, stageId, queueId }) => {
  const [isInsuranceAdjustmentVisible, setInsuranceAdjustmentVisibility] = useState(false);

  const toggleInsuranceAdjustment = useCallback(() => setInsuranceAdjustmentVisibility(
    !isInsuranceAdjustmentVisible,
  ), [isInsuranceAdjustmentVisible]);

  return (
    <>
      {isInsuranceAdjustmentVisible && (
      <Modal
        visible={isInsuranceAdjustmentVisible}
        footer={null}
        width="600"
        toggleModal={toggleInsuranceAdjustment}
      >
        <div className="addition-header">
          <div className="lookup sprite-img-before">
            <p>Insurance Adjustment</p>
          </div>
        </div>
        <InsuranceAdjustment
          closeTab={toggleInsuranceAdjustment}
          navigateFromCollection
          stateId={stateId}
          stageId={stageId}
          queueId={queueId}
        />
      </Modal>
      )}
      <Button onClick={toggleInsuranceAdjustment}>Enter Adjustment</Button>
    </>
  );
};

export default EnterAdjustment;
