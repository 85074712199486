/* eslint-disable consistent-return */
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import server, { multipartServer } from './server';
import serverV2, { multipartServerV2 } from './serverV2';
import formDataParser from '../lib/formDataParser';
import { contentType } from '../lib/constants';
import { getEncryptedParams, getEncryptedQueryParams } from './encryption';
import { apiUrls } from './constants';

let cancelToken = axios.CancelToken.source();

const urlNotEligibleForEncryption = [
  apiUrls.LOGIN,
  apiUrls.GET_MICROSOFT_LOGIN_DETAILS,
  apiUrls.RESET_PASSWORD,
  apiUrls.FORGOT_PASSWORD,
];

const multipartFormDataServer = (options, method) => {
  const {
    data, url, token, version = 1,
  } = options || {};
  delete data.contentType;
  const formData = formDataParser(data);

  const serverOptions = {
    url,
    method,
    data: formData,
    headers: { Authorization: `Bearer ${token}` },
    cancelToken: cancelToken.token,
  };

  if (version === 2) {
    return multipartServerV2(serverOptions);
  }

  return multipartServer(serverOptions);
};

const get = (options) => {
  const {
    token, url, params = {}, version = 1,
  } = options || {};
  const { responseType } = params;
  if (responseType) {
    delete params.responseType;
  }
  let encryptParams;
  /** if ?value=2  is appended in url */
  const [apiPath, queryParamsFromUrl] = url?.split('?');
  if (queryParamsFromUrl && isEmpty(params)) {
    encryptParams = getEncryptedParams(`?${queryParamsFromUrl}`);
  } else {
    encryptParams = getEncryptedQueryParams(params);
  }
  const serverOptions = {
    url: process.env.REACT_APP_API_ENCRYPTION === 'true' && queryParamsFromUrl ? apiPath : url,
    method: 'get',
    headers: process.env.REACT_APP_API_ENCRYPTION === 'true'
      ? { Authorization: `Bearer ${token}`, 'x-response-key': encryptParams?.rqk, 'x-iv-key': encryptParams?.ivKey }
      : { Authorization: `Bearer ${token}` },
    params: process.env.REACT_APP_API_ENCRYPTION === 'true' && (!isEmpty(JSON.parse(JSON.stringify(params))) || queryParamsFromUrl) ? { qs: encryptParams?.rq } : params,
    responseType,
    cancelToken: cancelToken.token,
  };

  if (version === 2) {
    return serverV2(serverOptions);
  }

  return server(serverOptions);
};

const post = (options) => {
  const {
    data, url, token, version = 1,
  } = options || {};
  if (!data) return;
  if (data.contentType === contentType.MULTIPART) {
    return multipartFormDataServer(options, 'POST');
  }
  const encryptData = getEncryptedParams(data);
  const serverOptions = {
    url,
    method: 'POST',
    data: process.env.REACT_APP_API_ENCRYPTION === 'true' && !urlNotEligibleForEncryption.includes(url) ? { rq: encryptData?.rq } : data,
    cancelToken: cancelToken.token,
    responseType: data?.responseType,
  };
  if (token) {
    serverOptions.headers = process.env.REACT_APP_API_ENCRYPTION === 'true'
      ? { Authorization: `Bearer ${token}`, 'x-response-key': encryptData?.rqk, 'x-iv-key': encryptData?.ivKey }
      : { Authorization: `Bearer ${token}` };
  }
  if (!token && !urlNotEligibleForEncryption.includes(url)) {
    serverOptions.headers = process.env.REACT_APP_API_ENCRYPTION === 'true'
      ? { 'x-response-key': encryptData?.rqk }
      : {};
  }

  if (version === 2) {
    return serverV2(serverOptions);
  }

  return server(serverOptions);
};

const put = (options) => {
  const {
    data, url, token, version = 1,
  } = options || {};
  if (data.contentType === contentType.MULTIPART) {
    return multipartFormDataServer(options, 'PUT');
  }
  const encryptData = getEncryptedParams(data);

  const serverOptions = {
    url,
    method: 'PUT',
    data: process.env.REACT_APP_API_ENCRYPTION === 'true' ? { rq: encryptData?.rq } : data,
    headers: process.env.REACT_APP_API_ENCRYPTION === 'true'
      ? { Authorization: `Bearer ${token}`, 'x-response-key': encryptData?.rqk, 'x-iv-key': encryptData?.ivKey }
      : { Authorization: `Bearer ${token}` },
    cancelToken: cancelToken.token,
  };

  if (version === 2) {
    return serverV2(serverOptions);
  }

  return server(serverOptions);
};

const deleteAPI = (options) => {
  const {
    data, url, token, version = 1,
  } = options || {};
  const encryptData = getEncryptedParams(data);
  const serverOptions = {
    url,
    method: 'DELETE',
    data: process.env.REACT_APP_API_ENCRYPTION === 'true' ? { rq: encryptData?.rq } : data,
    cancelToken: cancelToken.token,
  };
  if (token) {
    serverOptions.headers = process.env.REACT_APP_API_ENCRYPTION === 'true'
      ? { Authorization: `Bearer ${token}`, 'x-response-key': encryptData?.rqk, 'x-iv-key': encryptData?.ivKey }
      : { Authorization: `Bearer ${token}` };
  }

  if (version === 2) {
    return serverV2(serverOptions);
  }

  return server(serverOptions);
};

const cancelRequests = () => {
  cancelToken.cancel('REQUEST_CANCELLED_SESSION_TIMEOUT');
  cancelToken = axios.CancelToken.source();
};

export default {
  get,
  post,
  put,
  delete: deleteAPI,
  multipartFormDataServer,
  cancelRequests,
};
