import React, {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { Form as AntdForm } from 'antd';
import Search from 'antd/lib/input/Search';
import debounce from 'lodash/debounce';
import get from 'lodash/get';

import classNames from 'classnames';
import WithLabel from '../../../hoc/withLabel';
import useRedirect from '../../../hooks/useRedirect';
import { enums, labelPaths, enumMasterCode } from '../../../lib/constants';
import { getEnumMaster, getEnumOptions } from '../../../store/selectors';

import Modals from '../../../components/Modal';
import Button from '../../../components/Button';
import Smcheckbox from '../../../components/SmCheckbox';

import PatientTable from '../../PatientTable';
import SelectBox from '../../Enum/Select';
import AddNote from './Components/AddNote';
import NotesTable from './Components/NoteTable';

import './notes.scss';
import '../Tasks/tasks.scss';

const { ALL_NOTES } = enumMasterCode;

const NotesModal = ({
  labels, visible, toggleModal, notesProps, isPverifyNotes, ...otherProps
}) => {
  const { params } = useRedirect();
  const [addMode, setAddMode] = useState(false);
  const [selectedNoteId, setSelectedNoteId] = useState(null);
  const [search, setSearch] = useState(null);
  const [patientId, setSelectedPatientId] = useState(null);
  const [isExclude, setExclude] = useState(true);
  const [panelOpen, setPanelState] = useState(false);
  const [noteSearch, setNoteSearch] = useState(null);
  const [selectedCategoryId, setSelectCategoryId] = useState(undefined);
  const [selectedDefaultCategoryId, setSelectDefaultCategoryId] = useState(undefined);
  const debounced = useMemo(() => debounce(setSearch, 500), [setSearch]);
  const noteDebounced = useMemo(() => debounce(setNoteSearch, 500), [setNoteSearch]);
  const enumMaster = useSelector((state) => getEnumMaster(state));
  const enumId = get(enumMaster, `${enums.NOTES}.enumId`);
  const notesEnumOptions = useSelector((state) => getEnumOptions(state)?.[enumId]?.data || []);

  useEffect(() => {
    if (otherProps?.patientId) setSelectedPatientId(otherProps.patientId);
  }, [otherProps, otherProps.patientId]);

  useEffect(() => {
    if (isPverifyNotes) {
      setAddMode(true);
      setPanelState(true);
      setSelectCategoryId(otherProps?.categoryId);
      setSelectedNoteId(otherProps?.pverifyNoteId);
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(notesEnumOptions) && notesEnumOptions?.length) {
      const defaultOptions = notesEnumOptions.find(
        (enumItem) => enumItem?.masterCode?.toLowerCase() === notesProps?.page,
      );
      setSelectCategoryId(defaultOptions?.masterId);
      setSelectDefaultCategoryId(defaultOptions?.masterId);
    }
  }, [notesEnumOptions, notesProps]);

  const [form] = AntdForm.useForm();
  const toggleAddMode = useCallback(() => {
    setAddMode(!addMode);
    setSelectedNoteId(null);
    noteDebounced(null);
  }, [addMode, noteDebounced]);

  const togglePanel = useCallback(() => {
    setPanelState(!panelOpen);
  }, [panelOpen]);

  const toggleState = useCallback(() => {
    setPanelState(false);
    setAddMode(false);
    setSelectedNoteId(null);
  }, []);

  const onClose = useCallback(() => {
    setSearch('');
    setNoteSearch('');
    if (addMode) toggleAddMode();
    if (panelOpen) togglePanel();
    toggleModal();
    if (!params?.id && !params?.patientId) setSelectedPatientId(null);
  }, [addMode, params, toggleAddMode, toggleModal, togglePanel, panelOpen]);

  const setSelectedPatient = useCallback((patient) => {
    setSelectedPatientId(patient.patientId);
  }, []);

  const handleEditNote = useCallback(({ original: { noteId } }) => {
    setAddMode(true);
    setPanelState(true);
    setSelectedNoteId(noteId);
  }, [setAddMode, setPanelState, setSelectedNoteId]);

  const handleExclude = useCallback(({ target: { checked } }) => setExclude(checked), []);
  const handleSearch = useCallback(({ target: { value } }) => debounced(value), [debounced]);

  const handleNotesSearch = useCallback(({ target: { value } }) => {
    noteDebounced(value);
  }, [noteDebounced]);

  const handleCategoryId = useCallback((val, data) => {
    if (data?.item?.masterCode?.toLowerCase() === ALL_NOTES) {
      setSelectCategoryId(undefined);
    } else {
      setSelectCategoryId(val);
    }
  }, [setSelectCategoryId]);

  if (patientId) {
    return (
      <Modals
        visible={visible}
        width={1020}
        className="notes-modal"
        toggleModal={onClose}
        destroyOnClose
        footer={null}
      >
        <div className="addition-header tasks-modal-header">
          <p className="bold">{labels.get('titles.tasks')}</p>
          { !addMode && !isPverifyNotes && (
          <Button type="button" className="btn sm-btn btn-success" onClick={toggleAddMode}>
            {labels.get('buttons.newNote')}
          </Button>
          )}
        </div>
        <div className="modal-content-area">
          {(addMode && !panelOpen)
            ? (
              <AddNote
                toggleAddMode={toggleAddMode}
                form={form}
                labels={labels}
                patientId={patientId}
                noteId={selectedNoteId}
                handleCancel={toggleState}
                categoryId={selectedDefaultCategoryId || selectedCategoryId}
                toggleState={toggleState}
                notesCategory={notesProps?.page}
                {...otherProps}
              />
            )
            : (
              <>
                <div className="notes-wrapper">
                  <Search
                    className="notes-search"
                    onChange={handleNotesSearch}
                    placeholder="Search"
                  />
                  {!panelOpen
                      && (
                      <SelectBox
                        enumName={enums.NOTES}
                        labelSpan={0}
                        inputSpan={24}
                        selectProps={{
                          style: { minWidth: 150 },
                        }}
                        placeholder="All Notes"
                        name={null}
                        value={selectedCategoryId}
                        dropdownMatchSelectWidth={false}
                        onChange={handleCategoryId}
                      />
                      )}
                </div>
                <div className={classNames('panel-area', { 'toggle-side-pannel': !panelOpen })}>
                  <div className="custom-task-table-task left">
                    <NotesTable
                      data={[]}
                      patientId={patientId}
                      onRowClick={handleEditNote}
                      labels={labels}
                      scrollId="notes-modal-table"
                      filters={{
                        PatientId: patientId,
                        SearchText: noteSearch,
                        CategoryId: selectedCategoryId,
                      }}
                      {...otherProps}
                    />
                  </div>
                  {panelOpen && (
                  <div className="right">
                    <AddNote
                      toggleAddMode={toggleAddMode}
                      form={form}
                      labels={labels}
                      patientId={patientId}
                      noteId={selectedNoteId}
                      togglePanel={togglePanel}
                      handleCancel={toggleState}
                      categoryId={selectedCategoryId}
                      toggleState={toggleState}
                      notesCategory={notesProps?.page}
                      isPverifyNotes={isPverifyNotes}
                      {...notesProps}
                      {...otherProps}
                    />
                  </div>
                  )}
                </div>
              </>
            )}
        </div>
      </Modals>
    );
  }
  return (
    <Modals
      visible={visible}
      width={1020}
      className="notes-modal"
      toggleModal={onClose}
      destroyOnClose
      footer={null}
    >
      <div className="addition-header tasks-modal-header">
        <p className="bold">{labels.get('titles.tasks')}</p>
        {patientId && !addMode && !isPverifyNotes && (
          <Button type="button" className="btn sm-btn btn-success" onClick={toggleAddMode}>
            {labels.get('buttons.newNote')}
          </Button>
        )}
      </div>
      <div className="modal-content-area">
        <div className="patient-list-modal">
          <div className="header-wrapper">
            <div className="filter-wrapper">
              <Search
                className="patent-list-search"
                onChange={handleSearch}
                placeholder="Search"
              />
              <Smcheckbox
                isFormItem={false}
                checked={isExclude}
                onChange={handleExclude}
                className="exclude-filter"
              >
                {labels.get('labels.excludeInactive')}
              </Smcheckbox>
            </div>
          </div>
          <PatientTable
            labels={labels}
            onRowClick={setSelectedPatient}
            filters={{ SearchText: search, ExcludeInactivePatients: isExclude }}
          />
        </div>
      </div>
    </Modals>
  );
};

export default WithLabel(NotesModal, labelPaths.NOTES_MODAL);
