import React, { useCallback, useEffect } from 'react';
import { Form as AntdForm } from 'antd';
import { connect, useDispatch } from 'react-redux';
import get from 'lodash/get';

import Notification from '../../../../components/Notification';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import Form from '../../../../components/Form';

import useCRUD from '../../../../hooks/useCRUD';
import useRedirect from '../../../../hooks/useRedirect';
import useMasterId from '../../../../hooks/useMasterId';

import SuccessMessages from '../../../../lib/successMessages';
import { labelPaths, enums, enumMasterCode } from '../../../../lib/constants';
import { getSystemDate } from '../../../../lib/util';

import { getEnumMasterData } from '../../../../store/actions/enum';

import { apiUrls } from '../../../../api/constants';

import WithLabel from '../../../../hoc/withLabel';

import * as selectors from '../../../../store/selectors';

import PolicyForm from './PolicyForm';
import RenameProfile from './ResponsibleParty/Rename';
import PersonForm from './PersonForm';

const responsiblePartyFormFieldPrefix = 'rp-';
const policyHolderFormFieldPrefix = 'ph-';

function NewProfile(props) {
  const {
    labels, enumMaster = {}, enumOptions, baseRoute,
    queryString, showInActiveInsuranceData, isPayerRequestAuthenticated, formName,
  } = props;

  const {
    params, params: { tabId }, push, generatePath,
  } = useRedirect();

  const dispatch = useDispatch();

  const insuranceRelationSelfMasterId = useMasterId(
    [enumMasterCode.InsuranceRelationSelf],
    [enums.RELATION],
  );

  const enumId = get(enumMaster, `${enums.INSURANCE_POLICY_LEVEL}.enumId`);
  const policyLevel = enumOptions && enumOptions[enumId] && enumOptions[enumId].data;

  const [form] = AntdForm.useForm();

  const requestInsuranceData = useCRUD({
    id: 'patient-insurance-rp', url: apiUrls.GET_PATIENT_INSURANCE, type: 'read',
  })[3];

  const [
    newInsurance,,
    newInsuranceLoading,
    createNewInsurance,
    clearCreateNewInsuranceData] = useCRUD(
    { id: 'masterservice/PatientInsurance', url: 'masterservice/PatientInsurance', type: 'create' },
  );

  useEffect(() => {
    if (enumId) {
      dispatch(getEnumMasterData(enumId));
    }
  }, [enumId]);

  useEffect(() => {
    if (newInsurance && !Array.isArray(newInsurance)) {
      const { insuranceProfileId } = newInsurance;
      Notification({
        message: SuccessMessages.INSURANCE_POLICY_ADDED_SUCCESSFULLY, success: true,
      });
      requestInsuranceData({ PatientId: params.id, includeinactive: showInActiveInsuranceData });
      if (insuranceProfileId) {
        push({
          pathname: generatePath(`${baseRoute}/profile/view/:profileId`, {
            ...params, profileId: insuranceProfileId,
          }),
          search: queryString,
        });
      }
      clearCreateNewInsuranceData();
    }
  }, [newInsurance]);

  const onAddProfile = useCallback((data) => {
    const rpPersonId = form.getFieldValue(`${responsiblePartyFormFieldPrefix}personId`);
    const rpPartyType = form.getFieldValue(`${responsiblePartyFormFieldPrefix}type`);

    const phPersonId = form.getFieldValue(`${policyHolderFormFieldPrefix}personId`);
    const phPartyType = form.getFieldValue(`${policyHolderFormFieldPrefix}type`);
    const insuranceProfile = {
      profileCategoryName: data['rp-profileCategoryName'],
      patientId: params.id,
      responsibleRelationId: data['rp-responsibleRelationId'],
      responsiblePartyType: rpPartyType,
      responsiblePersonId: rpPartyType !== 'Patient' ? (rpPersonId) : null,
      responsiblePatientId: rpPartyType === 'Patient' ? (rpPersonId || params.id) : null,
      isActive: true,
    };
    const profileTypeId = policyLevel?.[0] || {};
    const policyEnumData = form.getFieldInstance('policyTypeId')?.props?.enumData;
    const selectedPolicy = form.getFieldInstance('policyTypeId')?.props?.value;
    const selectedPolicyData = policyEnumData.filter((policy) => policy.value === selectedPolicy);
    const newData = {
      ...data,
      patientId: params.id,
      profileName: data['rp-profileCategoryName'],
      profileTypeId: profileTypeId.masterId,
      planId: 1,
      coPayment: Number(data.coPayment ? data.coPayment : 0),
      deductible: Number(data.deductible ? data.deductible : 0),
      effectiveFrom: getSystemDate(data?.effectiveFrom),
      effectiveTo: getSystemDate(data?.effectiveTo),
      requiresAuthorization: data.requiresAuthorization,
      primaryInsuredRelationId: data['ph-responsibleRelationId'],
      primaryInsuredType: phPartyType,
      primaryInsuredPatientId: phPartyType === 'Patient' ? (phPersonId || params.id) : null,
      primaryInsuredPersonId: phPartyType !== 'Patient' ? (phPersonId) : null,
      policyTypeCode: selectedPolicyData[0]?.masterCode,
      isActive: true,
      insuranceProfile,
    };
    createNewInsurance({ data: newData });
  }, [createNewInsurance, params, policyLevel, form]);

  const onCancel = useCallback(() => {
    form.resetFields();
    push({ pathname: generatePath(baseRoute, { ...params }) });
  }, [baseRoute, form, generatePath, params, push]);

  if (!insuranceRelationSelfMasterId[enumMasterCode.InsuranceRelationSelf]) {
    return <Loader />;
  }
  return (
    <Form
      section
      form={form}
      name={formName}
      tabId={tabId}
      onFinish={onAddProfile}
      initialData={{
        isActive: true,
        [`${responsiblePartyFormFieldPrefix}responsibleRelationId`]: insuranceRelationSelfMasterId[enumMasterCode.InsuranceRelationSelf],
        [`${policyHolderFormFieldPrefix}responsibleRelationId`]: insuranceRelationSelfMasterId[enumMasterCode.InsuranceRelationSelf],
      }}
    >
      <div className="complete-form-wrap insurance-aside-wrap posi-relative">
        {newInsuranceLoading && <Loader />}
        <RenameProfile
          labels={labels}
          formFieldPrefix={responsiblePartyFormFieldPrefix}
          form={form}
          hideActive
        />
        <div className="feild-row justify-content-sp-bt align-center">
          <h2 className="h2-title">{labels.get('titles.responsibleParty')}</h2>
        </div>
        <PersonForm
          form={form}
          labels={labels}
          formFieldPrefix={responsiblePartyFormFieldPrefix}
        />
        <Form.Section>
          <PolicyForm
            form={form}
            labels={labels}
            formFieldPrefix={policyHolderFormFieldPrefix}
            isPayerRequestAuthenticated={isPayerRequestAuthenticated}
            isPolicyHolder
          />
        </Form.Section>
        <Form.Section>
          <div className="btns-action">
            <Button
              className="btn btn-success min-wt-86"
              id="patients_insurance_profile_save"
              type="submit"
            >
              {labels.get('buttons.save')}
            </Button>
            <Button
              className="btn"
              id="patients_insurance_profile_clear"
              onClick={onCancel}
            >
              {labels.get('buttons.cancel')}
            </Button>
          </div>
        </Form.Section>
      </div>
    </Form>
  );
}

export default connect((state) => ({
  enumOptions: selectors.getEnumOptions(state),
  enumMaster: selectors.getEnumMaster(state),
}))(WithLabel(NewProfile, labelPaths.ADD_INSURANCE_PROFILE));
