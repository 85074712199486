import React, { useCallback, useState } from 'react';

import useRedirect from '../../../../../hooks/useRedirect';
import { UiRoutes } from '../../../../../lib/constants';

import EventWrapper from '../../../../../components/EventWrapper';

import AssignItem from './AssignItem';
import SuspenseDetailTable from './SuspenseDetailTable';

const SuspenseDetailList = ({
  labels, businessEntityId, setBusinessEntityId, setWorklistSearch, searchText,
}) => {
  const { params, generatePath, replace } = useRedirect();
  const { parentTab, suspenseId, reasonName } = params;
  const [selectedRow, setSelectedRow] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  const onBack = useCallback(() => {
    replace(generatePath(UiRoutes.remittanceClaimsWorkList, params));
    setBusinessEntityId();
    setWorklistSearch();
  }, [generatePath, params, replace, setBusinessEntityId, setWorklistSearch]);

  const toggleAssignToModal = useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible]);

  return (
    <div>
      <EventWrapper className="back-arrow suspense-back-arrow" onClick={onBack}>
        {`${labels.get('labels.reason')} ${decodeURIComponent(reasonName)}`}
      </EventWrapper>
      <div className="heading-area flex justify-content-sp-bt align-center patient-screen mr-top-10">
        <div className="group-btns" />
      </div>
      <div className="patient-filter custom-table-container">
        <SuspenseDetailTable
          setSelectedRow={setSelectedRow}
          selectedRow={selectedRow}
          businessEntityId={businessEntityId}
          searchText={searchText}
          labels={labels}
          Queue={parentTab}
          RemittanceReasonId={suspenseId}
          toggleAssignToModal={toggleAssignToModal}
        />
      </div>
      <AssignItem
        labels={labels}
        isVisible={isVisible}
        selectedRow={selectedRow}
        toggleAssignToModal={toggleAssignToModal}
      />
    </div>
  );
};

export default SuspenseDetailList;
