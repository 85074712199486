import React, { useCallback, useState } from 'react';
import { Form as AntdForm } from 'antd';

import SuccessMessages from '../../../../lib/successMessages';
import { enums, formId } from '../../../../lib/constants';
import { apiUrls } from '../../../../api/constants';

import Modals from '../../../../components/Modal';
import Form from '../../../../components/Form';
import { Input, CheckBox } from '../../../../components/Form/inputFields';
import Button from '../../../../components/Button';
import Notification from '../../../../components/Notification';

import EnumSelect from '../../../../wiredComponents/Enum/Select';

import '../composer.scss';

const EditTemplateModal = ({
  toggleModal, isVisible, labels, editTemplateId, reFetchTable,
}) => {
  const [selectedCategory, setSelectedCategory] = useState();
  const [form] = AntdForm.useForm();

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.TEMPLATE_UPDATED_SUCCESSFULLY, success: true });
      reFetchTable();
      toggleModal();
    }
  }, [reFetchTable, toggleModal]);

  const handleCategorySelect = useCallback((value, data) => {
    if (data?.children) {
      setSelectedCategory(data?.children);
    }
  }, []);

  return (
    <Modals
      visible={isVisible}
      toggleModal={toggleModal}
      destroyOnClose
      footer={[
        <div className="btns-action mr-left-60 mr-bt-12" key="footer">
          <Button className="btn btn-success min-wt-86" onClick={form.submit}>{labels.get('buttons.save')}</Button>
          <Button className="btn" onClick={toggleModal}>{labels.get('buttons.cancel')}</Button>
        </div>,
      ]}
      title={labels.get('titles.editTemplate')}
      className="quick-edit-modal composer-design-header"
      width={460}
    >
      <Form
        form={form}
        url={`${apiUrls.SAVE_TEMPLATE}/quick/${editTemplateId?.composerTemplateId}`}
        formId={formId.UPDATE_COMPOSER_TEMPLATE}
        editKey={editTemplateId}
        onRequestComplete={onRequestComplete}
        initialData={editTemplateId}
        extraData={{
          category: selectedCategory,
          templateType: editTemplateId?.templateType,
          method: 'put',
        }}
      >
        <Form.Column>
          <Input
            name="templateName"
            labelSpan="9"
            inputSpan="15"
            label={labels.get('labels.name')}
            required
          />
          <EnumSelect
            name="categoryId"
            enumName={enums.COMPOSER_TEMPLATE_CATEGORY}
            labelSpan="9"
            inputSpan="15"
            label={labels.get('labels.category')}
            selectProps={{
              onSelect: handleCategorySelect,
            }}
            required
          />
          <CheckBox
            name="isRequireProviderSignOff"
            label={labels.get('labels.requiresProviderSignOff')}
            valuePropName="checked"
            labelSpan="9"
            inputSpan="15"
          />
        </Form.Column>
      </Form>
    </Modals>
  );
};

export default EditTemplateModal;
