import React, { useState, useMemo } from 'react';
import {
  Col, Row, Button, Form as AntdForm,
} from 'antd';
import { useLocation } from 'react-router-dom';

import Input from '../../components/Form/Input';
import SelectBox from '../../components/Form/SelectBox';
import DatePicker from '../../components/Form/DatePicker';
import CheckBox from '../../components/Form/CheckBox';
import Radio from '../../components/Form/Radio';
import Form from '../../components/Form';
import Select from '../../wiredComponents/Enum/Select';
import { UiRoutes } from '../../lib/constants';
import AutoComplete from '../../components/Form/AutoComplete';
import WiredSelect from '../../wiredComponents/Select';

import OSODSelect from '../../components/OSODSelect';

import './theme.scss';

const NewFormImplementation = () => {
  const [form] = AntdForm.useForm();
  const noOfColumns = 2;
  const options = [{ name: 'Ten', value: 10 }, { name: 'Twenty', value: 20 }, { name: 'Thirty', value: 30 }];
  const genderOptions = useMemo(() => [{ label: 'Male', value: 'Male' }, { label: 'Female', value: 'Female' }, { label: 'Unknown', value: 'Unknown' }], []);
  return (
    <Form
      noOfColumns={noOfColumns}
      onFinish={() => { /* This is intentional */ }}
      form={form}
    >
      <Form.Column>
        <Input label="First Name" name="firstName" required />
        <Input label="Middle Name" name="middleName" />
        <Input label="Last Name" name="lastName" required />
        <Input label="Primary Phone" name="primaryPhone" required />
        <SelectBox label="Phone Type" name="primaryPhoneType" options={options} required />
        <CheckBox label="Active" />
        <Radio required options={genderOptions} label="Gender" name="gender" />
      </Form.Column>
      <Form.Column>
        <DatePicker label="Date of Birth" name="dateOfBirth" required />
        <Input label="Address Line 1" name="address1" required />
        <Input label="Address Line 2" name="address2" />
        <Form.Item label="Zip City and State">
          <Form.Row columnTemplate="1fr 1.5fr 1.5fr">
            <AutoComplete />
            <Input name="zip" type="number" required />
            <Input name="city" required />
            <SelectBox placeholder="State" name="state" options={options} required />
          </Form.Row>
        </Form.Item>
        <SelectBox label="Country" placeholder="" name="country" options={options} required />
        <Select enumName="Country" label="Country" />
      </Form.Column>
    </Form>
  );
};

const Theme = () => {
  const location = useLocation();
  const isPatient = location.pathname !== UiRoutes.editPatient;
  const [basicInfo, setBasicInfo] = useState({
    firstName: isPatient ? '' : 'Georgie',
    lastName: isPatient ? '' : 'Dougles',
    prefferdName: isPatient ? '' : 'Georgie',
    chartNumber: isPatient ? '' : '47256',
  });
  const options = [{ name: 'Ten', value: 10 }, { name: 'Twenty', value: 20 }, { name: 'Thirty', value: 30 }];
  const selectedOptionData = () => { /* This is intentional */ };
  const handeler = (field) => (event) => {
    setBasicInfo({
      ...basicInfo,
      [field]: event.target.value,
    });
  };

  return (
    <div className="theme-screen">
      <Row gutter={24}>
        <Col span={12}>
          <Input label="First Name" id="1" value={basicInfo.firstName} onChange={handeler('firstName')} required />
        </Col>
        <Col span={12}>
          <DatePicker label="Date of Birth" required id="19" />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <OSODSelect label="OS OD" id="2" />
        </Col>
        <Col span={12}>
          <Radio>Male</Radio>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Input label="Last Name" id="3" value={basicInfo.lastName} onChange={handeler('lastName')} />
        </Col>
        <Col span={12}>
          <WiredSelect url="schedulerservice/examtype" id="examType" nameAccessor="examName" valueAccessor="examTypeId" />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <AutoComplete label="Zip City and State" id="6" required />
        </Col>
        <Col span={3}>
          <Input id="6" required />
        </Col>
        <Col span={3}>
          <Input id="6" required />
        </Col>
        <Col span={12}>
          <SelectBox label="Phone Type" options={options} selectedOption={selectedOptionData} required id="12" />
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={2}>
          <Button type="primary">Save</Button>
        </Col>
        <Col span={1}>
          <Button>Cancel</Button>
        </Col>
      </Row>
      <br />
      {NewFormImplementation()}
    </div>
  );
};

export default Theme;
