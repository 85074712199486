import React from 'react';
import * as Icons from '@ant-design/icons';
import PropTypes from 'prop-types';

export default function Icon({ name, color, ...otherProps }) {
  const IconsComponent = Icons[name];
  return <IconsComponent style={{ color }} {...otherProps} />;
}

Icon.defaultProps = {
  name: '',
};

Icon.propTypes = {
  name: PropTypes.string,
};
