import React, { useCallback } from 'react';

import { UiRoutes, labelPaths } from '../../lib/constants';
import rights from '../../lib/rights';
import useRedirect from '../../hooks/useRedirect';
import useRights from '../../hooks/useRights';

import WithClearCurrentTab from '../../hoc/withClearCurrentTab';
import WithRights from '../../hoc/withRights';
import SavedNavigationBar from '../../components/SavedNavigationBar';
import './newLocation.scss';

import Button from '../../components/Button';
import LocationTable from './Table';
import WithLabel from '../../hoc/withLabel';

const Locations = (props) => {
  const { labels } = props;
  const { push } = useRedirect();
  const [isLocationAuthenticated] = useRights([rights.location]);

  const navigateToForm = useCallback(() => push({ pathname: UiRoutes.newLocations }), [push]);
  return (
    <SavedNavigationBar
      parentPath={UiRoutes.locations}
      myPath={UiRoutes.editLocationWithTabId}
    >
      <div className="new-contract-wrap">
        <div className="heading-area flex justify-content-sp-bt align-center screen-title">
          <h2>{labels.get('header.location')}</h2>
          {isLocationAuthenticated && (
            <Button className="btn btn-success sm-btn" id="locations_add" type="primary" onClick={navigateToForm}>{labels.get('buttons.addLocation')}</Button>
          )}
        </div>
      </div>
      <div>
        <LocationTable labels={labels} />
      </div>
    </SavedNavigationBar>
  );
};

export default WithClearCurrentTab(WithRights(WithLabel(Locations,
  labelPaths.LOCATION_LIST), [rights.location, rights.location_view_only]));
