import React, { useCallback } from 'react';

import { UiRoutes, labelPaths, enums } from '../../../../../../lib/constants';

import withLabel from '../../../../../../hoc/withLabel';

import useRedirect from '../../../../../../hooks/useRedirect';

import RadioGroup from '../../../../../../components/Form/RadioGroup';

import General from './Tabs/General';
import Exception from './Tabs/TermsException';
import Modifiers from './Tabs/TermsModifier';
import useMasterId from '../../../../../../hooks/useMasterId';

const enumNames = [
  enums.SCHEDULAR_PROVIDER,
  enums.CONTRACT_TERM_EXCEPTION_TYPE,
  enums.CONTRACT_TERM_EXCEPTION_TYPE_VALUE_TYPE,
];

const Components = {
  general: General,
  exception: Exception,
  modifiers: Modifiers,
};

const tabList = (labels) => [
  { value: 'general', label: labels.get('tabs.general') },
  { value: 'exception', label: labels.get('tabs.exceptions') },
  { value: 'modifiers', label: labels.get('tabs.modifiers') },
];

const EditTerms = ({ labels, isUpdateContractAuthenticated }) => {
  const {
    params, generatePath, replace, push,
  } = useRedirect();

  const {
    id, tabId, tab, termsTab = 'general', termId,
  } = params;

  useMasterId([], enumNames);
  const navigateToTermsList = useCallback(() => (
    push(generatePath(UiRoutes.editContractWithTabId, params))
  ), [generatePath, push, params]);

  const tabSwitchHandler = useCallback((event) => {
    const { value } = event.target;
    replace(generatePath(UiRoutes.editContractTermsWithTabId, {
      id, tabId, tab, termId, termsTab: value,
    }));
  }, [replace, generatePath, id, tabId, tab, termId]);

  const ActiveComponent = Components[termsTab];

  return (
    <div className="pannel-content-area contract-container">
      {/* <div className="main-form-container contract-container"> */}
      <div className="flex justify-content-sp-bt align-center mr-bt-16">
        <div className="heading-area flex justify-content-sp-bt align-center">
          {/* <h1>Terms</h1> */}
        </div>
        <div>
          <RadioGroup
            tabList={tabList(labels)}
            onChange={tabSwitchHandler}
            value={termsTab}
            labels={labels}
            id="contract_terms_tabs"
          />
        </div>
      </div>
      <ActiveComponent
        navigateToTermsList={navigateToTermsList}
        isUpdateContractAuthenticated={isUpdateContractAuthenticated}
      />
    </div>
  );
};

export default withLabel(EditTerms, labelPaths.EDIT_CONTRACT_TERMS);
