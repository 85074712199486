import { initialColumns } from './commonColumns';

const patientPaymentColumn = (labels) => [
  ...initialColumns(labels),
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ row: { original: { amount } } }) => (
      (amount || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
  {
    Header: 'Balance',
    accessor: 'balance',
    Cell: ({ row: { original: { balance } } }) => (
      (balance || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
  {
    Header: 'Applied',
    accessor: 'applied',
    Cell: ({ row: { original: { applied, adjustment } } }) => (
      (applied || adjustment || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
];

export default patientPaymentColumn;
