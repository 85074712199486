import React, { useCallback, useState } from 'react';

import { Route } from 'react-router-dom';
import { UiRoutes, labelPaths } from '../../lib/constants';

import WithClearCurrentTab from '../../hoc/withClearCurrentTab';
import WithLabel from '../../hoc/withLabel';

import useRedirect from '../../hooks/useRedirect';

import Button from '../../components/Button';
import SavedNavigationBar from '../../components/SavedNavigationBar';

import BillingTable from './Table';
import PatientListModal from './PatientListModal';
import useTabLink from '../../hooks/useTabLink';
import Link from '../../components/SavedNavigationBar/Link';
import useRights from '../../hooks/useRights';
import rights from '../../lib/rights';
import WithRights from '../../hoc/withRights';

const Billing = (props) => {
  const { params } = useRedirect();
  const { labels } = props;

  const [isPatientListVisible, setPatientListVisibility] = useState(false);
  const [search, setSearch] = useState();
  const [isEnterBillingChargesPermitted] = useRights([rights.enter_charges]);

  const { navigate: navigateToAddEncounter } = useTabLink({
    to: UiRoutes.addBilling,
  });

  const togglePatientList = useCallback(() => {
    setSearch('');
    setPatientListVisibility(!isPatientListVisible);
  }, [isPatientListVisible]);

  const navigateToForm = useCallback(({
    patientId,
  }) => navigateToAddEncounter({
    data: {
      name: 'Add Encounter',
    },
    ...params,
    id: patientId,
  }),
  [navigateToAddEncounter, params]);

  return (
    <>
      <SavedNavigationBar
        parentPath={UiRoutes.billing}
        myPath={UiRoutes.editBillingEncounterWithTabId}
        listName="Billing List"
      >
        <Route exact path={UiRoutes.billing}>
          <div>
            <div className="heading-area flex justify-content-sp-bt align-center billing-header">
              <h2>{labels.get('titles.title')}</h2>
              <div className="group-btns feild-row">
                <Link to={UiRoutes.tasksWithTabId} params={{ tab: 'all', data: { name: 'task' } }}>
                  <div className="group-btns">
                    <Button id="exam_type_save" className="btn btn-success min-wt-86 sm-btn">{labels.get('buttons.tasks')}</Button>
                  </div>
                </Link>
                {
                  isEnterBillingChargesPermitted ? (
                    <Button className="btn btn-success sm-btn" id="patients_add" onClick={togglePatientList}>
                      {labels.get('buttons.newEncounter')}
                    </Button>
                  ) : <span />
              }
              </div>
            </div>
            <BillingTable labels={labels} />
          </div>
        </Route>
      </SavedNavigationBar>
      <PatientListModal
        labels={labels}
        isPatientListVisible={isPatientListVisible}
        togglePatientList={togglePatientList}
        onRowClick={navigateToForm}
        search={search}
        setSearch={setSearch}
      />
    </>
  );
};

export default WithClearCurrentTab(
  WithRights(WithLabel(Billing,
    labelPaths.BILLING_LIST), rights.access_to_billing_app),
);
