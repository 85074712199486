import React, { useMemo } from 'react';

import { apiUrls } from '../../../../../api/constants';
import { listIds } from '../../../../../lib/constants';
import useRedirect from '../../../../../hooks/useRedirect';
import withQuery from '../../../../../hoc/withQuery/withQuery';

import columns from './columns';

import './History.scss';

const HistoryTable = ({ labels, ...props }) => {
  const { params: { id } } = useRedirect();

  const CustomHistoryTable = useMemo(() => withQuery({
    url: apiUrls.GET_HISTORY_ENCOUNTER_LIST,
    listId: listIds.GET_HISTORY_ENCOUNTER_LIST,
  })(), []);

  return (
    <div className="encounter-history-table">
      <CustomHistoryTable
        columns={columns(labels)}
        filters={{ PatientId: id }}
        scrollId="encounterHistoryTable"
        {...props}
      >
        {({ Component }) => Component}
      </CustomHistoryTable>
    </div>
  );
};

export default HistoryTable;
