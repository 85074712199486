import React, { useState } from 'react';
import Tabs from '../../../../../../../../components/Tabs/TabsWithoutUrlNavigation';

import OrderSets from './Tabs/OrderSets';
import Dx from '../../Procedures/RightTab/Tabs/Dx';
import Procedures from './Tabs/Procedure';
import LabsImaging from './Tabs/LabsImaging';
import EducationalMaterial from './Tabs/EducationalMaterial';
import Rx from './Tabs/Rx';

const RightTab = ({
  dxList,
  setDxList,
  procedureList,
  setProcedureList,
  setProcedureSetsList,
  procedureSetsList,
  labsImagingList,
  setLabsImagingList,
  educationalMaterialList,
  setEducationalMaterialList,
  getDiagnosis,
  isLessTab,
}) => {
  const tabsList = [
    {
      id: 'procedureSets',
      name: 'Order Sets',
      component: OrderSets,
    },
    {
      id: 'dx',
      name: 'Dx',
      component: Dx,
    },
    {
      id: 'rx',
      name: 'Rx',
      component: Rx,
    },
    {
      id: 'procedures',
      name: 'Procedures',
      component: Procedures,
    },
    {
      id: 'labsImaging',
      name: 'Labs / Imaging',
      component: LabsImaging,
    },
    {
      id: 'edu',
      name: 'Edu',
      component: EducationalMaterial,
    },
  ];

  const lessTabsList = [
    {
      id: 'procedureSets',
      name: 'Order Sets',
      component: OrderSets,
    },
    {
      id: 'dx',
      name: 'Dx',
      component: Dx,
    },
    {
      id: 'procedures',
      name: 'Procedures',
      component: Procedures,
    },
    {
      id: 'labsImaging',
      name: 'Labs / Imaging',
      component: LabsImaging,
    },
  ];
  const [selectedTab, setSelectedTab] = useState('procedureSets');
  const updatedTabsList = isLessTab ? lessTabsList : tabsList;
  return (
    <div className="right-tab">
      <Tabs
        id="procedureSets"
        tabsList={updatedTabsList}
        className="main-tab-wrap"
        selectedTab={selectedTab}
        handleChange={setSelectedTab}
        dxList={dxList}
        setDxList={setDxList}
        procedureList={procedureList}
        setProcedureList={setProcedureList}
        setProcedureSetsList={setProcedureSetsList}
        procedureSetsList={procedureSetsList}
        labsImagingList={labsImagingList}
        setLabsImagingList={setLabsImagingList}
        educationalMaterialList={educationalMaterialList}
        setEducationalMaterialList={setEducationalMaterialList}
        getDiagnosis={getDiagnosis}
        isAAndP
      />
    </div>
  );
};

export default RightTab;
