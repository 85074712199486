import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import Button from '../../components/Button';
import { UiRoutes, labelPaths } from '../../lib/constants';
import rights from '../../lib/rights';
import WithClearCurrentTab from '../../hoc/withClearCurrentTab';
import useRedirect from '../../hooks/useRedirect';
import useRights from '../../hooks/useRights';
import WithLabel from '../../hoc/withLabel';
import SavedNavigationBar from '../../components/SavedNavigationBar';

import PatientTable from './Table';
import WithRights from '../../hoc/withRights';

function Patients(props) {
  const { push } = useRedirect();
  const { labels } = props;
  const navigateToForm = useCallback(() => push(UiRoutes.newPatient), [push]);
  const [
    isCreatePatientAuthenticated,
  ] = useRights([rights.create_new_patient]);

  return (
    <SavedNavigationBar
      parentPath={UiRoutes.patients}
      myPath={UiRoutes.editPatientWithTabId}
    >
      <div>
        <div className="heading-area flex justify-content-sp-bt align-center patient-screen">
          <h2>Patients</h2>
          {isCreatePatientAuthenticated && (
            <div className="group-btns">
              <Button className="btn btn-success sm-btn" id="patients_add" onClick={navigateToForm}>
                {labels.get('buttons.addPatient')}
              </Button>
            </div>
          )}
        </div>
        <div className="patient-filter">
          <PatientTable
            labels={labels}
          />
        </div>
      </div>
    </SavedNavigationBar>
  );
}

const mapStateToProps = (state) => ({
  patients: state.patients && state.patients.get('patients'),
});

export default WithClearCurrentTab(connect(mapStateToProps)(WithRights(WithLabel(Patients,
  labelPaths.PATIENT_LIST), rights.access_to_patient_app)));
