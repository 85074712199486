import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import get from 'lodash/get';
import { Tooltip } from 'antd';
import { apiUrls } from '../../../../../../api/constants';
import dateFormatter from '../../../../../../lib/dateFormatter';
import { formatPhoneNumber, getString } from '../../../../../../lib/util';

import useCRUD from '../../../../../../hooks/useCRUD';

import Form from '../../../../../../components/Form';
import Input from '../../../../../../components/Form/Input';
import WidgetLoader from '../../../../../../components/WidgetLoader';

import WiredSelect from '../../../../../../wiredComponents/Select/selectBoxV2';
import InsuranceDetailsSelect from '../../../../../../wiredComponents/InsuranceDetailsSelect';
import ReferringPhysicianAutoComplete from '../../../../../../wiredComponents/ReferringPhysicianAutoComplete';
import { enumMasterCode, UiRoutes } from '../../../../../../lib/constants';
import Link from '../../../../../../components/SavedNavigationBar/Link';

const color = ['#2a4a79'];

const InsuranceInfo = ({
  labels,
  patientId,
  form,
  setAuthorizationId,
  setInsuranceId,
  setReferredById,
  referredById,
  insuranceId,
  appointmentDetail,
  insuranceDetailId,
  showDefaultInsurance,
  patientInfo,
  doctor,
}) => {
  const [insuranceInfo, setInsuranceInfo] = useState(null);
  const [insuranceDetailInfoId, setInsuranceDetailInfoId] = useState(null);
  const [referringPhysician, setReferringPhysician] = useState(null);
  const [isResetFields, setIsResetFields] = useState(false);
  const [insuranceProfile, , , getInsuranceProfile, clearInsuranceProfile] = useCRUD({ id: `${apiUrls.GET_SELF_PAY_INSURANCE_PROFILE_BY_ID}-${patientId}`, url: apiUrls.GET_SELF_PAY_INSURANCE_PROFILE_BY_ID, type: 'read' });

  const onInsuranceSelect = useCallback((id, item) => {
    setInsuranceId(item?.pId);
    setInsuranceDetailInfoId(id);
    const fieldObject = { insuranceId: item?.pId };
    if (!item?.pId) {
      fieldObject.authorizationId = undefined;
    }
    form.setFieldsValue(fieldObject);
  }, [form, setInsuranceId]);

  const onInsuranceChange = useCallback((id) => {
    // using to clear the insurance
    if (!id) {
      setInsuranceId();
      setInsuranceDetailInfoId();
      setInsuranceInfo();
      form.setFieldsValue({ insuranceId: undefined, authorizationId: undefined });
    }
  }, [form, setInsuranceId]);

  const referringPhysicianData = useMemo(() => patientInfo?.patientCareTeams?.find(
    (item) => item?.providerTypeName === enumMasterCode.ReferringPhysicianType && item.isActive
    && item.latest),
  [patientInfo]);

  useEffect(() => {
    const receivedInsuranceId = appointmentDetail?.insuranceId || insuranceId;
    const receivedInsuranceDetailId = appointmentDetail?.insurancedetailId
    || insuranceDetailId || insuranceDetailInfoId;
    const isSelf = !!(receivedInsuranceId && !receivedInsuranceDetailId);
    form.setFieldsValue({
      insuranceId: isSelf ? 0 : receivedInsuranceId,
      authorizationId: get(appointmentDetail, 'authorizationId'),
      referringPhysicianNPI: referringPhysicianData?.npi,
      insurancedetailId: isSelf ? receivedInsuranceId : receivedInsuranceDetailId,
    });
    setInsuranceId(receivedInsuranceId);
    setInsuranceDetailInfoId(receivedInsuranceDetailId);
  }, [appointmentDetail, isResetFields, referringPhysicianData]);

  useEffect(() => {
    if (referringPhysicianData) {
      setReferredById(referringPhysicianData?.npi);
      setReferringPhysician(
        {
          name: referringPhysicianData.providerName,
          value: referringPhysicianData.providerName,
          ...referringPhysicianData,
          address: referringPhysicianData.addressLine1 || '',
        },
      );
    }
  }, [referringPhysicianData]);

  useEffect(() => {
    if (insuranceId) {
      getInsuranceProfile({}, `/${insuranceId}`);
    }
    return () => clearInsuranceProfile(true);
  }, [insuranceId]);

  useEffect(() => {
    if (insuranceProfile
        && insuranceProfile.insuraceProfileDetail) {
      if (insuranceProfile.insuraceProfileDetail.length) {
        setInsuranceInfo(insuranceProfile.insuraceProfileDetail
          .filter((item) => item.insuranceProfileDetailId === insuranceDetailInfoId)[0]);
      } else setInsuranceInfo(null);
    }
  }, [insuranceProfile, insuranceDetailInfoId]);

  const handleReferredById = useCallback(({ npi }) => setReferredById(npi), [setReferredById]);

  const effectiveDateRange = useMemo(() => (
    insuranceInfo?.effectiveFrom || insuranceInfo?.effectiveTo
      ? `${getString([
        dateFormatter(insuranceInfo?.effectiveFrom),
        dateFormatter(insuranceInfo?.effectiveTo),
      ], ' - ')}` : ''
  ), [insuranceInfo]);

  return (
    <Form
      form={form}
      loaderComponent={WidgetLoader}
    >
      <div className="appointment-insurance-wrap">
        <div className="card-header-title">
          <p>{labels.get('titles.insuranceInfo')}</p>
          <span
            role="presentation"
            className="green-text cursor-pointer"
          >
            <Link
              to={UiRoutes.viewInsuranceProfileWithTabId}
              params={{
                tab: 'insurance',
                id: patientInfo.patientId,
                rpId: insuranceProfile?.insuranceProfileId || insuranceDetailInfoId,
                profileId: insuranceProfile?.insuranceProfileId || insuranceDetailInfoId,
                data: { name: `Insurance: ${patientInfo.fullName}` },
              }}
            >
              {labels.get('labels.viewInsurance')}

            </Link>
          </span>
        </div>
        <div className="insurance-info-wrap">
          <div className="left-area">
            <div className="feilds-wrap 111">
              <div className="feilds select-feilds-wrap select-fields-wrap-100 ">
                <div className="display-none">
                  <Input name="insuranceId" />
                </div>
                <InsuranceDetailsSelect
                  id={`${apiUrls.INSURANCE_PROFILE_DROPDOWN}-${patientId}`}
                  name="insurancedetailId"
                  placeholder={labels.get('placeholders.insuranceDetail')}
                  patientId={patientId}
                  extraClass="insurence-info-error"
                 // selectNodes={[insuranceId]}
                  label={labels.get('labels.insuranceInfo')}
                  onSelect={onInsuranceSelect}
                  onChange={onInsuranceChange}
                  initialValue={{
                    insuranceProfileId: insuranceId,
                    insuranceProfileDetailId: appointmentDetail?.insurancedetailId,
                  }}
                  labelSpan={10}
                  inputSpan={12}
                  includeSelfProfile
                  disableOtherThanPrimaryAndSelf
                  showDefaultInsurance={showDefaultInsurance}
                  form={form}
                  doctor={doctor}
                  // required // manual validation done for required
                />
              </div>
              <div className="scan-text green-text mr-rt-8">{labels.get('labels.scanNew')}</div>
            </div>
            <div className="feilds-wrap">
              <div className="feilds">
                <div className="label">{labels.get('labels.primaryPayer')}</div>
                <div className="value" data-testid="payerName">{insuranceInfo && insuranceInfo.payerName}</div>
              </div>
              <div className="feilds posi-relative">
                <div className="label">Member ID</div>
                <Tooltip placement="right" color={color} title={insuranceInfo && insuranceInfo.memberNumber}>
                  <div className="value">{insuranceInfo && insuranceInfo.memberNumber}</div>
                </Tooltip>
              </div>
            </div>
            <div className="feilds-wrap">
              <div className="feilds">
                <div className="label">{labels.get('labels.payerAddress')}</div>
                <div className="value">
                  {insuranceInfo && insuranceInfo.payerAddressLine1}
                </div>
              </div>
              <div className="feilds">
                <div className="label">{labels.get('labels.phone')}</div>
                <div className="value">{formatPhoneNumber(insuranceInfo?.payerPrimaryPhone)}</div>
              </div>
              {/* <div className="feilds">
              <div className="label">{insurance.gName}</div>
              <div className="value" />
            </div> */}
            </div>
            <div className="feilds-wrap">
              <div className="feilds">
                <div className="label">{labels.get('labels.fax')}</div>
                <div className="value">{insuranceInfo?.fax}</div>
              </div>
              <div className="feilds">
                <div className="label">{labels.get('labels.groupNumber')}</div>
                <div className="value">{insuranceInfo && insuranceInfo.groupNumber}</div>
              </div>
            </div>
            <div className="feilds-wrap">
              <div className="feilds">
                <div className="label">{labels.get('labels.policyType')}</div>
                <div className="value">{insuranceInfo && insuranceInfo.policyType}</div>
              </div>
              <div className="feilds">
                <div className="label">{labels.get('labels.coPayment')}</div>
                <div className="value">{insuranceInfo && insuranceInfo.coPayment?.toFixed(2)}</div>
              </div>
            </div>
            <div className="feilds-wrap">
              <div className="feilds select-feilds-wrap select-fields-sub-child-100">
                <WiredSelect
                  label={labels.get('labels.authorization')}
                  name="authorizationId"
                  id={`${apiUrls.AUTHORIZATION_LIST_DROPDOWN}-${patientId}`}
                  url={apiUrls.AUTHORIZATION_LIST_DROPDOWN}
                  nameAccessor="authorizationNo"
                  valueAccessor="authorizationId"
                  params={insuranceId
                    && insuranceDetailInfoId ? {
                      insuranceProfileId: insuranceId,
                      insuranceProfileDetailId:
                      insuranceDetailInfoId,
                      PatientId: patientId,
                    } : { PatientId: patientId }}
                  labelSpan={10}
                  inputSpan={12}
                  onChange={setAuthorizationId}
                  disabled={!insuranceId}
                />
              </div>
              <div className="feilds posi-relative">
                <div className="label">{labels.get('labels.deductible')}</div>
                <div className="value">{insuranceInfo && insuranceInfo.deductible?.toFixed(2)}</div>
              </div>
            </div>
            <div className="feilds-wrap">
              <div className="feilds">
                <div className="label">{labels.get('labels.requiresAuth')}</div>
                <div className="value">{insuranceInfo && insuranceInfo.requiresAuthorization}</div>
              </div>
              <div className="feilds">
                <div className="label">{labels.get('labels.effectiveFromTo')}</div>
                <div className="value pd-rt-zero">{effectiveDateRange}</div>
              </div>
            </div>
            <div className="feilds-wrap">
              <div className="feilds">
                <ReferringPhysicianAutoComplete
                  label="Referred By"
                  id="11"
                  name="referringPhysicianNPI"
                  labelSpan={10}
                  inputSpan={12}
                  form={form}
                  onSelect={handleReferredById}
                  initialValue={referringPhysician}
                  isResetFields={isResetFields}
                  setIsResetFields={setIsResetFields}
                  placeholder="Search"
                />
              </div>
              <div className="feilds">
                <div className="label">{labels.get('labels.npi')}</div>
                <div className="value">{appointmentDetail.referredBy || referredById}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default InsuranceInfo;
