import React, { useCallback } from 'react';
import { Route, Switch } from 'react-router-dom';

import { labelPaths, UiRoutes } from '../../../../lib/constants';
import rights from '../../../../lib/rights';

import WithLabel from '../../../../hoc/withLabel';

import useRedirect from '../../../../hooks/useRedirect';
import useRights from '../../../../hooks/useRights';

import ClaimsTable from './Table';
import CollectionClaims from './Claims';

const Collections = (props) => {
  const {
    replace, generatePath, params, path,
  } = useRedirect();

  const { labels } = props;
  const [isAccessToCollectionsAuthenticated] = useRights([rights.access_to_collections]);

  const routeToTable = useCallback(() => {
    replace(generatePath(UiRoutes.collectionClaimsFilters, { ...params, claimFilter: 'new' }));
  }, [generatePath, params, replace]);

  return (
    <Switch>
      <Route exact path={path}>
        {routeToTable}
      </Route>
      {
          isAccessToCollectionsAuthenticated
      && (
      <Route exact path={UiRoutes.collectionClaimsFilters}>
        <ClaimsTable labels={labels} isSetSearchData />
      </Route>
      )
        }
      <Route
        exact
        path={[
          UiRoutes.collectionClaims,
          UiRoutes.collectionClaimsAdditionalFilters,
        ]}
      >
        <CollectionClaims />
      </Route>
    </Switch>
  );
};

export default WithLabel(Collections,
  labelPaths.COLLECTIONS_LIST);
