import React, { useCallback } from 'react';
import SMCheckBox from '../../../../components/SmCheckbox';

const CoverPageWrap = ({ activeCoverPage, setActiveCoverPage, coverPages }) => {
  const handleCoverPage = useCallback((page) => {
    setActiveCoverPage(page);
  }, [setActiveCoverPage]);
  return (
    <div className="cover-page-wrap">
      {coverPages?.map((page, index) => (
        <div key={index} className={`cover-card ${activeCoverPage?.uuid === page?.uuid ? 'active' : ''}`}>
          <SMCheckBox
            className="labelRight"
            onChange={() => handleCoverPage(page)}
            checked={activeCoverPage?.uuid === page?.uuid}
          />
          <div className="cover-card-content">
            <img src={page?.previewLink} alt="logo" />
          </div>
          <div className="cover-card-footer">
            {page?.name}
          </div>
        </div>
      )
      )}
    </div>
  );
};

export default CoverPageWrap;
