import React from 'react';
import CheckBox from '../../../../../../components/SmCheckbox';

const columns = (mapWithOtherPayerData, handleSelectedRow, labels) => [
  {
    Header: '',
    accessor: 'check',
    fixWidth: '50',
    Cell: ({ row, row: { original } }) => {
      const rowProps = row.getToggleRowSelectedProps();
      rowProps.onClick = (e) => e.stopPropagation();
      return (
        <span {...rowProps}>
          <CheckBox
            checked={mapWithOtherPayerData === original?.payerMappingConfiguration?.payerId}
            onChange={() => handleSelectedRow(original?.payerMappingConfiguration?.payerId)}
          />
        </span>
      );
    },
  },
  {
    Header: labels.get('labels.focusPayerName'),
    accessor: 'name',
  },
  {
    Header: labels.get('labels.payerName'),
    accessor: 'payerMappingConfiguration.payerName',
  },
  {
    Header: labels.get('labels.policyType'),
    accessor: 'payerMappingConfiguration.policyType',
  },
  {
    Header: labels.get('labels.planName'),
    accessor: 'payerMappingConfiguration.planName',
  },
  {
    Header: labels.get('labels.groupNumber'),
    accessor: 'payerMappingConfiguration.groupNumber',
  },
  {
    Header: labels.get('labels.groupName'),
    accessor: 'payerMappingConfiguration.groupName',
  },
  {
    Header: labels.get('labels.coPayment'),
    accessor: 'payerMappingConfiguration.coPayment',
  },
  {
    Header: labels.get('labels.deductible'),
    accessor: 'payerMappingConfiguration.deductible',
  },
  {
    Header: labels.get('labels.outDeductible'),
    accessor: 'payerMappingConfiguration.outDeductible',
  },
  {
    Header: labels.get('labels.coInsurance'),
    accessor: 'payerMappingConfiguration.coInsurance',
  },
  {
    Header: labels.get('labels.effectiveFrom'),
    accessor: 'payerMappingConfiguration.effectiveFrom',
  },
  {
    Header: labels.get('labels.effectiveTo'),
    accessor: 'payerMappingConfiguration.effectiveTo',
  },
];

export default columns;
