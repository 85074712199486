import { useEffect } from 'react';
import Events from '../../../lib/events';

const TableWrapper = ({
  Component, reFetch,
}) => {
  useEffect(() => {
    Events.on('reFetchICDTable', 'reFetchICDTable', reFetch);
    return () => Events.remove('reFetchICDTable');
  }, []);
  return Component;
};

export default TableWrapper;
