import React from 'react';
import { Row, Col } from 'antd';
import { isEmpty } from 'lodash';
import UpdateComment from '../UpdateComment';
import CommentCard from '../../../../../components/CommentCard';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Form/Input';

const CommentSection = ({
  taskId, onAddComment, labels, comments,
}) => (
  <Col span={24}>
    {!taskId ? (
      <div className="group-shadow comment-tab">
        <Row gutter={16}>
          <Col span={12}>
            <Input.TextArea
              name="comment 2"
              autoSize={{ minRows: 4, maxRows: 10 }}
              labelSpan="0"
              inputSpan="24"
              placeholder="Enter Comments"
              maxValueLength={500}
            />
            <Button
              type="button"
              className="btn sm-btn btn-success"
              data-testid="onAddComment"
              onClick={onAddComment}
            >
              {labels.get('buttons.addComment')}
            </Button>
          </Col>
          <Col span={12}>
            <div className="comment-card-container-wrap">
              <div className="comment-card-container mr-top-12">
                {!isEmpty(comments) && comments.map((comment, index) => (
                  <CommentCard comment={comment} key={index + 1} name="me" />
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    ) : (
      <UpdateComment labels={labels} taskId={taskId} />
    )}
  </Col>
);

export default CommentSection;
