import React, { useEffect, useState } from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

import useRedirect from '../../../../../../hooks/useRedirect';
import useCRUD from '../../../../../../hooks/useCRUD';
import { apiUrls } from '../../../../../../api/constants';
import WidgetLoader from '../../../../../../components/WidgetLoader';
import { formatPhoneNumber, getString } from '../../../../../../lib/util';

const Insurance = ({
  labels,
}) => {
  const [insurance, setInsurance] = useState({});
  const { params: { id: PatientId } } = useRedirect();
  const [insuranceResponse, , loading, getInsuranceData, clear] = useCRUD({ id: `insurance-summary-${PatientId}`, url: apiUrls.GET_PATIENT_INSURANCE, type: 'read' });
  const [insuranceData, , insuranceLoading, getInsurance, clearInsurance] = useCRUD({ id: `insurance-details-summary-${PatientId}`, url: apiUrls.GET_PATIENT_INSURANCE, type: 'read' });

  useEffect(() => {
    if (PatientId) {
      getInsuranceData({ PatientId });
    }
  }, [PatientId, getInsuranceData]);

  useEffect(() => {
    setInsurance({});
  }, [PatientId]);

  useEffect(() => {
    if (insuranceResponse && Array.isArray(insuranceResponse) && insuranceResponse.length) {
      const insuranceId = insuranceResponse.find(({ isDefault }) => isDefault)?.insuranceProfileId;
      if (insuranceId) getInsurance({}, `/${insuranceId}`);
      clear(true);
    }
  }, [insuranceResponse, getInsurance, clear]);

  useEffect(() => {
    if (
      insuranceData
      && Object.keys(insuranceData).length
    ) {
      const { insuraceProfileDetail, relationName, responsibleParty } = insuranceData;
      const insuranceDetails = {};
      insuranceDetails.responsibleParty = {
        ...insuranceData,
        name: responsibleParty?.firstName,
        relation: relationName,
        phone: formatPhoneNumber(responsibleParty?.primaryPhone),
        phoneType: responsibleParty?.primaryPhoneTypeName,
      };
      insuraceProfileDetail.forEach((currentProfile) => {
        const { memberNumber, payerName, profileType } = currentProfile;
        insuranceDetails[profileType.toLowerCase()] = {
          ...currentProfile,
          payerName,
          memberNumber,
        };
      });
      setInsurance(insuranceDetails);
    }
  }, [insuranceData]);

  useEffect(() => () => { clearInsurance(true); }, []);

  return (
    <div className="posi-relative">
      {(insuranceLoading || loading) && <WidgetLoader />}
      <div className="insurance-summary insurance-summary-box shadow-wrap mr-rt-16">
        <p className="title sprite-img-before">
          <span className="summary-icon" />
          {labels.get('titles.insuranceSummary')}
        </p>

        <div className="descriptions">
          {!isEmpty(insurance) && insurance?.primary && (
          <>
            <div className="description-feilds">
              <p className="key">{labels.get('labels.insuranceProfile')}</p>
            </div>
            <div className="description-feilds">
              <div className="insurance-summary-sub-sec">
                {insurance?.primary && (
                <div className="primary-sec">
                  <p className="sub-title">{labels.get('labels.primary')}</p>
                  <p className="value">{insurance?.primary?.payerName}</p>
                  <p className="value">
                    {labels.get('labels.memberNo')}
                    :
                    {` ${insurance?.primary?.memberNumber || ''}`}
                  </p>
                  <p className="value">
                    Group Name
                    :
                    {` ${insurance?.primary?.groupName || ''}`}
                  </p>
                  <p className="value">
                    Co Pay
                    :
                    {` ${insurance?.primary?.coPayment || ''}`}
                  </p>
                  <p className="value">
                    Address
                    :
                    {getString([
                    insurance?.primary?.payerAddressLine1,
                     insurance?.primary?.payerAddressLine2,
                      insurance?.primary?.payerCity,
                      insurance?.primary?.payerCountryName])}
                  </p>
                </div>
              )}
                {insurance?.secondary && (
                <div className="primary-sec">
                  <p className="sub-title">{labels.get('labels.secondary')}</p>
                  <p className="value">{insurance?.secondary?.payerName}</p>
                  <p className="value">
                    {labels.get('labels.memberNo')}
                    :
                    {` ${insurance?.secondary?.memberNumber || ''}`}
                  </p>
                  <p className="value">
                    Group Name
                    :
                    {` ${insurance?.secondary?.groupName || ''}`}
                  </p>
                  <p className="value">
                    Co Pay
                    :
                    {` ${insurance?.secondary?.coPayment || ''}`}
                  </p>
                  <p className="value">
                    Address
                    :
                    {getString([
                    insurance?.secondary?.payerAddressLine1,
                     insurance?.secondary?.payerAddressLine2,
                      insurance?.secondary?.payerCity,
                      insurance?.secondary?.payerCountryName])}
                  </p>
                </div>
              )}
                {insurance?.tertiary && (
                <div className="primary-sec">
                  <p className="sub-title">{labels.get('labels.tertiary')}</p>
                  <p className="value">{insurance?.tertiary?.payerName || ''}</p>
                  <p className="value">
                    {labels.get('labels.memberNo')}
                    :
                    {` ${insurance?.tertiary?.memberNumber || ''}`}
                  </p>
                  <p className="value">
                    Group Name
                    :
                    {` ${insurance?.tertiary?.groupName || ''}`}
                  </p>
                  <p className="value">
                    Co Pay
                    :
                    {` ${insurance?.tertiary?.coPayment || ''}`}
                  </p>
                  <p className="value">
                    Address
                    :
                    {getString([
                    insurance?.tertiary?.payerAddressLine1,
                     insurance?.tertiary?.payerAddressLine2,
                      insurance?.tertiary?.payerCity,
                      insurance?.tertiary?.payerCountryName])}
                  </p>
                </div>
              )}
              </div>
            </div>
          </>
          )}
          {insurance?.responsibleParty && insurance?.responsibleParty?.relation === 'Self' && (
            <div className="description-feilds">
              <p className="key">{labels.get('labels.responsibleParty')}</p>
              <p className="value">{`${insurance?.responsibleParty?.name || ''} (${insurance?.responsibleParty?.relation || ''})`}</p>
              {insurance?.responsibleParty?.phone && <p className="value">{`${formatPhoneNumber(insurance?.responsibleParty?.phone)} (${insurance?.responsibleParty?.phoneType || ''})`}</p>}
            </div>
            )}
          {insurance?.responsibleParty && insurance?.responsibleParty?.relation !== 'Self' && (
            <div className="description-feilds">
              <p className="key">{labels.get('labels.responsibleParty')}</p>
              <p className="value">{`${insurance?.responsibleParty?.name || ''}`}</p>
              <p className="value">{`DOB: ${moment(insurance?.responsibleParty?.responsibleParty?.dateOfBirth).format('MM-DD-YYYY')}`}</p>
              <p className="value">{`Phone: ${formatPhoneNumber(insurance?.responsibleParty?.phone)}`}</p>
              <p className="value">{`Relation:  ${insurance?.responsibleParty?.relation || ''}`}</p>
              <p className="value">{`SSN:  ${insurance?.responsibleParty?.responsibleParty?.ssn || ''}`}</p>
            </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default Insurance;
