import React, { useCallback, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import classNames from 'classnames';
import { connect, useDispatch } from 'react-redux';
import get from 'lodash/get';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import clone from 'lodash/clone';
import moment from 'moment';

import Notification from '../../../../../components/Notification';
import CheckBox from '../../../../../components/Checkbox';
import Button from '../../../../../components/Button';
import Loader from '../../../../../components/Loader';

import useRedirect from '../../../../../hooks/useRedirect';
import useCRUD from '../../../../../hooks/useCRUD';

import { labelPaths, enums } from '../../../../../lib/constants';

import { apiUrls } from '../../../../../api/constants';

import WithLabel from '../../../../../hoc/withLabel';

import { getEnumMasterData } from '../../../../../store/actions/enum';
import * as selectors from '../../../../../store/selectors';

import SubMenu, { PolicySubMenuItem } from './profileSubmenu';
import PolicyList from './policyList';
import SuccessMessages from '../../../../../lib/successMessages';
import Events from '../../../../../lib/events';
import PopOver from '../../../../../components/PopOver';
import useReduxState from '../../../../../hooks/useReduxState';

const getIPArray = (insurance, ipArray) => {
  const clonedInsurance = cloneDeep(insurance);
  const activeProfileDetails = [];
  insurance.profileDetails.filter((profileDetail) => {
    const clonedProfileDetail = clone(profileDetail);
    clonedProfileDetail.isValid = false;
    if (clonedProfileDetail?.effectiveTo
         && moment(clonedProfileDetail?.effectiveTo)
           .isBefore(moment())) {
      return activeProfileDetails.push(clonedProfileDetail);
    }
    if (!clonedProfileDetail?.effectiveTo
        && !clonedProfileDetail?.effectiveFrom) {
      return activeProfileDetails.push(clonedProfileDetail);
    }
    if (moment(moment())
      .isBefore(clonedProfileDetail?.effectiveFrom)) {
      return activeProfileDetails.push(clonedProfileDetail);
    }
    clonedProfileDetail.isValid = true;
    return activeProfileDetails.push(clonedProfileDetail);
  });
  activeProfileDetails.sort(
    (a, b) => a?.profileTypeId - b?.profileTypeId,
  );
  clonedInsurance.profileDetails = activeProfileDetails;
  ipArray.push(clonedInsurance);
  return ipArray;
};
const insuranceOptions = ['Active', 'Inactive', 'Both'];

const InsuranceSidebar = (props) => {
  const {
    parentParams,
    enumMaster,
    enumOptions,
    labels,
    baseRoute,
    queryString,
    isUpdatePatientAuthenticated,
  } = props;

  const {
    push, generatePath, location, params,
  } = useRedirect();

  const dispatch = useDispatch();

  const { id } = params;
  const enumId = get(enumMaster, `${enums.INSURANCE_POLICY_LEVEL}.enumId`);
  const masterConfigEnumId = get(enumMaster, `${enums.MASTER_CONFIG}.enumId`);

  const policyLevel = enumOptions && enumOptions[enumId] && enumOptions[enumId].data;
  const masterConfigData = enumOptions && enumOptions[masterConfigEnumId]
  && enumOptions[masterConfigEnumId].data;

  useEffect(() => {
    if (!(policyLevel && policyLevel.length)) {
      dispatch(getEnumMasterData(enumId));
    }
  }, [enumId, dispatch, policyLevel]);

  useEffect(() => {
    if (!(masterConfigData && masterConfigData.length)) {
      dispatch(getEnumMasterData(masterConfigEnumId));
    }
  }, [dispatch, masterConfigData, masterConfigEnumId]);

  const [insuranceData, , insuranceLoading, requestInsuranceData] = useCRUD({ id: 'patient-insurance-rp', url: apiUrls.GET_PATIENT_INSURANCE, type: 'read' });

  const [pverifyFrequencyAttemptsData, , pverifyFrequencyAttemptsLoading, getPverifyFrequencyAttempts] = useCRUD({ id: 'pverify-frequency-attempts', url: apiUrls.GET_PVERIFY_FREQUENCY_ATTEMPTS, type: 'read' });
  const [movePolicyResponse, , movePolicyLoading, movePolicy, clearMovePolicy] = useCRUD({ id: 'move-patient-insurance-policy', url: apiUrls.MOVE_INSURANCE_POLICY, type: 'create' });

  const [
    manualPverifyVerificationResponse, ,
    manualPverifyVerificationLoading,
    manualPverifyVerification,
    clearManualPverifyVerification,
  ] = useCRUD({ id: apiUrls.MANUAL_P_VERIFY_VERIFICATION, url: apiUrls.MANUAL_P_VERIFY_VERIFICATION, type: 'create' });

  const [isRedirectedToDefault, setRedirectedToDefault] = useReduxState(`insurance-sidebar-isRedirectedToDefault-${id}`, false);
  const [rpData, setRpData] = useReduxState(`insurance-sidebar-rpData-${id}`, []);
  const [ipData, setIpData] = useReduxState(`insurance-sidebar-ipData-${id}`, []);
  const [popOverContentVisible, setPopOverContentVisible] = useReduxState(`insurance-sidebar-popOverContentVisible-${id}`, false);
  const [showInActiveInsurance, setShowInactiveInsurance] = useReduxState(`insurance-sidebar-showInActiveInsurance-${id}`);

  const moveItem = useCallback((policyId, profileId, policyTypeId) => {
    if (policyId && profileId && policyTypeId) {
      movePolicy({
        data: {
          patientId: id,
          InsuranceProfileDetailId: policyId,
          InsuranceProfileId: profileId,
          ProfileTypeId: policyTypeId,
        },
      });
    }
  }, [id, movePolicy]);

  useEffect(() => {
    if (movePolicyResponse && requestInsuranceData) {
      Notification({
        message: SuccessMessages.INSURANCE_PROFILE_TYPE_MOVED_SUCCESSFULLY,
        success: true,
      });
      Events.trigger('update-insurance-data');
      requestInsuranceData({ patientId: id, includeinactive: showInActiveInsurance });
      clearMovePolicy();
    }
  }, [movePolicyResponse, requestInsuranceData]);

  const handleHideInsurance = useCallback(({ target: { checked } }) => {
    setShowInactiveInsurance(!checked);
    requestInsuranceData({ patientId: id, includeinactive: !checked });
  }, [id, requestInsuranceData, setShowInactiveInsurance]);

  useEffect(() => {
    requestInsuranceData({ patientId: id, includeinactive: showInActiveInsurance });
  }, [requestInsuranceData]);

  useEffect(() => {
    if (ipData?.length) {
      getPverifyFrequencyAttempts({
        patientId: id,
      });
    }
  }, [ipData]);

  useEffect(() => {
    const ipArray = [];
    const rpArray = [];
    if (!showInActiveInsurance) {
      setIpData();
    }
    if (insuranceData?.length) {
      insuranceData.forEach((insurance) => {
        if (insurance.profileType === 'Self') {
          rpArray.push(insurance);
        } else if (insurance.profileType === 'Other') {
          setIpData(getIPArray(insurance, ipArray));
        }
      });
      setRpData(rpArray);
    }
  }, [insuranceData, showInActiveInsurance]);

  useEffect(() => {
    if (!isRedirectedToDefault) {
      if (ipData && ipData.length) {
        const defaultProfile = ipData.filter((data) => data.isDefault);
        if (defaultProfile && defaultProfile.length) {
          push({
            pathname: generatePath(`${baseRoute}/profile/view/:profileId`,
              { ...parentParams, profileId: defaultProfile[0].insuranceProfileId }),
            search: queryString,
          });
          setRedirectedToDefault(true);
        }
      }
    }
  }, [ipData, setRedirectedToDefault, isRedirectedToDefault,
    generatePath, push, parentParams, baseRoute]);

  const isActive = useCallback((path, activeParams) => {
    const generatedPath = generatePath(path, { ...activeParams, ...parentParams });
    return location.pathname === generatedPath;
  }, [generatePath, location.pathname, parentParams]);

  const navigateToRPViewMode = useCallback((rpId) => {
    push({ pathname: generatePath(`${baseRoute}/rp/view/:rpId`, { ...parentParams, rpId }), search: queryString });
  }, [baseRoute, generatePath, parentParams, push, queryString]);

  const navigateToRPAddMode = useCallback(() => {
    push({ pathname: generatePath(`${baseRoute}/rp/add`, { ...parentParams }), search: queryString });
  }, [baseRoute, generatePath, parentParams, push, queryString]);

  const navigateToRPEditMode = useCallback((rpId) => {
    push({ pathname: generatePath(`${baseRoute}/rp/edit/:rpId`, { ...parentParams, rpId }), search: queryString });
  }, [baseRoute, generatePath, parentParams, push, queryString]);

  const navigateToProfileViewMode = useCallback((profileId) => {
    push({ pathname: generatePath(`${baseRoute}/profile/view/:profileId`, { ...parentParams, profileId }), search: queryString });
  }, [baseRoute, generatePath, parentParams, push, queryString]);

  const navigateToAddNewProfile = useCallback((profileId) => {
    push({ pathname: generatePath(`${baseRoute}/profile/new`, { ...parentParams, profileId }), search: queryString });
  }, [baseRoute, generatePath, parentParams, push, queryString]);

  const navigateToAddNewPolicy = useCallback((profileId, policyLevelId) => {
    push({
      pathname: generatePath(`${baseRoute}/profile/new/:profileId/:policyId`, { ...parentParams, profileId, policyId: policyLevelId }),
    });
  }, [baseRoute, generatePath, parentParams, push]);

  const navigateVerificationHistory = useCallback((profileId) => {
    push({ pathname: generatePath(`${baseRoute}/profile/history/:profileId`, { ...parentParams, profileId }) });
  }, [baseRoute, generatePath, parentParams, push]);

  useEffect(() => {
    if (manualPverifyVerificationResponse) {
      Notification({
        message: manualPverifyVerificationResponse,
        success: true,
      });
      Events.trigger('update-insurance-data');
      Events.trigger('refresh-verification-history-table');
      requestInsuranceData({ patientId: id, includeinactive: showInActiveInsurance });
      clearManualPverifyVerification(true);
    }
  }, [manualPverifyVerificationResponse]);

  const handleManualVerification = useCallback((item) => {
    let status;
    if (item === 'Active') {
      status = true;
    } else if (item === 'Inactive') {
      status = false;
    }
    manualPverifyVerification({ data: { patientId: id, status } });
    setPopOverContentVisible(false);
  }, [id, manualPverifyVerification, setPopOverContentVisible]);

  const handlePopOverContent = useCallback(() => {
    setPopOverContentVisible(true);
  }, [setPopOverContentVisible]);

  const isDisabled = useCallback(() => {
    let isDisabledManualVerification = false;
    const noOfManualVerificationAllow = find(masterConfigData, (o) => o.masterCode === 'PverifyFrequencyAttempts')?.masterDescription || 0;
    if (pverifyFrequencyAttemptsData && noOfManualVerificationAllow) {
      isDisabledManualVerification = !(Number(noOfManualVerificationAllow)
      - Number(pverifyFrequencyAttemptsData?.insuranceVerificationCount || 0) > 0);
    }
    if (!ipData?.length) {
      isDisabledManualVerification = true;
    }
    return isDisabledManualVerification;
  }, [ipData, masterConfigData, pverifyFrequencyAttemptsData]);

  const getIsInsuranceTypeDisabled = useCallback((index) => {
    let isDisabledManualVerification = false;
    if (index === 0 && pverifyFrequencyAttemptsData?.allActivePayersNonEdi) {
      isDisabledManualVerification = true;
    } else if (index === 1 && pverifyFrequencyAttemptsData?.allInActivePayersNonEdi) {
      isDisabledManualVerification = true;
    } else if (index === 2 && pverifyFrequencyAttemptsData?.allActivePayersNonEdi
      && pverifyFrequencyAttemptsData?.allInActivePayersNonEdi) {
      isDisabledManualVerification = true;
    }
    return isDisabledManualVerification;
  }, [pverifyFrequencyAttemptsData]);

  if (insuranceLoading && !insuranceData) {
    return <Loader />;
  }

  return (
    <div className="insurance-side-bar">
      {(movePolicyLoading || manualPverifyVerificationLoading
        || pverifyFrequencyAttemptsLoading) && <Loader />}
      {isUpdatePatientAuthenticated && (
        <>
          <Button
            className="btn btn-success btn-block"
            id="patients_insurance_new"
            onClick={navigateToAddNewProfile}
          >
            {labels.get('buttons.newInsuranceProfile')}
          </Button>
          <Button
            className="btn btn-success btn-block"
            onClick={navigateVerificationHistory}
          >
            {labels.get('buttons.verificationHistory')}
          </Button>
          <Button
            className="btn btn-success btn-block"
            id="patients_insurance_new_self"
            onClick={navigateToRPAddMode}
          >
            {labels.get('buttons.newSelfPayProfile')}
          </Button>
          <PopOver
            visible={popOverContentVisible}
            onVisibleChange={setPopOverContentVisible}
            content={() => (
              <div className="pophover-content manual-status">
                <ul className="address-list education-form-list">
                  {insuranceOptions?.map((item, index) => (
                    <li key={index} role="presentation" className={classNames(getIsInsuranceTypeDisabled(index) ? 'disable-insurance-type' : '')} onClick={() => (handleManualVerification(item))}>
                      <p className="name">
                        {item}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            className="letter-popover"
            overlayStyle={{ zIndex: 1000 }}
            placement="bottomRight"
          >
            <Button
              className="btn btn-success btn-block drop-icon"
              id="patients_insurance_new_self"
              disabled={isDisabled()}
              onClick={handlePopOverContent}
            >
              {labels.get('buttons.manualVerification')}
            </Button>
          </PopOver>
        </>
      )}
      <h2 className="menu-header">{labels.get('buttons.insuranceProfile')}</h2>
      <Scrollbars style={{ width: 'auto', height: '60vh' }}>
        <ul className="ant-menu ant-menu-light ant-menu-inline" role="menu">
          {
            rpData && rpData.map((rp) => (
              <div key={`key-${rp.insuranceProfileId}`}>
                <SubMenu
                  title={rp && rp.profileName}
                  onClick={navigateToRPViewMode}
                  myPath={`${baseRoute}/rp/view/:rpId`}
                  isActive={isActive}
                  profileId={rp.insuranceProfileId}
                >
                  <div className="mr-lt-12">
                    <PolicySubMenuItem
                      title="Responsible Party"
                      onClick={() => navigateToRPEditMode(rp.insuranceProfileId)}
                      myPath={`${baseRoute}/rp/edit/:rpId`}
                      classKey="rp-edit"
                      isActive={isActive}
                      profileId={rp.insuranceProfileId}
                    />
                  </div>
                </SubMenu>
              </div>
            ))
          }
          { ipData?.map((ip) => (
            <div key={`key-${ip.insuranceProfileId}`}>
              <SubMenu
                profileId={ip.insuranceProfileId}
                title={ip.profileName}
                onClick={navigateToProfileViewMode}
                myPath={`${baseRoute}/profile/view/:profileId`}
                isDefault={ip.isDefault}
                isActive={isActive}
              >
                <div className="mr-left-12">
                  <PolicySubMenuItem
                    title="Responsible Party"
                    onClick={() => navigateToRPEditMode(ip.insuranceProfileId)}
                    myPath={`${baseRoute}/rp/edit/:rpId`}
                    classKey="rp-edit"
                    isActive={isActive}
                    profileId={ip.insuranceProfileId}
                  />
                  <PolicyList
                    navigateToAddNewPolicy={navigateToAddNewPolicy}
                    myPath={`${baseRoute}/profile/edit/:profileId/:policyId`}
                    profileId={ip.insuranceProfileId}
                    isActive={isActive}
                    policiesData={ip.profileDetails}
                    policyLevel={policyLevel}
                    patientId={id}
                    moveItem={moveItem}
                    isUpdatePatientAuthenticated={isUpdatePatientAuthenticated}
                  />
                </div>
              </SubMenu>
            </div>
          ))}
        </ul>
      </Scrollbars>
      <CheckBox
        valuePropName="checked"
        isFormItem={false}
        checked={!showInActiveInsurance}
        onChange={handleHideInsurance}
      >
        {labels.get('buttons.hideInactiveInsurances')}
      </CheckBox>
    </div>
  );
};

export default connect((state) => ({
  enumOptions: selectors.getEnumOptions(state),
  enumMaster: selectors.getEnumMaster(state),
}))(WithLabel(InsuranceSidebar, labelPaths.INSURANCE_SIDEBAR));
