import { Dropdown, Menu } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../components/Button';
import Icon from '../../../../components/Icon';

const PostChargesDropdownBtn = ({ labels, setSelectedOption }) => {
  const menu = (
    <Menu>
      <Menu.Item onClick={() => setSelectedOption('postCharges')}>
        <span>{labels.get('buttons.postChargesWOEvaluation')}</span>
      </Menu.Item>
      <Menu.Item onClick={() => setSelectedOption('evalRules')}>
        <span>{labels.get('buttons.postChanges')}</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown trigger={['click']} overlay={menu}>
      <Button className="btn btn-success sm-btn">
        <Icon color="white" name="DownOutlined" />
      </Button>
    </Dropdown>
  );
};

PostChargesDropdownBtn.propTypes = {
  labels: PropTypes.instanceOf(Map).isRequired,
  setSelectedOption: PropTypes.func.isRequired,
};

export default PostChargesDropdownBtn;
