import { useEffect } from 'react';
import Events from '../../lib/events';

const TableWrapper = ({
  Component, reFetch,
}) => {
  useEffect(() => {
    Events.on('collectionClaimTable', 'collectionClaimTable', reFetch);
    return () => Events.remove('collectionClaimTable');
  }, []);
  return Component;
};

export default TableWrapper;
