import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import moment from 'moment';

import { apiUrls } from '../../../../../../../api/constants';
import { listIds } from '../../../../../../../lib/constants';

import useCRUD from '../../../../../../../hooks/useCRUD';
import useRedirect from '../../../../../../../hooks/useRedirect';

import Input from '../../../../../../../components/Form/Input';

const constantFilters = {
  IsPatientResponsibility: true,
  isCharge: true,
  BalanceGreaterThanZero: true,
};

const PatientRefundTable = ({
  labels, onDataChange, lineItemData, setLineItemData,
}) => {
  const { params } = useRedirect();

  const [
    claimDetails,, , getClaimDetails,
  ] = useCRUD({
    id: listIds.PATIENT_SERVICE_LINE_ITEM,
    url: params?.financialId
      ? apiUrls.GET_PATIENT_PAYMENT_EDIT_LINE_ITEMS : apiUrls.FINANCIAL_LINE_ITEMS,
    type: 'read',
  });

  useEffect(() => {
    if (params.id || params.financialId) {
      getClaimDetails(params.financialId ? {
        financeId:
       params?.financialId,
        IsPatientResponsibility: true,
        PatientId: params?.id,
        IsPaymentRefund: true,
      } : {
        PatientId: params?.id,
        ...constantFilters,
      });
    }
  }, []);

  useEffect(() => {
    if (claimDetails) {
      // all data fields has to be float
      const parsedData = claimDetails?.map((item) => ({
        financeId: item?.financeId,
        dateString: moment(item?.effectiveDate).format('MM-DD-YYYY'),
        cptCode: item?.cptDescription,
        balance: item?.balance,
        billed: item?.amount,
        paid: item?.paid,
        patientId: item?.patientId,
        applied: item?.applied,
      }));
      setLineItemData(parsedData);
      if (onDataChange) {
        onDataChange(parsedData);
      }
    }
  }, [claimDetails, onDataChange, setLineItemData]);

  const handleLineItemData = useCallback((index) => ({ target: { value, name } }) => {
    const clonedLineItemData = [...lineItemData];
    const currentLineItemData = { ...lineItemData[index], [name]: parseFloat(value) };
    clonedLineItemData[index] = {
      ...currentLineItemData,
    };
    setLineItemData(clonedLineItemData);
    if (onDataChange) {
      onDataChange(clonedLineItemData);
    }
  }, [lineItemData]);

  const totals = useMemo(() => {
    const total = {
      billed: 0,
      paid: 0,
      balance: 0,
      refund: 0,
      applied: 0,
    };
    // eslint-disable-next-line no-unused-expressions
    lineItemData?.forEach((item) => {
      total.billed += parseFloat(item?.billed || 0);
      total.paid += parseFloat(item?.paid || 0);
      total.balance += parseFloat(item?.balance || 0);
      total.refund += parseFloat(item?.refund || 0);
      total.applied += parseFloat(item?.applied || 0);
    });
    return total;
  }, [lineItemData]);

  return (
    <div className="patient-line-item-table min-width-100">
      <div className="app-table">
        <table>
          <thead>
            <tr>
              <th>{labels.get('tableColumns.dateOfService')}</th>
              <th>{labels.get('tableColumns.description')}</th>
              <th className="text-align-right">{labels.get('tableColumns.billed')}</th>
              <th className="text-align-right">{labels.get('tableColumns.paid')}</th>
              <th className="text-align-right">{labels.get('tableColumns.applied')}</th>
              <th className="text-align-right">{labels.get('tableColumns.balance')}</th>
              <th>{labels.get('tableColumns.refund')}</th>
            </tr>
          </thead>
          {lineItemData?.length ? (
            <tbody>
              {lineItemData?.map((item, index) => (
                <tr key={item?.id}>
                  <td className="text-align-left">{item?.dateString}</td>
                  <td className="text-align-left">{item?.cptCode}</td>
                  <td className="text-align-right">{parseFloat(item?.billed || 0).toFixed(2)}</td>
                  <td className="text-align-right">{parseFloat(item?.paid || 0).toFixed(2)}</td>
                  <td className="text-align-right">{parseFloat(item?.applied || 0).toFixed(2)}</td>
                  <td className="text-align-right">{parseFloat(item?.balance || 0).toFixed(2)}</td>
                  <td>
                    <Input
                      isFormItem={false}
                      numberOnly
                      decimalPlaces={2}
                      labelSpan={0}
                      inputSpan={24}
                      value={item?.approved}
                      onChange={handleLineItemData(index)}
                      name="refund"
                      defaultValue="0.00"
                    />
                  </td>
                </tr>
              ))}
              <tr>
                <td className="text-align-left">Total</td>
                <td />
                <td className="text-align-right">{(totals?.billed?.toFixed(2))}</td>
                <td className="text-align-right">{(totals?.paid?.toFixed(2))}</td>
                <td className="text-align-right">{(totals?.applied?.toFixed(2))}</td>
                <td className="text-align-right">{(totals?.balance?.toFixed(2))}</td>
                <td className="text-align-right">{(totals?.refund?.toFixed(2))}</td>
              </tr>
            </tbody>
          )
            : (
              <tbody>
                <tr>
                  <td colSpan={15} className="custom-no-data-text">
                    No Data Found
                  </td>
                </tr>
              </tbody>
)}
        </table>
      </div>
    </div>
  );
};

export default PatientRefundTable;
