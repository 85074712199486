import React, { useCallback } from 'react';

import { UiRoutes, labelPaths } from '../../../lib/constants';
import rights from '../../../lib/rights';
import Button from '../../../components/Button';
import WithRights from '../../../hoc/withRights';
import useRedirect from '../../../hooks/useRedirect';
import useRights from '../../../hooks/useRights';

import WithClearCurrentTab from '../../../hoc/withClearCurrentTab';
import withLabel from '../../../hoc/withLabel';
import SavedNavigationBar from '../../../components/SavedNavigationBar';

import Table from './UserTable';

function Users(props) {
  const { labels } = props;
  const [isUserSetupAuthenticated] = useRights([rights.user_setup]);

  const { push } = useRedirect();
  const navigateToForm = useCallback(() => push({ pathname: UiRoutes.newUser }), [push]);
  return (
    <SavedNavigationBar
      parentPath={UiRoutes.users}
      myPath={UiRoutes.editUserWithTabId}
    >
      <div>
        <div className="heading-area flex justify-content-sp-bt align-center user-screen">
          <h2>{labels.get('titles.user')}</h2>
          <div className="group-btns">
            {isUserSetupAuthenticated && (
              <Button className="btn btn-success sm-btn" id="users_add" onClick={navigateToForm}>
                {labels.get('buttons.addUser')}
              </Button>
            )}
          </div>
        </div>
        <Table labels={labels} />
      </div>
    </SavedNavigationBar>
  );
}

export default WithClearCurrentTab(WithRights(
  withLabel(Users, labelPaths.USER_LIST), [rights.user_setup, rights.user_setup_view_only],
));
