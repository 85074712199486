import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';
import { useSelector } from 'react-redux';
import { getCrudData } from '../../store/selectors';

import useCRUD from '../../hooks/useCRUD';
import Select from '../../components/Form/SelectBox';

import './Select.scss';
import { defaultProps, defaultPropsType } from './commonPropType';
import Events from '../../lib/events';

const getParsedOptions = ({
  parser, dropDownOptions,
  data, crudData,
  isStatCaseOptions, nameAccessor,
  valueAccessor, sortParamter,
  optionRenderer, defaultSorting,
  valueInString,
}) => {
  let clonedDropDownOptions = dropDownOptions;
  if (isFunction(parser)) {
    clonedDropDownOptions = parser(clonedDropDownOptions, data || crudData);
  }
  clonedDropDownOptions = clonedDropDownOptions.map((item) => ({
    ...item,
    name: isStatCaseOptions ? startCase(lowerCase(item[nameAccessor])) : item[nameAccessor],
    sortName: startCase(lowerCase(item[nameAccessor])),
    value: valueInString ? `${item[valueAccessor]}` : item[valueAccessor],
  }));
  clonedDropDownOptions = defaultSorting ? orderBy(clonedDropDownOptions, sortParamter || 'sortName') : clonedDropDownOptions;
  if (isFunction(optionRenderer)) {
    clonedDropDownOptions = optionRenderer(clonedDropDownOptions);
  }
  return clonedDropDownOptions;
};

const SelectBox = ({
  url,
  id,
  nameAccessor,
  valueAccessor,
  Component,
  onFetchComplete,
  params, reFetchOptions,
  defaultOptions,
  parser,
  optionRenderer,
  isParamsRequired,
  sortParamter,
  defaultSorting,
  isStatCaseOptions,
  valueInString,
  wrapperClassName,
  updateData,
  dataTestId,
  ...props
}) => {
  const [options, setOptions] = useState([]);
  const crudData = useSelector((state) => getCrudData(state, id));
  const [data, error, loading, getOptions] = useCRUD({ id, url, type: 'read' });
  const [localParams, setLocalParams] = useState(() => params);

  useEffect(() => {
    if (url && ((!crudData && (data === undefined || data === null) && !error && loading !== true)
    || updateData)) {
      if ((isParamsRequired && localParams && Object.keys(localParams).length)
       || !isParamsRequired) {
        getOptions(localParams);
      }
    }
  }, [url, getOptions, localParams, isParamsRequired, data, loading, crudData, id, updateData]);

  useEffect(() => {
    if (!isEqual(params, localParams)) {
      setLocalParams(params);
    }
  }, [params]);

  useEffect(() => {
    Events.on(id, id, () => {
      getOptions({ ...localParams });
    });
    return () => Events.remove(id);
  }, []);

  useEffect(() => {
    if (isFunction(reFetchOptions)) {
      reFetchOptions(getOptions);
    }
  }, [reFetchOptions, getOptions]);

  useEffect(() => {
    let dropDownOptions = [];
    if (isArray(data || crudData)) {
      dropDownOptions = data || crudData;
    } else if (isObject(data || crudData)) {
      dropDownOptions = get(data || crudData, 'result', []);
    }
    dropDownOptions = getParsedOptions({
      parser,
      dropDownOptions,
      data,
      crudData,
      isStatCaseOptions,
      nameAccessor,
      valueAccessor,
      sortParamter,
      optionRenderer,
      defaultSorting,
      valueInString,
    });
    setOptions(dropDownOptions);
    if (typeof onFetchComplete === 'function' && dropDownOptions.length) {
      onFetchComplete(dropDownOptions);
    }
  }, [data, crudData, nameAccessor, valueAccessor, onFetchComplete, optionRenderer]);

  return (
    <div className={classNames((defaultOptions || options)?.length ? '' : 'hidden-wrapper', wrapperClassName)}>
      <Component
        options={defaultOptions || options}
        loading={loading && !options.length}
        dataTestId={dataTestId}
        nameAccessor={nameAccessor}
        valueAccessor={valueAccessor}
        {...props}
      />
    </div>
  );
};

SelectBox.defaultProps = {
  ...defaultProps,
  Component: Select,

};

SelectBox.propTypes = {
  ...defaultPropsType,
};

export default SelectBox;
