import React, {
  useEffect, useCallback, useState,
} from 'react';
import { Upload } from 'antd';
import get from 'lodash/get';
import compact from 'lodash/compact';
import JSZip from 'jszip';
import moment from 'moment';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { apiUrls } from '../../../../api/constants';

import { fileSizeCheck, getName } from '../../../../lib/util';
import Events from '../../../../lib/events';
import ErrorMessages from '../../../../lib/errorMessages';
import successMessages from '../../../../lib/successMessages';
import {
  UiRoutes, contentType, listIds, listId, acceptedFileTypes,
  acceptedFileTypesForPatient, enums, dateFormat,
} from '../../../../lib/constants';
import { getEnumMaster } from '../../../../store/selectors';

import useCRUD from '../../../../hooks/useCRUD';
import useTabLink from '../../../../hooks/useTabLink';
import useRedirect from '../../../../hooks/useRedirect';
import useReduxState from '../../../../hooks/useReduxState';
import usePatientDetail from '../../../../hooks/usePatientDetail';

import Icon from '../../../../components/Icon';
import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader';
import SearchBox from '../../../../components/Search';
import SelectBox from '../../../../components/SelectBox';
import Notification from '../../../../components/Notification';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import getTabId from '../../../../components/SavedNavigationBar/tabIdGenerator';

import DocumentTable from './Table';
import CategoryList from './CategoryList';

import './document.scss';

const DocumentsTable = (props) => {
  const {
    labels,
    closeTab,
    enumMaster,
    // isUpdateDocumentAuthenticated,
    // isReadDocumentAuthenticated,
  } = props;

  const {
    params: {
      id, tab, tabId, categoryId, categoryName,
    }, generatePath, push, params,
  } = useRedirect();
  const [selectedRows, setSelectedRows] = useState([]);
  const [search, setSearch] = useReduxState(`patient-documents-search-${id}`);
  const [data, , loading, uploadFile, clearResponse] = useCRUD({ id: `${id}-new-document`, url: apiUrls.UPLOAD_PATIENT_DOCUMENT, type: 'create' });
  const [
    deleteDocumentResponse,,
    deleteLoading,
    deleteDocument,
    clearDeleteResponse] = useCRUD({
    id: listIds.DELETE_PATIENT_DOCUMENT,
    url: apiUrls.DELETE_PATIENT_DOCUMENT,
    type: 'create',
  });

  const [
    categoryData,,
    categoryDataLoading,
    getCategoryData,
  ] = useCRUD({
    id: listId.DOCUMENT_CATEGORY, url: apiUrls.GET_DOCUMENT_CATEGORY, type: 'read',
  });
  const [
    categoryCount,,
    categoryCountLoading,
    getCategoryCount,
  ] = useCRUD({
    id: listId.DOCUMENT_CATEGORY_COUNTS, url: apiUrls.GET_DOCUMENT_COUNTS, type: 'read',
  });

  const [addToFaxQueueRepsonse, , addToFaxLoading, addToFax, clearFaxResponse] = useCRUD({
    id: listId.ADD_FAX_QUEUE_SHOPPING_CART,
    url: apiUrls.ADD_FAX_QUEUE_SHOPPING_CART,
    type: 'create',
  });

  const [patientDetails] = usePatientDetail({
    patientId: id, params,
  });

  // to clear setSelectedRow when we are changing tab.
  useEffect(() => {
    if (id) {
      setSelectedRows([]);
    }
  }, [id]);

  useEffect(() => {
    if (addToFaxQueueRepsonse?.isDuplicateExists) {
      ConfirmDialog({
        onOk: (close) => {
          addToFax({
            data: {
              FaxDocumentIds: selectedRows?.map(({ original }) => original?.documentId),
              faxSource: 'patientDocument',
              patientId: params?.id,
              includeDuplicate: true,
            },
          });
          close();
        },
        onCancel: (close) => {
          addToFax({
            data: {
              FaxDocumentIds: selectedRows?.map(({ original }) => original?.documentId),
              faxSource: 'patientDocument',
              patientId: params?.id,
              includeDuplicate: false,
            },
          });
          close();
        },
        okText: labels.get('buttons.ok'),
        title: labels.get('titles.warning'),
        content: labels.get('message.warningMessage'),
        icon: <Icon name="ExclamationCircleOutlined" />,
        cancelButtonProps: { className: 'ant-modal-continue-green-btn' },
        okButtonProps: { className: 'ant-modal-ok-white-btn' },
      })();
    } else if (addToFaxQueueRepsonse?.recordInserted > 0) {
      Notification({ message: successMessages.DOCUMENT_ADDED_TO_FAX_SUCCESSFULLY, success: true });
      Events.trigger('get-patient-fax-document-count');
      clearFaxResponse(true);
    }
  }, [addToFaxQueueRepsonse]);

  useEffect(() => {
    getCategoryData({
      EnumId: get(enumMaster, `${enums.PATIENT_DOCUMENT_CATEGORY}.enumId`),
    });
  }, [getCategoryData, enumMaster]);

  useEffect(() => {
    getCategoryCount({ PatientId: id });
  }, [getCategoryCount, id]);

  const { navigate } = useTabLink(
    {
      to: UiRoutes.editPatientDocumentWithTabId,
      id: UiRoutes.editPatientWithTabId,
    },
  );

  const navigateToEditDocument = useCallback((documentParams = {}) => {
    navigate({
      data: {
        name: `${getName(patientDetails.firstName, patientDetails.middleName, patientDetails.lastName)}-${documentParams.documentName || ''}`,
      },
      ...documentParams,
    });
  }, [navigate, patientDetails]);

  useEffect(() => {
    if (data?.documentid) {
      clearResponse();
      navigateToEditDocument({ documentId: data.documentid });
    }
  }, [data]);

  const searchDocuments = useCallback(({ target: { value } }) => setSearch(value), [setSearch]);

  const setCategory = ({ newCategoryId, name }) => {
    push(generatePath(UiRoutes.patientDocumentsWithCategoryId, {
      id, tab, tabId, categoryId: newCategoryId, categoryName: name,
    }));
  };

  const onSelectFile = (fileData) => {
    const documentType = fileData?.name?.substring(fileData?.name?.lastIndexOf('.'));
    if (fileSizeCheck(fileData, 120)) {
      if (acceptedFileTypesForPatient.includes(documentType)) {
        uploadFile({
          data: {
            contentType: contentType.MULTIPART,
            File: fileData,
            CategoryId: parseInt(categoryId, 10),
            PatientId: id,
            DocumentDisplayName: fileData.name.substr(0, fileData.name.lastIndexOf('.')),
          },
        });
      } else {
        Notification({ message: ErrorMessages.FILES_TYPE_NOT_SUPPORTED, success: false });
      }
    }
  };

  const handleCareTeamLetterUpload = useCallback((parentId) => ({ file: fileData }) => {
    uploadFile({
      data: {
        contentType: contentType.MULTIPART,
        File: fileData,
        CategoryId: parseInt(categoryId, 10),
        PatientId: id,
        parentdocumentid: parentId,
        DocumentDisplayName: fileData.name.substr(0, fileData.name.lastIndexOf('.')),
      },
    });
  }, [categoryId, id, uploadFile]);

  const onFileDelete = useCallback((documentId, documentCategoryId = categoryId) => {
    deleteDocument({ data: { PatientId: id, CategoryId: documentCategoryId } }, `/${documentId}`);
    const dummyTabId = 'tabId';
    const generatedPath = generatePath(
      UiRoutes.editPatientDocumentWithTabId, { ...params, documentId, tabId: dummyTabId },
    );
    const documentTabId = getTabId(generatedPath);
    closeTab(documentTabId, false);
  }, [categoryId, closeTab, deleteDocument, generatePath, id, params]);

  useEffect(() => {
    if (deleteDocumentResponse?.message) {
      Notification({ message: successMessages.DELETE_PATIENT_DOCUMENT_SUCCESSFUL, success: true });
      getCategoryCount();
      clearDeleteResponse();
    }
  }, [deleteDocumentResponse]);

  const handleAddToFaxQueue = useCallback(() => {
    const documentIds = selectedRows?.map(({ original }) => original?.documentId);
    addToFax({
      data: {
        FaxDocumentIds: documentIds,
        faxSource: 'patientDocument',
        patientId: params?.id,
      },
    });
  }, [addToFax, params, selectedRows]);

  /**
   * This function is used for creating and downloading the zip of a selected document.
   */

  const handlePrintAll = useCallback(async () => {
    if (selectedRows?.length) {
      try {
        Notification({ message: successMessages.PRINT_IS_IN_PROGRESS, success: true });
        const zip = new JSZip();
        const alreadyPresentDocument = {};
        // eslint-disable-next-line consistent-return
        const promises = selectedRows.map(async (value) => {
          try {
            const { documentDisplayName, documentURL, fileExt } = value?.original || {};
            const response = await fetch(documentURL);
            const file = response?.blob();
            let documentName = documentDisplayName;
            const documentType = documentName?.substring(documentName?.lastIndexOf('.'));
            if (!acceptedFileTypes.includes(documentType)) {
              documentName += `.${fileExt}`;
            }
            if (alreadyPresentDocument[documentName]) {
              alreadyPresentDocument[documentName] += 1;
              documentName = `${documentName.slice(0, documentName.lastIndexOf('.'))} (${alreadyPresentDocument[documentName] - 1})${documentName.slice(documentName.lastIndexOf('.'))}`;
            } else {
              alreadyPresentDocument[documentName] = 1;
            }
            return zip.file(documentName, file, { binary: true });
          } catch (error) {
            // in case of error do nothing
          }
        });
        await Promise.all(promises);
        const content = await zip.generateAsync({ type: 'blob' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(content);
        link.download = `${categoryName}-${moment().format(dateFormat)}.zip`;
        document.body.appendChild(link);
        link.click();
        Notification({
          message:
          successMessages.PRINTED_SUCCESSFULLY(compact(promises || [])?.length),
          success: true,
        });
        document.body.removeChild(link);
      } catch (e) {
        // in case of error do nothing
      }
    }
  }, [categoryName, selectedRows]);

  return (
    <>
      {(loading || deleteLoading || addToFaxLoading) && <Loader />}
      <div className="documents-wrap">
        <div className="top-button green-select-box no-mar-bt">
          {selectedRows?.length ? (
            <div className="button-wraper">
              <Button className="sm-btn" onClick={handleAddToFaxQueue}>{labels.get('label.addToFax')}</Button>
            </div>
            ) : null}
          <div className="button-wraper">
            <SelectBox
              id="patients_documents_upload"
              placeholder={labels.get('placeholder.addDocument')}
              selectProps={{
                dropdownRender: () => (
                  <Upload
                    className="cursor-pointer upload-design"
                    accept="image/png, image/jpeg, .doc, .docx, .pdf, .DOC, .DOCX, .PDF"
                    transformFile={onSelectFile}
                    multiple={false}
                    showUploadList={false}
                    customRequest={() => { /** */ }}
                    placeholder="Upload"
                  >
                    {labels.get('dropdown.uploadFile')}
                  </Upload>
                ),
              }}
            />
          </div>
          <div className="button-wraper">
            <Button className={classNames('sm-btn', { 'cursor-not-allowed disabled-btn': !selectedRows?.length })} onClick={handlePrintAll} disabled={!selectedRows?.length} data-testid="print-all">{labels.get('label.printAll')}</Button>
          </div>
        </div>
        <div className="documents-wrap-in">
          <div className="documents-list-wrap">
            <div className="list-header">
              <div className="ant-search">
                <SearchBox id="patients_documents_search" value={search} onChange={searchDocuments} />
              </div>
            </div>
            <CategoryList
              setCategory={setCategory}
              categoryId={categoryId}
              searchText={search}
              setSearchText={setSearch}
              categoryCount={categoryCount}
              categoryCountLoading={categoryCountLoading}
              categoryData={categoryData}
              categoryDataLoading={categoryDataLoading}
            />
            <div className="btm-tags">
              Tags
            </div>
          </div>
          <DocumentTable
            getSelectedFile={onSelectFile}
            categoryId={categoryId}
            patientId={id}
            searchText={search}
            deletePatientDocument={onFileDelete}
            navigateToEditDocument={navigateToEditDocument}
            selectedCategoryName={categoryName}
            handleCareTeamLetterUpload={handleCareTeamLetterUpload}
            /* isUpdateDocumentAuthenticated={isUpdateDocumentAuthenticated} */
            // isReadDocumentAuthenticated={isReadDocumentAuthenticated}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  enumMaster: getEnumMaster(state),
});

export default connect(mapStateToProps)(DocumentsTable);
