import React, { forwardRef } from 'react';

import InnerTimeNeedle from './TimeNeedle';

const TimeSlotContainer = forwardRef(({
  children, selectedDate, timeRange, showNeedle, numberOfRows,
}, ref) => (
  <div className="grid-timeslot-container" ref={ref}>
    {children}
    <InnerTimeNeedle
      show={showNeedle}
      selectedDate={selectedDate}
      timeRange={timeRange}
      numberOfRows={numberOfRows}
    />
  </div>
));

export default TimeSlotContainer;
