import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import moment from 'moment';

import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { apiUrls } from '../../../../../../api/constants';
import { enums, listIds } from '../../../../../../lib/constants';
import { isValueUptoTwoDecimalPlaces } from '../../../../../../lib/util';

import useCRUD from '../../../../../../hooks/useCRUD';
import useRedirect from '../../../../../../hooks/useRedirect';

import Input from '../../../../../../components/Form/Input';
import Select from '../../../../../../components/SelectBox';
import DenialReasonComponent from '../../../../../../screens/Claims/withoutTabs/Remittance/InsurancePayment/LineItemsTable/DenialReasonComponent';
import { getEnumMaster } from '../../../../../../store/selectors';

const LineItemsTable = ({
  labels, onDataChange, lineItemData, setLineItemData, navigateFromCollection,
}) => {
  const { params } = useRedirect();
  const enumMaster = useSelector((state) => getEnumMaster(state));
  const enumId = get(enumMaster, `${enums.WRITE_OFF_REASON}.enumId`);
  const [
    claimDetails, , ,
    getClaimDetails,
    clearClaimDetails,
  ] = useCRUD({
    id: listIds.PATIENT_SERVICE_LINE_ITEM,
    url: apiUrls.FINANCIAL_LINE_ITEMS,
    type: 'read',
  });

  const [
    writeOffReason,,
    writeOffReasonLoading,
    getWriteOffReason,
  ] = useCRUD({
    id: listIds.WRITE_OFF_REASON,
    url: apiUrls.GET_ENUM_FIELDS,
    type: 'read',
  });

  useEffect(() => {
    getClaimDetails({
      PatientId: params?.id,
      IsPatientResponsibility: false,
      IsCharge: true,
      IsClaimGenerated: true,
      pageSize: 10000,
    });
    return () => clearClaimDetails(true);
  }, [params.id]);

  useEffect(() => {
    if (claimDetails?.result?.length) {
      // all data fields has to be float
      const parsedData = claimDetails?.result
      ?.filter((claimItem) => !((claimItem?.denialReasonId || claimItem?.isDenied)
      && !navigateFromCollection))
      ?.map((item) => ({
        financeId: item?.financeId,
        claimNo: item?.claimNo,
        dateString: moment.utc(item?.effectiveDate).format('MM-DD-YYYY'),
        cptCode: item?.description,
        encounterModifiers: item?.encounterModifiers,
        providerName: item?.providerName,
        payerName: item?.payerName,
        balance: item?.balance,
        billed: item?.amount,
        allowed: item?.value,
        paid: item?.paid,
        location: item?.locationName,
        patientId: item?.patientId,
        IsDenied: false,
        DenialReasonId: 0,
        billingEncounterServiceLineId: item?.billingEncounterServiceLineId,
      }));
      setLineItemData(parsedData);
      if (onDataChange) {
        onDataChange(parsedData);
      }
    }
  }, [claimDetails, onDataChange]);

  useEffect(() => {
    if (enumId && !writeOffReason) {
      getWriteOffReason({ EnumId: enumId });
    }
  }, [enumId]);

  const writeOffReasonOptions = useMemo(() => writeOffReason?.map((item) => ({
    ...item,
    name: item?.masterCode,
    value: item?.masterId,
  })), [writeOffReason]);

  const handleLineItemData = useCallback((index) => ({ target: { value, name, category } }) => {
    if (name === 'adjustmentReasonId' || isValueUptoTwoDecimalPlaces(value, /^-?[0-9]+(?:\.\d{0,2})?$/) || !value) {
      const clonedLineItemData = [...lineItemData];
      const currentLineItemData = { ...lineItemData[index], [name]: parseFloat(value) };
      clonedLineItemData[index] = {
        ...currentLineItemData,
        [name]: value,
        category,
      };
      setLineItemData(clonedLineItemData);
      if (onDataChange) {
        onDataChange(clonedLineItemData);
      }
    }
  }, [lineItemData, onDataChange, setLineItemData]);

  const handleLineItemSelect = useCallback((index, name) => (id, _all) => {
    const item = _all?.item;
    const clonedLineItemData = [...lineItemData];
    const currentLineItemData = { ...lineItemData[index] };

    if (_all) {
      currentLineItemData[name] = id;
      currentLineItemData.writeOffReason = item?.name;
    } else {
      delete currentLineItemData[name];
      delete currentLineItemData.writeOffReason;
    }
    clonedLineItemData[index] = currentLineItemData;
    setLineItemData(clonedLineItemData);
    if (onDataChange) {
      onDataChange(clonedLineItemData);
    }
  }, [lineItemData, onDataChange, setLineItemData]);

  const totals = useMemo(() => {
    const total = {
      billed: 0,
      allowed: 0,
      paid: 0,
      adjAmount: 0,
      writeOffAmount: 0,
      balance: 0,
    };
    // eslint-disable-next-line no-unused-expressions
    lineItemData?.forEach((item) => {
      total.billed += parseFloat(item?.billed || 0);
      total.allowed += parseFloat(item?.allowed || 0);
      total.paid += parseFloat(item?.paid || 0);
      total.adjAmount += parseFloat(item?.adjustment || 0);
      total.writeOffAmount += parseFloat(item?.writeOffAmount || 0);
      total.balance += parseFloat(item?.balance || 0);
    });
    return {
      billed: (total?.billed || 0).toFixed(2),
      allowed: (total?.allowed || 0).toFixed(2),
      paid: (total?.paid || 0).toFixed(2),
      adjAmount: (total?.adjAmount || 0).toFixed(2),
      writeOffAmount: (total?.writeOffAmount || 0).toFixed(2),
      balance: (total?.balance || 0).toFixed(2),
    };
  }, [lineItemData]);

  return (
    <div className="patient-line-item-table">
      <div className="app-table">
        <table>
          <thead>
            <tr>
              <th>{labels.get('tableColumns.claim')}</th>
              <th>{labels.get('tableColumns.dateOfService')}</th>
              <th>{labels.get('tableColumns.cptDesc')}</th>
              <th>{labels.get('tableColumns.mod')}</th>
              <th>{labels.get('tableColumns.insurance')}</th>
              <th>{labels.get('tableColumns.provider')}</th>
              <th>{labels.get('tableColumns.location')}</th>
              <th className="text-align-right">{labels.get('tableColumns.billed')}</th>
              <th className="text-align-right">{labels.get('tableColumns.allowed')}</th>
              <th className="text-align-right">{labels.get('tableColumns.paid')}</th>
              <th>{labels.get('tableColumns.adjustmentReason')}</th>
              <th>{labels.get('tableColumns.adjAmount')}</th>
              <th>{labels.get('tableColumns.writeOffReason')}</th>
              <th>{labels.get('tableColumns.writeOffAmount')}</th>
              <th className="text-align-right">{labels.get('tableColumns.balance')}</th>
            </tr>
          </thead>
          {lineItemData?.length ? (
            <tbody>
              {lineItemData?.map((item, index) => (
                <tr key={item?.id} data-testid={`row-${index}`}>
                  <td className="text-align-left">{item?.claimNo}</td>
                  <td className="text-align-left">{item?.dateString}</td>
                  <td className="text-align-left">{item?.cptCode}</td>
                  <td className="text-align-left">{item?.encounterModifiers}</td>
                  <td className="text-align-left">{item?.payerName}</td>
                  <td className="text-align-left">{item?.providerName}</td>
                  <td className="text-align-left">{item?.location}</td>
                  <td className="text-align-right">{parseFloat(item?.billed || 0).toFixed(2)}</td>
                  <td className="text-align-right">{parseFloat(item?.allowed || 0).toFixed(2)}</td>
                  <td className="text-align-right">{parseFloat(item?.paid || 0).toFixed(2)}</td>
                  <td key={index}>
                    <DenialReasonComponent
                      item={item}
                      index={index}
                      labels={labels}
                      handleLineItemData={handleLineItemData}
                      name={`adjustmentReasonId-${index}`}
                      id={`adjustmentReasonId-${index}-${params?.tabId}`}
                      reasonIdName="adjustmentReasonId"
                      dataTestId={`denial-reason-${index}`}
                    />
                  </td>
                  <td>
                    <Input
                      isFormItem={false}
                      numberOnly
                      decimalPlaces={2}
                      labelSpan={0}
                      inputSpan={24}
                      value={item?.adjustment || ''}
                      maxValueLength={11}
                      onChange={handleLineItemData(index)}
                      name="adjustment"
                      dataTestId={`adjustment-${index}`}
                    />
                  </td>
                  <td>
                    <Select
                      isFormItem={false}
                      labelSpan={0}
                      inputSpan={24}
                      value={item?.writeOffReason}
                      name="writeOffReasonId"
                      options={writeOffReasonOptions}
                      onChange={handleLineItemSelect(index, 'writeOffReasonId')}
                      selectProps={{
                        style: { width: 130 },
                        loading: writeOffReasonLoading,
                        'data-testid': `write-off-reason-${index}`,
                      }}
                      allowClear
                    />
                  </td>
                  <td>
                    <Input
                      isFormItem={false}
                      numberOnly
                      decimalPlaces={2}
                      labelSpan={0}
                      inputSpan={24}
                      maxValueLength={11}
                      value={item?.writeOffAmount || ''}
                      onChange={handleLineItemData(index)}
                      name="writeOffAmount"
                      dataTestId={`write-off-amount-${index}`}
                    />
                  </td>
                  <td className="text-align-right">{parseFloat(item?.balance || 0).toFixed(2)}</td>
                </tr>
              ))}
              <tr>
                <td>Total</td>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td className="text-align-right">{(totals?.billed)}</td>
                <td className="text-align-right">{(totals?.allowed)}</td>
                <td className="text-align-right">{(totals?.paid)}</td>
                <td />
                <td className="text-align-right">{(totals?.adjAmount)}</td>
                <td />
                <td className="text-align-right">{(totals?.writeOffAmount)}</td>
                <td className="text-align-right">{(totals?.balance)}</td>
              </tr>
            </tbody>
          )
            : (
              <tbody>
                <tr>
                  <td colSpan={15} className="custom-no-data-text">
                    No Data Found
                  </td>
                </tr>
              </tbody>
)}
        </table>
      </div>
    </div>
  );
};

export default LineItemsTable;
