import React, { useCallback } from 'react';
import {
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';

import Icon from '../../../../components/Icon';
import StatusCell from '../../../../components/StatusCell';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import Upload from '../../../../components/Upload';
import Button from '../../../../components/Button';

import { formatPhoneNumber } from '../../../../lib/util';

const payerColumns = (onAddMappingIconClick, labels) => [
  {
    Header: labels.get('tables.name'),
    accessor: 'name',
    Cell: ({
      row,
      row: { original: { name, insurancePayerId } },
      navigateToEditPayer,
      isEditPermitted,
    }) => {
      const expanderProps = row.getToggleRowExpandedProps();
      if (expanderProps.onClick) {
        const { onClick } = expanderProps;
        expanderProps.onClick = (e) => {
          e.stopPropagation();
          onClick(e);
        };
      }
      return (
        <div className="feild-row">
          <span {...expanderProps}>
            <div>
              {row.isExpanded ? <MinusOutlined /> : <PlusOutlined />}
            </div>
          </span>
          {isEditPermitted ? (
            <span onClick={() => navigateToEditPayer(insurancePayerId)} className="csr-pointer mr-left-20" role="presentation">
              {name}
            </span>
          ) : (
            <span>
              {name}
            </span>
          )}
        </div>
      );
    },
    sort: true,
  },
  {
    Header: labels.get('tables.address'),
    accessor: 'address',
    Cell: ({ row: { original: { address } } }) => <Tooltip title={address}>{address}</Tooltip>,
    sort: true,
  },
  {
    Header: labels.get('tables.phone'),
    accessor: 'phone',
    Cell: ({ row: { original: { phone } } }) => <span>{formatPhoneNumber(phone)}</span>,
    fixWidth: '110',
  },
  {
    Header: labels.get('tables.submissionMethod'),
    accessor: 'submissionMethodName',
  },
  {
    Header: 'Internal Id',
    accessor: 'insurancePayerId',
  },
  {
    Header: labels.get('tables.addMapping'),
    accessor: 'addMapping',
    fixWidth: '100',
    Cell: ({ row: { original }, isPayerAuthenticated }) => isPayerAuthenticated && (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a
        className="row-add sprite-img-before"
        onClick={() => onAddMappingIconClick([original], original)}
        role="presentation"
      />
    ),
  },
  {
    Header: labels.get('tables.upload'),
    accessor: 'upload',
    fixWidth: '100',
    Cell: ({ onSelectFile, isPayerAuthenticated, row: { original: { insurancePayerId } } }) => {
      const onUpload = useCallback(({ fileList }) => {
        onSelectFile(fileList[0].originFileObj, insurancePayerId);
      }, [insurancePayerId]);
      if (isPayerAuthenticated) {
        return (
          <Upload
            accept=".xlsx"
            onChange={onUpload}
            multiple={false}
            showUploadList={false}
            name={null}
            labelSpan="0"
          >
            <Button className="btn btn-success sm-btn">{labels.get('label.import')}</Button>
          </Upload>
        );
      }
      return null;
    },
  },
];

const cptColumns = (onAddMappingIconClick, labels) => [
  {
    Header: labels.get('tables.expander'),
    accessor: 'expander',
    fixWidth: '50',
    Cell: ({ row }) => {
      const expanderProps = row.getToggleRowExpandedProps();
      if (expanderProps.onClick) {
        const { onClick } = expanderProps;
        expanderProps.onClick = (e) => {
          e.stopPropagation();
          onClick(e);
        };
      }
      return (
        <span {...expanderProps}>
          <div>
            {row.isExpanded ? <MinusOutlined /> : <PlusOutlined />}
          </div>
        </span>
      );
    },
  },
  {
    Header: labels.get('tables.CPTCode'),
    accessor: 'cptCode',
  },
  {
    Header: labels.get('tables.description'),
    accessor: 'description',
  },
  {
    Header: labels.get('tables.status'),
    accessor: 'isActive',
    Cell: (e) => {
      const { isActive } = e.row.original;
      return <StatusCell active={isActive} />;
    },
  },
  {
    Header: labels.get('tables.createdAt'),
    accessor: 'createdAt',
    fixWidth: '50',
    Cell: ({ row: { original }, currentPayer }) => (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a
        className="row-add sprite-img-before"
        onClick={() => onAddMappingIconClick([original, currentPayer], original)}
        role="presentation"
      />
    ),
  },
];

const columns = (labels) => [
  {
    Header: labels.get('tables.ICDCode'),
    accessor: 'icdCode',
  },
  {
    Header: labels.get('tables.description'),
    accessor: 'description',
  },
  {
    Header: labels.get('tables.status'),
    accessor: 'isActive',
    Cell: ({ row: { original } }) => {
      const { isActive } = original;
      return <StatusCell active={isActive} />;
    },
  },
  {
    Header: 'Actions',
    accessor: 'remove',
    Cell: ({ row: { original: { id } }, onDeleteICD, cptId }) => (
      <Icon
        className="cursor-pointer"
        onClick={ConfirmDialog({
          onOk: (close) => {
            onDeleteICD(id, cptId);
            close();
          },
          title: 'Do you want to delete this item?',
          content: 'When clicked the OK button, item will be deleted permanently.',
          icon: <Icon name="ExclamationCircleOutlined" />,
        })}
        name="DeleteOutlined"
      />
    ),
  },
];

export {
  payerColumns,
  cptColumns,
  columns,
};
