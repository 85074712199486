import React from 'react';

import WithLabel from '../../../../hoc/withLabel';
import { labelPaths } from '../../../../lib/constants';

import HistoryTable from './Table';
import dateFormatter from '../../../../lib/dateFormatter';
import { getDateString } from '../../../../lib/util';

const History = ({ labels, patientDateOfBirth }) => (
  <>
    <div className="dob-row flex">
      <div className="key">{`${labels.get('labels.dob')} :`}</div>
      <div className="value">
        {dateFormatter(getDateString(patientDateOfBirth))}
      </div>
    </div>
    <HistoryTable labels={labels} />
  </>
);

export default WithLabel(History, labelPaths.ENCOUNTER_HISTORY);
