const exceptions = (labels) => [
  {
    Header: labels.get('tableColumns.range'),
    accessor: 'isRange',
    fixWidth: '50',
  },
  {
    Header: labels.get('tableColumns.procedure'),
    accessor: 'procedureFromId',
    fixWidth: '280',
  },
  {
    Header: labels.get('tableColumns.procedureDescription'),
    accessor: 'procedureFromDescription',
  },
  {
    Header: 'Fee Schedule',
    accessor: 'feeSchedule',
    fixWidth: '100',
  },
  {
    Header: labels.get('tableColumns.type'),
    accessor: 'typeId',
    fixWidth: '100',
  },
  {
    Header: labels.get('tableColumns.modifier'),
    accessor: 'modifier',
    fixWidth: '100',
  },
  {
    Header: labels.get('tableColumns.value'),
    accessor: 'typeValue',
    fixWidth: '120',
  },
  {
    Header: labels.get('tableColumns.valueType'),
    accessor: 'typeValueTypeId',
    fixWidth: '120',
  },
  {
    Header: labels.get('tableColumns.delete'),
    accessor: 'delete',
    fixWidth: '50',
  },
  {
    Header: labels.get('tableColumns.action'),
    accessor: 'action',
    fixWidth: '200',
  },
];

export default exceptions;
