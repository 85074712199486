import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import moment from 'moment';

import useCRUD from '../../../../hooks/useCRUD';
import usePatientDetail from '../../../../hooks/usePatientDetail';
import useRedirect from '../../../../hooks/useRedirect';
import WithLabel from '../../../../hoc/withLabel';
import { labelPaths, listIds } from '../../../../lib/constants';

import './dashboard.scss';
import PatientAppointmentTable from './components/PatientAppointmentTable';
import Information from './components/Information';
import Notes from '../../../../wiredComponents/Notes';
import Insurance from './components/Insurance';
import ActivityLog from './components/ActivityLog';
import { apiUrls } from '../../../../api/constants';
import WidgetLoader from '../../../../components/WidgetLoader';
import BalanceSheetTable from '../../../../wiredComponents/Adustment/Components/BalanceSheetTable';
import AddMedicationModal from '../../../Doctor/Medications/AddMedicationModal';
import useReduxState from '../../../../hooks/useReduxState';

const ClinicalSummary = ({ clinicalSummary, clinicalSummaryLoading }) => (
  <div className="insurance-summary shadow-wrap mr-rt-16">
    <div className="pharmacy-wrap">
      <p className="title sprite-img-before clinical-icon">
        <span className="clinical-icon" />
        {' '}
        Clinical Summary
      </p>
    </div>
    <div className="posi-relative clinical-summary-box">
      {clinicalSummaryLoading && <WidgetLoader />}
      <div className="descriptions-clinical">
        <div className="description-feilds">
          <p className="key">Primary Provider</p>
          <p className="value">{clinicalSummary?.result?.primaryProvider}</p>
        </div>
        <div className="description-feilds">
          <p className="key">Primary Location</p>
          <p className="value">{clinicalSummary?.result?.primaryLocation}</p>
        </div>
      </div>
      <div className="descriptions-clinical">
        <div className="description-feilds">
          <p className="key">Referred By</p>
          <p className="value">{clinicalSummary?.result?.referredBy}</p>
        </div>
        <div className="description-feilds">
          <p className="key">Last Seen By</p>
          <p className="value">{clinicalSummary?.result?.lastSeenBy}</p>
        </div>
      </div>
      <div className="description-feilds">
        <p className="key">Diagnosis</p>
        {clinicalSummary?.result?.diagnoses?.map((item) => (
          <div className="value-wrap" key={item?.id}>
            <span className="first-value">{item?.icdCode}</span>
            <p className="value">
              {item?.description}
            </p>
          </div>
        ))}
      </div>
      <div className="description-feilds">
        <p className="key">Medications</p>
        {clinicalSummary?.result?.medications?.map((item) => (
          <div className="value-wrap flex flex-dir-column" key={item?.id}>
            <p className="value">
              {moment(item?.writtenDate).format('MM-DD-YYYY')}
            </p>
            {
            item?.prescriptionDrugs?.map((medications) => (
              <span
                className="first-value mr-lt-12"
                key={medications?.drugOrder}
              >
                {medications?.drugName || ''}
                {' '}
                {medications?.line1 || ''}
                {' '}
                {medications?.line2 || ''}
              </span>
            ))
          }
          </div>
        ))}
      </div>
      <div className="description-feilds">
        <p className="key">Allergies</p>
        {clinicalSummary?.result?.allergies?.patientAllergies?.map((item) => (
          <div className="value-wrap" key={item?.id}>
            <p className="value">
              {item?.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const PharmacyItem = ({ item }) => (
  <div className="description-feilds insurance-summary shadow-wrap" key={item?.pharmacy}>
    <p className="value"><b>{item?.pharmacy}</b></p>
    <p className="value"><b>{item?.addressLine1}</b></p>
    <p className="value">{item?.city}</p>
  </div>
);

const Pharmacy = ({
  labels, pharmacyDetailsLoading, pharmacyDetails, medicationSyncLoading, toggleMedicationModal,
}) => {
  const parsedPharmacyDetails = useMemo(() => {
    const parsedPharmacy = [];
    if (pharmacyDetails?.length) {
      pharmacyDetails?.forEach((item) => {
        if (item?.isDefault) {
          parsedPharmacy.unshift(<PharmacyItem item={item} />);
        } else {
          parsedPharmacy.push(<PharmacyItem item={item} />);
        }
      });
    }
    return parsedPharmacy;
  }, [pharmacyDetails]);

  return (
    <div className="insurance-summary shadow-wrap posi-relative">
      <div className="pharmacy-wrap">
        <p className="title sprite-img-before">
          <span className="pharmacy-icon" />
          {labels.get('titles.pharmacy')}
        </p>
        <div className="pharmacy-wrap">
          <p className="edit-icon" role="presentation" onClick={toggleMedicationModal} />
        </div>
      </div>
      <div className="descriptions    notes-container">
        {(pharmacyDetailsLoading || medicationSyncLoading) && <WidgetLoader />}
        {parsedPharmacyDetails}
      </div>
    </div>
  );
};

const Dashboard = ({
  labels,
  isUpdatePatientAuthenticated,
}) => {
  const { params: { id } } = useRedirect();
  const [currentPatient] = usePatientDetail({ patientId: id });
  const [isVisible, setMedicationVisible] = useState(false);
  const [sessionToken, setSessionToken] = useReduxState('dashboard-sessionToken', null);

  const [
    clinicalSummary,,
    clinicalSummaryLoading,
    getClinicalSummary,
  ] = useCRUD({
    id: `${listIds.GET_PATIENT_CLINICAL_SUMMARY}_${id}`,
    url: apiUrls.GET_PATIENT_CLINICAL_SUMMARY,
    type: 'read',
  });

  const [
    pharmacyDetails,,
    pharmacyDetailsLoading,
    getPharmacyDetails,
  ] = useCRUD({
    id: `${listIds.GET_PATIENT_PHARMACY}_${id}`,
    url: apiUrls.GET_PATIENT_PHARMACY,
    type: 'read',
  });

  const [
    Medication,,
    medicationSyncLoading,
    getMedications,
    clearMedicationsResponse,
  ] = useCRUD({
    url: apiUrls.SYNC_PATIENT_PHARMACY,
    id: `SYNC_MEDICATION_TOKEN_${id}`,
    type: 'read',
  });

  useEffect(() => {
    if (id) {
      getClinicalSummary({ patientid: id });
      getMedications({
        SessionToken: sessionToken,
        PatientId: id,
      });
    }
  }, [id]);

  useEffect(() => {
    if (Medication) {
      getClinicalSummary({ patientid: id });
      getPharmacyDetails({ patientid: id });
      clearMedicationsResponse(true);
    }
  }, [Medication]);

  const columnsRequired = ['type', 'totalDue', 'unbilled', 'pastDue'];

  const setSession = useCallback((url) => {
    let token = url.split('=');
    token = token && token[1].split('#');
    setSessionToken((token && token[0]) || null);
  }, [setSessionToken]);

  const toggleMedicationModal = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isVisible) {
      getMedications({
        SessionToken: sessionToken,
        PatientId: id,
      });
    }
    setMedicationVisible(!isVisible);
  }, [getMedications, id, isVisible, sessionToken]);

  return (
    <div className="dahsboard-wrapper">
      <section className="information-section">
        {currentPatient && <Information currentPatient={currentPatient} labels={labels} />}
        {/* <BalanceChart labels={labels} />
        <FlagChart labels={labels} /> */}
      </section>
      <div>
        <ActivityLog patientId={id} />
        <div className="summary-balance-sheet">
          <BalanceSheetTable
            labels={labels}
            isCustomized
            columnsRequired={columnsRequired}
          />
        </div>
        <PatientAppointmentTable
          labels={labels}
        />
        <section className="bottom-box-section">
          <Insurance labels={labels} />
          <ClinicalSummary
            clinicalSummary={clinicalSummary}
            clinicalSummaryLoading={clinicalSummaryLoading}
          />
          <div className="pharmacy-btm-section">
            <Pharmacy
              pharmacyDetails={pharmacyDetails}
              pharmacyDetailsLoading={pharmacyDetailsLoading}
              medicationSyncLoading={medicationSyncLoading}
              toggleMedicationModal={toggleMedicationModal}
              labels={labels}
            />
            <Notes
              labels={labels}
              isUpdatePatientAuthenticated={isUpdatePatientAuthenticated}
            />
            <AddMedicationModal
              toggleModal={toggleMedicationModal}
              isVisible={isVisible}
              setSession={setSession}
              id={id}
              widget="Pharmacy"
              modalTitle="Pharmacy"
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default WithLabel(Dashboard, labelPaths.PATIENT_DASHBOARD);
