import React from 'react';

import Label from '../../../../../components/Label';
import Form from '../../../../../components/Form';

import dateFormatter from '../../../../../lib/dateFormatter';
import { formatPhoneNumber, getDateString, getName } from '../../../../../lib/util';

const PolicyHolder = (props) => {
  const { policyData, labels } = props;
  const policyInfo = policyData && policyData.primaryInsured;

  return (
    <div className="form-container view-mode">
      <form>
        <div className="feild-row justify-content-sp-bt align-center">
          <h2 className="h2-title">{labels.get('titles.policyHolder')}</h2>
        </div>
        <Form.Section noOfColumns={3}>
          <Form.Column>
            <div className="group-shadow">
              <div className="posi-relative combine-fields">
                <div md={9}><Label label={labels.get('labels.name')} /></div>
                <div md={15} className="value-view">{getName(policyInfo?.firstName, policyInfo?.middleName, policyInfo?.lastName)}</div>
              </div>
              <div className="posi-relative combine-fields">
                <div md={9}><Label label={labels.get('labels.country')} /></div>
                <div md={15} className="value-view">{policyInfo?.countryName}</div>
              </div>
              <div className="posi-relative combine-fields">
                <div md={9}><Label label={labels.get('labels.addressLine1')} /></div>
                <div md={15} className="value-view">{policyInfo?.addressLine1}</div>
              </div>
              <div className="posi-relative combine-fields">
                <div md={9}><Label label={labels.get('labels.addressLine2')} /></div>
                <div md={15} className="value-view">{policyInfo?.addressLine2}</div>
              </div>
            </div>
          </Form.Column>
          <Form.Column>
            <div className="group-shadow">
              <div className="posi-relative combine-fields">
                <div md={9}><Label label={labels.get('labels.phone')} /></div>
                <div md={15} className="value-view" data-testid="phone">{formatPhoneNumber(policyInfo?.primaryPhone)}</div>
              </div>
              <div className="posi-relative combine-fields">
                <div md={9}><Label label={labels.get('labels.city')} /></div>
                <div md={15} className="value-view">{policyInfo?.city}</div>
              </div>
              <div className="posi-relative combine-fields">
                <div md={9}><Label label={labels.get('labels.primaryPhone')} /></div>
                <div md={15} className="value-view" data-testid="primaryPhone">{formatPhoneNumber(policyInfo?.primaryPhone)}</div>
              </div>
              <div className="posi-relative combine-fields">
                <div md={9}><Label label={labels.get('labels.secondaryPhone')} /></div>
                <div md={15} className="value-view" data-testid="secondaryPhone">{formatPhoneNumber(policyInfo?.secondaryPhone)}</div>
              </div>
            </div>
          </Form.Column>
          <Form.Column>
            <div className="group-shadow">
              <div className="posi-relative combine-fields">
                <div md={9}><Label label={labels.get('labels.email')} /></div>
                <div md={15} className="value-view">{policyInfo?.emailAddress}</div>
              </div>
              <div className="posi-relative combine-fields">
                <div md={9}><Label label={labels.get('labels.ssn')} /></div>
                <div md={15} className="value-view">{policyInfo?.ssn}</div>
              </div>
              <div className="posi-relative combine-fields">
                <div md={9}><Label label={labels.get('labels.dateOfBirth')} /></div>
                <div md={15} className="value-view">{policyInfo?.dateOfBirth && dateFormatter(getDateString(policyInfo.dateOfBirth))}</div>
              </div>
              <div className="posi-relative combine-fields">
                <div md={9}>
                  <Label label={labels.get('labels.gender')} />
                </div>
                <div md={15} className="value-view">{policyInfo?.genderName}</div>
              </div>
            </div>
          </Form.Column>
        </Form.Section>

      </form>
    </div>
  );
};

export default PolicyHolder;
