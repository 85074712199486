import React, { useEffect, useCallback, useState } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';

import { generatePath, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import get from 'lodash/get';
import { Form as AntdForm } from 'antd';

import Form from '../../components/Form';
import Image from '../../components/Image';
import Error from '../../components/Error';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import Input from '../../components/Form/Input';
import Notification from '../../components/Notification';
import ConfirmModal from '../../components/ConfirmDialog';

import useRedirect from '../../hooks/useRedirect';
import useCRUD from '../../hooks/useCRUD';

import api from '../../api';
import { apiUrls } from '../../api/constants';
import { loginRequest, msalConfig } from '../../api/authConfig';
import encryptData from '../../lib/encryptData';
import { formId, UiRoutes } from '../../lib/constants';
import formFieldValuesParser from '../../lib/formFieldValuesParser';

import { getEnumData } from '../../store/actions/enum';
import { setCurrentData, clearLoginData, logout } from '../../store/actions/login';
import { updateUserSettings } from '../../store/actions/users';

import './login.scss';

const roleBasedRoutes = {
  physician: UiRoutes.doctor,
  officeStaff: UiRoutes.schedular,
  officeSuperVisor: UiRoutes.schedular,
  billing: UiRoutes.billing,
  admin: UiRoutes.dashboard,
  other: UiRoutes.schedular,
};

const appBasedRoutes = {
  frontdesk: UiRoutes.dashboard, // Frontdesk | 366
  scheduler: UiRoutes.schedular, // Scheduler | 473
  doctor: UiRoutes.doctor, // Doctor | 474
  technicians: UiRoutes.technician, // Technicians | 475
  patients: UiRoutes.patients, // Patients | 476
  claims: generatePath(UiRoutes.claimsTab, { tab: 'claims' }), // Claims | 477
  remittance: generatePath(UiRoutes.claimsTab, { tab: 'remittance' }), // Remittance | 478
  collections: generatePath(UiRoutes.claimsTab, { tab: 'collections' }), // Collections | 479
  inventory: UiRoutes.Inventory, // Inventory | 480
  asc: UiRoutes.SurgeryPatient, // ASC | 481
  reporting: UiRoutes.reportsMain, // Reporting | 482
  contracts: UiRoutes.contract, // Contracts | 483
  appointment: UiRoutes.appointment, // Appointment | 1879
  billingencounter: UiRoutes.billing, // BillingEncounter | 1880
  dashboard: UiRoutes.dashboard, // Dashboard | 1881
  other: UiRoutes.schedular,
};

const parser = (data) => {
  const { email } = data;
  const splittedEmail = email.split('@');
  if (splittedEmail.length > 1) {
    return formFieldValuesParser({
      ...data,
      email: encryptData(data?.email),
      password: encryptData(data?.password),
      Offset: encryptData(moment().utcOffset()),
    }, {
      toBeRemove: [
        'captchaFormItem',
      ],
    });
  }
  return formFieldValuesParser({
    ...data,
    email: encryptData(`${splittedEmail[0]}@acuityeyegroup.com`),
    password: encryptData(data?.password),
  }, {
    toBeRemove: [
      'captchaFormItem',
    ],
  });
};

const getProviderCurrentLocation = async (providerId, token) => {
  const providerTemplate = await api.get({
    url: apiUrls.GET_SCHEDULER_DATA,
    params: {
      providerId,
      scheduleStartDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      scheduleEndDate: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    },
    token,
  });
  if (Array.isArray(providerTemplate) && providerTemplate?.length) {
    const [current] = providerTemplate;
    if (Array.isArray(current?.providersSchedule) && current?.providersSchedule?.length) {
      const [{ schedule }] = current?.providersSchedule;
      const currentLocation = schedule.find(({ slotStartTime, slotEndTime }) => (
        moment().isSameOrAfter(moment(slotStartTime, 'hh:mm:ss'))
        && moment().isSameOrBefore(moment(slotEndTime, 'hh:mm:ss'))
      ));
      return currentLocation?.locationId;
    }
  }
  return null;
};

function Login(props) {
  const {
    getEnums, setCurrentUserData, clearLogin, updateUserSettingsData, logoutUser,
  } = props;

  const [form] = AntdForm.useForm();
  const [microsoftIdToken, setMicrosoftIdToken] = useState('');

  const { push } = useRedirect();

  const [isLoggedIn, setLoggedIn] = useState(true);

  const [microsoftLoginResponse, , microsoftLoginLoading, getMicrosoftLoginVerification] = useCRUD({
    id: 'microsoft-login-response',
    url: apiUrls.GET_MICROSOFT_LOGIN_DETAILS,
    type: 'create',
  });

  const [forceLoginResponse, , forceLoginLoading, forceLogin] = useCRUD({
    id: `${apiUrls.LOGIN}-force-login`,
    url: apiUrls.LOGIN,
    type: 'create',
  });

  useEffect(() => {
    const token = localStorage.getDecryptedData('token');
    if (token) {
      const user = JSON.parse(localStorage.getDecryptedData('user'));
      setCurrentUserData(user, token);
      const { defaultAppId } = user || {};
      const userRole = (user?.roleName || 'other').toLowerCase();
      if (defaultAppId) {
        const openDefaultApp = async () => {
          try {
            const masterData = await api.get({ url: `${apiUrls.GET_ENUM_FIELDS}/${defaultAppId}`, token });
            const defaultApp = (masterData?.masterCode || 'other').split(' ').join('').toLowerCase();
            push(appBasedRoutes[defaultApp] || UiRoutes.schedular);
          } catch (error) {
            logoutUser();
            setLoggedIn(false);
          }
        };
        openDefaultApp();
      } else push(roleBasedRoutes[userRole] || UiRoutes.schedular);
    } else {
      setLoggedIn(false);
    }
  }, []);

  const onRequestComplete = useCallback(async (response) => {
    clearLogin();
    if (!get(response, 'response.alreadyTokenExist')) {
      if (get(response, 'response.accessToken')) {
        setLoggedIn(true);
        const token = get(response, 'response.accessToken');
        localStorage.setEncryptedData('token', token);
        const pKey = get(response, 'response.pKey');
        localStorage.setEncryptedData('pKey', pKey);
        const userData = await api.get({ url: apiUrls.GET_USER_PROFILE, token }) || {};
        if (userData?.claimsMasterResponseModels) {
          const permissions = {};
          userData.claimsMasterResponseModels.forEach((right) => {
            if (right.claimcode !== null) {
              permissions[right.claimcode] = true;
            }
          });
          userData.permissions = permissions;
        }
        localStorage.setEncryptedData('user-profile', JSON.stringify((userData)));
        setCurrentUserData({ ...get(response, 'response', {}), userData }, get(response, 'response.accessToken'));
        if (userData?.providerId) {
          const locationId = await Promise.resolve(
            getProviderCurrentLocation(userData?.providerId, token),
          );
          if (locationId) userData.locationId = locationId;
          updateUserSettingsData(userData);
        }
        localStorage.setEncryptedData('user', JSON.stringify({
          ...(response?.response || {}),
          ...(userData),
        }));
        const refreshToken = get(response, 'response.refreshToken');
        localStorage.setEncryptedData('refreshToken', refreshToken);
        const accessTokenExpiration = get(response, 'response.accessTokenExpiration');
        localStorage.setEncryptedData('accessTokenExpiration', accessTokenExpiration);
        localStorage.setEncryptedData('accessTokenExpirationTime', moment(accessTokenExpiration).diff(moment(), 'minutes'));
        localStorage.setEncryptedData('userLoggedIn', true); // used by user settings reducer
        // getEnums();
        const { defaultAppId } = userData;
        const userRole = (userData?.roleName || 'other').toLowerCase();
        if (defaultAppId) {
          const masterData = await api.get({ url: `${apiUrls.GET_ENUM_FIELDS}/${defaultAppId}`, token });
          const defaultApp = (masterData?.masterCode || 'other').split(' ').join('').toLowerCase();
          push(appBasedRoutes[defaultApp] || UiRoutes.schedular);
        } else push(roleBasedRoutes[userRole] || UiRoutes.schedular);
      }
    } else {
      ConfirmModal({
        title: 'You are already login on other device. If you will continue to login here then you will be logout from other device. Do you want to continue?',
        onOk: () => {
          const { email, password } = form.getFieldsValue();
          if (get(response, 'response.isMicrosoftAuth')) {
            getMicrosoftLoginVerification({
              data: {
                forceLogin: true,
                idToken: microsoftIdToken && encryptData(microsoftIdToken),
                Offset: encryptData(moment().utcOffset()),
                SSOType: encryptData('Microsoft'),
              },
            });
          } else {
            forceLogin({
              data: {
                email: encryptData(email),
                password: encryptData(password),
                Offset: encryptData(moment().utcOffset()),
                forceLogin: true,
              },
            });
          }
        },
      })();
    }
    setLoggedIn(false);
  }, [clearLogin, setCurrentUserData, push, updateUserSettingsData, getEnums,
    form, getMicrosoftLoginVerification, microsoftIdToken, forceLogin]);

  useEffect(() => {
    if (forceLoginResponse) {
      onRequestComplete({ response: { ...forceLoginResponse } });
    }
    if (microsoftLoginResponse) {
      localStorage.setEncryptedData('isMicroSoftLogin', true);
      onRequestComplete({ response: { ...microsoftLoginResponse, isMicrosoftAuth: true } });
    }
  }, [forceLoginResponse, microsoftLoginResponse]);

  const handleMicroSoftLogin = useCallback(() => {
    const msalInstance = new PublicClientApplication(msalConfig);
    msalInstance.loginPopup(loginRequest).then((res) => {
      setMicrosoftIdToken(res.idToken);
      getMicrosoftLoginVerification({
        data: {
          idToken: res.idToken && encryptData(res.idToken),
          Offset: encryptData(moment().utcOffset()),
          SSOType: encryptData('Microsoft'),
        },
      });
    }).catch((e) => {
      console.log('error-inside-microsoft-login-integration', e);
    });
  }, [getMicrosoftLoginVerification]);

  const onSubmit = useCallback((data) => {
    setLoggedIn(true);
    return parser(data);
  }, []);

  const onRequestFail = useCallback((messages) => {
    setLoggedIn(false);
    Notification({ message: (<Error messages={messages} />) });
  }, []);

  return (
    <>
      {(isLoggedIn || forceLoginLoading || microsoftLoginLoading) && <Loader />}
      <div className="login-wrap">
        <div className="login-section">
          <div className="login-logo">
            {process.env.REACT_APP_DEMO === 'true' && <Image name="login-logo.png" alt="logo" />}
          </div>
          <div className="shadow-bbg-1" />
          <div className="shadow-bbg-2" />
          <div className="login-alignment">
            <div className="login-field-wrap">
              <h1>Login to Healthcare</h1>
              <Route exact path={UiRoutes.login} key="main page">
                <div className="microsoft-login-button">
                  <Button onClick={handleMicroSoftLogin}>
                    <span>Sign in with Single Sign-On</span>
                  </Button>
                </div>
              </Route>
              <Route exact path={UiRoutes.supportLogin} key="main support page">
                <Form
                  shouldShowLoader={false}
                  form={form}
                  section
                  formId={formId.LOGIN_FORM}
                  url={apiUrls.LOGIN}
                  onRequestComplete={onRequestComplete}
                  parser={onSubmit}
                  onRequestFail={onRequestFail}
                >
                  <Input
                    placeholder="Email"
                    label="Email"
                    required
                    labelSpan={0}
                    inputSpan={24}
                    name="email"
                    rules={[{ type: 'email', message: 'Please enter the valid email-id' }]}
                  />
                  <Input
                    type="password"
                    label="Password"
                    placeholder="Password"
                    required
                    name="password"
                    labelSpan={0}
                    inputSpan={24}
                    applyValidation={false}
                    showInputTooltip={false}
                  />
                  <Button className="btn btn-success btn-block" data-testid="loginBtn" type="submit">Login</Button>
                </Form>
                <Link to={UiRoutes.forgotPassword} className="forget-pass">Forgot Password?</Link>
              </Route>
            </div>
          </div>
        </div>
        {process.env.REACT_APP_DEMO === 'true' && <div className="version-section">V0.9.0 Beta</div>}
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getEnums: () => dispatch(getEnumData()),
  setCurrentUserData: (response, token) => dispatch(setCurrentData(response, token)),
  clearLogin: () => dispatch(clearLoginData()),
  logoutUser: () => dispatch(logout('REQUEST_CANCELLED_SESSION_TIMEOUT')),
  updateUserSettingsData: (userData) => {
    const settings = {
      defaultProviders: { doctor: userData?.providerId },
      defaultLocations: { location: userData?.locationId },
    };
    dispatch(updateUserSettings(settings));
  },
});

export default connect(null, mapDispatchToProps)(Login);
