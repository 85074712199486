import React, { useCallback } from 'react';
import omit from 'lodash/omit';

import CheckBox from '../../../../../components/Form/CheckBox';

const CheckBoxList = ({
  data, allChecked, handleRights, isUserRightsViewOnly,
}) => {
  const handleOnChange = useCallback((e, claimsData) => {
    const { target: { checked } } = e;
    if (checked) {
      handleRights({ ...allChecked, [claimsData.claimsMasterId]: claimsData });
    } else {
      handleRights(omit(allChecked, [claimsData.claimsMasterId]));
    }
  }, [allChecked, handleRights]);

  return (
    <div className="form-container">
      {(data.sort((a, b) => a.claimsMasterName.localeCompare(b.claimsMasterName)) || [])?.map(
        (item) => (
          <div className="feild-row" key={item.claimsMasterId}>
            <div className="radio-wraper mng-radio-center">
              <CheckBox
                labelSpan="0"
                inputSpan="24"
                name={item.claimsMasterId}
                id={item.claimsMasterName}
                checked={!!allChecked[item.claimsMasterId]}
                onChange={(e) => handleOnChange(e, item)}
                disabled={isUserRightsViewOnly}
              >
                {item.claimsMasterName}
              </CheckBox>
            </div>
          </div>
        ))}
    </div>
  );
};

export default CheckBoxList;
