import React, {
  useEffect, useCallback, useState,
} from 'react';
import map from 'lodash/map';
import { Form } from 'antd';
import omit from 'lodash/omit';
import { Route } from 'react-router-dom';
import encode from '../../lib/encode';
import { enums, UiRoutes } from '../../lib/constants';
import useRedirect from '../../hooks/useRedirect';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { apiUrls } from '../../api/constants';

import SelectBox from '../../components/SelectBox';
import Notification from '../../components/Notification';
import ErrorMessages from '../../lib/errorMessages';

import WiredSelect from '../../wiredComponents/Select';
import WiredSelectWithoutTab from '../../wiredComponents/Select/selectBoxV2';

import EnumSelect from '../../wiredComponents/Enum/Select';

import './Scheduler.scss';
import { formatPhoneNumber, isInputExistInMap } from '../../lib/util';
import ActionIcons from '../../wiredComponents/ActionIcons';

const payerOptionRenderer = (options) => options.map((item) => ({
  value: item.value,
  item,
  name: (
    <div className="option payer-option-renderer" key={item.payerId}>
      <span title={item.name || item.payerId}>
        {item.name || item.payerId}
      </span>
      <span title={item.address}>
        {item.address}
      </span>
      <span title={formatPhoneNumber(item.phone)}>
        {formatPhoneNumber(item.phone)}
      </span>
    </div>
  ),
}));

const payerDropDownRenderer = (options = []) => {
  if (!Object.values(options).length) return <div />;
  return (
    <>
      <div className="option headerWrapper schedular-option-select">
        <span className="header">
          Name
        </span>
        <span className="header">
          Address
        </span>
        <span className="header">
          Phone
        </span>
      </div>
      <div>
        {options}
      </div>
    </>
  );
};

const handlePayerSearch = (input, option) => isInputExistInMap(input, ['payerName', 'address', 'phone'], option?.item?.item);

const SchedulerHeader = ({
  // doctors,
  schedulerHeaderHandler,
  // locations,
  // doctor,
  // location,
  setVisitType,
  visitType,
  viewType,
  labels,
  selectedDoctor,
  selectedPayer,
  setSelectedPayer,
  selectedLocation,
  setSelectedLocation,
  setTestType,
  setAllDoctors,
  setAllLocations,
  setAllVisitType,
  setAllTestType,
  testType,
  saveSchedulerFilters,
  selectedDate,
  // region,
  // setRegion,
  form,
  schedulerData,
  dataViewType,
}) => {
  const {
    replace: replaceUrl, path, query, query: { mode = dataViewType },
  } = useRedirect();

  const [localPayer, setLocalPayer] = useState(null);
  const [localDoctor, setLocalDoctor] = useState(null);
  const [localLocation, setLocalLocation] = useState(null);
  const [localRegion, setLocalRegion] = useState(null);
  const [visitTypeValidationStatus, setVisitTypeValidationStatus] = useState(query.visitType ? 'success' : 'warning');

  const replace = useCallback((data) => {
    replaceUrl(data);
    saveSchedulerFilters(data);
  }, [replaceUrl, saveSchedulerFilters]);

  // handles doctor select in single as well as multi select mode.
  // multi select mode returns selected doctor's indexes in an array which is directly assigned
  // single select returns selected doctor's index which is wrapped in an array and then assigned
  const handleSelectDoctor = useCallback((index, selectedDoctors) => {
    if (viewType === 'days' || viewType === 'months') {
      if (selectedDoctors && selectedDoctors.length < 6) {
        const doctors = map(selectedDoctors, (item) => item.item);
        setLocalDoctor(doctors);
        schedulerHeaderHandler('doctor')(doctors);
        replace({
          search: encode({ ...query, doctor: doctors.map((item) => item.providerId).join(',') }),
          pathname: path,
        });
      } else {
        form.setFieldsValue({ doctor: selectedDoctors.slice(0, 5).map((item) => item.value) });
        Notification({ message: ErrorMessages.SCHEDULER_CANNOT_SELECT_MORE_THAN_5 });
      }
    } else {
      const value = selectedDoctors?.item ? [selectedDoctors.item] : [];
      schedulerHeaderHandler('doctor')(value);
      setLocalDoctor(value);
    }
  }, [viewType, schedulerHeaderHandler, replace, query, path, form]);

  useEffect(() => {
    if (localDoctor && localDoctor.length) {
      form.setFieldsValue({
        doctor: localDoctor.map(({ providerId }) => parseInt(providerId, 10)),
      });
    } else {
      form.setFieldsValue({ doctor: [] });
    }
    if (localDoctor && localDoctor.length > 1 && !localLocation) {
      form.setFieldsValue({ location: 'Any' });
    }
  }, [form, localDoctor]);

  useUpdateEffect(() => {
    if ((viewType !== 'days' && viewType !== 'months') && localDoctor && localDoctor.length > 1) {
      const updatedDoctors = localDoctor.splice(0, 1);
      form.setFieldsValue({ doctor: updatedDoctors[0].value });
      handleSelectDoctor(0, { item: updatedDoctors[0] });
      schedulerHeaderHandler('doctor')([updatedDoctors[0]]);
      replace({
        search: encode({
          ...query,
          doctor: updatedDoctors[0].providerId,
        }),
        pathname: path,
      });
    }
  }, [viewType]);

  const handleLocationChange = useCallback((value, all = {}) => {
    setSelectedLocation(all.item);
    setLocalLocation(all.item);
    if (all?.item) {
      replace({
        search: encode({ ...query, location: all?.item?.locationId }),
        pathname: path,
      });
    } else {
      replace({
        search: encode(omit(query, ['location'])),
        pathname: path,
      });
    }
    if (localDoctor && localDoctor.length > 1) { handleSelectDoctor(0, []); }
  }, [handleSelectDoctor, localDoctor, path, query, replace, setSelectedLocation]);

  const handleRegionChange = useCallback((value, all = {}) => {
    setLocalRegion(all.item);
    if (all?.item) {
      replace({
        search: encode({ ...query, region: all?.item?.value }),
        pathname: path,
      });
    } else {
      replace({
        search: encode(omit(query, ['region'])),
        pathname: path,
      });
    }
    if (localDoctor && localDoctor.length > 1) { handleSelectDoctor(0, []); }
    setSelectedLocation(null);
    form.setFieldsValue({ location: undefined });
    setLocalLocation(null);
  }, [form, handleSelectDoctor, localDoctor, path, query, replace, setSelectedLocation]);

  const handleVisitTypeChange = useCallback((value, all = {}) => {
    setVisitType(all.item);
    if (all?.item?.visitName?.toLowerCase()?.includes('lily')) {
      form.setFieldsValue({ doctor: undefined });
    }
    if (all?.item?.visitTypeId) {
      setVisitTypeValidationStatus('success');
      replace({
        search: encode({ ...query, visitType: all?.item?.visitTypeId, mode }),
        pathname: path,
      });
    } else {
      setVisitTypeValidationStatus('error');
      replace({
        search: encode(omit({ ...query, mode }, ['visitType'])),
        pathname: path,
      });
    }
  }, [form, mode, path, query, replace, setVisitType]);

  const handleTestTypeChange = useCallback((value, all) => {
    const testTypes = all.map(({ item }) => item);
    setTestType(testTypes);
    if (testTypes.length) {
      replace({
        search: encode({ ...query, testType: testTypes.map((item) => item.examTypeId).join(','), mode }),
        pathname: path,
      });
    } else {
      replace({
        search: encode(omit({ ...query, mode }, ['testType'])),
        pathname: path,
      });
    }
  }, [mode, path, query, replace, setTestType]);

  const handlePayerChange = useCallback((value) => {
    setSelectedPayer(value);
    setSelectedLocation(null);
    setLocalPayer(value);
    setLocalLocation(null);
    handleSelectDoctor(0, []);
    form.setFieldsValue({ location: undefined });
    if (value) {
      replace({
        search: encode({ ...omit(query, ['location', 'doctor']), payer: value, mode }),
        pathname: path,
      });
    } else {
      replace({
        search: encode({ ...omit(query, ['location', 'doctor', 'payer']), mode }),
        pathname: path,
      });
    }
  }, [form, handleSelectDoctor, mode, path, query, replace, setSelectedLocation, setSelectedPayer]);

  const onClear = useCallback(() => {
    setSelectedPayer(null);
    setVisitType(null);
    setSelectedLocation(null);
    setTestType(null);
    setLocalPayer(null);
    setLocalLocation(null);
    handleSelectDoctor(0, []);
    schedulerHeaderHandler('doctor')([]);
    setVisitTypeValidationStatus('warning');
    form.resetFields();
    replace({
      search: encode({ mode }),
      pathname: path,
    });
  }, [form, handleSelectDoctor, mode, path, replace, schedulerHeaderHandler,
    setSelectedLocation, setSelectedPayer, setTestType, setVisitType]);

  useEffect(() => {
    if (selectedDoctor && selectedDoctor.length) {
      setLocalDoctor(selectedDoctor);
    }
  }, [selectedDoctor]);

  useEffect(() => {
    if (selectedLocation) {
      setLocalLocation(selectedLocation);
      form.setFieldsValue({
        location: selectedLocation.locationId,
      });
    }
  }, [selectedLocation, form]);

  useEffect(() => {
    if (selectedPayer) {
      setLocalPayer(selectedPayer);
      form.setFieldsValue({
        payer: selectedPayer,
      });
    }
  }, [selectedPayer, form]);

  useEffect(() => {
    if (testType) {
      form.setFieldsValue({
        examTypeId: testType.map((item) => item.examTypeId),
      });
    }
  }, [testType, form]);

  useEffect(() => {
    if (visitType) {
      form.setFieldsValue({
        visitType: visitType.visitTypeId,
      });
    }
  }, [visitType, form]);

  useEffect(() => {
    if (query?.region) {
      const regionId = parseInt(query.region, 10);
      setLocalRegion({ value: regionId });
      form.setFieldsValue({
        region: regionId,
      });
    }
  }, []);

  return (
    <div className="scheduler-header-main">
      <div className="left-side-header">
        <h2 className="bold">{labels.get('titles.schedular')}</h2>
      </div>
      <Route exact path={[UiRoutes.schedular, UiRoutes.schedularWithId]}>
        <div className="right-side-header fields-wrapper">
          <Form form={form}>
            <div className="input-wrap">
              <WiredSelect
                id="examType"
                name="examTypeId"
                url={apiUrls.GET_EXAM_TYPES}
                nameAccessor="examName"
                valueAccessor="examTypeId"
                labelSpan="0"
                inputSpan="24"
                startCaseOptions
                selectProps={{
                  mode: 'multiple',
                  maxTagCount: 1,
                  style: { minWidth: 200 },
                  showArrow: true,
                  dropdownMatchSelectWidth: false,
                }}
                params={{
                  SortBy: 'examName',
                  SortOrder: 'asc',
                }}
                onChange={handleTestTypeChange}
                onFetchComplete={setAllTestType}
                placeholder="Select Test/Procedure Type"
                Component={SelectBox}
              />
            </div>
            <div className="input-wrap schedular-visit-type">
              <WiredSelectWithoutTab
                id="visitType"
                name="visitType"
                url={apiUrls.GET_VISIT_TYPES}
                label={labels.get('labels.visitType')}
                nameAccessor="visitName"
                valueAccessor="visitTypeId"
                startCaseOptions
                Component={SelectBox}
                selectProps={{
                  style: { width: 'auto', maxWidth: '148px', minWidth: '148px' },
                  showSearch: true,
                  dropdownMatchSelectWidth: false,
                  setVisitTypeValidationStatus,
                }}
                params={{
                  SortBy: 'visitName',
                  SortOrder: 'asc',
                }}
                onChange={handleVisitTypeChange}
                onFetchComplete={setAllVisitType}
                placeholder="Select Visit Type "
                labelSpan="0"
                inputSpan="24"
                className="warp-visit-type"
                shouldShowWarningText={false}
                validateStatus={visitTypeValidationStatus}
                isStatCaseOptions={false}
              />
            </div>
            <div className="input-wrap">

              <WiredSelect
                id="payer"
                name="payer"
                url={apiUrls.CONTRACT_PAYER_DROPDOWN}
                nameAccessor="payerName"
                valueAccessor="payerId"
                startCaseOptions
                Component={SelectBox}
                selectProps={{
                  style: { minWidth: '120px', maxWidth: '120px' },
                  showSearch: true,
                  dropdownMatchSelectWidth: 350,
                  dropdownRender: payerDropDownRenderer,
                }}
                filterOption={handlePayerSearch}
                optionRenderer={payerOptionRenderer}
                onChange={handlePayerChange}
                placeholder="Select Payer"
              />
            </div>
            <div className="input-wrap">
              <EnumSelect
                id="region"
                name="region"
                placeholder={labels.get('placeholders.region')}
                component={SelectBox}
                selectProps={{
                  size: 'middle',
                  style: { minWidth: '120px', maxWidth: '124px' },
                  showSearch: true,
                  dropdownMatchSelectWidth: false,
                }}
                enumName={enums.REGION}
                onChange={handleRegionChange}
              />
            </div>
            <div className="input-wrap">
              <WiredSelectWithoutTab
                id="schedular-location"
                name="location"
                url={apiUrls.SCHEDULAR_LOCATION_DROPDOWN}
                placeholder={labels.get('placeholders.location')}
                nameAccessor="locationName"
                valueAccessor="locationId"
                Component={SelectBox}
                startCaseOptions
                onFetchComplete={setAllLocations}
                selectProps={{
                  size: 'middle',
                  style: { minWidth: '120px', maxWidth: '130px' },
                  showSearch: true,
                  dropdownMatchSelectWidth: 250,
                }}
                onChange={handleLocationChange}
                params={{
                  PayerId: localPayer,
                  ProviderId: localDoctor && localDoctor.length === 1
                    ? localDoctor[0]?.providerId
                    : null,
                  RegionId: localRegion?.value,
                  PageSize: 1000,
                }}
              />
            </div>
            <div className="input-wrap doctor">
              <WiredSelectWithoutTab
                id="schedular-doctor"
                name="doctor"
                url={apiUrls.SCHEDULAR_PROVIDER_DROPDOWN}
                placeholder={labels.get('placeholders.doctor')}
                nameAccessor="providerName"
                valueAccessor="providerId"
                Component={SelectBox}
                startCaseOptions
                selectProps={{
                  mode: viewType === 'days' || viewType === 'months' ? 'multiple' : undefined,
                  maxTagCount: 1,
                  style: { minWidth: '124px' },
                  showSearch: true,
                  dropdownMatchSelectWidth: 250,
                }}
                onChange={handleSelectDoctor}
                labelSpan="0"
                inputSpan="24"
                params={{
                  PayerId: localPayer,
                  LocationId: localLocation && localLocation.locationId,
                  PageSize: 1000,
                }}
                defaultSorting={false}
                onFetchComplete={setAllDoctors}
              />
            </div>
            <div className="schedular-action-btn">
              <button className="btn sm-btn btn-success btn-primary" onClick={onClear} type="button">{labels.get('labels.reset')}</button>
            </div>
            <div className="flex flex-auto align-center align-print-icon">
              <ActionIcons
                print
                printProps={{
                  superbill: true,
                  facesheet: true,
                  appointment: true,
                  locationIds: selectedLocation?.locationId,
                  providerIds: selectedDoctor?.map(({ providerId }) => providerId),
                  providerNames: selectedDoctor?.map(({ providerName }) => providerName),
                  selectedDate,
                  viewType,
                  url: apiUrls.GET_ALL_FACESHEET_DATA,
                  schedulerData,
                }}
                notesProps={{
                  page: 'appointments',
                }}
              />
            </div>
          </Form>
        </div>
      </Route>
    </div>
  );
};

export default SchedulerHeader;

