import React, { useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';

import useRedirect from '../../../hooks/useRedirect';

import { setCurrentTab as SetCurrentTab } from '../../../store/actions/genericAction';
import { setNewPath } from '../../../store/actions/navigation';

import getTabId from '../tabIdGenerator';

import './link.scss';
/**
 * @class Components/SavedNavigationBar/Link
 */
const Link = ({
  to,
  root,
  className,
  children,
  component: Component,
  params,
  saveNewLink,
  setCurrentTab,
  navigation,
  tabs,
  dataTestId,
  ...props
}) => {
  const {
    params: routerParams, push, replace, path, generatePath,
  } = useRedirect();
  const navigate = useCallback((event) => {
    event.preventDefault();
    const dummyTabId = 'tabId';
    let generatedPath = generatePath(to, { ...routerParams, ...params, tabId: dummyTabId });
    const tabId = getTabId(generatedPath);
    const savedNavigation = navigation && navigation.get(to);
    const alreadyOpenedTabData = savedNavigation && savedNavigation.get(tabId);
    if (alreadyOpenedTabData && alreadyOpenedTabData.path) {
      generatedPath = alreadyOpenedTabData.path;
    } else {
      generatedPath = generatedPath.replace('tabId', tabId);
    }
    setCurrentTab(tabId);
    saveNewLink({
      id: to,
      tabId,
      path: generatedPath,
      params: { ...params, ...routerParams },
      tabName: tabs.get('tabModuleName'),
      parentInfo: { path: generatePath(path, routerParams), id: path, params: routerParams },
      previousTabId: routerParams.tabId,
    });
    if (to.indexOf(path) === 0) {
      replace(generatedPath);
    } else {
      push(generatedPath);
    }
  }, [generatePath,
    navigation,
    params,
    path,
    push,
    replace,
    routerParams,
    saveNewLink,
    setCurrentTab,
    tabs,
    to]);
  if (Component === 'a') {
    return (<RouterLink data-testid={dataTestId} to={to} {...props}>{children}</RouterLink>);
  }
  return (
    <Component data-testid={dataTestId} className={classNames('custom-link', className)} onClick={navigate}>{children}</Component>
  );
};

Link.propTypes = {
  ...RouterLink.propTypes,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  root: PropTypes.bool,
};

Link.defaultProps = {
  ...RouterLink.defaultProps,
  component: 'span',
  root: false,
};

const mapStateToProps = ({ navigation, tabs }) => ({
  navigation,
  tabs,
});

const mapDispatchToProps = (dispatch) => ({
  saveNewLink: (linkDetails) => dispatch(setNewPath(linkDetails)),
  setCurrentTab: (tabId) => dispatch(SetCurrentTab(tabId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Link);
