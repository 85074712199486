import React, { useState, useEffect } from 'react';
import { UiRoutes, listId } from '../../../lib/constants';

import NewEncounter from '../../../wiredComponents/AddNewEncounter';
import useRedirect from '../../../hooks/useRedirect';
import SavedNavigationBar from '../../../components/SavedNavigationBar';
import ActionIcons from '../../../wiredComponents/ActionIcons';
import useCRUD from '../../../hooks/useCRUD';
import { apiUrls } from '../../../api/constants';

const AddBillingEncounter = () => {
  const { params } = useRedirect();
  const [patientDetails, setPatientDetails] = useState(null);

  const [patientList, , , getPatientList] = useCRUD({
    url: apiUrls.GET_PATIENT,
    id: listId.SCHEDULAR_PATIENTS_LIST,
    type: 'read',
  });

  useEffect(() => {
    getPatientList({
      ExcludeInactivePatients: true,
      SortBy: 'FirstName',
      SortOrder: 'asc',
    });
  }, []);

  useEffect(() => {
    if (patientList?.result?.length) {
      const patientData = patientList.result
        .filter((patient) => patient.patientId === Number(params.id));
      setPatientDetails(patientData[0]);
    }
  }, [patientList]);

  return (
    <SavedNavigationBar
      listName="Billing List"
      parentPath={UiRoutes.billing}
      title={(<h1>Billing</h1>)}
      rightChildren={(
        <ActionIcons
          userNotes
          edit
          demoGraphic
          documentsWithDropDown
          insurance
          patient={{
            patientId: params.id,
            patientFirstName: patientDetails?.fullName,
          }}
          patientId={params?.id}
          notesProps={{
            page: 'encounters',
          }}
        />
)}
    >
      {({ closeTab }) => (
        <div className="main-form-container">
          <NewEncounter
            goBackPath={UiRoutes.billing}
            closeTab={closeTab}
          />
        </div>
      )}
    </SavedNavigationBar>
  );
};

export default AddBillingEncounter;
