import { useEffect } from 'react';

const TableComponent = ({ Component, reFetch, handleReFetch }) => {
  useEffect(() => {
    handleReFetch(reFetch);
  }, []);
  return Component;
};

export default TableComponent;
