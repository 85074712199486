import React, { useCallback } from 'react';
import { Dropdown, Menu } from 'antd';

import Icon from '../../../../../components/Icon';

import iconNames from '../../../../../lib/iconNames';

const ErrorPopOver = ({
  errorList = [],
  iconName = iconNames.warningOutlined,
  trigger = ['click'],
  color = 'yellow',
}) => {
  const menu = useCallback(() => (
    <Menu className="remittance-error-list">
      {errorList?.length ? errorList?.map((errorItem) => (
        <li className="remittance-error-list-item" key={errorItem?.errorId}>
          {`${errorItem?.errorCode}: ${errorItem?.error}`}
        </li>
      )) : <li />}
    </Menu>
  ), [errorList]);

  return (
    <Dropdown
      overlay={menu}
      trigger={trigger}
      arrow={{ pointAtCenter: true }}
    >
      <Icon name={iconName} color={color} />
    </Dropdown>
  );
};

export default ErrorPopOver;
