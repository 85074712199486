const SuccessMessages = {
  PROVIDER_SAVE_SUCCESSFULLY: 'Provider has been added successfully.',
  PROVIDER_UPDATE_SUCCESSFULLY: 'Provider has been updated successfully.',
  USER_SAVED_SUCCESSFULLY: 'User has been added successfully.',
  USER_UPDATED_SUCCESSFULLY: 'User has been updated successfully.',
  PATIENT_UPDATED_SUCCESSFULLY: 'Patient has been updated successfully.',
  DOCUMENT_UPDATED_SUCCESSFULLY: 'Document has been updated successfully.',
  PATIENT_SAVED_SUCCESSFULLY: 'Patient has been added successfully.',
  LOCATION_UPDATED_SUCCESSFULLY: 'Location has been updated successfully.',
  LOCATION_SAVED_SUCCESSFULLY: 'Location has been added successfully.',
  EXAM_ROOM_UPDATED_SUCCESSFULLY: 'Exam room has been updated successfully.',
  EXAM_ROOM_SAVED_SUCCESSFULLY: 'Exam room has been added successfully.',
  BILL_SAVED_SUCCESSFULLY: 'Billing has been added successfully.',
  BILL_UPDATED_SUCCESSFULLY: 'Billing has been updated successfully.',
  E_PRESCRIPTION_SAVED_SUCCESSFULLY: 'E-Prescription has been added successfully.',
  E_PRESCRIPTION_UPDATED_SUCCESSFULLY: 'E-Prescription updated successfully.',
  PAYER_SAVED_SUCCESSFULLY: 'Payer has been added successfully.',
  PAYER_UPDATED_SUCCESSFULLY: 'Payer has been updated successfully.',
  FEE_PREFERENCES_GENERAL_UPDATED_SUCCESSFULLY: 'Fee preferences has been updated successfully.',
  POLICY_UPDATE_SUCCESSFULLY: 'Policy updated successfully.',
  PAYER_REQUESTED_SUCCESSFULLY: 'Insurance provider has been requested successfully.',
  MEDICATION_SAVED_SUCCESSFULLY: 'Medication has been added successfully.',
  CPT_SAVED_SUCCESSFULLY: 'CPT has been added successfully.',
  CASE_SAVED_SUCCESSFULLY: 'Case has been added successfully.',
  CASE_UPDATED_SUCCESSFULLY: 'Case has been updated successfully.',
  USER_PROFILE_SAVED_SUCCESSFULLY: 'User profile has been added successfully.',
  CREDENTIALS_SUCCESSFULLY: 'Credentials has been added successfully.',
  CREDENTIALS_UPDATED_SUCCESSFULLY: 'Credentials has been updated successfully.',
  AUTHORIZATION_UPDATED_SUCCESSFULLY: 'Authorization updated successfully.',
  STATEMENT_ADDED_SUCCESSFULLY: 'Statement has been added successfully.',
  STATEMENT_UPDATED_SUCCESSFULLY: 'Statement has been updated successfully.',
  ADD_CPT_ICD_MAPPING: 'CPT ICD mapping has been added successfully.',
  AUTHORIZATION_SAVED_SUCCESSFULLY: 'Authorization has been added successfully.',
  USER_RIGHTS_UPDATE_SUCCESSFULLY: 'User rights  has been updated successfully.',
  DELETE_PATIENT_DOCUMENT_SUCCESSFUL: 'Patient document has been successfully deleted.',
  DELETE_MASTER_DATA_SUCCESSFUL: 'Master data has been successfully deleted.',
  PAYER_HOLD_SAVED_SUCCESSFULLY: 'Payer hold has been added successfully.',
  PAYER_HOLD_UPDATED_SUCCESSFULLY: 'Payer hold updated successfully.',
  PAYER_SUCCESSFULLY_ADDED: 'Payer has been added successfully.',
  DELETE_ICD_CODE_SUCCESSFUL: 'ICD Code has been successfully deleted.',
  VISIT_TYPE_SAVED_SUCCESSFULLY: 'Visit Type  has been added successfully',
  VISIT_TYPE_UPDATED_SUCCESSFULLY: 'Visit Type has been updated successfully',
  TEST_TYPE_SAVED_SUCCESSFULLY: 'Test Type  has been added successfully',
  TEST_TYPE_UPDATED_SUCCESSFULLY: 'Test Type has been updated successfully',
  CANCELLATION_REASON_UPDATED_SUCCESSFULLY: 'Cancellation reason has been updated successfully',
  CANCELLATION_REASON_ADDED_SUCCESSFULLY: 'Cancellation reason has been added successfully',
  RESOURCE_UPDATED_SUCCESSFULLY: 'Resource has been updated successfully',
  RESOURCE_ADDED_SUCCESSFULLY: 'Resource has been added successfully',
  TEMPLATE_SAVED_SUCCESSFULLY: 'Template has been added successfully.',
  TEMPLATE_UPDATED_SUCCESSFULLY: 'Template has been updated successfully.',
  CONTRACT_SAVE_SUCCESSFULLY: 'Contract has been added successfully.',
  PERSON_SAVED_SUCCESSFULLY: 'Person has been added successfully.',
  PERSON_UPDATED_SUCCESSFULLY: 'Person has been updated successfully.',
  INSURANCE_PROFILE_ADDED_SUCCESSFULLY: 'Insurance Profile has been saved successfully',
  ENCOUNTER_ADDED_SUCCESSFULLY: 'Encounter has been saved successfully',
  ENCOUNTER_UPDATED_SUCCESSFULLY: 'Encounter has been updated successfully',
  INSURANCE_PROFILE_UPDATED_SUCCESSFULLY: 'Insurance Profile has been updated successfully',
  APPOINTMENT_ADDED_SUCCESSFULLY: 'Appointment has been saved successfully',
  APPOINTMENT_UPDATED_SUCCESSFULLY: 'Appointment has been updated successfully',
  PASSWORD_RESET_SUCCESSFULLY: 'Password has been reset successfully',
  CONTRACT_UPDATED_SUCCESSFULLY: 'Contract has been updated successfully.',
  INSURANCE_POLICY_ADDED_SUCCESSFULLY: 'Insurance Policy has been saved successfully',
  APPOINTMENT_CANCELLED_SUCCESSFULLY: 'Appointment has been cancelled successfully',
  EMAIL_SENT_SUCCESSFULLY: 'Please check your email to reset password',
  BLOCKOUT_SAVED_SUCCESSFULLY: 'Blockout has been added successfully.',
  BLOCKOUT_UPDATED_SUCCESSFULLY: 'Blockout has been updated successfully.',
  CONTRACT_TERM_SAVE_SUCCESSFULLY: 'Terms has been added successfully',
  CONTRACT_TERM_UPDATED_SUCCESSFULLY: 'Terms has been updated successfully',
  CC_HPI_SAVED_SUCCESSFULLY: 'CC/HPI has been added successfully',
  CC_HPI_UPDATED_SUCCESSFULLY: 'CC/HPI has been updated successfully',
  ALLERGY_SUCCESSFULLY_ADDED: 'Allergy has been added successfully',
  ALLERGY_SUCCESSFULLY_DELETED: 'Allergy has been deleted successfully',
  ALL_ALLERGY_SUCCESSFULLY_DELETED: 'All allergy has been deleted successfully',
  EXAM_VISION_ADDED_SUCCESSFULLY: 'Vision has been added successfully',
  EXAM_PRESSURE_ADDED_SUCCESSFULLY: 'Pressure has been added successfully',
  EXCEPTION_ADDED_SUCCESSFULLY: 'Exception has been added successfully.',
  EXCEPTION_UPDATED_SUCCESSFULLY: 'Exception has been updated successfully.',
  EXCEPTION_DELETED_SUCCESSFULLY: 'Exception has been deleted successfully',
  EXCEPTIONS_ADDED_SUCCESSFULLY: 'Exceptions has been added successfully.',
  CHANGES_ADDED_SUCCESSFULLY: 'Changes has been added successfully.',
  CHANGES_UPDATED_SUCCESSFULLY: 'Changes has been updated successfully.',
  BASICS_SUCCESSFULLY_ADDED: 'Basics has been added successfully',
  FAVOURITE_ADDED_SUCCESSFULLY: 'Favourite has been added successfully',
  FOLDER_ADDED_SUCCESSFULLY: 'Folder has been added successfully',
  FAVOURITE_MOVE_SUCCESSFULLY: 'Favourites has been moved successfully',
  FAVOURITE_DELETED_SUCCESSFULLY: 'Favourites has been deleted successfully',
  PATIENT_UPDATE_REFRACTION_SUCCESSFUL: 'Refraction has been updated successfully.',
  PATIENT_DELETE_REFRACTION_SUCCESSFUL: 'Refraction has been deleted successfully.',
  MOTILITY_ADDED_SUCCESSFULLY: 'Motility has been added successfully',
  MOTILITY_UPDATE_SUCCESSFULLY: 'Motility has been updated successfully.',
  FAVOURITE_UPDATE_SUCCESSFUL: 'Favourite has been updated successfully.',
  EXCEL_IMPORTED_SUCCESSFUL: 'Excel File has been imported successfully.',
  PROCEDURE_CREATED_SUCCESSFULLY: 'Procedure has been created successfully.',
  PROCEDURE_UPDATED_SUCCESSFULLY: 'Procedure has been updated successfully.',
  ASSESSMENT_ADDED_SUCCESSFULLY: 'Assessment has been added successfully.',
  MODIFIER_ADDED_SUCCESSFULLY: 'Modifier has been added successfully',
  MODIFIER_UPDATED_SUCCESSFULLY: 'Modifier has been updated successfully',
  MODIFIER_DELETED_SUCCESSFULLY: 'Modifier has been deleted successfully',
  OUTSIDE_CREATED_SUCCESSFULLY: 'Outside test has been created successfully.',
  OUTSIDE_TEST_DELETED_SUCCESSFULLY: 'Outside test has been deleted successfully.',
  DIAGNOSIS_DELETED_SUCCESSFULLY: 'Diagnosis has been deleted successfully',
  DX_ADDED_SUCCESSFULLY: 'Dx has been added successfully',
  DIAGNOSIS_FAVOURITE_APPLIED_SUCCESSFULLY: 'Diagnosis favorite has been applied successfully',
  PROCEDURE_CODE_MOVED_SUCCESSFULLY: 'Procedure code has been moved successfully',
  PAYER_IMPORTED_SUCCESSFULLY: 'Payer Imported Successfully',
  ICD_IMPORTED_SUCCESSFULLY: 'ICD Imported Successfully',
  CPT_IMPORTED_SUCCESSFULLY: 'CPT Imported Successfully',
  IMAGE_UPLOAD_SUCCESSFULLY: 'Images have been uploaded successfully',
  TEST_TYPE_UPDATED: 'Images test type has been updated successfully',
  FOLLOW_UP_ADDED_SUCCESSFULLY: 'Follow-up has been added successfully.',
  FOLLOW_UP_UPDATED_SUCCESSFULLY: 'Follow-up has been updated successfully',
  ADDRESS_BOOK_SAVED_SUCCESSFULLY: 'Address has been added successfully',
  ADDRESS_BOOK_UPDATED_SUCCESSFULLY: 'Address book has been updated successfully',
  PROVIDER_ADDED_IN_CARE_TEAM_SUCCESSFULLY: 'Provider has been added successfully into patient care team',
  PROVIDER_UPDATED_IN_CARE_TEAM_SUCCESSFULLY: 'Provider has been updated successfully into patient care team',
  PROVIDER_DELETED_IN_CARE_TEAM_SUCCESSFULLY: 'Provider has been deleted successfully from patient care team',
  DELETE_CARE_TEAM_SUCCESSFUL: 'CareTeam has been deleted successfully.',
  GOAL_ADDED_SUCCESSFULLY: 'Goal has been added successfully.',
  DRAWING_DELETED_SUCCESSFULLY: 'Tab has been deleted successfully',
  DRAWING_UPDATED_SUCCESSFULLY: 'Tab has been updated successfully',
  DRAWING_CREATED_SUCCESSFULLY: 'Tab has been added successfully',
  ADD_PROVIDER_CARE_TEAM_SUCCESSFULLY: 'Provider has been added successfully',
  ORDER_SET_SAVED_SUCCESSFULLY: 'Order set has been saved successfully',
  ORDER_SET_UPDATED_SUCCESSFULLY: 'Order set has been updated successfully',
  ASSEMBLE_CLAIMS_SUCCESSFULLY: 'Claims has been assembled successfully',
  SIGN_AND_BILL_SUCCESSFULLY: 'Sign and Bill has been saved successfully',
  PAYMENT_RESPONSIBILITY_CHANGED_SUCCESSFULLY: 'Payment responsibility move to patient successfully',
  INSURANCE_RESPONSIBILITY_CHANGED_SUCCESSFULLY: 'Insurance responsibility changed successfully',
  MASTER_DATA_ADDED_SUCCESSFULLY: 'Master data has been added successfully.',
  MASTER_DATA_UPDATED_SUCCESSFULLY: 'Master data has been updated successfully.',
  INCIDENT_ADDED_SUCCESSFULLY: 'Incident has been saved successfully',
  CLAIM_ADDED_SUCCESSFULLY: 'Claim has been added successfully',
  REMITTANCE_BATCH_ADDED_SUCCESSFULLY: 'Batch has been added successfully',
  TASK_ADDED_SUCCESSFULLY: 'Task has been added successfully',
  TASK_UPDATED_SUCCESSFULLY: 'Task has been updated successfully',
  INSURANCE_PAYMENT_SAVED_SUCCESSFULLY: 'Payment has been added successfully',
  TASKS_ASSIGN_SUCCESSFULLY: 'Tasks has been assigned successfully',
  MARKED_AS_READ_SUCCESSFULLY: 'Marked as read successfully',
  MARKED_AS_UNREAD_SUCCESSFULLY: 'Marked as unread successfully',
  COMMENT_ADDED_SUCCESSFULLY: 'Comment has been added successfully',
  COOLTONE_ADDED_SUCCESSFULLY: 'Cooltone has been added successfully',
  ULTHERAPY_ADDED_SUCCESSFULLY: 'UlTherapy has been added successfully',
  CUTERA_ADDED_SUCCESSFULLY: 'Cutera laser has been added successfully',
  COOL_SCULPT_ADDED_SUCCESSFULLY: 'Cool Sculpt has been added successfully',
  COOL_SCULPT_UPDATED_SUCCESSFULLY: 'Cool Sculpt has been updated successfully',
  CLAIMS_FOLLOWUP_UPDATED_SUCCESSFULLY: 'Followup has been updated successfully',
  SUBMIT_CLAIM_SUCCESSFULLY: 'Claim has been submitted successfully',
  INSURANCE_PAYMENT_CHECK_COMPLETE: 'Insurance Payment has been checked successfully',
  PAYER_AGING_OVERRIDE_ADDED_SUCCESSFULLY: 'Payer Aging has been added successfully',
  PAYER_AGING_OVERRIDE_UPDATED_SUCCESSFULLY: 'Payer Aging has been updated successfully',
  HPI_ADDED_SUCCESSFULLY: 'CC/HPI has been added successfully',
  PATIENT_PAYMENT_CHECK_COMPLETE: 'Patient Payment has been checked successfully',
  WORKLIST_ADDED_SUCCESSFULLY: 'Worklist has been added successfully',
  PAYMENT_ADDED_SUCCESSFULLY: 'Payment has been added successfully',
  WORK_LIST_ASSIGNED_SUCCESSFULLY: 'Work list has been assigned successfully',
  INSURANCE_PAYMENT_ADDED_SUCCESSFULLY: 'Insurance payment has been added successfully',
  CONFIRMATION_ADDED_SUCCESSFULLY: 'Confirmation has been added successfully',
  BATCH_DELETED_SUCCESSFULLY: 'Batch has been deleted successfully',
  PROMOTION_ADDED_SUCCESSFULLY: 'Promotion has been added successfully',
  PROMOTION_UPDATED_SUCCESSFULLY: 'Promotion has been updated successfully',
  INVOICE_ADDED_SUCCESSFULLY: 'Invoice has been added successfully',
  INSURANCE_ADJUSTMENT_ADDED_SUCCESSFULLY: 'Insurance adjustment has been added successfully.',
  PATIENT_ADJUSTMENT_ADDED_SUCCESSFULLY: 'Patient adjustment has been added successfully.',
  INVOICE_POSTED_SUCCESSFULLY: 'Invoice has been posted successfully',
  INVOICE_DELETED_SUCCESSFULLY: 'Invoice has been deleted successfully',
  PRODUCT_ADDED_SUCCESSFULLY: 'Product has been added successfully',
  PRODUCT_UPDATED_SUCCESSFULLY: 'Product has been updated successfully',
  MARK_COMPLETE_WORKLIST_SUCCESSFULLY: 'Worklist has been marked completed successfully',
  INVENTORY_ITEM_DELETED_SUCCESSFULLY: 'Inventory item has been deleted successfully',
  INVENTORY_ITEM_ADDED_SUCCESSFULLY: 'Inventory item has been added successfully',
  PRODUCT_ADDED_TO_INVENTORY: 'Product has been added to inventory',
  DISCARD_INVENTORY_ITEM_SUCCESSFULLY: 'Inventory has been discarded successfully',
  RECALL_INVENTORY_ITEM_SUCCESSFULLY: 'Inventory has been recalled successfully',
  EXPORT_INVENTORY_ITEM_SUCCESSFULLY: 'Inventory has been exported successfully',
  EXPORT_INVENTORY_RECEIPT_ITEM_SUCCESSFULLY: 'Inventory receipts has been exported successfully',
  EXPORT_TRANSFER_INVENTORY_ITEM_SUCCESSFULLY: 'Inventory transfers has been exported successfully',
  EXPORT_DATA_PRODUCT_LIST_SUCCESSFULLY: 'Product list has been exported successfully',
  ITEM_SENT_SUCCESSFULLY: 'Inventory items has been sent successfully',
  BARCODE_DETAILS_UPDATED_SUCCESSFULLY: 'Barcode details has been updated successfully',
  COLLECTED_SUCCESSFULLY: 'Collected successfully',
  BATCH_ITEM_DELETED_SUCCESSFULLY: 'Batch item has been deleted successfully',
  BATCH_POSTED_TO_INVENTORY_SUCCESSFULLY: 'Batch has been posted to inventory successfully',
  FILE_UPLOADED_SUCCESSFULLY: 'File has been uploaded successfully',
  MANUFACTURER_ADDED_SUCCESSFULLY: 'Manufacturer has been added successfully',
  REORDER_POINT_EXCEPTION_REQUESTED_SUCCESSFULLY: 'Re-Order Point Exception requested successfully',
  PRICE_COST_EXCEPTION_REQUESTED_SUCCESSFULLY: 'Price and Cost Exception requested successfully',
  ADJUSTMENT_UPDATED_SUCCESSFULLY: 'Adjustment has been updated successfully',
  MANUFACTURER_UPDATED_SUCCESSFULLY: 'Manufacturer has been updated successfully',
  MANUFACTURER_DELETED_SUCCESSFULLY: 'Manufacturer has been deleted successfully',
  PROMOTION_DELETED_SUCCESSFULLY: 'Promotion has been deleted successfully',
  TEMPLATE_DELETED_SUCCESSFULLY: 'Template has been deleted successfully',
  HEADER_SAVED_SUCCESSFULLY: 'Header has been saved successfully',
  HEADER_UPDATED_SUCCESSFULLY: 'Header has been updated successfully',
  FOOTER_SAVED_SUCCESSFULLY: 'Footer has been saved successfully',
  FOOTER_UPDATED_SUCCESSFULLY: 'Footer has been updated successfully',
  PRODUCT_DELETED_SUCCESSFULLY: 'Product has been deleted successfully',
  SAVE_TEMPLATE: 'Template has been saved successfully',
  UPDATE_TEMPLATE: 'Template has been updated successfully',
  SAVE_BUSINESS_ENTITY: 'Entity has been saved successfully',
  BE_CREDENTIALS_ADDED_SUCCESSFULLY: 'Credential has been saved successfully',
  BE_CREDENTIALS_UPDATED_SUCCESSFULLY: 'Credential has been updated successfully',
  BUSINESS_ENTITY_STATEMENTS_SAVED_SUCCESSFULLY: 'Statement has been added successfully',
  SAVE_BUSINESS_REMITTANCE_ENTITY: 'Remittance has been saved successfully',
  BE_GENERAL_UPDATED_SUCCESSFULLY: 'General has been updated successfully',
  BE_FEE_SCHEDULE_UPLOADED_SUCCESSFULLY: 'Fee schedule upload is running in background.',
  SAVE_BUSINESS_PATIENT_CLAIMS: 'Patient Claims has been saved successfully',
  ADD_BUSINESS_ENTITY_CONTACT_TERMS: 'Contact terms has been saved successfully',
  SAVE_BUSINESS_PATIENT_BASICS: 'Patient Basics has been saved successfully',
  BE_BILLING_BASIC_ADDED_SUCCESSFULLY: 'Billing basic has been added successfully',
  CARE_TEAM_LETTER_SENT_SUCCESSFULLY: 'Care team letter has been sent successfully',
  CHART_NUMBER_SAVED_SUCCESSFULLY: 'Chart Number has been saved successfully',
  SAVE_BUSINESS_ENTITY_MODIFIERS: 'Modifiers has been saved successfully',
  CCT_ADDED_SUCCESSFULLY: 'CCT has been added successfully',
  CCT_UPDATED_SUCCESSFULLY: 'CCT has been updated successfully',
  BUSINESS_ENTITY_BILLING_NDC_ADDED_SUCCESSFULLY: 'Billing NDC has been added successfully',
  BUSINESS_ENTITY_BILLING_NDC_UPDATED_SUCCESSFULLY: 'Billing NDC has been updated successfully',
  BUSINESS_ENTITY_SCHEDULE_ADDED_SUCCESSFULLY: 'Schedule has been added successfully',
  BUSINESS_ENTITY_SCHEDULE_UPDATED_SUCCESSFULLY: 'Schedule has been updated successfully',
  ENCOUNTER_IMAGE_DELETED_SUCCESSFULLY: 'Image has been deleted successfully',
  PROFILE_PIC_UPLOADED_SUCCESSFULLY: 'Profile pic has been uploaded successfully',
  BUSINESS_ENTITY_TASK_ADDED_SUCCESSFULLY: 'Task has been saved successfully',
  BUSINESS_ENTITY_SUB_TASK_ADDED_SUCCESSFULLY: 'Sub Task has been saved successfully',
  BUSINESS_ENTITY_SUB_TASK_UPDATED_SUCCESSFULLY: 'Sub Task has been updated successfully',
  BUSINESS_ENTITY_TASK_UPDATED_SUCCESSFULLY: 'Task has been updated successfully',
  TASK_USER_SAVED_SUCCESSFULLY: 'Task Users has been saved successfully',
  TASK_USER_UPDATED_SUCCESSFULLY: 'Task Users has been updated successfully',
  ERROR_OVERRIDE_SUCCESSFULLY: 'Error override successfully',
  FavoritesShared: 'Favorites has been shared successfully',
  SYNONYM_SAVED_SUCCESSFULLY: 'Synonym has been saved successfully',
  TASK_USER_REMOVED_SUCCESSFULLY: 'Task user has been removed successfully',
  TASK_CLOSED_SUCCESSFULLY: 'Tasks have been closed successfully',
  REPORT_TEMPLATE_SAVED_SUCCESSFULLY: 'Report template has been saved successfully',
  REPORT_TEMPLATE_SAVED_UPDATED: 'Report template has been saved updated successfully',
  MAIL_SENT_SUCCESSFULLY: 'E-Mail has been sent successfully',
  CLAIM_COPIED_SUCCESSFULLY: 'Claim number copied successfully.',
  PROCEDURE_DELETED_SUCCESSFULLY: 'Procedure has been deleted successfully',
  PROOF_OF_FILLING_SUCCESSFULLY: 'Proof of filling has been downloaded successfully',
  CHECK_ELIGIBILITY_SUCCESSFULLY: 'Check eligibility has been successfully',
  DELETE_SHARED_REPORT: 'Shared report has been deleted successfully',
  SHARED_FILE_SAVED_SUCCESSFULLY: 'Shared file has been saved successfully',
  INSURANCE_REFUND_ADDED_SUCCESSFULLY: 'Insurance refund has been added successfully',
  PATIENT_REFUND_SAVED_SUCCESSFULLY: 'Patient refund has been saved successfully',
  PAYMENT_REVERSED_SUCCESSFULLY: 'Payment has been reversed successfully',
  TRANSACTION_DELETED_SUCCESSFULLY: 'Simple charge has been deleted successfully',
  STATEMENT_STATUS_HOLD: 'Statement status changed to hold',
  STATEMENT_STATUS_UN_HOLD: 'Statement status changed to unhold',
  STATEMENT_STATUS_SUBMIT: 'Statement has been submitted successfully',
  STATEMENT_STATUS_ASSEMBLE: 'Statement has been assembled successfully',
  CHARGE_RE_ASSEMBLED_SUCCESSFULLY: 'Charge has been re-assembled successfully',
  REPORT_DOWNLOADED_SUCCESSFULLY: 'Report has been downloaded successfully',
  ICD_ADDED_SUCCESSFULLY: 'ICD has been added successfully',
  CPT_ADDED_SUCCESSFULLY: 'CPT has been added successfully',
  CPT_ICD_IMPORTED_SUCCESSFULLY: 'CPT ICD mapping imported successfully',
  PROCEDURE_SET_APPLIED_SUCCESSFULLY: 'Procedure set has been applied successfully',
  ORDER_SET_APPLIED_SUCCESSFULLY: 'Order set has been applied successfully',
  DIAGNOSIS_FAVOURITE_DELETED_SUCCESSFULLY: 'Diagnosis favourite has been deleted successfully',
  COPY_LAST_ENCOUNTER_SUCCESS: 'Last Encounter\'s SuperBill questionaires apply successfully',
  ENCOUNTER_ASSOCIATED_SUCCESSFULLY: 'Encounter has been associated with previous encounter successfully',
  DELETE_CLAIMS_SUCCESSFULLY: 'Claim has been deleted successfully',
  INSURANCE_PROFILE_TYPE_MOVED_SUCCESSFULLY: 'Insurance profile type has been moved successfully',
  CREATED_VERSION_SUCCESSFULLY: 'Encounter version has been created successfully.',
  ENCOUNTER_SAVED_SUCCESSFULLY: 'Encounter has been saved successfully',
  NOTE_ADDED_SUCCESSFULLY: 'Note has been added successfully',
  NOTE_UPDATED_SUCCESSFULLY: 'Note has been updated successfully',
  CONSENT_FORM_SAVED_SUCCESSFULLY: 'Form has been saved successfully',
  MASS_REBILL_SUCCESSFULLY: 'Mass rebill successful',
  PROCEDURE_SET_COPY_FAVORITE_SUCCESSFULLY: 'Procedure Sets has been copied successfully',
  ORDER_SET_COPY_FAVORITE_SUCCESSFULLY: 'Order Sets has been copied successfully',
  REPORT_DELETED_SUCCESSFULLY: 'Report has been deleted successfully',
  REMITTANCE_BATCH_UPDATED_SUCCESSFULLY: 'Batch has been updated successfully',
  SCHEDULED_REPORT_SUCCESSFULLY: 'Report has been scheduled successfully',
  BUNDLE_CREATED_SUCCESSFULLY: 'Bundle has been created successfully',
  BUNDLE_APPLIED_SUCCESSFULLY: 'Bundle has been applied successfully',
  PATIENT_PAYMENT_ADDED_SUCCESSFULLY: 'Patient payment has been added successfully',
  BATCH_COMPLETED_SUCCESSFULLY: 'The Batch has been processed successfully',
  PAYMENT_SAVED_SUCCESSFULLY: 'Check details has been saved successfully',
  POST_BATCH_FROM_OUTBOX_TO_POSTED: 'Remittance batch has been moved from outbox to posted successfully',
  COPY_VITALS_DETAILS_SUCCESSFULLY: 'Vitals has been copied successfully',
  WORKLIST_EDITED_SUCCESSFULLY: 'Worklist has been edited successfully',
  SKIP_ERROR_VALIDATION: 'Skip error validation has been applied successfully',
  USE_ADJUSTMENT_VALIDATION: 'Use Adjustment Validation applied successfully',
  SKIP_ADJUSTMENT_VALIDATION: 'Skip Adjustment Validation applied successfully',
  USE_DUPLICATE_VALIDATION: 'Use Duplicate Validation applied successfully',
  SKIP_DUPLICATE_VALIDATION: 'Skip Duplicate Validation applied successfully',
  REMITTANCE_ERROR_TRANSACTION_DELETED_SUCCESSFULLY: 'Transaction has been deleted successfully',
  REMITTANCE_ERROR_TRANSACTION_TO_WORKLIST: 'Transaction has been added to worklist successfully',
  MARK_AS_BLANK_PAGE_SUCCESSFULLY: 'Page has been marked as blank page successfully',
  UNMARK_AS_BLANK_PAGE_SUCCESSFULLY: 'Page has been unmarked as blank page successfully',
  REMITTANCE_BATCH_DELETED_SUCCESSFULLY: 'Remittance batch has been deleted successfully',
  // Akshat Garg - 10/7/24 - adding new success message for batch moved to unbatched
  REMITTANCE_BATCH_MOVED_SUCCESSFULLY: 'Remittance batch has been moved to unbatched successfully',
  PRIMARY_INSURANCE_CHANGED_SUCCESSFULLY: 'Primary Insurance profile has been changed',
  EDUCATION_MATERIAL_PRINTED_SUCCESSFULLY: 'MIPS education material has been printed successfully',
  CHECK_DELETED_SUCCESSFULLY: 'Check has been deleted successfully',
  PAYMENT_DELETED_SUCCESSFULLY: 'Payment has been deleted successfully',
  DOCUMENT_ADDED_TO_FAX_SUCCESSFULLY: 'Documents has been added to fax successfully',
  REMITTANCE_ERROR_TRANSACTION_UPDATED_SUCCESSFULLY: 'Transaction updated successfully',
  FAX_DOCUMENT_DELETE_SUCCESSFULLY: 'Fax document has been delete successfully',
  REORDER_FAX_DOCUMENT: 'Fax document has been reorder successfully.',
  PLAN_TYPE_SAVED_SUCCESSFULLY: 'Plan type has been saved successfully',
  FAX_DOCUMENT_SENT_SUCCESSFULLY: 'Fax document has been sent successfully',
  FAX_BUNDLE_CREATE_SUCCESSFULLY: 'Bundle has been created successfully',
  FAX_DOCUMENT_UPDATE_SUCCESSFULLY: 'Fax bundle has been updated successfully',
  USER_ROLE_DELETE_SUCCESSFULLY: 'User role has been deleted successfully',
  USER_PERMISSION_ROLE_DELETE_SUCCESSFULLY: 'Permission set has been deleted successfully',
  REPORT_GENERATION_IN_PROGRESS: 'Report generation in progress, please find the report in history',
  LETTER_PRINT_SUCCESSFULLY: 'The letter has been printed successfully',
  LETTER_SENT_SUCCESSFULLY: 'The letter has been sent successfully',
  LETTER_PRINT_AND_SENT_SUCCESSFULLY: 'The letter has been printed/sent successfully',
  TOTAL_LETTER_PRINT_SUCCESSFULLY: (letterCount) => `${letterCount} letters have been printed successfully`,
  TOTAL_LETTER_SENT_SUCCESSFULLY: (letterCount) => `${letterCount} letters have been sent successfully`,
  BUNDLE_DELETED_SUCCESSFULLY: 'Bundle has been deleted successfully',
  LETTER_UPDATED_SUCCESSFULLY: ' Letter has been updated successfully',
  BUNDLE_UPDATED_SUCCESSFULLY: 'Bundle has been updated successfully',
  LETTER_SAVED_SUCCESSFULLY: 'Letter has been successfully updated',
  MFAX_KEY_GENERATE_SUCCESSFULLY: 'Your mFax API Key has been generated successfully',
  CHARGE_UPDATE_UNIT_VALIDATION: 'Update units applied successfully',
  ASSEMBLE_CLAIM_IN_PROGRESS: 'Assemble claim is in progress',
  PRINT_IS_IN_PROGRESS: 'Print is in progress',
  POSTING_BATCH_IS_IN_PROGRESS: 'Posting batch is in progress',
  DELETION_OF_CLAIM_IS_IN_PROGRESS: 'Deletion of claims is in progress',
  PRINTED_SUCCESSFULLY: (file) => `${file} ${file > 1 ? 'documents' : 'document'} printed successfully`,
  PASSWORD_CHANGED_SUCCESSFULLY: 'Password changed successfully',
  REFETCH_SIGNED_PDF_SUCCESSFULLY: 'Refetched Signed PDF successfully',
  MARKED_ENCOUNTER_AS_UNBILL_SUCCESS: 'Marked encounter as unbillable successfully',
  CLAIM_SUBMISSION_IS_IN_PROGRESS: 'Claim submission is in progress',
};

export default SuccessMessages;
