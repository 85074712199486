import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { useMemo } from 'react';

const useRights = (keys = []) => {
  const user = useSelector((store) => store.login.get('current'));

  const isPermitted = useMemo(() => {
    const data = [];
    keys.forEach((key, index) => {
      data[index] = get(user, ['permissions', key], false);
    });
    return data;
  }, [user, keys]);

  return isPermitted || [];
};

export default useRights;
