import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useDrop } from 'react-dnd';
import { Collapse, Input, Tooltip } from 'antd';
import classNames from 'classnames';
import capitalize from 'lodash/capitalize';

import useCRUD from '../../../../../../../../../../../../hooks/useCRUD';
import useRights from '../../../../../../../../../../../../hooks/useRights';
import * as selectors from '../../../../../../../../../../../../store/selectors';

import rights from '../../../../../../../../../../../../lib/rights';
import { getUTCDate } from '../../../../../../../../../../../../lib/util';
import iconNames from '../../../../../../../../../../../../lib/iconNames';
import { apiUrls } from '../../../../../../../../../../../../api/constants';
import { dateFormatWith12Hour } from '../../../../../../../../../../../../lib/constants';

import Icon from '../../../../../../../../../../../../components/Icon';
import WidgetLoader from '../../../../../../../../../../../../components/WidgetLoader';
import RenderMultipleSynoynms from '../../../../../../../../../../../../components/RenderMultipleSynonyms';

import PatientFollowUp from './followUpNonEditableForm';
import FundusExam from '../../../../../../../../../ODOS/Fundus';
import SlitExam from '../../../../../../../../../ODOS/SlitExam';
import { procedureDiagnosisMapping } from '../../../../../../../../../../commonParser';

import '../../../../../../CustomDropppableTab/customDroppableTab.scss';

export const DiagnosisProcedureMappingComponent = ({
  data, subtype, groupId, showChild, favoriteId, associatedJcodes,
  showDiagnosisIcon, diagnosisIconName, onClickDiagnosis, handleDragEnd, drop,
}) => {
  const handleProcedureDragStart = useCallback((event, subTypeElement) => {
    event.dataTransfer.setData('text', event?.target?.id);
    event.dataTransfer.setData('type', 'acuity-eye-tree-leaf');
    event.dataTransfer.setData('draggedItem', 'procedure');
    event.target.classList.add('dragging');
    event.dataTransfer.setData('draggedData', JSON.stringify(subTypeElement));
  }, []);

  const handleDiagnosisProcedureDrag = useCallback((event, diagnosisProcedureSet) => {
    event.dataTransfer.setData('text', event?.target?.id);
    event.dataTransfer.setData('type', 'acuity-eye-tree-leaf');
    event.dataTransfer.setData('draggedItem', 'diagnosis-procedure-set');
    event.target.classList.add('dragging');
    event.dataTransfer.setData('draggedData', JSON.stringify(diagnosisProcedureSet));
  }, []);

  if (data?.diagnosisList?.length) {
    return (
      <div
        className="info-alert"
        id={data?.groupId}
        draggable
        onDragStart={(e) => {
          e.stopPropagation();
          handleDiagnosisProcedureDrag(e, { ...data, favoriteId });
        }}
        onDragEnd={(e) => handleDragEnd(e)}
      >
        <div className="hhh" id={`${data?.groupId}_parent`}>
          {data.diagnosisList.map((element) => (
            <div className="library-tree-list-code add-alert-bg j-code-based-icon" key={`${data?.groupId}_${element?.id}`} id={`${data?.groupId}_${element?.id}_H`}>
              <div
                id={`${data?.groupId}_${element?.id}`}
                aria-hidden="true"
                ref={drop}
                className="positive-relative"
              >
                <p id={`${data?.groupId}_${element?.id}_parentlabel`} aria-hidden="true">
                  <div className="separate-code m-r-0">
                    <span className="label-text-width">{element?.synonyms ? <RenderMultipleSynoynms synonyms={element?.synonyms} /> : (element?.label || element?.description || '')}</span>
                    {' '}
                    <span className="code-only separate-code-bg">
                      {element?.icdCode}
                      {showDiagnosisIcon && (
                        <Icon
                          name={diagnosisIconName}
                          onClick={(event) => onClickDiagnosis(element, event)}
                        />
                      )}
                    </span>
                  </div>
                </p>
              </div>
              <span className="square-line" />
            </div>
          ))}
          <DiagnosisProcedureMappingComponent
            subtype={data?.subType}
            showChild={showChild}
            groupId={data?.groupId}
            handleDragEnd={handleDragEnd}
            drop={drop}
          />
        </div>
      </div>
    );
  }
  if (subtype?.length) {
    return (
      <div className="sub-l-child-group" style={{ marginLeft: '22px' }}>
        <span className="hide-line" />
        {subtype.map((subtypeElement) => !subtypeElement.associatedProcedureId && (
          <div key={subtypeElement.id} id={`${groupId}_${subtypeElement.id}_showChild`}>
            <div
              onDragStart={(e) => {
                e.stopPropagation();
                handleProcedureDragStart(e, subtypeElement);
              }}
              onDragEnd={(e) => handleDragEnd(e)}
              id={`${groupId}_${subtypeElement.id}`}
              draggable
              aria-hidden="true"
              className="positive-relative"
            >
              <p id={`${groupId}_${subtypeElement.id}_para`}>
                <div className="separate-code">
                  <span className="label-text-width">{subtypeElement?.synonyms ? <RenderMultipleSynoynms synonyms={subtypeElement?.synonyms} /> : subtypeElement?.label}</span>
                  {subtypeElement.source === 'ImagingLab' ? '' : (
                    <span className="code-only separate-code-bg">
                      {subtypeElement?.cptCode}
                    </span>
                  )}
                </div>
              </p>
            </div>
            <DiagnosisProcedureMappingComponent
              associatedJcodes={subtypeElement?.associatedJcodes}
              groupId={groupId}
            />
          </div>
        ))}
      </div>
    );
  }
  if (associatedJcodes?.length) {
    return (
      <div className="sub-l-child-group j-code-sub-child" style={{ marginLeft: '28px' }}>
        <span className="hide-line" />
        {associatedJcodes?.map((subtypeElement) => (
          <div key={subtypeElement.id} id={`${groupId}_${subtypeElement.id}_showChild`}>
            <div
              onDragStart={(e) => {
                e.stopPropagation();
                handleProcedureDragStart(e, subtypeElement);
              }}
              onDragEnd={(e) => handleDragEnd(e)}
              id={`${groupId}_${subtypeElement.id}`}
              draggable
              aria-hidden="true"
              className="positive-relative"
            >
              <p id={`${groupId}_${subtypeElement.id || subtypeElement?.cptCode}_para`}>
                {subtypeElement?.label}
                {subtypeElement.source === 'ImagingLab' ? '' : ` - ${subtypeElement?.cptCode}`}
                {subtypeElement.Prompt4 && `  ${subtypeElement?.Prompt4}`}
                <p
                  key={`${subtypeElement?.cptCode}_jcodes`}
                  id={`${groupId}_${subtypeElement.id || subtypeElement?.cptCode}_modifiers`}
                  role="presentation"
                >
                  {subtypeElement?.modifiers?.map(
                  ({ modifierCode, modifierId }, iter) => (
                    <button
                      // eslint-disable-next-line no-nested-ternary
                      className={`code-btn ${(subtypeElement?.modifiers?.[0]?.modifierId === modifierId) ? 'active' : !subtypeElement?.modifiers?.length ? '' : 'active-inactive'}`}
                      style={{
                        marginRight: '2px',
                      }}
                      id={`${subtypeElement?.cptCode}-btn-${iter + 1}`}
                      type="button"
                      value={modifierId}
                    >
                      {modifierCode}
                    </button>
                  ))}
                </p>
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

const { Panel } = Collapse;
const JsonTree = ({
  labelList, type, deleteProcedureSet, deleteOrderSet, showFollowUpExamFinding,
  activeIds, showHideCount, onNodeClick, currentTab, showAllExpended, isAAndP,
}) => {
  const [isDeleteFavouriteSetAuthenticated] = useRights([rights.delete_favourites]);
  const [panelsList, setPanelsList] = useState([]);
  const [procedureDiagnosisMappingData, setProcedureDiagnosisMappingData] = useState({});
  const [procedureSetsResponse, , procedureSetsLoading, getProcedureSetById] = useCRUD({
    id: 'get-doctor-screen-sets',
    url: apiUrls.GET_PROCEDURE_SET_BY_ID,
    type: 'read',
  });

  useEffect(() => {
    if (procedureSetsResponse) {
      setProcedureDiagnosisMappingData({
        ...procedureDiagnosisMappingData,
        [procedureSetsResponse.favouriteId]: procedureDiagnosisMapping(procedureSetsResponse),
      });
    }
  }, [procedureSetsResponse]);

  const [, drop] = useDrop({
    accept: 'CARD',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });
  useEffect(() => {
    if (labelList) {
      setPanelsList([]);
    }
  }, [labelList]);

  useEffect(() => {
    if (showAllExpended) {
      if (type === 'procedureSets') {
        const activeIdsOfPanels = [];
        labelList.forEach((e) => {
          if (e?.key) activeIdsOfPanels.push(e.key);
        });
        setPanelsList(activeIdsOfPanels);
      } else {
        setPanelsList(labelList.map((e) => e?.tagId || e?.orderSetId));
      }
    } else {
      setPanelsList([]);
    }
  }, [labelList, showAllExpended, type]);

  useEffect(() => {
    if (showHideCount) {
      if (activeIds && labelList?.length) {
        const key = type === 'procedureSets' ? 'key' : 'orderSetId';
        setPanelsList(labelList.map((e) => (e[key])));
      } else {
        setPanelsList([]);
      }
    }
  }, [showHideCount, activeIds, labelList]);

  const handleProcedureSetById = useCallback((favouriteId) => {
    if (!procedureDiagnosisMappingData[favouriteId]) {
      getProcedureSetById({ id: favouriteId });
    }
  }, [getProcedureSetById, procedureDiagnosisMappingData]);

  const openClosePanel = useCallback((key) => {
    if (panelsList.includes(key)) {
      setPanelsList([...panelsList.filter((e) => e !== key)]);
    } else {
      setPanelsList([...panelsList, key]);
    }
  }, [panelsList]);

  const openCloseNestedPanel = useCallback((tagId, key) => {
    if (key) {
      if (panelsList.includes(key)) {
        setPanelsList([...panelsList.filter((e) => e !== key)]);
      } else {
        setPanelsList([...panelsList, key]);
        handleProcedureSetById(tagId);
      }
    } else if (panelsList.includes(tagId)) {
      setPanelsList([...panelsList.filter((e) => e !== tagId)]);
    } else {
      setPanelsList([...panelsList, tagId]);
      handleProcedureSetById(tagId);
    }
  }, [handleProcedureSetById, panelsList]);

  const handleDragStart = useCallback((event, examInfo = false, followUpInfo = false) => {
    event.dataTransfer.setData('text', event?.target?.id);
    event.dataTransfer.setData('type', 'acuity-eye-tree-leaf');
    event.target.classList.add('dragging');
    event.dataTransfer.setData('draggedItem', type);
    event.dataTransfer.setData('draggedData', '{}');
    if (type === 'orderSets') {
      event.dataTransfer.setData('orderSetExamFindings', JSON.stringify(examInfo));
      event.dataTransfer.setData('orderSetFollowUpFindings', JSON.stringify(followUpInfo));
    }
  }, [type]);

  const handleDragEnd = useCallback((event) => {
    event.target.classList.remove('dragging');
  }, []);

  const genSetHeader = useCallback((data) => {
    if (data?.copiedId) {
      return (
        <Tooltip title={`${data?.fromProviderName || ''}  ${getUTCDate(data?.createdOn, dateFormatWith12Hour)}`}>
          {data?.favouriteName || data?.orderSetName}
        </Tooltip>
      );
    }
    return <span>{data?.favouriteName || data?.orderSetName}</span>;
  }, []);

  const genExtra = useCallback((id, data) => (
    <div className="flex mr-top-4 3">
      {showFollowUpExamFinding && (
        <div className="flex">
          <span
            title={`Follow-up-${data?.isFollowUpExist ? 'Included' : 'Excluded'}`}
            className={classNames(
              data?.isFollowUpExist ? 'follow-up-bind-green-label' : 'follow-up-bind-grey-label',
            )}
          >
            F
          </span>
          <span
            title={`Exam Findings-${data?.isExamExist ? 'Included' : 'Excluded'}`}
            className={classNames(
              data?.isExamExist ? 'follow-up-bind-green-label' : 'follow-up-bind-grey-label',
            )}
          >
            E
          </span>
        </div>
      )}
      {(isDeleteFavouriteSetAuthenticated || currentTab === 'provider') && (
        <Icon
          className="mr-rt-12 dlt-procedure-icon"
          name={iconNames.closeCircleOutlined}
          onClick={(event) => (type === 'procedureSets' ? deleteProcedureSet(id, event) : deleteOrderSet(id, event))}
        />
      )}
      <Icon
        className="dlt-procedure-icon"
        name={iconNames.deliveredProcedureOutlined}
        onClick={(event) => {
          onNodeClick(event, id, data?.isFollowUpExist, data?.isExamExist);
        }}
      />
    </div>
  ), [currentTab, deleteOrderSet, deleteProcedureSet,
    isDeleteFavouriteSetAuthenticated, onNodeClick, showFollowUpExamFinding, type]);

  if (type === 'procedureSets') {
    return (
      <div className="sub-menu-collapse">
        {(labelList || [])?.length > 0 && (
          labelList.map((data) => (
            <div key={data?.tagId} className=" right-tree">
              <Collapse
                accordion
                activeKey={panelsList.includes(data?.key) ? data?.key : `${data?.key}_i`}
                onChange={() => openClosePanel(data?.key, data?.tagId)}
              >
                <Panel
                  header={data?.tagName}
                  key={data?.key}
                >
                  <div className="right-tree-view procedure-right">
                    {data?.favourites?.length !== 0 && <i className="arrow down" />}
                    {data?.favourites?.map((fav) => (
                      <div
                        key={fav.favouriteId}
                        draggable
                        id={fav.favouriteId}
                        onDragStart={(e) => handleDragStart(e)}
                        onDragEnd={(e) => handleDragEnd(e)}
                        className="positive-relative"
                      >
                        <Collapse
                          accordion
                          activeKey={panelsList.includes(fav.favouriteId) && fav.favouriteId}
                          onChange={() => openCloseNestedPanel(fav.favouriteId)}
                        >
                          <Panel
                            header={genSetHeader(fav)}
                            key={fav.favouriteId}
                            extra={genExtra(fav?.favouriteId)}
                          >
                            <div key={fav.favouriteId}>
                              {procedureSetsLoading && <WidgetLoader size="small" />}
                              {procedureDiagnosisMappingData?.[fav.favouriteId]?.result?.length
                                ? procedureDiagnosisMappingData?.[fav.favouriteId]?.result?.map(
                                  (subType, subTypeIndex) => (
                                    <DiagnosisProcedureMappingComponent
                                      data={subType}
                                      key={subTypeIndex + 1}
                                      showChild
                                      favoriteId={fav?.favouriteId}
                                      handleDragEnd={handleDragEnd}
                                      drop={drop}
                                    />
                                  )) : <span className="pd-lt-8">No Data Found</span>}
                            </div>
                          </Panel>
                        </Collapse>
                      </div>
                    ))}
                  </div>
                </Panel>
              </Collapse>
            </div>
          ))
        )}
      </div>
    );
  }

  return (
    <div className="ap-accordion-tree">
      {(labelList || [])?.length > 0 && (
        labelList.map((data) => (
          <div
            key={data.orderSetId}
            className="right-tree"
            draggable
            id={data.orderSetId}
            onDragStart={(e) => handleDragStart(e, data?.isExamExist, data?.isFollowUpExist)}
            onDragEnd={(e) => handleDragEnd(e)}
          >
            <Collapse
              accordion
              activeKey={panelsList.includes(data?.orderSetId) ? data?.orderSetId : `${data?.orderSetId}_i`}
              onChange={() => openClosePanel(data?.orderSetId)}
            >
              <Panel
                header={genSetHeader(data)}
                key={data.orderSetId}
                extra={genExtra(data.orderSetId, data)}
              >
                <div className="wrap-inner-panel">
                  <Collapse
                    onChange={() => openCloseNestedPanel(data.orderSetId, data.key)}
                  >
                    <Panel
                      header="Procedure"
                      key={data.key}
                    >
                      <div className="right-tree-view procedure-right ap-tree">
                        {procedureSetsLoading && <WidgetLoader size="small" />}
                        {procedureDiagnosisMappingData?.[data?.orderSetId]?.result?.length
                          ? procedureDiagnosisMappingData?.[data.orderSetId]?.result?.map(
                            (subType, subTypeIndex) => (
                              <DiagnosisProcedureMappingComponent
                                data={subType}
                                key={subTypeIndex + 1}
                                showChild
                                favoriteId={data?.orderSetId}
                                handleDragEnd={handleDragEnd}
                                drop={drop}
                              />
                            )) : <span draggable={false}>No Data Found</span>}
                      </div>
                      {procedureDiagnosisMappingData?.[data?.orderSetId]?.notes?.map(
                        (notesData) => (
                          <div
                            role="presentation"
                            key={notesData.id}
                            className="tree-panel-box"
                          >
                            <div className="pannel">
                              <div className="pannel-heading">
                                <div className="width-100-pr flex justify-content-sp-bt">
                                  <div>
                                    {capitalize(notesData.noteType)}
                                  </div>
                                </div>
                              </div>
                              <div className="pannel-body">
                                <Input.TextArea
                                  label=""
                                  name={null}
                                  labelSpan="0"
                                  inputSpan="24"
                                  autoSize={{ minRows: 1 }}
                                  value={notesData.note}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                    </Panel>
                    <Panel
                      header="Follow up"
                      key="2"
                    >
                      <div className="follow-up-bind">
                        <PatientFollowUp
                          followUpData={procedureDiagnosisMappingData?.
                            [data?.orderSetId]?.followUp}
                          maxTagCount={2}
                          disabledAll
                          id={`${data?.orderSetId}-${isAAndP}`}
                          key={`${data?.orderSetId}-${isAAndP}`}
                        />
                      </div>
                    </Panel>
                    <Panel
                      header="Exam findings"
                      key="3"
                    >
                      <div
                        className="select-box-section"
                        id="select-box-section-doctor"
                        style={{ position: 'relative', pointerEvents: 'none', opacity: '0.6' }}
                      >
                        <SlitExam scrollId="phy-exam-tab-slit-and-fundus" fetchCall={false} slitExamData={procedureDiagnosisMappingData?.[data?.orderSetId]?.slitLampList || []} displayOnly={data?.orderSetId} />
                        <FundusExam scrollId="phy-exam-tab-slit-and-fundus" fetchCall={false} fundusExamData={procedureDiagnosisMappingData?.[data?.orderSetId]?.fundusExamList || []} displayOnly={data?.orderSetId} />
                      </div>
                    </Panel>
                  </Collapse>
                </div>
              </Panel>
            </Collapse>
          </div>
        ))
      )}
    </div>
  );
};

JsonTree.defaultProps = {
  list: [],
  search: '',
  url: '',
  setLabelsList: () => { /* This is intentional */ },
  draggable: true,
  showCloseIcon: true,
  onAddItems: () => { /* This is intentional */ },
  handleProcedureClick: () => { /* This is intentional */ },
  showSelection: false,
};

JsonTree.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  search: PropTypes.string,
  url: PropTypes.string,
  setLabelsList: PropTypes.func,
  draggable: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
  onAddItems: PropTypes.func,
  handleProcedureClick: PropTypes.func,
  showSelection: PropTypes.bool,
};

export default connect((states) => ({
  enumOptions: selectors.getEnumOptions(states),
  enumMaster: selectors.getEnumMaster(states),
}))(JsonTree);
