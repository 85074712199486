/* eslint-disable max-len */
import React, {
  useCallback, useMemo, useState,
} from 'react';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import { orderBy } from 'lodash';
import JsonTree from '../../JsonTree';
import { apiUrls } from '../../../../../../../../../../api/constants';
import Form from '../../../../../../../../../../components/Form';
import SearchBox from '../../../../../../../../../../components/Search';
import useCRUD from '../../../../../../../../../../hooks/useCRUD';
import WidgetLoader from '../../../../../../../../../../components/WidgetLoader';
import Collapsable from '../../../../../../../../../../components/Collapse';
import { procedureSources } from '../../../../../../../../commonParser';
import sortWithoutSymbols from '../../../../../../../../../../lib/sortWithoutSymbols';

const Procedure = ({
  procedureList,
  setProcedureList,
}) => {
  const [show, showList] = useState(null);
  const [
    result, ,
    resultLoading,
    getResult,
    clearResult,
  ] = useCRUD({
    id: apiUrls.GET_CPT_PAGINATED,
    url: apiUrls.GET_CPT_PAGINATED,
    type: 'read',
  });

  const previousForPatientList = useMemo(() => {
    const list = [];
    procedureList?.forEach((item) => {
      list?.push(...item?.procedureDetails);
    });
    const cptCodesList = uniqBy(list, 'cptCode').filter((item) => (!procedureSources?.includes(item?.source) && (parseInt(item?.cptCode, 10) <= 70000 || parseInt(item?.cptCode, 10) >= 90000)));
    const sortedCptCodesList = orderBy(cptCodesList, (e) => sortWithoutSymbols(e.cptDescription));

    return sortedCptCodesList;
  }, [procedureList]);
  const sortedResult = orderBy(result?.result, (e) => sortWithoutSymbols(e.synonyms) || sortWithoutSymbols(e.description));

  const debounceFunction = useMemo(() => debounce((value) => {
    if (value) {
      getResult({
        CPTCodeText: value,
        IncludeInActive: false,
        IsUnique: true,
        ExcludeLabImaging: true,
        ExcludeEnMCodes: true,
      });
      showList(true);
    } else {
      showList(false);
      clearResult(true);
    }
  }, 1500), [getResult]);

  const onSearch = useCallback((event) => {
    event.persist();
    debounceFunction(event?.target?.value);
  }, [clearResult, debounceFunction]);

  return (
    <div className="side-tab">
      <div className="merge-fields-container">
        <div className="temp-search-wrapper">
          <Form.Section noOfColumns={2}>
            <Form.Column>
              <SearchBox name="searchText" onChange={onSearch} />
            </Form.Column>
          </Form.Section>
        </div>
        <div className="wrapper-tree-fix-height">
          <Collapsable
            className="ae-accordian posi-relative mr-bt-8"
            header="Previous Procedure for Patient "
            panelKey={show === true ? 'ProcedureLists' : 'ProcedureList'}
            defaultActiveKey={['ProcedureList']}
          >
            <div className="pannel-body">
              <div className="json-tree-container remove-tree-switchers procedure-list-100-per">
                <JsonTree
                  setLabelsList={setProcedureList}
                  list={previousForPatientList}
                  selectable={false}
                  tab="procedure"
                />
              </div>
            </div>
          </Collapsable>
          <Collapsable
            className="ae-accordian posi-relative procedure-result-list manage-ui-block"
            header="Result"
            panelKey={show === true ? 'result' : 'results'}
            defaultActiveKey={['result']}
          >
            {resultLoading && <WidgetLoader />}
            <JsonTree
              list={sortedResult || []}
              selectable={false}
              tab="procedure"
              isResult
            />
          </Collapsable>
        </div>
      </div>
    </div>
  );
};

export default Procedure;
