import React from 'react';
import { Select, Form } from 'antd';
import PropTypes from 'prop-types';
import idCalculator from '../../lib/idCalculator';

const { Option, OptGroup } = Select;
export default function GroupSelect({
  label, name, selectOption, groupList, id, labelClassName, selectProps, ...otherProps
}) {
  return (
    <Form.Item
      name={name}
      {...otherProps}
    >
      <Select defaultValue="Select Transaction Type" style={{ width: 200 }} onSelect={selectOption} {...selectProps}>
        {groupList && !!groupList.length && groupList.map(({ listLabel, list }) => (
          <OptGroup
            label={labelClassName ? (
              <div className={labelClassName}>
                {listLabel}
              </div>
            ) : listLabel}
            key={listLabel}
          >
            {list.map((listItem) => (
              <Option
                key={`${id}-${listItem.value}`}
                id={idCalculator(id, listLabel, listItem.value)}
                value={listItem.value}
                data-testid={listItem.value}
              >
                {listItem.name}
              </Option>
            ))}
          </OptGroup>
        ))}
      </Select>
    </Form.Item>
  );
}

GroupSelect.defaultProps = {
  label: '',
  name: '',
  selectOption: '',
  groupList: [],
  id: '',
  labelClassName: '',
  selectProps: {},
};

GroupSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  selectOption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  groupList: PropTypes.instanceOf(Array),
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  labelClassName: PropTypes.string,
  selectProps: PropTypes.instanceOf(Object),
};
