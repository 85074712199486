import React, { useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import Notification from '../../components/Notification';
import Select from '../../components/Form/SelectBox';
import Events from '../../lib/events';

import './Select.scss';
import useCRUDWithoutTab from '../../hooks/useCRUDWithoutTab';
import { defaultProps, defaultPropsType } from './commonPropType';

const SelectBoxV2 = ({
  url,
  id,
  nameAccessor,
  valueAccessor,
  Component,
  onFetchComplete,
  params, reFetchOptions,
  defaultOptions,
  parser,
  optionRenderer,
  isParamsRequired,
  sortParamter,
  defaultSorting,
  isStatCaseOptions,
  valueInString,
  wrapperClassName,
  updateData,
  dataTestId,
  showRcmHighlight,
  finalRcmHighlightData,
  ...props
}) => {
  const [options, setOptions] = useState([]);
  const [data, error, loading, getOptionsPlain, clear] = useCRUDWithoutTab({ id, url, type: 'read' });
  const [localParams, setLocalParams] = useState(() => params);
  const getOptions = useMemo(() => debounce(getOptionsPlain || (() => null),
    1000), [getOptionsPlain]);

  useEffect(() => {
    if (url) {
      if ((isParamsRequired && localParams && Object.keys(localParams).length)
        || !isParamsRequired) {
        getOptions(localParams);
      }
    }
  }, [url, localParams, isParamsRequired]);

  useEffect(() => {
    Events.on(id, id, () => {
      getOptions({ ...localParams, isReFetch: true });
    });
    return () => Events.remove(id);
  }, []);

  useEffect(() => {
    if (!isEqual(params, localParams)) {
      setLocalParams(params);
    }
  }, [params]);

  useEffect(() => {
    if (error) {
      Notification({ message: error });
      clear(true);
    }
  });

  useEffect(() => {
    if (isFunction(reFetchOptions)) {
      reFetchOptions(getOptions);
    }
  }, [reFetchOptions, getOptions]);

  useEffect(() => {
    let dropDownOptions = [];
    if (isArray(data)) {
      dropDownOptions = data;
    } else if (isObject(data)) {
      dropDownOptions = get(data, 'result', []);
    }
    if (isFunction(parser)) {
      dropDownOptions = parser(dropDownOptions, data);
    }
    dropDownOptions = dropDownOptions.map((item) => ({
      ...item,
      name: isStatCaseOptions ? startCase(lowerCase(item[nameAccessor])) : item[nameAccessor],
      sortName: startCase(lowerCase(item[nameAccessor])),
      value: valueInString ? `${item[valueAccessor]}` : item[valueAccessor],
    }));

    dropDownOptions = defaultSorting ? orderBy(dropDownOptions, sortParamter || 'sortName') : dropDownOptions;

    if (isFunction(optionRenderer)) {
      dropDownOptions = optionRenderer(dropDownOptions);
    }
    setOptions(dropDownOptions);
    if (typeof onFetchComplete === 'function' && dropDownOptions.length) {
      onFetchComplete(dropDownOptions);
    }
  }, [data, nameAccessor, valueAccessor, onFetchComplete, optionRenderer]);

  return (
    <div className={classNames((defaultOptions || options)?.length ? '' : 'hidden-wrapper', wrapperClassName)}>
      <Component
        options={defaultOptions || options}
        loading={loading && (!defaultOptions?.length && !options?.length)}
        dataTestId={dataTestId || props?.name}
        nameAccessor={nameAccessor}
        valueAccessor={valueAccessor}
        showRcmHighlight={showRcmHighlight}
        finalRcmHighlightData={finalRcmHighlightData}
        {...props}
      />
    </div>
  );
};

SelectBoxV2.defaultProps = {
  ...defaultProps,
  Component: Select,
  showRcmHighlight: false,
  finalRcmHighlightData: null,
};

SelectBoxV2.propTypes = {
  ...defaultPropsType,
  showRcmHighlight: PropTypes.bool,
  finalRcmHighlightData: PropTypes.instanceOf(Array),
};

export default React.memo(SelectBoxV2);
