import React from 'react';
import PostedClaimTable from '../../InsurancePayment/PostedClaimsTable';

const Payments = ({
  labels, setCurrentPath, currentTab, viewEnteredItemsDetails,
  viewEnteredItemLoading, ...otherProps
}) => (
  <PostedClaimTable
    labels={labels}
    setCurrentPath={setCurrentPath}
    data={viewEnteredItemsDetails || []}
    loading={viewEnteredItemLoading}
    isPostedInsuranceClaims
    {...otherProps}
  />
);

export default Payments;
