import React, { useCallback, useEffect, useMemo } from 'react';

import { listIds } from '../../../../lib/constants';
import { apiUrls } from '../../../../api/constants';

import withQuery from '../../../../hoc/withQuery/withQuery';
import useRedirect from '../../../../hooks/useRedirect';
import Events from '../../../../lib/events';

import columns from './columns';

const TableRefetchEvent = ({ reFetch }) => {
  useEffect(() => {
    Events.on('refetch-balance-sheet', 'refetch-balance-sheet', reFetch);
    return () => Events.remove('refetch-balance-sheet');
  }, []);
  return null;
};

const BalanceSheetTable = ({ labels, isCustomized, columnsRequired = [] }) => {
  const { params: { id: patientId } } = useRedirect();

  const CustomTable = useMemo(() => withQuery({
    url: `${apiUrls.GET_PATIENT_BALANCE_SHEET}/${patientId}`,
    listId: `${listIds.FINANCIAL_LIST}-${patientId}`,
    accessor: (data) => ({ result: data }),
  })(), [patientId]);

  const getColumns = useCallback(() => {
    if (columnsRequired?.length) {
      return columns(labels, isCustomized).filter(
        (data) => columnsRequired.includes(data.accessor),
      );
    }
    return columns(labels, isCustomized);
  }, [columnsRequired, labels, isCustomized]);

  return (
    <CustomTable
      footer
      columns={getColumns()}
      noDataText="Data not found"
      scrollId="balance-sheet-table"
    >
      {({ Component, reFetch }) => (
        <>
          <TableRefetchEvent reFetch={reFetch} />
          {Component}
        </>
      )}
    </CustomTable>
  );
};

export default BalanceSheetTable;
