import React, { useCallback, useEffect } from 'react';

import {
  UiRoutes, labelPaths, mimeType, responseType, listIds,
} from '../../lib/constants';
import downloadFile from '../../lib/downloadFile';

import { apiUrls } from '../../api/constants';

import WithLabel from '../../hoc/withLabel';
import WithRights from '../../hoc/withRights';
import useCRUD from '../../hooks/useCRUD';
import useRights from '../../hooks/useRights';

import rights from '../../lib/rights';

import Link from '../../components/Link';
import Button from '../../components/Button';
import WidgetLoader from '../../components/WidgetLoader';
import SavedNavigationBar from '../../components/SavedNavigationBar';

import ContactTable from './ContractTable';

import WithClearCurrentTab from '../../hoc/withClearCurrentTab';

import './contract.scss';

const Contracts = ({ labels }) => {
  const [response, , loading, exportContracts, clearDownloadedFile] = useCRUD({
    id: listIds.EXPORT_CONTRACT, url: apiUrls.EXPORT_CONTRACTS, type: 'read',
  });
  const [isCreateContractAuthenticated] = useRights([rights.can_create_and_edit_contracts]);

  useEffect(() => {
    if (response) {
      downloadFile(response, 'ExportContract.xlsx', mimeType.XLSX);
      clearDownloadedFile(true);
    }
  }, [response, clearDownloadedFile]);

  const exportContractHandler = useCallback((event) => {
    event.preventDefault();
    exportContracts({ responseType: responseType.ARRAY_BUFFER });
  }, [exportContracts]);

  return (
    <SavedNavigationBar
      parentPath={UiRoutes.contract}
      myPath={UiRoutes.editContractWithTabId}
    >
      <div className="contract-wrapper">
        <div className="heading-area flex justify-content-sp-bt align-center">
          <h2>{labels.get('header.contract')}</h2>
          <div className="group-btns flex">
            <div className="posi-relative">
              {loading && <WidgetLoader size="medium" />}
              <Button className="btn btn-success sm-btn mr-rt-8" onClick={exportContractHandler}>{labels.get('buttons.export')}</Button>
            </div>
            {isCreateContractAuthenticated && (
              <Link to={UiRoutes.newContract}><Button className="btn btn-success sm-btn">{labels.get('buttons.addContract')}</Button></Link>
            )}
          </div>
        </div>
        <ContactTable
          labels={labels}
        />
      </div>
    </SavedNavigationBar>
  );
};

export default WithClearCurrentTab(
  WithRights(WithLabel(Contracts, labelPaths.CONTRACT_LIST), rights.access_to_contract_management),
);
