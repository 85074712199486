import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form as AntdForm } from 'antd';

import Button from '../../components/Button';
import Form from '../../components/Form';
import Input from '../../components/Form/Input';
import Image from '../../components/Image';
import Loader from '../../components/Loader';
import Notification from '../../components/Notification';

import * as loginAction from '../../store/actions/login';

import useRedirect from '../../hooks/useRedirect';

import { UiRoutes, formId } from '../../lib/constants';
import SuccessMessages from '../../lib/successMessages';

import { apiUrls } from '../../api/constants';
import ErrorMessages from '../../lib/errorMessages';
import encryptData from '../../lib/encryptData';

const parser = (formValues) => ({
  ...formValues,
  email: encryptData(formValues?.email),
});

function ForgotPassword(props) {
  const { loading } = props;
  const { push } = useRedirect();
  const [form] = AntdForm.useForm();

  const onRequestComplete = useCallback((response) => {
    if (response) {
      Notification({ message: SuccessMessages.EMAIL_SENT_SUCCESSFULLY, success: true });
      push(UiRoutes.login);
    }
  }, [push]);

  const onFinishFailed = useCallback(() => {
    Notification({ message: ErrorMessages.VALID_EMAIL_ADDRESS });
  }, []);

  return (
    <>
      {loading && <Loader />}
      <div className="login-wrap">
        <div className="login-section">
          <div className="login-logo">
            {process.env.REACT_APP_DEMO === 'true' && <Image name="login-logo.png" alt="logo" />}
          </div>
          <div className="shadow-bbg-1" />
          <div className="shadow-bbg-2" />
          <div className="login-alignment">
            <div className="login-field-wrap fields-wrapper">
              <Form
                section
                form={form}
                url={apiUrls.FORGOT_PASSWORD}
                formId={formId.FORGOT_PASSWORD_FORM}
                parser={parser}
                onRequestComplete={onRequestComplete}
                onFinishFailed={onFinishFailed}
              >
                <h1>Forgot password</h1>
                <Form.Section>
                  <Input name="email" labelSpan="0" label="Email" inputSpan="24" placeholder="Email" rules={[{ type: 'email', message: 'Please enter the valid email-id' }]} required />
                  <Button className="btn btn-success btn-block" type="submit">Reset Password</Button>
                  <Link to={UiRoutes.login} className="forget-pass">Back to Login</Link>
                </Form.Section>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  loading: state.login.get('loading'),
});

const mapDispatchToProps = (dispatch) => ({
  requestLogin: (email, password) => dispatch(loginAction.requestLogin(email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
