import { getRandomNumber } from '../../lib/util';

const tabIds = {};

const tabIdGenerator = (path) => {
  if (!tabIds[path]) {
    tabIds[path] = getRandomNumber().toString(36).substring(2, 6)
    + getRandomNumber().toString(36).substring(2, 6);
  }
  return tabIds[path];
};

export default tabIdGenerator;
