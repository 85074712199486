import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import { Menu, Upload } from 'antd';

import isBoolean from 'lodash/isBoolean';
import { apiUrls } from '../../api/constants';
import useTabLink from '../../hooks/useTabLink';
import useCRUD from '../../hooks/useCRUD';
import useRights from '../../hooks/useRights';
import usePatientDetail from '../../hooks/usePatientDetail';
import {
  contentType, UiRoutes, enums, enumMasterCode,
} from '../../lib/constants';
import rights from '../../lib/rights';

import Loader from '../../components/Loader';

import Notification from '../../components/Notification';

import PatientPayment from '../../screens/Dashboard/Tabs/Financial/Popups/Patient/PatientPayment';
import PatientSimpleCharge from '../../screens/Dashboard/Tabs/Financial/Popups/Patient/PatientSimpleCharge';
import TasksModal from '../Modal/Tasks';
import UploadPhotoModal from '../Modal/Photo';
import NotesModal from '../Modal/Note';
import PrintModal from '../Modal/Print';
import FaxModal from '../Modal/mFax';
import IconsWithDropDown from './IconsWithDropDown';
import AuditLogsModal from '../Modal/AuditLog';
import PverifyModal from '../Modal/Pverify';
import EventWrapper from '../../components/EventWrapper';
import Events from '../../lib/events';
import useMasterId from '../../hooks/useMasterId';
import { fileSizeCheck } from '../../lib/util';

const navigateToDocument = ({
  data, patientInfo, clearResponse, navigateToEditDocument,
}) => {
  const documentId = data?.data?.documentId || data?.documentid;
  if (documentId && (patientInfo?.patientId)) {
    clearResponse();
    navigateToEditDocument({ documentId });
  }
};

const showErrorNotification = ({ error, cb, isRead }) => {
  if (error) {
    Notification({ message: error });
    cb(!!isRead);
  }
};

const getPatientId = (patientInfoPatientId, otherPropPatientId) => (patientInfoPatientId
  || otherPropPatientId);

const pushToIconsArray = ({
  value, memoizedOptions, key, iconsArray,
}) => {
  if (value && isBoolean(value)
  && memoizedOptions?.[key]
  && (memoizedOptions?.[key]?.condition
  ?? true)) {
    iconsArray.push(<EventWrapper key={key} {...memoizedOptions[key]} />);
  }
};

const masterCodes = [
  enumMasterCode.document_authorization,
];

const enumNames = [
  enums.PATIENT_DOCUMENT_CATEGORY,
];

const ActionIcons = ({
  patient,
  transactionWithDropDown,
  documentsWithDropDown,
  printProps,
  claimNumber,
  auditLogsProps,
  ...otherProps
}) => {
  const [selectedSocialOption, selectSocialOption] = useState();
  const [SocialBoolean, setSocialBoolean] = useState(false);
  const [modalVisibility, setModalVisibility] = useState();
  const { Authorization } = useMasterId(masterCodes, enumNames);

  const [patientInfo] = usePatientDetail({
    patientId: otherProps?.patientId
    || patient?.patientId,
  });

  const { navigate: navigateToPatient } = useTabLink({
    to: UiRoutes.patientInsuranceBaseRoute,
  });
  const [isInvitePatientAuth] = useRights([rights.invite_patient]);

  const handleModalVisbility = useCallback((value) => () => setModalVisibility(value), []);

  const currentPatientId = useMemo(() => getPatientId(patientInfo?.patientId,
    otherProps?.patientId), [otherProps, patientInfo]);

  const [data, error, loading, uploadFile, clearResponse] = useCRUD({
    id: 'new-document-action-icon',
    url: apiUrls.UPLOAD_PATIENT_DOCUMENT,
    type: 'create',
    shouldClearError: false,
  });
  const [
    cms1500Response,
    cms1500Error,
    cmsLoading,
    getCMS1500Form,
    clear,
  ] = useCRUD({
    id: `${apiUrls.GET_CLAIMS_PDF}-${claimNumber}`,
    url: apiUrls.GET_CLAIMS_PDF,
    type: 'create',
    shouldClearError: false,
  });

  const [patientDocumentResponse,,, getPatientDocumentCount] = useCRUD({
    id: 'GET_PATIENT_DOCUMENT_COUNT',
    url: apiUrls.GET_PATIENT_DOCUMENT_COUNT,
    type: 'read',
  });

  useEffect(() => {
    if (otherProps?.fax) {
      getPatientDocumentCount({ id: otherProps?.patientId });
      Events.on('get-patient-fax-document-count', 'get-patient-fax-document-count', () => getPatientDocumentCount({ id: otherProps?.patientId }));
    }
  }, []);

  useEffect(() => {
    showErrorNotification({ error, cb: clearResponse, isRead: true });
  }, [error]);

  const toggleModal = useCallback((value) => {
    setModalVisibility(value);
  }, []);

  const setSocialOption = useCallback((element) => {
    selectSocialOption(element.target.id);
  }, []);

  const viewPatientInsurance = useCallback(() => {
    navigateToPatient({
      data: {
        showFullTitle: true,
        name: `Insurance:${patientInfo?.fullName}`,
      },
      tab: 'insurance',
      id: currentPatientId,
    });
  }, [currentPatientId, navigateToPatient, patientInfo]);

  const viewPatient = useCallback(() => {
    navigateToPatient({
      data: {
        showFullTitle: true,
        name: `Demographic:${patientInfo?.fullName}`,
      },
      tab: 'summary',
      id: patientInfo.patientId || otherProps.patientId,
    });
  }, [navigateToPatient, otherProps.patientId, patientInfo]);

  const viewPatientFinancial = useCallback(() => {
    navigateToPatient({
      data: {
        showFullTitle: true,
        name: `Financial:${patientInfo?.fullName}`,
      },
      tab: 'financial',
      id: currentPatientId,
    });
  }, [currentPatientId, navigateToPatient, patientInfo]);

  const openSocialMenu = () => {
    setSocialBoolean(!SocialBoolean);
  };

  const viewDemoGraphic = useCallback(() => {
    navigateToPatient({
      data: {
        showFullTitle: true,
        name: `Demographic:${patientInfo?.fullName}`,
      },
      tab: 'demographic',
      id: currentPatientId,
    });
  }, [currentPatientId, navigateToPatient, patientInfo]);

  const viewDocuments = useCallback(() => {
    navigateToPatient({
      data: {
        name: `Documents:${patientInfo?.fullName}`,
        showFullTitle: true,
      },
      tab: 'documents',
      id: currentPatientId,
    });
  }, [currentPatientId, navigateToPatient, patientInfo]);

  const onSelectFile = useCallback((fileData) => {
    if (fileSizeCheck(fileData, 120)) {
      uploadFile({
        data: {
          contentType: contentType.MULTIPART,
          File: fileData,
          CategoryId: Authorization,
          PatientId: currentPatientId,
          DocumentDisplayName: fileData.name.substr(0, fileData.name.lastIndexOf('.')),
        },
      });
    }
  }, [Authorization, currentPatientId, uploadFile]);

  const { navigate: redirectToEditDocument } = useTabLink(
    {
      to: UiRoutes.editPatientDocumentWithTabId,
      id: UiRoutes.editPatientWithTabId,
    },
  );

  const navigateToEditDocument = useCallback((documentParams = {}) => {
    redirectToEditDocument({
      data: {
        name: documentParams?.documentName ? `${patientInfo?.fullName}-${documentParams?.documentName}` : `${patientInfo?.fullName}-Document`,

        id: currentPatientId,
      },
      id: currentPatientId,
      ...documentParams,
    });
  }, [redirectToEditDocument, patientInfo, currentPatientId]);

  useEffect(() => {
    navigateToDocument({
      data, patientInfo, clearResponse, navigateToEditDocument,
    });
  }, [data]);

  useEffect(() => {
    showErrorNotification({ error: cms1500Error, cb: clear });
  }, [cms1500Error, clear]);

  useEffect(() => {
    if (cms1500Response && !Array.isArray(cms1500Response)) {
      window.open(cms1500Response?.pdfUrl);
      clear();
    }
  }, [cms1500Response, clear]);

  const handlePreviewClick = useCallback(() => {
    if (claimNumber) {
      getCMS1500Form({ data: { claimIds: claimNumber, isClaimCorrected: false } });
    }
  }, [claimNumber, getCMS1500Form]);

  const memoizedOptions = useMemo(() => ({
    maps: {
      title: 'Maps',
      type: 'li',
      id: 'social-menu-item-map',
      className: 'map-icons icon',
      onClick: setSocialOption,
    },
    share: {
      title: 'Share',
      id: 'social-menu-item-share',
      className: 'share-icons icon',
      onClick: setSocialOption,
      type: 'li',
    },
    invitePatient: {
      title: 'Share',
      id: 'social-menu-item-share',
      className: 'share-icons icon',
      onClick: setSocialOption,
      condition: isInvitePatientAuth,
      type: 'li',
    },
    print: {
      title: 'Print',
      id: 'social-menu-item-print',
      className: 'print-icons icon',
      onClick: setSocialOption,
      type: 'li',
    },
    scan: {
      title: 'Scan',
      id: 'social-menu-item-scan',
      className: 'scan-icons icon',
      onClick: setSocialOption,
      type: 'li',
    },
    email: {
      title: 'Email',
      id: 'social-menu-item-email',
      className: 'email-icons icon',
      onClick: setSocialOption,
      type: 'li',
    },
    edit: {
      title: 'Task',
      id: 'social-menu-item-edit',
      className: 'task-icons icon',
      onClick: setSocialOption,
      type: 'li',
    },
    camera: {
      title: 'Photo',
      id: 'social-menu-item-camra',
      className: 'camra-icons icon',
      onClick: setSocialOption,
      type: 'li',
    },
    diagonos: {
      title: 'Diagnosis',
      id: 'social-menu-item-diagoonos',
      className: 'diagonos-icons icon',
      onClick: setSocialOption,
      type: 'li',
    },
    notes: {
      title: 'Notes',
      id: 'social-menu-item-note',
      className: 'note-icons icon',
      onClick: setSocialOption,
      type: 'li',
    },
    download: {
      title: 'Download',
      id: 'social-menu-item-download',
      className: 'download-icons icon',
      onClick: setSocialOption,
      type: 'li',
    },
    user: {
      title: 'User',
      id: 'social-menu-item-user',
      className: 'user-icons icon',
      onClick: viewPatient,
      type: 'li',
    },
    demoGraphic: {
      title: 'Demographic',
      id: 'social-menu-item-user',
      className: 'user-icons icon',
      onClick: viewDemoGraphic,
      type: 'li',
    },
    watch: {
      title: 'Schedule',
      id: 'social-menu-item-watch',
      className: 'watch-icons icon',
      onClick: setSocialOption,
      type: 'li',
    },
    merge: {
      title: 'Merge',
      id: 'social-menu-item-diagoonos',
      className: 'diagonos-icons icon',
      onClick: setSocialOption,
      type: 'li',
    },
    forward: {
      title: 'Forward',
      id: 'social-menu-item-print',
      className: 'print-icons icon',
      onClick: setSocialOption,
      type: 'li',
    },
    payment: {
      title: 'Payment',
      id: 'social-menu-item-print',
      className: 'payment-icons icon',
      onClick: setSocialOption,
      type: 'li',
    },
    insurance: {
      title: 'Insurance',
      id: 'social-menu-item-print',
      className: 'insurance-icons icon',
      onClick: viewPatientInsurance,
      type: 'li',
    },
    search: {
      title: 'Search',
      id: 'social-menu-item-search',
      className: 'search-icons icon',
      onClick: setSocialOption,
      type: 'li',
    },
    deleteAction: {
      title: 'Delete',
      id: 'social-menu-item-delete',
      className: 'delete-icons icon',
      onClick: setSocialOption,
      type: 'li',
    },
    transaction: {
      title: 'Transaction',
      id: 'social-menu-item-transaction',
      className: 'transaction-icons icon',
      onClick: viewPatientFinancial,
      type: 'li',
    },
    insurance2: {
      title: 'Insurance',
      id: 'social-menu-item-insurance2',
      className: 'insurance-icons icon',
      onClick: setSocialOption,
      type: 'li',
    },
    documents: {
      title: 'Documents',
      id: 'social-menu-item-documents',
      className: 'document-icons icon',
      onClick: viewDocuments,
      type: 'li',
    },
    userNotes: {
      title: 'Notes',
      id: 'social-menu-item-notes',
      className: 'note-icons icon',
      onClick: setSocialOption,
      type: 'li',
    },
    claimsPreview: {
      title: 'Claims Preview',
      id: 'social-menu-item-claims-preview',
      className: 'claim-icons icon',
      onClick: handlePreviewClick,
      type: 'li',
    },
    auditLogs: {
      title: 'Audit Logs',
      id: 'social-menu-item-audit',
      className: 'watch-icons icon',
      onClick: setSocialOption,
      type: 'li',
    },
    pVerify: {
      title: 'pVerify',
      id: 'social-menu-item-pVerify',
      className: 'note-icons icon',
      onClick: setSocialOption,
      type: 'li',
    },
    fax: {
      title: 'Fax',
      id: 'social-menu-item-fax',
      className: 'fax-icon icon',
      onClick: setSocialOption,
      type: 'li',
      badge: patientDocumentResponse || 0,
    },

  }), [handlePreviewClick, isInvitePatientAuth, patientDocumentResponse, setSocialOption,
    viewDemoGraphic, viewDocuments, viewPatient, viewPatientFinancial, viewPatientInsurance]);

  const renderIcons = useMemo(() => {
    const iconsArray = [];
    Object.entries(otherProps).forEach(([key, value]) => {
      pushToIconsArray({
        value, memoizedOptions, key, iconsArray,
      });
    });
    return iconsArray;
  }, [memoizedOptions, otherProps]);

  return (
    <div className={SocialBoolean ? 'social-icons open-social flex justify-content-flex-end mobile-social' : 'social-icons open-social flex justify-content-flex-end'}>
      <div role="presentation" className="mobile-menu-icon" onClick={openSocialMenu}><span>+</span></div>
      <ul>
        {renderIcons}
        {transactionWithDropDown
           && (
           <IconsWithDropDown
             overlay={(
               <Menu>
                 <Menu.Item
                   className="cursor-pointer"
                   onClick={handleModalVisbility('Patient Payment')}
                 >
                   Patient Payment
                 </Menu.Item>
                 <Menu.Item
                   className="cursor-pointer"
                   onClick={handleModalVisbility('Simple Charge')}
                 >
                   Simple Charge
                 </Menu.Item>
               </Menu>
            )}
             placement="bottomLeft"
             trigger="click"
             iconProps={{
               type: 'li',
               title: 'Transaction',
               id: 'social-menu-item-transaction',
               className: 'transaction-icons icon',
               handleClick: viewPatientFinancial,
             }}
           />
           )}
        {documentsWithDropDown && Authorization
           && (
           <IconsWithDropDown
             overlay={(
               <Menu>
                 <Menu.Item className="cursor-pointer">
                   <Upload
                     className="cursor-pointer upload-design"
                     accept="image/png, image/jpeg, .doc, .docx, .pdf, .DOC, .DOCX, .PDF"
                     beforeUpload={onSelectFile}
                     multiple={false}
                     showUploadList={false}
                   >
                     Upload File
                   </Upload>
                 </Menu.Item>
                 {/* <Menu.Item
                   className="cursor-pointer"
                   onClick={handleModalVisbility('Scan File')}
                 >
                   Scan File
                 </Menu.Item> */}
               </Menu>
            )}
             placement="bottomLeft"
             trigger="click"
             iconProps={{
               type: 'li',
               title: 'Documents',
               id: 'social-menu-item-documents',
               className: 'document-icons icon',
               handleClick: viewDocuments,
             }}
           />
           )}
      </ul>
      <TasksModal
        visible={selectedSocialOption === 'social-menu-item-edit'}
        toggleModal={selectSocialOption}
        {...otherProps}
      />
      <PrintModal
        visible={selectedSocialOption === 'social-menu-item-print'}
        toggleModal={selectSocialOption}
        printProps={printProps}
        {...otherProps}
      />
      {selectedSocialOption === 'social-menu-item-fax' && (
      <FaxModal
        visible={selectedSocialOption === 'social-menu-item-fax'}
        toggleModal={selectSocialOption}
      />
      )}
      <PatientPayment visible={modalVisibility === 'Patient Payment'} toggleModal={toggleModal} isBillingFinancial />
      <PatientSimpleCharge visible={modalVisibility === 'Simple Charge'} toggleModal={toggleModal} isReplaceable={false} />
      <NotesModal
        visible={selectedSocialOption === 'social-menu-item-notes'}
        toggleModal={selectSocialOption}
        {...otherProps}
      />
      <AuditLogsModal
        visible={selectedSocialOption === 'social-menu-item-audit'}
        toggleModal={selectSocialOption}
        appointmentId={auditLogsProps}
        {...otherProps}
      />
      <UploadPhotoModal
        visible={selectedSocialOption === 'social-menu-item-camra'}
        toggleModal={selectSocialOption}
        {...otherProps}
      />
      <PverifyModal
        visible={selectedSocialOption === 'social-menu-item-pVerify'}
        toggleModal={selectSocialOption}
        patientName={patientInfo?.fullName}
        patientId={patient?.patientId || otherProps?.patientId}
        {...otherProps}
      />
      {(loading || cmsLoading) && <Loader />}
    </div>
  );
};

export default ActionIcons;
