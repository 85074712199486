import React, {
  forwardRef,
  useCallback, useEffect, useImperativeHandle, useMemo, useState,
} from 'react';
import moment from 'moment';

import { apiUrls } from '../../../../../api/constants';

import { isValueUptoTwoDecimalPlaces } from '../../../../../lib/util';

import useCRUD from '../../../../../hooks/useCRUD';
import useRedirect from '../../../../../hooks/useRedirect';

import Input from '../../../../../components/Form/Input';
import WidgetLoader from '../../../../../components/WidgetLoader';

const getTotal = ({ chargesList, total }) => (
  Object.keys(chargesList)?.forEach((item) => {
    chargesList[item].forEach((chargeItem) => {
      // eslint-disable-next-line no-param-reassign
      total.billed += (parseFloat(chargeItem?.billed) || 0);
      // eslint-disable-next-line no-param-reassign
      total.paid += (parseFloat(chargeItem?.paid) || 0);
      // eslint-disable-next-line no-param-reassign
      total.balance += (parseFloat(chargeItem?.balance) || 0);
      // eslint-disable-next-line no-param-reassign
      total.refund += (parseFloat(chargeItem?.refund) || 0);
    });
  })
);

const EditInsuranceRefundTable = forwardRef(({
  labels, lineItemData, setLineItemData, onDataChange = () => { /* This is intentional */ },
}, ref) => {
  const { params } = useRedirect();
  const [chargesList, setChargesList] = useState({});

  const [
    claimDetails,,
    tableDataLoading,
    getClaimDetails,
  ] = useCRUD({
    id: `${apiUrls.GET_EDIT_INSURANCE_REFUND_LINE_ITEMS}-${params.id}`,
    url: apiUrls.GET_EDIT_INSURANCE_REFUND_LINE_ITEMS,
    type: 'read',
  });

  useEffect(() => {
    getClaimDetails({
      PatientId: params?.id,
    });
  }, [getClaimDetails, params.id]);

  useEffect(() => {
    if (claimDetails) {
      const allCharges = {};
      const parsedData = claimDetails?.map(({ charges, payments }) => {
        const parsedPayments = {
          financeId: payments?.financeId,
          dateString: moment(payments?.effectiveDate).format('MM-DD-YYYY'),
          cptCode: payments?.description,
          balance: payments?.balance,
          billed: payments?.amount,
          paid: payments?.paid,
          patientId: payments?.patientId,
        };
        const parsedCharges = charges.map((charge) => ({
          financeId: charge?.financeId,
          dateString: moment(charge?.effectiveDate).format('MM-DD-YYYY'),
          cptCode: charge?.description,
          balance: charge?.balance,
          billed: charge?.amount,
          paid: charge?.approved,
          patientId: charge?.patientId,
        }));
        allCharges[parsedPayments?.financeId] = parsedCharges;
        return { payments: parsedPayments, charges: parsedCharges };
      });
      setChargesList(allCharges || {});
      setLineItemData(parsedData);
      onDataChange(parsedData);
    }
  }, [claimDetails]);

  const handleLineItemData = useCallback((index, chargeIndex) => ({ target: { value, name } }) => {
    if (isValueUptoTwoDecimalPlaces(value)) {
      const clonedLineItemData = [...lineItemData];
      const clonedChargesList = { ...chargesList };

      const currentLineItemData = {
        ...lineItemData[index].charges[chargeIndex],
        [name]: value,
      };
      clonedLineItemData[index].charges[chargeIndex] = {
        ...currentLineItemData,
      };
      clonedChargesList[lineItemData[index]
        .payments.financeId][chargeIndex] = clonedLineItemData[index]?.
      charges[chargeIndex];
      setChargesList(clonedChargesList);
      setLineItemData(clonedLineItemData);
      onDataChange(clonedLineItemData);
    }
  }, [chargesList, lineItemData, onDataChange, setLineItemData]);

  const totals = useMemo(() => {
    const total = {
      billed: 0,
      paid: 0,
      balance: 0,
      refund: 0,
    };
    getTotal({ total, chargesList });
    return total;
  }, [chargesList]);

  useImperativeHandle(ref, () => ({
    checkIfRefundGreaterThanPaid() {
      return totals?.paid < totals?.refund;
    },
  }));

  return (
    <div className="patient-line-item-table posi-relative table-max-height-500 min-width-100">
      {tableDataLoading && <WidgetLoader />}
      <div className="app-table">
        <table>
          <thead>
            <tr>
              <th>{labels.get('tableColumns.dateOfService')}</th>
              <th>{labels.get('tableColumns.description')}</th>
              <th className="text-align-right">{labels.get('tableColumns.billed')}</th>
              <th className="text-align-right">{labels.get('tableColumns.paid')}</th>
              <th className="text-align-right">{labels.get('tableColumns.balance')}</th>
              <th>{labels.get('tableColumns.refund')}</th>
            </tr>
          </thead>
          {lineItemData?.length ? (
            <tbody>
              {lineItemData?.map(({ payments, charges }, index) => (
                <>
                  <tr key={payments?.id} className="payment-table-row">
                    <td className="text-align-left">{payments?.dateString}</td>
                    <td className="text-align-left">{payments?.cptCode}</td>
                    <td className="text-align-right">{parseFloat(payments?.billed || 0).toFixed(2)}</td>
                    <td className="text-align-right">{parseFloat(payments?.paid || 0).toFixed(2)}</td>
                    <td className="text-align-right">{parseFloat(payments?.balance || 0).toFixed(2)}</td>
                    <td className="text-align-right">{payments?.refund && parseFloat(payments?.refund).toFixed(2)}</td>
                  </tr>
                  {charges.map((item, chargeIndex) => (
                    <tr key={item?.id}>
                      <td className="text-align-left">{item?.dateString}</td>
                      <td className="text-align-left">{item?.cptCode}</td>
                      <td className="text-align-right">{parseFloat(item?.billed || 0).toFixed(2)}</td>
                      <td className="text-align-right">{parseFloat(item?.paid || 0).toFixed(2)}</td>
                      <td className="text-align-right">{parseFloat(item?.balance || 0).toFixed(2)}</td>
                      <td>
                        <Input
                          isFormItem={false}
                          numberOnly
                          decimalPlaces={2}
                          maxValueLength={11}
                          labelSpan={0}
                          inputSpan={24}
                          value={item?.refund}
                          onChange={handleLineItemData(index, chargeIndex)}
                          name="refund"
                          dataTestId={`row-${index}`}
                        />
                      </td>
                    </tr>
                  ))}
                </>
              )
              )}
              <tr>
                <td>Total</td>
                <td />
                <td className="text-align-right">{(totals?.billed?.toFixed(2))}</td>
                <td className="text-align-right">{(totals?.paid?.toFixed(2))}</td>
                <td className="text-align-right">{(totals?.balance?.toFixed(2))}</td>
                <td className="text-align-right">{(totals?.refund?.toFixed(2))}</td>
              </tr>
            </tbody>
            )
            : (
              <tbody>
                <tr>
                  <td colSpan={15} className="custom-no-data-text">
                    No Data Found
                  </td>
                </tr>
              </tbody>
  )}
        </table>
      </div>
    </div>
  );
});

export default EditInsuranceRefundTable;

