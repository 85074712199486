import React from 'react';

import { enums } from '../../../../../../../../../lib/constants';

import Form from '../../../../../../../../../components/Form';
import { Input, CheckBox } from '../../../../../../../../../components/Form/inputFields';

import Select from '../../../../../../../../../wiredComponents/Enum/Select';

const NDCForm = ({ labels }) => (
  <Form.Section noOfColumns={2}>
    <Form.Column>
      <Form.Row columnTemplate="4fr 1fr 1fr">
        <Input
          name="ndcManufacturerNumber"
          label={labels.get('labels.ndcNumber')}
          numberOnly
          maxValueLength={5}
          labelSpan="16"
          inputSpan="8"
          required
        />
        <Input
          name="ndcProductNumber"
          numberOnly
          maxValueLength={4}
          labelSpan="0"
          inputSpan="24"
          required
        />
        <Input
          name="ndcPackageNumber"
          numberOnly
          maxValueLength={2}
          labelSpan="0"
          inputSpan="24"
          required
        />
      </Form.Row>
      <Input
        name="drugName"
        label={labels.get('labels.drugName')}
      />
      <Input
        name="supplierName"
        label={labels.get('labels.supplierName')}
      />
      <Input
        name="ndcQuantity"
        label={labels.get('labels.ndcQuantity')}
        numberOnly
        required
        decimalPlaces={2}
      />
      <Select
        name="ndcQuantityUnit"
        label={labels.get('labels.ndcQuantityUnit')}
        enumName={enums.NDC_QUANTITY_UNIT}
        required
      />
      <CheckBox
        name="isActive"
        label={labels.get('labels.active')}
        valuePropName="checked"
      />
    </Form.Column>
    <Form.Column />
  </Form.Section>
);

export default NDCForm;
