import React, { useEffect, useMemo } from 'react';

import { apiUrls } from '../../api/constants';
import { listId } from '../../lib/constants';

import withReduxManager from '../../hoc/withReduxManager';
import withQuery from '../../hoc/withQuery/withQuery';

import columns from './columns';
import TableWrapper from './TableWrapper';

const initialSort = [{ id: 'name', desc: false }];

const TableSubTitleWrapper = (props) => {
  const {
    Component: { props: { setTableSubTitle = () => { /* This is intentional */ } } = {} },
    data,
  } = props;
  useEffect(() => {
    if (data?.length) {
      setTableSubTitle(data[0]?.insurancePayerName);
    }
  }, [data]);
  return null;
};

const CollectionClaimsTable = ({
  labels, claimNumber, setTableSubTitle, isPatientResponsibility, isCollectionCharges, ...otherProps
}) => {
  const ClaimsTable = useMemo(() => withReduxManager({
    listId: `${listId.PATIENT_CLAIM_LIST}-${claimNumber}`,
  })(withQuery({
    url: `${apiUrls.PATIENT_CLAIM_LIST}`,
    listId: `${listId.PATIENT_CLAIM_LIST}-${claimNumber}`,
    accessor: (data) => ({ result: data }),
  })()), [claimNumber]);
  return (
    <ClaimsTable
      footer
      columns={columns(labels)}
      initialSort={initialSort}
      noDataText="Patient Claims  not found"
      filters={{
        claimId: claimNumber, isPatientResponsibility, isCollectionCharges,
      }}
      setTableSubTitle={setTableSubTitle}
      {...otherProps}
    >
      {({
        Component, reFetch, data,
      }) => (
        <>
          <TableWrapper Component={Component} reFetch={reFetch} />
          <TableSubTitleWrapper Component={Component} data={data} />
        </>
      )}
    </ClaimsTable>
  );
};

export default CollectionClaimsTable;
