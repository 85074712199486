import React from 'react';

import TimeCell from '../../components/Cells/TimeCell';

const columns = (labels) => [
  {
    Header: labels.get('tableColumns.date'),
    accessor: 'appointmentStartTime',
    Cell: ({ row: { original } }) => (
      <TimeCell
        value={original?.appointmentStartDateTime?.dateString}
      />
    ),
    sort: true,
  },
  {
    Header: labels.get('tableColumns.time'),
    accessor: 'time',
    Cell: ({ row: { original } }) => (
      <TimeCell
        value={original?.appointmentStartDateTime?.dateString}
        format="hh:mm a"
      />
    ),
    sort: true,
  },
  // {
  //   Header: labels.get('tableColumns.resource'),
  //   accessor: 'resource',
  //   sort: true,
  // },
  {
    Header: labels.get('tableColumns.provider'),
    accessor: 'providerFirstName',
    Cell: ({ row: { original: { providerFirstName, providerLastName } } }) => (
      (providerFirstName || providerLastName) ? `${providerFirstName} ${providerLastName}` : ''
    ),
    sort: true,
  },
  {
    Header: labels.get('tableColumns.location'),
    accessor: 'locationName',
    sort: true,

  },
  {
    Header: labels.get('tableColumns.visitType'),
    accessor: 'visitType',
    Cell: ({ row: { original: { visitType } } }) => visitType?.visitName,
    sort: true,
  },
  {
    Header: labels.get('tableColumns.cancellationReason'),
    accessor: 'reason',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.status'),
    accessor: 'status',
    sort: true,
  }, {
    Header: labels.get('tableColumns.eligibility'),
    accessor: 'eligibility',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.addedOn'),
    accessor: 'createdOn',
    Cell: ({ row: { original } }) => <TimeCell value={original?.createdOn} />,
  },
  {
    Header: labels.get('tableColumns.addedBy'),
    accessor: 'createdBy',
    Cell: ({ row: { original } }) => <span>{original?.createdByName}</span>,
  },
];

export default columns;
