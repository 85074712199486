import React from 'react';
import get from 'lodash/get';
import moment from 'moment';

import Form from '../../../../../components/Form';
import Label from '../../../../../components/Label';
import { formatPhoneNumber } from '../../../../../lib/util';
import InsuranceVerificationShieldIcon from '../InsuranceVerificationShieldIcon';
import { dateFormat } from '../../../../../lib/constants';

const getAbb = (name) => {
  if (name) {
    return name[0].toUpperCase();
  }
  return 'N/A';
};

const PPO = (props) => {
  const {
    policyData, labels,
  } = props;
  return (
    <div className="form-container view-mode">
      <form>
        <div className="heading-with-icon align-center no-top-mr">
          <h2 className="h2-title">{`${policyData.policyType} (${getAbb(policyData.profileType)}) `}</h2>
          <InsuranceVerificationShieldIcon
            verificationStatus={policyData?.verificationStatus}
            lastVerificationDate={policyData?.lastVerificationDate}
          />
        </div>

        <Form.Section noOfColumns={3}>
          <Form.Column>
            <div className="group-shadow">
              <div className="posi-relative combine-fields">
                <div md={10}><Label label={labels.get('labels.healthPlan')} /></div>
                <div md={14} className="value-view">{get(policyData, 'healthPlan', '')}</div>
              </div>
              <div className="posi-relative combine-fields">
                <div md={10}><Label label={labels.get('labels.payerName')} /></div>
                <div md={14} className="value-view">{get(policyData, 'payerName', '')}</div>
              </div>
              <div className="posi-relative combine-fields">
                <div md={10}><Label label={labels.get('labels.pverifyPolicyType')} /></div>
                <div md={14} className="value-view">{get(policyData, 'pverifyPolicyType', '')}</div>
              </div>
              <div className="posi-relative combine-fields">
                <div md={10}><Label label={labels.get('labels.planName')} /></div>
                <div md={14} className="value-view">{get(policyData, 'pverifyPlanName', '')}</div>
              </div>
              <div className="posi-relative combine-fields">
                <div md={10}><Label label={labels.get('labels.policyType')} /></div>
                <div md={14} className="value-view">{get(policyData, 'profileType', '')}</div>
              </div>
            </div>
          </Form.Column>
          <Form.Column>
            <div className="group-shadow">
              <div className="posi-relative combine-fields">
                <div md={10}><Label label={labels.get('labels.payerAddress')} /></div>
                <div md={14} className="value-view">{get(policyData, 'payerAddressLine1', '')}</div>
              </div>
              <div className="posi-relative combine-fields">
                <div md={10}><Label label={labels.get('labels.policyName')} /></div>
                <div md={14} className="value-view">{get(policyData, 'policyType', '')}</div>
              </div>
              <div className="posi-relative combine-fields">
                <div md={9}><Label label={labels.get('labels.payerPhone')} /></div>
                <div md={15} className="value-view">{formatPhoneNumber(get(policyData, 'payerPrimaryPhone', ''))}</div>
              </div>
              <div className="posi-relative combine-fields">
                <div md={9}>
                  <Label label={labels.get('labels.memberNumber')} />
                </div>
                <div md={15} className="value-view">{get(policyData, 'memberNumber', '')}</div>
              </div>
              <div className="posi-relative combine-fields">
                <div md={9}><Label label={labels.get('labels.groupNumber')} /></div>
                <div md={15} className="value-view">{get(policyData, 'groupNumber', '')}</div>
              </div>
            </div>
          </Form.Column>
          <Form.Column>
            <div className="group-shadow">
              <div className="posi-relative combine-fields">
                <div md={9}><Label label={labels.get('labels.groupName')} /></div>
                <div md={15} className="value-view">{get(policyData, 'groupName', '')}</div>
              </div>
              <div className="posi-relative combine-fields">
                <div md={9}><Label label={labels.get('labels.coPayment')} /></div>
                <div md={15} className="value-view">{(get(policyData, 'coPayment', '')).toFixed(2)}</div>
              </div>
              <div className="posi-relative combine-fields">
                <div md={9}><Label label={labels.get('labels.deductible')} /></div>
                <div md={15} className="value-view">{(get(policyData, 'deductible', '')).toFixed(2)}</div>
              </div>
              <div className="posi-relative combine-fields">
                <div md={9}><Label label={labels.get('labels.effectiveFrom')} /></div>
                <div md={15} className="value-view">{policyData?.effectiveFrom && moment.utc(policyData.effectiveFrom).format(dateFormat)}</div>
              </div>
              <div className="posi-relative combine-fields">
                <div md={9}><Label label={labels.get('labels.effectiveTo')} /></div>
                <div md={15} className="value-view">{policyData?.effectiveTo && moment.utc(policyData.effectiveTo).format(dateFormat)}</div>
              </div>
            </div>
          </Form.Column>
        </Form.Section>
      </form>
    </div>
  );
};

export default PPO;
