/* eslint-disable camelcase */
import React, { useCallback, useState } from 'react';
import AutoComplete from '../../components/Form/AutoComplete';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { apiUrls } from '../../api/constants';
import { getName } from '../../lib/util';
import { commonReffPhysicianAndAddressBookAutoCompleteDefaultPropType } from '../commonDefaultPropType';

const npiOptionRenderer = (item) => (
  <div className="option" key={item.addressBookId}>
    <span style={{ flex: 2 }} title={item.name}>
      <span>{item.name}</span>
    </span>
    <span style={{ flex: 2 }} title={item.npi}>
      <span>{item.npi}</span>
    </span>
    <span style={{ flex: 2 }} title={item.speciality}>
      {item.speciality}
    </span>
    <span style={{ flex: 4 }}>
      <div className="team-row">
        {item?.addresses?.map((address) => (
            address?.address_purpose === 'LOCATION'
              && (
                <>
                  <span>{`${address?.address_1 || ' '}, ${address?.address_2 || ' '}`}</span>
                  <br />
                  <span>{`${address?.city || ' '}, ${address?.postal_code || ' '}`}</span>
                  <br />
                  <span>
                    <strong>Phone:</strong>
                    {' '}
                    {`${address?.telephone_number || '-'}`}
                  </span>
                  <br />
                  <span>
                    <strong>Fax:</strong>
                    {' '}
                    {`${address?.fax_number || '-'}`}
                  </span>
                </>
              )))}
      </div>
    </span>
    <span style={{ flex: 4 }}>
      {item?.addresses?.map((address) => (
          address?.address_purpose === 'MAILING'
            && (
            <>
              <span>{`${address?.address_1 || ' '}, ${address?.address_2 || ' '}`}</span>
              <br />
              <span>{`${address?.city || ' '}, ${address?.postal_code || ' '}`}</span>
              <br />
              <span>
                <strong>Phone:</strong>
                {' '}
                {`${address?.telephone_number || '-'}`}
              </span>
              <br />
              <span>
                <strong>Fax:</strong>
                {' '}
                {`${address?.fax_number || '-'}`}
              </span>

            </>
            )))}
    </span>
  </div>
);
const optionHeaderRenderer = (options) => (options?.length ? (
  <div className="option headerWrapper">
    <span className="header" style={{ flex: 2 }}>
      Name
    </span>
    <span className="header" style={{ flex: 2 }}>
      NPI
    </span>
    <span className="header" style={{ flex: 2 }}>
      Specialty
    </span>
    <span className="header" style={{ flex: 4 }}>
      Primary Address
    </span>
    <span className="header" style={{ flex: 4 }}>
      Mailing Address
    </span>
  </div>
) : '');

const ReferringPhysicianCareTeam = ({
  labels,
  form,
  disabled,
  required,
  initialValue,
  labelSpan,
  inputSpan,
  onSelect,
  styleWidth,
  name,
  className,
  dropdownMatchSelectWidth,
  selectProps,
  ...props
}) => {
  const [setAutoCompleteValue, handleAutoCompleteValue] = useState(null);
  const npiOptionParser = useCallback((option) => ({
    name: getName(option.firstName, option.middleName, option.lastName),
    value: option.npi,
    ...option,
  }), []);

  const onNPISelect = useCallback((item) => {
    if (onSelect) onSelect(item, { item });
    form.setFields([{ name: 'providerName', value: item?.name }]);
  }, [form, onSelect]);

  const setError = useCallback(() => {
    if (required) {
      form.setFields([{
        name,
        value: null,
        errors: ['address book is Required'],
      }]);
    }
  }, [form, name, required]);

  useUpdateEffect(() => {
    if (!required && disabled) {
      setAutoCompleteValue(null);
      form.setFields([{
        name,
        value: null,
        errors: [],
      }]);
    } else if (required) {
      form.setFields([{
        name,
        value: null,
        errors: ['npi is Required'],
      }]);
    }
  }, [required]);

  return (
    <div className="filter-patient-autocomplete">
      <AutoComplete
        label=""
        placeholder=""
        name={name}
        url={apiUrls.GET_REFERRING_PHYSICIAN}
        optionParser={npiOptionParser}
        optionHeaderRenderer={optionHeaderRenderer}
        optionMaster="SearchText"
        labelSpan={labelSpan}
        inputSpan={inputSpan}
        disabled={disabled}
        required={required}
        initialValue={initialValue}
        optionRenderer={npiOptionRenderer}
        style={styleWidth ? { width: '100%' } : {}}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        onSelect={onNPISelect}
        setAutoCompleteValue={handleAutoCompleteValue}
        setError={setError}
        className={className}
        notFoundContent="Provider is not present is the system."
        showArrow
        labelInValue
        selectProps={{ ...selectProps, optionLabelProp: 'name', allowClear: true }}
        {...props}
      />
    </div>
  );
};

ReferringPhysicianCareTeam.defaultProps = {
  disabled: false,
  required: false,
  initialValue: {},
  labelSpan: '10',
  inputSpan: '13',
  onSelect: () => { /* This is intentional */ },
  styleWidth: true,
  name: 'patientName',
  className: '',
  dropdownMatchSelectWidth: 350,
};

ReferringPhysicianCareTeam.propTypes = {
  ...commonReffPhysicianAndAddressBookAutoCompleteDefaultPropType,
};

export default ReferringPhysicianCareTeam;
