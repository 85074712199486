import React, {
  useCallback, useState, useEffect, useMemo, useRef,
} from 'react';
import { Form as AntdForm } from 'antd';
import get from 'lodash/get';

import Modals from '../../../../../../components/Modal';
import Button from '../../../../../../components/Button';
import Notification from '../../../../../../components/Notification';
import Loader from '../../../../../../components/Loader';
import Form from '../../../../../../components/Form';

import ErrorMessages from '../../../../../../lib/errorMessages';
import SuccessMessages from '../../../../../../lib/successMessages';
import {
  labelPaths, UiRoutes, listId, formId,
} from '../../../../../../lib/constants';

import WithLabel from '../../../../../../hoc/withLabel';

import useRedirect from '../../../../../../hooks/useRedirect';
import useCRUD from '../../../../../../hooks/useCRUD';

import { apiUrls } from '../../../../../../api/constants';

import PatientClaimTable from '../../../../../../wiredComponents/CollectionClaimsTable';
import InsuranceDetailsSelect from '../../../../../../wiredComponents/InsuranceDetailsSelect';
import ChangeResponsibilityForm from './Form';
import Events from '../../../../../../lib/events';
import { getuuid } from '../../../../../../lib/util';
import CreatePayerClaimPopUp from '../CreateNewClaimPopup/createPayerClaimPopup';
import AuthorizationAndCase from '../AuthorizationAndCase';

const ChangeResponsibilityPopup = ({
  labels, queueId, stateId, stageId,
}) => {
  const [selectedRows, setSelectedRow] = useState([]);
  const [claimDelivery, setClaimDelivery] = useState();
  const [isCorrected, setIsCorrected] = useState();
  const [payerResponsibility, setResponsibility] = useState();
  const [createPayerClaimPopUpVisibility, setCreatePayerClaimPopUpVisibility] = useState(false);
  const isPayerClaimAddedRef = useRef(false);
  const [tabId] = useState(getuuid);
  const [form] = AntdForm.useForm();
  const [payerClaimForm] = AntdForm.useForm();

  const {
    params, push, generatePath,
  } = useRedirect();

  const { claimNumber, id: patientId } = params;

  const [response, , loading, updateResponsibility, clear] = useCRUD({
    id: listId.CHANGE_INSURANCE_RESPONSIBILITY,
    url: apiUrls.CHANGE_INSURANCE_RESPONSIBILITY,
    type: 'update',
  });

  const [
    claimDetails,,, getClaimDetails, ,
  ] = useCRUD({
    id: listId.GET_CLAIMS_DETAILS,
    url: apiUrls.GET_CLAIMS_DETAILS,
    type: 'read',
  });

  const {
    claimId, dateOfService, insuranceProfileId, insuranceProfileDetailId, encounterId,
  } = useMemo(() => {
    if (claimDetails) {
      const { billingEncounterData } = claimDetails;
      return {
        claimId: billingEncounterData?.serviceLineItems[0]?.claimDetails?.claimId,
        dateOfService: billingEncounterData?.serviceLineItems[0]?.serviceDate,
        insuranceProfileId: billingEncounterData?.insurance?.insuranceProfileId,
        insuranceProfileDetailId: billingEncounterData?.insurance?.insuranceProfileDetailId,
        encounterId: billingEncounterData?.billingEncounterId,
      };
    }
    return {};
  }, [claimDetails]);

  const [encounterDataResponse, , , getEncounter] = useCRUD({
    url: `${apiUrls.GET_PATIENT_ENCOUNTER}`,
    id: `${apiUrls.GET_PATIENT_ENCOUNTER}-billing-encounter-${encounterId}`,
    type: 'read',
  });

  useEffect(() => {
    if (encounterId && !encounterDataResponse) {
      getEncounter({ isCollectionCharges: true }, `/${encounterId}`);
    }
  }, [encounterId]);

  useEffect(() => {
    if (encounterDataResponse && form && encounterId) {
      form.setFieldsValue({
        authorizationId: encounterDataResponse.authorization?.authorizationId,
        caseId: encounterDataResponse.encounterDetails?.caseId,
      });
    }
  }, [encounterDataResponse, form]);

  const onCancel = useCallback(() => {
    push(generatePath(UiRoutes.claimsCollections, { ...params }));
  }, [push, generatePath, params]);

  const toggleCreatePayerClaimPopup = useCallback(() => {
    setCreatePayerClaimPopUpVisibility((value) => !value);
  }, []);

  useEffect(() => {
    if (response) {
      Notification({
        message: SuccessMessages.INSURANCE_RESPONSIBILITY_CHANGED_SUCCESSFULLY,
        success: true,
      });
      clear();
      if (encounterId) {
        getEncounter({ isCollectionCharges: true }, `/${encounterId}`);
      }
      Events.trigger('refetch-claim-overview');
      onCancel();
    }
  }, [response]);

  useEffect(() => {
    getClaimDetails({}, `/${claimNumber}`);
  }, [claimNumber, getClaimDetails]);

  const changeResponsibilityHandler = useCallback(() => {
    if (!selectedRows?.length) {
      return Notification({ message: ErrorMessages.AT_LEAST_ONE_CLAIM_SELECTED });
    }
    if (!payerResponsibility?.payerId) {
      return Notification({ message: 'Please select insurance payer.' });
    }
    if (!claimDelivery) {
      return Notification({ message: 'Please select delivery method.' });
    }
    if (!isPayerClaimAddedRef.current && isCorrected) {
      setCreatePayerClaimPopUpVisibility(true);
      return true;
    }
    return updateResponsibility({
      billingEncounterServiceLineIds: (
        selectedRows.map(({ original }) => original?.billingEncounterServiceLineId)
      ),
      deliveryMethodId: claimDelivery,
      sendAsCorrectedClaim: isCorrected,
      BillingEncounterId: claimDetails?.billingEncounterData?.billingEncounterId,
      payerId: payerResponsibility?.payerId,
      patientId: claimDetails?.billingEncounterData?.patient?.patientId,
      payerClaimNo: payerClaimForm.getFieldValue('payerClaimNo'),
      insuranceProfileDetailId: payerResponsibility?.insuranceProfileDetailId,
      queueId,
      stateId,
      stageId,
      includeInactiveInsurance: true,
      caseId: form.getFieldValue('caseId'),
      authorizationId: form.getFieldValue('authorizationId'),
      isCollectionCharges: true,
    }, `/${claimNumber}`);
  }, [selectedRows, payerResponsibility, claimDelivery, isCorrected, updateResponsibility,
    claimDetails, payerClaimForm, queueId, stateId, stageId, claimNumber, form]);

  const onInsuranceSelect = useCallback((id, _all) => {
    const { item } = _all;
    setResponsibility({
      payerId: item?.payerId,
      insuranceProfileDetailId: item?.insuranceProfileDetailId,
    });
  }, []);

  const handleSubmitClaim = useCallback(() => {
    if (!payerClaimForm.getFieldValue('payerClaimNo')) {
      return Notification({ message: ErrorMessages.PLEASE_FILL_ALL_REQUIRED_FIELDS });
    }
    isPayerClaimAddedRef.current = true;
    toggleCreatePayerClaimPopup();
    changeResponsibilityHandler();
    return null;
  }, [changeResponsibilityHandler, payerClaimForm, toggleCreatePayerClaimPopup]);

  const onRequestComplete = useCallback(() => {
    isPayerClaimAddedRef.current = true;
    changeResponsibilityHandler();
  }, [changeResponsibilityHandler]);

  const handleRadio = useCallback(({ target: { value } }) => setClaimDelivery(value), []);
  const handleCheckbox = useCallback(({ target: { checked } }) => setIsCorrected(checked), []);

  return (
    <>
      <Modals
        visible
        width={1000}
        toggleModal={onCancel}
        className="change-responsibility-modal"
        footer={[
          <div className="group-btns" key="footer">
            <Button className="btn btn-success min-wt-86 inline" onClick={changeResponsibilityHandler}>
              {labels.get('buttons.changeResponsibility')}
            </Button>
            <Button className="btn min-wt-86" onClick={onCancel}>{labels.get('buttons.cancel')}</Button>
          </div>,
        ]}
        destroyOnClose
      >
        <div className="addition-header posi-relative">
          {(loading) && <Loader />}
          <div className="lookup sprite-img-before">
            <p>{labels.get('titles.changeResponsibility')}</p>
          </div>
        </div>
        <p className="current-payer">
          {`${labels.get('titles.currentPayerName')} ${claimDetails?.billingEncounterData?.insurancePayer?.insurancePayerName || ''}`}
        </p>
        <div style={{ width: '50%' }} className="insurance-dropdown-wrapper">
          <InsuranceDetailsSelect
            id={`${apiUrls.INSURANCE_PROFILE_DROPDOWN}-${patientId}`}
            name="insurancedetailId"
            label="Insurance Info"
            labelSpan="6"
            inputSpan="18"
            patientId={patientId}
            selectProps={{ allowClear: false }}
            onSelect={onInsuranceSelect}
            tabId={`${tabId}-change-responsibility`}
            includePayerName
            includeInActive
            dateOfService={dateOfService}
            dosRequired
          />
        </div>
        <Form
          form={form}
        >
          <AuthorizationAndCase
            key="change-responsibility"
            patientId={patientId}
            insuranceProfileId={insuranceProfileId}
            insuranceProfileDetailId={insuranceProfileDetailId}
            form={form}
            tabId={tabId}
          />
        </Form>
        <PatientClaimTable
          labels={labels}
          claimNumber={claimNumber}
          showRowSelection
          setSelectedRow={setSelectedRow}
          checkedRowIds={selectedRows}
          isCollectionCharges
        />
        <Form
          form={form}
          onRequestComplete={onRequestComplete}
          isUpdate
          formId={formId.NEW_CLAIM}
          url={`${apiUrls.CREATE_NEW_CLAIM}/${claimId}`}
          extraData={{
            billingEncounterServiceLineIds:
           selectedRows.map(({
             original: { billingEncounterServiceLineId },
           }) => billingEncounterServiceLineId),
            billingEncounterId: get(claimDetails?.billingEncounterData, 'billingEncounterId'),
            existingBillingClaimId: claimId,
            queueId,
            stateId,
            stageId,
            isCollectionCharges: true,
          }}
        >
          <ChangeResponsibilityForm
            labels={labels}
            claimDelivery={claimDelivery}
            handleRadio={handleRadio}
            isCorrected={isCorrected}
            handleCheckbox={handleCheckbox}
          />
        </Form>
      </Modals>
      {createPayerClaimPopUpVisibility && (
      <CreatePayerClaimPopUp
        isVisible={createPayerClaimPopUpVisibility}
        toggleModal={toggleCreatePayerClaimPopup}
        handlePayerClaimSave={handleSubmitClaim}
        form={payerClaimForm}
      />
      )}
    </>
  );
};

export default WithLabel(ChangeResponsibilityPopup, labelPaths.CHANGE_RESPONSIBILITY_POPUP);
