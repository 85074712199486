import React, { useCallback } from 'react';
import findIndex from 'lodash/findIndex';

import AllowedProcedureLookup from '../../../../../../../wiredComponents/AllowedProcedureLookup';
import { apiUrls } from '../../../../../../../api/constants';

const AllowedProcedure = (props) => {
  const { labels, allowedProcedure, setAllowedProcedure } = props;

  const handleDiagnosticsIds = useCallback((value, allItems) => {
    const { item } = allItems;
    const index = findIndex(allowedProcedure, (diagnostic) => item.id === diagnostic.id
    || item.id === diagnostic.diagnosticId);
    if (index < 0) {
      setAllowedProcedure([...allowedProcedure, item]);
    }
  }, [allowedProcedure, setAllowedProcedure]);

  const deleteDiagnosticId = useCallback((id) => {
    const index = findIndex(allowedProcedure, (item) => item.id === id || item.diagnosticId === id);
    const cloned = [...allowedProcedure];
    cloned.splice(index, 1);
    setAllowedProcedure(cloned);
  }, [allowedProcedure, setAllowedProcedure]);

  return (
    <AllowedProcedureLookup
      title={labels.get('titles.modalAllowedProcedures')}
      onSelect={handleDiagnosticsIds}
      data={allowedProcedure}
      deleteDiagnosticId={deleteDiagnosticId}
      url={apiUrls.GET_CPTS}
      {...props}
    />
  );
};

export default AllowedProcedure;
