import React from 'react';
import Form from '../../../../../../components/Form';
import {
  Input, CheckBox,
} from '../../../../../../components/Form/inputFields';
import DatePicker from '../../../../../../components/Form/DatePicker';
import { Select } from '../../../../../../wiredComponents/Enum';
import WiredSelect from '../../../../../../wiredComponents/Select';

function PostNursing() {
  return (
    <div>
      <div className="main-form-container">
        <div className="Nursing-form-wrap">
          <Form.Section noOfColumns={3}>

            <div className="group-shadow">
              <Form.Column>
                <Input
                  label="Height"
                  id="1"
                  name="Height"
                  labelSpan="10"
                  inputSpan="14"
                />
                <Input
                  label="Weight"
                  id="2"
                  name="Weight"
                  labelSpan="10"
                  inputSpan="14"
                />
                <Select
                  label="ASA"
                  name="ASA"
                  id=""
                  labelSpan="10"
                  inputSpan="14"
                />
                <DatePicker
                  label="Anesthesia
                Start Time:"
                  id=""
                  labelSpan="10"
                  inputSpan="14"
                />
                <DatePicker
                  label="Anesthesia
                  End Time:"
                  id=""
                  labelSpan="10"
                  inputSpan="14"
                />
                <DatePicker
                  label="Surgery
                Start Time:"
                  id=""
                  labelSpan="10"
                  inputSpan="14"
                />
                <DatePicker
                  label="Surgery
                  End Time:"
                  id=""
                  labelSpan="10"
                  inputSpan="14"
                />
                <CheckBox
                  label="Allergies Confirmed"
                  name="isOptOutReferringPhysician"
                  labelSpan="10"
                  inputSpan="14"
                />
                <Select
                  label="Anesthesia Plan"
                  name=""
                  id=""
                  labelSpan="10"
                  inputSpan="14"
                />
              </Form.Column>
            </div>

            <div className="group-shadow">
              <Form.Column>
                <CheckBox
                  label="Anesthesia Plan"
                  name=""
                  labelSpan="10"
                  inputSpan="14"
                />
                <CheckBox
                  label="Procedure Confirmed"
                  name=""
                  labelSpan="10"
                  inputSpan="14"
                />
                <WiredSelect
                  label="Significant Medical History Values"
                  labelSpan="10"
                  inputSpan="14"
                />
                <Input
                  label="Other"
                  id=""
                  name=""
                  labelSpan="10"
                  inputSpan="14"
                />

                <Select
                  label="IV Totals"
                  name=""
                  id=""
                  labelSpan="10"
                  inputSpan="14"
                />
                <Input
                  label="IV Totals Amount"
                  id=""
                  name=""
                  labelSpan="10"
                  inputSpan="14"
                />
                <CheckBox
                  label="Medications Reviewed"
                  name=""
                  labelSpan="10"
                  inputSpan="14"
                />
                <CheckBox
                  label="Labs Reviewed"
                  name=""
                  labelSpan="10"
                  inputSpan="14"
                />
                <CheckBox
                  label="Labs Abnormal"
                  name=""
                  labelSpan="10"
                  inputSpan="14"
                />
              </Form.Column>
            </div>

            <div className="group-shadow">
              <Form.Column>

                <Input
                  label="EBL"
                  id=""
                  name=""
                  labelSpan="10"
                  inputSpan="14"
                />
                <CheckBox
                  label="Anesthesia Hx Reviewed"
                  name=""
                  labelSpan="10"
                  inputSpan="14"
                />
                <CheckBox
                  label="Preop Abx Given"
                  name=""
                  labelSpan="10"
                  inputSpan="14"
                />
                <DatePicker
                  label="TIME"
                  id=""
                  labelSpan="10"
                  inputSpan="14"
                />
                <Select
                  label="Oxygen"
                  name=""
                  id=""
                  labelSpan="10"
                  inputSpan="14"
                  required
                />
                <Input
                  label="Other"
                  id=""
                  name=""
                  labelSpan="10"
                  inputSpan="14"
                />
                <Input
                  label="Nitrous Oxide"
                  id=""
                  name=""
                  labelSpan="10"
                  inputSpan="14"
                />
                <Select
                  label="Agents"
                  name=""
                  id=""
                  labelSpan="10"
                  inputSpan="14"
                  required
                />
              </Form.Column>
            </div>

          </Form.Section>
        </div>
        <div className="post-nursing-graph">
          <img src="' + process.env.PUBLIC_URL + '/images/graph-img.png" alt="temp-img" />
        </div>
      </div>
    </div>
  );
}

export default PostNursing;
