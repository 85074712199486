import React, {
  useCallback, useState, useEffect, useMemo,
} from 'react';
import moment from 'moment';

import ExceptionTable from './ExceptionTable';
import DatePicker from '../../../../../../../components/Form/DatePicker';
import Button from '../../../../../../../components/Button';
import SelectBox from '../../../../../../../components/Form/SelectBox';
import Loader from '../../../../../../../components/Loader';
import Icon from '../../../../../../../components/Icon';

import iconNames from '../../../../../../../lib/iconNames';
import dateFormatter from '../../../../../../../lib/dateFormatter';
import { dateFormatWithSlash } from '../../../../../../../lib/constants';
import useCRUD from '../../../../../../../hooks/useCRUD';

import { apiUrls } from '../../../../../../../api/constants';
import useRedirect from '../../../../../../../hooks/useRedirect';

import './exceptions.scss';
import { Input } from '../../../../../../../components/Form/inputFields';
import idCalculator from '../../../../../../../lib/idCalculator';

const dateCalculator = (day) => moment().add(day, 'days').format(dateFormatWithSlash);

const scheduleOptionsFake = [
  {
    name: `${dateCalculator(1)} - ${dateCalculator(2)}`,
    value: 1,
    effectiveFrom: dateCalculator(1),
    effectiveTo: dateCalculator(2),
  },
  {
    name: `${dateCalculator(2)} - ${dateCalculator(3)}`,
    value: 2,
    effectiveFrom: dateCalculator(2),
    effectiveTo: dateCalculator(3),
  },
  {
    name: `${dateCalculator(3)} - ${dateCalculator(4)}`,
    value: 3,
    effectiveFrom: dateCalculator(3),
    effectiveTo: dateCalculator(4),
  },
];
const { REACT_APP_FAKE } = process.env;

const Exception = ({ labels, isProviderViewOnly }) => {
  const [editSchedule, setEditSchedule] = useState(false);
  const [newSchedule, setNewSchedule] = useState(false);
  const [schedule, setSchedule] = useState({});
  const [feeExceptionScheduleId, setFeeExceptionScheduleId] = useState(null);
  const [feeExceptionSearch, setFeeExceptionSearch] = useState('');
  const [scheduleOptions, setScheduleOptions] = useState(
    (REACT_APP_FAKE ? scheduleOptionsFake : []),
  );

  const { params: { id } } = useRedirect();

  const [dates, , loading, getDates, clearDates] = useCRUD({
    id: 'provider-fee-preferences-date',
    url: apiUrls.PROVIDER_FEE_PREFERENCES_EXCEPTION,
    type: 'read',
  });

  const getAllScheduleDates = useCallback(() => {
    getDates({ ProviderId: id });
  }, [idCalculator]);

  useEffect(() => {
    getAllScheduleDates();
  }, []);

  useEffect(() => {
    if (dates) {
      const dateOptions = dates.map((item) => ({
        effectiveFrom: dateFormatter(item.effectiveFrom?.split('T')?.[0]),
        effectiveTo: dateFormatter(item.effectiveTo?.split('T')?.[0]),
        value: item.feePreferencesExceptionSchedulerId,
        name: `${dateFormatter(item.effectiveFrom?.split('T')?.[0])} - ${dateFormatter(item.effectiveTo?.split('T')?.[0])}`,
      }));
      setScheduleOptions(dateOptions);
      clearDates(true);
    }
  }, [setScheduleOptions, dates, clearDates]);

  const scheduleHandler = useCallback(() => {
    setEditSchedule(!editSchedule);
  }, [setEditSchedule, editSchedule]);

  const onChangeSchedule = useCallback((value, selectedItem) => {
    let selectedSchedule = selectedItem?.item || {};
    if (REACT_APP_FAKE) {
      const scheduleData = scheduleOptionsFake.filter((currentSchedule) => (
        currentSchedule.value === value
      ));
      selectedSchedule = { ...scheduleData[0] };
    } else setFeeExceptionScheduleId(value);
    setSchedule(selectedSchedule);
  }, [setSchedule]);

  const onSearch = useCallback(({ target: { value } }) => {
    setFeeExceptionSearch(value);
  }, [setFeeExceptionSearch]);

  const onChangeDate = useCallback((label) => (date) => {
    const clonedSchedule = { ...schedule };
    clonedSchedule[label] = dateFormatter(date);
    setSchedule(clonedSchedule);
  }, [schedule, setSchedule]);

  const onNewSchedule = useCallback(() => {
    setSchedule({
      effectiveFrom: schedule?.effectiveFrom || dateFormatter(moment()),
      effectiveTo: schedule?.effectiveTo || dateFormatter(moment()),
    });
    setEditSchedule(true);
    setNewSchedule(true);
  }, [setSchedule, schedule]);

  const onCancelNewSchedule = useCallback(() => {
    setEditSchedule(false);
    setNewSchedule(false);
    setFeeExceptionScheduleId(feeExceptionScheduleId);
  }, [setEditSchedule, setNewSchedule, feeExceptionScheduleId]);

  const onCancelEditSchedule = useCallback(() => {
    setEditSchedule(false);
  }, []);
  const onScheduleAdded = useCallback((latestScheduleId) => {
    setSchedule({ ...schedule, value: latestScheduleId });
    setEditSchedule(false);
    setNewSchedule(false);
    setFeeExceptionScheduleId(latestScheduleId);
    getAllScheduleDates();
  }, [schedule, getAllScheduleDates]);

  const onUpdateSchedule = useCallback(() => {
    if (editSchedule) {
      setEditSchedule(false);
      getAllScheduleDates();
    }
  }, [editSchedule, getAllScheduleDates]);

  const { effectiveFrom, effectiveTo } = useMemo(() => schedule, [schedule]);

  return (
    <div className="exceptions">
      {loading && <Loader />}
      <div className="lists-wrap">
        {!isProviderViewOnly && (
          <Button className="btn btn-success sm-btn" onClick={onNewSchedule}>New Schedule</Button>
        )}
        <div className="effective-filter">
          <div className="feild-row display-view">
            {!editSchedule && (
            <div>
              <div className="flex align-center">
                <SelectBox
                  label="Effective Dates:"
                  labelSpan="10"
                  inputSpan="14"
                  options={scheduleOptions}
                  selectProps={{
                    onChange: onChangeSchedule,
                  }}
                  value={feeExceptionScheduleId}
                  name={null}
                />
                {Object.values(schedule).length > 0 && (
                  !isProviderViewOnly
                && <Icon className="edit-schedule" name={iconNames.edit} onClick={scheduleHandler} />
                )}
              </div>
            </div>
            )}
            {editSchedule && (
              <>
                <div className="date-from">
                  <DatePicker
                    label={labels.get('labels.effectiveDates')}
                    id="13"
                    name={null}
                    placeholder="From"
                    inputSpan="15"
                    labelSpan="9"
                    value={effectiveFrom ? moment(effectiveFrom) : ''}
                    onChange={onChangeDate('effectiveFrom')}
                  />
                </div>
                <div className="date-to">
                  <DatePicker
                    label="To"
                    id="13"
                    placeholder="To"
                    name={null}
                    inputSpan="20"
                    labelSpan="4"
                    value={effectiveTo ? moment(effectiveTo) : ''}
                    onChange={onChangeDate('effectiveTo')}
                    disabledDate={(current) => current && (current < moment(effectiveFrom))}
                  />
                </div>
              </>
            )}
          </div>
          <div className="search sm-search-design search-box-schedule">
            <div className="search-input">
              <Input
                labelSpan="0"
                inputSpan="24"
                onChange={onSearch}
                inputProps={{
                  allowClear: true,
                }}
              />
              {!feeExceptionSearch && <span className="search-icon sprite-img-before" /> }
            </div>
          </div>
        </div>
      </div>
      <ExceptionTable
        labels={labels}
        schedule={schedule}
        // clearSchedule={clearSchedule}
        providerId={id}
        feeExceptionScheduleId={feeExceptionScheduleId}
        searchText={feeExceptionSearch}
        setSchedule={setSchedule}
        newSchedule={newSchedule}
        onScheduleAdded={onScheduleAdded}
        onCancelNewSchedule={onCancelNewSchedule}
        onUpdateSchedule={onUpdateSchedule}
        onCancelEditSchedule={onCancelEditSchedule}
        isProviderViewOnly={isProviderViewOnly}
      />
    </div>
  );
};

export default Exception;
