import React, {
  useState, useEffect, useCallback, useMemo, useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import size from 'lodash/size';
import keys from 'lodash/keys';
import get from 'lodash/get';

import useRedirect from '../../hooks/useRedirect';

import SchedularTable from './TableView';
import TimeRangeContainer from './TimeRangeContainer';
import Notification from '../../components/Notification';
import ConfirmDialog from '../../components/ConfirmDialog';
import Icon from '../../components/Icon';

import DayView from './Views/DayView';
import MonthView from './Views/MonthView';
import OuterTimeNeedle from './Views/DayView/TimeNeedle';

import {
  updateAppointmentPosition,
  updateAppointmentDetail,
  removeAppointment,
  updateAppointmentAfterDragAndDrop,
} from '../../store/actions/doctors';

import './Scheduler.scss';
import useCRUD from '../../hooks/useCRUD';
import { apiUrls } from '../../api/constants';
import ErrorMessages from '../../lib/errorMessages';

const SchedulerGrid = (props) => {
  const {
    slotDuration,
    timeRange,
    minSlotTime,
    schedulerData,
    reFetchData,
    selectedDate,
    selectedDoctor,
    location,
    testType,
    viewType,
    dataViewType,
    visitType,
    selectedPayer,
    selectedLocation,
    setListAppointmentData,
    toggleAppointmentModal,
    getSchedulerDataParams,
    checkAppointmentRules,
    allDoctors,
    allLocations,
    form,
  } = props;
  const ref = useRef();
  const supressWarningRef = useRef(false);
  const reschedulePayloadRef = useRef({});
  const { query: { patient: paramsPatient } } = useRedirect();

  const [rescheduled, rescheduledError,, reScheduleAppointment, clearReschedule] = useCRUD({
    id: 'check-in-reschedule',
    url: apiUrls.RESCHEDULE_APPOINTMENT,
    type: 'update',
    shouldClearError: false,
  });

  const dispatch = useDispatch();
  const numberOfRows = useMemo(() => Math.ceil(
    moment(timeRange.to).diff(moment(timeRange.from), 'minutes') / slotDuration,
  ), [slotDuration, timeRange]);

  const [needleLeft, setNeedleLeft] = useState(73);
  const [isVisible, setVisible] = useState(false);
  const [reFetch, setReFetch] = useState(null);

  const onListAppointmentClick = useCallback(({ original }) => {
    setListAppointmentData({
      ...original,
      id: original.appointmentId,
      patient: {
        name: `${original.patientFirstName} ${original.patientLastName}`,
        id: original.patientId,
        patientId: original.patientId,
      },
      location: {
        location: {
          name: original.locationName,
          id: original.locationId,
          locationId: original.locationId,
        },
      },
      doctor: {
        name: `${original.providerFirstName} ${original.providerLastName}`,
        id: original.providerId,
        providerId: original.providerId,
      },
      startTime: original.appointmentStartDateTime?.dateString,
      endTime: original.appointmentEndDateTime?.dateString,
      time: moment(original.appointmentStartDateTime?.dateString),
    });
    setVisible(false);
    toggleAppointmentModal();
  }, [setListAppointmentData, toggleAppointmentModal]);

  const onRescheduleAppointment = useCallback(() => {
    const reschedulePayload = reschedulePayloadRef.current;
    const requestObj = {
      appointmentId: reschedulePayload?.currentAppointment?.id,
      patientId: get(reschedulePayload, 'currentAppointment.patient.id'),
      providerId: get(reschedulePayload, 'currentAppointment.doctor.id'),
      locationId: get(reschedulePayload, 'currentAppointment.location.id'),
      appointmentStartTime: get(reschedulePayload, 'currentAppointment.startTime'),
      visitTypeId: get(reschedulePayload, 'currentAppointment.visitType.visitTypeId'),
      insuranceId: get(reschedulePayload, 'currentAppointment.insuranceId'),
      totalDuration: get(reschedulePayload, 'currentAppointment.totalDuration'),
      supervisingProviderId: get(reschedulePayload, 'currentAppointment.doctor.id'),
      offset: reschedulePayload.offset,
      examTypeId: reschedulePayload?.currentAppointment?.examType?.length ? reschedulePayload?.currentAppointment?.examType?.map(({ examTypeId }) => examTypeId).join(',') : null,
    };
    if (reschedulePayload?.prevAppointment?.insuranceProfileName?.toLowerCase() !== 'self') { // is self
      requestObj.insurancedetailId = get(reschedulePayload, 'currentAppointment.insurancedetailId');
    }
    if (supressWarningRef.current) {
      requestObj.SupressPverifyWarning = supressWarningRef.current;
    }
    reScheduleAppointment({
      ...requestObj,
    });
  }, [reScheduleAppointment]);

  useEffect(() => {
    if (rescheduled) {
      const { message } = rescheduled;
      Notification({ message, success: true });
      dispatch(updateAppointmentAfterDragAndDrop({
        response: rescheduled,
        success: true,
        ...reschedulePayloadRef.current,
      }));
      clearReschedule();
      supressWarningRef.current = false;
    }
  }, [rescheduled]);

  const handleRescheduleError = useCallback(() => {
    dispatch(updateAppointmentAfterDragAndDrop({
      ...reschedulePayloadRef.current,
    }));
  },
  [dispatch]);

  useEffect(() => {
    if (rescheduledError) {
      if ((rescheduledError === ErrorMessages.P_VERIFY_PENDING_ERROR
          || rescheduledError === ErrorMessages.P_VERIFY_NOT_VERIFIED_ERROR)) {
        ConfirmDialog({
          onOk: (close) => {
            supressWarningRef.current = true;
            onRescheduleAppointment();
            close();
          },
          onCancel: (close) => {
            close();
            handleRescheduleError();
          },
          okText: 'Continue',
          title: 'Warning',
          content: rescheduledError,
          icon: <Icon name="ExclamationCircleOutlined" />,
        })();
      } else {
        Notification({ message: rescheduledError, success: false });
        handleRescheduleError();
        supressWarningRef.current = false;
      }
      clearReschedule(true);
    }
  }, [rescheduledError]);

  const updateDoctorAppointmentPosition = useCallback(
    (index, currentAppointment, prevAppointment) => {
      const params = getSchedulerDataParams({
        selectedDate,
        viewType,
        visitType,
        testType,
        selectedLocation,
      });
      dispatch(updateAppointmentPosition({ index, currentAppointment, prevAppointment }));
      reschedulePayloadRef.current = {
        index,
        currentAppointment,
        prevAppointment,
        params,
        offset: moment().utcOffset(),
      };
      onRescheduleAppointment();
    },
    [getSchedulerDataParams,
      selectedDate, viewType,
      visitType, testType, selectedLocation,
      dispatch, onRescheduleAppointment],
  );
  const updateDoctorAppointmentDetail = useCallback(
    (currentAppointment, prevAppointment) => {
      const params = getSchedulerDataParams({
        selectedDate,
        viewType,
        visitType,
        testType,
        selectedLocation,
      });
      dispatch(updateAppointmentDetail({ currentAppointment, prevAppointment }));
      reschedulePayloadRef.current = {
        currentAppointment,
        prevAppointment,
        params,
        offset: moment().utcOffset(),
      };
      onRescheduleAppointment();
    },
    [getSchedulerDataParams,
      selectedDate, viewType, visitType,
      testType, selectedLocation, dispatch, onRescheduleAppointment],
  );
  const removeDoctorAppointment = useCallback(
    (data) => {
      const params = getSchedulerDataParams({
        selectedDate, viewType, visitType, testType, selectedLocation,
      });
      dispatch(removeAppointment({ data, params }));
    },
    [getSchedulerDataParams, selectedDate, viewType,
      visitType, testType, selectedLocation, dispatch],
  );

  const scrollSchedulerToTime = useCallback(({ time, format = 'HH:mm A' }) => {
    if (time && timeRange?.from) {
      ref.current.scrollTop = 30 + Math.floor(moment(time, format).diff(timeRange?.from, 'minutes') / slotDuration) * 40;
    }
  }, [slotDuration, timeRange]);

  // DON'T REMOVE - Scroll Method for needle. Used when no of 'days components' overflows vertically
  // This method defines the position for the outer needle
  // const handleDaysScroll = (e) => {
  //   if (!location) {
  //     const shift = Math.max(73 - e.currentTarget.scrollLeft, 54);
  //     // restrict useless state updates
  //     if (shift !== needleLeft) setNeedleLeft(shift);
  //   }
  // };

  // uses filterDoctors method to filter doctors according to the location (filter) selected
  // also it sets the needle position as the location tabs are removed on filter select
  useEffect(() => {
    if (location) {
      setNeedleLeft(54);
    } else {
      setNeedleLeft(73);
    }
  }, [location]);

  useEffect(() => {
    scrollSchedulerToTime({
      time: moment().subtract(slotDuration, 'minutes').format('HH:mm A'),
    });
  }, []);

  const handleReFetch = useCallback((reFetchFunction) => {
    if (!reFetch) {
      setReFetch(() => reFetchFunction);
    }
  }, [reFetch, setReFetch]);

  const dayView = useMemo(() => (viewType === 'days' && (
  <div className="grid-days-container" key={selectedDoctor} style={{ gridTemplateColumns: `repeat(${selectedDoctor.length},1fr)` }}>
    {!size(selectedDoctor)
      ? <div className="grid-days-empty" />
      : selectedDoctor.map((doctor, index) => (
        <DayView
          container={ref}
          index={index}
          viewType={viewType}
          selectedDate={selectedDate}
          numberOfRows={numberOfRows}
          timeRange={timeRange}
          slotDuration={slotDuration}
          minSlotTime={minSlotTime}
          selectedDoctor={doctor}
          location={location}
          showDoctor={selectedDoctor.length > 1}
          schedulerData={get(schedulerData, doctor.providerId)}
          checkAppointmentRules={checkAppointmentRules}
          onUpdatePosition={updateDoctorAppointmentPosition}
          onUpdateDetail={updateDoctorAppointmentDetail}
          onRemove={removeDoctorAppointment}
          visitType={visitType}
          reFetchData={reFetchData}
          testType={testType}
          paramsPatient={paramsPatient}
          key={doctor.providerId}
          form={form}
        />
      ))}
  </div>
  )));

  const weekView = useMemo(() => (
    !size(selectedDoctor) || keys(schedulerData).length === 0
      ? <div className="grid-days-empty" style={{ gridColumn: 'span 6' }} />
      : keys(schedulerData).map((date, index) => (
        <DayView
          container={ref}
          index={index}
          selectedDate={moment(selectedDate).day('Monday').add(index, 'days')}
          numberOfRows={numberOfRows}
          timeRange={timeRange}
          slotDuration={slotDuration}
          minSlotTime={minSlotTime}
          selectedDoctor={selectedDoctor[0]}
          location={location}
          schedulerData={schedulerData[date][get(selectedDoctor[0], 'providerId', '')]}
          checkAppointmentRules={checkAppointmentRules}
          onUpdatePosition={updateDoctorAppointmentPosition}
          onUpdateDetail={updateDoctorAppointmentDetail}
          onRemove={removeDoctorAppointment}
          visitType={visitType}
          testType={testType}
          reFetchData={reFetchData}
          key={`${date}-${get(selectedDoctor[0], 'providerId', '')}`}
          form={form}
        />
      ))
  ));

  return (
    <div ref={ref} className="schedular-content">
      {dataViewType === 'scheduler' && (viewType !== 'months'
        ? (
          <TimeRangeContainer timeRange={timeRange} slotDuration={slotDuration}>
            {/* ----------------------------- < Day view > ------------------------------ */}
            {dayView}
            {/* ----------------------------- </ Day view > ----------------------------- */}
            {/* ----------------------------- < Week view > ----------------------------- */}
            {viewType === 'weeks'
              && (
                <div className="grid-days-container" style={{ gridTemplateColumns: '1fr '.repeat(6) }}>
                  {weekView}
                </div>
              )}
            {/* ----------------------------- </ Week view > ---------------------------- */}
            {viewType === 'days' && (
              <OuterTimeNeedle
                show={size(selectedDoctor) > 1}
                selectedDate={selectedDate}
                timeRange={timeRange}
                numberOfRows={numberOfRows}
                leftShift={needleLeft}
                topShift={40}
              />
            )}
          </TimeRangeContainer>
        )
        : (
          // ----------------------------- < Month view > -----------------------------
          <div className="month-container">
            <MonthView
              selectedDate={selectedDate}
              selectedDoctor={selectedDoctor}
              allDoctors={allDoctors}
              allLocations={allLocations}
            />
          </div>
          // ----------------------------- </ Month view > ----------------------------
        ))}
      {
        dataViewType === 'list' && (
          <SchedularTable
            schedulerData={schedulerData}
            selectedPayer={selectedPayer}
            selectedLocation={selectedLocation}
            selectedDoctor={selectedDoctor}
            selectedDate={selectedDate}
            visitType={visitType}
            testType={testType}
            viewType={viewType}
            isVisible={isVisible}
            setVisible={setVisible}
            onRowClick={onListAppointmentClick}
            onRemove={removeDoctorAppointment}
            handleReFetch={handleReFetch}
            reFetch={reFetch}
          />
        )
      }
    </div>
  );
};

export default SchedulerGrid;
