import React from 'react';

import { labelPaths, listIds } from '../../../../../../../../lib/constants';
import { apiUrls } from '../../../../../../../../api/constants';

import WithLabel from '../../../../../../../../hoc/withLabel';
import withQuery from '../../../../../../../../hoc/withQuery/withQuery';
import useRedirect from '../../../../../../../../hooks/useRedirect';

import ModifierTableForm from './ModifierTable';

const CustomModifierTable = withQuery({
  url: apiUrls.CONTRACT_TERMS_MODIFIER_LIST,
  listId: listIds.CONTRACT_TERMS_MODIFIER,
})();

const Modifier = ({ labels, navigateToTermsList, isUpdateContractAuthenticated }) => {
  const { params: { termId } } = useRedirect();
  return (
    <CustomModifierTable filters={{ contractTermId: termId }}>
      {({
        data, onSort, initialSort, totalCount, loading, reFetch,
      }) => (
        <ModifierTableForm
          labels={labels}
          data={data}
          onSort={onSort}
          initialSort={initialSort}
          totalCount={totalCount}
          navigateToTermsList={navigateToTermsList}
          loading={loading}
          reFetch={reFetch}
          isUpdateContractAuthenticated={isUpdateContractAuthenticated}
        />
      )}
    </CustomModifierTable>
  );
};

export default WithLabel(Modifier, labelPaths.EDIT_CONTRACT_TERMS_MODIFIERS);
