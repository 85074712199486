import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { connect } from 'react-redux';
import { Form, Input } from 'antd';
import moment from 'moment';
import debounce from 'lodash/debounce';

import * as selectors from '../../../../../store/selectors';

import WithLabel from '../../../../../hoc/withLabel';
import useRedirect from '../../../../../hooks/useRedirect';
import {
  labelPaths, dateFormatWith12Hour,
  UiRoutes, dateFormatStartsWithYear,
} from '../../../../../lib/constants';
import encode from '../../../../../lib/encode';
import ErrorMessages from '../../../../../lib/errorMessages';

import Modal from '../../../../../components/Modal';
import ModalCustomFooter from '../../../../../components/ModalCustomFooter';
import FilterComponents from '../../../../../components/FilterComponents';
import FilterManager from '../../../../../components/FilterManager';
import Notification from '../../../../../components/Notification';

import ActionIcons from '../../../../../wiredComponents/ActionIcons';
import PatientTable from '../../../../../wiredComponents/PatientTable';

import Patient from './Patient';
import columns from './columns';

import './AddAppointmentDialog.scss';

const examDurationMap = {
  newPatient: 20,
  followUpLong: 15,
  followUpShort: 10,
  preop: 15,
  postop: 5,
  laser: 5,
  injection: 5,
  minorSurgery: 20,
};

const FilterCollection = FilterComponents([
  {
    type: 'filterButton',
    filterProps: {
      placeholder: 'Recently Viewed',
      name: 'RecentlyViewed',
      id: 'patients_recently_viewed',
    },
  },
  {
    type: 'filterButton',
    filterProps: {
      placeholder: 'Recently Created',
      name: 'RecentlyCreated',
      id: 'patients_recently_created',
    },
  }]);

const AddAppointmentDialog = ({
  data,
  isVisible,
  toggle,
  mode,
  visitType,
  labels,
  reFetchData,
  testType,
  paramsPatient,
  selectedSchedulerData,
}) => {
  const {
    doctor, location, time, status = '', id,
  } = data;
  const appointmentTime = moment(time).add(1, 'seconds');
  const [form] = Form.useForm();
  const [patient, setPatient] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [search, setSearch] = useState('');
  const initialData = {
    date: appointmentTime,
    appointmentTime,
    provider: doctor && doctor.name,
    location: location && location.location.name,
    duration: examDurationMap[visitType],
    visitType: mode === 'add' ? visitType : data.visitType,
    exam: undefined,
  };

  const [scheduleData, setScheduleData] = useState(initialData);
  const getHeading = useMemo(() => (mode === 'add' ? 'Add Appointment' : 'Appointment'), [mode]);
  const getVisitType = useMemo(() => (mode === 'add' ? visitType : data.visitType), [data.visitType, mode, visitType]);

  const {
    replace, query, path, push, generatePath,
  } = useRedirect();

  useEffect(() => {
    const newData = { ...initialData };
    const { patient: selectedPatient, visitType: selectedVisitType } = data;
    if (selectedPatient && selectedPatient !== patient) setPatient(selectedPatient);
    if (selectedVisitType && selectedVisitType !== visitType) {
      newData.visitType = selectedVisitType;
      newData.duration = examDurationMap[selectedVisitType];
    }
    setScheduleData(newData);
  }, [data]);

  React.useEffect(() => {
    form.setFieldsValue(scheduleData);
  }, [scheduleData, form]);

  const onPatientClose = () => {
    setPatient(null);
    replace({
      search: encode({
        ...query,
        patient: null,
      }),
      pathname: path,
    });
  };

  const ModalTitle = () => (
    <div className="modal-title">
      <div>
        {getHeading}
      </div>
      <div className="date-time flex">
        {(patient || paramsPatient) && (
          <ActionIcons
            share
            print
            camera
            userNotes
            edit
            documentsWithDropDown
            transactionWithDropDown
            insurance
            demoGraphic
            auditLogs={mode !== 'add'}
            auditLogsProps={id}
            patient={patient}
            patientId={patient?.patientId || paramsPatient}
            printProps={{
              patientId: patient?.patientId || paramsPatient,
              superbill: true,
              facesheet: true,
              appointment: true,
              detailsStatement: true,
              includeLatestAppointmentSuperBill: !id,
              appointmentIds: id,
            }}
            notesProps={{
              page: 'appointments',
            }}
          />
        )}
        <span className="mr-lt-5">
          Appointment date and time:
        </span>
        <span data-testid="appointment-time" className="mr-lt-5">{appointmentTime?.format(dateFormatWith12Hour)}</span>
      </div>
    </div>
  );

  const modalFooter = ((patient || paramsPatient) && status.toLowerCase() !== 'reschedule' && status.toLowerCase() !== 'cancel'
    ? (
      <ModalCustomFooter
        onCancel={() => {
          onPatientClose();
          toggle(!isVisible);
        }}
        onSave={() => form.submit()}
        saveDataTestId="add-appointment-btn"
        cancelDataTestId="cancel-appointment-btn"
      />
    )
    : (
      <ModalCustomFooter
        saveVisible={false}
        onCancel={() => {
          onPatientClose();
          toggle(!isVisible);
        }}
      />
    )
  );

  useEffect(() => {
    if (!isVisible) {
      setPatient(null);
      setSearchText('');
    }
  }, [isVisible]);

  useEffect(() => {
    if (path === UiRoutes.schedularWithExtraInfo) { return; }
    if (patient) {
      push(`${generatePath(UiRoutes.schedularWithId, { id: patient.patientId })}?${encode(query)}`);
    } else {
      push(`${generatePath(UiRoutes.schedular)}?${encode(query)}`);
    }
  }, [patient]);

  const debounceSearch = useMemo(() => debounce(setSearch, 1500), []);

  const handleSearch = useCallback(({ target: { value } }) => setSearchText(value), []);

  useEffect(() => {
    if (searchText?.length >= 3) {
      debounceSearch(searchText);
    } else {
      debounceSearch('');
    }
  }, [searchText]);

  const isRepeatingAppointment = useCallback((formData) => {
    // returns true if repeating appointments are found
    if (selectedSchedulerData) {
      const date = moment(appointmentTime).format(dateFormatStartsWithYear);
      const scheduleOnSameDate = selectedSchedulerData[date];
      if (scheduleOnSameDate) {
        const { providerId } = doctor;
        const scheduleForSameDoctor = scheduleOnSameDate[providerId];
        if (scheduleForSameDoctor) {
          const { appointments = [] } = scheduleForSameDoctor;
          const {
            insuranceId,
            insurancedetailId,
          } = formData;
          return !appointments.every((appointment) => {
            const {
              appointmentId,
              insuranceId: appointmentInsuranceId,
              insurancedetailId: appointmentInsurancedetailId,
              patient: { id: appointmentPatientId },
            } = appointment;
            if (appointmentId === id) return true; // skip checking with same id in case of edit
            if (
              appointmentPatientId !== patient?.patientId
            ) return true; // skip checking for other patients
            if (insuranceId === appointmentInsuranceId) {
              if (insurancedetailId && insurancedetailId !== appointmentInsurancedetailId) {
                // if insurance detail is selected and not already present
                return true;
              }
              Notification({ message: ErrorMessages.CANNOT_BOOK_APPOINTMENT_WITH_SAME_DETAILS });
              return false;
            }
            return true;
          });
        }
      }
    }
    return false; // allow if any of the above conditions fail
  }, [appointmentTime, doctor, id, patient, selectedSchedulerData]);

  return (isVisible && (
  <Modal
    visible={isVisible}
    width="1110px"
    centered
    title={<ModalTitle />}
    footer={[modalFooter]}
    className="appointment-modal"
    onCancel={() => {
      onPatientClose();
      toggle(!isVisible);
    }}
  >
    {patient || paramsPatient
      ? (
        <Patient
          data={patient}
          mode={mode}
          onClose={onPatientClose}
          labels={labels}
          form={form}
          searchText={searchText}
          time={appointmentTime}
          toggle={toggle}
          location={location}
          doctor={doctor}
          reFetchData={reFetchData}
          visitType={getVisitType}
          testType={testType}
          paramsPatient={paramsPatient}
          appointmentId={id}
          isRepeatingAppointment={isRepeatingAppointment}
          appointmentTime={time}
        />
      )
      : (
        <FilterManager>
          {({ onFilterChange, filters }) => (
            <>
              <div className="table-filters">
                <div className="search-input lg-search mr-bt-16">
                  <Input
                    label=""
                    id="11"
                    onChange={handleSearch}
                    value={searchText}
                    labelSpan="0"
                    inputSpan="24"
                    placeholder="Search"
                  />
                  <span className="search-icon sprite-img-before" />
                </div>
                <FilterCollection
                  onFilterChange={onFilterChange}
                  filters={filters}
                />
              </div>
              {mode === 'add' && (
              <div className="table-max-height-600 appointment-scheduler-patient-list">
                <PatientTable
                  onRowClick={setPatient}
                  labels={labels}
                  filters={{ SearchText: search, ...filters }}
                  column={columns}
                  columnPropsPassed
                />
              </div>
              )}
            </>
          )}
        </FilterManager>
      )}
  </Modal>
  ));
};

export default connect((state) => ({
  selectedSchedulerData: selectors.schedulerData(state),
}))(WithLabel(AddAppointmentDialog, labelPaths.ADD_APPOINTMENT_SCHEDULAR));
