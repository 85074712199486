import React, { useCallback, useMemo } from 'react';
import { Form as AntdForm } from 'antd';
import Modals from '../../../../components/Modal';
import Button from '../../../../components/Button';
import Form from '../../../../components/Form';
import Notification from '../../../../components/Notification';

import ReassignForm from './ReassignForm';
import { apiUrls } from '../../../../api/constants';
import SuccessMessages from '../../../../lib/successMessages';
import { formId } from '../../../../lib/constants';
import ErrorMessages from '../../../../lib/errorMessages';
import Events from '../../../../lib/events';

const ReassignModal = ({
  labels, toggleModal, isVisible, selectedTask,
}) => {
  const [form] = AntdForm.useForm();

  const taskIds = useMemo(() => {
    if (selectedTask && selectedTask.length) {
      return selectedTask.map(({ original }) => original.taskId);
    }
    return [];
  }, [selectedTask]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.TASKS_ASSIGN_SUCCESSFULLY, success: true });
      Events.trigger('refetchTaskManagementList');
      Events.trigger('refetchManualInterventionQueueList');
      toggleModal();
    }
  }, [toggleModal]);

  const submitForm = useCallback(() => {
    if (taskIds?.length) form.submit();
    else Notification({ message: ErrorMessages.SELECT_AT_LEAST_ONE_TASK });
  }, [form, taskIds]);

  return (
    <Modals
      visible={isVisible}
      toggleModal={toggleModal}
      destroyOnClose
      title={labels.get('titles.reAssign')}
      width="400px"
      footer={[
        <div className="group-btns" key="footer">
          <Button
            className="btn min-wt-86"
            onClick={toggleModal}
          >
            {labels.get('buttons.cancel')}
          </Button>
          <Button
            className="btn btn-success min-wt-86 inline"
            data-testid="reassign-modal-save"
            onClick={submitForm}
          >
            {labels.get('buttons.save')}
          </Button>
        </div>,
      ]}
    >
      <Form
        form={form}
        formId={formId.TASK_REASSIGN}
        url={apiUrls.REASSIGN_TASK}
        onRequestComplete={onRequestComplete}
        extraData={{ taskId: taskIds }}
      >
        <ReassignForm labels={labels} form={form} />
      </Form>
    </Modals>
  );
};
export default ReassignModal;
