import React, { useCallback } from 'react';
import get from 'lodash/get';
import moment from 'moment';
import { apiUrls } from '../../../../../../api/constants';
import { formId } from '../../../../../../lib/constants';
import dateFormatter from '../../../../../../lib/dateFormatter';

import Button from '../../../../../../components/Button';
import Form from '../../../../../../components/Form';
import Notification from '../../../../../../components/Notification';

import useRedirect from '../../../../../../hooks/useRedirect';

import PersonForm from '../../PersonForm';
import RenameProfile from '../Rename';
import { formatPhoneNumber } from '../../../../../../lib/util';
import ErrorMessages from '../../../../../../lib/errorMessages';
import useReduxState from '../../../../../../hooks/useReduxState';

const EditForm = ({
  form,
  labels,
  formName,
  onRequestComplete,
  defaultParams,
  formProps = {},
  onCancel,
  self,
  isUpdatePatientAuthenticated,
}) => {
  const { params } = useRedirect();

  const { rpId, tabId, id } = defaultParams || params;

  const [formData, setFormData] = useReduxState(`responsible-party-edit-${id}`, {});
  const [isChecked, setIsChecked] = useReduxState(`responsible-party-edit-isChecked-${id}`, true);

  const initialDataParser = useCallback((value) => {
    const data = {
      isActive: Boolean(value.isActive),
      profileCategoryName: value.profileName,
      relationName: value.relationName,
      responsibleRelationId: value.responsibleRelationId,
      type: value.responsiblePartyType,
      personId: get(value, 'responsibleParty.personId'),
      personName: `${get(value, 'responsibleParty.firstName', '')} ${get(value, 'responsibleParty.lastName', '')}`,
      firstName: get(value, 'responsibleParty.firstName', ''),
      lastName: get(value, 'responsibleParty.lastName', ''),
      addressLine1: get(value, 'responsibleParty.addressLine1', ''),
      addressLine2: get(value, 'responsibleParty.addressLine2', ''),
      zip: get(value, 'responsibleParty.zip', ''),
      city: get(value, 'responsibleParty.city', ''),
      state: get(value, 'responsibleParty.stateName', ''),
      country: get(value, 'responsibleParty.countryName', ''),
      primaryPhone: formatPhoneNumber(get(value, 'responsibleParty.primaryPhone', '')),
      primaryPhoneType: get(value, 'responsibleParty.primaryPhoneTypeName', ''),
      secondaryPhone: formatPhoneNumber(get(value, 'responsibleParty.secondaryPhone', '')),
      secondaryPhoneType: get(value, 'responsibleParty.secondaryPhoneTypeName', ''),
      email: get(value, 'responsibleParty.emailAddress', ''),
      gender: get(value, 'responsibleParty.genderName', ''),
      dateOfBirth: dateFormatter(get(value, 'responsibleParty.dateOfBirth')),
    };
    setFormData(value);
    return data;
  }, [setFormData]);

  const onClear = useCallback((resetForm) => {
    resetForm();
  }, []);
  const isActiveInsurancePresent = useCallback(() => {
    let isActiveInsurance = true;
    for (let i = 0; i < formData?.insuranceProfileDetail?.length; i += 1) {
      const item = formData?.insuranceProfileDetail[i];
      if (item?.effectiveTo
        && moment(item?.effectiveTo)
          .isBefore(moment())) {
        isActiveInsurance = false;
      } else if (!item?.effectiveTo
       && !item?.effectiveFrom) {
        isActiveInsurance = false;
      } else if (moment(moment())
        .isBefore(item?.effectiveFrom)) {
        isActiveInsurance = false;
      } else {
        isActiveInsurance = true;
        break;
      }
    }
    return isActiveInsurance;
  }, [formData]);

  const onChange = useCallback(({ target }) => {
    if (formData?.insuranceProfileDetail?.length
       && isActiveInsurancePresent() && !target?.checked) {
      Notification({ message: ErrorMessages.CAN_NOT_INACTIVE_PROFILE, success: false });
    } else {
      setIsChecked(target?.checked);
    }
  }, [formData, isActiveInsurancePresent, setIsChecked]);

  const parser = useCallback((parseValue = {}) => ({ ...parseValue, isActive: isChecked }),
    [isChecked]);

  const getExtraData = useCallback(() => {
    const personId = form.getFieldValue('personId');
    const personType = form.getFieldValue('type');
    const personPartyType = personType || 'Patient';
    return {
      patientId: id,
      responsiblePersonId: (personPartyType) !== 'Patient' ? personId : null,
      responsiblePatientId: (personPartyType) === 'Patient' ? (personId || id) : null,
      responsiblePartyType: personPartyType,
      profileCategoryId: rpId,
      PrimaryInsuredType: personPartyType,
      isDefault: formData?.isDefault,
    };
  }, [form, formData, id, rpId]);

  return (
    <div className="required-feilds">
      <div className="complete-form-wrap insurance-aside-wrap posi-relative">
        <Form
          form={form}
          tabId={tabId}
          editKey={rpId}
          name={`${formName}-${rpId}`}
          url={formData?.insuranceProfileType === 'Other' ? apiUrls.UPDATE_PATIENT_INSURANCE_PROFILE : apiUrls.UPDATE_PATIENT_INSURANCE}
          getUrl={`${apiUrls.GET_PATIENT_INSURANCE}/${rpId}`}
          formId={formId.EDIT_RESPONSIBLE_PARTY}
          initialDataParser={initialDataParser}
          extraData={getExtraData}
          listId="patient-insurance-rp"
          onRequestComplete={onRequestComplete}
          parser={parser}
          isUpdate
          section
          getDataUsingCrud
          disableForm={!isUpdatePatientAuthenticated}
          {...formProps}
        >
          { ({ resetForm }) => (
            <>
              <RenameProfile
                labels={labels}
                isUpdate
                self={self}
                onChange={onChange}
                isChecked={isChecked}
                editResponsibleParty
              />
              <div className="feild-row justify-content-sp-bt align-center">
                <h2 className="h2-title">{labels.get('titles.responsibleParty')}</h2>
              </div>
              <PersonForm
                form={form}
                labels={labels}
              />
              <Form.Section>
                <div className="btns-action">
                  <Button className="btn btn-success min-wt-86" id="patients_insurance_party_save" type="submit">{labels.get('buttons.update')}</Button>
                  <Button className="btn btn-success min-wt-86" id="patients_insurance_party_save" onClick={() => onClear(resetForm)}>{labels.get('buttons.clear')}</Button>
                  {onCancel && <Button className="btn min-wt-86" id="patients_insurance_party_cancel" onClick={onCancel}>{labels.get('buttons.cancel')}</Button>}
                </div>
              </Form.Section>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};

export default EditForm;
