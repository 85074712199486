import React from 'react';

import CheckBox from '../../../../../../components/Form/CheckBox';
import Form from '../../../../../../components/Form';

import { labelPaths } from '../../../../../../lib/constants';

import WithLabel from '../../../../../../hoc/withLabel';
import SelectBox from '../../../../../../components/Form/SelectBox';
import Input from '../../../../../../components/Form/Input';

const profileCategoryNameOptions = [
  { name: 'Medical', value: 'Medical' },
  { name: 'Vision', value: 'Vision' },
];

const RenameProfile = ({
  labels, formFieldPrefix = '', self, hideActive, onChange, isChecked, editResponsibleParty,
}) => (
  <div className="form-container">
    <div className="feild-row justify-content-sp-bt align-center">
      <h2 className="h2-title">{labels.get('titles.profileName')}</h2>
    </div>
    <div>
      <Form.Section noOfColumns={3}>
        <Form.Column>
          <div className="mr-bt-16">
            <div className="group-shadow">
              {self ? (
                <Input
                  label={labels.get('labels.name')}
                  name={`${formFieldPrefix}profileCategoryName`}
                  inputSpan="14"
                  labelSpan="9"
                  required
                  placeholder="Enter Name"
                />
              ) : (
                <SelectBox
                  label={labels.get('labels.name')}
                  name={`${formFieldPrefix}profileCategoryName`}
                  inputSpan="14"
                  labelSpan="9"
                  required
                  options={profileCategoryNameOptions}
                />
              )}
            </div>
          </div>
        </Form.Column>
        <Form.Column>
          {!hideActive && (
          <div className="mr-bt-16">
            <div className="group-shadow">
              <CheckBox
                label={labels.get('labels.active')}
                name="isActive"
                valuePropName={!editResponsibleParty && 'checked'}
                onChange={onChange}
                checked={isChecked}
              />
            </div>
          </div>
          )}
        </Form.Column>
        <Form.Column />
      </Form.Section>
    </div>
  </div>
);

export default WithLabel(RenameProfile, labelPaths.PROFILE_NAME);
