import React from 'react';

const columns = [
  {
    Header: 'Procedure Code',
    fixWidth: '200',
    accessor: 'cptCode',
  },
  {
    Header: 'Procedure Description',
    accessor: 'description',
  },
  {
    Header: ' ',
    Cell: ({
      row: { original: { id } },
      deleteDiagnosticId,
    }) => (
      <div
        role="presentation"
        onClick={() => deleteDiagnosticId(id)}
        className="delete-button"
      >
        <span className="delet-btn-icon" />
      </div>
    ),
    fixWidth: '100',
  },
];

export default columns;
