import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import classNames from 'classnames';

import { Input, CheckBox } from '../../components/Form/inputFields';
import Form from '../../components/Form';
import { apiUrls } from '../../api/constants';
import useRedirect from '../../hooks/useRedirect';

const style = { padding: '8px 8px 0 8px' };

const initialDataParser = (masterData) => {
  const clonedMasterData = masterData;
  if (clonedMasterData?.optionalValues) {
    clonedMasterData.optionalValues = JSON.parse(clonedMasterData.optionalValues);
  }
  return clonedMasterData;
};
const EnumForm = ({
  form, onRequestComplete, title, isUpdate, fields, enumId,
}) => {
  const { params } = useRedirect();
  const [formData, setFormData] = useState({});

  const onGetResponseComplete = useCallback((data) => {
    if (data && Object.keys(data).length) {
      if (!data?.parentId) {
        // eslint-disable-next-line no-param-reassign
        data.parentId = 0;
      }
      setFormData(data);
    }
  }, []);

  const masterDataParser = useCallback((masterData) => {
    const clonedMasterData = masterData;
    if (!isUpdate && !fields?.masterCode) {
      clonedMasterData.masterCode = clonedMasterData.masterName;
    }
    if (clonedMasterData?.optionalValues) {
      clonedMasterData.optionalValues = JSON.stringify(clonedMasterData.optionalValues);
    }
    return clonedMasterData;
  }, [isUpdate, fields]);

  const {
    masterCode = {}, masterDescription = {}, masterName = {}, optionalValues = [], isActive = {},
  } = fields;
  return (
    <div className="cpt-form m-ui-modal">
      <div className="addition-header">
        <div className="required-feilds top-feild-mng">
          <p className="h2-title">{title}</p>
        </div>
      </div>

      <Form
        form={form}
        isUpdate={isUpdate}
        onRequestComplete={onRequestComplete}
        parser={masterDataParser}
        onGetResponseComplete={onGetResponseComplete}
        formId={apiUrls.GET_ENUM_FIELDS}
        initialDataParser={initialDataParser}
        tabId={params?.tabId}
        formName={apiUrls.GET_ENUM_FIELDS}
        getUrl={isUpdate ? `${apiUrls.GET_ENUM_FIELDS}/${params.masterDataId}` : apiUrls.GET_ENUM_FIELDS}
        url={isUpdate ? `${apiUrls.GET_ENUM_FIELDS}/${params.masterDataId}` : apiUrls.GET_ENUM_FIELDS}
        extraData={{ enumId, parentId: 0, ...formData }}
      >
        <Row gutter={16}>
          <Col md={24} className="gutter-row">
            <div style={style} className="group-shadow">
              <Row gutter={16}>
                <Col md={24} className="gutter-row">
                  <div className={classNames({ 'display-none': !masterName?.display })}>
                    <Input
                      label={masterName?.label}
                      id="1"
                      name="masterName"
                      labelSpan="6"
                      inputSpan="6"
                      required={masterName?.required}
                    />
                  </div>
                  <div className={classNames({ 'display-none': !masterCode?.display })}>
                    <Input
                      className="non-editable"
                      label={masterCode?.label}
                      name="masterCode"
                      id="3"
                      labelSpan="6"
                      inputSpan="6"
                      disabled={isUpdate}
                      required={!!masterCode?.required}
                    />
                  </div>
                  <div className={classNames({ 'display-none': !masterDescription?.display })}>
                    <Input
                      label={masterDescription?.label}
                      name="masterDescription"
                      id="3"
                      labelSpan="6"
                      inputSpan="6"
                      required={masterDescription?.required}
                    />
                  </div>
                  {optionalValues?.map((currentItem, index) => (
                    <Input
                      label={currentItem?.label}
                      name={['optionalValues', currentItem?.accessor]}
                      labelSpan="6"
                      inputSpan="6"
                      required={currentItem?.required}
                      numberOnly={currentItem?.isNumber}
                      key={index}
                    />
                  ))}
                  <div className={classNames({ 'display-none': !isActive?.display })}>
                    <CheckBox label={isActive?.label} name="isActive" valuePropName="checked" labelSpan="6" inputSpan="6" />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

EnumForm.defaultProps = {
  form: {},
  fields: {
    masterName: {
      label: 'Name',
      required: true,
      display: true,
    },
    masterCode: {
      label: 'Master Code',
      required: true,
      display: true,
    },
    masterDescription: {
      label: 'Description',
      required: false,
      display: true,
    },
    isActive: {
      label: 'Active',
      display: true,
    },
  },
  onRequestComplete: () => { /* This is intentional */ },
  title: 'Add data in selected master',
  isUpdate: false,
};

EnumForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.object),
  fields: PropTypes.instanceOf(Object),
  onRequestComplete: PropTypes.func,
  title: PropTypes.string,
  isUpdate: PropTypes.bool,

};

export default EnumForm;
