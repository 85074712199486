import React from 'react';
import { Route, Switch } from 'react-router-dom';

import WithLabel from '../../../../hoc/withLabel';
import useRedirect from '../../../../hooks/useRedirect';
import { labelPaths, UiRoutes } from '../../../../lib/constants';

import ViewEncounter from '../../withoutTabs/encounters/EncounterDetails';

import EncountersList from './encounterList';

import './encounter.scss';

function Encounter({ labels }) {
  const {
    params, path,
  } = useRedirect();

  return (
    <Switch>
      <Route exact path={path}>
        <EncountersList labels={labels} parentParams={params} />
      </Route>
      <Route path={UiRoutes.viewEncounterWithId}>
        <ViewEncounter labels={labels} />
      </Route>
    </Switch>
  );
}

export default WithLabel(Encounter, labelPaths.PATIENT_ENCOUNTERS);
