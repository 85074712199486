import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Modals from '../../../../../../components/Modal';
import Form from '../../../../../../components/Form';
import Button from '../../../../../../components/Button';
import Input from '../../../../../../components/Form/Input';
import { apiUrls } from '../../../../../../api/constants';
import { getTableDataById } from '../../../../../../store/selectors';
import { listId } from '../../../../../../lib/constants';

const CreatePayerClaimPopUp = ({
  isVisible, toggleModal, claimNumber, handlePayerClaimSave, form,
}) => {
  const tableData = useSelector((state) => getTableDataById(state, `${listId.PATIENT_CLAIM_LIST}-${claimNumber}`));

  useEffect(() => {
    if (tableData?.data?.length) {
      form.setFieldsValue({ payerClaimNo: tableData?.data?.[0]?.claimDetails?.payerClaimNo });
    }
  }, [tableData]);

  return (
    <Modals
      visible={isVisible}
      toggleModal={toggleModal}
      width={500}
      footer={null}
      title="Create Payer Claim Number"
      destroyOnClose
    >
      <div className="create-payer-claim-wrapper">
        <div className="form">
          <Form
            formId="create-payer-claim-popup"
            form={form}
            url={apiUrls.SAVE_PAYER_CLAIM_NUMBER}
            onFinish={handlePayerClaimSave}
          >
            <Input
              name="payerClaimNo"
              label="Payer Claim Number"
              labelSpan={8}
              inputSpan={16}
              required
            />
            <div className="inline-right create-payer-claim-button-wrapper">
              <div className="group-btns">
                <Button className="btn min-wt-86" onClick={toggleModal}>Cancel</Button>
                <Button className="btn btn-success min-wt-86" onClick={handlePayerClaimSave}>Save</Button>
              </div>

            </div>
          </Form>
        </div>
      </div>
    </Modals>
  );
};

export default CreatePayerClaimPopUp;
