import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { Form as AntdForm } from 'antd';

import omit from 'lodash/omit';
import keyBy from 'lodash/keyBy';
import findIndex from 'lodash/findIndex';
import sortBy from 'lodash/sortBy';

import useCRUD from '../../../../../hooks/useCRUD';
import withLabel from '../../../../../hoc/withLabel';

import { apiUrls } from '../../../../../api/constants';
import { labelPaths, listId } from '../../../../../lib/constants';

import Form from '../../../../../components/Form';
import WidgetLoader from '../../../../../components/WidgetLoader';
import Smcheckbox from '../../../../../components/SmCheckbox';

import TabLeft from './TabLeft';
import CheckBoxList from './CheckBoxList';

import './Rights.scss';
import '../../../../../components/TabLeft/lefttab.scss';

const Rights = ({
  labels, isUserRightsViewOnly, setRights, rights, permissionSetId,
}) => {
  const [form] = AntdForm.useForm();
  const [isSelectAll, setSelectAll] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(null);

  const [rightsMasterResponse,, rightsMasterLoading, getRightsMaster] = useCRUD({
    id: listId.USER_RIGHTS_MASTER, url: apiUrls.GET_USER_RIGHTS_MASTER, type: 'read',
  });

  const rightsMaster = useMemo(() => (rightsMasterResponse ? sortBy(rightsMasterResponse, 'rightsMasterName') : []), [rightsMasterResponse]);

  const [userRightsResponse, , userRightLoading, getUserRights, clearUserRights] = useCRUD({
    id: `${apiUrls.USER_RIGHTS_DATA}/getResponse`, url: apiUrls.USER_RIGHTS_DATA, type: 'read',
  });

  const handleSelectAll = useCallback(() => {
    const { claimsMaster } = rightsMaster[activeTabIndex];
    setRights({ ...rights, ...keyBy(claimsMaster, (item) => item.claimsMasterId) });
  }, [rightsMaster, activeTabIndex, setRights, rights]);

  const handleNone = useCallback(() => {
    const { claimsMaster } = rightsMaster[activeTabIndex];
    setRights(omit(rights, claimsMaster.map((item) => item.claimsMasterId)));
  }, [rightsMaster, activeTabIndex, setRights, rights]);

  useEffect(() => {
    setRights(keyBy(userRightsResponse, (item) => item.claimsMasterId));
  }, [userRightsResponse]);

  useEffect(() => {
    getRightsMaster();
  }, []);

  useEffect(() => {
    if (permissionSetId) {
      getUserRights({ roleId: permissionSetId });
    }
    return () => clearUserRights(true);
  }, [permissionSetId]);

  useEffect(() => {
    if (rights && rightsMaster && activeTabIndex >= 0 && rightsMaster[activeTabIndex]) {
      const rightsList = Object.keys(rights);
      let selectAll = true;
      rightsMaster[activeTabIndex].claimsMaster.map((item) => {
        if (!rightsList.includes(`${item.claimsMasterId}`)) {
          selectAll = false;
        }
        return true;
      });
      form.setFieldsValue({ selectAll });
      setSelectAll(selectAll);
    }
  }, [activeTabIndex, form, rights, rightsMaster]);

  const handleTabChange = useCallback((tabId) => {
    const index = findIndex(rightsMaster,
      (item) => parseInt(item.rightsMasterId, 10) === parseInt(tabId, 10));
    setActiveTabIndex(index);
  }, [rightsMaster]);

  const onChange = useCallback(({ target: { checked } }) => {
    setSelectAll(checked);
    if (checked) {
      handleSelectAll();
    } else {
      handleNone();
    }
  }, [handleNone, handleSelectAll]);

  return (
    <div className="user-rt-forms posi-relative">
      {rightsMasterLoading || userRightLoading ? <WidgetLoader /> : null}
      <Form form={form}>
        <Form.Section noOfColumns={1}>
          <Form.Column>
            <div className="user-form-header">
              <div className="user-form-header-wrap">
                <div className="right-option-header">{labels.get('headings.availableRights')}</div>
                <Smcheckbox name="selectAll" checked={isSelectAll} onChange={onChange} valuePropName="checked" labelSpan="16" inputSpan="8" disabled={isUserRightsViewOnly}>Select All </Smcheckbox>
              </div>
            </div>
            <div className="user-right-wrap">
              <div className="v-tab">
                <TabLeft
                  id="users_rights_tab"
                  tabList={rightsMaster}
                  nameAccessor="rightsMasterName"
                  valueAccessor="rightsMasterId"
                  componentDataAccessor="claimsMaster"
                  component={CheckBoxList}
                  allChecked={rights}
                  isUserRightsViewOnly={isUserRightsViewOnly}
                  handleRights={setRights}
                  handleTabChange={handleTabChange}
                />
              </div>
            </div>
          </Form.Column>
        </Form.Section>
      </Form>
    </div>
  );
};

export default withLabel(Rights, labelPaths.EDIT_USER_RIGHTS);
