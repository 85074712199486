import React, { useCallback } from 'react';
import { Form as AntdForm } from 'antd';
import orderBy from 'lodash/orderBy';

import { enums, enumMasterCode } from '../../../../../lib/constants';
import { getIsInjectionAuthNeeded, getIsPolicyTypeRequireAuthorization } from '../../../../../lib/util';
import { toDateValidator } from '../../../../../lib/validator';
import { apiUrls } from '../../../../../api/constants';

import DatePicker from '../../../../../components/Form/DatePicker';
import CheckBox from '../../../../../components/Form/CheckBox';
import Input from '../../../../../components/Form/Input';
import Modals from '../../../../../components/Modal';
import Form from '../../../../../components/Form';
import PhoneNumberInput from '../../../../../components/Form/PhoneNumberInput';

import EnumSelect from '../../../../../wiredComponents/Enum/Select';
import WiredSelectWithoutTab from '../../../../../wiredComponents/Select';
import RequestNewPayerForm from '../../../../../wiredComponents/Modal/Payer/component/RequestNewPayer';
import PayerGridAutoComplete from '../../../../../wiredComponents/PayerGridAutoComplete';
import InsuranceVerificationShieldIcon from '../InsuranceVerificationShieldIcon';

import AddPlanType from './AddPlanTypeForm';
import useReduxState from '../../../../../hooks/useReduxState';

const insuranceRegex = /^($|\d{1,8}|\d{0,8}\.|\d{0,8}\.\d{1,2})$/;

const VALUE_SHOULD_BE_11_DIGIT = 'Value should be up-to 10 digits(including two decimal places).';

const valueChecker = (rules, value) => {
  if (!value) return Promise.resolve();
  if (insuranceRegex.test(value)) return Promise.resolve();
  return Promise.reject(VALUE_SHOULD_BE_11_DIGIT);
};

const labelRender = (plan, isactive) => (
  <div className="option">
    <span>{plan}</span>
    <span>{isactive ? '(Active)' : '(Inactive)'}</span>
  </div>
);

const planTypeOptionRenderer = (options) => {
  if (!options?.length) return [];
  return (orderBy(options, 'isActive', 'desc')?.map((item) => ({
    value: item?.value,
    item,
    name: `${item?.plan} ${item?.isActive ? '(Active)' : '(Inactive)'}`,
    title: item?.plan,
    label: labelRender(item?.plan, item?.isActive),
  })));
};

const checkRequiredFields = (policyOptions, selectedPolicy) => {
  if (policyOptions?.length) {
    return !!policyOptions?.find(
      (item) => (item.masterId === selectedPolicy)
      && item.masterCode === enumMasterCode.WorkersComp);
  }
  return false;
};
const PPO = ({
  form, labels, newPolicy, formData, policyId, isPayerRequestAuthenticated,
}) => {
  const [isAddPayerModalVisible, setAddPAyerModalVisible] = useReduxState(`policy-form-${policyId}-isAddPayerModalVisible`, false);
  const [isPlanTypeModalVisible, setPlanTypeModal] = useReduxState(`policy-form-${policyId}-isPlanTypeModalVisible`, false);
  const [isEditPlanType, setIsEditPlanType] = useReduxState(`policy-form-${policyId}-isEditPlanType`, false);

  const toggleAddPayerModal = useCallback(() => {
    setAddPAyerModalVisible(!isAddPayerModalVisible);
  }, [isAddPayerModalVisible, setAddPAyerModalVisible]);

  const togglePlanTypeModal = useCallback(() => {
    setPlanTypeModal(!isPlanTypeModalVisible);
  }, [isPlanTypeModalVisible, setPlanTypeModal]);

  const setSelectedPayer = useCallback((value) => {
    const {
      address, phone, submissionMethodName, comments, insurancePayerId, createdOn, name,
    } = value;
    form.customSetFieldsValue({
      payerName: name,
      payerId: insurancePayerId,
      healthPlanId: insurancePayerId,
      addressLine: address,
      phone,
      method: submissionMethodName,
      comment: comments,
      createdOn,
    });
  }, [form]);

  const onSelectedPayer = useCallback(
    (value) => {
      const { insurancePayerId, phone, address } = value;
      form.setFields([{ name: 'payerId', value: insurancePayerId }]);
      form.customSetFieldsValue({
        addressLine: address,
        phone,
      });
    },
    [form],
  );

  const onPolicyTypeSelect = useCallback((selectedPolicyType, allItem) => {
    if (allItem?.children) {
      const selectedPolicyTypeCode = allItem?.children.split(' ').join('');
      form.setFieldsValue({
        requiresAuthorization: getIsPolicyTypeRequireAuthorization(selectedPolicyTypeCode),
      });
      form.setFieldsValue({
        injectionNeedAuth: getIsInjectionAuthNeeded(selectedPolicyTypeCode),
      });
    }
  }, [form]);

  const handleAddPlanTypeModal = useCallback(() => {
    setIsEditPlanType(false);
    togglePlanTypeModal();
  }, [setIsEditPlanType, togglePlanTypeModal]);

  const handleEditPlanTypeModal = useCallback(() => {
    setIsEditPlanType(true);
    togglePlanTypeModal();
  }, [setIsEditPlanType, togglePlanTypeModal]);

  const handlePlanTypeSelect = useCallback((value, allItem) => {
    const { item: { item = {} } = {} } = allItem || {};
    form.customSetFieldsValue({ planTypeId: item?.id, planType: item?.plan });
  }, [form]);

  return (
    <>
      <div className="form-container">
        <div className="heading-with-icon align-center no-top-mr">
          <h2 className="h2-title">{labels.get('titles.pop')}</h2>
          <InsuranceVerificationShieldIcon
            verificationStatus={formData?.verificationStatus}
            lastVerificationDate={formData?.lastVerificationDate}
          />
        </div>
        <Form.Section noOfColumns={3}>
          <Form.Column>
            <div className="group-shadow">
              <div>
                <div style={{ display: 'none' }}>
                  <Input label="" name="healthPlanId" disabled />
                  <Input label="" name="payerId" disabled />
                  <Input label="" name="method" disabled />
                  <Input label="" name="createdOn" disabled />
                  <Input label="" name="planTypeId" disabled />
                </div>
                <div className="payer-wrapper with-edit-option">
                  <div className="add-input">
                    <PayerGridAutoComplete
                      label={labels.get('labels.healthPlan')}
                      name="healthPlan"
                      required
                      nameAccessor="payerName"
                      valueAccessor="name"
                      labelSpan="8"
                      inputSpan="16"
                      dropdownMatchSelectWidth={false}
                      onSelect={setSelectedPayer}
                      params={{
                        sortBy: 'name',
                        sortorder: 'asc',
                      }}
                    />
                    {isPayerRequestAuthenticated && (
                      <span
                        className="add-icon sprite-img-before"
                        onClick={toggleAddPayerModal}
                        role="presentation"
                      />
                    )}
                  </div>
                </div>
                <div className="add-input">
                  <PayerGridAutoComplete
                    label={labels.get('labels.payerName')}
                    name="payerName"
                    required
                    nameAccessor="payerName"
                    valueAccessor="name"
                    labelSpan="8"
                    inputSpan="16"
                    dropdownMatchSelectWidth={false}
                    placeholder={labels.get('placeHolder.payerName')}
                    onSelect={onSelectedPayer}
                    params={{
                      sortBy: 'name',
                      sortorder: 'asc',
                    }}
                  />
                  {isPayerRequestAuthenticated && (
                  <span
                    className="add-icon sprite-img-before"
                    onClick={toggleAddPayerModal}
                    role="presentation"
                  />
                  )}
                </div>
                <Input
                  label={labels.get('labels.payerAddress')}
                  name="addressLine"
                  required
                  id="3"
                  inputSpan="16"
                  labelSpan="8"
                  disabled
                  placeholder={labels.get('placeHolder.payerAddress')}
                />

                <Input
                  label={labels.get('labels.pverifyPolicyType')}
                  name="pverifyPolicyType"
                  id="3"
                  inputSpan="16"
                  labelSpan="8"
                  disabled
                  placeholder={labels.get('placeHolder.pverifyPolicyType')}
                />
                <Input
                  label={labels.get('labels.planName')}
                  name="pverifyPlanName"
                  id="3"
                  inputSpan="16"
                  labelSpan="8"
                  disabled
                  placeholder={labels.get('placeHolder.planName')}
                />
                <PhoneNumberInput
                  label={labels.get('labels.payerPhone')}
                  name="phone"
                  id="3"
                  inputSpan="16"
                  labelSpan="8"
                  placeholder={labels.get('placeHolder.payerPhone')}
                  disabled
                />
                <AntdForm.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => (
                    prevValues.healthPlan !== currentValues.healthPlan)}
                >
                  {({ getFieldValue }) => (
                    <Input
                      labelSpan="8"
                      inputSpan="16"
                      className="submission-method-input"
                      label="Submission Method"
                      disabled
                      value={getFieldValue('method')}
                      isFormItem={false}
                      placeholder={labels.get('placeHolder.submissionMethod')}
                      inputProps={{ style: { color: '#2a4a79', height: '28px' } }}
                    />
                  )}
                </AntdForm.Item>
                <EnumSelect
                  label={labels.get('labels.policyType')}
                  name="policyTypeId"
                  required
                  inputSpan="16"
                  onChange={onPolicyTypeSelect}
                  labelSpan="8"
                  enumName={enums.POLICY_TYPE}
                />

                <CheckBox
                  name="requiresAuthorization"
                  label={labels.get('labels.requiredAuthorization')}
                  inputSpan="16"
                  labelSpan="8"
                  valuePropName="checked"
                />
                <CheckBox
                  name="injectionNeedAuth"
                  label={labels.get('labels.injectionNeedAuth')}
                  inputSpan="16"
                  labelSpan="8"
                  valuePropName="checked"
                />
              </div>
            </div>
          </Form.Column>
          <Form.Column>
            <div className="group-shadow">
              <div>
                <AntdForm.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => (
                    prevValues.healthPlan !== currentValues.healthPlan)}
                >
                  {({ getFieldValue }) => newPolicy && (getFieldValue('payerName')?.toLowerCase() === 'medicare') && (
                    <EnumSelect
                      label="MSP Type"
                      name="mspTypeId"
                      inputSpan="16"
                      onChange={onPolicyTypeSelect}
                      labelSpan="8"
                      enumName={enums.MSP_TYPE}
                    />
                  )}
                </AntdForm.Item>
                <AntdForm.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => (
                    prevValues.planType !== currentValues.planType)}
                >
                  {({ getFieldValue }) => (
                    <div className={`add-input add-input-with-icon  ${getFieldValue('planTypeId') ? 'add-edit-icon' : ''}`}>
                      <WiredSelectWithoutTab
                        id="plan-type-list"
                        name="planTypeId"
                        url={apiUrls.GET_PLAN_TYPE_LIST}
                        label={labels.get('labels.planType')}
                        placeholder={labels.get('placeHolder.planType')}
                        nameAccessor="plan"
                        valueAccessor="id"
                        isStatCaseOptions={false}
                        selectProps={{
                          showSearch: true,
                          showArrow: true,
                        }}
                        labelSpan="8"
                        inputSpan="16"
                        onChange={handlePlanTypeSelect}
                        optionRenderer={planTypeOptionRenderer}
                        defaultSorting={false}
                      />
                      {getFieldValue('planTypeId') && (
                      <span
                        className="edit-icon sprite-img-before"
                        onClick={handleEditPlanTypeModal}
                        role="presentation"
                      />
                      )}
                      <span
                        className="add-icon sprite-img-before"
                        onClick={handleAddPlanTypeModal}
                        role="presentation"
                      />
                    </div>
                  )}
                </AntdForm.Item>
                <Input
                  label={labels.get('labels.memberNumber')}
                  name="memberNumber"
                  required
                  id="6"
                  inputSpan="16"
                  labelSpan="8"
                  placeholder={labels.get('placeHolder.memberNumber')}
                  alphaNumericOnly
                  /* numberOnly  commenting this based on client feedback on email */
                />
                <Input
                  label={labels.get('labels.groupNumber')}
                  name="groupNumber"
                  id="8"
                  inputSpan="16"
                  labelSpan="8"
                  placeholder={labels.get('placeHolder.groupNumber')}
                  /* numberOnly  commenting this based on client feedback on email */
                />
                <Input
                  label={labels.get('labels.groupName')}
                  name="groupName"
                  id="9"
                  inputSpan="16"
                  labelSpan="8"
                  placeholder={labels.get('placeHolder.groupName')}
                />
                <Input
                  label={labels.get('labels.coPayment')}
                  name="coPayment"
                  id="9"
                  inputSpan="16"
                  labelSpan="8"
                  // numberOnly
                  rules={[{ validator: valueChecker }]}
                  placeholder={labels.get('placeHolder.coPayment')}
                />
                <Input
                  label={labels.get('labels.deductible')}
                  name="deductible"
                  id="9"
                  inputSpan="16"
                  labelSpan="8"
                  placeholder={labels.get('placeHolder.deductible')}
                  // numberOnly
                  rules={[{ validator: valueChecker }]}
                />
                <Input
                  label={labels.get('labels.outDeductible')}
                  name="outDeductible"
                  id="9"
                  inputSpan="16"
                  labelSpan="8"
                  placeholder={labels.get('placeHolder.outDeductible')}
                  // numberOnly
                  rules={[{ validator: valueChecker }]}
                />
                <Input
                  label={labels.get('labels.coInsurance')}
                  name="coInsurance"
                  id="9"
                  inputSpan="16"
                  labelSpan="8"
                  placeholder={labels.get('placeHolder.coInsurance')}
                  maxValueLength={3}
                  numberOnly
                  decimalPlaces={2}
                  // rules={[{ validator: valueChecker }]}
                />
              </div>
            </div>
          </Form.Column>
          <Form.Column>
            <div className="group-shadow">
              <div>
                <DatePicker
                  label={labels.get('labels.effectiveFrom')}
                  name="effectiveFrom"
                  id="10"
                  inputSpan="16"
                  labelSpan="8"
                  required
                />
                <DatePicker
                  label={labels.get('labels.effectiveTo')}
                  name="effectiveTo"
                  id="10"
                  inputSpan="16"
                  labelSpan="8"
                  rules={[
                    ({ getFieldValue }) => toDateValidator(getFieldValue, 'effectiveFrom'),
                  ]}
                />
                <Input label={labels.get('labels.comments')} placeholder="Enter Comments" name="comment" id="9" inputSpan="16" labelSpan="8" />
                <CheckBox
                  label={labels.get('labels.electronic')}
                  name="electronic"
                  inputSpan="16"
                  labelSpan="8"
                  valuePropName="checked"
                />
                <CheckBox
                  label={labels.get('labels.manual')}
                  name="manual"
                  inputSpan="16"
                  labelSpan="8"
                  valuePropName="checked"
                />
                <AntdForm.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => (
                    prevValues.policyTypeId !== currentValues.policyTypeId)}
                >
                  {({ getFieldValue, getFieldInstance }) => {
                    const isRequiredFields = checkRequiredFields(getFieldInstance('policyTypeId')?.props?.enumData, getFieldValue('policyTypeId'));
                    return (
                      <>
                        <DatePicker
                          label={labels.get('labels.dateOfInjury')}
                          name="dateOfInjury"
                          id="10"
                          inputSpan="16"
                          labelSpan="8"
                          required={isRequiredFields}
                        />
                        <Input
                          label={labels.get('labels.adjusterName')}
                          name="adjusterName"
                          id="9"
                          inputSpan="16"
                          labelSpan="8"
                          maxValueLength={200}
                          placeholder={labels.get('placeHolder.adjusterName')}
                          required={isRequiredFields}
                        />
                      </>
                    );
                  }}
                </AntdForm.Item>
                <PhoneNumberInput
                  label={labels.get('labels.adjusterNumber')}
                  name="adjusterNumber"
                  id="9"
                  inputSpan="16"
                  labelSpan="8"
                  maxValueLength={10}
                  minValueLength={10}
                  placeholder={labels.get('placeHolder.adjusterNumber')}
                  inputProps={{ 'data-testid': 'adjust-number-input' }}
                />
              </div>
            </div>
          </Form.Column>
        </Form.Section>
      </div>
      <Modals
        destroyOnClose
        visible={isAddPayerModalVisible}
        title={labels.get('titles.requestNewPayer')}
        toggleModal={toggleAddPayerModal}
        footer={null}
      >
        <RequestNewPayerForm toggleModal={toggleAddPayerModal} />
      </Modals>
      <Modals
        destroyOnClose
        visible={isPlanTypeModalVisible}
        title={isEditPlanType ? labels.get('titles.editPlanType') : labels.get('titles.addPlanType')}
        toggleModal={togglePlanTypeModal}
        footer={null}
      >
        <AddPlanType
          labels={labels}
          toggleModal={togglePlanTypeModal}
          isEditPlanType={isEditPlanType}
          policyFormInstance={form}
        />
      </Modals>
    </>
  );
};

PPO.defaultProps = {
  payer: {},
  onPayerSelect: () => { /* This is intentional */ },
};

export default PPO;
