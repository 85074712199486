import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import { Form as AntdForm } from 'antd';
import findIndex from 'lodash/findIndex';

import Events from '../../../../../lib/events';
import useCRUD from '../../../../../hooks/useCRUD';
import { apiUrls } from '../../../../../api/constants';
import SuccessMessages from '../../../../../lib/successMessages';
import iconNames from '../../../../../lib/iconNames';

import Button from '../../../../../components/Button';
import SelectBox from '../../../../../components/SelectBox';
import Notification from '../../../../../components/Notification';
import Icon from '../../../../../components/Icon';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import Form from '../../../../../components/Form';

import RenameTemplateModal from './RenameTemplateModal';

const HeaderMenu = ({
  visible,
  onSelect,
  toggleSaveModal,
  labels,
  setHeaderHTML,
  savedHeaderFooterId,
  setSavedHeaderFooterId,
}) => {
  const [parsedHeaderList, setParsedHeaderList] = useState([]);
  const [isRenameModalVisible, setRenameModalVisibility] = useState(false);
  const [headerTemplateData, setHeaderTemplateData] = useState(null);
  const [form] = AntdForm.useForm();

  const [
    headerList,,
    headerListLoading,
    getHeaderList,
  ] = useCRUD({
    id: 'get-template-header-list',
    url: apiUrls.GET_TEMPLATE_HEADER_FOOTER_LIST,
    type: 'read',
  });

  const [
    deleteTemplateResponse,,,
    deleteHeaderFooterTemplate,
    clearDeleteResponse,
  ] = useCRUD({
    id: 'delete-selected-template-header',
    url: apiUrls.DELETE_HEADER_FOOTER_TEMPLATE,
    type: 'delete',
  });

  useEffect(() => {
    if (deleteTemplateResponse) {
      Notification({ message: SuccessMessages.TEMPLATE_DELETED_SUCCESSFULLY, success: true });
      Events.trigger('reFetchHeaderList');
      form.resetFields();
      setSavedHeaderFooterId({ ...savedHeaderFooterId, header: null });
      clearDeleteResponse();
      setHeaderTemplateData(null);
      if (setHeaderHTML) {
        setHeaderHTML('');
      }
    }
  }, [clearDeleteResponse, deleteTemplateResponse, form, savedHeaderFooterId]);

  useEffect(() => {
    getHeaderList({}, '/header');
  }, [getHeaderList]);

  useEffect(() => {
    if (headerList) {
      setParsedHeaderList(headerList?.map((item) => ({
        ...item,
        name: item?.name,
        value: item?.composerTemplateHeaderFooterId,
      })));
    }
  }, [headerList]);

  useEffect(() => {
    Events.on('reFetchHeaderList', 'reFetchHeaderList', () => getHeaderList({}, '/header'));
    return () => Events.remove('reFetchHeaderList');
  }, []);

  useEffect(() => {
    if (savedHeaderFooterId?.header && !headerListLoading) {
      form.setFieldsValue({ headerSelect: savedHeaderFooterId?.header });
      const index = findIndex(parsedHeaderList,
        // eslint-disable-next-line max-len
        (headerOption) => headerOption?.composerTemplateHeaderFooterId === savedHeaderFooterId?.header);
      setHeaderTemplateData(parsedHeaderList[index] || {});
    }
  }, [form, savedHeaderFooterId, parsedHeaderList, headerListLoading]);

  const toggleRenameModal = useCallback(() => {
    setRenameModalVisibility(!isRenameModalVisible);
  }, [isRenameModalVisible]);

  const onChangeTemplate = useCallback((value, data) => {
    setHeaderTemplateData(data?.item);
  }, []);

  const handleDelete = useCallback(() => {
    ConfirmDialog({
      onOk: (close) => {
        deleteHeaderFooterTemplate({}, `/Header/${headerTemplateData?.composerTemplateHeaderFooterId}`);
        close();
      },
      okText: labels.get('buttons.delete'),
      title: labels.get('titles.warning'),
      content: labels.get('message.warningMessage'),
      icon: <Icon name="ExclamationCircleOutlined" />,
    })();
  }, [deleteHeaderFooterTemplate, headerTemplateData, labels]);

  return (
    <>
      <div className={classNames('extra-options-container header-options-container', { visible })}>
        <Form form={form}>
          <SelectBox
            name="headerSelect"
            placeholder="Load Previous"
            onChange={onChangeTemplate}
            selectProps={{
              loading: headerListLoading,
              onSelect,
              allowClear: false,
            }}
            options={parsedHeaderList}
          />
        </Form>
        <Button className="btn btn-success sm-btn" type="submit" onClick={() => toggleSaveModal('header')}>Save Header</Button>
        {headerTemplateData && (
        <>
          <Button className="btn sm-btn mr-lt-10" onClick={toggleRenameModal}>
            <Icon className="cursor-pointer" name={iconNames.edit} />
          </Button>
          <Button className="btn sm-btn mr-lt-10" onClick={handleDelete}>
            <Icon className="cursor-pointer" name={iconNames.deleteIcon} />
          </Button>
        </>
        )}
      </div>
      <RenameTemplateModal
        sectionName="Header"
        isVisible={isRenameModalVisible}
        toggleModal={toggleRenameModal}
        labels={labels}
        selectedTemplate={headerTemplateData}
        reFetchEvent="reFetchHeaderList"
      />
    </>
  );
};

export default HeaderMenu;
