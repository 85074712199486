import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import moment from 'moment';
import find from 'lodash/find';
import filter from 'lodash/filter';
import useCRUD from '../../../../../hooks/useCRUD';
import useRights from '../../../../../hooks/useRights';
import useRedirect from '../../../../../hooks/useRedirect';
import withQuery from '../../../../../hoc/withQuery/withQuery';

import rights from '../../../../../lib/rights';
import iconNames from '../../../../../lib/iconNames';
import { apiUrls } from '../../../../../api/constants';
import { listIds, UiRoutes } from '../../../../../lib/constants';
import SuccessMessages from '../../../../../lib/successMessages';

import Icon from '../../../../../components/Icon';
import Button from '../../../../../components/Button';
import Table from '../../../../../components/Table';
import TableWrapper from '../../../../../components/Table/TableWrapper';

import Notification from '../../../../../components/Notification';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import FilterComponents from '../../../../../components/FilterComponents';
import FilterManager from '../../../../../components/FilterManager';
import WiredSelect from '../../../../../wiredComponents/Select/selectBoxV2';
import TreeSelect from '../../../../../components/TreeSelect';

import UserLocationModal from '../UserLocationModal';
import expandRowColumns from './expandRowColumns';
import sortData from './sortingUtils';
import columns from './columns';

const groupChildRoleData = (data) => {
  const newGroupData = data?.reduce((acc, value) => {
    if (acc[value.roleId]) {
      acc[value.roleId].locationName = value?.locationName && !acc[value.roleId]?.locationName?.includes(value?.locationName) ? `${acc[value.roleId]?.locationName} , ${value?.locationName}` : acc[value.roleId]?.locationName;
    } else {
      acc[value.roleId] = {
        roleId: value.roleId,
        roleName: value.roleName,
        locationName: value?.locationName,
        locationId: value?.locationId,
        allLocations: value?.allLocations,
      };
      if (value.allLocations) {
        acc[value.roleId].locationName = 'All locations';
      }
    }
    return acc;
  }, {});
  return Object.values(newGroupData || {});
};

const groupRoleData = (userRoleLocationData) => {
  const data = userRoleLocationData?.reduce((acc, value) => {
    if (acc[value.permissionSetId]) {
      acc[value.permissionSetId].moduleAccess = value?.moduleAccess && !acc[value.permissionSetId]?.moduleAccess?.includes(value?.moduleAccess) ? `${acc[value.permissionSetId]?.moduleAccess} , ${value?.moduleAccess}` : acc[value.permissionSetId]?.moduleAccess;
      if (value?.modifiedOn) {
        if (acc[value.permissionSetId]?.modifiedOn
           && moment(value.modifiedOn).diff(moment(acc[value.permissionSetId].modifiedOn)) > 0) {
          acc[value.permissionSetId].modifiedOn = value?.modifiedOn;
          acc[value.permissionSetId].modifiedBy = value?.modifiedBy;
        } else if (!acc[value.permissionSetId]?.modifiedOn) {
          acc[value.permissionSetId].modifiedOn = value.modifiedOn;
          acc[value.permissionSetId].modifiedBy = value.modifiedBy;
        }
      }

      if (value.roleId && value.roleName) {
        if (acc[value.permissionSetId].childRoleData) {
          acc[value.permissionSetId].childRoleData.push({
            roleId: value.roleId,
            roleName: value.roleName,
            locationId: value.locationId,
            locationName: value.locationName,
            allLocations: value.allLocations,
          });
        } else {
          acc[value.permissionSetId].childRoleData = [{
            roleId: value.roleId,
            roleName: value.roleName,
            locationId: value.locationId,
            locationName: value.locationName,
            allLocations: value.allLocations,
          }];
        }
      }
    } else {
      acc[value.permissionSetId] = {
        permissionSetId: value.permissionSetId,
        permissionSetName: value.permissionSetName,
        moduleAccess: value?.moduleAccess,
        modifiedBy: value?.modifiedBy,
        modifiedOn: value?.modifiedOn,
      };
      if (value.roleId && value.roleName) {
        acc[value.permissionSetId].childRoleData = [{
          roleId: value.roleId,
          roleName: value.roleName,
          locationId: value.locationId,
          locationName: value.locationName,
          allLocations: value.allLocations,
        }];
      }
    }
    return acc;
  }, {});
  let key = 0;
  const newData = Object.values(data || {}).map((value) => {
    const val = value;
    if (val?.childRoleData) {
      val.childRoleData = groupChildRoleData(val.childRoleData);
    }
    val.key = key.toString();
    key += 1;
    return val;
  });
  return newData;
};

const UserRoleTable = withQuery({
  url: apiUrls.USER_LOCATION,
  listId: 'get-user-role-data',
  accessor: (data) => ({ result: groupRoleData(data) }),
})();

const FilterCollection = FilterComponents([
  {
    type: 'search',
    filterProps: {
      placeholder: 'Search',
      name: 'SearchText',
      id: 'cpt_filter_search',
    },
  },
]);

const UserRoleLocationTable = () => {
  const [userLocationModalVisible, setUserLocationModalVisible] = useState(false);
  const [selectedPermissionSetData, setSelectedPermissionSetData] = useState({});
  const [refetch, setRefetch] = useState(() => {});
  const [moduleValue, setModuleValue] = useState([]);

  const { push, generatePath } = useRedirect();
  const [isManageRightsAuthenticated,
    isManageRolesAuthenticated] = useRights([rights.manage_rights, rights.manage_roles]);

  const [deleteUserRoleResponse,,, deleteUserRole, clearDeleteUserRole] = useCRUD({
    id: 'delete-user-role', url: apiUrls.DELETE_USER_ROLE, type: 'delete',
  });

  const [deleteUserPermisionResponse,,, deleteUserPermission, clearDeleteUserPermission] = useCRUD({
    id: 'delete-user-permission', url: apiUrls.DELETE_USER_PERMISSION, type: 'delete',
  });

  const [rightsData,,, getRightsData] = useCRUD({
    id: listIds.GET_RIGHTS_DATA, url: apiUrls.GET_USER_RIGHTS_MASTER, type: 'read',
  });

  const parsedRightsData = useMemo(() => {
    if (rightsData?.length) {
      const parsedData = [];
      rightsData?.forEach((item) => {
        const parsedChildren = [];
        item?.claimsMaster?.forEach((child) => {
          parsedChildren.push({
            title: child.claimsMasterName,
            value: child.claimsMasterId,
            isLeaf: true,
          });
        });
        parsedData.push({
          title: item.rightsMasterName,
          value: `parent-${item.rightsMasterId}`,
          children: parsedChildren,
        });
      });
      return parsedData;
    }
    return [];
  }, [rightsData]);

  useEffect(() => {
    if (deleteUserRoleResponse) {
      Notification({ message: SuccessMessages.USER_ROLE_DELETE_SUCCESSFULLY, success: true });
      refetch();
      clearDeleteUserRole();
    }
    if (deleteUserPermisionResponse) {
      Notification({
        message: SuccessMessages.USER_PERMISSION_ROLE_DELETE_SUCCESSFULLY,
        success: true,
      });
      refetch();
      clearDeleteUserPermission();
    }
  }, [deleteUserRoleResponse, deleteUserPermisionResponse]);

  const handleUserLocationModal = useCallback(() => {
    setUserLocationModalVisible((value) => !value);
  }, []);

  const handleEditRole = useCallback((selectedData) => {
    push(UiRoutes.UserRolesEdit,
      {
        permissionSetName: selectedData?.permissionSetName,
        permissionSetId: selectedData?.permissionSetId,
      });
  }, [push]);

  const handleAddChildRole = useCallback((data) => {
    setUserLocationModalVisible(true);
    setSelectedPermissionSetData(data);
  }, []);

  const addUserRole = useCallback(() => {
    push(generatePath(UiRoutes.UserRolesAdd));
  }, [generatePath, push]);

  const handleDeleteRole = useCallback((id, isParent) => {
    ConfirmDialog({
      onOk: (close) => {
        if (isParent) {
          deleteUserPermission({}, `?permissionSetId=${id}`);
        } else {
          deleteUserRole({}, `?roleId=${id}`);
        }
        close();
      },
      okText: 'Ok',
      title: 'Warning',
      content: 'If this role is associated with user(s) then this association will get deleted also , would you still like to continue?',
      icon: <Icon name={iconNames.exclamationCircleOutlined} />,
    })();
  }, [deleteUserPermission, deleteUserRole]);

  const expandedRowRender = useCallback(({ row }) => (
    <div className="custom-fixed-table-height">
      <Table
        columns={expandRowColumns}
        data={row?.original?.childRoleData}
        isManagePermitted={isManageRightsAuthenticated || isManageRolesAuthenticated}
        isHeaderFixed={false}
        handleDeleteRole={handleDeleteRole}
      />
    </div>
  ), [handleDeleteRole, isManageRightsAuthenticated, isManageRolesAuthenticated]);

  const handleLocationFilter = useCallback((data, onFiltersChange, currentFilters) => {
    const locationIds = data?.map((item) => item?.value)?.join(',');
    const clonedFilters = { ...currentFilters };
    clonedFilters.locationIds = locationIds;
    onFiltersChange(clonedFilters, true);
  }, []);

  useEffect(() => {
    getRightsData();
  }, []);

  const handleModulePermissionFilter = useCallback((value = [], label, item,
    onFiltersChange, currentFilters) => {
    const parentNode = find(parsedRightsData, ['value', item?.triggerValue]);
    if (parentNode) {
      if (item?.selected) {
        parentNode?.children?.forEach((childData) => {
          if (value?.indexOf(childData?.value) < 0) {
            value.push(childData?.value);
          }
        });
      } else {
        parentNode?.children?.forEach((childData) => {
          if (value?.indexOf(childData?.value) >= 0) {
            value.splice(value?.indexOf(childData?.value), 1);
          }
        });
      }
    } else {
      const childNode = filter(parsedRightsData, { children: [{ value: item?.triggerValue }] });
      if (childNode?.length) {
        const children = childNode[0]?.children;
        if (item?.selected) {
          const isAllChildSelected = children?.every(({ value: id }) => value.includes(id));
          if (isAllChildSelected) {
            value.push(childNode[0]?.value);
          }
        } else {
          const noChildSelected = children?.every(({ value: id }) => !value.includes(id));
          if (noChildSelected && value.indexOf(childNode[0]?.value) > -1) {
            value.splice(value.indexOf(childNode[0]?.value), 1);
          }
        }
      }
    }

    setModuleValue(value);
    const data = value?.filter((val) => !(typeof val === 'string' && val?.includes('parent')));
    const claimsMasterIds = data?.join(',');
    const clonedFilters = { ...currentFilters };
    clonedFilters.claimsmasterids = claimsMasterIds;
    onFiltersChange(clonedFilters, true);
  }, [parsedRightsData]);

  return (
    <>
      <div className="heading-area flex justify-content-sp-bt align-center user-screen">
        <h2>User Role</h2>
      </div>
      <div className="user-role-location-table">
        <div className="posi-relative">
          <UserRoleTable
            columns={columns}
            handleAddChildRole={handleAddChildRole}
            handleEditRole={handleEditRole}
            handleDeleteRole={handleDeleteRole}
            renderRowSubComponent={expandedRowRender}
            scrollId="user-role-location"
            isManagePermitted={isManageRightsAuthenticated || isManageRolesAuthenticated}
            autoResetExpanded={false}
            customSort={sortData}
          >
            {({
              Component, initialFilters, onFiltersChange, reFetch,
            }) => (
              <FilterManager
                initialFilters={initialFilters}
                onChange={onFiltersChange}
              >
                {({ onFilterChange, filters }) => (
                  <>
                    <div className="table-filters">
                      <div className="flex table-filters-wrap">
                        <FilterCollection
                          onFilterChange={onFilterChange}
                          filters={filters}
                        />
                        <div className="flex dropdown-filters-roles-wrapper">
                          <WiredSelect
                            id="location"
                            name={null}
                            url={apiUrls.SCHEDULAR_LOCATION_DROPDOWN}
                            inputSpan="24"
                            labelSpan="0"
                            valueAccessor="locationId"
                            nameAccessor="locationName"
                            onChange={(value, data) => handleLocationFilter(
                              data, onFilterChange, filters,
                            )}
                            selectProps={{
                              style: {
                                width: 250,
                                maxWidth: 250,
                                dropdownMatchSelectWidth: 250,
                              },
                              showArrow: true,
                              maxTagCount: 'responsive',
                              mode: 'multiple',
                              placeholder: 'Select Locations',
                            }}
                          />
                          <div className="mr-lt-16" style={{ width: '250px' }}>
                            <TreeSelect
                              dropdownClassName="insurance-tree-dropdown"
                              options={parsedRightsData}
                              labelSpan="0"
                              inputSpan="24"
                              selectNodes={moduleValue}
                              value={moduleValue}
                              placeholder="Please select"
                              onChange={(value, label, item) => handleModulePermissionFilter(
                                value, label, item, onFilterChange, filters,
                              )}
                              selectProps={{
                                treeNodeFilterProp: 'title',
                                multiple: true,
                                maxTagCount: 'responsive',
                                mode: 'multiple',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mr-bt-8">
                        {(isManageRightsAuthenticated || isManageRolesAuthenticated) && (
                        <Button
                          type="button"
                          className="sm-btn btn-success"
                          onClick={addUserRole}
                        >
                          Add New Role
                        </Button>
                        )}
                      </div>
                    </div>
                    <TableWrapper
                      Component={Component}
                      reFetch={reFetch}
                      setReFetch={setRefetch}
                    />
                  </>
                )}
              </FilterManager>
            )}
          </UserRoleTable>
        </div>
      </div>
      {userLocationModalVisible && (
        <UserLocationModal
          isVisible={userLocationModalVisible}
          toggleModal={handleUserLocationModal}
          selectedPermissionSetData={selectedPermissionSetData}
          setSelectedPermissionSetData={setSelectedPermissionSetData}
          getRoleUserLocation={refetch}
        />
      )}
    </>
  );
};

export default UserRoleLocationTable;
