import React, { useState } from 'react';

import rights from '../../../lib/rights';
import WithRights from '../../../hoc/withRights';

import Tabs from '../../../components/Tabs/TabsWithoutUrlNavigation';
import DxFavorite from './Tabs/DxFavorite';
import ManageOrderSet from './Tabs/OrderSet';
import ProcedureSet from './Tabs/ProcedureSet';

import './managefavdoc.scss';

const tabsList = [
  {
    id: 'order',
    component: ManageOrderSet,
    name: 'Order Sets',
  },
  {
    id: 'procedure',
    component: ProcedureSet,
    name: 'Procedure Sets',
  },
  {
    id: 'dx',
    component: DxFavorite,
    name: 'Dx Favorites',
  },
];

const ManageFavDoc = () => {
  // this could be use so its commented for now
  // const [createOrderValue, setCreateOrderValue] = useState(false);
  // const createOderSet = () => {
  //   setCreateOrderValue(!createOrderValue);
  // };
  const [selectedTab, setSelectedTab] = useState('order');
  return (
    <div className="white-tabs manage-favorite-wrap">
      <Tabs
        id="order"
        tabsList={tabsList}
        className="main-tab-wrap"
        selectedTab={selectedTab}
        handleChange={setSelectedTab}
      />
      {/* <Button className="btn-success sm-btn">Create</Button> */}
    </div>
  );
};

export default WithRights(ManageFavDoc, rights.show_manage_doctor_favorites);
