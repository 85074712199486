import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { UiRoutes } from '../../../lib/constants';
import WithRights from '../../../hoc/withRights';
import rights from '../../../lib/rights';

import useRights from '../../../hooks/useRights';
import ComposerTable from './Table';
import NewTemplate from './Components/NewTemplate';

const Composer = () => {
  const [isLetterTemplateAuthenticated] = useRights([rights.access_to_clinical_templates]);
  return (
    <Switch>
      <Route exact path={[UiRoutes.newComposerTemplate, UiRoutes.editComposerTemplate]}>
        <NewTemplate />
      </Route>
      <Route path={UiRoutes.composer}>
        <ComposerTable isLetterTemplateAuthenticated={isLetterTemplateAuthenticated} />
      </Route>
    </Switch>
  );
};

export default WithRights(Composer, rights.access_to_composer);
