import React from 'react';

const columns = () => [
  {
    Header: 'Response 1',
    accessor: 'response1',
    Cell: ({ row: { original } }) => <span>{original?.response1?.toString()}</span>,
  },
  {
    Header: 'Response 2',
    accessor: 'response2',
    Cell: ({ row: { original } }) => <span>{original?.response2?.toString()}</span>,
  },
  {
    Header: 'Response 3',
    accessor: 'response3',
    Cell: ({ row: { original } }) => <span>{original?.response3?.toString()}</span>,
  },
  {
    Header: 'Response 4',
    accessor: 'response4',
    Cell: ({ row: { original } }) => <span>{original?.response4?.toString()}</span>,
  },
  {
    Header: 'Response 5',
    accessor: 'response5',
    Cell: ({ row: { original } }) => <span>{original?.response5?.toString()}</span>,
  },
  {
    Header: 'Response 6',
    accessor: 'response6',
    Cell: ({ row: { original } }) => <span>{original?.response6?.toString()}</span>,
  },
  {
    Header: 'Response 7',
    accessor: 'response7',
    Cell: ({ row: { original } }) => <span>{original?.response7?.toString()}</span>,
  },
  {
    Header: 'Response 8',
    accessor: 'response8',
    Cell: ({ row: { original } }) => <span>{original?.response8?.toString()}</span>,
  },
  {
    Header: 'Response 9',
    accessor: 'response9',
    Cell: ({ row: { original } }) => <span>{original?.response9?.toString()}</span>,
  },
  {
    Header: 'Response 10',
    accessor: 'response10',
    Cell: ({ row: { original } }) => <span>{original?.response10?.toString()}</span>,
  },
];

export default columns;
