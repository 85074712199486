import React from 'react';

const useUpdateEffect = (fn, inputs) => {
  const didMountRef = React.useRef(false);
  return React.useEffect(() => {
    if (didMountRef.current) {
      fn();
    } else {
      didMountRef.current = true;
    }
  }, inputs);
};
export default useUpdateEffect;
