import React, {
  useEffect, useState, useMemo, useRef,
} from 'react';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';

import isArray from 'lodash/isArray';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';

import isEmpty from 'lodash/isEmpty';
import Select from '../../../components/Form/SelectBox';

import * as selectors from '../../../store/selectors';
import { getEnumMasterData } from '../../../store/actions/enum';

import './Select.scss';

const EnumSelect = ({
  enumName,
  parentId,
  initialValue,
  form,
  name,
  onChange,
  mode,
  component: Component,
  valueAccessor,
  isOptionSortable,
  extraSelectprops,
  sortByKey = 'name',
  sortByFunction = sortBy,
  startCaseOptions,
  refetchOnEachRender,
  defaultOptions,
  optionParser,
  propsEnumId,
  inActiveOptions,
  excludeOptionNames,
  ...props
}) => {
  const [enumField, setEnumField] = useState({});
  const [isInitialAssigned, setInitialAssigned] = useState(false);
  const [defaultValue, setDefaultValue] = useState(undefined);
  const parentIdData = useRef(parentId);
  const enumOptions = useSelector((state) => selectors.getEnumOptions(state));
  const enumMaster = useSelector((state) => selectors.getEnumMaster(state));
  const enumId = get(enumMaster, `${enumName}.enumId`);
  const dispatch = useDispatch();

  useEffect(() => {
    if ((!enumOptions?.[enumId]?.data?.length
       || (parentIdData.current !== parentId) || refetchOnEachRender) && enumId) {
      parentIdData.current = parentId;
      dispatch(getEnumMasterData(enumId, parentId));
    }
  }, [enumId, parentId, refetchOnEachRender]);

  const { loading, data = [] } = useMemo(() => {
    const { loading: load, data: currentData = [] } = enumField;
    let masterData = currentData?.map((item) => ({
      ...item,
      value: item[valueAccessor || 'masterId'],
      masterCode: item.masterCode,
      [sortByKey]: item[sortByKey],
      name: startCaseOptions ? startCase(lowerCase(item.masterName)) : item.masterName,
      title: item.masterDescription
      || (startCaseOptions ? startCase(lowerCase(item.masterName)) : item.masterName),
    }));
    if (optionParser) {
      masterData = optionParser(masterData);
    }
    return {
      loading: load,
      data: isOptionSortable ? sortByFunction(masterData, sortByKey) : masterData,
    };
  }, [JSON.stringify(enumField), enumOptions, optionParser]);

  useEffect(() => {
    if (!isEmpty(enumOptions) && (enumId || propsEnumId)) {
      if (parentId) {
        setEnumField((enumOptions?.[enumId] && enumOptions[enumId][parentId]) || {});
      } else {
        setEnumField(enumOptions[enumId || propsEnumId] || {});
      }
      if (initialValue && form && !isInitialAssigned && !form.getFieldValue(name) && data?.length) {
        const { data: currentData = [] } = enumOptions[enumId] || {};
        let initial = [];
        if (mode === 'multiple' && isArray(initialValue)) {
          initial = currentData.filter((item) => initialValue.indexOf(item[valueAccessor || 'masterId'].toString()) > -1 || initialValue.indexOf(item[valueAccessor || 'masterId']) > -1);
        } else {
          initial = currentData.filter((item) => item.masterName === initialValue);
        }
        if (initial.length) {
          setInitialAssigned(true);
          if (mode === 'multiple') {
            let selectedOptions = initial.map((item) => item[valueAccessor || 'masterId']);
            if (inActiveOptions && Array.isArray(inActiveOptions)) {
              // We have implemented this to support InActive options in Enum Select.
              const parsedOptions = [];
              inActiveOptions?.forEach((item) => {
                const isExist = data?.findIndex((option) => option?.masterId === item);
                if (isExist > -1) parsedOptions.push(item);
              });
              selectedOptions = parsedOptions;
              // End of the InActive Options code.
            }
            setDefaultValue(selectedOptions);
            form.setFieldsValue({ [name]: selectedOptions });
          } else {
            form.setFieldsValue({ [name]: initial[0][valueAccessor || 'masterId'] });
            if (isFunction(onChange)) {
              onChange(initial[0][valueAccessor || 'masterId'], { children: initial[0].masterName });
            }
          }
        }
      }
    }
  }, [JSON.stringify(enumField), data, enumOptions, mode, form, onChange, enumId, initialValue,
    isInitialAssigned, name, propsEnumId, inActiveOptions]);

  const processedData = useMemo(() => {
    if (excludeOptionNames && excludeOptionNames.length > 0) {
      return data.filter((option) => !excludeOptionNames.includes(option[valueAccessor || 'name']));
    }
    return data;
  }, [data, excludeOptionNames, valueAccessor]);

  return (
    <div className={data?.length ? '' : 'hidden-wrapper'}>
      <Component
        loading={loading}
        options={defaultOptions || processedData}
        name={name}
        onChange={onChange}
        mode={mode}
        defaultValue={defaultValue}
        enumData={processedData}
        selectProps={{ enumData: processedData, showSearch: true, ...extraSelectprops }}
        {...props}
      />
    </div>
  );
};

EnumSelect.defaultProps = {
  component: Select,
  isOptionSortable: true,
  startCaseOptions: false,
  extraSelectprops: {},
  refetchOnEachRender: false,
  defaultOptions: null,
  excludeOptionIds: [],
};

export default EnumSelect;
