import React, {
  useCallback, useState, useMemo, useEffect, useRef,
} from 'react';
import { Route } from 'react-router-dom';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import { Form as AntdForm } from 'antd';
import moment from 'moment';

import isEmpty from 'lodash/isEmpty';
import useCRUD from '../../../../../hooks/useCRUD';
import withLabel from '../../../../../hoc/withLabel';
import { apiUrls } from '../../../../../api/constants';
import useRedirect from '../../../../../hooks/useRedirect';
import successMessages from '../../../../../lib/successMessages';
import {
  labelPaths, UiRoutes, listIds, enums, enumMasterCode,
} from '../../../../../lib/constants';
import Events from '../../../../../lib/events';

import useMasterId from '../../../../../hooks/useMasterId';
import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import Search from '../../../../../components/Search';
import Loader from '../../../../../components/Loader';
import Input from '../../../../../components/Form/Input';
import Notification from '../../../../../components/Notification';
import EventWrapper from '../../../../../components/EventWrapper';
import DatePicker from '../../../../../components/Form/DatePicker';

import WiredSelect from '../../../../../wiredComponents/Select';
import EnumSelect from '../../../../../wiredComponents/Enum/Select';

import WorkList from './Worklist';
import PostedClaimTable from '../InsurancePayment/PostedClaimsTable';
import LineItemsTable from './PatientLineItemTable';
import PatientPaymentTable from './PatientPaymentTable';
import { parsedDecimalAmount } from '../RemittanceCommonParser';

import './patientPayment.scss';
import {
  convertToRangeString, getPageNumbers, getuuid, isPageRange, sortedUniq,
} from '../../../../../lib/util';
import useReduxState from '../../../../../hooks/useReduxState';

const setCurrentPath = () => {};
const getPaymentTypeDetails = (paymentOptions = [], selectedPaymentValue) => {
  if (paymentOptions?.length) {
    return paymentOptions?.find(
      (item) => item.masterId === selectedPaymentValue)?.masterCode?.toLowerCase();
  }
  return '';
};

const masterCodes = [
  enumMasterCode.Remittance_Insurance_Payment,
];

const enumNames = [
  enums.REMITTANCE_PAYMENT_TYPE,
];

const PatientPayment = ({
  onBack, labels, currentPageNo, totalNoOfPages, remittanceBatchData, setCurrentPageNo,
  isElectronicBatch, path,
}) => {
  const {
    replace, generatePath, params: {
      remittanceId, remittancePaymentId, patientPaymentPage, tabId, pageNo,
    }, params, path: currentPath,
  } = useRedirect();
  const uuidRef = useRef(getuuid());
  const formId = path.indexOf('new') > -1 ? uuidRef : remittancePaymentId;
  const [customPatientFormInstance] = AntdForm.useForm();
  const masterCodesWithId = useMasterId(masterCodes, enumNames);

  const [
    patientPaymentDetails,,,
    getPatientPaymentDetails,
    clearPatientPaymentDetails,
  ] = useCRUD({
    id: `${listIds.GET_REMITTANCE_PAYMENT_DETAILS}-${formId}`,
    url: apiUrls.GET_REMITTANCE_PAYMENT_DETAILS,
    type: 'read',
  });

  const [
    secondPatientPaymentDetails,,,
    getSecondPatientPaymentDetails,
    clearSecondPatientPaymentDetails,
  ] = useCRUD({
    id: `${listIds.GET_REMITTANCE_PAYMENT_DETAILS}-Second-${formId}`,
    url: apiUrls.GET_REMITTANCE_PAYMENT_DETAILS,
    type: 'read',
  });

  const [
    viewEnteredItemsDetails,,
    viewEnteredItemsDetailsloading,
    getViewEnteredItemsDetails,
    clearViewEnteredItemsDetails,
  ] = useCRUD({
    id: `GET_VIEW_ENTERED_ITEMS_${formId}`,
    url: apiUrls.GET_VIEW_ENTERED_ITEMS,
    type: 'read',
  });

  const [
    amountAfterPostingResponse,,,
    getAmountAfterPostingBatch,
    clearGetAmountResponse,
  ] = useCRUD({
    id: `GET_AMOUNT_AFTER_POSTING_BATCH_PATIENT_PAYMENT-${formId}`,
    url: apiUrls.GET_AMOUNT_AFTER_POSTED_BATCH,
    type: 'read',
  });

  const [
    patientPaymentLineItemResponse,,
    patientPaymentLineItemLoading,
    savePatientPaymentLineItem,
    clearPatientPaymentLineItem,
  ] = useCRUD({
    id: `${listIds.PATIENT_PAYMENT_ENTERED_LINE_ITEMS}-${formId}`,
    url: apiUrls.PATIENT_PAYMENT_ENTERED_LINE_ITEMS,
    type: 'create',
  });

  const [
    patientPaymentResponse,,,
    savePatientPayment,
    clearPatientPayment,
  ] = useCRUD({
    id: `${listIds.SET_PATIENT_PAYMENT}-${formId}`,
    url: apiUrls.SET_PATIENT_PAYMENT,
    type: 'create',
  });

  const [
    completeCheckResponse,,
    completeCheckLoading,
    completeCheck,
    clearCompleteCheck,
  ] = useCRUD({
    id: `${listIds.REMITTANCE_FINANCIAL_COMPLETE_CHECK}-${formId}`,
    url: apiUrls.REMITTANCE_FINANCIAL_COMPLETE_CHECK,
    type: 'create',
  });

  const [searchText, setSearchText] = useReduxState(`patient-payment-searchText-${formId}`);
  const [search, setSearch] = useReduxState(`patient-payment-search-${formId}`);
  const [isWorklistModalVisible, setIsWorklistModalVisible] = useState(false);
  const [pageRange, setPageRange] = useReduxState(`Patient-Payment-Page-Range-${formId}`, '');
  const [needToScroll, setNeedToScroll] = useReduxState(`Patient-Payment-Need-To-Scroll-${formId}`, true);
  const [claimPageNo, setClaimPageNo] = useReduxState(`Insurance-Claim-Page-No-${formId}`, currentPageNo);

  const [form] = AntdForm.useForm();

  const debouncedSetSearch = useMemo(() => debounce(setSearch, 1500), [setSearch]);

  const handleSearch = useCallback(({ target: { value } }) => setSearchText(value),
    [setSearchText]);

  useEffect(() => {
    const newSearchString = searchText || '';
    if (newSearchString && newSearchString.length >= 3) {
      debouncedSetSearch(newSearchString);
    } else {
      debouncedSetSearch();
    }
  }, [debouncedSetSearch, searchText]);

  const isBatchPosted = useMemo(() => remittanceBatchData?.stateMasterCode === 'BillingStatePosted', [remittanceBatchData]);

  const shouldCallAlreadyPaidAmount = useMemo(() => remittanceBatchData?.stateMasterCode === 'BillingStatePosted'
  || remittanceBatchData?.stateMasterCode === 'BillingStateOutBox' || remittanceBatchData?.stateMasterCode === 'BillingStateError', [remittanceBatchData]);
  useEffect(() => {
    Events.on(`GET_VIEW_ENTERED_ITEMS_${remittancePaymentId}`, `GET_VIEW_ENTERED_ITEMS_${remittancePaymentId}`, () => {
      getViewEnteredItemsDetails({ PaymentId: remittancePaymentId });
    });
    return () => { Events.remove(`GET_VIEW_ENTERED_ITEMS_${remittancePaymentId}`, `GET_VIEW_ENTERED_ITEMS_${remittancePaymentId}`); };
  }, []);

  useEffect(() => {
    Events.on('GET_AMOUNT_AFTER_POSTING_BATCH_PATIENT_PAYMENT', 'GET_AMOUNT_AFTER_POSTING_BATCH_PATIENT_PAYMENT', () => {
      getAmountAfterPostingBatch({
        PaymentId: remittancePaymentId,
        remittanceBatchId: remittanceId,
      });
    });
    return () => { Events.remove('GET_AMOUNT_AFTER_POSTING_BATCH_PATIENT_PAYMENT', 'GET_AMOUNT_AFTER_POSTING_BATCH_PATIENT_PAYMENT'); };
  }, []);

  const handleGoBack = useCallback(() => {
    form.completeReset();
    setSearchText();
    onBack();
  }, [form, onBack, setSearchText]);

  const navigateToClaimTable = useCallback(() => {
    if (!isBatchPosted) {
      setSearchText();
      replace(
        generatePath(
          `${path}/searchClaim`,
          params,
        ),
      );
    }
  }, [isBatchPosted, setSearchText, replace, generatePath, path, params]);

  const navigateToPostedClaims = useCallback(() => {
    setSearchText();
    replace(
      generatePath(
        path,
        params,
      ),
    );
  }, [setSearchText, replace, generatePath, path, params]);

  const navigateBack = useCallback(() => {
    form.completeReset();
    onBack();
    replace(
      generatePath(
        UiRoutes.claimsRemittance,
        params,
      ),
    );
  }, [form, onBack, replace, generatePath, params]);

  useEffect(() => {
    if (completeCheckResponse) {
      Notification({ message: successMessages.PATIENT_PAYMENT_CHECK_COMPLETE, success: true });
      clearCompleteCheck();
      Events.trigger(`refetch-remittance-payment-details${remittanceId}`);
      Events.trigger('refetchBatchDetails');
      navigateBack();
    }
  }, [completeCheckResponse]);

  const formValidation = useCallback(async (shouldSetError = true, label, totalNoOfPagesInPdf) => {
    const formData = {
      ...form.getFieldValue(),
      selectedTypeName: getPaymentTypeDetails(
        form.getFieldInstance('paymentTypeId')?.props?.enumData,
        form.getFieldValue('paymentTypeId'),
      ),
    };
    const errors = [];
    if (!formData.paymentTypeId) {
      errors.push({
        name: 'paymentTypeId',
        value: formData.type,
        errors: ['Type is required'],
      });
    }
    if (formData.selectedTypeName?.toLowerCase() === 'hra' && !formData.payerId) {
      errors.push({
        name: 'payerId',
        value: formData.payerId,
        errors: ['Payer is required'],
      });
    }
    if (formData.selectedTypeName?.toLowerCase() === 'check') {
      if (!formData.checkNumber) {
        errors.push({
          name: 'checkNumber',
          value: formData.checkNumber,
          errors: ['Check Number is required'],
        });
      }
      if (!formData.checkDate) {
        errors.push({
          name: 'checkDate',
          value: formData.checkDate,
          errors: ['Check Date is required'],
        });
      }
    }
    if ((formData.selectedTypeName?.toLowerCase() === 'creditcard'
        || formData.selectedTypeName?.toLowerCase() === 'debitcard') && !formData.cardTypeId) {
      errors.push({
        name: 'cardTypeId',
        value: formData.cardTypeId,
        errors: ['Card Type is required'],
      });
    }
    if (!formData.checkAmount) {
      errors.push({
        name: 'checkAmount',
        value: formData.checkAmount,
        errors: ['Amount is required'],
      });
    }
    if (!formData.pageNo && (
      path === `${UiRoutes.claimsRemittanceNewPatient}/searchClaim/:PatientId`
      || path === `${UiRoutes.claimsRemittanceEditPatient}/searchClaim/:PatientId`
    ) && !isElectronicBatch) {
      errors.push({
        name: 'pageNo',
        value: formData.pageNo,
        errors: ['EOB Page Number is required'],
      });
    }
    if (totalNoOfPagesInPdf && formData.pageNo) {
      let checkClaimNumber = false;
      try {
        await isPageRange(formData.pageNo, labels.get('claimPageNumber'), totalNoOfPagesInPdf).validator();
        checkClaimNumber = true;
      } catch (e) {
        checkClaimNumber = false;
      }
      if ((path === `${UiRoutes.claimsRemittanceNewPatient}/searchClaim/:PatientId`
      || path === UiRoutes.claimsRemittanceNewPatient
      || path === UiRoutes.claimsRemittanceEditPatient
      || path === `${UiRoutes.claimsRemittanceEditPatient}/searchClaim/:PatientId`)
        && !isElectronicBatch && !checkClaimNumber
      ) {
        errors.push({
          name: 'pageNo',
          value: formData.pageNo,
          errors: ['Please enter a valid Claim Page Number '],
        });
      }
    }

    if (errors.length) {
      if (shouldSetError) {
        form.setFields(errors);
      }
      return false;
    }

    return true;
  }, [form, path, isElectronicBatch, labels]);

  const onPost = useCallback(async (lineItemData) => {
    const isValidated = await formValidation(true, labels, totalNoOfPages);
    if (isValidated) {
      let formData = form.getFieldsValue();
      formData = omit(formData, 'pageRange');
      lineItemData.map((item) => {
        // eslint-disable-next-line no-param-reassign
        item.pageNo = !isElectronicBatch ? form?.getFieldValue('pageNo') || currentPageNo : null;
        return item;
      });
      savePatientPaymentLineItem({
        data: {
          ...formData,
          checkDate: formData?.checkDate?.toISOString(),
          LineItems: lineItemData,
          RemittanceInsurancePayment: masterCodesWithId?.RemittanceInsurancePayment,
          remittancePaymentId,
          remittanceBatchId: remittanceId,
          RemittancePaymentTypeMasterCode: 'RemittanceInsurancePayment',
          paymentTypeCode: getPaymentTypeDetails(
            form.getFieldInstance('paymentTypeId')?.props?.enumData,
            form.getFieldValue('paymentTypeId'),
          ),
          pageNo: form.getFieldValue('pageNo'),
        },
      });
      setNeedToScroll(false);
    }
  }, [currentPageNo, form, formValidation, isElectronicBatch, labels, masterCodesWithId,
    remittanceId, remittancePaymentId, savePatientPaymentLineItem,
    setNeedToScroll, totalNoOfPages]);

  const autoSaveForm = useCallback(async () => {
    const isValidated = await formValidation(path === UiRoutes.claimsRemittanceEditPatient,
      labels, totalNoOfPages);
    const isFormValid = !form.getFieldsError().some((item) => item?.errors?.length > 0);
    if (isValidated && isFormValid) {
      let formData = form.getFieldValue();
      if (formData?.paymentTypeId !== 949) {
        formData = omit(formData, ['checkNumber', 'checkDate']);
      }
      formData.pageNo = `${form.getFieldValue('pageNo') || currentPageNo || 1}`;
      savePatientPayment({
        data: {
          ...formData,
          financePaymentId: secondPatientPaymentDetails?.financeId
          || patientPaymentDetails?.financeId,
          checkDate: formData?.checkDate ? moment(formData?.checkDate).format('YYYY-MM-DD HH:mm:ss') : null,
          remittancePaymentId,
          remittanceBatchId: remittanceId,
          paymentTypeCode: getPaymentTypeDetails(
            form.getFieldInstance('paymentTypeId')?.props?.enumData,
            form.getFieldValue('paymentTypeId'),
          ),
          PaymentSource: isElectronicBatch ? 'Remitted' : 'OverTheCounter',
          PaymentMethod: isElectronicBatch ? 'Electronic' : 'Manual',
          Source: 'PatientPayment',
          batchnumber: remittanceBatchData?.batchNumber,
          batchIdentifier: remittanceBatchData?.batchIdentifier,
        },
      });
    }
  }, [formValidation, path, labels, totalNoOfPages, form, currentPageNo, savePatientPayment,
    secondPatientPaymentDetails, patientPaymentDetails, remittancePaymentId, remittanceId,
    isElectronicBatch, remittanceBatchData]);

  const handleCompleteCheck = useCallback(() => {
    completeCheck({
      data: {
        paymentId: remittancePaymentId,
        source: 'PatientPayment',
        remittancebatchid: remittanceId,
        IsBatchAlreadyPosted: shouldCallAlreadyPaidAmount,
      },
    });
  }, [completeCheck, remittanceId, remittancePaymentId, shouldCallAlreadyPaidAmount]);

  const toggleWorklistModal = useCallback(async () => {
    if (isBatchPosted) { return; }
    if (!isWorklistModalVisible) {
      const isValidated = await formValidation(true, labels, totalNoOfPages);
      if (isValidated) {
        setIsWorklistModalVisible(!isWorklistModalVisible);
      }
    } else {
      setIsWorklistModalVisible(!isWorklistModalVisible);
    }
  },
  [isBatchPosted, isWorklistModalVisible, formValidation, labels, totalNoOfPages]);

  useEffect(() => {
    if (path === UiRoutes.claimsRemittanceEditPatient) {
      getPatientPaymentDetails({}, `/${remittancePaymentId}`);
      getViewEnteredItemsDetails({ PaymentId: remittancePaymentId });
      getAmountAfterPostingBatch({
        PaymentId: remittancePaymentId,
        remittanceBatchId: remittanceId,
      });
    }
  }, [remittancePaymentId]);

  useEffect(() => {
    Events.on(`refetch-view-entered-item-details${remittancePaymentId}`, `refetch-view-entered-item-details${remittancePaymentId}`, () => getViewEnteredItemsDetails({ PaymentId: remittancePaymentId }));
    return () => {
      Events.remove(`refetch-view-entered-item-details${remittancePaymentId}`, `refetch-view-entered-item-details${remittancePaymentId}`);
    };
  }, [getViewEnteredItemsDetails, remittancePaymentId]);

  useEffect(() => () => {
    clearSecondPatientPaymentDetails(true);
    clearPatientPaymentDetails(true);
    clearViewEnteredItemsDetails(true);
    clearGetAmountResponse(true);
  }, []);

  const handlePageRangeChangeScroll = useCallback((pageRangeToScroll) => {
    setClaimPageNo(pageRangeToScroll || 1);
    Events.trigger('PDF_VIEWER', { pageNo: parseInt(pageRangeToScroll || 1, 10) });
    Events.trigger('PDF_VIEWER_PAGE_NO', { pageNo: parseInt(pageRangeToScroll || 1, 10), shouldTriggerClaimPage: false });
  }, [setClaimPageNo]);

  useEffect(() => {
    if (secondPatientPaymentDetails?.financeDetails != null) {
      clearPatientPaymentDetails(true);
      const { financeDetails } = secondPatientPaymentDetails;
      const { viewEnteredItems } = viewEnteredItemsDetails || {};
      if (!isEmpty(pageRange) && viewEnteredItems) {
        const initialPageRange = pageRange === 'Pages Not Found' ? '' : pageRange;
        form.setFieldsValue({
          pageRange: initialPageRange,
        });
        if (needToScroll) {
          setTimeout(handlePageRangeChangeScroll(parseInt(financeDetails?.pageNo, 10)), 0);
        } else {
          setNeedToScroll(true);
        }
        if (isBatchPosted && pageRange !== financeDetails?.pageRange) {
          setPageRange(financeDetails?.pageRange || 1);
          form.setFieldsValue({
            pageRange: financeDetails?.pageRange || 1,
          });
        }
        if (!isBatchPosted) {
          setPageRange('');
          autoSaveForm();
        }
      } else if (isBatchPosted && pageRange !== financeDetails?.pageRange) {
        setPageRange(financeDetails?.pageRange || 1);
        form.setFieldsValue({
          pageRange: financeDetails?.pageRange || 1,
        });
      }
    }
  }, [secondPatientPaymentDetails, pageRange, viewEnteredItemsDetails]);

  useEffect(() => {
    if (patientPaymentDetails?.financeDetails != null) {
      const { financeDetails } = patientPaymentDetails;
      const { viewEnteredItems } = viewEnteredItemsDetails || {};
      const formData = {
        checkNumber: financeDetails?.checkNumber,
        checkAmount: (financeDetails?.checkAmount || 0).toFixed(2),
        checkDate: financeDetails?.checkDate && moment(financeDetails?.checkDate),
        payerId: financeDetails?.payerId,
        pageNo: currentPageNo,
        paymentTypeId: financeDetails?.paymentTypeId,
        cardTypeId: financeDetails?.cardTypeId,
        locationId: patientPaymentDetails?.locationId,
      };
      setClaimPageNo(currentPageNo);
      if (!isEmpty(pageRange) && viewEnteredItems) {
        const initialPageRange = pageRange === 'Pages Not Found' ? '' : pageRange;
        form.setFieldsValue({
          ...formData,
          pageRange: initialPageRange,
        });
        if (needToScroll) {
          setTimeout(handlePageRangeChangeScroll(parseInt(currentPageNo, 10)), 0);
        } else {
          setNeedToScroll(true);
        }
        if (isBatchPosted && pageRange !== financeDetails?.pageRange) {
          setPageRange(financeDetails?.pageRange || 1);
          form.setFieldsValue({
            pageRange: financeDetails?.pageRange || 1,
          });
        }
        if (!isBatchPosted) {
          setPageRange('');
        }
      } else {
        if (isBatchPosted && pageRange !== financeDetails?.pageRange) {
          setPageRange(financeDetails?.pageRange || 1);
          form.setFieldsValue({
            pageRange: financeDetails?.pageRange || 1,
          });
        }
        form.setFieldsValue(formData);
      }
    }
  }, [patientPaymentDetails, pageRange, viewEnteredItemsDetails]);

  useEffect(() => {
    if (viewEnteredItemsDetails) {
      const { viewEnteredItems } = viewEnteredItemsDetails;
      if (!isElectronicBatch && !isBatchPosted) {
        setPageRange(convertToRangeString(sortedUniq(getPageNumbers(viewEnteredItems))));
      }
      if (!viewEnteredItems?.length && !currentPath?.includes('searchClaim/:PatientId')) navigateToClaimTable();
    }
  }, [viewEnteredItemsDetails, isElectronicBatch, isBatchPosted]);

  const debouncedAutsave = useCallback(debounce(autoSaveForm, 1000),
    [remittancePaymentId, secondPatientPaymentDetails, totalNoOfPages, patientPaymentDetails]);

  useEffect(() => {
    Events.on('patient-claim-page-number', 'patient-claim-page-number', ({ pageNo: onPage, shouldSave = false }) => {
      if ((`${claimPageNo}`?.includes('-') || `${claimPageNo}`?.includes(',')) && !shouldSave) {
        return null;
      }
      form.setFieldsValue({ pageNo: onPage });
      debouncedAutsave();
      return null;
    });
    return () => {
      Events.remove('patient-claim-page-number', 'patient-claim-page-number');
    };
  }, [claimPageNo, form, remittancePaymentId, patientPaymentDetails,
    secondPatientPaymentDetails, totalNoOfPages]);

  useEffect(() => {
    if (patientPaymentLineItemResponse && !Array.isArray(patientPaymentLineItemResponse)) {
      Notification({
        message: successMessages.INSURANCE_PAYMENT_SAVED_SUCCESSFULLY,
        success: true,
      });
      form.setFieldsValue({
        pageNo: parseInt(currentPageNo, 10),
      });
      setClaimPageNo(parseInt(currentPageNo, 10) || 1);
      Events.trigger('PDF_VIEWER', { pageNo: parseInt(currentPageNo || 1, 10) });
      Events.trigger('PDF_VIEWER_PAGE_NO', { pageNo: parseInt(currentPageNo || 1, 10), shouldTriggerClaimPage: false });
      clearPatientPaymentLineItem();
      getSecondPatientPaymentDetails({}, `/${remittancePaymentId}`);
      Events.trigger('GET_AMOUNT_AFTER_POSTING_BATCH');
      replace(generatePath(
        UiRoutes.claimsRemittanceEditPatient,
        {
          ...params,
          remittancePaymentId: patientPaymentLineItemResponse?.remittancePaymentId,
          patientPaymentPage: patientPaymentPage ?? 'addPaymentDetails',
        },
      ));
    }
  }, [patientPaymentLineItemResponse, form, currentPageNo]);

  useEffect(() => {
    if (patientPaymentResponse && !Array.isArray(patientPaymentResponse)) {
      Notification({ message: successMessages.PAYMENT_SAVED_SUCCESSFULLY, success: true });
      clearPatientPayment();
      getSecondPatientPaymentDetails({}, `/${patientPaymentResponse?.financeId}`);
      Events.trigger(`refetch-remittance-payment-details${remittanceId}`);
      if (!params?.remittancePaymentId || params?.remittancePaymentId === '0') {
        replace(generatePath(
          currentPath,
          {
            ...params,
            remittancePaymentId: patientPaymentResponse?.financeId,
            patientPaymentPage: patientPaymentPage ?? 'addPaymentDetails',
          },
        ));
      }
    }
  }, [patientPaymentResponse]);

  const patientAmountDetails = useMemo(() => {
    const totalAmount = {
      payment: 0, totalPostedAmount: 0, accountAmount: 0,
    };
    const { totalPosted, viewEnteredItems } = viewEnteredItemsDetails || {};
    viewEnteredItems?.forEach((item) => {
      const isClaimItem = item?.applied && item?.patientId;
      if (isClaimItem) {
        totalAmount.payment += item?.applied || 0;
      } else {
        totalAmount.accountAmount += item?.balance || 0;
      }
    });
    totalAmount.totalPostedAmount = totalPosted || 0;
    return totalAmount;
  }, [viewEnteredItemsDetails]);

  return (
    <div>
      {(patientPaymentLineItemLoading
        || completeCheckLoading) && <Loader />}
      <div className="insurance-header">
        <div
          onClick={handleGoBack}
          className="back-arrow head-back"
          role="presentation"
        >
          {labels.get('patientPayment')}
        </div>
        <div className="head-text">
          <div className="col">
            <div className="key">
              {labels.get('check')}
              :
            </div>
            <div className="value">
              {' '}
              {secondPatientPaymentDetails?.financeDetails?.checkNumber
              || patientPaymentDetails?.financeDetails?.checkNumber}
            </div>
          </div>
          <div className="col">
            <div className="key">
              {labels.get('amount')}
              :
              {' '}
            </div>
            <div className="value">
              $
              {secondPatientPaymentDetails?.amount?.toFixed(2)
              || patientPaymentDetails?.amount?.toFixed(2) || 0?.toFixed(2)}
            </div>
          </div>
          <div className="col">
            <div className="key">
              {labels.get('posted')}
              :
              {' '}
            </div>
            <div className="value">
              $
              {parsedDecimalAmount(shouldCallAlreadyPaidAmount ? (
                (amountAfterPostingResponse?.applied || 0)
                      + (patientAmountDetails?.accountAmount || 0)
              )
                : patientAmountDetails?.totalPostedAmount)}
            </div>
          </div>
        </div>
      </div>
      <div className="insurance-pay-detail">
        <div className="insurance-field">
          <Form
            form={form}
            formId={`patient_payment-${formId}`}
            name={`patient_payment-${formId}`}
            listId={`patient_payment-${formId}`}
            getUrl="dummy"
            key={tabId}
            tabId={tabId}
            initialData={{
              pageNo: !isElectronicBatch ? pageNo || currentPageNo : null,
            }}
          >
            <EnumSelect
              label={labels.get('type')}
              labelSpan="10"
              inputSpan="14"
              name="paymentTypeId"
              required
              enumName={enums.PATIENT_PAYMENT_TYPE}
              excludeOptionNames={['PPA']}
              onChange={autoSaveForm}
              disabled={isBatchPosted}
            />
            <AntdForm.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => (
                prevValues.paymentTypeId !== currentValues.paymentTypeId)}
            >
              {({ getFieldValue, getFieldInstance }) => {
                const selectedPaymentType = getPaymentTypeDetails(getFieldInstance('paymentTypeId')?.props?.enumData, getFieldValue('paymentTypeId'));
                if (selectedPaymentType === 'hra') {
                  return (
                    <WiredSelect
                      id="payerId"
                      name="payerId"
                      url={apiUrls.CONTRACT_PAYER_DROPDOWN}
                      label={labels.get('payer')}
                      nameAccessor="payerName"
                      valueAccessor="payerId"
                      selectProps={{
                        showSearch: true,
                      }}
                      labelSpan="10"
                      inputSpan="14"
                      onChange={autoSaveForm}
                      required
                      disabled={isBatchPosted}
                    />
                  );
                }
                if (['creditcard', 'debitcard']?.includes(selectedPaymentType)) {
                  return (
                    <EnumSelect
                      label={labels.get('cardType')}
                      labelSpan="10"
                      inputSpan="14"
                      name="cardTypeId"
                      required
                      enumName={enums.PATIENT_PAYMENT_CARD_TYPE}
                      onChange={autoSaveForm}
                      disabled={isBatchPosted}
                    />
                  );
                }
                if (selectedPaymentType === 'check') {
                  return (
                    <>
                      <Input
                        label={labels.get('checkNumber')}
                        labelSpan="10"
                        inputSpan="14"
                        maxValueLength={50}
                        alphaNumericOnly
                        name="checkNumber"
                        onBlur={autoSaveForm}
                        placeholder="Enter Check Amount"
                        required
                        disabled={isBatchPosted}
                      />
                      <DatePicker
                        label={labels.get('checkDate')}
                        labelSpan="10"
                        inputSpan="14"
                        name="checkDate"
                        onChange={autoSaveForm}
                        required
                        disabled={isBatchPosted}
                      />
                    </>
                  );
                }
                return null;
              }}
            </AntdForm.Item>
            <Input
              label={labels.get('amount')}
              labelSpan="10"
              inputSpan="14"
              name="checkAmount"
              onBlur={autoSaveForm}
              numberWithDecimal
              maxValueLength={11}
              required
              placeholder="Enter Amount"
              disabled={isBatchPosted}
            />
            <Input
              label={labels.get('checkPageNumber')}
              labelSpan="10"
              inputSpan="14"
              name="pageRange"
              disabled
              placeholder="Enter Check Page Number"
            />
            <Input
              label={labels.get('claimPageNumber')}
              labelSpan="10"
              inputSpan="14"
              name="pageNo"
              onBlur={autoSaveForm}
              onChange={(e) => handlePageRangeChangeScroll(e.target.value)}
              rules={[
                ({ getFieldValue }) => isPageRange(getFieldValue('pageNo'), labels.get('claimPageNumber'), totalNoOfPages),
              ]}
              placeholder="Enter Claim Page Number"
              required={!isElectronicBatch}
            />
            <Route
              exact
              path={[
                `${UiRoutes.claimsRemittanceNewPatient}/searchClaim/:PatientId`,
                `${UiRoutes.claimsRemittanceEditPatient}/searchClaim/:PatientId`,
              ]}
            >
              <div style={{ display: 'none' }}>
                <Input label="" name="pageNo" />
              </div>
            </Route>
          </Form>
        </div>
        <div className="insurance-table">
          <div className="table-in app-table">
            <table>
              <tbody>
                <tr>
                  <td>
                    {labels.get('payment')}
                  </td>
                  <td>
                    {parsedDecimalAmount(
                      (shouldCallAlreadyPaidAmount
                        ? amountAfterPostingResponse?.applied
                        : patientAmountDetails?.payment) || 0,
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    {labels.get('account')}
                  </td>
                  <td>
                    {parsedDecimalAmount(patientAmountDetails?.accountAmount)}
                  </td>
                </tr>
                <tr>
                  <td>
                    {labels.get('net')}
                  </td>
                  <td>
                    {parsedDecimalAmount(shouldCallAlreadyPaidAmount ? (
                      (amountAfterPostingResponse?.applied || 0)
                      + (patientAmountDetails?.accountAmount || 0)
                    )
                      : (patientAmountDetails?.payment || 0)
                    + (patientAmountDetails?.accountAmount || 0))}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="action-text">
            <Route
              exact
              path={[
                UiRoutes.claimsRemittanceEditPatient,
                UiRoutes.claimsRemittanceNewPatient,
                `${UiRoutes.claimsRemittanceNewPatient}/searchClaim/:PatientId`,
                `${UiRoutes.claimsRemittanceEditPatient}/searchClaim/:PatientId`,
              ]}
            >
              <p
                onClick={navigateToClaimTable}
                role="presentation"
                className={`${isBatchPosted && 'cursor-not-allowed'}`}
              >
                {labels.get('addPayment')}

              </p>
            </Route>
            <Route
              exact
              path={[
                `${UiRoutes.claimsRemittanceEditPatient}/searchClaim`,
                UiRoutes.claimsRemittanceEditPatient,
                UiRoutes.claimsRemittanceNewPatient,
                `${UiRoutes.claimsRemittanceNewPatient}/searchClaim`,
                `${UiRoutes.claimsRemittanceEditPatient}/searchClaim/:PatientId`,
              ]}
            >
              <EventWrapper type="p" onClick={toggleWorklistModal} className={`${isBatchPosted && 'cursor-not-allowed'}`}>{labels.get('addToWorklist')}</EventWrapper>
            </Route>
          </div>
        </div>
      </div>
      <div className="search-table-wrap">
        <Route
          exact
          path={[
            UiRoutes.claimsRemittanceEditPatient,
            UiRoutes.claimsRemittanceNewPatient,
            `${UiRoutes.claimsRemittanceEditPatient}${UiRoutes.postClaimTablePopUp}`,
            `${UiRoutes.claimsRemittanceEditPatient}${UiRoutes.postClaimTablePopUpWithSearchText}`,
            `${UiRoutes.claimsRemittanceEditPatient}${UiRoutes.postClaimTablePopUpWithSearchTextWithErrorId}`,
            `${UiRoutes.claimsRemittanceEditPatient}${UiRoutes.postClaimTablePopUpWithSelectedClaim}`,
            `${UiRoutes.claimsRemittanceEditPatient}${UiRoutes.postClaimTablePopUpWithSelectedClaimWithErrorId}`,
            `${UiRoutes.claimsRemittanceNewPatient}${UiRoutes.postClaimTablePopUp}`,
            `${UiRoutes.claimsRemittanceNewPatient}${UiRoutes.postClaimTablePopUpWithSearchText}`,
            `${UiRoutes.claimsRemittanceNewPatient}${UiRoutes.postClaimTablePopUpWithSearchTextWithErrorId}`,
            `${UiRoutes.claimsRemittanceNewPatient}${UiRoutes.postClaimTablePopUpWithSelectedClaim}`,
            `${UiRoutes.claimsRemittanceNewPatient}${UiRoutes.postClaimTablePopUpWithSelectedClaimWithErrorId}`,
          ]}
        >
          <PostedClaimTable
            labels={labels}
            searchText={search}
            setCurrentPath={setCurrentPath}
            data={viewEnteredItemsDetails}
            loading={viewEnteredItemsDetailsloading}
            isPatientPaymentItems
            remittanceBatchData={remittanceBatchData}
            onPost={onPost}
            paymentForm={form}
            formValidation={formValidation}
            totalNoOfPages={totalNoOfPages}
            patientTransactionTypeId={secondPatientPaymentDetails?.transactionTypeId
              || patientPaymentDetails?.transactionTypeId}
            setCurrentPageNo={setCurrentPageNo}
            currentPageNo={currentPageNo}
            setNeedToScroll={setNeedToScroll}
            setClaimPageNo={setClaimPageNo}
          />
        </Route>
        <Route
          exact
          path={[
            `${UiRoutes.claimsRemittanceNewPatient}/searchClaim`,
          ]}
        >
          <div className="insurance-payment-search mr-bt-16">
            <Search placeholder="Search Patient" value={searchText} onChange={handleSearch} />
          </div>
          <PatientPaymentTable
            labels={labels}
            searchText={search}
            setCurrentPath={setCurrentPath}
          />
        </Route>
        <Route
          exact
          path={[
            `${UiRoutes.claimsRemittanceEditPatient}/searchClaim`,
          ]}
        >
          <div className="insurance-payment-search mr-bt-16">
            <Search placeholder="Search Patient" value={searchText} onChange={handleSearch} />
            <Button onClick={navigateToPostedClaims} className="btn btn-success sm-btn">View Entered Items</Button>
          </div>
          <PatientPaymentTable
            labels={labels}
            searchText={search}
            setCurrentPath={setCurrentPath}
          />
        </Route>
        <Route
          exact
          path={[
            `${UiRoutes.claimsRemittanceNewPatient}/searchClaim/:PatientId`,
            `${UiRoutes.claimsRemittanceEditPatient}/searchClaim/:PatientId`,
          ]}
        >
          <div className="flex justify-content-flex-end mr-bt-16">
            <Button onClick={navigateToPostedClaims} className="btn btn-success sm-btn">View Entered Items</Button>
          </div>
          <AntdForm form={form}>
            <LineItemsTable
              labels={labels}
              onCancel={navigateToClaimTable}
              onPost={onPost}
              setCurrentPath={setCurrentPath}
              paymentForm={form}
              navigateToPostedClaims={navigateToPostedClaims}
              formValidation={formValidation}
              totalNoOfPages={totalNoOfPages}
              transactionTypeId={secondPatientPaymentDetails?.transactionTypeId
                || patientPaymentDetails?.transactionTypeId}
              currentPageNo={form.getFieldValue('pageNo')}
              setCurrentPageNo={setCurrentPageNo}
              isElectronicBatch={isElectronicBatch}
              customPatientFormInstance={customPatientFormInstance}
              setClaimPageNo={setClaimPageNo}
              pdfPageNo={currentPageNo}
              setNeedToScroll={setNeedToScroll}
            />
          </AntdForm>
        </Route>
        <Route
          exact
          path={[
            UiRoutes.claimsRemittanceEditPatient,
            UiRoutes.claimsRemittanceNewPatient,
          ]}
        >
          <div className="flex mr-top-16">
            <button type="button" className="btn btn-success mr-rt-16" onClick={handleCompleteCheck}>{labels.get('completeCheck')}</button>
            <button type="button" className="btn btn-primary" onClick={navigateBack}>{labels.get('cancel')}</button>
          </div>
        </Route>
      </div>
      <WorkList
        labels={labels}
        path={path}
        isVisible={isWorklistModalVisible}
        toggle={toggleWorklistModal}
        formData={form?.getFieldsValue()}
        navigateToPostedClaims={navigateToPostedClaims}
        totalNoOfPages={totalNoOfPages}
        isElectronicBatch={isElectronicBatch}
        setNeedToScroll={setNeedToScroll}
      />
    </div>
  );
};

export default withLabel(PatientPayment, labelPaths.REMITTANCE_PATIENT_PAYMENT);
