import React from 'react';
import TimeCell from '../../../../../../../components/Cells/TimeCell';
import Icon from '../../../../../../../components/Icon';
import iconNames from '../../../../../../../lib/iconNames';

const providerColumns = ([
  {
    Header: 'Providers',
    accessor: 'providerName',
  },
  {
    Header: 'Effective From',
    accessor: 'effectiveFrom',
    Cell: ({ row: { original: { effectiveFrom } } }) => (
      <TimeCell value={effectiveFrom} format="MM-DD-YYYY hh:mm A" />
    ),
  },
  {
    Header: 'Effective To',
    accessor: 'effectiveTo',
    Cell: ({ row: { original: { effectiveTo } } }) => (
      <TimeCell value={effectiveTo} format="MM-DD-YYYY hh:mm A" />
    ),
  },
  {
    Header: 'Action',
    width: 220,
    Cell: ({ row: { original }, handleProviderDelete, onChangeHandle }) => (
      <div className="add-location-feild-wrap">
        <span className="delete-icon">
          <Icon
            name={iconNames.edit}
            onClick={onChangeHandle(true, original)}
            color="#32c788"
          />
        </span>
        <span className="delete-icon">
          <Icon
            name={iconNames.deleteIcon}
            onClick={() => handleProviderDelete(original)}
            color="#32c788"
          />
        </span>
      </div>
    ),
  },
]);
export default providerColumns;
