import React from 'react';

import PolicyHolder from './PolicyHolder';
import PPO from './PPO';

const PolicyForm = ({ ppo, ...props }) => (
  <div>
    <PPO {...props} />
    <PolicyHolder {...props} />
  </div>
);

export default PolicyForm;
