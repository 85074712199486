import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';

import { getEnumMaster, getEnumOptions } from '../store/selectors';
import { getEnumMasterData } from '../store/actions/enum';

const useMasterId = (masterCodes = [], enumNames = []) => {
  const enumMaster = useSelector((storeState) => getEnumMaster(storeState));
  const enumOptions = useSelector((storeState) => getEnumOptions(storeState));

  const dispatch = useDispatch();
  const masterIds = useMemo(() => {
    const masterCodesWithId = {};
    if (!isEmpty(enumMaster) && !isEmpty(enumOptions)) {
      const selectedEnums = pick(enumMaster, enumNames);
      const enums = !isEmpty(selectedEnums) ? selectedEnums : enumMaster;
      Object.values(enums)?.forEach((enumItem) => {
        if (!enumOptions[enumItem?.enumId]) {
          dispatch(getEnumMasterData(enumItem?.enumId));
        }
          enumOptions[enumItem?.enumId]?.data?.forEach((masterItem) => {
            if (masterCodes.find((code) => code?.toLowerCase()
                === masterItem?.masterCode?.toLowerCase())) {
              masterCodesWithId[masterItem?.masterCode] = masterItem?.masterId;
            }
          });
      });
    }
    return masterCodesWithId;
  }, [dispatch, enumMaster, enumNames, enumOptions, masterCodes]);

  return masterIds || {};
};

export default useMasterId;
