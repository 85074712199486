import React from 'react';

import Icon from '../../../../components/Icon';
import IconNames from '../../../../lib/iconNames';

const plan = [
  {
    Header: 'Name',
    accessor: (data) => data?.masterName || data?.name,
  },
  {
    Header: 'Delete',
    Cell: ({
      row: { original: { id } },
      deletePlan,
    }) => (
      <Icon
        name={IconNames.deleteIcon}
        onClick={() => deletePlan && deletePlan(id)}
      />
    ),
    fixWidth: '60',
  },
];

export default plan;
