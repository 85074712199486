import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form as AntForm } from 'antd';

import WithLabel from '../../../hoc/withLabel';
import { apiUrls } from '../../../api/constants';
import SuccessMessages from '../../../lib/successMessages';
import { labelPaths, formId } from '../../../lib/constants';
import formFieldValuesParser from '../../../lib/formFieldValuesParser';

import Form from '../../../components/Form';
import Modals from '../../../components/Modal';
import Button from '../../../components/Button';
import Notification from '../../../components/Notification';

import PersonForm from './PersonForm';

const parser = (values) => formFieldValuesParser(values, {
  date: [
    'dateOfBirth',
  ],
});

const PersonPopup = (props) => {
  const {
    visibility, setVisibility, labels, isUpdate, id, setPersonId, isPolicyHolder, isRequiredFields,
  } = props;
  const [form] = AntForm.useForm();

  const onAddRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.PERSON_SAVED_SUCCESSFULLY, success: true });
      if (setPersonId) setPersonId(response.personId);
      setVisibility(false);
    }
  }, [setPersonId, setVisibility]);

  const onUpdateRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.PERSON_UPDATED_SUCCESSFULLY, success: true });
      if (setPersonId) setPersonId(response.personId || id);
      setVisibility(false);
    }
  }, [id, setPersonId, setVisibility]);

  const [formProps, setFormProps] = useState({
    formId: formId.ADD_PERSON,
    url: apiUrls.ADD_PERSON,
    onRequestComplete: onAddRequestComplete,
    listId: formId.ADD_PERSON,
  });

  useEffect(() => {
    if (isUpdate && id) {
      setFormProps({
        formId: formId.UPDATE_CASE,
        url: apiUrls.ADD_PERSON,
        getUrl: `${apiUrls.GET_PERSON_BY_ID}?PersonId=${id}`,
        onRequestComplete: onUpdateRequestComplete,
        isUpdate: true,
        editKey: id,
        initialDataParser: parser,
        extraData: {
          personId: id,
        },
        listId: formId.EDIT_PERSON,
      });
    } else {
      setFormProps({
        formId: formId.ADD_PERSON,
        url: apiUrls.ADD_PERSON,
        onRequestComplete: onAddRequestComplete,
        listId: formId.ADD_PERSON,
        initialData: { isActive: true },
      });
    }
  }, [id, isUpdate]);

  return (
    <Modals
      width="900px"
      visible={visibility}
      toggleModal={() => setVisibility(false)}
      footer={[
        <div className="group-btns" key="footer">
          <Button className="btn min-wt-86" onClick={() => setVisibility(false)}>Cancel</Button>
          <Button className="btn btn-success min-wt-86 inline" onClick={() => form.submit()}>{isUpdate ? 'Update' : 'Save'}</Button>
        </div>,
      ]}
    >
      <>
        <div className="addition-header">
          <div className="lookup sprite-img-before">
            <p>{isUpdate ? labels.get('titles.person') : labels.get('titles.addNewPerson')}</p>
          </div>
        </div>
        <div className="">
          <div className="modal-content-area">
            <Form
              form={form}
              {...formProps}
            >
              <PersonForm
                labels={labels}
                form={form}
                isPolicyHolder={isPolicyHolder}
                isRequiredFields={isRequiredFields}
              />
            </Form>
          </div>
        </div>
      </>
    </Modals>
  );
};

PersonPopup.defaultProps = {
  visibility: false,
  setVisibility: () => { /* This is intentional */ },
  labels: {},
  isUpdate: false,
  id: '',
  setPersonId: () => { /* This is intentional */ },
};

PersonPopup.propTypes = {
  visibility: PropTypes.bool,
  setVisibility: PropTypes.func,
  labels: PropTypes.objectOf(PropTypes.object),
  isUpdate: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setPersonId: PropTypes.func,
};

export default WithLabel(PersonPopup, labelPaths.PERSON_MODAL);
