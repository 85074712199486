import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import AutoComplete from '../../components/Form/GridAutoComplete';
import { apiUrls } from '../../api/constants';

const columns = [
  {
    Header: 'Modifier CPT code',
    accessor: 'modifierCode',
    flex: 3,
    maxWidth: 150,
  },
  {
    Header: 'Description',
    accessor: 'description',
    flex: 3,
    maxWidth: 150,
  },
  {
    Header: 'Tier',
    accessor: 'tier',
    flex: 3,
    maxWidth: 150,
  },
];

const ModifierAutocomplete = ({
  label,
  form,
  disabled,
  required,
  initialValue = {},
  labelSpan = '10',
  inputSpan = '13',
  name,
  className,
  onSelectCode,
  minCharLength,
  params,
  styleWidth,
  selectProps,
  valueAccessor,
  ...otherProps
}) => {
  const onCPTCodeSelect = useCallback((item) => {
    form.setFields([{ name, value: item[valueAccessor] }]);
    if (onSelectCode) onSelectCode(item);
  }, [form, name, onSelectCode]);

  const modifierOptionParser = useCallback((option) => ({
    ...option,
    name: option.modifierCode,
    value: option.modifierId,
  }), []);

  return (
    <AutoComplete
      name={name}
      url={apiUrls.GET_MODIFIERS}
      optionParser={modifierOptionParser}
      optionMaster="ModifierText"
      labelSpan={labelSpan}
      inputSpan={inputSpan}
      disabled={disabled}
      required={required}
      initialValue={initialValue}
      columns={columns}
      style={styleWidth ? { width: '100%' } : {}}
      className={className}
      notFoundContent="Modifier is not present is the system."
      showArrow
      labelInValue
      selectProps={{ ...selectProps, optionLabelProp: 'name', allowClear: true }}
      params={params}
      onSelect={onCPTCodeSelect}
      minCharLength={minCharLength}
      {...otherProps}
    />
  );
};

ModifierAutocomplete.defaultProps = {
  label: '',
  form: {},
  disabled: false,
  required: false,
  initialValue: {},
  labelSpan: '10',
  inputSpan: '13',
  name: '',
  className: '',
  params: { PageSize: 50, PageIndex: 0 },
  minCharLength: 2,
  styleWidth: false,
  selectProps: {},
  valueAccessor: 'modifierCode',
};

ModifierAutocomplete.propTypes = {
  label: PropTypes.string,
  form: PropTypes.objectOf(PropTypes.object),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  initialValue: PropTypes.objectOf(PropTypes.object),
  labelSpan: PropTypes.string,
  inputSpan: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  params: PropTypes.objectOf(PropTypes.object),
  minCharLength: PropTypes.number,
  styleWidth: PropTypes.bool,
  selectProps: PropTypes.instanceOf(Object),
  valueAccessor: PropTypes.string,
};

export default ModifierAutocomplete;
