import React, {
  useCallback, useEffect, useState,
} from 'react';

import { apiUrls } from '../../../../../../../../../../api/constants';
import useRedirect from '../../../../../../../../../../hooks/useRedirect';
import useCRUD from '../../../../../../../../../../hooks/useCRUD';
import { I_FRAME_URL } from '../../../../../../../../../../lib/constants';

import AddMedicationModal from '../../../../../../../../Medications/AddMedicationModal';
import { getURLFromLocalStorage } from '../../../../../../../../../../lib/localStorageUtil';

const Rx = ({ handleChange }) => {
  const { params } = useRedirect();
  const { encounterId, patientId, providerId } = params;
  const [isVisible, setMedicationVisible] = useState(true);
  const [sessionToken, setSessionToken] = useState(null);

  const [
    Medication,,,
    getMedications,
    clearMedicationsResponse,
  ] = useCRUD({
    url: apiUrls.GET_MEDICATION_TOKEN,
    id: `${apiUrls.GET_MEDICATION_TOKEN}_${encounterId}`,
    type: 'read',
  });

  const [, , ,
    getMedicationsList,,
  ] = useCRUD({
    url: apiUrls.GET_MEDICATION_LIST,
    id: `${apiUrls.GET_MEDICATION_LIST}_${patientId}_${encounterId}-rx`,
    type: 'read',
  });

  useEffect(() => {
    if (Medication) {
      getMedicationsList({ PatientId: patientId });
      clearMedicationsResponse(true);
    }
  }, [patientId, getMedicationsList, Medication, clearMedicationsResponse]);

  const toggleMedicationModal = useCallback(() => {
    if (isVisible && sessionToken) {
      getMedications({
        SessionToken: sessionToken,
        PatientId: patientId,
        EncounterId: encounterId,
      });
    }
    setMedicationVisible(!isVisible);
  }, [encounterId, getMedications, isVisible, patientId, sessionToken]);

  useEffect(() => {
    if (!isVisible) {
      handleChange('procedureSets');
    }
  }, [handleChange, isVisible]);
  const setSession = useCallback(() => {
    const localStorageUrl = getURLFromLocalStorage(providerId, patientId, I_FRAME_URL);
    if (localStorageUrl) {
      let token = localStorageUrl.split('=');
      token = token && token[1].split('#');
      setSessionToken((token && token[0]) || null);
    }
  }, [patientId, providerId]);

  return (
    <div className="assesment-area">
      <AddMedicationModal
        toggleModal={toggleMedicationModal}
        isVisible={isVisible}
        setSession={setSession}
      />
    </div>
  );
};

export default Rx;
