import React from 'react';
import { Route, Switch } from 'react-router-dom';

import useRedirect from '../../../../hooks/useRedirect';
import AddResponsibleParty from './ResponsibleParty/Add';
import EditResponsibleParty from './ResponsibleParty/Edit';
import ViewResponsibleParty from './ResponsibleParty/View';
import InsuranceInformation from './ViewProfile';
import SideBar from './SideBar';
import EditPolicy from './EditPolicy';
import AddNewPolicy from './AddNewPolicy';
import AddNewProfile from './AddNewProfile';
import AddHistory from './AddHistory/AddHistory';
import { UiRoutes } from '../../../../lib/constants';
import useReduxStoreWithId from '../../../../hooks/useReduxStoreWithId';

export default function Insurance(props) {
  const {
    params, path,
  } = useRedirect();
  const showInActiveInsuranceData = !!useReduxStoreWithId({ id: `insurance-sidebar-showInActiveInsurance-${params.id}` })?.[0]?.get('data');

  return (
    <div className="insurance-wrapper-all">
      <SideBar
        baseRoute={UiRoutes.patientInsuranceBaseRoute}
        {...props}
        parentParams={params}
      />
      <div className="required-feilds">
        <Switch>
          <Route path={`${path}/profile/history/:profileId`}>
            <AddHistory
              {...props}
              parentParams={params}
              baseRoute={UiRoutes.patientInsuranceBaseRoute}
            />
          </Route>
          <Route path={`${path}/profile/view/:profileId`}>
            <InsuranceInformation
              {...props}
              parentParams={params}
              baseRoute={UiRoutes.patientInsuranceBaseRoute}
            />
          </Route>
          <Route exact path={`${path}/profile/new`}>
            <AddNewProfile
              {...props}
              parentParams={params}
              baseRoute={UiRoutes.patientInsuranceBaseRoute}
              showInActiveInsuranceData={showInActiveInsuranceData}
              formName="add-new-profile"
            />
          </Route>
          <Route path={`${path}/profile/edit/:profileId/:policyId`}>
            <EditPolicy
              {...props}
              parentParams={params}
              baseRoute={UiRoutes.patientInsuranceBaseRoute}
              showInActiveInsuranceData={showInActiveInsuranceData}
              formName="edit-policy-profile"
            />
          </Route>
          <Route path={`${path}/profile/new/:profileId/:policyId`}>
            <AddNewPolicy
              {...props}
              parentParams={params}
              baseRoute={UiRoutes.patientInsuranceBaseRoute}
              showInActiveInsuranceData={showInActiveInsuranceData}
            />
          </Route>
          <Route path={`${path}/rp/add`}>
            <AddResponsibleParty
              {...props}
              parentParams={params}
              self
              baseRoute={UiRoutes.patientInsuranceBaseRoute}
              showInActiveInsuranceData={showInActiveInsuranceData}
              formName="add-new-self-profile"
            />
          </Route>
          <Route path={`${path}/rp/edit/:rpId`}>
            <EditResponsibleParty
              {...props}
              parentParams={params}
              self
              baseRoute={UiRoutes.patientInsuranceBaseRoute}
              showInActiveInsuranceData={showInActiveInsuranceData}
              formName="edit-rp"
            />
          </Route>
          <Route path={`${path}/rp/view/:rpId`}>
            <ViewResponsibleParty
              {...props}
              parentParams={params}
              baseRoute={UiRoutes.patientInsuranceBaseRoute}
            />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
