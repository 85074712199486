import React from 'react';

import PropTypes from 'prop-types';

import { apiUrls } from '../../api/constants';

import Table from '../../components/Table';

import AutoComplete from '../../components/Form/AutoComplete/AntdAutocomplete';
import columns from './columns';

import './AllowedProcedureLookup.scss';

const optionParser = (option) => ({
  value: option.id,
  code: option.cptCode,
  desc: option.description,
  ...option,
});

const optionRenderer = (options = []) => {
  if (!options.length) return [];
  return [{
    label: (
      <div className="option-header-main-wrapper">
        <div className="option headerWrapper">
          <span className="header">
            Code
          </span>
          <span className="header">
            Address
          </span>
        </div>
      </div>
    ),
    options: options && options.map((item) => (
      {
        value: item.cptCode,
        item,
        label: (
          <div className="option">
            <span title={item.cptCode}>
              {item.cptCode}
            </span>
            <span title={item.description}>
              {item.description}
            </span>
          </div>
        ),
      }

    )),
  }];
};

const AllowedProcedureLookup = (props) => {
  const {
    title, data, onSelect, deleteDiagnosticId, url,
  } = props;

  return (
    <div className="custom-list-wrap shadow-wrap diagnostic-icd-component">
      <div className="title">
        <p>{title}</p>
        <div className="icd-lookup-select">
          <AutoComplete
            url={url}
            optionMaster="CPTCodeText"
            params={{ IncludeInActive: false }}
            optionParser={optionParser}
            onSelect={onSelect}
            placeholder="Search Procedure To Add"
            optionRenderer={optionRenderer}
            dropdownMatchSelectWidth={300}
            labelSpan="8"
            inputSpan="16"
            allowClearOnSelect
          />
        </div>
      </div>
      <Table
        columns={columns}
        data={data}
        noDataText=""
        deleteDiagnosticId={deleteDiagnosticId}
      />
    </div>
  );
};

AllowedProcedureLookup.defaultProps = {
  data: [],
  url: apiUrls.GET_SETTINGS_ICD,
  title: '',
  onSelect: () => { /* This is intentional */ },
  deleteDiagnosticId: null,
};

AllowedProcedureLookup.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  onSelect: PropTypes.func,
  url: PropTypes.string,
  deleteDiagnosticId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default AllowedProcedureLookup;
