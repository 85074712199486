import React, { useCallback, useRef } from 'react';
import { Route, Switch } from 'react-router-dom';

import WithLabel from '../../../../hoc/withLabel';
import useRedirect from '../../../../hooks/useRedirect';

import { labelPaths, UiRoutes } from '../../../../lib/constants';

import ListComponent from './List';
import AddAuthorization from './components/Authorization/Add';
import EditAuthorization from './components/Authorization/Edit';
import CaseForm from './components/Cases/CaseForm/Case';
import EditCase from './components/Cases/CaseForm/EditCase';
import SuccessMessages from '../../../../lib/successMessages';
import Notification from '../../../../components/Notification';

const CaseBackNavigationWrapper = (CaseComponent) => function WithCase(parentProps) {
  const {
    params, generatePath, push,
  } = useRedirect();
  const clearButtonRef = useRef();
  const navigateToCases = useCallback(() => {
    push(generatePath(UiRoutes.patientListWithListTabId, { ...params, tab: 'lists', listTab: 'cases' }));
    if (clearButtonRef && clearButtonRef.current) {
      clearButtonRef.current.click();
    }
  }, [push, generatePath, params]);

  const onAddRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.CASE_SAVED_SUCCESSFULLY, success: true });
      navigateToCases();
    }
  }, [navigateToCases]);

  const onUpdateRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.CASE_UPDATED_SUCCESSFULLY, success: true });
      navigateToCases();
    }
  }, [navigateToCases]);

  const { labels } = parentProps;
  return (
    <div className="pannel back-pannel mr-top-20">
      <div className="pannel-heading">
        <p role="presentation" id="patients_lists_authorization_goBack" className="back-arrow" onClick={navigateToCases}>{labels.get('buttons.goBack')}</p>
      </div>
      <div className="pannel-body">
        <CaseComponent
          {...parentProps}
          params={params}
          clearButtonRef={clearButtonRef}
          navigateToCases={navigateToCases}
          onAddRequestComplete={onAddRequestComplete}
          onUpdateRequestComplete={onUpdateRequestComplete}
        />
      </div>
    </div>
  );
};

const WrappedNewCaseForm = CaseBackNavigationWrapper(CaseForm);
const WrappedEditCaseForm = CaseBackNavigationWrapper(EditCase);

const Lists = ({ labels }) => {
  const {
    path,
  } = useRedirect();
  return (
    <Switch>
      <Route exact path={[path, UiRoutes.patientListWithListTabId]}>
        <ListComponent labels={labels} />
      </Route>
      <Route exact path={UiRoutes.editListCasesWithTabId}>
        <WrappedEditCaseForm labels={labels} />
      </Route>
      <Route exact path={UiRoutes.newListCasesWithTabId}>
        <WrappedNewCaseForm labels={labels} />
      </Route>
      <Route exact path={UiRoutes.editAuthorizationWithTabId}>
        <EditAuthorization />
      </Route>
      <Route exact path={UiRoutes.newAuthorizationWithTabId}>
        <AddAuthorization />
      </Route>
    </Switch>
  );
};

export default WithLabel(Lists, labelPaths.PATIENT_LISTS);
