import React, { useEffect } from 'react';

import Modals from '../../../../../components/Modal';
import useCRUD from '../../../../../hooks/useCRUD';
import { apiUrls } from '../../../../../api/constants';
import useRedirect from '../../../../../hooks/useRedirect';
import WidgetLoader from '../../../../../components/WidgetLoader';
import Table from '../../../../../components/Table';
import TimeCell from '../../../../../components/Cells/TimeCell';
import { dateFormatWith12Hour } from '../../../../../lib/constants';

const auditLogColumns = [
  {
    Header: 'Date',
    accessor: 'date',
    Cell: ({ row: { original } }) => (
      <TimeCell value={original?.createdOn} format={dateFormatWith12Hour} />
    ),
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Action Taken By',
    accessor: 'createdByName',
  },
];

const AuditLogModal = ({ labels, toggleModal, visible }) => {
  const { params: { financialId } } = useRedirect();
  const [auditResponse, , loading, getAuditLog] = useCRUD({ id: 'charge-audit-log', url: apiUrls.GET_AUDIT_LOG, type: 'read' });

  useEffect(() => {
    if (financialId && visible) {
      getAuditLog({ FinanceId: financialId });
    }
  }, [financialId, getAuditLog, visible]);

  return (
    <Modals
      visible={visible}
      width={1000}
      toggleModal={toggleModal}
      destroyOnClose
    >
      <div className="addition-header">
        <div className="lookup sprite-img-before">
          <p>{labels.get('titles.auditLog')}</p>
        </div>
      </div>
      <div className="tree-view-wrap posi-relative table-max-height-800">
        {loading && <WidgetLoader />}
        <Table
          columns={auditLogColumns}
          data={auditResponse || []}
        />
      </div>
    </Modals>
  );
};

export default AuditLogModal;
