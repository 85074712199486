import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';
import isFunction from 'lodash/isFunction';

import Table from '../../../components/Table';
import policyColumns from './columns/policy';
import { enums as enumConstant } from '../../../lib/constants';
import { Select } from '../../Enum';

function PolicyLookup({
  label,
  required,
  labelSpan,
  inputSpan,
  form,
  initialData, setParentData,
  isSetToInitial,
  enumName,
}) {
  const [data, setData] = useState(initialData || []);
  const [initialTableData, setInitialTableData] = useState(null);

  useEffect(() => {
    if (isSetToInitial) {
      setData(initialTableData || []);
      if (isFunction(setParentData)) setParentData(initialTableData || []);
    }
  }, [isSetToInitial, initialTableData, setParentData]);

  useEffect(() => {
    if (initialData?.length) {
      setData(initialData);
      if (!initialTableData) setInitialTableData(initialData);
    }
  }, [initialData, initialTableData]);

  const onPolicySelect = useCallback((value, allItems) => {
    const { item } = allItems;
    const index = findIndex(data,
      (policy) => item.value === policy.value || item.value === policy.masterId);
    if (index < 0) {
      setData([...data, item]);
      setParentData([...data, item]);
    }
    form.customSetFieldsValue({ PolicyName: null });
  }, [data, form, setParentData]);

  const deletePolicy = useCallback((id) => {
    const index = findIndex(data, (item) => item.id === id);
    const cloned = [...data];
    cloned.splice(index, 1);
    setData(cloned);
    setParentData(cloned);
  }, [data, setParentData]);

  return (
    <div>
      <Select
        label={label}
        labelSpan={labelSpan}
        inputSpan={inputSpan}
        name="PolicyName"
        required={data.length ? null : required}
        enumName={enumName}
        onChange={onPolicySelect}
      />
      {data.length > 0
        ? <Table columns={policyColumns} data={data} deletePolicy={deletePolicy} />
        : null}

    </div>
  );
}

PolicyLookup.defaultProps = {
  label: '',
  placeholder: '',
  disabled: false,
  required: false,
  name: '',
  labelSpan: '10',
  inputSpan: '13',
  isSetToInitial: false,
  enumName: enumConstant.POLICY_TYPE,
};

PolicyLookup.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  name: PropTypes.string,
  labelSpan: PropTypes.string,
  inputSpan: PropTypes.string,
  isSetToInitial: PropTypes.bool,
  enumName: PropTypes.string,
};

export default PolicyLookup;
