/* eslint-disable max-len */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useDrop } from 'react-dnd';
import { Collapse, Tooltip } from 'antd';

import useCRUD from '../../../../../../hooks/useCRUD';
import useRights from '../../../../../../hooks/useRights';
import * as selectors from '../../../../../../store/selectors';

import rights from '../../../../../../lib/rights';
import iconNames from '../../../../../../lib/iconNames';
import { getUTCDate } from '../../../../../../lib/util';
import { dateFormatWith12Hour } from '../../../../../../lib/constants';
import { apiUrls } from '../../../../../../api/constants';

import Icon from '../../../../../../components/Icon';
import WidgetLoader from '../../../../../../components/WidgetLoader';
import RenderMultipleSynonyms from '../../../../../../components/RenderMultipleSynonyms';
import { procedureDiagnosisMapping } from '../../../../commonParser';

import '../../../../Components/PatientDetails/Components/Tabs/CustomDropppableTab/customDroppableTab.scss';

const { Panel } = Collapse;
const JsonTree = ({
  labelList, type, deleteProcedureSet, deleteOrderSet,
  activeIds, showHideCount, onClick, onApplyButtonClick, libraryList,
  isLibrary, copyProviderAllProcedureSets, currentTab, showAllExpanded,
}) => {
  const [isDeleteFavouriteSetAuthenticated] = useRights([rights.delete_favourites]);

  const [panelsList, setPanelsList] = useState([]);
  const [procedureDiagnosisMappingData, setProcedureDiagnosisMappingData] = useState({});

  const [procedureSetsResponse, , procedureSetsLoading, getProcedureSetById] = useCRUD({
    id: 'get-doctor-screen-overview-library-sets',
    url: apiUrls.GET_PROCEDURE_SET_BY_ID,
    type: 'read',
  });
  useEffect(() => {
    if (procedureSetsResponse) {
      setProcedureDiagnosisMappingData({
        ...procedureDiagnosisMappingData,
        [procedureSetsResponse.favouriteId]: procedureDiagnosisMapping(procedureSetsResponse),
      });
    }
  }, [procedureSetsResponse]);

  const handleProcedureSetById = useCallback((favouriteId) => {
    if (!procedureDiagnosisMappingData[favouriteId]) {
      getProcedureSetById({ id: favouriteId });
    }
  }, [getProcedureSetById, procedureDiagnosisMappingData]);

  const currentProviderData = useMemo(() => {
    const user = localStorage.getDecryptedData('user');
    const { providerDetail = {} } = JSON.parse(user);
    return {
      name: providerDetail?.providerName,
      id: providerDetail?.providerId,
      specialityId: providerDetail?.specialtyId,
      specialityName: providerDetail?.specialtyName,
    };
  }, []);

  const [, drop] = useDrop({
    accept: 'CARD',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });
  useEffect(() => {
    if (labelList) {
      setPanelsList([]);
    }
  }, [labelList]);

  useEffect(() => {
    if (showAllExpanded) {
      if (!isLibrary) {
        const activeIdsOfPanels = [];
        labelList.forEach((e) => {
          if (e?.tagId) activeIdsOfPanels.push(e.tagId);
          if (e?.subType?.length) {
            e?.subType?.forEach((item) => {
              if (item?.key) activeIdsOfPanels.push(item?.key);
            });
          }
        });
        setPanelsList(activeIdsOfPanels);
      } else {
        setPanelsList(labelList.map((e) => e?.tagId || e?.orderSetId));
      }
    } else {
      setPanelsList([]);
    }
  }, [labelList, showAllExpanded, isLibrary]);

  useEffect(() => {
    if (showHideCount) {
      if (activeIds) {
        setPanelsList(labelList.map((e) => e?.tagId || e?.orderSetId));
      } else {
        setPanelsList([]);
      }
    }
  }, [showHideCount, activeIds, labelList]);

  const openClosePanel = useCallback((tagId) => {
    if (panelsList.includes(tagId)) {
      setPanelsList([...panelsList.filter((e) => e !== tagId)]);
    } else {
      setPanelsList([...panelsList, tagId]);
    }
  }, [panelsList]);

  const openCloseNestedPanel = useCallback((tagId, key) => {
    if (key) {
      if (panelsList.includes(key)) {
        setPanelsList([...panelsList.filter((e) => e !== key)]);
      } else {
        setPanelsList([...panelsList, key]);
        handleProcedureSetById(tagId);
      }
    } else if (panelsList.includes(tagId)) {
      setPanelsList([...panelsList.filter((e) => e !== tagId)]);
    } else {
      setPanelsList([...panelsList, tagId]);
      handleProcedureSetById(tagId);
    }
  }, [handleProcedureSetById, panelsList]);

  const handleDragStart = useCallback((event) => {
    event.dataTransfer.setData('text', event?.target?.id);
    event.dataTransfer.setData('type', 'acuity-eye-tree-leaf');
    event.target.classList.add('dragging');
    event.dataTransfer.setData('draggedItem', type);
    event.dataTransfer.setData('draggedData', '{}');
  }, [type]);

  const handleProcedureDragStart = useCallback((event, subTypeElement) => {
    event.dataTransfer.setData('text', event?.target?.id);
    event.dataTransfer.setData('type', 'acuity-eye-tree-leaf');
    event.dataTransfer.setData('draggedItem', 'procedure');
    event.target.classList.add('dragging');
    event.dataTransfer.setData('draggedData', JSON.stringify(subTypeElement));
  }, []);

  const handleDiagnosisProcedureDrag = useCallback((event, diagnosisProcedureSet) => {
    event.dataTransfer.setData('text', event?.target?.id);
    event.dataTransfer.setData('type', 'acuity-eye-tree-leaf');
    event.dataTransfer.setData('draggedItem', 'diagnosis-procedure-set');
    event.target.classList.add('dragging');
    event.dataTransfer.setData('draggedData', JSON.stringify(diagnosisProcedureSet));
  }, []);

  const handleDragEnd = useCallback((event) => {
    event.target.classList.remove('dragging');
  }, []);

  const genSetHeader = useCallback((data) => {
    if (data?.copiedId) {
      return (
        <Tooltip title={`${data?.fromProviderName || ''}  ${getUTCDate(data?.createdOn, dateFormatWith12Hour)}`}>
          {data?.favouriteName}
        </Tooltip>
      );
    }
    return <span>{data?.favouriteName}</span>;
  }, []);

  const genExtra = useCallback((id) => (
    <div className="flex mr-top-4">
      {(isDeleteFavouriteSetAuthenticated || currentTab === 'provider') && (
        <Icon
          className="mr-rt-12"
          name={iconNames.closeCircleOutlined}
          onClick={(event) => (type === 'procedureSets' ? deleteProcedureSet(id, event) : deleteOrderSet(id, event))}
        />
      )}
      <Icon
        name={iconNames.deliveredProcedureOutlined}
        onClick={(event) => {
          onApplyButtonClick(event, id);
        }}
      />
    </div>
  ), [currentTab, deleteOrderSet, deleteProcedureSet, isDeleteFavouriteSetAuthenticated, onApplyButtonClick, type]);

  const Compo = ({
    data, subtype, groupId, showChild, favoriteId, diagnosisList, associatedJcodes,
  }) => {
    if (data?.diagnosisList?.length) {
      return (
        <div
          id={data?.groupId}
          draggable
          onDragStart={(e) => {
            e.stopPropagation();
            handleDiagnosisProcedureDrag(e, { ...data, favoriteId });
          }}
          onDragEnd={(e) => handleDragEnd(e)}
        >
          <div className="hhh" id={`${data?.groupId}_parent`}>
            {data.diagnosisList.map((element) => (
              <div key={`${data?.groupId}_${element.id}`} id={`${data?.groupId}_${element.id}_H`}>
                <div
                  id={`${data?.groupId}_${element.id}`}
                  aria-hidden="true"
                  ref={drop}
                  className="positive-relative"
                >
                  <p className="separate-code m-r-0" id={`${data?.groupId}_${element.id}_parentlabel`} aria-hidden="true">
                    <span className="label-text-width">{element?.synonyms ? <RenderMultipleSynonyms synonyms={element?.synonyms} /> : element?.label}</span>
                    {' '}
                    <span className="code-only separate-code-bg">{element?.icdCode}</span>
                  </p>
                </div>
                <span className="square-line" />
              </div>
            ))}

            <Compo
              subtype={data?.subType}
              diagnosisList={data?.diagnosisList}
              showChild={showChild}
              groupId={data?.groupId}
            />
          </div>
        </div>
      );
    }
    if (subtype?.length) {
      return (
        <div className="sub-l-child-group" style={{ marginLeft: '22px' }}>
          <span className="hide-line" />
          {subtype.map((subtypeElement) => !subtypeElement.associatedProcedureId && (
            <div key={subtypeElement.id} id={`${groupId}_${subtypeElement.id}_showChild`}>
              <div
                onDragStart={(e) => {
                  e.stopPropagation();
                  handleProcedureDragStart(e, subtypeElement);
                }}
                onDragEnd={(e) => handleDragEnd(e)}
                id={`${groupId}_${subtypeElement.id}`}
                draggable
                aria-hidden="true"
                className="positive-relative"
                style={{ cursor: 'pointer' }}
                onClick={onClick(subtypeElement, diagnosisList)}
              >
                <p id={`${groupId}_${subtypeElement.id}_para`} className="separate-code m-r-0">
                  <div className="separate-code">
                    <span className="label-text-width">{subtypeElement?.synonyms ? <RenderMultipleSynonyms synonyms={subtypeElement?.synonyms} /> : subtypeElement?.label}</span>
                    {subtypeElement.source === 'ImagingLab' ? '' : (
                      <span className="code-only separate-code-bg">
                        {subtypeElement?.cptCode}
                      </span>
                    )}
                  </div>
                </p>
              </div>
              <Compo
                associatedJcodes={subtypeElement?.associatedJcodes}
                groupId={groupId}
              />
            </div>
          ))}
        </div>
      );
    }
    if (associatedJcodes?.length) {
      return (
        <div className="sub-l-child-group j-code-sub-child" style={{ marginLeft: '28px' }}>
          <span className="hide-line" />
          {associatedJcodes?.map((subtypeElement) => (
            <div key={subtypeElement.id} id={`${groupId}_${subtypeElement.id}_showChild`}>
              <div
                onDragStart={(e) => {
                  e.stopPropagation();
                  handleProcedureDragStart(e, subtypeElement);
                }}
                onDragEnd={(e) => handleDragEnd(e)}
                id={`${groupId}_${subtypeElement.id}`}
                draggable
                aria-hidden="true"
                className="positive-relative"
              >
                <p id={`${groupId}_${subtypeElement.id || subtypeElement?.cptCode}_para`}>
                  {subtypeElement?.label}
                  {subtypeElement.source === 'ImagingLab' ? '' : ` - ${subtypeElement?.cptCode}`}
                  {subtypeElement.Prompt4 && `  ${subtypeElement?.Prompt4}`}
                  <p
                    key={`${subtypeElement?.cptCode}_jcodes`}
                    id={`${groupId}_${subtypeElement.id || subtypeElement?.cptCode}_modifiers`}
                    role="presentation"
                  >
                    {subtypeElement?.modifiers?.map(
                    ({ modifierCode, modifierId }, iter) => (
                      <button
                        // eslint-disable-next-line no-nested-ternary
                        className={`code-btn ${(subtypeElement?.modifiers?.[0]?.modifierId === modifierId) ? 'active' : !subtypeElement?.modifiers?.length ? '' : 'active-inactive'}`}
                        style={{
                          marginRight: '2px',
                        }}
                        id={`${subtypeElement?.cptCode}-btn-${iter + 1}`}
                        type="button"
                        value={modifierId}
                      >
                        {modifierCode}
                      </button>
                    ))}
                  </p>
                </p>
              </div>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  if (isLibrary) {
    return (
      <div className="ap-accordion-tree">
        {(libraryList || [])?.length > 0 && (
          libraryList.map((data) => (
            <div
              className="right-tree"
              key={data?.id}
            >
              <Collapse>
                <Panel
                  header={data?.specialityName}
                  key={data?.specialityId}
                >
                  <div className="line-tree-view" style={{ paddingLeft: '1.6rem' }}>
                    {data?.providers?.map((provider) => (
                      <Collapse>
                        <Panel
                          header={provider?.name}
                          key={provider?.id}
                          extra={(
                            <Icon
                              className="mr-rt-12"
                              name={iconNames.copyOutlined}
                              onClick={(event) => copyProviderAllProcedureSets({
                                fromProvider: provider?.id,
                                fromProviderName: provider?.name,
                                toProvider: currentProviderData?.id,
                                toProviderName: currentProviderData?.name,
                                source: provider?.source,
                                specialityId: currentProviderData?.specialityId,
                                specialityName: currentProviderData?.specialityName,
                              }, event)}
                            />
                          )}
                        >
                          <div style={{ paddingLeft: '20px' }}>
                            {(provider?.sets?.map((tagDetails) => (
                              tagDetails?.tags?.map((tag) => (
                                <div key={tag?.tagId} className=" right-tree">
                                  <Collapse>
                                    <Panel
                                      header={tag?.tagName}
                                      key={tag?.tagId}
                                      extra={(
                                        <Icon
                                          className="mr-rt-12"
                                          name={iconNames.copyOutlined}
                                          onClick={(event) => copyProviderAllProcedureSets({
                                            fromTag: tag?.tagName,
                                            fromProvider: provider?.id,
                                            fromProviderName: provider?.name,
                                            toProvider: currentProviderData?.id,
                                            toProviderName: currentProviderData?.name,
                                            source: provider?.source,
                                            specialityId: currentProviderData?.specialityId,
                                            specialityName: currentProviderData?.specialityName,
                                          }, event)}
                                        />
                                      )}
                                    >
                                      <div className="right-tree-view procedure-right ap-tree inner-sub-child-icon">
                                        {(tag?.favourites || [])?.map((fav) => (
                                          <div
                                            key={fav.favouriteId}
                                            draggable
                                            id={fav.favouriteId}
                                            onDragStart={(e) => handleDragStart(e)}
                                            onDragEnd={(e) => handleDragEnd(e)}
                                          >
                                            <Collapse
                                              onChange={() => handleProcedureSetById(fav?.favouriteId)}
                                            >
                                              <Panel
                                                header={fav?.favouriteName}
                                                key={fav?.favouriteId}
                                                extra={(
                                                  <Icon
                                                    className="mr-rt-12"
                                                    name={iconNames.copyOutlined}
                                                    onClick={(e) => copyProviderAllProcedureSets({
                                                      favouriteId: fav?.favoriteId,
                                                      toProvider: currentProviderData?.id,
                                                      toProviderName: currentProviderData?.name,
                                                      source: provider?.source,
                                                      fromProvider: provider?.id,
                                                      fromProviderName: provider?.name,
                                                      specialityId: currentProviderData?.specialityId,
                                                      specialityName: currentProviderData?.specialityName,
                                                    }, e)}
                                                  />
                                                )}
                                              >
                                                {procedureSetsLoading && <WidgetLoader size="small" />}
                                                {procedureDiagnosisMappingData?.[fav.favouriteId]?.result?.length
                                                  ? procedureDiagnosisMappingData?.[fav.favouriteId]?.result?.map((subType, subTypeIndex) => (
                                                    <Compo
                                                      data={subType}
                                                      key={subTypeIndex + 1}
                                                      showChild
                                                      favoriteId={fav?.favoriteId}
                                                    />
                                                  )) : <span>No Data Found</span>}
                                              </Panel>
                                            </Collapse>
                                          </div>
                                        ))}
                                      </div>
                                    </Panel>
                                  </Collapse>
                                </div>
                              )))))}
                          </div>
                        </Panel>
                      </Collapse>
                    ))}
                  </div>
                </Panel>
              </Collapse>
            </div>
          ))
        )}
      </div>
    );
  }
  return (
    <div className="sub-menu-collapse">
      {(labelList || [])?.length > 0 && (
        labelList.map((data) => (
          <div key={data?.tagId} className=" right-tree">
            <Collapse
              accordion
              activeKey={panelsList.includes(data?.key) ? data?.key : `${data?.key}_i`}
              onChange={() => openClosePanel(data?.key, data?.tagId)}
            >
              <Panel
                header={data?.tagName}
                key={data?.key}
              >
                <div className="right-tree-view procedure-right">
                  {data?.favourites?.length !== 0 && <i className="arrow down" />}
                  {data?.favourites?.map((fav) => (
                    <div
                      key={fav.favouriteId}
                      draggable
                      id={fav.favouriteId}
                      onDragStart={(e) => handleDragStart(e)}
                      onDragEnd={(e) => handleDragEnd(e)}
                      className="positive-relative"
                    >
                      <Collapse
                        accordion
                        activeKey={panelsList.includes(fav.favouriteId) && fav.favouriteId}
                        onChange={() => openCloseNestedPanel(fav.favouriteId)}
                      >
                        <Panel
                          header={genSetHeader(fav)}
                          key={fav.favouriteId}
                          extra={genExtra(fav?.favouriteId)}
                        >
                          <div key={fav.favouriteId}>
                            {procedureSetsLoading && <WidgetLoader size="small" />}
                            {procedureDiagnosisMappingData?.[fav.favouriteId]?.result?.length
                              ? procedureDiagnosisMappingData?.[fav.favouriteId]?.result?.map(
                                  (subType, subTypeIndex) => (
                                    <Compo
                                      data={subType}
                                      key={subTypeIndex + 1}
                                      showChild
                                      favoriteId={fav?.favoriteId}
                                    />
                                  )) : <span>No Data Found</span>}
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  ))}
                </div>
              </Panel>
            </Collapse>
          </div>
        ))
      )}
    </div>
  );
};

JsonTree.defaultProps = {
  list: [],
  search: '',
  url: '',
  setLabelsList: () => { /* This is intentional */ },
  draggable: true,
  showCloseIcon: true,
  onAddItems: () => { /* This is intentional */ },
  handleProcedureClick: () => { /* This is intentional */ },
  showSelection: false,
};

JsonTree.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  search: PropTypes.string,
  url: PropTypes.string,
  setLabelsList: PropTypes.func,
  draggable: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
  onAddItems: PropTypes.func,
  handleProcedureClick: PropTypes.func,
  showSelection: PropTypes.bool,
};

export default connect((states) => ({
  enumOptions: selectors.getEnumOptions(states),
  enumMaster: selectors.getEnumMaster(states),
}))(JsonTree);
