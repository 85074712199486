import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import rights from '../../../lib/rights';
import { labelPaths, UiRoutes } from '../../../lib/constants';

import useRedirect from '../../../hooks/useRedirect';
import WithLabel from '../../../hoc/withLabel';
import WithRights from '../../../hoc/withRights';

import TabsComponent from '../../../components/Tabs';

import IcdSynonym from './IcdSynonym';
import CptSynonym from './CptSynonym';
import CPT from '../CPT';
import ICD from '../ICD';
import CPTICDCrosswalk from '../CPTICDCrosswalk';

import './synonyms.scss';

const tabsList = [
  {
    id: 'cptSynonym',
    name: 'CPT Synonym',
    component: CptSynonym,
  },
  {
    id: 'icdSynonym',
    name: 'ICD Synonym',
    component: IcdSynonym,
  },
  {
    id: 'cpt',
    name: 'CPT',
    component: CPT,
  },
  {
    id: 'icd',
    name: 'ICD',
    component: ICD,
  },
  {
    id: 'cptIcdCrossWalk',
    name: 'CPT ICD Crosswalk',
    component: CPTICDCrosswalk,
  },
];

const Synonyms = ({ labels }) => {
  const { generatePath } = useRedirect();
  return (
    <Switch>
      <Route path={UiRoutes.SynonymsWithTab}>
        <div className="synonyms-container">
          <div className="heading-area flex justify-content-sp-bt align-center screen-title synonyms-screen">
            <h2>Synonyms</h2>
            {/* <Button className="btn-success sm-btn">New</Button> */}
          </div>
          <div className="white-tabs">
            <TabsComponent
              tabsList={tabsList}
              id="inventory"
              className="main-tab-wrap"
              labels={labels}
              route={UiRoutes.SynonymsWithTab}
            />
          </div>
        </div>
      </Route>
      <Route exact path={UiRoutes.Synonyms}>
        <Redirect from={UiRoutes.Synonyms} to={generatePath(UiRoutes.SynonymsWithTab, { tab: 'cptSynonym' })} />
      </Route>
    </Switch>
  );
};

export default WithRights(WithLabel(Synonyms, labelPaths.SETTINGS_SYNONYMS),
  rights.access_to_cpt_icd_synonym);
