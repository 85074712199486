/* eslint-disable camelcase */
import React, {
  useState, useCallback, useEffect,
} from 'react';
import {
  Upload, Form as AntdForm,
} from 'antd';
import sortBy from 'lodash/sortBy';
import isNaN from 'lodash/isNaN';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import remove from 'lodash/remove';
import uniq from 'lodash/uniq';
import classNames from 'classnames';

import useCRUD from '../../../hooks/useCRUD';
import useRedirect from '../../../hooks/useRedirect';

import Events from '../../../lib/events';
import iconNames from '../../../lib/iconNames';
import { contentType, responseType } from '../../../lib/constants';
import SuccessMessages from '../../../lib/successMessages';
import ErrorMessages from '../../../lib/errorMessages';
import { apiUrls } from '../../../api/constants';

import Form from '../../../components/Form';
import Icon from '../../../components/Icon';
import Radio from '../../../components/Radio';
import Modals from '../../../components/Modal';
import Button from '../../../components/Button';
import Input from '../../../components/Form/Input';
import SMCheckBox from '../../../components/SmCheckbox';
import WidgetLoader from '../../../components/WidgetLoader';
import Notification from '../../../components/Notification';
import ConfirmDialog from '../../../components/ConfirmDialog';
import SelectBox from '../../../components/SelectBox';

import WiredSelect from '../../Select';
import ReferringPhysicianCareTeam from '../../ReferringPhysicianCareTeam';
import AddressBookAutoComplete from '../../AddressBookAutoComplete';
import PayerGridAutoComplete from '../../PayerGridAutoComplete';
import CoverPageWrap from './coverPage/index';
import PdfPreview from '../../UploadFilePreview/PDFPreview';
import { fileSizeCheck } from '../../../lib/util';
import PhoneNumberInput from '../../../components/Form/PhoneNumberInput';

const searchTypeSourceOptions = [
  { label: 'Physicians', value: 'physicians' },
  { label: 'Payers', value: 'payers' },
  { label: 'Manually', value: 'manually' },
  { label: 'Commonly Used', value: 'commonlyUsed' },
];

const PayerSearch = ({
  setFaxNumber, setRecepientName, setRecepientPhone, clearFaxData,
}) => {
  const handleOnSelect = useCallback((data) => {
    setFaxNumber(data?.faxNumber);
    setRecepientName(data?.name);
    setRecepientPhone(data?.phone);
  }, [setFaxNumber, setRecepientName, setRecepientPhone]);

  const handleOnClear = useCallback(() => {
    clearFaxData();
  }, [clearFaxData]);

  return (
    <PayerGridAutoComplete
      name="payerName"
      nameAccessor="payerName"
      valueAccessor="name"
      labelSpan="0"
      allowClear
      inputSpan="24"
      dropdownMatchSelectWidth={725}
      onSelect={handleOnSelect}
      selectProps={{
        onClear: handleOnClear,
      }}
      params={{
        sortBy: 'name',
        sortorder: 'asc',
      }}
    />
  );
};
const PhysicianSearch = ({
  form, setFaxNumber, setRecepientName, setRecepientPhone, clearFaxData,
}) => {
  const [searchField, setSearchField] = useState(true);

  const toggleSearchFields = useCallback(() => {
    setFaxNumber(null);
    setRecepientName();
    setRecepientPhone();
    setSearchField(!searchField);

    const allFields = form.getFieldsValue();
    const fieldsToExclude = ['subject', 'locations'];
    const fieldsToReset = Object.keys(allFields).filter(
      (fieldName) => !fieldsToExclude.includes(fieldName),
    );
    form.resetFields(fieldsToReset);
  }, [form, searchField, setFaxNumber, setRecepientName, setRecepientPhone]);

  const handleOnSelect = useCallback((data) => {
    if (data) {
      if (!searchField) {
        setRecepientName(data?.providerName);
        setFaxNumber(data?.primaryFax);
        setRecepientPhone(data?.primaryPhone);
      } else {
        let faxNumber;
        let phoneNumber;
        let providerName;

        data?.addresses?.map((address) => {
          if (address.address_purpose === 'LOCATION') {
            faxNumber = address?.fax_number;
            phoneNumber = address?.telephone_number;
            providerName = data?.name;
          }
        });
        setRecepientName(providerName);
        setFaxNumber(faxNumber);
        setRecepientPhone(phoneNumber);
      }
    } else {
      clearFaxData();
    }
  }, [clearFaxData, searchField, setFaxNumber, setRecepientName, setRecepientPhone]);

  return (
    <div>
      <p>
        <span
          role="presentation"
          className="test-tab"
          onClick={toggleSearchFields}
          data-testid="search-field-criteria"
        >
          {!searchField ? 'Search NPI' : 'Search AddressBook'}
        </span>
      </p>
      {searchField ? (
        <ReferringPhysicianCareTeam
          labels=""
          name="providerName"
          placeholder="Search NPI"
          labelSpan="0"
          inputSpan="24"
          form={form}
          className="patient-autocomplete"
          dropdownMatchSelectWidth={725}
          virtual={false}
          onSelect={handleOnSelect}
          selectProps={{
            showSearch: true,
          }}
        />
      ) : (
        <AddressBookAutoComplete
          labels=""
          name="addressBook"
          placeholder="Search AddressBook"
          labelSpan="0"
          inputSpan="24"
          form={form}
          className="patient-autocomplete"
          dropdownMatchSelectWidth={725}
          virtual={false}
          onSelect={handleOnSelect}
          selectProps={{
            showSearch: true,
          }}
        />
      )}
    </div>
  );
};

const FaxModal = ({ visible, toggleModal }) => {
  const [form] = AntdForm.useForm();
  const [fileData, setFileData] = useState(null);
  const [mfaxRender, setMfaxRender] = useState(true);
  const [documentList, setDocumentList] = useState([]);
  const [activeDocument, setActiveDocument] = useState({});
  const [faxNumber, setFaxNumber] = useState(null);
  const [recepientName, setRecepientName] = useState('');
  const [recepientPhone, setRecepientPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [disableCreateBundle, setDisableCreateBundle] = useState(false);
  const [selectedDocument, setSelectedDocuments] = useState([]);
  const [activeCoverPage, setActiveCoverPage] = useState({});
  const [providerValue, setProviderValue] = useState('physicians');
  const [selectedDocumentForPreview, setSelectedDocumentForPreview] = useState([]);
  const [selectedLocationForSendFax, setSelectedLocationForSendFax] = useState(undefined);
  const [defaultLocationOptions, setDefaultLocationOptions] = useState([]);
  const { params: { id } } = useRedirect();

  const [
    documentListResponse, ,
    documentListLoading,
    getDocumentsList,
  ] = useCRUD({
    id: 'get-mfax-documents-list',
    url: apiUrls.GET_MFAX_DOCUMENTS_LIST,
    type: 'read',
  });

  const [uploadResponse, , uploadLoading, uploadFiles, clearUploadResponse] = useCRUD({
    id: `patient-${id}-multiple-fax-new-document`,
    url: apiUrls.UPLOAD_PATIENT_MULTIPLE_DOCUMENTS,
    type: 'create',
  });

  const [deleteFaxDocumentResponse, , deleteFaxLoading,
    deleteFaxDocument, clearDeleteResponse] = useCRUD({
    id: 'patient-delete-fax-document',
    url: apiUrls.DELETE_FAX_DOCUMENT_IN_SHOPPING_CART,
    type: 'create',
  });

  const [reorderFaxDocumentResponse, , reorderLoading,
    reOrderFaxDocument, clearReorderRepsonse] = useCRUD({
    id: 'reorder-documents-in-shopping-cart',
    url: apiUrls.REORDER_FAX_DOCUMENT_IN_SHOPPING_CART,
    type: 'create',
  });

  const [sendFaxResponse, , sendFaxLoading, sendFax, clearSendFaxResponse] = useCRUD({
    id: 'send-fax-document-in-shopping-cart',
    url: apiUrls.SEND_FAX_BY_SHOPPING_CART,
    type: 'create',
  });

  const [updateBundleResponse, , updateBundleLoading, updateBundle, clearUpdateBundle] = useCRUD({
    id: 'create-bundle-document-in-shopping-cart',
    url: apiUrls.UPDATE_FAX_DOCUMENT_BUNDLE,
    type: 'update',
  });

  const [createBundleResponse, , createBundleLoading, createBundle, clearCreateBundle] = useCRUD({
    id: 'create-bundle-document-in-shopping-cart',
    url: apiUrls.CREATE_FAX_DOCUMENT_BUNDLE,
    type: 'create',
  });

  const [mfaxCoverPagesResponse, , coverPagesLoading, getMfaxCoverPages] = useCRUD({
    id: 'get-mfax-cover-pages',
    url: apiUrls.GET_MFAX_COVERPAGES,
    type: 'read',
  });

  const [previewMfaxDocumentResponse, , previewLoading,
    getDocumentPreview, clearPreview] = useCRUD({
    id: 'preview-mfax-document',
    url: apiUrls.PREVIEW_MFAX_DOCUMENTS_WITH_COVERPAGE,
    type: 'create',
  });

  const [
    locationListResponse, ,
    locationListLoading,
    getLocationList,,
  ] = useCRUD({
    id: 'get-mfax-location-list',
    url: apiUrls.LOCATIONS_FOR_MFAX,
    type: 'read',
  });

  useEffect(() => {
    getLocationList();
  }, []);

  useEffect(() => {
    getDocumentsList({ PatientId: id });
    return () => clearPreview(true);
  }, []);

  useEffect(() => {
    if (updateBundleResponse) {
      Notification({ message: SuccessMessages.FAX_DOCUMENT_UPDATE_SUCCESSFULLY, success: true });
      setDocumentList([]);
      getDocumentsList({ PatientId: id });
      clearUpdateBundle(true);
    }
  }, [updateBundleResponse]);

  useEffect(() => {
    if (sendFaxResponse?.[0]?.faxStatus) {
      Notification({ message: SuccessMessages.FAX_DOCUMENT_SENT_SUCCESSFULLY, success: true });
      getDocumentsList({ PatientId: id });
      Events.trigger('get-patient-fax-document-count');
      form.resetFields();
      setSelectedDocuments([]);
      setFaxNumber(null);
      setActiveCoverPage({});
    } else if (sendFaxResponse?.[0]?.faxStatus === false) {
      Notification({ message: ErrorMessages.UNABLE_TO_SEND_FAX_ON_ENTERED_NUMBER, success: false });
    }
    clearSendFaxResponse(true);
  }, [sendFaxResponse]);

  useEffect(() => {
    if (createBundleResponse) {
      Notification({ message: SuccessMessages.FAX_BUNDLE_CREATE_SUCCESSFULLY, success: true });
      setDocumentList([]);
      setSelectedDocuments([]);
      getDocumentsList({ PatientId: id });
      clearCreateBundle(true);
    }
  }, [createBundleResponse]);

  useEffect(() => {
    if (reorderFaxDocumentResponse) {
      Notification({ message: SuccessMessages.REORDER_FAX_DOCUMENT, success: true });
      getDocumentsList({ PatientId: id });
      clearReorderRepsonse(true);
    }
  }, [reorderFaxDocumentResponse]);

  useEffect(() => {
    if (deleteFaxDocumentResponse) {
      Notification({ message: SuccessMessages.FAX_DOCUMENT_DELETE_SUCCESSFULLY, success: true });
      getDocumentsList({ PatientId: id });
      Events.trigger('get-patient-fax-document-count');
      setActiveDocument({});
      setSelectedDocuments([]);
      clearDeleteResponse(true);
    }
  }, [deleteFaxDocumentResponse]);

  useEffect(() => {
    if (documentListResponse) {
      const parsedData = [];
      const groupedDataByBundleId = groupBy(sortBy(documentListResponse?.result, 'sortOrder'), 'bundleId');
      Object.entries(groupedDataByBundleId)?.forEach(([key, value]) => {
        if (key !== 'null') {
          parsedData.push({ bundleId: key, bundled: value });
        } else {
          parsedData.push(...value);
        }
      });
      setDocumentList([...parsedData]);
    }
  }, [documentListResponse]);

  useEffect(() => {
    if (uploadResponse?.isDuplicateExists) {
      ConfirmDialog({
        onOk: (close) => {
          uploadFiles({
            data: {
              contentType: contentType.MULTIPART,
              File: fileData,
              PatientId: id,
              includeDuplicate: true,
            },
          });
          close();
        },
        onCancel: (close) => {
          uploadFiles({
            data: {
              contentType: contentType.MULTIPART,
              File: fileData,
              PatientId: id,
              includeDuplicate: false,
            },
          });
          close();
        },
        okText: 'Ok',
        title: 'Duplicate Documents',
        content: 'Document (s) already added to Fax queue, do you want to add the document again?',
        icon: <Icon name="ExclamationCircleOutlined" />,
        cancelButtonProps: { className: 'ant-modal-continue-green-btn' },
        okButtonProps: { className: 'ant-modal-ok-white-btn' },
      })();
    } else if (uploadResponse?.recordInserted > 0) {
      Notification({ message: SuccessMessages.DOCUMENT_ADDED_TO_FAX_SUCCESSFULLY, success: true });
      getDocumentsList({ PatientId: id });
      Events.trigger('get-patient-fax-document-count');
    }
    clearUploadResponse(true);
  }, [uploadResponse]);

  useEffect(() => {
    if (locationListResponse) {
      setDefaultLocationOptions(locationListResponse?.map(
        (item) => ({ ...item, name: item?.locationName, value: item?.locationId })));
    }
  }, [locationListResponse]);

  const onChangeSubject = useCallback(({ target: { value } }) => {
    setSubject(value);
  }, []);

  const clearFaxData = () => {
    setFaxNumber();
    setRecepientName();
    setRecepientPhone();
  };

  const onChangeRecepientName = useCallback((e) => {
    setRecepientName(e.target?.value);
  }, []);
  const onChangeRecepientPhone = useCallback((value) => {
    setRecepientPhone(value.toString());
  }, []);

  const onChangeProviders = useCallback((e) => {
    setProviderValue(e.target.value);
    clearFaxData();

    const allFields = form.getFieldsValue();
    const fieldsToExclude = ['subject', 'locations'];
    const fieldsToReset = Object.keys(allFields).filter(
      (fieldName) => !fieldsToExclude.includes(fieldName),
    );
    form.resetFields(fieldsToReset);
  }, []);

  const sendFaxMethod = useCallback(() => {
    setMfaxRender(!mfaxRender);
    clearPreview(true);
  },
  [clearPreview, mfaxRender]);

  const handleBackToMainView = useCallback(() => {
    sendFaxMethod();
    setActiveDocument({});
  }, [sendFaxMethod]);

  const handleDragStart = (event, data) => {
    event.stopPropagation();
    event.dataTransfer.setData('id', event.target.id);
    event.dataTransfer.setData('index', event.target.getAttribute('index'));
    event.dataTransfer.setData('draggedData', JSON.stringify(data));
    event.dataTransfer.setData('type', 'simple-list');
    event.target.classList.add('dragging');
  };

  const notify = (message) => Notification({ message, success: false });

  const checkConditions = useCallback((type) => {
    if (type === 'preview') {
      if (isEmpty(activeCoverPage)) return 'Please select coverpage for preview';
      if (isEmpty(selectedDocumentForPreview)) return 'Please select document for preview';
    } else {
      if (providerValue === 'manually') {
        if (!form.getFieldValue('faxNumber') && !recepientName) return 'Please select/enter a destination Fax number';
      } else if (!faxNumber?.length || !recepientName) return 'Please select/enter a destination Fax number';
      if (isEmpty(activeCoverPage)) return 'Please select coverpage for preview';
      if (isEmpty(selectedDocumentForPreview)) return 'Please select document for preview';
      if (!recepientName) return 'Please enter the recipient\'s name';
      if (defaultLocationOptions?.length && !selectedLocationForSendFax) return 'Please select Location';
    }
    return null;
  }, [
    activeCoverPage,
    selectedDocumentForPreview,
    faxNumber,
    recepientName,
    defaultLocationOptions,
    selectedLocationForSendFax,
    providerValue,
    form,
  ]);

  const handleDragEnd = (event) => {
    event.target.classList.remove('dragging');
  };

  const handleDragOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const getClosestTag = (e, tagName = 'li') => {
    let element = e?.target;
    while (element?.parentNode) {
      element = element.parentNode;
      if (element.tagName?.toLowerCase() === tagName) {
        return element?.id;
      }
    }
    return null;
  };

  const handleBundleDrop = useCallback((event, bundleData) => {
    event.stopPropagation();
    event.preventDefault();
    const [dropLocation, bundleId, , srt1, , srt2] = getClosestTag(event)?.split('_');
    const sortOrder1 = parseFloat(srt1);
    const sortOrder2 = parseFloat(srt2);
    const [dragDiv, , dragCartId, , dragCartIdInReorder] = event.dataTransfer.getData('id')?.split('_');
    if (dragDiv?.toLowerCase() === 'bundledoc' && bundleId && dragCartId) {
      reOrderFaxDocument({
        data: {
          patientId: id,
          cartDocumentId: parseInt(dragCartIdInReorder, 10),
          sortOrder: parseFloat((sortOrder1 + sortOrder2) / 2),
        },
      });
      return;
    }
    if (dropLocation?.toLowerCase() === 'bundledoc' && bundleId && dragCartId) {
      const existingDocumentIds = bundleData?.map((crt) => crt?.cartDocumentId);
      updateBundle({
        patientId: id,
        bundleId: parseInt(bundleId, 10),
        faxCartDocumentIds: [...existingDocumentIds, parseInt(dragCartId, 10)],
      });
    }
  }, [id, reOrderFaxDocument, updateBundle]);

  const handleDrop = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
    const sortOrder = getClosestTag(event)?.split('_');
    sortOrder[0] = parseFloat(sortOrder[1]);
    sortOrder[1] = parseFloat(sortOrder[3]);
    const cartDocumentId = event.dataTransfer.getData('id')?.split('_')?.[2];
    if (!isNaN(sortOrder[0]) && !isNaN(sortOrder[1])) {
      reOrderFaxDocument({
        data: {
          patientId: id,
          cartDocumentId,
          sortOrder: parseFloat((sortOrder[0] + sortOrder[1]) / 2),
        },
      });
    }
  }, [id, reOrderFaxDocument]);

  const handleFileUpload = useCallback((fileInfo) => {
    if (fileSizeCheck(fileInfo, 30)) {
      setFileData(fileInfo);
      uploadFiles({
        data: {
          contentType: contentType.MULTIPART,
          File: fileInfo,
          PatientId: id,
        },
      });
    }
  }, [id, uploadFiles]);

  const handleDocumentClick = useCallback((document, bundle = []) => {
    const isBundle = !!bundle?.length;
    setActiveDocument(document);
    clearPreview(true);
    getDocumentPreview({
      data: {
        cartIds: isBundle
          ? uniq(bundle?.map((item) => item?.cartDocumentId)) : [document?.cartDocumentId],
        responseType: responseType.BLOB,
      },
    });
  }, [clearPreview, getDocumentPreview]);

  const handleDocumentCheckbox = useCallback((e, doc) => {
    e.stopPropagation();
    if (e?.target?.checked) {
      setSelectedDocuments([...selectedDocument, doc]);
      setSelectedDocumentForPreview([...selectedDocumentForPreview, doc]);
    } else {
      const index = selectedDocument?.findIndex(
        (item) => item?.cartDocumentId === doc?.cartDocumentId);
      const clonedDocuments = [...selectedDocument];
      clonedDocuments.splice(index, 1);
      setSelectedDocuments([...clonedDocuments]);
      // for preview
      const previewIndex = selectedDocumentForPreview?.findIndex(
        (item) => item?.cartDocumentId === doc?.cartDocumentId);
      const clonedPreviewDocuments = [...selectedDocumentForPreview];
      clonedPreviewDocuments.splice(previewIndex, 1);
      setSelectedDocumentForPreview([...clonedPreviewDocuments]);
    }
  }, [selectedDocument, selectedDocumentForPreview]);

  const handleDeleteDocument = useCallback((e, documentId) => {
    e.stopPropagation();
    deleteFaxDocument({
      data: {
        patientId: id,
        faxCartDocumentIds: [documentId],
      },
    });
  }, [deleteFaxDocument, id]);

  const handleSendFax = useCallback(() => {
    const errorMessage = checkConditions('send');
    if (errorMessage !== null) {
      notify(errorMessage);
      return null;
    }

    sendFax({
      data: {
        faxNumber: faxNumber || form.getFieldValue('faxNumber'),
        cartIds: selectedDocument?.map((it) => it?.cartDocumentId),
        patientid: id,
        coverPageId: activeCoverPage?.uuid,
        locationId: selectedLocationForSendFax,
        recipientName: recepientName,
        subject,
        recipientPhone: recepientPhone,
      },
    });
    return null;
  }, [
    recepientPhone,
    checkConditions,
    activeCoverPage,
    faxNumber,
    form,
    id,
    recepientName,
    selectedDocument,
    selectedLocationForSendFax,
    sendFax,
    subject,
  ]);

  const handleCommonFaxNumber = useCallback((val) => {
    setFaxNumber(val);
  }, []);

  const handleCreateBundle = useCallback(() => {
    if (selectedDocument?.length > 1) {
      createBundle({
        data: {
          patientId: id,
          faxCartDocumentIds: selectedDocument?.map((it) => it?.cartDocumentId),
        },
      });
    } else {
      Notification({
        message:
        ErrorMessages.AT_LEAST_TWO_DOC_REQ_TO_CREATE_BUNDLE,
        success: false,
      });
    }
  }, [createBundle, id, selectedDocument]);

  const handleDeleteBundleDocument = useCallback((e, bundleId, bundledData, cartDocumentId) => {
    e.stopPropagation();
    const faxCartDocumentIds = [];
      bundledData?.forEach((it) => {
        if (it.cartDocumentId !== cartDocumentId) {
          faxCartDocumentIds.push(it.cartDocumentId);
        }
      });
      updateBundle({
        patientId: id,
        bundleId,
        faxCartDocumentIds,
      });
  }, [id, updateBundle]);

  useEffect(() => {
    if (!mfaxCoverPagesResponse?.length) {
      getMfaxCoverPages({});
    }
  }, []);

  useEffect(() => {
    if (mfaxCoverPagesResponse?.length > 0 && isEmpty(activeCoverPage)) {
      const defaultCoverPage = mfaxCoverPagesResponse.find((item) => item?.id === 'a19fe374-e7bc-49f1-9dd6-d0d80651accd');
      if (!isEmpty(defaultCoverPage)) {
        setActiveCoverPage(defaultCoverPage);
      }
    }
  }, [mfaxCoverPagesResponse]);

  const handleBundleCheckbox = useCallback((e, bundled = []) => {
    e.stopPropagation();
    if (e?.target?.checked) {
      setSelectedDocumentForPreview([...selectedDocumentForPreview, ...bundled]);
      setSelectedDocuments([...selectedDocument, bundled?.[0]]);
      setDisableCreateBundle(true);
    } else {
      const clonedPreviewDocuments = [...selectedDocumentForPreview];
      bundled?.forEach((ind) => {
        remove(clonedPreviewDocuments, (item) => item.cartDocumentId === ind.cartDocumentId);
      });
      setSelectedDocumentForPreview(clonedPreviewDocuments);

      const clonedDocuments = [...selectedDocument];
      remove(clonedDocuments, (item) => item.cartDocumentId === bundled?.[0]?.cartDocumentId);
      setSelectedDocuments([...clonedDocuments]);
      if (find(clonedDocuments, ({ bundleId }) => bundleId)) {
        setDisableCreateBundle(true);
      } else {
        setDisableCreateBundle(false);
      }
    }
  }, [selectedDocument, selectedDocumentForPreview]);

  const handlePreviewDocument = useCallback(() => {
    const errorMessage = checkConditions('preview');
    if (errorMessage !== null) {
      notify(errorMessage);
      return;
    }

    clearPreview(true);
    getDocumentPreview({
      data: {
        cartIds: uniq(selectedDocumentForPreview?.map((item) => item?.cartDocumentId)),
        coverPageId: activeCoverPage?.uuid,
        responseType: responseType.BLOB,
        locationId: selectedLocationForSendFax,
        faxNumber: faxNumber || form.getFieldValue('faxNumber'),
        recipientName: recepientName,
        recipientPhone: recepientPhone,
        subject,
      },
    });
  }, [
    checkConditions,
    form,
    activeCoverPage,
    clearPreview,
    faxNumber,
    getDocumentPreview,
    recepientName,
    selectedDocumentForPreview,
    subject,
    selectedLocationForSendFax,
    recepientPhone,
  ]);

  const onSendFax = useCallback(() => {
    const errorMessage = checkConditions('send');
    if (errorMessage !== null) {
      notify(errorMessage);
      return null;
    }
    form.submit();
    return false;
  }, [checkConditions, form]);

  return (
    <Modals
      visible={visible}
      width={1120}
      className="fax-modal-cls"
      toggleModal={toggleModal}
      destroyOnClose
      footer={null}
    >
      <div className="addition-header print-modal-header">
        <div className="fax-modal-header-wrap">
          <div className="header-icon-wrap">
            <span className="fax-modal-icon" />
            <p className="bold">Fax Cart</p>
          </div>
          {mfaxRender && (
            <Button type="text" className="sm-btn btn-success" onClick={sendFaxMethod}> Select Recipient</Button>
          )}
        </div>
      </div>
      <div className="modal-content-area">
        {sendFaxLoading && <WidgetLoader />}
        <div className="fax-content-area">
          <div className="left-pannel posi-relative">
            {(uploadLoading || documentListLoading || updateBundleLoading
                || deleteFaxLoading || reorderLoading || createBundleLoading) && <WidgetLoader />}
            <div className="left-pannel-button">
              <Button
                type="text"
                className={classNames('sm-btn btn-success', { 'cursor-not-allowed disabled-btn': disableCreateBundle })}
                onClick={handleCreateBundle}
                disabled={disableCreateBundle}
              >
                Create Bundle
              </Button>
              <Upload
                name={null}
                labelSpan="0"
                accept="image/png, image/jpeg, .doc, .docx, .pdf, .DOC, .DOCX, .PDF"
                multiple
                showUploadList={false}
                transformFile={handleFileUpload}
                customRequest={() => { /** */ }}
              >
                <Button
                  type="text"
                  className="sm-btn btn-success"
                >
                  Upload
                </Button>
              </Upload>
            </div>
            <div className="documet-list">
              {documentList?.map((doc, index) => (doc?.bundleId ? (
                <div
                  key={doc?.bundleId}
                  className={classNames('bundle-document documet-list selected-document-list',
                      activeDocument?.bundleId === parseInt(doc?.bundleId, 10) ? 'active' : '')}
                >
                  <div className="fax-checkbox-wrap">
                    <SMCheckBox
                      className="labelRight"
                      onClick={(e) => handleBundleCheckbox(e, doc?.bundled)}
                    />
                  </div>
                  {doc?.bundled?.map((bundledDoc, index1) => (
                    <li
                      key={`${doc?.bundleId}-${bundledDoc?.documentId}`}
                      id={`bundledoc_${doc?.bundleId}_${bundledDoc?.documentId}_${bundledDoc?.sortOrder}_${bundledDoc?.cartDocumentId}_${doc?.bundled[index1 - 1]?.sortOrder || 0}`}
                      index={index1}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleBundleDrop(e, doc?.bundled)}
                      role="presentation"
                      onClick={() => handleDocumentClick(bundledDoc, doc?.bundled)}
                    >
                      <div
                        key={`${doc?.bundleId}-${bundledDoc?.documentId}`}
                        id={`bundledoc_${doc?.bundleId}_${bundledDoc?.documentId}_${bundledDoc?.sortOrder}_${bundledDoc?.cartDocumentId}_${doc?.bundled[index1 - 1]?.sortOrder || 0}`}
                        index={index1}
                        draggable
                        onDragStart={(e) => handleDragStart(e, bundledDoc)}
                        onDragEnd={handleDragEnd}
                        className="documet-list-wrap"
                      >
                        <div className="sort-icon" />
                        <div className="documet-list-wrap-checkbox">
                          <p>{bundledDoc?.displayName}</p>
                        </div>
                        <p className="delete" title="Remove">
                          <Icon
                            name={iconNames.minusCircleOutlined}
                            onClick={(e) => handleDeleteBundleDocument(e,
                                bundledDoc?.bundleId, doc?.bundled, bundledDoc?.cartDocumentId)}
                          />
                        </p>
                      </div>
                    </li>
                  ))}
                </div>
              ) : (
                <li
                  key={doc?.documentId}
                  id={`${doc?.documentId}_${doc?.sortOrder}_${doc?.cartDocumentId}_${documentList[index - 1]?.sortOrder || 0}`}
                  index={index}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  role="presentation"
                  onClick={() => handleDocumentClick(doc)}
                  className={classNames(activeDocument?.cartDocumentId === doc?.cartDocumentId ? 'active' : '')}
                >
                  <div
                    key={doc?.documentId}
                    id={`${doc?.documentId}_${doc?.sortOrder}_${doc?.cartDocumentId}_${documentList[index - 1]?.sortOrder || 0}`}
                    index={index}
                    draggable
                    onDragStart={(e) => handleDragStart(e, doc)}
                    onDragEnd={handleDragEnd}
                    className="documet-list-wrap"
                  >
                    <div className="error-icon" title="Error type" />
                    <div className="sort-icon" />
                    <div className="documet-list-wrap-checkbox">
                      <div
                        key={doc?.documentId}
                        role="presentation"
                        onClick={(e) => handleDocumentCheckbox(e, doc)}
                      >
                        <SMCheckBox
                          className="labelLeft"
                          checked={selectedDocument?.find(
                              (item) => item?.cartDocumentId === doc?.cartDocumentId)}
                        />
                      </div>
                      <p>{doc?.displayName}</p>
                    </div>
                    <p className="delete" title="Delete">
                      <Icon
                        name={iconNames.deleteFilled}
                        onClick={(e) => handleDeleteDocument(e, doc?.cartDocumentId)}
                      />
                    </p>
                  </div>
                </li>
              )))}
            </div>
          </div>
          <div className="preview-section parent-preview">
            <div className="preview">
              {(previewLoading) && <WidgetLoader />}
              {(mfaxRender && previewMfaxDocumentResponse) && (
                <PdfPreview
                  file={previewMfaxDocumentResponse}
                  collapsableView
                />
              )}
            </div>
            {!mfaxRender && (
              <div>
                {(coverPagesLoading || previewLoading) && <WidgetLoader />}
                <div className="m-fax-ui">
                  <span role="presentation" onClick={handleBackToMainView} className="close-icon" title="Close" />
                  <Radio
                    options={searchTypeSourceOptions}
                    onChange={onChangeProviders}
                    value={providerValue}
                  />
                </div>
                <hr className="provider-location-seperator" />
                <Form
                  form={form}
                  onFinish={handleSendFax}
                >
                  <div className="form-inner-content">
                    <div>
                      <div className="flex justify-content-sp-bt button-wrapper">
                        <SMCheckBox
                          className="labelRight"
                          defaultChecked
                          disabled
                        >
                          Include Cover Page
                        </SMCheckBox>
                        {mfaxCoverPagesResponse?.length ? (
                          <Button
                            className="btn sm-btn btn-success"
                            onClick={handlePreviewDocument}
                          >
                            Preview Document
                          </Button>
                        ) : null}
                      </div>
                      {mfaxCoverPagesResponse?.length ? (
                        <CoverPageWrap
                          activeCoverPage={activeCoverPage}
                          setActiveCoverPage={setActiveCoverPage}
                          coverPages={mfaxCoverPagesResponse}
                        />
                      ) : null}
                    </div>
                    <div className="fax-container">
                      <div className="physicain-wrap">
                        <Input
                          name="subject"
                          label="Subject"
                          placeholder="Enter Subject"
                          labelSpan="7"
                          inputSpan="10"
                          maxValueLength={55}
                          onChange={onChangeSubject}
                        />
                        {providerValue === 'physicians' && (
                        <>
                          <PhysicianSearch
                            faxNumber={faxNumber}
                            setFaxNumber={setFaxNumber}
                            setRecepientName={setRecepientName}
                            setRecepientPhone={setRecepientPhone}
                            clearFaxData={clearFaxData}
                            form={form}
                            className="mfax-search"
                          />
                          <div className="destination-fax-no">
                            <span className="label-name">Destination fax number:</span>
                            <span className="label-value">
                              {faxNumber}
                            </span>
                          </div>
                        </>
                        )}
                        {providerValue === 'payers' && (
                          <>
                            <PayerSearch
                              setFaxNumber={setFaxNumber}
                              setRecepientName={setRecepientName}
                              setRecepientPhone={setRecepientPhone}
                              clearFaxData={clearFaxData}
                            />
                            <div className="destination-fax-no">
                              <span className="label-name">destination fax number:</span>
                              <span className="label-value">
                                {faxNumber}
                              </span>
                            </div>
                          </>
                        )}
                        {(providerValue === 'manually' || providerValue === 'commonlyUsed') && (
                          <Input
                            name="recepientName"
                            label="Recipient Name"
                            placeholder="Enter Recipient Name"
                            labelSpan="7"
                            inputSpan="10"
                            required
                            maxValueLength={35}
                            onChange={onChangeRecepientName}
                          />
                        )}
                        {providerValue === 'manually' && (
                        <>
                          <Input
                            name="faxNumber"
                            label="Destination Fax Number"
                            placeholder="Enter Destination Fax Number"
                            labelSpan="7"
                            inputSpan="10"
                            required
                            numberOnly
                            maxValueLength={10}
                          />
                          <PhoneNumberInput
                            label="Primary Phone"
                            name="primaryPhone"
                            maxValueLength={10}
                            minValueLength={10}
                            labelSpan="7"
                            inputSpan="10"
                            placeholder="Enter Recepient Phone"
                            onChange={onChangeRecepientPhone}
                          />
                        </>
                        )}
                        {providerValue === 'commonlyUsed' && (
                        <>
                          <div className="select-destination-fax-no">
                            <span className="label-name">Select from Commonly used top 10 destination fax number:</span>
                          </div>
                          <div className="fax-no-list-wrap">
                            <WiredSelect
                              id="get-top-common-fax-numbers"
                              name="commonFaxNumber"
                              url={apiUrls.GET_TOP_MOST_FAX_NUMBERS}
                              placeholder="Select Destination Fax Number"
                              inputSpan="20"
                              labelSpan="0"
                              onChange={handleCommonFaxNumber}
                              nameAccessor="faxNumber"
                              valueAccessor="faxNumber"
                            />
                          </div>
                        </>
                        )}
                      </div>
                    </div>
                    <div className="footer-btn-section">
                      {(locationListLoading) && <WidgetLoader />}
                      {defaultLocationOptions?.length ? (
                        <>
                          <Button className={classNames('sm-btn btn-success mr-rt-12', { 'cursor-not-allowed disabled-btn': !selectedLocationForSendFax })} onClick={onSendFax}> Send Fax</Button>
                          <WiredSelect
                            id="locations"
                            name="locations"
                            labelSpan="0"
                            inputSpan="24"
                            nameAccessor="locationName"
                            valueAccessor="locationId"
                            startCaseOptions
                            defaultOptions={defaultLocationOptions}
                            Component={SelectBox}
                            selectProps={{
                              showSearch: true,
                              dropdownMatchSelectWidth: 350,
                              style: {
                                width: 160,
                              },
                            }}
                            onChange={setSelectedLocationForSendFax}
                            placeholder="Select Location"
                          />
                        </>
                      ) : <Button className="sm-btn btn-success" type="submit"> Send Fax</Button>}
                    </div>
                  </div>
                </Form>
              </div>
            )}
            {!mfaxRender && previewMfaxDocumentResponse && (
              <>
                <hr className="provider-location-seperator" />
                <div className="preview-section">
                  <div className="preview">
                    <PdfPreview
                      file={previewMfaxDocumentResponse}
                      collapsableView
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Modals>

  );
};
export default FaxModal;
