import React, { useState, useEffect } from 'react';
import { Tabs, Tooltip } from 'antd';
import PropTypes from 'prop-types';

import './lefttab.scss';
import isFunction from 'lodash/isFunction';
import useRedirect from '../../hooks/useRedirect';

const { TabPane } = Tabs;

const BlankComponent = () => <div />;

function TabLeft({
  tabList,
  setAllSelected,
  currentTab,
  activeTab,
  setActiveTab,
  tabWithAllSelected,
  checkTabs,
  setCheckedTabsWithCheckBoxes,
  setTabWithAllSelected,
  onTabChange,
  id,
  showToolTip,
  initialTab,
  routerParamsPropsName,
  patientDateOfBirth,
  ...otherProps
}) {
  const { params } = useRedirect();

  const [activeKey, setActiveKey] = useState(initialTab);

  const onChange = (currentActiveKey) => {
    setActiveKey(currentActiveKey);
    if (setActiveTab) {
      setActiveTab(Object.keys(currentTab));
    }
    if (isFunction(onTabChange)) onTabChange(currentActiveKey);
  };

  useEffect(() => {
    if (routerParamsPropsName) {
      const tab = params[routerParamsPropsName];
      const activeTabObject = tab ? tabList.find(({ route }) => route === tab) : {};
      setActiveKey(activeTabObject?.key || activeKey);
    }
  }, [params]);

  return (
    <div className="v-tab">
      <Tabs tabPosition="left" onChange={onChange} defaultActiveKey="1" activeKey={activeKey} destroyInactiveTabPane id={id}>
        {tabList && tabList.length && tabList.map((item) => {
          const name = item.name || '';
          const iconClassName = item.iconClassName || '';
          const TabComponent = item.component || BlankComponent;
          return (
            <TabPane
              tab={
                  showToolTip ? (
                    <Tooltip title={item?.toolTipTitle}>
                      <div className={iconClassName}>
                        {name}
                      </div>
                    </Tooltip>
                  ) : (
                    <div className={iconClassName}>
                      {name}
                    </div>
                  )
              }
              style={{ marginBottom: 0 }}
              key={item.key}
            >
              <TabComponent
                checkTabs={checkTabs}
                setCheckedTabsWithCheckBoxes={setCheckedTabsWithCheckBoxes}
                tabWithAllSelected={tabWithAllSelected}
                setTabWithAllSelected={setTabWithAllSelected}
                allSelected={tabWithAllSelected && !!tabWithAllSelected[activeTab]}
                setAllSelected={setAllSelected}
                patientDateOfBirth={patientDateOfBirth}
                {...otherProps}
              />
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
}

TabLeft.defaultProps = {
  tabList: [],
  id: 'tab-left',
  setAllSelected: () => { /* This is intentional */ },
  currentTab: {},
  activeTab: '',
  setActiveTab: null,
  tabWithAllSelected: {},
  checkTabs: {},
  setCheckedTabsWithCheckBoxes: () => { /* This is intentional */ },
  setTabWithAllSelected: () => { /* This is intentional */ },
  initialTab: '1',
  showToolTip: false,
  routerParamsPropsName: 'tab',
};

TabLeft.propTypes = {
  tabList: PropTypes.instanceOf(Array),
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  setAllSelected: PropTypes.func,
  currentTab: PropTypes.instanceOf(Object),
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
  tabWithAllSelected: PropTypes.instanceOf(Object),
  checkTabs: PropTypes.instanceOf(Object),
  setCheckedTabsWithCheckBoxes: PropTypes.func,
  setTabWithAllSelected: PropTypes.func,
  initialTab: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  showToolTip: PropTypes.bool,
  routerParamsPropsName: PropTypes.string,
};

export default TabLeft;
