import CommonColumns from './commonColumns';

const chargeColumn = (labels) => [
  ...CommonColumns(labels),
  {
    Header: 'Withhold',
    accessor: 'withHold',
    Cell: ({ row: { original: { withHold } } }) => (
      parseFloat(withHold || 0).toFixed(2)
    ),
    className: 'text-align-right',
    fixWidth: '90',
  },
  {
    Header: labels.get('tableColumns.adjusted'),
    accessor: 'adjustment',
    Cell: ({ row: { original: { adjustment } } }) => (
      parseFloat(adjustment || 0).toFixed(2)
    ),
    className: 'text-align-right',
    fixWidth: '90',
  },
  {
    Header: labels.get('tableColumns.insuranceBalance'),
    accessor: 'insuranceBalance',
    Cell: ({
      row: {
        original: {
          isPatientResponsibility, balance,
        },
      },
    }) => (
      !isPatientResponsibility ? parseFloat(balance || 0).toFixed(2) : parseFloat(0).toFixed(2)
    ),
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.patientBalance'),
    accessor: 'patientBalance',
    Cell: ({
      row: {
        original: {
          isPatientResponsibility, patientBalance, balance,
        },
      },
    }) => (!isPatientResponsibility
      ? parseFloat(patientBalance || 0).toFixed(2) : parseFloat(balance || 0).toFixed(2)),
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.enteredBy'),
    accessor: 'enteredBy',
    className: 'text-align-left',
    fixWidth: '110',
  },
];

export default chargeColumn;
