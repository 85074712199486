import React, { useCallback, useState } from 'react';
import Search from '../../../../../../../../../../components/Search';
import JsonTree from '../../JsonTree';
import { apiUrls } from '../../../../../../../../../../api/constants';

const Procedure = ({
  educationalMaterialList,
  setEducationalMaterialList,
}) => {
  const [searchString, setSearchString] = useState('');
  const onSearch = useCallback((event) => {
    const { value } = event.target;
    setSearchString(value || '');
  }, []);

  return (
    <div className="side-tab">
      <div className="merge-fields-container">
        <div className="temp-search-wrapper">
          <Search
            placeholder="Search Fields"
            onChange={onSearch}
          />
        </div>
        <div className="json-tree-container">
          <JsonTree
            url={apiUrls.GET_COMPOSER_LABELS}
            setLabelsList={setEducationalMaterialList}
            list={educationalMaterialList}
            search={searchString}
            selectable={false}
            tab="edu"
          />
        </div>
      </div>
    </div>
  );
};

export default Procedure;
