import PropTypes from 'prop-types';

export const defaultProps = {
  url: '',
  id: '',
  nameAccessor: '',
  valueAccessor: '',
  wrapperClassName: '',
  onFetchComplete: null,
  reFetchOptions: null,
  defaultOptions: null,
  parser: null,
  params: {},
  isParamsRequired: false,
  defaultSorting: true,
  isStatCaseOptions: true,
  valueInString: false,
  updateData: false,
};

export const defaultPropsType = {
  url: PropTypes.string,
  id: PropTypes.string,
  nameAccessor: PropTypes.string,
  valueAccessor: PropTypes.string,
  wrapperClassName: PropTypes.string,
  Component: PropTypes.oneOf([
    PropTypes.func,
    PropTypes.shape({
      $$typeof: PropTypes.symbol,
    }),
  ]),
  onFetchComplete: PropTypes.func,
  params: PropTypes.objectOf(PropTypes.object),
  reFetchOptions: PropTypes.arrayOf(PropTypes.object),
  defaultOptions: PropTypes.arrayOf(PropTypes.object),
  parser: PropTypes.func,
  isParamsRequired: PropTypes.bool,
  defaultSorting: PropTypes.bool,
  isStatCaseOptions: PropTypes.bool,
  valueInString: PropTypes.bool,
  updateData: PropTypes.bool,
};
