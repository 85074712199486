import React from 'react';
import { Route, Switch } from 'react-router-dom';
import useRedirect from '../../../../hooks/useRedirect';
import ExamRoom from './ExamRoom';
import EditExamRoom from './EditExamRoom';
import AddExamRoom from './AddExamRoom';

function ExamRoomContainer(props) {
  const { params, path } = useRedirect();
  return (
    <Switch>
      <Route exact path={path}>
        <ExamRoom {...props} parentParams={params} />
      </Route>
      <Route path={`${path}/edit/:examId`}>
        <EditExamRoom {...props} parentParams={params} />
      </Route>
      <Route path={`${path}/new`}>
        <AddExamRoom {...props} parentParams={params} />
      </Route>
    </Switch>
  );
}

export default ExamRoomContainer;
