import React, { useCallback } from 'react';
import get from 'lodash/get';

import { apiUrls } from '../../../../../api/constants';
import { listIds } from '../../../../../lib/constants';

import withQuery from '../../../../../hoc/withQuery/withQuery';
import withReduxManager from '../../../../../hoc/withReduxManager';

import TableWrapper from '../../../../../components/Table/TableWrapper';
import Button from '../../../../../components/Button';
import FilterManager from '../../../../../components/FilterManager';
import Table from '../../../../../components/Table';
import { columns, commonCPTICDColumns, FilterCollection } from '../commonCell';

const initialSort = [{ id: 'ICDCode', desc: false }];
const initialFilter = { Pending: true, Status: 'Pending' };

const icdColumns = (onAddSynonym) => [
  ...commonCPTICDColumns(),
  {
    Header: 'ICD Code',
    accessor: 'icdCode',
    Cell: ({ row: { original } }) => original?.icdCode || 'No ICD',
  },
  {
    Header: 'Description',
    accessor: 'icdDescription',
  },
  {
    Header: '',
    accessor: 'create',
    fixWidth: '50',
    Cell: ({ row: { original } }) => {
      const onAddClick = useCallback((e) => {
        e.stopPropagation();
        onAddSynonym(original);
      }, [original]);
      if (original?.icdCode === 'No ICD') return (<span />);
      return (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a
          className="row-add sprite-img-before"
          onClick={onAddClick}
          role="presentation"
        />
      );
    },
  },
];

const CustomICDTable = withReduxManager({
  listId: listIds.ICD_CODE_SYNONYMS_LIST,
})(
  withQuery({
    url: apiUrls.ICD_CODE_SYNONYMS_LIST,
    listId: listIds.ICD_CODE_SYNONYMS_LIST,
  })(),
);

const ExpandedICDRowRender = ({ row, ...otherProps }) => {
  const icdCode = get(row, 'original.icdCode');
  const synonyms = get(row, 'original.synonym');
  return (
    <Table
      data={synonyms}
      columns={columns()}
      noDataText={`No synonym suggested for ICD: ${icdCode}`}
      isHeaderFixed={false}
      {...otherProps}
    />
  );
};

const ICDTable = ({ onAddSynonym, setRefetch, ...otherProps }) => {
  const onNewSynonym = useCallback(() => onAddSynonym(), [onAddSynonym]);

  return (
    <CustomICDTable
      columns={icdColumns(onAddSynonym)}
      initialSort={initialSort}
      initialFilters={initialFilter}
      filters={{ isCptCode: false }}
      noDataText="No results found"
      autoResetExpanded={false}
      pageSize={null}
      renderRowSubComponent={(props) => <ExpandedICDRowRender {...props} {...otherProps} />}
    >
      {({
        Component, totalCount, initialFilters, onFiltersChange, reFetch,
      }) => (
        <FilterManager
          initialFilters={initialFilters}
          onChange={onFiltersChange}
          commaSeperated
          filtersToInclude={['Pending', 'Approved', 'Declined']}
          filtersOnClear="Pending,Approved"
        >
          {({ onFilterChange, filters }) => (
            <>
              <div className="table-filters">
                <FilterCollection onFilterChange={onFilterChange} filters={filters} allowClear />
                <span className="table-count">
                  <span>{`Total Count: ${totalCount}`}</span>
                </span>
                <Button className="btn-success sm-btn" onClick={onNewSynonym}>Add ICD Synonym</Button>
              </div>
              <TableWrapper Component={Component} reFetch={reFetch} setReFetch={setRefetch} />
            </>
          )}
        </FilterManager>
      )}
    </CustomICDTable>
  );
};

ICDTable.defaultProps = {
  setRefetch: () => { /* This is intentional */ },
  onEditSynonym: () => { /* This is intentional */ },
  onDeleteSynonym: () => { /* This is intentional */ },
  onAcceptSynonym: () => { /* This is intentional */ },
  onRejectSynonym: () => { /* This is intentional */ },
};

export default ICDTable;
