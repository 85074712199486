import React, {
  useRef, useEffect, useImperativeHandle, forwardRef, useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import panzoom from '@panzoom/panzoom';
import isEqual from 'lodash/isEqual';

import Events from '../../lib/events';

import './panzoom.scss';

const assignAllEvents = (triggerList, eventClass, callback = () => { /* This is intentional */ }) => triggerList.forEach(
  (eventName) => Events.on(eventName, eventClass, callback),
);

const removeAllEvents = (triggerList, eventClass) => triggerList.current.forEach(
  (eventName) => Events.remove(eventName, eventClass),
);

const onPanZoomChange = (eventId, { detail = {} }) => (
  Events.trigger(eventId, { eventId, ...detail })
);

const syncPanZoom = (instance, data = {}) => {
  const { x = 0, y = 0, scale = 1 } = data;
  const oldPan = instance.getPan();
  const oldScale = instance.getScale();
  if (!isEqual(oldPan, { x, y })) instance.pan(x, y, { silent: true });
  if (oldScale !== scale) instance.zoom(scale, { silent: true });
};

const Panzoom = forwardRef(({
  eventClassName,
  eventId,
  triggerIds,
  images,
  children,
  className,
  panZoomOptions,
}, ref) => {
  const imageRef = useRef();
  const instance = useRef();
  const lastTriggers = useRef([]);

  useLayoutEffect(() => {
    const element = document.querySelector(`.${eventClassName}`);
    if (element) {
      instance.current = panzoom(element, {
        canvas: false,
        ...panZoomOptions,
      });
      element.addEventListener('wheel', instance.current.zoomWithWheel);
      element.addEventListener('panzoomchange', (data) => onPanZoomChange(eventId, data));
    }
    return () => {
      if (instance.current) {
        element.removeEventListener('wheel', instance.current.zoomWithWheel);
        element.removeEventListener('panzoomchange', (data) => onPanZoomChange(eventId, data));
        removeAllEvents(lastTriggers, eventClassName);
      }
    };
  }, []);

  useEffect((() => {
    if (lastTriggers.current && lastTriggers.current.length) {
      removeAllEvents(lastTriggers, eventClassName);
    }
    if (triggerIds.length) {
      assignAllEvents(triggerIds, eventClassName, (data) => syncPanZoom(instance.current, data));
    }
    lastTriggers.current = triggerIds;
  }), [triggerIds]);

  useImperativeHandle(ref, () => ({
    zoomIn() {
      if (instance.current) instance.current.zoomIn();
    },
    zoomOut() {
      if (instance.current) instance.current.zoomOut();
    },
    fitToScreen(height) {
      if (instance.current) {
        instance.current.reset();
        instance.current.zoom(height / imageRef.current.height, { animate: true });
      }
    },
    reset() {
      if (instance.current) instance.current.reset();
    },
    imageRef,
  }), [instance]);

  return (
    <>
      <div className={`${eventClassName} pan-zoom-image flex ${className}`}>
        {images.map((image, index) => (
          <img
            src={image}
            key={`${index + 1}`}
            alt=""
            ref={imageRef}
          />
        ))}
      </div>
      {children && children(ref && ref.current ? {
        zoomIn: ref.zoomIn,
        zoomOut: ref.zoomOut,
        reset: ref.reset,
      } : {})}
    </>
  );
});

Panzoom.defaultProps = {
  eventId: 'panzoom-panzoomchange-event',
  triggerIds: [],
  images: [],
  className: '',
  syncPanZoom: false,
  panZoomOptions: {},
};

Panzoom.propTypes = {
  eventClassName: PropTypes.string.isRequired,
  eventId: PropTypes.string,
  triggerIds: PropTypes.arrayOf(PropTypes.string),
  images: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  syncPanZoom: PropTypes.bool,
  panZoomOptions: PropTypes.objectOf(PropTypes.any),
};

export default Panzoom;
