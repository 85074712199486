import React, { useCallback, useEffect } from 'react';
import { Form as AntdForm } from 'antd';
import moment from 'moment';
import Button from '../../../../../components/Button';
import Modals from '../../../../../components/Modal';
import WithLabel from '../../../../../hoc/withLabel';
import { labelPaths } from '../../../../../lib/constants';
import NewBatchPopupForm from '../../../Components/NewBatchPopup/newBatchForm';
import Notification from '../../../../../components/Notification';
import SuccessMessages from '../../../../../lib/successMessages';
import Events from '../../../../../lib/events';
import ErrorMessages from '../../../../../lib/errorMessages';

const EditBatchModal = ({
  labels, isVisible, toggleModal, formData, isUpload,
}) => {
  const [form] = AntdForm.useForm();
  const {
    batchIdentifier, totalBatchAmount, checkCount, depositDate, assignToUserId,
  } = formData;

  useEffect(() => {
    const fileData = [];
    if (formData?.documentUrl) {
      fileData.push({
        uid: '-1',
        name: formData.documentDisplayName,
        status: 'done',
        url: formData.documentUrl,
        type: 'pdf',
      });
    }
    form.setFieldsValue({
      batchIdentifier,
      totalBatchAmount: `${(totalBatchAmount || 0).toFixed(2)}`,
      checkCount: `${checkCount || 0}`,
      assignToUserId,
      depositDate: moment(depositDate?.dateString),
    });
  }, []);

  const onComplete = useCallback(({ response }) => {
    if (response) {
      Notification({
        message:
         SuccessMessages.REMITTANCE_BATCH_UPDATED_SUCCESSFULLY,
        success: true,
      });
      Events.trigger('refetchBatchDetails', { refetchPdf: true });
      toggleModal();
    }
  }, [toggleModal]);

  const onSubmit = useCallback(() => {
    const fileTypeInfo = form.getFieldValue('file')?.[0]?.type;
    if (isUpload) {
      if (!fileTypeInfo) {
        Notification({ message: ErrorMessages.PLEASE_ADD_A_FILE, success: false });
        return;
      }
      if (fileTypeInfo === 'application/pdf') {
        form.submit();
      } else {
        Notification({ message: 'Only pdf files are supported', success: false });
      }
    } else {
      form.submit();
    }
  }, [form, isUpload]);

  return (
    <Modals
      visible={isVisible}
      toggleModal={toggleModal}
      width={400}
      className="create-new-batch"
      footer={[
        <div className="group-btns mng-footer-btn" key="footer">
          <Button
            className="btn min-wt-86 sm-btn"
            onClick={toggleModal}
          >
            {labels.get('buttons.cancel')}
          </Button>
          <Button
            className="btn btn-success min-wt-86 inline sm-btn"
            onClick={onSubmit}
          >
            {labels.get('buttons.updateBatch')}
          </Button>
        </div>,
      ]}
    >
      <div className="addition-header">
        <div className="lookup sprite-img-before">
          <p>{labels.get('titles.editBatch')}</p>
        </div>
      </div>
      <NewBatchPopupForm
        isEdit
        isUpload={isUpload}
        labels={labels}
        form={form}
        formData={formData}
        onComplete={onComplete}
      />
    </Modals>
  );
};

export default WithLabel(EditBatchModal, labelPaths.BATCH_POPUP);
