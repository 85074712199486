import React from 'react';
import get from 'lodash/get';

import TimeCell from '../../../../../../components/Cells/TimeCell';
import Label from '../../../../../../components/Label';
import Form from '../../../../../../components/Form';
import { dateFormatWith12Hour } from '../../../../../../lib/constants';
import { formatPhoneNumber } from '../../../../../../lib/util';

const View = ({
  data, labels, profileName, showResponsibleParty, createdBy, createdOn, modifiedBy, modifiedOn,
}) => (
  <div className="required-feilds">
    <div className="complete-form-wrap insurance-aside-wrap posi-relative">
      <div className="flex justify-content-flex-end align-center" />
      <div className="form-container view-mode">
        <form>
          <div className="feild-row">
            <h2 className="h2-title">{labels.get('titles.profileName')}</h2>
          </div>
          <Form.Section noOfColumns={3}>
            <Form.Column>
              <div className="group-shadow">
                <div className="posi-relative combine-fields">
                  <div md={10}><Label label={labels.get('labels.name')} /></div>
                  <div md={14} className="value-view">{profileName}</div>
                </div>
              </div>
            </Form.Column>
            <Form.Column />
            <Form.Column />
          </Form.Section>
          {showResponsibleParty && (
            <>
              <div className="feild-row">
                <h2 className="h2-title">{labels.get('titles.responsibleParty')}</h2>
              </div>
              <Form.Section noOfColumns={3}>
                <Form.Column>
                  <div className="group-shadow">
                    <div className="posi-relative combine-fields">
                      <div md={10}><Label label={labels.get('labels.firstName')} /></div>
                      <div md={14} className="value-view">{get(data, 'firstName', '')}</div>
                    </div>
                    <div className="posi-relative combine-fields">
                      <div md={10}><Label label={labels.get('labels.lastName')} /></div>
                      <div md={14} className="value-view">{get(data, 'lastName', '')}</div>
                    </div>
                    <div className="posi-relative combine-fields">
                      <div md={10}><Label label={labels.get('labels.addressLine1')} /></div>
                      <div md={14} className="value-view">{get(data, 'addressLine1', '')}</div>
                    </div>
                    <div className="posi-relative combine-fields">
                      <div md={10}><Label label={labels.get('labels.addressLine2')} /></div>
                      <div md={14} className="value-view">{get(data, 'addressLine2', '')}</div>
                    </div>
                    <div className="posi-relative combine-fields">
                      <div md={10}><Label label={labels.get('labels.zip')} /></div>
                      <div md={14} className="value-view">{get(data, 'zip', '')}</div>
                    </div>
                  </div>
                </Form.Column>
                <Form.Column>
                  <div className="group-shadow">
                    <div className="posi-relative combine-fields">
                      <div md={10}><Label label={labels.get('labels.city')} /></div>
                      <div md={14} className="value-view">{get(data, 'city', '')}</div>
                    </div>
                    <div className="posi-relative combine-fields">
                      <div md={10}><Label label={labels.get('labels.state')} /></div>
                      <div md={14} className="value-view">{get(data, 'stateName', '')}</div>
                    </div>
                    <div className="posi-relative combine-fields">
                      <div md={10}><Label label={labels.get('labels.country')} /></div>
                      <div md={14} className="value-view">{get(data, 'countryName', '')}</div>
                    </div>
                    <div className="posi-relative combine-fields">
                      <div md={10}><Label label={labels.get('labels.primaryPhone')} /></div>
                      <div md={14} className="value-view">
                        {formatPhoneNumber(get(data, 'primaryPhone', ''))}
                      </div>
                    </div>
                    <div className="posi-relative combine-fields">
                      <div md={10}><Label label={labels.get('labels.primaryPhoneType')} /></div>
                      <div md={14} className="value-view">{get(data, 'primaryPhoneTypeName', '')}</div>
                    </div>
                  </div>
                </Form.Column>
                <Form.Column>
                  <div className="group-shadow">
                    <div className="posi-relative combine-fields">
                      <div md={10}><Label label={labels.get('labels.secondaryPhone')} /></div>
                      <div md={14} className="value-view">
                        {formatPhoneNumber(get(data, 'secondaryPhone', ''))}
                      </div>
                    </div>
                    <div className="posi-relative combine-fields">
                      <div md={10}><Label label={labels.get('labels.secondaryPhoneType')} /></div>
                      <div md={14} className="value-view">{get(data, 'secondaryPhoneTypeName', '')}</div>
                    </div>
                    <div className="posi-relative combine-fields">
                      <div md={10}><Label label={labels.get('labels.email')} /></div>
                      <div md={14} className="value-view">{get(data, 'emailAddress', '')}</div>
                    </div>
                    <div className="posi-relative combine-fields">
                      <div md={10}><Label label={labels.get('labels.dateOfBirth')} /></div>
                      <div md={14} className="value-view">
                        <TimeCell value={get(data, 'dateOfBirth', '')} />
                      </div>
                    </div>
                    <div className="posi-relative combine-fields">
                      <div md={10}><Label label={labels.get('labels.gender')} /></div>
                      <div md={14} className="value-view">
                        {get(data, 'genderName', '')}
                      </div>
                    </div>
                  </div>
                </Form.Column>
              </Form.Section>
            </>
          )}
          {(createdBy || createdOn || modifiedOn || modifiedBy) ? (
            <>
              <hr className="seperator" />
              <Form.Section noOfColumns={3}>
                <Form.Column>
                  <div className="group-shadow">
                    <div className="posi-relative combine-fields">
                      <div md={10}><Label label={labels.get('labels.createdBy')} /></div>
                      <div md={14} className="value-view">{createdBy}</div>
                    </div>
                  </div>
                </Form.Column>
                <Form.Column>
                  <div className="group-shadow">
                    <div className="posi-relative combine-fields">
                      <div md={10}><Label label={labels.get('labels.createdAt')} /></div>
                      <div md={14} className="value-view"><TimeCell format={dateFormatWith12Hour} value={createdOn} /></div>
                    </div>
                  </div>
                </Form.Column>
                <Form.Column>
                  <div className="group-shadow">
                    <div className="posi-relative combine-fields">
                      <div md={10}><Label label={labels.get('labels.lastModifiedBy')} /></div>
                      <div md={14} className="value-view">{modifiedBy}</div>
                    </div>
                  </div>
                </Form.Column>
                <Form.Column>
                  <div className="group-shadow">
                    <div className="posi-relative combine-fields">
                      <div md={10}><Label label={labels.get('labels.lastModifiedAt')} /></div>
                      <div md={14} className="value-view"><TimeCell format={dateFormatWith12Hour} value={modifiedOn} /></div>
                    </div>
                  </div>
                </Form.Column>
              </Form.Section>
            </>
          ) : <span />}
        </form>
      </div>
    </div>
  </div>
);

export default View;
