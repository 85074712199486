import React, { useMemo } from 'react';
import { apiUrls } from '../../../../api/constants';
import { enums as enumConstant, listIds } from '../../../../lib/constants';
import { Select } from '../../../Enum';
import WiredSelectWithoutTab from '../../../Select/selectBoxV2';
import Input from '../../../../components/Form/Input';
import DatePicker from '../../../../components/Form/DatePicker';
import UserAutoComplete from '../../../UserAutoComplete';

const TaskLeftSection = ({
  labels,
  onCategoryChange,
  subjectId,
  onDosChange,
  form,
  taskId,
  formData,
  handleDueDateChange,
  dueDateDisableFunc,
  onChangeSubCategory,
  assignedToUserInitialValue,
}) => {
  const getInitialValue = useMemo(() => {
    if (taskId && formData?.assignedTo && formData?.assignedToName) {
      return { name: formData?.assignedToName, value: formData?.assignedTo };
    } if (assignedToUserInitialValue) {
      return {
        ...assignedToUserInitialValue,
        name: assignedToUserInitialValue?.defaultAssigneeName,
        value: assignedToUserInitialValue?.defaultAssigneeId,
      };
    }
    return null;
  },
  [assignedToUserInitialValue, formData, taskId]);

  return (
    <div className="group-shadow tasks-modal-form-sections">
      <WiredSelectWithoutTab
        id={listIds.TASK_CATEGORY_ID}
        label={labels.get('labels.subject')}
        name="categoryId"
        url={apiUrls.GET_TASK_MANAGEMENT_CATEGORY_WITH_COUNT}
        nameAccessor="name"
        valueAccessor="taskCategoryId"
        startCaseOptions
        labelSpan={10}
        inputSpan={14}
        isStatCaseOptions={false}
        selectProps={{
          showArrow: true,
          dropdownMatchSelectWidth: false,
        }}
        placeholder="Select category"
        onChange={onCategoryChange}
        required
      />
      <Select
        label={labels.get('labels.subCategory')}
        parentId={subjectId?.value}
        name="subCategoryId"
        enumName={enumConstant.TASK_SUB_CATEGORIES}
        onChange={onChangeSubCategory}
        refetchOnEachRender
        required={(subjectId?.name?.toLowerCase() === 'prescription refill')
    || (subjectId?.name?.toLowerCase() === 'authorization')}
      />
      <Input.TextArea
        label={labels.get('labels.description')}
        name="taskDescription"
        autoSize={{ minRows: 2, maxRows: 4 }}
        placeholder="Enter Description"
      />
      <DatePicker
        label={labels.get('labels.dos')}
        name="dos"
        onChange={onDosChange}
      />
      <UserAutoComplete
        name="assignedTo"
        label={labels.get('labels.assignedTo')}
        form={form}
        minCharLength={3}
        labelSpan={10}
        inputSpan={14}
        header={false}
        initialValue={getInitialValue}
        required
        dataTestId="assignedTo"
        placeholder="Search"
      />
      <DatePicker
        label={labels.get('labels.dueDate')}
        name="dueDate"
        required
        onChange={handleDueDateChange}
        disabledDate={dueDateDisableFunc}
      />
      <Select
        label={labels.get('labels.priority')}
        name="taskPriorityId"
        enumName={enumConstant.TASK_PRIORITY_IDS}
        disabled
        required
      />
      <Select
        label={labels.get('labels.status')}
        name="taskStatusId"
        enumName={enumConstant.TASK_STATUS_IDS}
        required
      />
    </div>
  );
};
export default TaskLeftSection;
