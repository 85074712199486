// IntelligentDx
// Created: 14/4/2023
// Updated: 14/4/2023
// Purpose: Render Contract details in payer page
import React from 'react';
import Table from '../../../../../components/Table';
import Columns from './Columns';

const ContractList = ({ data }) => (
  <Table
    data={data}
    columns={Columns()}
    noDataText="No Contracts With the Payer"
  />
);

export default ContractList;
