import React, {
  useMemo, useCallback, useState, useRef, useEffect,
} from 'react';

import moment from 'moment';
import { DatePicker } from 'antd';

import EventWrapper from '../../../../components/EventWrapper';
import WidgetLoader from '../../../../components/WidgetLoader';

import useCRUD from '../../../../hooks/useCRUD';

import { apiUrls } from '../../../../api/constants';
import dateFormatter from '../../../../lib/dateFormatter';
import { dateFormatWithSlash, listIds } from '../../../../lib/constants';

import './widgets.scss';

const { RangePicker } = DatePicker;

const dateRanges = {
  thisDay: [moment().startOf('day'), moment().endOf('day')],
  lastWeek: [
    moment().day('Sunday').subtract(7, 'days').startOf('day'),
    moment().day('Saturday').subtract(7, 'days').endOf('day')],
  thisWeek: [moment().day('Sunday').startOf('day'), moment().day('Saturday').endOf('day')],
  nextWeek: [
    moment().day('Sunday').add(7, 'days').startOf('day'),
    moment().day('Saturday').add(7, 'days').endOf('day')],
  lastMonth: [
    moment().subtract(1, 'month').startOf('month'),
    moment().subtract(1, 'month').endOf('month')],
  thisMonth: [moment().startOf('month'), moment().endOf('month')],
  nextMonth: [
    moment().add(1, 'month').startOf('month'),
    moment().add(1, 'month').endOf('month')],
};

const humanizeDateRange = (labels, dates = []) => {
  if (Array.isArray(dates) && dates.length > 1) {
    let dateString;
    const unixDates = [moment(dates[0]).unix(), moment(dates[1]).unix()];
    Object.keys(dateRanges).every((unit) => {
      const [from, to] = dateRanges[unit];
      if (moment(from).unix() === unixDates[0] && moment(to).unix() === unixDates[1]) {
        dateString = unit;
        return false;
      }
      return true;
    });
    if (dateString) return labels.get(`dateStrings.${dateString}`);
    const duration = moment.duration(moment(dates[1]).diff(moment(dates[0]))).humanize();
    return duration[0].toUpperCase() + duration.slice(1);
  }
  return '';
};

const Widgets = ({
  labels, selectedDoctors, location, onDoctorSelect, onLocationSelect, filterSetter, initialFilters,
}) => {
  const datePickerRef = useRef();

  const [pickerStatus, togglePicker] = useState(false);
  const [appointmentCount, setAppointmentCount] = useState({
    appointmentsCancelled: 0,
    appointmentsCompleted: 0,
    appointmentsDue: 0,
    totalAppointments: 0,
  });
  const [dateRange, setDateRange] = useState([]);

  const [
    appointmentResponse,,
    appointmentLoading,
    getAppointment,
    clearAppointment] = useCRUD({
    id: 'dashboard-appointment-count', url: apiUrls.APPOINTMENT_COUNT, type: 'read',
  });
  const [updateAppointmentStatusResponse] = useCRUD({
    id: listIds.SCHEDULER_UPDATE_STATUS, url: apiUrls.SCHEDULER_UPDATE_APPOINTMENT_STATUS, type: 'update',
  });

  useEffect(() => {
    if (appointmentResponse) {
      setAppointmentCount(appointmentResponse);
      clearAppointment(true);
    }
  }, [appointmentResponse]);

  const openPicker = useCallback(() => {
    if (!pickerStatus) {
      togglePicker(true);
      datePickerRef.current.focus();
    }
  }, [pickerStatus]);

  const closePicker = useCallback((status) => {
    if (pickerStatus && !status) togglePicker(false);
  }, [pickerStatus]);

  const onDateSelect = useCallback((dates = []) => {
    if (Array.isArray(dates) && dates.length > 1) {
      const normalizedDates = [
        moment(dates[0]).startOf('day'),
        moment(dates[1]).endOf('day'),
      ];
      setDateRange(normalizedDates);
      filterSetter('appointmentDate', normalizedDates);
    }
  }, [filterSetter]);

  const getCountData = useCallback(() => {
    let queryString = '';
    const startDate = dateFormatter(dateRange[0], dateFormatWithSlash);
    const endDate = dateFormatter(dateRange[1], dateFormatWithSlash);
    queryString = `?AppointmentFilterStartDate=${startDate}&AppointmentFilterEndDate=${endDate}`;
    if (selectedDoctors && selectedDoctors.length) {
      selectedDoctors.forEach((providerId) => {
        queryString += `&ProviderId=${providerId}`;
      });
    }
    if (location) queryString += `&LocationId=${location}`;
    getAppointment({}, queryString);
  }, [dateRange, getAppointment, location, selectedDoctors]);

  useEffect(() => {
    if (dateRange && dateRange.length) {
      getCountData();
    }
  }, [dateRange, getCountData]);

  useEffect(() => {
    if (updateAppointmentStatusResponse) {
      getCountData();
    }
  }, [updateAppointmentStatusResponse, getCountData]);

  useEffect(() => {
    const { location: appointmentLocation, doctor, appointmentDate } = initialFilters || {};
    setDateRange(appointmentDate && appointmentDate.length
      ? appointmentDate.map((date) => moment.unix(date))
      : dateRanges.thisWeek);
    if (doctor?.length) {
      onDoctorSelect(doctor);
    }
    if (appointmentLocation) {
      onLocationSelect(appointmentLocation);
    }
  }, []);

  const humanizedDateRange = useMemo(
    () => humanizeDateRange(labels, dateRange),
    [labels, dateRange],
  );

  const rangesPreset = useMemo(() => {
    const ranges = {};
    Object.keys(dateRanges).forEach((unit) => {
      ranges[labels.get(`dateStrings.${unit}`)] = dateRanges[unit];
    });
    return ranges;
  }, [labels]);

  return (
    <div className="dashboard-widgets-container posi-relative">
      {appointmentLoading && <WidgetLoader />}
      <EventWrapper className="component cursor-pointer" onClick={openPicker}>
        <div className="widget-icon week-icon" />
        <div className="info-area">
          <div className="heading">{humanizedDateRange}</div>
          <RangePicker
            ref={datePickerRef}
            open={pickerStatus}
            dropdownClassName="dashboard-datepicker-dropdown"
            onOpenChange={closePicker}
            bordered={false}
            allowClear={false}
            suffixIcon={null}
            placeholder={['Start', 'End']}
            format="MMM D"
            separator="-"
            inputReadOnly
            value={dateRange}
            onChange={onDateSelect}
            ranges={rangesPreset}
          />
        </div>
      </EventWrapper>
      <div className="component">
        <div className="widget-icon appointment-icon" />
        <div className="info-area">
          <div className="heading">{labels.get('labels.appointments')}</div>
          <div className="data">{appointmentCount?.totalAppointments}</div>
        </div>
      </div>
      <div className="component">
        <div className="widget-icon due-icon" />
        <div className="info-area">
          <div className="heading">{labels.get('labels.upcoming')}</div>
          <div className="data">{appointmentCount?.appointmentsDue}</div>
        </div>
      </div>
      <div className="component">
        <div className="widget-icon complete-icon" />
        <div className="info-area">
          <div className="heading">{labels.get('labels.completed')}</div>
          <div className="data">{appointmentCount?.appointmentsCompleted}</div>
        </div>
      </div>
      <div className="component">
        <div className="widget-icon cancel-icon" />
        <div className="info-area">
          <div className="heading">{labels.get('labels.cancelled')}</div>
          <div className="data">{appointmentCount?.appointmentsCancelled}</div>
        </div>
      </div>
    </div>
  );
};

export default Widgets;
