import React, { useState, useCallback, useEffect } from 'react';

import { apiUrls } from '../../../../api/constants';
import { listId, labelPaths, UiRoutes } from '../../../../lib/constants';
import SuccessMessages from '../../../../lib/successMessages';

import WithLabel from '../../../../hoc/withLabel';
import withQuery from '../../../../hoc/withQuery/withQuery';
import withReduxManager from '../../../../hoc/withReduxManager';
import useCRUD from '../../../../hooks/useCRUD';
import useRedirect from '../../../../hooks/useRedirect';

import Button from '../../../../components/Button';
import Notification from '../../../../components/Notification';
import ConfirmModal from '../../../../components/ConfirmDialog';
import TableWrapper from '../../../../components/Table/TableWrapper';

import columns from './columns';
import NewTemplateModal from '../Components/NewTemplateModal';
import EditTemplateModal from '../Components/EditTemplateModal';

const hiddenColumns = ['edit', 'delete'];

const CustomComposerTable = withReduxManager({
  listId: listId.COMPOSER_LIST,
})(
  withQuery({
    url: apiUrls.COMPOSER_LIST,
    listId: listId.COMPOSER_LIST,
  })(),
);

const ComposerTable = ({ labels, isLetterTemplateAuthenticated, ...props }) => {
  const [isVisible, setVisibility] = useState(false);
  const [isEditModalVisible, setEditModalVisibility] = useState(false);
  const [editTemplateId, setTemplateId] = useState(null);
  const [reFetchTable, setReFetch] = useState(null);

  const { replace, generatePath } = useRedirect();

  const [deleteResponse, ,, deleteTemplate, clearResponse] = useCRUD({
    id: 'composer-template-delete',
    url: apiUrls.DELETE_COMPOSER_TEMPLATE,
    type: 'delete',
  });

  useEffect(() => {
    if (deleteResponse) {
      Notification({ message: SuccessMessages.TEMPLATE_DELETED_SUCCESSFULLY, success: true });
      reFetchTable();
      clearResponse();
    }
  }, [clearResponse, deleteResponse, reFetchTable]);

  const toggleEditModal = useCallback(() => {
    setEditModalVisibility(!isEditModalVisible);
  }, [isEditModalVisible]);

  const toggleModal = useCallback(() => {
    setVisibility(!isVisible);
  }, [isVisible]);

  const handleEditClick = useCallback((editComposerTemplateId) => {
    setTemplateId(editComposerTemplateId);
    toggleEditModal();
  }, [toggleEditModal]);

  const handleDelete = useCallback((editComposerTemplateId) => {
    ConfirmModal({
      title: 'Are you sure you want to delete this Template ?',
      onOk: () => deleteTemplate({}, `/${editComposerTemplateId}`),
    })();
  },
  [deleteTemplate]);

  const onRowClick = useCallback((original) => () => {
    replace(generatePath(UiRoutes.editComposerTemplate,
      { templateId: original?.composerTemplateId }));
  }, [generatePath, replace]);

  return (
    <>
      <CustomComposerTable
        columns={columns(labels, handleEditClick, handleDelete, onRowClick)}
        noDataText="Composer not found"
        isRowClickable={false}
        hiddenColumns={!isLetterTemplateAuthenticated ? hiddenColumns : []}
        {...props}
      >
        {({
          Component, totalCount, reFetch,
        }) => (
          <>
            <h2>{labels.get('titles.composer')}</h2>
            <div className="table-filters mr-bt-20 mr-top-20">
              {isLetterTemplateAuthenticated && (
                <Button className="btn btn-success min-wt-86" onClick={toggleModal}>{labels.get('buttons.newTemplate')}</Button>
              )}
              <span className="table-count">
                <span>{`${labels.get('titles.totalCount')}: ${totalCount}`}</span>
              </span>
            </div>
            <TableWrapper
              Component={Component}
              reFetch={reFetch}
              setReFetch={setReFetch}
            />
          </>
        )}
      </CustomComposerTable>
      <NewTemplateModal
        labels={labels}
        toggleModal={toggleModal}
        isVisible={isVisible}
      />
      <EditTemplateModal
        labels={labels}
        toggleModal={toggleEditModal}
        isVisible={isEditModalVisible}
        editTemplateId={editTemplateId}
        reFetchTable={reFetchTable}
      />
    </>
  );
};

export default WithLabel(ComposerTable, labelPaths.SETTINGS_COMPOSER);
