import React from 'react';

import StatusCell from '../../../../../components/StatusCell';

const columns = (labels) => [
  {
    Header: labels.get('tableColumns.name'),
    accessor: 'name',
    Cell: ({ navigateToEditExamRoom, row: { original: { name, examRoomId, id } } }) => (
      <span className="cursor-pointer" role="presentation" onClick={() => { navigateToEditExamRoom(examRoomId || id); }}>
        {name}
      </span>
    ),
    sort: true,
  },
  {
    Header: labels.get('tableColumns.description'),
    accessor: 'description',
  },
  {
    Header: labels.get('tableColumns.status'),
    accessor: 'active',
    Cell: (e) => <StatusCell active={e.row.original.isActive} />,
  },
];

export default columns;
