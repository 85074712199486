import React from 'react';
import { Tooltip } from 'antd';
import TimeCell from '../../../../../../components/Cells/TimeCell';
import { dateFormat } from '../../../../../../lib/constants';

export const initialColumns = (labels) => [
  {
    Header: labels.get('tableColumns.effectiveDate'),
    accessor: 'effectiveDate',
    Cell: ({ row: { original: { effectiveDate } } }) => (
      <TimeCell value={effectiveDate} format={dateFormat} />
    ),
    className: 'text-align-left',
  },
  {
    Header: labels.get('tableColumns.type'),
    accessor: 'transactionType',
    className: 'text-align-left',
  },
  {
    Header: labels.get('tableColumns.description'),
    accessor: 'description',
    className: 'text-align-left',
    Cell: ({ row: { original: { description } } }) => <Tooltip placement="topLeft" title={description}>{description}</Tooltip>,
  },
];

const CommonColumns = (labels) => [
  ...initialColumns(labels),
  {
    Header: labels.get('tableColumns.approved'),
    accessor: 'approved',
    Cell: ({ row: { original: { approved } } }) => (
      parseFloat(approved || 0).toFixed(2)
    ),
    className: 'text-align-right',
    fixWidth: '80',
  },
  {
    Header: labels.get('tableColumns.coInsurance'),
    accessor: 'coInsurance',
    Cell: ({ row: { original: { coInsurance } } }) => (
      parseFloat(coInsurance || 0).toFixed(2)
    ),
    className: 'text-align-right',
    fixWidth: '100',
  },
  {
    Header: labels.get('tableColumns.coPayment'),
    accessor: 'coPay',
    Cell: ({ row: { original: { coPay } } }) => (
      parseFloat(coPay || 0).toFixed(2)
    ),
    className: 'text-align-right',
    fixWidth: '100',
  },
  {
    Header: labels.get('tableColumns.deductible'),
    accessor: 'deductible',
    Cell: ({ row: { original: { deductible } } }) => (
      parseFloat(deductible || 0).toFixed(2)
    ),
    className: 'text-align-right',
    fixWidth: '80',
  },
  {
    Header: labels.get('tableColumns.paid'),
    accessor: 'paid',
    Cell: ({ row: { original: { paid } } }) => (
      parseFloat(paid || 0).toFixed(2)
    ),
    className: 'text-align-right',
    fixWidth: '80',
  },
];

export default CommonColumns;
