import React from 'react';
import Button from '../../components/Button';
import Modals from '../../components/Modal';

const SessionModal = ({ isVisible, toggleModal, count }) => (
  <Modals
    visible={isVisible}
    toggleModal={toggleModal}
    destroyOnClose
    title="Session Expire"
    width={500}
    footer={[
      <div className="group-btns mng-footer-btn" key="footer">
        <Button className="btn sm-btn btn-success min-wt-86" data-testid="session-ok" onClick={toggleModal}>Ok</Button>
      </div>,
    ]}
  >
    <>
      <div>
        {`Your session is about to expire in ${count} seconds.`}
      </div>
      <div>Please click on ok button to stay logged in</div>
    </>
  </Modals>
);
export default SessionModal;
