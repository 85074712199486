import React, { useCallback } from 'react';
import { Form as AntdForm } from 'antd';

import Form from '../../../../components/Form';
import Button from '../../../../components/Button';
import Notification from '../../../../components/Notification';
import useRedirect from '../../../../hooks/useRedirect';
import WithLabel from '../../../../hoc/withLabel';

import {
  UiRoutes, labelPaths, listIds, formId,
} from '../../../../lib/constants';
import SuccessMessages from '../../../../lib/successMessages';
import formFieldValueParser from '../../../../lib/formFieldValuesParser';

import { apiUrls } from '../../../../api/constants';

import ExamroomForm from '../../components/ExamroomForm';

function AddExamRoom({
  parentParams,
  labels,
}) {
  const { push, generatePath, params } = useRedirect();
  const { id } = params;
  const [form] = AntdForm.useForm();

  const goBack = useCallback(() => {
    push(generatePath(UiRoutes.editLocationWithTabId, { ...parentParams }));
  }, [push, generatePath, parentParams]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.EXAM_ROOM_SAVED_SUCCESSFULLY, success: true });
      goBack();
    }
  }, [goBack]);

  const parser = useCallback((values) => formFieldValueParser(values, {
    bool: [
      'isActive',
    ],
  }), []);

  return (
    <Form
      form={form}
      noOfColumns={2}
      onRequestComplete={onRequestComplete}
      formId={formId.ADD_EXAM_ROOM}
      listId={listIds.LOCATION_EXAMROOM_LIST}
      parser={parser}
      initialData={{ isActive: false, locationId: id }}
      url={apiUrls.Add_EXAMROOM}
    >
      <div className="pannel back-pannel">
        <div className="pannel-heading">
          <p role="presentation" className="back-arrow" onClick={goBack} id="locations_exam_room_back">{labels.get('buttons.goBack')}</p>
        </div>
        <div className="pannel-body">
          <ExamroomForm
            form={form}
            labels={labels}
            goBack={goBack}
          />
          <div className="form-btns">
            <Button className="btn btn-success" type="primary" htmlType="submit" id="locations_exam_room_save">{labels.get('buttons.save')}</Button>
            <Button className="btn btn-ghost" onClick={goBack} id="locations_exam_room_cancel">{labels.get('buttons.cancel')}</Button>
          </div>
        </div>
      </div>
    </Form>
  );
}

export default WithLabel(AddExamRoom, labelPaths.EDIT_LOCATION_ADD_EXAMROOM);
