import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import { getEnumMaster, getEnumOptions } from '../store/selectors';
import { getEnumMasterData } from '../store/actions/enum';
import { enums } from '../lib/constants';

const useAnesthesiaCptCode = () => {
  const dispatch = useDispatch();
  const enumMaster = useSelector((state) => getEnumMaster(state));
  const enumId = get(enumMaster, `${enums.MASTER_CONFIG}.enumId`);
  const enumOptions = useSelector((state) => getEnumOptions(state)?.[enumId]?.data || []);

  useEffect(() => {
    if (!enumOptions.length && enumId) {
      dispatch(getEnumMasterData(enumId));
    }
  }, [enumId]);

  const anesthesiaCPTCode = useMemo(() => {
    const anesthesiaCptCodeMasterData = enumOptions?.find(({ masterCode }) => masterCode === 'AnesthesiaCPTCode')?.masterDescription;
    return anesthesiaCptCodeMasterData ? anesthesiaCptCodeMasterData.split(',') : [];
  }, [enumOptions]);

  return anesthesiaCPTCode;
};

export default useAnesthesiaCptCode;
