import React from 'react';

import withQuery from '../../hoc/withQuery/withQuery';
import withReduxManager from '../../hoc/withReduxManager';

import { apiUrls } from '../../api/constants';
import { listIds } from '../../lib/constants';
import TableWrapper from './TableWrapper';

const initialSort = [{ id: 'effectiveDateFrom', desc: true }];

const CustomTable = withReduxManager({
  listId: listIds.AUTHORIZATION_LIST,
  pageSize: 30,
})(withQuery({
  url: apiUrls.GET_AUTHORIZATION_LIST,
  listId: listIds.AUTHORIZATION_LIST,
})());

const AuthorizationTable = ({
  labels,
  navigateToEditAuthorization,
  patientId,
  columns,
  scrollId,
  className,
  onRowClick,
}) => (
  <CustomTable
    initialSort={initialSort}
    filters={{ PatientId: patientId }}
    navigateToEditAuthorization={navigateToEditAuthorization}
    columns={columns(labels)}
    scrollId={scrollId}
    onRowClick={onRowClick}
  >
    {({
      Component, totalCount, reFetch,
    }) => (
      <>
        <div className="table-filters">
          <span />
          <span className="table-count">
            <span>{`${labels.get('labels.totalCount')}: ${totalCount}`}</span>
          </span>
        </div>
        <div className={className}>
          <TableWrapper
            Component={Component}
            reFetch={reFetch}
          />
        </div>
      </>
    )}
  </CustomTable>
);

export default AuthorizationTable;
