import { enumDataIds } from '../../../../../../../../lib/constants';

const numberRegex = /^($|\d+)(\.\d*)?$/;
const modifierNumRegex = /^($|\d{1,5}|\d{0,5}\.|\d{0,5}\.\d{1,2})$/;

export const validator = (data) => {
  const error = {};
  if (!data.procedureFromId) error.procedureFrom = 'Procedure is required';
  if (data.isRange && !data.procedureToId) error.procedureTo = 'Procedure is required';
  if (!data.typeId) error.typeId = 'Type is required';
  if (data.typeValueTypeName === 'Multiplier' && parseInt(data.typeValue, 10) === 0) {
    error.multiplier = 'Value is required';
  }
  if (data.typeId === enumDataIds.TERMS_EXCEPTION_TYPE_ID) {
    if (!data.typeValue && data.typeValue !== 0) error.typeValue = 'Value is required';
    if (!data.typeValueTypeId) error.typeValueTypeId = 'Value Type is required';
  }
  if (Object.values(error).length) return error;
  return {};
};

export const numberCheck = (value, prevValue, isModifier) => {
  const regexToCheck = isModifier ? modifierNumRegex : numberRegex;
  if (regexToCheck.test(value)) return value;
  if (regexToCheck.test(prevValue) && !regexToCheck.test(value)) return prevValue;
  return '';
};

export const onChangeData = ({
  label, event, item = {}, exception, dataError,
}) => {
  const exceptionData = { ...exception };
  const resetError = { ...dataError };

  switch (label) {
    case 'typeId': {
      exceptionData.typeId = event;
      exceptionData.typeName = item?.children;
      if (event !== enumDataIds.TERMS_EXCEPTION_TYPE_ID) {
        delete exceptionData.typeValueTypeId;
        delete exceptionData.typeValueTypeName;
        delete resetError.typeValueTypeId;
      }
      if (event === enumDataIds.TERMS_EXCEPTION_TYPE_ID) {
        exceptionData.typeValueTypeId = enumDataIds.TERMS_EXCEPTION_VALUE_TYPE_ID_AMOUNT;
        exceptionData.typeValueTypeName = 'Amount';
      }
      if (dataError.typeId) resetError.typeId = false;
      break;
    }
    case 'typeValue': {
      exceptionData.typeValue = numberCheck(event.target.value, exception.typeValue);
      if (dataError.typeValue) resetError.typeValue = false;
      break;
    }
    case 'modifier': {
      exceptionData.modifier = event?.modifierId;
      exceptionData.modifierCode = event?.modifierCode;
      if (dataError.modifier) resetError.modifier = false;
      break;
    }
    case 'isRange': {
      exceptionData.isRange = event.target.checked;
      if (!event.target.checked) {
        delete exceptionData.procedureToId;
        delete exceptionData.procedureToCode;
        delete exceptionData.procedureToDescription;
        delete resetError.procedureTo;
      }
      break;
    }
    case 'typeValueTypeId': {
      exceptionData.typeValueTypeId = event;
      exceptionData.typeValueTypeName = item?.children;
      if (dataError.typeValueTypeId) resetError.typeValueTypeId = false;
      break;
    }
    case 'procedureFrom': {
      const { cptCode, description, id } = event || {};
      exceptionData.procedureFromId = id;
      exceptionData.procedureFromCode = cptCode;
      exceptionData.procedureFromDescription = description;
      if (dataError.procedureFrom) resetError.procedureFrom = false;
      break;
    }
    case 'procedureTo': {
      const { cptCode, description, id } = event || {};
      exceptionData.procedureToId = id;
      exceptionData.procedureToCode = cptCode;
      exceptionData.procedureToDescription = description;
      if (dataError.procedureTo) resetError.procedureTo = false;
      break;
    }
    case 'feeSchedule': {
      exceptionData.feeScheduleTypeId = event;
      exceptionData.feeScheduleTypeName = item?.children;
      if (dataError.feeScheduleTypeId) resetError.feeScheduleTypeId = false;
      break;
    }
    default: return null;
  }
  return {
    exceptionData,
    resetError,
  };
};

export const setFocusToFields = (error, parentFocusId) => {
  if (error?.procedureFrom) {
    const node = document.getElementById(`${parentFocusId}_cpt_search`);
    if (node) node.focus();
  } else if (error?.procedureTo) {
    const node = document.getElementById(`${parentFocusId}_to_cpt_search`);
    if (node) node.focus();
  } else if (error?.typeValue) {
    const node = document.getElementById(`${parentFocusId}_type`);
    if (node) node.focus();
  }
};
