import React, { useCallback, useEffect } from 'react';
import { Tooltip, Form as AntdForm } from 'antd';
import { useDispatch, connect } from 'react-redux';

import get from 'lodash/get';

import Form from '../../../../../components/Form';
import Input from '../../../../../components/Form/Input';
import EventWrapper from '../../../../../components/EventWrapper';

import useCRUD from '../../../../../hooks/useCRUD';
import useMasterId from '../../../../../hooks/useMasterId';

import * as selectors from '../../../../../store/selectors';
import { getEnumMasterData } from '../../../../../store/actions/enum';

import { apiUrls } from '../../../../../api/constants';
import dateFormatter from '../../../../../lib/dateFormatter';
import { enumMasterCode, enums } from '../../../../../lib/constants';

import EnumSelect from '../../../../../wiredComponents/Enum/Select';
import PersonAutoComplete from '../../../../../wiredComponents/PersonAutoComplete';
import PersonPopup from '../../../../../wiredComponents/Modal/Person';

import PersonDetailForm from './PersonDetailForm';
import useReduxState from '../../../../../hooks/useReduxState';

const masterCodes = [
  enumMasterCode.policyTypeVision,
];

const enumNames = [
  enums.POLICY_TYPE,
];

const personInitialData = (person) => {
  if (person && Object.values(person).length) {
    const name = `${person.firstName} ${person.lastName}`;
    return {
      ...person,
      name,
      value: name,
      personDateOfBirth: dateFormatter(person.dateOfBirth),
      address: `${person.addressLine1 || ''} ${person.addressLine2 || ''}`,
    };
  }
  return false;
};

const isRelationSelf = (options = [], selectedValue) => {
  if (options.length) {
    const isExist = options.find((item) => (item.masterId === selectedValue)
    && item.masterCode === enumMasterCode.InsuranceRelationSelf);
    return !!isExist;
  }
  return true;
};
const PersonForm = ({
  form,
  labels,
  person,
  onPersonSelect,
  formFieldPrefix,
  enumMaster,
  isPolicyHolder,
  profileName,
}) => {
  const enumId = get(enumMaster, `${enums.RELATION}.enumId`);

  const dispatch = useDispatch();

  const crudReadId = `${formFieldPrefix}person-lookup`;
  const [personPopupVisible, setPersonPopupVisibility] = useReduxState('person-form-personPopupVisible', false);
  const [addPersonPopupVisible, setAddPersonPopupVisible] = useReduxState('person-form-addPersonPopupVisible', false);
  const [isRequiredFields, setRequiredFields] = useReduxState('person-form-isRequiredFields', false);
  const masterCodesWithId = useMasterId(masterCodes, enumNames);

  const getPersonData = useCRUD({
    id: crudReadId,
    url: apiUrls.GET_RESPONSIBLE_PARTY_BY_ID,
    type: 'read',
  })[3];

  const togglePersonPopupVisibility = useCallback(() => {
    setRequiredFields(form.getFieldValue('policyTypeId') === masterCodesWithId?.vision || profileName === 'Vision' || form.getFieldValue('rp-profileCategoryName') === 'Vision');
    setAddPersonPopupVisible(false);
    setPersonPopupVisibility(!personPopupVisible);
  }, [form, masterCodesWithId, personPopupVisible, profileName,
    setAddPersonPopupVisible, setPersonPopupVisibility, setRequiredFields]);

  const toggleAddPersonPopupVisible = useCallback(() => {
    setAddPersonPopupVisible(true);
    setPersonPopupVisibility(!personPopupVisible);
    setRequiredFields(form.getFieldValue('policyTypeId') === masterCodesWithId?.vision || profileName === 'Vision' || form.getFieldValue('rp-profileCategoryName') === 'Vision');
  }, [form, masterCodesWithId, personPopupVisible, profileName,
    setAddPersonPopupVisible, setPersonPopupVisibility, setRequiredFields]);

  const onRelationChange = useCallback(() => {
    form.setFieldsValue({ [`${formFieldPrefix}personName`]: '' });
    onPersonSelect({});
  }, [onPersonSelect, form, formFieldPrefix]);

  const handleSetPerson = useCallback((personId) => {
    getPersonData({ PersonId: personId });
  }, [getPersonData]);

  useEffect(() => {
    if (enumId) {
      dispatch(getEnumMasterData(enumId));
    }
  }, [enumId]);

  return (
    <div className="form-container">
      <Form.Section noOfColumns={3}>
        <Form.Column>
          <div className="mr-bt-16">
            <div className="group-shadow">
              <EnumSelect
                label={labels.get('labels.relation')}
                name={`${formFieldPrefix}responsibleRelationId`}
                required={!isPolicyHolder}
                inputSpan="14"
                labelSpan="9"
                enumName={enums.RELATION}
                onChange={onRelationChange}
                form={form}
              />
            </div>
          </div>
        </Form.Column>
        <Form.Column>
          <AntdForm.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => (
              prevValues[`${formFieldPrefix}responsibleRelationId`] !== currentValues[`${formFieldPrefix}responsibleRelationId`])}
          >
            {({ getFieldInstance, getFieldValue }) => !isRelationSelf(getFieldInstance(`${formFieldPrefix}responsibleRelationId`)?.props?.enumData, getFieldValue(`${formFieldPrefix}responsibleRelationId`))
             && (
             <div className="mr-bt-16">
               <div className="group-shadow">
                 <div className="payer-wrapper with-edit-option">
                   <div className="add-input">
                     <PersonAutoComplete
                       labels={labels}
                       form={form}
                       required
                       inputSpan="15"
                       labelSpan="9"
                       formFieldPrefix={formFieldPrefix}
                       initialValue={personInitialData(person)}
                     />
                     <Tooltip title={labels.get('titles.addPerson')}>
                       <EventWrapper
                         type="span"
                         className="add-icon sprite-img-before"
                         onClick={toggleAddPersonPopupVisible}
                       />
                     </Tooltip>
                     <AntdForm.Item
                       noStyle
                       shouldUpdate={(prevValues, currentValues) => (
                         prevValues[`${formFieldPrefix}personId`] !== currentValues[`${formFieldPrefix}personId`])}
                     >
                       {({ getFieldValue: nestedGetFieldValue }) => (!nestedGetFieldValue(`${formFieldPrefix}type`) || (nestedGetFieldValue(`${formFieldPrefix}type`)?.toLowerCase() === 'person')) && (
                         <EventWrapper
                           type="span"
                           className="edit-icon"
                           onClick={togglePersonPopupVisibility}
                         />
                       )}
                     </AntdForm.Item>
                   </div>
                 </div>
               </div>
             </div>
             )}
          </AntdForm.Item>
        </Form.Column>
        <Form.Column />
      </Form.Section>
      <AntdForm.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => (
          prevValues[`${formFieldPrefix}personName`] !== currentValues[`${formFieldPrefix}personName`]
          || prevValues[`${formFieldPrefix}personId`] !== currentValues[`${formFieldPrefix}personId`]
        )}
      >
        {({ getFieldValue, getFieldInstance }) => !isRelationSelf(getFieldInstance(`${formFieldPrefix}responsibleRelationId`)?.props?.enumData, getFieldValue(`${formFieldPrefix}responsibleRelationId`)) && getFieldValue(`${formFieldPrefix}personName`) && (
        <Form.Section noOfColumns={3}>
          <div style={{ display: 'none' }}>
            <Input name={`${formFieldPrefix}personId`} />
            <Input name={`${formFieldPrefix}type`} />
          </div>
          <PersonDetailForm
            form={form}
            formFieldPrefix={formFieldPrefix}
            labels={labels}
            personId={getFieldValue(`${formFieldPrefix}personId`)}
            personType={getFieldValue(`${formFieldPrefix}type`)}
          />
        </Form.Section>
        )}
      </AntdForm.Item>
      {personPopupVisible && (
        <PersonPopup
          id={form.getFieldValue(`${formFieldPrefix}personId`)}
          isUpdate={form.getFieldValue(`${formFieldPrefix}personId`) && !addPersonPopupVisible}
          visibility={personPopupVisible}
          setVisibility={setPersonPopupVisibility}
          formFieldPrefix={formFieldPrefix}
          setPersonId={handleSetPerson}
          isPolicyHolder={isPolicyHolder}
          isRequiredFields={isRequiredFields}
        />
      )}
    </div>
  );
};

PersonForm.defaultProps = {
  person: {},
  isUpdate: false,
  onRelationSelect: () => { /* This is intentional */ },
  onPersonSelect: () => { /* This is intentional */ },
  formFieldPrefix: '',
  relationName: '',
};

export default connect((state) => ({
  enumOptions: selectors.getEnumOptions(state),
  enumMaster: selectors.getEnumMaster(state),
}))(PersonForm);
