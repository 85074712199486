
export const getURLFromLocalStorage = (providerId, patientId, name) => {
  const data = JSON.parse(localStorage.getDecryptedData(name) || '{}');
  const parsedProviderId = parseInt(providerId, 10);
  const parsedPatientId = parseInt(patientId, 10);
  return data && data[parsedProviderId] && data[parsedProviderId][parsedPatientId];
};

export const setURLToLocalStorage = (providerId, patientId, item, name) => {
  const data = JSON.parse(localStorage.getDecryptedData(name) || '{}');
  const parsedProviderId = parseInt(providerId, 10);
  const parsedPatientId = parseInt(patientId, 10);
  if (Object.keys(data).length) {
    const providerData = data && data[parsedProviderId];
    if (providerData) {
      data[parsedProviderId][parsedPatientId] = item;
    } else {
      data[parsedProviderId] = { [parsedPatientId]: item };
    }
  } else {
    data[parsedProviderId] = { [parsedPatientId]: item };
  }
  localStorage.setEncryptedData(name, JSON.stringify(data));
};
