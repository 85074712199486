import React, { useCallback, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import { apiUrls } from '../../../../../api/constants';
import { UiRoutes, labelPaths } from '../../../../../lib/constants';
import iconNames from '../../../../../lib/iconNames';
import useRedirect from '../../../../../hooks/useRedirect';
import useCRUD from '../../../../../hooks/useCRUD';
import WithLabel from '../../../../../hoc/withLabel';

import Loader from '../../../../../components/Loader';
import Table from '../../../../../components/Table';
import Icon from '../../../../../components/Icon';
import WidgetLoader from '../../../../../components/WidgetLoader';

import StatementForm from './StatementForm';
import columns from './StatementDetailsTable/columns';

const StatementDetails = ({ labels }) => {
  const {
    push, generatePath, params,
  } = useRedirect();
  const { statementId } = params;
  const viewPanel = useRef();

  const handleHtmlPrint = useReactToPrint({
    content: () => viewPanel.current,
    copyStyles: false,
  });

  const [statementDetailData,,
    statementLoading,
    requestStatement,
  ] = useCRUD({
    id: `patient-statement-detail-${statementId}`,
    url: apiUrls.STATEMENTS_DETAILS,
    type: 'read',
  });

  const [statementHtml,,
    statementHtmlLoading,
    getStatementHtml,
    clearStatementHtmlResponse] = useCRUD({
    id: 'get-patient-statement-html',
    url: apiUrls.STATEMENTS_PDF_PREVIEW,
    type: 'read',
  });

  useEffect(() => {
    if (statementHtml) {
      viewPanel.current.innerHTML = statementHtml || '';
      handleHtmlPrint();
      clearStatementHtmlResponse(true);
    }
  }, [statementHtml]);

  const onPrint = useCallback(() => {
    getStatementHtml({}, `?StatementId=${parseInt(statementId, 10)}`);
  }, [getStatementHtml, statementId]);

  useEffect(() => {
    if (statementId) requestStatement({ statementId });
  }, [statementId]);

  const goBack = useCallback(() => {
    push(generatePath(`${UiRoutes.patientBillStatementListWithTabId}`, { ...params, tab: 'bills' }));
  }, [push, generatePath, params]);

  return (
    <div className="pannel back-pannel mr-top-20 posi-relative">
      {statementHtmlLoading && <WidgetLoader />}
      <div className="pannel-heading flex justify-content-sp-bt">
        <div>
          <p id="patients_lists_statement_detail_goBack" className="back-arrow" onClick={goBack} role="presentation">{labels.get('buttons.goBack')}</p>
        </div>
        <div className="cursor-pointer">
          <Icon name={iconNames?.printFill} onClick={onPrint} />
        </div>
      </div>
      <div className="pannel-body">
        {statementLoading && <Loader />}
        <StatementForm
          labels={labels}
          statementDetail={statementDetailData?.statement || {}}
        />
        <div className="mr-top-20">
          <Table columns={columns(labels)} data={statementDetailData?.statementDetails || []} />
          {/* <StatementDetailTable labels={labels} /> */}
        </div>
      </div>
      <div className="display-none" ref={viewPanel} />
    </div>
  );
};
export default WithLabel(StatementDetails, labelPaths.STATEMENT_DETAILS);
