import React from 'react';

import TimeCell from '../../../../../../components/Cells/TimeCell';
import Icon from '../../../../../../components/Icon';

import { isDate } from '../../../../../../lib/util';
import iconNames from '../../../../../../lib/iconNames';

const readyColumns = (labels) => [
  {
    Header: labels.get('tableColumns.payerName'),
    accessor: 'insurancePayerName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.status'),
    accessor: 'stageMasterName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.depositDate'),
    accessor: 'depositDate',
    sort: true,
    Cell: (e) => <TimeCell value={e?.row.original?.depositDate?.dateString} format="MM-DD-YYYY" />,
  },
  {
    Header: labels.get('tableColumns.createdDate'),
    accessor: 'createdDate',
    sort: true,
    Cell: (e) => <TimeCell value={e?.row.original?.createdDate?.dateString} format="MM-DD-YYYY" />,
  },
  {
    Header: labels.get('tableColumns.batchNumber'),
    accessor: 'batchNumber',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.assignedTo'),
    accessor: 'assignToUserName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.checks'),
    accessor: 'checkCount',
    className: 'text-align-right',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.checkDate'),
    accessor: 'checkDate',
    sort: true,
    Cell: ({ row: { original: { checkDate } } }) => (
      isDate(checkDate) ? <TimeCell value={checkDate} format="MM-DD-YYYY" /> : checkDate
    ),
  },
  {
    Header: labels.get('tableColumns.checkNumber'),
    accessor: 'checkNumber',
    className: 'text-align-right',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.totalAmount'),
    accessor: 'totalBatchAmount',
    Cell: ({ row: { original: { totalBatchAmount } } }) => (
      parseFloat(totalBatchAmount || 0).toFixed(2)
    ),
    className: 'text-align-right',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.entered'),
    accessor: 'enteredAmount',
    Cell: ({ row: { original: { enteredAmount } } }) => (
      parseFloat(enteredAmount || 0).toFixed(2)
    ),
    className: 'text-align-right',
    sort: true,
  },
  {
    Header: '',
    accessor: 'paperClipIcon',
    Cell: () => (
      <span className="flex justify-content-center">
        <Icon name={iconNames.paperClipOutlined} color="green" />
      </span>
    ),
    fixWidth: '60',
  },
  {
    Header: '',
    accessor: 'delete',
    Cell: ({ row, handleDeleteRemittanceBatch, batchDeletePermitted }) => {
      const rowProps = row.getToggleRowSelectedProps();
      rowProps.onClick = (e) => e.stopPropagation();
      return (
        <span {...rowProps} className="flex justify-content-center" title="Delete Batch">
          {batchDeletePermitted && (
          <Icon
            name={iconNames.deleteIcon}
            onClick={handleDeleteRemittanceBatch(row?.original?.remittanceBatchId)}
          />
          )}
        </span>
      );
    },
    fixWidth: '60',
  },
];

export default readyColumns;
