import React, { useRef, useCallback } from 'react';
import classNames from 'classnames';
import toLower from 'lodash/toLower';
import { useReactToPrint } from 'react-to-print';

import iconNames from '../../lib/iconNames';

import Icon from '../../components/Icon';
import PanZoom from '../../components/Panzoom';
import ConfirmDialog from '../../components/ConfirmDialog';

import PdfPreview from './PDFPreview';
import Docx from './DocxPreview';

import './uploadPreview.scss';

export default function UploadFilePreview({
  children,
  type = 'pdf',
  fileUrl,
  onDelete,
  collapsableView,
  shouldShowDocument = true,
  ...otherProps
}) {
  const panRef = useRef(null);
  const imageContainerRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => panRef?.current && panRef?.current?.imageRef?.current,
    copyStyles: false,
  });

  const onPrintClick = useCallback(() => {
    panRef?.current?.reset();
    handlePrint();
  }, [panRef, handlePrint]);

  return (
    <div className="preview-container">
      {!collapsableView && (
      <div className="left-section">
        {children}
      </div>
      )}
      <div className={classNames('upload-document-preview', { 'width-100-pr': collapsableView })}>
        {
          otherProps?.isRemittanceDocument ? (
            <div className="file-not-found-preview"><div>{shouldShowDocument}</div></div>
          ) : (!fileUrl && <div className="file-not-found-preview"><div>File Not Found</div></div>)
        }
        {toLower(type) === 'pdf' && fileUrl && shouldShowDocument === true && (
        <PdfPreview
          onDelete={onDelete}
          fileUrl={fileUrl}
          collapsableView
          {...otherProps}
        />
        )}
        {(toLower(type) === 'docx' || toLower(type) === 'doc') && fileUrl && (
          <div className="doc-document">
            <Docx path={fileUrl} onDelete={onDelete} collapsableView {...otherProps} />
          </div>
        )}
        {['jpeg', 'jpg', 'png'].indexOf(toLower(type)) > -1 && fileUrl && (
          <div className="image-preview-container" ref={imageContainerRef}>
            <PanZoom ref={panRef} images={[fileUrl]} />
            <div className="preview-image-tools">
              {otherProps?.isFax ? (
                <Icon
                  title="Add to Fax Queue"
                  name={iconNames.shopTwoTone}
                  onClick={otherProps?.handleAddToFaxQueue}
                />
                ) : null}
              {!collapsableView && (
              <Icon
                title="Delete"
                name={iconNames.deleteIcon}
                onClick={ConfirmDialog({
                  onOk: (close) => {
                    onDelete();
                    close();
                  },
                  title: 'Do you want to delete this item?',
                  content: 'When clicked the OK button, item will be deleted permanently.',
                  icon: <Icon name="ExclamationCircleOutlined" />,
                })}
              />
              )}
              <Icon title="Print" name={iconNames.print} onClick={onPrintClick} />
              <Icon
                title="Fit To Screen"
                onClick={() => panRef.current.fitToScreen(imageContainerRef.current
                  && imageContainerRef.current.offsetHeight)}
                name={iconNames.fitContent}
              />
              <Icon title="Reset Size" onClick={() => panRef.current.reset()} name={iconNames.undo} />
              <Icon title="Zoom In" onClick={() => panRef.current.zoomIn()} name={iconNames.plusIcon} />
              <Icon title="Zoom Out" onClick={() => panRef.current.zoomOut()} name={iconNames.minus} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
