import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './statusCell.scss';

const StatusCell = ({ active }) => {
  let status = '';
  let isActive = true;

  if (typeof active === 'string') {
    status = active;
    isActive = active === 'Active';
  } else {
    status = active ? 'Active' : 'Inactive';
    isActive = active;
  }
  return (<span className={classNames({ active: isActive, inactive: !isActive })}>{status}</span>);
};

StatusCell.defaultProps = {
  active: false,
};

StatusCell.propTypes = {
  active: PropTypes.bool,
};

export default StatusCell;
