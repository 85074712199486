import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';

import {
  Form as AntdForm, Modal,
} from 'antd';

import classNames from 'classnames';

import { connect, useDispatch, useSelector } from 'react-redux';

import get from 'lodash/get';
import keys from 'lodash/keys';
import isFunction from 'lodash/isFunction';
import keyBy from 'lodash/keyBy';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { getEnumOptions, getEnumMaster, getTableDataById } from '../../../../store/selectors';

import {
  formId, labelPaths, listIds, enums, listId, UiRoutes,
} from '../../../../lib/constants';

import WithLabel from '../../../../hoc/withLabel';

import Form from '../../../../components/Form';
import Panel from '../../../../components/Panel';
import Notification from '../../../../components/Notification';

import ProcedureForm from '../Components/ProcedureForm';

import { apiUrls } from '../../../../api/constants';
import SuccessMessages from '../../../../lib/successMessages';

import useCRUD from '../../../../hooks/useCRUD';
import useRedirect from '../../../../hooks/useRedirect';
import { getEnumMasterData } from '../../../../store/actions/enum';
import Events from '../../../../lib/events';
import ProcedureSets from '../ProcedureSets';
import useReduxStoreWithId from '../../../../hooks/useReduxStoreWithId';
import useAnesthesiaCptCode from '../../../../hooks/useAnesthesiaCptCode';
import Icon from '../../../../components/Icon';
import ErrorMessages from '../../../../lib/errorMessages';

const mandatoryFields = (source) => {
  if (source === 'imaginglab') return [];
  if (source === 'jCode') return [];
  return ['modifier1'];
};

const parsedResultsForJcodes = (item, modifier) => {
  const obj = { };
  const jcodeResultCheck = item?.result?.every((it) => it.answer === null);
  (item?.result || [])?.map((res) => {
    if (modifier) {
      if (res.key === 'modifier') {
        obj[res.key] = parseInt(res.answer, 10);
      } else {
        obj[res.key] = res.answer;
      }
    } else if (!modifier && res.key !== 'modifier') {
      obj[res.key] = res.answer;
    }
  });
  // eslint-disable-next-line no-nested-ternary
  return Object.keys(obj)?.length
    ? (!jcodeResultCheck ? { ...obj, id: item?.procedureDetailsId } : obj) : {};
};

const EditProcedure = ({
  labels, procedureData, enumMaster, setAddOption,
  enumOptions, resetProcedureHistory, setProcedureData, updateUrl,
  extraUrl, hideDiagnosis, refetchEvent, onRequestComplete,
  hideFavorites, procedureValue, showJCodeFields, isAutoSave, isEditProcedure, hideProcedurePanel,
  closeForm, setAutoSaveData, type: screenType, isAddFromOverview, associatedJcodeDetails,
  isOverview = true, tabType,
}) => {
  const [form] = AntdForm.useForm();
  const dispatch = useDispatch();
  const { params, replace, generatePath } = useRedirect();
  const {
    patientId, encounterId, providerId, procedureId, type,
  } = params;

  const [newProcedure, createProcedureError,
    newProcedureLoading, createNewProcedure, clearNewProcedure] = useCRUD({
    id: listId.NEW_PATIENT_PROCEDURE, url: updateUrl ? updateUrl + extraUrl : apiUrls.ADD_PATIENT_PROCEDURE, type: 'update', shouldClearError: false,
  });
  const [procedureHistoryData, , , getProcedureHistory] = useCRUD({ id: 'procedure-history-table-crud-data', url: apiUrls.DOCTOR_PROCEDURES, type: 'read' });
  const [questionnaire, , loading, getQuestionnaire, clearQuestionnaire] = useCRUD({
    id: listIds.DOCTOR_QUESTIONNAIRES_MASTER, url: apiUrls.GET_QUESTIONNAIRES_MASTER_DATA, type: 'read',
  });

  const procedureHistoryTableData = useSelector(
    (state) => getTableDataById(state, listIds.DOCTOR_PROCEDURES),
  )?.data;

  const [questionnaireData, setQuestionnaireData] = useState({});
  const [selectedICDCodes, setSelectedICDCodes] = useState({});
  const [procedureSetsList, setProcedureSetsList] = useState([]);
  const [newProcedureData, setNewProcedureData] = useState({});
  const [showProcedureSets, setShowProcedureSets] = useState(true);
  const [saveProcedureReference, setSaveProcedureReference] = useState('');
  const [isModalVisible, , setModalVisibility] = useReduxStoreWithId({ id: 'procedure-set-modal-state' });

  // eslint-disable-next-line max-len, no-shadow
  const parseResult = useCallback((selectedProcedureData, showJCodeFields, procedureHistoryTableData) => {
    const results = procedureHistoryTableData?.find((item) => item?.encounterId === parseInt(
      selectedProcedureData?.encounterId, 10,
    ))?.procedureDetails;
    // eslint-disable-next-line no-shadow
    const procedureData = results?.find(
        (item) => item?.procedureDetailsId === (selectedProcedureData?.patientProcedureCodeId
          || selectedProcedureData?.procedureDetailsId));
    const data = { ...selectedProcedureData, ...procedureData };
    const { result = [] } = data;
    const dynamicFormFields = keyBy(result, (element) => element.Key);
    Object.keys(dynamicFormFields).forEach((key) => {
      dynamicFormFields[key] = dynamicFormFields[key].Answer;
    });

    const nestedFieldResults = results?.reduce((acc, item) => {
      if ((item?.associatedProcedureId === data?.procedureDetailsId)) {
        acc.push(Object.assign(parsedResultsForJcodes(item, true), {
          Prompt1: item?.cptCode,
          modifier: item?.modifiers?.[0] && parseInt(item?.modifiers?.[0]?.modifierId, 10),
          description: item?.procedureDescription,
        }));
      }
      return acc;
    }, []);
    const jcodeResultCheck = data?.result?.every((it) => it.answer === null);
    let defaultResultValues = { };
    if (questionnaireData?.Questionnaire?.length && (!data?.result)) {
      defaultResultValues = questionnaireData?.Questionnaire?.reduce((acc, item) => {
        if (item?.Key) {
          acc[item.Key] = item.Default;
        }
        return acc;
      }, {});
      defaultResultValues.Prompt4 = questionnaireData?.Questionnaire?.[0]?.Options?.[0]?.Dosage;
      defaultResultValues.Prompt5 = questionnaireData?.Questionnaire?.[0]?.Options?.[0]?.Units;
    }
    const defaultPrompt1Value = (data?.isJcode && showJCodeFields) ? data?.cptCode : null;
    if (defaultPrompt1Value) {
      /* eslint-disable no-param-reassign */
      data.Prompt1 = defaultPrompt1Value;
    }
    return {
      ...data,
      ...dynamicFormFields,
      modifier1: data?.modifiers?.[0] && parseInt(data?.modifiers?.[0]?.modifierId, 10),
      modifier2: data?.modifiers?.[1] && parseInt(data?.modifiers?.[1]?.modifierId, 10),
      modifier3: data?.modifiers?.[2] && parseInt(data?.modifiers?.[2]?.modifierId, 10),
      modifier4: data?.modifiers?.[3] && parseInt(data?.modifiers?.[3]?.modifierId, 10),
      result: nestedFieldResults?.length ? nestedFieldResults
        : [Object.assign(parsedResultsForJcodes(data, false),
          {
            ...defaultResultValues,
            modifier: jcodeResultCheck
              ? undefined : data?.modifiers?.[0]?.modifierId,
            description: jcodeResultCheck ? '' : data?.procedureDescription,
          })],
    };
  }, [questionnaireData]);

  const [formData, setFormData] = useState(
    parseResult(procedureData, showJCodeFields, procedureHistoryData?.result?.length
      ? procedureHistoryData?.result : procedureHistoryTableData),
  );
  const parsedJcodeOptions = useMemo(() => {
    const parsedData = procedureHistoryData?.result?.length
      ? procedureHistoryData?.result : procedureHistoryTableData;
    const data = parsedData?.find(
      (item) => item?.encounterId === parseInt(procedureData?.encounterId, 10))?.procedureDetails;
    const parentModifiersForJcode = data?.find(
      (item) => procedureData?.associatedProcedureId === item?.procedureDetailsId)?.modifiers;
    return (parentModifiersForJcode || [])?.map(
      (item) => ({ name: item?.description, value: item?.modifierId }));
  }, [procedureData, procedureHistoryData, procedureHistoryTableData]);

  const questionnaireEnumId = get(enumMaster, `${enums.PROCEDURE_QUESTIONNAIRES}.enumId`);
  const statusEnumId = get(enumMaster, `${enums.ENCOUNTER_PROCEDURE_STATUS}.enumId`);
  const procedureQuestionnaires = enumOptions
    && enumOptions[questionnaireEnumId] && enumOptions[questionnaireEnumId].data;
  const encounterProcedureStatus = enumOptions
    && enumOptions[statusEnumId] && enumOptions[statusEnumId].data;

  const [deleteCPTResponse, , , deleteCPTCode, clearDeleteResponse] = useCRUD({ id: 'delete-procedure-cpt', url: apiUrls.DELETE_IN_OFFICE_PROCEDURES, type: 'delete' });

  const anesthesiaCPTCode = useAnesthesiaCptCode();

  const getProcedureHistoryData = useCallback(() => {
    getProcedureHistory({
      SortBy: 'createdOn',
      SortOrder: 'desc',
      PatientId: patientId,
      EncounterID: encounterId,
      Source: 'Overview',
      PageIndex: 0,
      PageSize: 999,
    });
  }, [encounterId, getProcedureHistory, patientId]);

  useEffect(() => {
    Events.on('reFetchProcedureHistoryTable', 'reFetchProcedureHistoryTable', () => {
      getProcedureHistoryData();
    });
    return () => Events.remove('reFetchProcedureHistoryTable');
  }, []);

  useEffect(() => {
    getProcedureHistoryData();
  }, []);

  useEffect(() => () => {
    setQuestionnaireData({});
    setFormData({});
    setSelectedICDCodes({});
    clearNewProcedure();
    Events.trigger('disable-other-doctor-components', ({ isApplied: false }));
  }, []);

  useEffect(() => {
    if (createProcedureError?.toLowerCase()?.includes(ErrorMessages?.SAME_PROCEDURE_ERROR)
      || createProcedureError?.toLowerCase()?.includes(ErrorMessages?.TIME_ERROR_FOR_PROCEDURE)
      || createProcedureError?.toLowerCase()
      ?.includes(ErrorMessages?.ERROR_FOR_ALREADY_USED_PROCEDURE)) {
      if (!isEmpty(newProcedureData)) {
        Events.trigger('disable-other-doctor-components', ({ isApplied: false, formInstance: form }));
        Modal.warning({
          onOk: (close) => {
            localStorage.setEncryptedData('procedureSuppressWarning', 'true');
            createNewProcedure({ ...newProcedureData, suppressWarning: true }, updateUrl ? '' : `/${procedureId}`);
            Events.trigger('disable-other-doctor-components', ({ isApplied: true, formInstance: form }));
            close();
          },
          okText: 'ok',
          title: 'Warning',
          closable: true,
          centered: true,
          afterClose: () => {
            Events.trigger('disable-other-doctor-components', ({ isApplied: true, formInstance: form }));
            clearNewProcedure();
          },
          content: createProcedureError,
          icon: <Icon name="ExclamationCircleOutlined" />,
        });
      } else {
        clearNewProcedure();
      }
    } else if (createProcedureError) {
      Notification({ message: createProcedureError, success: false });
      clearNewProcedure();
    }
  }, [createProcedureError]);

  useEffect(() => () => {
    Events.trigger('removeSelectedProcedure');
  }, []);
  useEffect(() => {
    if (!(procedureQuestionnaires && procedureQuestionnaires.length)) {
      dispatch(getEnumMasterData(questionnaireEnumId));
    }
  }, [questionnaireEnumId, dispatch, procedureQuestionnaires]);

  const debouncedSubmit = useMemo(() => debounce(form.submit, 700), [form.submit]);

  const mandatoryFormFields = mandatoryFields(formData?.source?.toLowerCase()?.trim());

  const onValueChange = useCallback((value, allValues) => {
    const isMandatoryFieldFilled = mandatoryFormFields?.every((item) => allValues[item]);
    const mandatoryFieldsForJcode = parsedJcodeOptions?.length > 1 ? 'modifier' : null;
    const mandatoryFieldsFilledForJcode = mandatoryFieldsForJcode
      ? allValues?.results?.every((item) => item[mandatoryFieldsForJcode]) : true;
    if (isMandatoryFieldFilled || (procedureData?.isJcode && mandatoryFieldsFilledForJcode)) {
      debouncedSubmit();
      return true;
    }
    return false;
  }, [mandatoryFormFields, parsedJcodeOptions, procedureData, debouncedSubmit]);

  const debouncedAddOption = useMemo(() => debounce(
    () => setAddOption && setAddOption(null), 1000,
  ), [setAddOption]);

  useEffect(() => {
    if (procedureValue) {
      form.validateFields().then(() => {
        const formValues = form.getFieldsValue();
        onValueChange(0, formValues);
      });
    }
  }, [procedureValue]);

  useEffect(() => {
    if (!(encounterProcedureStatus && encounterProcedureStatus.length)) {
      dispatch(getEnumMasterData(statusEnumId));
    }
  }, [statusEnumId, dispatch, encounterProcedureStatus]);

  useEffect(() => {
    if (isAutoSave && (procedureData?.isJcode || (!procedureData?.procedureDescription && (!procedureData?.modifier1 || procedureData?.source?.toLowerCase() === 'imaginglab')))) {
      Events.trigger('disable-other-doctor-components', ({ isApplied: true, formInstance: form }));
    }
  }, [procedureData]);

  useEffect(() => {
    if (procedureData && procedureHistoryData) {
      const parsedData = procedureHistoryData?.result;
      const newData = parseResult(procedureData, false, parsedData);
      setFormData({ ...newData, results: newData?.result });
      if (procedureData?.diagnosisList) {
        const ICDObject = {};
        for (let index = 0; index < procedureData?.diagnosisList.length; index += 1) {
          const element = procedureData?.diagnosisList[index];
          ICDObject[element.id] = element;
        }
        setSelectedICDCodes(ICDObject);
      }
    }
  }, [procedureHistoryData, questionnaireData]);

  const onICDCheckBoxClick = useCallback((data) => {
    const { id } = data;
    const icdCodeObj = selectedICDCodes;
    icdCodeObj[id] = data;
    if (Object.keys(icdCodeObj)?.length <= 4) {
      setSelectedICDCodes({ ...icdCodeObj });
    } else {
      Notification({ message: 'Maximum diagnosis limit reached in a group.', success: false });
    }
  }, [selectedICDCodes]);

  const getQuestionnaireFromCPT = useCallback((cptCode, { item }) => {
    if (procedureQuestionnaires && procedureQuestionnaires.length) {
      setQuestionnaireData({
        cptCodeId: item.id,
        cptDescription: item.description,
        cptCode: item.cptCode,
        isJcode: item?.isJcode,
      });
      const { masterId } = procedureQuestionnaires[0];
      getQuestionnaire({ TypeId: masterId, Descriptor: cptCode });
    }
  }, [procedureQuestionnaires, getQuestionnaire]);

  useEffect(() => {
    if (questionnaire && questionnaire.length) {
      const {
        CPTCode, DescriptorName, QuestionnairesMasterId, Questionnaire,
      } = questionnaire[0];
      const {
        cptCodeId, cptDescription, cptCode, isJcode,
      } = questionnaireData;
      setQuestionnaireData({
        CPTCode,
        DescriptorName,
        questionnaresMasterId: QuestionnairesMasterId,
        Questionnaire: Questionnaire ? JSON.parse(Questionnaire) : [],
        cptCodeId,
        cptDescription,
        cptCode,
        isJcode,
      });
      clearQuestionnaire(true);
    }
  }, [questionnaire, questionnaireData]);

  const getParams = useCallback(() => {
    const { cptCodeId, questionnaresMasterId, isJcode } = questionnaireData;
    const {
      modifier1, modifier2, modifier3, modifier4, procedureDescription, status,
    } = form.getFieldsValue();
    const resultKeys = questionnaireData?.Questionnaire?.map((item) => item?.Key);
    /** check duplicate results  */
    const clonedResults = [...(form.getFieldValue('results') || [])];
    const flag = clonedResults?.some((item) => {
      const entry = clonedResults?.filter(
          (re) => re.modifier === item?.modifier && re.Prompt1 === item?.Prompt1);
      if (entry?.length > 1) {
        return true;
      }
      return false;
    });
    if (flag) {
      Notification({ message: 'Jcode with same modifier not allowed.', success: false });
      return true;
    }
    const nestedResults = form.getFieldValue('results')?.reduce((acc, field) => {
      const updatedKeys = [...new Set([...(resultKeys || []), ...Object.keys(field)])];
      acc.push(updatedKeys?.map((key) => {
        if (field[key]) {
          return { Key: key, answer: field[key] };
        }
        return { Key: key, answer: null };
      }));
      return acc;
    }, []);
    const results = resultKeys?.map((key) => {
      const resultFormList = form.getFieldValue('results')?.[0];
      if (resultFormList?.[key] && key !== 'modifier') {
        return { Key: key, answer: resultFormList?.[key] };
      }
      return { Key: key, answer: null };
    });
    const units = anesthesiaCPTCode?.includes(formData?.cptCode)
      ? (formData?.units || formData?.unit) : formData.units;
    return {
      providerId,
      cptCodeId,
      patientId: parseInt(patientId, 10),
      encounterId: parseInt(encounterId, 10),
      results: isAddFromOverview ? nestedResults : results,
      modifiers: procedureData?.isJcode ? [parseInt(form?.getFieldValue('results')?.[0]?.modifier, 10)] : [modifier1, modifier2, modifier3, modifier4],
      description: procedureDescription || form?.getFieldValue('results')?.[0]?.description,
      QuestonarieMasterId: questionnaresMasterId,
      status,
      isJcode,
      groupId: formData?.groupId,
      Icd10Codes: keys(selectedICDCodes).map((item) => parseInt(item, 10)),
      source: formData?.source,
      units: !procedureData?.isJcode ? units : undefined,
      associatedProcedureId: associatedJcodeDetails?.associatedProcedureId
    || procedureData?.associatedProcedureId,
    };
  }, [questionnaireData, form, providerId, patientId, encounterId,
    isAddFromOverview, procedureData, formData, selectedICDCodes,
    anesthesiaCPTCode, associatedJcodeDetails]);

  useEffect(() => {
    if (newProcedure) {
      Events.trigger('reFetchProcedures');
      Events.trigger(`refetchInOfficeProcedureList-${encounterId}`);
      Events.trigger('disable-other-doctor-components', { isApplied: false });
      if (!isAutoSave) {
        Notification({ message: SuccessMessages.PROCEDURE_UPDATED_SUCCESSFULLY, success: true });
        debouncedAddOption();
        localStorage.setEncryptedData('procedureSuppressWarning', 'false');
      }
      if (isAutoSave) {
        setAutoSaveData({ ...procedureData, ...form.getFieldsValue() });
      }
      clearNewProcedure();
      if (isOverview && type !== 'newSimpleEncounter') {
        replace(generatePath(UiRoutes.addProcedure, params));
      }
      if (isFunction(setProcedureData)) setProcedureData({});
      if (refetchEvent) Events.trigger(refetchEvent);
      if (isFunction(onRequestComplete)) onRequestComplete();
      if (saveProcedureReference === 'saveAsProcedureSet') {
        Events.trigger(saveProcedureReference);
      }
    }
  }, [form, newProcedure, procedureValue]);

  useEffect(() => {
    if (deleteCPTResponse && formData?.cptCodeId) {
      const cptData = formData;
      delete cptData.cptCodeId;
      setFormData(cptData);
      clearDeleteResponse(true);
      setQuestionnaireData({});
    }
  }, [deleteCPTResponse, formData.cptCodeId, clearDeleteResponse, formData]);

  const saveProcedure = useCallback(() => {
    if (procedureData?.isJcode && !questionnaireData?.Questionnaire) {
      return;
    }
    const data = getParams();
    data.isBillable = formData?.isBillable;
    if (data?.Icd10Codes?.length || hideDiagnosis) {
      setNewProcedureData({ ...data });
      createNewProcedure({ ...data, suppressWarning: JSON.parse(localStorage.getDecryptedData('procedureSuppressWarning')) }, updateUrl ? '' : `/${procedureId}`);
    } else {
      Notification({ message: 'Procedure need to have an associated ICD-10 code.' });
    }
  }, [procedureData, questionnaireData, getParams, formData, hideDiagnosis,
    createNewProcedure, updateUrl, procedureId]);

  const deleteCPTCodeId = useCallback(({ procedureDetailsId, groupId }) => {
    deleteCPTCode({}, `?ProcedureDetailsId=${procedureDetailsId}&groupId=${groupId}&EncounterId=${encounterId}`);
  }, [deleteCPTCode, encounterId]);

  useEffect(() => {
    if (deleteCPTResponse) {
      Events.trigger('reFetchProcedures');
      Events.trigger(`refetch-InOffice-ProcedureList-${encounterId}`);
    }
    clearDeleteResponse();
  }, [deleteCPTResponse]);

  useEffect(() => () => resetProcedureHistory && resetProcedureHistory(null), []);

  return (
    <Panel
      className={classNames('posi-relative', {
        hideProcedurePanel,
      })}
      title={!hideProcedurePanel ? labels.get('titles.procedure') : ''}
      showCloseIcon={!hideProcedurePanel}
      onClose={closeForm}
    >
      <Form
        form={form}
        section
        formId={formId.PROCEDURE_FORM}
        // initialData={formData}
        onFinish={saveProcedure}
        onValueChange={isAutoSave && onValueChange}
        shouldShowLoader={!isAutoSave}
      >
        <ProcedureForm
          labels={labels}
          formData={formData}
          setFormData={setFormData}
          form={form}
          getParams={getParams}
          questionnaireData={questionnaireData}
          getQuestionnaireFromCPT={getQuestionnaireFromCPT}
          loading={loading}
          encounterProcedureStatus={encounterProcedureStatus}
          onICDCheckBoxClick={onICDCheckBoxClick}
          selectedICDCodes={selectedICDCodes}
          setSelectedICDCodes={setSelectedICDCodes}
          setQuestionnaireData={setQuestionnaireData}
          deleteCPTCodeId={deleteCPTCodeId}
          hideDiagnosis={hideDiagnosis}
          showJCodeFields={showJCodeFields}
          isAutoSave={isAutoSave}
          isEditProcedure={isEditProcedure}
          isLoading={newProcedureLoading || loading}
          screenType={screenType}
          saveProcedureReference={saveProcedureReference}
          setSaveProcedureReference={setSaveProcedureReference}
          isModalVisible={isModalVisible?.get('data')}
          setModalVisibility={setModalVisibility}
          isAddFromOverview={isAddFromOverview}
          parsedJcodeOptions={parsedJcodeOptions}
          tabType={tabType}
          procedureData={procedureData}
          setShowProcedureSets={setShowProcedureSets}
        />
        {!hideFavorites && (
          (!showProcedureSets && (
          <ProcedureSets
            procedureSetsList={procedureSetsList}
            setProcedureSetsList={setProcedureSetsList}
            setFormData={setFormData}
            setSelectedICDCodes={setSelectedICDCodes}
          />
          ))
        )}
      </Form>
    </Panel>
  );
};

EditProcedure.defaultProps = {
  hideDiagnosis: false,
  hideFavorites: false,
  hideProcedurePanel: false,
  isAddFromOverview: false,
};

export default connect((state) => ({
  enumOptions: getEnumOptions(state),
  enumMaster: getEnumMaster(state),
}))(WithLabel(EditProcedure, labelPaths.ADD_PROCEDURE));

