import React, { useCallback, useState } from 'react';
import CommentSection from './CommentSection';
import RadioGroup from '../../../../../components/RadioGroup';
import './activity.scss';
import TaskLogs from './TaskLogs';

const Activity = ({
  taskId, onAddComment, labels, comments,
}) => {
  const [activeComponent, setActiveComponent] = useState('Comments');
  const tabList = () => [
    { value: 'Comments', label: 'Comments' },
    { value: 'Logs', label: 'Logs' },
  ];

  const tabSwitchHandler = useCallback(
    ({ target: { value } }) => {
      setActiveComponent(value);
    },
    [activeComponent],
  );
  return (
    <div>
      <RadioGroup
        tabList={taskId ? tabList(labels) : [{ value: 'Comments', label: 'Comments' }]}
        onChange={tabSwitchHandler}
        value={activeComponent}
      />
      <div className="comment-log-section">
        {activeComponent === 'Comments' && (
          <CommentSection
            taskId={taskId}
            onAddComment={onAddComment}
            labels={labels}
            comments={comments}
          />
        )}
        {activeComponent === 'Logs' && <TaskLogs taskId={taskId} />}
      </div>
    </div>
  );
};

export default Activity;
