import React, { useCallback, useState, useEffect } from 'react';
import classnames from 'classnames';
import { Row, Col } from 'antd';
import AutoComplete from '../../components/Form/GridAutoComplete';
import SmCheckbox from '../../components/SmCheckbox';
import { isInputExistInMap } from '../../lib/util';
import useCRUDWithoutTab from '../../hooks/useCRUDWithoutTab';
import { apiUrls } from '../../api/constants';
import { modifierDefaultProps, modifierDefaultPropsTypes } from './commonModifierPropTypes';

const columns = [
  {
    Header: 'Code',
    accessor: 'modifierCode',
    flex: 1,
    maxWidth: 150,
  },
  {
    Header: 'Description',
    accessor: 'description',
    flex: 6,
  },
];

const ModifierAutoComplete = ({
  disabled,
  required,
  initialValue,
  labelSpan,
  inputSpan,
  onSelect,
  styleWidth,
  name,
  className,
  dropdownMatchSelectWidth,
  selectProps,
  params,
  minCharLength,
  selectClassName,
  defaultOptions,
  nameAccessor,
  cptCode,
  filterModifierSearch,
  optionKey,
  onClear,
  ...props
}) => {
  const [isHovering, setHovering] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [crossWalk, setCrossWalk] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const [
    responseWithCPT, ,, getWithCPT,
  ] = useCRUDWithoutTab({
    id: `${apiUrls.MODIFIERS_CODE_LIST}_${cptCode}`,
    url: `${apiUrls.MODIFIERS_CODE_LIST}?PageSize=1200`,
    type: 'read',
  });

  useEffect(() => {
    if (cptCode && crossWalk && !responseWithCPT) {
      getWithCPT({ CPTCode: cptCode });
    }
  }, [crossWalk]);

  const modifierOptionParser = useCallback((option) => ({
    ...option,
    name: option[nameAccessor] || option.modifierCode,
    value: option.modifierId,
  }), [nameAccessor]);

  const handleCheckbox = useCallback(({ target: { checked } }) => setCrossWalk(checked), []);

  const handleOnSearch = (value) => {
    setInputValue(value);
  };

  const handleFilterSort = useCallback((optionA, optionB) => {
    const firstOption = optionA?.item?.[optionKey]?.toLowerCase();
    const secondOption = optionB?.item?.[optionKey]?.toLowerCase();
    const searchValue = inputValue && inputValue?.toLowerCase();

    // prioritize exact code match
    if (searchValue && firstOption === searchValue) {
      return -1;
    }
    if (searchValue && secondOption === searchValue) {
      return 1;
    }

    // prioritize earlier matches
    if (
      searchValue && firstOption?.indexOf(searchValue) !== -1
      && secondOption?.indexOf(searchValue) !== -1
      && firstOption?.indexOf(searchValue) < secondOption?.indexOf(searchValue)
    ) {
      return -1;
    }
    if (
      searchValue && firstOption?.indexOf(searchValue) !== -1
      && secondOption?.indexOf(searchValue) !== -1
      && firstOption?.indexOf(searchValue) > secondOption?.indexOf(searchValue)
    ) {
      return 1;
    }

    if (searchValue && firstOption?.indexOf(searchValue) !== -1
    && secondOption?.indexOf(searchValue) === -1) {
      return -1;
    }
    if (searchValue && firstOption?.indexOf(searchValue) === -1
    && secondOption?.indexOf(searchValue) !== -1) {
      return 1;
    }
    return firstOption?.localeCompare(secondOption);
  }, [inputValue, optionKey]);

  return (
    <div
      className="filter-modifier-autocomplete"
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <AutoComplete
        name={name}
        optionParser={modifierOptionParser}
        optionMaster="SearchText"
        labelSpan={labelSpan}
        inputSpan={inputSpan}
        disabled={disabled}
        required={required}
        columns={columns}
        style={styleWidth ? { width: '100%' } : {}}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        className={className}
        notFoundContent="Modifier is not present is the system."
        showArrow
        labelInValue
        selectProps={{
          ...selectProps,
          onClear,
          optionLabelProp: 'name',
          allowClear: true,
          onSearch: filterModifierSearch ? handleOnSearch : () => { /* This is intentional */ },
          dropdownMatchSelectWidth: 500,
          filterOption: (input, option) => isInputExistInMap(input, ['name', 'modifierCode', 'description'], option?.item),
          filterSort: filterModifierSearch && handleFilterSort,
          ...selectProps,
        }}
        params={params}
        onSelect={onSelect}
        minCharLength={minCharLength}
        defaultOptions={crossWalk ? (responseWithCPT || []) : defaultOptions}
        {...props}
      />
      <Row className="crosswalk-checkbox-row">
        <Col span={inputSpan} offset={labelSpan}>
          <SmCheckbox
            name="all"
            disabled={disabled}
            className={classnames(selectClassName, 'crosswalk-checkbox', { show: isHovering && !disabled })}
            isFormItem={false}
            onChange={handleCheckbox}
          >
            Crosswalk
          </SmCheckbox>
        </Col>
      </Row>
    </div>
  );
};

ModifierAutoComplete.defaultProps = {
  ...modifierDefaultProps,
};

ModifierAutoComplete.propTypes = {
  ...modifierDefaultPropsTypes,
};

export default React.memo(ModifierAutoComplete);
