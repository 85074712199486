import React, {
  useCallback, useState, useMemo,
} from 'react';
import debounce from 'lodash/debounce';
import Button from '../../../../../../components/Button';
import Modals from '../../../../../../components/Modal';
import TableWrapper from '../../../../../../components/Table/TableWrapper';
import SearchBox from '../../../../../../components/Search';
import WidgetLoader from '../../../../../../components/WidgetLoader';
import withQuery from '../../../../../../hoc/withQuery/withQuery';
import { apiUrls } from '../../../../../../api/constants';
import columns from './columns';

const AnotherPayerMappingTable = withQuery({
  listId: 'get-insurance-mapping-data',
  url: apiUrls.GET_PAYER_MAPPING,
})();

const MapAsAnotherPayerModal = ({
  labels,
  isVisible,
  handleMapAsAnotherPayerModalVisible,
  setMapAsAnotherPayerModalVisible,
  setMapWithOtherPayerData,
  mapWithOtherPayerData,
  mapOtherPayerInsurance,
  mappingLoading,
}) => {
  const [reFetchTable, setReFetch] = useState(() => { /* This is intentional */ });
  const [searchText, setSearchText] = useState(null);

  const debouncedSearch = useMemo(() => debounce(setSearchText, 500), [setSearchText]);

  const onChange = useCallback(({ target: { value } }) => {
    debouncedSearch(value);
  }, [debouncedSearch]);

  const handleSelectedRow = useCallback((payerId) => {
    const selectedPayerId = mapWithOtherPayerData === payerId ? undefined : payerId;
    setMapWithOtherPayerData(selectedPayerId);
  },
  [mapWithOtherPayerData, setMapWithOtherPayerData]);

  return (
    <Modals
      visible={isVisible}
      width={1100}
      toggleModal={setMapAsAnotherPayerModalVisible}
      destroyOnClose
      className="provider-location-ui map-another-payer-modal"
      footer={[
        <div className="group-btns" key="footer">
          <Button
            className="btn min-wt-86"
            onClick={handleMapAsAnotherPayerModalVisible}
          >
            {labels.get('buttons.cancel')}
          </Button>
          <Button
            className="btn btn-success min-wt-86 inline"
            onClick={mapOtherPayerInsurance}
          >
            {labels.get('buttons.map')}
          </Button>
        </div>,
      ]}
    >
      <div className="addition-header">
        <div className="lookup sprite-img-before">
          <p>{labels.get('titles.mapAsAnotherPayer')}</p>
        </div>
      </div>
      <div className="search-box mr-bt-8">
        <SearchBox
          placeholder="Select payer for mapping"
          style={{ width: '280px', height: '28px' }}
          className="mr-rt-8"
          onChange={onChange}
        />
      </div>
      {mappingLoading && (<WidgetLoader />)}
      <AnotherPayerMappingTable
        columns={columns(mapWithOtherPayerData, handleSelectedRow, labels)}
        filters={{ OnlyPayersWithMappingConfig: true, SearchText: searchText }}
        initialSort={[{ id: 'name', desc: false }]}
      >
        {({
          Component, reFetch,
        }) => (
          <TableWrapper
            reFetch={reFetch}
            setReFetch={setReFetch}
            Component={Component}
            reFetchTable={reFetchTable}
          />
        )}
      </AnotherPayerMappingTable>
    </Modals>
  );
};

export default MapAsAnotherPayerModal;
