import React, { useState, useEffect, useCallback } from 'react';

import { apiUrls } from '../../../../../api/constants';
import SuccessMessages from '../../../../../lib/successMessages';
import { enums as enumConstant, dateFormatWith12Hour } from '../../../../../lib/constants';
import Events from '../../../../../lib/events';
import withQuery from '../../../../../hoc/withQuery/withQuery';

import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Form/Input';
import Notification from '../../../../../components/Notification';
import DatePicker from '../../../../../components/Form/DatePicker';
import AutoComplete from '../../../../../components/Form/AutoComplete';

import { Select } from '../../../../../wiredComponents/Enum';
import dateFormatter from '../../../../../lib/dateFormatter';

const optionParser = (option) => ({
  name: option.name,
  value: option.id,
  ...option,
});

const columns = [
  {
    Header: 'Name',
    accessor: 'documentDisplayName',
  },
  {
    Header: 'Category Name',
    accessor: 'Category Name',
    Cell: () => 'Signed Encounter PDF',
  },
];

const VersionedEncounterTable = withQuery({
  url: apiUrls.GET_VERSIONED_SIGNED_DOCUMENTS,
  listId: apiUrls.GET_VERSIONED_SIGNED_DOCUMENTS,
  accessor: (data) => ({ result: data }),
})();

export default function UploadFileForm({
  document,
  setDocument,
  form,
  parser,
  initialDataParser,
  tabId,
  documentId,
  patientId,
  initialData,
  // isUpdateDocumentAuthenticated,
}) {
  const [initialAssignedTo, setInitialAssignedTo] = useState(null);

  useEffect(() => {
    if (document && document.assignedToId && document.assignedToName) {
      setInitialAssignedTo({ name: document.assignedToName, value: document.assignedToId });
    }
  }, [document]);

  const onRequestComplete = useCallback(() => {
    Notification({ message: SuccessMessages.DOCUMENT_UPDATED_SUCCESSFULLY, success: true });
    Events.trigger('refetch-updated-patient-document-item');
  }, []);

  const onRowClick = useCallback(({ original }) => {
    if (document.documentId !== original?.documentId) {
      setDocument(original);
      const parsedValues = initialDataParser(original);
      form.setFieldsValue(parsedValues);
    }
  }, [document, form, initialDataParser, setDocument]);

  return (
    <>
      <Form
        section
        form={form}
        parser={parser}
        initialDataParser={initialDataParser}
        initialData={initialData}
        formId={`${apiUrls.GET_PATIENT_DOCUMENT}/${documentId}`}
        name={`patient-documents-upload-form-${patientId}-${documentId}`}
        getUrl={`${apiUrls.GET_PATIENT_DOCUMENT}/${documentId}`}
        url={apiUrls.GET_PATIENT_DOCUMENT}
        editKey={documentId}
        isUpdate
        refetchId="refetch-updated-patient-document-item"
        onRequestComplete={onRequestComplete}
        onGetResponseComplete={setDocument}
        tabId={tabId}
        extraData={{
          patientId,
          documentId,
          encounterId: document?.encounterId,
          rootEncounterId: document?.rootEncounterId,
        }}
      >
        {({ resetForm }) => (
          <Form.Section>
            <div>
              <Select
                label="Category"
                name="categoryId"
                required
                enumName={enumConstant.PATIENT_DOCUMENT_CATEGORY}
                id="9"
                form={form}
                initialValue="Optometry"
              />
              <DatePicker
                label="Effective Dates"
                name="effectiveDate"
              />
              <Input
                label="Name"
                name="documentDisplayName"
                required
                placeholder="Enter Name"
              />
              <AutoComplete
                id="AssignedToId"
                name="assignedToId"
                url={apiUrls.USER_SUGGESTIVE_SEARCH}
                label="Assign To"
                optionMaster="searchText"
                optionParser={optionParser}
                placeholder="Search User"
                initialValue={initialAssignedTo}
                inputSpan="14"
                labelSpan="10"
                dropdownMatchSelectWidth
              />
              <Input
                label="Description"
                name="description"
                placeholder="Enter Description"
              />
              <Form.Section>
                <div className="flex document-btns">
                  <Button className="btn btn-success min-wt-86 mr-rt-12" type="submit">Update</Button>
                  <Button className="btn" onClick={resetForm}>Clear</Button>
                </div>
              </Form.Section>
              <div className="discription-area ">
                <div className="row">
                  <div className="key">Created By:</div>
                  <div className="value">{document && document.createdByName}</div>
                </div>
                <div className="row">
                  <div className="key">Created On:</div>
                  <div className="value">{document && dateFormatter(document.createdOn, dateFormatWith12Hour)}</div>
                </div>
                <div className="row">
                  <div className="key">Updated By:</div>
                  <div className="value">{document && document.modifiedByName}</div>
                </div>
                <div className="row">
                  <div className="key">Updated On:</div>
                  <div className="value">{document && dateFormatter(document.modifiedOn, dateFormatWith12Hour)}</div>
                </div>
              </div>
            </div>
          </Form.Section>
        )}
      </Form>
      {(document?.rootEncounterId || document?.parentDocumentId
      || (document?.documentId && document?.mipsType?.toLowerCase() === 'referralletter')) && (
      <VersionedEncounterTable
        columns={columns}
        filters={{
          RootEncounterId: document?.rootEncounterId,
          parentDocumentId: document?.mipsType?.toLowerCase() === 'referralletter' ? document?.documentId : document?.parentDocumentId,
          PatientId: patientId,
          ChildDocumentId: document?.parentDocumentId ? document?.documentId : undefined,
          CategoryId: document?.categoryId,
        }}
        onRowClick={onRowClick}
      >
        {({ Component }) => Component }
      </VersionedEncounterTable>
      )}
    </>
  );
}
