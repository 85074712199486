import React, {
  useCallback, useEffect, useState, useMemo,
} from 'react';
import { Menu } from 'antd';
import { Route } from 'react-router-dom';

import useRedirect from '../../../../../../hooks/useRedirect';
import useCRUD from '../../../../../../hooks/useCRUD';

import Table from '../../../../../../components/Table';
import Loader from '../../../../../../components/Loader';
import Notification from '../../../../../../components/Notification';

import { apiUrls } from '../../../../../../api/constants';
import { listIds, UiRoutes } from '../../../../../../lib/constants';
import Events from '../../../../../../lib/events';
import successMessages from '../../../../../../lib/successMessages';

import WorkList from '../Worklist';
import columns from './columns';
import EditWorklist from './EditWorklist';
import Button from '../../../../../../components/Button';

const patientPaymentHiddenColumns = ['claimNo', 'adjustment'];
const errorHiddenColumns = ['pageNumber'];

const getNormalizedData = (data) => {
  const normalizedData = [];
  data?.viewEnteredItems?.forEach((element) => {
    normalizedData.push({ ...element, totalPosted: data?.totalPosted });
  });
  return normalizedData;
};

const PostedClaimTable = ({
  labels, setCurrentPath, data, loading, tabName, setCurrentPageNo,
  isPostedInsuranceClaims, transactionTypeId, isPatientPaymentItems,
  formDetails = {}, isRowClickAble = true, isInsurancePayment, onPost, paymentForm,
  formValidation, patientTransactionTypeId, remittanceBatchData, isRemittanceErrorTab,
  currentPageNo, setNeedToScroll, form, setClaimPageNo, totalNoOfPages,
}) => {
  const {
    params: {
      remittanceId, remittancePaymentId, pageNo, claimFilter,
      errorTab, tab, tabId, insurancePaymentPage, patientPaymentPage,
    },
    path, generatePath, replace, params,
  } = useRedirect();
  const [claimDetails, setClaimDetails] = useState({});
  const [isWorklistModalVisible, setIsWorklistModalVisible] = useState(false);
  const [isUnexpectedError, setIsUnexpectedError] = useState(false);
  const [isSkippingValidation, setIsSkippingValidation] = useState();
  const [isAdjustmentValidator, setisAdjustmentValidator] = useState();
  const [isDuplicateValidation, setIsDuplicateValidation] = useState();
  const [isUpdateUnitValidation, setIsUpdateUnitValidation] = useState(false);
  const [isPageChanging, setIsPageChanging] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(300);

  const [
    skipErrorValidationResponse,,
    skipErrorValidationLoading,
    skipErrorValidation,
    clearSkipErrorValidation,
  ] = useCRUD({
    id: listIds.SKIP_ERROR_VALIDATION,
    url: apiUrls.SKIP_ERROR_VALIDATION,
    type: 'create',
  });

  const [
    deleteRemittancePaymentResponse,,
    deleteRemittancePaymentLoading,
    deleteRemittancePayment,
    clearDeleteRemittancePayment,
  ] = useCRUD({
    id: 'DELETE_REMITTANCE_PAYMENT',
    url: apiUrls.DELETE_REMITTANCE_PAYMENT,
    type: 'update',
  });

  const onRowClick = useCallback(({ original }) => {
    if (isRowClickAble && original?.worklistId) {
      const {
        patientId, amount, worklistId, claimId, balance,
        pageNumber, claimNo, remittancePaymentIdentifier,
      } = original;
      const url = `${path}${UiRoutes.postClaimTablePopUp}`;
      replace(generatePath(url, {
        ...params,
        patientId: patientId ?? 'notFound',
        amount: amount ?? 'notFound',
        ClaimId: claimId ?? 'notFound',
        balance: balance ?? 'notFound',
        pageNumber: pageNumber ?? 'notFound',
        claimNo: claimNo ?? 'notFound',
        worklistId: worklistId ?? 'notFound',
        remittancePaymentIdentifier: remittancePaymentIdentifier ?? 'notFound',
        title: labels.get('titles.editWorklist'),
      }));
      setClaimPageNo(original?.pageNumber);
      form?.setFieldsValue({ pageNo: original?.pageNumber });
      paymentForm?.setFieldsValue({ pageNo: original?.pageNumber });
      Events.trigger('PDF_VIEWER', { pageNo: original?.pageNumber || 1 });
      Events.trigger('insurance-claim-page-number', { pageNo: original?.pageNumber || 1, shouldSave: true });
      Events.trigger('patient-claim-page-number', { pageNo: original?.pageNumber || 1, shouldSave: true });
    }
    if (setCurrentPageNo) { setCurrentPageNo(original?.pageNumber || currentPageNo || 1); }
    const claimId = original?.claimId;
    const patientId = original?.patientId;
    const identifier = original?.remittancePaymentIdentifier;
    if (isRowClickAble && !original?.worklistId) {
      setClaimPageNo(original?.pageNumber);
      Events.trigger('PDF_VIEWER', { pageNo: original?.pageNumber || 1 });
      Events.trigger('insurance-claim-page-number', { pageNo: original?.pageNumber || 1, shouldSave: true });
      Events.trigger('patient-claim-page-number', { pageNo: original?.pageNumber || 1, shouldSave: true });
      form?.setFieldsValue({ pageNo: original?.pageNumber });
      paymentForm?.setFieldsValue({ pageNo: original?.pageNumber });
      const insurancePaymentPath = identifier ? `${path}/searchClaim/:claimId/:id/:identifier` : `${path}/searchClaim/:claimId/:id`;
      replace(
        generatePath(
          isPatientPaymentItems ? `${path}/searchClaim/:id` : insurancePaymentPath,
          {
            tab,
            tabId,
            insurancePaymentPage,
            pageNo,
            remittanceId,
            remittancePaymentId,
            patientPaymentPage,
            errorTab,
            claimId,
            id: patientId,
            identifier,
            claimFilter,
          },
        ),
      );
    }
  }, [isRowClickAble, setCurrentPageNo, replace, generatePath, params, labels, setClaimPageNo,
    form, paymentForm, currentPageNo, path, isPatientPaymentItems, tab, tabId, insurancePaymentPage,
    pageNo, remittanceId, remittancePaymentId, patientPaymentPage, errorTab, claimFilter]);

  const toggleWorklistModal = useCallback(() => {
    setIsWorklistModalVisible((prevIsWorklistModalVisible) => !prevIsWorklistModalVisible);
    setIsUnexpectedError(false);
  }, []);

  useEffect(() => {
    if (setCurrentPath) {
      setCurrentPath(path);
    }
  }, []);

  const tableData = useMemo(() => {
    if (data) {
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = startIndex + pageSize;

      if (isRemittanceErrorTab) {
        return data.slice(startIndex, endIndex);
      }
      const normalized = getNormalizedData(data);
      return normalized.slice(startIndex, endIndex);
    }
    return [];
  }, [data, currentPage, pageSize]);

  const handlePageChange = (page) => {
    setIsPageChanging(true);
    setCurrentPage(page);
    setTimeout(() => {
      setCurrentPage(page);
      setIsPageChanging(false);
    }, 200);
  };

  const totalPages = useMemo(() => {
    if (data) {
      if (isRemittanceErrorTab) {
        return Math.ceil(data.length / pageSize);
      }
      const normalized = getNormalizedData(data);
      return Math.ceil(normalized.length / pageSize);
    }
    return 1;
  }, [data, pageSize]);

  const handleEditTransaction = useCallback((row) => () => {
    const {
      patientId, amount, worklistId, claimId, balance,
      pageNumber, claimNo, remittancePaymentIdentifier, errors,
    } = row;
    const url = `${path}${UiRoutes.postClaimTablePopUpWithSearchTextWithErrorId}`;
    const errorID = errors?.find(({ errorId } = {}) => errorId === 2468);
    replace(generatePath(url, {
      ...params,
      patientId: patientId ?? 'notFound',
      amount: amount ?? 'notFound',
      ClaimId: claimId ?? 'notFound',
      balance: balance ?? 'notFound',
      pageNumber: pageNumber ?? 'notFound',
      claimNo: claimNo ?? 'notFound',
      worklistId: worklistId ?? 'notFound',
      remittancePaymentIdentifier: remittancePaymentIdentifier ?? 'notFound',
      title: 'Edit Transaction',
      searchTextData: claimNo ?? 0,
      errorId: errorID?.errorId ?? 'notFound',
    }));
  }, [generatePath, params, path, replace]);

  const handleBack = useCallback(() => {
    const url = path.substring(0, path.indexOf('/postedClaimTable'));
    replace(generatePath(url, {
      ...params,
    }));
  }, [generatePath, params, path, replace]);

  const handleApplyTransaction = useCallback((row) => () => {
    const {
      patientId, amount, worklistId, claimId, balance,
      pageNumber, claimNo, remittancePaymentIdentifier,
    } = row;
    const url = `${path}${UiRoutes.postClaimTablePopUp}`;
    replace(generatePath(url, {
      ...params,
      patientId: patientId ?? 'notFound',
      amount: amount ?? 'notFound',
      ClaimId: claimId ?? 'notFound',
      balance: balance ?? 'notFound',
      pageNumber: pageNumber ?? 'notFound',
      claimNo: claimNo ?? 'notFound',
      worklistId: worklistId ?? 'notFound',
      remittancePaymentIdentifier: remittancePaymentIdentifier ?? 'notFound',
      title: 'Update To New Claim',
    }));
  }, [generatePath, params, path, replace]);

  const handleSkipValidation = useCallback((
    row, isAdjustmentValidation, isSkipValidation,
  ) => () => {
    setisAdjustmentValidator(isAdjustmentValidation);
    skipErrorValidation({
      data: {
        RemittanceBatchId: remittanceId,
        ClaimId: row?.claimId,
        FinancePaymentId: remittancePaymentId,
        EnumName: 'RemittanceBatchError',
        isSkipForCheck: false,
        isSkipped: !isSkipValidation,
        Mastercode: isAdjustmentValidation ? 'Error8035' : 'Error8516',
        remittancePaymentIdentifier: row?.remittancePaymentIdentifier,
      },
    });
  }, [remittanceId, remittancePaymentId, skipErrorValidation]);

  const handleUpdateChargeUnits = useCallback((row) => () => {
    setIsUpdateUnitValidation(true);
    skipErrorValidation({
      data: {
        RemittanceBatchId: remittanceId,
        ClaimId: row?.claimId,
        ClaimNo: row?.claimNo,
        FinancePaymentId: remittancePaymentId,
        EnumName: 'RemittanceBatchError',
        isUpdateChargeBalanceByUnits: true,
        Mastercode: 'Error8529',
        remittancePaymentIdentifier: row?.remittancePaymentIdentifier,
      },
    });
  }, [remittanceId, remittancePaymentId, skipErrorValidation]);

  const handleDeleteRemittancePayment = useCallback((item) => () => {
    deleteRemittancePayment({
      patientId: parseInt(item?.patientId, 10) || 0,
      worklistId: parseInt(item?.worklistId, 10) || 0,
      deleteCheck: false,
      remittanceBatchId: parseInt(remittanceId, 10) || 0,
      financePaymentId: parseInt(remittancePaymentId, 10) || 0,
      claimId: parseInt(item?.claimId, 10) || 0,
      remittancePaymentIdentifier: item?.remittancePaymentIdentifier,
    });
  }, [deleteRemittancePayment]);

  const handleTransactionAddToWorkList = useCallback((row, unexpectedError) => () => {
    setClaimDetails(row);
    setIsWorklistModalVisible((prevIsWorklistModalVisible) => !prevIsWorklistModalVisible);
    setIsUnexpectedError(unexpectedError);
  }, []);

  useEffect(() => {
    if (skipErrorValidationResponse && !Array.isArray(skipErrorValidationResponse)) {
      if (isUpdateUnitValidation) {
        Notification({
          message: successMessages.CHARGE_UPDATE_UNIT_VALIDATION,
          success: true,
        });
      } else if (isAdjustmentValidator) {
        Notification({
          message: isSkippingValidation ? successMessages.USE_ADJUSTMENT_VALIDATION
            : successMessages.SKIP_ADJUSTMENT_VALIDATION,
          success: true,
        });
      } else {
        Notification({
          message: isDuplicateValidation ? successMessages.USE_DUPLICATE_VALIDATION
            : successMessages.SKIP_DUPLICATE_VALIDATION,
          success: true,
        });
      }
      Events.trigger(`refetch-remittance-error-list${remittanceId}`);
      Events.trigger(`refetchPostedClaimsError${remittancePaymentId}`);
      Events.trigger(`GET_VIEW_ENTERED_ITEMS_${remittancePaymentId}`);
      Events.trigger('GET_AMOUNT_AFTER_POSTING_BATCH');
      Events.trigger('refetchBatchDetails');
      setIsUpdateUnitValidation(false);
      setisAdjustmentValidator(false);
      setIsDuplicateValidation(false);
      clearSkipErrorValidation(true);
    }
  }, [skipErrorValidationResponse]);

  const defaultRightClickMenu = useCallback(({ original }) => () => (
    <Menu className="profile-menu-container">
      <Menu.Item key="delete transaction" onClick={handleDeleteRemittancePayment(original)}>
        <span>Delete Payment</span>
      </Menu.Item>
    </Menu>
  ), [handleDeleteRemittancePayment]);

  const errorRightClickMenu = useCallback(({ original }) => () => {
    setIsSkippingValidation(original?.isSkippedAdjustmentValidation);
    setIsDuplicateValidation(original?.isSkippedDuplicateValidation);
    const unexpectedError = original?.errors?.some(({ errorCode } = {}) => errorCode === 'UnexpectedError_RemittanceBatch' || errorCode === 'UnexpectedError_DupChargesMapped' || errorCode === 'UnexpectedError_ChargesUnmapped');
    return (
      <Menu className="profile-menu-container">
        {!unexpectedError && (
        <Menu.Item key="edit transaction" onClick={handleEditTransaction(original)}>
          <span>{labels.get('buttons.editTransaction')}</span>
        </Menu.Item>
        )}
        <Menu.Item key="delete transaction" onClick={handleDeleteRemittancePayment(original)}>
          <span>{labels.get('buttons.deleteTransaction')}</span>
        </Menu.Item>
        {!unexpectedError && (
        <>
          <Menu.Item key="use skip duplicate validation" onClick={handleSkipValidation(original, false, original?.isSkippedDuplicateValidation)}>
            <span>
              {original?.isSkippedDuplicateValidation
                ? labels.get('buttons.useDuplicateValidation')
                : labels.get('buttons.skipDuplicateValidation')}
            </span>
          </Menu.Item>
          <Menu.Item key="use skip adjustment validation" onClick={handleSkipValidation(original, true, original?.isSkippedAdjustmentValidation)}>
            <span>
              {original?.isSkippedAdjustmentValidation
                ? labels.get('buttons.useAdjustmentValidation')
                : labels.get('buttons.skipAdjustmentValidation')}
            </span>
          </Menu.Item>
          <Menu.Item key="updateToNewClaim" onClick={handleApplyTransaction(original)}>
            <span>{labels.get('buttons.updateToNewClaim')}</span>
          </Menu.Item>
        </>
        )}
        <Menu.Item key="addToWorkList" onClick={handleTransactionAddToWorkList(original, unexpectedError)}>
          <span>{labels.get('buttons.addToWorkList')}</span>
        </Menu.Item>
        {!unexpectedError && (
        <Menu.Item key="updateUnits" onClick={handleUpdateChargeUnits(original)}>
          <span>{labels.get('buttons.updateUnits')}</span>
        </Menu.Item>
        )}
      </Menu>
    );
  }, [handleApplyTransaction, handleDeleteRemittancePayment, handleEditTransaction,
    handleSkipValidation, handleTransactionAddToWorkList, handleUpdateChargeUnits, labels]);

  const rightClickMenus = {
    errors: errorRightClickMenu,
  };

  const rightClickMenu = rightClickMenus[tabName];

  const hiddenColumns = useMemo(() => {
    if (isPatientPaymentItems) {
      return patientPaymentHiddenColumns;
    }
    if (isPostedInsuranceClaims) {
      return errorHiddenColumns;
    }
    return [];
  }, [isPatientPaymentItems, isPostedInsuranceClaims]);

  useEffect(() => {
    if (deleteRemittancePaymentResponse) {
      Notification({ message: successMessages.PAYMENT_DELETED_SUCCESSFULLY, success: true });
      Events.trigger('GET_AMOUNT_AFTER_POSTING_BATCH');
      if (errorTab || isInsurancePayment) {
        Events.trigger(`GET_VIEW_ENTERED_ITEMS_${remittancePaymentId}`);
        Events.trigger(`refetchPostedClaimsError${remittancePaymentId}`);
        Events.trigger('refetchBatchDetails');
        Events.trigger(`refetch-view-entered-item-details${remittancePaymentId}`, `refetch-view-entered-item-details${remittancePaymentId}`);
        Events.trigger(`set-page-range${remittancePaymentId}`);
      } else { Events.trigger(`refetch-view-entered-item-details${remittancePaymentId}`, `refetch-view-entered-item-details${remittancePaymentId}`); }
      Events.trigger('refetchBatchDetails');
      Events.trigger(`refetchPostedClaimsError${remittancePaymentId}`);
      clearDeleteRemittancePayment();
    }
  }, [clearDeleteRemittancePayment, deleteRemittancePaymentResponse]);

  const disableButtons = useMemo(() => {
    const noData = (isRemittanceErrorTab
      && (!data || data.length === 0))
      || (!isRemittanceErrorTab
        && (!getNormalizedData(data)?.length));

    return {
      disableFirstPrev: currentPage === 1
      || noData,
      disableNextLast: currentPage === totalPages
      || noData,
    };
  }, [data, currentPage, totalPages, isRemittanceErrorTab]);

  return (
    <>
      {(isPageChanging
        || skipErrorValidationLoading
        || deleteRemittancePaymentLoading) && <Loader />}
      <Table
        columns={columns(labels, tabName)}
        data={tableData}
        footer
        onRowClick={onRowClick}
        hiddenColumns={hiddenColumns}
        loading={loading}
        rightClickMenu={
          rightClickMenu
          || (remittanceBatchData?.stageMasterCode !== 'BillingStagePosted'
            && remittanceBatchData?.batchType?.toLowerCase() !== 'electronic'
            && defaultRightClickMenu)
        }
        handleDeleteRemittancePayment={handleDeleteRemittancePayment}
        // onFetchMore={onFetchMore}
        // hasMore={hasMore}
        totalData={isRemittanceErrorTab ? data : getNormalizedData(data) || []}
        scrollId="post-claim-table"
      />
      <div className="pagination-controls mr-top-8">
        <Button className="mr-rt-5" type="button" onClick={() => handlePageChange(1)} disabled={disableButtons.disableFirstPrev}>
          First
        </Button>
        <Button className="mr-rt-5" type="button" onClick={() => handlePageChange(currentPage - 1)} disabled={disableButtons.disableFirstPrev}>
          Previous
        </Button>
        {[...Array(totalPages)].map((_, index) => {
          const page = index + 1;
          return (
            <Button
              key={page}
              type="button"
              onClick={() => handlePageChange(page)}
              className={`mr-rt-5 ${currentPage === page ? 'active-page' : ''}`}
              style={currentPage === page ? { backgroundColor: '#32c788', color: 'white' } : {}}
            >
              {page}
            </Button>
          );
        })}
        {/* <span className="mr-rt-5 mr-left-5">
          Page
          {currentPage}
          {' '}
          of
          {totalPages}
        </span> */}
        <Button
          type="button"
          className="mr-rt-5"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={disableButtons.disableNextLast}
        >
          Next
        </Button>
        <Button type="button" onClick={() => handlePageChange(totalPages)} disabled={disableButtons.disableNextLast}>
          Last
        </Button>
      </div>
      <Route
        exact
        path={[
          `${UiRoutes.claimsRemittanceNewInsurance}${UiRoutes.postClaimTablePopUp}`,
          `${UiRoutes.claimsRemittanceNewInsurance}${UiRoutes.postClaimTablePopUpWithSearchText}`,
          `${UiRoutes.claimsRemittanceNewInsurance}${UiRoutes.postClaimTablePopUpWithSearchTextWithErrorId}`,
          `${UiRoutes.claimsRemittanceNewInsurance}${UiRoutes.postClaimTablePopUpWithSelectedClaim}`,
          `${UiRoutes.claimsRemittanceNewInsurance}${UiRoutes.postClaimTablePopUpWithSelectedClaimWithErrorId}`,
          `${UiRoutes.claimsRemittanceErrorEditInsurance}${UiRoutes.postClaimTablePopUp}`,
          `${UiRoutes.claimsRemittanceErrorEditInsurance}${UiRoutes.postClaimTablePopUpWithSearchText}`,
          `${UiRoutes.claimsRemittanceErrorEditInsurance}${UiRoutes.postClaimTablePopUpWithSearchTextWithErrorId}`,
          `${UiRoutes.claimsRemittanceErrorEditInsurance}${UiRoutes.postClaimTablePopUpWithSelectedClaim}`,
          `${UiRoutes.claimsRemittanceErrorEditInsurance}${UiRoutes.postClaimTablePopUpWithSelectedClaimWithErrorId}`,
          `${UiRoutes.claimsRemittanceErrorEditInsurance}/error${UiRoutes.postClaimTablePopUp}`,
          `${UiRoutes.claimsRemittanceErrorEditInsurance}/error${UiRoutes.postClaimTablePopUpWithSearchText}`,
          `${UiRoutes.claimsRemittanceErrorEditInsurance}/error${UiRoutes.postClaimTablePopUpWithSearchTextWithErrorId}`,
          `${UiRoutes.claimsRemittanceErrorEditInsurance}/error${UiRoutes.postClaimTablePopUpWithSelectedClaim}`,
          `${UiRoutes.claimsRemittanceErrorEditInsurance}/error${UiRoutes.postClaimTablePopUpWithSelectedClaimWithErrorId}`,
          `${UiRoutes.claimsRemittanceNewPatient}${UiRoutes.postClaimTablePopUp}`,
          `${UiRoutes.claimsRemittanceNewPatient}${UiRoutes.postClaimTablePopUpWithSearchText}`,
          `${UiRoutes.claimsRemittanceNewPatient}${UiRoutes.postClaimTablePopUpWithSearchTextWithErrorId}`,
          `${UiRoutes.claimsRemittanceNewPatient}${UiRoutes.postClaimTablePopUpWithSelectedClaim}`,
          `${UiRoutes.claimsRemittanceNewPatient}${UiRoutes.postClaimTablePopUpWithSelectedClaimWithErrorId}`,
          `${UiRoutes.claimsRemittanceEditPatient}${UiRoutes.postClaimTablePopUp}`,
          `${UiRoutes.claimsRemittanceEditPatient}${UiRoutes.postClaimTablePopUpWithSearchText}`,
          `${UiRoutes.claimsRemittanceEditPatient}${UiRoutes.postClaimTablePopUpWithSearchTextWithErrorId}`,
          `${UiRoutes.claimsRemittanceEditPatient}${UiRoutes.postClaimTablePopUpWithSelectedClaim}`,
          `${UiRoutes.claimsRemittanceEditPatient}${UiRoutes.postClaimTablePopUpWithSelectedClaimWithErrorId}`,
          `${UiRoutes.claimsRemittanceEditInsurance}${UiRoutes.postClaimTablePopUp}`,
          `${UiRoutes.claimsRemittanceEditInsurance}${UiRoutes.postClaimTablePopUpWithSearchText}`,
          `${UiRoutes.claimsRemittanceEditInsurance}${UiRoutes.postClaimTablePopUpWithSearchTextWithErrorId}`,
          `${UiRoutes.claimsRemittanceEditInsurance}${UiRoutes.postClaimTablePopUpWithSelectedClaim}`,
          `${UiRoutes.claimsRemittanceEditInsurance}${UiRoutes.postClaimTablePopUpWithSelectedClaimWithErrorId}`,
        ]}
      >
        <EditWorklist
          isVisible
          toggleModal={handleBack}
          labels={labels}
          patientDetails={{
            patientId: params?.patientId === 'notFound' ? undefined : params?.patientId,
            amount: params?.amount === 'notFound' ? undefined : params?.amount,
            claimId: params?.ClaimId === 'notFound' ? undefined : params?.ClaimId,
            balance: params?.balance === 'notFound' ? undefined : params?.balance,
            pageNumber: params?.pageNumber === 'notFound' ? undefined : params?.pageNumber,
            claimNo: params?.claimNo === 'notFound' ? undefined : params?.claimNo,
            worklistId: params?.worklistId === 'notFound' ? undefined : params?.worklistId,
            remittancePaymentIdentifier:
              params?.remittancePaymentIdentifier === 'notFound'
                ? undefined
                : params?.remittancePaymentIdentifier,
          }}
          isRemittancePayment
          isEditTransaction={params?.title === 'Edit Transaction'}
          title={params?.title}
          transactionTypeId={transactionTypeId}
          isApplyTransaction={params?.title === 'Update To New Claim'}
          isEditWorkList={params?.title === labels.get('titles.editWorklist')}
          isResolvingError={tabName === 'errors' ? true : undefined}
          insurancePayerId={formDetails?.payerId}
          pageNo={formDetails?.pageNo || currentPageNo}
          isPatientPaymentItems={isPatientPaymentItems}
          setCurrentPath={setCurrentPath}
          onPostPatientWorklist={onPost}
          paymentForm={paymentForm}
          formValidation={formValidation}
          totalNoOfPages={totalNoOfPages}
          patientTransactionTypeId={patientTransactionTypeId}
          setNeedToScroll={setNeedToScroll}
          isDataSaveInRoute
        />
      </Route>
      <WorkList
        labels={labels}
        path={path}
        isVisible={isWorklistModalVisible}
        toggle={toggleWorklistModal}
        isAddClaimToWorkList
        formData={{
          ...formDetails,
          amount: (claimDetails?.applied || 0).toFixed(2),
        }}
        claimId={claimDetails?.claimId}
        claimNo={claimDetails?.claimNo}
        isUnexpectedError={isUnexpectedError}
        remittancePaymentIdentifier={claimDetails?.remittancePaymentIdentifier}
        setNeedToScroll={setNeedToScroll}
      />
    </>
  );
};

export default PostedClaimTable;
