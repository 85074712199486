import React, { useState } from 'react';
import Tabs from '../../../../../../../../components/Tabs/TabsWithoutUrlNavigation';

import ProcedureSets from './Tabs/ProcedureSets';
import Dx from './Tabs/Dx';
import Procedures from '../../AP/RightTab/Tabs/Procedure';

const RightTab = ({
  dxList,
  setDxList,
  procedureList,
  setProcedureList,
  setProcedureSetsList,
  procedureSetsList,
}) => {
  const tabsList = [
    {
      id: 'procedureSets',
      name: 'Procedure Sets',
      component: ProcedureSets,
    },
    {
      id: 'dx',
      name: 'Dx',
      component: Dx,
    },
    {
      id: 'procedures',
      name: 'Procedures',
      component: Procedures,
    },
  ];
  const [selectedTab, setSelectedTab] = useState('procedureSets');

  return (
    <div className="right-tab">
      <Tabs
        id="procedureSets"
        tabsList={tabsList}
        className="main-tab-wrap"
        selectedTab={selectedTab}
        handleChange={setSelectedTab}
        dxList={dxList}
        setDxList={setDxList}
        procedureList={procedureList}
        setProcedureList={setProcedureList}
        setProcedureSetsList={setProcedureSetsList}
        procedureSetsList={procedureSetsList}
        type="inOffice"
      />
    </div>
  );
};

export default RightTab;
