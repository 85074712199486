import React from 'react';

import Icon from '../../../../../components/Icon';

const columns = (labels, isSoftEdit) => [
  {
    Header: labels.get('tableColumns.icon'),
    accessor: 'icon',
    Cell: () => (
      <Icon name="ExclamationCircleOutlined" color={isSoftEdit ? 'yellow' : 'red'} />
    ),
    fixWidth: '60',
  },
  {
    Header: labels.get('tableColumns.code'),
    accessor: 'cptCode',
  },
  {
    Header: labels.get('tableColumns.type'),
    accessor: 'errorType',
  },
  {
    Header: labels.get('tableColumns.description'),
    accessor: 'description',
    Cell: ({ row: { original: { description } } }) => (
      <span title={description}>{description}</span>
    ),
  },
];

export default columns;
