import React, { useCallback, useEffect } from 'react';
import set from 'lodash/set';

import Input from '../../../../components/Input';
import useReduxStoreWithId from '../../../../hooks/useReduxStoreWithId';
import useRedirect from '../../../../hooks/useRedirect';

const InputWrapper = ({
  question, examId, handleValues, values, savedValues, displayOnly,
}) => {
  const { params: { encounterId } } = useRedirect();
  const [savedSelectedValue,, setFormData] = useReduxStoreWithId({ id: displayOnly ? `slit-exam-${examId}-${encounterId}-${displayOnly}` : `slit-exam-${examId}-${encounterId}` });
  const formData = savedSelectedValue?.get('data') || {};

  const onBlur = useCallback(({ target: { name, value } }) => {
    const updated = { ...formData, [name]: value };
    setFormData(updated);
    const clonedValues = { OD: {}, OS: {}, ...values };
    set(clonedValues, `${name}[${examId}]`, [{ Key: question.Key, answers: [{ Answer: value }] }]);
    handleValues(clonedValues);
  }, [examId, formData, handleValues, question.Key, values]);

  const onChange = useCallback(({ target: { name, value } }) => {
    const updated = { ...formData, [name]: value };
    setFormData(updated);
  }, [formData]);

  const handleArrowClick = useCallback((fromField, toField) => {
    const clonedValues = { OD: {}, OS: {}, ...values };
    setFormData({ ...formData, [toField]: formData[fromField] });
    set(clonedValues, `${toField}[${examId}]`, clonedValues[fromField][examId]);
    handleValues(clonedValues);
  }, [values, formData, examId, handleValues]);

  useEffect(() => {
    const OD = savedValues.OD && savedValues.OD[examId];
    const OS = savedValues.OS && savedValues.OS[examId];
    let parsedOD = null;
    let parsedOS = null;
    if (OD) {
      OD.forEach(({ answers }) => {
        if (answers && answers.length) {
          parsedOD = answers[0].Answer;
        }
      });
    }
    if (OS) {
      OS.forEach(({ answers }) => {
        if (answers && answers.length) {
          parsedOS = answers[0].Answer;
        }
      });
    }
    setFormData({ OS: parsedOS, OD: parsedOD });
  }, [examId, savedValues]);

  const OStoOD = useCallback(() => handleArrowClick('OS', 'OD'), [handleArrowClick]);
  const ODtoOS = useCallback(() => handleArrowClick('OD', 'OS'), [handleArrowClick]);

  return (
    <>
      <div className="select-box one site-input">
        <div className="ant-select">
          <Input
            name="OD"
            placeholder=""
            onBlur={onBlur}
            onChange={onChange}
            value={formData.OD}
            inputSpan="23"
            labelSpan="1"
          />
        </div>
        <div className="right-arrow" role="presentation" onClick={ODtoOS} />
      </div>
      <div className="select-box two site-input">
        <div className="ant-select">
          <Input
            name="OS"
            inputSpan="23"
            labelSpan="1"
            onBlur={onBlur}
            onChange={onChange}
            value={formData.OS}
          />
        </div>
        <div className="right-arrow" role="presentation" onClick={OStoOD} />
      </div>
    </>
  );
};

export default InputWrapper;
