import React, { useEffect } from 'react';

import Form from '../../../../../components/Form';
import TimeCell from '../../../../../components/Cells/TimeCell';
import { apiUrls } from '../../../../../api/constants';

import useCRUD from '../../../../../hooks/useCRUD';
import useRedirect from '../../../../../hooks/useRedirect';

const noOfColumns = 2;

const BasicInfo = ({ labels }) => {
  const { params: { id } } = useRedirect();
  const [basicInfo, ,, getBasicInfo] = useCRUD({ id: 'basic-info-demographic', url: apiUrls.GET_PATIENT, type: 'read' });

  useEffect(() => {
    if (id && !basicInfo) getBasicInfo({}, `/${id}`);
  }, [basicInfo, getBasicInfo, id]);

  return (
    <div className="pannel">
      <div className="pannel-heading">
        <p>{labels.get('titles.basicInfo')}</p>
      </div>
      <div className="pannel-body">
        <Form
          noOfColumns={noOfColumns}
        >
          <Form.Column>
            <div>
              <div className="info-row">
                <label>{labels.get('labels.name')}</label>
                <div className="lavel-value">{`${basicInfo?.firstName} ${basicInfo?.lastName}`}</div>
              </div>
              <div className="info-row">
                <label>{labels.get('labels.addressLine1')}</label>
                <div className="lavel-value">{basicInfo?.addressLine1}</div>
              </div>
              <div className="info-row">
                <label>{labels.get('labels.addressLine2')}</label>
                <div className="lavel-value">{basicInfo?.addressLine2}</div>
              </div>
            </div>
          </Form.Column>
          <Form.Column>
            <div>
              <div className="info-row">
                <label>{labels.get('labels.city')}</label>
                <div className="lavel-value">{basicInfo?.city}</div>
              </div>
              <div className="info-row">
                <label>{labels.get('labels.state')}</label>
                <div className="lavel-value">{basicInfo?.stateName}</div>
              </div>
              <div className="info-row">
                <label>{labels.get('labels.dob')}</label>
                <div className="lavel-value"><TimeCell value={basicInfo?.dateOfBirth} format="MM-DD-YYYY" /></div>
              </div>
            </div>
          </Form.Column>
          <Form.Column>
            <div>
              <div className="info-row">
                <label>{labels.get('labels.gender')}</label>
                <div className="lavel-value">{basicInfo?.genderName}</div>
              </div>
            </div>
          </Form.Column>
        </Form>
      </div>
    </div>
  );
};
export default BasicInfo;
