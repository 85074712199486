import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'antd';
import { CameraOutlined } from '@ant-design/icons';

import Image from '../../../../components/Image';

function urltoFile(url, filename, mimeType) {
  // eslint-disable-next-line no-param-reassign
  mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1];
  return (fetch(url)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], filename, { type: mimeType }))
  );
}

const WebcamCapture = ({ uploadFile }) => {
  const videoPlayer = useRef(null);
  const canvas = useRef(null);
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);

  const setDevice = async (device) => {
    try {
      const { deviceId } = device;
      const stream = await navigator?.mediaDevices?.getUserMedia({
        audio: false,
        video: { deviceId },
      });
      videoPlayer.current.srcObject = stream;
      await videoPlayer?.current?.play();
    } catch (error) {
      console.log('error', error);
    }
  };

  const processDevices = (devices) => {
    setDevice(devices);
  };

  useEffect(() => {
    videoPlayer.current.addEventListener('canplay', () => {
      const height = videoPlayer?.current?.videoHeight / (videoPlayer?.current?.videoWidth / 300);
      // eslint-disable-next-line no-unused-expressions
      videoPlayer?.current?.setAttribute('height', height);
    }, false);
  }, []);

  const stopStream = () => {
    if (!videoPlayer.current) return;
    const getTracks = videoPlayer?.current?.srcObject?.getTracks();
    if (Array.isArray(getTracks)) {
      getTracks.forEach((track) => {
        track.stop();
      });
    }
    videoPlayer.current = null;
  };

  useEffect(() => {
    (async () => {
      if (navigator?.mediaDevices && navigator?.mediaDevices?.enumerateDevices) {
        const cameras = await navigator.mediaDevices.enumerateDevices();
        processDevices(cameras[0]);
      }
    })();
    return () => {
      stopStream();
    };
  }, []);

  const takePhoto = async () => {
    canvas.current.setAttribute('width', '300');
    canvas.current.setAttribute('height', '225');
    const context = canvas.current.getContext('2d');
    context.drawImage(videoPlayer.current, 0, 0, 300, 225);

    const data = canvas.current.toDataURL('image/jpeg');
    setImage(data);
    videoPlayer.current.setAttribute('width', '0');
    videoPlayer.current.setAttribute('height', '0');
    stopStream();
    const imageFile = await urltoFile(data, 'test.jpeg');
    setFile({ file: imageFile });
  };

  return (
    <div className="webcam-container">
      {(!image) && (
        <div className="take-image-container">
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video ref={videoPlayer} />
          <Button className="click-image-btn" onClick={takePhoto}>
            <CameraOutlined />
            <span>Take photo!</span>
          </Button>
        </div>
      )}
      <canvas id="canvas" ref={canvas} />
      {image && (
        <div className="upload-image-container">
          <Image url={image} width="300" height="225" alt="patient-image" />
          <Button className="upload-image-btn" onClick={() => uploadFile(file)}>
            Upload photo!
          </Button>
        </div>
      )}
    </div>
  );
};

export default WebcamCapture;

