import React from 'react';

const resultColumn = () => [
  {
    Header: 'Rule Name',
    fixWidth: '40',
    Cell: ({ row: { original } }) => <span>{original.ruleName}</span>,
  },
  {
    Header: 'Status',
    fixWidth: '20',
    Cell: ({ row: { original } }) => (
      original.evaluationSuccess ? <span className="green-text font-medium">Passed</span> : <span className="red-text font-medium">Failed</span>
    ),
  },
  {
    Header: 'Errors',
    fixWidth: '140',
    Cell: ({ row: { original } }) => (
      <span>
        {original?.relatedFailures.length ? (
          <ul className="rule-message">
            {original?.relatedFailures.map((errorMsg) => (
              <li className="font-medium">{errorMsg.errorDisplayMessage}</li>
            ))}
          </ul>
        ) : (
          '--'
        )}
      </span>
    ),
  },
];

export default resultColumn;
