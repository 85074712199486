import React, { useCallback } from 'react';
import { Form as AntdForm } from 'antd';
import moment from 'moment';

import { apiUrls } from '../../../../../api/constants';

import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import SelectBox from '../../../../../components/Form/SelectBox';
import DatePicker from '../../../../../components/Form/DatePicker';
import AutoComplete from '../../../../../components/Form/GridAutoComplete';

const options = [{ name: 'Account', value: null }, { name: 'Patient', value: 'true' }, { name: 'Insurance', value: 'false' }];

const columns = [
  {
    Header: 'Code',
    accessor: 'cptCode',
    flex: 1,
  },
  {
    Header: 'Description',
    accessor: 'description',
    flex: 5,
  },
];

const optionParser = (option) => ({
  value: option.cptCode,
  code: option.cptCode,
  name: option.cptCode,
  desc: option.description,
  ...option,
});

const FilterForm = ({
  labels, patientId, filter, setFilter, tabId,
}) => {
  const [form] = AntdForm.useForm();

  const handleSearch = useCallback(() => {
    const formValues = form.getFieldsValue();
    if (formValues?.createdDateTo)formValues.createdDateTo = moment(formValues.createdDateTo).format('MM-DD-YYYY').toString();
    if (formValues?.createdDateFrom)formValues.createdDateFrom = moment(formValues.createdDateFrom).format('MM-DD-YYYY').toString();
    setFilter({ ...formValues });
  }, [form, setFilter]);

  const handleReset = useCallback((resetForm) => () => {
    resetForm();
    setFilter({});
  }, [setFilter]);

  const disabledFromDate = useCallback((current) => {
    const formValues = form.getFieldsValue();
    return formValues?.createdDateTo && current > moment(formValues?.createdDateTo);
  }, [form]);

  const disabledToDate = useCallback((current) => {
    const formValues = form.getFieldsValue();
    return (formValues?.createdDateFrom && current < moment(formValues?.createdDateFrom));
  }, [form]);

  return (
    <Form
      name={`patient-financial-filter-form-${patientId}`}
      formId={`patient-financial-filter-form-${patientId}`}
      tabId={tabId}
      form={form}
      initialData={{
        createdDateFrom: filter.createdDateFrom ? moment(filter.createdDateFrom) : undefined,
        createdDateTo: filter.createdDateTo ? moment(filter.createdDateTo) : undefined,
      }}
    >
      {({ resetForm }) => (
        <div className="filter-area-left filter-input-mr">
          <div className="input-wrap sm-date-picker mr-rt-16 mr-tp-5">
            <DatePicker
              label={labels.get('labels.effectiveDate')}
              id="10"
              name="createdDateFrom"
              labelSpan="10"
              inputSpan="14"
              disabledDate={disabledFromDate}
              dataTestId="createdDateFrom"
            />
          </div>
          <div className="input-wrap sm-date-picker mr-tp-5 mr-rt-16">
            <DatePicker
              label={labels.get('labels.to')}
              id="10"
              name="createdDateTo"
              labelSpan="5"
              inputSpan="19"
              disabledDate={disabledToDate}
              dataTestId="createdDateTo"
            />
          </div>
          <div className="input-wrap mr-rt-16">
            <SelectBox
              placeholder="Balance"
              label=""
              options={options}
              labelSpan="0"
              inputSpan="24"
              name="IsPatientResponsibility"
              selectProps={{ style: { width: '120px' } }}
            />
          </div>
          <div className="search-input mr-tp-5 height-28-only grid-autoComplete-clear">
            <AutoComplete
              url={apiUrls.GET_CPT_PAGINATED}
              name="searchTextByCPTCode"
              optionMaster="CPTCodeText"
              params={{
                IncludeInActive: false,
                PageSize: 20,
                PageIndex: 0,
              }}
              optionParser={optionParser}
              placeholder="Search CPT"
              columns={columns}
              dropdownMatchSelectWidth={300}
              labelSpan="1"
              inputSpan="23"
              labelInValue
              showArrow
              allowClear
              selectProps={{
                optionLabelProp: 'name',
                style: { minWidth: '120px' },
                allowClear: true,
              }}
            />
          </div>
          <div className="mr-left-20">
            <Button className="btn btn-success sm-btn" id="patients_financial_search" onClick={handleSearch}>
              {labels.get('buttons.search')}
            </Button>
          </div>
          <div className="mr-left-20">
            <Button className="btn btn-success sm-btn" id="patients_financial_search" onClick={handleReset(resetForm)}>
              {labels.get('buttons.reset')}
            </Button>
          </div>
        </div>
      )}
    </Form>
  );
};
export default React.memo(FilterForm);
