import React, { useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';

import { Form as AntdForm } from 'antd';
import {
  listIds, labelPaths, UiRoutes,
} from '../../../lib/constants';
import { apiUrls } from '../../../api/constants';

import withQuery from '../../../hoc/withQuery/withQuery';
import withReduxManager from '../../../hoc/withReduxManager';

import Form from '../../../components/Form';
import FilterComponents from '../../../components/FilterComponents';
import FilterManager from '../../../components/FilterManager';
import Button from '../../../components/Button';
import SelectBox from '../../../components/SelectBox';

import WithLabel from '../../../hoc/withLabel';
import FilterPanel from './Components/FilterPanel';

import columns from './columns';
import MarkAsRead from './Components/MarkAsRead';
import TableWrapper from './Components/TableWrapper';
import useRedirect from '../../../hooks/useRedirect';
import useTabLink from '../../../hooks/useTabLink';
import ReassignModal from './Components/ReassignModal';
import WiredSelectWithoutTab from '../../Select/selectBoxV2';
import MarkAsClosed from './Components/MarkAsClosed';

const CustomTaskTable = withReduxManager({
  listId: listIds.ALL_TASK_TABLE,
})(withQuery({
  url: apiUrls.GET_TASK_LIST,
  listId: listIds.ALL_TASK_TABLE,
  alias: {
    status: 'TaskStatus',
    priority: 'TaskPriority',
    createdByName: 'CreatedByName',
    createdOn: 'CreatedOn',
    patientName: 'PatientName',
    insuranceName: 'InsuranceName',
    assignedTo: 'AssignedToName',
    chartNumber: 'ChartNumber',
    location: 'LocationName',
    dueDate: 'DueDate',
  },
})());

const additionalFilterOptions = [
  {
    name: 'Mine',
    value: 'true',
  },
  {
    name: 'Everyone\'s',
    value: 'false',
  },
];

const FilterCollections = FilterComponents([
  {
    type: 'select',
    filterProps: {
      placeholder: 'Queues',
      id: 'select_additional_filter',
      name: 'IsMyQueue',
      options: additionalFilterOptions,
      selectProps: {
        style: {
          minWidth: 150,
          marginLeft: 10,
        },
      },
    },
  },
]);

const categoryOptionRender = (option) => (
  <div className="category-select-option-container">
    <span className="option-name">{option?.name}</span>
    <span className="option-count">{`(${option?.taskCount || 0})`}</span>
  </div>
);

const TaskTable = ({ labels, ...props }) => {
  const [table] = AntdForm.useForm();
  const {
    params, location, push, generatePath,
  } = useRedirect();

  const [isFilterPanelVisible, setFilterModalVisible] = useState(false);
  const [isReassignModalVisible, setReassignModalVisible] = useState(false);
  const [selectedTask, setSelectedTasks] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const { navigate } = useTabLink({});

  useEffect(() => {
    const { state: { lastPath } = {} } = location;
    if (lastPath && categoryOptions?.length) {
      const segments = lastPath.split('/').splice(1).reverse(); // remove empty string and reverse
      segments.some((segment) => {
        const selected = categoryOptions.find(({ masterCode }) => (
          segment.toLowerCase().includes(masterCode.toLowerCase())
        ));
        if (selected) {
          setSelectedCategory(selected?.value);
          return true;
        }
        return false;
      });
    }
  }, [location, categoryOptions]);

  const toggleFilterModal = useCallback(() => {
    setFilterModalVisible(!isFilterPanelVisible);
  }, [isFilterPanelVisible]);

  const toggleReassignModal = useCallback(() => {
    setReassignModalVisible(!isReassignModalVisible);
  }, [isReassignModalVisible]);

  const navigateToEditTask = useCallback((original) => () => {
    push(generatePath(UiRoutes.tasksDetailWithTabId, { ...params, taskId: original?.taskId }));
  }, [generatePath, params, push]);

  const handleTakeAction = useCallback((original) => () => {
    const { categoryName, taskDetails: { patientId } } = original || {};
    if (categoryName?.toLowerCase() === 'authorization') {
      return navigate({
        to: UiRoutes.patientListWithListTabId,
        id: patientId,
        tab: 'lists',
        listTab: 'authorization',
        tabModuleName: 'Task',
        tabName: 'Task',
        data: { name: original?.taskDetails?.patientName },
      });
    }
    if (categoryName?.toLowerCase() === 'verification') {
      return navigate({
        to: UiRoutes.editPatientWithTabId,
        id: patientId,
        tab: 'insurance',
        tabModuleName: 'Task',
        tabName: 'Task',
        data: { name: original?.taskDetails?.patientName },
      });
    }
    return null;
  }, [navigate]);

  const createCategoryOptions = useCallback((options) => {
    if (options?.length) {
      const newOptions = options.map((option) => ({
        value: option?.taskCategoryId,
        name: categoryOptionRender(option),
        masterCode: option?.masterCode,
      }));
      setCategoryOptions(newOptions);
    }
  }, []);

  return (
    <div className="task-table-wrapper">
      <CustomTaskTable
        columns={columns(labels)}
        noDataText="Tasks Data not found"
        showRowSelection
        setSelectedRow={setSelectedTasks}
        checkedRowIds={selectedTask}
        navigateToEditTask={navigateToEditTask}
        handleTakeAction={handleTakeAction}
        // checkedRowIds={!selectedTask?.length && {}}
        // selectName="task-checked-row"
        filters={selectedCategory && { TaskCategoryId: selectedCategory }}
        {...props}
      >
        {({
          Component, initialFilters, onFiltersChange, reFetch,
        }) => (
          <FilterManager initialFilters={initialFilters} onChange={onFiltersChange}>
            {({ onFilterChange, filters }) => (
              <>
                <div className="table-filters">
                  <div className="task-list-filters">
                    <div className="mr-bt-12">
                      <MarkAsRead
                        selectedTask={selectedTask}
                        setSelectedTasks={setSelectedTasks}
                      />
                    </div>
                    <FilterCollections onFilterChange={onFilterChange} filters={filters} />
                    {/* <span className="table-count">
                      <span>{`${labels.get('titles.totalCount')}: ${totalCount}`}</span>
                    </span> */}
                    <MarkAsClosed
                      selectedTask={selectedTask}
                      labels={labels}
                      setSelectedTasks={setSelectedTasks}
                      table={table}
                    />
                    <div className="btns-action mr-bt-12 ">
                      <Button
                        className="btn btn-success min-wt-86 sm-btn mr-lt-12"
                        data-testid="reassign-modal-button"
                        onClick={toggleReassignModal}
                      >
                        {labels.get('buttons.reAssign')}
                      </Button>
                    </div>
                  </div>
                  <div className="btns-action mr-left-60 mr-bt-12 feild-row">
                    <div className="mr-rt-12">
                      <WiredSelectWithoutTab
                        id="category"
                        name={null}
                        url={apiUrls.GET_TASK_MANAGEMENT_CATEGORY_WITH_COUNT}
                        params={{ IsMyQueue: filters?.IsMyQueue ?? false }}
                        value={filters?.TaskCategoryId}
                        nameAccessor="name"
                        valueAccessor="taskCategoryId"
                        labelSpan="0"
                        inputSpan="24"
                        isStatCaseOptions={false}
                        selectProps={{
                          style: { minWidth: 250 },
                          showArrow: true,
                          dropdownMatchSelectWidth: false,
                        }}
                        placeholder="Select category"
                        Component={SelectBox}
                        defaultOptions={categoryOptions}
                        onChange={(data) => {
                          onFilterChange({ TaskCategoryId: data });
                          setSelectedCategory(undefined);
                        }}
                        onFetchComplete={createCategoryOptions}
                      />
                    </div>
                    <Button
                      className={classNames('btn min-wt-86 sm-btn', { 'btn-success': !isFilterPanelVisible })}
                      onClick={toggleFilterModal}
                      data-testid="filter-button"
                    >
                      {isFilterPanelVisible ? labels.get('buttons.closeFilters') : labels.get('buttons.filters')}
                    </Button>
                  </div>
                </div>
                <FilterPanel
                  isVisible={isFilterPanelVisible}
                  labels={labels}
                  setAdditionalFilters={onFilterChange}
                  additionalFilters={filters}
                  toggleFilterModal={toggleFilterModal}
                />
                <Form form={table}>
                  <TableWrapper
                    Component={Component}
                    reFetch={reFetch}
                  />
                </Form>
              </>
            )}
          </FilterManager>
        )}
      </CustomTaskTable>
      <ReassignModal
        toggleModal={toggleReassignModal}
        isVisible={isReassignModalVisible}
        labels={labels}
        selectedTask={selectedTask}
      />
    </div>
  );
};

export default WithLabel(TaskTable, labelPaths.TASK_LIST);
