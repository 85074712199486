import React from 'react';
import TimeCell from '../../../components/Cells/TimeCell';
import StatusCell from '../../../components/StatusCell';
import { dateFormatWithSlash } from '../../../lib/constants';

const columns = (labels) => [
  {
    Header: labels.get('tableColumns.notes'),
    accessor: 'notes',
  },
  {
    Header: labels.get('tableColumns.insuranceProfileName'),
    accessor: 'insuranceProfileName',
  },
  {
    Header: labels.get('tableColumns.policyTypeName'),
    accessor: 'policyTypeName',
  },
  {
    Header: labels.get('tableColumns.payerName'),
    accessor: 'payerName',
  },
  {
    Header: labels.get('tableColumns.profileCategoryName'),
    accessor: 'profileCategoryName',
  },
  {
    Header: labels.get('tableColumns.status'),
    accessor: 'isActive',
    fixWidth: '90',
    Cell: ({ row: { original: { isActive } } }) => (
      <StatusCell active={isActive} />
    ),
  },
  {
    Header: labels.get('tableColumns.createdOn'),
    accessor: 'createdDate',
    fixWidth: '120',
    Cell: ({ row: { original: { createdDate } } }) => (
      <TimeCell value={createdDate} format={dateFormatWithSlash} />
    ),
  },
  {
    Header: labels.get('tableColumns.effectiveFrom'),
    accessor: 'effectiveFrom',
    fixWidth: '120',
    Cell: ({ row: { original: { effectiveFrom } } }) => (
      <TimeCell value={effectiveFrom} format={dateFormatWithSlash} />
    ),
  },
  {
    Header: labels.get('tableColumns.effectiveTo'),
    accessor: 'effectiveTo',
    fixWidth: '120',
    Cell: ({ row: { original: { effectiveTo } } }) => (
      <TimeCell value={effectiveTo} format={dateFormatWithSlash} />
    ),
  },
];

export default columns;
