import React, { useRef, useMemo, forwardRef } from 'react';
import { useDragLayer } from 'react-dnd';

import Card from './AppointmentList/Components/Card';

const getItemStyles = (layerDimensions, cardWidth, currentOffset) => {
  if (!layerDimensions || !currentOffset) {
    return {
      display: 'none',
    };
  }
  let { x, y } = currentOffset;
  x -= layerDimensions.x;
  y -= layerDimensions.y;
  const transform = `translate(${x}px, ${y}px) rotate(-7deg)`;
  return {
    width: (cardWidth || (layerDimensions.width / 5) - 32) + 4, // add border width
    transform,
    WebkitTransform: transform,
  };
};

const CustomDragLayer = forwardRef((_, cardRef) => {
  const ref = useRef(null);
  const {
    itemType,
    item,
    currentOffset,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const layerDimensions = ref && ref.current && ref.current.getBoundingClientRect();
  // clientWidth of the card compromises as we remove the component being dragged.
  // useMemo helps freezing the width value as the dragging begins
  const cardWidth = useMemo(() => item
    && item.ref
    && item.ref.current
    && item.ref.current.clientWidth,
  [item]);

  const renderItem = () => {
    if (itemType && itemType !== '__NATIVE_TEXT__') {
      return (
        <Card
          data={{ ...item, ref: cardRef }}
          style={getItemStyles(layerDimensions, cardWidth, currentOffset)}
        />
      );
    }
    return null;
  };

  return (
    <div ref={ref} className="dashboard-drag-layer">
      {renderItem()}
    </div>
  );
});

export default CustomDragLayer;
