/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import moment from 'moment';
import './rcmResult.scss';
import { isEmpty } from 'lodash';
import useCRUD from '../../../../hooks/useCRUD';
import { apiUrls } from '../../../../api/constants';
import Loader from '../../../../components/Loader';
import Tooltip from '../../../../components/Tooltip';
import Table from '../../../../components/Table';
import resultColumn from './resultColumns';
import Collapsable from '../../../../components/Collapse';
import ErrorMessages from '../../../../lib/errorMessages';

const RulesResult = ({ encounterId }) => {
  const [data, , loading, getResults] = useCRUD({
    id: `rulesEvaluation-${encounterId}`,
    url: apiUrls.GET_RULES_EVALUATION,
    type: 'read',
  });

  useEffect(() => {
    getResults({}, `?BillingEncounterId=${Number(encounterId)}`);
  }, [encounterId, getResults]);

  return (
    <>
      {loading && <Loader />}
      {!isEmpty(data?.encounterEvaluations) ? (
        <div className="rcm-collapsable">
          {data?.encounterEvaluations?.map((evaluationSet, index) => (
            <div key={index} className="dropdown">
              <Collapsable
                style={{ width: '100%' }}
                header={(
                  <div className="header">
                    {console.log(!isEmpty(evaluationSet?.evaluation, 'sdsdsd'))}
                    <Tooltip
                      content={moment
                        .utc(!isEmpty(evaluationSet?.evaluation)
                          && evaluationSet?.evaluation[0]?.endTime)
                        .fromNow()}
                    >
                      <p>
                        {moment
                          .utc(!isEmpty(evaluationSet?.evaluation)
                           && evaluationSet?.evaluation[0]?.endTime)
                          .format('MM-DD-YYYY')}
                      </p>
                    </Tooltip>
                    <h3>
                      {index === 0
                        ? 'Latest Evaluation'
                        : `Evaluation ${data?.encounterEvaluations.length - index}`}
                    </h3>
                  </div>
                )}
                panelKey={index}
                defaultActiveKey={['0']}
              >
                {evaluationSet?.evaluation[0]?.ruleName === null
                && evaluationSet?.evaluation[0]?.ruleId === null ? (
                  <div className="rcm-no-data-text">
                    {ErrorMessages.NO_MATCHING_RULES_ALL_CPT_CODES }
                  </div>
                ) : (
                  <Table
                    data={evaluationSet?.evaluation}
                    columns={resultColumn()}
                    noDataText="No Data Found"
                  />
                )}
              </Collapsable>
            </div>
          ))}
        </div>
      ) : (
        <div className="rcm-no-data-text">No Rules Evaluated yet!</div>
      )}
    </>
  );
};

export default RulesResult;
