import axios from 'axios';

const downloadFile = (content, fileName, mimeType) => {
  const a = document.createElement('a');
  const mime = mimeType || 'application/octet-stream';
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(new Blob([content], {
      type: mime,
    }), fileName);
  } else if (URL && 'download' in a) {
    a.href = URL.createObjectURL(new Blob([content], {
      type: mime,
    }));
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    // eslint-disable-next-line no-restricted-globals
    location.href = `data:application/octet-stream,${encodeURIComponent(content)}`;
  }
};

const downloadFileFromURL = (url, fileName, mimeType) => new Promise((resolve, reject) => {
  axios.get(url, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': mimeType,
    },
  })
    .then((response) => {
      const content = new Uint8Array(response.data);

      const a = document.createElement('a');
      const mime = mimeType || 'application/octet-stream';
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(new Blob([content], { type: mime }), fileName);
        resolve();
      } else if (URL && 'download' in a) {
        a.href = URL.createObjectURL(new Blob([content], { type: mime }));
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        resolve();
      } else {
        // eslint-disable-next-line no-restricted-globals
        location.href = `data:application/octet-stream,${encodeURIComponent(content)}`;
        resolve();
      }
    })
    .catch((error) => {
      console.error('Error fetching the file:', error);
      reject(error);
    });
});

export { downloadFileFromURL };
export default downloadFile;
