import React from 'react';

import TabsComponent from '../../../components/Tabs';
import { labelPaths } from '../../../lib/constants';

import Demographic from './Demographic';
import Financial from './Financial';
import Insurance from './Insurance';
import Documents from './Documents';
import Encounters from './Encounters';
import Bills from './Bills';
import Lists from './Lists';
import Dashboard from './Dashboard';

import WithLabel from '../../../hoc/withLabel';

export const tabsList = [
  {
    id: 'summary',
    component: Dashboard,
    className: 'dashboard-icon sprite-img-2-before',
  },
  {
    id: 'demographic',
    component: Demographic,
    className: 'demographic-icon sprite-img-before',
  },
  {
    id: 'insurance',
    component: Insurance,
    className: 'insurance-icon sprite-img-before',
  },
  {
    id: 'financial',
    component: Financial,
    className: 'financials-icon sprite-img-before',
  },
  {
    id: 'documents',
    component: Documents,
    className: 'documents-icon sprite-img-before',
  },
  {
    id: 'encounters',
    component: Encounters,
    className: 'encounters-icon sprite-img-before',
  },
  {
    id: 'bills',
    component: Bills,
    className: 'bills-icon sprite-img-before',
  },
  {
    id: 'lists',
    component: Lists,
    className: 'lists-icon sprite-img-before',
  },
];

const Tabs = (props) => {
  const {
    labels, closeTab, isUpdatePatientAuthenticated, isCreateDocumentAuthenticated,
    isUpdateDocumentAuthenticated, isReadDocumentAuthenticated, isDocumentAuthenticated,
    isPayerRequestAuthenticated,
  } = props;
  const clonedTabs = [];
  tabsList.forEach((tab) => {
    if (tab.id === 'documents') {
      if (isDocumentAuthenticated) {
        clonedTabs.push({ ...tab, name: labels.get(`tabs.${tab.id}`) });
      }
    } else {
      clonedTabs.push({ ...tab, name: labels.get(`tabs.${tab.id}`) });
    }
  });
  return (
    <TabsComponent
      tabsList={clonedTabs}
      id="patients"
      className="main-tab-wrap"
      closeTab={closeTab}
      isUpdatePatientAuthenticated={isUpdatePatientAuthenticated}
      isCreateDocumentAuthenticated={isCreateDocumentAuthenticated}
      isUpdateDocumentAuthenticated={isUpdateDocumentAuthenticated}
      isReadDocumentAuthenticated={isReadDocumentAuthenticated}
      isPayerRequestAuthenticated={isPayerRequestAuthenticated}
    />
  );
};
export default WithLabel(Tabs, labelPaths.EDIT_PATIENT);
