import React, { useEffect, useState } from 'react';
import { Comment as Logs, Tooltip, Avatar } from 'antd';
import moment from 'moment';
import { RightOutlined } from '@ant-design/icons';
import WidgetLoader from '../../../../../components/WidgetLoader';
import { apiUrls } from '../../../../../api/constants';
import { getInitials } from '../../../../../lib/util';
import useCRUD from '../../../../../hooks/useCRUD';

const TaskLogs = ({ taskId }) => {
  const [logs, setLogs] = useState([]);
  const [data, , loading, getLogs] = useCRUD({
    id: 'tasksLogs',
    url: apiUrls.GET_TASK_LOGS,
    type: 'read',
  });

  useEffect(() => {
    getLogs({}, `?TaskId=${Number(taskId)}`);
  }, [taskId, getLogs]);

  useEffect(() => {
    if (data) {
      setLogs(data);
    }
  }, [data]);
  return (
    <div className="auditLogs">
      {loading && <WidgetLoader />}
      {logs.length > 0 ? (
        <div>
          {logs?.map((log) => (
            <Logs
              style={{ minHeight: '65px', padding: '7px 0 0' }}
              key={log.id}
              data-testid="auditLogs"
              avatar={
                <Avatar alt="Logs by">{log.modifiedBy ? getInitials(log.modifiedBy) : 'UA'}</Avatar>
              }
              author={(
                <div className="author">
                  <h4>{log.modifiedBy}</h4>
                  <p>
                    {log.actionType}
                    <span>{log.actionType === 'CREATED' ? 'Task' : log.fieldDisplayName}</span>
                  </p>
                </div>
              )}
              content={
                log.actionType === 'MODIFIED' && (
                <p className="content">
                  {log.oldValue && log.oldValue !== '[]' ? log.oldValue : 'NONE'}
                  <RightOutlined />
                  <span>{ log.newValue ? log.newValue : 'NONE'}</span>
                </p>
                )
              }
              datetime={(
                <Tooltip
                  title={
                    log.modifiedTime
                      ? moment(log.modifiedTime).format('YYYY-MM-DD HH:mm:ss')
                      : moment().fromNow('YYYY-MM-DD HH:mm:ss')
                  }
                >
                  <span>
                    {log.modifiedTime
                      ? moment(log.modifiedTime).fromNow()
                      : moment().fromNow()}
                  </span>
                </Tooltip>
              )}
            />
          ))}
        </div>
      ) : (
        <div className="no-data-text">No Logs</div>
      )}
    </div>
  );
};

export default TaskLogs;
