import React, { useCallback, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { apiUrls } from '../../../../../../api/constants';
import { enums, listId, UiRoutes } from '../../../../../../lib/constants';

import withQuery from '../../../../../../hoc/withQuery/withQuery';

import columns from './columns';
import useRedirect from '../../../../../../hooks/useRedirect';
import { getEnumMaster, getEnumOptions } from '../../../../../../store/selectors';
import { getEnumMasterData } from '../../../../../../store/actions/enum';

const CustomTable = withQuery({
  url: apiUrls.GET_REMITTANCE_WORK_LIST_SUSPENSE,
  listId: listId.REMITTANCE_WORK_LIST_SUSPENSE,
  accessor: (data) => ({ result: data }),
})();

const Table = ({
  labels, businessEntityId, searchText, ...props
}) => {
  const {
    params, generatePath, replace,
  } = useRedirect();
  const { parentTab } = params;
  const dispatch = useDispatch();

  const enumMaster = useSelector((state) => getEnumMaster(state));
  const enumId = get(enumMaster, `${enums.WorkListStatus}.enumId`);
  const workListStatusOptions = useSelector((state) => getEnumOptions(state)?.[enumId]?.data || []);

  useEffect(() => {
    if (enumId && !workListStatusOptions.length) {
      dispatch(getEnumMasterData(enumId));
    }
  }, [enumId, workListStatusOptions.length]);

  const WorkListStatusId = useMemo(() => workListStatusOptions?.find(
    ({ masterCode }) => masterCode === parentTab,
  )?.masterId, [parentTab, workListStatusOptions]);

  const onRowClick = useCallback(({ original }) => {
    replace(generatePath(UiRoutes.remittanceClaimsWorkListEditSuspense,
      {
        ...params,
        suspenseId: original?.id || 0,
        claimFilter: 'worklist',
        reasonName: original?.remittanceReasonName,
      }));
  }, [replace, generatePath, params]);

  return (
    <div className="work-list-table-alignment">
      <CustomTable
        columns={columns(labels)}
        onRowClick={onRowClick}
        filters={{ WorkListStatusId, BusinessEntityId: businessEntityId, SearchText: searchText }}
        noDataText="Data not found"
        {...props}
      >
        {({
          Component,
        }) => (
          <span>
            {Component}
          </span>
        )}
      </CustomTable>
    </div>
  );
};

export default Table;
