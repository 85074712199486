import React, {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import moment from 'moment';
import { Form as AntdForm } from 'antd';
import { useReactToPrint } from 'react-to-print';

import Select from '../../../../Enum/Select';
import WiredSelect from '../../../../Select/selectBoxV2';

import useCRUD from '../../../../../hooks/useCRUD';
import { apiUrls } from '../../../../../api/constants';
import useRedirect from '../../../../../hooks/useRedirect';
import { formId, enums, listIds } from '../../../../../lib/constants';
import SuccessMessages from '../../../../../lib/successMessages';
import formFieldValuesParser from '../../../../../lib/formFieldValuesParser';

import Form from '../../../../../components/Form';
import Loader from '../../../../../components/Loader';
import Input from '../../../../../components/Form/Input';
import Notification from '../../../../../components/Notification';
import DatePicker from '../../../../../components/Form/DatePicker';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import SelectBox from '../../../../../components/Form/SelectBox';

const parser = (values) => {
  const formData = formFieldValuesParser(values, {
    zeroTimeFormat: [
      'effectiveDate',
    ],
  });
  if (formData?.transactionTypeId) {
    formData.adjustmentReasonId = formData.transactionTypeId;
  }
  return formData;
};

const AdjustmentForm = ({
  labels, form, lineItemData, closeTab,
}) => {
  const { params: { id, tabId } } = useRedirect();
  const confirmModalRef = useRef(null);
  const viewPanel = useRef(null);

  const [
    printReceiptResponse,,
    printReceiptLoading,
    printReceipt,
  ] = useCRUD({
    id: listIds.PRINT_ADJUSTMENT_RECEIPT,
    url: apiUrls.PRINT_ADJUSTMENT_RECEIPT,
    type: 'read',
  });

  const handleHtmlPrint = useReactToPrint({
    content: () => viewPanel.current,
    copyStyles: false,
  });

  const lineItems = useMemo(() => {
    if (lineItemData?.length) {
      return lineItemData.map((item) => {
        const
          {
            financeId, balance, applied, patientId,
          } = item;
        return {
          financeId, balance, applied: applied || 0, patientId,
        };
      });
    }
    return [];
  }, [lineItemData]);

  useEffect(() => {
    if (printReceiptResponse && !Array.isArray(printReceiptResponse)) {
      viewPanel.current.innerHTML = printReceiptResponse || '';
      handleHtmlPrint();
      confirmModalRef.current?.();
      confirmModalRef.current = null;
      closeTab(tabId, true);
    }
  }, [printReceiptResponse]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({
        message: SuccessMessages.PATIENT_ADJUSTMENT_ADDED_SUCCESSFULLY,
        success: true,
      });
      ConfirmDialog({
        onOk: (close) => {
          printReceipt({}, `/${id}`);
          confirmModalRef.current = close;
        },
        onCancel: () => closeTab(tabId, true),
        okText: 'Print Receipt',
        content: 'Do you want to print receipt?',
        centered: true,
        okButtonProps: {
          'data-testid': 'confirm-popup-ok-btn',
        },
      })();
    }
  }, [closeTab, id, printReceipt, tabId]);

  return (
    <div className="modal-from-feilds">
      <div ref={viewPanel} className="display-none" />
      {printReceiptLoading && <Loader />}
      <Form
        form={form}
        section
        parser={parser}
        tabId={tabId}
        name={`${formId.ADD_PATIENT_ADJUSTMENT}-${id}`}
        formId={formId.ADD_PATIENT_ADJUSTMENT}
        url={apiUrls.ADD_PATIENT_ADJUSTMENT}
        // isUpdate
        onRequestComplete={onRequestComplete}
        extraData={{ lineItems, patientId: id }}
      >
        <Form.Section>
          <Form.Column noOfColumns="3">
            <DatePicker
              label={labels.get('labels.effectiveDate')}
              name="effectiveDate"
              id="19"
              required
              labelSpan="8"
              inputSpan="16"
              initialValue={moment()}
            />
            <Input
              label={labels.get('labels.amount')}
              id="1"
              required
              name="amount"
              labelSpan="8"
              inputSpan="16"
              numberWithDecimal
              maxValueLength={11}
              dataTestId="patient-adjustment-amount"
              placeholder="Enter Amount"
            />
          </Form.Column>
          <Form.Column noOfColumns="3">
            <AntdForm.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => (
                prevValues.locationId !== currentValues.locationId)}
            >
              {({ getFieldValue }) => (
                <WiredSelect
                  id="provider"
                  name="providerId"
                  label={labels.get('labels.provider')}
                  placeholder="Select Provider"
                  url={apiUrls.SCHEDULAR_PROVIDER_DROPDOWN}
                  nameAccessor="providerName"
                  valueAccessor="providerId"
                  Component={SelectBox}
                  labelSpan="8"
                  inputSpan="16"
                  startCaseOptions
                  selectProps={{
                    style: { width: '100%' },
                    showSearch: true,
                    getPopupContainer: (trigger) => trigger?.parentNode,
                  }}
                  defaultSorting={false}
                  params={{
                    LocationId: getFieldValue('locationId'),
                  }}
                  dataTestId="patient-adjustment-provider"
                />
              )}
            </AntdForm.Item>
            <Select
              label={labels.get('labels.transactionType')}
              enumName={enums.ADJUSTMENT_REASON}
              name="transactionTypeId"
              required
              id="9"
              labelSpan="8"
              inputSpan="16"
              dataTestId="patient-adjustment-transaction-type"
            />
          </Form.Column>
          <Form.Column noOfColumns="3">
            <Input
              label={labels.get('labels.comments')}
              id="1"
              name="comments"
              labelSpan="8"
              inputSpan="16"
              dataTestId="patient-adjustment-comments"
              placeholder="Enter Comments"
            />
            <AntdForm.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => (
                prevValues.providerId !== currentValues.providerId)}
            >
              {({ getFieldValue }) => (
                <WiredSelect
                  id="location"
                  name="locationId"
                  label={labels.get('labels.location')}
                  url={apiUrls.SCHEDULAR_LOCATION_DROPDOWN}
                  placeholder="Select Location"
                  nameAccessor="locationName"
                  valueAccessor="locationId"
                  Component={SelectBox}
                  startCaseOptions
                  labelSpan="8"
                  inputSpan="16"
                  selectProps={{
                    style: { width: '100%' },
                    showSearch: true,
                    getPopupContainer: (trigger) => trigger?.parentNode,
                  }}
                  params={{
                    ProviderId: getFieldValue('providerId'),
                  }}
                  required
                  dataTestId="patient-adjustment-location"
                />
              )}
            </AntdForm.Item>
          </Form.Column>
        </Form.Section>
      </Form>
    </div>
  );
};

export default AdjustmentForm;
