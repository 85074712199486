import React from 'react';
import StatusCell from '../../../../../../components/StatusCell';
import TimeCell from '../../../../../../components/Cells/TimeCell';

const columns = (labels) => ([
  {
    Header: labels.get('tableColumns.planName'),
    accessor: 'name',
    Cell: ({
      row: {
        original: {
          planName, name, id, contractTermId,
        },
      }, navigateToEditTerms,
    }) => (
      <span role="presentation" className="cursor-pointer" onClick={() => { navigateToEditTerms({ termId: contractTermId || id, planName }); }}>
        {name}
      </span>
    ),
    sort: true,
  },
  {
    Header: labels.get('tableColumns.status'),
    accessor: 'isActive',
    Cell: ({ row: { original: { isActive } } }) => <StatusCell active={isActive} />,
    reverseSort: true,
  },
  {
    Header: labels.get('tableColumns.type'),
    accessor: 'typeName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.effectiveDate'),
    accessor: 'effectiveFrom',
    Cell: ({ row: { original: { effectiveFrom } } }) => <TimeCell value={effectiveFrom} />,
    sort: true,
  },
  {
    Header: labels.get('tableColumns.lastModified'),
    accessor: 'modifiedOn',
    Cell: ({ row: { original: { modifiedOn } } }) => <TimeCell value={modifiedOn} />,
    sort: true,
  },
  {
    Header: labels.get('tableColumns.lastModifiedBy'),
    accessor: 'modifiedByName',
    sort: true,
  },
]);

export default columns;
