import React, {
  useCallback, useMemo,
} from 'react';
import moment from 'moment';

import { apiUrls } from '../../../../../api/constants';
import useRedirect from '../../../../../hooks/useRedirect';
import SuccessMessages from '../../../../../lib/successMessages';
import { formId } from '../../../../../lib/constants';
import formFieldValuesParser from '../../../../../lib/formFieldValuesParser';
import Events from '../../../../../lib/events';

import Form from '../../../../../components/Form';
import Input from '../../../../../components/Form/Input';
import Notification from '../../../../../components/Notification';
import DatePicker from '../../../../../components/Form/DatePicker';

const InsuranceAdjustmentForm = (props) => {
  const {
    labels, form, lineItemData, closeTab, stateId, stageId, queueId,
  } = props;
  const { params: { id, tabId } } = useRedirect();

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({
        message: SuccessMessages.INSURANCE_ADJUSTMENT_ADDED_SUCCESSFULLY,
        success: true,
      });
      closeTab(tabId, true);
      Events.trigger('refetch-claim-overview');
    }
  }, [closeTab, tabId]);

  const parsedLineItems = useMemo(() => lineItemData?.map((item) => ({
    ...item,
    writeOffAmount: item?.writeOffAmount || 0,
    writeOffReasonId: item?.writeOffReasonId || 0,
    adjustment: item?.adjustment || 0,
    adjustmentReasonId: item?.adjustmentReasonId || 0,
  })), [lineItemData]);

  const parser = useCallback((values) => {
    const parsedValues = formFieldValuesParser(values);
    if (!parsedValues?.amount) {
      const adjustmentSum = lineItemData.reduce(
        (accumulator, currentValue) => accumulator + parseFloat(currentValue?.writeOffAmount || 0)
         + parseFloat(currentValue?.adjustment || 0),
        0,
      );
      parsedValues.amount = adjustmentSum || 0;
    }
    if (parsedValues?.effectiveDate) {
      parsedValues.effectiveDate = moment(parsedValues.effectiveDate).format('MM-DD-YYYY 00:00:00');
    }
    if (stateId && stageId && queueId) {
      parsedValues.stateId = stateId;
      parsedValues.stageId = stageId;
      parsedValues.queueId = queueId;
    }
    return parsedValues;
  }, [lineItemData, queueId, stageId, stateId]);

  return (
    <div className="modal-from-feilds">
      <div className="display-none" />
      <Form
        section
        form={form}
        parser={parser}
        tabId={tabId}
        name={`${formId.Add_INSURANCE_ADJUSTMENT}-${id}`}
        formId={formId.Add_INSURANCE_ADJUSTMENT}
        url={apiUrls.ADD_INSURANCE_ADJUSTMENT}
        onRequestComplete={onRequestComplete}
        extraData={{
          lineItems: parsedLineItems,
          patientId: id,
          PaymentSource: 'OverTheCounter',
          PaymentMethod: 'Manual',
        }}
        // isUpdate
      >
        <Form.Section>
          <Form.Column noOfColumns="2">
            <DatePicker
              label={labels.get('labels.effectiveDate')}
              name="effectiveDate"
              initialValue={moment()}
              allowClear={false}
              id="19"
              dataTestId="effectiveDate"
            />

          </Form.Column>
          <Form.Column noOfColumns="2">
            <div>
              <Input
                label={labels.get('labels.comments')}
                id="1"
                name="description"
                dataTestId="description"
                placeholder="Enter Comments"
              />
            </div>
          </Form.Column>
        </Form.Section>
      </Form>
    </div>
  );
};

export default InsuranceAdjustmentForm;
