import { fork, all } from 'redux-saga/effects';
import login from './login';
import users from './users';
import providers from './providers';
import enums from './enum';
import queryApi from './queryApi';
import doctors from './doctors';
import fromHandler from './formHandler';
import crud from './crud';
import crudWithoutTab from './crudWithoutTab';
import reduxStoreWithId from './reduxStoreWithId';
import superBillSaga from './superBillSaga';

export default function* root() {
  yield all([
    fork(login),
    fork(users),
    fork(queryApi),
    fork(providers),
    fork(enums),
    fork(fromHandler),
    fork(crud),
    fork(crudWithoutTab),
    fork(reduxStoreWithId),
    fork(doctors),
    fork(superBillSaga),
  ]);
}
