import React from 'react';
import { Row, Col, Form as AntdForm } from 'antd';
import moment from 'moment';

import { apiUrls } from '../../../../../../../api/constants';
import { toDateValidator } from '../../../../../../../lib/validator';
import { enumMasterCode, enums as enumConstant, enums } from '../../../../../../../lib/constants';

import {
  Input, CheckBox, DatePicker,
} from '../../../../../../../components/Form/inputFields';

import { Select } from '../../../../../../../wiredComponents/Enum';
import WiredSelect from '../../../../../../../wiredComponents/Select';
import ReferringPhysicianAutoComplete from '../../../../../../../wiredComponents/ReferringPhysicianGridAutoComplete';
import useMasterId from '../../../../../../../hooks/useMasterId';
import LocationGridAutoComplete from '../../../../../../../wiredComponents/LocationGridAutoComplete';
import ProviderGridAutoComplete from '../../../../../../../wiredComponents/ProviderGridAutoComplete';

const masterCodes = [
  enumMasterCode.accident,
];

const enumNames = [
  enums.CASE_TYPE,
];
const style = { padding: '8px 8px 0 8px' };
const CaseForm = ({
  labels, handleCaseTypeSelect, form, patientId, formData,
}) => {
  // const [selectedLocation, setSelectedLocation] = useState(null);
  // const [selectedProvider, setSelectedProvider] = useState(null);
  const masterCodesWithId = useMasterId(masterCodes, enumNames);

  return (
    <Row gutter={16}>
      <Col md={24} className="gutter-row">
        <div style={style} className="">
          <Row gutter={16}>
            <Col lg={8} md={12} className="gutter-row">
              <Select
                label={labels.get('labels.caseType')}
                name="caseTypeId"
                id="caseType"
                enumName={enumConstant.CASE_TYPE}
                labelSpan="8"
                inputSpan="16"
                onChange={handleCaseTypeSelect}
                required
              />
              <AntdForm.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => (
                  prevValues.caseTypeId !== currentValues.caseTypeId)}
              >
                {({ getFieldValue }) => getFieldValue('caseTypeId') === masterCodesWithId?.Accident && (
                  <>
                    <DatePicker
                      label={labels.get('labels.accidentDate')}
                      name="accidentDate"
                      required
                      id="accidentDate"
                      labelSpan="8"
                      inputSpan="16"
                      disabledDate={(current) => current
                      && current <= moment().subtract(150, 'years')}
                    />
                    <Select
                      label={labels.get('labels.accidentType')}
                      name="accidentTypeId"
                      id="accidentType"
                      enumName={enumConstant.ACCIDENT_TYPE}
                      labelSpan="8"
                      inputSpan="16"
                      required
                    />
                  </>
                )}
              </AntdForm.Item>
              <DatePicker
                label={labels.get('labels.effectiveDateFrom')}
                name="effectiveDateFrom"
                required
                id="effectiveDateFrom"
                labelSpan="8"
                inputSpan="16"
                disabledDate={(current) => current
                  && current <= moment().subtract(150, 'days')}
              />
              <DatePicker
                label={labels.get('labels.effectiveDateTo')}
                name="effectiveDateTo"
                id="effectiveDateTo"
                labelSpan="8"
                inputSpan="16"
                disabledDate={(current) => current
                  && current <= moment().subtract(150, 'days')}
                dependencies={['effectiveDateFrom']}
                rules={[
                  ({ getFieldValue }) => toDateValidator(getFieldValue, 'effectiveDateFrom'),
                ]}
              />

              <ProviderGridAutoComplete
                label={labels.get('labels.provider')}
                name="providerId"
                id="provider"
                labelSpan="8"
                inputSpan="16"
                placeholder="Select Provider"
                selectProps={{
                  showSearch: true,
                  getPopupContainer: (trigger) => trigger?.parentNode,
                }}

                initialValue={formData?.providerId && formData?.providerName
                  ? { name: formData.providerName, value: formData.providerId } : null}
              />
              <LocationGridAutoComplete
                id="location"
                label={labels.get('labels.location')}
                name="locationId"
                placeholder="Select Location"
                selectProps={{
                  showSearch: true,
                  getPopupContainer: (trigger) => trigger?.parentNode,
                }}
                labelSpan="8"
                inputSpan="16"
                initialValue={formData?.locationId && formData?.locationName
                  ? { name: formData.locationName, value: formData.locationId } : null}
              />
            </Col>
            <Col lg={8} md={12} className="gutter-row">
              <div>
                <ReferringPhysicianAutoComplete
                  label={labels.get('labels.referringPhysician')}
                  id="referringPhysicianId"
                  name="referringPhysicianNPI"
                  labelSpan="8"
                  inputSpan="16"
                  form={form}
                  initialValue={formData?.referringPhysicianNPI && formData?.referringPhysicianName
                    && {
                      name: formData?.referringPhysicianName,
                      value: formData?.referringPhysicianNPI,
                      npi: formData?.referringPhysicianNPI,
                    }}
                />

                <AntdForm.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => (
                    prevValues.caseTypeId !== currentValues.caseTypeId)}
                >
                  {({ getFieldValue }) => getFieldValue('caseTypeId') === masterCodesWithId?.Accident && (

                    <>
                      <Select
                        label={labels.get('labels.accidentState')}
                        name="accidentStateId"
                        id="accidentState"
                        enumName={enumConstant.STATE}
                        labelSpan="8"
                        inputSpan="16"
                      />
                      <Input
                        label={labels.get('labels.claimNumber')}
                        name="claimNumber"
                        labelSpan="8"
                        inputSpan="16"
                        numberOnly
                      />
                    </>
                  )}
                </AntdForm.Item>
                <WiredSelect
                  label={labels.get('labels.authorization')}
                  name="authorizationId"
                  id="authorizationId"
                  labelSpan="8"
                  inputSpan="16"
                  url={apiUrls.AUTHORIZATION_LIST_DROPDOWN}
                  nameAccessor="authorizationNo"
                  valueAccessor="authorizationId"
                  params={{ PatientId: patientId }}
                />
                <WiredSelect
                  label={labels.get('labels.insuranceProfile')}
                  name="insuranceProfileId"
                  id="insuranceProfileId"
                  labelSpan="8"
                  inputSpan="16"
                  url={apiUrls.INSURANCE_PROFILE_DROPDOWN}
                  nameAccessor="profileName"
                  valueAccessor="insuranceProfileId"
                  params={{ PatientId: patientId, IncludeInActive: false }}
                />
                <DatePicker
                  label={labels.get('labels.dateLastSeen')}
                  name="lastSeenDate"
                  id="lastSeenDate"
                  labelSpan="8"
                  inputSpan="16"
                  disabledDate={(current) => current
                    && (current >= moment().subtract(0, 'days')
                      || current <= moment().subtract(150, 'years'))}
                />
                <AntdForm.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => (
                    prevValues.caseTypeId !== currentValues.caseTypeId)}
                >
                  {({ getFieldValue }) => (
                    <DatePicker
                      label={labels.get('labels.onsetDate')}
                      name="onSetDate"
                      required={getFieldValue('caseTypeId') === masterCodesWithId?.Accident}
                      id="onSetDate"
                      labelSpan="8"
                      inputSpan="16"
                      disabled={getFieldValue('caseTypeId') !== masterCodesWithId?.Accident}
                      disabledDate={(current) => current
                    && current <= moment().subtract(150, 'years')}
                    />
                  )}
                </AntdForm.Item>
              </div>
            </Col>
            <Col lg={8} md={12} className="gutter-row">
              <div>
                <Input
                  label={labels.get('labels.claimInformation')}
                  id="additionalClaimInfo"
                  name="additionalClaimInfo"
                  charOnly
                  labelSpan="8"
                  inputSpan="16"
                  maxValueLength={100}
                  placeholder="Enter Additional claim Information"
                />
                <AntdForm.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => (
                    prevValues.caseTypeId !== currentValues.caseTypeId)}
                >
                  {({ getFieldValue }) => getFieldValue('caseTypeId') === masterCodesWithId?.Accident && (

                    <>
                      <Input
                        label={labels.get('labels.adjuster')}
                        id="adjuster"
                        name="adjuster"
                        labelSpan="8"
                        inputSpan="16"
                        maxValueLength={50}
                        placeholder="Enter Adjuster"
                      />
                      {/* <Select
                      label={labels.get('labels.adjuster')}
                      name="adjusterId"
                      id="adjuster"
                      enumName={enumConstant.ADJUSTOR}
                      labelSpan="8"
                      inputSpan="16"
                    /> */}
                      <Input
                        label={labels.get('labels.liableParty')}
                        id="liablePartyId"
                        name="liableParty"
                        charOnly
                        labelSpan="8"
                        inputSpan="16"
                        maxValueLength={50}
                        placeholder="Enter Liable Party"
                      />
                      {/* <Select
                      label={labels.get('labels.liableParty')}
                      name="liabelPartyId"
                      id="liabelParty"
                      enumName={enumConstant.LIABLE_PARTY}
                      labelSpan="8"
                      inputSpan="16"
                    /> */}
                    </>
                  )}
                </AntdForm.Item>
                <DatePicker
                  label={labels.get('labels.hospitalizationDateFrom')}
                  name="hospitalizationDateFrom"
                  id="hospitalizationDateFrom"
                  labelSpan="8"
                  inputSpan="16"
                  disabledDate={(current) => current
                    && current <= moment().subtract(150, 'days')}
                />
                <DatePicker
                  label={labels.get('labels.hospitalizationDateTo')}
                  name="hospitalizationDateTo"
                  id="hospitalizationDateTo"
                  labelSpan="8"
                  inputSpan="16"
                  disabledDate={(current) => current
                    && current <= moment().subtract(150, 'days')}
                  dependencies={['hospitalizationDateFrom']}
                  rules={[
                    ({ getFieldValue }) => toDateValidator(getFieldValue, 'hospitalizationDateFrom'),
                  ]}
                />
                <Select
                  label={labels.get('labels.dischargeStatus')}
                  name="dischargeStatusId"
                  id="dischargeStatusId"
                  enumName={enumConstant.DISCHARGE_STATUS}
                  labelSpan="8"
                  inputSpan="16"
                />
                <CheckBox
                  label={labels.get('labels.emergencyIndicator')}
                  valuePropName="checked"
                  name="isEmergencyIndicator"
                  labelSpan="8"
                  inputSpan="16"
                />
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default CaseForm;
