import React, {
  useCallback, useRef, useEffect,
} from 'react';
import { Form as AntdForm } from 'antd';
import { useSelector } from 'react-redux';

import moment from 'moment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Notification from '../../../../components/Notification';
import Button from '../../../../components/Button';
import Form from '../../../../components/Form';
import {
  getCrudDataWithOrWithoutTabId, getEnumMaster, getEnumOptions,
} from '../../../../store/selectors';

import formFieldValueParser from '../../../../lib/formFieldValuesParser';
import SuccessMessages from '../../../../lib/successMessages';
import dateFormatter from '../../../../lib/dateFormatter';

import {
  labelPaths, formId, listId, dateFormatWith12Hour, enums, listIds,
} from '../../../../lib/constants';
import Events from '../../../../lib/events';
import {
  getInsuranceProfileRelationDetails, getString, getDateString, getSystemDate,
} from '../../../../lib/util';

import useRedirect from '../../../../hooks/useRedirect';
import useCRUD from '../../../../hooks/useCRUD';

import { apiUrls } from '../../../../api/constants';

import WithLabel from '../../../../hoc/withLabel';

import Label from '../../../../components/Label';
import TimeCell from '../../../../components/Cells/TimeCell';
import WidgetLoader from '../../../../components/WidgetLoader';

import PolicyForm from './PolicyForm';
import UpdateOptions from './UpdateOptionsModal';
import useReduxState from '../../../../hooks/useReduxState';

const getPayerDetails = (payerOptions, selectedPayer) => {
  const parsedPayerOptions = payerOptions?.props?.children?.[0];
  if (parsedPayerOptions?.length && selectedPayer) {
    const payerDetails = parsedPayerOptions.find(
      (item) => item.props?.item?.insurancePayerId === selectedPayer,
    );
    return payerDetails?.props?.item;
  }
  return {};
};
const parser = (data = {}) => ({
  ...data,
  effectiveFrom: getSystemDate(data.effectiveFrom),
  effectiveTo: getSystemDate(data.effectiveTo),
});

function EditPolicy(props) {
  const {
    labels,
    baseRoute,
    queryString,
    isUpdatePatientAuthenticated,
    isPayerRequestAuthenticated,
    showInActiveInsuranceData,
    formName,
  } = props;

  const profileTypeId = useRef(null);

  const {
    params, generatePath, push, replace,
  } = useRedirect();

  const { profileId, policyId, tabId } = params;

  const [form] = AntdForm.useForm();

  const [formData, setFormData] = useReduxState(`patient-edit-policy-${policyId}-formData`, {});
  const [data, setData] = useReduxState(`patient-edit-policy-${policyId}-data`, {});
  const [updateOptions, setUpdateOptions] = useReduxState(`patient-edit-policy-${policyId}-updateOptions`, false);
  const [optionChange, setOptionChange] = useReduxState(`patient-edit-policy-${policyId}-optionChange`, false);
  const [selectedOption, setSelectedOption] = useReduxState(`patient-edit-policy-${policyId}-selectedOption`);
  const ipData = useSelector((state) => getCrudDataWithOrWithoutTabId(state, 'patient-insurance-rp', tabId));

  const enumMaster = useSelector((state) => getEnumMaster(state));
  const enumOptions = useSelector((state) => getEnumOptions(state));
  const enumId = get(enumMaster, `${enums.INSURANCE_POLICY_LEVEL}.enumId`);

  const policyLevel = enumOptions && enumOptions[enumId] && enumOptions[enumId].data;

  const requestInsuranceData = useCRUD({
    id: 'patient-insurance-rp', url: apiUrls.GET_PATIENT_INSURANCE, type: 'read',
  })[3];

  const [secondaryProfileChangeRespone,,, requestSecondaryProfileChange] = useCRUD({
    id: `${apiUrls.CHANGING_SECONDARY_PROFILE_STATUS}/${profileId}`,
    url: apiUrls.CHANGING_SECONDARY_PROFILE_STATUS,
    type: 'create',
  });

  const [inActiveInsuranceProfileChangeResponse,,
    inActiveInsuranceProfileLoading,
    requestInActiveInsuranceProfileChange,
    clearInActiveInsuranceProfileChangeResponse] = useCRUD({
    id: listIds.CHANGE_INACTIVE_PROFILE,
    url: apiUrls.CHANGE_INACTIVE_PROFILE,
    type: 'create',
  });

  const [
    newInsuranceResponse,,
    loading,
    makeNewPrimaryInsurance,
    clearResponse,
  ] = useCRUD({
    id: apiUrls.MAKE_SECONDARY_PROFILE_PRIMARY,
    url: apiUrls.MAKE_SECONDARY_PROFILE_PRIMARY,
    type: 'create',
  });

  useEffect(() => {
    if (newInsuranceResponse) {
      Notification({
        message: SuccessMessages.PRIMARY_INSURANCE_CHANGED_SUCCESSFULLY,
        success: true,
      });
      requestInsuranceData({ PatientId: params.id, includeinactive: showInActiveInsuranceData });
      Events.trigger('reFetchInsuranceData');
      setUpdateOptions(false);
      clearResponse(true);
    }
  }, [newInsuranceResponse]);

  useEffect(() => {
    if (secondaryProfileChangeRespone) {
      requestInsuranceData({ PatientId: params.id, includeinactive: showInActiveInsuranceData });
    }
  }, [secondaryProfileChangeRespone]);

  useEffect(() => {
    if (inActiveInsuranceProfileChangeResponse) {
      Notification({
        message: SuccessMessages.INSURANCE_PROFILE_UPDATED_SUCCESSFULLY, success: true,
      });
      requestInsuranceData({ PatientId: params.id, includeinactive: showInActiveInsuranceData });
      Events.trigger('reFetchInsuranceData');
      clearInActiveInsuranceProfileChangeResponse(true);
    }
  }, [inActiveInsuranceProfileChangeResponse]);

  const initialDataParser = useCallback((value) => {
    let { responsibleParty = {} } = value;
    const { isActive } = value;
    const insuraceProfileDetail = value.insuraceProfileDetail.find(
      (profile) => profile.insuranceProfileDetailId === parseInt(policyId, 10),
    ) || {};
    profileTypeId.current = insuraceProfileDetail.profileTypeId;
    const {
      payerAddressLine1,
      payerAddressLine2,
      payerCity,
      payerStateName,
      payerCountryName,
      primaryInsuredRelationId,
    } = insuraceProfileDetail;
    responsibleParty = insuraceProfileDetail.primaryInsured;

    let newData = {
      ...insuraceProfileDetail,
      ...responsibleParty,
      isActive,
      coPayment: (insuraceProfileDetail?.coPayment || 0).toFixed(2),
      deductible: (insuraceProfileDetail?.deductible || 0).toFixed(2),
      outDeductible: (insuraceProfileDetail?.outDeductible || 0).toFixed(2),
      responsibleRelationId: primaryInsuredRelationId,
      personName: responsibleParty?.firstName,
      state: responsibleParty?.stateName,
      country: responsibleParty?.countryName,
      gender: responsibleParty?.genderName,
      email: responsibleParty?.emailAddress,
      dateOfBirth: dateFormatter(getDateString(responsibleParty?.dateOfBirth)),
      primaryPhoneType: responsibleParty?.primaryPhoneTypeName,
      secondaryPhoneType: responsibleParty?.secondaryPhoneTypeName,
      addressLine: getString([payerAddressLine1, payerAddressLine2, payerCity, payerStateName, payerCountryName], ', '),
      phone: insuraceProfileDetail?.payerPrimaryPhone,
      dateOfInjury: insuraceProfileDetail?.dateOfInjury
        ? moment(insuraceProfileDetail?.dateOfInjury) : null,
    };
    newData = formFieldValueParser(newData, {
      date: ['createOn', 'dob'],
      bool: ['isActive', 'manual', 'electronic'],
    });
    newData = {
      ...newData,
      effectiveFrom: newData.effectiveFrom ? moment.utc(newData.effectiveFrom) : undefined,
      effectiveTo: newData.effectiveTo ? moment.utc(newData.effectiveTo) : undefined,
    };
    setFormData(value);
    setData(newData);
    return newData;
  }, [policyId, setData, setFormData]);

  const onRequestComplete = ({ response }) => {
    if (response && response.insuranceProfileDetailId) {
      Notification({
        message: SuccessMessages.INSURANCE_PROFILE_UPDATED_SUCCESSFULLY, success: true,
      });
      if (selectedOption === 'newPrimaryProfile') {
        replace(generatePath(`${baseRoute}/profile/new/:profileId/:policyId`, {
          ...params, profileId, policyId: policyLevel?.[0]?.masterId,
        }));
      }
      if (selectedOption === 'secondaryToPrimary') {
        makeNewPrimaryInsurance({
          data: {
            insuranceProfileId: profileId,
            insuranceProfileDetailId: data?.insuranceProfileDetailId,
            profileTypeId: data?.profileTypeId,
            patientId: formData?.patientId,
          },
        });
        return;
      }
      if (data?.profileType?.toLowerCase() !== 'primary') {
        requestSecondaryProfileChange({
          data: {
            insuranceProfileId: profileId,
            insuranceProfileDetailId: data?.insuranceProfileDetailId,
            profileTypeId: data?.profileTypeId,
            patientId: params?.id,
          },
        });
        if (selectedOption === 'profileInactive') { push({ pathname: generatePath(`${baseRoute}/profile/view/:profileId`, params), search: queryString }); }
      } else {
        requestInsuranceData({ PatientId: params.id, includeinactive: showInActiveInsuranceData });
      }
      Events.trigger('reFetchInsuranceData');
      setSelectedOption();
    }
  };

  const getExtraData = useCallback(() => {
    const policyEnumData = form.getFieldInstance('policyTypeId')?.props?.enumData;
    const selectedPolicy = form.getFieldInstance('policyTypeId')?.props?.value;
    const relationInstance = form.getFieldInstance('responsibleRelationId');
    const relationDetail = getInsuranceProfileRelationDetails(
      relationInstance?.props?.enumData,
      relationInstance?.props?.value,
    );
    const personId = form.getFieldValue('personId');
    const personType = form.getFieldValue('type');
    const selectedPolicyData = policyEnumData.filter((policy) => policy.value === selectedPolicy);
    const personPartyType = personType || 'Patient';
    return {
      patientId: params?.id,
      profileName: relationDetail?.masterName,
      // primaryInsuredId: (person && person?.personId) || params?.id,
      primaryInsuredType: personPartyType,
      primaryInsuredRelationId: relationDetail?.masterId,
      profileCategoryId: profileId,
      insuranceProfileDetailId: policyId,
      profileTypeId: profileTypeId?.current,
      planId: 1,
      policyTypeCode: selectedPolicyData[0]?.masterCode,
      primaryInsuredPatientId: personPartyType === 'Patient' ? (personId || params.id) : null,
      primaryInsuredPersonId: personPartyType !== 'Patient' ? (personId) : null,
    };
  }, [form, params, policyId, profileId]);

  const handleClear = useCallback((resetForm) => {
    resetForm();
    initialDataParser(formData);
  }, [formData, initialDataParser]);

  const handleShowUpdateOptions = useCallback(() => {
    if (formData?.insuranceProfileDetail?.isActive) {
      requestInActiveInsuranceProfileChange({
        data: {
          insuranceProfileId: profileId,
          insuranceProfileDetailId: data?.insuranceProfileDetailId,
          profileTypeId: data?.profileTypeId,
          patientId: formData?.patientId,
          effectiveFrom: form?.getFieldValue('effectiveFrom'),
          effectiveTo: form?.getFieldValue('effectiveTo'),
        },
      });
      return;
    }
    const ourProfile = ipData?.find(
      (profile) => profile?.insuranceProfileId === parseInt(profileId, 10)) || {};
    const isSecondaryDetected = ourProfile?.profileDetails?.find((insProfile) => insProfile?.isActive && insProfile?.profileType?.toLowerCase() === 'secondary');
    if (data?.profileType === 'Primary') {
      if (moment(form.getFieldValue('effectiveTo')).isBefore(moment())) {
        if (isEmpty(isSecondaryDetected)) {
          setOptionChange(true);
        }
        setUpdateOptions(true);
      } else {
        form.submit();
      }
    } else {
      form.submit();
    }
  }, [data, form, formData, ipData, profileId,
    requestInActiveInsuranceProfileChange, setOptionChange, setUpdateOptions]);

  return (
    <Form
      form={form}
      tabId={tabId}
      editKey={policyId}
      name={`${formName}-${policyId}`}
      url={apiUrls.EDIT_PATIENT_INSURANCE}
      getUrl={`${apiUrls.GET_PATIENT_INSURANCE}/${profileId}`}
      formId={`${formId.EDIT_INSURANCE_PROFILE}-${policyId}`}
      initialDataParser={initialDataParser}
      parser={parser}
      extraData={getExtraData}
      listId={listId.PATIENT_INSURANCE_PROFILE_POLICY}
      onRequestComplete={onRequestComplete}
      isUpdate
      section
      getDataUsingCrud
      disableForm={!isUpdatePatientAuthenticated}
      refetchId="reFetchInsuranceData"
    >
      {({ resetForm }) => (
        <div className="complete-form-wrap insurance-aside-wrap posi-relative">
          <div className="mr-bt-8 insurance-head-box">
            <AntdForm.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => (
                prevValues.healthPlan !== currentValues.healthPlan)}
            >
              {({ getFieldValue, getFieldInstance }) => {
                const payerDetails = getPayerDetails(getFieldInstance('healthPlan')?.props?.children, getFieldValue('healthPlanId'));
                return (
                  <Form.Section noOfColumns={3}>
                    <Form.Column>
                      {(payerDetails?.createdByName || formData?.createdByName) && (
                      <div className="group-shadow">
                        <div className="posi-relative flex">
                          <div md={10} className="mr-rt-8"><Label label={labels.get('labels.createdBy')} /></div>
                          <div md={14} className="flex align-center mr-bt-8 font-size-13">{payerDetails?.createdByName || formData?.createdByName}</div>
                        </div>
                      </div>
                      )}
                    </Form.Column>
                    <Form.Column>
                      {(payerDetails?.createdOn || formData?.createdOn) && (
                      <div className="group-shadow">
                        <div className="posi-relative flex">
                          <div md={10} className="mr-rt-8"><Label label={labels.get('labels.createdAt')} /></div>
                          <div md={14} className="flex align-center mr-bt-8 font-size-13"><TimeCell format={dateFormatWith12Hour} value={payerDetails?.createdOn || formData?.createdOn} /></div>
                        </div>
                      </div>
                      )}
                    </Form.Column>
                    <Form.Column>
                      {(payerDetails?.modifiedByName || formData?.modifiedByName) && (
                      <div className="group-shadow">
                        <div className="posi-relative flex">
                          <div md={10} className="mr-rt-8"><Label label={labels.get('labels.lastModifiedBy')} /></div>
                          <div md={14} className="flex align-center mr-bt-8 font-size-13">{payerDetails?.modifiedByName || formData?.modifiedByName}</div>
                        </div>
                      </div>
                      )}
                    </Form.Column>
                    <Form.Column>
                      {(payerDetails?.modifiedOn || formData?.modifiedOn) && (
                      <div className="group-shadow">
                        <div className="posi-relative flex">
                          <div md={10} className=""><Label label={labels.get('labels.lastModifiedAt')} /></div>
                          <div md={14} className="flex align-center mr-bt-8 font-size-13"><TimeCell format={dateFormatWith12Hour} value={payerDetails?.modifiedOn || formData?.modifiedOn} /></div>
                        </div>
                      </div>
                      )}
                    </Form.Column>
                  </Form.Section>
                );
              }}
            </AntdForm.Item>
          </div>
          <Form.Section>
            <PolicyForm
              form={form}
              labels={labels}
              isUpdate
              isPolicyHolder
              profileName={formData?.profileName}
              newPolicy={data?.profileType !== 'Primary'}
              formData={data}
              policyId={policyId}
              isPayerRequestAuthenticated={isPayerRequestAuthenticated}
            />
          </Form.Section>
          <Form.Section>
            <div className="btns-action">
              <Button className="btn btn-success min-wt-86" id="patients_insurance_update" onClick={handleShowUpdateOptions}>{labels.get('buttons.update')}</Button>
              <Button className="btn" id="patients_insurance_cancel" onClick={() => handleClear(resetForm)}>{labels.get('buttons.clear')}</Button>
            </div>
          </Form.Section>
          {updateOptions && (
          <UpdateOptions
            isVisible={updateOptions}
            toggleModal={setUpdateOptions}
            form={form}
            optionChange={optionChange}
            loading={loading}
            setSelectedOption={setSelectedOption}
          />
          )}
          {inActiveInsuranceProfileLoading && <WidgetLoader />}
        </div>
      )}
    </Form>
  );
}

export default WithLabel(EditPolicy, labelPaths.EDIT_INSURANCE_POLICY);
