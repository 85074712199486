import React from 'react';
import Icon from '../../../../../../../components/Icon';
import iconNames from '../../../../../../../lib/iconNames';

const columns = ([
  {
    Header: 'Locations',
    accessor: 'locationName',
  },
  {
    Header: 'Action',
    width: 220,
    Cell: ({ row: rowData, rowProps, handleLocationDelete }) => (
      <span className="delete-icon">
        <Icon
          name={iconNames.deleteIcon}
          onClick={() => handleLocationDelete(rowData, rowProps)}
          color="#32c788"
        />
      </span>
    ),
  },
]);
export default columns;
