import React from 'react';
import Button from '../../../../../components/Button';
import TimeCell from '../../../../../components/Cells/TimeCell';
import Icon from '../../../../../components/Icon';
import iconNames from '../../../../../lib/iconNames';

export default [
  {
    Header: '',
    fixWidth: '50',
    accessor: 'expander',
    Cell: ({ row }) => {
      const expanderProps = row.getToggleRowExpandedProps();
      if (expanderProps.onClick) {
        const { onClick } = expanderProps;
        expanderProps.onClick = (e) => {
          e.stopPropagation();
          onClick(e);
        };
      }
      return (
        <span {...expanderProps} title="">
          <div>
            <Icon
              name={row?.isExpanded ? iconNames.minus : iconNames.plusIcon}
            />
          </div>
        </span>
      );
    },
  },
  {
    Header: 'Roles',
    accessor: 'permissionSetName',
    sort: true,
  },
  {
    Header: 'Module Access',
    accessor: 'moduleAccess',
  },
  {
    Header: 'Modified By',
    accessor: 'modifiedBy',
    sort: true,
  },
  {
    Header: 'Modified Date',
    accessor: 'modifiedOn',
    sort: true,
    Cell: ({ row: { original: { modifiedOn } } }) => (
      <TimeCell
        value={modifiedOn}
      />
    ),
  },
  {
    Header: 'Action',
    width: 220,
    Cell: ({
      handleAddChildRole, handleEditRole,
      handleDeleteRole, row, row: { original }, isManagePermitted,
    }) => {
      const rowProps = row.getToggleRowExpandedProps();
      rowProps.onClick = (e) => {
        e.stopPropagation();
      };
      if (isManagePermitted) {
        return (
          <div className="add-location-feild-wrap" {...rowProps} title="">
            <span className="addLocation">
              <Button
                type="button"
                className="sm-btn"
                onClick={() => handleAddChildRole(original)}
              >
                Add Child Role
              </Button>
            </span>
            <span className="mr-lt-12 delete-icon">
              <Icon
                name={iconNames.edit}
                onClick={() => handleEditRole(original)}
              />
            </span>
            <span className="mr-lt-12 delete-icon">
              <Icon
                name={iconNames.deleteIcon}
                onClick={() => handleDeleteRole(original?.permissionSetId, true)}
              />
            </span>
          </div>
        );
      }
      return null;
    },
  },
];

