
import React from 'react';

const WithTableWrapper = (WrappedComponent) => function WithTableWrapperHOC(props) {
  const { disableTable } = props;
  if (disableTable) {
    return (
      <div title="View Mode">
        <div style={{ pointerEvents: 'none', opacity: '0.8' }}>
          <WrappedComponent {...props} />
        </div>
      </div>
    );
  }
  return <WrappedComponent {...props} />;
};

export default WithTableWrapper;
