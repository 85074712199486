
import React, { useMemo } from 'react';
import moment from 'moment';

const TimeRangeContainer = ({ timeRange, children, slotDuration }) => {
  // prepares time stamps for the grid view
  const timestamps = useMemo(() => {
    const { from, to } = timeRange;
    const timeSlots = [];
    if (from && to) {
      const time = moment(from);
      while (moment(to).isAfter(time)) {
        timeSlots.push(moment(time).format('h:mm A'));
        time.add(slotDuration, 'minutes');
      }
    }
    return timeSlots;
  }, [timeRange, slotDuration]);

  return (
    <div className="grid-time-range-container">
      <div className="grid-time-range-view">
        <div />
        {timestamps.map((time) => <div className="timestamp" key={time}>{time}</div>)}
      </div>
      {children}
    </div>
  );
};

export default TimeRangeContainer;
