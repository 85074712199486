import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';

import './collapse.scss';

const Collapsable = ({
  children, className, panelClassName, header, panelKey, extra, panelProps, ...props
}) => {
  const { Panel } = Collapse;
  return (
    <Collapse className={className} {...props}>
      <Panel
        className={panelClassName}
        header={header}
        key={panelKey}
        extra={extra}
        {...panelProps}
      >
        {children}
      </Panel>
    </Collapse>
  );
};

Collapsable.propTypes = {
  className: PropTypes.string,
  panelClassName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  header: PropTypes.string,
  panelKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  extra: PropTypes.node,
  panelProps: PropTypes.objectOf(
    PropTypes.bool,
  ),
};

Collapsable.defaultProps = {
  className: 'ae-accordian',
  panelClassName: 'site-collapse-custom-panel inner-accordian note-accordion',
  children: [],
  header: '',
  panelKey: '',
  extra: '',
  panelProps: {},
};

export default Collapsable;
