import React, { useEffect } from 'react';

import { apiUrls } from '../../../../../../api/constants';
import { listIds } from '../../../../../../lib/constants';
import Events from '../../../../../../lib/events';

import useRedirect from '../../../../../../hooks/useRedirect';
import useCRUD from '../../../../../../hooks/useCRUD';

import PostedClaimTable from '../../InsurancePayment/PostedClaimsTable';

const Errors = ({
  labels, setCurrentPath,
  remittanceBatchData, currentTab, ...otherProps
}) => {
  const {
    params: { remittanceId, remittancePaymentId },
  } = useRedirect();

  const [
    postedInsuranceClaimsError,,
    postedInsuranceClaimsErrorLoading,
    getPostedInsuranceClaimsError,
    clearPostedInsuranceClaimsError,
  ] = useCRUD({
    id: listIds.REMITTANCE_POSTED_INSURANCE_CLAIMS,
    url: apiUrls.REMITTANCE_POSTED_INSURANCE_CLAIMS,
    type: 'read',
  });

  useEffect(() => {
    if (currentTab === 'errors') {
      getPostedInsuranceClaimsError({
        RemittanceBatchId: remittanceId,
        FinancePaymentId: remittancePaymentId,
        QueueId: 2,
      });
    }
    return () => clearPostedInsuranceClaimsError(true);
  }, [currentTab]);

  useEffect(() => {
    if (currentTab === 'errors') {
      Events.on(`refetchPostedClaimsError${remittancePaymentId}`, `refetchPostedClaimsError${remittancePaymentId}`, () => {
        getPostedInsuranceClaimsError({
          RemittanceBatchId: remittanceId,
          FinancePaymentId: remittancePaymentId,
          QueueId: 2,
        });
      });
    }
    return () => Events.remove(`refetchPostedClaimsError${remittancePaymentId}`, `refetchPostedClaimsError${remittancePaymentId}`);
  }, [currentTab, remittanceId, remittancePaymentId]);

  return (
    <PostedClaimTable
      labels={labels}
      setCurrentPath={setCurrentPath}
      data={postedInsuranceClaimsError || []}
      loading={postedInsuranceClaimsErrorLoading}
      isRowClickAble={false}
      isRemittanceErrorTab
      isPostedInsuranceClaims
      tabName="errors"
      remittanceBatchData={remittanceBatchData}
      {...otherProps}
    />
  );
};

export default Errors;
