import React from 'react';
import { withRouter } from 'react-router-dom';
import { Drawer } from 'antd';

import rights from '../../lib/rights';

import Link from '../../components/Link';

import useRights from '../../hooks/useRights';

import './opensidebar.scss';

function OpenSidebar(props) {
  const { booleanMenu, openMenuClass, handleTabModule } = props;
  const [
    isLocationAuthenticated,
    isLocationViewOnlyAuthenticated,
    isProviderAuthenticated,
    isProviderViewOnlyAuthenticated,
    isBusinessEntityAuthenticated,
    isBusinssEntityViewOnlyAuthenticated,
    isManageDoctorFavoritesAuthenticated,
    isPayerAuthenticated,
    isPayerViewOnlyAuthenticated,
    isComposerAuthenticated,
    isFeePreferencesAuthenticated,
    isMastersAuthenticated,
    isCptIcdMappingAuthenticated,
    isConsentFormAuthenticated,
    isUserSetupAuthenticated,
    isUserViewOnlyAuthenticated,
    isReadRightsAuthenticated,
    isReadRolesAuthenticated,
    isManageRightsAuthenticated,
    isManageRolesAuthenticated,
    isUserRightsViewOnlyAuthenticated,
  ] = useRights([
    rights.location,
    rights.location_view_only,
    rights.provider,
    rights.provider_view_only,
    rights.business_entity_setup,
    rights.business_entity_setup_view_only,
    rights.show_manage_doctor_favorites,
    rights.payer,
    rights.payer_view_only,
    rights.access_to_composer,
    rights.access_to_fee_preferences,
    rights.access_to_masters,
    rights.access_to_cpt_icd_synonym,
    rights.access_to_consent_form,
    rights.user_setup,
    rights.user_setup_view_only,
    rights.read_rights,
    rights.read_roles,
    rights.manage_rights,
    rights.manage_roles,
    rights.user_rights_view_only,
  ]);

  return (
    <Drawer
      placement="left"
      className="app-open-sidebar"
      closable={false}
      onClose={openMenuClass}
      visible={booleanMenu}
      zIndex={101}
      style={{ position: 'fixed' }}
    >
      <h2 className="title">Settings</h2>
      <ul role="presentation">
        {/* <li className="business sprite-img-before">Business entity</li> */}
        {(isBusinessEntityAuthenticated || isBusinssEntityViewOnlyAuthenticated) && (
        <Link className="business sprite-img-before" onClick={handleTabModule('businessEntity')} component="li" root to="/settings/businessEntity">
          Business Entity
        </Link>
        )}
        {(isProviderAuthenticated || isProviderViewOnlyAuthenticated) && (
          <Link className="provider sprite-img-before" onClick={handleTabModule('provider')} component="li" root to="/settings/provider">
            Provider
          </Link>
        )}
        {(isUserSetupAuthenticated || isUserViewOnlyAuthenticated) && (
          <Link component="li" onClick={handleTabModule('users')} className="user sprite-img-before" root to="/settings/users">
            User
          </Link>
        )}
        {(isLocationAuthenticated || isLocationViewOnlyAuthenticated) && (
          <Link component="li" onClick={handleTabModule('locations')} className="location sprite-img-before" root to="/settings/locations">
            Location
          </Link>
        )}
        {(isPayerAuthenticated || isPayerViewOnlyAuthenticated) && (
          <Link component="li" onClick={handleTabModule('payer')} className="payer sprite-img-before" root to="/settings/payer">
            Payer
          </Link>
        )}
        {/* <Link component="li" onClick={handleTabModule('cpt')} className="cpt
        sprite-img-before" root to="/settings/cpt">
          CPT
        </Link>
        <Link component="li" onClick={handleTabModule('icd')}
        className="cpt sprite-img-before" root to="/settings/icd">
          ICD
        </Link>
        <Link component="li" onClick={handleTabModule('icd')}
        className="cpt sprite-img-before" root to="/settings/cpticd">
          CPT-ICD Crosswalk
        </Link> */}
        {/* <li className="user-rights sprite-img-before">User rights</li> */}
        {isComposerAuthenticated && (
        <Link className="composer sprite-img-before" onClick={handleTabModule('composer')} component="li" root to="/settings/composer">
          Composer
        </Link>
        )}
        {isFeePreferencesAuthenticated && (
        <Link className="preferences sprite-img-before" onClick={openMenuClass} component="li" root to="#">Fee preferences</Link>
        )}
        {isMastersAuthenticated && (
          <Link className="cpt sprite-img-before" onClick={handleTabModule('masters')} component="li" root to="/settings/masters">
            Masters
          </Link>
        )}
        {/* <Link
          className="multi-dropdown-list cpt sprite-img-before"
          component="li"
          root
          to="/settings/surgery"
        >
          Surgery
        </Link> */}
        {isCptIcdMappingAuthenticated && (
          <Link className="multi-dropdown-list cpt sprite-img-before" onClick={openMenuClass} component="li" root to="/settings/synonyms">
            CPT and ICD Synonyms
          </Link>
        )}
        {isConsentFormAuthenticated && (
          <Link className="multi-dropdown-list cpt sprite-img-before" onClick={openMenuClass} component="li" root to="/settings/concernform">
            Consent form
          </Link>
        )}
        {(isReadRightsAuthenticated
          || isReadRolesAuthenticated
          || isManageRightsAuthenticated
          || isManageRolesAuthenticated
          || isUserRightsViewOnlyAuthenticated) && (
          <Link component="li" onClick={openMenuClass} className="user sprite-img-before" root to="/settings/UserRole">
            User Roles
          </Link>
        )}
        {isManageDoctorFavoritesAuthenticated && (
          <Link component="li" onClick={openMenuClass} className="user sprite-img-before" root to="/settings/ManageFavDoc">
            Manage
          </Link>
        )}
      </ul>
      <div className="close-back" onClick={openMenuClass} role="presentation" data-testid="close-icon" />
    </Drawer>
  );
}

export default withRouter(OpenSidebar);
