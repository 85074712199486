import React, { useEffect } from 'react';
import moment from 'moment';

import useCRUD from '../../../hooks/useCRUD';
import useRedirect from '../../../hooks/useRedirect';
import { apiUrls } from '../../../api/constants';

import Image from '../../../components/Image';
import WidgetLoader from '../../../components/WidgetLoader';

const UserProfile = (props) => {
  const { labels } = props;
  const { params: { id: patientId } } = useRedirect();
  const [patientDetail, , loading, getPatientDetail] = useCRUD({ id: 'patient-detail', url: apiUrls.GET_PATIENT, type: 'read' });

  useEffect(() => {
    if (patientId) getPatientDetail({}, `/${patientId}`);
  }, [getPatientDetail, patientId]);
  const dateOfBirth = patientDetail?.dateOfBirth;
  return (
    <>
      {loading && <WidgetLoader />}
      <div className="patient-profiles">
        <div className="user-img">
          <Image name="placeholder-img.png" alt="logo" />
        </div>
        <div className="descriptions">
          <div className="name">
            <p className="title">{labels.get('labels.patientName')}</p>
            <p>{patientDetail && `${patientDetail?.firstName} ${patientDetail?.middleName || ''} ${patientDetail?.lastName || ''}`}</p>
          </div>
          <div className="name">
            <p className="title">{labels.get('labels.dob')}</p>
            <p>
              {dateOfBirth
              && `${moment().diff(moment(dateOfBirth.substring(0, dateOfBirth.length - 1)), 'years')} year (${moment(dateOfBirth.substring(0, dateOfBirth.length - 1)).format('MM/DD/YYYY')})`}
            </p>
          </div>
          <div className="name">
            <p className="title">{labels.get('labels.chartNumber')}</p>
            <p>{patientDetail?.chartNumber}</p>
          </div>
          <div className="name">
            <p className="title">{labels.get('labels.primaryProvider')}</p>
            <p>{patientDetail?.providerName}</p>
          </div>
          <div className="name">
            <p className="title">{labels.get('labels.location')}</p>
            <p>{patientDetail?.locationName}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
