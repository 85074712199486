import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import times from 'lodash/times';

import { apiUrls } from '../../../../api/constants';
import { listIds, dateFormatStartsWithYear, schedularLocationColorRules } from '../../../../lib/constants';

import useCRUD from '../../../../hooks/useCRUD';
import useRedirect from '../../../../hooks/useRedirect';

import Loader from '../../../../components/Loader';

const getAvailabilityStyle = (bookTime, freeTime) => {
  const bookPercentage = (bookTime * 100) / (bookTime + freeTime);
  const { color } = schedularLocationColorRules.find((rule) => (
    bookPercentage >= rule?.minPercent && bookPercentage < rule?.maxPercent
  )) || {};
  return { color: color || 'transparent' };
};

const getMode = ({ selectedLocation, selectedDoctor }) => {
  if (selectedLocation && selectedDoctor?.length) return 'both';
  if (selectedLocation) return 'location';
  if (selectedDoctor.length === 1) return 'doctor';
  return 'multi-doctors';
};

const mapFunction = (providerMonthlyAppointmentLists) => (doctor) => ({
  rowName: doctor.name,
  ...(providerMonthlyAppointmentLists.find(
    ({ providerId }) => providerId === doctor.providerId,
  ) || {}),
});

const DateCell = ({
  selectedDate,
  data = {},
  allDoctors,
  allLocations,
  selectedDoctor,
  selectedLocation,
  date,
}) => {
  const { providerMonthlyAppointmentLists = [] } = data;

  const isSameMonth = useMemo(() => date.get('month') === selectedDate.get('month'), [date, selectedDate]);
  const isCurrentDate = useMemo(() => date.isSame(moment(), 'day'), [date]);
  const isFirstDate = useMemo(() => date.get('date') === 1, [date]);

  const rows = useMemo(() => {
    let rowsDataArray = [];
    if (selectedLocation || (selectedDoctor?.length)) {
    // eslint-disable-next-line no-nested-ternary
      const mode = getMode({ selectedLocation, selectedDoctor });

      switch (mode) {
        case 'doctor': rowsDataArray = allLocations.map(
          (location) => ({
            rowName: location.locationName,
            ...(providerMonthlyAppointmentLists?.find(
              ({ locationId }) => locationId === location.locationId,
            ) || {}),
          }),
        );
          break;
        case 'location': rowsDataArray = allDoctors.map(mapFunction(providerMonthlyAppointmentLists));
          break;
        default: rowsDataArray = selectedDoctor.map(mapFunction(providerMonthlyAppointmentLists));
      }
    }
    return rowsDataArray;
  }, [selectedLocation, selectedDoctor, allLocations, allDoctors, providerMonthlyAppointmentLists]);

  return (
    <div
      className={classNames({
        'calendar-day-cell': true,
        inactive: !isSameMonth,
      })}
    >
      <div className="date-header">
        <div className={classNames({ 'date-bubble': isCurrentDate })}>
          {date.format(isFirstDate ? 'MMM D' : 'D')}
        </div>
      </div>
      {isSameMonth && (
        <div className="date-body month-inner-table">
          <table border="1">
            <thead>
              <tr>
                <th> </th>
                <th colSpan="2">AM</th>
                <th colSpan="2">PM</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td />
                <td>FFS</td>
                <td>CAP</td>
                <td>FFS</td>
                <td>CAP</td>
              </tr>
              {rows.map((rowData) => (
                <tr
                  key={rowData?.providerId}
                  className={classNames({ 'row-on-leave': !rowData?.totalTime })}
                >
                  <td className="dr-name" style={getAvailabilityStyle(rowData?.bookTime, rowData?.freeTime)}>
                    <span title={rowData.rowName} className="ellipse">{rowData.rowName}</span>
                    <span>
                      {`Total Appt (${
                        (rowData.aM_FFS || 0)
                          + (rowData.pM_FFS || 0)
                          + (rowData.aM_CAP || 0)
                          + (rowData.pM_CAP || 0)})`}

                    </span>
                  </td>
                  <td>{rowData.aM_FFS || 0}</td>
                  <td>{rowData.aM_CAP || 0}</td>
                  <td>{rowData.pM_FFS || 0}</td>
                  <td>{rowData.pM_CAP || 0}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
const MonthView = ({
  selectedDate, selectedDoctor, allDoctors, allLocations,
}) => {
  const { query: { doctor, location } } = useRedirect();

  const [noOfRows, setNoOfRows] = useState(6);

  const [schedules, , loading, getMonthlySchedules, clearMonthlySchedules] = useCRUD({
    id: listIds.SCHEDULAR_MONTHLY_SCHEDULES_LIST,
    url: apiUrls.GET_MONTHLY_SCHEDULER_DATA,
    type: 'read',
  });

  const firstCellDate = useMemo(() => moment(selectedDate).startOf('month').day('Monday'), [selectedDate]);

  const daysLabels = useMemo(() => times(6, (index) => (<div>{moment().day('Monday').add(index, 'days').format('ddd')}</div>)), []);

  useEffect(() => {
    clearMonthlySchedules(true);
    if (doctor || location) {
      const startMoment = moment(selectedDate).startOf('month');
      const endMoment = moment(selectedDate).endOf('month');
      getMonthlySchedules({
        ProviderId: doctor,
        LocationId: location,
        ScheduleStartDate: moment(startMoment).day('Monday').format(dateFormatStartsWithYear),
        ScheduleEndDate: moment(endMoment).day('Saturday').format(dateFormatStartsWithYear),
      });
      const rowsCount = moment(endMoment).startOf('week')
        .diff(startMoment.startOf('week'), 'weeks')
        + (endMoment.format('dd') === 'Su' ? 0 : 1);
      if (rowsCount !== noOfRows) {
        setNoOfRows(rowsCount);
      }
    }
  }, [selectedDate, doctor, location]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {loading && <Loader />}
      {!loading && schedules && schedules.length
        ? (
          <>
            <div className="calendar-week-container">{daysLabels}</div>
            {times(noOfRows, (noOfWeek) => (
              <div className="calendar-day-container">
                {times(6, (noOfDay) => (
                  <DateCell
                    selectedDate={moment(selectedDate)}
                    data={schedules[(noOfWeek * 7) + noOfDay]}
                    date={moment(firstCellDate).add(noOfWeek, 'weeks').add(noOfDay, 'days')}
                    selectedDoctor={selectedDoctor}
                    selectedLocation={location}
                    allDoctors={allDoctors}
                    allLocations={allLocations}
                  />
                ))}
              </div>
            ))}
          </>
        ) : <span />}
    </div>
  );
};

export default MonthView;
