import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import PropTypes from 'prop-types';

import './select.scss';

function SelectBox(props) {
  const {
    options,
    selectedOption,
    label,
    name,
    value,
    required,
    disabled,
    onChange,
    placeholder,
    selectProps,
    setFirstOptionSelected,
    isFormItem,
    filterOption,
    allowClear,
    dataTestId,
    loading,
    ...otherProps
  } = props;

  const [initialSet, setInitialSet] = useState(true);

  useEffect(() => {
    if (setFirstOptionSelected && typeof onChange === 'function' && options[0] && initialSet) {
      onChange([0], [{ item: options[0] }]);
      setInitialSet(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, setFirstOptionSelected]);
  const select = (
    <Select
      placeholder={placeholder}
      onChange={onChange || selectedOption}
      allowClear={allowClear}
      loading={loading}
      value={value}
      showArrow
      disabled={disabled}
      optionFilterProp="children"
      name={name}
      filterOption={filterOption}
      data-testid={!isFormItem ? dataTestId : ''}
      {...selectProps}
    >
      {options
    && options.map((item) => (
      <Select.Option
        key={item.value}
        value={item.value}
        disabled={item.disabled}
        item={item}
        title={item.name}
        name={name}
        data-testid={item.value}
      >
        {item.name}
      </Select.Option>
    ))}
    </Select>
  );

  return (
    <div className="custom-select-box">
      {isFormItem ? (
        <Form.Item
          name={name}
          label={label}
          rules={[
            {
              required,
              message: `${label} is required`,
            },
          ]}
          data-testid={dataTestId}
          {...otherProps}
        >
          {select}
        </Form.Item>
      ) : (
        <>
          <label className="ant-form-item-no-colon">{label}</label>
          {select}
        </>
      )}
    </div>
  );
}

SelectBox.defaultProps = {
  placeholder: '',
  name: '',
  id: 'select-box',
  onChange: null,
  value: undefined,
  filters: {},
  options: [],
  defaultValue: null,
  selectedOption: () => { /* This is intentional */ },
  label: '',
  required: false,
  disabled: false,
  selectProps: {},
  setFirstOptionSelected: false,
  isFormItem: true,
  filterOption: null,
  allowClear: true,
};

SelectBox.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  filterOption: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  filters: PropTypes.instanceOf(Object),
  options: PropTypes.instanceOf(Array),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  selectedOption: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  selectProps: PropTypes.instanceOf(Object),
  setFirstOptionSelected: PropTypes.bool,
  isFormItem: PropTypes.bool,
  allowClear: PropTypes.bool,
};

export default React.memo(SelectBox);
