import React, { useCallback } from 'react';

import classNames from 'classnames';
import { apiUrls } from '../../../../api/constants';
import SuccessMessages from '../../../../lib/successMessages';

import Form from '../../../../components/Form';
import Notification from '../../../../components/Notification';
import { CptIcdButtons, CptIcdFormColumns } from '../../CPT/AddCPT';

const AddICD = ({
  form, navigateBack, headingVisible,
}) => {
  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.ICD_ADDED_SUCCESSFULLY, success: true });
      navigateBack();
    }
  }, [navigateBack]);
  return (
    <div className="main-content-area">
      <Form
        form={form}
        section
        scrollToFirstErrors
        formId="ADD_ICD"
        url={apiUrls.ADD_ICD}
        listId="ADD_ICD"
        onRequestComplete={onRequestComplete}
      >
        <div className={classNames('heading-area flex align-center add-payer-screen', {
          'justify-content-sp-bt': headingVisible, 'justify-content-flex-end': !headingVisible,
        })}
        >
          {headingVisible && <h2>Add ICD</h2>}
          <CptIcdButtons navigateBack={navigateBack} />
        </div>
        <CptIcdFormColumns label="ICD Code" name="icdCode" />
      </Form>
    </div>
  );
};

export default AddICD;
