import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const ErrorBoundary = ({ children, logErrorBoundaryMessage }) => {
  const [dummyHasError, setDummyHasError] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (dummyHasError) {
      setDummyHasError(false);
    }
  }, [location.key]);

  return (
    <ErrorBoundaryInner
      dummyHasError={dummyHasError}
      setDummyHasError={setDummyHasError}
      logErrorBoundaryMessage={logErrorBoundaryMessage}
    >
      {children}
    </ErrorBoundaryInner>
  );
};

class ErrorBoundaryInner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: '' };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidUpdate(prevProps) {
    const { dummyHasError } = this.props;
    if (!dummyHasError && prevProps.dummyHasError) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(error) {
    const { setDummyHasError, logErrorBoundaryMessage } = this.props;
    setDummyHasError(true);
    this.setState({ errorMessage: error.message });
    const pathName = window?.location?.pathname;
    logErrorBoundaryMessage({ data: { error: error?.message, errorLocation: pathName } });
  }

  render() {
    const { hasError, errorMessage } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <div className="error-boundary-wrapper">
          <div className="error-boundary-img">
            <img src={`${process.env.PUBLIC_URL}/images/error-boundary-img.png`} alt="error boundary" />
          </div>
          <h1>Something went wrong.</h1>
          {errorMessage}
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
