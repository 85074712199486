import React, {
  useCallback, useState, useEffect,
} from 'react';
import { Modal } from 'antd';
import Button from '../../../../../components/Button';
import Loader from '../../../../../components/Loader';

import useRights from '../../../../../hooks/useRights';
import useCRUD from '../../../../../hooks/useCRUD';
import { apiUrls } from '../../../../../api/constants';
import useRedirect from '../../../../../hooks/useRedirect';

import {
  listIds, UiRoutes, enums, enumMasterCode,
} from '../../../../../lib/constants';
import rights from '../../../../../lib/rights';

import ProofOfFilling from './Components/ProofOfFilling';
import CheckEligibility from './Components/CheckEligibility';
import EnterAdjustment from './Components/EnterAdjustment';
import PatientPayment from '../../../../Dashboard/Tabs/Financial/Popups/Patient/PatientPayment';
import useMasterId from '../../../../../hooks/useMasterId';
import { fileNameFromUrl, openUrlInNewTab, urltoFile } from '../../../../../lib/util';
import downloadFile from '../../../../../lib/downloadFile';
import Notification from '../../../../../components/Notification';

const keysToMapStage = {
  Denial: 'BillingStageDenial',
  Manual: 'BillingStageManual',
  'No Response': 'BillingStageNoResponse',
};

const keysToMapState = {
  new: 'BillingStateNew',
  inProgress: 'BillingStateInProgress',
  needsAction: 'BillingStateNeedAction',
  smallBalance: 'BillingStateSmallBalance',
  completed: 'BillingStateCompleted',
};

const masterCodes = [
  enumMasterCode.billingStateNew,
  enumMasterCode.billingStateInProgress,
  enumMasterCode.billingStateNeedAction,
  enumMasterCode.billingStateSmallBalance,
  enumMasterCode.billingStateCompleted,
  enumMasterCode.billingStageDenial,
  enumMasterCode.billingStageManual,
  enumMasterCode.billingStageNoResponse,
  enumMasterCode.collectionQueue,
];

const { error } = Modal;

const SideTabs = () => {
  const { push, generatePath, params } = useRedirect();

  const {
    type, claimFilter, tabId,
  } = params;

  const [remittanceBatchId, setRemittanceBatchId] = useState(0);
  const [remittanceEOBPageNumber, setRemittanceEOBPageNumber] = useState('1');
  const [patientPaymentVisible, setPatientPaymentVisible] = useState(false);
  const [eobEdiAction, setEobEdiAction] = useState('');
  const [disabedDownloadAndView, setDisabedDownloadAndView] = useState(true);

  const [claimDetails] = useCRUD({ id: `${listIds.CLAIM_DETAILS}-${tabId}`, url: apiUrls.GET_CLAIM_DETAILS, type: 'read' });
  const [
    remittanceDetail,,
    remittanceDetailLoading,
    getRemittanceDetail,
    clearRemittanceDetail,
  ] = useCRUD({ id: listIds.REMITTANCE_BATCH_DETAILS, url: apiUrls.GET_REMITTANCE_BATCH_DETAILS, type: 'read' });

  const [
    ediToHtmlFile,,
    ediToHtmlFileLoading,
    getEdiToHtmlFile,
    clearEdiToHtmlFileRes,
  ] = useCRUD({
    id: listIds.REMITTANCE_BATCH_EDIFILETOHTML,
    url: apiUrls.REMITTANCE_BATCH_EDIFILETOHTML,
    type: 'read',
  });

  const masterCodesWithId = useMasterId(masterCodes,
    [enums.BILLING_STATE, enums.BILLING_STAGE, enums.CLAIMS_QUEUE]);

  const stateId = masterCodesWithId[keysToMapState[claimFilter]];
  const stageId = masterCodesWithId[keysToMapStage[type]];
  const queueId = masterCodesWithId?.CollectionQueue;

  const downloadEDIFile = useCallback(async (fileUrl) => {
    if (!fileUrl) {
      Notification({ message: 'No EOB/EDI files present', success: false });
      return;
    }
    if (eobEdiAction === 'view') {
      openUrlInNewTab(fileUrl, remittanceEOBPageNumber);
      return;
    }
    const fileName = fileNameFromUrl(fileUrl);
    const file = await urltoFile(fileUrl, fileName);
    downloadFile(file, fileName);
    Notification({ message: 'File has been downloaded successfully', success: true });
  }, [eobEdiAction, remittanceEOBPageNumber]);

  useEffect(() => {
    if (claimDetails?.billingEncounterData) {
      if (
        stageId === masterCodesWithId[keysToMapStage.Denial]
        && claimDetails?.billingEncounterData?.serviceLineItems[0]?.remittance?.remittanceBatchId
        !== null
      ) {
        setDisabedDownloadAndView(false);
      }
      setRemittanceBatchId(
        claimDetails?.billingEncounterData.serviceLineItems[0]?.remittance.remittanceBatchId,
      );
      setRemittanceEOBPageNumber(
        claimDetails?.billingEncounterData.serviceLineItems[0]?.remittance.remmitanceEOBPageNumber,
      );
    }
  }, [claimDetails, masterCodesWithId, stageId]);

  useEffect(() => {
    if (ediToHtmlFile && !Array.isArray(ediToHtmlFile)) {
      const w = window.open('');
      w?.document?.write(ediToHtmlFile);
      w?.document?.close();
      clearEdiToHtmlFileRes(true);
    }
  }, [clearEdiToHtmlFileRes, ediToHtmlFile]);

  useEffect(() => {
    if (remittanceDetail && !Array.isArray(remittanceDetail)) {
      if (eobEdiAction === 'view' && remittanceDetail?.remittanceBatchData?.batchType === 'Electronic') {
        getEdiToHtmlFile({ batchId: remittanceBatchId });
      } else {
        downloadEDIFile(
          remittanceDetail.remittanceBatchData?.documentUrl,
        );
      }
      clearRemittanceDetail(true);
      setEobEdiAction('');
    }
  }, [remittanceDetail, eobEdiAction, remittanceBatchId]);

  const onClickCreateNewClaim = useCallback(() => {
    push(generatePath(UiRoutes.createNewClaimsPopup, { ...params }));
  }, [generatePath, params, push]);

  const onClickMarkForFollowUp = useCallback(() => {
    push(generatePath(UiRoutes.markForFollowUpPopUp, { ...params }));
  }, [generatePath, params, push]);

  const onClickPatientPayment = useCallback(() => {
    setPatientPaymentVisible(!patientPaymentVisible);
  }, [patientPaymentVisible]);

  const toggleModal = useCallback(() => setPatientPaymentVisible(!patientPaymentVisible),
    [patientPaymentVisible]);

  const onClickChangeResponsibility = useCallback(() => {
    push(generatePath(UiRoutes.changeResponsibilityPopUp, { ...params }));
  }, [generatePath, params, push]);

  const onClickBillPatient = useCallback(() => {
    push(generatePath(UiRoutes.billPatientPopUp, { ...params }));
  }, [generatePath, params, push]);

  const handleEobAction = useCallback((action) => () => {
    if (!remittanceBatchId) {
      error({
        title: 'Remittance is not created yet!',
      });
    } else {
      setEobEdiAction(action);
      getRemittanceDetail({}, `/${remittanceBatchId}`);
    }
  }, [getRemittanceDetail, remittanceBatchId]);

  const [isPerformInsuranceAdjustmentAuthenticated] = useRights(
    [rights.perform_insurance_adjustment],
  );

  return (
    <div className="side-tabs">
      {(remittanceDetailLoading || ediToHtmlFileLoading) && <Loader />}
      <Button onClick={onClickCreateNewClaim}>Create new claim</Button>
      <Button onClick={onClickChangeResponsibility}>Change Responsibility</Button>
      <Button onClick={onClickBillPatient}>Bill Patient</Button>
      <Button onClick={onClickPatientPayment}>Enter Payment</Button>
      {isPerformInsuranceAdjustmentAuthenticated && (
      <EnterAdjustment
        stateId={stateId}
        stageId={stageId}
        queueId={queueId}
      />
      )}
      <Button disabled>Create New Attachment</Button>
      <Button disabled>Check Active Status</Button>
      <Button disabled>Active Coverage</Button>
      <Button disabled>Financial settings</Button>
      <Button onClick={onClickMarkForFollowUp}>Mark for follow-up</Button>
      <Button
        disabled={disabedDownloadAndView}
        onClick={handleEobAction('view')}
      >
        View EOB/EDI
      </Button>
      <Button
        disabled={disabedDownloadAndView}
        onClick={handleEobAction('download')}
      >
        Download EOB/EDI
      </Button>
      <ProofOfFilling />
      <CheckEligibility />
      <PatientPayment
        visible={patientPaymentVisible}
        toggleModal={toggleModal}
        isCollectionsTab
        stateId={stateId}
        stageId={stageId}
        queueId={queueId}
      />
    </div>
  );
};

export default SideTabs;
