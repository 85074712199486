import { Map } from 'immutable';
import last from 'lodash/last';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';

import {
  SET_SELECTED_USER,
  GET_USERS,
  SET_UPDATED_USER_IN_STORE,
  CLEAR_SELECTED_USER,
  SET_REDIRECT_KEY,
  SET_ERROR,
  SET_LOADING,
  SET_UPDATE,
  SET_USER_SETTINGS,
} from '../actions/users';
import { decodeUserSettings } from '../../lib/util';

const getLocalUserData = () => {
  let localUserData = {};
  try {
    const userData = JSON.parse(localStorage.getDecryptedData('user'));
    if ((userData?.roleName || '').toLowerCase() === 'physician' && userData?.providerId) {
      localUserData = {
        doctor: userData.providerId,
        doctors: [userData.providerId],
        location: userData.locationId,
      };
    }
  } catch {
    return {};
  }
  return localUserData;
};

const initialState = Map({
  users: [],
  userSettings: {},
});

const actionsMap = {
  [GET_USERS]: (state) => Map({
    ...state,
    users: [],
  }),
  [SET_SELECTED_USER]: (state, action) => {
    const { user } = action;
    return state.set('selectedUser', user);
  },
  [SET_REDIRECT_KEY]: (state, action) => {
    const { userId } = action;
    return state.set('redirectToEditKey', userId);
  },
  [SET_UPDATED_USER_IN_STORE]: (state, action) => {
    const { payload } = action;
    return state.set('selectedUser', payload);
  },
  [CLEAR_SELECTED_USER]: (state) => state.set('selectedUser', {})
    .set('redirectToEditKey', null)
    .set('error', null)
    .set('loading', false)
    .set('update', false),
  [SET_LOADING]: (state, action) => {
    const { flag } = action;
    return state.set('loading', flag);
  },
  [SET_ERROR]: (state, action) => {
    const { error } = action;
    return state.set('error', error);
  },
  [SET_UPDATE]: (state, action) => {
    const { flag } = action;
    return state.set('update', flag);
  },
  [SET_USER_SETTINGS]: (state, { payload }) => {
    if (payload) {
      let current = {};

      if (localStorage.getDecryptedData('userLoggedIn')) { // only on login
        current = getLocalUserData();
      }

      const defaultProviders = decodeUserSettings(payload?.defaultProviders);
      const defaultLocations = decodeUserSettings(payload?.defaultLocations);
      const schedulerFilters = decodeUserSettings(payload?.schedulerFilters);
      const frontdeskFilters = decodeUserSettings(payload?.frontdeskFilters);

      const filtersArray = [schedulerFilters, frontdeskFilters, defaultLocations, defaultProviders];

      const latestFilterFirst = reverse(sortBy(
        filtersArray.filter((filter) => filter?.lastModified),
        ['lastModified'],
      ));

      latestFilterFirst.some((filter) => {
        if (filter) {
          if (!current?.doctor && filter?.doctor) { // single latest doctor
            const doctor = Array.isArray(filter?.doctor) && filter.doctor.length
              ? last(filter.doctor) : filter.doctor;
            current.doctor = Number.isNaN(Number(doctor)) ? doctor : parseInt(doctor, 10);
          }
          if (!current?.doctors && filter?.doctor) { // many latest doctors
            const doctors = Array.isArray(filter?.doctor)
              ? ((filter.doctor.length && filter.doctor) || undefined) : [filter.doctor];
            current.doctors = doctors.map(
              (doctorId) => (Number.isNaN(Number(doctorId)) ? doctorId : parseInt(doctorId, 10)),
            );
          }
          if (!current?.location && filter?.location) { // single latest location
            current.location = filter?.location && Number.isNaN(Number(filter?.location))
              ? filter?.location
              : parseInt(filter?.location, 10);
          }
          return current?.doctor && current?.doctors && current?.location;
        }
        return false;
      });

      if (schedulerFilters) {
        schedulerFilters.doctor = current?.doctors
          || (current?.doctor ? [current?.doctor] : undefined);
        schedulerFilters.location = current.location;
        current.schedulerFilters = schedulerFilters;
        current.schedulerParams = decodeUserSettings(schedulerFilters, true);
      }

      if (frontdeskFilters) {
        frontdeskFilters.doctor = current?.doctors
          || (current?.doctor ? [current?.doctor] : undefined);
        frontdeskFilters.location = current.location;
        current.frontdeskFilters = frontdeskFilters;
      }

      let localStorageData = JSON.parse(localStorage.getDecryptedData('userSettingsData')) || {};
      localStorageData = { ...localStorageData, ...payload, current };
      localStorage.setEncryptedData('userSettingsData', JSON.stringify(localStorageData));

      return state.set('userSettings', { ...payload, current });
    }
    return state;
  },
};

export default function users(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
