import moment from 'moment';

import formFieldValueParser from '../../../../../../lib/formFieldValuesParser';

// eslint-disable-next-line import/prefer-default-export
export const beforeFinishParser = (entity, formAllData) => {
  const data = formFieldValueParser(entity, {
    date: [
      'effectiveDateFrom',
      'effectiveDateTo',
      'dateOfServicesManual',
    ],
  });
  if (data.dosOptionsSelected !== 'appointments') {
    data.dateOfServices = [{ dateOfService: data.dateOfServicesManual }];
  } else {
    data.dateOfServices = formAllData.dateOfServices;
  }

  data.dateOfServices = data?.dateOfServices?.map(
      (date) => ({ ...date, dateOfService: moment(date.dateOfService).format() }),
    );

  if (data?.documentIds?.length) {
    data.documentIds = data.documentIds.map((documentId) => `${documentId}`).join(',');
  } else {
    data.documentIds = '';
  }

  return data;
};
