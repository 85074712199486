import React, {
  useCallback, useEffect, useState, useMemo,
} from 'react';
import debounce from 'lodash/debounce';

import useCRUD from '../../../../../../../../../../hooks/useCRUD';
import useRedirect from '../../../../../../../../../../hooks/useRedirect';

import { apiUrls } from '../../../../../../../../../../api/constants';
import Events from '../../../../../../../../../../lib/events';
import SuccessMessages from '../../../../../../../../../../lib/successMessages';
import ErrorMessages from '../../../../../../../../../../lib/errorMessages';

import Icon from '../../../../../../../../../../components/Icon';
import Form from '../../../../../../../../../../components/Form';
import Button from '../../../../../../../../../../components/Button';
import Search from '../../../../../../../../../../components/Search';
import Notification from '../../../../../../../../../../components/Notification';
import RadioGroup from '../../../../../../../../../../components/RadioGroup';
import ConfirmDialog from '../../../../../../../../../../components/ConfirmDialog';
import WidgetLoader from '../../../../../../../../../../components/WidgetLoader';
import DroppableJsonTree from './Components/DroppableJsonTree';

import { libraryParser, procedureSetParser as parser } from '../../../../../../../../commonParser';
import LibraryDroppableTree from './Components/DroppableJsonTree/LibraryDroppableTree';
import GlobalPeriodModal from '../../../../CustomDropppableTab/Component/globalPeriodModal';

const tabList = [
  { label: 'Practice', name: 'practice', value: 'practice' },
  { label: 'Provider', name: 'provider', value: 'provider' },
  { label: 'Library', name: 'library', value: 'library' },
];

const ProcedureSets = ({
  procedureSetsList, setProcedureSetsList,
}) => {
  const { params: { providerId, patientId, encounterId } } = useRedirect();
  const [currentTab, setCurrentTab] = useState('provider');
  const [activeIds, setActiveIds] = useState(false);
  const [showHideCount, setShowHide] = useState(0);
  const [libraryList, setLibraryList] = useState();
  const [showAllExpended, setShowAllExpended] = useState('');
  const [applyProcedureSetPayload, setApplyProcedureSetPayload] = useState({});

  const getProcedureHistory = useCRUD({ id: 'procedure-history-table-crud-data', url: apiUrls.DOCTOR_PROCEDURES, type: 'read' })[3];
  const [
    deleteResponse,,
    deleteLoading,
    deleteInOfficeProcedureSet,
    clearDeleteResponse] = useCRUD({
    id: 'delete-procedure-set',
    url: apiUrls.DELETE_FAVOURITE_SET,
    type: 'create',
  });

  const [
    applyProcedureSetResponse,
    applyProcedureSetError,
    applyLoading,
    applyProcedureSet,
    clearProcedureSetResponse,
  ] = useCRUD({
    id: 'apply-procedure-set-button',
    url: apiUrls.APPLY_PROCEDURE_ORDER_SET,
    type: 'create',
    shouldClearError: false,
  });

  const [
    inOfficeProcedureSets,,
    loading,
    getInOfficeProcedureSet,
    clearInofficeProcedures,
  ] = useCRUD({
    id: 'get-procedure-set',
    url: apiUrls.GET_IN_OFFICE_PROCEDURE_SETS,
    type: 'read',
  });

  const [
    copyProcedureSets,,
    copyLoading,
    copyProcedures,
    clearCopyProcedureSets,
  ] = useCRUD({
    id: apiUrls.COPY_PROVIDERS_FAVORITE_SET,
    url: apiUrls.COPY_PROVIDERS_FAVORITE_SET,
    type: 'create',
  });

  useEffect(() => {
    if (copyProcedureSets) {
      Notification({
        message: SuccessMessages.PROCEDURE_SET_COPY_FAVORITE_SUCCESSFULLY,
        success: true,
      });
    }
    clearCopyProcedureSets(true);
  }, [clearCopyProcedureSets, copyProcedureSets]);

  useEffect(() => {
    if (applyProcedureSetResponse) {
      Events.trigger(`refetch-InOffice-ProcedureList-${encounterId}`);
      Notification({ message: SuccessMessages.PROCEDURE_SET_APPLIED_SUCCESSFULLY, success: true });
      getProcedureHistory({
        SortBy: 'createdOn',
        SortOrder: 'desc',
        PatientId: patientId,
        EncounterID: encounterId,
        Source: 'Overview',
        PageIndex: 0,
        PageSize: 999,
      });
      clearProcedureSetResponse(true);
    }
  }, [applyProcedureSetResponse, encounterId]);

  useEffect(() => {
    getInOfficeProcedureSet({ IsActive: true, ProviderId: providerId, source: 'ProcedureSet' });
  }, []);

  const debounceFunction = useMemo(() => debounce((value) => {
    const data = { IsActive: true };
    if (currentTab === 'provider') {
      data.providerId = providerId;
    }
    if (currentTab === 'library') {
      data.ProviderId = providerId;
      data.isLibrary = true;
    }
    data.SearchText = value;
    setShowAllExpended(value?.trim());
    getInOfficeProcedureSet({ ...data, source: 'ProcedureSet' });
  }, 1500), [currentTab, getInOfficeProcedureSet, providerId]);

  const onSearch = useCallback((event) => {
    event.persist();
    if (event?.target?.value?.trim?.()?.length) {
      debounceFunction(event?.target?.value?.trim?.());
    } else {
      debounceFunction('');
    }
  }, [debounceFunction]);

  const deleteProcedureSet = useCallback((node, event) => {
    event.stopPropagation();
    if (node) {
      ConfirmDialog({
        onOk: (close) => {
          deleteInOfficeProcedureSet({ data: { favoriteId: node } });
          close();
        },
        okText: 'Ok',
        title: 'Warning',
        content: 'Are you sure, you want to delete this procedure set?',
        icon: <Icon name="ExclamationCircleOutlined" />,
      })();
    }
  }, [deleteInOfficeProcedureSet]);

  useEffect(() => {
    if (deleteResponse) {
      Notification({ message: 'Procedure set has been deleted successfully', success: true });
      clearDeleteResponse();
      const data = { IsActive: true, source: 'ProcedureSet' };
      if (currentTab === 'provider') {
        data.providerId = providerId;
      }
      getInOfficeProcedureSet(data);
    }
  }, [clearDeleteResponse, deleteResponse, currentTab, getInOfficeProcedureSet, providerId]);

  useEffect(() => {
    if (inOfficeProcedureSets && currentTab !== 'library') {
      setProcedureSetsList(parser(inOfficeProcedureSets));
    }
    if (inOfficeProcedureSets && currentTab === 'library') {
      setLibraryList(libraryParser(inOfficeProcedureSets));
    }
  }, [currentTab, inOfficeProcedureSets, setProcedureSetsList]);

  const onNodeClick = useCallback((event, id) => {
    event.stopPropagation();
    const payload = {
      FavoriteId: id,
      PatientId: patientId,
      EncounterId: encounterId,
      providerId,
    };
    setApplyProcedureSetPayload(payload);
    applyProcedureSet({
      data: payload,
    });
  }, [applyProcedureSet, encounterId, patientId, providerId]);

  useEffect(() => {
    if (applyProcedureSetError?.toLowerCase()?.includes(ErrorMessages?.SAME_PROCEDURE_ERROR)
        || applyProcedureSetError?.toLowerCase()?.includes(ErrorMessages?.TIME_ERROR_FOR_PROCEDURE)
        || applyProcedureSetError?.toLowerCase()
        ?.includes(ErrorMessages?.ERROR_FOR_ALREADY_USED_PROCEDURE)) {
      GlobalPeriodModal(
        applyProcedureSetError,
        applyProcedureSet,
        applyProcedureSetPayload,
        clearProcedureSetResponse,
      );
    } else if (applyProcedureSetError) {
      Notification({ message: applyProcedureSetError, success: false });
      clearProcedureSetResponse(true);
    }
  }, [applyProcedureSetError]);

  useEffect(() => {
    Events.on(`fetch-procedure-sets-${encounterId}`, `fetch-procedure-sets-${encounterId}`, () => {
      const data = { IsActive: true, source: 'ProcedureSet' };
      if (currentTab === 'provider') {
        data.providerId = providerId;
      }
      getInOfficeProcedureSet(data);
    });
    return Events.remove(`fetch-procedure-sets-${encounterId}`);
  }, [encounterId, providerId, currentTab]);

  const tabSwitchHandler = useCallback(({ target: { value } }) => {
    clearInofficeProcedures(true);
    setCurrentTab(value);
    const data = { IsActive: true, source: 'ProcedureSet' };
    if (value === 'provider') {
      data.providerId = providerId;
    }
    if (value === 'library') {
      data.providerId = providerId;
      data.isLibrary = true;
    }
    getInOfficeProcedureSet(data);
  }, [clearInofficeProcedures, getInOfficeProcedureSet, providerId]);

  const showHideOrderSets = useCallback((val) => {
    if (val) {
      setActiveIds(true);
    } else {
      setActiveIds(false);
    }
    setShowHide(showHideCount + 1);
  }, [showHideCount]);

  const copyProviderAllProcedureSets = useCallback((data, event) => {
    event.stopPropagation();
    copyProcedures({ data });
  }, [copyProcedures]);

  return (
    <>
      <div className="mr-bt-8 flex justify-content-flex-end fix-height-tabs">
        <RadioGroup
          tabList={tabList}
          onChange={tabSwitchHandler}
          value={currentTab}
        // labels={labels}
          id="contract_terms_tabs"
        />
      </div>
      <div className="procedures posi-relative procedure-right-section">
        { (deleteLoading || loading || applyLoading || copyLoading)
        && <WidgetLoader />}
        <div className="side-tab">
          <div className="merge-fields-container">
            <div className="temp-search-wrapper">
              <Form.Section noOfColumns={2}>
                <Form.Column>
                  <Search name="searchText" onChange={onSearch} />
                </Form.Column>
              </Form.Section>
            </div>
            <div className="wrap-expend-collapse">
              <Button className="btn btn btn-ghost sm-btn" onClick={() => showHideOrderSets(true)}>Expand All</Button>
              <Button className="btn btn btn-ghost sm-btn" onClick={() => showHideOrderSets(false)}>Collapse All</Button>
            </div>
            <div className="json-tree-container procedure-set-tree-wrap">
              {currentTab === 'library'
                ? (
                  <LibraryDroppableTree
                    type="procedureSets"
                    showFollowUpExamFinding={false}
                    activeIds={activeIds}
                    showHideCount={showHideCount}
                    libraryList={libraryList}
                    copyProviderAllProcedureSets={copyProviderAllProcedureSets}
                  />
                )
                : (
                  <DroppableJsonTree
                    labelList={procedureSetsList}
                    type="procedureSets"
                    deleteProcedureSet={deleteProcedureSet}
                    showFollowUpExamFinding={false}
                    activeIds={activeIds}
                    showHideCount={showHideCount}
                    onNodeClick={onNodeClick}
                    currentTab={currentTab}
                    disableParentCloseIcon
                    showAllExpended={showAllExpended?.length}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProcedureSets;
