import mammoth from 'mammoth';
import axios from 'axios';

import { useEffect, useRef } from 'react';

const parseToDocx = async (url) => {
  try {
    const axiosConfig = { method: 'get', url, responseType: 'arraybuffer' };
    const file = await axios(axiosConfig);
    const docxFile = { arrayBuffer: file.data };
    const config = { includeDefaultStyleMap: true };
    const docx = await mammoth.convertToHtml(docxFile, config);
    return docx.value;
  } catch (e) {
    return e;
  }
};

const useDocx = (path) => {
  const container = useRef(null);

  useEffect(() => {
    const createDocx = async () => {
      const html = await parseToDocx(path);
      if (container.current) {
        container.current.innerHTML = html;
      }
    };
    createDocx();
  }, [path, container]);

  return container;
};

export default useDocx;
