import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  List, Avatar,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';

import Button from '../../../../components/Button';
import { urls } from '../../../../lib/constants';

import './DoctorList.scss';

const ListItem = ({
  data, style, labels, scheduleAppointment,
}) => {
  const {
    locationAddressLine1,
    locationAddressLine2,
    locationAddress,
    locationDistance,
    // locationId,
    // locationLatitude,
    // locationLongitude,
    locationName,
    providerSpeciality,
    providerId,
    providerName,
  } = data;

  const onScheduleAppointment = useCallback(
    () => scheduleAppointment(providerId),
    [scheduleAppointment, providerId],
  );

  return (
    <List.Item key={providerId} style={style} data-testid="doctor-item">
      <div className="list-item">
        <div className="header">
          <div className="doctor-basic-info">
            <Avatar size={50} icon={<UserOutlined />} />
            <div className="text-info">
              <h3 className="name">{providerName}</h3>
              <span className="text">{providerSpeciality ?? ''}</span>
            </div>
          </div>
          <div>
            <Button
              className="btn btn-success"
              onClick={onScheduleAppointment}
            >
              {labels.get('buttons.schedule')}
            </Button>
          </div>
        </div>
        <div><p className="text">{locationName}</p></div>
        <div className="content">
          <div>
            <p className="text">
              {locationAddressLine1}
              <br />
              {locationAddressLine2 || locationAddress || ''}
              <br />
              {locationAddressLine2 ? locationAddress : ''}
            </p>
          </div>
          <div><p className="distance-info">{`Approx ${locationDistance?.toFixed(0)} miles`}</p></div>
        </div>
        <div className="footer">
          {/* <a className="profile vr-seperator">
            <span className="icon">{labels.get('buttons.viewProfile')}</span>
          </a> */}
          <a className="direction " target="_blank" rel="noopener noreferrer" href={`${urls.SEARCH_MAP}${locationAddress}`}>
            <span className="icon">{labels.get('buttons.directions')}</span>
          </a>
        </div>
      </div>
    </List.Item>
  );
};

const DoctorList = ({ data, labels, scheduleAppointment }) => (
  <div className="list-wrapper" data-testid="doctor-list">
    <List
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(itemData, key, style) => (
        <ListItem
          data-testid="doctor-item"
          data={itemData}
          labels={labels}
          style={style}
          scheduleAppointment={scheduleAppointment}
        />
      )}
    />
  </div>
);

ListItem.defaultProps = {
  data: {},
  scheduleAppointment: () => { /* This is intentional */ },
};
DoctorList.defaultProps = {
  data: [],
  scheduleAppointment: () => { /* This is intentional */ },
};

ListItem.propTypes = {
  data: PropTypes.objectOf(PropTypes.string),
  scheduleAppointment: PropTypes.func,
};
DoctorList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  scheduleAppointment: PropTypes.func,
};

export default DoctorList;
