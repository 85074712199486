import React, {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import { Form as AntdForm } from 'antd';
import debounce from 'lodash/debounce';

import Search from 'antd/lib/input/Search';
import WithLabel from '../../../hoc/withLabel';
import { labelPaths } from '../../../lib/constants';

import Modals from '../../../components/Modal';
import Button from '../../../components/Button';

import AddTask from './Components/AddTask';
import TaskTable from './Components/TaskTable';

import PatientTable from '../../PatientTable';

import './tasks.scss';
import Smcheckbox from '../../../components/SmCheckbox';
import useRedirect from '../../../hooks/useRedirect';

const TaskModalWrapper = ({
  visible, onClose, selectedTaskId, onSaveNewTask, labels, addMode, toggleAddMode, component,
}) => (
  <Modals
    visible={visible}
    width={1020}
    toggleModal={onClose}
    destroyOnClose
    footer={addMode && (
      <div className="group-btns" key="footer">
        <Button className="btn btn-success min-wt-86 inline" onClick={onSaveNewTask}>
          {labels.get(selectedTaskId ? 'buttons.update' : 'buttons.save')}
        </Button>
        <Button className="btn min-wt-86" onClick={toggleAddMode}>
          {labels.get('buttons.cancel')}
        </Button>
      </div>
    )}
  >
    {component}
  </Modals>
);

const TasksModal = ({
  labels, visible, toggleModal, ...otherProps
}) => {
  const { params } = useRedirect();
  const [patientId, setSelectedPatientId] = useState(null);
  const [addMode, setAddMode] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(false);
  const [search, setSearch] = useState(null);
  const [isExclude, setExclude] = useState(true);
  const debounced = useMemo(() => debounce(setSearch, 1000), [setSearch]);

  useEffect(() => {
    if (otherProps?.patientId) setSelectedPatientId(otherProps.patientId);
  }, [otherProps, otherProps.patientId]);

  const [form] = AntdForm.useForm();
  const toggleAddMode = useCallback(() => {
    setAddMode(!addMode);
    setSelectedTaskId(null);
  }, [addMode]);

  const onSaveNewTask = useCallback(() => {
    form.submit();
  }, [form]);

  const onClose = useCallback(() => {
    setSearch('');
    if (addMode) toggleAddMode();
    toggleModal();
    if (!params?.id && !params?.patientId) setSelectedPatientId(null);
  }, [addMode, params, toggleAddMode, toggleModal]);

  const handleEditTask = useCallback(({ original: { taskId } }) => {
    toggleAddMode();
    setSelectedTaskId(taskId);
  }, [toggleAddMode]);

  const setSelectedPatient = useCallback((patient) => {
    setSelectedPatientId(patient.patientId);
  }, []);

  const handleExclude = useCallback(({ target: { checked } }) => setExclude(checked), []);
  const handleSearch = useCallback(({ target: { value } }) => debounced(value), [debounced]);

  if (patientId) {
    return (
      <TaskModalWrapper
        visible={visible}
        onClose={onClose}
        selectedTaskId={selectedTaskId}
        onSaveNewTask={onSaveNewTask}
        labels={labels}
        addMode={addMode}
        toggleAddMode={toggleAddMode}
        component={(
          <>
            <div className="addition-header tasks-modal-header">
              <p className="bold">{labels.get('titles.tasks')}</p>
              {!addMode && (
              <Button type="button" className="btn sm-btn btn-success" onClick={toggleAddMode}>
                {labels.get('buttons.newTask')}
              </Button>
              )}
            </div>
            <div className="modal-content-area">
              {addMode
                ? (
                  <AddTask
                    toggleAddMode={toggleAddMode}
                    form={form}
                    labels={labels}
                    patientId={patientId}
                    taskId={selectedTaskId}
                    {...otherProps}
                  />
                )
                : (
                  <div className="custom-task-table-task">
                    <TaskTable
                      data={[]}
                      patientId={patientId}
                      onRowClick={handleEditTask}
                      labels={labels}
                      scrollId="tasks-modal-table"
                      {...otherProps}
                    />
                  </div>
                )}
            </div>
          </>
      )}
      />
    );
  }
  return (
    <TaskModalWrapper
      visible={visible}
      onClose={onClose}
      selectedTaskId={selectedTaskId}
      onSaveNewTask={onSaveNewTask}
      labels={labels}
      addMode={addMode}
      toggleAddMode={toggleAddMode}
      component={(
        <>
          <div className="addition-header tasks-modal-header">
            <p className="bold">{labels.get('titles.tasks')}</p>
          </div>
          <div className="modal-content-area">
            <div className="patient-list-modal">
              <div className="header-wrapper">
                <div className="filter-wrapper">
                  <Search
                    className="patent-list-search"
                    onChange={handleSearch}
                    placeholder="Search"
                  />
                  <Smcheckbox
                    isFormItem={false}
                    checked={isExclude}
                    onChange={handleExclude}
                    className="exclude-filter"
                  >
                    {labels.get('labels.excludeInactive')}
                  </Smcheckbox>
                </div>
              </div>
              <PatientTable
                labels={labels}
                onRowClick={setSelectedPatient}
                filters={{ SearchText: search, ExcludeInactivePatients: isExclude }}
              />
            </div>
          </div>
        </>
        )}
    />
  );
};

export default WithLabel(TasksModal, labelPaths.TASKS_MODAL);
