import React, {
  forwardRef,
  useCallback, useEffect, useImperativeHandle, useMemo,
} from 'react';
import moment from 'moment';

import { apiUrls } from '../../../../../../api/constants';
import { listIds } from '../../../../../../lib/constants';

import useCRUD from '../../../../../../hooks/useCRUD';
import useRedirect from '../../../../../../hooks/useRedirect';

import Input from '../../../../../../components/Form/Input';
import WidgetLoader from '../../../../../../components/WidgetLoader';

const InsuranceRefundTable = forwardRef(({
  labels, onDataChange, lineItemData, setLineItemData, claimId,
}, ref) => {
  const { params } = useRedirect();

  const [
    claimDetails,, loading,
    getClaimDetails,
  ] = useCRUD({
    id: listIds.PATIENT_SERVICE_LINE_ITEM,
    url: apiUrls.GET_INSURANCE_REFUND_EDIT_LINE_ITEMS,
    type: 'read',
  });

  useEffect(() => {
    getClaimDetails({
      FinanceId: params?.financialId,
      IsPatientResponsibility: false,
      claimId,
    });
  }, [params.id]);

  useEffect(() => {
    if (claimDetails) {
      // all data fields has to be float
      const parsedData = claimDetails?.map((item) => ({
        financeId: item?.financeId,
        dateString: moment(item?.effectiveDate).format('MM-DD-YYYY'),
        cptCode: item?.description,
        balance: item?.balance,
        billed: item?.amount,
        paid: item?.paid,
        patientId: item?.patientId,
      }));
      setLineItemData(parsedData);
      if (onDataChange) {
        onDataChange(parsedData);
      }
    }
  }, [claimDetails, onDataChange, setLineItemData]);

  const handleLineItemData = useCallback((index) => ({ target: { value, name } }) => {
    const clonedLineItemData = [...lineItemData];
    const currentLineItemData = { ...lineItemData[index], [name]: parseFloat(value) };
    clonedLineItemData[index] = {
      ...currentLineItemData,
    };
    setLineItemData(clonedLineItemData);
    if (onDataChange) {
      onDataChange(clonedLineItemData);
    }
  }, [lineItemData]);

  const totals = useMemo(() => {
    const total = {
      billed: 0,
      paid: 0,
      balance: 0,
      refund: 0,
    };
    // eslint-disable-next-line no-unused-expressions
    lineItemData?.forEach(({
      billed = 0, paid = 0, balance = 0, refund = 0,
    }) => {
      total.billed += (billed);
      total.paid += (paid);
      total.balance += (balance);
      total.refund += (refund);
    });
    return total;
  }, [lineItemData]);

  useImperativeHandle(ref, () => ({
    checkIfRefundGreaterThanPaid() {
      return totals?.paid < totals?.refund;
    },
  }));

  return (
    <div className="patient-line-item-table min-width-100 posi-relative">
      {loading && <WidgetLoader />}
      <div className="app-table">
        <table>
          <thead>
            <tr>
              <th>{labels.get('tableColumns.dateOfService')}</th>
              <th>{labels.get('tableColumns.description')}</th>
              <th className="text-align-right">{labels.get('tableColumns.billed')}</th>
              <th className="text-align-right">{labels.get('tableColumns.paid')}</th>
              <th className="text-align-right">{labels.get('tableColumns.balance')}</th>
              <th>{labels.get('tableColumns.refund')}</th>
            </tr>
          </thead>
          {lineItemData?.length ? (
            <tbody>
              {lineItemData?.map((item, index) => (
                <tr key={item?.id}>
                  <td className="text-align-left">{item?.dateString}</td>
                  <td className="text-align-left">{item?.cptCode}</td>
                  <td className="text-align-right">{item?.billed}</td>
                  <td className="text-align-right">{item?.paid}</td>
                  <td className="text-align-right">{item?.balance}</td>
                  <td>
                    <Input
                      isFormItem={false}
                      numberOnly
                      decimalPlaces={2}
                      labelSpan={0}
                      inputSpan={24}
                      value={item?.approved}
                      onChange={handleLineItemData(index)}
                      name="refund"
                    />
                  </td>
                </tr>
              ))}
              <tr>
                <td>Total</td>
                <td />
                <td className="text-align-right">{(totals?.billed?.toFixed(2))}</td>
                <td className="text-align-right">{(totals?.paid?.toFixed(2))}</td>
                <td className="text-align-right">{(totals?.balance?.toFixed(2))}</td>
                <td className="text-align-right">{(totals?.refund?.toFixed(2))}</td>
              </tr>
            </tbody>
          )
            : (
              <tbody>
                <tr>
                  <td colSpan={15} className="custom-no-data-text">
                    No Data Found
                  </td>
                </tr>
              </tbody>
)}
        </table>
      </div>
    </div>
  );
});

export default InsuranceRefundTable;
