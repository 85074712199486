import React, { useCallback } from 'react';
import AutoComplete from '../../components/Form/GridAutoComplete';
import { apiUrls } from '../../api/constants';
import commonDefaultPropTypes from '../commonDefaultPropType';

const columns = [
  {
    Header: 'Code',
    accessor: 'icdCode',
    flex: 1,
  },
  {
    Header: 'Description',
    accessor: 'description',
    flex: 3,
  },
];

const CPTICDAutoComplete = ({
  disabled,
  required,
  initialValue,
  labelSpan,
  inputSpan,
  onSelect,
  styleWidth,
  name,
  className,
  dropdownMatchSelectWidth,
  selectProps,
  params,
  ...props
}) => {
  const ICDOptionParser = useCallback((option) => ({
    ...option,
    name: option.icdCode,
    value: option.id,
  }), []);

  return (
    <div className="filter-modifier-autocomplete">
      <AutoComplete
        name={name}
        url={apiUrls.GET_ICD_CPT_MAPPING}
        optionParser={ICDOptionParser}
        optionMaster="ICDCodeText"
        labelSpan={labelSpan}
        inputSpan={inputSpan}
        disabled={disabled}
        required={required}
        initialValue={initialValue}
        columns={columns}
        style={styleWidth ? { width: '100%' } : {}}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        className={className}
        notFoundContent="ICD Code is not present is the system."
        showArrow
        labelInValue
        selectProps={{ allowClear: true, ...selectProps, optionLabelProp: 'name' }}
        params={params}
        onSelect={onSelect}
        {...props}
      />
    </div>
  );
};

CPTICDAutoComplete.defaultProps = {
  disabled: false,
  required: false,
  initialValue: {},
  labelSpan: '0',
  inputSpan: '24',
  onSelect: () => { /* This is intentional */ },
  styleWidth: true,
  name: 'procedure',
  className: '',
  dropdownMatchSelectWidth: false,
  params: {},
  placeholder: 'ICD Code',
  label: 'ICDCode',
};

CPTICDAutoComplete.propTypes = {
  ...commonDefaultPropTypes,
};

export default CPTICDAutoComplete;
