import React, { useState, useCallback } from 'react';

import { UiRoutes } from '../../../../lib/constants';
import rights from '../../../../lib/rights';

import useRedirect from '../../../../hooks/useRedirect';
import useTabLink from '../../../../hooks/useTabLink';
import usePatientDetail from '../../../../hooks/usePatientDetail';
import useRights from '../../../../hooks/useRights';

import BalanceSheetTable from '../../../../wiredComponents/Adustment/Components/BalanceSheetTable';
import GroupSelect from '../../../../components/GroupSelect';

import PatientPayment from './Popups/Patient/PatientPayment';
import PatientSimpleCharge from './Popups/Patient/PatientSimpleCharge';
import EditInsuranceRefund from './Components/EditInsuranceRefund';
import InsurancePayment from './Popups/Insurance/InsurancePayment';
import ChargeTable from './ChargeTable';
import PatientRefund from './Popups/Patient/PatientRefund';

const selectData = (labels) => [
  {
    listLabel: labels.get('popups.patient'),
    list: [
      { name: labels.get('popups.patientPayment'), value: 'PatientPayment' },
      { name: labels.get('popups.patientSimpleCharge'), value: 'PatientSimpleCharge' },
      { name: labels.get('popups.patientAdjustment'), value: 'PatientAdjustment' },
    ],
  },
  {
    listLabel: 'Insurance',
    list: [
      { name: labels.get('popups.insurancePayment'), value: 'InsurancePayment' },
      { name: labels.get('popups.insuranceRefund'), value: 'InsuranceRefund' },
      { name: labels.get('popups.insuranceAdjustment'), value: 'InsuranceAdjustment' }],
  },
];

const Financials = ({
  parentParams, labels, setType,
}) => {
  const { navigate: navigatePatientAdjustment } = useTabLink({
    to: UiRoutes.newAdjustmentWithTabId,
    id: UiRoutes.editPatientWithTabId,
    params: { ...parentParams },
  });
  const { navigate: navigateInsuranceAdjustment } = useTabLink({
    to: UiRoutes.newAdjustmentWithTabId,
    id: UiRoutes.editPatientWithTabId,
    params: { ...parentParams },
  });
  const { params, generatePath } = useRedirect();

  const [patientData] = usePatientDetail({ patientId: params?.id });

  const [modalVisible, setModalVisibility] = useState();

  const [
    isInsuracneTransactionAuthenticated,
    isPatientTransactionAuthenticated,
  ] = useRights([
    rights.enter_insurance_transaction,
    rights.enter_patient_transaction,
  ]);

  const navigateToPatientAdjustment = useCallback(() => {
    navigatePatientAdjustment({ data: { name: `Adjustment-${patientData?.firstName || ''} ${patientData?.middleName || ''} ${patientData?.lastName || ''}` }, ...params, adjustmentType: 'patientAdjustment' });
  }, [navigatePatientAdjustment, patientData, params]);

  const navigateToInsuranceAdjustment = useCallback(() => {
    navigateInsuranceAdjustment({ data: { name: `Adjustment-${patientData?.firstName || ''} ${patientData?.middleName || ''} ${patientData?.lastName || ''}` }, ...params, adjustmentType: 'insuranceAdjustment' });
  }, [navigateInsuranceAdjustment, patientData, params]);

  const toggleModal = useCallback((value) => {
    if (value === 'PatientAdjustment') {
      navigateToPatientAdjustment(value);
    } else if (value === 'InsuranceAdjustment') {
      navigateToInsuranceAdjustment(value);
    } else {
      setModalVisibility(value);
    }
  }, [navigateToInsuranceAdjustment, navigateToPatientAdjustment]);

  const defaultList = useCallback(() => {
    let list = selectData(labels);
    if (!isPatientTransactionAuthenticated && !isInsuracneTransactionAuthenticated) {
      list = [];
    } else if (!isPatientTransactionAuthenticated) {
      list.splice(0, 1);
    } else if (!isInsuracneTransactionAuthenticated) {
      list.splice(1, 1);
    }
    return list;
  }, [labels, isPatientTransactionAuthenticated, isInsuracneTransactionAuthenticated]);

  return (
    <>
      <PatientPayment key={`PatientPayment-${params?.tabId}`} visible={modalVisible === 'PatientPayment'} toggleModal={toggleModal} />
      <PatientSimpleCharge key={`PatientSimpleCharge-${params?.tabId}`} visible={modalVisible === 'PatientSimpleCharge'} toggleModal={toggleModal} />
      <InsurancePayment key={`InsurancePayment-${params?.tabId}`} visible={modalVisible === 'InsurancePayment'} toggleModal={toggleModal} />
      <EditInsuranceRefund key={`EditInsuranceRefund-${params?.tabId}`} visible={modalVisible === 'InsuranceRefund'} toggleModal={toggleModal} />
      <PatientRefund key={`PatientRefund-${params?.tabId}`} visible={modalVisible === 'PatientRefund'} toggleModal={toggleModal} />
      <div className="flex justify-content-flex-end align-center select-transection-type">
        <GroupSelect id="patients_financial_popup" groupList={defaultList()} selectOption={toggleModal} labelClassName="financial-label-wrapper" selectProps={{ dropdownClassName: 'financial-select-dropdown-wrapper' }} />
      </div>
      <div className="">
        <BalanceSheetTable labels={labels} isCustomized />
      </div>
      <div className="mr-top-8 table-scroll-wrapper">
        <ChargeTable
          params={params}
          generatePath={generatePath}
          labels={labels}
          setType={setType}
        />
      </div>
    </>
  );
};

export default Financials;
