import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { Radio } from '../../../components/Form/inputFields';
import { getEnumMasterData } from '../../../store/actions/enum';

import * as selectors from '../../../store/selectors';

const EnumRadio = ({
  enumName, parentId, dispatch, enumMaster, enumOptions, defaultOptions, ...props
}) => {
  const [enumField, setEnumField] = useState({});
  const enumId = get(enumMaster, `${enumName}.enumId`);

  useEffect(() => {
    if (!enumOptions?.[enumId]?.data?.length) {
      dispatch(getEnumMasterData(enumId, parentId));
    }
  }, [enumId, parentId]);

  useEffect(() => {
    if (parentId) {
      setEnumField((enumOptions[enumId] && enumOptions[enumId][parentId]) || {});
    } else {
      setEnumField(enumOptions[enumId] || {});
    }
  }, [enumOptions]);

  const { loading, data = [] } = useMemo(() => {
    const { loading: load, data: currentData = [] } = enumField;
    return {
      loading: load,
      data: currentData.map(
        (item) => ({
          value: item.masterId,
          name: item.masterName,
          label: item.masterName,
          radioName: item?.masterCode,
        }),
      ),
    };
  }, [enumField, enumOptions]);
  return <Radio loading={loading} options={defaultOptions || data} {...props} />;
};

export default connect((state) => ({
  enumOptions: selectors.getEnumOptions(state),
  enumMaster: selectors.getEnumMaster(state),
}))(EnumRadio);
