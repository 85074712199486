import { getUTCDate } from '../../../../../../lib/util';

const columns = [
  {
    Header: 'Date',
    accessor: 'createdOn',
    sort: true,
    Cell: ({ row: { original: { logDate: { dateString } } } }) => getUTCDate(dateString, 'MM-DD-YYYY'),
  },
  {
    Header: 'Name of Person',
    accessor: 'actionPerformedBy',
    sort: true,
  },
  {
    Header: 'Status',
    accessor: 'actionPerformed',
    sort: true,
  },
  {
    Header: 'Time',
    accessor: 'time',
    Cell: ({ row: { original: { logDate: { dateString } } } }) => getUTCDate(dateString, 'HH:mm'),
  },
];

export default columns;
