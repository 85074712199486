import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';
import isFunction from 'lodash/isFunction';

import AutoComplete from '../../../components/Form/AutoComplete/AntdAutocomplete';
import { apiUrls } from '../../../api/constants';
import Table from '../../../components/Table';
import locationColumns from './columns/locations';
import locationOptionRenderer from './locationOptionRenderer';

function LocationLookup({
  label,
  placeholder,
  disabled,
  required,
  labelSpan = '10',
  inputSpan = '13',
  setParentData,
  initialData,
  isSetToInitial,
}) {
  const [data, setData] = useState(initialData);
  const [initialTableData, setTableData] = useState(null);

  useEffect(() => {
    if (isSetToInitial) {
      setData(initialTableData || []);
      if (isFunction(setParentData)) setParentData(initialTableData || []);
    }
  }, [initialTableData, isSetToInitial, setParentData]);

  useEffect(() => {
    if (initialData?.length) {
      setData(initialData);
      if (!initialTableData) {
        setTableData(initialData);
      }
    }
  }, [initialData, initialTableData]);

  const locationOptionParser = useCallback((option) => ({
    name: option.locationName,
    value: option.locationName,
    ...option,
  }), []);

  const onLocationSelect = useCallback((value, allItems) => {
    const { item } = allItems;
    const index = findIndex(data, (location) => item.locationId === location.id
    || item.locationId === location.locationId);
    if (index < 0) {
      setData([...data, item]);
      setParentData([...data, item]);
    }
  }, [data, setParentData]);

  const deleteLocation = useCallback((id) => {
    const index = findIndex(data, (item) => item.id === id);
    const cloned = [...data];
    cloned.splice(index, 1);
    setData(cloned);
    setParentData(cloned);
  }, [data, setParentData]);

  return (
    <div>
      <AutoComplete
        label={label}
        placeholder={placeholder}
        name="LocationName"
        url={apiUrls.GET_LOCATIONS}
        optionParser={locationOptionParser}
        optionMaster="SearchText"
        labelSpan={labelSpan}
        inputSpan={inputSpan}
        disabled={disabled}
        required={required}
        optionRenderer={locationOptionRenderer}
        style={{ width: '100%' }}
        dropdownMatchSelectWidth={350}
        onSelect={onLocationSelect}
        allowClearOnSelect
      />
      {data.length > 0
        ? <div className="mr-bt-16"><Table columns={locationColumns} data={data} deleteLocation={deleteLocation} /></div>
        : null}
    </div>
  );
}

LocationLookup.defaultProps = {
  initialData: [],
  label: '',
  placeholder: '',
  disabled: false,
  required: false,
  setParentData: () => { /* This is intentional */ },
  labelSpan: '10',
  inputSpan: '13',
  isSetToInitial: false,
};

LocationLookup.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  initialData: PropTypes.arrayOf(PropTypes.object),
  setParentData: PropTypes.func,
  labelSpan: PropTypes.string,
  inputSpan: PropTypes.string,
  isSetToInitial: PropTypes.bool,
};

export default LocationLookup;
