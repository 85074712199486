import React from 'react';

import iconNames from '../../../../lib/iconNames';

import TimeCell from '../../../../components/Cells/TimeCell';

import Icon from '../../../../components/Icon';

const columns = (labels, handleEditClick, handleDelete, onRowClick) => [
  {
    Header: labels.get('tableColumns.templateName'),
    accessor: 'templateName',
    Cell: ({ row: { original } }) => <span role="presentation" onClick={onRowClick(original)}>{original?.templateName}</span>,
  },
  {
    Header: labels.get('tableColumns.category'),
    accessor: 'category',
  },
  {
    Header: labels.get('tableColumns.date'),
    accessor: 'createdOn',
    Cell: ({ row: { original } }) => <TimeCell value={original?.createdOn} />,
  },
  {
    Header: labels.get('tableColumns.author'),
    accessor: 'createdByName',
  },
  {
    Header: 'Edit',
    accessor: 'edit',
    Cell: ({ row: { original } }) => (
      <Icon
        name={iconNames.edit}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleEditClick(original);
        }}
        title="Edit"
      />
    ),
    fixWidth: '60',
  },
  {
    Header: 'Delete',
    accessor: 'delete',
    Cell: ({ row: { original } }) => (
      <Icon
        name={iconNames.deleteIcon}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleDelete(original?.composerTemplateId);
        }}
        title="Delete"
      />
    ),
    fixWidth: '60',
  },
];

export default columns;
