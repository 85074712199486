import React, { useCallback, useEffect, useMemo } from 'react';
import { Form as Antd } from 'antd';

import {
  labelPaths, listId, formId, enumMasterCode, enums,
} from '../../../../../../lib/constants';
import formFieldValuesParser from '../../../../../../lib/formFieldValuesParser';

import WithLabel from '../../../../../../hoc/withLabel';

import Button from '../../../../../../components/Button';
import Form from '../../../../../../components/Form';
import Notification from '../../../../../../components/Notification';

import TermsGeneralForm from '../Components/TermsGeneralForm';
import { apiUrls } from '../../../../../../api/constants';
import SuccessMessages from '../../../../../../lib/successMessages';
import useRedirect from '../../../../../../hooks/useRedirect';
import useCRUD from '../../../../../../hooks/useCRUD';
import useMasterId from '../../../../../../hooks/useMasterId';

const masterCodes = [
  enumMasterCode.Capitation_Visit_Arrangement_Per_Visit,
  enumMasterCode.Contract_Term_Plan_Type_Category_All,
  enumMasterCode.allPlacesOfService,
];

const enumNames = [
  enums.CAPITATION_PAYMENT_ARRANGEMENT,
  enums.CONTRACT_TERM_PLAN_TYPE_CATEGORY,
  enums.CONTRACT_TERM_PLACES_OF_SERVICE,
];

const newMasterCodes = [
  enumMasterCode.Contract_Term_Policy_Type_Category_All,
];

const newEnumNames = [
  enums.CONTRACT_TERM_POLICY_OF_CATEGORY,
];

const General = ({ labels, navigateToTermsList, navigateToEditTerms }) => {
  const [form] = Antd.useForm();
  const { params: { id: contractId, tabId } } = useRedirect();
  const masterCodesWithId = useMasterId(masterCodes, enumNames);
  const newMasterCodesWithId = useMasterId(newMasterCodes, newEnumNames);

  const [termsTableResponse, , ,
    getTableData,
    clearResponse] = useCRUD({
    id: 'contract-terms-table-data',
    url: apiUrls.GET_CONTRACT_TERM,
    type: 'read',
  });

  useEffect(() => {
    getTableData({ contractId });
    return () => { clearResponse(true); };
  }, []);

  const parser = useCallback((values, allValues) => {
    const parsedValues = formFieldValuesParser(values, {
      date: [
        'effectiveFrom',
        'effectiveTo',
      ],
      bool: [
        'capitationAdjustClaim',
        'capitationSubmitClaims',
        'isActive',
      ],
    });
    if (!values.specificPlacesOfServiceId) {
      parsedValues.specificPlacesOfServiceId = [];
    }
    if (!values.specificLocationId) {
      parsedValues.specificLocationId = [];
    }
    parsedValues.specificPlanId = allValues?.selectedPlans?.length ? allValues?.selectedPlans.map(
      (item) => item.masterId || item.value,
    ) : [];
    parsedValues.specificPolicyId = allValues?.selectedPolicies?.length && !form.getFieldValue('AppliedToAllPolicyTypes')
      ? allValues?.selectedPolicies.map((item) => item.masterId || item.value) : [];
    return {
      ...parsedValues,
    };
  }, [form]);

  const onRequestComplete = useCallback(({ response: { contractTermId } }) => {
    if (contractTermId) {
      Notification({ message: SuccessMessages.CONTRACT_TERM_SAVE_SUCCESSFULLY, success: true });
      navigateToEditTerms(contractTermId);
    }
  }, [navigateToEditTerms]);

  const formInitialData = useMemo(() => {
    if (masterCodesWithId?.All && newMasterCodesWithId?.All && masterCodesWithId?.['All Places of Service']) {
      return {
        capitationAdjustClaim: true,
        capitationSubmitClaims: true,
        isActive: true,
        placesOfServiceId: masterCodesWithId?.['All Places of Service'],
        capitationPaymentArrangementId: masterCodesWithId?.['Per Visit'],
        policyTypeId: newMasterCodesWithId?.All,
        planId: masterCodesWithId?.All,
        AppliedToAllPolicyTypes: true,
        AppliedToAllPlans: true,
        AppliedToAllPlacesOfService: true,
      };
    }
    return {};
  }, [masterCodesWithId, newMasterCodesWithId]);

  return (
    <Form
      section
      form={form}
      onRequestComplete={onRequestComplete}
      parser={parser}
      listId={listId.CONTRACT_TERMS}
      url={apiUrls.ADD_CONTRACT_TERM_GENERAL}
      name={formId.ADD_TERM_GENERAL}
      tabId={tabId}
      formId={formId.ADD_TERM_GENERAL}
      extraData={{ contractId }}
      initialData={formInitialData}
    >
      <TermsGeneralForm
        form={form}
        termsTableResponse={termsTableResponse}
      />
      <div className="form-btns contract-btns">
        <Button className="btn btn-success" type="primary" htmlType="submit">{labels.get('buttons.save')}</Button>
        <Button className="btn btn-ghost" onClick={navigateToTermsList}>{labels.get('buttons.cancel')}</Button>
      </div>
    </Form>
  );
};

export default WithLabel(General, labelPaths.EDIT_CONTRACT_TERMS_GENERAL);
