import React from 'react';
import { formatPhoneNumber } from '../../../lib/util';

const payerOptionRenderer = (options = []) => {
  if (!options.length) return [];
  return [{
    label: (
      <div className="option-header-main-wrapper">
        <div className="option headerWrapper">
          <span className="header">
            Name
          </span>
          <span className="header">
            Address
          </span>
          <span className="header">
            Phone
          </span>

        </div>
      </div>
    ),
    options: options && options.map((item) => (
      {
        value: item.name,
        item,
        label: (
          <div className="option" key={item.insurancePayerId} data-testid={item.name}>
            <span>
              {item.name}
            </span>
            <span>
              {item.address}
            </span>
            <span>
              {formatPhoneNumber(item.phone)}
            </span>
          </div>
        ),
      }
    )),
  }];
};
export default payerOptionRenderer;
