import React, { useCallback } from 'react';
import AutoComplete from '../../components/Form/GridAutoComplete';
import { apiUrls } from '../../api/constants';
import commonDefaultPropTypes from '../commonDefaultPropType';

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    flex: 1,
    maxWidth: 'auto',
  },
];

const LocationGridAutoComplete = ({
  disabled,
  required,
  initialValue,
  labelSpan,
  inputSpan,
  onSelect,
  styleWidth,
  name,
  minCharLength,
  className,
  dropdownMatchSelectWidth,
  selectProps,
  params,
  ...props
}) => {
  const locationOptionParser = useCallback((option) => ({
    ...option,
    name: option.locationName,
    value: option.locationId,
  }), []);

  return (
    <div>
      <AutoComplete
        name={name}
        url={apiUrls.GET_LOCATIONS}
        optionParser={locationOptionParser}
        optionMaster="SearchText"
        labelSpan={labelSpan}
        inputSpan={inputSpan}
        disabled={disabled}
        required={required}
        minCharLength={minCharLength || 1}
        initialValue={initialValue}
        columns={columns}
        style={styleWidth ? { width: '100%' } : {}}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        className={className}
        notFoundContent="Location is not present is the system."
        showArrow
        labelInValue
        selectProps={{ ...selectProps, optionLabelProp: 'name', allowClear: true }}
        params={params}
        onSelect={onSelect}
        {...props}
      />
    </div>
  );
};

LocationGridAutoComplete.defaultProps = {
  disabled: false,
  required: false,
  initialValue: {},
  labelSpan: '0',
  inputSpan: '24',
  onSelect: () => { /* This is intentional */ },
  styleWidth: true,
  name: 'location',
  className: '',
  dropdownMatchSelectWidth: false,
  params: {},
  placeholder: '',
  label: 'location',
};

LocationGridAutoComplete.propTypes = {
  ...commonDefaultPropTypes,
};

export default LocationGridAutoComplete;
