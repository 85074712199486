import React, {
  useState, useCallback, useMemo, useRef,
} from 'react';
import { Form as AntdForm } from 'antd';

import WithLabel from '../../../../../../hoc/withLabel';
import { labelPaths } from '../../../../../../lib/constants';
import useRedirect from '../../../../../../hooks/useRedirect';

import Modals from '../../../../../../components/Modal';
import Button from '../../../../../../components/Button';

import InsurancePaymentTable from './InsurancePaymentTable';
import InsurancePaymentDetails from './InsurancePaymentDetails';

import './InsurancePayment.scss';

function InsurancePayment(props) {
  const {
    visible, toggleModal, labels, isEditInsurancePayment, claimDetails, transactionTypeId,
  } = props;
  const childRef = useRef();
  const [form] = AntdForm.useForm();
  const viewPanel = useRef();
  const { params: { financialId } } = useRedirect();

  const [isAddInsurancePayment, setAddInsurancePayment] = useState(false);

  const handleModal = useCallback(() => {
    toggleModal(!visible);
    setAddInsurancePayment(false);
  }, [toggleModal, visible]);

  const handleAddEncounter = useCallback((data) => setAddInsurancePayment(data), []);

  const submitForm = useCallback(() => childRef.current.submitPaymentForm(), []);

  const modalFooter = useMemo(() => ((isAddInsurancePayment || financialId) ? [
    <div className="group-btns" key="footer">
      <Button
        className="btn min-wt-86"
        id="patients_financial_insurancePayment_cancel"
        onClick={handleModal}
        data-testid="cancel-btn"
      >
        {labels.get('buttons.cancel')}
      </Button>
      <Button
        className="btn btn-success min-wt-86 inline"
        id="patients_financial_insurancePayment_save"
        onClick={submitForm}
        data-testid="save-btn"
      >
        {labels.get('buttons.save')}
      </Button>
    </div>,
  ] : [
    <div className="group-btns" key="footer">
      <Button
        className="btn min-wt-86"
        id="patients_financial_insurancePayment_cancel"
        onClick={handleModal}
      >
        {labels.get('buttons.cancel')}
      </Button>
    </div>,
  ]), [isAddInsurancePayment]);

  return (
    <>
      <Modals
        visible={visible}
        toggleModal={handleModal}
        width={1400}
        footer={modalFooter}
        destroyOnClose
      >
        <div className="addition-header">
          <div className="lookup sprite-img-before">
            <p className="">{labels.get('titles.insurancePayment')}</p>
          </div>
        </div>
        {(isAddInsurancePayment || financialId)
          ? (
            <InsurancePaymentDetails
              labels={labels}
              data={isAddInsurancePayment}
              handleAddEncounter={handleAddEncounter}
              form={form}
              closeModal={handleModal}
              setAddInsurancePayment={setAddInsurancePayment}
              ref={childRef}
              financialId={financialId}
              isEditInsurancePayment={isEditInsurancePayment}
              claimDetails={claimDetails}
              transactionTypeId={transactionTypeId}
            />
          )
          : (
            <InsurancePaymentTable
              labels={labels}
              handleAddEncounter={handleAddEncounter}
              financialId={financialId}
            />
          )}
      </Modals>
      <div ref={viewPanel} className="display-none" />
    </>
  );
}

export default
React.memo(WithLabel(InsurancePayment, labelPaths.FINANCIAL_POPUP_INSURANCE_PAYMENT));
