import React, { useMemo, useCallback, useEffect } from 'react';

import { apiUrls } from '../../../../../../api/constants';
import useRedirect from '../../../../../../hooks/useRedirect';
import withQuery from '../../../../../../hoc/withQuery/withQuery';
import { listId } from '../../../../../../lib/constants';

import columns from './column';

const PatientPaymentTable = ({
  labels, searchText, setCurrentPath, onClick,
}) => {
  const {
    replace, path, generatePath, params,
  } = useRedirect();

  const Table = useMemo(() => withQuery({
    url: apiUrls.GET_PATIENT,
    listId: listId.PATIENTS,
  })(), []);

  const onRowClick = useCallback(({ original: { patientId } }) => {
    if (onClick) {
      onClick(patientId);
    } else {
      replace(
        generatePath(
          `${path}/:PatientId`,
          { ...params, PatientId: patientId },
        ),
      );
    }
  }, [onClick, replace, generatePath, path, params]);

  useEffect(() => {
    if (setCurrentPath) {
      setCurrentPath(path);
    }
  }, []);

  return (
    <div className="app-table">
      <Table
        columns={columns(labels)}
        filters={{
          SearchText: searchText,
          ExcludeInactivePatients: true,
        }}
        footer
        onRowClick={onRowClick}
        customData={searchText ? null : []}
      >
        {({ Component }) => Component}
      </Table>
    </div>
  );
};

export default PatientPaymentTable;
