import React, { useEffect, useCallback } from 'react';

import { apiUrls } from '../../../../../../api/constants';

import { enums } from '../../../../../../lib/constants';

import SelectBox from '../../../../../../components/SelectBox';

import WiredSelect from '../../../../../../wiredComponents/Select';
import WiredSelectWithoutTab from '../../../../../../wiredComponents/Select/selectBoxV2';
import Select from '../../../../../../wiredComponents/Enum/Select';

const CollectionOtherAdditionalFilters = ({
  labels, selectedAdditionFilter, filters, onFiltersChange,
  setAdditionalFilter, stage, queueId, stateId,
}) => {
  useEffect(() => {
    if (filters && filters?.additionalFilter && !selectedAdditionFilter) {
      setAdditionalFilter(filters?.additionalFilter);
    }
  }, [filters]);

  useEffect(() => {
    if (queueId && stateId && stage?.noResponse) {
      onFiltersChange({
        BillingStages: `${stage?.noResponse},${stage?.denial},${stage?.manual}`,
        ...filters,
        QueueId: queueId,
        StateId: stateId,
      }, true);
    }
  }, [queueId, stateId, stage?.noResponse]);

  const locationFilterHandler = useCallback((item, currentFilters) => {
    const clonedFilters = currentFilters;
    clonedFilters.filterId = item?.locationId;
    onFiltersChange(clonedFilters, true);
  }, [onFiltersChange]);

  const payerFilterHandler = useCallback((item, currentFilters) => {
    const clonedFilters = currentFilters;
    clonedFilters.filterId = item?.insurancePayerId;
    onFiltersChange(clonedFilters, true);
  }, [onFiltersChange]);

  const agingFilterHandler = useCallback((item, currentFilters) => {
    const clonedFilters = currentFilters;
    clonedFilters.agingMasterCode = item?.masterCode;
    onFiltersChange(clonedFilters, true);
  }, [onFiltersChange]);

  const incidentChangeFilterHandler = useCallback((item, currentFilters) => {
    const clonedFilters = currentFilters;
    clonedFilters.agingMasterCode = item?.masterCode;
    onFiltersChange(clonedFilters, true);
  }, [onFiltersChange]);

  const responsibilityFilterHandler = useCallback((item, currentFilters) => {
    const clonedFilters = currentFilters;
    clonedFilters.filterId = item?.masterId;
    onFiltersChange(clonedFilters, true);
  }, [onFiltersChange]);

  const denialTypeFilterHandler = useCallback((item, currentFilters) => {
    const clonedFilters = currentFilters;
    clonedFilters.denialCategoryCode = item?.name;
    if (item?.masterCode === 'NoResponses' && stage?.noResponse) {
      clonedFilters.BillingStages = `${stage.noResponse}`;
    } else if (item?.masterCode === 'Manual' && stage?.manual) {
      clonedFilters.BillingStages = `${stage.manual}`;
    } else if (stage?.noResponse) {
      clonedFilters.BillingStages = `${stage?.noResponse},${stage?.denial},${stage?.manual}`;
    }
    onFiltersChange(clonedFilters, true);
  }, [onFiltersChange, stage]);

  const denialReasonFilterHandler = useCallback((item, currentFilters) => {
    const clonedFilters = currentFilters;
    clonedFilters.filterId = item?.id;
    onFiltersChange(clonedFilters, true);
  }, [onFiltersChange]);

  const providerFilterHandler = useCallback((item, currentFilters) => {
    const clonedFilters = currentFilters;
    clonedFilters.filterId = item?.providerId;
    onFiltersChange(clonedFilters, true);
  }, [onFiltersChange]);

  return (
    <div className="filter-components">
      {selectedAdditionFilter === 'location' && (
        <div className="input-wrap filter-select-box">
          <WiredSelectWithoutTab
            id="collection-locations"
            name={null}
            url={apiUrls.GET_LOCATIONS}
            placeholder={labels.get('placeholders.location')}
            nameAccessor="locationName"
            valueAccessor="locationId"
            Component={SelectBox}
            selectProps={{
              size: 'middle',
              style: { width: 120 },
              showSearch: true,
            }}
            onChange={(value, data) => (
              locationFilterHandler(data?.item, filters)
            )}
            value={filters?.filterId}
          />
        </div>
      )}
      { selectedAdditionFilter === 'payer' && (
        <div className="input-wrap filter-select-box">
          <WiredSelectWithoutTab
            id="collection-payer"
            name={null}
            url={apiUrls.GET_PAYERS}
            nameAccessor="name"
            valueAccessor="insurancePayerId"
            isStatCaseOptions={false}
            Component={SelectBox}
            labelSpan="0"
            selectProps={{
              style: { width: 120 },
              showSearch: true,
            }}
            params={{
              pageIndex: 0,
              pageSize: 1000,
            }}
            onChange={(value, data) => (
              payerFilterHandler(data?.item, filters)
            )}
            placeholder="Select Payer"
            value={filters?.filterId}
          />
        </div>
      )}
      {selectedAdditionFilter === 'aging' && (
        <div className="input-wrap filter-select-box">
          <Select
            enumName={enums.AGING_FILTER}
            name={null}
            selectProps={{
              style: { width: 120 },
            }}
            labelSpan="0"
            inputSpan="24"
            onChange={(value, data) => {
              agingFilterHandler(data?.item, filters);
            }}
            placeholder="Select Aging"
            dataTestId="agingFilter"
            isOptionSortable={false}
            valueAccessor="masterCode"
            value={filters?.agingMasterCode}
          />
        </div>
      )}
      {selectedAdditionFilter === 'incidentDate' && (
        <div className="input-wrap filter-select-box">
          <Select
            enumName={enums.COLLECTION_GROUP_INCIDENT_DATE}
            name={null}
            selectProps={{
              style: { width: 120 },
            }}
            labelSpan="0"
            inputSpan="24"
            onChange={(value, data) => {
              incidentChangeFilterHandler(data?.item, filters);
            }}
            placeholder="Select Incident Age"
            dataTestId="incidentDate"
            isOptionSortable={false}
            valueAccessor="masterCode"
            value={filters?.agingMasterCode}
          />
        </div>
      )}
      {selectedAdditionFilter === 'provider' && (
        <div className="input-wrap filter-select-box">
          <WiredSelectWithoutTab
            id="provider"
            name={null}
            url={apiUrls.SCHEDULAR_PROVIDER_DROPDOWN}
            placeholder="Search Provider"
            nameAccessor="providerName"
            valueAccessor="providerId"
            Component={SelectBox}
            selectProps={{
              style: { width: 150 },
              showSearch: true,
              dropdownMatchSelectWidth: 250,
            }}
            onChange={(value, data) => {
              providerFilterHandler(data?.item, filters);
            }}
            labelSpan="0"
            defaultSorting={false}
            value={filters?.filterId}
          />
        </div>
      )}
      {selectedAdditionFilter === 'responsibility' && (
        <div className="input-wrap filter-select-box">
          <Select
            name={null}
            enumName={enums.INSURANCE_POLICY_LEVEL}
            startCaseOptions
            labelSpan="0"
            selectProps={{
              style: { minWidth: 130 },
              showArrow: true,
              dropdownMatchSelectWidth: false,
            }}
            onChange={(value, data) => {
              responsibilityFilterHandler(data?.item, filters);
            }}
            className="default-value-as-placeholder"
            placeholder="Select Payer Responsibility"
            initialValue={filters?.filterId}
          />
        </div>
      )}
      {selectedAdditionFilter === 'denialType' && (
        <div className="input-wrap filter-select-box">
          <Select
            name={null}
            enumName={enums.DENIAL_TYPE}
            startCaseOptions
            labelSpan="0"
            selectProps={{
              style: { width: 150 },
              showSearch: true,
              dropdownMatchSelectWidth: 250,
            }}
            onChange={(value, data) => {
              denialTypeFilterHandler(data?.item, filters);
            }}
            className="default-value-as-placeholder"
            placeholder="Select Denial Type"
            value={filters?.denialCategoryCode}
          />
        </div>
      )}
      {selectedAdditionFilter === 'denialName' && (
        <div className="input-wrap filter-select-box">
          <WiredSelect
            id="deniedReasons"
            name={null}
            url={apiUrls.GET_DENIAL_REASONS}
            valueAccessor="id"
            nameAccessor="description"
            isStatCaseOptions={false}
            labelSpan="0"
            valueInString
            onChange={(value, data) => {
              denialReasonFilterHandler(data?.item, filters);
            }}
            params={{ pageSize: 1000 }}
            selectProps={{
              style: { width: 150 },
              showSearch: true,
              dropdownMatchSelectWidth: 250,
            }}
            className="default-value-as-placeholder"
            placeholder="Select Denial Name"
            value={filters?.id}
          />
        </div>
      )}
      {selectedAdditionFilter === 'payerType' && (
        <div className="input-wrap filter-select-box">
          <Select
            name={null}
            enumName={enums.INSURANCE_PAYER_TYPE}
            startCaseOptions
            labelSpan="0"
            selectProps={{
              style: { minWidth: 130 },
              showArrow: true,
              dropdownMatchSelectWidth: false,
            }}
            onChange={(value, data) => {
              responsibilityFilterHandler(data?.item, filters);
            }}
            className="default-value-as-placeholder"
            placeholder="Select Payer Type"
            value={filters?.filterId}
          />
        </div>
      )}
    </div>
  );
};

export default CollectionOtherAdditionalFilters;
