import { Tooltip } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

const RcmWrapper = ({
  children,
  finalRcmHighlightData,
  isVisible,
  isDropDown,
}) => {
  const containerStyle = {
    border: '2px solid #cc3d00',
    borderRadius: '5px',
    padding: '2px',
    width: isDropDown ? '258px' : undefined,
    height: isDropDown ? '35px' : undefined,
    marginBottom: isDropDown ? '5px' : undefined,
  };
  return isVisible ? (
    <Tooltip
      defaultOpen
      placement="rightTop"
      style={{ color: 'white' }}
      color="#A03000"
      overlay={(
        <>
          <span style={{ fontWeight: 'bold' }}>{`${finalRcmHighlightData.ruleName}: `}</span>
          <span style={{ fontWeight: 'normal' }}>
            {finalRcmHighlightData?.errorDisplayMessage}
          </span>
        </>
)}
    >
      <div style={containerStyle}>
        {children}
      </div>
    </Tooltip>
  ) : (
    <div>{children}</div>
  );
};

RcmWrapper.propTypes = {
  children: PropTypes.node,
  finalRcmHighlightData: PropTypes.shape({
    errorDisplayMessage: PropTypes.string,
  }),
  isVisible: PropTypes.bool,
  isDropDown: PropTypes.bool,
};

RcmWrapper.defaultProps = {
  children: null,
  finalRcmHighlightData: null,
  isVisible: false,
  isDropDown: false,
};

export default RcmWrapper;
