import React, { useCallback } from 'react';
import moment from 'moment';

import Form from '../../../../components/Form';
import Input from '../../../../components/Form/Input';
import DatePicker from '../../../../components/Form/DatePicker';
import Upload from '../../../../components/Upload';
import Button from '../../../../components/Button';

import { apiUrls } from '../../../../api/constants';
import { formId, listIds, contentType } from '../../../../lib/constants';
import formFieldValuesParser from '../../../../lib/formFieldValuesParser';
import UserAutoComplete from '../../../../wiredComponents/UserAutoComplete';

const parser = (values) => {
  const parsedValues = formFieldValuesParser(values, {
    date: ['depositDate'],
    isDateToString: true,
    files: ['file'],
  });
  return {
    ...parsedValues,
    depositDate: moment(parsedValues?.depositDate).format('YYYY-MM-DD HH:mm:ss'),
  };
};

const NewBatchForm = ({
  labels, form, onComplete, isEdit, formData, isUpload, isNewbatch,
}) => {
  const getExtraData = useCallback(() => {
    if (isEdit) {
      if (isUpload) {
        if (formData.documentUrl) {
          return {
            documentUrl: formData?.documentUrl,
            remittanceBatchId: formData?.remittanceBatchId,
            batchIdentifier: formData?.batchIdentifier,
            depositDate: moment(formData?.depositDate?.dateString).format('YYYY-MM-DD HH:mm:ss'),
            assignToUserId: formData?.assignToUserId,
            totalBatchAmount: formData?.totalBatchAmount,
            checkCount: formData?.checkCount,
            method: 'put',
          };
        }
        return {
          remittanceBatchId: formData?.remittanceBatchId,
          batchIdentifier: formData?.batchIdentifier,
          depositDate: moment(formData?.depositDate?.dateString).format('YYYY-MM-DD HH:mm:ss'),
          assignToUserId: formData?.assignToUserId,
          totalBatchAmount: formData?.totalBatchAmount,
          checkCount: formData?.checkCount,
          method: 'put',
        };
      }
      return {
        remittanceBatchId: formData?.remittanceBatchId,
        method: 'put',
      };
    }
    return null;
  }, [formData, isEdit, isUpload]);
  return (
    <div>
      <Form
        form={form}
        formId={isEdit ? `${formId.NEW_REMITTANCE_BATCH}-edit` : formId.NEW_REMITTANCE_BATCH}
        parser={parser}
        url={isEdit ? apiUrls.UPDATE_REMITTANCE_BATCH_DETAILS : apiUrls.CREATE_NEW_REMITTANCE_BATCH}
        onRequestComplete={onComplete}
        scrollToFirstError
        listId={listIds.NEW_REMITTANCE_BATCH}
        contentType={contentType.MULTIPART}
        extraData={getExtraData}
      >
        {!isUpload && (
        <div>
          <Input
            label={labels.get('labels.batchIdentifier')}
            name="batchIdentifier"
            inputSpan="17"
            labelSpan="7"
            dataTestId="batch-identifier"
            maxValueLength={25}
            required
            placeholder="Enter Batch Identifier"
          />
          <DatePicker
            label={labels.get('labels.depositDate')}
            name="depositDate"
            inputSpan="17"
            labelSpan="7"
            required
            dataTestId="deposit-date"
          />
          <div className="with-clear-wrapper">
            <UserAutoComplete
              id="assignToUserId"
              name="assignToUserId"
              label={labels.get('labels.assignTo')}
              placeholder={labels.get('labels.unassigned')}
              form={form}
              inputSpan="17"
              labelSpan="7"
              header={false}
              initialValue={isEdit && formData?.assignToUserId && formData?.assignToUserName
                ? { name: formData?.assignToUserName, value: formData?.assignToUserId } : null}
              required
              dataTestId="assign-to-user-id"
            />
          </div>
          <Input
            label={labels.get('labels.amount')}
            name="totalBatchAmount"
            inputSpan="17"
            labelSpan="7"
            numberWithDecimal
            maxValueLength={11}
            dataTestId="amount"
            placeholder="Enter Amount"
            required
          />
          <Input
            label={labels.get('labels.checkCount')}
            numberOnly
            name="checkCount"
            inputSpan="17"
            labelSpan="7"
            dataTestId="check-count"
            maxValueLength={3}
            placeholder="Enter Check Count"
          />
        </div>
        )}
        {(isNewbatch || isUpload) && (
        <Upload
          accept=".pdf"
          label={labels.get('labels.attachment')}
          name="file"
          inputSpan="17"
          labelSpan="7"
          required={!isNewbatch}
          form={form}
        >
          <Button className="btn btn-success min-wt-86 sm-btn">{labels.get('buttons.attach')}</Button>
        </Upload>
        )}
      </Form>
    </div>
  );
};

export default NewBatchForm;
