import React, {
  useCallback, useEffect, useState, useRef, useMemo,
} from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import isFunction from 'lodash/isFunction';
import toLower from 'lodash/toLower';
import get from 'lodash/get';
import sumBy from 'lodash/sumBy';

import { getEnumMaster, getEnumOptions } from '../../store/selectors';
import { getEnumMasterData } from '../../store/actions/enum';

import TreeSelect from '../../components/TreeSelect';
import ConfirmModal from '../../components/ConfirmDialog';
import Icon from '../../components/Icon';

import { apiUrls } from '../../api/constants';
import { listId, enums, dateFormatWithSlash } from '../../lib/constants';
import rights from '../../lib/rights';

import Tooltip from '../../components/Tooltip';
import useCRUDWithoutTab from '../../hooks/useCRUDWithoutTab';
import useRights from '../../hooks/useRights';
import InsuranceVerificationShieldIcon from '../../screens/Dashboard/Tabs/Insurance/InsuranceVerificationShieldIcon';
import useReduxStoreWithId from '../../hooks/useReduxStoreWithId';

const isSelfInsuranceIncludeCheck = (includeSelfProfile) => {
  if (!includeSelfProfile) {
    return (profileType) => toLower(profileType) !== 'self';
  }
  return () => true;
};

const getAbb = (name) => {
  if (name) {
    return name[0].toUpperCase();
  }
  return 'N/A';
};

const calculateIsActive = (fromDate, toDate) => {
  if (toDate && moment(toDate).isBefore(moment())) return 'Status: Inactive';
  if (!toDate && !fromDate) return 'Status: Inactive';
  if (moment(moment()).isBefore(fromDate)) return 'Status: Inactive';
  return 'Status: Active';
};
const getToolTipContent = (data) => {
  const payerName = data?.item?.payerName;
  if (payerName) {
    return (
      <p>
        <p>
          {`Payer: ${payerName} `}
        </p>
        <p>
          {calculateIsActive(data?.item?.effectiveFrom, data?.item?.effectiveTo)}
        </p>
      </p>
    );
  }
  return '';
};

const getInsuranceNameWithStatus = (data, includeInActive) => {
  const getStatus = calculateIsActive(data?.effectiveFrom, data?.effectiveTo);
  const isFutureInsurance = moment(moment()).isBefore(data?.effectiveFrom);
  if (getStatus.includes('Status: Inactive') && includeInActive) {
    return (
      <div className="flex justify-content-sp-bt">
        <div>
          {`(${getAbb(data.profileType)}) ${data.policyType} - ${data.payerName} (Inactive as of ${moment(isFutureInsurance ? data?.effectiveFrom : data?.effectiveTo).format(dateFormatWithSlash)})`}
        </div>
      </div>
    );
  }
  return (
    <div className="add-shield-icon">
      <span>{`(${getAbb(data.profileType)}) ${data.policyType} - ${data.payerName} (Active since ${moment(data.effectiveFrom).format(dateFormatWithSlash)})`}</span>
      <InsuranceVerificationShieldIcon
        verificationStatus={data?.verificationStatus}
        lastVerificationDate={data?.lastVerificationDate}
      />
    </div>
  );
};

const getInsuranceProfileName = (item, includeInActive) => {
  if (!item?.isActive && includeInActive) {
    return (
      <div className="flex justify-content-sp-bt">
        <div>
          {item?.profileName}
        </div>
        <div>
          (Inactive)
        </div>
      </div>
    );
  }
  if (item?.profileName === 'Vision' && item?.profileDetails
  ) {
    const visionUsageCount = sumBy(item.profileDetails, (value) => value?.visionUsageCount);
    return typeof visionUsageCount === 'number' ? `${item.profileName} Used (${visionUsageCount})-${moment().format('YYYY')}` : item.profileName;
  }
  return item.profileName;
};

const addSelfInInsuranceData = ({
  insuranceData, item, includeInActive, includePayerName,
}) => {
  insuranceData.push({
    id: item.insuranceProfileId,
    pId: 0,
    value: item.insuranceProfileId,
    title: getInsuranceProfileName(item, includeInActive),
    isLeaf: includePayerName ? false : toLower(item.profileType) === 'self',
    item,
    selectable: includePayerName ? true : toLower(item.profileType) === 'self',
  });
};

const addDetailInsuranceInInsuranceData = ({
  insuranceData, detailItem, includeInActive, disableOtherThanPrimaryAndSelf,
}) => {
  insuranceData.push({
    id: detailItem.insuranceProfileDetailId,
    pId: detailItem.insuranceProfileId,
    value: detailItem.insuranceProfileDetailId,
    title: getInsuranceNameWithStatus(detailItem, includeInActive),
    isLeaf: true,
    item: detailItem,
    disabled: !!(disableOtherThanPrimaryAndSelf && detailItem?.profileType?.toLowerCase()?.trim() !== 'primary'),
  });
};

const InsuranceDetailsSelect = ({
  patientId,
  name,
  onSelect,
  initialValue,
  labelSpan,
  inputSpan,
  selectProps,
  disabled,
  required,
  includeSelfProfile,
  includeInActive,
  includePayerName,
  disableOtherThanPrimaryAndSelf,
  dataTestId,
  showDefaultInsurance,
  extraClass,
  form,
  doctor,
  providerId,
  insuranceSelectData,
  updateDueToInsuranceChange,
  isUpdateInsuranceData,
  setIsUpdateInsuranceData,
  tabId = '-',
  dateOfService,
  dosRequired,
  ...otherProps
}) => {
  const [
    isOverrideMedicalProfile,
  ] = useRights([
    rights.override_default_medical_insurance_profile,
  ]);
  const dispatch = useDispatch();

  const enumMaster = useSelector((state) => getEnumMaster(state));
  const enumId = get(enumMaster, `${enums.PROVIDER_SPECIALTY}.enumId`);
  const providerSpecialtyEnumOptions = useSelector(
    (state) => getEnumOptions(state)?.[enumId]?.data || [],
  );
  const resolveTreePromise = useRef({
    isTreeExist: {},
  });
  const isCloseInsuranceTreeSelectRef = useRef(true);
  const [insuranceList, setInsuranceList] = useState([]);
  const [selectedInsuranceData,, setSelectedInsurance] = useReduxStoreWithId({ id: `SELECTED_INSURANCE_DATA-${tabId}-${patientId}` });
  const selectedInsurance = selectedInsuranceData?.get('data');
  const [openInsuranceTree, setOpenInsuranceTree] = useState(false);

  const [data,, loading, getOptions, clearInsuranceData] = useCRUDWithoutTab({
    id: `${listId.INSURANCE_PROFILE_DETAILS_SELECT}-${patientId}-${otherProps?.id}`,
    url: apiUrls.GET_PATIENT_INSURANCE,
    type: 'read',
  });
  const [providerData] = useCRUDWithoutTab({
    id: 'doctor',
    url: apiUrls.SCHEDULAR_PROVIDER_DROPDOWN,
    type: 'read',
  });

  const getProviderProfile = useCallback((value) => {
    const getProviderSpecialty = providerSpecialtyEnumOptions?.find(({ masterId }) => masterId
      === value.specialityId);
    if (getProviderSpecialty?.masterName === 'Optometry') {
      return 'Vision';
    }
    if (getProviderSpecialty?.masterName === 'Ophthalmology') {
      return 'Medical';
    }
    return 'Other';
  }, [providerSpecialtyEnumOptions]);

  useEffect(() => {
    if (enumId && !providerSpecialtyEnumOptions?.length) {
      dispatch(getEnumMasterData(enumId));
    }
  }, [enumId, providerSpecialtyEnumOptions?.length]);

  useEffect(() => () => {
    clearInsuranceData(true);
  }, []);

  const isIncludeSelfProfile = useMemo(() => (
    isSelfInsuranceIncludeCheck(includeSelfProfile)
  ), [includeSelfProfile]);

  const selectedParent = useMemo(() => {
    if (selectedInsurance?.pId) return [selectedInsurance?.pId];
    return [];
  }, [selectedInsurance]);

  useEffect(() => {
    if (showDefaultInsurance && data?.length && doctor) {
      const providerProfile = getProviderProfile(doctor);
      let defaultInsuranceProfile;
      let insuranceProfile = [];
      if (providerProfile === 'Other') {
        defaultInsuranceProfile = data?.find(({ isDefault, profileType }) => isDefault && profileType?.toLowerCase() !== 'self');
      } else {
        insuranceProfile = data?.filter(({ profileName }) => profileName
        === providerProfile);
      }

      if (insuranceProfile?.length || defaultInsuranceProfile) {
        let defaultInsurance;
        if (!defaultInsuranceProfile) {
          defaultInsuranceProfile = insuranceProfile?.find(({ isDefault }) => isDefault);
        }
        if (defaultInsuranceProfile) {
          defaultInsurance = defaultInsuranceProfile?.profileDetails?.find(({ profileType }) => profileType?.toLowerCase() === 'primary');
        } else {
          defaultInsuranceProfile = insuranceProfile.find((insuranceProfileItem) => {
            defaultInsurance = insuranceProfileItem?.profileDetails?.find(({ profileType }) => profileType?.toLowerCase() === 'primary');
            return !!(defaultInsurance);
          });
        }
        if (defaultInsurance) {
          setSelectedInsurance({
            id: defaultInsurance.insuranceProfileDetailId,
            pId: defaultInsuranceProfile.insuranceProfileId,
            value: defaultInsurance.insuranceProfileDetailId,
            title: getInsuranceNameWithStatus(defaultInsurance, includeInActive),
            isLeaf: true,
            item: defaultInsurance,
          });
          onSelect(defaultInsurance.insuranceProfileDetailId, {
            id: defaultInsurance.insuranceProfileDetailId,
            pId: defaultInsuranceProfile.insuranceProfileId,
            value: defaultInsurance.insuranceProfileDetailId,
            title: getInsuranceNameWithStatus(defaultInsurance, includeInActive),
            isLeaf: true,
            item: defaultInsurance,
          });
          form.setFieldsValue({ [name]: defaultInsurance.insuranceProfileDetailId });
        }
      } else {
        const selfProfile = data?.find(({ profileType, isActive }) => profileType?.toLowerCase() === 'self' && isActive);
        if (selfProfile) {
          setSelectedInsurance({
            id: selfProfile.insuranceProfileId,
            pId: 0,
            value: selfProfile.insuranceProfileId,
            title: getInsuranceProfileName(selfProfile, includeInActive),
            isLeaf: includePayerName ? false : toLower(selfProfile.profileType) === 'self',
            item: selfProfile,
          });
          onSelect(selfProfile.insuranceProfileId, {
            id: selfProfile.insuranceProfileId,
            pId: 0,
            value: selfProfile.insuranceProfileId,
            title: getInsuranceProfileName(selfProfile, includeInActive),
            isLeaf: includePayerName ? false : toLower(selfProfile.profileType) === 'self',
            item: selfProfile,
          });
          form.setFieldsValue({ [name]: selfProfile.insuranceProfileId });
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (dosRequired) {
      if (patientId && !loading && dateOfService) {
        setInsuranceList([]);
        getOptions({ PatientId: patientId, IncludeInActive: includeInActive, dateOfService });
      }
    } else if (patientId && !loading) {
      setInsuranceList([]);
      getOptions({ PatientId: patientId, IncludeInActive: includeInActive });
    }
  }, [patientId, dateOfService, includeInActive, dosRequired]);

  useEffect(() => {
    if ((!selectedInsurance?.item || isUpdateInsuranceData) && (initialValue?.insuranceProfileId
      || initialValue?.insuranceProfileDetailId)) {
      const insuranceId = initialValue?.insuranceProfileDetailId
      || initialValue?.insuranceProfileId || 0;
      const insurance = insuranceList.find((item) => item.id === insuranceId);
      if (insurance) {
        insurance.insuranceProfileDetailId = initialValue.insuranceProfileDetailId;
        setSelectedInsurance(insurance);
      }
      if (isFunction(setIsUpdateInsuranceData)) {
        setIsUpdateInsuranceData(false);
      }
    }
  }, [initialValue, insuranceList]);

  useEffect(() => {
    if (data?.length) {
      const insuranceData = [];
      resolveTreePromise.current.isTreeExist = {};
      data.forEach((item) => {
        if (isIncludeSelfProfile(item.profileType)) {
          resolveTreePromise.current.isTreeExist[item.insuranceProfileId] = false;
          addSelfInInsuranceData({
            insuranceData, item, includeInActive, includePayerName,
          });
          if (item.profileDetails) {
            item.profileDetails.forEach((detailItem) => {
              // eslint-disable-next-line no-param-reassign
              detailItem.insuranceProfileId = item.insuranceProfileId;
              addDetailInsuranceInInsuranceData({
                insuranceData,
                detailItem,
                includeInActive,
                disableOtherThanPrimaryAndSelf,
              });
            });
          }
        }
      });
      setInsuranceList(insuranceData);
    }
  }, [data]);

  const onSelectInsurance = useCallback((item, all) => {
    const getInsuranceProfile = data?.find(({ insuranceProfileId }) => insuranceProfileId
    === all?.pId);

    const getPreviousSelectedProfile = data?.find(({ insuranceProfileId }) => insuranceProfileId
    === selectedInsurance?.pId);
    const isProviderPresent = doctor || providerData?.find(
      ({ providerId: ProviderID }) => ProviderID === providerId,
    );
    if (isProviderPresent && getProviderProfile(isProviderPresent) === 'Medical'
    && getInsuranceProfile?.profileName === 'Vision' && getPreviousSelectedProfile?.profileName === 'Medical') {
      isCloseInsuranceTreeSelectRef.current = false;
      ConfirmModal({
        onOk: (close) => {
          if (isOverrideMedicalProfile) {
            setSelectedInsurance(all);
            onSelect(item, all);
          }
          close();
          setOpenInsuranceTree(false);
          isCloseInsuranceTreeSelectRef.current = true;
        },
        onCancel: (close) => {
          close();
          isCloseInsuranceTreeSelectRef.current = true;
        },
        okText: 'Yes',
        cancelText: 'No',
        title: 'You are selecting a vision insurance profile for a Medical Doctor (MD), do you want to proceed?',
        icon: <Icon name="ExclamationCircleOutlined" />,
        zIndex: 2010,
      })();
    } else {
      if (!insuranceSelectData) {
        setSelectedInsurance(all);
      }
      onSelect(item, all);
    }
  }, [data, doctor, getProviderProfile, insuranceSelectData,
    isOverrideMedicalProfile, onSelect, providerData, providerId,
    selectedInsurance, setSelectedInsurance]);

  useEffect(() => {
    if (insuranceSelectData?.item && !updateDueToInsuranceChange) {
      setSelectedInsurance(insuranceSelectData?.item);
    }
  }, [insuranceSelectData, updateDueToInsuranceChange]);

  const onDropdownVisibleChange = useCallback((props) => {
    if (isCloseInsuranceTreeSelectRef.current) {
      setOpenInsuranceTree(props);
    }
  }, []);

  const handleOnChange = useCallback((props) => {
    if (!props) {
      setSelectedInsurance({});
      onSelect();
    }
  }, [onSelect, setSelectedInsurance]);

  return (
    <Tooltip
      overlayClassName="tooltip-container"
      title={getToolTipContent(selectedInsurance)}
    >
      <TreeSelect
        name={name}
        dropdownClassName={`insurance-tree-dropdown ${extraClass || ''}`}
        labelSpan={labelSpan}
        inputSpan={inputSpan}
        options={insuranceList}
        selectNodes={selectedParent}
        disabled={disabled}
        required={required}
        valuePropName={selectedInsurance?.value}
        value={selectedInsurance?.value}
        selectProps={{
          treeDataSimpleMode: true,
          onSelect: onSelectInsurance,
          selectedInsurance,
          treeDefaultExpandAll: true,
          onChange: handleOnChange,
          loading,
          open: openInsuranceTree,
          onDropdownVisibleChange,
          ...selectProps,
        }}
        dataTestId={dataTestId}
        {...otherProps}
      />
    </Tooltip>
  );
};

InsuranceDetailsSelect.defaultProps = {
  onSelect: () => { /* This is intentional */ },
  initialData: undefined,
  labelSpan: '0',
  inputSpan: '24',
  disabled: false,
  includeSelfProfile: false,
  includeInActive: false,
  includePayerName: false,
  disableOtherThanPrimaryAndSelf: false,
  tabId: '-',
  dateOfService: undefined,
  dosRequired: false,
};

export default InsuranceDetailsSelect;
