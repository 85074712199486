import React from 'react';

import TimeCell from '../../../../../components/Cells/TimeCell';

const columns = (labels) => [
  {
    Header: labels.get('columns.subject'),
    accessor: 'subjectName',
    Cell: ({ row: { original } }) => original?.categoryName,
  },
  {
    Header: labels.get('columns.assignedTo'),
    accessor: 'assignedToName',
    Cell: ({ row: { original } }) => original?.taskDetails?.assignedToName,
  },
  {
    Header: labels.get('columns.createdBy'),
    accessor: 'createdByName',
  },
  {
    Header: labels.get('columns.createdOn'),
    accessor: 'createdOn',
    Cell: (data) => <TimeCell value={data?.row?.original?.createdOn} />,
  },
  {
    Header: labels.get('columns.status'),
    accessor: 'taskStatus',
  },
];

export default columns;
