import React from 'react';

import { UiRoutes } from '../../../../lib/constants';

import TabsComponent from '../../../../components/Tabs';
import SavedNavigationBar from '../../../../components/SavedNavigationBar';
import PatientInformation from './Tabs/PatientInformation';

import Preop from './Tabs/Preop';
import Intraop from './Tabs/Intraop';
import PostNursing from './Tabs/PostofNursing';
import Physician from './Tabs/PhysicianDocument';
import FollowupTab from './Tabs/FollowupTab';
import AnesthesiaIntraop from './Tabs/AnesthesiaIntraop';

const tabsList = [
  {
    id: 'patient-information',
    name: 'Patient Information',
    component: PatientInformation,
  },
  {
    id: 'preop',
    name: 'Preop',
    component: Preop,
  },
  {
    id: 'Intraop',
    name: 'Intraop',
    component: Intraop,
  },
  {
    id: 'anesthesia-intra-op',
    name: 'Anesthesia Intra-op',
    component: PostNursing,
  },
  {
    id: 'physician-documentation',
    name: 'Physician Documentation',
    component: Physician,
  },
  {
    id: 'PostNursing',
    name: 'Recovery',
    component: AnesthesiaIntraop,
  },
  {
    id: 'Followup',
    name: 'followup',
    component: FollowupTab,
  },
];

const Tabs = () => (
  <SavedNavigationBar
    listName="Surgery List"
    parentPath={UiRoutes.SurgeryPatient}
    title={(<h1>Surgery</h1>)}
  >
    <div>
      <div className="heading-area flex justify-content-sp-bt align-center screen-title schedular-settings">
        <h2>Surgery Steps</h2>
      </div>
      <div className="main-form-container surgery-steps-wrap">
        <TabsComponent routerParamsPropsName="parentTab" tabsList={tabsList} id="schedularSetting" className="main-tab-wrap" />
      </div>
    </div>
  </SavedNavigationBar>
);

export default Tabs;

