import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearCurrentTab } from '../../store/actions/navigation';

const WithClearCurrentTab = (WrappedComponent) => function WithClearCurrentTabHOC(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearCurrentTab());
  });
  return <WrappedComponent {...props} />;
};

export default WithClearCurrentTab;
