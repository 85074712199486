/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import { Form as AntdForm, Checkbox } from 'antd';
import PropTypes from 'prop-types';
import Button from '../../../../components/Button';
import Form from '../../../../components/Form';
import Input from '../../../../components/Input';
import Modals from '../../../../components/Modal';
import useRights from '../../../../hooks/useRights';
import rights from '../../../../lib/rights';
import Tooltip from '../../../../components/Tooltip';

const EditCptModal = ({
  labels,
  isModalVisible,
  setModalVisibility,
  selectedCode,
  updateCptTrigger,
}) => {
  const [form] = AntdForm.useForm();
  const [checkboxChecked, setCheckboxChecked] = useState(
    selectedCode.isGlobalPeriodEyeSpecific,
  );
  const [shouldImpactChecked, setEyeImpactChecked] = useState(
    selectedCode.shouldImpactDoctor,
  );
  const [globalPeriod, setGlobalPeriod] = useState('');

  const [
    isAccessToAddOrEditGlobalPeriod,
  ] = useRights([
    rights.access_to_add_or_edit_global_period,
  ]);

  const handleFormSubmit = (formData) => {
    const dataToSend = {
      ...formData,
      isGlobalPeriodEyeSpecific: checkboxChecked,
      shouldImpactDoctor: shouldImpactChecked,
      id: selectedCode.id,
    };
    updateCptTrigger(dataToSend);
  };

  const handleDataPopulating = useCallback(() => {
    if (selectedCode?.isGlobalPeriodEyeSpecific) {
      setCheckboxChecked(true);
    } else {
      setCheckboxChecked(false);
    }
    if (selectedCode?.shouldImpactDoctor) {
      setEyeImpactChecked(true);
    } else {
      setEyeImpactChecked(false);
    }

    if (selectedCode?.globalPeriod) {
      setGlobalPeriod(selectedCode.globalPeriod);
    }
  }, [selectedCode]);

  useEffect(() => {
    handleDataPopulating();
  }, [handleDataPopulating]);

  const handleCheckboxCheck = (e) => {
    const { checked, name } = e.target;
    if ((name) === 'isGlobalPeriodEyeSpecific') {
      setCheckboxChecked(checked);
    } else if ((name) === 'shouldImpactDoctor') {
      setEyeImpactChecked(checked);
    }
  };

  const handleGlobalPeriodChange = (e) => {
    const { value } = e.target;
    if (value.length > 3 || /[^\d]/.test(value)) {
      e.target.value = globalPeriod;
    } else {
      setGlobalPeriod(value);
    }
  };

  return (
    <Modals
      visible={isModalVisible}
      toggleModal={() => setModalVisibility(!isModalVisible)}
      width={800}
      destroyOnClose
      className="schedular-patient-modal"
      footer={[
        <div className="group-btns" key="footer">
          <Button className="btn min-wt-86" onClick={() => setModalVisibility(!isModalVisible)}>
            {labels.get('buttons.cancel')}
          </Button>
          <Button className="btn btn-success min-wt-86 inline" onClick={form.submit}>
            {labels.get('buttons.save')}
          </Button>
        </div>,
      ]}
    >
      <div className="addition-header">
        <div className="lookup sprite-img-before">
          <p>{labels.get('titles.editCpt')}</p>
        </div>
      </div>
      <div className="modal-content-weapper shadow-wrap">
        <div className="modal-from-feilds">
          <Form form={form} onFinish={handleFormSubmit}>
            <Form.Column>
              {selectedCode?.cptCode && (
                <Input
                  name={null}
                  label={labels.get('labels.code')}
                  value={selectedCode?.cptCode}
                  labelSpan="8"
                  inputSpan="16"
                  className="non-editable"
                  disabled
                />
              )}
              {selectedCode?.description && (
                <Input
                  name={null}
                  label={labels.get('labels.description')}
                  value={selectedCode?.description}
                  inputSpan="16"
                  labelSpan="8"
                  className="non-editable"
                  disabled
                />
              )}
              <Input
                name={null}
                label={labels.get('labels.status')}
                value={selectedCode?.isActive ? 'Active' : 'Inactive'}
                inputSpan="16"
                labelSpan="8"
                className="non-editable"
                disabled
              />
              {isAccessToAddOrEditGlobalPeriod && (
                <>
                  <AntdForm.Item name="globalPeriod" initialValue={selectedCode?.globalPeriod}>
                    <Input
                      placeholder={labels.get('placeHolders.globalPeriod')}
                      value={globalPeriod}
                      onInput={handleGlobalPeriodChange}
                      label={labels.get('labels.globalPeriod')}
                      inputSpan="16"
                      labelSpan="8"
                    />
                  </AntdForm.Item>
                  <AntdForm.Item
                    label={labels.get('labels.eyeSpecific')}
                    name="isGlobalPeriodEyeSpecific"
                    initialValue={selectedCode?.isGlobalPeriodEyeSpecific}
                  >
                    <Checkbox name="isGlobalPeriodEyeSpecific" onChange={(e) => handleCheckboxCheck(e)} checked={checkboxChecked} />
                  </AntdForm.Item>
                  <AntdForm.Item
                    label={labels.get('labels.impactOnDoctorsApp')}
                    name="shouldImpactDoctor"
                    initialValue={selectedCode?.shouldImpactDoctor}
                  >
                    <Tooltip title="This will impact on Doctor's App">
                      <Checkbox name="shouldImpactDoctor" onChange={(e) => handleCheckboxCheck(e)} checked={shouldImpactChecked} />
                    </Tooltip>
                  </AntdForm.Item>
                </>
              )}
            </Form.Column>
          </Form>
        </div>
      </div>
    </Modals>
  );
};

EditCptModal.defaultProps = {
  selectedCode: {},
  updateCptTrigger: () => {
    /* This is intentional */
  },
};

EditCptModal.propTypes = {
  selectedCode: PropTypes.instanceOf(Object),
  updateCptTrigger: PropTypes.func,
};

export default EditCptModal;
