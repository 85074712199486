import React, { useEffect } from 'react';
import { Form as AntdForm } from 'antd';
import moment from 'moment';

import Form from '../../../../../../../components/Form';
import Loader from '../../../../../../../components/Loader';
import CheckBox from '../../../../../../../components/Form/CheckBox';
import DatePicker from '../../../../../../../components/Form/DatePicker';

import { enums } from '../../../../../../../lib/constants';
import { toDateValidator } from '../../../../../../../lib/validator';

import Select from '../../../../../../../wiredComponents/Enum/Select';
import PayerAutoComplete from '../../../../../../../wiredComponents/PayerGridAutoComplete';

const BillingForm = ({
  form,
  loading = false,
  labels,
  isClear,
  setClear,
  formData,
  onGetResponseComplete,
}) => {
  useEffect(() => {
    if (isClear) {
      setClear(false);
      onGetResponseComplete(formData);
    }
  }, [formData, isClear, onGetResponseComplete, setClear]);

  return (
    <>
      {loading && <Loader />}
      <Form.Section noOfColumns={3}>
        <Form.Column>
          <div className="">
            <div className="wrap-payer-option">
              <AntdForm.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => (
                  prevValues.isAllPayers !== currentValues.isAllPayers)}
              >
                {({ getFieldValue }) => (
                  <PayerAutoComplete
                    label={labels.get('labels.payer')}
                    name="payerId"
                    form={form}
                    labelSpan="8"
                    inputSpan="16"
                    required={!getFieldValue('isAllPayers')}
                    disabled={getFieldValue('isAllPayers')}
                    initialValue={formData?.payerName && formData?.payerId
                      ? { name: formData?.payerName, value: formData?.payerId }
                      : null}
                  />
                )}
              </AntdForm.Item>
              <CheckBox
                label={labels.get('labels.all')}
                valuePropName="checked"
                name="isAllPayers"
                labelSpan="8"
                inputSpan="12"
              />
            </div>
            <DatePicker
              label={labels.get('labels.effectiveDate')}
              id="13"
              required
              name="effectiveDateFrom"
              labelSpan="8"
              inputSpan="16"
              disabledDate={(current) => current && current <= moment().subtract(150, 'years')}
            />
            <DatePicker
              label={labels.get('labels.to')}
              id="13"
              name="effectiveDateTo"
              labelSpan="8"
              inputSpan="16"
              required
              disabledDate={(current) => current && current <= moment().subtract(150, 'years')}
              dependencies={['effectiveDateFrom']}
              rules={[
                ({ getFieldValue }) => toDateValidator(getFieldValue, 'effectiveDateFrom'),
              ]}
            />
          </div>
        </Form.Column>
        <Form.Column>
          <div className="">
            <Select
              required
              label={labels.get('labels.payToProvider')}
              name="paytoProviderId"
              labelSpan="8"
              inputSpan="16"
              enumName={enums.CASE_TYPE}
              form={form}
            />
            <Select
              required
              label={labels.get('labels.billToProvider')}
              name="billtoProviderId"
              labelSpan="8"
              inputSpan="16"
              enumName={enums.CASE_TYPE}
              form={form}
            />
          </div>
        </Form.Column>
        <Form.Column>
          <div>
            <div className="">
              <div className="repeat-wrap">
                <CheckBox
                  label={labels.get('labels.billToSpecialty')}
                  name="billtoSpeciality"
                  valuePropName="checked"
                  labelSpan="8"
                  inputSpan="16"
                />
                <span className="repeat-option">Bill to Provider</span>
              </div>
              <div className="repeat-wrap">
                <CheckBox
                  label={labels.get('labels.billToAddress')}
                  name="billtoAddress"
                  valuePropName="checked"
                  labelSpan="8"
                  inputSpan="16"
                />
                <span className="repeat-option">Bill to Provider</span>
              </div>
            </div>
          </div>
        </Form.Column>
      </Form.Section>
    </>
  );
};

export default BillingForm;
