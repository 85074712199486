import React, { useCallback } from 'react';

import RadioGroup from '../../components/RadioGroup';
import InsuranceAdjustment from './Components/InsuranceAdjustment';
import PatientAdjustment from './Components/PatientAdjustment';
import useRedirect from '../../hooks/useRedirect';
import useReduxState from '../../hooks/useReduxState';

const Components = {
  insuranceAdjustment: InsuranceAdjustment,
  patientAdjustment: PatientAdjustment,
};
const tabList = [
  { value: 'insuranceAdjustment', label: 'Insurance Adjustment' },
  { value: 'patientAdjustment', label: 'Patient Adjustment' },
];

const Adjustment = ({ closeTab }) => {
  const { params: { adjustmentType } } = useRedirect();
  const [activeTab, setActiveTab] = useReduxState('patient-financial-adjustment', adjustmentType || 'patientAdjustment');

  const getTabComponent = useCallback(() => Components[activeTab], [activeTab]);

  const tabSwitchHandler = useCallback(({ target: { value } }) => {
    setActiveTab(value);
  }, [setActiveTab]);

  const TabComponent = getTabComponent();
  return (
    <>
      <div className="addition-header mng-addition-header">
        <div className="lookup sprite-img-before">
          <h2>{activeTab === 'insuranceAdjustment' ? 'Insurance Adjustment' : 'Patient Adjustment'}</h2>
        </div>
        <RadioGroup
          tabList={tabList}
          value={activeTab}
          onChange={tabSwitchHandler}
        />
      </div>
      <TabComponent closeTab={closeTab} />
    </>
  );
};
export default Adjustment;
