import React, { useCallback, useState } from 'react';
import { Form as AntdForm } from 'antd';

import Modals from '../../../../components/Modal';
import Button from '../../../../components/Button';

import AddTask from '../../../Modal/Tasks/Components/AddTask';

import '../../billing.scss';
import useRedirect from '../../../../hooks/useRedirect';
import { UiRoutes } from '../../../../lib/constants';

const EditTaskModal = ({
  visible, labels, manualInterventionQueueTaskId, toggleModal,
  changeTaskFields,
}) => {
  const [form] = AntdForm.useForm();
  const [isFileUpdate, setFileUpdate] = useState(false);
  const {
    params: { taskId }, params, push, generatePath,
  } = useRedirect();
  const id = manualInterventionQueueTaskId || taskId;
  const submitForm = useCallback(() => form.submit(), [form]);

  const navigateBack = useCallback(() => {
    if (manualInterventionQueueTaskId) {
      toggleModal();
    } else {
      push(generatePath(UiRoutes.tasksWithTabId, params));
    }
  }, [generatePath, manualInterventionQueueTaskId, params, push, toggleModal]);

  return (
    <Modals
      visible={visible}
      toggleModal={navigateBack}
      destroyOnClose
      title={labels.get('titles.editTask')}
      width={1020}
      footer={[
        <div className="group-btns" key="footer">
          <Button className="btn min-wt-86" onClick={navigateBack}>{labels.get('buttons.cancel')}</Button>
          <Button className="btn btn-success min-wt-86 inline" onClick={submitForm}>{labels.get('buttons.update')}</Button>
        </div>,
      ]}
    >
      <div className="tasks-modal-add-new">
        <AddTask
          form={form}
          labels={labels}
          taskId={id}
          isFileUpdate={isFileUpdate}
          setFileUpdate={setFileUpdate}
          toggleAddMode={navigateBack}
          changeTaskFields={changeTaskFields}
          manualInterventionQueueTaskId={manualInterventionQueueTaskId}
        />
      </div>
    </Modals>
  );
};
export default EditTaskModal;
