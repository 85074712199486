import React, {
  useMemo, useEffect, useState, useCallback,
} from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import Image from '../../../../../../components/Image';
import { getName } from '../../../../../../lib/util';

import useRedirect from '../../../../../../hooks/useRedirect';
import { schedulerDataDateFormat, UiRoutes } from '../../../../../../lib/constants';

import EventWrapper from '../../../../../../components/EventWrapper';
import WidgetLoader from '../../../../../../components/WidgetLoader';
import * as selectors from '../../../../../../store/selectors';

import './card.scss';

const padTimeString = (time) => String(`0${time}`).slice(-2);

const PatientBasicInfo = ({
  patientImage, patientName,
  visitType, index, status, data,
  checkInTimer, statusTimer, reachTime,
  startTime,
}) => (
  <div className="row-details">
    <div className="avatar">
      <Image url={patientImage?.patientImageUrl ? patientImage?.patientImageUrl : data?.patientImageUrl} name="placeholder-img.png" alt="user-img" />
    </div>
    <div className="basic-info">
      <div className="row-name">
        <div className="name">{patientName}</div>
        {visitType && <div className="visit">{visitType?.visitName}</div>}
        {index >= 0 && (
        <div className="time">
          {status !== 'New'
                && (
                <>
                  <span>{checkInTimer}</span>
                  <span>{statusTimer}</span>
                </>
                )}
        </div>
        )}
      </div>
      <div className="row-time">
        <div className="time-stamp">
          Patient Arrival Time:
          {reachTime}
        </div>
        {/* <div className="dot-separator" /> */}
        <div className="time-stamp">
          Doctor Time:
          {startTime}
        </div>
        {/* <span className="dot-separator" />
            <span className="time-stamp">{endTime}</span> */}
      </div>
    </div>
  </div>
);

const getClassName = ({ status, durationCode }) => (['New', 'Cancel'].indexOf(status) < 0
  ? `legend-${durationCode}min`
  : `legend-${status}`);

const ProviderDetail = ({ providerName }) => (
  providerName ? (
    <div className="row-doctor">
      <span className="key">Doctor:</span>
      <span className="value">{providerName}</span>
    </div>
  ) : <span />
);

const StatusDetail = ({ statusChangedBy }) => (
  statusChangedBy ? (
    <div className="row-technician">
      <span className="key">Moved By:</span>
      <span className="value">{statusChangedBy}</span>
    </div>
  ) : <span />
);

const setDurationAndCheckInTimer = ({
  time, checkInTimeMoment, durationCode, setDurationCode, setCheckInTimer,
}) => {
  const checkInDuration = moment.duration(time - checkInTimeMoment, 'seconds');
  if (durationCode < 30) {
    const codeFromTimeElapsed = Math.min(Math.floor(checkInDuration.asMinutes() / 10) * 10, 30);
    if (codeFromTimeElapsed !== durationCode) setDurationCode(codeFromTimeElapsed);
  }
  let timer = checkInDuration.hours() ? `${padTimeString(checkInDuration.hours())}:` : '';
  timer += `${padTimeString(checkInDuration.minutes())}:${padTimeString(checkInDuration.seconds())}`;
  setCheckInTimer(timer);
};

const getCheckedInDuration = ({ patientCheckInDateString, statusChangedOn }) => {
  const checkInTime = moment(patientCheckInDateString, schedulerDataDateFormat);
  const lastStatusChangeTime = moment(statusChangedOn);
  const totalClinicTime = lastStatusChangeTime.diff(checkInTime, 'seconds');
  return moment.duration(totalClinicTime, 'seconds');
};

const Card = ({
  index, data, style, onHover, time, onClick,
  onDoubleClick,
  accept, loading, patientImage,
}) => {
  const {
    ref,
    status,
    appointmentId,
    // totalDoctorTime,
    statusChangedOn,
    // patientCheckInDateString,
    patientCheckInDateString,
    patientFirstName,
    patientMiddleName,
    patientLastName,
    providerFirstName,
    providerMiddleName,
    providerLastName,
    statusChangedBy,
    visitType,
    totalWorkupTime,
    appointmentStartDateTimeModel,
    currentStatusPastTime = 0,
    totalDurationInClinic = 0,
  } = data;
  const [checkInTimer, setCheckInTimer] = useState('');
  const [statusTimer, setStatusTimer] = useState('');
  const [durationCode, setDurationCode] = useState(0);

  const { replace, generatePath } = useRedirect();

  const startTimeMoment = useMemo(
    () => moment(appointmentStartDateTimeModel?.dateString, schedulerDataDateFormat),
    [appointmentStartDateTimeModel],
  );

  const startTime = useMemo(
    () => moment(startTimeMoment).format('h:mm A'),
    [startTimeMoment],
  );

  const reachTime = useMemo(
    () => moment(startTimeMoment).subtract(totalWorkupTime, 'minutes').format('h:mm A'),
    [startTimeMoment, totalWorkupTime],
  );

  const checkInTimeMoment = useMemo(
    () => moment().subtract(totalDurationInClinic, 'seconds')
      .unix(),
    [totalDurationInClinic],
  );
  const statusTimeMoment = useMemo(
    () => (statusChangedOn
      ? moment(statusChangedOn).subtract(currentStatusPastTime, 'seconds').unix() : moment().unix()),
    [currentStatusPastTime, statusChangedOn],
  );

  const patientName = useMemo(
    () => getName(patientFirstName, patientMiddleName, patientLastName),
    [patientFirstName, patientMiddleName, patientLastName],
  );

  const providerName = useMemo(
    () => getName(providerFirstName, providerMiddleName, providerLastName),
    [providerFirstName, providerMiddleName, providerLastName],
  );

  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: status || '', ...data,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => (moment().format('D-M-YYYY') === moment(startTimeMoment).format('D-M-YYYY')),
  });

  const [{ isOver }, drop] = useDrop({
    accept,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  useEffect(() => { if (isOver) onHover(data.id, index); }, [isOver]);

  useEffect(() => { preview(getEmptyImage(), { captureDraggingState: true }); }, []);

  useEffect(() => {
    if (time && checkInTimeMoment) {
      setDurationAndCheckInTimer({
        time, checkInTimeMoment, durationCode, setDurationCode, setCheckInTimer,
      });
    } else {
      let timer = '00:00';
      if (patientCheckInDateString && statusChangedOn) {
        const checkInDuration = getCheckedInDuration({ patientCheckInDateString, statusChangedOn });
        timer = checkInDuration.hours() ? `${padTimeString(checkInDuration.hours())}:` : '';
        timer += `${padTimeString(checkInDuration.minutes())}:${padTimeString(checkInDuration.seconds())}`;
      }
      setCheckInTimer(timer);
    }
    if (time && statusTimeMoment) {
      const statusDuration = moment.duration(time - (statusTimeMoment), 'seconds');
      let timer = statusDuration.hours() ? `${padTimeString(statusDuration.hours())}:` : '';
      timer += `${padTimeString(statusDuration.minutes())}:${padTimeString(statusDuration.seconds())}`;
      setStatusTimer(timer);
    }
  }, [time, patientCheckInDateString, statusChangedOn]);

  const onClickCard = useCallback(
    () => onClick(status, data),
    [onClick, status, data],
  );

  const onDoubleClickCard = useCallback(() => {
    if (isFunction(onDoubleClick)) onDoubleClick(status, data);
  }, [onDoubleClick, status, data]);

  const className = getClassName({ status, durationCode });

  drag(drop(ref));

  const viewActivityLog = (e) => {
    e.stopPropagation();
    replace(generatePath(UiRoutes.dashboardWithAppointment,
      { activityLogAppointmentId: appointmentId }));
  };

  return (
    <EventWrapper
      ref={ref}
      style={style}
      className={classNames(
        'dashboard-card-container posi-relative',
        className,
        { 'hide-card-container': isDragging },
      )}
      onClick={onClickCard}
      onDoubleClick={onDoubleClickCard}
    >
      {loading && <WidgetLoader size="medium" />}
      <PatientBasicInfo
        patientImage={patientImage}
        patientName={patientName}
        visitType={visitType}
        index={index}
        status={status}
        data={data}
        checkInTimer={checkInTimer}
        statusTimer={statusTimer}
        reachTime={reachTime}
        startTime={startTime}
      />
      <div className="card_bottomcard_outer-wrapper">
        <div>
          <ProviderDetail providerName={providerName} />
          <StatusDetail statusChangedBy={statusChangedBy} />
        </div>
        <div className="card_event-wrapper">
          <EventWrapper
            type="span"
            className="info-img sprite-img-before  cursor-pointer"
            onClick={viewActivityLog}
          />
        </div>
      </div>
    </EventWrapper>
  );
};

Card.defaultProps = {
  index: -1,
  data: {},
  style: {},
  onClick: () => { /* This is intentional */ },
  onHover: () => { /* This is intentional */ },
  time: moment().unix(),
  accept: [],
};

export default connect((states, ownProps) => ({
  patientImage: selectors.getCrudData(states, `profile-image-${ownProps?.data?.patientId}`),
}))(Card);
