import React from 'react';

import WithLabel from '../../../../hoc/withLabel';
import { labelPaths } from '../../../../lib/constants';
import useRedirect from '../../../../hooks/useRedirect';

import CasesTable from '../../../CasesTable';
import columns from './Table/columns';

import './cases.scss';

const Cases = ({ labels }) => {
  const { params: { id } } = useRedirect();
  return (
    <CasesTable labels={labels} columns={columns} patientId={id} scrollId="casesTable" className="cases-table" />
  );
};
export default WithLabel(Cases, labelPaths.ENCOUNTERS_CASES);
