import React, {
  useState, useEffect, useRef, useMemo,
} from 'react';
import moment from 'moment';

import dateFormatter from '../../../../lib/dateFormatter';
import { dateFormatStartsWithYear, dateFormatStartsWithYearSeconds, timeFormat } from '../../../../lib/constants';

const cellHeight = 40;

function getTodayMoment(selectedDate, current) {
  const date = dateFormatter(selectedDate, dateFormatStartsWithYear);
  const time = dateFormatter(current, timeFormat);
  return moment(`${date} ${time}`, dateFormatStartsWithYearSeconds);
}

const TimeNeedle = ({
  show,
  selectedDate,
  timeRange: { from, to },
  updateIn = 60000,
  numberOfRows,
  topShift = 0,
  leftShift = 0,
}) => {
  const timeInterval = useRef();

  const [currentTime, setCurrentTime] = useState(null);

  useEffect(() => {
    if (timeInterval.current) clearInterval(timeInterval.current);
    if (moment().isSame(selectedDate, 'day')) {
      setCurrentTime(moment().second(0).millisecond(0));
      timeInterval.current = setInterval(() => {
        setCurrentTime(moment().second(0).millisecond(0));
      }, updateIn);
    }
    return () => {
      if (timeInterval.current) clearInterval(timeInterval.current);
    };
  }, [show, selectedDate]);

  const todayStartYAt = useMemo(() => getTodayMoment(selectedDate, from), [selectedDate, from]);
  const todayEndYAt = useMemo(() => getTodayMoment(selectedDate, to), [selectedDate, to]);
  const clientHeight = useMemo(() => numberOfRows * cellHeight, [numberOfRows]);

  if (show) {
    // calculate the position of the needle through time
    const top = (currentTime
      ? (moment(currentTime).diff(todayStartYAt)
      / moment(todayEndYAt).diff(todayStartYAt))
      * clientHeight
      : 0) + topShift;

    // show needle for the same date
    if (currentTime && moment(currentTime).isSame(selectedDate, 'day')) {
      return (
        <div className="day-view-time-needle" style={{ top, left: leftShift }}>
          <div className="day-view-time-needle-head" />
        </div>
      );
    }
  }
  return null;
};

export default TimeNeedle;
