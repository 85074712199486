import React from 'react';
import Fundus from '../../../../../../Doctor/Components/ODOS/Fundus';
import SlitExam from '../../../../../../Doctor/Components/ODOS/SlitExam';
import '../../../../../../Doctor/doctor.scss';

const ExamFind = () => (
  <div className="select-box-section">
    <Fundus />
    <SlitExam />
  </div>
);

export default ExamFind;
