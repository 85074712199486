import React from 'react';

import TimeCell from '../../../../../../../components/Cells/TimeCell';

const columns = (labels) => [
  {
    Header: labels.get('tableColumns.effectiveFrom'),
    accessor: 'effectiveDateFrom',
    Cell: ({
      row: {
        original: {
          authorizationId, id, effectiveDateFrom,
        },
      }, navigateToEditAuthorization,
    }) => (
      <span
        className="cursor-pointer"
        onClick={() => {
          navigateToEditAuthorization({ authorizationId: authorizationId || id });
        }}
        role="presentation"
      >
        <TimeCell value={effectiveDateFrom} />
      </span>
    ),
    sort: true,
  },
  {
    Header: labels.get('tableColumns.effectiveTo'),
    accessor: 'effectiveDateTo',
    Cell: (e) => <TimeCell value={e.row.original.effectiveDateTo} />,
    sort: true,

  },
  {
    Header: labels.get('tableColumns.number'),
    accessor: 'authorizationNo',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.name'),
    accessor: 'name',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.units'),
    accessor: 'unitsAllowed',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.visits'),
    accessor: 'visitedAllowed',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.modifiedBy'),
    accessor: 'modifiedByName',
  },
  {
    Header: labels.get('tableColumns.modifiedOn'),
    accessor: 'modifiedOn',
    Cell: (e) => <TimeCell value={e.row.original.modifiedOn} />,
    sort: true,
  },
];

export default columns;
