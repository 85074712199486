export const SUPERBILL_ACTIONS = {
  SET_STATUS: 'SET_STATUS',
  SET_DOWNLOAD_URL: 'SET_DOWNLOAD_URL',
  RESET: 'RESET_SUPERBILL',
  ADD_TASK: 'ADD_TASK',
  RESET_TASK: 'RESET_TASK',
  GET_SUPERBILL_TASK: 'GET_SUPERBILL_TASK',
  HYDRATE_TASKS: 'HYDRATE_TASKS',
  POLL_SUPERBILL_STATUS: 'POLL_SUPERBILL_STATUS',
  SET_POLLING: 'SET_POLLING',
  SET_DOWNLOADING: 'SET_DOWNLOADING',
  SET_TIME_PERIOD: 'SET_TIME_PERIOD',
  DISABLE_PRINT_BUTTON: 'DISABLE_PRINT_BUTTON',
  ENABLE_PRINT_BUTTON: 'ENABLE_PRINT_BUTTON',
};

export const superBillActions = {
  addSuperBillTask: (task) => ({
    type: SUPERBILL_ACTIONS.ADD_TASK,
    payload: task,
  }),
  resetSuperBillTask: (taskId) => ({
    type: SUPERBILL_ACTIONS.RESET_TASK,
    taskId,
  }),
  getSuperBillTask: (payload) => ({
    type: SUPERBILL_ACTIONS.GET_SUPERBILL_TASK,
    payload,
  }),
  setSuperBillStatus: (status, taskId) => ({
    type: SUPERBILL_ACTIONS.SET_STATUS,
    payload: {
      status,
      taskId,
    },
  }),
  setSuperBillDownloadUrl: (downloadUrl, taskId) => ({
    type: SUPERBILL_ACTIONS.SET_DOWNLOAD_URL,
    payload: {
      downloadUrl,
      taskId,
    },
  }),
  resetSuperBill: () => ({
    type: SUPERBILL_ACTIONS.RESET,
  }),
  hydrateSuperBillTasks: (tasks) => ({
    type: SUPERBILL_ACTIONS.HYDRATE_TASKS,
    payload: tasks,
  }),
  pollSuperBillStatus: (taskId) => ({
    type: SUPERBILL_ACTIONS.POLL_SUPERBILL_STATUS,
    payload: { taskId },
  }),
  setSuperBillPolling: (taskId, polling) => ({
    type: SUPERBILL_ACTIONS.SET_POLLING,
    payload: {
      taskId,
      polling,
    },
  }),
  setSuperBillDownloading: (taskId, downloading) => ({
    type: SUPERBILL_ACTIONS.SET_DOWNLOADING,
    payload: {
      taskId,
      downloading,
    },
  }),
  setTimePeriod: (period) => ({
    type: SUPERBILL_ACTIONS.SET_TIME_PERIOD,
    payload: period,
  }),
  disablePrintButton: () => ({
    type: SUPERBILL_ACTIONS.DISABLE_PRINT_BUTTON,
  }),
  enablePrintButton: () => ({
    type: SUPERBILL_ACTIONS.ENABLE_PRINT_BUTTON,
  }),
};
