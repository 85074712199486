import React, { useCallback } from 'react';
import { Form as AntdForm } from 'antd';

import Modals from '../../../../components/Modal';
import Button from '../../../../components/Button';

import { labelPaths, UiRoutes } from '../../../../lib/constants';
import Events from '../../../../lib/events';
import SuccessMessages from '../../../../lib/successMessages';

import WithLabel from '../../../../hoc/withLabel';

import useRedirect from '../../../../hooks/useRedirect';

import Notification from '../../../../components/Notification';

import NewBatchPopupForm from './newBatchForm';

import './newBatchForm.scss';

const NewBatchPopup = (props) => {
  const {
    params, push, generatePath,
  } = useRedirect();

  const [form] = AntdForm.useForm();

  const { labels } = props;

  const { claimFilter } = params;

  const onCancel = useCallback(() => {
    push(generatePath(UiRoutes.remittanceClaimsFilters, { ...params, claimFilter }));
  }, [push, generatePath, params, claimFilter]);

  const onSubmit = useCallback(() => {
    const fileTypeInfo = form.getFieldValue('file')?.[0]?.type;
    if (fileTypeInfo === 'application/pdf' || fileTypeInfo === undefined) {
      form.submit();
    } else {
      Notification({ message: 'Only pdf files are supported', success: false });
    }
  }, [form]);

  const onComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.REMITTANCE_BATCH_ADDED_SUCCESSFULLY, success: true });
      push(generatePath(UiRoutes.remittanceClaimsFilters, { ...params }));
      Events.trigger('refetchRemittanceTable');
    }
  }, [generatePath, params, push]);

  return (
    <Modals
      visible
      toggleModal={onCancel}
      width={400}
      className="create-new-batch"
      footer={[
        <div className="group-btns mng-footer-btn" key="footer">
          <Button className="btn min-wt-86 sm-btn" data-testid="cancel-batch-button" onClick={onCancel}>{labels.get('buttons.cancel')}</Button>
          <Button className="btn btn-success min-wt-86 inline sm-btn" data-testid="create-batch-button" onClick={onSubmit}>
            {labels.get('buttons.createBatch')}
          </Button>
        </div>,
      ]}
    >
      <div className="addition-header">
        <div className="lookup sprite-img-before">
          <p>{labels.get('titles.createNewBatch')}</p>
        </div>
      </div>
      <NewBatchPopupForm
        labels={labels}
        form={form}
        onComplete={onComplete}
        isNewbatch={props?.isNewbatch}
      />
    </Modals>
  );
};

export default WithLabel(NewBatchPopup, labelPaths.BATCH_POPUP);
