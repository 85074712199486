import React from 'react';

import { listId, labelPaths } from '../../lib/constants';
import { apiUrls } from '../../api/constants';

import withQuery from '../../hoc/withQuery/withQuery';
import columns from './columns';
import TableWrapper from './TableWrapper';
import WithLabel from '../../hoc/withLabel';

const initialSort = [{ id: 'firstName', desc: false }];

const CustomPatientTable = withQuery({
  url: apiUrls.GET_PATIENT,
  listId: listId.SCHEDULAR_PATIENTS_LIST,
  alias: {
    firstName: 'FirstName',
    nextAppDate: 'NextAppDate',
    lastVisitedDate: 'LastVisitedDate',
    chartNumber: 'ChartNumber',
    patientBal: 'PatientBal',
    insuranceBal: 'InsuranceBal',
  },
})();

const PatientTable = ({
  onRowClick, labels, column, columnPropsPassed, ...props
}) => (
  <CustomPatientTable
    columns={columnPropsPassed === true ? column(onRowClick, labels) : columns(onRowClick, labels)}
    initialSort={initialSort}
    scrollId="patientListModal"
    pageSize={20}
    {...props}
  >
    {({ Component, reFetch }) => <TableWrapper Component={Component} reFetch={reFetch} />}
  </CustomPatientTable>
);

export default WithLabel(PatientTable, labelPaths.PATIENT_LIST);
